<script>
import InsightsItemsGroup from "@/components/insights/InsightsItemsGroup.vue";

export default {
  name: "InsightsCategoryItems",
  components: {InsightsItemsGroup},
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    itemsGroupedByName() {
      if (!this.items.length) return []

      return this.items.reduce((acc, obj) => {
        const key = obj.name;
        (acc[key] = acc[key] || []).push(obj);
        return acc;
      }, {});
    }
  }
}
</script>

<template>
  <div>
    <insights-items-group
      v-for="(items, key) in itemsGroupedByName"
      :items="items"
      :key="key"
      :group-title="key"
    />
  </div>
</template>

<style scoped>

</style>