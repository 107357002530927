<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'school-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>
        {{ $t("route.schoolSettings.children.demoMode") }}
      </v-card-title>
    </v-toolbar>
    <v-card-text>
      <v-row dense :no-gutters="$vuetify.breakpoint.xsOnly">
        <v-col cols="12" sm="9" md="10" class="d-flex justify-space-between">
          <div>
            <h4>{{ $t('label.reset_demo_account') }}</h4>
            <p class="text--secondary mt-1">{{ $t('label.reset_demo_account_explanation') }}</p>
          </div>
          <v-divider class="mx-5" v-if="$vuetify.breakpoint.smAndUp" vertical/>
        </v-col>
        <v-col cols="12" sm="3" md="2" :class="$vuetify.breakpoint.smAndUp && 'text-center'">
          <v-btn
              :loading="resettingDemoAccount"
              :disabled="resettingDemoAccount || switchingToRealUsage"
              color="warning"
              @click="resetAccount"
          >
            {{ $t('btn.reset_demo_account') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-divider class="my-5" />

      <v-row dense :no-gutters="$vuetify.breakpoint.xsOnly">
        <v-col cols="12" sm="9" md="10" class="d-flex justify-space-between">
          <div>
            <h4>{{ $t('label.switch_to_real_usage') }}</h4>
            <p class="text--secondary mt-1">{{ $t('label.switch_to_real_usage_explanation') }}</p>
          </div>
          <v-divider class="mx-5" v-if="$vuetify.breakpoint.smAndUp" vertical/>
        </v-col>
        <v-col cols="12" sm="3" md="2" :class="$vuetify.breakpoint.smAndUp && 'text-center'">
          <DemoModeToolTipWrapper :disabled="currentUser.isSchoolOwner" :tooltipText="$t('label.only_school_administrator')">
            <v-btn
              :loading="switchingToRealUsage"
              :disabled="switchingToRealUsage || resettingDemoAccount || !currentUser.isSchoolOwner"
              color="success"
              @click="switchToRealUsage"
            >
              {{ $t('btn.switch_to_real_usage') }}
            </v-btn>
          </DemoModeToolTipWrapper>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import responsive from '@/utils/mixins/responsive'
import user from '@/utils/mixins/user'
import { showConfirm } from '@/helpers/dialogs'
import schoolService from '@/services/schoolService'
import DemoModeToolTipWrapper from '@/views/DemoModeToolTipWrapper.vue'

export default {
  name: "DemoMode",
  components: { DemoModeToolTipWrapper },
  mixins: [responsive, user],
  data() {
    return {
      localState: undefined,
      resettingDemoAccount: false,
      switchingToRealUsage: false
    };
  },
  mounted() {
    this.localState = this.$store.state.school.isDigitalAttendanceEnabled;
    this.changing = false;
  },
  methods: {
    resetAccount() {
      showConfirm(
        this.$t('btn.confirm'),
        this.$t('messages.are_your_sure_reset_demo_account'),
        () => {
          this.resettingDemoAccount = true
          schoolService.resetDemoAccount()
            .then(() => location.reload())
            .catch(error => {
              const responseData = error.response.data;
              if (responseData.message) this.$snackbar.show({ message: responseData.message, color: "error" });
            })
            .finally(() => {
              this.resettingDemoAccount = false
            })
        }
      )
    },

    switchToRealUsage() {
      showConfirm(
        this.$t('btn.confirm'),
        this.$t('messages.are_your_sure_switch_to_real_usage'),
        () => {
          this.switchingToRealUsage = true
          schoolService.switchToRealUsage()
            .then(() => location.href = '/dashboard')
            .catch(error => {
              const responseData = error.response.data;
              if (responseData.message) this.$snackbar.show({ message: responseData.message, color: "error" });
            })
            .finally(() => {
              this.switchingToRealUsage = false
            })
        }
      )
    },

  },
};
</script>

<style scoped lang="scss"></style>
