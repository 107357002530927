import {Device} from "@capacitor/device";
import {App} from "@capacitor/app";
import packageInfo from '@/../package.json';

export default {
  data () {
    return {
      storeVersion: undefined,
      bundleVersion: undefined,
      platform: null,
    }
  },
  async mounted() {
    this.bundleVersion = packageInfo.version;

    this.platform = (await Device.getInfo()).platform

    if (this.platform !== 'web') {
      const appInfo = await App.getInfo()
      this.storeVersion = `${appInfo.version} (${appInfo.build})`
    }
  },
  computed: {
    appVersion () {
      return this.bundleVersion;
      // return this.platform !== 'web' ? this.storeVersion : this.bundleVersion;
    }
  }
}
