<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <span class="subtitle-1">{{ $t('calendar.events_filter') }}</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-list
        v-if="eventTypes.length > 0"
        dense
        style="max-height: 290px; overflow-y: auto">
        <v-list-item
          :disabled="loaderGetter"
          dense
          v-for="type in eventTypes"
          :key="type.id"
          @click="$emit('eventsToggle', type.id)">
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center">
              <v-icon class="mr-3" :color="type.bodyColor">mdi-record</v-icon>
              {{ $t(`event_type.${type.id}`) }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="mx-1 my-2">
            <CheckmarkBox :checked="activeEventTypes.includes(type.id)" />
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <empty-box v-else />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import CheckmarkBox from "@/components/calendar/CheckmarkBox.vue";
import { mapGetters } from "vuex";
import user from "@/utils/mixins/user";

export default {
  name: "EventsFilter",
  mixins: [user],
  components: { CheckmarkBox },
  props: {
    eventTypes: {
      type: Array,
      required: true,
    },
    titleFontSize: {
      type: Number,
    }
  },
  computed: {
    ...mapGetters(["loaderGetter"]),
    ...mapGetters("appointments", ["activeEventsGetter"]),
    activeEventTypes() {
      if (this.isTeacher)
        return this.activeEventsGetter.filter((type) => type !== "SIMULATOR");
      return this.activeEventsGetter;
    },
  },
};
</script>

<style scoped lang="scss"></style>
