<template>
  <div class="page__content">
    <v-progress-circular
      class="d-flex ma-auto mt-15"
      v-if="loading"
      color="primary"
      indeterminate />
    <slot v-else />
  </div>
</template>

<script>
export default {
  name: "PageContent",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.page__content {
  flex: 1;
  min-height: 0;
  overflow: auto;
}
</style>
