<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'personal-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>{{ $t('route.schoolSettings.children.customMessages') }}</v-card-title>
      <v-spacer/>
      <v-btn :loading="loading" :disabled="loading" text color="primary" @click="save">
        {{ $t('btn.save') }}
      </v-btn>
    </v-toolbar>

    <v-tabs class="mt-3" v-model="tab" :color="markErrorTab ? 'error' : 'primary'">
      <v-tab>
        <v-badge v-if="welcomeError" :content="welcomeError" color="error">
          <span class="error--text">
            {{ $t('route.schoolSettings.children.welcomeMessage') }}
          </span>
        </v-badge>
        <span v-else>{{ $t('route.schoolSettings.children.welcomeMessage') }}</span>
      </v-tab>
      <v-tab>
        <v-badge v-if="birthdayError" :content="birthdayError" color="error">
          <span class="error--text">
            {{ $t('route.schoolSettings.children.birthdayWishes') }}
          </span>
        </v-badge>
        <span v-else>{{ $t('route.schoolSettings.children.birthdayWishes') }}</span>
      </v-tab>
    </v-tabs>

    <v-card-text>
      <v-tabs-items v-model="tab">
        <v-tab-item eager>
          <WelcomeMessage
            ref="WelcomeMessage"
            @setFieldValue="updateFormObject"
            :messageData="welcomeMessagesData"
            :loading="loading"
          />
        </v-tab-item>
        <v-tab-item eager>
          <BirthdayWishes
            ref="BirthdayWishes"
            @setFieldValue="updateFormObject"
            @imagePicked="onImagePick"
            @deleteImage="onImageDelete"
            :uploadedFile="uploadedFile"
            :tempImage="tempImage"
            :messageData="birthdayMessageData"
            :loading="loading"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </div>
</template>

<script>
import user from '@/utils/mixins/user'
import responsive from '@/utils/mixins/responsive'
import WelcomeMessage from '@/views/PersonalSettings/WelcomeMessage.vue'
import BirthdayWishes from '@/views/PersonalSettings/BirthdayWishes.vue'
import schoolService from '@/services/schoolService'


export default {
  name: 'CustomMessages',
  mixins: [user, responsive],
  components: { WelcomeMessage, BirthdayWishes },
  data: () => ({
    loading: true,
    tab: 0,
    uploadedFile: null,
    tempImage: false,
    form: {
      welcomeTitle: null,
      welcomeText: null,
      birthdayTitle: null,
      birthdayText: null,
      birthdayImage: null,
    },
    welcomeError: 0,
    birthdayError: 0,
  }),
  async mounted () {
    this.uploadedFile = null
    this.tempImage = false
    try {
      const responseSchool = await schoolService.customMessages()
      const schoolData = responseSchool.data || {}

      this.form.welcomeTitle = schoolData.welcomeTitle
      this.form.welcomeText = schoolData.welcomeText
      this.form.birthdayTitle = schoolData.birthdayTitle
      this.form.birthdayText = schoolData.birthdayText
      this.form.birthdayImage = schoolData.birthdayImage
      this.uploadedFile = schoolData.birthdayImage
    } catch (e) {
      console.log(e)
    }

    this.loading = false
  },
  computed: {
    markErrorTab() {
      return this.welcomeError && this.tab === 0 || this.birthdayError && this.tab === 1
    },
    welcomeMessagesData() {
      return {
        welcomeTitle: this.form.welcomeTitle,
        welcomeText: this.form.welcomeText
      };
    },
    birthdayMessageData() {
      return {
        birthdayTitle: this.form.birthdayTitle,
        birthdayText: this.form.birthdayText,
        birthdayImage: this.form.birthdayImage
      };
    },
  },
  methods: {
    updateFormObject(val) {
      this.form = { ...this.form, ...val }
    },

    onImagePick(url, file) {
      this.uploadedFile = url;
      this.form.birthdayImage = file;
      this.tempImage = true
    },

    onImageDelete() {
      this.loading = true
      schoolService
        .deleteBirthdayImage()
        .then(() => {
          this.uploadedFile = null
          this.form.birthdayImage = null
          this.$snackbar.show({
            message: this.$t("messages.successfully_updated"),
            color: "success",
            timeout: 2000,
          });
        })
        .finally(() => {
          this.loading = false
          this.tempImage = true
      })
    },

    save () {
      this.loading = true;
      this.welcomeError = 0
      this.birthdayError = 0
      this.$refs.WelcomeMessage.reset();
      this.$refs.BirthdayWishes.reset();

      const formData = new FormData();
      Object.keys(this.form).forEach((field) => {
        const value = this.form[field];

        if (!value) return;

        if (Array.isArray(value)) {
          value.map((item) => formData.append(field + "[]", item));
        } else {
          formData.set(field, value);
        }
      });

      schoolService
        .updateCustomMessages(formData)
        .then((resp) => {
          this.$snackbar.show({
            message: this.$t("messages.successfully_updated"),
            color: "success",
            timeout: 2000,
          });
          const data = resp.data
          this.form = data
          this.uploadedFile = data.birthdayImage
          this.tempImage = false
        })
        .catch((error) => {
          const responseData = error.response?.data || {};
          const errors = responseData?.errors || [];

          if (errors.welcomeTitle) this.welcomeError++;
          if (errors.welcomeText) this.welcomeError++;
          if (errors.birthdayTitle) this.birthdayError++;
          if (errors.birthdayText) this.birthdayError++;

          this.$refs.WelcomeMessage.setErrors(errors);
          this.$refs.BirthdayWishes.setErrors(errors);

          if (this.uploadedFile) {
            this.tempImage = true
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
}
</script>

<style scoped></style>
