<template>
  <v-card
    elevation="0"
    class="event-card"
    @click="$emit('open')"
    @hover.prevent.self
  >
    <div class="flex-time">
      <div>
        {{ event.startDate }} | {{ event.startTime }} - {{ event.endTime }}
      </div>
      <div>{{ event.duration }} <v-icon size="small">mdi-clock</v-icon></div>
    </div>

    <div v-if="!isTeacher && event.teacher">{{ event.teacher }}</div>
    <span v-if="!isStudent && student" :class="{ 'font-italic': student.isDeleted }">
      {{ student.fullName }}
    </span>

    <div>
      {{ $t("event_type.PRACTICE") }}
      <span>
        ({{ event.license }}
        <span v-if="event.lessonType">— {{ $tc(event.lessonType) }}</span>
        <span v-if="event.manualLesson" class="ml-1">S</span>)
      </span>
    </div>

    <div v-if="event.primaryVehicle" class="vehicles">
      <div class="caption">{{ event.primaryVehicle }}</div>
      <div class="caption" v-if="event.secondaryVehicleId">
        {{ secondaryVehicle.label }}
      </div>
    </div>

    <div class="mt-1 d-flex" v-if="event.meetingPlace">
      <div class="meeting-place d-flex mr-4">
        <v-icon size="19">mdi-map-marker</v-icon>
        <div class="text-caption text-truncate">{{ event.meetingPlace }}</div>
      </div>

      <div class="d-flex ml-auto">
        <v-icon v-if="event.status === 'REQUESTED'" class="ml-1 icon" size="15">mdi-help-circle</v-icon>
      </div>
    </div>
  </v-card>
</template>

<script>
import user from "@/utils/mixins/user";
import StudentModel from "@/store/models/StudentModel";

export default {
  name: "PracticeAlert",
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [user],
  computed: {
    studentsIds() {
      return this.event.studentsIds || [];
    },
    student() {
      if (this.$route.name === "students.student.agenda") return null;
      if (this.event.studentsIds.length === 0) return null;
      return StudentModel.find(this.studentsIds[0]);
    },
  },
};
</script>

<style scoped lang="scss">
.event-card {
  padding: 0 5px 5px 5px;
  margin: 5px 0;
  font-size: 0.7rem;
  background: #fff !important;
  border-right: 1px solid #eee;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  .flex-time {
    display: flex;
    justify-content: space-between;
  }
  & > * {
    pointer-events: none;
    font-size: 12px;
    padding: 0 5px;
  }
  .request-mark {
    position: absolute;
    bottom: 3px;
    right: 3px;
  }
  .vehicles {
    width: 100%;
  }
  .meeting-place {
    min-width: 0;
  }
}
</style>
