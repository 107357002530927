<template>
  <div v-if="currentUser">
    <Sidebar />
    <Navbar ref="navBar" />

    <v-main>
      <PaymentAccountNotification v-if="showPaymentNotification" />
      <DemoModeNotification v-if="showDemoModeNotification" />
      <v-container
        fluid
        id="app-content"
        :style="{ height: $store.state.main.appContainerHeight }"
      >
        <slot />
      </v-container>
    </v-main>

    <ErrorBottomSheet />
    <AppReloadDialog v-if="!isElter" />
    <PushNotificationInit />
    <PatchNotesChecker v-if="!isElter" />
    <BottomMenu v-if="$vuetify.breakpoint.mobile && !isElterWithoutSelectedStudent" />
    <update-notification-dialog v-if="isWebBrowser()" />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import ErrorBottomSheet from "@/components/ErrorBottomSheet";
import Sidebar from "@/components/sidebar/Sidebar.vue";
import BottomMenu from "@/components/layouts/BottomMenu";
import PaymentAccountNotification from "@/components/PaymentAccountNotification";
import DemoModeNotification from "@/components/DemoModeNotification";
import responsive from "@/utils/mixins/responsive";
import user from "@/utils/mixins/user";
import * as mutationTypes from "@/store/mutation-types/main";
import AppReloadDialog from "@/components/AppReloadDialog";
import PushNotificationInit from "@/components/PushNotificationInit";
import messenger from "@/utils/mixins/messenger";

import { mapActions, mapGetters, mapState } from "vuex";
import PatchNotesChecker from "@/components/PatchNotesChecker.vue";
import UpdateNotificationDialog from "@/components/school/UpdateNotificationDialog.vue";
import { Device } from "@capacitor/device";
import store from '@/store'

export default {
  name: "Main",
  mixins: [user, messenger, responsive],
  components: {
    UpdateNotificationDialog,
    PatchNotesChecker,
    PushNotificationInit,
    AppReloadDialog,
    Navbar,
    ErrorBottomSheet,
    Sidebar,
    BottomMenu,
    PaymentAccountNotification,
    DemoModeNotification
  },
  data: () => ({
    loading: true
  }),
  computed: {
    ...mapState("main", [
      "pageLoading",
      "isSidebarMinimized"
    ]),
    ...mapState("messenger", ["activeRoomId"]),
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("school", ["school"]),
    ...mapGetters("main", ["showSplashScreen"]),
    mdLandscapeAndUp() {
      return (
        (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.mdAndUp) &&
        window.innerHeight < window.innerWidth
      );
    },
    showPaymentNotification() {
      return (
        !this.loading &&
        !this.school.paymentSetupComplete &&
        this.currentUser.type === "school-administrator" &&
        this.school.isWithStripePayment &&
        this.$route.name !== "school-settings.payments" &&
        this.$route.name !== "calendar"
      );
    },
    showDemoModeNotification() {
      return (
        !this.loading &&
        store.state.school.isDemoModeEnabled &&
        this.currentUser.type === "school-administrator" &&
        this.$route.name !== "school-settings.demo-mode" &&
        this.$route.name !== "calendar" &&
        this.$route.name !== "vehicles.calendar"
      );
    }
  },
  mounted() {
    this.loading = false;
    this.listenForOnlineStatus();
    this.listenForStudentBalanceUpdate();
    this.listenCurrentUserNotifications();
    this.listenForUserMessengerEvents();
    this.listenForSchoolPrivateEvents();
    this.listenForParentalControlEvents();
    this.listenForSchoolChannelEvents();
  },
  updated() {
    this.calculateAppContentHeight();
    window.addEventListener("orientationchange", () => this.calculateAppContentHeight());
  },
  beforeDestroy () {
    window.removeEventListener("orientationchange", () => this.calculateAppContentHeight());
  },
  methods: {
    ...mapActions("school", { loadSchool: "load" }),
    calculateAppContentHeight() {
      const appBar = document.querySelector(".v-app-bar");
      const appBarHeight = appBar ? appBar.clientHeight : 0;

      // const toolBar = document.querySelector('.v-toolbar')
      // const toolBarHeight = toolBar ? toolBar.clientHeight : 0

      const banners = document.querySelectorAll(".v-banner");
      let bannersTotalHeight = 0;
      banners.forEach((banner) => {
        bannersTotalHeight += banner.clientHeight;
      });

      const bottomMenu = document.querySelector(".v-bottom-navigation");
      const bottomMenuHeight = bottomMenu ? bottomMenu.clientHeight : 0;
      const totalHeight = appBarHeight + bannersTotalHeight + bottomMenuHeight;

      this.$store.commit(
        `main/${mutationTypes.SET_APP_CONTAINER_HEIGHT}`,
        `calc(100vh - ${totalHeight}px)`
      );
    },
    async isWebBrowser() {
      const info = await Device.getInfo();
      return info.platform === "web";
    }
  },
};
</script>

<style scoped lang="scss">
#app-content {
  padding: 0;
  margin: 0;
  overflow-y: auto;
}

.error-code {
  font-family: monospace;
  font-size: 270px !important;
  margin: 0;
  font-weight: 200;
  color: #ddd;
}
.error-text {
  color: #444;
  font-family: monospace;
}
</style>
