<template>
  <div>
    <StudentsCostsOverview v-if="!student.bookAndPay" :cardWrapper="false" />
    <StudentBasePayments v-if="student.bookAndPay" :student="student" />
    <StudentPriceLists :student="student" class="mt-5" />
    <StudentHistory v-if="student.bookAndPay" :student="student" class="mt-5" />
  </div>
</template>

<script>
import StudentHistory from "@/components/student/StudentHistory";
import StudentModel from "@/store/models/StudentModel";
import StudentPriceLists from "@/components/student/StudentPriceLists";
import StudentBasePayments from "@/components/student/StudentBasePayments.vue";
import StudentsCostsOverview from '@/views/Students/StudentCostsOverview.vue'

export default {
  name: "PaymentsTab",
  props: {
    studentId: {
      type: [Number, String],
      required: true,
    },
  },
  components: {
    StudentsCostsOverview,
    StudentBasePayments, StudentPriceLists, StudentHistory },
  computed: {
    student() {
      return StudentModel.find(this.studentId) || {};
    },
  },
};
</script>

<style scoped></style>
