import Notification from "@/notifications/notification";
import router from "@/router";

export default class SupportTicketResponseNotification extends Notification {
  icon() {
    return "mdi-lifebuoy";
  }

  wildCardsData() {
    const data = this.notification.data;
    return {
      title: {
        id: data.id,
      },
      message: {
        id: data.id,
        message: this._ellipsisString(data.lastMessage?.text)
      },
    };
  }

  action () {
    const id = this.notification.data.id;
    const sameRoute = router.currentRoute.name === 'support.ticket.view';

    if (sameRoute && router.currentRoute.params.ticket.toString() === id.toString()) return;

    const to = {name: 'support.ticket.view', params: {ticket: id}}

    if (sameRoute) {
      router.replace(to).catch((error) => console.log(error));
    } else {
      router.push(to).catch((error) => console.log(error));
    }
  }

  _ellipsisString(str) {
    const init = str || ""
    const string = init.slice(0, 60)

    if (string.length === 0) return ""
    if (string.length < init.length) return string + "..."

    return string
  }
}
