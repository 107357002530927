<template>
  <div class="d-flex flex-column">
    <div class="d-flex" :class="{ 'flex-column': !$vuetify.breakpoint.lgAndUp }">
      <StudentProgressChip
          v-if="licenseProgress.practiceInstructionsShould"
          class="my-1 mx-lg-1"
          :name="$tc('lesson_type.instruction', licenseProgress.practiceInstructionsIs)"
          :amount-is="licenseProgress.practiceInstructionsIs"
          :amount-should="licenseProgress.practiceInstructionsShould"
          :single-value="licenseProgress.practiceInstructionsShould === 0"
      />
      <StudentProgressChip
          class="my-1 mx-lg-1"
          :name="$tc('lesson_type.normal', licenseProgress.practiceNormalsIs)"
          :amount-is="licenseProgress.practiceNormalsIs"
          :amount-should="licenseProgress.practiceNormalsShould"
          :single-value="licenseProgress.practiceNormalsShould === 0"
      />

      <StudentProgressChip
        class="my-1 mx-lg-1"
        :name="$tc('lesson_type.overland', licenseProgress.practiceOverlandsIs)"
        :amount-is="licenseProgress.practiceOverlandsIs"
        :amount-should="licenseProgress.practiceOverlandsShould"
      />

      <StudentProgressChip
        class="my-1 mx-lg-1"
        :name="$tc('lesson_type.highway', licenseProgress.practiceHighwaysIs)"
        :amount-is="licenseProgress.practiceHighwaysIs"
        :amount-should="licenseProgress.practiceHighwaysShould"
      />

      <StudentProgressChip
        class="my-1"
        :class="licenseProgress.practiceRequiredMTLsShould ? 'mx-lg-1' : 'ml-lg-1'"
        :name="$tc('lesson_type.night', licenseProgress.practiceNightsIs)"
        :amount-is="licenseProgress.practiceNightsIs"
        :amount-should="licenseProgress.practiceNightsShould"
      />

      <StudentProgressChip
        class="my-1 ml-lg-1"
        v-if="licenseProgress.practiceRequiredMTLsShould"
        :name="$tc('lesson_type.manual_lessons', licenseProgress.practiceRequiredMTLsIs)"
        :amount-is="licenseProgress.practiceRequiredMTLsIs"
        :amount-should="licenseProgress.practiceRequiredMTLsShould"
      />
    </div>

    <v-progress-linear :value="licenseProgress.practiceProgress" height="25" class="mt-2" rounded>
      <span class="text-caption white--text">{{ licenseProgress.practiceProgress }}%</span>
    </v-progress-linear>
  </div>
</template>

<script>
import StudentProgressChip from '@/components/StudentProgressChip'
export default {
  components: { StudentProgressChip },

  props: {
    student: {
      type: Object,
      required: true,
    }
  },
  name: "StudentProgress",
  computed: {
    licenseProgress () {
      const licenseProgress = this.student.licenseProgress || {}

      return {
        practiceInstructionsIs: licenseProgress.practiceInstructionsIs || 0,
        practiceInstructionsShould: licenseProgress.practiceInstructionsShould || 0,
        practiceNormalsIs: licenseProgress.practiceNormalsIs || 0,
        practiceNormalsShould: licenseProgress.practiceNormalsShould || 0,
        practiceOverlandsIs: licenseProgress.practiceOverlandsIs || 0,
        practiceOverlandsShould: licenseProgress.practiceOverlandsShould || 0,
        practiceHighwaysIs: licenseProgress.practiceHighwaysIs || 0,
        practiceHighwaysShould: licenseProgress.practiceHighwaysShould || 0,
        practiceNightsIs: licenseProgress.practiceNightsIs || 0,
        practiceNightsShould: licenseProgress.practiceNightsShould || 0,
        practiceRequiredMTLsIs: licenseProgress.practiceRequiredMTLsIs || 0,
        practiceRequiredMTLsShould: licenseProgress.practiceRequiredMTLsShould || 0,
        practiceProgress: licenseProgress.practiceProgress || 0
      }
    }
  }
};
</script>

<style scoped lang="scss">
</style>
