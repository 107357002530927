<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'school-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>
        {{ $t("route.schoolSettings.children.carsPark") }}
      </v-card-title>

      <v-spacer/>

      <v-btn
        text
        color="primary"
        @click="save"
        :disabled="loading || ! $store.state.school.vehiclesScheduleNotificationEnabled"
        :loading="loading"
      >
        {{ $t('btn.save') }}
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <ValidationObserver ref="form">
        <SettingsResponsiveRow
            :title="$t('settings.cars.notification_enabled')"
            :description="$t('settings.cars.notification_enabled_explanation')"
        >
          <template #description
            v-if="
              $store.state.school.vehiclesScheduleNotificationEnabled &&
              !$store.state.school.vehiclesScheduleNotificationEmail
            "
          >
            <v-alert class="mt-1" border="left" colored-border type="warning" dense elevation="1">
              <v-list-item-title>
                {{ $t('settings.cars.no_email_specified') }}
              </v-list-item-title>
              <span class="text--secondary">{{ $t('settings.cars.no_email_specified_explanation') }}</span>
            </v-alert>
          </template>
          <v-switch
              :loading="changing"
              :disabled="changing"
              :input-value="localState.status"
              :label="$t('label.enabled')"
              @change="changeStatus"
          />
        </SettingsResponsiveRow>
        <SettingsResponsiveRow
            :title="$t('settings.cars.notification_email')"
            :description="$t('settings.cars.notification_email_explanation')"
        >
          <ValidationProvider v-slot="{ errors }" name="email">
            <v-text-field
              v-model="localState.email"
              outlined
              dense
              :placeholder="$t('form.email')"
              :label="$t('form.email')"
              :disabled="! localState.status"
              :error-messages="errors"
            />
          </ValidationProvider>
        </SettingsResponsiveRow>
        <SettingsResponsiveRow
            :title="$t('settings.cars.notification_time_range')"
            :description="$t('settings.cars.notification_time_range_explanation')"
        >
          <v-row>
            <v-col cols="6" sm="12" md="6">
              <v-dialog
                  ref="startTimeDialog"
                  v-model="startTimePicker"
                  :return-value.sync="localState.startTime"
                  persistent
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider v-slot="{ errors }" name="startTime">
                    <v-text-field
                      v-model="localState.startTime"
                      dense
                      :label="$t('label.start_time')"
                      prepend-inner-icon="mdi-clock-time-seven-outline"
                      outlined
                      readonly
                      :disabled="! localState.status"
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </template>
                <v-time-picker
                    v-if="startTimePicker"
                    v-model="localState.startTime"
                    full-width
                    format="24hr"
                    :allowed-minutes="[0]"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="startTimePicker = false"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.startTimeDialog.save(localState.startTime)"
                  >
                    {{ $t('btn.ok') }}
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
            <v-col cols="6" sm="12" md="6">
              <v-dialog
                  ref="endTimeDialog"
                  v-model="endTimePicker"
                  :return-value.sync="localState.endTime"
                  persistent
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider v-slot="{ errors }" name="endTime">
                    <v-text-field
                      v-model="localState.endTime"
                      :label="$t('label.end_time')"
                      prepend-inner-icon="mdi-clock-time-four-outline"
                      outlined
                      dense
                      readonly
                      :disabled="! localState.status"
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </template>
                <v-time-picker
                    v-if="endTimePicker"
                    v-model="localState.endTime"
                    full-width
                    format="24hr"
                    :allowed-minutes="[0]"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="endTimePicker = false"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.endTimeDialog.save(localState.endTime)"
                  >
                    {{ $t('btn.ok') }}
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </SettingsResponsiveRow>

        <SettingsResponsiveRow
          :title="$t('settings.cars.reservations_enabled_status')"
          :description="$t('settings.cars.reservations_enabled_status_explanation')"
          :divider-after="false"
        >
          <v-switch
            :loading="changingResStatus"
            :disabled="changingResStatus"
            :input-value="localState.reservationStatus"
            :label="$t('label.enabled')"
            @change="changeReservationStatus"
          />
        </SettingsResponsiveRow>
      </ValidationObserver>
    </v-card-text>
  </div>
</template>

<script>
import SettingsResponsiveRow from "@/components/settings/SettingsResponsiveRow.vue";
import schoolService from "@/services/schoolService";
import responsive from '@/utils/mixins/responsive'

export default {
  name: "CarsPark",
  components: {SettingsResponsiveRow},
  mixins: [responsive],
  data() {
    return {
      startTimePicker: false,
      endTimePicker: false,
      localState: {
        status: undefined,
        email: '',
        startTime: '',
        endTime: '',
        reservationStatus: false,
      },
      changing: true,
      changingResStatus: true,
      loading: false,
    };
  },
  mounted() {
    this.localState = {
      status: this.$store.state.school.vehiclesScheduleNotificationEnabled,
      email: this.$store.state.school.vehiclesScheduleNotificationEmail,
      startTime: this.$store.state.school.vehiclesScheduleNotificationStartTime,
      endTime: this.$store.state.school.vehiclesScheduleNotificationEndTime,
      reservationStatus: this.$store.state.school.isVehicleReservationsEnabled,
    }
    this.changing = false;
    this.changingResStatus = false;
  },
  methods: {
    changeStatus(status) {
      this.changing = true;
      this.localState.status = status;
      schoolService
          .updateVehiclesNotificationSettings({ status })
          .then(() => {
              this.$store.commit("school/UPDATE_VEHICLES_NOTIFICATION_STATUS", status)
              this.$snackbar.show({
                message: status ? this.$t('label.notifications_enabled') : this.$t('label.notifications_disabled'),
                timeout: 3000,
                color: status ? 'success' : 'warning'
              });
          })
          .catch(() => {
            this.localState.status = ! status;
          })
          .finally(() => (this.changing = false));
    },
    changeReservationStatus(status) {
      this.changingResStatus = true;
      this.localState.reservationStatus = status;
      schoolService
        .updateVehiclesReservationSettings({ status })
        .then(() => {
          this.$store.commit("school/UPDATE_VEHICLES_RESERVATION_STATUS", status)
          this.$snackbar.show({
            message: status ? this.$t('label.reservations_enabled') : this.$t('label.reservations_disabled'),
            timeout: 3000,
            color: status ? 'success' : 'warning'
          });
        })
        .catch(() => {
          this.localState.reservationStatus = ! status;
        })
        .finally(() => (this.changingResStatus = false));
    },
    save() {
      this.loading = true
      this.$refs.form.reset();
      schoolService
        .updateVehiclesNotificationSettings(this.localState)
        .then(() => {
          this.$store.commit("school/UPDATE_VEHICLES_NOTIFICATION_SETTINGS", this.localState)
          this.$snackbar.show({
            message: this.$t('messages.successfully_applied'),
            timeout: 3000,
            color: 'success'
          });
        })
        .catch(error => {
          const responseData = error.response.data;
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error" });
          }

          this.$refs.form.setErrors(errors)
        })
        .finally(() => (this.loading = false));
    }
  }
}
</script>

<style scoped>

</style>
