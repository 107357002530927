export const SET_TOKEN = "SET_TOKEN";
export const SESSION_EXPIRED = "SESSION_EXPIRED";
export const RESTRICTED_ACCESS = "RESTRICTED_ACCESS";
export const USER_LOADING = "USER_LOADING";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_UPDATE = "USER_UPDATE";
export const SET_UNSEEN_MESSAGES_COUNT = "SET_UNSEEN_MESSAGES_COUNT";
export const SET_UNSIGNED_DOCUMENTS_COUNT = "SET_UNSIGNED_DOCUMENTS_COUNT";
export const SET_PARENT_SELECTED_STUDENT_ID = "SET_PARENT_SELECTED_STUDENT_ID";
export const SET_PARENT_SELECTED_STUDENT = "SET_PARENT_SELECTED_STUDENT";
export const SET_PRIVACY_POLICY_STATUS = "SET_PRIVACY_POLICY_STATUS";
