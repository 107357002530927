<script>
import {defineComponent} from 'vue'
import clickAndLearnService from "@/services/clickAndLearnService";
import LastActivityInDaysIndicator from "@/components/LastActivityInDaysIndicator.vue";

export default defineComponent({
  name: "ClickAndLearn",
  components: {LastActivityInDaysIndicator},
  data() {
    return {
      loading: false,
      loadingPendingOrders: false,
      licenseCodes: [],
      pendingOrders: [],
    };
  },
  mounted() {
    this.loading = true;
    clickAndLearnService.getAssignedLicenseCodes().then((response) => {
      this.licenseCodes = response.data;
    }).finally(() => {
      this.loading = false;
    });
  },
  methods: {
    handleNewOrderPlaced(data) {
      this.pendingOrders.push(data)
    },
    colorByPercentage(percentage) {
      if (percentage === 0 || percentage === null) return 'grey';

      return percentage < 50 ? 'red' : (percentage < 80 ? 'orange' : 'green');
    }
  }
})
</script>

<template>
  <v-container>
    <v-card :loading="loading">
      <v-toolbar elevation="3">
        <v-card-title>{{ $t("clickandlearn.education_progress_overview") }}</v-card-title>
        <v-spacer />
      </v-toolbar>

      <v-card-text>
        <v-skeleton-loader type="table" v-if="loading" />
        <template v-else>
          <empty-box
            v-if="! licenseCodes.length"
            width="60"
            height="60"
            :text="$t('clickandlearn.no_license_codes_assigned')"
            image="assets/img/ClickAndLearn-logo.png"
          />
          <template v-else>
            <v-card-subtitle>
              {{ $t("clickandlearn.progress_explanation") }}
            </v-card-subtitle>
            <v-simple-table dense>
              <thead>
              <tr>
                <th style="width: 90px" class="text-left">{{ $t("clickandlearn.license_code") }}</th>
                <th style="width: 150px" class="text-left">{{ $t("clickandlearn.license_code_assigned_at") }}</th>
                <th  class="text-left">{{ $t("clickandlearn.license_code_assigned_to") }}</th>
                <th style="width: 200px" class="text-left">{{ $t("clickandlearn.learning_by_topics") }}</th>
                <th style="width: 200px" class="text-left">{{ $t("clickandlearn.learning_by_flashcards") }}</th>
                <th style="width: 200px" class="text-left">{{ $t("clickandlearn.exam_simulation") }}</th>
                <th style="width: 150px" class="text-center">{{ $t("clickandlearn.last_activity") }}</th>
                <th style="width: 80px" class="text-center">{{ $t("label.actions") }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="licenseCode in licenseCodes" :key="licenseCode.id">
                <td class="text-monospace"><v-chip label x-small>{{ licenseCode.code }}</v-chip></td>
                <td>{{ licenseCode.assignedAt ?? '-' }}</td>
                <td>{{ licenseCode.studentName ?? '-' }}</td>
                <td>
                  <v-progress-linear
                    dark
                    :value="licenseCode.progressByTopics"
                    :color="colorByPercentage(licenseCode.progressByTopics)"
                    height="20"
                  >
                    {{ licenseCode.progressByTopics }}%
                  </v-progress-linear>
                </td>
                <td>
                  <v-progress-linear
                    dark
                    :value="licenseCode.progressByFlashcards"
                    :color="colorByPercentage(licenseCode.progressByFlashcards)"
                    height="20"
                  >
                    {{ licenseCode.progressByFlashcards }}%
                  </v-progress-linear>
                </td>
                <td>
                  <v-progress-linear
                    dark
                    :value="licenseCode.progressByExams"
                    :color="colorByPercentage(licenseCode.progressByExams)"
                    height="20"
                  >
                    {{ licenseCode.progressByExams }}%
                  </v-progress-linear>
                </td>
                <td>
                  <last-activity-in-days-indicator :days="licenseCode.lastActivityInDays" />
                </td>
                <td class="text-center">
                  <v-btn v-if="licenseCode.isAssigned" text x-small :to="{ name: 'students.student', params: { id: licenseCode.studentId } }">
                    {{  $t('btn.manage')  }}
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </template>
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<style scoped>

</style>