<template>
  <v-switch
    v-model="status"
    :disabled="disabled"
    :label="label"
    inset
    dense
    hide-details></v-switch>
</template>

<script>
export default {
  name: "ManualLessonCheckboxField",
  props: {
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    statusProp: {
      type: Boolean,
    },
  },
  computed: {
    status: {
      set(value) {
        this.$emit("emitSwitchStatus", value);
      },
      get() {
        return this.statusProp;
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
