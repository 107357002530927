<script>
import TemplateRenderer from "@/components/insights/TemplateRenderer.vue";
import EvaluationModel from "@/store/models/insights/EvaluationModel";
import user from '@/utils/mixins/user'

export default {
  name: "EvaluationPreview",
  mixins: [user],
  components: {TemplateRenderer},
  props: {
    evaluationId: {
      type: Number
    }
  },
  computed: {
    evaluation() {
      return EvaluationModel.query().with(['items', 'items.category']).where('id', this.evaluationId).first()
    },
  }
}
</script>

<template>
<template-renderer
  v-if="evaluation?.items.length"
  tile
  expanded
  :show-description="false"
  :show-toolbar="false"
  :template-id="evaluation.templateId"
  :evaluation="evaluation"
  :has-loaded-template="isStudent"
/>
<empty-box v-else image="assets/img/evaluation_draft.png" :text="$t('insights.evaluation_is_empty')"/>
</template>

<style scoped>

</style>
