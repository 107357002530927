<template>
<v-container :fluid="$vuetify.breakpoint.lgAndDown">
  <v-card :loading="loading">
    <v-toolbar elevation="3">
      <v-card-title>{{ $t("label.theory_attendance") }}</v-card-title>
      <v-spacer />
      <v-btn text exact color="primary" :to="{ name: 'theory' }">
        {{ $t("label.theories") }}
      </v-btn>
    </v-toolbar>
    <v-card-text v-if="!loading && !filtering">
      <div class="grid">
        <div>
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                solo
                dense
                readonly
                :value="formattedDates"
                :label="$t('label.filter_by_date')"
                prepend-inner-icon="mdi-calendar"
                :disabled="exporting"
                clearable
                @click:clear="date = []"
                v-bind="attrs"
                v-on="on">
              </v-text-field>
            </template>
            <v-date-picker v-model="date" scrollable range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">{{
                $t("btn.cancel")
              }}</v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(date.sort())"
                >{{ $t("btn.ok") }}</v-btn
              >
            </v-date-picker>
          </v-dialog>
        </div>
        <div>
          <v-select
            v-model="selectedTeachers"
            :disabled="exporting"
            :items="teachers"
            :label="$t('calendar.teachers_filter')"
            solo
            dense
            multiple
            chips
            small-chips
            deletable-chips></v-select>
        </div>
        <div>
          <v-btn
            text
            color="primary"
            @click="filter"
            :disabled="exporting"
            :loading="loading"
            >{{ $t("btn.filter_results") }}</v-btn
          >
          <v-btn
            text
            color="primary"
            :loading="exporting"
            :disabled="
              loading ||
              exporting ||
              filtering ||
              !selectedTheories.length
            "
            @click="exportPdfs(selectedTheories)">
            {{ $t("btn.export") }}
          </v-btn>
        </div>
      </div>

      <TheoriesTable
        v-if="events.length"
        :events="events"
        ref="theories"
        @childToParent="onChildClick"
      />

      <v-alert v-else>
        <empty-box />
      </v-alert>
    </v-card-text>
    <v-card-text v-else>
      <v-skeleton-loader class="mx-auto" type="table-row@3" />
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import { format, parseISO, startOfMonth } from "date-fns";

import responsive from "@/utils/mixins/responsive";
import EmptyBox from "@/components/EmptyBox";
import licenses from "@/utils/mixins/licenses";
import TheoriesTable from "@/components/theory/TheoriesTable";
import TeacherModel from "@/store/models/TeacherModel";
import appointmentService from '@/services/appointmentService'
import AppointmentModel from '@/store/models/AppointmentModel'

export default {
  name: "TheoryAttendance",
  mixins: [responsive, licenses],
  components: { TheoriesTable, EmptyBox },
  data: () => ({
    loading: true,
    filtering: false,
    modal: false,
    date: [],
    selectedTeachers: [],
    selectedTheories: [],
    exporting: false
  }),
  mounted() {
    const startDate = format(startOfMonth(new Date()), "yyyy-MM-dd");
    const endDate = format(new Date(), "yyyy-MM-dd");

    this.date = [startDate, endDate];

    this.filter();
    this.loading = true;
  },
  computed: {
    events() {
      return AppointmentModel.query().with('teacher').orderBy("start", "desc").get();
    },
    teachers() {
      return TeacherModel.all().map((item) => ({ value: item.id, text: item.fullName }));
    },
    formattedDates() {
      if (this.date.length) {
        const localDate = this.date;
        const formatted = localDate.sort().map((item) => format(parseISO(item), "dd.MM.yyyy"));

        return formatted.length > 1 ? `vom ${formatted[0]} bis ${formatted[1]}` : formatted;
      }

      return this.date;
    },
    startDate() {
      return this.date.length ? `${this.date[0]}` : null;
    },
    endDate() {
      const end = this.date.length ? `${this.date[this.date.length - 1]}` : null;

      const now = format(new Date(), "yyyy-MM-dd");

      return end < now ? end : now;
    },
    filters() {
      const params = {};
      if (this.startDate) params.startDate = this.startDate;
      if (this.endDate) params.endDate = this.endDate;
      if (this.selectedTeachers.length) {
        params.selectedTeachers = this.selectedTeachers;
      }

      return params;
    },
  },
  methods: {
    exportPdfs(ids) {
      this.exporting = true
      this.$refs.theories.exportPdfList(ids).finally(() => {
        this.exporting = false
      })
    },
    onChildClick(value) {
      this.selectedTheories = value;
    },
    loadTheories() {
      appointmentService
        .load({
          from: this.startDate,
          to: this.endDate,
          type: ['THEORY'],
          lessonTypeGroup: 'lesson',
          teacher: this.selectedTeachers,
        })
        .then(response => {
          AppointmentModel.create({ data: response.data || [] })
        })
        .finally(() => {
          this.loading = false;
          this.filtering = false;
        });
    },
    filter() {
      this.filtering = true;
      this.loadTheories(this.filters);
    },
  },
};
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  gap: 0 20px;
  grid-template-columns: 1fr 1.5fr 190px;
}
</style>
