import store from "@/store";
import i18n from "@/plugins/i18n";

export default class Notification {
  constructor(notification) {
    this.type = notification.type;
    this.notification = notification;
  }

  icon() {
    return "mdi-bell-ring-outline";
  }

  title() {
    const wildCardsData = this.wildCardsData();
    return i18n.t(`${this.translationKey()}.title`, wildCardsData.title);
  }

  message() {
    const wildCardsData = this.wildCardsData();
    return i18n.t(`${this.translationKey()}.message`, wildCardsData.message);
  }

  subtitle() {
    return "";
  }

  action() {
    return () => {};
  }

  translationKey() {
    const currentUser = store.state.auth.authUser;
    const userType = currentUser.type === "elter" ? "student" : currentUser.type;

    return `notifications.${userType}.${this.type}`;
  }

  wildCardsData() {
    return {
      title: {},
      message: {},
    };
  }
}
