<script>
import {defineComponent} from 'vue'
import { format, parseISO } from 'date-fns'
import Pagination from '@/helpers/pagination'

import schoolService from "@/services/schoolService";
import SchoolCreditsHistoryDetailsDialog from "@/components/school/SchoolCreditsHistoryDetailsDialog.vue";
import laravelEcho from "@/plugins/laravel-echo";
import debounceThrottle from "@/utils/mixins/debounce-throttle";
import formattedDatesMixin from '@/utils/mixins/formattedDatesMixin'
import studentsService from '@/services/studentsService'

export default defineComponent({
  name: "SchoolCreditsHistory",
  mixins: [debounceThrottle, formattedDatesMixin],
  components: {SchoolCreditsHistoryDetailsDialog},
  data() {
    return {
      loading: false,
      history: [],
      hoveredRow: null,
      debouncedMouseEnter: null,
      debouncedMouseLeave: null,
      filtersDateModal: false,
      showLoadMoreBtn: false,
      loadingLoadMore: false,
      students: [],
      types: [
        "purchase",
        "refund",
        "manual_adjustment",
        "student_creation",
        "student_deletion",
        "license_assignment",
        "license_renewal",
        "license_revocation",
        "insights_template_assignment",
        "insights_template_revocation"
      ],
      date: [],
      filters: {
        from: null,
        to: null,
        types: [],
        student: null
      },
      pagination: new Pagination({ perPage: 50 }),
    }
  },
  mounted() {
    laravelEcho.private(`school-private-channel.${this.id}`).notification((notification) => {
      notification.data.isNew = true
      this.history.unshift(notification.data)
      this.fetchStudents()
      setTimeout(() => {
        notification.data.isNew = false;
      }, 10);
    })

    this.loading = true
    this.fetchCreditsHistory()
      .finally(() => {
        this.loading = false
      })

    this.fetchStudents()
  },
  created() {
    this.debouncedMouseEnter = this.debounce((item) => {
      this.hoveredRow = item.id;
    }, 100); // delay of 100ms

    this.debouncedMouseLeave = this.debounce(() => {
      this.hoveredRow = null;
    }, 100); // delay of 100ms
  },
  methods: {
    fetchCreditsHistory(successCallback = null) {
      return schoolService.loadCreditsHistory({
        ...this.filters,
        pagination: true,
        perPage: this.pagination.perPage,
        page: this.pagination.page
      })
        .then(resp => {
          const responseData = resp.data || {}

          if (successCallback && successCallback instanceof Function) {
            successCallback(responseData)
            return
          }

          this.history = responseData.data || []
          this.pagination.set(responseData.pagination || {})
        })
    },
    fetchStudents() {
      this.loadingStudents = true
      studentsService
        .load({ brief: true, withTrashed: true, withCreditsHistory: true })
        .then((resp) => {
          this.students = resp.data || [];
        })
        .finally(() => {
          this.loadingStudents = false
        })
    },
    showDetails(item) {
      this.$refs.detailsDialog.show(item)
    },
    filter() {
      this.filters.from = this.startDate
      this.filters.to = this.endDate
      this.pagination.setPage(1)

      this.loading = true
      this.fetchCreditsHistory()
        .finally(() => {
          this.loading = false
        })
    },
    onFiltersDateClear() {
      this.date = []
      this.filters.date = []
    },
    loadMoreOnIntersection(entries) {
      if (! entries[0]?.isIntersecting) return
      this.loadMore()
    },
    loadMore() {
      if (this.pagination.currentPage >= this.pagination.lastPage) return

      this.pagination.setPage(this.pagination.page + 1)

      this.loadingLoadMore = true
      this.fetchCreditsHistory((responseData) => {
        let newHistories = responseData.data || []

        newHistories = newHistories.filter((item) => {
          return !this.history.find((historyItem) => historyItem.id === item.id)
        })

        this.history = [...this.history, ...newHistories]
        this.pagination.set(responseData.pagination || {})
      })
        .finally(() => {
          this.loadingLoadMore = false
        })
    }
  },
  computed: {
    id() {
      return this.$store.getters['school/school'].id
    },
    formattedDates() {
      if (this.date.length) {
        const localDate = this.date;
        const formatted = localDate.sort().map((item) => {
          return format(parseISO(item), "dd.MM.yyyy");
        });
        if (formatted.length > 1)
          return `vom ${formatted[0]} bis ${formatted[1]}`;
        else return formatted;
      }
      return this.date;
    },
    typesOptions() {
      const filteredTypes = this.$store.state.school.hasInsights
        ? this.types
        : this.types.filter((type) => !["insights_template_assignment", "insights_template_revocation"].includes(type))

      return filteredTypes.map((item) => {
        return {
          value: item,
          text: this.$t(`creditsHistoryType.${item}`),
        };
      });
    }
  }
})
</script>

<template>
  <v-card outlined elevation="5">
    <v-card-title>{{ $t("label.history") }}</v-card-title>
    <v-card-subtitle>{{ $t("label.click_a_row_for_details") }}</v-card-subtitle>
    <v-skeleton-loader v-if="loading" type="table" />
      <template v-else>
        <v-card-text>
        <v-row
          class="mt-1"
          :class="{'pb-4': $vuetify.breakpoint.mobile}"
          :dense="$vuetify.breakpoint.mobile"
          :no-gutters="$vuetify.breakpoint.mobile"
        >
          <v-col cols="12" lg="3">
            <v-dialog
                ref="dialog"
                v-model="filtersDateModal"
                :return-value.sync="date"
                persistent
                width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    solo
                    dense
                    readonly
                    :value="formattedDates"
                    :label="$t('label.filter_by_date')"
                    prepend-inner-icon="mdi-calendar"
                    clearable
                    @click:clear="onFiltersDateClear"
                    v-bind="attrs"
                    v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker v-model="date" scrollable range>
                <v-spacer/>
                <v-btn text color="primary" @click="filtersDateModal = false">{{ $t("btn.cancel") }}</v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(date.sort())"
                >
                  {{ $t("btn.ok") }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" lg="3">
            <v-select
                :label="$t('label.filter_by_type')"
                :items="typesOptions"
                v-model="filters.types"
                solo
                dense
                multiple
                clearable
                @click:clear="filters.types = []"
            />
          </v-col>
          <v-col cols="12" lg="3">
            <v-autocomplete
                :label="$t('label.filter_by_student')"
                prepend-inner-icon="mdi-account"
                :items="students"
                v-model="filters.student"
                item-value="id"
                item-text="fullName"
                solo
                dense
                clearable
                @click:clear="filters.student = null"
            />
          </v-col>
          <v-col>
            <v-btn text color="primary" @click="filter" :loading="loading">{{ $t("btn.filter_results") }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-simple-table dense v-if="history.length">
        <thead>
        <tr>
          <th style="width: 160px">{{ $t("label.date") }}</th>
          <th>{{ $t("label.type") }}</th>
          <th style="width: 200px">{{ $t("label.author") }}</th>
          <th>{{ $t("label.student") }}</th>
          <th colspan="3" class="text-center">{{ $t("label.change") }}</th>
        </tr>
        </thead>
          <transition-group name="fade" tag="tbody">
            <tr
                v-for="item in history"
                :key="item.id"
                @click="showDetails(item)"
                @mouseenter="debouncedMouseEnter(item)"
                @mouseleave="debouncedMouseLeave"
                :class="item.isNew ? (item.amount < 0 ? 'error lighten-5' : 'success lighten-5') : ''"
            >
              <td>{{ item.createdAt }}</td>
              <td>
                <v-chip small label outlined :color="item.amount < 0 ? 'error' : 'success'">
                  {{ $t(`creditsHistoryType.${item.type}`) }}
                </v-chip>
              </td>
              <td>{{ item.author }}</td>
              <td>{{ item.student ?? '-' }}</td>
              <td class="text-right" :class="item.amount < 0 ? 'error--text' : 'success--text'">{{ item.balanceBefore }}</td>
              <td style="width: 100px" class="text-center" :class="item.amount < 0 ? 'error--text' : 'success--text'">
                <span v-if="hoveredRow !== item.id">&rarr;</span>
                <span v-else>{{ `${item.amount > 0 ? '+' : ''}${item.amount}` }}</span>
              </td>
              <td :class="item.amount < 0 ? 'error--text' : 'success--text'">{{ item.balanceAfter }}</td>
            </tr>
          </transition-group>

          <tr v-if="pagination.currentPage < pagination.lastPage">
            <td colspan="7" style="text-align: center;">
              <v-btn
                  class="mt-2"
                  text
                  color="primary"
                  small
                  v-intersect="loadMoreOnIntersection"
                  @click="loadMore"
                  :loading="loadingLoadMore"
                  :disabled="loadingLoadMore"
              >
                {{ $t('btn.load_more') }}
              </v-btn>
            </td>
          </tr>
      </v-simple-table>
      <empty-box v-else />
    </template>

    <school-credits-history-details-dialog ref="detailsDialog" />
  </v-card>
</template>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: background-color 3s;
}

.fade-enter, .fade-leave-to {
  background-color: transparent;
}

.ellipsis-select .v-select__slot,
.ellipsis-select .v-select__selections .v-select__selection {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
