<template>
  <RightClickMenu :item="item">
    <template v-slot="{ showItemMenu }">
      <v-card
        outlined
        :ripple="false"
        class="grid__item"
        :class="{ 'grid__item-active': item.checked }"
        :color="color"
        :style="{
          height: '100%',
          border: 'thin solid rgba(0, 0, 0, 0.12) !important',
          position: 'relative'
        }"
        @contextmenu="(e) => showItemMenu(e, item)"
        @click.native="onItemClick"
      >
        <div class="d-flex align-center" style="position: absolute; right: 5px; top: 10px" @click.stop>
          <v-btn icon large @click="(e) => showItemMenu(e, item)">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </div>

        <v-card-text class="d-flex flex-column fill-height justify-space-between">
          <slot name="content" />
        </v-card-text>
      </v-card>
    </template>

    <template v-slot:menu>
      <slot name="actions" />
    </template>
  </RightClickMenu>
</template>

<script>
import RightClickMenu from "@/components/RightClickMenu";

export default {
  props: {
    to: {
      type: Object,
      default: null,
    },
    item: {
      type: Object,
      required: true,
    },
    hasBackground: {
      type: Boolean,
      default: false,
    },
  },
  name: "GridItem",
  components: {
    RightClickMenu,
  },
  computed: {
    color() {
      if ("active" in this.item && !this.item.active) {
        return "red lighten-5";
      }

      if (this.hasBackground) return "blue lighten-5";

      return "";
    },
  },
  methods: {
    onItemClick() {
      if (this.to) return this.$router.push(this.to);

      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss">
.grid__item {
  &:hover {
    background: #f5f5f5;
    cursor: pointer;
  }
  &-active {
    background: #f5f5f5;
    cursor: pointer;
  }
}
</style>
