import api from "@/utils/api";

export default {
  load: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("/tickets", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getTicket: (ticket) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/tickets/${ticket}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  create: (params) => {
    return new Promise((resolve, reject) => {
      api
        .post("/tickets", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  reply: (params) => {
    return new Promise((resolve, reject) => {
      api
        .post(`/tickets/${params.ticket}/reply`, params.data)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  close: (ticket) => {
    return new Promise((resolve, reject) => {
      api
        .patch(`/tickets/${ticket}/close`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getTypes: () => {
    return new Promise((resolve, reject) => {
      api
        .get("/tickets/types")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
