<template>
  <v-card elevation="0" class="event-card" @click="$emit('open')">
    <div class="flex-time">
      <div>
        {{ event.startDate }} | {{ event.startTime }} - {{ event.endTime }}
      </div>
      <div>{{ event.duration }} <v-icon size="small">mdi-clock</v-icon></div>
    </div>

    <div v-if="event.teacher && !isTeacher">{{ event.teacher }}</div>
    <span v-if="!isStudent && event.lessonType">{{
      $tc(event.lessonType)
    }}</span>

    <div
      class="theory-topic text-overflow-ellipsis"
      v-if="event.theoryTopicType">
      <v-icon class="mb-1" size="15">{{
        event.theoryTopicType === "basic"
          ? "mdi-alpha-g-box"
          : "mdi-alpha-z-box"
      }}</v-icon>
      <span>{{ $t("form.topic") + " " + event.theoryTopic }}</span>
    </div>

    <span class="text-overflow-ellipsis" v-if="event.text">{{
      $t("label.notes") + ": " + event.text
    }}</span>

    <div class="icon-box d-flex justify-end">
      <v-icon
        v-if="isStudent && event.group === 'lesson' && event.studentsCount"
        class="ml-2"
        size="15"
        >mdi-check-circle</v-icon
      >
      <div v-if="!isStudent" class="students-counter ml-2">
        <label>{{
          event.roomSize > 0
            ? event.studentsCount + "/" + event.roomSize
            : event.studentsCount
        }}</label>
        <v-icon class="ml-1 icon" size="15">mdi-account-multiple</v-icon>
      </div>
    </div>
  </v-card>
</template>

<script>
import EventTypeModel from "@/store/models/EventTypeModel";
import user from "@/utils/mixins/user";

export default {
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
  },
  name: "TheoryAlert",
  mixins: [user],
  computed: {
    lessonTypeLabel() {
      return EventTypeModel.find(this.event.eventType) || {};
    },
    eventTypeModel() {
      return EventTypeModel.find(this.event.eventType) || {};
    },
  },
};
</script>

<style scoped lang="scss">
.event-card {
  padding: 0 5px 5px 5px;
  margin: 5px 0;
  font-size: 0.7rem;
  background: #fff !important;
  border-right: 1px solid #eee;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  .flex-time {
    display: flex;
    justify-content: space-between;
  }
  & > * {
    pointer-events: none;
    font-size: 12px;
    padding: 0 5px;
  }
  .request-mark {
    position: absolute;
    bottom: 3px;
    right: 3px;
  }
  .icon-box {
    position: absolute;
    right: 5px;
    bottom: 5px;
    color: #333;
  }
  .vehicles {
    width: 100%;
  }
  .theory-topic {
    width: 90%;
  }
}
</style>
