import router from '@/router'
import Notification from '@/notifications/notification'

export default class AppointmentNotification extends Notification {
  action () {
    const id = this.notification.data.id;
    const sameRoute = router.currentRoute.name === 'calendar.appointment';

    if (sameRoute && router.currentRoute.params.id.toString() === id.toString()) return;

    const to = {name: 'calendar.appointment', params: {id}}

    if (sameRoute) {
      router.replace(to).catch((error) => console.log(error));
    } else {
      router.push(to).catch((error) => console.log(error));
    }
  }

  subtitle() {
    const data = this.notification.data;
    return data.startDate + " " + data.startTime + " - " + data.endTime;
  }
}
