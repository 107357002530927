<template>
  <div>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown">
      <v-card>
        <v-toolbar elevation="3">
          <v-card-title>{{ $t("route.vehiclesOverview") }}</v-card-title>
          <v-spacer />
          <v-btn text exact color="primary" @click="createItem">{{ $t("label.create") }}</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row :class="{'flex-column-reverse': $vuetify.breakpoint.xs}">
            <v-col class="d-flex align-center" cols="12" sm="10">
              <template v-if="filtersCount > 0">
                <v-chip-group show-arrows>
                  <v-chip
                    v-for="group in appliedFilters.licensesGroups"
                    :key="`license_group_${group.id}`"
                    close
                    small
                    @click:close="removeFromAppliedFilters('licensesGroups', group.id)"
                  >
                    {{ group.name }}
                  </v-chip>

                  <v-chip
                    v-for="license in appliedFilters.licenses"
                    :key="`license_${license.id}`"
                    close
                    small
                    @click:close="removeFromAppliedFilters('licenses', license.id)"
                  >
                    {{ license.name }}
                  </v-chip>

                  <v-chip
                    v-for="teacher in appliedFilters.teachers"
                    :key="`teacher_${teacher.id}`"
                    close
                    small
                    @click:close="removeFromAppliedFilters('teachers', teacher.id)"
                  >
                    {{ teacher.fullName }}
                  </v-chip>
                </v-chip-group>
              </template>
            </v-col>
            <v-col v-if="loadingPage || items.length > 0 || filtersCount > 0" class="d-flex align-center" cols="12"
                   sm="2">
              <v-spacer />

              <ShowsAs
                ref="sortMenu"
                :options="{
                  offsetX: true,
                  offsetY: true,
                  left: true,
                  zIndex: 10,
                  minWidth: 300,
                  maxWidth: 300
                }"
                :type="$vuetify.breakpoint.mobile ? 'dialog' : 'menu'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-badge
                    :content="sortingCount"
                    :value="sortingCount"
                    color="primary"
                    overlap
                  >
                    <v-btn color="primary" icon text v-bind="attrs" v-on="on">
                      <v-icon>mdi-sort</v-icon>
                    </v-btn>
                  </v-badge>
                </template>

                <v-card>
                  <v-card-title>{{ $t("label.sorting") }}</v-card-title>

                  <v-list class="py-0" dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t("label.mileage") }}</v-list-item-title>

                        <v-radio-group
                          :value="menuSortValues.driven"
                          @change="value => onSortingChange('driven', value)"
                          dense
                          hide-details
                          row
                        >
                          <v-radio :label="$t('label.asc')" :ripple="false" value="asc" />
                          <v-radio :label="$t('label.desc')" :ripple="false" value="desc" />
                        </v-radio-group>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t("label.next_service_appointment") }}</v-list-item-title>

                        <v-radio-group
                          :value="menuSortValues.nextAppointment"
                          @change="value => onSortingChange('nextAppointment', value)"
                          dense
                          row
                        >
                          <v-radio :label="$t('label.asc')" :ripple="false" value="asc" />
                          <v-radio :label="$t('label.desc')" :ripple="false" value="desc" />
                        </v-radio-group>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <v-divider />

                  <v-card-actions>
                    <v-btn color="red" text @click="clearSortingAndCloseMenu">{{ $t("btn.clear") }}</v-btn>
                    <v-spacer/>
                    <v-btn color="primary" text @click="sort">{{ $t("btn.ok") }}</v-btn>
                  </v-card-actions>
                </v-card>
              </ShowsAs>

              <ShowsAs
                :type="$vuetify.breakpoint.mobile ? 'dialog' : 'menu'"
                :options="{
                  fullScreen: $vuetify.breakpoint.mobile,
                  offsetX: true,
                  offsetY: true,
                  left: true,
                  zIndex: 10,
                  minWidth: 800
                }"
                ref="filtersMenu"
              >
                  <template v-slot:activator="{ on, attrs }">
                      <v-badge
                        color="primary"
                        overlap
                        :content="filtersCount"
                        :value="filtersCount"
                      >
                          <v-btn color="primary" text v-bind="attrs" v-on="on" icon>
                              <v-icon>mdi-filter-outline</v-icon>
                          </v-btn>
                      </v-badge>
                  </template>

                <v-card :tile="$vuetify.breakpoint.mobile">
                  <v-card-title>{{ $t("label.filters") }}</v-card-title>

                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="filterValues.licensesGroups"
                          :items="licensesGroups"
                          clearable
                          :label="$t('label.filter_by_licenses_groups')"
                          dense
                          hide-details
                          item-text="name"
                          item-value="id"
                          multiple
                          outlined
                        />
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-select
                            v-model="filterValues.licenses"
                            :items="licenses"
                            :label="$t('label.filter_by_license')"
                            clearable
                            dense
                            hide-details
                            item-text="name"
                            item-value="id"
                            multiple
                            outlined
                          />
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-select
                            v-model="filterValues.teachers"
                            :items="teachersAsOptions"
                            :label="$t('label.filter_by_teacher')"
                            clearable
                            dense
                            hide-details
                            multiple
                            outlined
                          >
                            <template v-slot:selection="{ item, index }">
                                <span v-if="index < 4" class="mr-1">
                                  {{ item.text + (index !== filterValues.teachers.length - 1 ? "," : "") }}
                                </span>
                              <span v-if="index === 4" class="grey--text text-caption">
                                  (+{{ filters.teachers.length - 4 }} others)
                                </span>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>

                      <v-divider />

                      <v-card-actions>
                        <v-btn color="red" text @click="clearFilters">{{ $t("btn.clear") }}</v-btn>
                          <v-spacer />
                        <v-btn color="primary" text @click="filter">{{ $t("btn.ok") }}</v-btn>
                      </v-card-actions>
                  </v-card>
              </ShowsAs>

              <ListChangeViewBtn class="ma-auto" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <GridView :loading="loadingPage">
                <GridItem
                  v-for="item in items"
                  :key="item.id"
                  :item="item"
                  :to="{ name: 'vehicles.vehicle', params: { id: item.id } }"
                >
                  <template v-slot:content>
                    <div class="d-flex justify-space-between align-self-start">
                      <VehicleAvatar
                        left
                        size="50"
                        class="mt-1"
                        :path="item.photo"
                        :contain="!item.photo"
                        :color="item.color"
                      />

                      <div
                        class="ml-4 d-flex flex-column"
                        :style="{ 'max-width': textTruncateContainerWidth }"
                      >
                        <div class="d-flex flex-column">
                          <div class="d-flex text-subtitle-1 font-weight-medium">
                            <span class="text-truncate">{{ item.make + " " + item.model }}<template v-if="item.year">, {{ item.year }}</template></span>
                          </div>

                          <span>{{ item.licensePlate }}</span>
                          <span class="text-caption">{{ $t("label.mileage") }}: {{ item.currentMileage }} Km</span>

                          <div class="text-caption">
                            {{ $t("label.next_service_appointment") }}:
                            <template v-if="item.nextServiceAppointmentAt">
                              {{ item.nextServiceAppointmentAt | dateFormat("dd.MM.yyyy HH:mm") }}
                            </template>
                            <span v-else :class="{ 'error--text' : item.nextServiceAppointmentIn < 0 }">
                              <template v-if="item.nextServiceAppointmentIn > 0">{{ $t("label.in") }}</template>
                              {{ Math.abs(item.nextServiceAppointmentIn) }} Km
                              <template v-if="item.nextServiceAppointmentIn < 0">{{ $t("ago") }}</template>

                              <v-tooltip v-if="!item.servicePeriod" left>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon color="warning" small v-bind="attrs" v-on="on">mdi-alert-outline</v-icon>
                                </template>
                                {{ $t("messages.no_vehicle_service_period_setup") }}
                              </v-tooltip>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>

                  <template v-slot:actions>
                    <v-list-item @click="$refs.vehicleMileageDialog.open(item.id)">
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-road-variant</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ $t("label.update_mileage") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$refs.vehicleRefuelDialog.open(item.id)">
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-fuel</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ $t("label.new_refueling") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="schoolSettings.isVehicleReservationsEnabled"
                      @click="$refs.vehicleReservationDialog.open({vehicleId: item.id})"
                    >
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-calendar-clock</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ $t("label.new_reservation") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$refs.vehicleServiceDialog.open(item.id)">
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-car-wrench</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ $t("label.new_service_apt") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$refs.vehicleExpenseDialog.open(item.id)">
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-cash-minus</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ $t("label.new_expense") }}</v-list-item-title>
                    </v-list-item>
                    <v-divider />
                    <v-list-item @click="downloadDriverAssistantSystemsReport(item)">
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-file-document</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ $t("btn.fas_pdf") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="editItem(item)">
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ $t("label.edit") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteItem(item)">
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-archive-arrow-down</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ $t("btn.archive") }}</v-list-item-title>
                    </v-list-item>
                  </template>
                </GridItem>
              </GridView>

              <template v-if="itemsView === 'list' && ! $vuetify.breakpoint.mobile">

                <v-skeleton-loader
                  v-if="loadingPage"
                  class="mx-auto"
                  type="table-row@3"
                />

                <v-simple-table v-else dense>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th style="width: 150px">{{ $t("list.name") }}</th>
                      <th style="width: 150px">{{ $t("label.license_plate") }}</th>
                      <TableTh v-model="tableSortValues.driven" :width="150" name="driven">{{ $t("label.mileage") }}</TableTh>
                      <TableTh v-model="tableSortValues.nextAppointment" :width="150" name="nextAppointment">
                        {{ $t("label.next_service_appointment") }}
                      </TableTh>
                      <th class="text-right" style="width: 20px">{{ $t("list.actions") }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      v-for="item in items"
                      :key="item.id"
                      style="cursor: pointer"
                      @click="
                        () =>
                          $router.push({
                            name: 'vehicles.vehicle',
                            params: { id: item.id }
                          })
                      "
                    >
                      <td>
                        {{ item.make + " " + item.model }}
                        <template v-if="item.year">, {{ item.year }}</template>
                      </td>
                      <td>{{ item.licensePlate }}</td>
                      <td>{{ item.currentMileage }} Km</td>
                      <td>
                        <template v-if="item.nextServiceAppointmentAt">
                          <span v-if="item.isInService">{{ $t('label.in_service') }}</span>

                          <template v-else>
                            {{ item.nextServiceAppointmentAt | dateFormat("dd.MM.yyyy HH:mm") }}

                            <span v-if="isToday(parseISO(item.nextServiceAppointmentAt))">({{ $t('today') }})</span>
                            <span v-else-if="isTomorrow(parseISO(item.nextServiceAppointmentAt))">({{ $t('tomorrow') }})</span>
                            <span v-else>
                            ({{ $t("label.in") + " " + item.numberOfDaysTillNow + " " + $tc("times.day", item.numberOfDaysTillNow)}})
                          </span>
                          </template>
                        </template>

                        <span v-else :class="{ 'error--text' : item.nextServiceAppointmentIn < 0 }">
                          <template v-if="item.nextServiceAppointmentIn > 0">{{ $t("label.in") }}</template>
                          {{ Math.abs(item.nextServiceAppointmentIn) }} Km
                          <template v-if="item.nextServiceAppointmentIn < 0">{{ $t("ago") }}</template>

                          <v-tooltip v-if="!item.servicePeriod" left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="warning" small v-bind="attrs" v-on="on">mdi-alert-outline</v-icon>
                            </template>
                            {{ $t("messages.no_vehicle_service_period_setup") }}
                          </v-tooltip>
                        </span>
                      </td>
                      <td class="text-right">
                        <RightClickMenu :item="item">
                          <template v-slot="{ showItemMenu }">
                            <v-icon
                              :disabled="loadingAction"
                              size="25"
                              @click="(e) => showItemMenu(e, item)"
                              @click.native.stop
                            >mdi-dots-vertical
                            </v-icon
                            >
                          </template>

                          <template v-slot:menu>
                            <v-list-item @click="$refs.vehicleMileageDialog.open(item.id)">
                              <v-list-item-icon class="mr-2">
                                <v-icon>mdi-road-variant</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>{{ $t("label.update_mileage") }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="$refs.vehicleRefuelDialog.open(item.id)">
                              <v-list-item-icon class="mr-2">
                                <v-icon>mdi-fuel</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>{{ $t("label.new_refueling") }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              v-if="schoolSettings.isVehicleReservationsEnabled"
                              @click="$refs.vehicleReservationDialog.open({vehicleId: item.id})"
                            >
                              <v-list-item-icon class="mr-2">
                                <v-icon>mdi-calendar-clock</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>{{ $t("label.new_reservation") }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="$refs.vehicleServiceDialog.open(item.id)">
                              <v-list-item-icon class="mr-2">
                                <v-icon>mdi-car-wrench</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>{{ $t("label.new_service_apt") }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="$refs.vehicleExpenseDialog.open(item.id)">
                              <v-list-item-icon class="mr-2">
                                <v-icon>mdi-cash-minus</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>{{ $t("label.new_expense") }}</v-list-item-title>
                            </v-list-item>
                            <v-divider />
                            <v-list-item @click="downloadDriverAssistantSystemsReport(item)">
                              <v-list-item-icon class="mr-2">
                                <v-icon>mdi-file-document</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>{{ $t("btn.fas_pdf") }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="editItem(item)">
                              <v-list-item-icon class="mr-2">
                                <v-icon>mdi-pencil</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>{{ $t("label.edit") }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="deleteItem(item)">
                              <v-list-item-icon class="mr-2">
                                <v-icon>mdi-archive-arrow-down</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>{{ $t("btn.archive") }}</v-list-item-title>
                            </v-list-item>
                          </template>
                        </RightClickMenu>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>

              <v-alert v-if="!loadingPage && items.length === 0">
                <empty-box />
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <VehicleFormDialog
        ref="formItem"
        @create="onCreateItem"
        @update="onUpdateItem"
      />
      <VehicleMileageDialog ref="vehicleMileageDialog" show-details-alert />
      <VehicleRefuelDialog ref="vehicleRefuelDialog" show-details-alert />
      <VehicleExpensesDialog ref="vehicleExpenseDialog" show-details-alert />
      <VehicleReservationDialog ref="vehicleReservationDialog" show-details-alert />
      <VehicleServiceDialog ref="vehicleServiceDialog" show-details-alert />

      <v-btn
        v-if="!mdLandscapeAndUp"
        fixed
        right
        bottom
        fab
        small
        class="mr-2"
        color="primary"
        @click="createItem"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import vehiclesService from "@/services/vehiclesService";
import responsive from "@/utils/mixins/responsive";
import GridView from "@/components/grid/GridView.vue";
import GridItem from "@/components/grid/GridItem.vue";
import gridElementsHeaderWidth from "@/utils/mixins/gridElementsHeaderWidth";
import VehicleAvatar from '@/components/VehicleAvatar.vue'
import RightClickMenu from '@/components/RightClickMenu.vue'
import ListChangeViewBtn from "@/components/list/ListChangeViewBtn.vue";
import VehicleFormDialog from "@/components/vehicle/VehicleFormDialog.vue";

import { debounce } from "throttle-debounce";
import { mapGetters, mapState } from 'vuex'
import ShowsAs from "@/components/ShowsAs.vue";
import VehicleRefuelDialog from "@/components/vehicle/VehicleRefuelDialog.vue";
import VehicleMileageDialog from "@/components/vehicle/VehicleMileageDialog.vue";
import VehicleExpensesDialog from "@/components/vehicle/VehicleExpensesDialog.vue";
import { EventBus } from "@/EventBus";
import TeacherModel from "@/store/models/TeacherModel";
import VehicleModel from "@/store/models/VehicleModel";
import LicenseModel from "@/store/models/LicenseModel";
import LicenseGroupModel from "@/store/models/LicenseGroupModel";
import VehicleServiceDialog from "@/components/vehicle/VehicleServiceDialog.vue";
import TableTh from "@/components/table/TableTh.vue";
import VehicleReservationDialog from '@/components/vehicle/VehicleReservationDialog.vue'
import { isToday, isTomorrow, parseISO } from 'date-fns'
import vehicleService from "@/services/vehiclesService";
import SchoolDataLoader from '@/helpers/schoolDataLoader'
import FileSaver from "@/helpers/fileSaver";
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: "Vehicles",
  components: {
    VehicleReservationDialog,
    TableTh,
    VehicleServiceDialog,
    VehicleExpensesDialog,
    VehicleMileageDialog, VehicleRefuelDialog,
    ShowsAs,
    VehicleFormDialog,
    ListChangeViewBtn,
    RightClickMenu,
    VehicleAvatar,
    GridItem,
    GridView
  },
  mixins: [responsive, gridElementsHeaderWidth],
  data() {
    return {
      filters: {},
      filterValues: {
        licensesGroups: [],
        licenses: [],
        teachers: []
      },
      menuSortValues: {
        driven: null,
        nextAppointment: null
      },
      tableSortValues: {
        driven: null,
        nextAppointment: null
      },
      filterMenuOpened: false,
      loadingAction: false,
      loadingPage: true,
      headers: [],
      loadingSearch: false,
      searchText: ""
    };
  },
  computed: {
    ...mapState("main", ["itemsView"]),
    ...mapGetters("school", { schoolSettings: "school" }),
    debouncedSearch() {
      return debounce(300, this.onSearch);
    },
    licensesById() {
      const byId = {};
      LicenseModel.all().forEach((item) => {
        if (!(item.id in byId)) byId[item.id] = item;
      });
      return byId;
    },
    appliedFilters() {
      return {
        licensesGroups: LicenseGroupModel.findIn(this.filters?.licensesGroups),
        licenses: LicenseModel.findIn(this.filters?.licenses),
        teachers: TeacherModel.findIn(this.filters?.teachers)
      };
    },
    teachersAsOptions() {
      return this.teachers.map((item) => ({ text: item.fullName, value: item.id }));
    },
    teachers() {
      return TeacherModel.all();
    },
    licenses() {
      return LicenseModel.query().where('forPractice', true).get();
    },
    licensesGroups() {
      const availableGroupsIds = this.licenses.map(license => license.groupId)

      return LicenseGroupModel
          .query()
          .whereIdIn(availableGroupsIds)
          .get();
    },
    filtersCount() {
      return Object.values(this.filters).length;
    },
    sortingCount() {
      return Object.values(this.tableSortValues).filter(item => item !== null).length;
    },
    items() {
      const vehicleQuery = VehicleModel.query();

      if (this.tableSortValues.driven) {
        vehicleQuery.orderBy(vehicle => vehicle.currentMileage, this.tableSortValues.driven);
      }

      if (this.tableSortValues.nextAppointment) {
        vehicleQuery.orderBy(vehicle => vehicle.nextServiceAppointmentAt, this.tableSortValues.nextAppointment === "desc" ? "asc" : "desc");
        vehicleQuery.orderBy(vehicle => vehicle.nextServiceAppointmentIn, this.tableSortValues.nextAppointment);
      }

      const licensesGroups = this.filters?.licensesGroups || [];
      const licenses = this.filters?.licenses || [];

      let licensesIds = [];
      if (licensesGroups.length > 0 || licenses.length > 0) {
        const licenseQuery = LicenseModel.query();
        if (licensesGroups.length > 0) {
          licenseQuery.where("groupId", groupId => licensesGroups.includes(groupId));
        }
        if (licenses.length > 0) {
          licenseQuery.where(license => licenses.includes(license.id));
        }
        licensesIds = licenseQuery.get().map(item => item.id);
        vehicleQuery.where("licensesIds", values => licensesIds.some(item => values.includes(item)));
      }

      const teachers = this.filters?.teachers || [];
      if (teachers.length > 0) {
        vehicleQuery.where("teachersIds", values => teachers.some(item => values.includes(item)));
      }

      vehicleQuery.where("deletedAt", value => value === null);

      return vehicleQuery.orderBy("name", "asc").get();
    }
  },
  beforeCreate () {
    SchoolDataLoader.load({ only: ['t'] })
  },
  created() {
    EventBus.$on("service-appointment-created", this.fetch);
    EventBus.$on("refueling-created", this.fetch);
    EventBus.$on("mileage-created", this.fetch);
  },
  mounted() {
    this.fetch()
      .finally(() => {
        this.loadingPage = false;
      });
  },
  destroyed() {
    EventBus.$off("service-appointment-created", this.fetch);
    EventBus.$off("refueling-created", this.fetch);
    EventBus.$off("mileage-created", this.fetch);
  },
  methods: {
    isTomorrow,
    parseISO,
    isToday,
    fetch() {
      return vehiclesService
        .load()
        .then((resp) => VehicleModel.create({ data: resp.data || [] }));
    },
    async onSearch(searchText) {
      this.searchText = searchText;
      this.loadingSearch = true;

      await this.fetch().finally(() => {
        this.loadingPage = false;
      });
    },
    createItem() {
      this.$refs.formItem.open();
    },
    downloadDriverAssistantSystemsReport(item) {
      vehicleService.getVehicleDriverAssistanceSystemsPdf(item.id)
          .then((response) => {
            const fileSaver = new FileSaver([response.data])
            fileSaver.setType('application/pdf').saveToDevice(`Fahrerassistenzsystemen-${item.licensePlate}.pdf`)
          })
    },
    editItem(item) {
      this.$refs.formItem.open(item);
    },
    deleteItem(item) {
      this.loadingAction = true;
      showConfirm(
        this.$t('btn.confirm'),
        this.$t("messages.are_your_sure_archive"),
        () => {
          vehiclesService
            .destroy(item.id)
            .then(() => {
              this.fetch();
              EventBus.$emit("service-appointment-deleted");
            })
            .finally(() => {
              this.loadingAction = false;
            });
        },
        () => {
          this.loadingAction = false;
        }
      );
    },
    toggleActive(item, value) {
      let data = {};

      if (value) data = { active: true };

      const itemIndex = this.items.indexOf(item);

      this.$set(this.items, itemIndex, { ...item, loading: true });
      vehiclesService
        .makeActive(item.id, data)
        .then(() => {
          this.$set(this.items, itemIndex, { ...item, active: true });
        })
        .finally(() => {
          this.$set(this.items, itemIndex, { ...item, loading: false });
        });
    },
    onCreateItem(createdItem) {
      VehicleModel.insert({ data: createdItem });
    },
    onUpdateItem(updatedItem) {
      VehicleModel.update({ where: updatedItem.id, data: updatedItem });
    },
    toggleListView(view) {
      this.itemsView = view;
    },
    clearFilters() {
      this.filterValues = {
        licensesGroups: [],
        licenses: [],
        teachers: []
      };
      this.filters = {};
    },
    removeFromAppliedFilters(filterName, filterId) {
      const filters = { ...this.filters };

      if (filters[filterName]) {
        filters[filterName] = filters[filterName].filter(item => item !== filterId);
        if (filters[filterName].length === 0) delete filters[filterName];
        this.filterValues[filterName] = filters[filterName] || [];
      }

      this.filters = { ...filters };
    },
    filter() {
      this.$refs.filtersMenu.close();

      this.filters = {};
      if (this.filterValues.licensesGroups.length > 0)
        this.filters.licensesGroups = this.filterValues.licensesGroups;
      if (this.filterValues.licenses.length > 0)
        this.filters.licenses = this.filterValues.licenses;
      if (this.filterValues.teachers.length > 0)
        this.filters.teachers = this.filterValues.teachers;
    },
    sort() {
      this.clearTableSorting()
      this.tableSortValues = structuredClone(this.menuSortValues)
      this.$refs.sortMenu.close();
    },
    onSortingChange(name, value) {
      this.clearMenuSorting()
      this.menuSortValues[name] = value;
    },
    clearMenuSorting() {
      this.menuSortValues = {
        driven: null,
        nextAppointment: null
      }
    },
    clearTableSorting() {
      this.tableSortValues = {
        driven: null,
        nextAppointment: null
      }
    },
    clearSortingAndCloseMenu() {
      this.$refs.sortMenu.close();
      this.clearTableSorting();
      this.clearMenuSorting();
    }
  },
};
</script>

<style scoped lang="scss"></style>
