import { Model } from "@vuex-orm/core";
import { formatISO } from "date-fns";
import ChatRoom from "@/store/models/ChatRoom";
import { v4 as uuid4 } from "uuid";

export default class ChatMessage extends Model {
  static entity = "chatMessages";

  static fields() {
    return {
      id: this.uid(() => uuid4()),
      userId: this.number(),
      roomId: this.string(null),
      message: this.string(""),
      state: this.string("loading"),
      room: this.belongsTo(ChatRoom, "roomId"),
      createdAt: this.attr(() => formatISO(new Date())),
      updatedAt: this.attr(() => formatISO(new Date())),
    };
  }
}
