<template>
  <v-dialog
    :value="show"
    max-width="600"
    @click:outside="close"
    @keydown.esc="close"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    scrollable
  >
    <v-card tile>
      <v-toolbar class="mb-2 flex-grow-0" dark color="primary" dense>
        <v-toolbar-title>{{ $t("label.create_or_edit_address", { createOrEdit: item ? $t("label.edit") : $t("label.create") }) }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-form>
          <validation-observer ref="form">
            <v-row>
              <v-col cols="12">
                <v-radio-group
                  v-model="type"
                  :row="!$vuetify.breakpoint.mobile"
                  :disabled="item !== null || disableTypes"
                  @change="onChangeType"
                >
                  <v-radio :label="$t('label.office')" value="office"/>
                  <v-radio :label="$t('label.meeting_place')" value="meetingPlace"/>
                  <v-radio :label="$t('label.invoice_address')" value="invoiceAddress"/>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <validation-provider v-slot="{ errors }" name="name">
                  <v-text-field
                      dense
                      required
                      outlined
                      v-model="form.name"
                      :label="$t('form.name')"
                      :placeholder="$t('form.name')"
                      :error="errors.length > 0"
                      :error-messages="errors"
                      :hide-details="errors.length === 0"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row v-if="type === 'invoiceAddress'" dense>
              <v-col>
                <FieldsGroup :title="$t('label.contact_person')">
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <validation-provider v-slot="{ errors }" name="clientFirstName">
                        <v-text-field
                            v-model="form.clientFirstName"
                            :label="$t('form.first_name')"
                            :placeholder="$t('form.first_name')"
                            required
                            dense
                            outlined
                            :error="errors.length > 0"
                            :error-messages="errors"
                            :hide-details="errors.length === 0"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="6">
                      <validation-provider v-slot="{ errors }" name="clientLastName">
                        <v-text-field
                            dense
                            required
                            outlined
                            v-model="form.clientLastName"
                            :label="$t('form.last_name')"
                            :placeholder="$t('form.last_name')"
                            :error="errors.length > 0"
                            :error-messages="errors"
                            :hide-details="errors.length === 0"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </FieldsGroup>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <FieldsGroup :title="$t('label.address')">
                  <v-row dense>
                    <v-col cols="12" sm="6" md="9">
                      <validation-provider v-slot="{ errors }" name="street">
                        <v-text-field
                            outlined
                            dense
                            v-model="form.street"
                            :label="$t('form.street')"
                            :placeholder="$t('form.street')"
                            type="text"
                            required
                            :error="errors.length > 0"
                            :error-messages="errors"
                            :hide-details="errors.length === 0"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <validation-provider v-slot="{ errors }" name="houseNr">
                        <v-text-field
                            outlined
                            dense
                            v-model="form.houseNr"
                            :label="$t('form.house')"
                            :placeholder="$t('form.house')"
                            type="text"
                            required
                            :error="errors.length > 0"
                            :error-messages="errors"
                            :hide-details="errors.length === 0"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="6" md="3">
                      <validation-provider v-slot="{ errors }" name="postcode">
                        <v-text-field
                            outlined
                            dense
                            v-model="form.postcode"
                            :label="$t('form.plz')"
                            :placeholder="$t('form.plz')"
                            type="text"
                            required
                            :error="errors.length > 0"
                            :error-messages="errors"
                            :hide-details="errors.length === 0"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="6" md="9">
                      <validation-provider v-slot="{ errors }" name="city">
                        <v-text-field
                            outlined
                            dense
                            v-model="form.city"
                            :label="$t('form.city')"
                            :placeholder="$t('form.city')"
                            type="text"
                            required
                            :error="errors.length > 0"
                            :error-messages="errors"
                            :hide-details="errors.length === 0"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </FieldsGroup>
              </v-col>
            </v-row>
            <v-row v-if="type === 'invoiceAddress'" dense>
              <v-col cols="12">
                <validation-provider v-slot="{ errors }" name="country">
                  <v-text-field
                    outlined
                    dense
                    required
                    type="text"
                    v-model="form.country"
                    :label="$t('form.country')"
                    :placeholder="$t('form.country')"
                    :error="errors.length > 0"
                    :error-messages="errors"
                    :hide-details="errors.length === 0"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </validation-observer>
        </v-form>
      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-btn text color="grey darken-2" :disabled="loading" @click.native="close">
          {{ $t('btn.close') }}
        </v-btn>

        <v-spacer/>

        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading || !type"
          @click.native="save"
        >
          <v-icon :left="!$vuetify.breakpoint.mobile">{{ item ? 'mdi-content-save-edit' : 'mdi-content-save' }}</v-icon>
          <span v-if="!$vuetify.breakpoint.mobile">{{ item ? $t('btn.update') : $t('btn.create') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import officesService from '@/services/officesService'
import meetingPlacesService from '@/services/meetingPlacesService'
import invoiceAddressesService from '@/services/invoiceAddressesService'
import FieldsGroup from '@/components/form/FieldsGroup.vue'
import OfficeModel from '@/store/models/OfficeModel'
import MeetingPlaceModel from '@/store/models/MeetingPlaceModel'
import InvoiceAddressesModel from '@/store/models/InvoiceAddressesModel'

const typeService = {
  office: officesService,
  meetingPlace: meetingPlacesService,
  invoiceAddress: invoiceAddressesService
}

const typeOrmModel = {
  office: OfficeModel,
  meetingPlace: MeetingPlaceModel,
  invoiceAddress: InvoiceAddressesModel
}

export default {
  name: 'AddressFormDialog',
  components: { FieldsGroup },
  props: {
    disableTypes: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      show: false,
      type: null,
      form: {
        name: '',
        clientFirstName: '',
        clientLastName: '',
        street: '',
        houseNr: '',
        postcode: '',
        city: '',
        country: '',
      },
      item: null
    }
  },
  methods: {
    open(type = null, item = null) {
      this.show = true
      this.type = type
      this.item = item

      this.$nextTick(() => {
        if (item) {
          Object.keys(item).forEach(key => {
            if (key in this.form) {
              this.form[key] = item[key]
            }
          })
        }
      })
    },
    close() {
      this.show = false

      setTimeout(() => {
        this.$refs.form.reset()
        this.form = {
          name: '',
          clientFirstName: '',
          clientLastName: '',
          street: '',
          houseNr: '',
          postcode: '',
          city: '',
          country: '',
        }
        this.type = null
        this.item = null
      }, 200)
    },
    save() {
      const saveMethod = this.item ? 'update' : 'create'

      this.loading = true
      this[saveMethod]()
        .then(response => {
          this[`${saveMethod}Orm`](response.data)
          this.close()
        })
        .catch((error) => this.showErrors(error))
        .finally(() => {
          this.loading = false
        })
    },
    create() {
      return typeService[this.type]['create'](this.form)
    },
    update() {
      return typeService[this.type]['update'](this.item.id, this.form)
    },
    createOrm(data) {
      return typeOrmModel[this.type]['insert']({ data })
    },
    updateOrm(data) {
      return typeOrmModel[this.type]['update']({ where: this.item.id, data })
    },
    showErrors(error) {
      this.$refs.form.reset()

      const responseData = error.response.data || {};
      const errors = responseData.errors || [];

      if (Object.keys(errors).length === 0 && responseData.message) {
        this.$snackbar.show({
          message: responseData.message,
          color: "error",
        });
      }

      this.$refs.form.setErrors(errors);
    },
    onChangeType() {
      this.$refs.form.reset()
    }
  }
}
</script>

<style scoped>

</style>
