export const RESET_STATE = "reset-state";
export const INCREASE_CELL_SIZE = "increase-cell-size";
export const DECREASE_CELL_SIZE = "decrease-cell-size";
export const SET_GRID_SIZE = "set-grid-size";
export const SET_DATE = "set-date";
export const ON_RESIZE_HANDLER = "on-resize-handler";
export const SET_ACTIVE_VEHICLES_IDS = "set-active-vehicles-ids";
export const SET_VEHICLES_ORDER = "sort-vehicles";
export const SET_INITIAL_LOADER = "set-initial-loader";
export const SET_APPOINTMENTS_LOADER = "set-appointments-loader";
export const TOGGLE_LEFT_SIDEBAR = "toggle-left-sidebar";
export const TOGGLE_RIGHT_SIDEBAR = "toggle-right-sidebar";
export const SET_DRAGGABLE_APPOINTMENT = "set-draggable-appointment";
export const SET_VALID_VEHICLES_FOR_ASSIGNMENT = "set-valid-vehicles-for-assignment";
export const DISABLE_APPOINTMENT = "disable-appointments";
export const HOVER_APPOINTMENT = "hover-appointment";
export const DRAGGING_PROCESS = "dragging-process";
export const HIDE_CALENDAR_ERRORS = "hide-calendar-errors";
