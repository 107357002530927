<template>
  <component
    :is="eventTypes[event.eventType]"
    :event="event"
    :style="`border-left: 10px solid ${color};`"
    @open="$emit('open')"
  />
</template>

<script>
import PracticeAlert from "@/components/event/PracticeAlert";
import TheoryAlert from "@/components/event/TheoryAlert";
import PlaceholderAlert from "@/components/event/PlaceholderAlert";
import SimulatorAlert from "@/components/event/SimulatorAlert";
import SpecialAlert from "@/components/event/SpecialAlert";

export default {
  name: "EventAlert",
  data() {
    return {
      eventTypes: {
        PRACTICE: PracticeAlert,
        THEORY: TheoryAlert,
        PLACEHOLDER: PlaceholderAlert,
        SIMULATOR: SimulatorAlert,
        SPECIAL: SpecialAlert,
      },
    };
  },
  components: {
    PlaceholderAlert,
    TheoryAlert,
    PracticeAlert,
    SimulatorAlert,
    SpecialAlert,
  },
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    color: {
      type: String,
      default: "white",
    },
  },
};
</script>

<style scoped></style>
