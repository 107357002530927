<template>
  <v-dialog
    v-model="show"
    width="1010"
    @click:outside="close"
    @keydown.esc="close"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    scrollable
    eager
  >
    <v-card :loading="loading">
      <v-toolbar class="mb-2 flex-grow-0" dark color="primary" dense>
        <v-toolbar-title>{{ $t("label.add_service") }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-card-text>
        <ValidationObserver ref="form">
          <v-row class="mt-5" dense>
            <v-col cols="12">
              <ValidationProvider v-slot="{ errors }" name="description">
                <v-combobox
                  solo
                  :placeholder="$t('label.description')"
                  :items="templateItems"
                  v-model="form.description"
                  @change="changeTemplate"
                  :error-messages="errors"
                  required
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.text" />
                      <v-list-item-subtitle>
                        {{ data.item.price | currency }} ({{ data.item.taxRate }}%)
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip label outlined>{{ data.item.license }}</v-chip>
                    </v-list-item-action>
                  </template>
                </v-combobox>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" lg="6">
              <ValidationProvider v-slot="{ errors }" name="price">
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        solo
                        v-model="formattedDate"
                        :label="$t('date')"
                        :placeholder="$t('label.date')"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    />
                  </template>

                  <v-date-picker
                      v-model="form.date"
                      solo
                      :error-messages="errors"
                      required
                      @change="setDate"
                  />
                </v-menu>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" lg="6">
              <ValidationProvider v-slot="{ errors }" name="price">
                <v-text-field
                  solo
                  :disabled="disableInput"
                  :placeholder="$t('label.cost')"
                  prepend-inner-icon="mdi-currency-eur"
                  v-model="form.price"
                  :error-messages="errors"
                  v-currency="{
                    currency: null,
                    allowNegative: true,
                    valueAsInteger: false,
                    precision: 2,
                  }"
                />
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" lg="6">
              <ValidationProvider v-slot="{ errors }" name="taxRate">
                <v-select
                  solo
                  :disabled="disableInput"
                  :placeholder="$t('label.tax_rate')"
                  @change="changeTaxRate"
                  prepend-inner-icon="mdi-percent-outline"
                  :items="percentages"
                  v-model="form.taxRate"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" lg="6">
              <ValidationProvider v-slot="{ errors }" name="licenseId">
                <v-select
                  clearable
                  solo
                  :disabled="disableInput"
                  :placeholder="$t('label.license')"
                  prepend-inner-icon="mdi-caps-lock"
                  :items="licensesItems"
                  v-model="form.licenseId"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-checkbox
                :disabled="disableInput"
                :label="$t('label.save_as_template')"
                v-model="form.saveTemplate"
              />
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>

      <v-divider class="mt-auto" />

      <v-card-actions>
        <v-btn class="mr-2" text @click="close">{{ $t("btn.close") }}</v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          @click="save"
          :disabled="loading || saving"
          :loading="saving"
        >
          <v-icon left>mdi-content-save</v-icon>
          {{ $t("btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format, parseISO } from 'date-fns'

import studentServiceTemplatesService from "@/services/studentServiceTemplatesService"
import licensesService from "@/services/licensesService"
import studentsService from "@/services/studentsService"
import StudentModel from "@/store/models/StudentModel";
import LicenseModel from "@/store/models/LicenseModel";

export default {
  name: "CreateServiceDialog",
  data: () => ({
    activePicker: null,
    show: false,
    menu: false,
    loading: true,
    saving: false,
    serviceTemplates: [],
    percentages: [
      { text: "19%", value: 19 },
      { text: "7%", value: 7 },
      { text: "0%", value: 0 },
    ],
    licenses: [],
    form: {
      date: new Date().toISOString().substr(0, 10),
      description: null,
      price: null,
      taxRate: null,
      licenseId: null,
      saveTemplate: false,
      studentId: null,
    },
    student: null,
    disableInput: false,
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    setDate() {
      this.menu = false;
    },
    open(studentId) {
      this.show = this.loading = true;
      this.menu = false;
      this.activePicker = null;
      this.studentId = studentId;
      this.student = StudentModel.find(studentId)
      studentServiceTemplatesService
        .load()
        .then((resp) => {
          this.serviceTemplates = resp.data;
        })
        .finally(() => {
          this.loading = false;
        });
      licensesService.load().then((resp) => {
        this.licenses = resp.data;
      });
    },
    close() {
      this.$refs.form.reset();
      this.form.description =
        this.form.taxRate =
        this.form.price =
        this.form.licenseId =
          null;
      this.form.date = new Date().toISOString().substr(0, 10)
      this.serviceTemplates = [];
      this.disableInput = false;
      this.loading = this.show = false;
      this.studentId = null;
      this.student = null;
    },
    save() {
      this.saving = true;
      const payload = { ...this.form, price: this.form.price?.replace(".", "")?.replace(",", ".") }
      studentsService
        .createService(this.studentId, payload)
        .then((resp) => {
          this.$emit("newServiceCreated", resp.data);
          this.close();
        })
        .catch((error) => {
          this.$refs.form.setErrors(error.response.data.errors);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    changeTaxRate(value) {
      this.form.taxRate = value;
    },
    changeTemplate(item) {
      if (typeof item === "string" || item instanceof String) {
        this.form.description = item;
        if (this.disableInput) {
          this.form.taxRate = null;
          this.form.price = null;
          this.form.licenseId = null;
          this.form.saveTemplate = false;
        }
        this.disableInput = false;
      } else {
        this.form.description = item.text;
        this.form.taxRate = item.taxRate;
        this.form.price = item.price.toString().replace('.', ',');
        this.form.licenseId = item.licenseId;
        this.disableInput = true;
        this.form.saveTemplate = false;
      }
    },
  },
  computed: {
    formattedDate() {
      return format(parseISO(this.form.date), "dd.MM.yyyy")
    },
    licensesItems() {
      // filter only licenses belonging to the student and map to v-select items
      return this.licenses.filter((item) => {
        return this.student?.licensesIds?.includes(item.id);
      }).map((item) => {
        return {
          text: item.name,
          value: item.id,
        };
      });
    },
    templateItems() {
      return this.serviceTemplates.filter((item) => {
        return this.student?.licensesIds?.includes(item.licenseId);
      }).map((item) => {
        return {
          text: item.description,
          value: item.id,
          license: LicenseModel.find(item.licenseId)?.name,
          licenseId: item.licenseId,
          price: item.price,
          taxRate: item.taxRate
        };
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
