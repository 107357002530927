
const defaultTimer = 300;
const currentVersion = process.env.VUE_APP_WEB_VERSION;
const state = () => ({
  dialogState: false,
  newVersion: null,
  timer: null,
  secondsLeft: defaultTimer,
});

const getters = {
  dialogState: (state) => state.dialogState,
  newVersion: (state) => state.newVersion,
  secondsLeft: (state) => state.secondsLeft,
  secondsAsMinutesAndSeconds: (state) => {
    if (state.secondsLeft <= 0) return "0:00";
    const minutes = Math.floor(state.secondsLeft / 60);
    const seconds = state.secondsLeft - minutes * 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  }
};
const actions = {
  openDialog({ commit, state }) {
    if (state.newVersion === currentVersion) return;

    commit("SET_DIALOG_STATE", true);
    commit("START_TIMER");
  },
  closeDialog({ commit }) {
    commit("SET_DIALOG_STATE", false);
    commit("STOP_TIMER");
  },
  setVersion({ commit }, newVersion) {
    commit("SET_VERSION", newVersion);
  },
  startTimer({ commit }) {
    commit("START_TIMER");
  },
  stopTimer({ commit }) {
    commit("STOP_TIMER");
  }
};

const mutations = {
  SET_DIALOG_STATE: (state, payload) => {
    state.dialogState = payload;
  },
  SET_VERSION: (state, payload) => {
    state.newVersion = payload;
  },
  START_TIMER: (state) => {
    clearInterval(state.timer);
    state.secondsLeft = defaultTimer;
    state.timer = setInterval(() => {
      state.secondsLeft--;
    }, 1000);
  },
  STOP_TIMER: (state) => {
    clearInterval(state.timer);
    state.secondsLeft = defaultTimer;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};