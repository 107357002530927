<template>
  <div class="mb-5 mt-3 justify-center">
    <v-btn-toggle
      group
      dense
      borderless
      mandatory
      tile
      v-model="model.type"
      :color="color.bodyColor"
      @change="$emit('emitTypeChange', model.type)">
      <v-btn
        text
        v-for="type in eventTypes"
        :key="type.id"
        :disabled="isTypeDisabled(type)"
        :value="type.id">
        {{ eventTypeLabel(type) }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import EventTypeModel from "@/store/models/EventTypeModel";
import user from "@/utils/mixins/user";
import { mapGetters } from "vuex";
import DateRange from "@/helpers/dateRange";
import { parseISO } from "date-fns";
import EventModel from "@/store/models/EventModel";

export default {
  name: "EventTypesTabs",
  mixins: [user],
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    eventTypes() {
      const allTypes = EventTypeModel.all() || {};
      return this.isSchoolAdministrator
        ? allTypes
        : allTypes.filter((type) => type.id !== "SIMULATOR");
    },
    color() {
      return EventTypeModel.find(this.model.type) || {};
    },
    isHoliday() {
      const dateRange = new DateRange();
      const regions = this.schoolSettings.regions;
      const date = this.model.date ? parseISO(this.model.date) : new Date();
      return regions.length === 0
        ? dateRange.isHoliday(date)
        : dateRange.isHoliday(date, regions.split(", "));
    },
    isModelTypeDisabled() {
      const model = EventModel.find(this.model.id) || {};
      const isPast = parseISO(model.start) <= new Date();
      const requestedPlaceholder =
        model.eventType === "PLACEHOLDER" && !!model.requests.length;
      const nonChangeableEvents = [
        "PRACTICE",
        "THEORY",
        "SIMULATOR",
        "SPECIAL",
      ].includes(model.eventType);
      return isPast || requestedPlaceholder || nonChangeableEvents;
    },
  },
  methods: {
    isTypeDisabled(item) {
      return (
        (this.isHoliday && item.id !== "OFFTIME" && item.id !== "SPECIAL") ||
        this.isModelTypeDisabled
      );
    },
    eventTypeLabel(item) {
      return this.$t(`event_type.${item.id}`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
