<template>
  <v-row>
    <v-col cols="5" sm="5">
      <v-menu
        ref="menu"
        v-model="datePicker"
        :close-on-content-click="false"
        :return-value.sync="model.date"
        transition="scale-transition"
        offset-y
        min-width="290px">
        <template v-slot:activator="{ on }">
          <v-text-field
            :disabled="!model.teacher"
            label="Date"
            v-model="model.date"
            readonly
            outlined
            dense
            format="d.m.Y"
            v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="model.date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="datePicker = false">{{
            $t("btn.cancel")
          }}</v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(model.date)">{{
            $t("btn.ok")
          }}</v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="3" sm="3">
      <TimePicker
        fieldType="startTime"
        label="Start"
        :model="model"
        :eventDuration="this.eventDuration"
        :adaptiveTime="adaptiveTime" />
    </v-col>
    <v-col cols="1" sm="1" :class="`link ${!model.teacher ? 'disabled' : ''}`">
      <v-icon color="black"
        >{{ adaptiveTime ? "mdi-link" : "mdi-link-off" }} /
      </v-icon>
      <div class="line" />
    </v-col>
    <v-col cols="3" sm="3">
      <TimePicker
        fieldType="endTime"
        label="End"
        :model="model"
        :eventDuration="this.eventDuration"
        :adaptiveTime="adaptiveTime" />
    </v-col>
  </v-row>
</template>

<script>
import TimePicker from "@/components/calendar/TimePicker";
import { mapGetters } from "vuex";

export default {
  name: "TimeFields",
  components: { TimePicker },
  data() {
    return {
      datePicker: false,
      visiblePicker: false,
      pickedHour: null,
    };
  },
  props: {
    eventDuration: {
      type: Number,
      required: true,
    },
    adaptiveTime: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "currentAppointment" }),
  },
};
</script>

<style lang="scss" scoped>
.link {
  text-align: center;
  &.disabled {
    opacity: 0.4;
  }
  i {
    margin-top: 5px;
    border: 1px solid #9e9e9e;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 4px;
    background: #fff;
    font-size: 1.3em;
    z-index: 5;
  }
  .line {
    border-bottom: 1px solid #9e9e9e;
    width: 300%;
    position: relative;
    left: -100%;
    top: -15px;
    z-index: 3;
  }
}
</style>
