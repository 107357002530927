<template>
<v-card>
  <VehicleToolBar title="vehicleMileage" activeTab="mileage">
    <v-btn color="primary" text @click="$refs.vehicleMileageDialog.open($route.params.id)">
      <v-icon>mdi-plus</v-icon>
      {{ $t("btn.add") }}
    </v-btn>
  </VehicleToolBar>

  <v-card-text>
    <v-skeleton-loader v-if="loading" type="table" />
    <template v-else>
        <empty-box v-if="! mileages.length" />
        <v-simple-table v-else dense>
            <thead>
                <tr>
                  <th class="text-left" style="width: 30%">{{ $t("label.mileage") }}</th>
                  <th class="text-left">{{ $t("label.date") }}</th>
                  <th class="text-left">{{ $t("label.recorded_by") }}</th>
                  <th class="text-left">{{ $t("label.type") }}</th>
                </tr>
            </thead>
            <tbody>
                <tr
                  v-for="(mileage, index) in orderedMileages"
                  :key="mileage.id"
                  @mouseenter="hovered = mileage.id"
                  @mouseleave="hovered = null"
                >
                    <td class="text-monospace">
                      {{ mileage.amount }} Km
                      <span style="color: green" v-if="hovered === mileage.id && index !== mileages.length - 1">
                        (+{{ mileageIncreases[index] }} Km)
                      </span>
                    </td>
                    <td>{{ mileage.createdAt }}</td>
                    <td>{{ mileage.author }}</td>
                  <td class="d-flex justify-space-between align-center">
                      {{ $t(`mileageType.${mileage.type}`) }}
                      <v-btn v-if="mileage.canDelete" text x-small color="red" @click="deleteMileage(mileage.id)">
                        {{ $t('btn.delete') }}
                      </v-btn>
                  </td>
                </tr>
            </tbody>
        </v-simple-table>
    </template>
    <VehicleMileageDialog ref="vehicleMileageDialog" />
  </v-card-text>
</v-card>
</template>

<script>
import vehiclesService from "@/services/vehiclesService";
import VehicleMileageDialog from "@/components/vehicle/VehicleMileageDialog.vue";
import VehicleToolBar from "@/components/vehicle/VehicleToolBar.vue";
import { EventBus } from "@/EventBus";
import { showConfirm } from "@/helpers/dialogs";

export default {
  name: 'VehicleMileage',
  components: {VehicleMileageDialog, VehicleToolBar},
  data: () => ({
    loading: false,
    mileages: [],
    hovered: null
  }),
  mounted () {
    this.load();
  },
  created() {
    EventBus.$on('mileage-created', this.handleMileageCreated);
  },
  destroyed() {
    EventBus.$off('mileage-created', this.handleMileageCreated);
  },
  methods: {
    handleMileageCreated (mileage) {
      this.mileages.unshift(mileage);
    },
    load () {
      this.loading = true;
      vehiclesService.loadMileages(this.$route.params.id)
        .then(response => {
          this.mileages = response.data;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteMileage(mileageId) {
      showConfirm(
        this.$t("btn.confirm"),
        this.$t("messages.are_your_sure_delete"),
        () => {
          vehiclesService.deleteMileage(this.$route.params.id, mileageId)
            .then(() => {
              this.load();
              EventBus.$emit("mileage-deleted");
            });
        });
    }
  },
  computed: {
    orderedMileages() {
      const mileages = [...this.mileages]

      return mileages.sort((prev, next) => next.amount - prev.amount)
    },
    mileageIncreases() {
      return this.orderedMileages.map((mileage, index, array) => {
        if (index === array.length - 1) {
          return 0;
        }
        return Math.abs(mileage.amount - array[index + 1].amount);
      });
    },
  }
}
</script>

<style scoped>

</style>
