<script>
import {defineComponent} from 'vue'
import LicenseModel from "@/store/models/LicenseModel";
import clickAndLearnService from "@/services/clickAndLearnService";
import codeTypes from "@/components/clickandlearn/ClickAndLearnCodeTypes";
import passwordGenerator from "@/utils/mixins/passwordGenerator";

export default defineComponent({
  name: "RegisterLicenseDialog",
  mixins: [passwordGenerator],
  data: () => ({
    show: false,
    student: null,
    loading: false,
    // loadingCodes: false,
    initialForm: {
      studentId: null,
      lastName: null,
      firstName: null,
      login: null,
      password: null,
      classes: [],
      primaryLanguage: null,
      erstErwerb: false
    },
    form: {},
    // selectedLicenseCode: null,
    availableLanguages: ["de","en","tr","es","ru","ro","pl","hr","fr","pt","it","el","ar"],
    availableClasses: [],
    // availableLicenseCodes: []
  }),
  computed: {
    // licenseCodes() {
    //   return this.availableLicenseCodes.map(code => ({
    //     text: code.code,
    //     value: code.id,
    //     props: (code.code.split('-')[0] in codeTypes) ? codeTypes[code.code.split('-')[0]] : null,
    //     disabled: ! this.isCodeSuitableForStudent(code.code)
    //   }))
    // },
    languages() {
      return this.availableLanguages
        // .filter(language => this.selectedCodeType && this.selectedCodeType.languages.includes(language))
        .map(language => ({
          text: this.$t(`languages.${language}`),
          value: language
        }))
    },
    classes() {
      return this.availableClasses
        // .filter(c => this.selectedCodeType && this.selectedCodeType.classes.includes(c))
        .map(c => ({
          text: c,
          value: c
        }))
    },
    // selectedCode () {
    //   return this.availableLicenseCodes.find(code => code.id === this.selectedLicenseCode)?.code
    // },
    // selectedCodeType() {
    //   if (! this.selectedCode) return null
    //
    //   // split the code by '-' and take the first part
    //   const type = this.selectedCode.split('-')[0]
    //   if (! (type in codeTypes)) return null
    //
    //   return codeTypes[type]
    // },
    savingDisabled () {
      return this.loading ||
        !this.form.login ||
        !this.form.password ||
        !this.form.primaryLanguage ||
        !this.form.classes.length ||
        this.studentEducationStatusIsNotLearning
    },
    studentEducationStatusIsNotLearning() {
      return this.student && this.student.educationStatus !== 'learning'
    }
    // noCodesAvailableAlert() {
    //   return !this.loadingCodes && !this.availableLicenseCodes.length
    // }
  },
  methods: {
    open(student) {
      this.show = true
      this.student = student
      this.availableClasses = LicenseModel.query().whereId(student.licensesIds).get().map(license => {
        return ['B197', 'BAt'].includes(license.name) ? 'B' : license.name
      })

      this.form = {
        ...this.initialForm,
        studentId: student.id,
        lastName: student.lastName,
        firstName: student.firstName,
        login: student.email,
        password: this.generatePassword(),
      }
      // this.loadingCodes = true
      // clickAndLearnService.getUnassignedLicenses().then(response => {
      //   this.availableLicenseCodes = response.data
      //   if (this.availableLicenseCodes.length) {
      //     this.selectedLicenseCode = this.availableLicenseCodes[0].id
      //   }
      // }).finally(() => {
      //   this.loadingCodes = false
      // })
    },
    close() {
      this.show = false
      this.student = null
      this.form = this.initialForm
      // this.selectedLicenseCode = null
      // this will prevent the message about no codes showing up early
      // setTimeout(() => {
      //   this.availableLicenseCodes = []
      // }, 500)
    },
    save() {
      this.$refs.form?.reset()
      this.loading = true
      clickAndLearnService.registerLicense(this.form)
        .then(response => {
          this.$emit('license-registered', response.data)
          this.close()
        })
        .catch((error) => {
          const responseData = error.response.data || {};
          const errors = responseData.errors || [];

          // if (responseData.code === 'clickandlearn.license.not.available') {
          //   this.availableLicenseCodes = this.availableLicenseCodes.filter(code => {
          //     return code.id !== this.selectedLicenseCode
          //   })
          //   this.selectedLicenseCode = this.availableLicenseCodes.length ? this.availableLicenseCodes[0].id : null
          // }

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error" });
          }

          this.$refs.form?.setErrors(errors);
        })
        .finally(() => {
          this.loading = false
        })
    },
    isCodeSuitableForStudent(code) {
      const type = code.split('-')[0];

      // Check if type exists in codeTypes object
      if (! (type in codeTypes)) return false;

      const codeType = codeTypes[type];

      // Return true if the student has a class that is in the codeType.classes array
      return codeType.allClasses || this.availableClasses.some(c => codeType.classes.includes(c));
    }
  }
})
</script>

<template>
  <v-dialog width="600" :value="show" @click:outside="close" @keydown.esc="close" scrollable>
    <v-card>
      <v-toolbar class="mb-4" dark color="primary" dense>
        <v-toolbar-title>{{ $t('clickandlearn.register_license') }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <ValidationObserver ref="form">
<!--        <v-select-->
<!--          class="mt-1"-->
<!--          outlined-->
<!--          :disabled="loading"-->
<!--          :loading="loadingCodes"-->
<!--          :items="licenseCodes"-->
<!--          required-->
<!--          :label="$t('clickandlearn.license_code')"-->
<!--          v-model="selectedLicenseCode"-->
<!--          prepend-inner-icon="mdi-key"-->
<!--        >-->
<!--          <template v-slot:item="{item}">-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title class="text-monospace">{{ item.text }}</v-list-item-title>-->
<!--              <v-list-item-subtitle v-if="item.props" class="d-flex">-->
<!--                <span v-if="item.props.allClasses">{{ $t('clickandlearn.all_licenses') }}</span>-->
<!--                <span v-else>{{ item.props.classes.join(', ') }}</span>-->

<!--                <div class="mx-1">-->
<!--                  &middot;-->
<!--                </div>-->

<!--                <span v-if="item.props.allLanguages">{{ $t('clickandlearn.all_languages') }}</span>-->
<!--                <span v-else>{{ item.props.languages.map(lang => $t(`languages.${lang}`)).join(', ') }}</span>-->

<!--                <template v-if="item.props.simdrive">-->
<!--                  <div class="mx-1">-->
<!--                    &middot;-->
<!--                  </div>-->

<!--                  <span>{{ $t('clickandlearn.simdrive') }}</span>-->
<!--                </template>-->

<!--                <template v-if="item.props.ebook">-->
<!--                  <div class="mx-1">-->
<!--                    &middot;-->
<!--                  </div>-->

<!--                  <span>{{ $t('clickandlearn.ebook') }}</span>-->
<!--                </template>-->
<!--              </v-list-item-subtitle>-->
<!--            </v-list-item-content>-->
<!--            <v-list-item-action>-->
<!--              <v-icon v-if="item.props?.type === 'premium'" color="yellow">mdi-star</v-icon>-->
<!--            </v-list-item-action>-->
<!--            <v-list-item-action-text v-if="item.disabled">-->
<!--              {{ $t('clickandlearn.code_not_applicable_to_this_student') }}-->
<!--            </v-list-item-action-text>-->
<!--          </template>-->
<!--        </v-select>-->

        <v-row dense class="mt-1">
          <v-col cols="12" md="6">
            <ValidationProvider v-slot="{ errors }" name="lastName">
              <v-text-field
                outlined
                :error-messages="errors"
                :disabled="loading"
                :hide-details="! $refs.form?.errors?.lastName || ! $refs.form?.errors?.lastName?.length"
                v-model="form.lastName"
                :label="$t('form.last_name')"
                required
                dense
                autocomplete="off"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider v-slot="{ errors }" name="firstName">
              <v-text-field
                outlined
                :error-messages="errors"
                :disabled="loading"
                :hide-details="! $refs.form?.errors?.firstName || ! $refs.form?.errors?.firstName?.length"
                v-model="form.firstName"
                :label="$t('form.first_name')"
                required
                dense
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <ValidationProvider v-slot="{ errors }" name="login">
              <v-text-field
                outlined
                :error-messages="errors"
                :disabled="loading"
                :hide-details="! $refs.form?.errors?.login || ! $refs.form?.errors?.login?.length"
                v-model="form.login"
                :label="$t('form.email')"
                required
                dense
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider v-slot="{ errors }" name="password">
              <v-text-field
                outlined
                :disabled="loading"
                :hide-details="! $refs.form?.errors?.password || ! $refs.form?.errors?.password?.length"
                :error-messages="errors"
                v-model="form.password"
                :label="$t('form.password')"
                required
                dense
                append-icon="mdi-reload"
                @click:append="form.password = generatePassword()"
              />
            </ValidationProvider>
          </v-col>
        </v-row>

        <ValidationProvider v-slot="{ errors }" name="classes">
          <v-select
            outlined
            :error-messages="errors"
            :disabled="loading"
            :hide-details="! $refs.form?.errors?.classes || ! $refs.form?.errors?.classes?.length"
            :items="classes"
            :label="$t('form.licenses')"
            v-model="form.classes"
            required
            dense
            multiple
            class="mt-2"
          />
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" name="primaryLanguage">
          <v-select
            outlined
            :error-messages="errors"
            :disabled="loading"
            :hide-details="! $refs.form?.errors?.primaryLanguage || ! $refs.form?.errors?.primaryLanguage?.length"
            :items="languages"
            v-model="form.primaryLanguage"
            required
            dense
            :label="$t('form.language')"
            class="mt-2"
          />
        </ValidationProvider>

        <v-checkbox
          class="mt-2"
          :label="$t('clickandlearn.first_license')"
          v-model="form.erstErwerb"
          :hint="$t('clickandlearn.erst_erwerb_hint')"
          persistent-hint
        />

<!--        <v-alert elevation="1" dense type="warning" colored-border border="left" v-if="noCodesAvailableAlert">-->
<!--          <v-list-item-title>-->
<!--            {{ $t('clickandlearn.no_codes_available') }}-->
<!--          </v-list-item-title>-->
<!--          <div class="text&#45;&#45;secondary">-->
<!--            {{ $t('clickandlearn.no_codes_available_text') }}-->
<!--          </div>-->
<!--          <v-btn x-small text class="mt-2" color="primary" @click="$router.push({name: 'click-and-learn'})">-->
<!--            {{ $t('clickandlearn.buy_licenses') }}-->
<!--          </v-btn>-->
<!--        </v-alert>-->
        </ValidationObserver>

        <v-alert class="mt-5" elevation="2" colored-border border="left" type="error" v-if="studentEducationStatusIsNotLearning">
          <div class="text-h6">
            {{ $t('label.license_assignation_not_possible') }}
          </div>
          <div>{{ $t('label.license_assignation_not_possible_description') }}</div>
        </v-alert>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn text :disabled="loading" @click="close">{{ $t('btn.close') }}</v-btn>
        <v-spacer />
        <v-btn color="primary" @click="save" :disabled="savingDisabled" :loading="loading">
          <v-icon left>mdi-key-plus</v-icon>
          {{ $t('btn.register') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>
