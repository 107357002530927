<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "ChildrenAccountsSkeletonLoader",
  props: {
    count: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    array() {
      return [...Array(this.count)];
    },
  },
})
</script>
<template>
  <div>
    <v-list-item three-line v-for="item in array" :key="item">
      <v-list-item-avatar>
        <v-skeleton-loader type="avatar" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          <v-skeleton-loader type="text" width="200" />
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-skeleton-loader type="text" width="250" />
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <v-skeleton-loader type="text" width="190" />
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-skeleton-loader type="button" />
      </v-list-item-action>
    </v-list-item>
  </div>
</template>
<style scoped>
</style>
