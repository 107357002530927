<template>
  <v-card class="shadow-event" :style="event.style">
    <div class="overline mb-4 pl-2 event-time">
      {{ event.startTime }} - {{ event.endTime }}
    </div>
  </v-card>
</template>

<script>
export default {
  name: "ShadowEvent",
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.shadow-event {
  position: absolute;
  background: rgba(145, 199, 213, 0.5);
  border: 1px dashed #6094b3;
  width: 100%;
  z-index: 8;
  pointer-events: none;
  font-size: 0.8rem;
  font-weight: bold;
  color: #6094b3;
}
</style>
