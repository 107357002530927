<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "TreeView",
  computed: {
    ...mapGetters('studentsDirectory', ['tree', 'isTreeSelectable'])
  },
  methods: {
    ...mapActions('studentsDirectory', ['openStudent']),
  }
}
</script>

<template>
  <v-treeview
      selection-type="leaf"
      v-model="$store.state.studentsDirectory.selectedTreeItems"
      :selectable="isTreeSelectable"
      hoverable
      dense
      expand-icon="mdi-chevron-down"
      :items="tree"
      open-on-click
  >
    <template v-slot:append="{item, leaf}">
      <v-badge v-if="! leaf" inline color="grey lighten-1" :content="item.count"/>
    </template>

    <template v-slot:label="{ item, leaf }">
      <span v-if="leaf" class="v-treeview-node__label d-block" :class="{inactive: ! item.active}" @click="openStudent(item)">
        <div
          v-if="leaf"
          style="position: absolute; left: 0; right: 0; top: 0; bottom: 0;"
          :class="{'click-through' : isTreeSelectable}"
          @click="openStudent(item)"
        />
        {{ item.name }}
      </span>
      <span v-else class="v-treeview-node__label" >
        {{ item.name }}
      </span>
    </template>
  </v-treeview>
</template>
<style scoped lang="scss">
.click-through {
  pointer-events: none;
}
.inactive {
  font-style: italic;
  color: rgba(0,0,0, 0.4);
}
</style>
