<template>
  <div
    class="events-box"
    @mouseover="hovered"
    @mousedown.left="mouseDownListener"
    @click="mobileClick"
  >
    <Appointment
      v-for="event in overlappedEvents"
      :key="event.id"
      :event="event"
      tabindex="0"
      @onDrag="checkIfDraggable($event, event)"
      :class="eventClassName" />

    <ShadowEvent v-if="shadowEvent.visible" :event="shadowEvent" />
    <DraggedEvent
      v-if="draggedEvent.visible"
      :event="draggedEvent.model"
      :styleCSS="draggedEvent.style"
      :time="draggedEvent.time" />

    <SunIndicator :date="date" :day="day" period="sunrise" />
    <SunIndicator :date="date" :day="day" period="sunset" />

  </div>
</template>

<script>
import { addMinutes, format, parseISO, isPast } from "date-fns";
import { mapActions, mapGetters } from "vuex";

import DateRange from "@/helpers/dateRange";
import clearEvaluation from '@/helpers/appointmentEvaluation'

import appointmentService from "@/services/appointmentService";
import insightsService from '@/services/insightsService'

import AppointmentModel from "@/store/models/AppointmentModel";
import TeacherModel from "@/store/models/TeacherModel";
// import LessonTypeModel from '@/store/models/LessonTypeModel'

import Appointment from "@/components/calendar/Appointment";
import ShadowEvent from "@/components/calendar/ShadowEvent";
import DraggedEvent from "@/components/calendar/DraggedEvent";
import SunIndicator from "@/components/calendar/SunIndicator";

import eventsOverlapMixins from "@/utils/mixins/eventsOverlapMixins";
import responsive from "@/utils/mixins/responsive";
import user from "@/utils/mixins/user";
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: "RegularEventsBox",
  mixins: [eventsOverlapMixins, responsive, user],
  components: { ShadowEvent, DraggedEvent, Appointment, SunIndicator },
  props: {
    date: {
      type: String,
      required: true,
    },
    day: {
      type: Object,
      required: true,
    },
    tIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      calendarSize: 2880,
      eventCanBeCreated: true,
      shadowEventOn: false,
      newEventStart: null,
      newEventEnd: null,
      parentHeight: null,
      draggedEventModel: null,
      draggedEventOn: false,
      startPositionX: null,
      startPositionY: null,
      dragOffset: null,
      draggedEventTop: null,
      draggedEventHeight: null,
      draggedEventWidth: null,
      draggedEventLeft: 0,
    };
  },
  watch: {
    draggedToOtherDay(newCount, oldCount) {
      const multiplier = this.separateTeachersGetter
        ? this.activeTeachersIds.length
        : 1;
      if (parseISO(newCount) > parseISO(oldCount)) {
        this.draggedEventLeft =
          this.draggedEventLeft + this.draggedEventWidth * multiplier + 1;
      } else if (parseISO(newCount) < parseISO(oldCount)) {
        this.draggedEventLeft =
          this.draggedEventLeft - this.draggedEventWidth * multiplier - 1;
      }
    },
  },
  computed: {
    ...mapGetters(["times", "activeDate", "separateTeachersGetter"]),
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("appointments", [
      "activeEventsGetter",
      "eventCreationMenuGetter",
      "shadowEventGetter",
      "draggedEventGetter",
      "loadingEventGetter",
      "hoveredDateGetter",
      "multiDeleteStatusGetter",
      "showModalGetter",
    ]),
    ...mapGetters("teachers", { activeTeachersIds: "activeIds" }),
    eventClassName() {
      if (
        this.shadowEventGetter ||
        this.draggedEventGetter ||
        this.loadingEventGetter
      )
        return "creating-new-event";
      return "";
    },
    draggedToOtherDay() {
      return this.hoveredDateGetter;
    },
    shadowEvent() {
      const down = this.newEventStart <= this.newEventEnd;
      const margin = down ? "top" : "bottom";
      const offset = down
        ? this.newEventStart % 30
        : (this.newEventStart % 30) - 30 + 1;
      const height = Math.floor(Math.abs(this.newEventEnd - this.newEventStart + offset) / 30 + 1) * 30;
      const top = Math.floor(this.newEventStart / 30) * 30;
      const bottom = Math.floor((this.parentHeight - this.newEventStart) / 30) * 30;
      return {
        visible: this.shadowEventOn,
        top,
        bottom,
        height,
        style: `height: ${height}px; ${margin}: ${down ? top : bottom}px`,
        startTime: down
          ? this.timeCalc(top / this.oneMinuteSize)
          : this.timeCalc(
              (this.parentHeight - bottom - height) / this.oneMinuteSize
            ),
        endTime: down
          ? this.timeCalc((top + height) / this.oneMinuteSize)
          : this.timeCalc((this.parentHeight - bottom) / this.oneMinuteSize),
      };
    },
    draggedEvent() {
      const offset = this.draggedEventTop % 30;
      const height = this.draggedEventHeight;
      const left = this.draggedEventLeft;
      const top =
        this.draggedEventTop > 0 &&
        Math.floor(Math.abs(this.draggedEventTop + offset) / 30 + 1) * 30 - 30;
      return {
        model: this.draggedEventModel,
        visible: this.draggedEventOn,
        style: `z-index: 999; height: ${height}px; top: ${top}px; left: ${left}px;`,
        time: `${this.timeCalc(top / this.oneMinuteSize)} - ${this.timeCalc(
          (top + height + 1) / this.oneMinuteSize
        )}`,
      };
    },
    eventsByTeachers() {
      const eventsQuery = AppointmentModel.query().with("teacher");
      if (this.isSchoolAdministrator) {
        // TODO check this if/else statement
        if (this.separateTeachersGetter) {
          eventsQuery.where("teacherId", (value) => this.tIds.includes(value));
        } else {
          eventsQuery.where("teacherId", (value) => this.tIds.includes(value) || value === null);
        }
      }

      if (this.isTeacher) eventsQuery.where("teacherId", this.currentUser.id);

      if (this.isStudent) {
        const foreignTeachers = TeacherModel.all().filter(
          (item) => !this.currentUser.teachersIds.includes(item.id)
        );
        const foreignTeachersIds = foreignTeachers.map((teacher) => teacher.id);
        eventsQuery
          .where(
            (event) =>
              this.tIds.includes(event.teacherId) ||
              foreignTeachersIds.includes(event.teacherId) ||
              event.teacherId === null
          )
          .orWhere("eventType", AppointmentModel.TYPES.THEORY);
      }

      return eventsQuery.where("type", this.activeEventsGetter).get();
    },
    eventsByDate() {
      const eventsList = this.eventsByTeachers.filter(
        (event) => event.startDate === this.date
      );
      return eventsList.sort(
        (a, b) =>
          parseISO(`${a.startDate} ${a.startTime}`) -
          parseISO(`${b.startDate} ${b.startTime}`)
      );
    },
    showTimeIndicator() {
      return this.date === format(new Date(), "yyyy-MM-dd");
    },
    computedHoliday() {
      const regions = this.schoolSettings.regions?.split(", ");
      return new DateRange().isHoliday(parseISO(this.date), regions);
    },
  },
  methods: {
    ...mapActions("appointments", [
      "showAppointmentModal",
      "shadowAppointmentToggle",
      "dragActionOn",
      "dragActionOff",
      "hoverDateAction",
      "eventCreationMenuOn",
      "eventCreationMenuOff",
      "toggleLoadingEvent",
    ]),
    checkIfDraggable($event, event) {
      if (this.isStudent || this.isPastExam(event)) return;
      this.dragStart($event, event)
    },
    hovered() {
      this.hoverDateAction(this.date);
    },
    checkIfIsPast(/*date*/) {
      /*commented past exam logic*/
      // return !this.schoolSettings.pastEventsAllowed && date <= new Date();
      return false
    },
    isPastExam(/*event*/) {
      /*commented past exam logic*/
      // const lessonType = LessonTypeModel.find(event.lessonTypeId)
      // return isPast(parseISO(event.start)) && (lessonType && !lessonType.canBeMovedInPast)
      return false
    },
    fixedPastEvent(event, endDate) {
      const notOfftime = event.type !== "OFFTIME";
      return this.checkIfIsPast(endDate) && notOfftime;
    },
    dragStart(e, event) {
      if (this.multiDeleteStatusGetter) return;
      if (this.fixedPastEvent(event, event.endDate)) return;
      if (["REQUESTED", "MISSED", "MISSED_PARTIALLY_REFUNDED", "MISSED_REFUNDED", "JOKER_REFUNDED"].includes(event.status)) return;

      this.draggedEventLeft = 0;
      this.startPositionX = e.x;
      this.startPositionY = e.y;
      this.draggedEventTop = e.target.offsetTop;
      this.draggedEventHeight = e.target.clientHeight;
      this.draggedEventWidth = e.target.parentElement.clientWidth;
      this.dragOffset = e.layerY;
      this.draggedEventModel = event;

      window.addEventListener("mousemove", this.dragging);
      window.addEventListener("mouseup", this.dragStop);
    },
    dragging(e) {
      const absX = Math.abs(this.startPositionX - e.x);
      const absY = Math.abs(this.startPositionY - e.y);
      if (absX > 10 || absY > 10) {
        this.dragActionOn();
        if (e.offsetY - this.dragOffset + this.draggedEventHeight < this.calendarSize && e.clientY !== e.layerY) {
          this.draggedEventTop = e.offsetY - this.dragOffset;
        } else {
          this.draggedEventTop = this.calendarSize - this.draggedEventHeight + 1;
        }
      }
      this.draggedEventOn = true;
    },
    dragStop() {
      const offset = this.draggedEventTop % 30;
      const top = Math.floor(Math.abs(this.draggedEventTop + offset) / 30 + 1) * 30 - 30;
      const startTime = this.timeCalc(top / this.oneMinuteSize);
      if (this.draggedEventOn) {
        const appointmentModel = {
          ...this.draggedEventModel,
          start: this.hoveredDateGetter + " " + startTime + ":00",
          date: this.hoveredDateGetter,
          group: this.draggedEventModel.group,
        }

        this.dragActionOff();
        this.draggedEventOn = false;
        const blockEventUpdate = this.draggedEventModel.start === appointmentModel.start;
        const regions = this.schoolSettings.regions?.split(", ");
        const holidayStatus = new DateRange().isHoliday(parseISO(appointmentModel.start), regions);
        const holiday = holidayStatus && this.draggedEventModel.type !== "OFFTIME" &&
          (this.draggedEventModel.type !== "SPECIAL" || !!this.draggedEventModel.studentsIds.length);
        if (blockEventUpdate || this.isPastExam(appointmentModel) || holiday ||
            this.fixedPastEvent(appointmentModel, parseISO(appointmentModel.start))) {
          AppointmentModel.update({ where: appointmentModel.id, data: this.draggedEventModel, });
        } else {
          if (clearEvaluation(this.draggedEventModel, appointmentModel)) {
            showConfirm(
              this.$t('btn.confirm'),
              this.$t('messages.are_your_sure_update_clear_evaluation'),
              () => {
                AppointmentModel.update({ where: appointmentModel.id, data: appointmentModel });
                this.toggleLoadingEvent(appointmentModel.id);

                appointmentService
                  .drag_n_drop({ id: appointmentModel.id, start: appointmentModel.start, })
                  .then((response) => {
                    insightsService.deleteAppointmentEvaluation(appointmentModel.id)
                      .then(() => AppointmentModel.update({
                          where: appointmentModel.id,
                          data: {
                            evaluation: { id: null, isPublished: null },
                            isAvailableForEvaluation: response.data.isAvailableForEvaluation
                          }
                        })
                      )
                  })
                  .catch((err) => {
                    AppointmentModel.update({ where: appointmentModel.id, data: this.draggedEventModel, })
                    this.$store.commit("main/toggle-error-bottom-sheet", { ...err.response?.data, status: 'show' })
                  })
                  .finally(() => this.toggleLoadingEvent(0));
              }
            )
          } else {
            AppointmentModel.update({ where: appointmentModel.id, data: appointmentModel });
            this.toggleLoadingEvent(appointmentModel.id);

            appointmentService
              .drag_n_drop({ id: appointmentModel.id, start: appointmentModel.start, })
              .then((response) => AppointmentModel.update({
                  where: appointmentModel.id,
                  data: { isAvailableForEvaluation: response.data.isAvailableForEvaluation }
                })
              )
              .catch((err) => {
                AppointmentModel.update({ where: appointmentModel.id, data: this.draggedEventModel, })
                this.$store.commit("main/toggle-error-bottom-sheet", { ...err.response?.data, status: 'show' })
              })
              .finally(() => this.toggleLoadingEvent(0));
          }


        }
      }

      window.removeEventListener("mousemove", this.dragging, false);
      window.removeEventListener("mouseup", this.dragStop, false);
    },

    checkForCreationRestrictions() {
      this.eventCanBeCreated = true;
      const pastDate = parseISO(
        this.date + " " + this.shadowEvent.startTime + ":00"
      );

      const teachers = TeacherModel.query().where("active", true).get();
      const studentTeachers = teachers.filter((teacher) =>
        this.currentUser.teachersIds.includes(teacher.id)
      );
      const studentCanOnlyBook = studentTeachers.every(
        (teacher) => teacher.onlyPlaceholdersBooking
      );
      if (this.computedHoliday || this.checkIfIsPast(pastDate) || studentCanOnlyBook) {
        this.shadowAppointmentToggle(false);
        this.shadowEventOn = false;
        this.eventCanBeCreated = false;
      }
    },
    mobileClick(event) {
      if (this.multiDeleteStatusGetter || !this.$vuetify.breakpoint.mdAndDown || this.showModalGetter) return;
      this.newEventStart = this.newEventEnd = event.offsetY;
      this.parentHeight = event.target.clientHeight;
      let eventModel = {
        id: null,
        type: !this.computedHoliday ? "PRACTICE" : "OFFTIME",
        date: this.date,
        startTime: this.shadowEvent.startTime,
        start: this.date + " " + this.shadowEvent.startTime + ":00",
        duration: this.shadowEvent.height / this.oneMinuteSize,
        lessonTypeId: null,
        studentsIds: this.isStudent ? [this.currentUser.id] : [],
      };
      const pastDate = parseISO(this.date + " " + eventModel.startTime + ":00");
      if (this.checkIfIsPast(pastDate)) eventModel = { ...eventModel, type: "OFFTIME" };
      let studentCanOnlyBook = false;
      if (this.isStudent) {
        const teachers = TeacherModel.query().where("active", true).get();
        const studentTeachers = teachers.filter((teacher) =>
          this.currentUser.teachersIds.includes(teacher.id)
        );
        studentCanOnlyBook = studentTeachers.every((teacher) => teacher.onlyPlaceholdersBooking)
      }
      if (this.mdLandscapeAndUp ||
          (this.isStudent && (this.computedHoliday || this.checkIfIsPast(pastDate))) ||
          studentCanOnlyBook) {
        return
      }

      const endTime = format(addMinutes(parseISO(`${eventModel.date} ${eventModel.startTime}`), eventModel.duration), "HH:mm")
      eventModel = { ...eventModel, endTime };
      this.showAppointmentModal(eventModel);
    },
    mouseDownListener(event) {
      if (this.loadingEventGetter) return;
      if (this.multiDeleteStatusGetter) return;
      if (this.isElter) return;
      if (this.isStudent) {
        this.checkForCreationRestrictions();
        if (!this.eventCanBeCreated) return;
      }
      const pastDate = parseISO(
        this.date + " " + this.shadowEvent.startTime + ":00"
      );
      const disabledForStudent = this.isStudent && (this.computedHoliday || this.checkIfIsPast(pastDate));
      if (
        disabledForStudent ||
        !this.mdLandscapeAndUp ||
        this.draggedEventOn ||
        this.eventCreationMenuGetter.visible ||
        event.target.closest(".event, .busy-time")
      )
        return;
      this.newEventStart = this.newEventEnd = event.offsetY;
      this.shadowEventOn = true;
      this.shadowAppointmentToggle(true);
      this.parentHeight = event.target.clientHeight;
      window.addEventListener("mousemove", this.mouseMove);
      window.addEventListener("mouseup", this.mouseUpListener);
    },
    mouseMove(event) {
      if (this.shadowEvent.visible && event.target.closest(".events-box")) {
        this.newEventEnd = event.offsetY - 2;
      }
    },
    mouseUpListener(event) {
      const pastDate = parseISO(this.date + " " + this.shadowEvent.startTime + ":00");
      let studentCanOnlyBook = false;
      if (this.isStudent) {
        const teachers = TeacherModel.query().where("active", true).get();
        const studentTeachers = teachers.filter((teacher) => this.currentUser.teachersIds.includes(teacher.id));
        studentCanOnlyBook = studentTeachers.every((teacher) => teacher.onlyPlaceholdersBooking);
      }
      if ((this.isStudent && (this.computedHoliday || isPast(pastDate))) || studentCanOnlyBook) {
        this.shadowAppointmentToggle(false);
        this.shadowEventOn = false;
        return;
      }
      if (this.shadowEvent.visible && !event.target.closest(".event")) {
        const teacher = TeacherModel.find(this.tIds[0])
        let newAppointment = {
          id: null,
          start: this.date + " " + this.shadowEvent.startTime + ":00",
          duration: (teacher && this.separateTeachersGetter)
              ? teacher.lessonDuration
              : (this.shadowEvent.height / this.oneMinuteSize),
          teacherId: this.separateTeachersGetter ? this.tIds[0] : null,
        };

        if (this.isStudent) {
          newAppointment = {
            ...newAppointment,
            type: "PRACTICE",
            lessonsAmount: 1,
            lessonDuration: newAppointment.duration,
            studentsIds: [this.currentUser.id],
          };
          this.showAppointmentModal(newAppointment);
        } else {
          const tempAppointment = {
            ...newAppointment,
            type: "OFFTIME",
            lessonsAmount: 1,
            lessonDuration: newAppointment.duration,
          };
          const menuData = {
            tempAppointment: tempAppointment,
            x: event.clientX,
            y: event.clientY,
            visible: true,
          };
          this.$nextTick(() => {
            this.eventCreationMenuOn({
              menu: menuData,
              event: newAppointment,
            });
            const appointment = { ...menuData.tempAppointment, id: "AAAAAA", teacherId: this.isTeacher ? this.currentUser.id : this.tIds[0] }
            AppointmentModel.insert({ data: appointment });
          });
        }
        this.shadowAppointmentToggle(false);
        this.shadowEventOn = false;
      }
      window.removeEventListener("mousemove", this.mouseMove, false);
      window.removeEventListener("mouseup", this.mouseUpListener, false);
    },
    clickEvent(browserEvent, event) {
      this.$store.commit("events/delete", event.id);
    },
    zeroFill(number) {
      return number < 10 ? `0${number}` : number;
    },
    timeCalc(minutes) {
      const startHour = 0;
      const resultHour = this.zeroFill(Math.floor(minutes / 60) + startHour);
      const resultMinute = this.zeroFill(minutes % 60);
      return `${resultHour === 24 ? '00' : resultHour}:${resultMinute}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.events-box {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 50px;
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  user-select: none;
  &:first-child {
    border-left: none !important;
  }

  &.last-view-day {
    border-right: none;
  }

  &.today {
    background: rgba(243, 255, 189, 0.2);
  }

  &.holiday {
    width: 100%;
    background: url("../../assets/img/dots-red.png");
  }

  &.past-day {
    background: rgba(238, 238, 238, 0.2);
    &.holiday {
      background: url("../../assets/img/dots-red.png");
    }
  }

  .busy-time {
    position: absolute;
    width: 100%;
    cursor: not-allowed;
    background: url("../../assets/img/dots.png");
  }
}
</style>
