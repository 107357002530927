import api from "@/utils/api";

export default {
  set(device) {
    return new Promise((resolve, reject) => {
      api
        .post("/user/device", device)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  delete(deviceUUID) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/user/device/${deviceUUID}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
