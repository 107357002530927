<template>
<div class="category-grid" :class="!showToggle || 'with-toggle-section'">
  <items-loader v-if="!activeCategory"/>

  <template v-else>
    <div class="categories-box pa-2">
      <EmptyBox v-if="allItemsAreFinished" :text="$t('insights.all_items_are_finished')"/>

      <EmptyBox v-if="activeCategory.children.length === 0 && activeCategory.items.length === 0"/>

      <template v-if="activeCategory.children.length > 0">
        <EvaluationCategoryGroup :categories="activeCategoryItems" />
      </template>

      <template v-if="activeCategory.items.length > 0">
        <EvaluationItemsGroup
            class="mb-2"
            v-for="(items, key) in itemsGroupedByName"
            :items="items"
            :key="key"
            :group-title="key"
        />
      </template>
    </div>

    <div v-if="!isStudent && showToggle" class="category-footer px-3 d-flex align-center">
      <v-switch inset dense hide-details class="mt-1" :label="$t('insights.btn.show_finished')" v-model="showFinishedItems"/>
    </div>
  </template>
</div>
</template>

<script>
import CategoryModel from '@/store/models/insights/CategoryModel'
import EvaluationItemsGroup from '@/components/insights/EvaluationItemsGroup.vue'
import EvaluationCategoryGroup from '@/components/insights/EvaluationCategoryGroup.vue'
import ItemsLoader from '@/components/skeleton-loaders/evaluations/ItemsLoader.vue'
import EmptyBox from '@/components/EmptyBox.vue'
import user from '@/utils/mixins/user'

export default {
  name: 'EvaluationCategory',
  mixins: [user],
  data() {
    return {
      showFinishedItems: false
    }
  },
  components: { EvaluationCategoryGroup, EvaluationItemsGroup, ItemsLoader, EmptyBox },
  computed: {
    activeCategoryFinishedItems() {
      const categoryItems = this.activeCategory?.items || []
      const templateFinishedItems = this.activeCategory?.template?.finishedItems || []

      return categoryItems.filter(item => templateFinishedItems.includes(item.id))
    },
    allItemsAreFinished() {
      const categoryItems = this.activeCategory?.items || []

      return !this.showFinishedItems &&
          categoryItems.length > 0 &&
          categoryItems.length === this.activeCategoryFinishedItems.length
    },
    activeCategory() {
      const categoryId = parseInt(this.$route.params.categoryId)
      return CategoryModel.query().with(['template', 'children', 'items']).where('id', categoryId).first()
    },
    activeCategoryItems() {
      const pluckIds = this.activeCategory?.children.map(item => item.id)
      return CategoryModel.query().whereIdIn(pluckIds).with(['items']).get()
    },
    itemsGroupedByName () {
      let items = this.activeCategory?.items
      const template = this.activeCategory?.template

      if (! items) return []

      if (template && !this.showFinishedItems) {
        items = items.filter(item => !template.finishedItems.includes(item.id))
      }

      return items.reduce((acc, obj) => {
        obj.isFinished = template?.finishedItems.includes(obj.id)

        const key = obj.name;
        (acc[key] = acc[key] || []).push(obj);
        return acc;
      }, {});
    },
    showToggle() {
      return this.activeCategory?.items?.length > 0 && this.activeCategoryFinishedItems.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
.category-grid {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  &.with-toggle-section{
    grid-template-rows: 1fr 48px;
  }
}
.categories-box{
  overflow: auto;
}
.category-footer{
  border-top: 1px solid #ccc;
  background: #fff;
}
</style>
