<template>
  <component :is="indexComponent" />
</template>

<script>
import LegacyIndex from "@/views/Students/LegacyIndex.vue";
import IndexV2 from "@/views/Students/IndexV2.vue";
import user from "@/utils/mixins/user";

export default {
  name: "StudentsIndexEntrypoint",
  mixins: [user],
  components: {
    LegacyIndex, IndexV2
  },
  data() {
    return {
      legacyIndexPreferred: false
    }
  },
  mounted() {
    const storedValue = localStorage.getItem('legacyStudentDirectoryPreferred');
    this.legacyIndexPreferred = storedValue !== null ? storedValue === 'true' : false;
  },
  computed: {
    shouldUseLegacy () {
      return ! this.isSchoolAdministrator || this.$vuetify.breakpoint.mdAndDown || this.legacyIndexPreferred;
    },
    indexComponent () {
      return this.shouldUseLegacy ? LegacyIndex : IndexV2
    }
  },
};
</script>

<style lang="scss"></style>
