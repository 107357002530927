<template>
  <v-card>
    <VehicleToolBar title="vehicleExpenses" activeTab="expenses">
      <v-btn color="primary" text @click="$refs.vehicleExpensesDialog.open($route.params.id)">
        <v-icon>mdi-plus</v-icon>
        {{ $t("btn.add") }}
      </v-btn>
    </VehicleToolBar>

    <v-card-text>
      <v-skeleton-loader v-if="loading" type="table" />
      <template v-else>
        <v-row class="mt-1">
          <v-col cols="12" lg="3">
            <v-dialog
                ref="dialog"
                v-model="dateDialog"
                :return-value.sync="date"
                persistent
                width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    solo
                    dense
                    readonly
                    :value="formattedDates"
                    :label="$t('label.filter_by_date')"
                    prepend-inner-icon="mdi-calendar"
                    clearable
                    @click:clear="date = []"
                    v-bind="attrs"
                    v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker v-model="date" scrollable range>
                <v-spacer/>
                <v-btn text color="primary" @click="dateDialog = false">{{ $t("btn.cancel") }}</v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(date.sort())"
                >
                  {{ $t("btn.ok") }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" lg="4">
            <v-select
                v-model="filters.serviceAppointmentId"
                :items="serviceAppointments"
                :label="$t('label.filter_by_service_appointment')"
                :placeholder="$t('label.filter_by_service_appointment')"
                :menu-props="{ contentClass: 'fit-parent-width' }"
                solo
                clearable
                dense
                item-value="id"
            >
              <template v-slot:selection="{ item }">
                <span>{{ item.timeLabel }}</span>
              </template>

              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.timeLabel }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.notes }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" lg="4">
            <v-select
                v-model="filters.types"
                :items="types"
                :label="$t('label.filter_by_type')"
                solo
                dense
                multiple
                clearable
            />
          </v-col>
          <v-col cols="12" lg="1">
            <v-btn text color="primary" @click="filter" :loading="loading">{{ $t("btn.filter_results") }}</v-btn>
          </v-col>
        </v-row>

        <empty-box v-if="! expenses.length" />
        <v-simple-table v-else dense>
          <thead>
          <tr>
            <th class="text-left" style="width: 125px;">{{ $t("label.date") }}</th>
            <th class="text-left">{{ $t("label.type") }}</th>
            <th class="text-left">{{ $t("label.description") }}</th>
            <th class="text-left">{{ $t("label.amount") }}</th>
            <th style="width: 165px;text-align: right">{{ $t("label.actions") }}</th>
          </tr>
          </thead>
          <tbody>
          <tr
            class="table-row"
            v-for="expense in sortedExpenses"
            :key="expense.id"
          >
            <td>
              {{ expense.date | dateFormat("dd.MM.yyyy") }}
              <v-icon v-if="expense.vehicleServiceId" color="primary" small>mdi-calendar</v-icon>
            </td>
            <td>{{ $t("vehicleExpenseType." + expense.type) }}</td>
            <td>{{ expense.description }}</td>
            <td>{{ expense.amount | currency }}</td>
            <td style="text-align: right">
              <v-progress-linear indeterminate color="primary" v-if="[editing, deleting].includes(expense.id)" />

              <template v-else>
                <v-chip
                  :disabled="editing !== null"
                  @click.stop="edit(expense.id)"
                  class="hidden-until-row-hovered mx-1"
                  link
                  label
                  x-small
                  :loading="editing === expense.id">
                  <v-icon x-small>mdi-pencil</v-icon>
                </v-chip>

                <v-chip
                  :disabled="deleting !== null"
                  class="hidden-until-row-hovered mx-1"
                  link
                  label
                  x-small
                  dark
                  color="red"
                  @click.stop="destroy(expense.id)"
                >
                  <v-icon x-small>mdi-close</v-icon>
                </v-chip>
              </template>
            </td>
          </tr>
          </tbody>
          <tfoot v-if="expenses.length > 1">
            <tr>
              <td colspan="3" class="text-right font-weight-bold">{{$t('label.total')}}</td>
              <td class="font-weight-bold">{{ totalAmount | currency }}</td>
              <td></td>
            </tr>
          </tfoot>
        </v-simple-table>
      </template>
    </v-card-text>

    <VehicleExpensesDialog ref="vehicleExpensesDialog" @updated="updateExpense" />
  </v-card>
</template>

<script>

import VehicleExpensesDialog from "@/components/vehicle/VehicleExpensesDialog.vue";
import vehiclesService from "@/services/vehiclesService";
import { showConfirm } from "@/helpers/dialogs";
import { EventBus } from "@/EventBus";
import { compareAsc, format, isSameDay, parseISO } from 'date-fns'
import currency from '@/utils/mixins/currency'
import formattedDatesMixin from '@/utils/mixins/formattedDatesMixin'
import vehicleService from '@/services/vehiclesService'
import VehicleToolBar from '@/components/vehicle/VehicleToolBar.vue'

export default {
  name: "VehicleExpenses",
  components: { VehicleToolBar, VehicleExpensesDialog },
  mixins: [currency, formattedDatesMixin],
  data() {
    return {
      loading: false,
      dateDialog: false,
      expenses: [],
      editing: null,
      deleting: null,
      filters: {
        serviceAppointmentId: null,
        types: null,
        dates: null
      },
      serviceAppointments: []
    };
  },
  computed: {
    types() {
      const types = [
        "MAINTENANCE_AND_SERVICING",
        "INSURANCE_PREMIUMS",
        "REGISTRATION_FEES",
        "ANNUAL_TAX",
        "INSPECTION_FEES",
        "WIPER_REPLACEMENT",
        "CAR_WASH_DETAILING",
        "PARKING_FEES_TOLLS",
        "DEPRECIATION_AMORTIZATION",
        "LEASING_FINANCING",
        "EMERGENCY_ROADSIDE_ASSIST",
        "UPGRADES_MODIFICATIONS",
        "SPARE_PARTS_STORAGE",
        "OTHER"
      ];

      return types.map(type => {
        return {
          value: type,
          text: this.$t("vehicleExpenseType." + type)
        };
      });
    },
    sortedExpenses() {
      const expenses = [...this.expenses];

      return expenses.sort((first, second) => compareAsc(parseISO(first.date), parseISO(second.date)));
    },
    totalAmount () {
      return this.expenses.reduce((sum, item) => sum + item.amount, 0)
    }
  },
  watch: {
    date: function (value) {
      this.filters.dates = value
    }
  },
  created() {
    EventBus.$on("expense-created", this.addExpense);
  },
  mounted() {
    this.loadExpenses()

    this.loadingServiceAppointments = true;
    vehicleService
      .loadServices(this.$route.params.id)
      .then(response => {
        this.serviceAppointments = (response.data || []).map(item => {
          return { ...item, timeLabel: this.appointmentTimeLabel(item.labelFrom, item.labelTo) };
        });
      })
      .finally(() => {
        this.loadingServiceAppointments = false;
      });
  },
  destroyed() {
    EventBus.$off("expense-created", this.addExpense);
  },
  methods: {
    appointmentTimeLabel(from, to) {
      const parsedISOFrom = parseISO(from);
      const parsedISOTo = parseISO(to);

      if (isSameDay(parsedISOFrom, parsedISOTo)) {
        const date = format(parsedISOFrom, "dd.MM.yyyy");
        const fromTime = format(parsedISOFrom, "HH:mm");
        const toTime = format(parsedISOTo, "HH:mm");

        return date + " " + fromTime + " - " + toTime;
      }

      return from + " - " + to;
    },
    loadExpenses (params) {
      this.loading = true;
      vehiclesService.loadExpenses(this.$route.params.id, params)
        .then(response => {
          this.expenses = response.data || [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    edit(id) {
      this.$refs.vehicleExpensesDialog.loading = true;
      this.$refs.vehicleExpensesDialog.open(this.$route.params.id);
      vehiclesService
        .getExpense(this.$route.params.id, id)
        .then(response => {
          this.$refs.vehicleExpensesDialog.initExpenseData(response.data);
        })
        .finally(() => {
          this.$refs.vehicleExpensesDialog.loading = false;
        });
    },
    destroy(id) {
      showConfirm(
        this.$t("btn.confirm"),
        this.$t("messages.are_your_sure_delete"),
        () => {
          this.deleting = true;
          vehiclesService
            .deleteExpense(this.$route.params.id, id)
            .then(() => {
              this.expenses = this.expenses.filter(item => item.id !== id);
              EventBus.$emit("expense-deleted");
            })
            .finally(() => {
              this.deleting = null;
            });
        }
      );
    },
    filter() {
      this.loadExpenses(this.filters)
    },
    addExpense(expense) {
      expense.amount = parseFloat(expense.amount)
      this.expenses.push(expense);
    },
    updateExpense(expense) {
      const index = this.expenses.findIndex(item => item.id === expense.id);
      this.$set(this.expenses, index, expense);
    }
  }
};
</script>

<style scoped>

</style>
