<template>
  <div :class="`work-time ${!mdLandscapeAndUp ? 'mobile' : ''}`">
    <span :class="`time ${drivingTime > school.teacherDrivingWorkTimePerDay ? 'overtime' : ''}`">
      {{ drivingTime }}
    </span>
    /
    <span :class="`time ${overallTime > school.teacherOverallWorkTimePerDay ? 'overtime' : ''}`">
      {{ overallTime }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import responsive from "@/utils/mixins/responsive";
import AppointmentModel from '@/store/models/AppointmentModel'

export default {
  mixins: [responsive],
  name: "WorkingHoursDaily",
  props: {
    day: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('school', ['school']),
    ...mapGetters(["days", "hours", "loaderGetter"]),
    ...mapGetters("appointments", ["draggedEventGetter"]),
    appointments () {
      return AppointmentModel.query().where("startDate", this.day.originalDate).get()
    },
    drivingTime () {
      return this.getDurationSum(this.appointments.filter(item => item.isDrivingWorkingTime))
    },
    overallTime () {
      return this.getDurationSum(this.appointments.filter(item => item.isOverallWorkingTime))
    }
  },
  methods: {
    getDurationSum(appointments = []) {
      return appointments.reduce((sum, appointment) => sum + (appointment.duration || 0), 0)
    }
  }
};
</script>

<style lang="scss" scoped>
.work-time {
  border-top: var(--default-border);
  text-align: center;
  background: #fff;
  height: auto;
  width: 100%;
  background: rgba(243, 243, 243, 0.473);
  font-weight: bold;
  font-size: 0.8rem;
}
.time {
  width: 100%;
  text-align: center;
  color: #76ab26;
  &.overtime {
    color: red !important;
  }
}
</style>
