import { render, staticRenderFns } from "./InsightsCategory.vue?vue&type=template&id=4a8c1f99&scoped=true"
import script from "./InsightsCategory.vue?vue&type=script&lang=js"
export * from "./InsightsCategory.vue?vue&type=script&lang=js"
import style0 from "./InsightsCategory.vue?vue&type=style&index=0&id=4a8c1f99&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a8c1f99",
  null
  
)

export default component.exports