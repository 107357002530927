<template>
  <div v-html="strGraph.render()" />
</template>

<script>
import CalendarGraph from "../plugins/calendar-graph-str";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    colorFun: {
      type: Function,
      default: () => {},
    },
    startDate: {
      type: Date,
    },
    endDate: {
      type: Date,
    },
    size: {
      type: [Number, String],
    },
    space: {
      type: [Number, String],
    },
    padX: {
      type: [Number, String],
    },
    padY: {
      type: [Number, String],
    },
    styleOptions: {
      type: Object,
      default: () => ({
        textColor: "#959494",
        fontSize: "12px",
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
      }),
    },
  },
  name: "CalendarGraph",
  computed: {
    strGraph() {
      return new CalendarGraph(this.data, {
        startDate: this.startDate,
        endDate: this.endDate,
        colorFun: this.colorFun,
        size: this.size,
        space: this.space,
        padX: this.padX,
        padY: this.padY,
        styleOptions: this.styleOptions,
      });
    },
  },
};
</script>

<style scoped />
