<template>
  <div>{{ appointment.students }}</div>
</template>

<script>
export default {
  name: "SimulatorCard",
  props: {
    appointment: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style scoped>

</style>