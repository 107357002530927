<template>
  <v-card color="primary" dark height="141">
    <v-card-title class="d-flex justify-space-between py-2">{{
      $t("label.students")
    }}</v-card-title>
    <v-divider />
    <v-card-text class="d-flex justify-space-between white--text">
      <router-link
        class="d-flex flex-column flex-grow-1 white--text text-decoration-none"
        :to="{
          name: 'students',
          query: { filters: this.formatFilters({ activityStatus: 'active' }) },
          params: { clear: true }
        }"
        style="flex-basis: 0">
        <span class="text-caption">{{ $t("label.active") }}</span>
        <v-skeleton-loader
          type="image"
          class="mt-3 mb-3"
          width="40"
          height="15"
          v-if="loading" />
        <span class="text-h4" v-else>{{ active || "—" }}</span>
      </router-link>
      <router-link
        class="d-flex flex-column flex-grow-1 white--text text-decoration-none"
        :to="{
           name: 'students',
           query: { filters: this.formatFilters({ activityStatus: 'active', noBalance: true }) },
           params: { clear: true }
        }"
        style="flex-basis: 0">
        <span class="text-caption">{{ $t("label.no_balance") }}</span>
        <v-skeleton-loader
          type="image"
          class="mt-3 mb-3"
          width="40"
          height="15"
          v-if="loading" />
        <span class="text-h4" v-else>{{ noBalance || "—" }}</span>
      </router-link>
      <router-link
        class="d-flex flex-column flex-grow-1 white--text text-decoration-none"
        :to="{
          name: 'students',
          params: { clear: true }
        }"
        style="flex-basis: 0">
        <span class="text-caption">{{ $t("label.total") }}</span>
        <v-skeleton-loader
          type="image"
          class="mt-3 mb-3"
          width="40"
          height="15"
          v-if="loading" />
        <span class="text-h4" v-else>{{ total || "—" }}</span>
      </router-link>
    </v-card-text>
  </v-card>
</template>

<script>
import schoolService from "@/services/schoolService";
import listFilters from "@/utils/mixins/listFilters";

export default {
  name: "StudentsCount",
  mixins: [listFilters],
  data() {
    return {
      loading: true,
      total: null,
      active: null,
      noBalance: null,
    };
  },
  mounted() {
    schoolService
      .studentsStats()
      .then((response) => {
        const studentsStats = response.data || {};

        this.total = studentsStats.totalCount;
        this.active = studentsStats.activeCount;
        this.noBalance = studentsStats.noBalanceCount;
      })
      .catch(console.log)
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped></style>
