<template>
  <v-dialog v-model="show" width="600" persistent>
    <v-card>
      <v-toolbar class="mb-2" dark color="primary" dense>
        <v-toolbar-title>{{$t("label.buy_learning_materials")}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-5">
        <v-row dense>
          <v-col>
            <v-select
              @change="licenseChange"
              :label="$t('form.licence')"
              v-model="form.licenseId"
              :items="licenses"
              item-value="id"
              item-text="name"
              solo
              hide-details
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-select
              @change="productChange"
              :label="$t('label.choose_product')"
              v-model="form.productId"
              :items="products"
              item-value="id"
              item-text="name"
              :disabled="!form.licenseId"
              solo
              hide-details />
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center align-center" dense>
          <v-col cols="12">
            <v-alert dense color="blue" outlined>{{ productPrice | currency }}</v-alert>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn text @click="close" :disabled="loadingForm">
          {{ $t("btn.cancel") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="submitPurchase" :loading="loadingForm" :disabled="loadingForm">
          {{ $t("btn.ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LicenseModel from "@/store/models/LicenseModel";
import LearningMaterialModel from "@/store/models/LearningMaterialModel";
import studentsService from "@/services/studentsService";
import StudentModel from "@/store/models/StudentModel";
import learningMaterialsService from "@/services/learningMaterialsService";

export default {
  name: "ProductPriceCalculator",
  data() {
    return {
      show: false,
      form: {
        license: null,
        productId: null,
        amount: 1,
        duration: 45,
      },
      loadingForm: false
    };
  },
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    learningMaterialsService
      .load()
      .then((response) => {
        LearningMaterialModel.create({ data: response.data });
      })
      .catch((error) => console.log(error));
  },
  computed: {
    licenses() {
      return LicenseModel.findIn(this.student.licensesIds) || {};
    },
    products() {
      return (
        LearningMaterialModel.query()
          .where(
            (product) =>
              this.student.licensesIds.includes(product.licenseId) &&
              product.priceCategoryId === this.student.priceCategoryId &&
              product.licenseId === this.form.licenseId
          )
          .get() || []
      );
    },
    productPrice() {
      const product = LearningMaterialModel.find(this.form.productId) || {};
      return product.price;
    },
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
      this.form.licenseId = null;
      this.form.productId = null;
    },
    licenseChange(e) {
      this.form.licenseId = e;
      this.form.productId = null;
    },
    productChange(e) {
      this.form.productId = e;
    },
    submitPurchase() {
      this.loadingForm = true;
      studentsService
        .buyLearningMaterials(this.student.id, {
          learningMaterialId: this.form.productId,
        })
        .then((response) => {
          const responseData = response.data || {};
          const { balance, availableBalance } = responseData;

          StudentModel.update({
            where: this.student.id,
            data: { balance, availableBalance },
          }).then(() => {
            this.form = { licenseId: null, productId: null };
            this.$emit("confirm");
            this.close();
          });
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loadingForm = false;
        });
    },
  },
};
</script>
