<template>
  <div>
    {{ $t('event_type.SPECIAL_TYPES.' + appointment.specialType) }}, {{ appointment.specialName }}
  </div>
</template>

<script>
export default {
  name: "SpecialCard",
  props: {
    appointment: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style scoped>

</style>
