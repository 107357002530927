import { Model } from "@vuex-orm/core";

export default class PaymentModel extends Model {
  static entity = "transactions";

  static fields() {
    return {
      id: this.attr(null),
      amount: this.number(0),
      fee: this.number(0),
      payment: this.attr({}),
      user: this.string(""),
      client_name: this.string(''),
      paymentIntent: this.string(""),
      licenses: this.string(''),
      type: this.string(""),
      refunded: this.boolean(false),
      created: this.string(""),
    };
  }

  get clientName() {
    return this.client_name !== "null"
      ? this.client_name
      : (this.user !== "null"
            ? this.user
            : "Keine Informationen");
  }
}
