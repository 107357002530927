import {
  addDays,
  eachDayOfInterval,
  endOfWeek,
  format,
  isBefore,
  isSameDay,
  isSunday,
  parseISO,
  startOfWeek,
} from 'date-fns'
import HolidaysClass from "@/helpers/holiday";
import LocalizationManager from "@/helpers/LocalizationManager";

export default class DateRange {
  constructor(startDate) {
    this.startDate = parseISO(startDate);
    this.today = new Date();
  }

  range(layout = null) {
    switch (layout) {
      case "LAYOUT_DAY":
        return this.generateDay();
      case "LAYOUT_THREE_DAYS":
        return this.generateThreeDays();
      case "LAYOUT_WEEK":
      default:
        return this.generateWeek();
    }
  }

  rangeFromTo(layout = null) {
    return {
      from: this.range(layout)[0],
      to: this.range(layout).at(-1),
    }
  }

  generateDay() {
    const day = this.startDate;
    return eachDayOfInterval({ start: day, end: day });
  }

  generateThreeDays() {
    const range = {};
    range.start = addDays(this.startDate, 0);
    range.end = addDays(this.startDate, 2);
    return eachDayOfInterval(range);
  }

  generateWeek() {
    const weekStartDate = startOfWeek(this.startDate, { weekStartsOn: 1 });
    const weekEndDate = endOfWeek(this.startDate, { weekStartsOn: 1 });

    return eachDayOfInterval({ start: weekStartDate, end: weekEndDate });
  }

  dates(layout = null, regions = ["BUND"]) {
    return this.range(layout)
      .filter(this.filterDays)
      .map((day) => {
        return {
          date: format(day, "dd", { locale: LocalizationManager.dateFnsLocale }),
          month: format(day, "MM", { locale: LocalizationManager.dateFnsLocale }),
          dayName: format(day, "EE", { locale: LocalizationManager.dateFnsLocale }),
          class: this.cssClass(day, regions),
          selectedDate: isSameDay(day, this.startDate),
          originalDate: format(day, "yyyy-MM-dd"),
          holidayName: new HolidaysClass().getHoliday(day, regions),
        };
      });
  }

  filterDays(date) {
    return date;
  }

  cssClass(date, regions = ["BUND"]) {
    if (this.isHoliday(date, regions) && isBefore(date, this.today))
      return "past-day holiday";
    if (this.isHoliday(date, regions)) return "holiday";
    if (isSameDay(date, this.today)) return "today";
    if (isBefore(date, this.today)) return "past-day";

    return "";
  }

  isHoliday(date, regions = ["BUND"]) {
    return isSunday(date) || new HolidaysClass().isHoliday(date, regions);
  }
}
