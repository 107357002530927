<script>
import EvaluationItem from '@/components/insights/EvaluationItem.vue'

export default {
  name: 'EvaluationItemsGroup',
  components: {EvaluationItem},
  props: {
    items: {
      type: Array,
    },
    groupTitle: {
      type: String,
      default: null
    }
  },
  computed: {
    showGroupTitle() {
      return this.items.length > 1
    }
  }
}
</script>

<template>
  <div>
    <span v-if="showGroupTitle">{{ groupTitle }}</span>
    <div class="items-grid">
      <EvaluationItem
        class="evaluation-item"
        :class="{'disabled': item.isFinished}"
        :show-item-name="! showGroupTitle"
        v-for="item in items"
        :item="item"
        :key="item.id"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.items-grid {
  grid-gap: .5em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  padding: 0;
}

</style>
