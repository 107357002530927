import store from "@/store";
import {Device} from "@capacitor/device";

export const isNotAuthenticated = (to, from, next) => {
  if (!store.state.auth.authToken) {
    next();
    return;
  }
  next("/dashboard");
};

export const isNotAuthenticatedAndWebOnly = async (to, from, next) => {
  const platform = (await Device.getInfo()).platform
  if (!store.state.auth.authToken && platform === 'web') {
    next();
    return;
  }
  next("/login");
};

export const isAuthenticated = (to, from, next) => {
  if (!store.state.auth.authToken) {
    next("/login");
    return;
  }

  const authUser = store.getters["auth/currentUser"];
  const school = store.state.school;
  const isStudent = authUser.type === "student";
  const isElter = authUser.type === "elter";
  const verifiedElter = isElter && authUser.emailVerifiedAt;
  const hasChildSelected = store.state.auth.parentSelectedStudentId;
  const parentAuthorizedRoutes= ['children-accounts', 'personal-settings', 'personal-settings.profile', 'personal-settings.password', 'patch-notes']
  const routesWithRedirectByStatus = ['account-activation', 'accept-privacy-policy', 'inactive.account', 'personal-settings.password']

  // This middleware is used for multiple routes, in case 2 different routes fail at the same time, middleware is called recursively, so we need a flag to prevent that
  if (!routesWithRedirectByStatus.includes(to.name)) {
    // Check if the user is a student, and he did not accept the school's privacy policy
    if (isStudent && !authUser.privacyPolicyAccepted) {
      next('accept-privacy-policy');
      return;
    }

    // Check if auth user is not a school administrator and if he has profile activated
    if ((isStudent || isElter) && !authUser.emailVerifiedAt) {
      next('account-activation');
      return;
    }

    // Check whether user is active
    if (!authUser.active && !(isElter && parentAuthorizedRoutes.includes(to.name))) {
      next('inactive-account');
      return;
    }

    // Check if current user has to change password
    if (authUser.isOneTimePassword) {
      next('personal-settings/change-password');
      return;
    }
  }

  if (!parentAuthorizedRoutes.includes(to.name) && verifiedElter && !hasChildSelected) {
    next('/children-accounts')
    return
  }

  if (to.name === "accept-privacy-policy" && isStudent && authUser.privacyPolicyAccepted) {
    next("/dashboard");
    return;
  }

  if (to.name === "children-accounts" && verifiedElter && hasChildSelected) {
    next("/dashboard");
    return;
  }

  if (to.name === "happy-hours" && !school.hasHappyHours) {
    next('/dashboard');
    return;
  }

  if (to.name === "click-and-learn" && (!school.clickAndLearnApiKey || !school.hasClickAndLearn)) {
    next("/dashboard");
    return;
  }

  if (to.name === "school-settings.clicklearn" && !school.hasClickAndLearn) {
    next("/dashboard");
    return;
  }

  if (to.name === "balance" && !authUser.hasPaymentAbility) {
    next("/dashboard");
    return;
  }

  if (["invoices", "invoices.view"].includes(to.name) && !school.isInvoicingEnabled) {
    next("/dashboard");
    return;
  }

  // Check whether user type has ability to access this page
  const userTypes = to.meta.hasUserTypes;
  const isElterWithSelectedStudent = userTypes && userTypes.includes("student") && isElter && hasChildSelected;
  if (userTypes && userTypes.indexOf(authUser.type) === -1 && !isElterWithSelectedStudent) {
    next("/dashboard");
    return;
  }

  next();
};
