<template>
  <v-card>
    <StudentTabToolBar activeTab="documents" />

    <v-card-text class="px-0">
      <v-card elevation="0">
        <v-card-title class="mb-3 d-flex justify-space-between">
          <span class="title">{{ $t("label.documents_list") }}</span>
          <v-btn :disabled="loadingDocuments" color="primary" small @click="$refs.fileUploadDialog.open()">
            {{ $t("btn.new_document") }}
          </v-btn>
        </v-card-title>

        <StudentDocumentsList
          :documents="documents"
          :loading="loadingDocuments"
          @delete="removeFromList"
          @downloaded="markAsPreviewed"
          @signed="markAsSigned"
        />
      </v-card>

      <v-card
        elevation="0"
        v-if="$store.state.school.isInvoicingEnabled"
        class="mt-3">
        <v-card-title>
          {{ $t("label.invoices") }}
        </v-card-title>
        <template v-if="loadingInvoices">
          <v-skeleton-loader class="mx-auto" type="list-item@3" />
        </template>
        <template v-else>
          <StudentInvoicesList v-if="invoices.length" :open-invoices="openInvoices" :other-invoices="otherInvoices" />
          <empty-box v-else />
        </template>
      </v-card>

      <file-upload-dialog ref="fileUploadDialog" :student-id="$route.params.id" @fileUploaded="addToList" />
    </v-card-text>
  </v-card>
</template>

<script>
import StudentTabToolBar from "@/components/student/StudentTabToolBar.vue";
import studentsService from "@/services/studentsService";
import StudentModel from "@/store/models/StudentModel";
import StudentDocumentsList from "@/components/student/StudentDocumentsList.vue";
import FileUploadDialog from "@/components/FileUploadDialog.vue";
import StudentInvoicesList from "@/components/student/StudentInvoicesList.vue";

export default {
  name: "StudentDocuments",
  components: { StudentInvoicesList, FileUploadDialog, StudentDocumentsList, StudentTabToolBar },
  data() {
    return {
      documents: [],
      invoices: [],
      loadingInvoices: true,
      loadingDocuments: true
    };
  },
  beforeRouteEnter(from, to, next) {
    const student = StudentModel.find(from.params.id);

    if (student && student.isDeleted) {
      next({ name: "students.student", params: { id: from.params.id } });
      return;
    }

    next();
  },
  computed: {
    openInvoices() {
      return this.invoices.filter(invoice => !invoice.isPaid && !invoice.isCanceled);
    },
    otherInvoices() {
      return this.invoices.filter(invoice => invoice.isPaid || invoice.isCanceled);
    }
  },
  mounted() {
    studentsService
      .documents(this.$route.params.id)
      .then((documentResponse) => {
        this.documents = documentResponse.data || [];
      })
      .finally(() => {
        this.loadingDocuments = false;
      });

    studentsService
      .invoices(this.$route.params.id)
      .then((resp) => {
        this.invoices = resp.data;
      })
      .finally(() => (this.loadingInvoices = false));
  },
  methods: {
    addToList(file) {
      if (!file) return;
      this.documents.push(file);
    },
    removeFromList(id) {
      this.documents = this.documents.filter(item => item.id !== id);
    },
    markAsPreviewed(id) {
      this.documents = this.documents.map(obj => obj.id === id ? { ...obj, isPreviewed: true } : obj);
    },
    markAsSigned(id) {
      this.documents = this.documents.map(obj =>
        obj.id === id ? { ...obj, isSignedByStudent: true, requiresStudentSignature: false } : obj
      );
    }
  }
};
</script>

<style scoped></style>
