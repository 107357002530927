<template>
<v-simple-table dense>
  <template v-slot:default>
    <thead>
      <tr>
        <th style="width: 160px">{{ $t("list.licenses") }}</th>
        <th class="text-center">{{ $t("label.lesson_types") }}</th>
        <th class="text-center">{{ $t("label.days") }}</th>
        <th class="text-center">{{ $t("time") }}</th>
        <th class="text-center">{{ $t("form.price") }}</th>
        <th style="width: 70px"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in happyHours" :key="item.name" class="table-row">
        <td>{{ licenseName(item.licenseId) }}</td>
        <td class="text-center">{{ lessonTypeName(item.lessonTypeId) }}</td>
        <td class="text-center">{{ `${daysLine(item.days)}` }}</td>
        <td class="text-center">{{ `${item.timeFrom} - ${item.timeTo}` }}</td>
        <td class="text-center font-weight-bold">
          {{ item.discount | currency }}
        </td>
        <td>
          <v-chip
            class="hidden-until-row-hovered"
            link
            label
            x-small
            @click="deleteHappyHour(item.id)">
            <v-icon x-small>mdi-close</v-icon>
          </v-chip>
        </td>
      </tr>
    </tbody>
  </template>
</v-simple-table>
</template>

<script>
import LessonTypeModel from "@/store/models/LessonTypeModel";
import happyHoursService from "@/services/happyHoursService";
import HappyHoursModel from "@/store/models/HappyHoursModel";
import LicenseModel from "@/store/models/LicenseModel";
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: "HappyHoursList",
  data: () => ({
    currentLesson: null,
  }),
  props: {
    happyHours: {
      type: Array,
      required: true,
    }
  },
  computed: {},
  methods: {
    licenseName(val) {
      const license = LicenseModel.find(val) || {};
      return license.name;
    },
    lessonTypeName(val) {
      const type = LessonTypeModel.find(val) || {};
      return this.$tc(type.name, 1);
    },
    daysLine(val) {
      return val.map((day) => this.$t(`days.${day}`));
    },
    deleteHappyHour(id) {
      showConfirm(
        this.$t('btn.confirm'),
        this.$t('messages.are_your_sure_delete'),
        () => {
          happyHoursService
            .destroy(id)
            .then(() => HappyHoursModel.delete(id))
            .catch((error) => console.log(error));
        }
      )
    },
  },
};
</script>

<style scoped lang="scss">
.lt {
  font-weight: bold;
}
.hidden-until-row-hovered {
  display: none;
}
.table-row:hover > td > .hidden-until-row-hovered {
  display: inline-flex;
}
</style>
