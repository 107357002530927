<script>
import {defineComponent} from 'vue'
import clickAndLearnService from "@/services/clickAndLearnService";

export default defineComponent({
  name: "BuyClickAndLearnCodesDialog",
  data() {
    return {
      dialog: false,
      loading: false,
      form: {
        licenseCodesAmount: 0,
        renewalCodesAmount: 0,
      },
    }
  },
  methods: {
    open() {
      this.form.renewalCodesAmount = 0;
      this.form.licenseCodesAmount = 0;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$refs.form.reset();
    },
    buy() {
      this.loading = true;
      clickAndLearnService
        .placeOrder(this.form)
        .then(response => {
          this.$emit('order-placed', response.data)
          this.close()
          this.$snackbar.show({ message: this.$t('clickandlearn.order_placed'), color: "success", timeout: 3000 })
        })
        .catch((error) => {
          this.$refs.form.reset()

          const responseData = error.response.data || {};
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message)
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });

          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    buyDisabled() {
      return parseInt(this.form.licenseCodesAmount) === 0 && parseInt(this.form.renewalCodesAmount) === 0;
    }
  }
})
</script>

<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-toolbar class="mb-4" dark color="primary" dense>
        <v-toolbar-title>{{ $t('clickandlearn.buy_more_codes') }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <p>
          {{ $t('clickandlearn.buy_more_codes_intro') }}
        </p>
        <ValidationObserver ref="form">
          <v-row>
          <v-col cols="12" md="6">
            <ValidationProvider v-slot="{ errors }" name="licenseCodesAmount">
              <v-text-field
                outlined
                :label="$t('clickandlearn.license_codes')"
                v-model="form.licenseCodesAmount"
                dense
                :error-messages="errors"
                :hide-details="errors.length === 0"
                type="number"
                min="0"
                step="5"
                pattern="[0-9]+"
                @focus="parseInt(form.licenseCodesAmount) === 0 ? form.licenseCodesAmount = null : ''"
                @blur="form.licenseCodesAmount === null ? form.licenseCodesAmount = 0 : ''"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider v-slot="{ errors }" name="renewalCodesAmount">
              <v-text-field
                outlined
                :label="$t('clickandlearn.renewal_codes')"
                v-model="form.renewalCodesAmount"
                dense
                :error-messages="errors"
                :hide-details="errors.length === 0"
                type="number"
                min="0"
                step="5"
                pattern="[0-9]+"
                @focus="parseInt(form.renewalCodesAmount) === 0 ? form.renewalCodesAmount = null : ''"
                @blur="form.renewalCodesAmount === null ? form.renewalCodesAmount = 0 : ''"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        </ValidationObserver>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-btn text @click="close">{{ $t("btn.close") }}</v-btn>
        <v-spacer />
        <v-btn color="primary" @click="buy" :disabled="buyDisabled || loading" :loading="loading">
          <v-icon left>mdi-cart-arrow-up</v-icon>
          {{ $t("btn.place_order") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>
