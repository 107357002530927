<template>
<div class="py-2 d-flex align-center justify-center">
  <v-img :src="hasMinimizedLogo ? miniLogo : logo" :class="{'app-logo': !hasMinimizedLogo}" contain height="32"/>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SidebarAppLogo',
  data() {
    return {
      logo: require("@/assets/svg/book-and-pay.svg"),
      miniLogo: require("@/assets/svg/bookandpay_new_logo.svg"),
    }
  },
  computed: {
    ...mapState("main", ["isSidebarMinimized"]),
    hasMinimizedLogo() {
      return (this.isSidebarMinimized && !this.$vuetify.breakpoint.mobile)
    }
  }
}
</script>

<style scoped lang="scss">
.app-logo {
  height: 55px;
}
</style>
