import { render, staticRenderFns } from "./StudentsLabel.vue?vue&type=template&id=c1e84ed6&scoped=true"
import script from "./StudentsLabel.vue?vue&type=script&lang=js"
export * from "./StudentsLabel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1e84ed6",
  null
  
)

export default component.exports