<script>
import parentService from "@/services/parentService"
import user from "@/utils/mixins/user"
import ParentModel from '@/store/models/ParentModel'

export default {
  name: "LinkParentAccountDialog",
  mixins: [user],
  data: () => ({
    show: false,
    loading: false,
    code: null,
    codeError: false,
    errorMessages: '',
    policyAccepted: false,
  }),
  computed: {
    is18yo() {
      return this.currentUser.is18yo;
    },
  },
  methods: {
    open() {
      this.show = true
      this.codeError = false
      this.errorMessages = ''
      this.policyAccepted = false
    },
    async close() {
      this.show = false
      this.code = null
    },
    onFinish(val) {
      this.linkByCode(val)
    },
    async linkByCode(code) {
      this.loading = true;
      await parentService
        .linkParentToStudent({
          code,
          grantedPermission: this.policyAccepted
        })
        .then((resp) => {
          ParentModel.insert({ data: resp.data }).then(() => this.close())
        })
        .catch((err) => {
          this.errorMessages = err.response.data.message
          this.codeError = true;
          this.code = null;
        })
        .finally(() => {
          this.loading = false
        });
    }
  },
}
</script>

<template>
  <v-dialog width="500" :value="show" persistent>
    <v-card>
      <v-card-title primary-title>
        <v-toolbar-title>{{ $t('label.link_parent') }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-subtitle v-if="!loading">
        {{ $t("messages.explanation_how_to_link_a_parent") }}
        <v-checkbox
          v-if="is18yo"
          class="mt-3"
          v-model="policyAccepted"
          :label="$t('validation.fields.confirm_observation')"
          hide-details
        />
      </v-card-subtitle>
      <v-card-text>
        <div class="ma-auto position-relative" style="max-width: 300px">
          <v-otp-input
            v-model="code"
            type="number"
            inputmode="numeric"
            :length="6"
            :disabled="loading || (is18yo && !policyAccepted)"
            @finish="onFinish"
          />
          <v-overlay absolute :value="loading">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-overlay>
        </div>

        <v-alert
          v-if="codeError"
          class="mt-3"
          dense
          colored-border
          border="left"
          elevation="1"
          type="warning"
          icon="mdi-alert-outline"
        >
          {{ errorMessages }}
        </v-alert>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn text @click="close">{{ $t('btn.cancel') }}</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">

</style>
