<template>
  <v-card class="mx-auto mb-3" elevation="0" outlined>
    <v-card-title>{{ $t('label.theory_progress') }}</v-card-title>
    <v-card-subtitle>
      {{ totalProgress }}
    </v-card-subtitle>

    <v-card-text>
      <v-progress-linear
          :value="progress.theoryProgress"
          height="25"
          class="my-2"
          color="primary"
          dark
          rounded
      >
        {{ progress.theoryProgress }}%
      </v-progress-linear>

      <v-divider class="my-4"/>

      <div class="mb-3">
        <strong>{{ $t('label.normal') }}: </strong>
        <strong>
          {{ basicProgress }}
        </strong>
      </div>

      <div v-if="normalTopicsList.length" class="mb-6">
        <v-row v-for="topic in normalTopicsList" :key="topic.id" class="d-flex justify-space-between">
          <v-col class="ellipsis">
            <span class="topic-index">{{ $t('form.topic') + ' ' + topic.orderIndex }}:</span>
            {{ topic.name }}
          </v-col>
          <v-col v-if="topic.appointments" class="topic-status d-flex">
            <EducationTheoryPopup
              v-if="topic.appointments.length"
              :apps="topic.appointments"
              :last-visited="topic.isLastVisitedTopic"
            />
            <div v-else-if="isExactlyStudent && progress.theoriesNormalsShould">
              <v-icon
                color="primary"
                size="18"
                @click="openTheoryAttendanceShortcutDialog(topic)"
                :disabled="isStudentRegistered"
              >
                mdi-calendar-search
              </v-icon>
            </div>
            <v-icon class="ml-auto" :color="topic.appointments.length ? 'secondary' : 'red'">
              mdi-{{ topic.appointments.length ? 'check' : 'close' }}
            </v-icon>
          </v-col>
        </v-row>
      </div>

      <div class="mb-3">
        <strong>{{ $t('label.special') }}: </strong>
        <strong>
          {{ specialProgress }}
        </strong>
      </div>

      <div v-if="specialTopicsList.length">
        <v-row v-for="topic in specialTopicsList" :key="topic.id" class="d-flex justify-space-between">
          <v-col class="ellipsis">
            <span class="topic-index">{{ $t('form.topic') + ' ' + topic.orderIndex }}:</span>
            {{ topic.name }}
          </v-col>

          <v-col v-if="topic.appointments" class="topic-status d-flex">
            <EducationTheoryPopup
              v-if="topic.appointments.length"
              :apps="topic.appointments"
              :last-visited="topic.isLastVisitedTopic"
            />
            <div v-else-if="isExactlyStudent && progress.theoriesSpecialsShould">
              <v-icon
                  color="primary"
                  size="18"
                  @click="openTheoryAttendanceShortcutDialog(topic)"
                  :disabled="isStudentRegistered"
              >
                mdi-calendar-search
              </v-icon>
            </div>
            <v-icon class="ml-auto" :color="topic.appointments.length ? 'secondary' : 'red'">
              mdi-{{ topic.appointments.length ? 'check' : 'close' }}
            </v-icon>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <TheoryAttendanceShortcutDialog ref="theoryAttendanceShortcutDialog"/>
  </v-card>
</template>

<script>
import EducationTheoryPopup from '@/components/student/progress/EducationTheoryPopup'
import TheoryAttendanceShortcutDialog from "@/components/TheoryAttendanceShortcutDialog.vue";
import complexSort from '@/helpers/complexSort'
import user from "@/utils/mixins/user";

export default {
  name: 'EducationTheoryProgress',
  mixins: [user],
  components: {TheoryAttendanceShortcutDialog, EducationTheoryPopup },
  props: {
    progress: {
      type: Object,
      required: true,
    },
    licenseId: {
      type: [String, Number],
      required: true,
    },
  },
  methods: {
    openTheoryAttendanceShortcutDialog (topic) {
      this.$refs.theoryAttendanceShortcutDialog.open(topic, this.$route.params.id)
    }
  },
  computed: {
    // theoryIsRequired() {
    //   return this.progress.theoriesNormalsShould || this.progress.theoriesSpecialsShould
    // },
    normalTopicsList () {
      const topics = [...this.progress.theoryTopics.normals]
      return complexSort(topics, 'orderIndex')
    },
    specialTopicsList () {
      const topics = [... this.progress.theoryTopics.specials]
      return complexSort(topics, 'orderIndex')
    },

    basicProgress () {
      const {
        theoriesNormalsIs,
        theoriesNormalsShould
      } = this.progress
      return theoriesNormalsShould
          ? (theoriesNormalsIs + '/' + theoriesNormalsShould)
          : this.$t('label.not_required')
    },
    specialProgress () {
      const {
        theoriesSpecialsIs,
        theoriesSpecialsShould
      } = this.progress
      return theoriesSpecialsShould
          ? (theoriesSpecialsIs + '/' + theoriesSpecialsShould)
          : this.$t('label.not_required')
    },
    totalProgress () {
      const {
        theoriesNormalsIs,
        theoriesNormalsShould,
        theoriesSpecialsIs,
        theoriesSpecialsShould
      } = this.progress
      const shouldValue = theoriesNormalsShould + theoriesSpecialsShould
      const isValue = Math.min(theoriesNormalsIs, theoriesNormalsShould) +
          Math.min(theoriesSpecialsIs, theoriesSpecialsShould)
      return shouldValue
          ? (isValue + '/' + shouldValue)
          : this.$t('label.not_required')
    },

  }
}
</script>

<style scoped>
.ellipsis {
  width: 250px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.topic-index {
  display: inline-block;
  min-width: 20px
}

.topic-status {
  max-width: 74px;
  text-align: right
}

.disabled {
  opacity: .5;
}
</style>
