<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'personal-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>
        {{ $t("route.personalSettings.children.profile") }}
      </v-card-title>

      <v-spacer/>

      <v-btn
          text
          color="primary"
          @click="save"
          :loading="formLoading"
          :disabled="formLoading">
        {{ $t("btn.save") }}
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <ValidationObserver ref="form">
        <v-tabs v-model="activeTab">
          <v-tab href="#general">{{ $t("label.general") }}</v-tab>
          <v-tab href="#contacts">{{ $t("label.contact") }}</v-tab>
          <v-tab href="#address">{{ $t("label.address") }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="activeTab">
          <v-tab-item value="general">
            <v-row class="mt-3">
              <v-col cols="12" lg="9">
                <ValidationProvider v-slot="{ errors }" name="firstName">
                  <v-text-field
                    dense
                    v-model="form.firstName"
                    :label="$t('form.first_name')"
                    :placeholder="$t('form.first_name')"
                    outlined
                    :error-messages="errors"
                    required />
                </ValidationProvider>
                <ValidationProvider dense v-slot="{ errors }" name="lastName">
                  <v-text-field
                    dense
                    v-model="form.lastName"
                    :label="$t('form.last_name')"
                    :placeholder="$t('form.last_name')"
                    outlined
                    :error-messages="errors" />
                </ValidationProvider>
                <ValidationProvider
                  dense
                  v-slot="{ errors }"
                  name="placeOfBirth">
                  <v-text-field
                    dense
                    v-model="form.placeOfBirth"
                    :label="$t('form.place_of_birth')"
                    :placeholder="$t('form.place_of_birth')"
                    outlined
                    :error-messages="errors"></v-text-field>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" name="birthDate">
                  <v-menu
                    v-model="menuBirthDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        :value="birthDateFormat"
                        :label="$t('form.birthday')"
                        :placeholder="$t('form.birthday')"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errors"
                        clearable
                        @click:clear="form.birthDate = ''"></v-text-field>
                    </template>
                    <v-date-picker
                      :max="minDateOfBirthFromToday"
                      :value="form.birthDate"
                      ref="birthDatePicker"
                      @input="onInputBirthDate"
                      no-title />
                  </v-menu>
                </ValidationProvider>

                <v-select
                  :items="[
                    { text: this.$t('gender.M'), value: 'M' },
                    { text: this.$t('gender.W'), value: 'W' },
                    { text: this.$t('gender.D'), value: 'D' },
                  ]"
                  outlined
                  hide-details
                  dense
                  :placeholder="$t('label.gender')"
                  :label="$t('label.gender')"
                  v-model="form.gender"
                />
              </v-col>
              <v-col cols="12" lg="3">
                <UploadBtn
                  class="mb-2"
                  @file-picked="onFilePick"
                  accept="image/jpeg,image/png"
                  input-name="avatar"
                >
                  <UserAvatar class="elevation-3" size="150" :path="avatarImage" :alt="currentUser.fullName" />
                </UploadBtn>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item value="contacts">
            <v-row class="mt-3">
              <v-col cols="12" sm="6" md="12" lg="6">
                <EmailVerificationNotification
                  :email="currentUser.email"
                  v-if="!currentUser.emailVerifiedAt" />

                <ValidationProvider v-slot="{ errors }" name="email">
                  <v-text-field
                    dense
                    v-model="form.email"
                    :label="$t('form.email')"
                    :placeholder="$t('form.email')"
                    outlined
                    :error-messages="errors"
                    required
                    :disabled="!!currentUser.email"
                  >
                    <template v-slot:append>
                      <v-tooltip top v-if="!currentUser.emailVerifiedAt">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="warning" v-bind="attrs" v-on="on"
                            >mdi-information-outline</v-icon
                          >
                        </template>
                        <span>{{ $t("label.email_not_verified") }}</span>
                      </v-tooltip>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="success" v-bind="attrs" v-on="on"
                            >mdi-check</v-icon
                          >
                        </template>
                        <span>{{ $t("label.email_verified") }}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" name="emailOptional">
                  <v-text-field
                    dense
                    v-model="form.emailOptional"
                    :label="$t('form.email_opt')"
                    :placeholder="$t('form.email_opt')"
                    outlined
                    :error-messages="errors"
                    required />
                </ValidationProvider>
                <ValidationProvider dense v-slot="{ errors }" name="phone">
                  <v-text-field
                    dense
                    v-model="form.phone"
                    :label="$t('form.phone_mobile')"
                    :placeholder="$t('form.phone_mobile')"
                    outlined
                    :error-messages="errors"></v-text-field>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" name="landline">
                  <v-text-field
                    dense
                    v-model="form.landline"
                    :label="$t('form.phone_landline')"
                    :placeholder="$t('form.phone_landline')"
                    outlined
                    required
                    :error-messages="errors"></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item value="address">
            <v-row class="mt-3" dense>
              <v-col cols="12" sm="6">
                <ValidationProvider v-slot="{ errors }" name="street">
                  <v-text-field
                    outlined
                    dense
                    v-model="form.street"
                    :label="$t('form.street')"
                    :placeholder="$t('form.street')"
                    type="text"
                    required
                    :error-messages="errors"></v-text-field>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" name="houseNr">
                  <v-text-field
                    outlined
                    dense
                    v-model="form.houseNr"
                    :label="$t('form.house')"
                    :placeholder="$t('form.house')"
                    type="text"
                    required
                    :error-messages="errors"></v-text-field>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" name="postcode">
                  <v-text-field
                    outlined
                    dense
                    v-model="form.postcode"
                    :label="$t('form.plz')"
                    :placeholder="$t('form.plz')"
                    type="text"
                    required
                    :error-messages="errors"></v-text-field>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" name="city">
                  <v-text-field
                    outlined
                    dense
                    v-model="form.city"
                    :label="$t('form.city')"
                    :placeholder="$t('form.city')"
                    type="text"
                    required
                    :error-messages="errors"></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </ValidationObserver>
    </v-card-text>
  </div>
</template>

<script>
import UploadBtn from "@/components/UploadBtn";
import { mapActions, mapState } from "vuex";
import userService from "@/services/userService";
import localizationService from "@/services/localizationService";
import { format, sub } from "date-fns";
import responsive from "@/utils/mixins/responsive";
import EmailVerificationNotification from "@/components/EmailVerificationNotification";
import UserAvatar from '@/components/UserAvatar'

export default {
  name: "StudentSettingsGeneral",
  mixins: [responsive],
  components: {
    UserAvatar,
    EmailVerificationNotification,
    UploadBtn,
  },
  data: () => ({
    activeTab: null,
    form: {
      avatar: null,
      firstName: "",
      lastName: "",
      birthDate: "",
      gender: "",
      placeOfBirth: "",
      email: "",
      emailOptional: "",
      phone: "",
      landline: "",
      street: "",
      houseNr: "",
      postcode: "",
      city: "",
    },
    formLoading: false,
    avatarImage: "",
    languages: [],
    menuBirthDate: false,
  }),
  watch: {
    menuBirthDate(val) {
      val &&
        setTimeout(() => (this.$refs.birthDatePicker.activePicker = "YEAR"));
    },
  },
  computed: {
    ...mapState("auth", { currentUser: "authUser" }),
    environmentType() {
      return process.env.NODE_ENV;
    },
    birthDateFormat() {
      return this.form.birthDate
        ? format(new Date(this.form.birthDate), "dd.MM.yyyy")
        : "";
    },
    minDateOfBirthFromToday () {
      return format(sub(new Date(), { years: 14 }), 'yyyy-MM-dd')
    }
  },
  mounted() {
    localizationService
      .locales()
      .then((resp) => {
        const languages = resp.data || {};

        this.languages = Object.keys(languages).map((id) => {
          return { value: id, text: languages[id] };
        });
      })
      .catch((error) => console.log(error));

    this.form.firstName = this.currentUser.firstName;
    this.form.lastName = this.currentUser.lastName;
    this.form.placeOfBirth = this.currentUser.placeOfBirth;
    this.form.birthDate = this.currentUser.birthDate;
    this.form.gender = this.currentUser.gender;
    this.form.email = this.currentUser.email;
    this.form.emailOptional = this.currentUser.emailOptional;
    this.form.phone = this.currentUser.phone;
    this.form.landline = this.currentUser.landline;
    this.form.street = this.currentUser.street || "";
    this.form.houseNr = this.currentUser.houseNr || "";
    this.form.postcode = this.currentUser.postcode || "";
    this.form.city = this.currentUser.city || "";
    // this.form.localeId = this.currentUser.localeId.toString()

    this.avatarImage = this.currentUser.avatar;
  },
  methods: {
    ...mapActions("auth", ["getAuthUser"]),
    onFilePick(url, file) {
      this.avatarImage = url;
      this.form.avatar = file;
    },
    save() {
      const formData = new FormData();

      Object.keys(this.form).forEach((field) => {
        const value = this.form[field];

        if (!value) return;

        if (Array.isArray(value)) {
          value.map((item) => formData.append(field + "[]", item));
        } else {
          formData.set(field, value);
        }
      });

      formData.set("_method", "PUT");

      this.formLoading = true;
      userService
        .updateGeneralInfo(formData)
        .then(() => {
          this.getAuthUser();
          this.$snackbar.show({
            message: this.$t("messages.successfully_updated"),
            color: "success",
            timeout: 2000,
          });
        })
        .catch((error) => {
          if (!error.response) console.log(error);

          const responseData = error.response.data;
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message)
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });

          Object.keys(errors).forEach((fieldName) =>
            this.$refs.form.setErrors({ [fieldName]: errors[fieldName] })
          );
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    onInputBirthDate(value) {
      this.form.birthDate = value;
      this.menuBirthDate = false;
    },
  },
};
</script>

<style scoped></style>
