<template>
  <v-slide-y-reverse-transition>
    <v-btn
      fab
      small
      class="scroll__bottom mr-1"
      :class="{
        'mr-5': !$vuetify.breakpoint.xsOnly,
        'mr-1': $vuetify.breakpoint.xsOnly,
      }"
      color="primary"
      elevation="3"
      @click="$emit('click')">
      <v-icon class="text--white">mdi-arrow-down</v-icon>
    </v-btn>
  </v-slide-y-reverse-transition>
</template>

<script>
export default {
  name: "ScrollToBottomBtn",
};
</script>

<style scoped></style>
