<template>
  <div class="mr-1">
    <v-menu
      v-model="messagesMenu"
      :close-on-content-click="false"
      :nudge-width="300"
      :nudge-bottom="5"
      offset-y
      offset-x
      z-index="10">
      <template v-slot:activator="{ on }">
        <v-btn icon large v-on="on">
          <v-badge
            color="red"
            :content="totalUnseenMessages"
            :value="totalUnseenMessages"
            overlap
            offset-y="15"
            offset-x="15">
            <v-icon size="23">mdi-message-text-outline</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-card max-width="350" :outlined="true">
        <v-list-item
          class="text-uppercase d-flex justify-space-between pr-1"
          dense>
          <span>{{ $t("messenger.titleAlt") }}</span>

          <!--            <v-chip class="ml-auto" text-color="white" color="red" label small v-if="unseenMessagesCount">new {{unseenMessagesCount}}</v-chip>-->
          <!--          <v-btn icon class="ml-auto"><v-icon>mdi-dots-horizontal</v-icon></v-btn>-->
        </v-list-item>

        <v-divider></v-divider>

        <v-list class="pt-0 pb-0">
          <EmptyBox v-if="!totalUnseenMessages" />

          <v-list-item-group v-else>
            <v-list-item
              v-for="room in unseenRooms"
              :key="room.id"
              @click="openRoomInMessenger(room)">
              <v-list-item-icon class="mr-3">
                <v-avatar size="45"><v-img :src="room.image" /></v-avatar>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="d-flex flex-row">
                  <span>{{ room.fullTitle }}</span>
                  <MessageStatus
                    class="ml-auto"
                    v-if="room.lastMessage"
                    :message="room.lastMessage" />
                </v-list-item-title>
                <v-list-item-content
                  class="caption pa-0"
                  v-if="room.lastMessage">
                  <div class="d-flex" style="width: 100%">
                    <span class="text-caption text-truncate mt-1 mr-2">{{
                      room.lastMessage.message
                    }}</span>
                    <v-badge
                      class="ml-auto position-absolute"
                      color="red"
                      bottom
                      right
                      overlap
                      :value="room.unSeenMessagesCount"
                      inline
                      :content="room.unSeenMessagesCount" />
                  </div>
                </v-list-item-content>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import ChatRoom from "@/store/models/ChatRoom";
import MessageStatus from "@/components/messenger/MessageStatus";
import { mapActions } from "vuex";
import EmptyBox from "@/components/EmptyBox";
import messenger from "@/utils/mixins/messenger";

export default {
  name: "MessengerNotifications",
  mixins: [messenger],
  components: {
    EmptyBox,
    MessageStatus,
  },
  data() {
    return {
      messagesMenu: false,
      message: false,
    };
  },
  computed: {
    unseenRooms() {
      return ChatRoom.query()
        .with("conversationPartner")
        .with("lastMessage")
        .where("unSeenMessagesCount", (value) => value > 0)
        .orderBy("id", "desc")
        .all();
    },
    totalUnseenMessages() {
      return ChatRoom.query().sum("unSeenMessagesCount");
    },
  },
  methods: {
    ...mapActions("messenger", { selectRoom: "selectRoom" }),
    openRoomInMessenger(room) {
      console.log(room.conversationPartner);

      this.$router.push({ name: "messenger" }).then(() => {
        this.openDialogRoom(room.conversationPartner);
      });
    },
  },
};
</script>

<style scoped></style>
