<template>
  <v-sheet
    rounded
    elevation="3"
    color="white"
    class="messenger__room-bubble mr-5 d-flex flex-column"
    :class="{ 'messenger__room-bubble-minimized': isMinimized }">
    <!-- Room header -->
    <div
      class="header d-flex justify-space-between py-1 px-2"
      style="cursor: pointer; min-height: 48px"
      @click="toggleMinimize">
      <div class="d-flex align-center">
        <v-avatar size="35"><v-img :src="activeRoom.image" /></v-avatar>

        <v-toolbar-title class="d-flex flex-column ml-2">
          <span class="text-subtitle-2">{{ activeRoom.fullTitle }}</span>
          <span class="text-caption">Online</span>
        </v-toolbar-title>
      </div>

      <div class="d-flex align-center">
        <v-btn icon @click.stop="minimize"><v-icon>mdi-minus</v-icon></v-btn>
        <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </div>
    </div>

    <v-divider />

    <!-- Room content -->
    <div class="room__content">
      <div class="room__messages" ref="roomContent">
        <v-progress-circular
          class="room__loader align-self-center"
          color="primary"
          indeterminate
          size="50" />

        <template>
          <EmptyBox
            :width="100"
            :height="100"
            v-if="activeRoom && !messages.length"
            image="assets/svg/messages.svg">
            <span>No messages here yet...</span>
          </EmptyBox>

          <div
            class="mb-2 d-flex flex-column"
            v-if="showLoadMoreMessageButton && isLoadMoreAvailable">
            <v-divider /><v-btn
              class="text-capitalize my-1 text-center"
              color="blue"
              text
              :loading="loadMoreMessages"
              :disabled="loadMoreMessages"
              >LOAD MORE MESSAGES</v-btn
            ><v-divider />
          </div>

          <div
            class="messages__date"
            v-for="(messagesByUser, date) in sortedMessages"
            :key="date">
            <div class="text-center mb-5 mt-5 text-subtitle-2">
              <v-chip small>{{ date }}</v-chip>
            </div>

            <div
              class="messages__user d-flex flex-column mb-2"
              v-for="(user, userIndex) in messagesByUser"
              :key="userIndex">
              <div class="d-flex">
                <v-avatar
                  class="message__avatar mt-auto mr-3"
                  size="32px"
                  item
                  v-if="
                    (currentUser.type === 'school-administrator' &&
                      user.type !== 'school-administrator') ||
                    (currentUser.type !== 'school-administrator' &&
                      currentUser.id !== user.id)
                  ">
                  <v-img
                    :src="
                      user.type === 'school-administrator'
                        ? school.avatar
                        : user.avatar
                    " />
                </v-avatar>

                <div class="messages__stack">
                  <MessengerMessage
                    v-for="(message, messageIndex) in user.messages"
                    :key="messageIndex"
                    :user="user"
                    :message="message"
                    :message-index="messageIndex" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

      <v-slide-y-reverse-transition>
        <v-btn
          class="scroll__bottom mr-1"
          :class="{
            'mr-5': !$vuetify.breakpoint.xsOnly,
            'mr-1': $vuetify.breakpoint.xsOnly,
          }"
          fab
          color="primary"
          elevation="3"
          small
          @click="scrollToBottom"
          v-show="isScrollUp">
          <v-icon class="text--white">mdi-arrow-down</v-icon>
        </v-btn>
      </v-slide-y-reverse-transition>

      <MessengerMessageBar @messageCreated="onMessageCreated" />
    </div>
  </v-sheet>
</template>

<script>
import user from "@/utils/mixins/user";
import { mapActions, mapState } from "vuex";
import ChatRoom from "@/store/models/ChatRoom";
import MessengerMessageBar from "@/components/messenger/MessengerMessageBar";
import MessengerMessage from "@/components/messenger/MessengerMessage";

export default {
  name: "MessengerRoomBubble",
  components: { MessengerMessage, MessengerMessageBar },
  mixins: [user],
  data: () => ({
    show: true,
    isMinimized: false,
    message: "",
    messages: [],
  }),
  computed: {
    ...mapState("messenger", ["activeRoomId"]),
    activeRoom() {
      return ChatRoom.query().find(this.activeRoomId) || {};
    },
  },
  methods: {
    ...mapActions("messenger", { deselectRoom: "deselectRoom" }),
    minimize() {
      this.isMinimized = true;
    },
    toggleMinimize() {
      this.isMinimized = !this.isMinimized;
    },
    close() {
      this.deselectRoom();
    },
    onMessageCreated(message) {
      console.log(message);
    },
  },
};
</script>

<style scoped lang="scss">
.messenger {
  &__room-bubble {
    height: 500px;
    width: 380px;
    position: fixed;
    z-index: 100;
    bottom: 0;
    right: 0;

    &-minimized {
      height: 48px;
    }
  }
}
</style>
