<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'school-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>
        {{ $t("route.schoolSettings.children.limits") }}
      </v-card-title>

      <v-spacer />

      <v-btn
        text
        color="primary"
        @click="save"
        :loading="formLoading"
        :disabled="formLoading || loading"
      >
        {{ $t("btn.save") }}
      </v-btn>
    </v-toolbar>

    <v-progress-circular
        class="d-flex ma-auto mt-10"
        v-if="loading"
        style="min-height: 200px"
        color="primary"
        indeterminate
    />
    <template v-else>
      <ValidationObserver ref="form">
        <v-card-text>
<!--          <SettingsResponsiveRow-->
<!--              :title="$t('settings.limits.past_appointments_allowed')"-->
<!--              :description="$t('settings.limits.past_appointments_allowed_description')"-->
<!--          >-->
<!--            <v-switch class="mt-0" dense hide-details v-model="form.pastEventsAllowed" :label="$t('label.enabled')" inset />-->
<!--          </SettingsResponsiveRow>-->

          <SettingsResponsiveRow
              :title="$t('settings.limits.student_limits')"
              :description="$t('settings.limits.student_limits_description')"
          >
            <ValidationProvider
                v-slot="{ errors }"
                name="maxLessonsAmountPerWeek">
              <v-text-field
                  dense
                  v-model="form.maxLessonsAmountPerWeek"
                  :label="$t('form.max_practices_per_week')"
                  :hint="$t('messages.unlimited_for_empty_field')"
                  persistent-hint
                  :placeholder="maxLessonsAmountPerWeekPlaceholder"
                  outlined
                  type="number"
                  min="0"
                  :error-messages="errors" />
            </ValidationProvider>

            <ValidationProvider
                v-slot="{ errors }"
                name="maxLessonsAmountPerDay">
              <v-text-field
                  dense
                  v-model="form.maxLessonsAmountPerDay"
                  :label="$t('form.max_practices_per_day')"
                  :hint="$t('messages.unlimited_for_empty_field')"
                  persistent-hint
                  :placeholder="maxLessonsAmountPerDayPlaceholder"
                  outlined
                  type="number"
                  min="0"
                  :error-messages="errors" />
            </ValidationProvider>

            <ValidationProvider
                v-slot="{ errors }"
                name="maxLessonsDurationPerDay">
              <v-text-field
                  dense
                  v-model="form.maxLessonsDurationPerDay"
                  :label="$t('form.max_duration_per_day')"
                  :hint="$t('messages.unlimited_for_empty_field')"
                  persistent-hint
                  :placeholder="maxLessonsDurationPerDayPlaceholder"
                  outlined
                  type="number"
                  min="0"
                  :error-messages="errors" />
            </ValidationProvider>
          </SettingsResponsiveRow>

          <SettingsResponsiveRow
              :title="$t('settings.limits.student_can_cancel_appointments')"
              :description="$t('settings.limits.student_can_cancel_appointments_description')"
          >
            <v-switch
                class="mt-0"
                v-model="form.studentCanDeletePractice"
                :label="$t('label.enabled')"
                inset
                hide-details
                dense
            />
            <span class="d-block mt-2">{{ $t('form.events_cant_be_deleted_within_period') }}</span>
            <ValidationProvider v-slot="{ errors }" name="nonDeletablePeriod">
              <v-text-field
                  :disabled="!form.studentCanDeletePractice"
                  dense
                  v-model="form.nonDeletablePeriod"
                  :hint="$t('messages.unlimited_for_empty_field')"
                  persistent-hint
                  :placeholder="nonDeletablePeriodPlaceholder"
                  outlined
                  type="number"
                  min="0"
                  :error-messages="errors"
              />
            </ValidationProvider>
          </SettingsResponsiveRow>

          <SettingsResponsiveRow
              :title="$t('settings.limits.minimal_balance')"
              :description="$t('settings.limits.minimal_balance_description')"
          >
            <v-switch
                class="mt-0"
                v-model="form.isBalanceLimitEnabled"
                :label="$t('label.enabled')"
                inset
                hide-details
                dense
            />
            <v-text-field
                dense
                persistent-hint
                outlined
                v-model="form.balanceLimitAmount"
                :disabled="!form.isBalanceLimitEnabled"
                append-icon="mdi-currency-eur"
                class="mt-2"
                v-currency="{
                precision: 0,
                currency: null,
                allowNegative: false,
                valueRange: { min: 0.0, max: 999.0 },
              }"
            />
          </SettingsResponsiveRow>

          <SettingsResponsiveRow
              :title="$t('settings.limits.negative_balance_allowed')"
              :description="$t('settings.limits.negative_balance_allowed_description')"
              :divider-after="false"
          >
            <v-switch
                class="mt-0"
                v-model="form.isNegativeBalanceAllowed"
                :label="$t('label.enabled')"
                inset
                hide-details
                dense
            />
          </SettingsResponsiveRow>
        </v-card-text>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import schoolService from "@/services/schoolService";
import i18n from "@/plugins/i18n";
import { mapActions } from "vuex";
import SettingsResponsiveRow from "@/components/settings/SettingsResponsiveRow";
import responsive from '@/utils/mixins/responsive'

export default {
  name: "Limits",
  components: {SettingsResponsiveRow},
  mixins: [responsive],
  data: () => ({
    loading: true,
    formLoading: false,
    form: {
      maxLessonsAmountPerDay: null,
      maxLessonsAmountPerWeek: null,
      maxLessonsDurationPerDay: null,
      studentCanDeletePractice: false,
      nonDeletablePeriod: null,
      // pastEventsAllowed: false,
      isBalanceLimitEnabled: false,
      isNegativeBalanceAllowed: false,
      balanceLimitAmount: 0,
    },
  }),
  async mounted() {
    this.loading = true;

    try {
      const responseSchool = await schoolService.load();
      const schoolData = responseSchool.data || {};
      this.form.maxLessonsAmountPerDay = schoolData.maxLessonsAmountPerDay;
      this.form.maxLessonsAmountPerWeek = schoolData.maxLessonsAmountPerWeek;
      this.form.maxLessonsDurationPerDay = schoolData.maxLessonsDurationPerDay;
      this.form.studentCanDeletePractice = schoolData.studentCanDeletePractice;
      this.form.nonDeletablePeriod = schoolData.nonDeletablePeriod;
      // this.form.pastEventsAllowed = schoolData.pastEventsAllowed;
      this.form.isBalanceLimitEnabled = schoolData.isBalanceLimitEnabled;
      this.form.isNegativeBalanceAllowed = schoolData.isNegativeBalanceAllowed;
      this.form.balanceLimitAmount = schoolData.balanceLimitAmount;
    } catch (e) {
      console.log(e);
    }

    this.loading = false;
  },
  computed: {
    maxLessonsAmountPerWeekPlaceholder() {
      if (!this.form.maxLessonsAmountPerWeek) return i18n.t("label.unlimited");
      return this.form.maxLessonsAmountPerWeek.toString();
    },
    maxLessonsAmountPerDayPlaceholder() {
      if (!this.form.maxLessonsAmountPerDay) return i18n.t("label.unlimited");
      return this.form.maxLessonsAmountPerDay.toString();
    },
    maxLessonsDurationPerDayPlaceholder() {
      if (!this.form.maxLessonsDurationPerDay) return i18n.t("label.unlimited");
      return this.form.maxLessonsDurationPerDay.toString();
    },
    nonDeletablePeriodPlaceholder() {
      if (!this.form.nonDeletablePeriod) return i18n.t("label.unlimited");
      return this.form.nonDeletablePeriod.toString();
    },
  },
  methods: {
    ...mapActions("school", ["updateDefaultLimits"]),
    save() {
      const params = {
        maxLessonsAmountPerDay: this.form.maxLessonsAmountPerDay || null,
        maxLessonsAmountPerWeek: this.form.maxLessonsAmountPerWeek || null,
        maxLessonsDurationPerDay: this.form.maxLessonsDurationPerDay || null,
        studentCanDeletePractice: this.form.studentCanDeletePractice || null,
        nonDeletablePeriod: this.form.nonDeletablePeriod,
        // pastEventsAllowed: this.form.pastEventsAllowed,
        isBalanceLimitEnabled: this.form.isBalanceLimitEnabled,
        balanceLimitAmount: this.form.balanceLimitAmount,
        isNegativeBalanceAllowed: this.form.isNegativeBalanceAllowed,
      };
      this.formLoading = true;
      schoolService
        .updateEducationSettings(params)
        .then(() => {
          this.updateDefaultLimits(params);
          this.$snackbar.success(this.$t("messages.successfully_updated"))
        })
        .catch((error) => {
          const responseData = error.response.data;
          const errors = responseData.errors || [];
          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.error(responseData.message)
          }
          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
