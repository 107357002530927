<template>
  <v-breadcrumbs class="pl-0" dark large :items="items">
    <template v-slot:divider>
      <v-icon>mdi-arrow-right</v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: "Breadcrumbs",
  computed: {
    items() {
      const items = [
        {
          text: this.$route.meta.pageName || "",
          to: { name: this.$route.name || "" },
        },
      ];

      if (this.$route.meta.parent) {
        const route = this.$router.options.routes.find((route) => {
          return route.name === this.$route.meta.parent;
        });

        if (route)
          items.unshift({
            text: route.meta.pageName || "",
            exact: true,
            to: { name: route.name || "" },
          });
      }

      return items;
    },
  },
};
</script>

<style lang="scss">
.v-breadcrumbs__item {
  color: var(--v-secondary-base) !important;
}
.v-breadcrumbs__item--disabled {
  color: #fff !important;
}
</style>
