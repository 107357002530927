import { render, staticRenderFns } from "./ClickAndLearn.vue?vue&type=template&id=40bab837&scoped=true"
import script from "./ClickAndLearn.vue?vue&type=script&lang=js"
export * from "./ClickAndLearn.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40bab837",
  null
  
)

export default component.exports