import i18n from '@/plugins/i18n'
import { addMinutes, format, parseISO } from 'date-fns'
import { mapGetters } from 'vuex'
import vehiclesService from '@/services/vehiclesService'

import TeacherModel from '@/store/models/TeacherModel'
import OfficeModel from '@/store/models/OfficeModel'
import MeetingPlaceModel from '@/store/models/MeetingPlaceModel'
import StudentModel from '@/store/models/StudentModel'
import VehicleModel from '@/store/models/VehicleModel'
import LessonTypeModel from '@/store/models/LessonTypeModel'
import AppointmentModel from '@/store/models/AppointmentModel'

export default {
  data: () => ({
    initialMultiplier: 1,
    studentProgressData: {},
    progressObject: {
      'lesson_type.normal': { requiredValue: 'practiceNormalsShould' , totalValue: 'practiceNormalsInMinutes' },
      'lesson_type.instructions': { requiredValue: 'practiceInstructionsShould' , totalValue: 'practiceInstructionsInMinutes' },
      'lesson_type.overland': { requiredValue: 'practiceOverlandsShould' , totalValue: 'practiceOverlandsInMinutes' },
      'lesson_type.highway': { requiredValue: 'practiceHighwaysShould' , totalValue: 'practiceHighwaysInMinutes' },
      'lesson_type.night': { requiredValue: 'practiceNightsShould' , totalValue: 'practiceNightsInMinutes' },
    },
    primaryVehiclesIds: null,
    primaryVehiclesData: [],
    secondaryVehiclesIds: null,
    secondaryVehiclesData: [],
    primaryVehiclesLoader: false,
    secondaryVehiclesLoader: false,
  }),
  mounted() {
    const { id, duration } = this.currentAppointment
    if (!this.isStudent && !id) {
      this.updateFields({ duration: duration })
      this.recalculateAmount(true)
    }
  },
  watch: {
    "currentAppointment.licenseId"() {
      if (!this.teacher || this.createdAppointment) return
      this.recalculateDuration(this.currentAppointment.lessonTypeId, this.currentAppointment.licenseId)
    },
    "currentAppointment.lessonTypeId"(val) {
      if (!this.teacher || this.createdAppointment) return
      this.recalculateDuration(val, this.currentAppointment.licenseId)
    },
  },
  computed: {
    ...mapGetters('school', ['school']),
    ...mapGetters("appointments", ["initialDurationGetter",]),
    createdAppointment() {
      return AppointmentModel.find(this.currentAppointment.id) || null
    },
    currentLessonType() {
      return LessonTypeModel.find(this.currentAppointment.lessonTypeId) || null
    },
    teacher() {
      return TeacherModel.find(this.currentAppointment.teacherId) || null
    },

    licenseProgressData() {
      const hasRequiredInstructions = this.currentLessonType?.group === 'instructions'
        && !!this.studentProgressData[this.currentAppointment.licenseId]?.practiceInstructionsShould
      const hasRequiredNormals = this.currentLessonType?.group === 'normal'
        && this.currentLessonType?.name === 'lesson_type.normal'
        && !!this.studentProgressData[this.currentAppointment.licenseId]?.practiceNormalsShould

      if (this.currentAppointment.licenseId && (this.currentLessonType?.group === 'special' || hasRequiredInstructions || hasRequiredNormals)) {
        return this.studentProgressData[this.currentAppointment.licenseId]
      } else {
        return null
      }
    },
    licenseProgress () {
      if (!(this.currentAppointment.licenseId in this.studentProgressData)) return null
      return this.studentProgressData[this.currentAppointment.licenseId]
    },
    progressSpecialDuration() {
      return this.licenseProgress?.specialLessonDuration || 1
    },
    shouldValue() {
      if (!this.currentLessonType) return
      const correctName = this.progressObject[this.currentLessonType.name]
      return this.licenseProgressData[correctName.requiredValue]
    },
    totalValue() {
      if (!this.currentLessonType) return
      const correctName = this.progressObject[this.currentLessonType.name]
      return this.licenseProgressData[correctName.totalValue]
    },

    placesGroups() {
      return [
        {
          label: i18n.t("label.school_offices"),
          items: OfficeModel.all() || [],
        },
        {
          label: i18n.t("label.meeting_place"),
          items: MeetingPlaceModel.all() || [],
        },
        {
          label: i18n.t("label.student_address"),
          items: StudentModel.query()
            .whereIdIn(this.isStudent ? this.currentUser.id : this.currentAppointment.studentsIds)
            .get() || [],
        },
      ]
    },
    lessonTypes() {
      return this.baseLessonTypes.filter((type) => {
        return type.forLicenses && this.currentAppointment.licenseId
          ? type.forLicenses.includes(this.currentAppointment.licenseId)
          : this.baseLessonTypes
      })
    },

    primaryVehicles() {
      if (this.primaryVehiclesLoader) return []
      if (!this.primaryVehiclesIds) return VehicleModel.all()
      return VehicleModel.query()
        .where((vehicle) => {
          return (this.primaryVehiclesIds.includes(vehicle.id) && !vehicle.isDeleted)
            || (vehicle.isDeleted && vehicle.id === this.createdAppointment?.primaryVehicleId)
        })
        .get() || []
    },

    secondaryVehicles() {
      if (this.secondaryVehiclesLoader) return []
      const query = VehicleModel.query()
        .where((value) => value.id !== this.currentAppointment.primaryVehicleId)
      if (!this.secondaryVehiclesIds) return query.get() || []
      return query
        .where((vehicle) => {
          return (this.secondaryVehiclesIds.includes(vehicle.id) && !vehicle.isDeleted)
            || (vehicle.isDeleted && vehicle.id === this.createdAppointment?.secondaryVehicleId)
        })
        .get() || []
    },
  },
  methods: {
    requestPrimaryVehicles() {
      const { licenseId, teacherId, start, duration, secondaryVehicleId } = this.currentAppointment
      const params = {
        "exceptAppointmentId": this.currentAppointment.id,
        "licenses": [licenseId],
        "teachers": [teacherId],
        "teacher": teacherId,
        "from": format(parseISO(start), "yyyy-MM-dd HH:mm"),
        "to": format(addMinutes(parseISO(start), duration), "yyyy-MM-dd HH:mm"),
      }

      this.primaryVehiclesIds = null
      this.primaryVehiclesData = []
      this.secondaryVehiclesIds = null
      this.primaryVehiclesLoader = true
      vehiclesService
        .getVehiclesWithStatus(params)
        .then((response) => {
          const pluckedIds = response.data.map(vehicle => vehicle.id) || []
          this.primaryVehiclesIds = pluckedIds.filter(vehicleId => vehicleId !== secondaryVehicleId)
          this.primaryVehiclesData = response.data || []
        })
        .finally(() => this.primaryVehiclesLoader = false)
    },

    requestSecondaryVehicles() {
      const { teacherId, start, duration, primaryVehicleId } = this.currentAppointment
      const params = {
        "exceptAppointmentId": this.currentAppointment.id,
        "teachers": [teacherId],
        "teacher": teacherId,
        "from": format(parseISO(start), "yyyy-MM-dd HH:mm"),
        "to": format(addMinutes(parseISO(start), duration), "yyyy-MM-dd HH:mm")
      }

      this.secondaryVehiclesIds = null
      this.secondaryVehiclesData = []
      this.secondaryVehiclesLoader = true
      vehiclesService
        .getVehiclesWithStatus(params)
        .then((response) => {
          const pluckedIds = response.data.map(vehicle => vehicle.id) || []
          this.secondaryVehiclesIds = pluckedIds.filter(vehicleId => vehicleId !== primaryVehicleId)
          this.secondaryVehiclesData = response.data || []
        })
        .finally(() => this.secondaryVehiclesLoader = false)
    },

    getStudentProgress(val) {
      this.studentProgressData = val
    },
    recalculateAmount(initialAmount = false) {
      const teacher = TeacherModel.find(this.currentAppointment.teacherId)
      const teacherLessonDuration = teacher?.lessonDuration || this.currentAppointment.duration
      let amount = Math.ceil(this.initialDurationGetter / teacherLessonDuration)
      amount = amount <= 5 ? amount : 5
      if (initialAmount && teacher && (amount < 2)) amount = 2
      this.initialDuration = teacherLessonDuration * amount
      this.updateFields({ duration: teacherLessonDuration * amount })
    },
    recalculateDuration(lessonTypeId, licenseId) {
      const lessonType = LessonTypeModel.find(lessonTypeId)

      const { useTeacherDefaultDuration, multiplier, licenseDuration } = lessonType
      const teacherLessonDuration = this.teacher?.lessonDuration
      const durationForLicense = licenseDuration.find(item => item.licenseId === licenseId)?.duration
        || this.currentAppointment.duration
      const duration = useTeacherDefaultDuration ? teacherLessonDuration : durationForLicense
      const processedDuration = licenseId
      && ((this.initialDuration <= duration * multiplier) || !useTeacherDefaultDuration)
        ? duration * multiplier
        : this.initialDuration

      this.updateFields({ duration: processedDuration })
    },
    updateFields(value) {
      this.$store.commit(`appointments/UPDATE_APPOINTMENT_FIELD`, value)
    },
  },
}
