<template>
  <v-dialog
    max-width="850px"
    :value="showModalGetter"
    @click:outside="closeAppointmentModal"
    @keydown.esc="closeAppointmentModal"
    :fullscreen="isMobile"
    :hide-overlay="isMobile"
    scrollable
  >

    <v-card :tile="isMobile" style="padding-top:0!important;">
      <TopBar class="flex-shrink-0 flex-grow-0" :color="appointmentColor.bodyColor" :happyHour="false" />

      <v-divider></v-divider>

      <v-card-text class="mt-3 pt-1">
        <template v-if="skeleton">
          <component :is="skeletonComponent" />
        </template>

        <template v-else>
          <FormDateTimeArea
              :disabledDate="!!currentAppointment.id || placeholderOrPracticeStudentHasNoPriceList"
              :disabledTime="!!currentAppointment.id || placeholderOrPracticeStudentHasNoPriceList"
              :disabledDuration="!!currentAppointment.id || placeholderOrPracticeStudentHasNoPriceList"
          />
          <ValidationObserver ref="observer">
            <component class="mt-3" :is="formComponent" :teachers="teachers" />
          </ValidationObserver>
        </template>

        <v-alert
            v-if="simulatorStudentHasNoPriceList || placeholderStudentHasNoPriceList || practiceStudentHasNoPriceList"
            class="mt-9"
            dense
            type="warning"
            border="left"
            colored-border
            elevation="1"
        >
          {{ alertText }}
        </v-alert>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <Button
            @click.native="closeAppointmentModal"
            :text="true"
            color="black"
            icon="mdi-close"
            :label="$t('btn.close')"
            :disabled="appointmentLoader"
        />

        <v-spacer />

        <template v-if="!isPast && isExactlyStudent">
          <Button
              v-if="showDeleteBtn && studentCanDeleteThisEvent"
              @click.native="deleteApp"
              color="error"
              icon="mdi-delete"
              :label="$t('btn.delete')"
              :disabled="appointmentLoader"
              :loading="buttonLoaders.deleteLoader"
          />

          <Button
              v-if="showRequestedPracticeBtn"
              @click.native="declinePracticeApp"
              color="error"
              icon="mdi-minus-circle"
              :label="$t('btn.decline')"
              :disabled="appointmentLoader"
              :loading="buttonLoaders.declineLoader" />


          <Button
              v-if="showCreateBtn"
              @click.native="createApp"
              color="primary"
              icon="mdi-content-save"
              :label="$t('btn.create')"
              :disabled="appointmentLoader || placeholderOrPracticeStudentHasNoPriceList"
              :loading="buttonLoaders.createLoader"
          />

          <template v-if="currentAppointment.type === 'PLACEHOLDER'">
            <Button
                v-if="!currentAppointment.isRequested"
                @click.native="requestPlaceholderApp"
                color="primary"
                icon="mdi-calendar-question"
                :label="$t('btn.book_placeholder')"
                :disabled="appointmentLoader || placeholderOrPracticeStudentHasNoPriceList"
                :loading="buttonLoaders.requestLoader"
            />
            <Button
                v-else
                @click.native="declinePlaceholderRequestApp"
                color="error"
                icon="mdi-minus-circle"
                :label="$t('btn.abandon_placeholder')"
                :disabled="appointmentLoader"
                :loading="buttonLoaders.declineLoader"
            />
          </template>

          <template v-if="currentAppointment.type === 'THEORY' && currentAppointment.group === 'lesson'">
            <Button
                v-if="!currentAppointment.isSubscribed"
                @click.native="subscribeToTheoryApp"
                color="primary" icon="mdi-calendar-question"
                :label="$t('btn.attend_theory_lesson')"
                :disabled="appointmentLoader"
                :loading="buttonLoaders.subscribeLoader"
            />
            <Button
                v-else
                @click.native="unsubscribeFromTheoryApp"
                color="error"
                icon="mdi-minus-circle"
                :label="$t('btn.abandon_theory_lesson')"
                :disabled="appointmentLoader"
                :loading="buttonLoaders.unsubscribeLoader" />
          </template>

          <template v-if="currentAppointment.type === 'SIMULATOR'">
            <Button
                v-if="!currentAppointment.isSubscribed"
                @click.native="subscribeToSimulatorApp"
                color="primary"
                icon="mdi-calendar-question"
                :label="$t('btn.book_simulation')"
                :disabled="appointmentLoader || simulatorStudentHasNoPriceList"
                :loading="buttonLoaders.subscribeLoader"
            />
            <Button
                v-else-if="studentCanDeleteThisEvent"
                @click.native="unsubscribeFromSimulatorApp"
                color="error"
                icon="mdi-minus-circle"
                :label="$t('btn.cancel_simulation')"
                :disabled="appointmentLoader"
                :loading="buttonLoaders.unsubscribeLoader"
            />
          </template>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { addDays, parseISO, isPast } from 'date-fns'
import { showConfirm } from '@/helpers/dialogs'
import { mapActions, mapGetters, mapState } from 'vuex'
import user from '@/utils/mixins/user'
import colorMixin from '@/utils/mixins/colorMixin'
import priceCategoryMixin from '@/utils/mixins/priceCategoryMixin'

import Button from "@/components/calendar/appointmentModal/Button";
import TopBar from "@/components/calendar/appointmentModal/TopBar";
import FormDateTimeArea from "@/components/calendar/appointmentModal/fields/FormDateTimeArea";

import OfftimeForm from '@/components/calendar/appointmentModal/forms/OfftimeForm'
import StudentPlaceholderForm from "@/components/calendar/appointmentModal/forms/StudentPlaceholderForm";
import StudentPracticeForm from "@/components/calendar/appointmentModal/forms/StudentPracticeForm";
import StudentSimulatorForm from "@/components/calendar/appointmentModal/forms/StudentSimulatorForm";
import StudentSpecialForm from "@/components/calendar/appointmentModal/forms/StudentSpecialForm";
import StudentTheoryForm from "@/components/calendar/appointmentModal/forms/StudentTheoryForm";

import OfftimeLoader from "@/components/skeleton-loaders/appointment-form/OfftimeLoader";
import PlaceholderLoader from "@/components/skeleton-loaders/appointment-form/PlaceholderLoader";

import TeacherModel from "@/store/models/TeacherModel";
import StudentModel from "@/store/models/StudentModel";
import LicenseModel from '@/store/models/LicenseModel'

export default {
  name: "StudentAppointmentModal",
  mixins: [user, colorMixin, priceCategoryMixin],
  components: {
    Button,
    TopBar,
    FormDateTimeArea,

    OFFTIME_FORM: OfftimeForm,
    PLACEHOLDER_FORM: StudentPlaceholderForm,
    PRACTICE_FORM: StudentPracticeForm,
    SIMULATOR_FORM: StudentSimulatorForm,
    SPECIAL_FORM: StudentSpecialForm,
    THEORY_FORM: StudentTheoryForm,

    OFFTIME_LOADER: OfftimeLoader,
    PLACEHOLDER_LOADER: PlaceholderLoader,
  },
  data: () => ({
    skeleton: false,
  }),
  props: {
    currentAppointment: {
      type: Object,
      required: true,
    }
  },
  watch: {
    showModalGetter() {
      this.$refs.observer?.reset()
      this.skeleton = false
    },
  },
  computed: {
    ...mapState("appointments", ["buttonLoaders"]),
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("teachers", { activeTeachers: "activeIds" }),
    ...mapGetters("appointments", ["showModalGetter"]),
    licenseName() {
      return LicenseModel.find(this.currentAppointment.licenseId).name || ''
    },
    student() {
      return StudentModel.find(this.currentUser.id) || {}
    },
    studentHasNoPriceList() {
      return this.student.bookAndPay && !this.student.priceCategoryId
    },
    simulatorStudentHasNoPriceList() {
      return this.currentAppointment.type === 'SIMULATOR' && this.studentHasNoPriceList
    },
    placeholderOrPracticeStudentHasNoPriceList() {
      const teacher = TeacherModel.find(this.currentAppointment.teacherId)
      return ['PLACEHOLDER', 'PRACTICE'].includes(this.currentAppointment.type) &&
          this.studentHasNoPriceList &&
          teacher?.studentCanCreatePractice
    },

    placeholderStudentHasNoPriceList() {
      const teacher = TeacherModel.find(this.currentAppointment.teacherId)
      return ['PLACEHOLDER'].includes(this.currentAppointment.type) &&
          this.studentHasNoPriceList &&
          teacher?.studentCanCreatePractice
    },
    practiceStudentHasNoPriceList() {
      const teacher = TeacherModel.find(this.currentAppointment.teacherId)
      return ['PRACTICE'].includes(this.currentAppointment.type) &&
          this.studentHasNoPriceList &&
          teacher?.studentCanCreatePractice
    },

    alertText() {
      if (this.simulatorStudentHasNoPriceList) {
        return this.$t('alert.student_has_no_price_list_simulator')
      }
      if (this.placeholderStudentHasNoPriceList) {
        return this.$t('alert.student_has_no_price_list_placeholder')
      }
      if (this.practiceStudentHasNoPriceList) {
        return this.$t('alert.student_has_no_price_list_practice')
      }
      return ''
    },

    studentCanDeleteThisEvent() {
      const { studentCanDeletePractice, nonDeletablePeriod } = this.schoolSettings;
      const startTime = parseISO(this.currentAppointment.start);
      const deleteLimit = addDays(new Date(), nonDeletablePeriod);
      return (studentCanDeletePractice && (startTime > deleteLimit || nonDeletablePeriod === null));
    },
    showRequestedPracticeBtn() {
      return this.currentAppointment.type === 'PRACTICE' && this.currentAppointment.status === 'REQUESTED';
    },
    showDeleteBtn() {
      return this.currentAppointment.status === 'ACCEPTED' && this.studentCanDeleteThisEvent;
    },
    showCreateBtn() {
      return !this.currentAppointment.id;
    },
    showUpdateBtn() {
      return this.currentAppointment.id && this.currentAppointment.type === 'PRACTICE';
    },
    appointmentLoader() {
      return this.$store.state.appointments.appointmentLoader || false;
    },
    teachers() {
      const query = TeacherModel.query().where("active", true).where("id", this.currentUser.teachersIds);
      if (!this.currentAppointment.id) query.where("onlyPlaceholdersBooking", false);
      return query.get();
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    formComponent() {
      return this.currentAppointment.type + "_FORM";
    },
    skeletonComponent() {
      return this.currentAppointment.type + "_LOADER";
    },
    isPast() {
      return isPast(parseISO(this.currentAppointment.start))
    },
  },
  methods: {
    ...mapActions("appointments", [
        "closeAppointmentModal",
        "createAppointment",
        "declinePractice",
        "deleteAppointment",
        "requestPlaceholder",
        "declinePlaceholder",
        "subscribeToTheory",
        "unsubscribeFromTheory",
        "subscribeToSimulator",
        "unsubscribeFromSimulator",
    ]),
    deleteApp () {
      showConfirm(
          this.$t('btn.confirm'),
          this.$t('messages.are_your_sure_delete'),
          () => this.deleteAppointment()
      )
    },
    async createApp () {
      const validate = await this.$refs.observer.validate()
      if (validate) this.createAppointment()
    },
    async updateApp () {
      const validate = await this.$refs.observer.validate()
      if (validate) this.updateAppointment()
    },
    async requestPlaceholderApp () {
      const validate = await this.$refs.observer.validate()
      if (validate) this.requestPlaceholder()
    },

    declinePracticeApp () {
      showConfirm(
          this.$t('btn.confirm'),
          this.$t('messages.are_your_sure_decline_practice'),
          () => this.declinePractice()
      )
    },

    declinePlaceholderRequestApp () {
      showConfirm(
          this.$t('btn.confirm'),
          this.$t('messages.are_your_sure_decline_placeholder'),
          () => this.declinePlaceholder()
      )
    },
    subscribeToTheoryApp () {
      this.subscribeToTheory()
    },
    unsubscribeFromTheoryApp () {
      showConfirm(
          this.$t('btn.confirm'),
          this.$t('messages.are_your_sure_unsubscribe_theory'),
          () => this.unsubscribeFromTheory()
      )
    },
    async subscribeToSimulatorApp () {
      const validate = await this.$refs.observer.validate()
      if (validate) this.subscribeToSimulator()
    },
    unsubscribeFromSimulatorApp () {
      showConfirm(
          this.$t('btn.confirm'),
          this.$t('messages.are_your_sure_unsubscribe_simulator'),
          () => this.unsubscribeFromSimulator()
      )
    },

  },
};
</script>

<style lang="scss" scoped>
.v-input__prepend-inner,
.v-input__append-inner {
  cursor: pointer;
}
.justify-center {
  display: flex;
  justify-content: space-between;
}
</style>
