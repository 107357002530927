<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between">
      {{ title }}
      <v-icon v-if="icon" color="black" size="23">{{ icon }}</v-icon>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-progress-circular
        size="30"
        class="d-flex ma-auto"
        v-if="loading"
        indeterminate
      />

      <div v-else>
        <div v-if="events.length > 0">
          <EventAlert
            v-for="event in shownEvents"
            :key="event.id"
            :event="event"
            :color="eventBodyColorByType(event)"
            @open="
              $router.push({ name: 'calendar.appointment', params: { id: event.id } })
            " />
        </div>
        <empty-box v-else />
      </div>
      <v-btn
        v-if="events.length > viewAmount"
        class="mt-3"
        color="primary"
        block
        text
        x-small
        @click="() => (toggle = !toggle)">
        <v-icon class="mr-2">{{toggle ? "mdi-chevron-down" : "mdi-chevron-up" }}</v-icon>
        {{ toggle ? $t("label.more") : $t("label.less") }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import EventAlert from "@/components/event/EventAlert";
import eventsUtils from "@/utils/mixins/eventsUtils";
import user from "@/utils/mixins/user";
import LessonTypeModel from "@/store/models/LessonTypeModel";
import EventTypeModel from "@/store/models/EventTypeModel";

export default {
  name: "NextAppointments",
  components: { EventAlert },
  mixins: [eventsUtils, user],
  data: () => ({
    toggle: true,
  }),
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
    eventTypes: {
      type: Array,
      default: () => [],
    },
    viewAmount: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
  },
  computed: {
    shownEvents() {
      const allEvents = this.events || [];
      return this.toggle ? allEvents.slice(0, this.viewAmount) : allEvents;
    },
  },
  methods: {
    eventBodyColorByType(event) {
      const type = ["REQUESTED", "DECLINED"].includes(event.status)
        ? "lesson_type.normal"
        : event.lessonType;
      let eventType = EventTypeModel.find(event.eventType) || {};
      if (event.eventType === "PRACTICE")
        eventType = LessonTypeModel.query().where("name", type).first() || {};
      if (this.isStudent && eventType.name === "lesson_type.gratis_exam") {
        // Gratis exams for students should look like paid exam
        const studentType = LessonTypeModel.query().where("name", "lesson_type.exam").first() || {};
        return studentType.bodyColor;
      }
      return eventType.bodyColor;
    },
  },
};
</script>

<style scoped></style>
