<template>
  <div class="calendar-base" :class="{ 'no-sidebar': !isSidebarEnabled }">
    <CalendarTopMenu @sidebar="toggleSidebar" />
    <CalendarFilters class="calendar-side-menu" />

    <CalendarGrid />
    <StudentAppointmentModal v-if="isStudent" :currentAppointment="currentAppointmentGetter" />
    <AppointmentModal v-else :currentAppointment="currentAppointmentGetter" />
    <AppointmentCreationMenu />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import user from "@/utils/mixins/user"
import responsive from "@/utils/mixins/responsive"

import CalendarGrid from "@/components/calendar/CalendarGrid"
import AppointmentCreationMenu from "@/components/calendar/AppointmentCreationMenu"
import AppointmentModal from "@/components/calendar/appointmentModal/AppointmentModal"
import StudentAppointmentModal from "@/components/calendar/appointmentModal/StudentAppointmentModal"
import CalendarTopMenu from "@/components/calendar/CalendarTopMenu"
import CalendarFilters from "@/components/calendar/CalendarFilters"

export default {
  name: "CalendarDesktop",
  mixins: [user, responsive],
  components: {
    AppointmentModal,
    StudentAppointmentModal,
    AppointmentCreationMenu,
    CalendarTopMenu,
    CalendarFilters,
    CalendarGrid,
  },
  computed: {
    ...mapState({ isSidebarEnabled: (state) => state.calendar.isSidebarEnabled, }),
    ...mapGetters("appointments", ["currentAppointmentGetter", "activeEventsGetter"]),
  },
  methods: {
    toggleSidebar() {
      this.$store.dispatch("toggleSidebar", !this.isSidebarEnabled);
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-base {
  display: grid;
  grid-template-columns: 290px 1fr;
  grid-template-rows: 48px 1fr;
  grid-template-areas: "side top" "side body";
  height: calc(100vh - 48px - env(safe-area-inset-top));
  width: 100%;
  background-color: #fff;
  &.no-sidebar {
    grid-template-areas: "top top" "body body";

    & > .calendar-side-menu {
      display: none;
    }
  }
}

.calendar-side-menu {
  grid-area: side;
  padding: 10px;
  border-right: var(--default-border);
  min-height: 0; /* NEW */
  min-width: 0; /* NEW; needed for Firefox */
  overflow: auto;
}
</style>
