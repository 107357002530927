<template>
  <div>
    <v-row>
      <v-col>
        <LessonTypesField
            @emitLessonTypeSelect="changeTheoryLessonType"
            :lessonTypeProp="currentAppointment.lessonTypeId"
            :lessonTypesList="lessonTypesList"
            :disabled="currentAppointment.isSubscribed"
            :validation="true"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
        <SimulatorNameField
            @emitSimulatorNameChange="(val) => updateFields({ simulatorName: val })"
            :validation="true"
            :disabled="false"
            :simulatorNameProp="currentAppointment.simulatorName" />
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
        <OfficesField
            @emitOfficeChange="(val) => updateFields({ officeId: val })"
            :validation="true"
            :disabled="false"
            :officeIdProp="currentAppointment.officeId"
            :officesList="schoolOffices" />
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
        <LicenseField
            @emitLicenseSelect="(val) => updateFields({ licenseId: val })"
            :disabled="currentAppointment.isSubscribed || !currentAppointment.lessonTypeId"
            :validation="false"
            :multiple="false"
            :licenseProp="currentAppointment.licenseId"
            :licenseIdsList="schoolLicenses"
            :label="$t('form.licence')"
        />
      </v-col>

      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
        <StudentsField
          @emitStudentChange="(val) => updateFields({ studentsIds: val })"
          :disabled="currentAppointment.isSubscribed || !currentAppointment.licenseId"
          :validation="!!currentAppointment.licenseId"
          :multiple="false"
          :decorated="true"
          :studentsProp="currentAppointment.studentsIds"
          :studentsList="studentsByLicense"
        />
      </v-col>
    </v-row>

    <CopyAppointment
      @emitSetCopiedAmount="(val) => updateFields({ copyForNrOfWeeks: val })"
      :disabled="isLicenseSelected"
      :weeksAmount="25"
      :copyNrProp="currentAppointment.copyForNrOfWeeks" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import LicenseField from "@/components/calendar/appointmentModal/fields/LicenseField";
import StudentsField from "@/components/calendar/appointmentModal/fields/StudentsField";
import SimulatorNameField from "@/components/calendar/appointmentModal/fields/SimulatorNameField";
import OfficesField from "@/components/calendar/appointmentModal/fields/OfficesField";
import CopyAppointment from "@/components/calendar/appointmentModal/fields/CopyAppointment";

import LicenseModel from "@/store/models/LicenseModel";
import StudentModel from "@/store/models/StudentModel";
import OfficeModel from "@/store/models/OfficeModel";
import PriceCategoryLessonTypeStatusModel from "@/store/models/PriceCategoryLessonTypeStatusModel";
import LessonTypesField from '@/components/calendar/appointmentModal/fields/LessonTypesField.vue'
import LessonTypeModel from '@/store/models/LessonTypeModel'
import priceCategoryMixin from '@/utils/mixins/priceCategoryMixin'
export default {
  name: "SimulatorForm",
  mixins: [priceCategoryMixin],
  components: {
    LessonTypesField,
    LicenseField,
    StudentsField,
    SimulatorNameField,
    OfficesField,
    CopyAppointment,
  },
  computed: {
    ...mapGetters("appointments", {
      currentAppointment: "currentAppointmentGetter",
    }),
    schoolLicenses() {
      const licenses = LicenseModel.query().where('forSimulation', true).get() || [];

      return licenses.map((license) => license.id);
    },
    schoolOffices() {
      return OfficeModel.all() || [];
    },
    lessonType() {
      return LessonTypeModel.find(this.currentAppointment.lessonTypeId);
    },
    studentsByLicense() {
      let query = StudentModel.query()
        .where((student) => student.licensesIds.includes(this.currentAppointment.licenseId))

      if (!this.currentAppointment.isSubscribed) query.where("active", true).where('educationStatus', 'learning')

      return query.get().map((student) => {
        let lessonTypeDoesNotHavePrice = false
          if (this.lessonType?.isPayable && student.priceCategoryId) {
            const lessonTypeStatus = PriceCategoryLessonTypeStatusModel.query()
            .where("priceCategoryId", student.priceCategoryId)
            .where("licenseId", this.currentAppointment.licenseId)
            .where("lessonTypeId", this.lessonType.id)
            .first();

            lessonTypeDoesNotHavePrice = !lessonTypeStatus
          }

          const disabled = student.bookAndPay && (!student.priceCategoryId || lessonTypeDoesNotHavePrice)

        return {
          ...student,
          categoryName: student.categoryName,
          disabled,
          lessonTypeDoesNotHavePrice
        }
      })
    },
    lessonTypesList() {
      return LessonTypeModel.query().where('eventTypeId', 'SIMULATOR').get()
    },
    isLicenseSelected() {
      return !!this.currentAppointment.licenseId;
    }
  },
  mounted () {
    this.currentAppointment.lessonTypeId = this.lessonTypesList
        .find(lessonType => lessonType.id === this.currentAppointment.lessonTypeId)?.id || null
  },
  methods: {
    changeTheoryLessonType(val) {
      const oldLessonType = this.currentAppointment.id ? this.currentAppointment.lessonTypeId : null

      if (!oldLessonType || oldLessonType !== val) {
        this.updateFields({
          lessonTypeId: val,
          simulatorName: '',
          officeId: null,
          licenseId: null,
          studentsIds: [],
        })
      }
    },

    updateFields(value) {
      this.$store.commit(`appointments/UPDATE_APPOINTMENT_FIELD`, value)
    },
  },
};
</script>
