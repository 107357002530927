<script>
import privacyPolicyService from "@/services/privacyPolicyService";

export default {
  name: "AcceptPrivacyPolicy",
  data() {
    return {
      acceptedSchoolPP: false,
      acceptedBookandpayPP: false,
      loading: false
    }
  },
  methods: {
    save(delay = false) {
      if (this.savingDisabled) return;

      this.loading = true;

      setTimeout(() => {
        privacyPolicyService.save({
          acceptedSchoolPP: this.acceptedSchoolPP,
          acceptedBookandpayPP: this.acceptedBookandpayPP
        }).then(() => {
          this.$store.dispatch("auth/setPrivacyPolicyStatus", true).then(() => {
            this.$router.push({ name: "dashboard" });
          });
        }).catch(() => {
          console.log("Error saving privacy policy");
        }).finally(() => {
          this.loading = false;
        })
      }, delay ? 1000 : 0)
    },
    acceptAllAndSave() {
      this.acceptedSchoolPP = true;
      this.acceptedBookandpayPP = true;
      this.save(true);
    },
    acceptSelectedAndSave() {
      this.save();
    }
  },
  computed: {
    savingDisabled () {
      return !this.acceptedSchoolPP || this.loading;
    }
  }
}
</script>

<template>
  <v-container>
    <v-card :loading="loading">
      <v-card-title>
        {{ $t('label.attention_required') }}
      </v-card-title>
      <v-card-subtitle>
        {{$t('label.privacy_policy_acceptance_required') }}
      </v-card-subtitle>
      <v-card-text>
        <v-sheet rounded class="pa-1 my-1">
          <v-checkbox class="mt-0" v-model="acceptedSchoolPP" hide-details :label="$t('label.accept_your_school_privacy_policy')" />
          <div class="mt-2">
          </div>
        </v-sheet>

        <v-sheet rounded class="pa-1 my-1">
          <v-checkbox class="mt-0" v-model="acceptedBookandpayPP" hide-details :label="$t('label.accept_bookandpay_privacy_policy')" />
          <div class="mt-2">
          </div>
        </v-sheet>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-btn color="secondary" @click="acceptAllAndSave" :disabled="loading">
          {{ $t('btn.accept_all') }}
          <v-icon right>mdi-check</v-icon>
        </v-btn>
        <v-btn text color="primary" @click="acceptSelectedAndSave" small :disabled="savingDisabled">{{ $t('btn.accept_selected') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<style scoped>

</style>
