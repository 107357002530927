<template>
  <v-card :loading="loading">
    <v-img class="align-end" height="130px" :src="studentsImg" />

    <v-card-title>{{ $t("students") }}</v-card-title>

    <v-card-subtitle class="pb-0">{{
      $t("count_of_deleted", { entityName: $t("students"), count: count })
    }}</v-card-subtitle>

    <v-card-actions>
      <v-btn
        class="ml-auto"
        small
        text
        :to="{
          name: 'recycle-bin.recyclable',
          params: { recyclable: 'students' },
        }"
        :disabled="loading">
        {{ $t("btn.open") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import studentsService from "@/services/studentsService";

export default {
  name: "RecyclableStudentsCard",
  data: () => ({
    studentsImg: require("@/assets/img/students.jpg"),
    count: 0,
    loading: true,
  }),
  mounted() {
    studentsService
      .destroyedInfo()
      .then((response) => {
        const responseData = response.data || {};
        this.count = responseData.count;
      })
      .finally(() => (this.loading = false));
  },
};
</script>

<style scoped></style>
