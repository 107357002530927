import AppointmentNotification from '@/notifications/events/appointmentNotification'

export default class PlaceholderByLicenseCreated extends AppointmentNotification {
  icon() {
    return "mdi-calendar-account";
  }

  wildCardsData() {
    const data = this.notification.data;

    return {
      message: {
        studentName: data.studentName,
      },
    };
  }
}
