<script>
import {mapActions, mapGetters} from "vuex";
import {format, parseISO} from "date-fns";

export default {
  name: "FiltersDialog",
  components: {},
  data() {
    return {
      createdAtMenu: false,
    }
  },
  computed: {
    ...mapGetters('studentsDirectory', ['filtersData', 'filters', 'appliedFilters']),
    educationStatusesAsOptions() {
      return [
        { text: this.$t("education_statuses.registered"), value: "registered" },
        { text: this.$t("education_statuses.learning"), value: "learning" },
        { text: this.$t("education_statuses.finished"), value: "finished" },
      ];
    },
    activityStatusesAsOptions() {
      return [
        { text: this.$t('label.active'), value: 'active' },
        { text: this.$t('label.inactive'), value: 'inactive' },
      ]
    },
    gendersAsOptions() {
      return [
        { text: this.$t('gender.M'), value: "M" },
        { text: this.$t('gender.W'), value: "W" },
        { text: this.$t('gender.D'), value: "D" },
      ]
    },
    formattedDates() {
      const dates = [...this.filters.createdAtDates]

      return dates
          .sort()
          .map((item) => format(parseISO(item), "dd.MM.yyyy"))
          .join(' - ');
    },
  },
  methods: {
    ...mapActions('studentsDirectory', ['closeFiltersDialog', 'resetFilter']),
    clear() {
      this.resetFilter(Object.keys(this.appliedFilters));
    },
  }
}
</script>

<template>
  <v-dialog
    scrollable
    v-model="$store.state.studentsDirectory.filtersDialogState"
    width="850"
    @click:outside="closeFiltersDialog"
  >
    <v-card>
      <v-toolbar class="mb-4" color="primary" dark dense>
        <v-toolbar-title>{{ $t('label.filters') }}</v-toolbar-title>
        <v-spacer/>
        <v-btn dark icon @click="closeFiltersDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row dense>
          <v-col>
            {{ $t('label.search_term') }}
            <v-text-field
                hide-details
                v-model="$store.state.studentsDirectory.filters.search"
                :placeholder="$t('form.search')"
                solo
                prepend-inner-icon="mdi-account-search"
                clearable
            />
          </v-col>
        </v-row>
        <v-row dense class="mt-2">
          <v-col>
            {{ $t('label.advanced_filtering') }}
            <v-menu
                v-model="createdAtMenu"
                offset-y
                min-width="auto"
                :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="mt-1"
                  dense
                  readonly
                  :label="$t('filters.createdAt')"
                  :placeholder="$t('filters.createdAt')"
                  prepend-icon="mdi-calendar"
                  :value="formattedDates"
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  outlined
                  hide-details
                  @click:clear="$store.state.studentsDirectory.filters.createdAtDates = []"
                />
              </template>

              <v-date-picker
                  v-model="$store.state.studentsDirectory.filters.createdAtDates"
                  first-day-of-week="1"
                  no-title
                  range
              >
                <v-spacer />
                <v-btn text color="primary" @click="createdAtMenu = false">{{$t("btn.cancel")}}</v-btn>
                <v-btn text color="primary" @click="createdAtMenu = false">{{$t("btn.ok") }}</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-sheet class="py-2 mb-1 d-flex align-center">
              <v-switch
                class="mt-0 flex-grow-1"
                :label="$t('filters.without_appointments')"
                :placeholder="$t('filters.without_appointments')"
                dense
                hide-details
                prepend-icon="mdi-calendar-remove"
                v-model="$store.state.studentsDirectory.filters.withoutAppointments"
              />
              <div
                class="ml-10 flex-shrink-1 d-flex align-center"
                :class="{ disabled: !  $store.state.studentsDirectory.filters.withoutAppointments }"
              >
                <span class="d-block v-label mr-1">{{ $t('label.in_the_last') }}</span>
                <v-radio-group
                    dense
                    hide-details
                    v-model="$store.state.studentsDirectory.filters.noAppointmentsRange"
                    row
                    class="mt-0"
                >
                  <v-radio label="7" :value="7" />
                  <v-radio label="14" :value="14" />
                  <v-radio label="30" :value="30" />
                  <v-radio label="45" :value="45" />
                  <v-radio :label="`60 ${$t('label.days')}`" :value="60" />
                </v-radio-group>
              </div>
            </v-sheet>
            <v-sheet class="py-2 mb-1">
              <v-switch
                  class="mt-0"
                  :label="$t('filters.no_balance')"
                  :placeholder="$t('filters.no_balance')"
                  dense
                  hide-details
                  prepend-icon="mdi-cash-remove"
                  v-model="$store.state.studentsDirectory.filters.noBalance"
              />
            </v-sheet>

            <v-sheet class="py-2 mb-1" v-if="$store.state.school.isInvoicingEnabled && $store.state.school.isInvoicesAutomationEnabled">
              <v-switch
                  class="mt-0"
                  :label="$t('filters.automatic_invoicing_disabled')"
                  :placeholder="$t('filters.automatic_invoicing_disabled')"
                  dense
                  hide-details
                  prepend-icon="mdi-autorenew-off"
                  v-model="$store.state.studentsDirectory.filters.automaticInvoicingDisabled"
              />
            </v-sheet>
            <v-sheet class="py-2 mb-1" v-if="$store.state.school.hasClickAndLearn">
              <v-switch
                  class="mt-0"
                  :label="$t('filters.hasClickAndLearn')"
                  :placeholder="$t('filters.hasClickAndLearn')"
                  dense
                  hide-details
                  prepend-icon="$clickAndLearn"
                  v-model="$store.state.studentsDirectory.filters.hasClickAndLearn"
              />
            </v-sheet>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-select
                class="mb-2"
                type="string"
                :items="activityStatusesAsOptions"
                :label="$t('filters.active_inactive')"
                :placeholder="$t('filters.active_inactive')"
                dense
                outlined
                hide-details
                clearable
                prepend-icon="mdi-list-status"
                v-model="$store.state.studentsDirectory.filters.activityStatus"
            />

            <v-select
                class="mb-2"
                type="string"
                :items="educationStatusesAsOptions"
                :label="$t('filters.education_status')"
                :placeholder="$t('filters.education_status')"
                dense
                outlined
                hide-details
                clearable
                prepend-icon="mdi-account-badge"
                v-model="$store.state.studentsDirectory.filters.educationStatus"
            />

            <v-select
                class="mb-2"
                type="string"
                :items="filtersData.licenses"
                :label="$t('filters.licenses')"
                :placeholder="$t('filters.licenses')"
                dense
                outlined
                hide-details
                multiple
                clearable
                offset-y
                v-model="$store.state.studentsDirectory.filters.licenses"
                prepend-icon="mdi-alphabetical-variant"
            />

            <v-select
                class="mb-2"
                type="string"
                :items="filtersData.teachers"
                :label="$t('filters.teachers')"
                :placeholder="$t('filters.teachers')"
                dense
                outlined
                hide-details
                multiple
                clearable
                prepend-icon="mdi-account-tie-voice"
                v-model="$store.state.studentsDirectory.filters.teachers"
            />

            <v-select
                class="mb-2"
                type="string"
                :items="filtersData.priceLists"
                :label="$t('filters.price_list')"
                :placeholder="$t('filters.price_list')"
                dense
                outlined
                hide-details
                clearable
                prepend-icon="mdi-account-cash"
                v-model="$store.state.studentsDirectory.filters.priceCategory"
            />

            <v-select
              class="mb-2"
              type="string"
              :items="filtersData.offices"
              :label="$t('filters.office')"
              :placeholder="$t('filters.office')"
              dense
              outlined
              hide-details
              clearable
              prepend-icon="mdi-office-building"
              v-model="$store.state.studentsDirectory.filters.office"
            />

            <v-select
              class="mb-2"
              type="string"
              :items="gendersAsOptions"
              :label="$t('filters.gender')"
              :placeholder="$t('filters.gender')"
              dense
              outlined
              hide-details
              clearable
              prepend-icon="mdi-gender-male-female"
              v-model="$store.state.studentsDirectory.filters.gender"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn text color="error" @click="clear">{{ $t('btn.clear') }}</v-btn>
        <v-spacer/>
        <v-btn color="primary" @click="closeFiltersDialog">{{ $t('btn.apply') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>