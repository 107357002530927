import { Model } from "@vuex-orm/core";

export default class MeetingPlaceModel extends Model {
  static entity = "meeting-places";

  static fields() {
    return {
      id: this.attr(null),
      name: this.string(""),
      city: this.string(""),
      houseNr: this.string(""),
      postcode: this.string(""),
      street: this.string(""),
    };
  }

  get address() {
    return (
      this.street + " " + this.houseNr + ", " + this.postcode + " " + this.city
    );
  }
}
