<template>
  <div
    class="d-flex flex-column mt-5 mb-3">
    <div class="wrapper">
      <CalendarGraph
          :data="data"
          :start-date="startDate"
          :end-date="endDate"
          :color-fun="colorFun" />
    </div>

    <div class="text-caption px-4">
      <span>{{ $t("label.work_min_per_day") }}</span>

      <div class="d-flex flex-column text-center">
        <div class="d-flex align-center">
          <div class="work-day-color mb-1 mr-1" style="background-color: #F3E5F5" />
          0 - 200
        </div>
        <div class="d-flex align-center">
          <div class="work-day-color mb-1 mr-1" style="background-color: #CE93D8" />
          200 - 400
        </div>
        <div class="d-flex align-center">
          <div class="work-day-color mb-1 mr-1" style="background-color: #AB47BC" />
          400 - 600
        </div>
        <div class="d-flex align-center">
          <div class="work-day-color mr-1" style="background-color: #7B1FA2" />
          > 600
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { endOfYear, startOfYear } from "date-fns";
import CalendarGraph from "@/components/charts/calendar-graph/CalendarGraph";

export default {
  name: "WorkStatisticsGrid",
  props: {
    workedTimePerDay: {
      type: Array,
      default: () => [],
    },
  },
  components: { CalendarGraph },
  computed: {
    data() {
      return this.workedTimePerDay.map((workedTime) => {
        return { date: workedTime.date, count: workedTime.workDuration };
      });
    },
    startDate() {
      return startOfYear(new Date());
    },
    endDate() {
      return endOfYear(new Date());
    },
  },
  methods: {
    colorFun(v) {
      const count = parseInt(v.count);

      if (count >= 600) return "#7B1FA2";
      if (count >= 400) return "#AB47BC";
      if (count >= 200) return "#CE93D8";

      return "#F3E5F5";
    },
  },
};
</script>

<style scoped lang="scss">
.work-statistics-grid {
  display: grid;
  grid-template-columns: repeat(57, 15px);
  grid-template-rows: repeat(7, 15px);
  column-gap: 1px;
  row-gap: 1px;
}
.work-statistics-day {
  background-color: #ebedf0;
  border-radius: 5px;
}
.work-day-color {
  height: 13px;
  width: 13px;
  background-color: #ebedf0;
  margin-right: 2px;
}
</style>
