<template>
  <v-card>
    <v-card-title class="py-2 d-flex justify-space-between">
      <div class="d-flex flex-column">
        <span>{{ $t("label.students_without_appointments") }}</span>
        <span class="text-caption">
          {{ selectedRange.text }}
        </span>
      </div>

      <v-menu offset-y left>
        <template v-slot:activator="{ attrs, on }">
          <v-btn icon small v-bind="attrs" v-on="on">
            <v-icon>mdi-filter-outline</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
              @click="filterRange(item)"
              v-for="item in rangeFilter.selectOptions"
              :key="item.value"
              link>
            <v-list-item-title v-text="item.text"/>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>

    <v-divider/>

    <TeachersWorkTimeLoader v-if="loading" :count="6" />
    <template v-else>
      <empty-box v-if="!students.itemsTotal" />
      <v-list v-else>
        <v-list-item
          v-for="student in students.items"
          :key="student.id"
          dense
          two-line
          @click="$router.push('students/' + student.id)"
        >
          <v-list-item-content class="d-flex">
            <v-list-item-title>
              {{ student.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{
                $tc("label.count_total_events", student.eventsCount, { count: student.eventsCount })
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <div class="d-flex justify-space-between">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="gray" label small v-bind="attrs" v-on="on">
                    <span v-if="student.lastEvent" @click.stop>
                      {{ student.lastEventDistance }}
                    </span>
                    <span v-else>-</span>
                  </v-chip>
                </template>
                <span>{{ $t("label.last_event_date", { date: student.lastEvent || "-" }) }}</span>
              </v-tooltip>
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>

    <template v-if="!loading && students.itemsTotal > 10">
      <v-divider/>
      <v-card-actions class="py-3 px-7 d-flex justify-space-between">
        <span class="text-subtitle-1">{{$t("label.plus_some_more_items", {count: students.itemsTotal - 10}) }}</span>
        <v-btn
          text
          color="blue"
          :to="{
            name: 'students',
            query: {
              filters: this.formatFilters({ withoutAppointments: true }),
            },
          }"
        >
          {{ $t("btn.view_all") }}
        </v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import daysFilter from "@/utils/mixins/daysFilter";
import TeachersWorkTimeLoader from "@/components/skeleton-loaders/TeachersWorkTimeLoader";
import {format, parseISO } from "date-fns";
import listFilters from "@/utils/mixins/listFilters";
import widgetsService from "@/services/widgetsService";

export default {
  name: "StudentsWithoutAppointments",
  components: {TeachersWorkTimeLoader},
  mixins: [daysFilter, listFilters],
  data() {
    return {
      students: [],
      loading: true,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      widgetsService
          .studentsWithNoAppointments({
            from: this.datesRange.from
          })
          .then((response) => {
            this.students = response.data || {};

            this.students.items = this.students.items
                .map((item) => {
                  if (item.lastEvent) {
                    item.lastEvent = format(
                        parseISO(item.lastEvent),
                        "dd.MM.yyyy HH:mm"
                    );
                  }
                  return item;
                })
          })
          .finally(() => {
            this.loading = false;
          });
    },
    filterRange(filterItem) {
      this.rangeFilter.selectedValue = filterItem.value;

      this.load();
    },
  },
};
</script>

<style scoped></style>
