import { render, staticRenderFns } from "./StudentDocuments.vue?vue&type=template&id=64b5e39c&scoped=true"
import script from "./StudentDocuments.vue?vue&type=script&lang=js"
export * from "./StudentDocuments.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64b5e39c",
  null
  
)

export default component.exports