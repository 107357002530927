<template>
  <div class="slider" @click="toggle" v-touch="toggle">
    <div class="wrapper">
      <div class="arrow text-center">
        <v-icon size="14">{{ sidebarArrow }}</v-icon>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "SidebarSlider",
  data: () => ({
    collapsed: false,
  }),
  props: {
    type:{
      type: String,
      required: true,
    },
    size:{
      type: Number,
      required: true,
    },
  },
  computed: {
    sidebarArrow() {
      if (this.type === 'left') {
        return this.collapsed ? 'mdi-arrow-collapse-right' : 'mdi-arrow-collapse-left'
      } else {
        return this.collapsed ? 'mdi-arrow-collapse-left' : 'mdi-arrow-collapse-right'
      }
    }
  },
  methods: {
    toggle() {
      this.collapsed = !this.collapsed
    }
  }
}
</script>

<style lang="scss" scoped>
.slider {
  width: 20px;
  background: #ebebeb;
  margin-top: 1px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.55);
    i {
      color: var(--v-primary-base)
    }
  }
}
.wrapper{
  width: inherit;
  z-index: 9;
  position: fixed;
  height: 100%;
  pointer-events: none;
}
.arrow{
  width: 100%;
  height: 40px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
</style>
