<script>
import UserAvatar from "@/components/UserAvatar.vue";
import ExpandablePanel from "@/components/ExpandablePanel.vue";
export default {
  name: "StudentTeachers",
  components: {ExpandablePanel, UserAvatar},
  props: {
    student: {
      type: Object,
      required: true
    },
  },
}
</script>

<template>
  <expandable-panel panel-name="teachersPanelExpanded">
    <template v-slot:title="{expanded}">
      {{ $t('label.teachers') }}
      <v-badge inline color="grey lighten-1" :value="student.teachers.length && !expanded" :content="student.teachers.length"/>
    </template>
    <v-simple-table v-if="student.teachers.length" dense>
      <tbody>
      <tr v-for="teacher in student.teachers" :key="teacher.id">
        <td class="text-center" style="width: 20px">
          <user-avatar rounded :alt="teacher.fullName" size="20" :path="teacher.avatar" />
        </td>
        <td class="pl-0" :class="{'font-italic grey--text': ! teacher.active}">
          {{ teacher.fullName }}
        </td>
      </tr>
      </tbody>
    </v-simple-table>
    <empty-box v-else />
  </expandable-panel>
</template>

<style scoped>

</style>