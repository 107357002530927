<template>
  <v-container>
    <v-card :loading="loading || filtering">
      <v-toolbar elevation="3">
        <v-card-title>{{ $t("label.recent_payments") }}</v-card-title>
        <v-spacer />
        <v-btn text color="primary" :to="{ name: 'payouts' }">
          {{ $t("btn.payouts") }}
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="!loading && !filtering">
        <v-row class="mt-1">
          <v-col cols="12" md="4">
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date"
              persistent
              width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  solo
                  dense
                  readonly
                  :value="formattedDates"
                  :label="$t('label.filter_by_date')"
                  prepend-inner-icon="mdi-calendar"
                  clearable
                  @click:clear="date = []"
                  v-bind="attrs"
                  v-on="on">
                </v-text-field>
              </template>
              <v-date-picker v-model="date" scrollable range>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">{{ $t("btn.cancel") }}</v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(date.sort())"
                >
                  {{ $t("btn.ok") }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
                v-model="paymentType"
                :items="paymentTypes"
                :label="$t('label.payment_type')"
                solo
                dense
                @change="loadUniqueUsers"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              v-model="selectedUsers"
              :items="usersSelectValues"
              :label="$t('label.filter_by_user')"
              solo
              dense
              multiple
              chips
              small-chips
              deletable-chips
              :loading="loadingUniqueUsers"
              :disabled="loadingUniqueUsers"
            />
          </v-col>
          <v-col>
            <v-btn text color="primary" @click="filter" :loading="loading">{{ $t("btn.filter_results") }}</v-btn>
            <v-btn
              text
              color="primary"
              @click="exportPdf"
              :loading="downloading"
              :disabled="loading || filtering || !payments.length || downloading">
              {{ $t("btn.export") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-else>
        <v-skeleton-loader class="mx-auto" type="table-row@3" />
      </v-card-text>

      <component
          :is="componentsTable"
          v-if="payments.length && ! filtering"
          :payments="payments"
          fresh
      />

      <v-alert v-else-if="! loading && ! filtering">
        <empty-box />
      </v-alert>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import paymentsService from "@/services/paymentsService";
import PaymentsTable from "@/components/payments/PaymentsTable";
import CashPaymentsTable from "@/components/payments/CashPaymentsTable";
import { format, parseISO, startOfMonth } from "date-fns";
import formattedDatesMixin from "@/utils/mixins/formattedDatesMixin";
import FileSaver from "@/helpers/fileSaver";

export default {
  name: "Index",
  mixins: [formattedDatesMixin],
  components: { PaymentsTable, CashPaymentsTable },
  data() {
    return {
      loading: true,
      loadingUniqueUsers: false,
      filtering: false,
      modal: false,
      selectedUsers: [],
      uniqueUsers: [],
      payments: {},
      downloading: false,
      paymentType: 'online_payment',
      paymentTypes: [
        { value: 'online_payment', text: this.$t("label.online_payments") },
        { value: 'cash_payment', text: this.$t("label.cash_payments") },
      ],
      componentsTable: 'PaymentsTable',
      table: {
        'online_payment': 'PaymentsTable',
        'cash_payment': 'CashPaymentsTable',
      }
    };
  },
  mounted() {
    const startDate = format(startOfMonth(new Date()), "yyyy-MM-dd");
    const endDate = format(new Date(), "yyyy-MM-dd");
    this.date = [startDate, endDate];

    this.filter();
    this.loadUniqueUsers();
  },
  computed: {
    usersSelectValues() {
      return this.uniqueUsers.map((item) => {
        return {
          value: item.id,
          text: item.name,
        };
      });
    },
    filters() {
      const params = {};
      if (this.startDate) params.startDate = this.startDate;
      if (this.endDate) params.endDate = this.endDate;
      if (this.selectedUsers.length) params.selectedUsers = this.selectedUsers;
      params.paymentType = this.paymentType;

      return params;
    },
    ...mapGetters("auth", { currentUser: "currentUser" }),
  },
  methods: {
    exportPdf() {
      this.downloading = true;
      paymentsService.downloadReport(this.filters)
        .then((resp) => {
          const responseData = [resp.data] || [];
          let fileName = `Zahlungsbericht (${this.paymentType === 'online_payment' ? this.$t("label.online_payments") : this.$t("label.cash_payments")})`;

          if (this.filters.startDate && this.filters.endDate) {
            const startDate = format(parseISO(this.filters.startDate), "dd.MM.yyyy");
            const endDate = format(parseISO(this.filters.endDate), "dd.MM.yyyy");

            fileName = `${fileName} ${startDate} ${endDate}`;
          }

          const fileSaver = new FileSaver(responseData);
          fileSaver.setType("application/pdf")
            .saveToDevice(`${fileName}.pdf`, "Umsätze");
        })
        .finally(() => {
          this.downloading = false;
        });
    },
    loadPayments(params = {}) {
      paymentsService
        .load(params)
        .then((resp) => {
          this.payments = resp.data;
        })
        .finally(() => {
          this.loading = this.filtering = false;
        });
    },
    filter() {
      this.filtering = true;
      this.loadPayments(this.filters);
      this.componentsTable = this.table[this.paymentType]
    },
    loadUniqueUsers() {
      this.loadingUniqueUsers = true;
      this.selectedUsers = [];

      paymentsService.uniqueUsers({paymentType: this.paymentType}).then((resp) => {
        this.uniqueUsers = resp.data;
        this.loadingUniqueUsers = false;
      });
    }
  },
};
</script>

<style scoped></style>
