<template>
  <div :class="`d-flex align-center ${valueShouldBe  === 0 ? 'disabled' : ''}`" style="height: 50px;">
    <v-avatar class="flex-shrink-1 mr-1" tile width="40" height="40">
      <v-img contain aspect-ratio="1" :src="image" />
    </v-avatar>
    <div class="flex-grow-1 d-flex flex-column">
      <span>{{ title }}</span>
      <span>
        {{ valueLine }}
      </span>
    </div>
    <v-progress-circular
      :value="progressValue"
      :color="color"
      :size="40"
    >
      {{ valueShouldBe ? progressValue : '✔️' }}
    </v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "LessonTypeProgress",
  props: {
    image: {
      required: true,
    },
    title: {
      required: true
    },
    valueIs: {
      required: true
    },
    valueShouldBe: {
      required: false
    },
    progressValue: {
      default: 0
    },
    color: {
      required: true
    }
  },
  computed: {
    valueLine() {
      if (this.valueShouldBe >= 0) {
        if (this.valueShouldBe === 0) return this.$t('label.not_required')

        const valueIs = this.valueIs >= this.valueShouldBe ? this.valueShouldBe : this.valueIs
        return `${valueIs} / ${this.valueShouldBe}`
      } else {
        return this.valueIs
      }
    }
  }
}
</script>

<style scoped>
.disabled{
  opacity: 0.7;
}
</style>
