import { Model } from "@vuex-orm/core";
import { format, parseISO } from "date-fns";

export default class PrintMediaModel extends Model {
  static entity = "printMedia";

  static fields() {
    return {
      id: this.attr(null),
      updated_at: this.string(""),
      pdf: this.string(""),
      price: this.attr(null),
      flyers: this.boolean(false),
      school_id: this.attr(null),

      school_address_name: this.attr(null),
      school_address_first_name: this.attr(null),
      school_address_last_name: this.attr(null),
      school_address_street: this.attr(null),
      school_address_house_nr: this.attr(null),
      school_address_postcode: this.attr(null),
      school_address_city: this.attr(null),

      delivery_address_status: this.attr(null),
      delivery_address_name: this.attr(null),
      delivery_address_first_name: this.attr(null),
      delivery_address_last_name: this.attr(null),
      delivery_address_street: this.attr(null),
      delivery_address_house_nr: this.attr(null),
      delivery_address_postcode: this.attr(null),
      delivery_address_city: this.attr(null),

      stickers_amount: this.attr(null),
      stickers_logo_file: this.string(""),
      price_stickers_price: this.string(""),
      price_nett_price: this.string(""),
      price_delivery_price: this.string(""),
      price_tax: this.string(""),
      price_final_price: this.string(""),
    };
  }

  get date() {
    return format(parseISO(this.updated_at), "yyyy-MM-dd");
  }
}
