<template>
  <v-btn class="px-2" :icon="false" :text="text" :color="color" :disabled="disabled" :loading="loading" :outlined="outlined">
    <v-icon :left="!isMobile">{{ icon }}</v-icon>
    <span v-if="!isMobile">{{ label }}</span>
  </v-btn>
</template>

<script>

export default {
  name: "Button",
  props: {
    text: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "grey darken-2"
    },
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  }
};
</script>

<style lang="scss" scoped></style>
