<template>
  <v-dialog v-model="show" transition="dialog-bottom-transition" max-width="600">
    <v-card>
      <v-card-title>{{ $t("label.event_history") }}</v-card-title>

      <v-divider/>

      <v-card-text>
        <v-list-item three-line v-for="history in eventHistory" :key="history.id">
          <v-list-item-icon>
            <UserAvatar :path="history.author.avatar" :alt="history.author.name" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(`history_action.${history.action}`) }}</v-list-item-title>
            <v-list-item-subtitle>{{ history.author.name }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ history.formattedDate }} {{ history.time }}</v-list-item-subtitle>
            <v-list-item-subtitle>
              <ColoredCurrency :value="history.moneyAmount" />
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-icon>
            <v-icon :color="totalColor">mdi-trending-{{ totalIcon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">{{ $t("label.balance_modification") }}</v-list-item-title>
            <v-list-item-subtitle>
              <ColoredCurrency :value="historyTotal" />
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-btn text @click="close">{{ $t("btn.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ColoredCurrency from "@/components/ColoredCurrency";
import StudentHistoryModel from '@/store/models/StudentHistoryModel'
import UserAvatar from '@/components/UserAvatar'

export default {
  name: 'AppointmentHistoryDialog',
  components: { UserAvatar, ColoredCurrency },
  data() {
    return {
      show: false,
      eventId: null
    }
  },
  computed: {
    totalColor() {
      return this.historyTotal > 0
          ? 'green'
          : this.historyTotal === 0
              ? ''
              : 'red'
    },
    totalIcon() {
      return this.historyTotal > 0
          ? "up"
          : this.historyTotal === 0
              ? "neutral"
              : "down"
    },
    eventHistory() {
      return StudentHistoryModel.query().where("eventId", this.eventId).get() || {}
    },
    historyTotal() {
      return this.eventHistory
          .map((item) => item.moneyAmount)
          .reduce((sum, item) => parseFloat(sum) + parseFloat(item));
    }
  },
  methods: {
    open(eventId) {
      this.show = true
      this.eventId = eventId
    },
    close() {
      this.show = false
      this.eventId = null
    }
  }
}
</script>

<style scoped>

</style>
