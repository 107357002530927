<template>
  <div>
    <v-date-picker
      class="sidebar-calendar"
      :value="activeDate"
      :events="appointmentsDots"
      :disabled="loader"
      color="primary"
      first-day-of-week="1"
      no-title
      full-width
      show-adjacent-months
      event-color="#76ab26"
      @change="dateSelected"
      @update:picker-date="monthSelected"
    >
      <div
        v-if="holiday && !mdLandscapeAndUp"
        class="px-5 mb-2 d-flex align-center">
        <v-icon class="mr-2" color="red">mdi-calendar-star</v-icon>
        {{ holiday }}
      </div>

      <v-overlay :value="loader && $vuetify.breakpoint.mobile" absolute opacity="0">
        <v-progress-circular indeterminate size="64" color="primary" />
      </v-overlay>
    </v-date-picker>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { format, isPast, parseISO } from 'date-fns'
import user from "@/utils/mixins/user";
import responsive from "@/utils/mixins/responsive";
import loadAppointments from "@/utils/mixins/loadAppointments";
import HolidaysClass from "@/helpers/holiday";
import AppointmentModel from '@/store/models/AppointmentModel'
import TeacherModel from '@/store/models/TeacherModel'

export default {
  name: "SmallCalendar",
  mixins: [user, loadAppointments, responsive],
  data: () => ({
    marked: false,
  }),
  props: {
    loader: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.marked = true;
  },
  computed: {
    ...mapGetters(["activeDate"]),
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("appointments", ["activeEventsGetter"]),
    ...mapGetters("teachers", {
      activeTeachers: "active",
      activeTeachersIds: "activeIds",
    }),
    holiday() {
      const regions = this.schoolSettings.regions
        ? this.schoolSettings.regions.split(", ")
        : ["BUND"];
      return new HolidaysClass().getHoliday(parseISO(this.activeDate), regions);
    },
    appointmentsDots() {
      let appointments = [];
      let dotsArray = {};

      if (this.isTeacher) {
        appointments = AppointmentModel.query()
            .where('teacherId', this.currentUser.id)
            .where(appointment => (
                appointment.type !== 'OFFTIME' &&
                appointment.type !== 'PLACEHOLDER' ||
                (appointment.type === 'PLACEHOLDER' && appointment.isRequested)
            ))
            .get();
        dotsArray = appointments.map(app => app.startDate)
      }

      if (this.isStudent) {
        const foreignTeachers = TeacherModel.all().filter((item) => !this.currentUser.teachersIds.includes(item.id))
        const foreignTeachersIds = foreignTeachers.map((teacher) => teacher.id)
        appointments = AppointmentModel.query()
            .where(appointment => !appointment.teacherId)
            .orWhere(appointment => (
                foreignTeachersIds.includes(appointment.teacherId) ||
                this.activeTeachersIds.includes(appointment.teacherId) &&
                (appointment.studentsIds.includes(this.currentUser.id) || ['PLACEHOLDER', 'THEORY'].includes(appointment.type))
            ))
            .get();
        appointments.forEach(app => {
          if (!(app.startDate in dotsArray)) dotsArray[app.startDate] = [];
          if (
              (app.type === 'PRACTICE' && app.group !== 'planned_exam') ||
              app.type === 'SPECIAL' ||
              (['THEORY', 'SIMULATOR'].includes(app.type) && app.isSubscribed) ||
              (app.type === 'PLACEHOLDER' && app.isRequested)
          ) {
            dotsArray[app.startDate] = [...new Set([...dotsArray[app.startDate], ...['green']])];
          }
          if (!isPast(parseISO(app.start)) &&
              (app.type === 'PLACEHOLDER' ||
              (app.type === 'THEORY' && !app.isSubscribed) ||
              (app.type === 'SIMULATOR' && !app.isSubscribed))) {
            dotsArray[app.startDate] = [...new Set([...dotsArray[app.startDate], ...['purple']])]
          }
        })
      }


      return dotsArray;
    }
  },
  methods: {
    ...mapActions(["setCalendarDate"]),
    dateSelected(date) {
      this.setCalendarDate({
        month: format(parseISO(date), "yyyy-MM"),
        activeDate: date,
      });
      this.loadAppointments();
    },
    monthSelected(month) {
      this.setCalendarDate({ month: month });
      if (!this.isSchoolAdministrator) this.loadAppointments();
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-side-menu::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.sidebar-calendar.v-picker {
  border-radius: 0;
}
</style>
