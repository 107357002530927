<template>
  <div>
    <v-row>
      <TeachersField
        :cols="12"
        :disabled="true"
        :model="model"
        :eventDuration="eventDuration"
        :filteredTeachers="teachers" />
    </v-row>

    <TimeFields
      :disabled="true"
      :model="model"
      :eventDuration="eventDuration"
      adaptiveTime />
    <AmountDurationFields
      :disabled="true"
      :model="model"
      :eventDuration="eventDuration" />

    <v-row v-if="model.teacher">
      <LicenseField
        :disabled="isPast || studentDidRequest"
        :activeLicenses="placeholderAllowedLicenses" />
      <LessonTypesField
        v-if="!requestPlaceholder"
        :disabled="isPast"
        :model="model"
        eventType="PRACTICE" />
    </v-row>

    <MeetingPlaceField
      :disabled="requestPlaceholder"
      :model="model"
      :validation="false" />
    <TextField :disabled="true" :model="model" />
  </div>
</template>

<script>
import EventModel from "@/store/models/EventModel";
import TeachersField from "@/components/calendar/eventModal/fields/TeachersField";
import TimeFields from "@/components/calendar/eventModal/fields/TimeFields";
import AmountDurationFields from "@/components/calendar/eventModal/fields/AmountDurationFields";
import LicenseField from "@/components/calendar/eventModal/fields/LicenseField";
import LessonTypesField from "@/components/calendar/eventModal/fields/LessonTypesField";
import MeetingPlaceField from "@/components/calendar/eventModal/fields/MeetingPlaceField";
import TextField from "@/components/calendar/eventModal/fields/TextField";

import activeLicenseMixins from "@/utils/mixins/activeLicenseMixins";
import { parseISO } from "date-fns";
import happyHourMixins from "@/utils/mixins/happyHourMixins";
import { mapGetters } from "vuex";

export default {
  name: "StudentPlaceholderForm",
  mixins: [activeLicenseMixins, happyHourMixins],
  components: {
    TeachersField,
    TimeFields,
    AmountDurationFields,
    LicenseField,
    LessonTypesField,
    MeetingPlaceField,
    TextField,
  },
  props: {
    eventDuration: {
      type: Number,
      required: true,
    },
    teachers: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    studentDidRequest() {
      const event = EventModel.find(this.model.id) || {};
      const eventRequests = event.requests || [];
      return !!eventRequests.find(
        (request) => request.studentId === this.currentUser.id
      );
    },
    isPast() {
      return parseISO(this.model.start) <= new Date();
    },
    requestPlaceholder() {
      return !this.model.teacher.studentCanCreatePractice;
    },
  },
};
</script>
