<template>
  <v-container>
    <StudentBalanceWidget class="mb-2" :student="currentUser" />
    <PaymentForm v-if="showPaymentForm" :initial="initialFormValue" />
    <StudentHistory class="mt-2" :student="currentUser" />
  </v-container>
</template>

<script>
import StudentBalanceWidget from "@/components/student/StudentBalanceWidget";
import PaymentForm from "@/components/student/PaymentForm";
import StudentHistory from "@/components/student/StudentHistory";
import { mapGetters } from "vuex";

export default {
  name: "Balance",
  components: {StudentBalanceWidget, PaymentForm, StudentHistory },
  computed: {
    ...mapGetters("auth", { currentUser: "currentUser" }),
    ...mapGetters("school", ["school"]),
    initialFormValue () {
      return this.$route.query.prefill && this.currentUser.balance < 0
          ? parseFloat(Math.abs(this.currentUser.balance))
          : null
    },
    showPaymentForm() {
      return (
        this.school.isWithStripePayment && this.school.paymentSetupComplete
      );
    },
  },
};
</script>
