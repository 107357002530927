import api from "@/utils/api";

export default {
  list() {
    return new Promise((resolve, reject) => {
      api
        .get("/school/pos-terminal-locations")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
