<template>
  <v-navigation-drawer
    v-model="showSidebar"
    fixed
    temporary
    right
    touchless
    width="280"
    class="px-2"
  >
    <v-list dense>
      <v-subheader class="d-flex justify-space-between">
        <span>{{ $t("label.actions") }}</span>
      </v-subheader>

      <v-list-item @click="openMessengerRoom(student)" :disabled="student.isDeleted">
        <v-list-item-icon><v-icon>mdi-chat-outline</v-icon></v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{$t("chat.write_a_message") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="openOneTimePasswordModal(student)" :disabled="student.isDeleted">
        <v-list-item-icon><v-icon>mdi-account-key</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("label.access_data") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="editItem(student)" :disabled="student.isDeleted">
        <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("btn.edit") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="isSchoolAdministrator" @click="deleteItem(student)" :disabled="student.isDeleted">
        <v-list-item-icon><v-icon>mdi-archive-arrow-down</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("btn.archive") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list dense class="no-list-group-padding">
      <v-subheader class="d-flex justify-space-between">
        <span>{{ $t("label.documents") }}</span>
      </v-subheader>

      <student-credentials-list-item :student="student" v-if="student.oneTimePassword" />

      <StudentDocumentListItem
          icon="mdi-file-account-outline"
          :label="$t('label.education_contract').toString()"
          :is-student-deleted="student.isDeleted"
          @click.native="openDocumentsDialog('education_contract')"
          v-if="isSchoolAdministrator"
      />
      <StudentEducationStatus :student="student" />
      <StudentEducationStatus :student="student" document-type="theory_certificate" />
      <StudentEducationStatus :student="student" document-type="practice_certificate" />

      <StudentDocumentListItem
        icon="mdi-file-document-outline"
        :label="$t('label.apology_letter').toString()"
        :is-student-deleted="student.isDeleted"
        @click.native="openDocumentsDialog('apology_letter')"
      />

      <template v-if="isSchoolAdministrator">
        <StudentDocumentListItem
            v-for="license in licenses"
            :key="license.id"
            icon="mdi-book-education-outline"
            :label="$t('label.certificate_license', { license: license.name }).toString()"
            :is-student-deleted="student.isDeleted"
            @click.native="openDocumentsDialog('certificate_license', license)"
        />
      </template>

      <template v-if="school.hasInsights && isSchoolAdministrator">
        <v-subheader class="d-flex justify-space-between">
          <span>Insights</span>
        </v-subheader>

        <InsightsWidget :student="student"/>
      </template>

      <template v-if="school.hasClickAndLearn && school.clickAndLearnApiKey && isSchoolAdministrator">
        <v-subheader class="d-flex justify-space-between">
          <span>{{ $t("route.schoolSettings.children.clicklearn") }}</span>
        </v-subheader>

        <ClickAndLearnWidget :student="student" />
      </template>
    </v-list>

    <v-list subheader dense v-if="student.bookAndPay">
      <v-subheader class="d-flex justify-space-between">
        <span>{{ $t("label.balance") }}</span>
        <BalanceLabel :student="student" :disabled="student.isDeleted" />
      </v-subheader>

      <v-list-item
        v-if="isSchoolAdministrator"
        @click="openBalanceForm('manual_funded')"
        :disabled="student.isDeleted">
        <v-list-item-icon><v-icon>mdi-cash-plus</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("btn.fill") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="isSchoolAdministrator"
        @click="openBalanceForm('manual_withdrawn')"
        :disabled="student.isDeleted">
        <v-list-item-icon><v-icon>mdi-cash-minus</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("btn.withdraw") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="isSchoolAdministrator"
        :to="{ name: 'terminal', query: { studentId: student.id } }"
        :disabled="student.isDeleted"
      >
        <v-list-item-icon><v-icon>mdi-contactless-payment</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("label.terminal_payment") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list dense v-if="isSchoolAdministrator && school.hasJoker">
      <v-list-item @click="reactivateJoker" :disabled="student.isDeleted" v-if="isSchoolAdministrator">
        <v-list-item-icon>
          <v-icon size="24">$jokerHat</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("label.joker_status") }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action class="d-flex flex-row">
          <v-icon v-if="!student.joker">mdi-refresh</v-icon>
          <v-icon>{{
            student.joker
              ? "mdi-checkbox-marked-outline"
              : "mdi-checkbox-blank-outline"
          }}</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <BalanceFormDialog ref="balanceFormDialog" :student="student" />
    <StudentChangePasswordDialog ref="changePasswordDialog" />
    <StudentFormDialog ref="formItem" />

    <StudentDocumentsDialog ref="studentDocuments" :student="student"/>
  </v-navigation-drawer>
</template>

<script>
import user from "@/utils/mixins/user";
import studentsService from "@/services/studentsService";
import StudentModel from "@/store/models/StudentModel";
import messenger from "@/utils/mixins/messenger";
import LicenseModel from '@/store/models/LicenseModel'

import { mapGetters } from 'vuex'
import { showConfirm } from '@/helpers/dialogs'

import StudentEducationStatus from "@/components/student/StudentEducationStatus";
import BalanceLabel from "@/components/BalanceLabel";
import BalanceFormDialog from "@/components/student/BalanceFormDialog";
import StudentChangePasswordDialog from "@/components/student/StudentChangePasswordDialog";
import StudentFormDialog from "@/components/student/StudentFormDialog";
import ClickAndLearnWidget from "@/components/clickandlearn/ClickAndLearnWidget.vue";
import InsightsWidget from '@/components/insights/InsightsWidget.vue'
import StudentDocumentListItem from '@/components/student/StudentDocumentListItem.vue'
import StudentDocumentsDialog from '@/components/student/StudentDocumentsDialog.vue'
import StudentCredentialsListItem from "@/components/student/StudentCredentialsListItem.vue";

export default {
  name: "StudentSidebar",
  props: {
    studentId: {
      type: [Number, String],
      required: true,
    },
  },
  mixins: [user, messenger],
  components: {
    StudentCredentialsListItem,
    StudentDocumentListItem,
    InsightsWidget,
    ClickAndLearnWidget,
    StudentEducationStatus,
    BalanceLabel,
    BalanceFormDialog,
    StudentChangePasswordDialog,
    StudentFormDialog,
    StudentDocumentsDialog
  },
  data: () => ({
    showSidebar: false,
    vaccinationDocument: {},
  }),
  computed: {
    ...mapGetters("school", ["school"]),
    student() {
      return StudentModel.find(this.studentId) || {};
    },
    vaccinationApproved() {
      return this.student.vaccinationStatus === "APPROVED";
    },
    licenses() {
      return LicenseModel.query()
          .whereIdIn(this.student.licensesIds)
          .where('name', name => ['B96', 'B196', 'B197'].includes(name))
          .get()
    }
  },
  mounted() {
    this.vaccinationDocument = {};
    studentsService.documents(this.studentId).then((documentResponse) => {
      this.vaccinationDocument =
        documentResponse.data.find(
          (doc) => doc.type === "VACCINATION_CERTIFICATE"
        ) || {};
    });
  },
  methods: {
    open() {
      this.showSidebar = true;
    },
    close() {
      this.showSidebar = false;
    },
    openMessengerRoom(student) {
      this.$router.push({ name: "messenger" }).then(() => {
        this.openDialogRoom(student);
      });
    },
    openOneTimePasswordModal() {
      this.$refs.changePasswordDialog.open(this.studentId);
    },
    editItem(item) {
      this.$refs.formItem.open(item);
    },
    deleteItem(item) {
      this.loadingAction = true;
      showConfirm(
        this.$t('btn.confirm'),
        this.$t("messages.are_your_sure_archive"),
        () => {
          studentsService
            .destroy(item.id)
            .then(() => {
              studentsService.loadItem(item.id).then((resp) => {
                StudentModel.update({ where: item.id, data: resp.data || {} });
              });
            })
            .catch((error) => console.log(error))
            .finally(() => {
              this.loadingAction = false;
            });
        },
        () => {
          this.loadingAction = false;
        }
      );
    },
    openBalanceForm(type) {
      this.$refs.balanceFormDialog.open(type);
    },
    approveVaccination() {
      if (this.vaccinationApproved) return;

      showConfirm(
          this.$i18n.t("alert.are_you_sure"),
          this.$i18n.t("label.school_approves_vaccination_status"),
          () => {
            studentsService.setVaccinationStatus(this.student.id).then(() =>
                StudentModel.update({
                  where: this.student.id,
                  data: { vaccinationStatus: "APPROVED" },
                })
            );
          }
      );
    },
    reactivateJoker() {
      if (this.student.joker) return;
      showConfirm(
          this.$i18n.t("alert.are_you_sure"),
          this.$i18n.t("messages.are_your_sure_joker"),
          () => {
            studentsService.reactivateJoker(this.student.id).then(() =>
                StudentModel.update({
                  where: this.studentId,
                  data: { joker: true },
                })
            );
          }
      );
    },
    onClickOutside(event) {
      if (event.target && event.target.classList.contains("v-overlay__scrim")) {
        this.close();
      }
    },
    openDocumentsDialog(documentType, license) {
      this.$refs.studentDocuments.open(documentType, license)
    }
  },
};
</script>

<style scoped></style>
