<template>
  <v-sheet
    rounded
    outlined
    :color="color"
    class="list__row py-1 text-body-2"
    :class="{
      'text--disabled': disabled,
      'list__row-bordered': leftBorderColor,
    }"
    :style="{
      'border-left-color': leftBorderColor,
      border: 'thin solid rgba(0, 0, 0, 0.12) !important',
    }"
    @click="onRowClick">
    <slot />
  </v-sheet>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    to: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    borderColor: {
      type: String,
      default: "",
    },
    hasBackground: {
      type: Boolean,
      default: false,
    },
  },
  name: "ListRow",
  computed: {
    leftBorderColor() {
      return this.item.color || this.borderColor;
    },
    color() {
      if ("active" in this.item && !this.item.active) {
        return "red lighten-5";
      }

      if (this.hasBackground) {
        return "blue lighten-5";
      }

      return "";
    },
  },
  methods: {
    onRowClick() {
      if (this.to !== null) return this.$router.push(this.to);

      this.$emit("click");
    },
  },
};
</script>

<style scoped></style>
