<template>
<v-dialog
  v-model="show"
  width="1200"
  @click:outside="close"
  @keydown.esc="close"
  :scrollable="true"
>
  <v-card>
    <v-toolbar class="flex-grow-0 mb-4" dark color="primary" dense>
      <v-toolbar-title>{{ $t('label.appointment_service_expenses') }}</v-toolbar-title>
      <v-spacer/>
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
<!--      <template v-if="loadingServiceAppointment">-->
<!--        <v-skeleton-loader class="mb-2" type="heading"/>-->
<!--        <v-skeleton-loader type="text"/>-->
<!--      </template>-->

      <v-row>
        <v-col cols="12">
            <span class="text-h5">
              {{ appointmentServiceTimeLabel }}
            </span>
          <br>
          <span class="subtitle-2">{{ serviceAppointment.notes }}</span>
        </v-col>
      </v-row>

      <div class="d-flex justify-space-between my-4">
        <span class="title">{{ $t('label.expenses_list') }}</span>
      </div>

      <ValidationObserver ref="form">
        <v-row class="mb-3">
          <v-col cols="12" md="9">
            <ValidationProvider v-slot="{ errors }" name="description">
              <v-textarea
                  v-model="form.description"
                  :disabled="loading"
                  dense
                  outlined
                  rows="1"
                  :error-messages="errors"
                  :hide-details="errors.length === 0"
                  :label="$t('label.notes')"
                  :placeholder="$t('label.notes')"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="2">
            <ValidationProvider v-slot="{ errors }" name="amount">
              <v-text-field
                  v-model="form.amount"
                  :disabled="loading"
                  suffix="€"
                  label="Amount"
                  placeholder="0,00"
                  outlined
                  dense
                  :hide-details="errors.length === 0"
                  :error-messages="errors"
                  v-currency="{
                    currency: null,
                    allowNegative: false,
                    valueRange: { min: 0.0 },
                  }"
              />
            </ValidationProvider>
          </v-col>
          <v-col>
            <v-spacer/>
            <v-btn
                color="primary"
                :loading="saving || loading"
                :disabled="saving || loading"
                @click="saveExpense"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-simple-table class="mt-1" dense>
          <thead>
          <tr>
            <th class="text-left">{{ $t("label.description") }}</th>
            <th class="text-left" style="width: 140px;">{{ $t("label.amount") }}</th>
            <th style="width: 165px;text-align: right">{{ $t("label.actions") }}</th>
          </tr>
          </thead>
          <tbody>
            <template v-if="loadingExpenses">
              <tr v-for="item in [1, 2]" :key="item">
                <td>
                  <v-skeleton-loader type="table-cell"/>
                </td>
                <td>
                  <v-skeleton-loader type="table-cell"/>
                </td>
                <td>
                  <v-skeleton-loader class="d-flex justify-end" type="table-cell"/>
                </td>
              </tr>
            </template>

            <tr v-else-if="expenses.length === 0">
              <td colspan="4">
                <empty-box />
              </td>
            </tr>

            <template v-else>
              <tr class="table-row" v-for="expense in expenses" :key="expense.id">
                <td>{{ expense.description }}</td>
                <td style="width: 140px;">{{ expense.amount | currency }}</td>
                <td style="width: 165px;text-align: right">
                  <v-chip
                      :disabled="deleting !== null"
                      class="hidden-until-row-hovered mx-1"
                      link
                      label
                      x-small
                      dark
                      color="red"
                      @click.stop="destroy(expense.id)"
                  >
                    <v-icon x-small>mdi-close</v-icon>
                  </v-chip>
                </td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>

      </ValidationObserver>
    </v-card-text>

    <v-divider />

    <v-card-actions>
      <v-btn text @click="close">{{ $t("btn.close") }}</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import vehiclesService from '@/services/vehiclesService'
import { format, isSameDay, parseISO } from 'date-fns'
import currency from '@/utils/mixins/currency'
import { EventBus } from '@/EventBus'
import { showConfirm } from '@/helpers/dialogs'

export default defineComponent({
  name: "VehicleServiceExpensesDialog",
  mixins: [currency],
  data() {
    return {
      show: false,
      loadingExpenses: true,
      saving: false,
      savingDisabled: false,
      vehicleId: null,
      serviceAppointmentId: null,
      serviceAppointment: {},
      expenses: [],
      editing: null,
      deleting: null,
      form: {
        vehicleServiceId: null,
        type: "MAINTENANCE_AND_SERVICING",
        date: null,
        description: null,
        amount: 0
      }
    }
  },
  computed: {
    loading () {
      return this.loadingExpenses
    },
    appointmentServiceTimeLabel() {
      if (! this.serviceAppointment.from && ! this.serviceAppointment.to) return ""

      const parsedISOFrom = parseISO(this.serviceAppointment.from);
      const parsedISOTo = parseISO(this.serviceAppointment.to);

      if (isSameDay(parsedISOFrom, parsedISOTo)) {
        const date = format(parsedISOFrom, "dd.MM.yyyy");
        const fromTime = format(parsedISOFrom, "HH:mm");
        const toTime = format(parsedISOTo, "HH:mm");

        return date + " " + fromTime + " - " + toTime;
      }

      return format(parsedISOFrom,"dd.MM.yyyy HH:mm") + " - " +  format(parsedISOTo,"dd.MM.yyyy HH:mm");
    }
  },
  methods: {
    open(vehicleId, serviceAppointment) {
      this.show = true
      this.vehicleId = vehicleId
      this.serviceAppointmentId = serviceAppointment.id
      this.serviceAppointment = serviceAppointment || {}

      this.loadExpenses()
    },
    close() {
      this.show = false

      this.vehicleId = null
      this.serviceAppointmentId = null

      this.clearForm()
    },
    loadExpenses () {
      this.loadingExpenses = true
      vehiclesService.loadExpenses(this.vehicleId, {
        serviceAppointmentId: this.serviceAppointmentId
      })
        .then(response => {
          this.expenses = response.data || []
        })
        .catch(error => {
          const responseData = error.response.data || {};

          if (responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error" });
          }
        })
        .finally(() => {
          this.loadingExpenses = false
        })
    },
    saveExpense () {
      this.form.date = format(parseISO(this.serviceAppointment.from),"yyyy-MM-dd")
      this.form.amount = this.sanitizePrice(this.form.amount);
      this.form.vehicleServiceId = this.serviceAppointmentId

      this.saving = true
      vehiclesService.storeExpense(this.vehicleId, this.form)
        .then(response => {
          this.expenses.push(response.data)
          this.clearForm()
        })
        .catch(error => {
          this.form.amount = this.sanitizePrice(this.form.amount,",");

          this.$refs.form.reset();

          const responseData = error.response.data || {};
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({
              message: responseData.message,
              color: "error"
            });
          }

          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.saving = false
        })
    },
    destroy(id) {
      showConfirm(
        this.$t("btn.confirm"),
        this.$t("messages.are_your_sure_delete"),
        () => {
          this.deleting = true;
          vehiclesService
              .deleteExpense(this.$route.params.id, id)
              .then(() => {
                this.expenses = this.expenses.filter(item => item.id !== id);
                EventBus.$emit("expense-deleted");
              })
              .finally(() => {
                this.deleting = null;
              });
        })
    },
    clearForm() {
      this.form = {
        type: "MAINTENANCE_AND_SERVICING",
        date: null,
        description: null,
        amount: 0
      }

      this.$refs.form.reset()
    }
  }
});
</script>

<style scoped>

</style>
