<template>
  <v-list>
    <v-list-item v-for="parent in parents" :key="parent.id" :class="$vuetify.breakpoint.mobile ? 'pa-0' : ''">
      <v-list-item-avatar>
        <UserAvatar :path="parent.avatar" :alt="parent.name" :size="40" :rounded="true" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ parent.name }}</v-list-item-title>
      </v-list-item-content>

      <v-list-item-action>
        <div :class="$vuetify.breakpoint.mobile ? 'flex-column' : 'flex-row'" class="d-flex justify-end">
          <v-btn
            v-if="is18yo && !parent.isPermissionGranted"
            class="ma-1 white--text"
            color="green"
            small
            :disabled="!!approvedIdLoader || !!unlinkIdLoader"
            :loading="approvedIdLoader === parent.id"
            @click="grantAccess(parent.id)"
          >
            <v-icon left dark>mdi-check</v-icon>
            {{ $t('btn.approve') }}
          </v-btn>

          <v-btn
            class="ma-1 white--text"
            color="red"
            small
            :disabled="!!unlinkIdLoader || !!approvedIdLoader"
            :loading="unlinkIdLoader === parent.id"
            @click="unlinkParent(parent.id)"
          >
            <v-icon dark left>mdi-link-variant-off</v-icon>
            {{ $t('btn.unlink') }}
          </v-btn>
        </div>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import user from '@/utils/mixins/user'
import parentService from "@/services/parentService";
import ParentModel from "@/store/models/ParentModel";
import { showConfirm } from "@/helpers/dialogs";
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  name: "ParentsList",
  components: { UserAvatar },
  mixins: [user],
  data: () => ({
    approvedIdLoader: null,
    unlinkIdLoader: null,
  }),
  props: {
    parents: {
      type: Array,
      required: true
    },
  },
  computed: {
    is18yo () {
      return this.currentUser.is18yo;
    },
  },
  methods: {
    unlinkParent (parentId) {
      showConfirm(
        this.$t("btn.confirm"),
        this.$t("messages.are_your_sure_unlink_parent"),
        () => {
          this.unlinkIdLoader = parentId;
          parentService
            .unlinkParentFromStudent(parentId)
            .then(() => {
              ParentModel.delete(parentId)
            })
            .catch((error) => {
              const response = error.response || {};
              const responseData = response.data || {};

              if (responseData.message) {
                this.$snackbar.show({ message: responseData.message, color: "error" });
              }
            })
            .finally(() => {
              this.unlinkIdLoader = null;
            });
        });
    },
    grantAccess (parentId) {
      this.approvedIdLoader = parentId
      parentService
        .grantParentAccess(parentId)
        .then(() => {
          ParentModel.update({ data: { isPermissionGranted: true }, where: parentId })
        })
        .catch((error) => {
          const response = error.response || {};
          const responseData = response.data || {};

          if (responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error" });
          }
        })
        .finally(() => {
          this.approvedIdLoader = null
        })
    },
  }
};
</script>

<style lang="scss" scoped>

</style>
