<template>
  <div class="mb-2 d-flex flex-column">
    <v-divider />
    <v-btn
      class="text-capitalize my-1 text-center"
      color="blue"
      text
      :loading="loading"
      :disabled="loading">
      {{ $t("btn.load_more_messages") }}
    </v-btn>
    <v-divider />
  </div>
</template>

<script>
export default {
  name: "LoadMoreMessagesBtn",
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped></style>
