<template>
  <v-dialog v-model="show" persistent max-width="700">
    <ValidationObserver ref="form">
      <v-stepper v-model="step">
        <v-toolbar class="mb-2" dark color="primary" dense>
          <v-toolbar-title>{{ item.label }}</v-toolbar-title>
          <v-spacer/>
          <v-btn icon dark @click="close()"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>

        <v-stepper-header>
          <v-stepper-step
              :complete="step > 1"
              :color="step > 1 ? 'success' : 'primary'"
              :rules="[() => true]"
              step="1"
          >
            Artikel
          </v-stepper-step>

          <v-divider/>

          <v-stepper-step
              :complete="step > 2"
              :color="step > 2 ? 'success' : 'primary'"
              :rules="[() => true]"
              step="2"
          >
            Kundendaten
          </v-stepper-step>

          <v-divider/>

          <v-stepper-step step="3">Abschließen</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <!--    STEP 1    -->
          <v-stepper-content step="1">
            <div class="content" style="max-width: 350px; margin: auto">
              <div class="pa-3">
                <h4>Quickstartflyer</h4>
                <v-radio-group row v-model="form.flyers" mandatory>
                  <v-radio
                    label="Ja"
                    :value="true"
                    class="mr-10"
                    style="width: 100px"/>

                  <v-radio label="Nein" :value="false"/>
                </v-radio-group>
              </div>

              <div class="pa-3 my-5">
                <h4>Aufkleber</h4>

                <div class="d-flex flex-row">
                  <ValidationProvider name="500_sticks" :rules="!form.flyers ? 'required' : ''" v-slot="{ errors }">
                    <v-checkbox
                        v-model="stickersState"
                        :value="500"
                        label="500 Stk."
                        class="mr-10"
                        :error="errors.length > 0"
                        style="width: 100px"
                    />
                  </ValidationProvider>

                  <ValidationProvider name="1000_sticks" :rules="!form.flyers ? 'required' : ''" v-slot="{ errors }">
                    <v-checkbox
                        v-model="stickersState"
                        :value="1000"
                        label="1.000 Stk."
                        :error="errors.length > 0"
                    />
                  </ValidationProvider>
                </div>

                <v-radio-group row v-model="form.stickers.withLogo" mandatory @change="resetFile">
                  <v-radio
                    label="Mit Logo"
                    :value="true"
                    :disabled="!form.stickers.amount"
                    class="mr-10"
                    style="width: 100px"
                  />

                  <v-radio
                    label="Ohne Logo"
                    :value="false"
                    :disabled="!form.stickers.amount"
                  />
                </v-radio-group>

                <ValidationProvider name="file" :rules="form.stickers.withLogo ? 'required' : ''" v-slot="{ errors }">
                  <v-file-input
                    label="Hochladen"
                    v-model="logoFileState"
                    :error="errors.length > 0"
                    :disabled="!form.stickers.withLogo"
                    accept="image/*"
                    outlined
                    dense
                    style="width: 250px"
                  />
                </ValidationProvider>
              </div>
            </div>

            <v-btn
              color="primary"
              class="mr-2"
              @click="validateProducts"
              :disabled="!form.stickers.amount && !form.flyers"
            >
              {{ $t("btn.next") }}
            </v-btn>
          </v-stepper-content>

          <!--    STEP 2    -->
          <v-stepper-content step="2">
            <div class="pa-3 mb-10">
              <h4 class="mb-2">Rechnungsadresse:</h4>
              <v-row>
                <v-col>
                  <ValidationProvider name="school-name" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.schoolAddress.name"
                      :error="errors.length > 0"
                      label="School Name"
                      outlined
                      hide-details
                      dense
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <ValidationProvider name="first-name" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.schoolAddress.firstName"
                      :error="errors.length > 0"
                      label="First Name"
                      outlined
                      hide-details
                      dense
                    />
                  </ValidationProvider>
                </v-col>

                <v-col cols="6">
                  <ValidationProvider name="last-name" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.schoolAddress.lastName"
                      :error="errors.length > 0"
                      label="Last Name"
                      outlined
                      hide-details
                      dense
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="8">
                  <ValidationProvider name="street" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.schoolAddress.street"
                      :error="errors.length > 0"
                      label="Street"
                      outlined
                      hide-details
                      dense
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="4">
                  <ValidationProvider name="house-nr" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.schoolAddress.houseNr"
                      :error="errors.length > 0"
                      label="House Nr"
                      outlined
                      hide-details
                      dense
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <ValidationProvider name="postcode" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.schoolAddress.postcode"
                      :error="errors.length > 0"
                      label="Plz"
                      outlined
                      hide-details
                      dense
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="8">
                  <ValidationProvider name="city" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.schoolAddress.city"
                      :error="errors.length > 0"
                      label="City"
                      outlined
                      hide-details
                      dense
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-checkbox v-model="form.newDeliveryAddress" @change="setDeliveryAddress" label="Lieferung an eine andere Adresse"/>

              <template v-if="form.newDeliveryAddress">
                <h4 class="mb-2">Lieferadresse</h4>

              <v-row>
                <v-col>
                  <ValidationProvider name="school-name" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.deliveryAddress.name"
                      :error="errors.length > 0"
                      label="School Name"
                      outlined
                      hide-details
                      dense
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <ValidationProvider name="first-name" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.deliveryAddress.firstName"
                      :error="errors.length > 0"
                      label="First Name"
                      outlined
                      hide-details
                      dense
                    />
                  </ValidationProvider>
                </v-col>

                <v-col cols="6">
                  <ValidationProvider name="last-name" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.deliveryAddress.lastName"
                      :error="errors.length > 0"
                      label="Last Name"
                      outlined
                      hide-details
                      dense
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="8">
                  <ValidationProvider name="street" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.deliveryAddress.street"
                      :error="errors.length > 0"
                      label="Street"
                      outlined
                      hide-details
                      dense
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="4">
                  <ValidationProvider name="house-nr" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.deliveryAddress.houseNr"
                      :error="errors.length > 0"
                      label="House Nr"
                      outlined
                      hide-details
                      dense
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <ValidationProvider name="postcode" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.deliveryAddress.postcode"
                      :error="errors.length > 0"
                      label="Plz"
                      outlined
                      hide-details
                      dense
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="8">
                  <ValidationProvider name="city" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.deliveryAddress.city"
                      :error="errors.length > 0"
                      label="City"
                      outlined
                      hide-details
                      dense
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              </template>
            </div>
            <v-btn color="primary" class="mr-2" @click="validateContactForm">{{ $t("btn.next") }}</v-btn>
            <v-btn text @click="previousStep">{{ $t("btn.back") }}</v-btn>
          </v-stepper-content>

          <!--    STEP 3    -->
          <v-stepper-content step="3">
            <div class="pa-3 mb-5">
              <v-row class="mb-5">
                <v-col cols="6">
                  <h4 class="mb-2">Rechnungsadresse</h4>
                  <div>{{ form.schoolAddress.name }}</div>
                  <div>
                    {{ form.schoolAddress.firstName }}
                    {{ form.schoolAddress.lastName }}
                  </div>
                  <div>
                    {{ form.schoolAddress.street }}
                    {{ form.schoolAddress.houseNr }}
                  </div>
                  <div>
                    {{ form.schoolAddress.postcode }}
                    {{ form.schoolAddress.city }}
                  </div>
                </v-col>
                <v-col cols="6">
                  <h4 class="mb-2">Lieferadresse</h4>
                  <template v-if="form.newDeliveryAddress">
                    <div>{{ form.deliveryAddress.name }}</div>
                    <div>
                      {{ form.deliveryAddress.firstName }}
                      {{ form.deliveryAddress.lastName }}
                    </div>
                    <div>
                      {{ form.deliveryAddress.street }}
                      {{ form.deliveryAddress.houseNr }}
                    </div>
                    <div>
                      {{ form.deliveryAddress.postcode }}
                      {{ form.deliveryAddress.city }}
                    </div>
                  </template>
                  <div v-else>identisch</div>
                </v-col>
              </v-row>

              <h4 class="mb-2">Bestellung:</h4>
              <div v-if="form.flyers" class="d-flex justify-space-between">
                <div>Quickstartflyer</div>
                <div>0,00 €</div>
              </div>
              <div
                v-if="form.stickers.amount"
                class="d-flex justify-space-between">
                <div>Aufkleber {{ form.stickers.amount }} Stk.</div>
                <div>{{ stickersPrice | currency }}</div>
              </div>
              <div class="d-flex justify-space-between mb-3">
                <div>Versandkosten</div>
                <div>{{ deliveryPrice | currency }}</div>
              </div>

              <div class="d-flex justify-space-between">
                <div>Gesamtbetrag netto:</div>
                <div>{{ priceNett | currency }}</div>
              </div>
              <div class="d-flex justify-space-between">
                <div>+ 19% MwSt.</div>
                <div>{{ taxAmount | currency }}</div>
              </div>
              <div class="d-flex justify-space-between mt-3">
                <h4>Rechnungsbetrag.</h4>
                <h4>{{ finalPrice | currency }}</h4>
              </div>
            </div>

            <v-btn color="primary" class="mr-2" @click="confirmInvoice"
              >Kostenpflichtig bestellen</v-btn
            >
            <v-btn text @click="previousStep">{{ $t("btn.back") }}</v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import schoolService from "@/services/schoolService";
import user from "@/utils/mixins/user";
import PrintMediaModel from "@/store/models/PrintMediaModel";

export default {
  name: "PrintMediaDialog",
  mixins: [user],
  data: () => ({
    show: false,
    step: 1,
    deliveryPrice: 5.99,
    initialForm: {},
    form: {
      flyers: false,
      stickers: {
        amount: null,
        withLogo: false,
        logoFile: null,
      },
      schoolAddress: {
        name: "",
        firstName: "",
        lastName: "",
        street: "",
        houseNr: "",
        postcode: "",
        city: "",
      },
      newDeliveryAddress: false,
      deliveryAddress: {
        name: "",
        firstName: "",
        lastName: "",
        street: "",
        houseNr: "",
        postcode: "",
        city: "",
      },
    },
  }),
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("school", ["school"]),
    stickersState: {
      get() {
        return this.form.stickers.amount;
      },
      set(e) {
        this.form.stickers.amount = e;
      },
    },
    logoFileState: {
      get() {
        return this.form.stickers.logoFile;
      },
      set(e) {
        this.form.stickers.logoFile = e;
      },
    },
    stickersPrice() {
      if (this.form.stickers.amount === 500) {
        return 14.0;
      } else if (this.form.stickers.amount === 1000) {
        return 18.0;
      } else {
        return 0;
      }
    },
    priceNett() {
      return this.stickersPrice + this.deliveryPrice;
    },
    taxAmount() {
      return this.priceNett * 0.19;
    },
    finalPrice() {
      return this.priceNett + this.taxAmount;
    },
  },
  methods: {
    resetFile() {
      this.form.stickers.logoFile = null;
    },
    nextStep() {
      this.step = this.step + 1;
    },
    previousStep() {
      this.step = this.step - 1;
    },
    async validateProducts() {
      const validate = await this.$refs.form.validate();
      if (validate) this.nextStep();
    },
    async validateContactForm() {
      const validate = await this.$refs.form.validate();
      if (validate) this.nextStep();
    },
    async confirmInvoice() {
      const obj = {
        flyers: this.form.flyers,
        stickers: this.form.stickers,
        schoolAddress: this.form.schoolAddress,
        deliveryAddressStatus: this.form.newDeliveryAddress,
        deliveryAddress: this.form.deliveryAddress,
        price: {
          stickersPrice: this.stickersPrice,
          deliveryPrice: this.deliveryPrice,
          nettPrice: this.priceNett,
          tax: this.taxAmount,
          finalPrice: this.finalPrice,
        },
      };

      const formData = new FormData();
      Object.keys(obj).forEach((field) => {
        let objectValue = obj[field]
        const value = objectValue === null ? '' : objectValue;

        if (typeof value === "object" && !(value instanceof Blob)) {
          Object.keys(value).map((key) => {
            objectValue = value[key]
            formData.append(field + `[${key}]`, objectValue === null ? '' : objectValue)
          });
        } else {
          formData.set(field, value);
        }
      });
      schoolService
        .createPrintMedia(formData)
        .then((response) => {
          PrintMediaModel.insert({ data: response.data });
        })
        .catch((error) => console.error(error))
        .finally(() => this.close());
    },
    open() {
      this.show = true;

      this.step = 1;

      this.form.flyers = this.item.type === "flyer" || false;
      this.form.stickers.amount = null;
      this.form.stickers.withLogo = false;
      this.form.stickers.logoFile = null;

      this.form.schoolAddress.name = this.school.name;
      this.form.schoolAddress.firstName = this.currentUser.firstName;
      this.form.schoolAddress.lastName = this.currentUser.lastName;
      this.form.schoolAddress.street = this.school.invoiceStreet;
      this.form.schoolAddress.houseNr = this.school.invoiceHouseNr;
      this.form.schoolAddress.postcode = this.school.invoicePostcode;
      this.form.schoolAddress.city = this.school.invoiceCity;

      this.form.newDeliveryAddress = false;
    },
    close() {
      this.show = false;
      this.$refs.form.reset()
    },
    setDeliveryAddress(state) {
      this.form.deliveryAddress.name = state ? this.form.schoolAddress.name : '';
      this.form.deliveryAddress.firstName = state ? this.form.schoolAddress.firstName : '';
      this.form.deliveryAddress.lastName = state ? this.form.schoolAddress.lastName : '';
      this.form.deliveryAddress.street = state ? this.form.schoolAddress.street : '';
      this.form.deliveryAddress.houseNr = state ? this.form.schoolAddress.houseNr : '';
      this.form.deliveryAddress.postcode = state ? this.form.schoolAddress.postcode : '';
      this.form.deliveryAddress.city = state ? this.form.schoolAddress.city : '';
    }
  },
};
</script>

<style lang="scss"></style>
