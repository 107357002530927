<template>
  <v-card :loading="reloading">
    <v-card-title class="py-2 d-flex justify-space-between">
      <div class="d-flex flex-column">
        <span>{{ $t("label.unpaid_students") }}</span>
      </div>
      <v-btn icon small @click="load(true)" :loading="reloading">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <TeachersWorkTimeLoader v-if="loading" :count="5" />
    <v-list v-else-if="students.items.length" dense>
      <v-list-item v-for="student in students.items" :key="student.id">
        <v-list-item-avatar :color="student.bookAndPay ? 'secondary' : 'grey'">
          <v-icon dark>{{ student.bookAndPay ? "mdi-account-cash" : "mdi-account" }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ student.fullName }}</v-list-item-title>
          <v-list-item-subtitle v-if="student.bookAndPay" class="d-flex align-center">
            <v-tooltip v-if="student.priceListId" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-1" small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>
                {{ $t("label.priceList") }}: {{ student.priceListName }}
              </span>
            </v-tooltip>
            <span :class="student.balance <= 0 ? 'red--text' : 'green--text'">
              {{ student.balance | currency }}
            </span>
          </v-list-item-subtitle>
          <v-list-item-subtitle>{{ $t("filters.createdAt") }}: {{ student.createdAt }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="justify-end list-item-action__text-overflow-ellipsis">
          <v-chip label outlined>
            <span class="text-overflow-ellipsis">{{ student.licenses.map(item => item.name).join(", ") }}</span>
          </v-chip>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <empty-box v-else />
    <template v-if="! loading && students.items.length">
      <v-divider />
      <v-card-actions class="py-3 px-7">
        <span class="text-subtitle-1" v-if="students.count > maxAmountToShow">
          {{$t("label.plus_some_more_items", {count: students.count - maxAmountToShow}) }}
        </span>
        <v-spacer />
        <v-btn text color="blue" :to="{name: 'unpaid-students'}">
          {{ $t("btn.view_all") }}
        </v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import TeachersWorkTimeLoader from "@/components/skeleton-loaders/TeachersWorkTimeLoader";
import widgetsService from "@/services/widgetsService";

export default {
  name: "UnpaidStudents",
  components: {TeachersWorkTimeLoader},
  data() {
    return {
      students: {
        items: [],
        count: 0,
      },
      loading: true,
      reloading: false,
      maxAmountToShow: 5,
    }
  },
  mounted() {
    this.loading = true;
    this.load();
  },
  methods: {
    load(reloading = false) {
      this.reloading = reloading
      widgetsService
          .unpaidStudents()
          .then(resp => {
            this.students = resp.data
          })
          .finally(() => {
            this.loading = this.reloading = false;
          })
    }
  }
}
</script>

<style scoped>

</style>
