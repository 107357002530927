<template>
  <div class="grid" v-if="(itemsView === 'grid' || $vuetify.breakpoint.mobile) || pageView">
    <template v-if="loading">
      <v-skeleton-loader v-for="item in [1,2,3,4,5,6,7,8]" :key="item" type="image" height="80"/>
    </template>

    <slot v-else />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "GridView",
  props: {
    pageView: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("main", ["itemsView"]),
  },
};
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(306px, 1fr));
  grid-gap: 7px 7px;
  overflow-y: auto;
}
</style>
