<template>
  <v-card>
    <v-card-text>
      <v-card-subtitle class="pa-0">
        {{ $t('label.event_type_color', { type: titleType }) }}
      </v-card-subtitle>

      <v-color-picker
          mode="hexa"
          :value="bodyColor"
          @input="changeBodyColor"
          show-swatches
          swatches-max-height="200"
          hide-canvas
          hide-sliders
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "EventColorForm",
  props: {
    eventType: {
      type: String,
      required: true,
    },
    lessonTypeName: {
      type: String,
    },
    bodyColor: {
      type: String,
      default: "#000000",
    },
    fontColor: {
      type: String,
      default: "#ffffff",
    },
  },
  data: () => ({
    form: {
      bodyColor: "",
      fontColor: "",
    },
  }),
  computed: {
    titleType() {
      return (this.lessonTypeName ? this.$tc(this.lessonTypeName) : this.$t(`event_type.${this.eventType}`))
    },
  },
  mounted() {
    this.form.bodyColor = this.bodyColor;
    this.form.fontColor = this.fontColor;
  },
  methods: {
    changeBodyColor(e) {
      this.form.bodyColor = e;
      this.$set(this.form, "fontColor", this.invertColor(e));
      this.$emit("colorsUpdate", { ...this.form });
    },
    invertColor(hex) {
      if (hex.indexOf("#") === 0) {
        hex = hex.slice(1);
      }
      // convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
        throw new Error("Invalid HEX color.");
      }

      const r = parseInt(hex.slice(0, 2), 16);
      const g = parseInt(hex.slice(2, 4), 16);
      const b = parseInt(hex.slice(4, 6), 16);

      // http://stackoverflow.com/a/3943023/112731
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
    },
  },
};
</script>

<style scoped></style>
