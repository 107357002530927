<template>
  <v-dialog
    :value="show"
    max-width="1400"
    persistent
    @keydown.esc="close"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    scrollable
    eager
  >
    <v-card>
      <v-toolbar class="mb-2 flex-grow-0" dark color="primary" dense>
        <v-toolbar-title>{{ $t("label.create_or_edit_student", { createOrEdit: isEdit ? $t("label.edit") : $t("label.create") }) }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <div class="justify-center pt-5" :class="loadingItem ? 'd-flex' : 'd-none'">
          <v-progress-circular color="primary" indeterminate />
        </div>

        <ValidationObserver v-show="!loadingItem" ref="form">
          <v-row class="ma-0">
            <v-col cols="12" sm="12" md="4" lg="4">
              <v-row>
                <v-col cols="12" class="d-flex justify-center mt-3">
                  <UploadBtn @file-picked="onFilePick" accept="image/jpeg,image/png" input-name="avatar">
                    <v-avatar class="elevation-2" size="150">
                      <v-img v-if="avatarImage" :src="avatarImage" />
                      <v-icon v-else size="100">mdi-account</v-icon>
                    </v-avatar>
                  </UploadBtn>
                </v-col>
              </v-row>

              <div class="mb-3">
                <span class="text-subtitle-1 font-weight-medium">{{ $t("label.profile") }}</span>
                <v-divider />
              </div>

              <v-row dense>
                <v-col cols="12" sm="12">
                  <ValidationProvider v-slot="{ errors }" name="personalId">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.personalId"
                      :label="$t('form.student_ID')"
                      :placeholder="$t('form.student_ID')"
                      autocomplete="cc-csc"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" name="email">
                    <v-text-field
                      dense
                      v-model="form.email"
                      :label="$t('form.email')"
                      :placeholder="$t('form.email')"
                      outlined
                      type="email"
                      required
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" v-if="!isEdit">
                  <ValidationProvider v-slot="{ errors }" name="password">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.password"
                      :label="$t('form.password_one_time')"
                      :placeholder="$t('form.password_one_time')"
                      type="text"
                      required
                      :error-messages="errors"
                      :hint="$t('messages.system_generate_credentials')"
                      persistent-hint>
                      <template v-slot:append>
                        <v-icon
                          :class="{ 'mr-2': isEdit && form.password.length }"
                          @click="refreshPassword"
                        >
                          mdi-refresh
                        </v-icon>
                        <v-icon
                          color="info"
                          v-if="isEdit && form.password.length"
                          @click="isOpenChangePasswordModal = true"
                        >
                          mdi-content-save
                        </v-icon>
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row v-if="!isEdit" dense>
                <v-col cols="12">
                  <v-sheet class="py-2 px-3" outlined rounded>
                    <ValidationProvider name="sendEmail">
                      <v-switch
                        class="mt-0"
                        dense
                        v-model="form.sendEmail"
                        inset
                        hide-details
                        :label="$t('form.send_email')"
                        color="primary"
                      />
                    </ValidationProvider>
                  </v-sheet>
                </v-col>
              </v-row>

              <v-row v-if="isSchoolAdministrator" dense>
                <v-col cols="12">
                  <v-sheet class="py-2 px-3" outlined rounded>
                    <ValidationProvider name="active">
                      <v-switch
                        class="ml-1 mt-0"
                        dense
                        v-model="form.active"
                        inset
                        hide-details
                        :label="$t('form.active')"
                        placeholder=""
                        color="primary"
                      />
                    </ValidationProvider>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-select
                    :items="[
                      { text: this.$t('gender.M'), value: 'M' },
                      { text: this.$t('gender.W'), value: 'W' },
                      { text: this.$t('gender.D'), value: 'D' },
                    ]"
                    outlined
                    hide-details
                    dense
                    :placeholder="$t('label.gender')"
                    :label="$t('label.gender')"
                    v-model="form.gender"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-alert
                v-if="item && item.educationStatus === 'registered'"
                dense
                border="left"
                type="warning"
                outlined
                prominent
              >
                {{ $t("messages.reminder_to_set_education") }}
              </v-alert>

              <v-tabs
                  v-model="activeTab"
                  :color="hasActiveTabErrors ? 'error' : 'primary'"
                  :show-arrows="false"
                  prev-icon=""
              >
                <v-tab>
                  <v-badge
                      icon="mdi-check"
                      :color="invalidTabFields['personal'].length ? 'error' : 'primary'"
                      :content="invalidTabFields['personal'].length"
                      :value="!!invalidTabFields['personal'].length"
                  >
                    <span :class="{ 'error--text' :  invalidTabFields['personal'].length}">
                      {{ $t("label.personal_info") }}
                    </span>
                  </v-badge>
                </v-tab>

                <v-tab>
                  <v-badge
                      icon="mdi-check"
                      :color="invalidTabFields['education'].length ? 'error' : 'primary'"
                      :content="invalidTabFields['education'].length"
                      :value="!!invalidTabFields['education'].length"
                  >
                    <span :class="{ 'error--text' :  invalidTabFields['education'].length}">
                      {{ $t("label.education") }}
                    </span>
                  </v-badge>
                </v-tab>

                <v-tab v-if="isSchoolAdministrator">
                  <v-badge
                      icon="mdi-check"
                      :color="invalidTabFields['additional_details'].length ? 'error' : 'primary'"
                      :content="invalidTabFields['additional_details'].length"
                      :value="!!invalidTabFields['additional_details'].length"
                  >
                    <span :class="{ 'error--text' :  invalidTabFields['additional_details'].length}">
                      {{ $t("label.additional_details") }}
                    </span>
                  </v-badge>
                </v-tab>

                <v-tab v-if="isSchoolAdministrator">
                  <v-badge
                      icon="mdi-check"
                      :color="invalidTabFields['school'].length ? 'error' : 'primary'"
                      :content="invalidTabFields['school'].length"
                      :value="!!invalidTabFields['school'].length"
                  >
                    <span :class="{ 'error--text' :  invalidTabFields['school'].length}">
                      {{ $t("label.school") }}
                    </span>
                  </v-badge>
                </v-tab>

                <v-tab v-if="isSchoolAdministrator">
                  <v-badge
                      icon="mdi-check"
                      :color="invalidTabFields['payment'].length ? 'error' : 'primary'"
                      :content="invalidTabFields['payment'].length"
                      :value="!!invalidTabFields['payment'].length"
                  >
                    <span :class="{ 'error--text' :  invalidTabFields['payment'].length}">
                      {{ $t("label.payment") }}
                    </span>
                  </v-badge>
                </v-tab>
              </v-tabs>


              <v-tabs-items v-model="activeTab">
                <v-tab-item class="py-5" :transition="false" eager>
                  <div class="mb-3">
                    <span class="text-subtitle-1 font-weight-medium">{{ $t("label.general") }}</span>
                    <v-divider />
                  </div>

                  <v-row dense>
                    <v-col cols="12" sm="12" md="4" lg="4">
                      <ValidationProvider v-slot="{ errors }" name="firstName">
                        <v-text-field
                          dense
                          v-model="form.firstName"
                          :label="$t('form.first_name')"
                          :placeholder="$t('form.first_name')"
                          outlined
                          :error-messages="errors"
                          required
                          autocomplete="cc-csc"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="4">
                      <ValidationProvider dense v-slot="{ errors }" name="lastName">
                        <v-text-field
                          dense
                          v-model="form.lastName"
                          :label="$t('form.last_name')"
                          :placeholder="$t('form.last_name')"
                          outlined
                          :error-messages="errors"
                          autocomplete="cc-csc"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="4">
                      <ValidationProvider dense v-slot="{ errors }" name="birthName">
                        <v-text-field
                          dense
                          v-model="form.birthName"
                          :label="$t('form.birth_name')"
                          :placeholder="$t('form.birth_name')"
                          outlined
                          :error-messages="errors"
                          autocomplete="cc-csc"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="4">
                      <ValidationProvider dense v-slot="{ errors }" name="placeOfBirth">
                        <v-text-field
                          dense
                          v-model="form.placeOfBirth"
                          :label="$t('form.place_of_birth')"
                          :placeholder="$t('form.place_of_birth')"
                          outlined
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="4">
                      <ValidationProvider v-slot="{ errors }" name="birthDate">
                        <v-menu
                          v-model="menuBirthDate"
                          :close-on-content-click="false"
                          :nudge-bottom="-20"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              dense
                              :value="birthDateFormat"
                              :label="$t('form.birthday')"
                              :placeholder="$t('form.birthday')"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                              :error-messages="errors"
                              clearable
                              @click:clear="form.birthDate = ''" />
                          </template>

                          <v-date-picker
                            ref="birthDatePicker"
                            @input="onInputBirthDate"
                            :value="minDateOfBirthFromToday"
                            no-title
                            scrollable
                            :max="minDateOfBirthFromToday"
                          />
                        </v-menu>
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" sm="12" md="4" lg="4">
                      <ValidationProvider dense v-slot="{ errors }" name="nationality">
                        <v-combobox
                          dense
                          :items="countries"
                          v-model="form.nationality"
                          :menu-props="{ top: $vuetify.breakpoint.mobile, offsetY: true, contentClass: 'fit-parent-width' }"
                          outlined
                          :label="$t('form.nationality')"
                          :placeholder="$t('form.nationality')"
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <div class="mb-3">
                    <span class="text-subtitle-1 font-weight-medium">{{ $t("label.contact") }}</span>
                    <v-divider />
                  </div>

                  <v-row dense>
                    <v-col cols="12" sm="12" md="4">
                      <ValidationProvider v-slot="{ errors }" name="emailOptional">
                        <v-text-field
                          dense
                          v-model="form.emailOptional"
                          :label="$t('form.email_opt')"
                          :placeholder="$t('form.email_opt')"
                          outlined
                          type="email_optional"
                          required
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <ValidationProvider v-slot="{ errors }" name="phone">
                        <v-text-field
                          dense
                          v-model="form.phone"
                          :label="$t('form.phone_mobile')"
                          :placeholder="$t('form.phone_mobile')"
                          outlined
                          required
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <ValidationProvider v-slot="{ errors }" name="landline">
                        <v-text-field
                          dense
                          v-model="form.landline"
                          :label="$t('form.phone_landline')"
                          :placeholder="$t('form.phone_landline')"
                          outlined
                          required
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <div class="d-flex">
                    <span class="text-subtitle-1 font-weight-medium">{{ $t("label.address") }}</span>
                    <v-spacer />
                    <address-lookup-dialog @address-selected="fillAddress" />
                  </div>
                  <v-divider  class="mb-3" />

                  <v-row dense>
                    <v-col cols="12" sm="6" md="5">
                      <ValidationProvider v-slot="{ errors }" name="street">
                        <v-text-field
                          outlined
                          dense
                          v-model="form.street"
                          :label="$t('form.street')"
                          :placeholder="$t('form.street')"
                          type="text"
                          required
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" sm="6" md="2">
                      <ValidationProvider v-slot="{ errors }" name="houseNr">
                        <v-text-field
                          outlined
                          dense
                          v-model="form.houseNr"
                          :label="$t('form.house')"
                          :placeholder="$t('form.house')"
                          type="text"
                          required
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" sm="6" md="2">
                      <ValidationProvider v-slot="{ errors }" name="postcode">
                        <v-text-field
                          outlined
                          dense
                          v-model="form.postcode"
                          :label="$t('form.plz')"
                          :placeholder="$t('form.plz')"
                          type="text"
                          required
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" sm="6" md="3">
                      <ValidationProvider v-slot="{ errors }" name="city">
                        <v-text-field
                          outlined
                          dense
                          v-model="form.city"
                          :label="$t('form.city')"
                          :placeholder="$t('form.city')"
                          type="text"
                          required
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-tab-item>

                <v-tab-item class="py-5" :transition="false" eager>
                  <v-row v-if="isSchoolAdministrator" dense>
                    <v-col cols="12" sm="12" md="6">
                      <ValidationProvider v-slot="{ errors }" name="educationStatus">
                        <v-select
                          dense
                          v-model="form.educationStatus"
                          :label="$t('form.status')"
                          :placeholder="$t('form.status')"
                          outlined
                          :items="educationStatusOptions"
                          required
                          :error-messages="errors"
                          @change="onChangeEducationStatus"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="6">
                      <ValidationProvider v-slot="{ errors }" name="maxLessonsAmountPerWeek">
                        <v-text-field
                          dense
                          v-model="form.maxLessonsAmountPerWeek"
                          :label="$t('form.max_practices_per_week')"
                          :hint="$t('messages.unlimited_for_empty_field')"
                          persistent-hint
                          :placeholder="maxLessonsAmountPerWeekPlaceholder"
                          outlined
                          type="number"
                          min="0"
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col v-if="!isSchoolAdministrator" cols="12" :md="isSchoolAdministrator ? 6 : 4">
                      <ValidationProvider v-slot="{ errors }" name="maxLessonsAmountPerWeek">
                        <v-text-field
                          dense
                          v-model="form.maxLessonsAmountPerWeek"
                          :label="$t('form.max_practices_per_week')"
                          :hint="$t('messages.unlimited_for_empty_field')"
                          persistent-hint
                          :placeholder="maxLessonsAmountPerWeekPlaceholder"
                          outlined
                          type="number"
                          min="0"
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" :md="isSchoolAdministrator ? 6 : 4">
                      <ValidationProvider v-slot="{ errors }" name="maxLessonsAmountPerDay">
                        <v-text-field
                          dense
                          v-model="form.maxLessonsAmountPerDay"
                          :label="$t('form.max_practices_per_day')"
                          :hint="$t('messages.unlimited_for_empty_field')"
                          persistent-hint
                          :placeholder="maxLessonsAmountPerDayPlaceholder"
                          outlined
                          type="number"
                          min="0"
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" :md="isSchoolAdministrator ? 6 : 4">
                      <ValidationProvider v-slot="{ errors }" name="maxLessonsDurationPerDay">
                        <v-text-field
                          dense
                          v-model="form.maxLessonsDurationPerDay"
                          :label="$t('form.max_duration_per_day')"
                          :hint="$t('messages.unlimited_for_empty_field')"
                          persistent-hint
                          :placeholder="maxLessonsDurationPerDayPlaceholder"
                          outlined
                          type="number"
                          min="0"
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <div class="mb-3">
                    <span class="text-subtitle-1 font-weight-medium">
                      {{ $t("form.licenses") }}
                    </span>

                    <v-divider />
                  </div>

                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <div class="text-caption warning--text mb-1" v-if="selectedLicense && form.licensesIds.length === 0">
                        {{ $t("messages.student_has_chosen", { license: selectedLicense.name }) }}
                      </div>
                      <ValidationProvider v-slot="{ errors }" name="licensesIds">
                        <v-autocomplete
                            :key="form.licensesIds.length"
                            dense
                            outlined
                            :menu-props="{ top: true, offsetY: true }"
                            :label="$t('form.licenses')"
                            :placeholder="$t('form.licenses')"
                            :items="licensesOptions"
                            :error-messages="errors"
                            @change="onLicenseChange"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <template v-if="form.licensesIds.length">
                    <div>
                      <ValidationProvider
                          v-for="item in form.licensesRequirements"
                          :key="item.licenseId"
                          v-slot="{ errors }"
                          name="license"
                          :vid="'license.' + item.licenseId + '.requirement'"
                      >
                        <v-row dense :class="{'mb-2': errors.length === 0}">
                          <v-col cols="4" md="2">
                            <v-chip
                              pill close
                              @click:close="handleRemoveLicense(item.licenseId)"
                            >
                              {{ getLicenseName(item.licenseId) }}
                            </v-chip>
                          </v-col>

                          <v-col cols="8" md="10">
                            <RequirementTemplateSelector
                                @templateSelect="val => selectTemplate(val, item.licenseId)"
                                @clickAppend="openLicenseRequirementsDialog(item.licenseId)"
                                :licenseId="item.licenseId"
                                :templateId="item.licenseRequirementId"
                            />
                          </v-col>
                        </v-row>

                        <div v-show="errors.length > 0" class="text-caption red--text my-2">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                  </template>
                </v-tab-item>

                <v-tab-item v-if="isSchoolAdministrator"  class="py-5" :transition="false" eager>
                  <settings-responsive-row :title="$t('label.object_of_contract')" :description="contractTypeDescription">
                    <v-select
                      class="mt-0"
                      dense
                      outlined
                      hide-details
                      :items="contractTypes"
                      v-model="form.objectOfContract"
                      clearable
                      :label="$t('label.object_of_contract')"
                      :placeholder="$t('label.object_of_contract')"
                    />
                  </settings-responsive-row>
                  <settings-responsive-row :title="$t('label.already_owns_a_license')">
                    <v-switch
                      class="mt-0"
                      dense
                      inset
                      hide-details
                      :label="$t('btn.yes')"
                      :placeholder="$t('btn.yes')"
                      color="primary"
                      v-model="form.ownsDrivingLicense"
                    />
                    <v-text-field
                        :label="$t('label.driving_license_number')"
                        :placeholder="$t('label.driving_license_number')"
                        class="mt-1"
                        hide-details
                        dense
                        outlined
                        v-if="form.ownsDrivingLicense"
                        v-model="form.drivingLicenseNumber"
                    />
                    <v-text-field
                        :label="$t('label.issued_by')"
                        :placeholder="$t('label.issued_by')"
                        class="mt-1"
                        hide-details
                        dense
                        outlined
                        v-if="form.ownsDrivingLicense"
                        v-model="form.drivingLicenseIssuedBy"
                    />
                    <v-menu
                      v-model="menuDrivingLicenseIssuedOn"
                      :close-on-content-click="false"
                      :nudge-bottom="-20"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      v-if="form.ownsDrivingLicense"
                    >
                      <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            :value="drivingLicenseIssuedOnFormat"
                            :label="$t('label.issued_on')"
                            :placeholder="$t('label.issued_on')"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            @click:clear="form.drivingLicenseIssuedOn = ''"
                            hide-details
                            class="mt-1"
                          />
                      </template>
                      <v-date-picker
                          ref="scheduledPriceCategoryPicker"
                          @input="onInputDrivingLicenseIssuedOnFrom"
                          :value="form.drivingLicenseIssuedOn"
                          no-title
                          scrollable
                          :max="(new Date()).toISOString()"
                      />
                    </v-menu>
                    <v-select
                        multiple
                        clearable
                        :label="$t('label.owned_licenses')"
                        :placeholder="$t('label.owned_licenses')"
                        class="mt-1"
                        hide-details
                        dense
                        outlined
                        v-if="form.ownsDrivingLicense"
                        v-model="form.ownedLicenses"
                        :items="allLicenses"
                    />
                  </settings-responsive-row>
                  <settings-responsive-row :title="$t('label.wears_visual_aid')">
                    <v-switch
                        class="mt-0"
                        dense
                        inset
                        hide-details
                        :label="$t('btn.yes')"
                        :placeholder="$t('btn.yes')"
                        color="primary"
                        v-model="form.wearsVisualAid"
                    />
                  </settings-responsive-row>
                  <settings-responsive-row :title="$t('label.has_deficiencies')" :divider-after="false">
                    <v-switch
                        class="mt-0"
                        dense
                        inset
                        hide-details
                        :label="$t('btn.yes')"
                        :placeholder="$t('btn.yes')"
                        color="primary"
                        v-model="form.hasDeficiencies"
                    />
                    <v-text-field
                      class="mt-2"
                      dense
                      hide-details
                      outlined
                      :label="$t('label.deficiencies')"
                      :placeholder="$t('label.deficiencies')"
                      v-if="form.hasDeficiencies"
                      v-model="form.deficiencies"
                    />
                  </settings-responsive-row>
                </v-tab-item>

                <v-tab-item v-if="isSchoolAdministrator" class="py-5" :transition="false" eager>
                  <v-row dense>
                    <v-col cols="12">
                      <ValidationProvider v-slot="{ errors }" name="officeId">
                        <v-select
                          dense
                          v-model="form.officeId"
                          :label="$t('form.office')"
                          :placeholder="$t('form.office')"
                          outlined
                          :items="offices"
                          required
                          :error-messages="errors"
                          clearable
                        />
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12">
                      <ValidationProvider v-slot="{ errors }" name="teachersIds">
                        <v-select
                          dense
                          v-model="form.teachersIds"
                          :label="$t('label.teachers')"
                          :placeholder="$t('label.teachers')"
                          outlined
                          :items="teachers"
                          multiple
                          clearable
                          :error-messages="errors"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index < 4" small>
                              <span>{{ item.text }}</span>
                            </v-chip>
                            <span v-if="index === 4" class="grey--text caption">(+{{ form.teachersIds.length - 3 }} others)</span>
                          </template>
                        </v-select>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-tab-item>

                <v-tab-item v-if="isSchoolAdministrator" class="py-5" :transition="false" eager>
                  <settings-responsive-row :title="$t('label.book&Pay')" :description="$t('label.book&Pay_description')">
                    <v-switch
                        class="mt-0"
                        dense
                        v-model="form.bookAndPay"
                        @change="onBookAndPayChange"
                        inset
                        hide-details
                        :label="$t('label.active')"
                        :placeholder="$t('label.active')"
                        color="primary"
                        :disabled="hasPaymentHistory"
                    />
                  </settings-responsive-row>
                  <settings-responsive-row
                    :title="$t('form.price_category')"
                    :description="$t('label.price_category_description')"
                    :divider-after="isEdit && form.priceCategoryId !== ''"
                  >
                    <template
                      #description
                      v-if="hasScheduledPriceCategory && form.priceCategoryId === form.scheduledPriceCategoryId"
                    >
                      <v-alert type="warning" border="left" colored-border elevation="1">
                        {{ $t('label.scheduled_price_list_will_be_replaced') }}
                      </v-alert>
                    </template>
                    <ValidationProvider v-slot="{ errors }" name="priceCategoryId">
                      <v-select
                          :menu-props="{ contentClass: 'fit-parent-width' }"
                          dense
                          v-model="form.priceCategoryId"
                          :label="$t('form.price_category')"
                          :placeholder="$t('form.price_category')"
                          outlined
                          :items="priceCategoriesAsOptions"
                          required
                          :error-messages="errors"
                          :disabled="hasPaymentHistory"
                      />
                    </ValidationProvider>
                  </settings-responsive-row>
                  <settings-responsive-row
                    v-if="isEdit && form.priceCategoryId"
                    :title="$t('form.scheduled_price_category')"
                    :description="$t('label.scheduled_price_category_description')"
                    :divider-after="false"
                  >
                    <template #description v-if="hasScheduledPriceCategory">
                      <v-btn
                        color="warning"
                        @click="removeScheduledPriceLists"
                        :loading="removingScheduledPriceLists"
                        :disabled="removingScheduledPriceLists"
                      >
                        <v-icon left>mdi-clock-remove-outline</v-icon>
                        {{ $t('btn.remove_scheduled_price_list') }}
                      </v-btn>
                    </template>
                    <ValidationProvider v-slot="{ errors }" name="scheduledPriceCategoryId">
                      <v-select
                        :disabled="removingScheduledPriceLists"
                        :menu-props="{ contentClass: 'fit-parent-width' }"
                        dense
                        v-model="form.scheduledPriceCategoryId"
                        :label="$t('form.scheduled_price_category')"
                        :placeholder="$t('form.scheduled_price_category')"
                        outlined
                        :items="priceCategoriesAsOptions"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                    <v-menu
                      :disabled="removingScheduledPriceLists"
                      v-model="menuScheduledPriceListFrom"
                      :close-on-content-click="false"
                      :nudge-bottom="-20"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider v-slot="{ errors }" name="scheduledPriceCategoryFrom">
                          <v-text-field
                            dense
                            :value="scheduledPriceCategoryFromFormat"
                            :label="$t('form.scheduled_price_category_from')"
                            :placeholder="$t('form.scheduled_price_category_from')"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            @click:clear="form.scheduledPriceCategoryFrom = ''"
                            :error-messages="errors"
                          />
                        </ValidationProvider>
                      </template>

                      <v-date-picker
                        ref="scheduledPriceCategoryPicker"
                        @input="onInputScheduledPriceCategoryFrom"
                        :value="form.scheduledPriceCategoryFrom"
                        no-title
                        scrollable
                        :min="minDateForSchedulingPriceCategory"
                      />
                    </v-menu>
                  </settings-responsive-row>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>

      <v-divider class="mt-auto" />

      <v-card-actions>
        <v-btn text @click="close">{{$t("btn.close")}}</v-btn>

        <v-spacer />

        <v-btn
          color="primary"
          @click="save"
          :loading="loadingForm"
          :disabled="loadingForm || loadingItem"
        >
          <v-icon left>mdi-content-save{{isEdit ? '-edit' : ''}}</v-icon>
          {{ $t("btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <LicenseTemplateCreateDialog ref="licenseDialog" @createdForLicense="onLicenseRequirementsTemplateCreated"/>
  </v-dialog>
</template>

<script>
import i18n from "@/plugins/i18n"
import { mapGetters } from "vuex"
import {addDays, format, sub} from "date-fns"
import Password from "@/helpers/password"

import licenses from "@/utils/mixins/licenses"
import user from "@/utils/mixins/user"

import UploadBtn from "@/components/UploadBtn"
import RequirementTemplateSelector from "@/components/student/RequirementTemplateSelector"
import LicenseTemplateCreateDialog from "@/components/license-requirements/LicenseTemplateCreateDialog"

import LicenseModel from "@/store/models/LicenseModel"
import TeacherModel from "@/store/models/TeacherModel"
import ProductCategoryModel from "@/store/models/ProductCategoryModel"
import LicenseRequirementModel from '@/store/models/LicenseRequirementModel'
import StudentModel from "@/store/models/StudentModel"

import studentsService from "@/services/studentsService"
import SettingsResponsiveRow from "@/components/settings/SettingsResponsiveRow.vue";
import AddressLookupDialog from "@/components/AddressLookupDialog.vue";

import countriesTranslations from "@/locales/countries.json";
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: "StudentFormDialog",
  mixins: [user, licenses],
  components: {
    AddressLookupDialog,
    SettingsResponsiveRow, UploadBtn, RequirementTemplateSelector, LicenseTemplateCreateDialog },
  props: {
    redirectAfterCreation: {
      type: Boolean,
      default: true
    },
    afterCreatedHandler: {
      type: [Function, null],
      default: null
    }
  },
  data: () => ({
    show: false,
    isEdit: false,
    item: null,
    vouchers: [],
    offices: [],
    educationStatuses: [],
    contractTypes: [
      {text: i18n.t('label.paragraph7'), value: 'PARAGRAPH_7'},
      {text: i18n.t('label.reissue'), value: 'REISSUE'},
      {text: i18n.t('label.conversion'), value: 'CONVERSION'},
    ],
    avatarImage: "",
    activeTab: "",
    username: "",
    initialForm: {},
    hasScheduledPriceCategory: false,
    removingScheduledPriceLists: false,
    form: {
      personalId: "",
      avatar: "",
      firstName: "",
      lastName: "",
      birthName: "",
      birthDate: "",
      placeOfBirth: "",
      nationality: "",
      gender: "",
      email: "",
      emailOptional: "",
      phone: "",
      landline: "",
      password: "",
      officeId: "",
      priceCategoryId: "",
      maxLessonsAmountPerWeek: "",
      maxLessonsAmountPerDay: "",
      maxLessonsDurationPerDay: "",
      priceCategory: "",
      educationStatus: "registered",
      city: "",
      street: "",
      houseNr: "",
      postcode: "",
      licensesIds: [],
      licensesRequirements: [],
      teachersIds: [],
      vouchersIds: [],
      active: true,
      bookAndPay: true,
      sendEmail: false,
      generatePdf: true,
      releaseUsedEmail: false,
      scheduledPriceCategoryId: "",
      scheduledPriceCategoryFrom: "",
      objectOfContract: '',
      ownsDrivingLicense: false,
      drivingLicenseNumber: '',
      drivingLicenseIssuedBy: '',
      drivingLicenseIssuedOn: '',
      ownedLicenses: [],
      wearsVisualAid: false,
      hasDeficiencies: false,
      deficiencies: '',
    },
    formObserver: { errors: {} },
    menuBirthDate: false,
    menuScheduledPriceListFrom: false,
    menuDrivingLicenseIssuedOn: false,
    loadingItem: false,
    loadingForm: false,
    imageUrl: "",
    imageFile: "",
    loadingOnTimePassword: false,
    hasPaymentHistory: false,
  }),
  computed: {
    ...mapGetters("school", ["school"]),
    teachers() {
      return TeacherModel.all()
        .filter((teacher) => {
          return this.form.licensesIds.find((licenseId) =>
            teacher.licensesIds.includes(licenseId)
          );
        })
        .map((item) => ({ text: item.fullName, value: item.id }));
    },
    birthDateFormat() {
      return this.form.birthDate
        ? format(new Date(this.form.birthDate), "dd.MM.yyyy")
        : "";
    },
    scheduledPriceCategoryFromFormat() {
      return this.form.scheduledPriceCategoryFrom
          ? format(new Date(this.form.scheduledPriceCategoryFrom), "dd.MM.yyyy")
          : "";
    },
    drivingLicenseIssuedOnFormat() {
      return this.form.drivingLicenseIssuedOn
          ? format(new Date(this.form.drivingLicenseIssuedOn), "dd.MM.yyyy")
          : "";
    },
    minDateForSchedulingPriceCategory () {
      return format(addDays(new Date(), 1), "yyyy-MM-dd")
    },
    defaultPriceCategory() {
      return ProductCategoryModel.query().where('isDefault',true).first()
    },
    imageAvatar() {
      if (typeof this.form.avatar === "string") return this.form.avatar;

      return this.imageURL;
    },
    minDateOfBirthFromToday () {
      return format(sub(new Date(), { years: 14 }), 'yyyy-MM-dd')
    },
    maxLessonsAmountPerWeekPlaceholder() {
      if (!this.form.maxLessonsAmountPerWeek) return i18n.t("label.unlimited");
      return this.form.maxLessonsAmountPerWeek.toString();
    },
    maxLessonsAmountPerDayPlaceholder() {
      if (!this.form.maxLessonsAmountPerDay) return i18n.t("label.unlimited");
      return this.form.maxLessonsAmountPerDay.toString();
    },
    maxLessonsDurationPerDayPlaceholder() {
      if (!this.form.maxLessonsDurationPerDay) return i18n.t("label.unlimited");
      return this.form.maxLessonsDurationPerDay.toString();
    },
    invalidTabFields() {
      const tabsFields = {
        personal: [
          "firstName",
          "lastName",
          "birthName",
          "birthDate",
          "placeOfBirth",
          "nationality",
          "emailOptional",
          "phone",
          "landline",
          "city",
          "street",
          "houseNr",
          "postcode",
        ],
        education: [
          "licensesIds",
          "maxLessonsAmountPerWeek",
          "maxLessonsAmountPerDay",
          "maxLessonsDurationPerDay",
          "educationStatus",
        ],
        additional_details: [
        ],
        school: [
          "officeId",
          "teachersIds"
        ],
        payment: [
          "bookAndPay",
          "priceCategoryId"
        ],
      };

      Object.keys(tabsFields).forEach((tab) => {
        tabsFields[tab] = tabsFields[tab].filter((field) => {
          return this.formObserver.errors[field]?.length > 0
        });
      });

      return tabsFields;
    },
    hasActiveTabErrors() {
      const invalidTabKeys = Object.keys(this.invalidTabFields) || [];
      const invalidTabErrors = this.invalidTabFields[invalidTabKeys[this.activeTab]] || [];

      return invalidTabErrors.length > 0;
    },
    licensesOptions() {
      let licenses = LicenseModel.all();

      if (this.isTeacher) {
        const teacherLicensesIds = this.currentUser.licensesIds || [];

        licenses = licenses.filter((license) => {
          return teacherLicensesIds.includes(license.id);
        });
      }

      return licenses
        .map((license) => ({ text: license.name, value: license.id }))
        .filter(item => ! this.form.licensesIds.includes(item.value));
    },
    selectedLicense() {
      if (this.item && this.item.selectedLicenseId)
        return this.licensesById[this.item.selectedLicenseId];

      return null;
    },
    allLicenses() {
      let licenses = LicenseModel.all();

      return licenses.map((license) => ({ text: license.name, value: license.id }));
    },
    educationStatusOptions() {
      return Object.keys(this.educationStatuses)
        .map((key) => {
          return { text: this.educationStatuses[key], value: key };
        })
        .filter((item) => {
          if (item.value === "registered")
            return !(this.item && this.item.educationStatus === "learning");
          return item.value !== "registered";
        });
    },
    priceCategories() {
      return ProductCategoryModel.query()
        .where("licensesIds", (licensesIds) => {
          return (
            licensesIds.filter((licenseId) =>
              this.form.licensesIds.includes(licenseId)
            ).length > 0
          );
        })
        .get();
    },
    priceCategoriesAsOptions() {
      return ProductCategoryModel.all().map((item) => ({
        text: item.name,
        value: item.id,
      }));
    },
    countries() {
      const countries = this.$i18n.locale in countriesTranslations ?
        countriesTranslations[this.$i18n.locale] : countriesTranslations.de;

      return Object.keys(countries)
        .map((key) => ({ text: countries[key], value: key }))
        .sort((prev, next) => prev.text.localeCompare(next.text));
    },
    contractTypeDescription() {
      const descriptions = {
        PARAGRAPH_7: i18n.t('label.paragraph7_description'),
        REISSUE: i18n.t('label.reissue_description'),
        CONVERSION: i18n.t('label.conversion_description'),
      }

      if (! this.form.objectOfContract || ! (this.form.objectOfContract in descriptions)) return '';

      return descriptions[this.form.objectOfContract];
    }
  },
  created() {
    this.initialForm = { ...this.form };
  },
  mounted() {
    this.formObserver = this.$refs.form;
  },
  watch: {
    menuBirthDate(val) {
      val &&
      setTimeout(() => (this.$refs.birthDatePicker.activePicker = "YEAR"));
    },
  },
  methods: {
    fillAddress(address) {
      this.form.city = address.city
      this.form.street = address.street
      this.form.houseNr = address.housenumber
      this.form.postcode = address.postcode
    },
    openLicenseRequirementsDialog(val) {
      this.$refs.licenseDialog.openForLicense(val)
    },
    selectTemplate(val, licenseId) {
      const index = this.form.licensesRequirements.findIndex(item => item.licenseId === licenseId)
      this.form.licensesRequirements[index].licenseRequirementId = val
    },
    onLicenseChange(val) {
      if (val === null) return

      this.form.licensesIds = [...this.form.licensesIds, val]

      const getTemplate = LicenseRequirementModel.query()
          .where('active', true)
          .where(item => item.licenses.includes(val))
          .get() || []

      this.form.licensesRequirements = [
        ...this.form.licensesRequirements,
        { licenseId: val, licenseRequirementId: getTemplate[0].id }
      ]

      this.$refs.form.setErrors({licensesIds: []})
    },
    getLicenseName(val) {
      return LicenseModel.find(val)?.name || ''
    },
    open(item) {
      this.show = true;
      this.activeTab = 0;

      this.loadFormData();

      this.form.password = Password.generate(8);
      this.form.maxLessonsAmountPerWeek = this.school.maxLessonsAmountPerWeek;
      this.form.maxLessonsAmountPerDay = this.school.maxLessonsAmountPerDay;
      this.form.maxLessonsDurationPerDay = this.school.maxLessonsDurationPerDay;
      this.form.bookAndPay = this.school.isWithStripePayment;

      if (item) {
        this.isEdit = true;
        this.loadingItem = true;
        studentsService
          .loadItem(item.id)
          .then((resp) => {
            const data = resp.data;
            const licensesIds = data.licensesIds || []

            this.item = data;
            this.item.licensesIds = [...licensesIds]

            this.username = data.username;

            this.form.personalId = data.personalId;
            this.form.firstName = data.firstName;
            this.form.lastName = data.lastName;
            this.form.birthName = data.birthName;
            this.form.birthDate = data.birthDate;
            this.form.placeOfBirth = data.placeOfBirth;
            this.form.gender = data.gender;
            this.form.email = data.email;
            this.form.emailOptional = data.emailOptional;
            this.form.phone = data.phone;
            this.form.landline = data.landline;
            this.form.active = data.active;
            this.form.city = data.city;
            this.form.street = data.street;
            this.form.houseNr = data.houseNr;
            this.form.postcode = data.postcode;
            this.form.officeId = data.officeId ? data.officeId : "";
            this.form.licensesIds = licensesIds;
            this.form.licensesRequirements = data.licensesRequirements || [];
            this.form.teachersIds = data.teachersIds;
            this.form.maxLessonsAmountPerWeek = data.maxLessonsAmountPerWeek || "";
            this.form.maxLessonsAmountPerDay = data.maxLessonsAmountPerDay || "";
            this.form.maxLessonsDurationPerDay = data.maxLessonsDurationPerDay || "";
            this.form.bookAndPay = !!data.bookAndPay;
            this.form.priceCategoryId = data.priceCategoryId || "";
            this.form.educationStatus = data.educationStatus;
            this.form.password = "";

            this.form.objectOfContract = data.additionalDetails.objectOfContract
            this.form.ownsDrivingLicense = data.additionalDetails.ownsDrivingLicense
            this.form.drivingLicenseNumber = data.additionalDetails.drivingLicenseNumber
            this.form.drivingLicenseIssuedBy = data.additionalDetails.drivingLicenseIssuedBy
            this.form.drivingLicenseIssuedOn = data.additionalDetails.drivingLicenseIssuedOn
            this.form.ownedLicenses = data.additionalDetails.ownedLicenses
            this.form.wearsVisualAid = data.additionalDetails.wearsVisualAid
            this.form.hasDeficiencies = data.additionalDetails.hasDeficiencies
            this.form.deficiencies = data.additionalDetails.deficiencies

            if (data.scheduledPriceCategory) {
              this.form.scheduledPriceCategoryId = data.scheduledPriceCategory?.id || "";
              this.form.scheduledPriceCategoryFrom = data.scheduledPriceCategory?.scheduledFrom || null;
              this.hasScheduledPriceCategory = true
            }

            this.avatarImage = data.avatar;
            this.hasPaymentHistory = data.hasPaymentHistory;

            this.form.nationality = this.countries.find(item => item.value === data.nationality) ||
              data.nationality;
          })
          .finally(() => {
            this.loadingItem = false;
          });
      }
    },
    loadFormData() {
      studentsService
        .loadFormData()
        .then((resp) => {
          const responseData = resp.data

          this.offices = (responseData.offices || []).map((item) => ({
            text: item.address,
            value: item.id,
          }));

          this.educationStatuses = responseData.educationStatuses || {};

          TeacherModel.create({ data: responseData.teachers || [] });
          LicenseRequirementModel.create({ data: responseData.licensesRequirements || [] })

          ProductCategoryModel
            .create({data: resp.data.priceCategories || []})
            .then(() => {
              if (! this.isEdit) {
                this.form.priceCategoryId = this.defaultPriceCategory?.id || ""
              }
            })
        })
    },
    save() {
      setTimeout(() => {
        const formData = new FormData();
        Object.keys(this.form).forEach((field) => {
          const value = this.form[field];

          if (!value) return;

          if (this.isTeacher && ["bookAndPay", "priceCategoryId"].includes(field)) return;

          if (field === "nationality" && typeof value === "object") {
            formData.set(field, value.value);
            return;
          }

          if (Array.isArray(value)) {
            value.map((item, index) => {
              if (item instanceof Object) {
                Object.keys(item).forEach(key => {
                  formData.append(field + `[${index}][${key}]`, item[key]);
                });
              } else {
                formData.append(field + `[${index}]`, item);
              }
            });
          } else {
            formData.set(field, value);
          }
        });

        if (this.item) {
          formData.set("user_id", this.item.userId);
          formData.set("_method", "PUT");
        }

        this.loadingForm = true;
        this[this.isEdit ? "update" : "store"](formData)
          .catch((error) => {
            const responseData = error.response.data || {};
            const errors = responseData.errors || [];

            if (responseData.error === "email_in_use_by_deleted_user") {
              showConfirm(
                this.$t('btn.ok'),
                this.$t('messages.user_with_same_email_already_exists', {userType: this.$t('user_type.student')}),
                () => {
                  this.form.releaseUsedEmail = true
                  this.save()
                },
              )
              return;
            }

            if (Object.keys(errors).length === 0 && responseData.message)
              this.$snackbar.show({
                message: responseData.message,
                color: "error"
              });
            this.$refs.form.setErrors(errors);
          })
          .finally(() => {
            this.loadingForm = false;
          });
      }, 0)
    },
    store(data) {
      return studentsService
        .create(data)
        .then((resp) => {
          const student = resp.data;
          this.$emit("create", student);
          if (this.redirectAfterCreation) {
            this.$router
                .push({
                  name: "students.student",
                  query: { tab: "documents" },
                  params: { id: student.id },
                }).finally(() => this.close())
          } else if (this.afterCreatedHandler) {
            this.afterCreatedHandler(student)
            this.close()
          }
        })
    },
    update(data) {
      return studentsService
        .update(this.item.id, data)
        .then((resp) => {
          const student = resp.data || {};
          this.$emit("update", student);
          StudentModel.update({ where: student.id, data: student || {} });
          this.close();
        })
    },
    close() {
      this.show = false;
      setTimeout(() => {
        this.form = { ...this.initialForm };
        this.isEdit = false;
        this.$refs.form.reset();
        this.tab = "";

        this.avatarImage = "";
        this.item = null;
        this.hasScheduledPriceCategory = false
      }, 200);
    },
    onFilePick(url, file) {
      this.avatarImage = url;
      this.form.avatar = file;
    },
    onInputBirthDate(value) {
      this.form.birthDate = value;
      this.menuBirthDate = false;
    },
    onInputScheduledPriceCategoryFrom(value) {
      this.form.scheduledPriceCategoryFrom = value;
      this.menuScheduledPriceListFrom = false;
    },
    onInputDrivingLicenseIssuedOnFrom(value) {
      this.form.drivingLicenseIssuedOn = value;
      this.menuDrivingLicenseIssuedOn = false;
    },
    refreshPassword() {
      this.form.password = Password.generate(8);
    },
    onChangeEducationStatus() {
      this.$refs.form.setErrors({ priceCategoryId: [] });
    },
    handleRemoveLicense (licenseId) {
      if (! this.isEdit || ! this.item?.licensesIds.includes(licenseId)) {
        this.removeLicense(licenseId)
        return
      }

      const otherLicenses =  this.form.licensesIds.filter(id => id !== licenseId)
      studentsService
        .studentLicenseAppointmentsCount(this.item.id, licenseId, {otherLicenses})
        .then(response => {
          const responseData = response.data || {}

          if (responseData.count === 0) {
            this.removeLicense(licenseId)
            return
          }

          this.$refs.form.setErrors({ [
            `license.${licenseId}.requirement`]: this.$t('messages.there_are_created_appointments_for_license', {appointmentsAmount: responseData.count})
          });
        })
    },
    removeLicense (licenseId) {
      const licenseIndex = this.form.licensesIds.findIndex(id => id === licenseId)

      if (licenseIndex === -1) return

      this.form.licensesIds.splice(licenseIndex, 1)
      this.form.licensesRequirements = this.form.licensesRequirements
          .filter(licenseRequirement => licenseRequirement.licenseId !== licenseId)
    },
    onLicenseRequirementsTemplateCreated ({ licenseId, data}) {
      this.$nextTick(() => {
        const licenseRequirementIndex = this.form
            .licensesRequirements
            .findIndex(licenseRequirement => licenseRequirement.licenseId === licenseId)

        if (licenseRequirementIndex !== -1) {
          this.form.licensesRequirements[licenseRequirementIndex].licenseRequirementId = data.id
        }
      })
    },
    onBookAndPayChange (status) {
      const defaultPriceCategoryId = this.defaultPriceCategory?.id || ''

      if (! this.item?.priceCategoryId) {
        this.form.priceCategoryId = status ? defaultPriceCategoryId : ''
      }
    },
    removeScheduledPriceLists() {
      this.removingScheduledPriceLists = true;

      studentsService
        .removeScheduledPriceLists(this.item.id)
        .then(() => {
          this.form.scheduledPriceCategoryId = null;
          this.form.scheduledPriceCategoryFrom = ''
          this.hasScheduledPriceCategory = false
          this.$emit("update", this.item);
        })
        .finally(() => {
          this.removingScheduledPriceLists = false;
        })

    }
  },
};
</script>

<style scoped></style>
