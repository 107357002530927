export default {
  methods: {
    checkIfEventsIsRequest(event) {
      return (
        (event.eventType === "PRACTICE" && event.status === "REQUESTED") ||
        (event.eventType === "PLACEHOLDER" &&
          event.typeEntity &&
          event.typeEntity.requests.length > 0)
      );
    },
  },
};
