<script>
import {mapActions} from "vuex";
import {EventBus} from "@/EventBus";
import studentsService from "@/services/studentsService";
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: "RightMenu",
  props: {
    student: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions('studentsDirectory', ['closeStudent', 'load']),
    openStudentDetails() {
      if (! this.student.id) return;

      this.$router.push({name: 'students.student', params: {id: this.student.id}})
    },
    editStudent() {
      if (! this.student.id) return;

      EventBus.$emit('open-student-form-dialog', this.student.id)
    },
    archiveStudent() {
      if (! this.student.id) return;

      showConfirm(
        this.$t('btn.confirm'),
        this.$t("messages.are_your_sure_archive"),
        () => {
          studentsService
            .destroy(this.student.id)
            .then(() => {
              studentsService
                .loadItem(this.student.id)
                .then(() => {
                  this.load(true);
                  this.closeStudent(this.student.id)
                  this.$emit('student-deleted');
                });
            })
            .catch((error) => console.log(error))
            .finally(() => {
              this.loadingAction = false;
            });
        },
        () => {
          this.loadingAction = false;
        }
      );
    }
  }
}
</script>

<template>
  <div class="d-flex flex-column">
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <button  v-bind="attrs" v-on="on"  class="btn d-flex align-center justify-center" @click="openStudentDetails">
          <v-icon large>mdi-account-eye</v-icon>
        </button>
      </template>
      {{ $t('btn.open') }}
    </v-tooltip>

    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <button v-bind="attrs" v-on="on"  class="btn d-flex align-center justify-center" @click="editStudent">
          <v-icon large>mdi-account-edit</v-icon>
        </button>
      </template>
      {{ $t('btn.edit') }}
    </v-tooltip>

    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <button v-bind="attrs" v-on="on" class="btn d-flex align-center justify-center" @click="archiveStudent">
          <v-icon large color="red">mdi-archive-arrow-down</v-icon>
        </button>
      </template>
      <span>
        {{$t("btn.archive")}}
        <span v-if="student.createdUsingCredits && student.deletionWillRefundCredits">
          ({{$tc("label.credits_will_be_refunded", 1, {credits: 1})}})
        </span>
      </span>
    </v-tooltip>
  </div>
</template>


<style lang="scss" scoped>
.btn{
  border-bottom: 1px solid #ccc;
  width: 100%;
  height: 64px;
  cursor: pointer;
  transition: .5s;
  position: relative;
  & > img {
    width: 55px;
    height: 55px;
  }
  &.active, &:hover {
    background: #eee;
  }
}
</style>
