<template>
  <div>
    <div class="mb-1">
      <span class="subtitle-1" v-if="type">{{ $t(`event_type.${type}`) }} </span>
      <span class="text-uppercase caption" v-if="lessonTypeName">{{ $tc(lessonTypeName) }}</span>
    </div>

    <v-card class="event" :style="styles">
      <div
        v-if="hasTeacher && teacher.colorLine"
        class="color-line"
        :style="`background: ${teacher.colorLine}`" />
      <div class="event-content px-2 pt-1" style="'color: ' + fontColor">
        <div class="text-overflow-ellipsis">
          {{ startTime }} - {{ endTime }}
        </div>
        <div v-if="hasTeacher" class="font-weight-bold text-overflow-ellipsis">
          {{ teacher.fullName }}
        </div>
        <div>
          <div
            v-if="hasStudent && student.fullName"
            class="text-overflow-ellipsis">
            {{ student.fullName }}
          </div>
          <div v-if="!lessonTypeName" class="text-overflow-ellipsis">
            {{ $t(`event_type.${type}`) }}
          </div>
          <div v-else class="text-overflow-ellipsis">
            B<span v-if="lessonTypeName"> — {{ $tc(lessonTypeName) }}</span>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import responsive from "@/utils/mixins/responsive";
import EventModel from "@/store/models/EventModel";
import StudentModel from "@/store/models/StudentModel";

export default {
  props: {
    bodyColor: {
      type: String,
      default: "#000000",
    },
    fontColor: {
      bodyColor: {
        type: String,
        default: "#ffffff",
      },
    },
    type: {
      type: String,
    },
    licenseName: {
      type: String,
    },
    lessonTypeName: {
      type: String,
    },
    teacher: {
      type: Object,
      default: () => ({ fullName: "", colorLine: "" }),
    },
    lessonTypeGroup: {
      type: String,
    },
  },
  name: "EventCard",
  mixins: [responsive],
  computed: {
    styles() {
      return `
        background: ${this.bodyColor};
        color: ${this.fontColor};
        pointer-events: static;
      `;
    },
    startTime() {
      return ("0" + Math.floor(Math.random() * 24)).slice(-2) + ":00";
    },
    endTime() {
      return this.startTime.split(":")[0] + ":45";
    },
    hasTeacher() {
      return !(
        this.type === EventModel.TYPES.SIMULATOR ||
        (this.type === EventModel.TYPES.THEORY &&
          this.lessonTypeGroup === "exam")
      );
    },
    hasStudent() {
      return ![EventModel.TYPES.OFFTIME, EventModel.TYPES.PLACEHOLDER].includes(
        this.type
      );
    },
    student() {
      return StudentModel.query().first() || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.event {
  overflow: hidden;
  color: #fff;
  pointer-events: all;
  z-index: 4;
  border-radius: 5px;
  cursor: grab;
  padding-bottom: 10px;
  &.past-event {
    filter: grayscale(60%);
    opacity: 0.8;
  }
  &.blocked-time-event {
    width: 100%;
    color: #333 !important;
    cursor: not-allowed;
    background: url("../../../assets/img/dots.png") !important;
    i {
      display: none !important;
    }
  }
  & > * {
    pointer-events: none;
    font-size: 11px;
    line-height: 16px;
    padding: 0 5px;
  }
  &.creating-new-event {
    pointer-events: none;
    opacity: 0.5;
    transition: opacity 0.3s;
  }
  .icon-box {
    position: absolute;
    right: 0;
    bottom: 3px;
  }
  .spacer {
    height: 8px;
  }
  .color-line {
    height: 8px;
  }
}
</style>
