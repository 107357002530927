<template>
<v-card :loading="loading" :disabled="disabled">
  <v-img height="100px" :src="image" eager />
  <v-card-title>{{ $t("route.vehicleMileage") }}</v-card-title>

  <v-card-subtitle class="student-card">
    {{ $t('label.vehicle_card_mileage_description') }}
  </v-card-subtitle>

  <v-card-text v-if="!disabled">
    <v-skeleton-loader v-if="loading" type="list-item-two-line" />
    <v-list v-else>
      <v-list-item class="pa-0 mb-2">
        <v-list-item-content class="pa-0">
          <v-list-item-title>{{ $t('label.last_mileage') }}</v-list-item-title>
          <v-list-item-subtitle>{{ lastMileage }} Km</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card-text>

  <v-card-actions>
    <v-btn
      small
      text
      class="ml-auto"
      :to="{ name: `vehicles.vehicle.mileage` }"
    >
        {{ $t("btn.open") }}
    </v-btn>
  </v-card-actions>
</v-card>
</template>

<script>
import cardsService from "@/services/cardsService";
import {EventBus} from "@/EventBus";

export default {
  name: "VehicleMileageCard",
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      lastMileage: 0,
      image: require("@/assets/img/mileage.jpg")
    };
  },
  mounted() {
    if (!this.disabled) this.init();
  },
  created() {
    EventBus.$on("mileage-created", this.init);
    EventBus.$on("mileage-deleted", this.init);
  },

  destroyed() {
    EventBus.$off("mileage-created", this.init);
    EventBus.$off("mileage-deleted", this.init);
  },
  methods: {
    init () {
      this.loading = true;
      cardsService.vehicleMileages(this.$route.params.id)
        .then(response => {
          this.lastMileage = response.data.lastMileage || 0;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style scoped>

</style>
