import EventSubscribedStudents from "@/components/calendar/EventSubscribedStudents";
import VehicleModel from "@/store/models/VehicleModel";
import TheoryTopicModel from "@/store/models/TheoryTopicModel";
import LessonTypeModel from "@/store/models/LessonTypeModel";
import StudentModel from "@/store/models/StudentModel";
import LicenseModel from "@/store/models/LicenseModel";
import i18n from "@/plugins/i18n";
// import { isPast, parseISO } from 'date-fns'

export default {
  components: { EventSubscribedStudents },
  computed: {
    passedExamIcon() {
      const { id, studentsIds, group, licenseId } = this.event
      const student = StudentModel.find(studentsIds[0])

      if (!student || !["exam", "mtl_exam"].includes(group)) return false;

      const exactProgress = student.licenseProgress.find(item => item.license === licenseId)

      return [
        exactProgress?.progress.practice_exam_event_id,
        exactProgress?.progress.mtl_exam_event_id
      ].includes(id);
    },
    isExamInPast() {
      /*commented past exam logic*/
      // const { id, start } = this.event
      // return !!id && isPast(parseISO(start)) && (this.lessonType && !this.lessonType?.canBeMovedInPast)
      return false
    },
    activeStudentsIds () {
      const activeStudents = StudentModel.query()
        .where((student) => !student.isDeleted)
        .whereIdIn(this.event.studentsIds)
        .get() || [];
      return activeStudents.map((student) => student.id)
    },
    showMissedIcon() {
      return !this.isStudent && this.event.type === 'PRACTICE'
        && (['MISSED', 'MISSED_PARTIALLY_REFUNDED', 'MISSED_REFUNDED', 'JOKER_REFUNDED'].includes(this.event.status));
    },
    showAccountIcon() {
      return !this.isStudent &&  this.event.type === 'SIMULATOR' &&  this.event.studentsIds.length;
    },
    showCheckIcon() {
      return this.isStudent && this.event.isSubscribed && this.event.group !== 'exam'
    },
    showHelpIcon() {
      let showIcon = false;
      if (this.isStudent && this.event.isRequested) showIcon = true;
      if (this.event.type === 'PRACTICE' && this.event.status === 'REQUESTED') showIcon = true;
      return showIcon;
    },
    requestedPlaceholderStudents() {
      const textLine = [];
      if (!this.isStudent && this.event.type === 'PLACEHOLDER') {
        this.event.studentsIds.forEach((req) => {
          const student = StudentModel.find(req.id) || null;
          const license = LicenseModel.find(req.licenseId) || {};
          textLine.push(student ? `${license.name} - ${student.fullName}` : license.name)
        });
      }
      return textLine;
    },
    primaryVehicle() {
      const vehicle = VehicleModel.find(this.event.primaryVehicleId) || {};
      const label = `${vehicle.make} ${vehicle.model} ${
        vehicle.licensePlate ? `(${vehicle.licensePlate})` : ""
      }`;
      return { ...vehicle, label: label };
    },
    secondaryVehicle() {
      const vehicle = VehicleModel.find(this.event.secondaryVehicleId) || {};
      const label = `${vehicle.make} ${vehicle.model} ${
        vehicle.licensePlate ? `(${vehicle.licensePlate})` : ""
      }`;
      return { ...vehicle, label: label };
    },
    theoryTopic() {
      return TheoryTopicModel.find(this.event.theoryTopicId) || {};
    },
    theoryTopicLabel() {
      return this.event.group === "lesson"
        ? `${i18n.t("form.topic")} ${this.theoryTopic.orderIndex}: ${
            this.theoryTopic.title
          }`
        : "";
    },
    eventStudent() {
      return StudentModel.find(this.event.studentsIds[0]);
    },
    studentsLabel() {
      return this.eventStudent ? this.eventStudent.fullName : "";
    },
    lessonType() {
      return LessonTypeModel.find(this.event.lessonTypeId);
    },
    lessonTypeLabel() {
      if (this.isStudent && this.lessonType?.name === "lesson_type.gratis_exam")
        return this.$tc("lesson_type.exam", 1); // Gratis exams for students should look like paid exam
      return this.$tc(this.lessonType?.name, 1);
    },
    licenseName() {
      const getLicense = LicenseModel.find(this.event.licenseId) || {};
      return getLicense.name;
    },
    eventLabel() {
      if (["THEORY", "SIMULATOR"].includes(this.event.type)) {
        return this.$t(this.event.lessonType?.name) || "";
      } else if (this.event.type === "SPECIAL") {
        return (`${this.event.eventTypeTranslation} (${this.$t(`label.${this.event.specialType.toLowerCase()}`)})` || "");
      } else {
        return this.event.eventTypeTranslation;
      }
    },
    blockedEvent () {
      if (this.isStudent) {
        let res = { status: false, className: 'normal-event' };
        if (this.event.type === 'OFFTIME' ||
          (['PRACTICE', 'SPECIAL'].includes(this.event.type) && !this.event.studentsIds.includes(this.currentUser.id)) ||
          this.event.type === 'PRACTICE' && this.event.group === 'planned_exam') {
          res = { status: true, className: 'blocked-time-event' }
        }
        return res
      } else {
        return { status: false, className: 'normal-event' }
      }
    }

  },
  methods: {
    invertColor(hex) {
      if (hex && hex?.indexOf("#") === 0) {
        hex = hex.slice(1);
      }
      // convert 3-digit hex to 6-digits.
      if (hex && hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex && hex.length !== 6) {
        throw new Error("Invalid HEX color.");
      }

      const r = parseInt(hex?.slice(0, 2), 16);
      const g = parseInt(hex?.slice(2, 4), 16);
      const b = parseInt(hex?.slice(4, 6), 16);

      // http://stackoverflow.com/a/3943023/112731
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
    },
    eventShow(event) {
      if (this.mdLandscapeAndUp && this.eventCreationMenuGetter.visible) {
        this.finishEventCreation();
      } else {
        this.eventOpenAction(event);
      }
    },
  },
};
