<template>
  <v-container>
    <v-card :loading="loadingPayment">
      <v-toolbar elevation="3">
        <v-btn icon :to="{ name: 'payments' }" class="ml-0" exact>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-card-title class="text-overflow-ellipsis">
          <v-skeleton-loader
            width="150"
            height="12"
            type="text@1"
            v-if="loadingPayment" />
          <span class="mr-2 text-overflow-ellipsis" v-else>{{
            $t("label.payment_nr", { number: $route.params.id })
          }}</span>
        </v-card-title>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" lg="3">
            <TwoRowsIconCard
              :loading="loadingPayment"
              :title="$t('label.status')"
              :icon="payment ? payment.statusIcon : ''"
              :color="payment ? payment.statusColor : ''">
              <span v-if="!loadingPayment">{{ payment.statusText }}</span>
            </TwoRowsIconCard>
          </v-col>
          <v-col cols="12" md="4" lg="3">
            <TwoRowsIconCard
              :loading="loadingPayment"
              :title="$t('label.amount')"
              icon="mdi-currency-eur">
              <span v-if="payment">{{ payment.amount | currency }}</span>
            </TwoRowsIconCard>
          </v-col>
          <v-col cols="12" md="4" lg="3">
            <TwoRowsIconCard
              :loading="loadingPayment"
              :title="$t('label.date')"
              icon="mdi-calendar-check-outline">
              <span v-if="payment">{{ payment.created }}</span>
            </TwoRowsIconCard>
          </v-col>
          <v-col cols="12" md="4" lg="3">
            <TwoRowsIconCard
              :loading="loadingPayment"
              :icon="payment ? payment.paymentMethodIcon : ''"
              type="image"
              :title="$t('label.payment_method')">
              <span v-if="payment" v-text="payment.method" />
            </TwoRowsIconCard>
          </v-col>
        </v-row>
      </v-card-text>

      <template v-if="payment.charges.length">
        <v-card-title>Charges</v-card-title>
        <v-card-text>
          <v-timeline dense>
            <v-timeline-item
              v-for="charge in payment.charges"
              :key="charge.id"
              class="mb-4"
              :color="charge.statusColor"
              small
              fill-dot
              :icon="charge.statusIcon"
            >
              <v-row justify="space-between" dense>
                <v-col cols="7" class="d-flex align-center">
                  <v-chip v-if="charge.refunded" small label color="warning darken-2" outlined class="mr-2">
                    {{ $t('payment_status.refunded') }}
                  </v-chip>
                  <span class="font-weight-bold">{{ charge.outcomeLocalized }}</span>
                  <span class="ml-2">
                    {{ charge.outcomeReason }}
                  </span>
                </v-col>
                <v-col
                  class="text-right"
                  cols="5"
                >
                  {{ charge.created | dateFormat }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-chip
                      label
                      small
                      class="text-monospace"
                      link
                      @click="copyToClipboard(charge.stripeChargeId)">
                    {{ charge.stripeChargeId | ellipsis }}
                  </v-chip>
                </v-col>
                <v-col class="d-flex flex-row align-center justify-end">
                  <img
                      v-if="charge.pmWalletIcon"
                      :src="charge.pmWalletIcon"
                      class="mr-1"
                      style="width: 22px; height: 22px"
                  />
                  <img
                      v-if="charge.pmIcon"
                      :src="charge.pmIcon"
                      class="mr-1"
                      style="width: 22px; height: 22px"
                  />
                  <v-chip
                      label
                      small
                      class="text-monospace"
                      link
                      outlined
                      @click="copyToClipboard(charge.stripeChargeId)"
                  >
                    {{ charge.pmCountry }} ····{{ charge.pmLast4 }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </template>

      <v-card-title>
        {{ $t("label.other_payments") }}
      </v-card-title>

      <PaymentsTable
          :payments="otherPayments"
          v-if="!loadingOthers"
          :except="payment.id"
          :limit="recordsLimit"
      />

        <v-card-actions
          class="d-flex align-center justify-center"
          v-if="otherPayments.length > recordsLimit">
          <v-btn
            text
            small
            color="primary"
            @click="recordsLimit = undefined">
            <v-icon left>mdi-arrow-down-circle</v-icon>
            {{ $t("btn.show_all") }}
          </v-btn>
        </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import paymentsService from "@/services/paymentsService";
import PaymentsTable from "@/components/payments/PaymentsTable";
import TwoRowsIconCard from "@/components/widgets/TwoRowsIconCard";
import PaymentModel from "@/store/models/PaymentModel";
import clipboard from "@/utils/mixins/clipboard";

export default {
  name: "ViewPayment",
  components: { PaymentsTable, TwoRowsIconCard },
  mixins: [clipboard],
  data() {
    return {
      id: null,
      loadingPayment: true,
      loadingOthers: true,
      originalPayment: {},
      otherPayments: [],
      recordsLimit: 10,
    };
  },
  created() {
    this.id = this.$route.params.id;
    paymentsService
      .loadItem(this.id)
      .then((resp) => {
        PaymentModel.create({ data: resp.data || {} });
        paymentsService
          .sameUserPayments(this.id)
          .then((resp) => {
            this.otherPayments = resp.data;
          })
          .finally(() => {
            this.loadingOthers = false;
          });
      })
      .catch((error) => {
        const response = error.response || {};
        const { status } = response;

        if (status && status === 404) {
          this.$snackbar.show({
            message: "Payment not found!",
            color: "error",
          });
          this.$router.push({ name: "payments" });
        }
      })
      .finally(() => {
        this.loadingPayment = false;
      });
  },
  computed: {
    payment() {
      return PaymentModel.query()
          .with('charges', query => {
            query.orderBy('created', 'desc');
          })
          .find(this.$route.params.id);
    },
    ...mapGetters("auth", { currentUser: "currentUser" }),
  },
};
</script>

<style scoped>
.hidden-until-row-hovered {
  display: none;
}
.table-row:hover > td > .hidden-until-row-hovered {
  display: inline-flex;
}
</style>
