<template>
<ValidationProvider v-slot="{ errors }" :name="name">
    <v-dialog
      v-model="menu"
      :close-on-content-click="false"
      persistent
      max-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            :value="value"
            :label="label"
            :placeholder="placeholder"
            readonly
            outlined
            v-bind="attrs"
            v-on="on"
            :error-messages="errors"
            @click:clear="$emit('clear')"
            :disabled="disabled"
            clearable
          />
      </template>

      <v-time-picker
        v-if="menu"
        :min="min"
        :max="max"
        @input="onInput"
        :value="time"
        scrollable
        format="24hr"
        :allowed-hours="allowedHours"
        :allowed-minutes="allowedMinutes"
        @click:hour="setHour"
        @click:minute="value => minutes = value"
      >
          <v-spacer/>
          <v-btn text color="primary" @click="cancel">
              {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn text color="primary" @click="save">
              {{ $t('btn.ok') }}
          </v-btn>
      </v-time-picker>
    </v-dialog>
</ValidationProvider>
</template>

<script>
export default {
  name: 'TimePickerField',
  props: {
    name: {
      type: String
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    value: {
      type: [Array, String]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    offsetOverflow: {
      type: Boolean,
      default: false
    },
    allowedHours: {
      type: [Function, Array],
    },
    allowedMinutes: {
      type: [Function, Array]
    },
    min: {
      type: String
    },
    max: {
      type: String
    }
  },
  data() {
    return {
      menu: false,
      time: null,
      hour: null,
      minutes: null
    }
  },
  watch: {
    menu: function (value) {
      if (value) {
        this.time = this.value
      }
    },
    value: function (value) {
      this.time = value

      if (value === null) {
        this.hour = null
        this.minutes = null
      }
    }
  },
  methods: {
    cancel() {
      this.menu = false
      this.time = null
      this.hour = null
      this.minutes = null
    },
    onInput(value) {
      this.time = value
    },
    save() {
      this.menu = false

      if(this.hour && this.minutes === null) {
        this.time = this.hour + ':00'
      }

      this.$emit('save', this.time)
    },
    setHour(hour) {
      this.hour = hour.toString().padStart(2, '0')

      if (this.min) {
        const minArr = this.min.split(':')

        if (hour === parseInt(minArr[0]) && minArr[1]) {
          this.minutes = minArr[1]

          this.time = this.hour + ':' + minArr[1]
        }
      }

    }
  }
}
</script>

<style scoped>

</style>
