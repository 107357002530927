<template>
  <v-hover :disabled="disabled" v-slot:default="{ hover }">
    <v-card
        :ripple="false"
        loader-height="8"
        :loading="loadingEventGetter === event.id"
        @mouseenter="eventHovered(event.id)"
        @mouseleave="eventHovered(null)"
        @mousedown.left="$emit('onDrag', $event)"
        @click="eventClicked(event)"
        :elevation="hover ? 12 : 2"
        :class="`event ${pastEvent} ${blockedEvent.className} ${deletableEvent} ${blockedForMultiDelete}`"
        :style="styles">
      <div v-if="event.hasSignature || isExamInPast || passedExamIcon" class="sing-icon">
        <v-icon v-if="event.hasSignature" size="15" :color="eventColor.fontColor">
          mdi-signature-freehand
        </v-icon>
        <v-icon v-if="passedExamIcon" class="ml-1" size="15" :color="eventColor.fontColor">
          mdi-check-decagram
        </v-icon>
        <v-icon v-if="isExamInPast" class="ml-1" size="15" :color="eventColor.fontColor">
          mdi-lock
        </v-icon>
      </div>
      <v-icon
          v-if="multiDeleteStatusGetter && canBeMultiDeleted"
          size="x-large"
          :color="eventColor.fontColor"
          class="delete-selector">
        {{ deleteIconStatus }}
      </v-icon>

      <div v-if="event.teacher && schoolSettings.showEventTeacherLine" class="color-line" :style="teacherLineStyles"/>
      <div v-else class="spacer"/>

      <div class="event-content pt-1 px-1">
        <div class="text-overflow-ellipsis">
          {{ event.startTime }} - {{ event.endTime }}
        </div>
        <div class="font-weight-bold text-overflow-ellipsis" v-if="event.teacher && !isTeacher">
          {{ event.teacherFullName }}
        </div>
        <div class="text-overflow-ellipsis" v-if="isStudent && blockedEvent.status">
          {{ $t('label.blocked_time') }}
        </div>
        <div v-if="!isStudent || (isStudent && !blockedEvent.status)">
          <div class="d-flex" v-if=" !isStudent && !['THEORY', 'SPECIAL'].includes(event.type) && eventStudent">
            <span class="text-overflow-ellipsis" :class="{ 'font-italic': eventStudent.isDeleted }">
              {{ studentsLabel }}
            </span>
          </div>
          <div class="text-overflow-ellipsis" v-if="event.type !== 'PRACTICE'">
            {{ eventLabel }}
          </div>
          <div v-else>
            <div class="text-overflow-ellipsis">
              <v-icon v-if="event.isManualB197" :color="eventColor.fontColor" small>mdi-alpha-s-box</v-icon>
              {{ licenseName }}
              <span v-if="lessonTypeLabel" class="text-uppercase">— {{ lessonTypeLabel }}</span>
            </div>
          </div>
        </div>
        <div v-if="event.type === 'SPECIAL' && !blockedEvent.status" class="text-overflow-ellipsis">
          {{ event.specialName }}
        </div>
        <div v-if="event.type === 'THEORY'" class="text-overflow-ellipsis d-flex align-center">
          <v-icon v-if="event.theoryTopicId" color="white" class="mr-1" size="15">
            {{ theoryTopic.type === 'basic' ? 'mdi-alpha-g-box' : 'mdi-alpha-z-box' }}
          </v-icon>
          {{ theoryTopicLabel }}
        </div>
        <div v-if="event.meetingPlace && !blockedEvent.status" class="text-caption text-truncate">
          {{ event.meetingPlace }}
        </div>
      </div>

      <div v-if="event.isRequested" class="pa-1">
        <div class="text-overflow-ellipsis" v-for="(student, index) in requestedPlaceholderStudents" :key="index">
          {{ student }}
        </div>
      </div>

      <div v-if="!blockedEvent.status" class="event-footer">
        <div class="icon-box d-flex justify-end px-1">
          <EventSubscribedStudents
              v-if="!isStudent &&  event.type === 'THEORY' && event.group === 'lesson'"
              :event-type="event.type"
              :students-count="event.studentsIds.length"
              :room-size="event.roomSize"/>
          <EventSubscribedStudents
              v-if="!isStudent && event.type === 'THEORY' && event.group === 'exam' && event.studentsIds.length"
              :event-type="event.type"
              :students-count="event.studentsIds.length"/>
          <EventSubscribedStudents
              v-if="!isStudent && event.type === 'SPECIAL' && event.studentsIds.length"
              :event-type="event.type"
              :students-ids="activeStudentsIds"/>
          <v-icon v-if="showCheckIcon" class="ml-2" size="15" :color="eventColor.fontColor">
            mdi-check-circle
          </v-icon>
          <v-icon v-if="showHelpIcon" class="ml-2" size="15" :color="eventColor.fontColor">
            mdi-help-circle
          </v-icon>
          <v-icon v-if="showAccountIcon" class="ml-2" size="15" :color="eventColor.fontColor">
            mdi-account-circle
          </v-icon>
          <v-icon v-if="showMissedIcon" class="ml-2" size="15" :color="eventColor.fontColor">
            {{ ['MISSED_PARTIALLY_REFUNDED', 'MISSED_REFUNDED', 'JOKER_REFUNDED'].includes(event.status) ? 'mdi-cash-refund' : 'mdi-call-missed' }}
          </v-icon>
        </div>

        <div v-if="event.primaryVehicleId" class="vehicles">
          <div class="px-1 text-overflow-ellipsis" :style="primaryVehicleStyles">
            {{ primaryVehicle.label }}
          </div>
          <div v-if="event.secondaryVehicleId" class="px-1 text-overflow-ellipsis" :style="secondaryVehicleStyles">
            {{ secondaryVehicle.label }}
          </div>
        </div>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
import { differenceInMinutes, parseISO } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'

import responsive from '@/utils/mixins/responsive'
import user from '@/utils/mixins/user'
import colorMixin from '@/utils/mixins/colorMixin'
import eventMixin from '@/utils/mixins/eventMixin'

export default {
  name: 'Appointment',
  mixins: [responsive, user, eventMixin, colorMixin],
  data () {
    return {
      checkIcon: false,
    }
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters(['hours']),
    ...mapGetters('appointments', [
      'eventCreationMenuGetter',
      'loadingEventGetter',
      'multiDeleteStatusGetter',
      'multiDeleteEventsGetter',
    ]),
    styles () {
      return `height: ${this.getHeight}px;
          top: ${this.getTopOffset}px;
          left: ${this.event.left}%;
          width: ${this.event.width}%;
          background: ${this.eventColor.bodyColor};
          color: ${this.eventColor.fontColor};
          pointer-events: static;`
    },
    teacherLineStyles () {
      return `height: 8px; background:${this.event.teacher.color}`
    },
    primaryVehicleStyles () {
      return `background: ${
          this.primaryVehicle.color
      }; color: ${this.invertColor(this.primaryVehicle.color)}`
    },
    secondaryVehicleStyles () {
      return `background: ${
          this.secondaryVehicle.color
      }; color: ${this.invertColor(this.secondaryVehicle.color)}`
    },
    deleteIconStatus () {
      return this.multiDeleteEventsGetter.includes(this.event.id)
          ? 'mdi-radiobox-marked'
          : 'mdi-radiobox-blank'
    },
    deletableEvent () {
      return this.multiDeleteStatusGetter ? 'deletable' : ''
    },
    blockedForMultiDelete () {
      return this.multiDeleteStatusGetter && !this.canBeMultiDeleted ? 'creating-new-event' : ''
    },
    pastEvent () {
      return this.event.endDate <= new Date() ? 'past-event' : ''
    },
    getHeight () {
      const duration = this.event.duration >= 15 ? this.event.duration : 15
      return duration * this.oneMinuteSize - 1
    },
    getTopOffset () {
      const startOfTheDay = parseISO(`${this.event.startDate} ${this.hours[0]}`)
      return (differenceInMinutes(parseISO(this.event.start), startOfTheDay) * this.oneMinuteSize)
    },
    canBeMultiDeleted () {
      const isPast = parseISO(this.event.startDate) <= new Date()
      const blockedInPastEvents = ['PRACTICE', 'SPECIAL', 'THEORY'].includes(this.event.type)
      const requestedPlaceholder = this.event.type === 'PLACEHOLDER' && this.event.isRequested
      const bookedSimulator = this.event.type === 'SIMULATOR' && !!this.event.studentsIds.length
      return (!isPast || (isPast && !blockedInPastEvents && !requestedPlaceholder && !bookedSimulator))
    },
  },
  methods: {
    ...mapActions('appointments', [
      'closeAppointmentModal',
      'eventBeenHovered',
      'showAppointmentModal',
      'multiDeleteAdd',
      'multiDeleteRemove',
    ]),
    eventHovered (eventId) {
      this.eventBeenHovered(eventId)
    },
    eventClicked (event) {
      if (this.blockedEvent.status) return
      if (this.multiDeleteStatusGetter && this.canBeMultiDeleted) {
        this.markForDelete(event.id)
      } else {
        if (this.mdLandscapeAndUp && this.eventCreationMenuGetter.visible) {
          this.closeAppointmentModal()
        } else {
          this.showAppointmentModal(event)
        }
      }
    },
    markForDelete (id) {
      if (!this.checkIcon) {
        this.multiDeleteAdd(id)
      } else {
        this.multiDeleteRemove(id)
      }
      this.checkIcon = !this.checkIcon
    },
  },
}
</script>

<style lang="scss" scoped>
.event {
  overflow: hidden;
  color: #fff;
  pointer-events: all;
  z-index: 4;
  min-width: 10%;
  border-radius: 5px;
  position: absolute;
  cursor: grab;
  width: calc(100% - 10px);

  &.deletable {
    cursor: pointer !important;
  }

  &.past-event {
    filter: grayscale(60%);
    opacity: 0.8;
  }

  &.blocked-time-event {
    width: 100%;
    color: #333 !important;
    cursor: not-allowed;
    background: url("../../assets/img/dots.png") !important;

    i {
      display: none !important;
    }
  }

  & > * {
    pointer-events: none;
    font-size: 11px;
    line-height: 16px;
  }

  &.creating-new-event {
    pointer-events: none;
    opacity: 0.5;
    transition: opacity 0.3s;
  }

  .icon-box {
    position: relative;
    right: 0;
    bottom: 3px;
  }

  .spacer {
    height: 8px;
  }

  .vehicles {
    position: relative;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #fff;
    padding: 0;
  }

  .delete-selector {
    position: absolute;
    top: 12px;
    right: 0;
  }

  .sing-icon {
    position: absolute;
    top: 12px;
    right: 4px;
  }

  &-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}
</style>
