<template>
  <v-dialog v-model="show" width="700" @click:outside="close">
    <v-card>
      <v-toolbar class="mb-2" dark color="primary" dense>
        <v-toolbar-title>{{ $t("form.copy") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-container>
        <ValidationObserver ref="form">
          <div v-if="this.isSchoolAdministrator">
            <h4 class="mb-3">Termine für folgenden Fahrlehrer kopieren:</h4>
            <ValidationProvider
              name="teachers"
              rules="required"
              v-slot="{ errors }">
              <v-select
                v-model="form.teacherId"
                :items="teachers"
                :error="errors.length > 0"
                :label="$t('form.teacher')"
                item-value="id"
                item-text="fullName"
                outlined
                dense
                hide-details />
            </ValidationProvider>
          </div>

          <div class="d-flex justify-start align-center my-8">
            <h4>Termine für</h4>
            <ValidationProvider
              name="copy-weeks"
              rules="required"
              v-slot="{ errors }">
              <v-select
                style="width: 100px"
                class="mx-5"
                v-model="form.copyForNrOfWeeks"
                :items="weeks"
                :error="errors.length > 0"
                :label="$t('form.copy')"
                outlined
                dense
                hide-details />
            </ValidationProvider>
            <h4>Wochen kopieren:</h4>
          </div>

          <div>
            <h4>Folgende Termintypen werden kopiert:</h4>
            <v-row class="px-5 mb-3">
              <v-col cols="4" v-for="(type, i) in eventTypes" :key="i">
                <v-checkbox
                  v-model="form.eventTypes"
                  :readonly="
                    form.eventTypes.length <= 1 &&
                    form.eventTypes.includes(type.id)
                  "
                  :color="type.bodyColor"
                  :label="$t(`event_type.${type.id}`)"
                  :value="type.id"
                  hide-details />
              </v-col>
            </v-row>
          </div>
        </ValidationObserver>
      </v-container>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn @click="close" text>{{$t('btn.close')}}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="submitCopy" :loading="loading" :disabled="loading">
          <v-icon left>mdi-content-copy</v-icon>
          {{$t("form.copy")}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getISOWeek, getYear, parseISO, } from "date-fns";
import user from "@/utils/mixins/user";
import Holiday from '@/helpers/holiday'
import { mapGetters } from "vuex";
import appointmentService from "@/services/appointmentService";
import AppointmentModel from "@/store/models/AppointmentModel";
import EventTypeModel from "@/store/models/EventTypeModel";
import TeacherModel from "@/store/models/TeacherModel";

export default {
  name: "CopyWeeksDialog",
  mixins: [user],
  data() {
    return {
      show: false,
      loading: false,
      form: {
        teacherId: this.isTeacher ? this.currentUser.id : null,
        eventTypes: ["PLACEHOLDER"],
        copyForNrOfWeeks: 2,
      },
    };
  },
  props: {
    activeDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    teachers() {
      return TeacherModel.all() || [];
    },
    weeks() {
      const arr = [];
      for (let i = 1; i <= 25; i++) arr.push(i);
      return arr;
    },
    types() {
      return EventTypeModel.all() || [];
    },
    eventTypes() {
      const eventTypes = ["OFFTIME", "PLACEHOLDER"]
      return EventTypeModel.query().whereIdIn(eventTypes).get() || []
    },
  },
  methods: {
    async submitCopy() {
      const formattedHolidays = new Holiday().getHolidaysForPeriod(
          parseISO(this.activeDate),
          this.form.copyForNrOfWeeks,
          this.schoolSettings.regions?.split(', ')
      )

      const data = {
        ...this.form,
        nrOfWeek: getISOWeek(parseISO(this.activeDate)),
        year: getYear(parseISO(this.activeDate)),
        exceptedDays: formattedHolidays,
      };

      const validate = await this.$refs.form.validate();
      if (validate) {
        this.loading = true;
        appointmentService
            .copyWeek(data)
            .then((resp) => {
              if (resp) {
                const data = resp.data || {}
                data.items.forEach(item => {
                  AppointmentModel.insert({ data: item })
                })
                data.overlapIds.forEach(id => {
                  AppointmentModel.delete(id);
                })
              }
            })
            .finally(() => {
              this.loading = false;
              this.$snackbar.show({ message: this.$i18n.t('messages.successfully_copied'), timeout: 5000, color: "success" })
              this.close();
            });
      }
    },
    close() {
      this.form.teacherId = this.isTeacher ? this.currentUser.id : null;
      this.form.eventTypes = ["PLACEHOLDER"];
      this.form.copyForNrOfWeeks = 2;
      this.show = false;
      this.$refs.form.reset()
    },
    open() {
      this.show = true;
    },
  },
};
</script>
