<template>
  <div>
    <v-sheet
        class="my-3"
        style="background-color: white"
        outlined
        color="#eee"
        rounded elevation="2"
    >
      <div class="d-flex flex-column pa-3 flex-md-row">
        <div style="min-width: 100px" class="d-flex justify-space-between text--secondary text-center">
          <template v-if="$vuetify.breakpoint.smAndDown">
            <v-skeleton-loader width="100px" type="list-item-two-line"></v-skeleton-loader>
            <v-skeleton-loader type="avatar"></v-skeleton-loader>
          </template>
          <v-skeleton-loader v-else class="ma-auto" type="avatar"></v-skeleton-loader>
        </div>

        <v-divider class="mx-3" v-if="$vuetify.breakpoint.mdAndUp" vertical />

        <div class="d-flex flex-column text--secondary mt-3 mt-md-0" style="width: 100%">
          <div class="d-flex flex-column">
            <v-row :dense="$vuetify.breakpoint.smAndDown">
              <v-col cols="12" md="4" class="ma-auto d-flex align-center justify-space-between">
                <div class="d-flex align-center">
                  <v-skeleton-loader type="button"></v-skeleton-loader>
                  <v-skeleton-loader width="100px" type="list-item-two-line"></v-skeleton-loader>
                </div>
                <v-skeleton-loader type="avatar"></v-skeleton-loader>
              </v-col>
              <v-col cols="12" md="4" class="ma-auto d-flex align-center justify-space-between">
                <div class="d-flex align-center">
                  <v-skeleton-loader type="button"></v-skeleton-loader>
                  <v-skeleton-loader width="100px" type="list-item-two-line"></v-skeleton-loader>
                </div>
                <v-skeleton-loader type="avatar"></v-skeleton-loader>
              </v-col>
              <v-col cols="12" md="4" class="ma-auto d-flex align-center justify-space-between">
                <div class="d-flex align-center">
                  <v-skeleton-loader type="button"></v-skeleton-loader>
                  <v-skeleton-loader width="100px" type="list-item-two-line"></v-skeleton-loader>
                </div>
                <v-skeleton-loader type="avatar"></v-skeleton-loader>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: 'StudentDashboardProgressLoader'
}
</script>

<style scoped>

</style>
