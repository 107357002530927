import { Model } from "@vuex-orm/core";
import LicenseModel from '@/store/models/LicenseModel'

export default class LicenseRequirementModel extends Model {
  static entity = "LicenseRequirement";

  static fields() {
    return {
      id: this.attr(null),
      name: this.string(''),
      isDefault: this.boolean(false),
      active: this.boolean(false),
      basicTheories: this.number(0),
      specialTheories: this.number(0),
      overlands: this.number(0),
      highways: this.number(0),
      nights: this.number(0),
      instructions: this.number(0),
      normals: this.number(0),
      theoryExam: this.boolean(false),
      practiceExam: this.boolean(false),
      licenses: this.attr([])
    };
  }

  get mtls () {
    const licenseB197 = LicenseModel.query().where('name', 'B197').first()

    return this.licenses.includes(licenseB197.id) ? licenseB197.requiredMtl : 0
  }
}
