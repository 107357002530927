import TeacherModel from "@/store/models/TeacherModel";
import StudentModel from "@/store/models/StudentModel";
import ProductCategoryModel from "@/store/models/ProductCategoryModel";
import LessonTypeModel from "@/store/models/LessonTypeModel";
import EventTypeModel from "@/store/models/EventTypeModel";
import VehicleModel from "@/store/models/VehicleModel";
import MeetingPlaceModel from "@/store/models/MeetingPlaceModel";
import TheoryTopicModel from "@/store/models/TheoryTopicModel";
import LicenseGroupModel from "@/store/models/LicenseGroupModel";
import LicenseModel from "@/store/models/LicenseModel";
import OfficeModel from "@/store/models/OfficeModel";
import schoolService from "@/services/schoolService";
import store from "@/store";
import * as mutationTypes from "@/store/mutation-types/events";
import PriceCategoryLessonTypeStatusModel from '@/store/models/PriceCategoryLessonTypeStatusModel'

export default class SchoolDataLoader {
    static load(params) {
        const models = {
            't': TeacherModel,
            's': StudentModel,
            'ds': StudentModel,
            'pc': ProductCategoryModel,
            'pclts': PriceCategoryLessonTypeStatusModel,
            'lt': LessonTypeModel,
            'et': EventTypeModel,
            'v': VehicleModel,
            'mp': MeetingPlaceModel,
            'tt': TheoryTopicModel,
            'lg': LicenseGroupModel,
            'l': LicenseModel,
            'o': OfficeModel
        }

        const method = 'method' in params ? params.method : 'create'
        let filters = Object.keys(models)

        if('only' in params) {
            filters = filters.filter(item => params.only.includes(item))
        } else if('except' in params) {
            filters = filters.filter(item => !params.except.includes(item))
        }

        return new Promise((resolve, reject) => {
            schoolService
              .getSchoolData(filters)
              .then(resp => {
                const response = resp.data;

                filters.forEach(filter => {
                    models[filter][method]({
                        data: response[filter] || {}
                    })
                })

                if ('et' in response) {
                    store.commit(`appointments/${mutationTypes.ASSIGN_EVENT_TYPES}`, response.et || []);
                }

                store.commit(`school/set_school_data_loaded`, true);
                resolve()
            })
            .catch(reject)
        })
    }
}

