<template>
  <ValidationProvider name="students" :rules="`${validation ? 'required' : ''}`" v-slot="{ errors }">
    <v-autocomplete
          @click:clear="clickClear"
          :no-data-text="$t('noItems')"
          :menu-props="{ top: $vuetify.breakpoint.mobile, offsetY: true, contentClass: 'fit-parent-width' }"
          :error="errors.length > 0"
          :disabled="disabled"
          v-model="studentsIds"
          :items="studentsList"
          item-value="id"
          :item-text="getFullName"
          :multiple="multiple"
          :hide-selected="multiple"
          :small-chips="multiple"
          :deletable-chips="multiple"
          :clearable="clearable"
          :loading="loading"
          outlined
          dense
          hide-details
          :label="$tc('label.student_plurable', multiple ? 10 : 1)"
      >
        <template v-slot:item="data" v-if="decorated">
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.fullName }}
              <v-icon v-if="data.item.isDeleted" small color="grey">mdi-delete</v-icon>
            </v-list-item-title>
            <v-list-item-subtitle v-if="data.item.bookAndPay">
              <template v-if="data.item.priceCategoryId">
                {{ data.item.categoryName }}
              </template>
              <template v-else>
                <v-icon color="orange" x-small left>mdi-alert</v-icon>
                {{ $t('label.missing_price_list') }}
              </template>
              <template v-if="data.item.lessonTypeDoesNotHavePrice">
                <v-icon color="orange" x-small left>mdi-alert</v-icon>
                {{ $t('label.missing_prices') }}
              </template>
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action v-if="data.item.bookAndPay">
            <v-list-item-action-text>{{ data.item.balance | currency }}</v-list-item-action-text>
          </v-list-item-action>
        </template>
    </v-autocomplete>
  </ValidationProvider>
</template>

<script>

export default {
  name: 'StudentsField',
  props: {
    studentsList: {
      type: [Number, Array],
      required: true,
    },
    studentsProp: {
      type: Array,
      required: true,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    decorated: {
      type: Boolean,
      required: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    studentsIds: {
      set (value) {
        this.$emit('emitStudentChange', this.multiple ? value : value ? [value] : [])
      },
      get () {
        return this.multiple ? this.studentsProp : this.studentsProp[0]
      },
    },
  },
  methods: {
    clickClear () {
      this.$emit('emitStudentChange', [])
    },
    getFullName (item) {
      return item.fullName
    },
  },
}
</script>

<style lang="scss" scoped></style>
