<template>
  <div>
    <v-row dense>
      <v-col cols="12" lg="10">
        <v-row>
          <v-col cols="12" lg="3">
            <v-text-field
                :label="$t('label.filter_by_name')"
                :placeholder="$t('label.filter_by_name')"
                v-model="filters.name"
                solo
                dense
                hide-details
                clearable
            />
          </v-col>
          <v-col cols="12" lg="3">
            <v-text-field
                :label="$t('label.filter_by_email')"
                :placeholder="$t('label.filter_by_email')"
                v-model="filters.email"
                solo
                dense
                hide-details
                clearable
            />
          </v-col>
          <v-col cols="12" lg="3">
            <v-select
                :label="$t('label.filter_by_office')"
                :placeholder="$t('label.filter_by_office')"
                v-model="filters.office"
                :items="officesOptions"
                solo
                dense
                hide-details
                clearable
            />
          </v-col>
          <v-col cols="12" lg="3">
            <v-dialog
                ref="dialog"
                v-model="isFiltersDatesMenuShow"
                :return-value.sync="filters.dates"
                persistent
                width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    dense
                    readonly
                    :value="formattedDates"
                    :label="$t('label.filter_by_created_at')"
                    :placeholder="$t('label.filter_by_created_at')"
                    clearable
                    @click:clear="onFiltersDatesClear"
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    solo
                    max-width="290px"
                />
              </template>

              <v-date-picker v-model="filters.dates" scrollable range>
                <v-spacer />

                <v-btn text color="primary" @click="isFiltersDatesMenuShow = false">{{$t("btn.cancel") }}</v-btn>
                <v-btn text color="primary" @click="onFiltersDatesDialogOK">{{$t("btn.ok") }}</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="2">
        <v-btn text color="primary" @click="filter" :loading="loading">{{
            $t("btn.filter_results")
          }}</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <Pagination
            v-if="pagination.total > 0"
            :loading="loading"
            :from="pagination.from"
            :to="pagination.to"
            :current-page="pagination.currentPage"
            :total-nr-of-pages="pagination.lastPage"
            :total-nr-of-items="pagination.total"
            @pageChange="onPageChange"
        />
      </v-col>
    </v-row>

    <v-skeleton-loader class="mx-auto" type="table-row@3" v-if="loading" />

    <template v-else>
      <v-simple-table v-if="items.length > 0" dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" style="width: 60px">ID</th>
              <th class="text-left">{{ $t("list.name") }}</th>
              <th class="text-left">{{ $t("list.email") }}</th>
              <th class="text-left">{{ $t("label.office") }}</th>
              <th class="text-left" style="width: 180px">{{ $t("list.created_at") }}</th>
              <th class="text-left" style="width: 180px">{{ $t("archived_at") }}</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id" class="table-row">
              <td class="text-monospace" style="width: 60px">{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.email ? item.email : "-" }}</td>
              <td>{{ item.office ? item.office : "-" }}</td>
              <td>{{ item.createdAt | dateFormat }}</td>
              <td>{{ item.deletedAt | dateFormat }}</td>
              <td style="width: 68px">
                <v-chip
                  class="hidden-until-row-hovered"
                  link
                  label
                  x-small
                  :to="{ name: 'students.student', params: { id: item.id } }">
                  <v-icon x-small>mdi-arrow-right</v-icon>
                </v-chip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-alert v-else>
        <empty-box />
      </v-alert>
    </template>
  </div>
</template>

<script>
import studentsService from "@/services/studentsService";
import Pagination from "@/components/Pagination";
import SchoolDataLoader from '@/helpers/schoolDataLoader'
import OfficeModel from '@/store/models/OfficeModel'
import { format, parseISO } from 'date-fns'

export default {
  name: "RecyclableStudentsList",
  components: { Pagination },
  data: () => ({
    loading: false,
    items: [],
    isFiltersDatesMenuShow: false,
    filters: {
      dates: [],
      name: "",
      email: "",
      office: null,
    },
    pagination: {
      currentPage: 1,
      from: 0,
      lastPage: 1,
      to: 0,
      total: 0,
    },
  }),
  computed: {
    officesOptions() {
      return OfficeModel.all()
        .map((office) => ({
          text: office.name,
          value: office.id,
        }))
    },
    formattedDates() {
      const periodDates = this.filters.dates;
      if (periodDates.length) {
        const formatted = periodDates
            .sort()
            .map((item) => format(parseISO(item), "dd.MM.yyyy"));

        return formatted.length > 1
            ? `vom ${formatted[0]} bis ${formatted[1]}`
            : formatted;
      }
      return periodDates;
    }
  },
  mounted() {
    this.fetch({ page: this.$route.query.page || 1 });
    SchoolDataLoader.load({method: 'create', only: ['o']})
  },
  methods: {
    fetch(params = {}) {
      this.loading = true;
      params.perPage = 50;
      studentsService
        .destroyedList(params)
        .then((response) => {
          const responseData = response.data || {};
          this.items = responseData.data || [];
          this.pagination = responseData.pagination || {};
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filter() {
      this.fetch({ filters: this.filters }).then(() => {
        this.$router
          .replace({ query: { ...this.$route.query, page: 1 } })
          .catch(console.log);
      });
    },
    onPageChange() {
      this.fetch({ page: this.$route.query.page || 1 });
    },
    onFiltersDatesClear() {
      this.filters.dates = [];
    },
    onFiltersDatesDialogOK() {
      this.$refs.dialog.save(this.filters.dates.sort());
    },
  },
};
</script>

<style scoped lang="scss"></style>
