<template>
  <div>
    <v-row>
      <TeachersField
        v-if="!isTeacher"
        :cols="12"
        :model="model"
        :eventDuration="eventDuration"
        :filteredTeachers="filteredTeachers"
        :disabled="!!model.id || isPast" />
      <StudentsField
        v-if="!holiday"
        :cols="12"
        :disabled="disabled || isPast"
        :model="model"
        :eventDuration="eventDuration"
        :filteredStudents="filteredStudents"
        :multiple="true"
        :validation="false" />
    </v-row>

    <TimeFields
      :disabled="disabled || isPast"
      :model="model"
      :eventDuration="eventDuration" />
    <TitleField :disabled="disabled || isPast" :model="model" />
    <TextField :disabled="disabled || isPast" :model="model" label="Title" />
    <v-row>
      <v-col>
        <v-switch
          v-model="model.isWorkTime"
          :disabled="isPast || isDisabledWorkTime"
          inset
          :label="$t('label.teacher_work_time')"
          hide-details></v-switch>
      </v-col>
      <v-col>
        <SignatureField
          v-if="showSignature"
          :eventId="model.id"
          :eventType="model.type"
          :eventSignature="model.signature" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TeachersField from "@/components/calendar/eventModal/fields/TeachersField";
import StudentsField from "@/components/calendar/eventModal/fields/StudentsField";
import TimeFields from "@/components/calendar/eventModal/fields/TimeFields";
import TitleField from "@/components/calendar/eventModal/fields/TitleField";
import TextField from "@/components/calendar/eventModal/fields/TextField";
import SignatureField from "@/components/calendar/eventModal/fields/SignatureField";

import user from "@/utils/mixins/user";
import { parseISO } from "date-fns";
import StudentModel from "@/store/models/StudentModel";
import DateRange from "@/helpers/dateRange";
import { mapGetters } from "vuex";

export default {
  name: "TeacherSpecialForm",
  mixins: [user],
  components: {
    TeachersField,
    StudentsField,
    TimeFields,
    TitleField,
    TextField,
    SignatureField,
  },
  props: {
    eventDuration: {
      type: Number,
      required: true,
    },
    filteredTeachers: {
      type: Array,
      required: true,
    },
    isPast: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    disabled() {
      return !this.model.teacherId;
    },
    holiday() {
      const regions = this.schoolSettings.regions?.split(", ");
      return new DateRange().isHoliday(parseISO(this.model.date), regions);
    },
    filteredStudents() {
      return (
        StudentModel.query()
          .where((student) => !student.isDeleted)
          .where("teachersIds", (teachersIds) =>
            teachersIds.includes(this.model.teacherId)
          )
          .get() || []
      );
    },
    showSignature() {
      return (
        this.model.id &&
        this.model.specialType === "STANDARD" &&
        this.model.isWorkTime
      );
    },
    isDisabledWorkTime() {
      return !!this.model.signature;
    },
  },
};
</script>
