<script>
import ExpandablePanel from "@/components/ExpandablePanel.vue";
import {mapGetters} from "vuex";

export default {
  name: "StudentMiscellaneous",
  components: {ExpandablePanel},
  props: {
    student: {
      type: Object,
      required: true
    },
  },
  computed: mapGetters('school', ['school']),
}
</script>

<template>
  <expandable-panel panel-name="studentMiscellaneousPanel">
    <template v-slot:title>
      {{ $t('label.miscellaneous') }}
    </template>
    <v-simple-table dense>
      <tbody>
      <tr>
        <td>{{ $t('label.office') }}</td>
        <td>{{ student.office ?? '-' }}</td>
      </tr>
      <tr>
        <td>{{ $t('label.max_lesson_per_week') }}</td>
        <td>{{ student.maxLessonsAmountPerWeek ?? $t('label.unlimited') }}</td>
      </tr>
      <tr>
        <td>{{ $t('label.max_lesson_per_day') }}</td>
        <td>{{ student.maxLessonsAmountPerDay ?? $t('label.unlimited') }}</td>
      </tr>
      <tr>
        <td>{{ $t('label.max_driving_minutes_per_day') }}</td>
        <td>{{ student.maxLessonsDurationPerDay ?? $t('label.unlimited') }}</td>
      </tr>
      <tr>
        <td>{{ $t('label.mobile_devices') }}</td>
        <td>{{ student.mobileDevices }}</td>
      </tr>
      <tr>
        <td>{{ $t('label.parent_accounts') }}</td>
        <td>{{ student.elternAccounts }}</td>
      </tr>
      <tr v-if="student.bookAndPay && school.isPaymentBonusEnabled">
        <td>{{ $t('label.payment_bonus') }}</td>
        <td>{{ student.isPaymentBonusApplied ? student.paymentBonusAppliedAt : $t('label.active') }}</td>
      </tr>
      </tbody>
    </v-simple-table>
  </expandable-panel>
</template>

<style scoped>

</style>