import Notification from "@/notifications/notification";
import router from "@/router";

export default class StudentBalanceUpdated extends Notification {
  translationKey() {
    const data = this.notification.data || {};
    return super.translationKey() + `.${data.key}`;
  }

  action() {
    return router
      .push({ name: "balance" })
      .catch((error) => console.log(error));
  }

  wildCardsData() {
    const data = this.notification.data;

    return {
      message: {
        moneyAmount: data.moneyAmount + "€",
      },
    };
  }
}
