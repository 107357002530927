<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "StudentFeatureInstructionsDialog",
  data() {
    return {
      show: false,
    }
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    }
  }
})
</script>

<template>
  <v-dialog width="500" :value="show" scrollable persistent>
    <v-card>
      <v-card-title primary-title>
        {{ $t("insights.instructions.dialogTitle") }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t("insights.instructions.dialogSubtitle") }}
      </v-card-subtitle>

      <v-card-text id="card">
        <p>{{ $t("insights.instructions.intro") }}</p>
        <ol>
          <li>{{ $t("insights.instructions.step_1") }}</li>
          <li>{{ $t("insights.instructions.step_2") }}</li>
          <li>{{ $t("insights.instructions.step_3") }}</li>
          <li>{{ $t("insights.instructions.step_4") }}</li>
          <li>{{ $t("insights.instructions.step_5") }}</li>
        </ol>
        <p class="mt-3">{{ $t("insights.instructions.outro") }}</p>
      </v-card-text>

      <v-divider/>

      <v-card-actions class="d-flex justify-end">
        <v-btn text @click="close">{{ $t("btn.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>
