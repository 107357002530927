<template>
    <v-alert :type="alertType">
        {{ message }}
    </v-alert>
</template>

<script>
export default {
  name: "VehicleNewMileageValidation",
  props: {
    newMileage: {
      type: Number,
      required: true
    },
    lastMileage: {
      type: Number,
      required: true
    }
  },
  computed: {
    increase() {
      return this.newMileage - this.lastMileage
    },
    message() {
      if (this.increase < 0) {
        return this.$t('label.mileage_should_be_greater', {lastMileage: this.lastMileage})
      }

      if (this.increase > 0) {
        return this.$t('label.mileage_increase', { newMileage: this.newMileage, increase: this.increase })
      }

      return this.$t('label.enter_new_mileage');
    },
    hasError () {
      return this.increase < 0
    },
    alertType () {
      return this.hasError ? 'error' : (this.increase ? 'success' : 'info')
    }
  },
}
</script>

<style scoped>

</style>