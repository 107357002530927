<template>
  <v-card
      elevation="0"
      class="event-card"
      @click="eventClicked(event)"
      :style="`border-left: 10px solid ${eventColor.bodyColor};`">
    <div class="flex-time">
      <div>
        {{ event.formattedDate }} | {{ event.startTime }} - {{ event.endTime }}
      </div>
      <div>{{ event.duration }}
        <v-icon size="small">mdi-clock</v-icon>
      </div>
    </div>
    <div class="font-weight-bold" v-if="event.teacher && !isTeacher">
      {{ event.teacher.fullName }}
    </div>
    <div v-if="!isStudent || (isStudent && !blockedEvent.status)">
      <div v-if="!isStudent && event.type !== 'THEORY' && event.studentsIds.length">
        {{ studentsLabel }}
      </div>
      <div v-if="event.type !== 'PRACTICE'">{{ eventLabel }}</div>
      <div v-else>
        <div class="text-overflow-ellipsis">
          <v-icon v-if="event.isManualB197" small>mdi-alpha-s-box</v-icon>
          {{ licenseName }}
          <span v-if="lessonTypeLabel" class="text-uppercase">— {{ lessonTypeLabel }}</span>
        </div>
      </div>
    </div>
    <div v-if="event.type === 'SPECIAL'" class="text-overflow-ellipsis">
      {{ event.title }}
    </div>

    <div v-if="event.type === 'THEORY'" class="text-overflow-ellipsis">
      <v-icon size="15" v-if="event.theoryTopicId">
        {{theoryTopic.type === 'basic' ? 'mdi-alpha-g-box' : 'mdi-alpha-z-box' }}
      </v-icon>
      {{ theoryTopicLabel }}
    </div>

    <div v-if="!blockedEvent.status" class="icon-box d-flex justify-end">
      <v-icon v-if="event.hasSignature" class="sing-icon" size="15">
        mdi-signature-freehand
      </v-icon>
      <v-icon v-if="passedExamIcon" class="sing-icon ml-1" size="15">
        mdi-check-decagram
      </v-icon>
      <v-icon v-if="isExamInPast" class="sing-icon ml-1" size="15">
        mdi-lock
      </v-icon>
      <v-icon v-if="showCheckIcon" class="ml-1" size="15">
        mdi-check-circle
      </v-icon>
      <v-icon v-if="showHelpIcon" class="ml-2" size="15">
        mdi-help-circle
      </v-icon>
      <v-icon v-if="showAccountIcon" class="ml-2" size="15">
        mdi-account-circle
      </v-icon>
      <v-icon v-if="showMissedIcon" class="ml-2" size="15">
        {{ event.status === 'MISSED' ? 'mdi-call-missed' : 'mdi-cash-refund' }}
      </v-icon>

      <EventSubscribedStudents
          v-if="!isStudent &&  event.type === 'THEORY' && event.group === 'lesson'"
          :event-type="event.type"
          :students-count="event.studentsIds.length"
          :room-size="event.roomSize"
      />
      <EventSubscribedStudents
          v-if="!isStudent && event.type === 'THEORY' && event.group === 'exam' && event.studentsIds.length"
          :event-type="event.type"
          :students-count="event.studentsIds.length"
      />
      <EventSubscribedStudents
          v-if="!isStudent && event.type === 'SPECIAL' && event.studentsIds.length"
          :event-type="event.type"
          :students-ids="activeStudentsIds"
      />
    </div>

    <div v-if="event.meetingPlace && !blockedEvent.status" class="text-caption text-truncate">
      {{ event.meetingPlace }}
    </div>

    <div v-if="event.primaryVehicleId" class="vehicles">
      <div :style="`background: ${primaryVehicle.color}; color: ${invertColor(primaryVehicle.color)}`">
        {{ primaryVehicle.label }}
      </div>
      <div v-if="event.secondaryVehicleId"
           :style="`background: ${secondaryVehicle.color}; color: ${invertColor(secondaryVehicle.color)}`">
        {{ secondaryVehicle.label }}
      </div>
    </div>

    <div v-if="event.isRequested" class="pa-1">
      <div v-for="(student, index) in requestedPlaceholderStudents" :key="index" style="font-size: 0.6rem" class="text-overflow-ellipsis">
        {{ student }}
      </div>
    </div>
  </v-card>
</template>

<script>
import user from '@/utils/mixins/user'
import { mapActions } from 'vuex'
import eventMixin from '@/utils/mixins/eventMixin'
import colorMixin from '@/utils/mixins/colorMixin'

export default {
  name: 'AppointmentMobile',
  mixins: [user, eventMixin, colorMixin],
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
  },
  methods: {
    ...mapActions('appointments', ['showAppointmentModal']),
    eventClicked (event) {
      if (this.disabled) return
      this.showAppointmentModal(event)
    },
  },
}
</script>

<style lang="scss" scoped>
.event-card {
  padding: 0 5px 5px 5px;
  margin: 5px 0;
  font-size: 0.7rem;
  background: #fff !important;

  .flex-time {
    display: flex;
    justify-content: space-between;
  }

  & > * {
    pointer-events: none;
    font-size: 12px;
    padding: 0 5px;
  }

  .request-mark {
    position: absolute;
    bottom: 3px;
    right: 3px;
  }

  .icon-box {
    position: absolute;
    right: 5px;
    bottom: 5px;
    color: #333;
  }

  .vehicles {
    width: 100%;
  }
}
</style>
