import { render, staticRenderFns } from "./SupportTicketsListLoader.vue?vue&type=template&id=5bc4580f&scoped=true"
import script from "./SupportTicketsListLoader.vue?vue&type=script&lang=js"
export * from "./SupportTicketsListLoader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bc4580f",
  null
  
)

export default component.exports