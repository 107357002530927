<template>
  <v-alert color="warning" text>
    <div class="text-subtitle-1 font-weight-bold">
      {{ $t("label.email_not_verified") }}
    </div>
    <div class="text-subtitle-1 mt-2">
      {{ $t("label.email_verification_text", { email }) }}
    </div>
  </v-alert>
</template>

<script>
export default {
  name: "EmailVerificationNotification",
  props: ["email"],
};
</script>

<style scoped></style>
