import { Model } from "@vuex-orm/core";
import { v4 as uuid4 } from "uuid";

export default class SnackbarModel extends Model {
  static entity = "snackbars";

  static fields() {
    return {
      id: this.uid(() => uuid4()),
      type: this.string(this.DEFAULT),
      icon: this.attr(null),
      title: this.string(""),
      message: this.string(""),
      list: this.attr([]),
      timeout: this.number(-1),
      position: this.attr({
        left: false,
        right: false,
        top: false,
        bottom: true,
      }),
      color: this.string(""),
    };
  }

  static get DEFAULT() {
    return "default";
  }

  static get TOAST() {
    return "toast";
  }
}
