<template>
  <v-row class="align-center">
    <v-col cols="1">
      <LicenseIcon :name="license.name" :icon="license.icon" row fluid height="30" />
    </v-col>
    <v-col cols="5" class="d-flex flex-row align-center justify-center">
      <v-radio-group
          row
          dense
          hide-details
          style="margin: 0 !important;"
          v-model="form.basicPriceType"
          @change="value => fillPriceFiled(studentId, license.id, value)"
          :disabled="form.basicPriceDisabled"
      >
        <v-radio :label="$t('one_time_product.basic_amount_full')" value="basic_amount_full" :ripple="false"/>
        <v-radio :label="$t('one_time_product.basic_amount_partial')" value="basic_amount_partial" :ripple="false"/>
      </v-radio-group>

      <v-text-field
          dense
          hide-details
          append-icon="mdi-currency-eur"
          placeholder="0,00"
          v-model="form.basicPriceAmount"
          :disabled="form.basicPriceDisabled || ! form.basicPriceType"
          v-currency="{
            currency: null,
            allowNegative: false,
            valueRange: { min: 0 },
            valueAsInteger: false,
            precision: 2,
          }"
          @change="setBasicPriceAmount"
          @click:clear="clearPriceFiled(studentId, license.id,'basic_amount')"
      />
    </v-col>

    <v-col cols="4" class="d-flex align-center ">
      <v-checkbox
          style="margin: 0 !important"
          class="mr-2"
          :ripple="false"
          hide-details
          :label="$t('one_time_product.learning_material')"
          v-model="form.learningMaterial"
          :disabled="form.learningMaterialDisabled"
          @change="fillPriceFiled(studentId, license.id, 'learning_material')"
      />

      <v-text-field
          dense
          hide-details
          clearable
          class="ml-2"
          placeholder="0,00"
          v-model="form.learningMaterialAmount"
          :disabled="form.learningMaterialDisabled || ! form.learningMaterial"
          append-icon="mdi-currency-eur"
          v-currency="{
            currency: null,
            allowNegative: false,
            valueRange: { min: 0 },
            valueAsInteger: false,
            precision: 2,
          }"
          @click:clear="clearPriceFiled(studentId, license.id, 'learning_material')"
      />
    </v-col>
    <v-col cols="2" class="d-flex flex-row align-center justify-end">
<!--      <v-checkbox style="margin: 0 !important" :ripple="false" hide-details label="Bill" v-model="form.bill" />-->
      <v-btn
          small
          color="primary"
          @click="payForLicense(studentId, license.id)"
          :disabled="isApplyButtonDisabled"
          :loading="paying"
      >
        {{ $t('btn.apply') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import LicenseIcon from '@/components/LicenseIcon.vue'
import studentsService from '@/services/studentsService'
import OneTimeProductModel from '@/store/models/OneTimeProductModel'
import currency from '@/utils/mixins/currency'

export default {
  name: 'UnpaidStudentLicenseRow',
  mixins: [currency],
  components: { LicenseIcon },
  props: {
    studentId: {
      type: Number,
      required: true
    },
    priceCategoryId: {
      type: Number,
      required: true
    },
    license: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => {
    return {
      form: {
        licenseId: null,
        basicPriceDisabled: false,
        basicPriceType: null,
        basicPriceAmount:  0,
        learningMaterialDisabled: false,
        learningMaterial: false,
        learningMaterialAmount: 0,
        bill: true
      },
      paying: false
    }
  },
  computed: {
    isApplyButtonDisabled() {
      return this.paying ||
          !(this.form.learningMaterial &&
          !this.form.learningMaterialDisabled ||
          this.form.basicPriceType &&
          !this.form.basicPriceDisabled)
    },
  },
  created () {
    const basicPricePaid = this.license.wasBasicAmountFullBilled || this.license.wasBasicAmountPartialBilled
    const basicPriceType = basicPricePaid ? this.license.wasBasicAmountFullBilled ? 'basic_amount_full' : 'basic_amount_partial' : null
    const learningMaterialAmount = this.license.wasLearningMaterialBilled ?
        parseFloat(this.license.learningMaterialPayment) : 0

    this.form = {
      licenseId: this.license.id,
      basicPriceDisabled: basicPricePaid,
      basicPriceType: basicPriceType,
      basicPriceAmount: basicPricePaid ? parseFloat(this.license.basicAmountPayment) : 0,
      learningMaterialDisabled: this.license.wasLearningMaterialBilled,
      learningMaterial: this.license.wasLearningMaterialBilled,
      learningMaterialAmount: learningMaterialAmount,
      bill: true
    }
  },
  methods: {
    payForLicense(studentId, licenseId) {
      this.paying = true

      this.form.learningMaterialAmount = this.sanitizePrice(this.form.learningMaterialAmount)
      this.form.basicPriceAmount = this.sanitizePrice(this.form.basicPriceAmount)

      studentsService
        .payForLicense(studentId, this.form)
        .then(() => {
          this.form.basicPriceDisabled = this.form.basicPriceType !== null
          this.form.learningMaterialDisabled = this.form.learningMaterial

          this.$emit('applied', {
            studentId,
            licenseId,
            basicPriceDisabled: this.form.basicPriceDisabled,
            learningMaterialDisabled: this.form.learningMaterialDisabled
          })

          this.$snackbar.show({
            message: this.$t('messages.successfully_applied'),
            color: "success",
            timeout: 3000,
          });
        })
        .catch(error => {
          const responseData = error.response.data || {};
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });
          }
        })
        .finally(() => {
          this.paying = false
        })
    },
    fillPriceFiled(studentId, licenseId, productType) {
      const product = OneTimeProductModel.query().with('prices').where('key', productType).first();
      let price = product.prices.find(item => {
        return item.priceCategoryId === this.priceCategoryId && item.licenseId === licenseId
      })

      price = parseFloat(price?.price) ? this.sanitizePrice(price?.price,',') : null

      if (productType === 'learning_material') {
        this.form.learningMaterialAmount = price
      } else {
        this.form.basicPriceAmount = price
      }
    },
    clearPriceFiled(studentId, licenseId, productType) {
      if(productType === 'learning_material') {
        this.learningMaterialAmount = ""
      } else {
        this.form.basicPriceAmount = ""
      }
    },
    setBasicPriceAmount(value) {
      this.form.basicPriceAmount = value
    }
  }
}
</script>
