<template>
  <div class="d-flex align-content-center align-center justify-center text-center text-caption caption" style="height: 30px;">
    <router-link v-if="appVersion" :to="{name: 'patch-notes'}" class="text-decoration-none">
      <span class="text--secondary">v.{{ appVersion }}</span>
    </router-link>
  </div>
</template>

<script>
import appVersion from "@/utils/mixins/appVersion";

export default {
  name: "AppVersionLink",
  mixins: [appVersion]
}
</script>

<style scoped>

</style>