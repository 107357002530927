import i18n from "@/plugins/i18n";
import AppointmentNotification from "@/notifications/events/appointmentNotification";

export default class AppointmentReminder extends AppointmentNotification {
  translationKey() {
    const data = this.notification.data || {};
    return super.translationKey() + `.${data.hoursBefore}_hours_before`;
  }

  wildCardsData() {
    const data = this.notification.data;
    const eventType = i18n.t(`event_type.${data.type}`);

    return {
      title: {
        eventType: eventType,
      },
      message: {
        eventType: eventType,
      },
    };
  }
}
