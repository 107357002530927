<script>
import {defineComponent} from 'vue'
import clickAndLearnService from "@/services/clickAndLearnService";
import BuyClickAndLearnCodesDialog from "@/components/clickandlearn/BuyClickAndLearnCodesDialog.vue";

export default defineComponent({
  name: "AvailableCodesWidget",
  components: {BuyClickAndLearnCodesDialog},
  data() {
    return {
      loadingLicenseCodes: false,
      loadingRenewalCodes: false,
      licenseCodes: {
        available: 0,
        total: 0
      },
      renewalCodes: {
        available: 0,
        total: 0
      }
    };
  },
  mounted() {
    this.loadingLicenseCodes = true;
    this.loadingRenewalCodes = true;
    clickAndLearnService
      .getLicenseCodesCount()
      .then((response) => {
        this.licenseCodes = response.data;
      })
      .finally(() => {
        this.loadingLicenseCodes = false;
      });
    clickAndLearnService
      .getRenewalCodesCount()
      .then((response) => {
        this.renewalCodes = response.data;
      })
      .finally(() => {
        this.loadingRenewalCodes = false;
      });
  },
  methods: {
    colorByCount(count, loading = false) {
      if (loading) return 'grey--text';

      if (count === 0) {
        return 'red--text';
      } else if (count <= 10) {
        return 'orange--text';
      } else {
        return 'green--text';
      }
    },
    handleNewOrderPlaced(data) {
      this.$emit('order-placed', data);
    }
  },
  computed: {
    buyingAvailable() {
      return !(this.loadingLicenseCodes || this.loadingRenewalCodes)
        && (this.licenseCodes.available <= 10 || this.renewalCodes.available <= 10)
    }
  }
})
</script>

<template>
  <v-row class="mb-3">
    <v-col cols="12" md="6">
      <v-card class="mx-auto" outlined :loading="loadingLicenseCodes">
        <v-list-item two-line>
          <v-list-item-content>
            <div class="text-overline">
              {{ $t('clickandlearn.available_license_codes') }}
            </div>
            <v-list-item-title class="text-h5">
              <span :class="colorByCount(licenseCodes.available, loadingLicenseCodes)">
                {{ loadingLicenseCodes ? '-' : licenseCodes.available }}
              </span>
              <span class="disabled text-overline">/{{ loadingLicenseCodes ? '-' : licenseCodes.total }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card class="mx-auto" outlined :loading="loadingRenewalCodes">
        <v-list-item two-line>
          <v-list-item-content>
            <div class="text-overline">
              {{ $t('clickandlearn.available_renewal_codes') }}
            </div>
            <v-list-item-title class="text-h5">
              <v-list-item-title class="text-h5">
                <span :class="colorByCount(renewalCodes.available, loadingRenewalCodes)">
                  {{ loadingRenewalCodes ? '-' : renewalCodes.available }}
                </span>
                <span class="disabled text-overline">/{{ loadingRenewalCodes ? '-' : renewalCodes.total }}</span>
              </v-list-item-title>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
    <v-col cols="12" v-if="buyingAvailable">
      <v-alert border="left" colored-border elevation="1" color="warning">
        <div class="d-flex flex-column flex-md-row">
          <div class="flex-grow-1 align-self-center">
            {{ $t('clickandlearn.buy_more_explanation') }}
          </div>
          <v-btn @click="$refs.buyCodesDialog.open()" small color="secondary" class="align-self-md-center mt-3 mt-md-0">
            <v-icon left>mdi-cart</v-icon>
            {{ $t('clickandlearn.buy_more') }}
          </v-btn>
        </div>
      </v-alert>
    </v-col>
    <buy-click-and-learn-codes-dialog ref="buyCodesDialog" @order-placed="handleNewOrderPlaced" />
  </v-row>
</template>

<style scoped>

</style>