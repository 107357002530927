<template>
  <ValidationProvider
    name="meeting-place"
    :rules="`${validation ? 'required' : ''}`"
    v-slot="{ errors }">
    <v-combobox
      dense
      outlined
      hide-details
      open-on-clear
      @update:search-input="onPlaceSelect"
      :error="errors.length > 0"
      v-model="meetingPlace"
      :disabled="disabled"
      :label="$t('form.meeting_place')"
      :items="placesList"
      item-text="value"
      item-value="value"
      :append-icon="!disabled ? 'mdi-menu-down' : ''">
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title v-if="data.item.name">
            {{ data.item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ data.item.address }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-combobox>
  </ValidationProvider>
</template>
<script>
import user from "@/utils/mixins/user";

export default {
  name: "MeetingPlaceField",
  mixins: [user],
  props: {
    validation: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    meetingPlacesList: {
      type: Array,
      validator: function (itemsArr) {
        return itemsArr.every(
          (item) => Object.hasOwn(item, "items") && Array.isArray(item.items)
        );
      },
    },
    meetingPlaceProp: {
      type: String,
    },
  },
  computed: {
    meetingPlace: {
      set(value) {
        this.onPlaceSelect(value.value);
      },
      get() {
        return this.meetingPlaceProp;
      },
    },
    placesList() {
      return this.meetingPlacesList
        .filter((group) => group.items.length > 0)
        .map((group) => this.createPlaceListGroup(group.label, group.items))
        .flat();
    },
  },
  methods: {
    onPlaceSelect(value) {
      this.$emit("emitMeetingPlaceSelect", value);
    },
    createPlaceListGroup(header, places = []) {
      return [
        { header: header },
        { divider: true },
        ...places.map((place) => ({
          name: place.name,
          address: place.address,
          value: place.name ? place.name + ", " + place.address : place.address ,
        })),
      ];
    },
  },
};
</script>
