export const SHOW_APPOINTMENT_MODAL = "SHOW_APPOINTMENT_MODAL";
export const CLOSE_APPOINTMENT_MODAL = "CLOSE_APPOINTMENT_MODAL";
export const TOGGLE_APPOINTMENT_LOADER = "TOGGLE_APPOINTMENT_LOADER";
export const APPOINTMENT_FILTER_TOGGLE = "APPOINTMENT_FILTER_TOGGLE";
export const HOVER_EVENT = "HOVER_EVENT";
export const SHADOW_APPOINTMENT_TOGGLE = "SHADOW_APPOINTMENT_TOGGLE";
export const DRAGGED_EVENT_ON = "DRAGGED_EVENT_ON";
export const DRAGGED_EVENT_OFF = "DRAGGED_EVENT_OFF";
export const SET_HOVER_DATE = "SET_HOVER_DATE";
export const SHOW_EVENT_CREATION_MENU = "SHOW_EVENT_CREATION_MENU";
export const HIDE_EVENT_CREATION_MENU = "HIDE_EVENT_CREATION_MENU";
export const LOADING_EVENT_TOGGLE = "LOADING_EVENT_TOGGLE";
export const ASSIGN_EVENT_TYPES = "ASSIGN_EVENT_TYPES";
export const START_APPOINTMENT_CREATION = "START_APPOINTMENT_CREATION";
export const RESET_APPOINTMENT_FORM = "RESET_APPOINTMENT_FORM";
export const MULTI_DELETE_STATUS_TOGGLE = "MULTI_DELETE_STATUS_TOGGLE";
export const MULTI_DELETE_ADD = "MULTI_DELETE_ADD";
export const MULTI_DELETE_REMOVE = "MULTI_DELETE_REMOVE";
export const MULTI_DELETE_CLEAR = "MULTI_DELETE_CLEAR";
export const COPY_EVENTS_STATUS_TOGGLE = "COPY_EVENTS_STATUS_TOGGLE";
export const SET_UPDATED_TYPE = "SET_UPDATED_TYPE";
export const UPDATE_APPOINTMENT_FIELD = "UPDATE_APPOINTMENT_FIELD";
export const SET_SIGNATURE = "SET_SIGNATURE";
export const SET_FILTER = "SET_FILTER";
