<template>
  <v-card class="mx-auto mb-5 print-media-product">
    <v-img class="image" :src="require(`@/assets/img/${item.image}`)" />

    <v-card-title>{{ item.label }}</v-card-title>

    <v-card-text class="py-0">
      <div>{{ item.text }}</div>
      <v-divider class="ma-4"></v-divider>

      <div>{{ item.priceText }}</div>
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <h4>{{ item.pricePer500 }}</h4>
          <h4 class="ml-10">{{ item.pricePer1000 }}</h4>
        </div>
      </div>
    </v-card-text>

    <div class="px-3 mb-3">
      <v-divider class="mx-4"></v-divider>
      <v-btn block color="green" text @click="download(item)">
        {{ item.previewLabel }}
      </v-btn>
      <v-btn color="primary" text block @click="$refs.PrintMediaDialog.open()">
        Bestellen
      </v-btn>
    </div>

    <PrintMediaDialog ref="PrintMediaDialog" :item="item" />
  </v-card>
</template>

<script>
import PrintMediaDialog from "@/components/print-media/PrintMediaDialog";
import documentsService from "@/services/documentsService";
import FileSaver from "@/helpers/fileSaver";

export default {
  name: "PrintMediaProduct",
  components: { PrintMediaDialog },
  data: () => ({
    dialog: false,
  }),
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    download(item) {
      documentsService.downloadPDF(item.downloadType)
        .then(response => {
          const fileSaver = new FileSaver([response.data]);
          fileSaver
            .setType("application/pdf")
            .saveToDevice(item.downloadFileName);
        });
    }
  }
};
</script>

<style lang="scss">
.print-media-product {
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
  .image {
    border-radius: 10px !important;
    margin: 20px auto 0;
    max-height: 250px;
    aspect-ratio: 1 / 1;
  }
}
</style>
