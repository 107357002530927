<template>
  <v-snackbar
    class="mr-2 v-snack--default"
    elevation="0"
    :value="true"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    :left="snackbar.position.left"
    :right="snackbar.position.right"
    :top="snackbar.position.top"
    :bottom="snackbar.position.bottom"
    transition="scroll-y-transition">
    <div :class="`mt-1 mx-3 ${!snackbar.list ? 'd-flex' : ''}`">
      <v-icon v-if="snackbar.icon">{{ snackbar.icon }}</v-icon>
      <span class="text-subtitle-1">{{ snackbar.message }}</span>
      <ul v-for="(item, index) in snackbar.list" :key="index">
        <li>{{ item.teacherName }} - {{ item.teacherPhone }}</li>
      </ul>
    </div>

    <template v-slot:action>
      <v-btn
        class="mr-1 ml-auto"
        icon
        small
        text
        @click="deleteSnackbar(snackbar.id)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackbarDefault",
  props: {
    snackbar: {
      type: Object,
      required: true,
    },
  },
  methods: {
    deleteSnackbar(notificationId) {
      this.$snackbar.delete(notificationId);
    },
  },
};
</script>
