<template>
  <div class="user-cell-header-meta">
    <v-avatar left size="32">
      <img :src="teacher.avatar" alt="" />
    </v-avatar>
    <span class="user-name">
      {{ teacher.fullName }}
    </span>
    <v-btn color="grey" icon @click="$emit('closed')">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Teacher from "@/helpers/teacher";

export default {
  name: "UserMeta",
  props: {
    teacher: {
      type: Teacher,
      required: true,
    },
  },
  computed: mapGetters(["activeTeachersIds"]),
};
</script>

<style lang="scss" scoped>
.user-cell-header-meta {
  z-index: 10;
  display: flex;
  align-items: center;
  padding: 0 10px;

  & > .user-name {
    display: inline-block;
    margin-left: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
    color: #737272;
    font-size: 18px;
  }

  & > button {
    margin-left: auto;
  }
}
</style>
