import { render, staticRenderFns } from "./VehicleInfoCard.vue?vue&type=template&id=60c994e5&scoped=true"
import script from "./VehicleInfoCard.vue?vue&type=script&lang=js"
export * from "./VehicleInfoCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60c994e5",
  null
  
)

export default component.exports