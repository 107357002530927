<template>
  <v-checkbox v-model="isManualLesson" :disabled="disabled" :label="$t('form.manual_lesson')" hide-details />
</template>

<script>
export default {
  name: "ManualLessonCheckboxField",
  props: {
    statusProp: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isManualLesson: {
      set(value) {
        this.$emit("emitManualStatusChange", value);
      },
      get() {
        return this.statusProp;
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
