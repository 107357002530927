import { Model } from "@vuex-orm/core";
import EvaluationItemModel from '@/store/models/insights/EvaluationItemModel'
import ItemModel from '@/store/models/insights/ItemModel'

export default class EvaluationModel extends Model {
  static entity = "InsightsEvaluations";

  static fields() {
    return {
      id: this.attr(null),
      appointmentId: this.attr(null),
      appointmentData: this.attr(null),
      comment: this.attr(null),
      drivingArea: this.attr(null),
      isPublished: this.boolean(false),
      isPublishedByStudent: this.boolean(false),
      selfEvaluation: this.boolean(false),
      items: this.belongsToMany(ItemModel, EvaluationItemModel, "evaluationId", "itemId"),
      templateId: this.attr(null)
    };
  }
}
