<template>
  <v-row>
    <v-col>
      <ValidationProvider
        name="text"
        :rules="`${validation ? 'required' : ''}`"
        v-slot="{ errors }">
        <v-textarea
          :error="errors.length > 0"
          v-model="model.eventText"
          :disabled="disabled"
          :label="$t('form.text')"
          auto-grow
          outlined
          dense
          rows="1"
          row-height="15"
          hide-details />
      </ValidationProvider>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TextField",
  props: {
    validation: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
  },
};
</script>
