<template>
  <v-container>
    <v-card :loading="loading">
      <v-toolbar elevation="3">
        <v-card-title>{{ $t("label.your_children") }}</v-card-title>
        <v-spacer />
        <v-btn
          v-if="children.length"
          color="primary"
          exact
          text
          @click="openAddChildAccountDialog"
        >
          {{ $t("label.add_child") }}
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <children-accounts-skeleton-loader v-if="loading" />
        <template v-else>
          <v-card-subtitle>{{ $t("label.child_accounts_explanation") }}</v-card-subtitle>
          <div v-if="!children.length" class="d-flex flex-column align-center pb-5">
            <empty-box :text="$t('messages.you_do_not_have_any_child_accounts')" />
            <v-btn x-large color="secondary" @click="openAddChildAccountDialog">
              <v-icon left>mdi-account-plus</v-icon>
              {{ $t("btn.add_child") }}
            </v-btn>
          </div>

          <template v-else>
            <v-divider/>

            <v-list two-line>
              <v-list-item
                v-for="child in children"
                :key="child.id"
                :class="{'red lighten-5': !activeStatus(child)}"
                :disabled="!activeStatus(child)"
                @click="select(child.id)"
              >
                <v-list-item-avatar>
                  <UserAvatar :path="child.avatar" :alt="child.name" :size="40" :rounded="true" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ child.name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <div>{{ child.school }}</div>
                    <div v-if="child.isBookandpay" :class="child.balance > 0 || 'red--text'">
                      {{ child.balance | currency }}
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    :disabled="!!unlinkIdLoader"
                    :loading="unlinkIdLoader === child.id"
                    class="white--text ml-2"
                    color="red"
                    small
                    style="pointer-events: initial"
                    @click="unlinkStudent(child.id)"
                    @click.stop
                  >
                    <v-icon :left="!$vuetify.breakpoint.mobile" dark small>mdi-link-variant-off</v-icon>
                    <span v-if="!$vuetify.breakpoint.mobile">{{ $t("btn.unlink") }}</span>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </template>
        </template>
      </v-card-text>
    </v-card>
    <add-child-account-dialog ref="addChildAccountDialog" @close="fetchChildren" />
  </v-container>
</template>

<script>

import user from '@/utils/mixins/user'
import currency from '@/utils/mixins/currency'
import ChildrenAccountsSkeletonLoader from "@/components/skeleton-loaders/ChildrenAccountsSkeletonLoader.vue";
import childrenAccountsService from "@/services/childrenAccountsService";
import AddChildAccountDialog from "@/components/parent/AddChildAccountDialog.vue";
import { mapActions } from "vuex";
import { showConfirm } from "@/helpers/dialogs";
import ChildModel from '@/store/models/ChildModel'
import UserAvatar from '@/components/UserAvatar.vue'
// import { EventBus } from '@/EventBus'
// import laravelEcho from '@/plugins/laravel-echo'

export default {
  name: 'ChildrenAccounts',
  components: {
    UserAvatar,
    AddChildAccountDialog,
    ChildrenAccountsSkeletonLoader
  },
  mixins:[user, currency],
  data() {
    return {
      unlinkIdLoader: null,
    }
  },
  computed: {
    children() {
      return ChildModel.query().get() || []
    },
    loading() {
      return this.$store.state.children.loadingChildren
    }
  },
  mounted() {
    this.fetchChildren()
  },
  methods: {
    ...mapActions("children", ['fetchChildren']),
    ...mapActions("auth", ["setParentSelectedStudentId"]),
    select(studentId) {
      this.$store.commit("school/set_school_loaded", false);
      this.$store.commit("school/set_school_data_loaded", false);

      this.setParentSelectedStudentId(studentId);

      //   location.reload()

      this.$router
        .push({ name: "dashboard" })
        .then(() => this.$root.refresh())
    },
    openAddChildAccountDialog() {
      this.$refs.addChildAccountDialog.open();
    },
    activeStatus(child) {
      return (child.isPermissionGranted || !child.is18yo) && child.privacyPolicyAccepted;
    },
    unlinkStudent(studentId) {
      showConfirm(
        this.$t("btn.confirm"),
        this.$t("messages.are_your_sure_unlink_child"),
        () => {
          this.unlinkIdLoader = studentId;
          childrenAccountsService
            .unlinkStudentFromParent(studentId)
            .then(() => ChildModel.delete(studentId))
            .catch((error) => {
              const response = error.response || {};
              const responseData = response.data || {};

              if (responseData.message) {
                this.$snackbar.show({ message: responseData.message, color: "error" });
              }
            })
            .finally(() => {
              this.unlinkIdLoader = null;
            });
        });
    },
  },
}
</script>

<style lang="scss" scoped></style>
