import messengerService from "@/services/messengerService";
import ChatParticipant from "@/store/models/ChatParticipant";
import ChatRoom from "@/store/models/ChatRoom";
import ChatMessage from "@/store/models/ChatMessage";
import SnackbarModel from "@/store/models/SnackbarModel";
import { mapActions } from "vuex";
import i18n from "@/plugins/i18n";
import user from "@/utils/mixins/user";
import laravelEcho from "@/plugins/laravel-echo";
import * as authMutationTypes from "@/store/mutation-types/auth";
import axios from "axios";
import TeacherModel from '@/store/models/TeacherModel'

export default {
  mixins: [user],
  computed: {
    activeRoom() {
      return ChatRoom.query().find(this.$route.query.r);
    },
  },
  methods: {
    ...mapActions("messenger", ["selectActivePartner"]),
    listenForOnlineStatus() {
      if (this.isElter) return;

      laravelEcho
        .join(`school-channel.${this.currentUser.schoolId}`)
        .here((users) => users.map((user) => this.$store.dispatch("messenger/isOnline", user.userId)))
        .joining((user) => this.$store.dispatch("messenger/isOnline", user.userId))
        .leaving((user) => this.$store.dispatch("messenger/isOffline", user.userId));
    },
    listenForUserMessengerEvents() {
      if (this.isElter) return;

      laravelEcho
        .private(`user.${this.currentUser.userId}`)
        .listen("ChatRoomCreated", (data) => {
          const broadcastRoom = data.room || {};
          const existingChatRoomQuery = ChatRoom.query();

          if (broadcastRoom.schoolId) {
            existingChatRoomQuery.where("schoolId", broadcastRoom.schoolId);
          } else if (broadcastRoom.participantsIds) {
            existingChatRoomQuery.where(
              "conversationPartner",
              (conversationPartner) => {
                return broadcastRoom.participantsIds.includes(conversationPartner?.userId);
              }
            );
          }

          const existingChatRoom = existingChatRoomQuery.first();

          if (existingChatRoom !== null) {
            ChatRoom.delete(existingChatRoom.id);
            if (existingChatRoom.id === this.$route.query.r)
              this.setRoomInRouteQuery(broadcastRoom);
          }

          const existingChatParticipantsIds = ChatParticipant.all().map(participant => participant.userId);
          const loadParticipantsIds = broadcastRoom.participantsIds.filter(participantId =>
            existingChatParticipantsIds.includes(participantId) !== -1
          );

          if (loadParticipantsIds.length > 0) {
            const loadParticipantsRequests = loadParticipantsIds.map(
              (loadParticipantsId) => messengerService.loadParticipant(loadParticipantsId)
            )

            axios.all(loadParticipantsRequests).then((responses) => {
              const participants = responses.map(response => response.data || {})

              ChatParticipant
                .insert({ data: participants || [] })
                .then(() => {
                  ChatRoom
                    .insert({ data: broadcastRoom })
                    .then(() => this.storeNewMessage(broadcastRoom.lastMessage || {}))
                });
            });
          } else {
            ChatRoom
              .insert({ data: broadcastRoom })
              .then(() => this.storeNewMessage(broadcastRoom.lastMessage || {}));
          }
        })
        .listen("ChatMessageCreated", async (data) => {
          this.storeNewMessage(data.message || {});
        })
      ;
    },
    async storeNewMessage(message) {
      await ChatMessage.insert({ data: message }).then(() => {
        const isMessageForActiveRoom = this.activeRoom && this.activeRoom.id !== message.roomId;

        if (!this.activeRoom || isMessageForActiveRoom) {
          this.messageNotification(message);
        }

        const lastMessage = ChatMessage.query()
          .orderBy("createdAt", "desc")
          .first();

        ChatRoom.update({ where: lastMessage.roomId, data: { lastMessageId: lastMessage.id } });
      })
    },
    async messageNotification(message) {
      let participant = ChatParticipant.find(message.userId);

      if (participant === null) {
        const participantResponse = await messengerService.loadParticipant(message.userId);
        await ChatParticipant.insert({ data: participantResponse.data || [] });
        participant = ChatParticipant.find(message.userId);
      }

      await this.$snackbar.show({
        timeout: 6000,
        icon: "mdi-message-reply-text-outline",
        title: i18n.t("messages.new_message_from", { name: participant.fullName, }),
        message: message.message,
        type: SnackbarModel.TOAST,
        position: { top: true, right: true },
      });

      this.$store.commit(
        `auth/${authMutationTypes.SET_UNSEEN_MESSAGES_COUNT}`,
        this.currentUser.unSeenMessagesCount + 1
      );

      const room = ChatRoom.find(message.roomId);
      if (room) {
        await ChatRoom.update({
          where: room.id,
          data: { unSeenMessagesCount: room.unSeenMessagesCount + 1 },
        });
      }
    },
    findRoom(participant) {
      const chatRoomQuery = ChatRoom.query();

      if (participant.schoolId) {
        chatRoomQuery.where("schoolId", participant.schoolId);
      }

      if (participant.userId) {
        chatRoomQuery.where("participantsIds", (participantsIds) =>
          participantsIds.includes(participant.userId)
        );
      }

      return chatRoomQuery.first();
    },
    openDialogRoom(participant) {
      const room = this.findRoom(participant);

      if (room !== null) {
        this.setRoomInRouteQuery(room);
        return;
      }

      messengerService
        .loadRooms({ participantsIds: [participant.userId] })
        .then(async (response) => {
          const lastMessages = [];
          const rooms = response.data || [];

          rooms.forEach((room) => lastMessages.push(room.lastMessage));

          await ChatRoom.insert({ data: rooms });
          await ChatMessage.insert({ data: lastMessages.length ? lastMessages : [] });

          const room = this.findRoom(participant);

          if (room !== null) {
            this.setRoomInRouteQuery(room);
            return;
          }

          this.createNewRoom(participant);
        })
        .catch(error => {
          const responseData = error.response.data || {};

          if (responseData.message) {
            this.$snackbar.error(responseData.message);
          }
        });
    },
    createNewRoom(participant) {
      const data = {};
      
      if (participant.schoolId) {
        data.schoolId = participant.schoolId;
      }

      if (participant.userId) {
        data.title = participant.fullName;
        data.participantsIds = [participant.userId];
      }

      ChatRoom
        .insert({ data })
        .then((resp) => {
          const room = resp.chatRooms[0];

          if (participant.userId) {
            const chatParticipant = ChatParticipant.find(participant.userId);

            if (!chatParticipant) {
              ChatParticipant.insert({ data: participant });
            }
          }

          this.setRoomInRouteQuery(room);
        });
    },
    setRoomInRouteQuery(room) {
      if (this.$route.query.r === room.id) return true;
      this.$router
        .push({ name: "messenger", query: { r: room.id } })
        .catch((error) => console.log(error));
    },
    removeRoomFromRouteQuery() {
      const query = Object.assign({}, this.$route.query);
      const room = ChatRoom.query().find(query.r);

      if (room.createdAt === null && !this.isStudent) {
        ChatRoom.delete(room.id);
      }

      delete query.r;
      this.$router.replace({ query }).catch((error) => console.log(error));
    },
    initRoomsForStudentsTeachers() {
      if (!(this.isStudent || this.isElterWithSelectedStudent)) return;

      const teachers = TeacherModel.query()
        .whereIdIn(this.currentUser.teachersIds)
        .where(teacher => {
          return !ChatRoom
            .query()
            .where(chatRoom => chatRoom.participantsIds.includes(teacher.userId))
            .exists();
        })
        .get();

      const data = teachers.map((teacher) => ({
        conversationPartner: {
          userId: teacher.userId,
          type: "teacher",
          firstName: teacher.firstName,
          lastName: teacher.lastName,
          avatar: teacher.avatar
        },
        participantsIds: [teacher.userId, this.currentUser.id]
      }));

      ChatRoom.insert({ data });
    }
  },
};
