<template>
  <div class="list__navbar"><slot /></div>
</template>

<script>
export default {
  name: "ListNavBar",
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
.list__navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
</style>
