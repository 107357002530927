<script>
import ReusableDialog from "@/components/ReusableDialog.vue";
import geoapifyService from "@/services/geoapifyService";

export default {
  name: "AddressLookupDialog",
  components: {ReusableDialog},
  data() {
    return {
      loading: false,
      list: null,
      query: null,
    }
  },
  methods: {
    autocomplete() {
      if (! this.query) return;

      this.loading = true
      geoapifyService
          .autocomplete(this.query)
          .then(resp => {
            this.list = resp.data
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            this.loading = false
          })
    },
    iconByType(type) {
      const icons = {
        unknown: 'mdi-map-marker-question',
        amenity: 'mdi-domain',
        building: 'mdi-home-modern',
        street: 'mdi-road-variant',
        suburb: 'mdi-city',
        district: 'mdi-city-variant-outline',
        postcode: 'mdi-map-marker-radius',
        city: 'mdi-city-variant',
        county: 'mdi-map-marker',
        state: 'mdi-map',
        country: 'mdi-earth'
      }

      if (! Object.keys(icons).includes(type)) return icons.unknown

      return icons[type];
    },
    selectAddress(address) {
      this.$emit('address-selected', address)
    }
  }
}
</script>

<template>
  <reusable-dialog :width="600" scrollable>
    <template v-slot:trigger="{open}">
      <v-btn @click="open" text small>
        <v-icon left>mdi-map-search-outline</v-icon>
        {{ $t('btn.lookup_address') }}
      </v-btn>
    </template>

    <template v-slot:title>
      {{ $t('label.find_an_address') }}
    </template>

    <v-text-field
      v-model="query"
      hide-details
      outlined
      dense
      prepend-inner-icon="mdi-map-search-outline"
      @keyup.enter="autocomplete"
      :placeholder="$t('form.address_lookup_placeholder')"
    />

    <empty-box v-if="! loading && query && list !== null && ! list.length" />

    <div v-if="loading" class="d-flex align-center justify-center my-15">
      <v-progress-circular indeterminate />
    </div>

    <v-list dense v-if="list?.length && !loading">
      <v-list-item dense two-line v-for="address in list" :key="address.plus_code" @click="selectAddress(address)">
        <v-list-item-avatar>
          <v-icon>{{ iconByType(address.type) }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ address.address_line1 }}</v-list-item-title>
          <v-list-item-subtitle>{{ address.address_line2 }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:actions-start="{close}">
      <v-btn @click="close" text>{{$t('btn.close')}}</v-btn>
    </template>
  </reusable-dialog>
</template>

<style scoped>

</style>