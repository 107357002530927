<template>
  <v-dialog
    :value="show"
    max-width="800"
    @click:outside="close"
    @keydown.esc="close"
    scrollable>
    <v-card>
      <v-toolbar class="mb-2" dark color="primary" dense>
        <v-toolbar-title>{{
          $t("messages.are_your_sure_delete")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-list two-line>
              <template v-for="(user, index) in users">
                <v-divider
                  :key="user.id + '_divider'"
                  inset
                  v-if="index !== 0" />

                <v-list-item :key="user.id" dense>
                  <v-list-item-avatar class="my-0">
                    <v-img :src="user.avatar" />
                  </v-list-item-avatar>

                  <v-list-item-content class="pa-0">
                    <v-list-item-title>{{ user.fullName }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text @click="close">{{ $t("btn.cancel") }}</v-btn>
        <v-btn
          color="primary"
          text
          :disabled="loading"
          :loading="loading"
          @click="$emit('deleteUsers')"
          >{{ $t("btn.ok") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteMultipleUsersDialog",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    show: false,
    users: [],
  }),
  methods: {
    open(users) {
      this.show = true;
      this.users = users || [];
    },
    close() {
      this.show = false;
      this.users = [];
    },
  },
};
</script>

<style scoped></style>
