import router from "@/router";

export default class ShowSupportTicket {
  constructor(ticketId) {
    this.ticket = ticketId;
  }

  run() {
    router.push({ name: "support.ticket.view", query: { ticket: this.ticket } });
  }
}
