<script>
import {mapGetters} from "vuex";
import ActiveFilters from "@/components/students-directory/ActiveFilters.vue";
import BulkStudentsActions from "@/components/students-directory/BulkStudentsActions.vue";
import ExamList from "@/components/students-directory/ExamList.vue";

export default {
  name: "HomeTab",
  components: {ExamList, BulkStudentsActions, ActiveFilters},
  computed: {
    ...mapGetters('studentsDirectory', ['isTreeSelectable', 'selectedTreeItems', 'filters', 'appliedFilters'])
  },
}
</script>

<template>
  <div style="padding: 10px;">
    <active-filters class="mb-3" />

    <bulk-students-actions v-if="isTreeSelectable" class="mb-3" />

    <exam-list class="mb-3" />
  </div>
</template>

<style scoped>

</style>
