<template>
  <v-app>
    <Snackbars />

    <component :is="layout" :key="refreshKey">
      <RouterTransition>
        <router-view />
      </RouterTransition>
    </component>

    <RestrictedAccessDialog/>
    <SessionExpiredDialog />
    <SplashScreen v-if="showSplashScreen && !isLoginAsRoute && ! getSessionExpired" />
    <OTAUDialog />
<!--    <NoInternetConnection />-->
  </v-app>
</template>

<script>
import ProgressCentered from "@/components/ProgressCentered";
import SessionExpiredDialog from "@/components/SessionExpiredDialog";
import RestrictedAccessDialog from "@/components/RestrictedAccessDialog.vue";
import Snackbars from "@/components/Snackbars";
import responsive from "@/utils/mixins/responsive";

import { mapGetters } from "vuex";
import SplashScreen from "@/components/SplashScreen";
import RouterTransition from "@/components/RouterTransition";
import { EventBus } from "@/EventBus";
import OTAUDialog from "@/components/OTAUDialog.vue";
// import NoInternetConnection from './components/NoInternetConnection.vue'

export default {
  name: "App",
  mixins: [responsive],
  components: {
    OTAUDialog,
    // NoInternetConnection,
    RouterTransition,
    SplashScreen,
    Snackbars,
    ProgressCentered,
    SessionExpiredDialog,
    RestrictedAccessDialog
  },
  data: () => ({
    refreshKey: 0
  }),
  computed: {
    ...mapGetters("main", ["showSplashScreen"]),
    ...mapGetters("auth", ["getSessionExpired"]),
    layout() {
      let layout = this.$route.meta.layout;

      if (!layout) {
        const layoutItem = this.$route.matched.find(
          (item) => item.meta && item.meta.layout
        );
        layout = layoutItem ? layoutItem.meta.layout : null;
      }

      return layout || "layouts-default";
    },
    isLoginAsRoute() {
      return this.$route.name === "login-as";
    }
  },
  created() {
    EventBus.$on("refresh-app", this.refreshApp);
  },
  destroyed() {
    EventBus.$off("refresh-app", this.refreshApp);
  },
  methods: {
    refreshApp() {
      this.refreshKey = Math.random();
    }
  }
};
</script>
