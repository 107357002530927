<template>
  <v-dialog width="500" :value="show" persistent>
    <v-card>
      <v-card-title primary-title>
        {{ $t("route.qrCode") }}
      </v-card-title>

      <v-card-text id="card">
        <div id="qrCode" class="pa-10">
          <qrcode-vue
              class="qr-code d-flex align-center justify-center"
              size="200"
              margin="100"
              render-as="svg"
              :value="studentId"
              level="H">
          </qrcode-vue>
        </div>
      </v-card-text>

      <v-divider/>

      <v-card-actions class="d-flex justify-end">
        <v-btn text @click="close">{{ $t("btn.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import user from "@/utils/mixins/user";

export default {
  name: "QRCodeDialog",
  mixins: [user],
  components: {
    QrcodeVue,
  },
  data: () => ({
    studentId: "",
    show: false
  }),
  mounted() {
    this.studentId = this.currentUser.id.toString();
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    }
  }
}
</script>

<style scoped lang="scss">
#card {
  max-width: 400px;
  margin: 20px auto;
  text-align: center;

  #qrCode {
    .qr-code {
      border-radius: 20px;
      aspect-ratio: 1 / 1;
    }
  }
}
</style>