<template>
  <v-navigation-drawer
    v-model="show"
    fixed
    temporary
    right
    touchless
    width="280"
    class="px-2"
    v-click-outside="onClickOutside"
  >
    <v-list dense>
      <v-subheader class="d-flex justify-space-between">
        <span>{{ $t("label.actions") }}</span>
      </v-subheader>

      <v-list-item @click="openMessengerRoom(teacher)">
        <v-list-item-icon><v-icon>mdi-chat-outline</v-icon></v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{$t("chat.write_a_message") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="editItem(teacher)">
        <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("btn.edit") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="isSchoolAdministrator" @click="deleteItem(teacher)">
        <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("btn.delete") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list dense>
      <v-subheader class="d-flex justify-space-between">
        <span>{{ $t("label.documents") }}</span>
      </v-subheader>

      <v-list-item link @click="$refs.dailyEvents.open()">
        <v-list-item-icon>
          <v-icon>mdi-file-document</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t("label.daily_work_report") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <TeacherFormDialog ref="formItem" />
    <TeacherDailyEvents ref="dailyEvents" :teacher="teacher" />
  </v-navigation-drawer>
</template>

<script>
import user from "@/utils/mixins/user";
import messenger from "@/utils/mixins/messenger";
import TeacherFormDialog from "@/components/teacher/TeacherFormDialog";
import TeacherModel from '@/store/models/TeacherModel'
import TeacherDailyEvents from '@/components/teacher/TeacherDailyEvents.vue'

import teachersService from '@/services/teachersService'
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: "TeacherSidebar",
  props: {
    teacherId: {
      type: [Number, String],
      required: true,
    },
  },
  mixins: [user, messenger],
  components: { TeacherDailyEvents, TeacherFormDialog, },
  data: () => ({
    show: false,
    vaccinationDocument: {},
  }),
  computed: {
    teacher() {
      return TeacherModel.find(this.teacherId) || {};
    },
    vaccinationApproved() {
      return this.teacher.vaccinationStatus === "APPROVED";
    },
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    openMessengerRoom(item) {
      this.$router.push({ name: "messenger" }).then(() => {
        this.openDialogRoom(item);
      });
    },
    editItem(item) {
      this.$refs.formItem.open(item);
    },
    deleteItem(item) {
      this.loadingAction = true;
      showConfirm(
        this.$t('btn.confirm'),
        this.$t('messages.are_your_sure_delete'),
        () => {
          teachersService
            .destroy(item.id)
            .then(() => {
              this.$router.push({ name: "teachers" });
            })
            .catch((error) => console.log(error))
            .finally(() => {
              this.loadingAction = false;
            });
        },
        () => {
          this.loadingAction = false;
        }
      )
    },

    onClickOutside(event) {
      if (event.target && event.target.classList.contains("v-overlay__scrim")) {
        this.close();
      }
    },
  },
};
</script>

<style scoped></style>
