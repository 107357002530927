<template>
<ShowsAs
  :type="$vuetify.breakpoint.mobile ? 'dialog' : 'menu'"
  :options="{
    offsetX: true,
    nudgeWidth: 300
  }"
>
  <template v-slot:activator="{ on, attrs }">
    <v-icon size="18" class="mr-2" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
  </template>

  <template v-slot:default="{ close }">
    <v-card>
      <v-card-title v-if="$vuetify.breakpoint.mobile"/>

      <template v-if="lessonDuration && totalDuration">
        <v-card-subtitle>
          1 {{ $tc('label.lessons', 1) }} = {{ $tc('label.duration_in_minutes', lessonDuration, { min: lessonDuration }) }}
          <br>
          {{ $tc('label.total') }} - {{ $tc('label.duration_in_minutes', totalDuration, { min: totalDuration }) }}
        </v-card-subtitle>

        <v-divider/>
      </template>

      <v-card-text>
        <table style="width: 100%; font-size: 0.8rem">
          <tr v-for="app in apps" :key="app.id">
            <td>
              <div v-if="isSimulator" style="font-size: 0.8rem">
                {{ app.simulatorName }}
              </div>
              <div v-else style="font-size: 0.8rem">
                {{ app.teacherName }}
              </div>

            </td>
            <td style="text-align: right">
              {{ app.start }}<template v-if="showLessonMinutes">, {{ $t('label.duration_in_minutes_shorthand', {min : app.duration}) }}</template>
            </td>
          </tr>
        </table>
      </v-card-text>

      <template v-if="$vuetify.breakpoint.mobile">
        <v-divider class="mt-auto" />
        <v-card-actions>
          <v-btn text @click="close">{{$t("btn.close")}}</v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </template>
</ShowsAs>
</template>

<script>
import ShowsAs from '@/components/ShowsAs'
export default {
  name: "EducationPracticePopup",
  components: { ShowsAs },
  props: {
    apps: {
      type: Array,
    },
    isSimulator: {
      type: Boolean,
      default: false
    },
    lessonDuration: {
      value: Number,
      default: null
    },
    totalDuration: {
      value: Number,
      default: null
    },
    showLessonMinutes: {
      type: Boolean,
      default: true
    },
  }
};
</script>
