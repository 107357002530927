<template>
  <div class="vehicle">
    <VehicleAvatar class="mr-2" :path="vehicle.photo" left :contain="!vehicle.photo" :color="vehicle.color"/>

    <div v-if="!isCollapsed" class="ellipsis">
      <div class="vehicle-name">
        {{ vehicle.make }} {{ vehicle.model }}
      </div>
      <div class="caption">
        {{ vehicle.licensePlate }}
      </div>
    </div>
  </div>
</template>

<script>
import VehicleAvatar from '@/components/VehicleAvatar.vue'

export default {
  name: "VehiclesList",
  components: { VehicleAvatar },
  props: {
    vehicle: {
      type: Object,
    },
    isCollapsed: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="scss" scoped>
.vehicle{
  height: 60px;
  padding: 5px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;

  .vehicle-name{
    font-size: 14px;
    font-weight: 900;
    color: #999;
  }
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
</style>
