<template>
  <div>
    <v-row class="mt-1">
      <v-col cols="12" md="1">
        <v-text-field
          v-model="filters.id"
          clearable
          dense
          label="ID"
          placeholder="ID"
          solo
        />
      </v-col>

      <v-col cols="12" md="3">
        <v-select
          v-model="filters.teachers"
          :items="teachersAsOptions"
          :label="$t('label.filter_by_teacher')"
          :placeholder="$t('label.filter_by_teacher')"
          chips
          deletable-chips
          dense
          multiple
          small-chips
          clearable
          solo
        />
      </v-col>

      <v-col cols="12" lg="3">
        <v-select
          v-model="filters.licenses"
          :items="licensesAsOptions"
          :label="$t('filters.licenses')"
          :placeholder="$t('filters.licenses')"
          clearable
          dense
          hide-details
          multiple
          offset-y
          solo
          type="string"
        />
      </v-col>

      <v-col>
        <v-btn :loading="loading" color="primary" text @click="filter">{{ $t("btn.filter_results") }}</v-btn>
      </v-col>

      <v-col>
        <Pagination
          v-if="pagination.total > 0"
          :current-page="pagination.currentPage"
          :from="pagination.from"
          :loading="loading"
          :to="pagination.to"
          :total-nr-of-items="pagination.total"
          :total-nr-of-pages="pagination.lastPage"
          @pageChange="onPageChange" />
      </v-col>
    </v-row>

    <v-skeleton-loader v-if="loading" class="mx-auto" type="table-row@3" />

    <template v-else>
      <v-simple-table v-if="items.length > 0" dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left" style="width: 60px">ID</th>
            <th style="width: 150px">{{ $t("list.name") }}</th>
            <th style="width: 150px">{{ $t("label.license_plate") }}</th>
            <th style="width: 150px">{{ $t("label.driven") }}</th>
            <th class="text-left" style="width: 180px">
              {{ $t("archived_at") }}
            </th>
            <th class="text-left"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in items" :key="item.id" class="table-row">
            <td>{{ item.id }}</td>
            <td>
              {{ item.make + " " + item.model }}
              <template v-if="item.year">, {{ item.year }}</template>
            </td>
            <td>{{ item.licensePlate }}</td>
            <td>{{ item.currentMileage }} Km</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ item.deletedAtForHumans }}</span>
                </template>
                {{ item.deletedAt | dateFormat }}
              </v-tooltip>
            </td>
            <td style="width: 68px">
              <v-chip
                :to="{ name: 'vehicles.vehicle', params: { id: item.id } }"
                class="hidden-until-row-hovered"
                label
                link
                x-small>
                <v-icon x-small>mdi-arrow-right</v-icon>
              </v-chip>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-alert v-else>
        <empty-box />
      </v-alert>
    </template>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import vehiclesService from "@/services/vehiclesService";
import TeacherModel from "@/store/models/TeacherModel";
import LicenseModel from "@/store/models/LicenseModel";

export default {
  name: "RecyclableEventsList",
  components: { Pagination },
  data: () => ({
    loading: true,
    items: [],
    filters: {},
    pagination: {
      currentPage: 1,
      from: 0,
      lastPage: 1,
      to: 0,
      total: 0
    }
  }),
  computed: {
    licensesAsOptions() {
      return LicenseModel
          .query()
          .where('forPractice', true)
          .get()
          .map((item) => ({ text: item.name, value: item.id.toString() }));
    },
    teachersAsOptions() {
      return TeacherModel.all().map((teacher) => ({ text: teacher.fullName, value: teacher.id }));
    },
    filledFilters() {
      const filledFilters = {};

      if (this.filters.id) filledFilters.id = this.filters.id;
      if (this.filters.teachers) filledFilters.teachers = this.filters.teachers;
      if (this.filters.licenses) filledFilters.licenses = this.filters.licenses;

      return filledFilters;
    }
  },
  mounted() {
    this.fetch({ page: this.$route.query.page || 1 });
  },
  methods: {
    fetch(params = {}) {
      this.loading = true;
      params.perPage = 50;
      return vehiclesService
        .getDeletedList(params)
        .then((response) => {
          const responseData = response.data || {};
          this.items = responseData.data || [];
          this.pagination = responseData.pagination || {};
        })
        .catch(error => {
          const response = error.response || {};
          const responseData = response.data || {};

          if (responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error" });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filter() {
      this.fetch(this.filledFilters)
        .then(() => {
          this.$router
            .replace({ query: { ...this.$route.query, page: 1 } })
            .catch(console.log);
        });
    },
    onPageChange() {
      this.fetch({ ...this.filledFilters, page: this.$route.query.page || 1 });
    }
  }
};
</script>

<style scoped></style>
