import { Model } from "@vuex-orm/core";
import { differenceInDays, isWithinInterval, parseISO } from 'date-fns'

export default class VehicleModel extends Model {
  static entity = "vehicles";

  get label() {
    return `${this.make} ${this.model} ${this.licensePlate ? `(${this.licensePlate})` : ""}`;
  }

  get isInService() {
    if (! this.nextServiceAppointmentAt || ! this.nextServiceAppointmentTo) return false

    const start= parseISO(this.nextServiceAppointmentAt)
    const end = parseISO(this.nextServiceAppointmentTo)

    return isWithinInterval(new Date(), { start, end })
  }

  get numberOfDaysTillNow() {
    return this.nextServiceAppointmentAt ? differenceInDays(parseISO(this.nextServiceAppointmentAt), new Date()) : 0;
  }

  get isDeleted() {
    return this.deletedAt !== null;
  }

  static fields() {
    return {
      id: this.attr(null),
      name: this.string(""),
      days: this.attr([]),
      licensesIds: this.attr([]),
      teachersIds: this.attr([]),
      licensePlate: this.string(""),
      make: this.string(""),
      model: this.string(""),
      year: this.attr(null),
      photo: this.attr(null),
      color: this.string(""),
      priceModifier: this.string(""),
      deletedAt: this.attr(null),
      fuelType: this.attr(null),
      gearboxType: this.attr(null),
      vehicleId: this.attr(null),
      servicePeriod: this.attr(null),
      currentMileage: this.attr(null),
      nextServiceAppointmentAt: this.attr(null),
      nextServiceAppointmentTo: this.attr(null),
      nextServiceAppointmentIn: this.attr(null),
      createdAt: this.attr(null)
    };
  }
}
