<template>
  <v-expansion-panel class="mb-2">
    <v-expansion-panel-header>
      <div class="d-flex">
        <span
          class="title"
          :class="{
            'text--disabled text-decoration-line-through':
              sendMethodsTitleNames.length === 0,
          }">
          {{ $t("notifications.groups." + notificationGroup) }}
        </span>

        <div class="ml-2 my-auto">{{ sendMethodsTitleNames.join(", ") }}</div>
      </div>
    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <div class="d-flex flex-column ml-2 mb-4">
        <span class="text-caption">{{
          $t("label.notifications_in_group")
        }}</span>

        <div class="mt-2">
          <v-chip
            x-small
            label
            class="mr-1"
            v-for="notificationsType in notificationTypesByUser"
            :key="notificationsType">
            {{ $t(`notifications.types.${notificationsType}`) }}
          </v-chip>
        </div>
      </div>

      <span class="ml-2 text-caption">{{
        $t("label.where_will_receive_notifications")
      }}</span>

      <div class="px-2">
        <v-list dense>
          <template v-for="(sentMethod, index) in sendMethods">
            <NotificationMethod
              :settingsArea="settingsArea"
              :user-type="userType"
              :key="notificationGroup + sentMethod.key"
              :notification-group="notificationGroup"
              :settings-by-group="settingsByGroup"
              :send-method="sentMethod"
              :disabled="methodLoading"
              @loading="onMethodLoading" />

            <v-divider
              v-if="index !== sendMethods.length - 1"
              :key="sentMethod.key"
            />
          </template>
        </v-list>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import NotificationsSettings from "@/store/models/NotificationsSettings";
import NotificationMethod from "@/components/school/settings/NotificationMethod";
import i18n from "@/plugins/i18n";

export default {
  name: "NotificationGroup",
  components: { NotificationMethod },
  props: {
    settingsArea: {
      type: String,
      required: true,
    },
    userType: {
      type: String,
      default: "",
    },
    notificationGroup: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    methodLoading: false,
    sendMethods: [
      {
        icon: "mdi-bell-ring-outline",
        key: "app",
        values: ["broadcast", "database"],
      },
      { icon: "mdi-email-outline", key: "mail", values: ["mail"] },
      { icon: "mdi-cellphone-message", key: "push", values: ["push"] },
    ],
  }),
  computed: {
    settings() {
      return NotificationsSettings.all();
    },
    settingsByGroup() {
      const notificationsSettingsQuery = NotificationsSettings.query().where(
        "settingsArea",
        this.settingsArea
      );

      if (this.settingsArea === "school")
        notificationsSettingsQuery.where("userType", this.userType);

      return (
        notificationsSettingsQuery
          .where("notificationGroup", this.notificationGroup)
          .first() || {}
      );
    },
    sendMethodsTitleNames() {
      const settingsGroupSendMethods = this.settingsByGroup.sendMethod || [];

      return this.sendMethods
        .filter((item) => {
          return settingsGroupSendMethods.some((sendMethod) =>
            item.values.includes(sendMethod)
          );
        })
        .map((item) => i18n.t(`notifications.sendMethods.${item.key}`));
    },
    notificationTypesByUser() {
      const notificationByUserType =
        NotificationsSettings.notificationsByUserType[this.userType];

      return NotificationsSettings.notificationGroups[
        this.notificationGroup
      ].filter((notificationType) => {
        return notificationByUserType.includes(notificationType);
      });
    },
  },
  methods: {
    onMethodLoading(value) {
      this.methodLoading = value;
    },
  },
};
</script>

<style scoped></style>
