<template>
  <v-scroll-y-reverse-transition v-if="$vuetify.breakpoint.mobile" mode="out-in">
    <slot/>
  </v-scroll-y-reverse-transition>
  <v-scroll-x-transition v-else mode="out-in">
    <slot/>
  </v-scroll-x-transition>
</template>

<script>
export default {
  name: "RouterTransition"
}
</script>
