export default class Password {
  static generate(length) {
    const randomFunctions = {
      lower: Password.getRandomLower,
      upper: Password.getRandomUpper,
      number: Password.getRandomNumber,
      symbol: Password.getRandomSymbol,
    };

    let generatedPassword = "";

    for (let i = 0; i < length; i++) {
      Object.keys(randomFunctions).forEach((randomFunction) => {
        generatedPassword += randomFunctions[randomFunction]();
      });
    }

    return generatedPassword
      .slice(0, length)
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");
  }

  static getRandomLower() {
    return String.fromCharCode(Math.floor(Math.random() * 26) + 97);
  }

  static getRandomUpper() {
    return String.fromCharCode(Math.floor(Math.random() * 26) + 65);
  }

  static secureMathRandom() {
    return (
      window.crypto.getRandomValues(new Uint32Array(1))[0] /
      (Math.pow(2, 32) - 1)
    );
  }

  static getRandomNumber() {
    return String.fromCharCode(
      Math.floor(Password.secureMathRandom() * 10) + 48
    );
  }

  static getRandomSymbol() {
    const symbols = '~!@#$%^&*()_+{}":?><;.,';
    return symbols[Math.floor(Math.random() * symbols.length)];
  }
}
