import Vue from "vue";
import Vuex from "vuex";
import VuexORM from "@vuex-orm/core";
import auth from "./modules/auth";
import calendar from "@/store/modules/calendar";
import teachers from "@/store/modules/teachers";
import children from "@/store/modules/children";
import parents from "@/store/modules/parents";
import insights from "@/store/modules/insights";
import appointments from "@/store/modules/appointments";
import school from "@/store/modules/school";
import vehiclesCalendar from "@/store/modules/vehiclesCalendar";
import vehiclesReservation from "@/store/modules/vehiclesReservation";
import updateNotificationDialog from "@/store/modules/updateNotificationDialog";
import studentsDirectory from "@/store/modules/studentsDirectory";
import ChatRoom from "@/store/models/ChatRoom";
import ChatMessage from "@/store/models/ChatMessage";
import ChatParticipant from "@/store/models/ChatParticipant";
import TeacherModel from "@/store/models/TeacherModel";
import EventModel from "@/store/models/EventModel";
import AppointmentModel from "@/store/models/AppointmentModel";
import EventTypeModel from "@/store/models/EventTypeModel";
import StudentModel from "@/store/models/StudentModel";
import StudentHistoryModel from "@/store/models/StudentHistoryModel";
import StudentTeacherModel from "@/store/models/StudentTeacherModel";
import LicenseGroupModel from "@/store/models/LicenseGroupModel";
import LicenseModel from "@/store/models/LicenseModel";
import LessonTypeModel from "@/store/models/LessonTypeModel";
import MeetingPlaceModel from "@/store/models/MeetingPlaceModel";
import SchoolModel from "@/store/models/SchoolModel";
import OfficeModel from "@/store/models/OfficeModel";
import ProductCategoryModel from "@/store/models/ProductCategoryModel";
import ProductPriceModel from "@/store/models/ProductPriceModel";
import LearningMaterial from "@/store/models/LearningMaterialModel";
import NotificationModel from "@/store/models/NotificationModel";
import SnackbarModel from "@/store/models/SnackbarModel";
import messenger from "@/store/modules/messenger";
import main from "@/store/modules/main";
import TheoryTopicModel from "@/store/models/TheoryTopicModel";
import StudentDocuments from "@/store/models/StudentDocuments";
import NotificationsSettings from "@/store/models/NotificationsSettings";
import EducationPlanModel from "@/store/models/EducationPlanModel";
import LicenseStudentModel from "@/store/models/LicenseStudentModel";
import VehicleModel from "@/store/models/VehicleModel";
import PrintMediaModel from "@/store/models/PrintMediaModel";
import HappyHoursModel from "@/store/models/HappyHoursModel";
import PaymentModel from "@/store/models/PaymentModel";
import PaymentChargeModel from "@/store/models/PaymentChargeModel";
import PayoutModel from "@/store/models/PayoutModel";
import TransactionModel from "@/store/models/TransactionModel";
import AppointmentRequestModel from "@/store/models/AppointmentRequestModel";
import PriceCategoryLessonTypeStatusModel from "@/store/models/PriceCategoryLessonTypeStatusModel";
import LicenseRequirementModel from "@/store/models/LicenseRequirementModel";
import OneTimeProductModel from '@/store/models/OneTimeProductModel'
import InvoiceAddressesModel from '@/store/models/InvoiceAddressesModel'
import VehicleReservationModel from '@/store/models/VehicleReservationModel'
import VehicleServiceModel from '@/store/models/VehicleServiceModel'
import ChildModel from '@/store/models/ChildModel'
import ParentModel from '@/store/models/ParentModel'
import EvaluationModel from '@/store/models/insights/EvaluationModel'
import TemplateModel from '@/store/models/insights/TemplateModel'
import CategoryModel from '@/store/models/insights/CategoryModel'
import ItemModel from '@/store/models/insights/ItemModel'
import EvaluationItemModel from '@/store/models/insights/EvaluationItemModel'

Vue.use(Vuex);

const database = new VuexORM.Database();

database.register(ChatParticipant);
database.register(ChatRoom);
database.register(ChatMessage);
database.register(TeacherModel);
database.register(EventModel);
database.register(AppointmentModel);
database.register(EventTypeModel);
database.register(StudentModel);
database.register(StudentHistoryModel);
database.register(StudentTeacherModel);
database.register(LicenseGroupModel);
database.register(LicenseModel);
database.register(LessonTypeModel);
database.register(MeetingPlaceModel);
database.register(SchoolModel);
database.register(NotificationsSettings);
database.register(OfficeModel);
database.register(ProductCategoryModel);
database.register(ProductPriceModel);
database.register(LearningMaterial);
database.register(SnackbarModel);
database.register(NotificationModel);
database.register(TheoryTopicModel);
database.register(StudentDocuments);
database.register(EducationPlanModel);
database.register(LicenseStudentModel);
database.register(VehicleModel);
database.register(PrintMediaModel);
database.register(PaymentModel);
database.register(PaymentChargeModel);
database.register(PayoutModel);
database.register(TransactionModel);
database.register(HappyHoursModel);
database.register(AppointmentRequestModel);
database.register(PriceCategoryLessonTypeStatusModel);
database.register(LicenseRequirementModel);
database.register(OneTimeProductModel);
database.register(InvoiceAddressesModel);
database.register(VehicleReservationModel);
database.register(VehicleServiceModel);
database.register(ChildModel);
database.register(ParentModel);
database.register(EvaluationModel)
database.register(TemplateModel)
database.register(CategoryModel)
database.register(ItemModel)
database.register(EvaluationItemModel)

const store = {
  plugins: [VuexORM.install(database)],
  modules: {
    main,
    auth,
    school,
    calendar,
    teachers,
    children,
    parents,
    insights,
    appointments,
    messenger,
    vehiclesCalendar,
    vehiclesReservation,
    updateNotificationDialog,
    studentsDirectory
  },
};

const resetHandler = (module) => {
  if (module.modules) {
    Object.keys(module.modules).forEach((modName) => {
      resetHandler(module.modules[modName]);
    });
  }

  if (!module.state) return;
  if (typeof module.state !== "function") {
    console.error("Vuex module state is not a function");
  }

  if (!module.mutations) module.mutations = {};
  if (!module.actions) module.actions = {};

  if (!module.mutations.reset) {
    module.mutations.reset = function (state) {
      Object.assign(state, module.state());
    };
  }

  if (!module.actions.reset) {
    module.actions.reset = {
      root: true,
      handler({ commit }) {
        commit("reset");
      },
    };
  }
};
resetHandler(store);

export default new Vuex.Store(store);
