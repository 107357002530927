import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import qs from "qs";
import SchoolDataLoader from '@/helpers/schoolDataLoader'
import routes from "@/router/routes"
import i18n from "@/plugins/i18n";

import { App } from '@capacitor/app'
import { Browser } from '@capacitor/browser'

import LogRocket from "logrocket";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  parseQuery: (query) => qs.parse(query),
  stringifyQuery(query) {
    const result = qs.stringify(query, { encode: false });
    return result ? "?" + result : "";
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = getDocumentTitle(to);

  if (to.name === "login-as") return next();

  await loadCurrentUser();
  await loadParentSelectedStudent();
  await loadSchool();

  store.commit("main/toggle-notification-menu", false);

  next();
});

async function loadCurrentUser() {
  if (store.state.auth.authUser) return;
  await store.dispatch("auth/getAuthUser");

  const user = store.state.auth.authUser;
  // Check if the user has logging enabled:
  const { isLoggingEnabled } = user || {};

  if (isLoggingEnabled) {
    LogRocket.init('z7eodt/drivolino-planer', {
      network: {
        requestSanitizer: request => {
          if (request.headers['Authorization']) {
            request.headers['Authorization'] = 'Bearer ***SANITIZED***';
          }
          if (request.headers['X-Socket-Id']) {
            request.headers['X-Socket-Id'] = null;
          }

          return request;
        },
      },
    });
    LogRocket.identify(user.userId, {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      type: user.type,
      city: user.city,
    })
  }
}

async function loadParentSelectedStudent() {
  const authState = store.state.auth;
  const isElter = authState.authUser?.type === "elter";

  if (isElter && authState.parentSelectedStudentId && !authState.parentSelectedStudent) {
    await store.dispatch("auth/getParentSelectedStudent");
  }
}

async function loadSchool() {
  if (!store.state.school.loaded) {
    await store.dispatch("school/load");
  }
  if (!store.state.school.dataLoaded) {
    await SchoolDataLoader.load({ method: "create", only: ["t", "lt", "et", "tt", "l", "lg"] });
  }
}

function getDocumentTitle(to) {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((route) => route.meta && route.meta.pageName);

  return nearestWithTitle
    ? `${i18n.t(nearestWithTitle.meta.pageName)} - ${process.env.VUE_APP_NAME}`
    : process.env.VUE_APP_NAME;
}

App.addListener("appUrlOpen", function (data) {
  const appEnv = localStorage.getItem('appenv')
  const appUrl = appEnv === 'dev' ? 'planer-dev.drivolino.de' : 'planer.drivolino.de'
  const slug = data.url.includes(appUrl)
    ? data.url.split(appUrl).pop()
    : data.url.split("://").pop();

  // We only push to the route if there is a slug present
  if (slug) {
    Browser.close();

    router.push({ path: slug }).catch(() => {});
  }
});

export default router;
