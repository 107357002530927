<template>
<v-card :loading="loading" :disabled="disabled">
  <v-img height="100px" :src="image" eager />
  <v-card-title>
    {{ $t("route.vehicleService") }}
    <v-spacer/>
    <v-tooltip v-if="!vehicle.servicePeriod" left>
      <template v-slot:activator="{ on, attrs }">
        <v-icon color="warning" v-bind="attrs" v-on="on">mdi-alert-outline</v-icon>
      </template>
      {{ $t("messages.no_vehicle_service_period_setup") }}
    </v-tooltip>
  </v-card-title>

  <v-card-subtitle class="student-card">
    {{ $t('label.vehicle_card_mileage_description') }}
  </v-card-subtitle>

  <v-card-text v-if="!disabled">
    <v-skeleton-loader v-if="loading" type="list-item-two-line" />
    <v-list v-else>
        <v-list-item class="pa-0 mb-2">
            <v-list-item-content class="pa-0">
                <v-list-item-title>
                  {{ $t('label.next_service_appointment') }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <template v-if="nextServiceAppointmentAt">
                    <span v-if="isInService">{{ $t('label.in_service') }}</span>

                    <template v-else>
                      {{ nextServiceAppointmentAt | dateFormat("dd.MM.yyyy") }}
                      <span v-if="isToday(parseISO(nextServiceAppointmentAt))">({{ $t('today') }})</span>
                      <span v-else-if="isTomorrow(parseISO(nextServiceAppointmentAt))">({{ $t('tomorrow') }})</span>
                      <span v-else>
                      ({{ $t("label.in") + " " + nrOfDaysRemainUntilNextServiceAppointment + " " + $tc("times.day", nrOfDaysRemainUntilNextServiceAppointment) }})
                    </span>
                    </template>
                  </template>

                  <span v-else :class="{ 'error--text' : remainingKilometersUntilService < 0 }">
                    <template v-if="remainingKilometersUntilService >= 0">{{ $t("label.in") }}</template>
                    {{ Math.abs(remainingKilometersUntilService) }} Km
                    <template v-if="remainingKilometersUntilService < 0">{{ $t("ago") }}</template>
                  </span>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-list>
  </v-card-text>

  <v-card-actions>
    <v-btn
      small
      text
      class="ml-auto"
      :to="{ name: `vehicles.vehicle.service` }"
    >
        {{ $t("btn.open") }}
    </v-btn>
  </v-card-actions>
</v-card>
</template>

<script>
import cardsService from "@/services/cardsService";
import { differenceInDays, isToday, isTomorrow, isWithinInterval, parseISO } from 'date-fns'
import VehicleModel from "@/store/models/VehicleModel";
import { EventBus } from '@/EventBus'

export default {
  name: "VehicleServiceCard",
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      image: require("@/assets/img/service.jpg"),
      nextServiceAppointmentAt: null,
      nextServiceAppointmentTo: null,
      remainingKilometersUntilService: 0
    };
  },
  computed: {
    vehicle() {
      return VehicleModel.find(this.$route.params.id) || {};
    },
    nrOfDaysRemainUntilNextServiceAppointment() {
      return this.nextServiceAppointmentAt ? differenceInDays(parseISO(this.nextServiceAppointmentAt), new Date()) : 0
    },
    isInService() {
      if (! this.nextServiceAppointmentAt || ! this.nextServiceAppointmentTo) return false

      const start= parseISO(this.nextServiceAppointmentAt)
      const end = parseISO(this.nextServiceAppointmentTo)

      return isWithinInterval(new Date(), { start, end })
    }
  },
  created() {
    EventBus.$on("mileage-created", this.init);
    EventBus.$on("service-appointment-created", this.init);
    EventBus.$on("service-appointment-updated", this.init);
    EventBus.$on("service-appointment-deleted", this.init);
  },
  destroyed() {
    EventBus.$off("mileage-created", this.init);
    EventBus.$off("service-appointment-created", this.init);
    EventBus.$off("service-appointment-updated", this.init);
    EventBus.$off("service-appointment-deleted", this.init);
  },
  mounted() {
    if (!this.disabled) this.init();
  },
  methods: {
    parseISO,
    isTomorrow,
    isToday,
    init() {
      this.loading = true;
      cardsService.vehicleServices(this.$route.params.id)
        .then(response => {
          const responseData = response.data || []

          this.nextServiceAppointmentAt = responseData.nextServiceAppointmentAt || null;
          this.nextServiceAppointmentTo = responseData.nextServiceAppointmentTo || null;
          this.remainingKilometersUntilService = responseData.remainingKilometersUntilService || 0;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style scoped>

</style>
