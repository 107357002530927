import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import api from "./utils/api";
import store from "./store";
import LayoutsMain from "./components/layouts/Main";
import LayoutsDefault from "./components/layouts/Default";
import VueCurrencyFilter from "vue-currency-filter";
import i18n from "./plugins/i18n";
import checkView from "vue-check-view";
import VueCurrencyInput from "vue-currency-input";
import Notifier from "./plugins/notifier";
import VueScroll from "vuescroll";
import VueSocialauth from "vue-social-auth";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueSignature from "vue-signature-pad";
import linkify from "vue-linkify";
import SlideUpDown from 'vue-slide-up-down'

import EmptyBox from "@/components/EmptyBox";
import "./plugins/validation";

import "./utils/filters/date";
import "./utils/filters/string";
import "./utils/filters/ellipsis";
import "./scss/main.scss";

import LocalizationManager from "@/helpers/LocalizationManager";

import axios from "axios";
import laravelEcho from "@/plugins/laravel-echo";

import { Device } from "@capacitor/device";
import { App as app } from "@capacitor/app";
import VueEcho from "vue-echo-laravel";
import { showConfirm } from "@/helpers/dialogs";
import { AppUpdate } from "@capawesome/capacitor-app-update";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { EventBus } from '@/EventBus'

const firebaseConfig = {
  apiKey: "AIzaSyCmXQR6466oXTqoaXf2t0aOANHF50dUGXo",
  authDomain: "bookandpay-push-notifications.firebaseapp.com",
  projectId: "bookandpay-push-notifications",
  storageBucket: "bookandpay-push-notifications.appspot.com",
  messagingSenderId: "458644302013",
  appId: "1:458644302013:web:febdfe7030e3b97c906fd4",
  measurementId: "G-DDVRD8S5Q8",
};

const firebaseApp = initializeApp(firebaseConfig);
getAnalytics(firebaseApp);

Vue.config.productionTip = false;
Vue.prototype.$http = api;

Vue.directive("linkified", linkify);

Vue.component("layouts-main", LayoutsMain);
Vue.component("layouts-default", LayoutsDefault);
Vue.component("empty-box", EmptyBox);
Vue.component('slide-up-down', SlideUpDown)


Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
  loaderOptions: { autoHideBadge: true },
})
Vue.use(VueSignature);
Vue.use(VueCurrencyFilter, {
  symbol: "€",
  thousandsSeparator: ".",
  fractionCount: 2,
  fractionSeparator: ",",
  symbolPosition: "rear",
  symbolSpacing: true,
});
Vue.use(checkView);
Vue.use(VueCurrencyInput, {
  globalOptions: { currency: "EUR", locale: "de" },
});
Vue.use(Notifier);
Vue.use(VueScroll, {
  ops: {
    bar: {
      background: "#90908f",
    },
  },
  name: "VScroll",
});
Vue.use(VueSocialauth, {
  providers: {
    google: {
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      redirectUri: process.env.VUE_APP_GOOGLE_REDIRECT_URL,
    },
    facebook: {
      clientId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
      redirectUri: process.env.VUE_APP_FACEBOOK_REDIRECT_URL,
    },
  },
});

const initApp = (api) => {
  api
    .get("locales")
    .then((resp) => {
      const localization = resp.data;
      const locales = Object.keys(localization);

      locales.forEach((locale) => {
        i18n.setLocaleMessage(locale, localization[locale] || {});
      });
    })
    .finally(() => {
      LocalizationManager.init();
      Vue.use(VueEcho, laravelEcho);

      new Vue({
        router,
        vuetify,
        store,
        i18n,
        methods: {
          refresh() {
            laravelEcho.leaveAllChannels()
            EventBus.$emit("refresh-app")
          }
        },
        render: (h) => h(App),
      }).$mount("#app");
    });
};

Device.getInfo().then(async ({ platform }) => {
  if (platform !== "web") {
    const { version, id } = await app.getInfo();

    const API_PROD_URL = process.env.VUE_APP_API_BASE_URL;
    const API_DEV_URL = process.env.VUE_APP_API_BASE_URL_DEV;
    const UPDATER_URL = process.env.VUE_APP_UPDATER_BASE_URL;

    axios
      .post(`${UPDATER_URL}/apps/${id}/environment`, { appId: id, version, platform })
      .then(async (response) => {
        const { env, requiresUpdate, criticalUpdate } = response.data;
        localStorage.setItem("appenv", env);

        api.defaults.baseURL = `${
          env === "prod" ? API_PROD_URL : API_DEV_URL
        }/api/v1`;

        if (env === "prod" && requiresUpdate) {
          const updateTitle = criticalUpdate
            ? "Update erforderlich"
            : "Update verfügbar";
          const updateMessage = criticalUpdate
            ? "Eine weitere Nutzung der App ist bis zur Installation des erforderlichen Updates nicht möglich."
            : "Ein neues Update ist verfügbar und es wird empfohlen, es zu installieren.";

          const openAppStore = await showConfirm(
            updateTitle,
            updateMessage,
            null,
            "Update",
            "Später"
          );

          if (openAppStore) {
            await AppUpdate.openAppStore();
          }

          if (criticalUpdate) {
            if (platform === "android") {
              await app.exitApp();
            } else {
              location.href = "/update-required.html";
            }
          }
        }
      })
      .catch(err => {
        console.log(err);
        localStorage.setItem("appenv", "prod");
      })
      .finally(() => {
        initApp(api);
      });
  } else {
    localStorage.setItem("appenv", "prod");
    initApp(api);
  }
});
