<template>
<!--  v-touch.stop="{ move: onMove, start: onStart, end: onEnd }"-->
<v-navigation-drawer
  left
  dark
  touchless
  width="275"
  class="navigation-drawer"
  color="primary"
  v-model="show"
  :app="!$vuetify.breakpoint.mobile"
  :fixed="$vuetify.breakpoint.mobile"
  :permanent="!$vuetify.breakpoint.mobile"
  :mini-variant="isSidebarMinimized && !$vuetify.breakpoint.mobile"
  ref="navigationDrawer"
  @input="onInput"
>
  <div
    v-if="$vuetify.breakpoint.mobile"
    ref="navigationDrawerDragHook"
    class="transparent-overlay"
    @click="closeWhenClickOutsideOfSidebar"
  />

  <SidebarAppLogo />

  <div
    :class="{'menu-mobile': $vuetify.breakpoint.mobile}"
    class="menu"
  >
    <v-list class="py-0">
      <template v-for="item in menuItems">
        <SidebarListGroup
          v-if="item.children"
          :key="item.name"
          :disabled="item.groupDisabled"
          :icon-src="item.icon"
          :input-value="item.active"
          :name="item.name"
          :to="item.groupRoute"
          :value="item.active"
        >
          <template v-slot:default="{ type }">
            <SidebarListItem
              v-for="item in item.children"
              :key="item.name"
              :click="item.onClick"
              :disabled="item.disabled"
              :input-value="item.active"
              :listClass="{
                  'pl-14 pr-8': type === 'list-group',
                  'v-list-item--active': item.active
                }"
              :to="item.route"
              :value="item.active"
              dense
            >
              <template v-if="type === 'list-group'">
                <SidebarListItemContent :subtitle="item.subtitle" :title="item.name" />
                <SidebarListItemIcon :badge="item.badge" :icon-src="item.icon" icon-height="22" icon-width="22" />
              </template>
              <template v-else>
                <SidebarListItemIcon :badge="item.badge" :icon-src="item.icon" />
                <SidebarListItemContent :subtitle="item.subtitle" :title="item.name" icon-height="22"
                                        icon-width="22" />
              </template>
            </SidebarListItem>
          </template>
        </SidebarListGroup>

        <SidebarListItem
          v-else
          :key="item.name"
          :click="item.onClick"
          :disabled="item.disabled"
          :to="item.route"
          :tooltip="item.name"
          :value="item.active"
        >
          <SidebarListItemIcon :badge="item.badge" :icon-src="item.icon" />
          <SidebarListItemContent :subtitle="item.subtitle" :title="item.name" />
        </SidebarListItem>
      </template>
    </v-list>
  </div>

  <QRCodeDialog ref="qrCodeDialog" />
</v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import * as mutationTypes from "@/store/mutation-types/main";
import menu from "@/utils/mixins/menu";
import user from "@/utils/mixins/user";

import QRCodeDialog from "@/components/QRCodeDialog.vue";
import SidebarAppLogo from '@/components/sidebar/SidebarAppLogo.vue'
import SidebarListItem from '@/components/sidebar/SidebarListItem.vue'
import SidebarListGroup from '@/components/sidebar/SidebarListGroup.vue'
import SidebarListItemContent from '@/components/sidebar/SidebarListItemContent.vue'
import SidebarListItemIcon from '@/components/sidebar/SidebarListItemIcon.vue'

export default {
  name: "Sidebar",
  components: {
    SidebarListItemIcon,
    SidebarListItemContent,
    SidebarListGroup,
    SidebarListItem,
    SidebarAppLogo,
    QRCodeDialog
  },
  mixins: [menu, user],
  data() {
    return {
      recycleBinIcon: require("@/assets/svg/recycle-bin-trash.svg"),
      settingsIcon: require("@/assets/svg/gears.svg"),
      isOnMobilePortrait: false,
      drawer: true,
      dragProgress: 0,
      offset: 0,
      start: 0,
      maybeDragging: false,
      touch: {
        start: {
          time: 0,
          position: 0
        },
        end: {
          time: 0,
          position: 0
        }
      }
    }
  },
  computed: {
    ...mapState("main", ["isSidebarMinimized"]),
    ...mapState("messenger", ["unseenMessagesCount"]),
    ...mapGetters("school", ["school"]),
    ...mapGetters("auth", ["currentUser"]),
    show: {
      get() {
        return this.$store.state.main.showSideBar;
      },
      set(value) {
        this.$store.commit(`main/${mutationTypes.SHOW_SIDEBAR}`, value);
      },
    },
    mdLandscapeAndUp() {
      return (
        (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.mdAndUp) &&
        window.innerHeight < window.innerWidth
      );
    }
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    async logoutUser() {
      try {
        await this.logout();

        await this.$router.push({ name: "login" });
      } catch (error) {
        const responseData = error.response.data;

        if (responseData.message) {
          this.$snackbar.show({ message: responseData.message, color: "error" });
        }
      }
    },
    onMove(e) {
      const navigationDrawerWidth = this.$refs.navigationDrawer.$el.offsetWidth;
      const percents = (e.touchmoveX - navigationDrawerWidth) * 100 / navigationDrawerWidth;

      if (percents < 0 && percents >= -100) {
        this.$refs.navigationDrawer.$el.style.transition = "none";
        this.$refs.navigationDrawer.$el.style.transform = "translateX(" + percents + "%)";
      }
    },
    onStart(e) {
      this.touch.start.time = new Date().getTime();
      this.touch.start.position = e.touchmoveX;

      if (!this.show) {
        this.$refs.navigationDrawer.$el.style.visibility = "visible";
      }
    },
    onEnd(e) {
      this.touch.end.time = new Date().getTime();
      this.touch.end.position = e.touchmoveX;

      const moveTime = this.touch.end.time - this.touch.start.time;
      const speed = e.touchendX / moveTime;

      const navigationDrawerWidth = this.$refs.navigationDrawer.$el.offsetWidth;
      const percents = (e.touchmoveX - navigationDrawerWidth) * 100 / navigationDrawerWidth;

      const processedDistance = this.touch.end.position - this.touch.start.position;
      if (processedDistance === 0) return;

      if (speed < (this.show ? 1.1 : 0.7)) {
        this.$store.commit(`main/${mutationTypes.SHOW_SIDEBAR}`, percents > -50);
        this.$refs.navigationDrawer.$el.style.transform = "translateX(" + (percents > -50 ? 0 : -100) + "%)";
      } else {
        this.$store.commit(`main/${mutationTypes.SHOW_SIDEBAR}`, !this.show);
      }

      // const navBarIcon = document.getElementById('nav-bar-icon')
      // if (!this.show && navBarIcon) navBarIcon.focus({ focusVisible: false });

      this.$refs.navigationDrawer.$el.style["transition-duration"] = "0.2s";
      this.$refs.navigationDrawer.$el.style["transition-timing-function"] = "cubic-bezier(0.4, 0, 0.2, 1)";
      this.$refs.navigationDrawer.$el.style["transition-property"] = "transform, visibility, width";
    },
    onInput(value) {
      this.show = value;

      if (this.$refs.navigationDrawerDragHook) {
        this.$refs.navigationDrawerDragHook.style.width = this.show ? "100vw" : "285px";
      }
    },
    closeWhenClickOutsideOfSidebar(e) {
      if (this.show && e.x > 275) {
        this.show = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.menu {
  height: calc(100vh - 61px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  &-mobile {
    height: calc(100vh - 48px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  }
}
.navigation-drawer::v-deep {
  z-index: 11;
  background-color: #00435e !important;
  padding-bottom: 0 !important;
  overflow: initial;

  .v-navigation-drawer__content {
    visibility: initial;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    scrollbar-width: none;
  }

  .v-navigation-drawer--fixed {
    z-index: 100;
  }
}
.v-list-item--link:before {
  background-color: #eee;
}
.transparent-overlay {
  position: absolute;
  width: 285px;
  height: 100%;
  background: transparent;
}
</style>
