<script>
import {defineComponent} from 'vue'
import PaymentModel from "@/store/models/PaymentModel";
import FileSaver from "@/helpers/fileSaver";
import paymentsService from "@/services/paymentsService";

export default defineComponent({
  name: "SchoolCreditsHistoryDetailsDialog",
  data() {
    return {
      item: null,
      dialog: false,
      payment: null,
      downloadingInvoice: false,
    }
  },
  methods: {
    show(item) {
      this.item = item
      this.dialog = true
      if (this.item.payment) {
        this.payment = new PaymentModel(this.item.payment);
      }
    },
    close() {
      this.dialog = false

      setTimeout(() => {
        this.payment = null
      }, 200)
    },
    downloadPaymentInvoice(paymentId) {
      this.downloadingInvoice = true
      paymentsService.downloadInvoice(paymentId)
        .then((resp) => {
          const responseData = [resp.data] || [];
          const file = new FileSaver(responseData)
          file.setType('application/pdf').saveToDevice(paymentId + '_Rechnung.pdf', 'Rechnungen');
        })
        .catch((err) => {
          this.$snackbar.error(err.message)
        })
        .finally(() => {
          this.downloadingInvoice = false
        })
    }
  },
})
</script>

<template>
  <v-dialog
    v-model="dialog"
    transition="slide-y-transition"
    :close-on-content-click="false"
    @keydown.esc="close"
    @click:outside="close"
    width="500"
  >
    <v-card v-if="item">
      <v-card-title class="text-h5">
        {{ $t('label.action_details') }}
      </v-card-title>
      <v-card-subtitle>{{ $t(`creditsHistoryType.${item?.type}`) }}, {{ item?.createdAt }}</v-card-subtitle>
      <v-card-text>
        <v-sheet outlined rounded class="my-1 py-2">
          <v-row :class="item.amount < 0 ? 'error--text' : 'success--text'">
            <v-col cols="3" sm="2" class="text-h6 text-right">{{ item.balanceBefore }}</v-col>
            <v-col cols="1" sm="3" class="text-h6 text-center">&rarr;</v-col>
            <v-col cols="4" sm="2" class="text-h6 text-center">{{ item.amount > 0 ? '+' : '' }}{{item.amount}}</v-col>
            <v-col cols="1" sm="3" class="text-h6 text-center">&rarr;</v-col>
            <v-col cols="3" sm="2" class="text-h6">{{ item.balanceAfter }}</v-col>
          </v-row>
        </v-sheet>
        <v-list class="mt-1">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ $t('label.author') }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.author }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="item.description">
            <v-list-item-content>
              <v-list-item-title>{{ $t('label.description') }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <template v-if="item.student">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('label.student') }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.student }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn text color="primary" :to="{ name: 'students.student', params: { id: item.studentId } }">
                  {{ $t('btn.view') }}
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
          <template v-if="item.licenseCode">
            <v-subheader>{{ $t('clickandlearn.license_details') }}</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('clickandlearn.license_code') }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.licenseCode.code }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="item.licenseCode.isAssigned && item.licenseCode.studentId === item.studentId">
              <v-list-item-content>
                <v-list-item-title>{{ $t('clickandlearn.license_code_assigned_at') }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.licenseCode.assignedAt }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="item.licenseCode.isRevokable && item.licenseCode.studentId === item.studentId">
              <v-list-item-content>
                <v-list-item-title>{{ $t('clickandlearn.revokable_until') }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.licenseCode.revokableUntil }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="item.renewalCode">
            <v-subheader>{{ $t('clickandlearn.license_details') }}</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('clickandlearn.renewal_code') }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.renewalCode.code }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('clickandlearn.license_code_renewed_at') }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.renewalCode.assignedAt }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="payment">
            <v-subheader>{{ $t('label.payment_details') }}</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('label.amount') }}</v-list-item-title>
                <v-list-item-subtitle>{{ payment.amount | currency }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action-text class="success--text">
                {{ $tc('label.credits', payment.creditsAmount, {credits: payment.creditsAmount} ) }}
              </v-list-item-action-text>
            </v-list-item>

            <v-list-item>
              <v-list-item-avatar tile>
                <v-img :src="payment.paymentMethodIcon" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ $t('label.payment_method') }}</v-list-item-title>
                <v-list-item-subtitle>{{ payment.method }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" :color="payment.statusColor">{{ payment.statusIcon }}</v-icon>
                  </template>
                  <span>{{ payment.statusText }}</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn text @click="dialog = false">
          {{ $t('btn.close') }}
        </v-btn>
        <v-spacer />
        <v-btn
          text
          color="primary"
          v-if="item.payment"
          @click="downloadPaymentInvoice(item.payment.id)"
          :loading="downloadingInvoice"
          :disabled="downloadingInvoice"
        >
          <v-icon left>mdi-download</v-icon>
          {{ $t('label.invoice') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>
