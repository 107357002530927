<template>
  <div class="layout d-flex text-center flex-column flex-md-row flex-grow-1">
    <v-sheet
      class="layout-side mx-auto mx-md-1 d-none d-md-flex flex-md-column justify-space-between px-4">
      <div class="mt-6 mt-md-20 pa-8">
        <v-img :src="require('@/assets/svg/book-and-pay.svg')" />
        <div class="title my-2">
          {{ $t("messages.we_digitize_processes_in_driving_school") }}
        </div>
      </div>
      <img :src="require('@/assets/img/Image_2.png')" style="width: 100%" />
    </v-sheet>

    <div class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column">
      <div class="layout-content ma-auto" style="width: 100%">
        <v-card class="layout-content pa-2 ma-auto">
          <v-card-title class="justify-center display-1 mb-2">
            <span class="d-none d-md-flex">{{ $t("label.welcome") }}</span>
            <v-img
              :src="require('@/assets/svg/book-and-pay.svg')"
              class="d-block d-md-none"
              eager />
          </v-card-title>
          <v-card-subtitle>{{ $t("messages.log_into_your_personal_account") }}</v-card-subtitle>
          <v-card-text>
            <ValidationObserver ref="form">
              <v-form @keyup.native.enter="submit">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="username"
                  rules="required|min:4"
                >
                  <v-text-field
                    inputmode="email"
                    :label="$t('validation.fields.username')"
                    :placeholder="$t('validation.fields.username')"
                    outlined
                    v-model="form.username"
                    :error-messages="errors"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="password"
                  rules="required">
                  <v-text-field
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="!showPassword ? 'password' : 'text'"
                    :label="$t('validation.fields.password')"
                    :placeholder="$t('validation.fields.password')"
                    outlined
                    v-model="form.password"
                    :error-messages="errors"
                    @click:append="
                      showPassword = !showPassword
                    "></v-text-field>
                </ValidationProvider>
              </v-form>
            </ValidationObserver>

            <v-btn
              :dark="!loading"
              block
              x-large
              color="primary"
              elevation="1"
              :loading="loading"
              :disabled="loading"
              @click="submit"
            >
              {{ $t("sign_in") }}
            </v-btn>

            <div class="mt-5">
              <router-link :to="{name: 'forgot-password'}">{{ $t('label.forgot_password') }}</router-link>
            </div>

            <div class="mt-5">
              <router-link :to="{name: 'create-parent-account'}">{{ $t('label.create_parent_account') }}</router-link>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <CopyrightAndVersion/>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import user from "@/utils/mixins/user";
import api from "@/utils/api";
import {Device} from "@capacitor/device";
import CopyrightAndVersion from "@/views/Auth/CopyrightAndVersion";
import LocalizationManager from "@/helpers/LocalizationManager";
import store from '@/store'
import { load } from 'recaptcha-v3'

export default {
  name: "Login",
  components: {CopyrightAndVersion},
  mixins: [user],
  data() {
    return {
      logo: require("@/assets/svg/book-and-pay.svg"),
      showPassword: false,
      loading: false,
      form: {
        username: "",
        password: "",
        platform: "",
      },
      showForm: false,
      windowHeight: window.innerHeight,
      appVersion: '',
      isWeb: undefined,
    };
  },
  computed: {
    mdLandscapeAndUp() {
      return (
        (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.mdAndUp) &&
        window.innerHeight < window.innerWidth
      );
    }
  },
  created() {
    LocalizationManager.set("de", this.$vuetify);
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    const platform = (await Device.getInfo()).platform;

    this.form.platform = platform;
    this.isWeb = platform === "web";

    const { username, password } = this.$route.query;
    if (username && password && (username + password).length >= 9) {
      this.form = {
        username: decodeURI(username.toString()),
        password: decodeURI(password.toString()),
      };

      this.$nextTick(() => this.submit());
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    ...mapActions("auth", ["login", "getAuthUser"]),
    async submit() {
      const response = await this.$refs.form.validate();

      if (response) {
        try {
          this.loading = true;

          if (this.isWeb) {
            const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_KEY);
            const token = await recaptcha.execute('login');
            await this.login({ ...this.form, token });
          } else {
            await this.login(this.form);
          }


          this.$store.commit('school/set_school_loaded', false)
          this.$store.commit('school/set_school_data_loaded', false)
          await store.dispatch("auth/getAuthUser");

          LocalizationManager.set(this.currentUser.language, this.$vuetify);

          if (this.isElter && this.currentUser.onlyChildId) {
            await store.dispatch("auth/setParentSelectedStudentId", this.currentUser.onlyChildId);
          }

          if (this.isElter) {
            await this.$router.push({ name: "children-accounts" });
            return
          }

          await this.$router.push({ name: "dashboard" });
        } catch (error) {
          console.log(error);

          const response = error.response || {};
          const responseData = response.data || {};
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message)
            this.$refs.form.setErrors({ username: responseData.message });

          this.$refs.form.setErrors(errors);
        }

        this.loading = false;
      }
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    loginWith(provider) {
      this.loading = true;
      this.$auth
        .authenticate(provider)
        .then((response) => {
          this.socialLogin(provider, response);
        })
        .catch((error) => console.log(error));
    },
    socialLogin(provider, response) {
      api
        .post("/login/" + provider, response)
        .then((response) => {
          this.$store
            .dispatch("auth/setAccessToken", response.data.access_token)
            .then(() => {
              this.$router.push({ name: "dashboard" });
            });
        })
        .catch((error) => {
          if (!error.response) {
            console.log(error);

            return false;
          }

          const responseData = error.response.data;

          if (error.response.status === 401) {
            this.$router.push({ name: "registration" }).then(() => {
              this.$snackbar.show({
                message: responseData.message,
                color: "info",
                timeout: 6000,
              });
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.layout {
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}
.layout-side {
  width: 420px;
}

.layout-content {
  max-width: 480px;
}
</style>
