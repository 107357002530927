import user from '@/utils/mixins/user'
import i18n from '@/plugins/i18n'

export default {
  mixins: [user],
  computed: {
    school () {
      return this.$store.state.school
    },
    showCostOverviewTab () {
      if (!this.isStudent) return
      return this.currentUser.isElter
        ? this.parentSelectedStudent.priceCategoryId
        : this.currentUser.priceCategoryId
    },
    menuItems () {
      const items = [
        {
          name: i18n.t('route.childrenAccounts'),
          icon: require('@/assets/svg/children-select.svg'),
          route: { name: 'children-accounts' },
          isShow: this.isElter && !this.parentSelectedStudentId
        },

        {
          name: i18n.t('route.dashboard'),
          icon: require('@/assets/svg/dashboard.svg'),
          route: { name: 'dashboard' },
          isShow: !this.isElter || this.isElter && this.parentSelectedStudentId
        },
        {
          name: i18n.t('route.studentEducationProgress'),
          icon: require('@/assets/svg/education-progress.svg'),
          route: { name: 'student.education.progress' },
          isShow: this.hasAccess(['student']),
        },
        {
          name: i18n.t('route.calendar'),
          icon: require('@/assets/svg/calendar.svg'),
          route: { name: 'calendar' },
          isShow: !this.isStudentRegistered && !(this.isElter && !this.parentSelectedStudentId)
        },
        {
          name: i18n.t('route.messenger'),
          icon: require('@/assets/svg/messenger.svg'),
          route: { name: 'messenger' },
          badge: this.currentUser && !this.isElter ? this.currentUser.unSeenMessagesCount : 0,
          isShow: !this.isElter || this.isElter && this.parentSelectedStudentId
        },
        {
          name: i18n.t('route.teachers'),
          icon: require('@/assets/svg/teachers.svg'),
          route: { name: 'teachers' },
          isShow: this.hasAccess(['school-administrator']),
        },
        {
          name: i18n.t('route.students'),
          icon: require('@/assets/svg/students.svg'),
          route: { name: 'students' },
          isShow: this.hasAccess(['school-administrator', 'teacher']),
        },
        {
          name: i18n.t('route.school'),
          icon: require('@/assets/svg/school.svg'),
          isShow: this.hasAccess(['school-administrator']),
          children: [
            {
              name: i18n.t('route.administrators'),
              icon: require('@/assets/svg/administrators.svg'),
              route: { name: 'administrators' },
              isShow: this.hasAccess(['school-administrator']),
            },
            {
              name: i18n.t('route.licenseRequirements'),
              icon: require('@/assets/svg/license-requirements.svg'),
              route: { name: 'license-requirements' },
              isShow: this.hasAccess(['school-administrator']),
            },
            {
              name: i18n.t('route.theory'),
              icon: require('@/assets/svg/theory.svg'),
              route: { name: 'theory-attendance' },
              activeOnRoutes: ['theory'],
              isShow: this.hasAccess(['school-administrator']),
            },
            {
              name: i18n.t('route.addresses'),
              icon: require('@/assets/svg/place.svg'),
              route: { name: 'addresses' },
              isShow: this.hasAccess(['school-administrator']),
            },
            {
              name: i18n.t('route.appointments'),
              icon: require('@/assets/svg/project-management.svg'),
              route: { name: 'appointments' },
              isShow: this.hasAccess(['school-administrator']),
            },
            {
              name: i18n.t('route.examStatistics'),
              icon: require('@/assets/svg/exam-stats.svg'),
              route: { name: 'exam-statistics' },
              isShow: this.hasAccess(['school-administrator']),
            }
          ],
        },
        {
          name: i18n.t('route.vehicles'),
          icon: require('@/assets/svg/car.svg'),
          isShow: this.hasAccess(['school-administrator']),
          children: [
            {
              name: i18n.t('route.vehiclesOverview'),
              icon: require('@/assets/svg/car.svg'),
              route: { name: 'vehicles' },
              activeOnRoutes: [
                'vehicles.vehicle',
                'vehicles.vehicle.mileage',
                'vehicles.vehicle.fueling',
                'vehicles.vehicle.service',
                'vehicles.vehicle.expenses'
              ],
              isShow: this.hasAccess(['school-administrator']),
            },
            {
              name: i18n.t('route.vehiclesCalendar'),
              icon: require('@/assets/svg/car_calendar.svg'),
              route: { name: 'vehicles.calendar' },
              isShow: this.hasAccess(['school-administrator']),
            }
          ],
        },
        {
          name: i18n.t('route.finances'),
          icon: require('@/assets/svg/finances.svg'),
          isShow: this.hasAccess(['school-administrator']),
          children: [
            {
              name: i18n.t('route.priceLists'),
              icon: require('@/assets/svg/products.svg'),
              route: { name: 'price-lists' },
              isShow: this.hasAccess(['school-administrator']),
            },
            {
              name: i18n.t('route.happyHours'),
              icon: require('@/assets/svg/happy-hours.svg'),
              route: { name: 'happy-hours' },
              isShow: this.hasAccess(['school-administrator'])
                && this.school.hasHappyHours,
            },
            {
              name: i18n.t('route.serviceTemplates'),
              icon: require('@/assets/svg/service-templates.svg'),
              route: { name: 'service-templates' },
              isShow: this.hasAccess(['school-administrator'])
            },
            {
              name: i18n.t('route.payments'),
              icon: require('@/assets/svg/profit.svg'),
              route: { name: 'payments' },
              isShow: this.hasAccess(['school-administrator']),
            },
            {
              name: i18n.t('route.invoices'),
              icon: require('@/assets/svg/invoice.svg'),
              route: { name: 'invoices' },
              isShow:
                this.hasAccess(['school-administrator']) &&
                this.school.isInvoicingEnabled,
            },
          ],
        },
        {
          name: i18n.t("route.studentDocuments"),
          icon: require("@/assets/svg/documents.svg"),
          route: { name: "student.documents" },
          isShow: this.hasAccess(["student"]),
          badge: this.currentUser.unsignedDocumentsCount
        },
        {
          name: i18n.t('route.schoolBalance'),
          icon: require('@/assets/img/coins.png'),
          route: { name: 'school-balance' },
          isShow: this.school.subscriptionPlan === 'credit_based' && this.hasAccess(['school-administrator']),
        },
        {
          name: i18n.t('route.clickAndLearn'),
          icon: require('@/assets/img/ClickAndLearn-logo-white.png'),
          route: { name: 'click-and-learn' },
          isShow: this.school.hasClickAndLearn && this.school.clickAndLearnApiKey && this.hasAccess(['school-administrator']),
        },
        {
          name: i18n.t('route.insights'),
          icon: require('@/assets/svg/evaluate.svg'),
          route: { name: 'insights' },
          isShow: this.school.hasInsights && this.hasAccess(['school-administrator', 'teacher']),
        },
        {
          name: i18n.t('route.print-media'),
          icon: require('@/assets/svg/print-media.svg'),
          route: { name: 'print-media' },
          isShow: this.hasAccess(['school-administrator']),
        },
        {
          name: i18n.t('route.terminal'),
          icon: require('@/assets/svg/pos-terminal.svg'),
          route: { name: 'terminal' },
          isShow: this.hasAccess(['school-administrator']),
        },
        {
          name: i18n.t('route.balance'),
          subtitle: this.$options.filters.currency(this.currentUser.balance),
          icon: require('@/assets/svg/balance-overview.svg'),
          route: { name: 'balance' },
          isShow: this.currentUser.hasPaymentAbility,
        },
        {
          name: i18n.t('route.costsOverview'),
          icon: require('@/assets/svg/costs-overview.svg'),
          route: { name: 'costs-overview' },
          isShow: this.showCostOverviewTab,
        },
        {
          name: i18n.t('route.qrCode'),
          icon: require('@/assets/svg/qr-code-icon.svg'),
          onClick: () => this.$refs.qrCodeDialog && this.$refs.qrCodeDialog.open(),
          isShow: this.school.isDigitalAttendanceEnabled && this.hasAccess(['student']) && !this.isElter
        },
        {
          name: i18n.t('route.recycleBin'),
          icon: require('@/assets/svg/recycle-bin-trash.svg'),
          route: { name: 'recycle-bin' },
          isShow: this.hasAccess(['school-administrator']),
        },
        {
          name: i18n.t('menu.settings'),
          icon: require('@/assets/svg/gears.svg'),
          route: { name: 'school-settings.general' },
          isShow: this.hasAccess(['school-administrator']),
        }
      ]

      items.map((item) => {
        const isPrivacyPolicyAccepted = !this.isStudent || this.isStudent && this.currentUser.privacyPolicyAccepted
        const isCurrentUserActive = isPrivacyPolicyAccepted && this.currentUser.active

        item.active = this.isActive(item)
        item.disabled = !isCurrentUserActive || item.disabled

        if (item.children) {
          item.children.map((child) => {
            child.active = this.isActive(child)
            child.disabled = !isCurrentUserActive || child.disabled
          })

          item.groupRoute = item.children[0].route
          item.active = item.children.some(item => item.active)
          item.groupDisabled = item.children.every(item => item.disabled)
        }
      })

      return items.filter((item) => this.isShow(item))
    },
  },
  methods: {
    isShow (item) {
      const hasIsShowProp = Object.prototype.hasOwnProperty.call(
        item,
        'isShow'
      )

      if (item.children) {
        item.children = item.children.filter((item) =>
          hasIsShowProp ? item.isShow : true
        )
      }

      return hasIsShowProp ? item.isShow : true
    },
    isActive (item) {
      return (
        this.$route.name === item.route?.name ||
        this.isActiveOnRoutes(item.activeOnRoutes)
      )
    },
    isActiveOnRoutes (activeOnRoutes = []) {
      return activeOnRoutes.includes(this.$route.name)
    },
    hasAccess (userTypes) {
      const isElterWithSelectedStudent = userTypes.includes('student') && this.isElter && this.parentSelectedStudentId

      return userTypes.indexOf(this.currentUser.type) !== -1 || isElterWithSelectedStudent
    },
  },
}
