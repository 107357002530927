<template>
  <div>
    <v-row dense :no-gutters="$vuetify.breakpoint.xsOnly">
      <v-col cols="12" sm="7" md="6" lg="8">
        <h4 v-html="title" />
        <p class="text--secondary mt-1" v-if="description">{{ description }}</p>
        <div><slot name="description"></slot></div>
      </v-col>

      <v-col cols="1" v-if="$vuetify.breakpoint.smAndUp" class="text-center"><v-divider vertical/></v-col>

      <v-col cols="12" sm="4" md="5" lg="3">
        <slot></slot>
      </v-col>
    </v-row>

    <v-divider v-if="dividerAfter" class="my-5" />
  </div>
</template>

<script>
export default {
  name: "SettingsResponsiveRow",
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    dividerAfter: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>

</style>
