import { Model } from "@vuex-orm/core";
import StudentModel from "@/store/models/StudentModel";
import LicenseModel from "@/store/models/LicenseModel";

export default class LicenseStudentModel extends Model {
  static entity = "licensesStudent";

  static fields() {
    return {
      id: this.attr(null),
      studentId: this.attr(null),
      licenseId: this.attr(null),
      student: this.belongsTo(StudentModel, "studentId"),
      license: this.belongsTo(LicenseModel, "licenseId"),
      practiceExamEventId: this.attr(null),
      practiceExamPassedAt: this.attr(null),
      theoryExamEventId: this.attr(null),
      theoryExamPassedAt: this.attr(null),
      practiceEducationEndedAt: this.attr(null),
      practiceEducationEventId: this.attr(null),
      theoryEducationEndedAt: this.attr(null),
      theoryEducationEventId: this.attr(null),
      educationSignature: this.attr(null),
      examiningPeriodInWeeks: this.number(0),
      wasBasicAmountFullBilled: this.boolean(false),
      wasBasicAmountPartialBilled: this.boolean(false),
      wasLearningMaterialBilled: this.boolean(false),
      basicAmountPayment: this.string(''),
      partialAmountPayment: this.string(''),
    };
  }
}
