import api from "@/utils/api";

export default {
  load: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("/signatures", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  }
}