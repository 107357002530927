<template>
<v-simple-table dense>
  <v-skeleton-loader v-if="loading" class="mx-auto" type="table-row@3" />

  <template v-else>
    <template v-if="items.length > 0">
      <thead>
      <tr>
        <th style="width: 400px;">{{ $t("list.name") }}</th>
        <th>{{ $t("label.address") }}</th>
        <th style="width: 120px"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in items" :key="item.id" class="table-row">
        <td>{{ item.name }}</td>
        <td>{{ address(item) }}</td>
        <td class="text-center">
          <v-progress-linear
              indeterminate
              color="primary"
              v-if="[...deleting].includes(item.id)"
          />

          <template v-else>
            <v-chip
                class="hidden-until-row-hovered mx-1"
                link
                label
                x-small
                @click="$emit('edit', item)"
            >
              <v-icon x-small>mdi-pencil</v-icon>
            </v-chip>
            <v-chip
                class="hidden-until-row-hovered mx-1"
                link
                label
                x-small
                @click="destroy(item.id)"
            >
              <v-icon x-small>mdi-close</v-icon>
            </v-chip>
          </template>
        </td>
      </tr>
      </tbody>
    </template>

    <v-alert v-else>
      <empty-box />
    </v-alert>
  </template>
</v-simple-table>
</template>

<script>
import meetingPlacesService from '@/services/meetingPlacesService'
import { showConfirm } from '@/helpers/dialogs'
import MeetingPlaceModel from '@/store/models/MeetingPlaceModel'
import searchAddress from '@/utils/mixins/address'

export default {
  name: 'MeetingPlaceTable',
  props: {
    searchText: {
      type: String,
      default: ''
    }
  },
  mixins: [searchAddress],
  data() {
    return {
      loading: false,
      deleting: []
    }
  },
  computed: {
    items() {
      const query = MeetingPlaceModel.query()

      if (this.searchText) {
        query.where(item => {
          return item.name.toLowerCase().includes(this.searchText) || this.searchByFullAddress(this.searchText, item)
        })
      }

      return query.get()
    }
  },
  mounted () {
    this.loading = true
    meetingPlacesService.load()
      .then((resp) => MeetingPlaceModel.create({ data: resp.data || [] }))
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    destroy(id) {
      showConfirm(
        this.$t('btn.confirm'),
        this.$t("messages.are_your_sure_delete"),
        () => {
          this.deleting.push(id)
          meetingPlacesService
            .destroy(id)
            .then(() => MeetingPlaceModel.delete(id))
            .finally(() => {
              this.deleting = this.deleting.filter(item => item !== id)
            })
        }
      );
    }
  }
}
</script>

<style scoped>

</style>
