<template>
  <v-list>
    <span
      v-if="!participants.length && !isSearching && searchText"
      class="ml-2 subtitle-1"
      >{{ $t("label.no_search_results_for", { query: searchText }) }}</span
    >

    <v-list-item
      v-for="participant in participants"
      :key="participant.userId"
      @click="selectParticipant(participant)">
      <v-list-item-avatar>
        <UserAvatar :path="participant.avatar" :alt="participant.fullName"/>
      </v-list-item-avatar>
      <v-list-item-content>
        <span class="text-truncate text-left">{{
          participant.firstName + " " + participant.lastName
        }}</span>
        <span
          class="caption text-left"
          :class="{
            'blue--text': participant.type === 'teacher',
            'green--text': participant.type === 'student',
          }">
          {{ $t(`label.${participant.type}`) }}
        </span>
      </v-list-item-content>
    </v-list-item>

    <div
      class="d-flex justify-start ml-5 mt-1 text--secondary text-body-2"
      v-if="isSearching">
      <v-progress-circular
        class="mr-2"
        color="primary"
        indeterminate
        size="23" />
      <span class="mt-1">{{ $t("label.search_in_progress") }}</span>
    </div>

    <v-progress-circular
      class="mr-2"
      v-if="loadingMore"
      color="primary"
      indeterminate
      size="23" />
  </v-list>
</template>

<script>
import UserAvatar from '@/components/UserAvatar'
export default {
  name: "MessengerParticipants",
  components: { UserAvatar },
  props: {
    loadingMore: {
      type: Boolean,
      default: false,
    },
    isSearching: {
      type: Boolean,
      default: false,
    },
    searchText: {
      type: String,
      default: "",
    },
    participants: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    selectParticipant(participant) {
      this.$emit("select", participant);
    },
  },
};
</script>

<style scoped></style>
