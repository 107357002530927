<script>
import BalanceLabel from "@/components/BalanceLabel.vue";
import ExpandablePanel from "@/components/ExpandablePanel.vue";
import StudentLessonPrices from "@/components/students-directory/student-file/StudentLessonPrices.vue";

export default {
  name: "StudentFinances",
  components: {StudentLessonPrices, ExpandablePanel, BalanceLabel},
  props: {
    student: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      panelName: 'financesPanelExpanded',
      activeTab: null,
    }
  },
  methods: {
    openPriceList(id) {
      this.$router.push({name: 'price-list', params: {id}})
    },
    openCostsOverview() {
      this.$router.push({name: 'students.student.finances.overview', params: {id: this.student.id}})
    },
    openServices() {
      this.$router.push({name: 'students.student.invoices', params: {id: this.student.id}})
    }
  }
}
</script>

<template>
  <expandable-panel panel-name="studentFinancesPanel">
    <template v-slot:title>
      {{ $t('label.finances') }}
    </template>
    <template v-slot:end>
      <balance-label :student="student" v-if="student.bookAndPay" />
    </template>
    <v-tabs v-model="activeTab">
      <v-tab>{{ $t('label.general_information') }}</v-tab>
      <v-tab :disabled="! student.priceLists.length">{{ $t('label.lesson_prices') }}</v-tab>
    </v-tabs>
    <template v-if="student.priceLists.length">
      <v-tabs-items v-model="activeTab" class="mt-4">
        <v-tab-item>
          <v-simple-table dense v-if="student.bookAndPay">
            <thead>
            <tr>
              <th colspan="3">{{ $t('route.costsOverview') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr @click="openCostsOverview" style="cursor:pointer;">
              <td style="width: 50%;" class="text-right success--text font-weight-bold">{{ student.costsOverview.payments | currency }}</td>
              <td style="width: 10px;" class="text-center pa-0"><v-icon color="grey">mdi-arrow-left-right-bold</v-icon></td>
              <td style="width: 50%;" class="text-left error--text font-weight-bold">{{ -1 * student.costsOverview.servicesCosts | currency }}</td>
            </tr>
            </tbody>
          </v-simple-table>

          <v-simple-table dense v-if="$store.state.school.isInvoicingEnabled">
            <thead>
            <tr>
              <th colspan="2" scope="col">{{ $t('label.invoicing') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="$store.state.school.isInvoicesAutomationEnabled">
              <td>{{ $t('label.automation') }}</td>
              <td class="text-right">
                <v-chip label small :color="student.isExcludedFromAutomation ? 'warning' : 'success'">
                  <v-icon left small>{{ student.isExcludedFromAutomation ? 'mdi-autorenew-off' : 'mdi-autorenew' }}</v-icon>
                  <span v-if="student.isExcludedFromAutomation">{{ $t('label.automatic_invoicing_is_disabled') }}</span>
                  <span v-else>{{ $t('label.automatic_invoicing_is_enabled') }}</span>
                </v-chip>
              </td>
            </tr>
            <tr @click="openServices" style="cursor:pointer;">
              <td>{{ $t('label.unbilled_services') }}</td>
              <td class="text-right">{{ student.servicesCount }}</td>
            </tr>
            <tr v-if="student.invoiceAddress">
              <td>{{ $t('label.invoice_address') }}</td>
              <td class="text-right">
                {{ student.invoiceAddress }}
              </td>
            </tr>
            </tbody>
          </v-simple-table>

          <v-simple-table dense class="mt-4">
            <thead>
            <tr>
              <th colspan="3">{{ $t('label.student_price_lists') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="priceList in student.priceLists" :key="priceList.since" @click="openPriceList(priceList.id)" style="cursor:pointer;">
              <td :class="{'font-italic': priceList.isScheduled}">{{ priceList.name }}</td>
              <td :class="{'font-italic': priceList.isScheduled}" style="width: 200px;">{{ priceList.since | dateFormat}}</td>
              <td style="width: 50px;">
                <v-chip color="success" small label  v-if="priceList.isCurrent">
                  <v-icon left small>mdi-check</v-icon>
                  {{ $t('label.pricelist_is_current') }}
                </v-chip>
                <v-chip color="info" small label  v-if="priceList.isScheduled">
                  <v-icon left small>mdi-clock-outline</v-icon>
                  {{ $t('label.pricelist_is_scheduled') }}
                </v-chip>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-tab-item>
        <v-tab-item>
          <student-lesson-prices :student="student" />
        </v-tab-item>
      </v-tabs-items>
    </template>
    <v-card-text v-else>
      <v-alert :type="student.bookAndPay ? 'error' : 'warning'" class="mt-4" >
        <span v-if="student.bookAndPay">
          {{ $t('alert.add_price_list_for_student') }}
        </span>
        <span v-else>
          {{ $t('alert.no_price_list_warning') }}
        </span>
      </v-alert>
    </v-card-text>
  </expandable-panel>
</template>

<style scoped>

</style>
