<script>
import TeacherStatisticsCard from "@/components/exam-statistics/TeacherStatisticsCard.vue";
import examStatisticsService from "@/services/examStatisticsService";

import { endOfMonth, startOfMonth, subMonths, format } from "date-fns";

import ShowsAs from "@/components/ShowsAs.vue";

import LicenseGroupModel from "@/store/models/LicenseGroupModel";
import LicenseModel from "@/store/models/LicenseModel";

import indexFilters from "@/utils/mixins/filters/indexFilters";
import datesFilters from "@/utils/mixins/filters/datesFilters";
import schoolService from "@/services/schoolService";
import usersFilters from "@/utils/mixins/filters/usersFilters";

export default {
  name: "ExamStatistics",
  mixins: [indexFilters, datesFilters, usersFilters],
  components: { ShowsAs, TeacherStatisticsCard },
  data: () => {
    return {
      loadingData: false,
      clearing: false,
      data: [],
      filtering: false,
      dateFilterDialog: false,
      filters: {},
      filterValues: {
        dates: [],
        licensesGroups: [],
        licenses: [],
        teachers: []
      },
      initialFilters: {},
      dates: [],
      sorting: false,
      sortValues: {
        name: null,
        passRate: null
      },
      appliedSortsValues: {
        name: null,
        passRate: null
      },
      teachers: []
    }
  },
  computed: {
    loading() {
      return this.loadingData || this.filtering || this.sorting || this.clearing;
    },
    filtersCount() {
      return Object.values(this.filters).length;
    },
    sortingCount() {
      return Object.values(this.appliedSortsValues).filter(item => item !== null).length;
    },
    appliedFilters() {
      return {
        dates: this.filters?.dates || [],
        licensesGroups: LicenseGroupModel.findIn(this.filters?.licensesGroups),
        licenses: LicenseModel.findIn(this.filters?.licenses),
        teachers: this.teachers.filter(item => (this.filters?.teachers || []).includes(item.id))
      };
    },
    licensesGroups() {
      return LicenseGroupModel.query().where("name", name => {
        return ["Auto", "Motorrad", "LKW", "Bus", "Zugmaschinen"].includes(name);
      }).get();
    },
    licenses() {
      const licensesQuery = LicenseModel.query();

      if (this.filterValues.licensesGroups.length > 0) {
        licensesQuery.where("groupId", groupId => {
          const licensesGroups = this.filterValues.licensesGroups || [];
          return licensesGroups.includes(groupId);
        });
      }

      return licensesQuery.get();
    },
    teachersOptions() {
      const teachers = this.teachers.map(teacher => ({
        active: teacher.active,
        deletedAt: teacher.deletedAt,
        fullName: teacher.fullName,
        id: teacher.id
      }));
      return this.getUsersFiltersOptionsWithHeaders(teachers);
    }
  },
  mounted() {
    this.initialFilters = { ...this.filterValues };

    let currentDate = new Date();
    let firstDayPreviousMonth = format(startOfMonth(subMonths(currentDate, 1)), "yyyy-MM-dd");
    let lastDayPreviousMonth = format(endOfMonth(subMonths(currentDate, 1)), "yyyy-MM-dd");

    this.dates = [firstDayPreviousMonth, lastDayPreviousMonth];
    this.setFilters({ dates: [firstDayPreviousMonth, lastDayPreviousMonth] });

    this.sortValues = { name: "asc", passRate: null };
    this.appliedSortsValues = { name: "asc", passRate: null };

    this.loadingData = true;
    this.fetch({ filters: this.filters, sort: this.appliedSortsValues })
      .finally(() => {
        this.loadingData = false;
      });

    schoolService.getUsers({ type: "teacher" })
      .then(resp => {
        this.teachers = resp.data || [];
      });
  },
  methods: {
    fetch(params = {}) {
      return examStatisticsService
        .list(params)
        .then(resp => {
          this.data = resp.data
        })
        .catch(console.log)
    },
    filter() {
      this.$refs.filtersMenu.close();
      this.applyFilters(this.filterValues);
    },
    setFilters(filters = {}) {
      const cleanFilters = {};
      for (const key in filters) {
        if (Array.isArray(filters[key]) && filters[key].length === 0) continue;
        if (
          filters[key] === null ||
          filters[key] === undefined ||
          filters[key] === "" ||
          filters[key] === false
        )
          continue;

        if (!(key in cleanFilters)) {
          cleanFilters[key] = filters[key];
        }

        if (key in this.filterValues) {
          this.filterValues[key] = filters[key];
        }
      }

      this.filters = cleanFilters;
    },
    applyFilters(filters = {}) {
      this.setFilters(filters);

      this.loadingData = true;
      this.filtering = true;
      this.fetch({ filters })
        .finally(() => {
          this.filtering = false;
          this.loadingData = false;
        });
    },
    clearFilters() {
      this.filterValues = { ...this.initialFilters };
      this.setFilters({});
      this.dates = [];

      this.loadingData = true;
      this.clearing = true;
      this.fetch({ filters: {} })
        .finally(() => {
          this.loadingData = false;
          this.clearing = false;
        });
    },
    removeDatesFilter() {
      const filters = { ...this.filters };

      this.filterValues.dates = [];
      delete filters["dates"];
      this.dates = [];

      this.setFilters(filters);
      this.applyFilters(filters);
    },
    sort() {
      this.clearTableSorting();
      this.appliedSortsValues = structuredClone(this.sortValues);
      this.$refs.sortMenu.close();

      this.sorting = true;
      this.fetch({ filters: this.filters, sort: this.appliedSortsValues })
        .finally(() => {
          this.sorting = false;
        });
    },
    onSortingChange(name, value) {
      this.clearMenuSorting();
      this.sortValues[name] = value;
    },
    clearMenuSorting() {
      this.sortValues = { name: null, passRate: null };
    },
    clearTableSorting() {
      this.appliedSortsValues = { name: null, passRate: null };
    },
    clearSortingAndCloseMenu() {
      this.$refs.sortMenu.close();
      this.clearTableSorting();
      this.clearMenuSorting();

      this.clearing = true;
      this.fetch({ filters: this.filters, sort: {} })
        .finally(() => {
          this.clearing = false;
        });
    },
    onChangeLicensesGroupsFilter(value) {
      if (value.length === 0) return false;

      const availableLicenses = LicenseModel
        .query()
        .where("groupId", groupId => value.includes(groupId))
        .get()
        .map(license => license.id);

      this.filterValues.licenses = this.filterValues
        .licenses.filter(licenseId => availableLicenses.includes(licenseId));
    }
  }
}
</script>

<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-card>
      <v-toolbar elevation="3">
        <v-card-title>{{ $t("route.examStatistics") }}</v-card-title>
      </v-toolbar>
      <v-card-text>
        <v-row :class="{'flex-column-reverse': $vuetify.breakpoint.xs}">
          <v-col class="d-flex align-center" cols="12" sm="10">
            <template v-if="filtersCount > 0">
              <v-chip-group show-arrows>
                <v-chip
                  v-if="appliedFilters.dates.length > 0"
                  :disabled="loading"
                  close
                  small
                  @click:close="removeDatesFilter"
                >
                  <span>{{ appliedFilters.dates[0] | dateFormat("dd.MM.yyyy") }}</span>
                  <span
                    v-if="appliedFilters.dates.length > 1">&nbsp;-&nbsp;{{ appliedFilters.dates[1] | dateFormat("dd.MM.yyyy")
                    }}</span>
                </v-chip>

                <v-chip
                  v-for="group in appliedFilters.licensesGroups"
                  :key="`license_group_${group.id}`"
                  close
                  small
                  @click:close="removeFromAppliedFilters('licensesGroups', group.id)"
                >
                  {{ group.name }}
                </v-chip>

                <v-chip
                  v-for="license in appliedFilters.licenses"
                  :key="`license_${license.id}`"
                  close
                  small
                  @click:close="removeFromAppliedFilters('licenses', license.id)"
                >
                  {{ license.name }}
                </v-chip>

                <v-chip
                  v-for="teacher in appliedFilters.teachers"
                  :key="`teacher_${teacher.id}`"
                  close
                  small
                  @click:close="removeFromAppliedFilters('teachers', teacher.id)"
                >
                  {{ teacher.fullName }}
                </v-chip>
              </v-chip-group>
            </template>
          </v-col>

          <v-col class="d-flex align-center" cols="12" sm="2">
            <v-spacer />

            <ShowsAs
              ref="sortMenu"
              :options="{
                offsetX: true,
                offsetY: true,
                left: true,
                zIndex: 10,
                minWidth: 300,
                maxWidth: 300
              }"
              :type="$vuetify.breakpoint.mobile ? 'dialog' : 'menu'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  :content="sortingCount"
                  :value="sortingCount"
                  color="primary"
                  overlap
                >
                  <v-btn
                    :disabled="loading"
                    color="primary"
                    icon
                    text
                    v-bind="attrs" v-on="on"
                  >
                    <v-icon>mdi-sort</v-icon>
                  </v-btn>
                </v-badge>
              </template>

              <v-card>
                <v-card-title>{{ $t("label.sorting") }}</v-card-title>

                <v-list class="py-0" dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("label.name") }}</v-list-item-title>

                      <v-radio-group
                        :value="sortValues.name"
                        dense
                        hide-details
                        row
                        @change="value => onSortingChange('name', value)"
                      >
                        <v-radio :label="$t('label.asc')" :ripple="false" value="asc" />
                        <v-radio :label="$t('label.desc')" :ripple="false" value="desc" />
                      </v-radio-group>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("label.pass_rate") }}</v-list-item-title>

                      <v-radio-group
                        :value="sortValues.passRate"
                        dense
                        row
                        @change="value => onSortingChange('passRate', value)"
                      >
                        <v-radio :label="$t('label.asc')" :ripple="false" value="asc" />
                        <v-radio :label="$t('label.desc')" :ripple="false" value="desc" />
                      </v-radio-group>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-divider />

                <v-card-actions>
                  <v-btn :disabled="clearing" :loading="clearing" color="red" text @click="clearSortingAndCloseMenu">
                    {{ $t("btn.clear") }}
                  </v-btn>
                  <v-spacer />
                  <v-btn :disabled="sorting" :loading="sorting" color="primary" text @click="sort">{{ $t("btn.ok") }}
                  </v-btn>
                </v-card-actions>
              </v-card>

            </ShowsAs>

            <ShowsAs
              ref="filtersMenu"
              :options="{
                fullScreen: $vuetify.breakpoint.mobile,
                offsetX: true,
                offsetY: true,
                left: true,
                zIndex: 10,
                minWidth: 800,
                maxWidth: 200
              }"
              :type="$vuetify.breakpoint.mobile ? 'dialog' : 'menu'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  :content="filtersCount"
                  :value="filtersCount"
                  color="primary"
                  overlap
                >
                  <v-btn
                    :disabled="loading"
                    color="primary"
                    icon
                    text v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-filter-outline</v-icon>
                  </v-btn>
                </v-badge>
              </template>

              <v-card :tile="$vuetify.breakpoint.mobile">
                <v-card-title>{{ $t("label.filters") }}</v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-dialog
                        ref="dialog"
                        v-model="dateFilterDialog"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :label="$t('label.time_range')"
                            :placeholder="$t('label.time_range')"
                            :value="formattedDates"
                            clearable
                            dense
                            hide-details
                            outlined
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="clearFilterByDate"
                          />
                        </template>

                        <v-date-picker
                          v-model="dates"
                          :max="new Date().toISOString()"
                          first-day-of-week="1"
                          range
                          scrollable
                        >
                          <v-spacer />
                          <v-btn color="primary" text @click="dateFilterDialog = false">{{ $t("btn.cancel") }}</v-btn>
                          <v-btn
                            color="primary"
                            text
                            @click="setStartDateFilter"
                          >
                            {{ $t("btn.ok") }}
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="filterValues.licensesGroups"
                        :items="licensesGroups"
                        :label="$t('label.filter_by_licenses_groups')"
                        clearable
                        dense
                        hide-details
                        item-text="name"
                        item-value="id"
                        multiple
                        outlined
                        @change="onChangeLicensesGroupsFilter"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="filterValues.licenses"
                        :items="licenses"
                        :label="$t('filters.licenses')"
                        clearable
                        dense
                        hide-details
                        item-text="name"
                        item-value="id"
                        multiple
                        outlined
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="filterValues.teachers"
                        :items="teachersOptions"
                        :label="$t('filters.teachers')"
                        clearable
                        dense
                        hide-details
                        multiple
                        outlined
                        item-text="fullName"
                        item-value="id"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-action>
                            <v-checkbox :input-value="filterValues.teachers.includes(item.id)" />
                          </v-list-item-action>

                          <v-list-item-content>
                            <v-list-item-title
                              :class="{'font-italic disabled': (item.deletedAt !== null || !item.active)}">
                              {{ item.fullName }}
                              <v-icon v-if="item.deletedAt !== null" class="mb-1 ml-1" small>mdi-trash-can</v-icon>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                  <v-btn :disabled="clearing" :loading="clearing" color="red" text @click="clearFilters">
                    {{ $t("btn.clear") }}
                  </v-btn>
                  <v-spacer />
                  <v-btn :disabled="filtering" :loading="filtering" color="primary" text @click="filter">
                    {{ $t("btn.ok") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </ShowsAs>
          </v-col>
        </v-row>

        <v-row dense>
          <empty-box v-if="! data.length" />
          <v-col v-else cols="12" sm="6" md="4" lg="3" v-for="item in data" :key="item.teacherId">
            <teacher-statistics-card :data="item" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<style scoped>

</style>
