<script>
import schoolService from "@/services/schoolService";
import debounceThrottle from "@/utils/mixins/debounce-throttle";

export default {
  name: "DrivingAreasCombobox",
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  mixins: [debounceThrottle],
  data() {
    return {
      loading: false,
      items: [],
      debounceSearch: null,
      deleting: null
    };
  },
  computed: {
    area: {
      set(value) {
        const area = value?.area || value;
        this.setDrivingArea(area);
      },
      get() {
        return this.value;
      }
    }
  },
  created() {
    this.debounceSearch = this.debounce(function(val) {
      this.loading = true;
      schoolService.getDrivingAreas({ search: val })
        .then(response => {
          this.items = response.data || [];
        })
        .finally(() => {
          this.loading = false;
        });
    }, 500);
  },
  methods: {
    search(val) {
      const area = val?.area || val;
      this.debounceSearch(area);
      this.setDrivingArea(area);
    },
    setDrivingArea(val) {
      this.$emit("input", val);
    },
    deleteItem(val) {
      this.deleting = val;
      schoolService.deleteDrivingArea(val)
        .then(() => {
          this.items = this.items.filter(item => item.id !== val);
        })
        .finally(() => {
          this.deleting = null;
        });
    }
  }
};
</script>

<template>
  <v-combobox
    v-model="area"
    :items="items"
    :label="$t('label.driving_area')"
    :loading="loading"
    :placeholder="$t('messages.start_type_to_see_previous_values')"
    clearable
    dense
    hide-no-data
    hide-selected
    item-text="area"
    item-value="area"
    outlined
    @update:search-input="search"
    @click:clear="search"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-text="item.area"></v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          :disabled="deleting === item.id"
          :loading="deleting === item.id"
          icon
          small
          @click.stop.native="deleteItem(item.id)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
  </v-combobox>
</template>

<style scoped>

</style>
