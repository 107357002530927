<template>
  <v-sheet
    class="py-4 px-3"
    :class="{ 'd-flex': orientation === 'horizontal' }"
    color="white"
    elevation="1"
    width="100%"
    rounded
  >

    <template v-if="orientation === 'horizontal'">
      <div class="d-flex mx-auto">
        <UserAvatar class="elevation-2" size="90" :path="teacher.avatar" :alt="teacher.fullName" />
      </div>

      <div class="ml-3 d-flex flex-column">
        <span class="text-h5">{{ teacher.fullName }}</span>

        <a class="teacher-info subtitle-2 text-truncate" :href="`mailto:${teacher.email}`">{{ teacher.email }}</a>
        <a class="teacher-info subtitle-2 text-truncate" :href="`tel:${teacher.phone}`">{{ teacher.phone }}</a>
        <a class="teacher-info subtitle-2 text-truncate" :href="`tel:${teacher.landline}`">{{ teacher.landline }}</a>

        <span class="subtitle-2">
          {{ teacher.street + " " + teacher.houseNr }}
          {{ teacher.postcode + " " + teacher.city }}
        </span>

      </div>

      <v-spacer />

      <v-btn icon large @click="openStudentSidebar">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <TeacherSidebar ref="sidebar" :teacher-id="teacherId" />
  </v-sheet>
</template>

<script>
import UserAvatar from '@/components/UserAvatar.vue'
import TeacherSidebar from '@/components/teacher/TeacherSidebar.vue'
import TeacherModel from '@/store/models/TeacherModel'

export default {
  name: "TeacherInfoCard",
  components: { TeacherSidebar, UserAvatar },
  props: {
    orientation: {
      type: String,
      default: "vertical",
    },
    teacherId: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    teacher() {
      return TeacherModel.find(this.teacherId) || {};
    },
  },
  methods: {
    openStudentSidebar() {
      this.$refs.sidebar.open();
    }
  }
};
</script>

<style scoped lang="scss">
.teacher-info {
  text-decoration: none;
  max-width: 200px;
}
</style>
