<template>
  <v-container>
    <v-card :loading="loading">
      <v-toolbar elevation="3">
        <v-card-title>{{ $t("label.payouts") }}</v-card-title>
        <v-spacer />
        <v-btn text color="primary" @click="$router.push({ name: 'payments' })">
          {{ $t("btn.payments") }}
        </v-btn>
      </v-toolbar>
      <template v-if="!loading">
        <PayoutsTable :payouts="payouts" v-if="payouts.length" class="mt-5" />
        <empty-box v-else />
      </template>
      <v-card-text v-else>
        <v-skeleton-loader class="mx-auto" type="table-row@3" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import payoutsService from "@/services/payoutsService";
import PayoutsTable from "@/components/payments/PayoutsTable";

export default {
  name: "Payouts",
  components: { PayoutsTable },
  data() {
    return {
      loading: true,
      payouts: [],
    };
  },
  mounted() {
    payoutsService
      .load()
      .then((resp) => {
        this.payouts = resp.data;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters("auth", { currentUser: "currentUser" }),
  },
};
</script>

<style scoped>
.hidden-until-row-hovered {
  display: none;
}
.table-row:hover > td > .hidden-until-row-hovered {
  display: inline-flex;
}
</style>
