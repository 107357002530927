<template>
  <div class="categories-grid">
    <EvaluationCategoryItem v-for="category in categories" :key="category.id" :category="category" />
  </div>
</template>

<script>
import EvaluationCategoryItem from '@/components/insights/EvaluationCategoryItem.vue'

export default {
  name: 'EvaluationCategoryGroup',
  components: { EvaluationCategoryItem },
  props: {
    categories: {
      type: Array,
    },
  }
}
</script>

<style lang="scss" scoped>
  .categories-grid {
    grid-gap: .5em;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px,1fr));
    padding: 0;
  }
</style>
