import api from "@/utils/api";

export default {
  load: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("calendar", { params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  get: (id) => {
    return new Promise((resolve, reject) => {
      api
        .get(`calendar/${id}`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  getBrief: (eventId) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/events/brief/${eventId}`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  store: (newEvent) => {
    return new Promise((resolve, reject) => {
      api
        .post("events", newEvent)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  update: (eventId, params) => {
    return new Promise((resolve, reject) => {
      api
        .put("events/" + eventId, params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  confirmPractice: (eventId, params) => {
    return new Promise((resolve, reject) => {
      api
        .put("events/" + eventId + "/confirm", params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  declinePractice: (eventId) => {
    return new Promise((resolve, reject) => {
      api
        .put("events/" + eventId + "/decline")
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  delete: (eventId) => {
    return new Promise((resolve, reject) => {
      api
        .delete("events/" + eventId)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  deleteMultiple: (params) => {
    return new Promise((resolve, reject) => {
      api
        .delete("events", { params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  theoryRequest: (eventId, params) => {
    return new Promise((resolve, reject) => {
      api
        .post("events/" + eventId + "/theory/request", params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  simulatorSubscribe: (eventId, params) => {
    return new Promise((resolve, reject) => {
      api
        .post("events/" + eventId + "/simulator/subscribe", params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  simulatorDecline: (eventId, params) => {
    return new Promise((resolve, reject) => {
      api
        .delete("events/" + eventId + "/simulator/decline", params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  placeholderRequest: (eventId, params) => {
    return new Promise((resolve, reject) => {
      api
        .post("events/" + eventId + "/placeholder/request", params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  placeholderConfirm: (eventId, params) => {
    return new Promise((resolve, reject) => {
      api
        .put("events/" + eventId + "/placeholder/confirm", params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  placeholderDecline: (eventId, params) => {
    return new Promise((resolve, reject) => {
      api
        .delete("events/" + eventId + "/placeholder/request/decline", params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  declineTheoryStudent: (eventId, params) => {
    return new Promise((resolve, reject) => {
      api
        .delete("events/" + eventId + "/theory/request/decline", { params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  eventTypes: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("events/event-types", { params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  lessonTypes: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("/lesson-types", { params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  sign: (eventId, params) => {
    return new Promise((resolve, reject) => {
      api
        .post(`events/${eventId}/sign`, params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  copy: (eventId, params) => {
    return new Promise((resolve, reject) => {
      api
        .post("events/" + eventId + "/copy", params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  copyWeek: (params) => {
    return new Promise((resolve, reject) => {
      api
        .put("events/copy-week", params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  eventCountByType: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("events/event-count-by-type", { params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  setMissedStatus: (eventId) => {
    return new Promise((resolve, reject) => {
      api
        .put("events/" + eventId + "/set-missed")
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  refundMissedEvent: (eventId, params) => {
    return new Promise((resolve, reject) => {
      api
        .put("events/" + eventId + "/refund-for-missed", params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  scanTheoryQR: (eventId, studentId) => {
    return new Promise((resolve, reject) => {
      api
        .patch("events/" + eventId + "/theory/scan-qr", studentId)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  setPresentStatus: (eventId, studentId) => {
    return new Promise((resolve, reject) => {
      api
        .patch("events/" + eventId + "/theory/set-present-status", studentId)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  getTheoryRequestsData: (eventId) => {
    return new Promise((resolve, reject) => {
      api
        .get("events/" + eventId + "/theory/requests-data")
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },

  generateTheoryPdf(eventId, params) {
    return new Promise((resolve, reject) => {
      api
        .get("events/" + eventId + "/theory/theory-attendance-pdf", {
          params: params,
          responseType: "blob",
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  destroyedInfo() {
    return new Promise((resolve, reject) => {
      api
        .get("/events/destroyed-info")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  destroyedList(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/events/destroyed-list", { params: params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  restore(eventId) {
    return new Promise((resolve, reject) => {
      api
        .patch(`/events/${eventId}/restore`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
