<template>
  <div class="d-flex align-end justify-space-between">
    <div class="pl-5">
      <v-chip small color="primary" text-color="white">
        <v-icon left size="1rem" @click="emitDelete(lesson)">mdi-close-circle</v-icon>
        {{ lessonName(lesson) }}
      </v-chip>
    </div>

    <div style="width: 80px">
      <ValidationProvider
        :name="`discount-${lesson}-${license}`"
        rules="required"
        v-slot="{ errors }"
      >
        <v-text-field
          placeholder="0,00"
          :value="discount"
          required
          dense
          hide-details
          append-icon="mdi-currency-eur"
          v-currency="{
            currency: null,
            allowNegative: false,
            valueRange: { min: 0.0 },
          }"
          :error-messages="errors"
          @input="value => $emit('discountChange', lesson, value)"
        />
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
import LessonTypeModel from "@/store/models/LessonTypeModel";
import { mapGetters } from "vuex";

export default {
  name: "PriceSection",
  props: {
    lesson: {
      type: Number,
      required: true,
    },
    license: {
      type: Number,
      required: true,
    },
    discount: {
      type: [Number, String],
      default: 0
    }
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
  },
  methods: {
    lessonName(id) {
      const lesson = LessonTypeModel.find(id) || {};
      return this.$tc(lesson.name);
    },
    emitDelete(value) {
      this.$emit("emitDelete", value);
    },
  },
};
</script>

<style scoped lang="scss"></style>
