export default {
  methods: {
    address(item) {
      let address = `${item.street} ${item.houseNr}, ${item.postcode} ${item.city}`

      if (item.country) address += `, ${item.country}`

      return address
    },
    searchByFullAddress (searchText, item) {
      return this.address(item).toLowerCase().includes(searchText)
    }
  }
}