<template>
  <div>
    <v-container>
      <DeletedAtAlert
        v-if="event.deletedAt"
        entityType="event"
        :entity="event"
        @restored="fetch(event.id)"
      />

      <v-card :loading="loading" height="100%">
        <v-toolbar elevation="3">
          <v-card-title class="pr-0 pl-1 text-truncate">
            <v-btn class="mr-4" @click="$router.go(-1)" icon exact>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>

            <v-skeleton-loader v-if="loading" width="150" type="list-item"/>

            <span class="text-truncate" v-else>{{ $t(`event_type.${event.type}`) }}</span>
          </v-card-title>

          <v-spacer/>

          <span v-if="!loading" class="small-font text-monospace">#{{ event.id }}</span>

          <AppointmentDetailsActions
            :appointment="event"
            :loading="loading"
            :actions-loading="actionsLoading"
            @requested="fetch(event.id)"
            @deleted="onDeleted"
          />
        </v-toolbar>


        <v-card-text>
          <AppointmentDetailsLoader v-if="loading"/>

          <v-row no-gutters dense v-else :class="{'flex-column-reverse': $vuetify.breakpoint.mobile}">
            <v-col cols="12" md="6">
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-calendar</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("label.start") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ event.start | dateFormat }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-clock-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("label.duration") }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $tc("label.duration_in_minutes", event.duration, {min: event.duration,}) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="event.theoryTopic">
                  <v-list-item-icon>
                    <v-icon>mdi-calendar</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("form.topic") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ event.theoryTopic }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="event.meetingPlace">
                  <v-list-item-icon>
                    <v-icon>mdi-map-marker-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("label.meeting_place") }}</v-list-item-title>
                    {{ event.meetingPlace }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="event.office">
                  <v-list-item-icon>
                    <v-icon>mdi-map-marker-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("label.location") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ event.office }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="event.teacherName">
                  <v-list-item-icon>
                    <v-icon>mdi-account-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("label.teacher") }}</v-list-item-title>
                    <conponent :is="isSchoolAdministrator ? 'router-link' : 'VListItemSubtitle'"
                               :to="{ name: 'teachers.teacher', params: { id: event.teacherId } }">
                      {{ event.teacherName }}
                    </conponent>
                  </v-list-item-content>
                </v-list-item>

                <template v-if="!isStudent">
                  <template v-if="event.students">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>{{ event.students.length === 1 ? "mdi-account-outline" : "mdi-account-multiple-outline"
                          }}
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t(event.students?.length === 1 ? "label.student" : "label.students") }}
                          <span v-if="['THEORY', 'SPECIAL', 'PLACEHOLDER'].includes(event.type)" class="caption">
                            - {{ event.students?.length || 0 }}
                            <template v-if="event.roomSize">/{{ event.roomSize }}</template>
                          </span>
                        </v-list-item-title>

                        <template v-if="event.students?.length">
                          <v-list-item-subtitle>
                            <table>
                              <tr v-for="student in event.students" :key="student.id">
                                <td class="py-1">
                                  <conponent
                                    :is="isSchoolAdministrator || isTeacher ? 'router-link' : 'span'"
                                    :to="{ name: 'students.student', params: { id: student.id } }"
                                  >
                                    {{ student.name }}
                                  </conponent>
                                </td>
                                <td
                                  v-if="schoolSettings.isDigitalAttendanceEnabled && event.lessonType === 'lesson_type.theory_lesson'"
                                  class="pl-3"
                                >
                                  <v-icon :color="student.isPresent ? 'secondary' : 'grey'" size="20">
                                    {{ student.isPresent ? 'mdi-account-check-outline' : 'mdi-account-alert-outline' }}
                                  </v-icon>
                                </td>
                                <td v-if="showPassedBtn(student)">
                                  <v-btn
                                    :class="{'white--text': student.passedExam}"
                                    :color="student.passedExam ? 'green' : 'primary'"
                                    :disabled="loadingPassedExamBtn === student.id ||
                                     !student.isExamRequired || event.deletedAt"
                                    :loading="loadingPassedExamBtn === student.id"
                                    :outlined="!student.passedExam"
                                    block
                                    class="ml-2"
                                    x-small
                                    @click="markAsPassedExam(student)"
                                  >
                                    <template v-slot:loader>
                                      <v-progress-circular color="primary" indeterminate size="15" width="2" />
                                    </template>

                                    <v-icon v-if="student.passedExam || !student.isExamRequired" class="mr-1" small>
                                      mdi-check
                                    </v-icon>
                                    {{ $t(student.isExamRequired ? "btn.passed" : "label.not_required") }}
                                  </v-btn>
                                </td>
                              </tr>
                            </table>
                          </v-list-item-subtitle>
                        </template>
                        <v-list-item-subtitle v-else>{{ $t("emptyList") }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </template>

                <v-list-item v-if="event.allowedLicenses && event.allowedLicenses.length > 0 && !event.license">
                  <v-list-item-icon>
                    <v-icon>mdi-license</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('label.allowed_licenses') }}</v-list-item-title>
                    <v-list-item-subtitle v-if="loading"
                    >
                      <v-skeleton-loader width="60" type="text@1"
                      />
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>{{ event.allowedLicenses.join(", ") }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="event.primaryVehicle">
                  <v-list-item-icon>
                    <v-icon>mdi-car</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("form.primary_vehicle") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ event.primaryVehicle }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="event.secondaryVehicle">
                  <v-list-item-icon>
                    <v-icon>mdi-car</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("form.secondary_vehicle") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ event.secondaryVehicle }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="event.text">
                  <v-list-item-icon>
                    <v-icon>mdi-pen</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("form.text") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ event.text }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="event.notes">
                  <v-list-item-icon>
                    <v-icon>mdi-text</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('label.notes') }}</v-list-item-title>
                    {{ event.notes }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="event.type === 'PLACEHOLDER' && !isStudent">
                  <v-list-item-icon>
                    <v-icon>mdi-account-multiple-plus-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('label.multiple_requests_allowed') }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-icon small>
                        {{ event.hasMultipleRequestsAllowed ? 'mdi-check' : 'mdi-close' }}
                      </v-icon>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="event.type === 'SPECIAL' && event.specialType === 'STANDARD'">
                  <v-list-item-icon>
                    <v-icon>mdi-timer-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('label.teacher_work_time') }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-icon small>
                        {{ event.isWorkTime ? 'mdi-check' : 'mdi-close' }}
                      </v-icon>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="event.cost">
                  <v-list-item-icon>
                    <v-icon>mdi-currency-eur</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("label.cost") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ event.cost | currency }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="12" md="6">
              <v-list class="basic-info" dense>
                <v-list-item dense v-if="studentRequest && studentRequest.chosenLicense">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('label.chosen_license') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ studentRequest.chosenLicense }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <template v-if="event.status !== 'REQUESTED'">
                  <v-list-item dense v-if="event.lessonType">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("label.lesson_type") }}</v-list-item-title>
                      <v-list-item-subtitle>{{ lessonType }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item dense v-if="event.license">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("label.license") }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ event.license }}
                        <span v-if="event.isManualLesson && event.license === 'B197'">, {{ $t('lesson_type.manual_lessons') }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item dense :three-line="event.refundedAmount > 0.0" v-if="event.status">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("label.status") }}</v-list-item-title>
                      <v-list-item-subtitle>{{ $t(`eventStatus.${event.status}`) }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-if="event.refundedAmount > 0.0">
                        {{ event.refundedAmount | currency }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>

                <v-list-item dense v-if="event.specialType">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('label.type') }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                        $t(`event_type.SPECIAL_TYPES.${event.specialType}`)
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item dense v-if="event.specialName">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('label.title') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ event.specialName }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item dense v-if="event.simulatorName">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('label.title') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ event.simulatorName }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item dense v-if="showRequestList">
                  <v-list-item-content>
                    <AppointmentDetailsRequestsList
                      v-if="showRequestList"
                      :appointment="event"
                      @confirmed="fetch(eventId)"
                      @declined="onDecline"
                    />
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>

          <v-row no-gutters dense v-if="isSchoolAdministrator && ! loading && event.boughtAt">
            <v-col cols="12" v-if="event.schoolLessonDurationIs !== event.schoolLessonDurationWas">
              <v-alert class="mb-0" border="left" type="warning" colored-border elevation="1">
                {{
                  $t('messages.school_lesson_duration_on_appointment_creation', {
                    lessonDurationWas: event.schoolLessonDurationWas,
                    lessonDurationIs: event.schoolLessonDurationIs
                  })
                }}
              </v-alert>
            </v-col>
            <v-col
              cols="12"
              v-if="student && student.priceCategoryUsed && student.priceCategoryId !== student.priceCategoryUsed.id"
            >
              <v-alert class="mb-0" border="left" type="warning" colored-border elevation="1">
                {{ $t('messages.price_category_has_changed_since_appointment_creation') }}
              </v-alert>
            </v-col>
            <v-col cols="12" v-if="event.vehiclePriceIs !== event.vehiclePriceWas">
              <v-alert class="mb-0" border="left" type="warning" colored-border elevation="1">
                {{
                  $t('messages.vehicle_price_changed_since_appointment_creation', {
                    vehiclePriceWas: event.vehiclePriceWas,
                    vehiclePriceIs: event.vehiclePriceIs
                  })
                }}
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <template v-if="!isStudent && event.type === 'THEORY' && isActiveQRScanner">
            <v-btn :disabled="!isWithinValidScanTimeRange" :to="`/scanner/${event.id}`" block color="primary">
              {{ $t("label.scan") }}
              <v-icon class="ml-3" small>mdi-scan-helper</v-icon>
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>

      <appointment-evaluation-result
        v-if="event.isAvailableForEvaluation && !event.deletedAt"
        :appointmentId="appointmentId"
      />
    </v-container>
  </div>
</template>

<script>
import {addMinutes, isPast, isWithinInterval, parseISO, subMinutes} from "date-fns";
import {mapGetters} from "vuex";
import user from "@/utils/mixins/user";

import { VListItemSubtitle } from 'vuetify/lib'

import appointmentService from "@/services/appointmentService";
import DeletedAtAlert from "@/components/student/DeletedAtAlert";

import LicenseModel from '@/store/models/LicenseModel'
import LessonTypeModel from '@/store/models/LessonTypeModel'
import AppointmentDetailsRequestsList from '@/components/appointment/AppointmentDetailsRequestsList'
import AppointmentDetailsActions from '@/components/appointment/AppointmentDetailsActions'
import laravelEcho from '@/plugins/laravel-echo'

import AppointmentModel from '@/store/models/AppointmentModel'
import AppointmentDetailsLoader from '@/components/skeleton-loaders/AppointmentDetailsLoader'

import {Device} from '@capacitor/device'
import AppointmentEvaluationResult from "@/components/insights/AppointmentEvaluationResult.vue";
import studentsService from "@/services/studentsService";
import { showConfirm } from "@/helpers/dialogs";

export default {
  name: "AppointmentDetails",
  components: {
    AppointmentEvaluationResult,
    AppointmentDetailsLoader,
    AppointmentDetailsActions,
    AppointmentDetailsRequestsList,
    DeletedAtAlert,
    VListItemSubtitle
  },
  mixins: [user],
  data() {
    return {
      loading: false,
      loadingPassedExamBtn: null,
      device: {platform: ""},
      eventId: this.$route.params.id,
      event: {},
      requestColor: {
        ACCEPTED: "green",
        REQUESTED: "amber",
        DECLINED: "red",
      },
      prevRoute: "",
      showApproveRequestDialog: false,
      confirmPracticeLoading: false,
      declinePracticeLoading: false,
      abandonPlaceholderLoading: false,
      teacher: {},
      licenses: [],
      form: {
        licenseId: null
      }
    };
  },
  computed: {
    ...mapGetters("school", {schoolSettings: "school"}),
    appointmentId() {
      return parseInt(this.$route.params.id)
    },
    isMobileApp() {
      return ["ios", "android", "electron"].includes(this.device.platform);
    },
    isActiveQRScanner() {
      return this.isMobileApp && this.schoolSettings.isDigitalAttendanceEnabled;
    },
    goBackTo() {
      return this.$route.query.return_to;
    },
    lessonTypeLabel() {
      if (this.isStudent && this.event.lessonType === "lesson_type.gratis_exam")
        return this.$tc("lesson_type.exam", 1);
      return this.$tc(this.event.lessonType);
    },
    activeLicenses() {
      return LicenseModel.all();
    },
    lessonTypesList() {
      return LessonTypeModel.all();
    },
    student() {
      return this.event.students[0];
    },
    actionsLoading() {
      return this.confirmPracticeLoading || this.confirmPracticeLoading;
    },
    showRequestList() {
      return !this.isStudent &&
        this.event.students &&
        this.event.students.length > 0 && (
          this.event.type === 'PLACEHOLDER' ||
          this.event.type === 'PRACTICE' && this.event.status === 'REQUESTED'
        )
    },
    isPracticeRequested() {
      return this.event.type === AppointmentModel.TYPES.PRACTICE && this.event.status === 'REQUESTED'
    },
    isAppointmentPermanentlyDeleted() {
      return [AppointmentModel.TYPES.OFFTIME, AppointmentModel.TYPES.PLACEHOLDER]
        .includes(this.event.type) || this.isPracticeRequested
    },
    isPast() {
      return isPast(parseISO(this.event.start))
    },
    studentRequest() {
      if (!this.isStudent) return null
      if (!this.event.students?.length) return null
      if (!(this.event.type === "PLACEHOLDER" || this.event.type === "PRACTICE" && this.event.status === "REQUESTED")) return null;
      return this.event.students.find(student => student.id === this.currentUser.id);
    },
    lessonType() {
      if (this.isStudent && this.event.lessonType === "lesson_type.gratis_exam") {
        return this.$tc("lesson_type.exam", 1);
      }

      return this.$tc(this.event.lessonType, 1);
    },
    isWithinValidScanTimeRange() {
      const now = new Date();
      const start = subMinutes(parseISO(this.event.start), 30);
      const end = addMinutes(parseISO(this.event.start), this.event.duration + 30);
      return isWithinInterval(now, {start, end});
    },
    isTheoryExam() {
      return ["lesson_type.theory_exam", "lesson_type.theory_repeated_exam"].includes(this.event.lessonType);
    },
    isPracticeExam() {
      return ["lesson_type.exam", "lesson_type.repeated_exam", "lesson_type.gratis_exam"].includes(this.event.lessonType);
    },
    isMTLExam() {
      return this.event.lessonType === "lesson_type.mtl_exam";
    },
    isMissedOrRefunded() {
      return ["MISSED", "MISSED_PARTIALLY_REFUNDED", "MISSED_REFUNDED", "JOKER_REFUNDED"].includes(this.event.status);
    }
  },
  watch: {
    '$route': function () {
      this.fetch(this.$route.params.id)
    }
  },
  async mounted() {
    this.device = await Device.getInfo();
    this.fetch(this.$route.params.id)

    laravelEcho
      .private("school-channel." + this.currentUser.schoolId)
      .listen("AppointmentUpdate", appointment => {
        this.fetchOnlyIfEqualById(appointment)
      })
      .listen("AppointmentRestored", appointment => {
        this.fetchOnlyIfEqualById(appointment)
      })
      .listen("AppointmentDeleted", appointment => {
        if (this.isAppointmentPermanentlyDeleted) {
          if (this.isPracticeRequested) this.event.students = []
          this.showDeletedAtAlertNow()
          return
        }

        this.fetchOnlyIfEqualById(appointment)
      })

      .listen('PracticeRequestConfirmed', appointment => (this.fetchOnlyIfEqualById(appointment)))
      .listen('PracticeRequestDeclined', () => {
        this.event.students = []
        this.showDeletedAtAlertNow()
      })

      .listen('PlaceholderRequested', appointment => (this.fetchOnlyIfEqualById(appointment)))
      .listen('PlaceholderConfirmed', appointment => (this.fetchOnlyIfEqualById(appointment)))
      .listen('PlaceholderDeclined', appointment => (this.fetchOnlyIfEqualById(appointment)))

      .listen('TheoryParticipantAdded', appointment => (this.fetchOnlyIfEqualById(appointment)))
      .listen('TheoryParticipantRemoved', appointment => (this.fetchOnlyIfEqualById(appointment)))

      .listen('SimulatorSubscribed', appointment => (this.fetchOnlyIfEqualById(appointment)))
      .listen('SimulatorUnsubscribed', appointment => (this.fetchOnlyIfEqualById(appointment)))

  },
  destroyed() {
    laravelEcho.leave("school-channel." + this.currentUser.schoolId)
  },
  methods: {
    goBack() {
      if (sessionStorage.getItem('from') === 'calendar') {
        this.$router.replace({ path: "/calendar?event_id=" + this.event.id })
      } else {
        this.$router.push({ name: "students.student.agenda", params: { id: this.student.id } });
      }
    },
    onDecline() {
      if (this.isPracticeRequested) {
        this.event.students = []
        this.showDeletedAtAlertNow()
        return
      }

      this.fetch(this.event.id)
    },
    onDeleted() {
      if (this.isAppointmentPermanentlyDeleted) {
        this.showDeletedAtAlertNow()
        return
      }

      this.fetch(this.event.id)
    },
    fetchOnlyIfEqualById(appointment) {
      if (appointment.id !== this.eventId) return
      this.fetch(this.eventId)
    },
    fetch(eventId) {
      this.loading = true;
      appointmentService
        .get(eventId)
        .then((response) => {
          this.event = response.data || {};
        })
        .catch((error) => {
          const response = error.response || {};
          const {status, data} = response;

          this.$snackbar.show({message: data ? data.message : '', color: 'error', timeout: 5000,});

          if (status === 404) this.$router.push({name: 'dashboard'});
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showDeletedAtAlertNow() {
      this.event.deletedAt = new Date()
      this.event.isTrashed = true;
    },
    showPassedBtn(student) {
      return !student.isDeleted &&
        !this.isMissedOrRefunded &&
        (this.isTheoryExam || this.isPracticeExam || this.isMTLExam);
    },
    markAsPassedExam(student) {
      let type = "";
      if (this.isTheoryExam) type = "theory";
      if (this.isPracticeExam) type = "practice";
      if (this.isMTLExam) type = "mtl";

      if (student.passedExam) {
        showConfirm(
          this.$t("btn.confirm"),
          this.$t("messages.are_you_sure_to_remove_passed_exam_status"),
          () => this.removePassedExamStatus(student, type));

        return;
      }

      this.loadingPassedExamBtn = student.id;
      studentsService
        .studentExamsProgress(student.id, { license: this.event.licenseId })
        .then(response => {
          const responseData = response.data || {};

          if (responseData[`${type}ExamEventId`] || responseData[`${type}ExamPassedAt`]) {
            showConfirm(
              this.$t("btn.confirm"),
              this.$t("messages.when_confirm_exam_will_pass_to_another_appointment"),
              () => this.setExamAsPassed(student, type)
            ).then(confirmation => {
              if (!confirmation) this.loadingPassedExamBtn = null;
            });
          } else {
            this.setExamAsPassed(student, type);
          }
        })
        .catch(error => {
          const responseData = error.response?.data || {};

          if (responseData.message) {
            this.$snackbar.error(responseData.message);
          }

          this.loadingPassedExamBtn = null;
        });
    },
    setExamAsPassed(student, examType = "") {
      studentsService
        .studentExamResults(student.id, {
          educationType: examType + "_exam",
          licenseId: this.event.licenseId,
          eventId: this.event.id,
          passedAt: this.event.start
        })
        .then(() => {
          const studentIndex = this.event.students.findIndex(s => s.id === student.id);

          if (studentIndex !== -1) {
            this.event.students[studentIndex].passedExam = true;
          }
        })
        .catch(error => {
          const responseData = error.response?.data || {};

          if (responseData.message) {
            this.$snackbar.error(responseData.message);
          }
        })
        .finally(() => {
          this.loadingPassedExamBtn = null;
        });
    },
    removePassedExamStatus(student, type) {
      this.loadingPassedExamBtn = student.id;
      studentsService
        .resetEducationStatus(student.id, { educationType: type + "_exam", licenseId: this.event.licenseId })
        .then(() => {
          const studentIndex = this.event.students.findIndex(s => s.id === student.id);

          if (studentIndex !== -1) {
            this.event.students[studentIndex].passedExam = false;
          }
        })
        .finally(() => {
          this.loadingPassedExamBtn = null;
        });
    }
  },
};
</script>

<style scoped lang="scss">
.basic-info:empty {
  padding: 0;
}
</style>
