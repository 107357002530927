import AppointmentNotification from '@/notifications/events/appointmentNotification'

export default class OfftimeCreatedNotification extends AppointmentNotification {
  icon() {
    return "mdi-calendar";
  }

  wildCardsData() {
    // const data = this.notification.data;
    // const eventType = i18n.t(`event_type.${data.type}`);

    return {
      title: {},
      message: {},
    };
  }
}
