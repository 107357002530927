<template>
  <v-dialog
    v-model="show"
    width="1300"
    persistent
    @keydown.esc="close"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    scrollable>
    <v-card loading>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>
          {{ $t("label.create_or_edit_administrator", {createOrEdit: isEdit ? $t("label.edit") : $t("label.create")})}}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="mt-5" style="height: 100%">
        <div class="d-flex justify-center pt-5" v-if="loadingItem">
          <v-progress-circular color="primary" indeterminate />
        </div>

        <ValidationObserver ref="form" v-else>
          <v-row class="ma-0">
            <v-col cols="12" sm="12" md="4" lg="4">
              <v-row no-gutters>
                <v-col cols="12" class="d-flex justify-center mt-3">
                  <UploadBtn
                    @file-picked="onFilePick"
                    accept="image/jpeg,image/png"
                    :btn-name="(this.imageAvatar ? 'Change' : 'Upload') + ' photo'"
                    input-name="avatar"
                  >
                    <v-avatar class="elevation-2" size="150">
                      <v-img v-if="avatarImage" :src="avatarImage"></v-img>
                      <v-icon v-else size="100">mdi-account</v-icon>
                    </v-avatar>
                  </UploadBtn>
                </v-col>
              </v-row>

              <div class="mb-3">
                <span class="text-subtitle-1 font-weight-medium">{{
                  $t("label.profile")
                }}</span>
                <v-divider />
              </div>
              <v-row>
                <v-col cols="12" sm="12">
                  <ValidationProvider v-slot="{ errors }" name="email">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.email"
                      :label="$t('form.email')"
                      :placeholder="$t('form.email')"
                      type="email"
                      required
                      :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <ValidationProvider v-slot="{ errors }" name="password">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.password"
                      :label="$t('form.password')"
                      :placeholder="$t('form.password')"
                      type="text"
                      required
                      :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-switch
                    class="ml-1 mt-0"
                    dense
                    v-model="form.active"
                    inset
                    :label="$t('form.active')"
                    placeholder=""
                    color="primary"></v-switch>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="12" md="8" lg="8">
              <div class="mb-3">
                <span class="text-subtitle-1 font-weight-medium">{{
                  $t("label.personal_info")
                }}</span>
                <v-divider />
              </div>

              <v-row>
                <v-col cols="12" sm="6" md="12" lg="6">
                  <ValidationProvider v-slot="{ errors }" name="firstName">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.firstName"
                      :label="$t('form.first_name')"
                      :placeholder="$t('form.first_name')"
                      :error-messages="errors"
                      required></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6" md="12" lg="6">
                  <ValidationProvider v-slot="{ errors }" name="lastName">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.lastName"
                      :label="$t('form.last_name')"
                      :placeholder="$t('form.last_name')"
                      :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" sm="12">
                  <ValidationProvider v-slot="{ errors }" name="birthDate">
                    <v-menu
                      v-model="menuBirthDate"
                      :close-on-content-click="false"
                      :nudge-bottom="-20"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          :value="birthDateFormat"
                          :label="$t('form.birthday')"
                          :placeholder="$t('form.birthday')"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          :error-messages="errors"
                          clearable
                          @click:clear="form.birthDate = ''" />
                      </template>
                      <v-date-picker
                        ref="birthDatePicker"
                        @input="onInputBirthDate"
                        :value="minDateOfBirthFromToday"
                        no-title
                        scrollable
                        :max="minDateOfBirthFromToday" />
                    </v-menu>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <div class="mb-3">
                <span class="text-subtitle-1 font-weight-medium">{{
                  $t("label.contact")
                }}</span>
                <v-divider />
              </div>

              <v-row>
                <v-col cols="12" sm="12">
                  <ValidationProvider v-slot="{ errors }" name="phone">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.phone"
                      :label="$t('form.phone_mobile')"
                      :placeholder="$t('form.phone_mobile')"
                      type="phone"
                      required
                      :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <div class="mb-3">
                <span class="text-subtitle-1 font-weight-medium">{{
                  $t("label.address")
                }}</span>
                <v-divider />
              </div>

              <v-row dense>
                <v-col cols="12" sm="6" md="5">
                  <ValidationProvider v-slot="{ errors }" name="street">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.street"
                      :label="$t('form.street')"
                      :placeholder="$t('form.street')"
                      type="text"
                      required
                      :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <ValidationProvider v-slot="{ errors }" name="houseNr">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.houseNr"
                      :label="$t('form.house')"
                      :placeholder="$t('form.house')"
                      type="text"
                      required
                      :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <ValidationProvider v-slot="{ errors }" name="postcode">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.postcode"
                      :label="$t('form.plz')"
                      :placeholder="$t('form.plz')"
                      type="text"
                      required
                      :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <ValidationProvider v-slot="{ errors }" name="city">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.city"
                      :label="$t('form.city')"
                      :placeholder="$t('form.city')"
                      type="text"
                      required
                      :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <div class="mb-3">
                <span class="text-subtitle-1 font-weight-medium">{{
                  $t("label.general")
                }}</span>
                <v-divider />
              </div>

              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" name="officeId">
                    <v-select
                      dense
                      v-model="form.officeId"
                      :label="$t('form.office')"
                      :placeholder="$t('form.office')"
                      outlined
                      :items="officesOptions"
                      required
                      :error-messages="errors"
                      clearable></v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>
      <v-divider class="mt-auto" />
      <v-card-actions v-if="!loadingItem">
        <v-btn class="mr-2" text @click="close">{{ $t("btn.close") }}</v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          @click="save"
          :loading="loadingForm"
          :disabled="loadingForm || loadingItem"
        >
          <v-icon left>mdi-content-save{{isEdit ? '-edit' : ''}}</v-icon>
          {{ $t("btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import administratorsService from "@/services/administratorsService";
import UploadBtn from "@/components/UploadBtn";
import { showConfirm } from '@/helpers/dialogs'
import { format, sub } from 'date-fns'

export default {
  name: "AdministratorFormDialog",
  components: {
    UploadBtn,
  },
  data: () => ({
    show: false,
    isEdit: false,
    item: null,
    initialForm: {},
    avatarImage: "",
    offices: [],
    username: "",
    form: {
      avatar: "",
      firstName: "",
      lastName: "",
      birthDate: "",
      email: "",
      phone: "",
      password: "",
      city: "",
      street: "",
      houseNr: "",
      postcode: "",
      active: true,
      officeId: "",
    },
    menuBirthDate: false,
    loadingItem: false,
    loadingForm: false,
    imageUrl: "",
    imageFile: "",
  }),
  created() {
    this.initialForm = { ...this.form };
  },
  watch: {
    menuBirthDate (val) {
      val &&
      setTimeout(() => (this.$refs.birthDatePicker.activePicker = "YEAR"));
    },
  },
  computed: {
    birthDateFormat() {
      return this.form.birthDate
        ? format(new Date(this.form.birthDate), "dd.MM.yyyy")
        : "";
    },
    minDateOfBirthFromToday() {
      return format(sub(new Date(), { years: 18 }), "yyyy-MM-dd");
    },
    imageAvatar() {
      if (typeof this.form.avatar === "string") return this.form.avatar;

      return this.imageURL;
    },
    officesOptions() {
      return this.offices.map((office) => ({
        text: office.address,
        value: office.id,
      }));
    },
  },
  methods: {
    onInputBirthDate(value) {
      this.form.birthDate = value;
      this.menuBirthDate = false;
    },
    open(item) {
      this.show = true;

      this.loadFormData();

      if (item) {
        this.isEdit = true;
        this.loadingItem = true;

        administratorsService
          .loadItem(item.id)
          .then((resp) => {
            const data = resp.data;

            this.item = resp.data;

            this.username = data.username;

            this.form.firstName = data.firstName;
            this.form.lastName = data.lastName;
            this.form.birthDate = data.birthDate;
            this.form.email = data.email;
            this.form.phone = data.phone;
            this.form.active = data.active;
            this.form.city = data.city;
            this.form.street = data.street;
            this.form.houseNr = data.houseNr;
            this.form.postcode = data.postcode;
            this.form.officeId = data.officeId ? data.officeId : "";

            this.avatarImage = data.avatar;
          })
          .catch(console.log)
          .finally(() => {
            this.loadingItem = false;
          });
      }
    },
    close() {
      this.show = false;

      setTimeout(() => {
        this.isEdit = false;
        this.form = { ...this.initialForm };
        this.avatarImage = "";
        this.item = null;
        this.$refs.form.reset();
      }, 200);
    },
    loadFormData() {
      administratorsService
        .loadFormData()
        .then((resp) => {
          this.offices = resp.data.offices;
        })
        .catch(console.log);
    },
    save() {
      const formData = new FormData();

      Object.keys(this.form).forEach((field) => {
        const value = this.form[field];

        if (!value) return;

        if (Array.isArray(value)) {
          value.map((item) => formData.append(field + "[]", item));
        } else {
          formData.set(field, value);
        }
      });

      if (this.item) {
        formData.set("user_id", this.item.userId);
        formData.set("_method", "PUT");
      }

      this.loadingForm = true;
      this[this.isEdit ? "update" : "store"](formData)
        .catch((error) => {
          const responseData = error.response.data;
          const errors = responseData.errors || [];

          if (responseData.error === 'email_in_use_by_deleted_user') {
            showConfirm(
                this.$t('label.email_address_conflict'),
                this.$t('messages.user_with_same_email_already_exists', {userType: this.$t('user_type.school-administrator')}),
                () => {
                  this.form.releaseUsedEmail = true
                  this.save()
                },
            )
            return
          }

          if (Object.keys(errors).length === 0 && responseData.message)
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });

          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    store(data) {
      return administratorsService
        .create(data)
        .then((resp) => {
          this.$emit("create", resp.data);
          this.close();
        })
    },
    update(data) {
      return administratorsService
        .update(this.item.id, data)
        .then((resp) => {
          this.$emit("update", resp.data);
          this.close();
        })
    },
    onFilePick(url, file) {
      this.avatarImage = url;
      this.form.avatar = file;
    },
  },
};
</script>

<style scoped>
.btn__color-picker {
  border: 1px solid var(--v-primary-base);
  border-radius: 4px;
  padding: 5px;
}
</style>
