<template>
<div>
  <template-overall-progress-by-student-license
    v-if="!loading && detailedProgress.insightsTemplateId"
    :average-score="detailedProgress.insightsAverageScore"
    :to="{ name: isStudent ? 'student.education.license.insights' : 'students.student.education.license.insights' }"
    :overall-progress="detailedProgress.insightsOverallProgress"
  />

  <v-row>
    <v-col cols="12">
      <EducationOverallProgress :loading="loading" :progress="detailedProgress.totalProgress"/>
    </v-col>
  </v-row>

  <v-row v-if="!loading" class="mt-1" dense>
    <v-col cols="12" :lg="hasExams ? '6' : '12'">
      <EducationTheoryProgress :progress="detailedProgress" :license-id="$route.params.licenseId"/>
    </v-col>
    <v-col v-if="hasExams" cols="12" lg="6">
      <EducationPracticeProgress
          v-if="license.forPractice"
          :progress="detailedProgress"
          :licenseId="$route.params.licenseId"
          :loading="loading"
      />
      <EducationProgressArea
          :progress="detailedProgress.examResults"
          :student-id="student.id"
          :license-id="$route.params.licenseId"
          @emitExamPassed="setExamStatus"
      />
    </v-col>
  </v-row>

  <EducationTabContentLoader
      v-else
      :has-exams="hasExams"
      :basicTopicsCount="basicTopicsCount.length"
      :specialTopicsCount="specialTopicsCount.length"
  />
</div>
</template>

<script>
import EducationProgressArea from '@/components/student/progress/EducationProgressArea.vue'
import EducationTheoryProgress from '@/components/student/progress/EducationTheoryProgress.vue'
import EducationTabContentLoader from '@/components/skeleton-loaders/EducationTabContentLoader.vue'
import EducationPracticeProgress from '@/components/student/progress/EducationPracticeProgress.vue'
import TheoryTopicModel from '@/store/models/TheoryTopicModel'
import LicenseModel from '@/store/models/LicenseModel'
import studentsService from '@/services/studentsService'
import StudentModel from '@/store/models/StudentModel'
import user from '@/utils/mixins/user'
import responsive from '@/utils/mixins/responsive'
import TemplateOverallProgressByStudentLicense from "@/components/insights/TemplateOverallProgressByStudentLicense.vue";
import EducationOverallProgress from '@/components/student/progress/EducationOverallProgress.vue'

export default {
  name: 'StudentEducationLicense',
  mixins: [user, responsive],
  components: {
    EducationOverallProgress,
    TemplateOverallProgressByStudentLicense,
    EducationPracticeProgress,
    EducationTabContentLoader,
    EducationTheoryProgress,
    EducationProgressArea
  },
  data() {
    return {
      loadingStudentLicenseProgress: false,
      detailedProgress: {},
      licenseId: null,
      theoryExamPassed: false,
      practiceExamPassed: false,
    }
  },
  computed: {
    loading() {
      return this.$store.state.entities.appointments.loading || this.loadingStudentLicenseProgress;
    },
    student () {
      return this.isStudent ? this.currentUser : StudentModel.find(this.$route.params.id)
    },
    license () {
      return LicenseModel.find(this.$route.params.licenseId) || {}
    },
    basicTopicsCount () {
      return TheoryTopicModel.query()
          .where('type', 'basic')
          .where(item => item.licensesIds.includes(this.$route.params.licenseId))
          .orderBy('orderIndex', 'asc')
          .get()
    },
    specialTopicsCount () {
      return TheoryTopicModel.query()
          .where('type', 'special')
          .where(item => item.licensesIds.includes(this.$route.params.licenseId))
          .orderBy('orderIndex', 'asc')
          .get()
    },
    hasExams() {
      return this.license.forPractice || this.license.forTheoryExam
    }
  },
  created () {
    this.loadDetailedProgress()
  },
  methods: {
    setExamStatus (val) {
      this.theoryExamPassed = !!val.theoryExamPassedAt
      this.practiceExamPassed = !!val.practiceExamPassedAt
    },
    loadDetailedProgress () {
      const studentId = this.isStudent ? this.currentUser.id : this.$route.params.id

      this.loadingStudentLicenseProgress = true;
      studentsService
          .studentProgressByLicense(studentId, this.$route.params.licenseId, { extended: true })
          .then((response) => this.detailedProgress = response.data)
        .finally(() => this.loadingStudentLicenseProgress = false)
    }
  }
}
</script>

<style scoped>

</style>
