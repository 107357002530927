<template>
  <v-row>
    <v-col :cols="mdLandscapeAndUp ? 5 : 12">
      <DateField :model="model" :disabled="disabled" />
    </v-col>
    <v-col :cols="mdLandscapeAndUp ? 3 : 5">
      <TimePicker
        :disabled="disabled"
        fieldType="startTime"
        :label="$t('label.start')"
        :model="model"
        :eventDuration="this.eventDuration"
        :adaptiveTime="adaptiveTime" />
    </v-col>
    <v-col
      :cols="mdLandscapeAndUp ? 1 : 2"
      :class="`link ${disabled ? 'disabled' : ''}`">
      <v-icon color="black"
        >{{ adaptiveTime ? "mdi-link" : "mdi-link-off" }} /
      </v-icon>
      <div class="line" />
    </v-col>
    <v-col :cols="mdLandscapeAndUp ? 3 : 5">
      <TimePicker
        :disabled="disabled"
        fieldType="endTime"
        :label="$t('label.end')"
        :model="model"
        :eventDuration="this.eventDuration"
        :adaptiveTime="adaptiveTime" />
    </v-col>
  </v-row>
</template>

<script>
import TimePicker from "@/components/calendar/TimePicker";
import DateField from "@/components/calendar/eventModal/fields/DateField";

import responsive from "@/utils/mixins/responsive";
import { mapGetters } from "vuex";

export default {
  name: "TimeFields",
  mixins: [responsive],
  components: { TimePicker, DateField },
  data() {
    return {
      visiblePicker: false,
      pickedHour: null,
    };
  },
  props: {
    eventDuration: {
      type: Number,
      required: true,
    },
    adaptiveTime: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
  },
};
</script>

<style lang="scss" scoped>
.link {
  text-align: center;
  &.disabled {
    opacity: 0.4;
  }
  i {
    margin-top: 5px;
    border: 1px solid #9e9e9e;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 4px;
    background: #fff;
    font-size: 1.3em;
    z-index: 5;
  }
  .line {
    border-bottom: 1px solid #9e9e9e;
    width: 300%;
    position: relative;
    left: -100%;
    top: -15px;
    z-index: 3;
  }
}
</style>
