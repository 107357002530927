import { Model } from "@vuex-orm/core";

export default class ChildModel extends Model {
  static entity = "children";

  static fields() {
    return {
      id: this.attr(null),
      name: this.string(""),
      avatar: this.attr(null),
      school: this.string(""),
      balance: this.string(""),
      isBookandpay: this.boolean(false),
      is18yo: this.boolean(false),
      isPermissionGranted: this.boolean(false),
      privacyPolicyAccepted: this.boolean(false),
    };
  }
}
