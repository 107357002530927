import { parseISO, getTime } from "date-fns";

export default class Teacher {
  constructor(teacherData, events) {
    Object.assign(this, { ...teacherData });
    this.events = events;
  }

  eventsByDate(date) {
    const eventsList = this.events.filter((event) => event.date === date);
    const sortedEvents = eventsList.sort(
      (a, b) =>
        parseISO(`${a.date} ${a.startTime}`) -
        parseISO(`${b.date} ${b.startTime}`)
    );
    this.adjustOverlappingEvents(sortedEvents);
    return sortedEvents;
  }

  adjustOverlappingEvents = (sortedEvents) => {
    const groupsList = this.groupOverlappingEventElements(sortedEvents);
    groupsList.forEach((curGroups) => {
      curGroups.forEach((curGroup, groupIndex) => {
        const newWidth = curGroups.length > 1 ? 100 / curGroups.length : 95;
        const newLeft = groupIndex * newWidth;
        curGroup.forEach((group) => {
          group.render.width = newWidth;
          group.render.left = newLeft;
        });
      });
    });
  };

  groupOverlappingEventElements(sortedEvents) {
    let lastEndTime = new Date(0, 0, 0);
    var groups = [];
    let curGroups = [];
    sortedEvents.map((event) => {
      if (curGroups.length > 0) {
        if (getTime(lastEndTime) <= getTime(event.startDate)) {
          groups.push(curGroups);
          curGroups = [];
        }
      }

      for (let groupIndex = 0; groupIndex < curGroups.length; groupIndex++) {
        if (curGroups[groupIndex].length > 0) {
          if (
            getTime(
              curGroups[groupIndex][curGroups[groupIndex].length - 1].endDate
            ) <= getTime(event.startDate)
          ) {
            curGroups[groupIndex].push(event);
            if (getTime(lastEndTime) < getTime(event.endDate)) {
              lastEndTime = event.endDate;
            }
            return;
          }
        }
      }

      curGroups.push([event]);
      if (getTime(lastEndTime) < getTime(event.endDate)) {
        lastEndTime = event.endDate;
      }
    });
    if (curGroups.length > 0) {
      groups.push(curGroups);
    }
    return groups;
  }
}
