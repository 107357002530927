import { Model } from "@vuex-orm/core";
import CategoryModel from "./CategoryModel";

export default class ItemModel extends Model {
  static entity = "InsightsItems"

  static fields() {
    return {
      id: this.attr(null),
      category: this.belongsTo(CategoryModel, "categoryId"),
      categoryId: this.attr(null),
      name: this.string(""),
      description: this.attr(null),
      order: this.number(0),
      color: this.string(""),
      icon: this.string(""),
    };
  }
}
