<template>
  <v-list-group
      v-model="expanded"
      no-action
      eager
      :disabled="student.isDeleted"
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('route.clickAndLearn') }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action-text>
          <v-chip label x-small :color="activeLabelColor" dark>{{ activeLabelText }}</v-chip>
        </v-list-item-action-text>
      </template>

      <template v-if="student.clickAndLearnEnabled">
        <v-sheet v-if="loading" class="pa-5 d-flex align-center justify-center">
          <v-progress-circular indeterminate size="40" color="primary" />
        </v-sheet>

        <template v-else>
          <v-subheader>{{ $t('clickandlearn.status_and_progress') }}</v-subheader>

          <v-list-item two-line dense :disabled="student.isDeleted">
            <v-list-item-icon><v-icon>mdi-key</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-monospace">{{ license.code}}</v-list-item-title>
              <v-list-item-subtitle>{{ $t('clickandlearn.valid_until') }} {{ license.status.validUntil }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="mt-3 mb-1" />

          <v-list-item dense :disabled="student.isDeleted">
            <v-list-item-content>
              <v-list-item-title>{{ $t('clickandlearn.first_license') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon :color="license.progress.erstErwerb ? 'green' : 'orange'">{{ license.progress.erstErwerb ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-list-item two-line dense :disabled="student.isDeleted">
            <v-list-item-content>
              <v-list-item-title>{{ $t('clickandlearn.classes') }}</v-list-item-title>
              <v-list-item-subtitle>{{ license.progress.classes }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line dense :disabled="student.isDeleted">
            <v-list-item-content>
              <v-list-item-title>{{ $t('clickandlearn.learning_by_topics') }}</v-list-item-title>
              <v-list-item-subtitle>{{ license.progress.topics }}%</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-progress-circular
                size="20"
                :indeterminate="loading"
                :value="license.progress.topics"
                :color="colorByPercentage(license.progress.topics)"
              />
            </v-list-item-action>
          </v-list-item>

          <v-list-item two-line dense :disabled="student.isDeleted">
            <v-list-item-content>
              <v-list-item-title>{{ $t('clickandlearn.learning_by_flashcards') }}</v-list-item-title>
              <v-list-item-subtitle>{{ license.progress.box }}%</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-progress-circular
                size="20"
                :indeterminate="loading"
                :value="license.progress.box"
                :color="colorByPercentage(license.progress.box)"
              />
            </v-list-item-action>
          </v-list-item>

          <v-list-item two-line dense :disabled="student.isDeleted">
            <v-list-item-content>
              <v-list-item-title>{{ $t('clickandlearn.exam_simulation') }}</v-list-item-title>
              <v-list-item-subtitle>{{ license.progress.exams }}%</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-progress-circular
                size="20"
                :indeterminate="loading"
                :value="license.progress.exams"
                :color="colorByPercentage(license.progress.exams)"
              />
            </v-list-item-action>
          </v-list-item>

          <v-subheader>{{ $t('clickandlearn.actions') }}</v-subheader>

          <DemoModeToolTipWrapper :disabled="!isDemoModeEnabled" :tooltipText="$t('label.demo_mode_inactive_button')">
            <v-list-item
              v-if="license.isRenewable"
              :disabled="student.isDeleted || isDemoModeEnabled"
              dense
              link
              two-line
              @click="openRenewLicenseDialog"
            >
              <v-list-item-icon>
                <v-icon :disabled="isDemoModeEnabled">mdi-key-arrow-right</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('clickandlearn.renew_license') }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('clickandlearn.number_of_renewals') }}: {{ license.status.numberOfRenewals }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action-text>-1 cr.</v-list-item-action-text>
            </v-list-item>
          </DemoModeToolTipWrapper>

          <DemoModeToolTipWrapper :disabled="!isDemoModeEnabled" :tooltipText="$t('label.demo_mode_inactive_button')">
            <v-list-item
              link
              two-line
              dense
              v-if="license.isRevokable"
              @click="handleRevokeLicense"
              :disabled="student.isDeleted || isDemoModeEnabled"
            >
              <v-list-item-icon>
                <v-icon :disabled="isDemoModeEnabled">mdi-key-remove</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('clickandlearn.revoke_license') }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('clickandlearn.revokable_until') }} {{ license.revokableUntil }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action-text>+3 cr.</v-list-item-action-text>
            </v-list-item>
          </DemoModeToolTipWrapper>

          <DemoModeToolTipWrapper :disabled="!isDemoModeEnabled" :tooltipText="$t('label.demo_mode_inactive_button')">
            <v-list-item link @click="openChangePasswordDialog" :disabled="student.isDeleted || isDemoModeEnabled">
              <v-list-item-icon>
                <v-icon :disabled="isDemoModeEnabled">mdi-form-textbox-password</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('clickandlearn.change_password') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </DemoModeToolTipWrapper>
        </template>
      </template>
      <template v-else>
        <v-subheader>{{ $t('clickandlearn.when_new_license') }}</v-subheader>
        <DemoModeToolTipWrapper :disabled="!isDemoModeEnabled" :tooltipText="$t('label.demo_mode_inactive_button')">
          <v-list-item @click="openRegisterLicenseDialog" :disabled="student.isDeleted || isDemoModeEnabled">
            <v-list-item-icon>
              <v-icon :disabled="isDemoModeEnabled">mdi-key-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('clickandlearn.register_license') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action-text>-3 cr.</v-list-item-action-text>
          </v-list-item>
        </DemoModeToolTipWrapper>
        <v-subheader>{{ $t('clickandlearn.when_existing_license') }}</v-subheader>
        <DemoModeToolTipWrapper :disabled="!isDemoModeEnabled" :tooltipText="$t('label.demo_mode_inactive_button')">

          <v-list-item @click="openAddExistingLicenseDialog" :disabled="student.isDeleted || isDemoModeEnabled">
            <v-list-item-icon>
              <v-icon :disabled="isDemoModeEnabled">mdi-key-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('clickandlearn.add_existing_license') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </DemoModeToolTipWrapper>
      </template>
      <RegisterLicenseDialog ref="registerLicenseDialog" @license-registered="handleLicenseRegistered" />
      <AddExistingLicenseDialog ref="addExistingLicenseDialog" @license-added="handleLicenseRegistered" />
      <RenewLicenseDialog ref="renewLicenseDialog" @license-renewed="handleLicenseRenewed" />
      <ChangePasswordDialog ref="changePasswordDialog" />
    </v-list-group>
</template>

<script>
import clickAndLearnService from "@/services/clickAndLearnService";
import {showConfirm} from "@/helpers/dialogs";
import StudentModel from "@/store/models/StudentModel";
import RegisterLicenseDialog from "@/components/clickandlearn/RegisterLicenseDialog.vue";
import RenewLicenseDialog from "@/components/clickandlearn/RenewLicenseDialog.vue";
import ChangePasswordDialog from "@/components/clickandlearn/ChangePasswordDialog.vue";
import AddExistingLicenseDialog from "@/components/clickandlearn/AddExistingLicenseDialog.vue";
import DemoModeToolTipWrapper from '@/views/DemoModeToolTipWrapper.vue'

export default {
  name: "ClickAndLearnWidget",
  components: {
    DemoModeToolTipWrapper,
    AddExistingLicenseDialog, ChangePasswordDialog, RenewLicenseDialog, RegisterLicenseDialog},
  props: {
    student: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      expanded: false,
      loading: false,
      license: {
        id: null,
        code: null,
        assignedAt: null,
        progress: {
          topics: 0,
          box: 0,
          exams: 0,
          classes: "",
          erstErwerb: false
        },
        status: {
          validUntil: null,
          numberOfRenewals: null
        },
        isRevokable: false,
        isRenewable: false
      }
    }
  },
  mounted () {
    this.loadData()
  },
  computed: {
    isDemoModeEnabled () {
      return this.$store.state.school.isDemoModeEnabled
    },
    activeLabelColor () {
      return this.student.clickAndLearnEnabled ? 'green' : 'red'
    },
    activeLabelText () {
      return this.student.clickAndLearnEnabled ? this.$t('label.enabled') : this.$t('label.disabled')
    }
  },
  methods: {
    loadData() {
      if (this.student.clickAndLearnEnabled) {
        this.loading = true
        clickAndLearnService
          .getLicense(this.student.clickAndLearnLicenseId)
          .then(response => {
            this.license = response.data
          })
          .catch(error => {
            const responseData = error.response.data || {};
            if (responseData.code === 'clickandlearn.license.not.assigned') {
              StudentModel.update({
                where: this.student.id,
                data: {
                  clickAndLearnEnabled: false,
                  clickAndLearnLicenseId: null,
                }
              })
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    openRegisterLicenseDialog() {
      if (this.license.id) return;

      this.$refs.registerLicenseDialog.open(this.student)
    },
    openAddExistingLicenseDialog() {
      if (this.license.id) return;

      this.$refs.addExistingLicenseDialog.open(this.student)
    },
    openRenewLicenseDialog() {
      if (! this.license.id || ! this.license.isRenewable) return;

      this.$refs.renewLicenseDialog.open(this.license)
    },
    openChangePasswordDialog() {
      if (! this.license.id) return;

      this.$refs.changePasswordDialog.open(this.license)
    },
    handleRevokeLicense() {
      if (! this.license.id || ! this.license.isRevokable) return;

      showConfirm(this.$t('clickandlearn.revoke_license'), this.$t('clickandlearn.revoke_license_confirm'),() => {
        this.loading = true
        clickAndLearnService
          .revokeLicense(this.license.id)
          .then(() => {
            this.license = {
              id: null,
              code: null,
              assignedAt: null,
              progress: {
                topics: 0,
                box: 0,
                exams: 0,
                classes: "",
                erstErwerb: false
              },
              status: {
                validUntil: null,
                numberOfRenewals: null
              },
              isRevokable: false,
              isRenewable: false
            }
            StudentModel.update({
              where: this.student.id,
              data: {
                clickAndLearnEnabled: false,
                clickAndLearnLicenseId: null,
              }
            })
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    handleLicenseRegistered(event) {
      this.loading = true
      this.$snackbar.show({
        message: event.message,
        timeout: 3000,
        color: "success"
      })
      StudentModel.update({
        where: this.student.id,
        data: {
          clickAndLearnEnabled: true,
          clickAndLearnLicenseId: event.data.id,
        }
      }).then(() => {
        this.loadData()
      })
    },
    handleLicenseRenewed() {
      this.loadData()
    },
    colorByPercentage(percentage) {
      if (percentage === 0 || percentage === null) return 'grey';

      return percentage < 50 ? 'red' : (percentage < 80 ? 'orange' : 'green');
    },
  }
}
</script>

<style scoped>

</style>
