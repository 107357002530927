import { render, staticRenderFns } from "./UpcomingAppointments.vue?vue&type=template&id=940e449c&scoped=true"
import script from "./UpcomingAppointments.vue?vue&type=script&lang=js"
export * from "./UpcomingAppointments.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "940e449c",
  null
  
)

export default component.exports