<template>
  <div class="time-indicator" :style="`left: ${position}px`"></div>
</template>

<script>

import { differenceInMinutes, startOfDay } from 'date-fns'
import { mapGetters } from 'vuex'
import timeLineMixin from '@/utils/mixins/timeLineMixin'

export default {
  name: "TimeIndicator",
  mixins: [timeLineMixin],
  computed: {
    ...mapGetters('vehiclesCalendar', ['oneMinuteSize']),
    indicatorPosition() {
      return differenceInMinutes(new Date(), startOfDay(new Date())) * this.oneMinuteSize
    }
  },
}
</script>

<style lang="scss" scoped>
.time-indicator{
  width: 1px;
  height: 100vh;
  z-index: 2;
  background: red;
  position: absolute;
  top: 40px;
  left: 0;
}
</style>
