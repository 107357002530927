<template>
  <v-col>
    <div class="theory-subscribers" :class="`${disabled ? 'disabled' : ''}`">
      <div v-if="model.lessonTypeGroup === 'lesson'" class="label">
        {{ `${$t("label.participants")} ${students.length}/${model.roomSize}` }}
      </div>
      <div class="subscribers-wrapper pa-1">
        <v-list class="pa-0">
          <v-list-item
            v-for="student in students"
            :key="student.studentId"
            class="pl-1 pr-1 item">
            <v-list-item-content class="pa-0">
              <v-list-item-title>
                <v-icon :color="student.isPresent ? 'green' : ''">{{
                  student.isPresent ? "mdi-account-check" : "mdi-account"
                }}</v-icon>
                {{ `${student.fullName}` }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="ma-0">
              <div class="d-flex justify-space-between">
                <div class="ml-2">
                  <v-btn
                    v-if="approveBtnShow && !student.isPresent"
                    class="ml-1"
                    x-small
                    color="success"
                    @click="$emit('onStudentApprove', student.studentId)">
                    {{ $t("btn.confirm") }}
                  </v-btn>
                  <template v-if="!readOnly">
                    <v-btn
                      class="ml-1"
                      v-if="!disabled"
                      x-small
                      color="error"
                      @click="$emit('onStudentDecline', student.studentId)"
                      >{{ $t("btn.remove") }}</v-btn
                    >
                  </template>
                </div>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </v-col>
</template>

<script>
import user from "@/utils/mixins/user";
import { mapGetters } from "vuex";

export default {
  name: "TheoryScannedStudentsField",
  mixins: [user],
  props: {
    students: {
      type: Array,
      required: true,
    },
    temporary: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("appointments", { model: "currentAppointment" }),
    approveBtnShow() {
      if (!this.schoolSettings.isDigitalAttendanceEnabled) return;
      return (
        this.isSchoolAdministrator &&
        this.model.lessonTypeGroup === "lesson" &&
        !this.temporary
      );
    },
  },
};
</script>

<style lang="scss">
.theory-subscribers {
  border: thin solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  position: relative;
  .label {
    position: absolute;
    top: -11px;
    left: 12px;
    background: #fff;
    padding: 0 2px;
    font-size: 0.8rem;
  }
  .subscribers-wrapper {
    overflow-x: auto;
    max-height: 187px;
  }
  .item {
    min-height: 35px !important;
    border-bottom: thin solid rgba(0, 0, 0, 0.38);
    &:last-child {
      border-bottom: none;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
</style>
