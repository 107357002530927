import { Model } from "@vuex-orm/core";
import { v4 as uuid4 } from "uuid";

export default class TheoryTopicModel extends Model {
  static entity = "theoryTopics";

  static fields() {
    return {
      loading: this.boolean(false),
      id: this.uid(() => uuid4()),
      title: this.string(""),
      type: this.string(""),
      licensesIds: this.attr([]),
      orderIndex: this.string(""),
      createdAt: this.attr(new Date()),
    };
  }
}
