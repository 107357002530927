<template>
  <v-sheet outlined class="teacher-statistics-card" elevation="3" rounded>
    <v-card-title class="pb-0">{{ data.name }}</v-card-title>
    <div class="d-flex flex-row" style="width: 100%">
      <div class="flex-grow-1">
        <v-card-subtitle class="pt-0 mt-0">
          {{ $t('label.pass_rate') }}: {{ data.passRate }}%
          ({{ data.passed }}/{{ data.passed + data.failed }})
        </v-card-subtitle>
      </div>
      <apexchart
          type="pie"
          :options="chartOptions"
          :series="chartSeries"
          height="100"
          width="100"
      />
    </div>
    <v-card-actions>
      <v-btn text small @click="$refs.failedDialog.openDialog()">{{ $t('btn.details') }}</v-btn>
      <v-btn text small @click="$refs.yearlyDialog.openDialog()">{{ $t('btn.yearly_overview') }}</v-btn>
    </v-card-actions>
    <teacher-yearly-statistics-dialog ref="yearlyDialog" :teacher-id="data.teacherId" />
    <teacher-failed-exams-list-dialog ref="failedDialog" :list="data.failedList" />
  </v-sheet>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import TeacherYearlyStatisticsDialog from "@/components/exam-statistics/TeacherYearlyStatisticsDialog.vue";
import TeacherFailedExamsListDialog from "@/components/exam-statistics/TeacherFailedExamsListDialog.vue";

export default {
  name: "TeacherStatisticsCard",
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  components: {TeacherFailedExamsListDialog, TeacherYearlyStatisticsDialog, apexchart: VueApexCharts},
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'pie',
          width: 90
        },
        labels: [this.$i18n.t('label.passed'), this.$i18n.t('label.failed')],
        colors: ['#00435e', '#ff5252'],
        sparkline: {
          enabled: true
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        }
      },
    };
  },
  computed: {
    successRate() {
      return Math.round(this.data.passed * 100 / (this.data.passed + this.data.failed));
    },
    chartSeries() {
      return [this.data.passed, this.data.failed,]
    }
  }
};
</script>

<style scoped>
.teacher-statistics-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
