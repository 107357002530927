<template>
  <v-row
    :no-gutters="$vuetify.breakpoint.smAndDown"
    class="mt-2"
    :class="{ 'flex-row-reverse': message.type === 'response' }">
    <v-col
      cols="12"
      md="2"
      :class="{ 'text-right': message.type === 'reply' }"
      class="flex-row flex-md-column">
      <div class="text-body-1">{{ message.author }}</div>
      <div class="text-body-2">{{ message.createdDiffForHumans }}</div>
    </v-col>
    <v-col cols="12" md="10">
      <v-sheet
        elevation="0"
        class="pa-4"
        :color="
          message.type === 'response' ? 'grey lighten-4' : 'grey lighten-5'
        "
        rounded>
        <div class="text-body-1 preline" v-html="message.text" v-linkified />
        <div class="mt-10" v-if="message.attachments">
          <v-chip-group>
            <v-chip
              v-for="attachment in message.attachments"
              :key="attachment.id"
              :href="attachment.url"
              target="_blank">
              {{ attachment.name }}
            </v-chip>
          </v-chip-group>
        </div>
      </v-sheet>
      <div
        :class="{ 'text-right': message.type === 'reply' }"
        class="text--disabled">
        {{ message.created }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TicketMessage",
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss"></style>
