<template>
  <v-card id="workTimeCalendar">
    <v-toolbar class="px-2" elevation="3">
      <v-btn class="mr-4" icon :to="{ name: 'teachers.teacher', id: $route.params.id }" exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="title">{{ $t('label.teacher_work_time') }}</span>
    </v-toolbar>
    <v-card-text>
      <v-sheet class="toolbar" height="64">
        <v-toolbar flat>
          <v-btn
              color="grey darken-2"
              outlined
              :disabled="loading"
              @click="prev">
            <v-icon small>
              mdi-chevron-left
            </v-icon>
            {{ prevLabel }}
          </v-btn>

          <v-spacer></v-spacer>
          <div class="text-h4">{{ todayLabel }}</div>
          <v-spacer></v-spacer>

          <v-btn
              color="grey darken-2"
              outlined
              :disabled="loading"
              @click="next">
            {{ nextLabel }}
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>

        </v-toolbar>
      </v-sheet>
      <v-sheet class="calendar" height="600">

        <v-calendar
            ref="calendar"
            v-model="focus"
            :events="events"
            :type="calendar.type"
            :weekdays="calendar.weekdays"
            :show-week="calendar.showWeek"
            :weekday-format="dayCellFormat"
            :show-month-on-first="true"
            @change="calendarChange"
        >
          <template v-slot:day="{ weekday, date }">
            <WorkTImeSumSlot
                v-if="weekday === 0"
                :ref="`calendarCell-${date}`"
                :loading="loading"
                :disabled="disabled"
                :drivingWorkedMinutes="weekSum(date).drivingWorkedMinutes || 0"
                :totalWorkedMinutes="weekSum(date).totalWorkedMinutes || 0"
                @weeklyPdf="getWeeklyPdf(date)"
            />
            <WorkTimeDaySlot
                v-else
                :ref="`calendarCell-${date}`"
                :date="date"
                :loading="loading"
                :disabled="disabled"
                :holiday="mappedHolidays.get(date)"
                :drivingWorkedMinutes="dates[date] ? dates[date].drivingWorkedMinutes : 0"
                :totalWorkedMinutes="dates[date] ? dates[date].totalWorkedMinutes : 0"
                @dailyPdf="getDailyPdf(date)"
            />
          </template>

          <template v-slot:day-label-header="{}">-</template>
        </v-calendar>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>

import HolidayClass from "@/helpers/holiday";
import { format, parseISO, startOfWeek, endOfWeek, isWithinInterval, addMonths, min, max } from "date-fns";
import LocalizationManager from '@/helpers/LocalizationManager'
import { mapGetters } from 'vuex'
import teachersService from '@/services/teachersService'
import WorkTimeDaySlot from '@/components/teacher/WorkTimeDaySlot'
import WorkTImeSumSlot from '@/components/teacher/WorkTImeSumSlot'
import FileSaver from '@/helpers/fileSaver'
import TeacherModel from '@/store/models/TeacherModel'

export default {
  name: "TeacherWorkTime",
  components: { WorkTimeDaySlot, WorkTImeSumSlot },
  data: () => ({
    holidays: [],
    focus: '',
    currentDate: null,
    events: [],
    calendar: {
      type: 'month',
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      showWeek: true,
    },
    loading: false,
    disabled: false,
    dates: [],
  }),
  mounted () {
    this.getMonthlyWorkTimes(this.currentDate.year, this.currentDate.month)
    this.getHolidays(this.currentDate.year, this.schoolSettings.regions.split(", "))
  },
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    mappedHolidays() {
      const map = new Map()
      this.holidays.forEach((item) => {
        return map.set(item.dateString, item.translate('de'))
      })
      return map
    },
    prevLabel() {
      if (!this.currentDate) return

      return format(
          addMonths(parseISO(this.currentDate?.date), -1),
          "MMMM yyyy",
          { locale: LocalizationManager.dateFnsLocale }
      )
    },
    nextLabel() {
      if (!this.currentDate) return

      return format(
          addMonths(parseISO(this.currentDate?.date), 1),
          "MMMM yyyy",
          { locale: LocalizationManager.dateFnsLocale }
      )
    },
    todayLabel() {
      if (!this.currentDate) return

      return format(
          parseISO(this.currentDate?.date),
          "MMMM yyyy",
          { locale: LocalizationManager.dateFnsLocale }
      )
    },
  },
  methods: {
    getMonthlyWorkTimes(year, month) {
      this.loading = true
      teachersService
          .workedTime(this.$route.params.id, { year, month })
          .then((response) => {
            this.dates = response.data || []
          })
          .catch((error) => console.log(error))
          .finally(() => this.loading = false)
    },
    getHolidays(year, regions) {
      this.holidays = new HolidayClass().getAllHolidaysHolidays(year, regions)
    },
    dayCellFormat(day) {
      return day.weekday !== 0
          ? format(parseISO(day.date), 'EEEEEE', {locale: LocalizationManager.dateFnsLocale})
          : 'Summe'
    },
    weekSum(date){
      const start = startOfWeek(parseISO(date), { weekStartsOn: 1 })
      const end = endOfWeek(parseISO(date), { weekStartsOn: 1 })
      let totalForWeek = { drivingWorkedMinutes: 0, totalWorkedMinutes: 0 }

      Object.keys(this.dates).forEach((key) => {
        if (isWithinInterval(parseISO(key), { start, end })) {
          totalForWeek.drivingWorkedMinutes += this.dates[key].drivingWorkedMinutes
          totalForWeek.totalWorkedMinutes += this.dates[key].totalWorkedMinutes
        }
      })
      return totalForWeek
    },
    weekDatesArr(date) {
      const start = startOfWeek(parseISO(date), { weekStartsOn: 1 })
      const end = endOfWeek(parseISO(date), { weekStartsOn: 1 })
      let arr = []
      Object
        .keys(this.dates)
        .forEach((key) => {
          if (isWithinInterval(parseISO(key), { start, end })) arr.push(key);
        });
      return arr
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    calendarChange (val) {
      this.currentDate = val.start
      this.getHolidays(val.start.year, this.schoolSettings.regions.split(", "))
      this.getMonthlyWorkTimes(val.start.year, val.start.month)
    },
    getDailyPdf(date) {
      this.getReport([date], date)
    },
    getWeeklyPdf(date) {
      this.getReport(this.weekDatesArr(date), date)
    },
    getReport: async function (datesArr, date) {
      this.disabled = true
      teachersService
          .downloadDailyWorkTimeReport(this.$route.params.id, { dates: datesArr })
          .then(response => {
            this.saveAsFile(response.data, datesArr)
            this.close();
          })
          .catch(error => {
            const response = error.response || {}
            const responseData = response.data || {};

            if (responseData.message) {
              this.$snackbar.show({ message: responseData.message, color: "error", });
            }
          })
          .finally(() => {
            this.$refs[`calendarCell-${date}`].stop()
            this.disabled = false
          });
    },

    saveAsFile(file, dates) {
      const teacher = TeacherModel.find(this.$route.params.id)

      const parsedISODates = dates.map(date => parseISO(date));
      const minDate = min(parsedISODates);
      const maxDate = max(parsedISODates);

      let fileName = `Tagesübersicht ${teacher.fullName} ${format(minDate, "dd.MM.yyyy")}`;
      if (dates.length > 1) {
        fileName = fileName + ` ${format(maxDate, "dd.MM.yyyy")}`;
      }

      const fileSaver = new FileSaver([file])
      fileSaver.setType("application/pdf").saveToDevice(`${fileName}.pdf`);
    },
  },
};
</script>

<style lang="scss">
#workTimeCalendar {
  .v-calendar-weekly__day{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .v-calendar-weekly__day:nth-child(8) {
    background: transparent !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .v-calendar-weekly__day-label {
      display: none;
    }
  }
  .v-calendar-weekly__weeknumber{
    align-items: center;
    justify-content: center;
    padding-top: 0 !important;
  }
  .v-calendar-weekly__head-weeknumber,
  .v-calendar-weekly__head-weekday{
    background: var(--v-primary-base);
    color: #fff;
  }
  .v-calendar-weekly__day:has(.holiday-child) {
    background: rgba(255, 0, 0, 0.1) !important;
  }
}
</style>
