<template>
  <div>
    <v-menu offset-y left nudge-bottom="5">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ml-2" icon large v-bind="attrs" v-on="on" :disabled="loading || isDeleted">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-sheet>
        <v-list dense>
          <v-list-item :to="{name: 'calendar', query: {event_id: appointment.id}}" :disabled="actionsLoading || disableForInactiveTeacher">
            <v-list-item-icon class="mr-2">
              <v-icon :disabled="actionsLoading || disableForInactiveTeacher">mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('label.view_on_calendar') }}</v-list-item-title>
          </v-list-item>

          <template v-if="!isElter && appointment.type === 'PLACEHOLDER' && !isPast">
            <v-list-item
                v-if="isExactlyStudent && !appointment.isSubscribed"
                :disabled="actionsLoading"
                @click="requestPlaceholder"
            >
              <v-list-item-icon class="mr-2" :disabled="actionsLoading">
                <v-icon>mdi-calendar-question</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('btn.book') }}</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="appointment.students.length > 0" :disabled="actionsLoading" @click="declinePlaceholder">
              <v-list-item-icon class="mr-2" :disabled="actionsLoading">
                <v-icon>mdi-calendar-remove</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ isStudent && appointment.isSubscribed ? $t('btn.abandon') : $t('btn.decline') }}
              </v-list-item-title>
            </v-list-item>
          </template>

          <v-list-item v-if="canBeDeleted" @click="destroy(appointment)" :disabled="actionsLoading">
            <v-list-item-icon class="mr-2">
              <v-icon :disabled="actionsLoading">{{ appointment.type === 'PLACEHOLDER' ? 'mdi-delete' : 'mdi-archive-arrow-down' }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t(appointment.type === 'PLACEHOLDER' ? "btn.delete" : "btn.archive") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-menu>

    <AppointmentRequestDialog ref="appointmentRequestDialog" @requested="$emit('requested')"/>
  </div>
</template>

<script>
import { addDays, isPast, parseISO, isAfter, isFuture } from 'date-fns'
import user from '@/utils/mixins/user'
import appointmentService from '@/services/appointmentService'
import { mapGetters } from 'vuex'
import AppointmentRequestDialog from '@/components/appointment/AppointmentRequestDialog'
import { showConfirm } from '@/helpers/dialogs'
import AppointmentModel from '@/store/models/AppointmentModel'
import TeacherModel from '@/store/models/TeacherModel'

export default {
  name: 'AppointmentDetailsActions',
  components: { AppointmentRequestDialog },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    actionsLoading: {
      type: Boolean,
      default: false,
    },
    appointment: {
      type: Object,
      required: true,
    },
  },
  mixins: [user],
  data: () => ({
    show: false
  }),
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    isPlaceholderRequested() {
      return this.appointment.type === AppointmentModel.TYPES.PLACEHOLDER && this.appointment.isRequested;
    },
    isPracticeRequested() {
      return this.appointment.type === AppointmentModel.TYPES.PRACTICE && this.appointment.status === 'REQUESTED'
    },
    isSimulatorBooked() {
      return this.appointment.type === AppointmentModel.TYPES.SIMULATOR && this.appointment.students.length > 0
    },
    canBeDeleted() {
      return this.isExactlyStudent && this.studentCanDeleteThisEvent ||
            this.isTeacher && this.teacherCanDeleteThisEvent ||
            this.isSchoolAdministrator && this.schoolAdministratorCanDeleteThisEvent
    },
    schoolAdministratorCanDeleteThisEvent () {
      return this.isNotRequested || !this.isSimulatorBooked
    },
    teacherCanDeleteThisEvent() {
      const hasMissedStatus = ["MISSED", "MISSED_REFUNDED"].includes(this.appointment.status)

      return isFuture(parseISO(this.appointment.start)) && this.isNotRequested && !hasMissedStatus;
    },
    studentCanDeleteThisEvent() {
      const isAcceptedPractice = this.appointment.type === AppointmentModel.TYPES.PRACTICE && this.appointment.status === 'ACCEPTED'
      const { studentCanDeletePractice, nonDeletablePeriod } = this.schoolSettings;

      const startTime = parseISO(this.appointment.start);
      const deleteLimit = addDays(new Date(), nonDeletablePeriod);

      return isAcceptedPractice &&
          studentCanDeletePractice &&
          (isAfter(startTime, deleteLimit) || nonDeletablePeriod === null)
    },
    isNotRequested() {
      return !(this.isPracticeRequested || this.isPlaceholderRequested)
    },
    isPast() {
      return isPast(parseISO(this.appointment.start))
    },
    isDeleted() {
      return this.appointment.deletedAt !== null
    },
    disableForInactiveTeacher() {
      const teacher = TeacherModel.find(this.appointment.teacherId)
      if (!this.appointment.teacherId || teacher?.active) return
      const activeTeachersIds = TeacherModel.query().where('active', true).get().map(teacher => teacher.id) || []
      return !activeTeachersIds.includes(this.appointment.teacherId)
    }
  },
  methods: {
    destroy(item) {
      this.loadingAction = true;
      showConfirm(
        this.$t('btn.confirm'),
        this.$t("messages.are_your_sure_archive"),
        () => {
          appointmentService
              .delete(item.id, item.type)
              .then(() => this.$emit('deleted'))
              .catch((error) => {
                const response = error.response || {};
                const { data } = response;

                this.$snackbar.show({ message: data ? data.message : '', color: 'error', timeout: 5000, });
              })
              .finally(() => {
                this.loadingAction = false;
              });
        },
        () => {
          this.loadingAction = false;
        }
      );
    },
    requestPlaceholder() {
      this.$refs.appointmentRequestDialog.open(this.appointment, this.currentUser);
    },
    declinePlaceholder () {
      showConfirm(
        this.$t('btn.confirm'),
        this.$t("messages.are_your_sure_delete"),
        () => {
          this.abandonPlaceholderLoading = true
          appointmentService.decline_placeholder_request(this.appointment.id)
              .then(() => this.$emit('requested'))
              .catch(error => {
                const responseData = error.response.data || {};

                if (responseData.message) {
                  this.$snackbar.show({ message: responseData.message, color: "error", });
                }
              })
              .finally(() => this.abandonPlaceholderLoading = false)
        }
      )
    }
  }
}
</script>

<style scoped>

</style>
