<template>
  <div>
    <template v-if="typeGroup.group === 'exam'">
      <v-row>
        <v-col>
          <LicenseField
              :disabled="true"
              :validation="true"
              :multiple="false"
              :licenseProp="currentAppointment.licenseId"
              :licenseIdsList="licensesIds" />
        </v-col>
      </v-row>
    </template>

    <template v-if="typeGroup.group === 'lesson'">
      <v-row>
        <v-col>
          <TeachersField
            :disabled="true"
            :filteredTeachers="teachers"
            :teacherProp="currentAppointment.teacherId" />
        </v-col>
        <v-col>
          <OfficesField
            :validation="false"
            :disabled="true"
            :officeIdProp="currentAppointment.officeId"
            :officesList="schoolOffices" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <TopicsField
            :disabled="true"
            :topicProp="currentAppointment.theoryTopicId"
            :topicsList="theoryTopicsList"
          />
        </v-col>
        <v-col cols="12">
          <LicenseField
            :disabled="true"
            :validation="true"
            :multiple="true"
            :licenseProp="currentAppointment.allowedLicenses"
            :licenseIdsList="licensesIds" />
        </v-col>
      </v-row>
    </template>

    <v-row>
      <v-col>
        <NotesField
          :disabled="true"
          :validation="false"
          :notesProp="currentAppointment.notes" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "@/plugins/i18n";

import user from "@/utils/mixins/user";
import isMobileApp from "@/utils/mixins/isMobileApp";

import TeachersField from "@/components/calendar/appointmentModal/fields/TeachersField";
import OfficesField from "@/components/calendar/appointmentModal/fields/OfficesField";
import TopicsField from "@/components/calendar/appointmentModal/fields/TopicsField";
import LicenseField from "@/components/calendar/appointmentModal/fields/LicenseField";
import NotesField from "@/components/calendar/appointmentModal/fields/NotesField";

import LicenseModel from "@/store/models/LicenseModel";
import OfficeModel from "@/store/models/OfficeModel";
import LessonTypeModel from "@/store/models/LessonTypeModel";
import TheoryTopicModel from "@/store/models/TheoryTopicModel";
import TeacherModel from "@/store/models/TeacherModel";
import theoryTopics from "@/utils/mixins/theoryTopics";

export default {
  name: "StudentTheoryForm",
  mixins: [isMobileApp, user, theoryTopics],
  data: () => ({
    start: new Date(),
    duration: 45
  }),
  components: {
    TeachersField,
    OfficesField,
    TopicsField,
    LicenseField,
    NotesField
  },
  computed: {
    ...mapGetters("appointments", {
      currentAppointment: "currentAppointmentGetter",
    }),
    teachers() {
      return TeacherModel.all() || []
    },
    typeGroup() {
      return LessonTypeModel.find(this.currentAppointment.lessonTypeId);
    },
    schoolOffices() {
      return OfficeModel.all() || [];
    },
    licenses() {
      return LicenseModel.query().orderBy("order", "asc").get() || [];
    },
    licensesIds() {
      const forType = this.currentAppointment.group === "exam" ? "forTheoryExam" : "forTheory";
      return LicenseModel.query().where(forType, true).get().map(license => license.id);
    },
    basicTopics() {
      return TheoryTopicModel.query().where("type", "basic").get() || [];
    },
    specialTopics() {
      return TheoryTopicModel.query().where("type", "special").get() || [];
    },
    theoryTopicsList() {
      const topicsArray = [
        { header: i18n.t("label.normal") },
        { divider: true },
        ...this.basicTopics
      ];

      const specialTopicsGroups = this.getSpecialTopicsGroups(this.specialTopics);

      specialTopicsGroups.forEach((specialTopicsGroup) => {
        return topicsArray.push(
          { header: specialTopicsGroup.header },
          { divider: true },
          ...specialTopicsGroup.topics
        );
      });

      return topicsArray;
    },
  }
};
</script>

<style lang="scss" scoped>
.skeleton-border {
  border: thin solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
}
.item {
  border-bottom: thin solid rgba(0, 0, 0, 0.38);
  &:last-child {
    border-bottom: none;
  }
}
</style>
