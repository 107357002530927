import router from "@/router";

export default class ViewEvent {
  constructor(eventId) {
    this.eventId = eventId;
  }

  run() {
    router.push({ name: "calendar.appointment", params: { id: this.eventId } });
  }
}
