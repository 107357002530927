<script>
import { defineComponent } from "vue";
import vehiclesService from '@/services/vehiclesService'

export default defineComponent({
  name: "RecyclableVehiclesCard",
  data() {
    return {
      calendarEventsImg: require("@/assets/img/vehicles.jpg"),
      count: 0,
      loading: false
    };
  },
  mounted() {
    this.loading = true;
    vehiclesService
      .getDeletedCount()
      .then((response) => {
        const responseData = response.data || {};
        this.count = responseData.count;
      })
      .finally(() => {
        this.loading = false;
      });
  },
});
</script>

<template>
  <v-card :loading="loading">
    <v-img :src="calendarEventsImg" class="align-end" height="130px" />

    <v-card-title>{{ $t("label.vehicles") }}</v-card-title>

    <v-card-subtitle class="pb-0">{{ $t("count_of_deleted", { entityName: $t("label.vehicles"), count: count }) }}
    </v-card-subtitle>

    <v-card-actions>
      <v-btn
        :disabled="loading"
        :to="{
        name: 'recycle-bin.recyclable',
        params: { recyclable: 'vehicles' },
      }"
        class="ml-auto"
        small
        text>
        {{ $t("btn.open") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>

</style>
