<template>
  <v-card-text>
    <ValidationObserver ref="form">
      <v-row dense class="mb-2">
        <v-col>
          <h4>{{ $t('label.birthday_wishes_message_heading') }}</h4>
          <p class="text--secondary mt-1">{{ $t('label.birthday_wishes_message_explanation') }}</p>
        </v-col>
      </v-row>
      <ValidationProvider v-slot="{ errors }" name="birthdayTitle">
        <v-text-field
          v-model="title"
          :disabled="loading"
          outlined
          dense
          :placeholder="$t('label.title')"
          :label="$t('label.title')"
          :error-messages="errors"
        />
      </ValidationProvider>

      <ValidationProvider v-slot="{ errors }" name="birthdayText">
        <v-textarea
          v-model="text"
          :disabled="loading"
          outlined
          dense
          rows="12"
          :placeholder="$t('label.message')"
          :label="$t('label.message')"
          :error-messages="errors"
        />
      </ValidationProvider>

      <h4>{{ $t('label.birthday_wishes_image') }}</h4>
      <p class="text--secondary mt-1">{{ $t('label.birthday_wishes_image_explanation') }}</p>
      <UploadBtn
        @file-picked="onFilePick"
        @file-delete="onFileDelete"
        accept="image/jpeg,image/png"
        input-name="birthdayImage"
        :clearable="uploadedFile && !tempImage"
        cssClass="ma-2"
      >
        <v-avatar class="elevation-2" size="150" rounded>
          <v-img v-if="uploadedFile" :src="uploadedFile" contain/>
          <v-icon v-else size="100">mdi-image-area</v-icon>
        </v-avatar>
      </UploadBtn>
    </ValidationObserver>
  </v-card-text>
</template>

<script>

import UploadBtn from '@/components/UploadBtn.vue'

export default {
  name: 'BirthdayWishes',
  components: {
    UploadBtn,
  },
  props: {
    loading: {
      type: Boolean
    },
    messageData: {
      type: Object,
    },
    uploadedFile: {
      type: String,
    },
    tempImage: {
      type: Boolean,
    },
  },
  computed: {
    title: {
      set(val) {
        this.$emit('setFieldValue', { birthdayTitle: val })
      },
      get() {
        return this.messageData.birthdayTitle
      }
    },
    text: {
      set (val) {
        this.$emit('setFieldValue', { birthdayText: val })
      },
      get () {
        return this.messageData.birthdayText
      },
    },
    image: {
      set(val) {
        this.$emit('setFieldValue', { birthdayImage: val })
      },
      get() {
        return this.messageData.birthdayImage
      }
    },
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    setErrors(errors) {
      if (errors.length === 0) return
      this.$refs.form.setErrors(errors);
    },
    onFilePick(url, file) {
      this.$emit("imagePicked", url, file);
    },
    onFileDelete() {
      this.$emit('deleteImage')
    },
  }
}
</script>

<style scoped></style>
