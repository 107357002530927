<script>
import {mapActions, mapGetters} from "vuex";
import {format} from "date-fns";
import ExpandablePanel from "@/components/ExpandablePanel.vue";

const filtersDataKeyMap = {
  priceCategory: 'priceLists',
  office: 'offices',
};

export default {
  name: "ActiveFilters",
  components: {ExpandablePanel},
  computed: {
    ...mapGetters('studentsDirectory', ['appliedFilters', 'appliedFiltersCount', 'filtersDataLoading', 'filtersData']),
    genderColor () {
      if (! this.appliedFilters.gender) return 'grey';

      const g = this.appliedFilters.gender

      return g === 'M' ? 'blue' : (g === 'W' ? 'pink' : 'grey')
    },
    filterIcon() {
      return {
        search: 'mdi-account-search',
        office: 'mdi-office-building',
        licenses: 'mdi-alphabetical-variant',
        teachers: 'mdi-account-tie-voice',
        priceCategory: 'mdi-account-cash',
        createdAtDates: 'mdi-calendar',
        activityStatus: 'mdi-list-status',
        educationStatus: 'mdi-account-badge',
        withoutAppointments: 'mdi-calendar-remove',
        noBalance: 'mdi-cash-remove',
        automaticInvoicingDisabled: 'mdi-autorenew-off',
        hasClickAndLearn: '$clickAndLearn',
        gender: 'mdi-gender-male-female',
      }
    },
    filterColor() {
      return {
        search: 'orange lighten-4',
        office: 'yellow lighten-4',
        licenses: 'light-green lighten-4',
        teachers: 'lime lighten-4',
        priceCategory: 'yellow lighten-4',
        createdAtDates: 'orange lighten-4',
        activityStatus: 'amber lighten-4',
        educationStatus: 'amber lighten-4',
        withoutAppointments: 'green lighten-4',
        noBalance: 'green lighten-4',
        automaticInvoicingDisabled: 'green lighten-4',
        hasClickAndLearn: 'green lighten-4',
        gender: `${this.genderColor} lighten-4`,
      }
    },
    displayText() {
      const skipFilters = ['noAppointmentsRange']
      const formatDates = (dates) => {
        return dates
            .sort((a, b) => new Date(a) - new Date(b))
            .map(date => format(new Date(date), 'dd.MM.yyyy'))
            .join(' - ');
      };

      const processFilter = (key, value) => {
        if (skipFilters.includes(key)) return;

        if (key === 'withoutAppointments' && value) {
          return `${this.translateFilterKey(key)}: (${this.appliedFilters.noAppointmentsRange} ${this.$t('label.days')})`
        }

        if (key === 'search' && value) {
          return `${value}`;
        }

        if (key === 'createdAtDates' && Array.isArray(value)) {
          return formatDates(value);
        }

        if (typeof value === 'boolean' && value) {
          return this.translateFilterKey(key);
        }

        if (typeof value === 'string' || typeof value === 'number') {
          return `${this.getFilterValueName(key, value)}`;
        }

        if (Array.isArray(value)) {
          return value.map(val => `${key}_${val}: ${this.getFilterValueName(key, val)}`);
        }
      };

      return Object.entries(this.appliedFilters).reduce((acc, [key, value]) => {
        if (!skipFilters.includes(key)) {
          const processedValue = processFilter(key, value);
          if (Array.isArray(processedValue)) {
            processedValue.forEach(val => {
              const [valKey, valValue] = val.split(': ');
              acc[valKey] = valValue;
            });
          } else {
            acc[key] = processedValue;
          }
        }
        return acc;
      }, {});
    },
    completeFiltersData () {
      return {
        ...this.filtersData,
        activityStatus: [
          { text: this.$t('label.active'), value: 'active' },
          { text: this.$t('label.inactive'), value: 'inactive' },
        ],
        educationStatus: [
          { text: this.$t("education_statuses.registered"), value: "registered" },
          { text: this.$t("education_statuses.learning"), value: "learning" },
          { text: this.$t("education_statuses.finished"), value: "finished" },
        ],
        gender: [
          { text: this.$t('gender.M'), value: "M" },
          { text: this.$t('gender.W'), value: "W" },
          { text: this.$t('gender.D'), value: "D" },
        ]
      }
    }
  },
  methods: {
    ...mapActions('studentsDirectory', ['resetFilter', 'load', 'openFiltersDialog']),
    translateFilterKey(key) {
      return this.$t(`studentsDirectory.filters.${key}`);
    },
    getFilterValueName(filterKey, filterValue) {
      let mappedKey = filtersDataKeyMap[filterKey] || filterKey;
      let filterData = this.completeFiltersData[mappedKey];

      // Check if filterData is defined
      if (!filterData) {
        console.error(`Filter data not found for key: ${filterKey}`);
        return 'Unknown';
      }

      if (Array.isArray(filterValue)) {
        return filterValue.map(val => {
          let item = filterData.find(f => f.value.toString() === val.toString());
          return item ? item.text : 'Unknown';
        }).join(', ');
      } else {
        let item = filterData.find(f => f.value.toString() === filterValue.toString());
        return item ? item.text : 'Unknown';
      }
    },
    clearFilter(key, value = null) {
      if (value !== null) {
        // Logic for removing a single value from an array filter
        let currentValues = this.appliedFilters[key];
        if (Array.isArray(currentValues)) {
          let updatedValues = currentValues.filter(val => val != value);
          this.resetFilter({ key: key, value: updatedValues }).then(() => {
            this.load();
          });
        }
      } else {
        // Existing logic to reset the filter to its default state
        this.resetFilter(key).then(() => {
          this.load();
        });
      }
    },
    clearAll() {
      this.resetFilter(Object.keys(this.appliedFilters)).then(() => {
        this.load()
      });
    },
  }
}
</script>

<template>
  <expandable-panel panel-name="activeFiltersPanel" content-in-container>
    <template v-slot:title>
      {{ $t('label.active_filters') }}
    </template>
    <template v-slot:end>
      <v-badge
          overlap
          :value="appliedFiltersCount"
          :content="appliedFiltersCount"
          bordered
      >
        <v-btn
            color="primary"
            rounded
            outlined
            small
            @click.stop="openFiltersDialog"
            :disabled="filtersDataLoading"
        >
          {{ appliedFiltersCount === 0 ? $t('btn.add_filters') : $t('btn.edit_filters') }}
        </v-btn>
      </v-badge>
      <v-btn
          rounded
          outlined
          small
          @click.stop="clearAll"
          color="red"
          class="ml-3"
          :disabled="appliedFiltersCount === 0"
      >
        {{ $t('btn.clearAll') }}
      </v-btn>
    </template>
    <v-chip-group v-if="appliedFiltersCount" column>
      <v-chip
          v-for="(displayText, key) in displayText"
          :key="key"
          close
          :color="filterColor[key.split('_')[0]]"
          @click:close="clearFilter(key.includes('_') ? key.split('_')[0] : key, key.includes('_') ? key.split('_')[1] : null)"
      >
        <v-icon left>{{ filterIcon[key.split('_')[0]] }}</v-icon>
        {{ displayText }}
      </v-chip>
    </v-chip-group>
    <p v-else>{{ $t('label.no_filters_applied') }}</p>
  </expandable-panel>
</template>

<style scoped>

</style>