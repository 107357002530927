<template>
  <v-btn
    v-if="!$vuetify.breakpoint.mobile || showOnMobileView"
    icon
    color="primary"
    text
    large
    @click="toggleListView">
    <v-icon v-if="itemsView !== 'list'">mdi-view-headline</v-icon>
    <v-icon v-else>mdi-apps</v-icon>
  </v-btn>
</template>

<script>
import { mapState } from "vuex";
import responsive from "@/utils/mixins/responsive";

export default {
  name: "ListChangeViewBtn",
  props: {
    showOnMobileView: {
      type: Boolean,
      default: false
    }
  },
  mixins: [responsive],
  computed: {
    ...mapState("main", ["itemsView"]),
  },
  methods: {
    toggleListView() {
      this.$store.commit("main/toggle-items-view");
    },
  },
};
</script>

<style scoped></style>
