<template>
  <div class="page__navbar">
    <slot />
  </div>
</template>

<script>
export default {
  name: "PageNavBar",
};
</script>

<style lang="scss" scoped>
.page__navbar {
  background: #fff;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
</style>
