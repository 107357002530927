<template>
  <v-dialog
    :loading="loading || saving"
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    @keydown.esc="close"
    scrollable
  >
    <v-card>
      <v-toolbar dark color="primary" class="flex-grow-0">
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("label.create_invoice") }}</v-toolbar-title>
        <v-spacer/>
        <v-toolbar-items>
          <v-btn text @click="save" :disabled="saving || !services.length" :loading="saving">
            {{ $t("btn.save") }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <div v-if="loading" class="mt-5">{{$t('label.please_wait')}}...</div>

        <div v-else class="mt-5">
          <ValidationObserver ref="form">
            <v-row>
              <v-col cols="6">
                <div class="d-flex justify-space-between">
                  <label>{{ $t('label.invoice_address') }}</label>
                  <v-btn text small right color="primary" @click="openInvoiceAddressesListDialog">
                    <v-icon :left="!$vuetify.breakpoint.mobile" small>mdi-map-marker-outline</v-icon>
                    <span v-if="!$vuetify.breakpoint.mobile">{{ $t('label.change_address') }}</span>
                  </v-btn>
                </div>

                <ValidationProvider v-slot="{ errors }" name="company">
                  <v-text-field
                    v-model="formData.company"
                    :label="$t('form.company')"
                    :hide-details="errors.length === 0"
                    :error-messages="errors"
                  />
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" name="clientName">
                  <v-text-field
                    v-model="formData.clientName"
                    :label="$t('form.client_name')"
                    :hide-details="errors.length === 0"
                    :error-messages="errors"
                  />
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" name="addressLine1">
                  <v-text-field
                    v-model="formData.addressLine1"
                    :hide-details="errors.length === 0"
                    :label="$t('form.address_line_1')"
                    :error-messages="errors"
                  />
                </ValidationProvider>

                <v-row dense>
                  <v-col cols="8">
                    <ValidationProvider v-slot="{ errors }" name="addressLine2">
                      <v-text-field
                        v-model="formData.addressLine2"
                        :hide-details="errors.length === 0"
                        :label="$t('form.address_line_2')"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="4">
                    <ValidationProvider v-slot="{ errors }" name="country">
                      <v-text-field
                          v-model="formData.country"
                          :hide-details="errors.length === 0"
                          :label="$t('form.country')"
                          :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12">
                    <ValidationProvider v-slot="{ errors }" name="email">
                      <v-text-field
                          v-if="studentless"
                          :hide-details="errors.length === 0"
                          v-model="formData.email"
                          :label="$t('form.email')"
                          :error-messages="errors"
                          autocomplete="off"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <label>{{ $t('label.invoice_details') }}</label>
                <v-text-field
                  hide-details
                  disabled
                  :label="$t('label.date')"
                  :value="today"
                />
                <v-text-field
                  hide-details
                  disabled
                  :label="$t('label.time_range')"
                  :value="`${servicesFirstDate} - ${servicesLastDate}`"
                />
              </v-col>
            </v-row>

            <InvoiceServicesTable
                :services="services"
                :show-new-service-form="studentless"
                @serviceCreated="addService"
                @deleteService="deleteService"
                ref="servicesTable"
            />

            <v-row>
              <v-col>
                <label>{{ $t('label.additional_information') }}</label>
                <v-textarea v-model="formData.note" rows="2" />
              </v-col>
            </v-row>
          </ValidationObserver>
        </div>
      </v-card-text>
    </v-card>

    <InvoiceAddressesListDialog
        ref="invoiceAddressesListDialog"
        :studentless="studentless"
        @selected="fillFormWithChosenAddress"
    />
  </v-dialog>
</template>

<script>
import { format } from "date-fns";
import { v4 as uuid4 } from "uuid";

import studentsService from "@/services/studentsService";
import invoicesService from "@/services/invoicesService";
import InvoiceServicesTable from "@/components/invoices/InvoiceServicesTable";
import InvoiceAddressesListDialog from '@/components/invoices/InvoiceAddressesListDialog.vue'

import StudentModel from '@/store/models/StudentModel'
import invoiceAddressesService from '@/services/invoiceAddressesService'

export default {
  name: "CreateInvoiceDialog",
  props: {
    studentless: {
      type: Boolean,
      default: false,
    }
  },
  components: { InvoiceAddressesListDialog, InvoiceServicesTable },
  data: () => ({
    show: false,
    saving: false,
    loadingStudent: true,
    loadingDefaultAddress: true,
    services: [],
    invoice_address_id: null,
    formData: {
      studentId: null,
      company: "",
      clientName: "",
      addressLine1: "",
      addressLine2: "",
      country: "",
      services: [],
      note: "",
      email: "",
    }
  }),
  computed: {
    loading() {
      return this.loadingStudent || this.loadingDefaultAddress
    },
    student() {
      return StudentModel.find(this.formData.studentId)
    },
    today() {
      return format(new Date(), "dd.MM.yyyy");
    },
    servicesFirstDate() {
      if (!this.services.length) return format(new Date(), "dd.MM.yyyy");

      const minDate = this.services.reduce((min, service) => {
        const serviceDate = new Date(service.date);
        return serviceDate < min ? serviceDate : min;
      }, new Date(this.services[0].date));

      return format(minDate, "dd.MM.yyyy");
    },
    servicesLastDate() {
      if (!this.services.length) return format(new Date(), "dd.MM.yyyy");

      const maxDate = this.services.reduce((max, service) => {
        const serviceDate = new Date(service.date);
        return serviceDate > max ? serviceDate : max;
      }, new Date(this.services[0].date));

      return format(maxDate, "dd.MM.yyyy");
    }
  },
  methods: {
    open(studentId, selectedServices) {
      this.show = true

      this.services = selectedServices;

      this.loadingStudent = true
      this.loadingDefaultAddress = true
      studentsService
        .loadItem(studentId)
        .then((resp) => {
          StudentModel.update({ data: resp.data, where: studentId })

          this.formData.studentId = studentId;
          this.formData.services = selectedServices.map((item) => item.id);

          if (this.student.invoiceAddressId) {
            invoiceAddressesService.loadItem(this.student.invoiceAddressId)
              .then((response) => {
                this.fillFormWithChosenAddress(response.data)
              })
              .finally(() => {
                this.loadingDefaultAddress = false
              })
          } else {
            this.loadingDefaultAddress = false
            this.fillFormWithChosenAddress(this.student.invoiceAddress)
          }
        })
        .finally(() => {
          this.loadingStudent = false;
          this.formData.note = this.student.bookAndPay
              ? 'Der Betrag wurde bereits mit drivolino bookandpay Guthaben bezahlt'
              : ''
        });
    },
    openStudentless() {
      this.show = true
      this.loadingStudent = this.loadingDefaultAddress = false;
    },
    close() {
      this.show = false

      this.formData = {
        studentId: null,
        company: "",
        clientName: "",
        addressLine1: "",
        addressLine2: "",
        services: [],
        note: "",
      }

      this.$refs.form.reset();

      this.services = [];
      this.$refs.servicesTable.clearForm()
    },
    saveInvoice(serviceMethod) {
      this.saving = true;
      serviceMethod(this.formData)
          .then((resp) => {
            const invoice = resp.data;
            this.$router.push({
              name: "invoices.view",
              params: { id: invoice.id },
            });
          })
          .catch((error) => {
            this.$refs.form.reset();

            const responseData = error.response.data || {};
            const errors = responseData.errors || [];

            if (Object.keys(errors).length === 0 && responseData.message) {
              this.$snackbar.show({
                message: responseData.message,
                color: "error",
              });
            }

            this.$refs.form.setErrors(errors);
          })
          .finally(() => {
            this.saving = false;
          });
    },
    save() {
      if (this.studentless) {
        this.formData.services = this.services;
      }
      this.saveInvoice(this.studentless ? invoicesService.createStudentless : invoicesService.create);
    },
    fillFormWithChosenAddress(address = null) {
      this.invoice_address_id = address?.id || null

      if (address) {
        this.formData.company = address.id !== null ? address.name : ''
        this.formData.clientName = address.clientFirstName + ' ' + address.clientLastName
        this.formData.addressLine1 = address.street + ' ' + address.houseNr
        this.formData.addressLine2 = address.postcode + ' ' + address.city
        this.formData.country = address.country
      }
    },
    openInvoiceAddressesListDialog() {
      this.$refs.invoiceAddressesListDialog.open(this.invoice_address_id, this.student?.id)
    },
    addService(service) {
      this.services.push({id: uuid4(), ...service})
    },
    deleteService(serviceId) {
      this.services = this.services.filter(service => service.id !== serviceId);
    }
  },
};
</script>

<style scoped lang="scss"></style>
