import { format, getISOWeek, parseISO } from 'date-fns'
import DateRange from "@/helpers/dateRange";
import * as mutationTypes from "./../mutation-types/calendar";

const localStorageCalendarSidebarEnabled = window.localStorage.getItem("calendarSidebarEnabled")

const state = () => ({
  hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,],
  durationInMinutes: [45, 60, 90, 135, 180],
  activeDate: localStorage.getItem("calendarActiveDate") || format(new Date(), "yyyy-MM-dd"),
  previousDate: localStorage.getItem("calendarActiveDate") || format(new Date(), "yyyy-MM-dd"),
  activeMonth: format(new Date(), "yyyy-MM"),
  activeWeek: getISOWeek(new Date()),
  calendarLayout: localStorage.getItem("calendarLayout") || "LAYOUT_WEEK",
  calendarSeparateTeachers: JSON.parse(window.localStorage.getItem("calendarSeparateTeachers")) || false,
  loader: false,
  forceReload: false,
  schoolRegions: ["BUND"],
  isSidebarEnabled: localStorageCalendarSidebarEnabled !== null ? JSON.parse(localStorageCalendarSidebarEnabled) : true,
  calendarWeek: null,
  calendarMonth: null,
});

const getters = {
  days: (state, getters) =>
    new DateRange(state.activeDate).dates(
      getters.calendarLayout,
      getters.schoolRegionsGetter
    ),
  hours: (state) =>
    state.hours.map((item) => (item < 10 ? `0${item}` : `${item}`) + ":00"),
  times: (state) =>
    state.hours
      .map((hour) =>
        ["00", "15", "30", "45"].map(
          (minutes) => (hour < 10 ? `0${hour}` : `${hour}`) + ":" + minutes
        )
      )
      .flat(),
  calendarLayout: (state) => state.calendarLayout,
  activeDate: (state) => state.activeDate,
  activeMonth: (state) => format(parseISO(state.activeDate), "yyyy-MM"),
  activeWeek: (state) => getISOWeek(parseISO(state.activeDate)),
  firstDayOfMonth: (state) => format(parseISO(state.activeDate), "yyyy-MM") + '-01',
  dateRange: (state) => new DateRange(state.activeDate).dates(),
  loaderGetter: (state) => state.loader,
  schoolRegionsGetter: (state) => state.schoolRegions,
  separateTeachersGetter: (state) => state.calendarSeparateTeachers,
};

const actions = {
  setCalendarDate: ({ commit }, data) => {
    // const element = document.getElementById("timeLine");
    // if (element) element.scrollIntoView({ block: "center", behavior: "smooth" });
    if (data.activeDate) commit(mutationTypes.SET_ACTIVE_DATE, data.activeDate);
    if (data.month) commit(mutationTypes.SET_ACTIVE_MONTH, data.month);
  },
  updateCalendarLayout: ({ commit }, newLayout) => {
    localStorage.setItem("calendarLayout", newLayout);
    commit(mutationTypes.SET_CALENDAR_LAYOUT, newLayout);
  },
  toggleSeparateTeachers: ({ commit }, payload) => {
    localStorage.setItem("calendarSeparateTeachers", payload);
    commit(mutationTypes.TOGGLE_SEPARATE_TEACHERS, payload);
  },
  setSchoolRegions: ({ commit }, data) => {
    const regions = data ? data.split(", ") : ["BUND"];
    commit(mutationTypes.SET_SCHOOL_REGIONS, regions);
  },
  toggleSidebar: ({ commit }, payload) => {
    window.localStorage.setItem("calendarSidebarEnabled", payload);
    commit(mutationTypes.TOGGLE_SIDEBAR, payload);
  },
};

const mutations = {
  [mutationTypes.SET_ACTIVE_DATE]: (state, newDate) => {
    state.previousDate = state.activeDate;
    state.activeDate = newDate;
    localStorage.setItem("calendarActiveDate", newDate);
  },
  [mutationTypes.SET_ACTIVE_MONTH]: (state, newMonth) => {
    state.activeMonth = newMonth;
  },
  [mutationTypes.SET_CALENDAR_INTERVAL]: (state, newDate) => {
    state.calendarDateInterval = newDate
    localStorage.setItem("calendarDateInterval", newDate);
  },
  [mutationTypes.TOGGLE_SEPARATE_TEACHERS]: (state, value) =>
    (state.calendarSeparateTeachers = value),
  [mutationTypes.SET_CALENDAR_LAYOUT]: (state, newLayout) =>
    (state.calendarLayout = newLayout),
  [mutationTypes.SET_CALENDAR_LOADER]: (state) =>
    (state.loader = !state.loader),
  [mutationTypes.SET_FORCE_RELOAD]: (state) =>
    (state.forceReload = !state.forceReload),
  [mutationTypes.SET_SCHOOL_REGIONS]: (state, regions) =>
    (state.schoolRegions = regions),
  [mutationTypes.TOGGLE_SIDEBAR]: (state, value) =>
    (state.isSidebarEnabled = value),
  [mutationTypes.SET_CALENDAR_WEEK]: (state, value) => (state.calendarWeek = value),
  [mutationTypes.SET_CALENDAR_MONTH]: (state, value) => (state.calendarMonth = value),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
