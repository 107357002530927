import { format, parseISO } from "date-fns";

export default {
  data: function () {
    return {
      date: [],
    };
  },
  computed: {
    formattedDates() {
      if (this.date.length) {
        const localDate = this.date;
        const formatted = localDate.sort().map((item) => {
          return format(parseISO(item), "dd.MM.yyyy");
        });
        if (formatted.length > 1)
          return `vom ${formatted[0]} bis ${formatted[1]}`;
        else return formatted;
      }
      return this.date;
    },
    startDate() {
      return this.date.length ? `${this.date[0]} 00:00:00` : null;
    },
    endDate() {
      return this.date.length
        ? `${this.date[this.date.length - 1]} 23:59:59`
        : null;
    },
  },
};
