<template>
  <v-row>
    <v-alert class="pa-5" type="warning" width="100%"
      >This page is under development</v-alert
    >
  </v-row>
</template>

<script>
import responsive from "@/utils/mixins/responsive";

export default {
  name: "Roles",
  data: () => ({
    itemsView: localStorage.getItem("list-type") || "list",
  }),
  mixins: [responsive],
  methods: {
    toggleListView() {
      this.itemsView = this.itemsView === "list" ? "grid" : "list";
      localStorage.setItem("list-type", this.itemsView);
    },
  },
};
</script>

<style scoped></style>
