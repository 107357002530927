<template>
  <div>
    <v-row>
      <LessonTypesField
        :cols="!mdLandscapeAndUp ? 12 : 6"
        :model="model"
        :disabled="!!model.id || isPast"
        eventType="THEORY" />
      <v-col :cols="!mdLandscapeAndUp ? 12 : 6">
        <v-select
          :disabled="isPast"
          v-model="model.roomSize"
          :items="roomSizeItems"
          hide-details
          outlined
          dense
          :label="$t('form.room_size')" />
      </v-col>
    </v-row>

    <TimeFields
      :disabled="isPast"
      :model="model"
      :eventDuration="eventDuration" />

    <v-row v-if="isSchoolAdministrator">
      <TeachersField
        :cols="12"
        :model="model"
        :eventDuration="eventDuration"
        :filteredTeachers="filteredTeachers"
        :disabled="isPast" />
    </v-row>

    <TopicField :model="model" :disabled="isPast" />

    <v-row>
      <v-col cols="12">
        <ValidationProvider
          name="licensesIds"
          rules="required"
          v-slot="{ errors }">
          <v-select
            class="license-select-ellipsis"
            :error="errors.length > 0"
            :label="$t('form.licence')"
            :placeholder="$t('form.licence')"
            item-value="id"
            :items="licenses"
            :item-text="getLicenseLabel"
            v-model="model.licensesIds"
            :disabled="isPast"
            outlined
            hide-details
            dense
            multiple
            clearable
            :menu-props="{ offsetY: true }">
            <template v-slot:selection="{ index }">
              <span class="text-overflow-ellipsis" v-if="index === 0">
                {{ licensesLine }}</span
              >
            </template>
          </v-select>
        </ValidationProvider>
      </v-col>
      <OfficesField
        :cols="12"
        :disabled="isPast"
        :validation="false"
        :model="model"
        :offices="offices" />
    </v-row>

    <template v-if="isSchoolAdministrator">
      <v-row>
        <AllStudentsField
          :cols="12"
          :disabled="
            !model.licensesIds.length > 0 || !model.theoryTopicId || isPast
          "
          :model="model"
          :students="theoryLessonStudents"
          :validation="false"
          clearable
          multiple
          :menu-props="{ top: true }" />
      </v-row>

      <v-row v-if="model.studentsIds && assignedStudents.length">
        <TheorySubscribersField
          :students="assignedStudents"
          :disabled="false"
          :readOnly="false"
          :model="model"
          temporary />
      </v-row>
    </template>

    <v-row v-if="signedTheoryStudents.length">
      <TheoryScannedStudentsField
        :students="signedTheoryStudents"
        :disabled="isPast"
        :readOnly="isTeacher"
        :model="model"
        :temporary="false"
        @onStudentApprove="studentApprove"
        @onStudentDecline="studentDecline" />
    </v-row>
    <v-row v-if="showStudentsSkeleton">
      <v-col>
        <div class="skeleton-border">
          <v-list dense class="pa-0">
            <v-list-item
              class="item"
              v-for="n in model.studentsIds.length"
              :key="n">
              <v-list-item-content class="pa-0">
                <v-skeleton-loader type="text@1" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-col>
    </v-row>

    <v-fab-transition v-if="isActiveQRScanner">
      <v-btn
        :to="`/scanner/${model.id}`"
        :disabled="!validScanTimeRange"
        color="primary"
        fixed
        bottom
        right
        fab>
        <v-icon>mdi-scan-helper</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import user from "@/utils/mixins/user";
import responsive from "@/utils/mixins/responsive";
import isMobileApp from "@/utils/mixins/isMobileApp";

import TheorySubscribersField from "@/components/calendar/eventModal/fields/TheorySubscribersField";
import TheoryScannedStudentsField from "@/components/calendar/eventModal/fields/TheoryScannedStudentsField";
import LessonTypesField from "@/components/calendar/eventModal/fields/LessonTypesField";
import TeachersField from "@/components/calendar/eventModal/fields/TeachersField";
import AllStudentsField from "@/components/calendar/eventModal/fields/AllStudentsField";
import TimeFields from "@/components/calendar/eventModal/fields/TimeFields";
import OfficesField from "@/components/calendar/eventModal/fields/OfficesField";
import TopicField from "@/components/calendar/eventModal/fields/TopicField";

import LicenseModel from "@/store/models/LicenseModel";
import OfficeModel from "@/store/models/OfficeModel";
import StudentModel from "@/store/models/StudentModel";
import EventModel from "@/store/models/EventModel";
import TheoryTopicModel from "@/store/models/TheoryTopicModel";
import { addMinutes, isWithinInterval, parseISO, subMinutes } from "date-fns";
import eventService from "@/services/eventService";

export default {
  name: "TheoryLessonForm",
  mixins: [user, responsive, isMobileApp],
  data: () => ({
    signedTheoryStudents: [],
    loading: false,
  }),
  components: {
    TheorySubscribersField,
    TheoryScannedStudentsField,
    LessonTypesField,
    TeachersField,
    AllStudentsField,
    TimeFields,
    OfficesField,
    TopicField,
  },
  props: {
    eventDuration: {
      type: Number,
      required: true,
    },
    filteredTeachers: {
      type: Array,
      required: true,
    },
    isPast: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    if (this.model.id) {
      this.loading = true;
      eventService
        .getTheoryRequestsData(this.model.id)
        .then((response) => {
          this.signedTheoryStudents = response.data || [];
        })
        .finally(() => (this.loading = false));
    }
  },
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    isActiveQRScanner() {
      return (
        this.isMobileApp &&
        this.isTeacher &&
        this.schoolSettings.isDigitalAttendanceEnabled &&
        this.model.id
      );
    },
    showStudentsSkeleton() {
      return (
        this.model.studentsIds.length &&
        this.model.id &&
        !this.isStudent &&
        this.loading
      );
    },
    validScanTimeRange() {
      const now = new Date();
      const start = subMinutes(parseISO(this.model.start), 30);
      const end = addMinutes(
        parseISO(this.model.start),
        this.model.duration + 30
      );
      return isWithinInterval(now, { start, end });
    },
    licensesLine() {
      const mapped = this.model.licensesIds.map((id) => {
        return LicenseModel.find(id).name;
      });
      return mapped.join(", ") || "";
    },
    theoryTopic() {
      return TheoryTopicModel.find(this.model.theoryTopicId) || {};
    },
    theoryLessonStudents() {
      const schoolStudents =
        StudentModel.query()
          .where((student) => !student.isDeleted)
          .get() || [];

      const subscribedStudents = this.subscribedStudents.length || 0;
      const assignedStudents = this.assignedStudents.length || 0;
      const studentsLimitReached =
        subscribedStudents + assignedStudents >= this.model.roomSize;

      let students = schoolStudents;
      if (this.theoryTopic.type === "special") {
        students = schoolStudents.filter((student) =>
          student.licensesIds.some((licensesId) =>
            this.theoryTopic.licensesIds.includes(licensesId)
          )
        );
      }

      return !studentsLimitReached
        ? students.filter(
            (student) => !this.model.studentsIds.includes(student.id)
          )
        : [];
    },
    subscribedStudents() {
      let requests = [];
      const event = EventModel.find(this.model.id) || {};
      if (event.id && this.model.eventType === "THEORY") {
        requests = StudentModel.query()
          .where((student) => !student.isDeleted)
          .whereIdIn(event.studentsIds)
          .get();
      }
      return requests;
    },
    assignedStudents() {
      if (this.model.id && this.subscribedStudents.length) {
        const staticStudentsIds = this.subscribedStudents.map(
          (student) => student.id
        );
        const selectStudents = this.model.studentsIds.map(
          (studentId) => StudentModel.find(studentId).id
        );
        const diff = selectStudents.filter(
          (item) => !staticStudentsIds.includes(item)
        );
        return StudentModel.query()
          .where((student) => !student.isDeleted)
          .whereIdIn(diff)
          .get();
      } else {
        return StudentModel.query()
          .where((student) => !student.isDeleted)
          .whereIdIn(this.model.studentsIds)
          .get();
      }
    },
    roomSizeItems() {
      const arr = [];
      for (let i = 1; i <= 50; i++) arr.push(i);
      return arr;
    },
    licenses() {
      if (this.model.theoryTopicId) {
        const theoryTopic =
          TheoryTopicModel.find(this.model.theoryTopicId) || {};
        return (
          LicenseModel.query().whereIdIn(theoryTopic.licensesIds).get() || []
        );
      }

      return LicenseModel.query().orderBy("order", "asc").get() || [];
    },
    offices() {
      return OfficeModel.all() || [];
    },
  },
  methods: {
    ...mapActions("appointments", ["finishEventCreation"]),
    getLicenseLabel(item) {
      return item.name;
    },
    studentApprove(studentId) {
      eventService
        .setPresentStatus(this.model.id, { studentId: studentId })
        .then((resp) => (this.signedTheoryStudents = resp.data));
    },
    async studentDecline(studentId) {
      const response = await eventService.declineTheoryStudent(this.model.id, {
        studentId: studentId,
      });
      if (response) {
        this.signedTheoryStudents = this.signedTheoryStudents.filter(
          (scannedStudent) => scannedStudent.studentId !== studentId
        );
        await EventModel.update({
          where: this.model.id,
          data: response.data,
        }).then(
          (this.model.studentsIds = this.model.studentsIds.filter(
            (id) => id !== studentId
          ))
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.license-select-ellipsis {
  input[type="text"] {
    display: none !important;
  }
}
.skeleton-border {
  border: thin solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
}
.item {
  border-bottom: thin solid rgba(0, 0, 0, 0.38);
  &:last-child {
    border-bottom: none;
  }
}
</style>
