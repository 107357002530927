<template>
  <div>
      <empty-box v-if="!evaluations.length" :image="'assets/svg/calendar-dark.svg'"/>
      <v-row dense v-else>
        <v-col cols="12" sm="6" mb="4" lg="3" v-for="evaluation in evaluations" :key="evaluation.appointment.id">
          <v-card
            class="fill-height"
            outlined
            :style="`border-left: 10px solid ${evaluation.appointment.bodyColor};`"
            @click="handleEvaluationClick(evaluation.appointment.id)"
          >
            <v-card-text class="px-2 py-1 subtitle-2">
              <div class="d-flex justify-space-between">
                  <span>
                    {{ evaluation.appointment.date }} &middot; {{ evaluation.appointment.startTime }}
                  </span>
              </div>
              <div>
                {{ evaluation.appointment.teacherName }}, {{ $tc(evaluation.appointment.lessonType.name) }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
  </div>
</template>

<script>

export default {
  name: "AwaitingEvaluationAppointments",
  props: {
    evaluations: {
      type: Array,
      default:() =>  [],
    }
  },
  methods: {
    handleEvaluationClick(appointmentId) {
      this.$router.push({name: 'calendar.appointment', params: {id: appointmentId}})
    },
  },
}
</script>

<style scoped lang="scss">

</style>
