<template>
  <div :class="`user-cell ${!mdLandscapeAndUp ? 'mobile' : ''} ${isTeacher ? 'with-work-time' : ''}`">
    <v-overlay absolute :value="loaderGetter" color="#ccc"></v-overlay>

    <v-dialog v-model="vehiclesCalendarDialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
      <v-card tile>
        <v-card-title class="pa-0 ma-0">
          <v-toolbar flat dark dense color="primary">
            <v-btn icon dark @click="closeCalendarDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t('route.vehiclesCalendar') }}</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text style="position:relative;">
          <div>{{ parsedDate }}</div>
          <VehiclesCalendar v-if="vehiclesCalendarDialog" :activeDate="parsedDate" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <div class="sticky user-cell-header">
      <v-progress-linear
          v-if="loaderGetter"
          indeterminate
          absolute
          color="primary"
      />

      <div class="user-cell-days">
        <div
            :class="`day-header ${day.class}`"
            v-for="day in days"
            :key="day.dayName"
            @mouseover.prevent="focusDay(day.originalDate)"
        >
          <div class="content-wrapper">
            <v-tooltip bottom :disabled="!day.holidayName">
              <template v-slot:activator="{ on, attrs }">
                <div
                    :class="`info-row ${day.holidayName ? 'holiday-color' : ''}`"
                    v-bind="attrs"
                    v-on="on"
                >
                  <div class="day-date">
                    <span class="date">{{ day.date }}</span>
                    <span class="month">{{ day.month }}</span>
                  </div>
                  <div v-if="teachers && separateTeachersGetter" :class="`name-plates ${mdLandscapeAndUp ? '' : 'mobile'}`">
                    <div
                        v-for="teacher in teachers"
                        :key="teacher.id"
                        class="name text-overflow-ellipsis px-1"
                        :style="`background-color: ${teacher.color}99; color: ${invertColor(teacher.color)}`"
                    >
                      {{ teacher.fullName }}
                    </div>
                  </div>
                  <SunPhases v-if="mdLandscapeAndUp" :day="day" />
                  <div class="day-name" :class="day.selectedDate ? 'selected-day' : ''">
                    {{ day.dayName }}
                  </div>
                </div>
              </template>
              <span v-if="day.holidayName">{{ day.holidayName }}</span>
            </v-tooltip>
          </div>

          <div v-if="showVehicleCalendarOptions && !day.holidayName" class="popover">
            <v-btn class="ml-1" text icon @click="vehiclesCalendarDialog = true">
              <v-icon size="20">mdi-car-multiple</v-icon>
            </v-btn>
          </div>

          <WorkingHoursDaily v-if="isTeacher" :day="day" />
        </div>
      </div>
    </div>

    <div
      class="user-cell-days"
      :class="{ 'dragging-event': draggedEventGetter }">
      <div class="day-cell" v-for="day in days" :key="day.dayName">
        <template v-if="separateTeachersGetter">
          <div class="teacher-cell" v-for="tId in activeTeachersIds" :key="tId">
            <RegularEventsBox
                :class="day.class"
                :date="day.originalDate"
                :day="day"
                :tIds="[tId]"
            />
          </div>
        </template>
        <RegularEventsBox
            v-else
            :class="day.class"
            :date="day.originalDate"
            :day="day"
            :tIds="activeTeachersIds"
        />
        <TimeIndicator v-if="showTimeIndicator(day.originalDate)" :withDot="false" :scrollTo="false" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { format, parseISO, isSunday } from 'date-fns'

import user from "@/utils/mixins/user";
import responsive from "@/utils/mixins/responsive";
import loadAppointments from '@/utils/mixins/loadAppointments';
import colorMixin from "@/utils/mixins/colorMixin";

import RegularEventsBox from "@/components/calendar/RegularEventsBox";
import WorkingHoursDaily from "@/components/calendar/WorkingHoursDaily";
import SunPhases from "@/components/calendar/SunPhases";
import TimeIndicator from "@/components/calendar/TimeIndicator";
import VehiclesCalendar from '@/views/Vehicles/VehiclesCalendar.vue'

import TeacherModel from "@/store/models/TeacherModel";

export default {
  name: "CalendarBody",
  mixins: [responsive, user, colorMixin, loadAppointments],
  data: () => ({
    focusedDay: null,
    vehiclesCalendarDialog: false,
  }),

  components: {
    VehiclesCalendar,
    RegularEventsBox,
    WorkingHoursDaily,
    SunPhases,
    TimeIndicator,
  },
  computed: {
    ...mapGetters("teachers", { activeTeachersIds: "activeIds" }),
    ...mapGetters(["days", "hours", "loaderGetter"]),
    ...mapGetters("appointments", ["draggedEventGetter"]),
    ...mapGetters(["separateTeachersGetter"]),
    teachers() {
      return TeacherModel.findIn(this.activeTeachersIds) || [];
    },
    parsedDate() {
      return parseISO(this.focusedDay)
    },
    showVehicleCalendarOptions() {
      return this.isSchoolAdministrator && !isSunday(this.parsedDate)
    },
  },
  methods: {
    showTimeIndicator(date) {
      return date === format(new Date(), "yyyy-MM-dd");
    },
    focusDay(date) {
      this.focusedDay = date
    },
    closeCalendarDialog() {
      this.vehiclesCalendarDialog = false
      this.focusedDay = null
      this.loadAppointments(true)
    },
  },
};
</script>

<style lang="scss" scoped>
.name-plates {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 50px;
  width: 100%;
  display: flex;
  text-align: center;
  color: #fff;
  font-size: 0.7rem;
  &.mobile {
    top: 40px;
  }
  .name {
    width: 100%;
  }
}

.day-cell {
  position: relative;
  width: 100%;
  display: flex;
  border-left: var(--default-border);
  &:first-child {
    border: none;
  }
}
.teacher-cell {
  width: 100%;
  border-left: 1px dashed #ccc;
  &:first-child {
    border: none;
  }
}
.sticky {
  position: sticky;
  top: 0;
  z-index: 5;
  background: #fff;
}
.user-cell {
  flex-grow: 1;
  flex-basis: 0;
  display: grid;
  grid-template-rows: 50px 1fr;
  border-right: var(--darker-border);
  &.with-work-time {
    grid-template-rows: 70px 1fr;
  }
  &.mobile {
    grid-template-rows: 40px 1fr;
    &.with-work-time {
      grid-template-rows: 65px 1fr;
    }
  }
  &:last-child {
    border: none;
  }
  &-header {
    display: flex;
    flex-direction: column;
    &-meta {
      border-bottom: var(--default-border);
    }
    & > div {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
  &-days {
    display: flex;
  }
}
.day-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 50px;
  border-right: var(--default-border);
  border-bottom: var(--default-border);
  position: relative;

  .content-wrapper{
    width: 100%;
    height: 100%;
    background: rgba(243, 243, 243);
    z-index: 999;
  }

  &:hover .popover{
    display: block;
    //max-height: 45px;
    top: 48px
  }

  .popover{
    //max-height: 0;
    transition: top 0.1s ease-in-out;
    background: rgba(204, 204, 204, 0.5);
    position: absolute;
    top: 0;
    min-width: 100%;
    text-align: center;
    padding: 2px 0;
    border-radius: 0 0 10px 10px;
  }



  &.today {
    background: rgba(243, 255, 189, 0.2);
  }
  &:last-child {
    border-right: none;
  }
  .info-row {
    width: 100%;
    height: 100%;
    background: rgba(243, 243, 243, 0.473);
    padding: 0px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &.holiday-color {
      background: rgba(255, 100, 70, 0.1);
      color: #fff !important;
    }
    & > .day-date {
      display: flex;
      flex-direction: column;
      padding-right: 4px;
      & > .date {
        font-size: 14px;
        font-weight: 900;
        color: #999;
      }
      & > .month {
        font-size: 11px;
        color: #999;
        border-top: solid 1px #eee;
        padding-top: 2px;
      }
    }
    & > .day-name {
      font-size: 14px;
      text-transform: uppercase;
      color: #777;
      transition: color 0.2s;
      &.selected-day {
        color: gold;
        font-weight: bold;
        transition: color 0.2s;
      }
    }
  }
}
</style>
