<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <div class="mb-3 d-flex justify-space-between">
          <span class="title">{{ $t("label.notes") }}</span>
          <v-btn
            class="text-subtitle-2 mb-4"
            color="primary"
            text
            small
            @click="openNoticeForm"
            >{{ $t("btn.new_note") }}</v-btn
          >
        </div>

        <div class="mt-7">
          <template v-if="loadingNotes">
            <v-skeleton-loader
              class="mb-2"
              v-for="item in 3"
              :key="item"
              type="paragraph" />
          </template>

          <template v-else>
            <empty-box v-if="!notes.length" />
            <Note
              v-for="note in sortedNotices"
              :key="note.id"
              :note="note"
              @edit="onEditNote"
              @delete="onDeleteNote" />
          </template>
        </div>
      </v-col>

      <v-col cols="12" md="6">
        <v-card elevation="0" outlined>
          <v-card-title class="d-flex justify-space-between" small dense>
            {{ $t("label.has_glasses") }}

            <v-switch
              class="mt-0"
              height="20"
              flat
              inset
              dense
              hide-details
              v-model="form.hasGlasses"
              :loading="hasGlassesLoading"
              :disabled="hasGlassesLoading"
              @change="toggleHasGlasses" />
          </v-card-title>
        </v-card>

        <v-card class="mt-3" elevation="0" outlined>
          <v-card-title class="d-flex justify-space-between">
            {{ $t("label.has_documents_complete") }}

            <v-switch
              class="mt-0"
              height="20"
              flat
              inset
              dense
              hide-details
              v-model="form.hasDocumentsComplete"
              :loading="hasDocumentsCompleteLoading"
              :disabled="hasDocumentsCompleteLoading"
              @change="toggleHasDocumentsComplete" />
          </v-card-title>
        </v-card>

        <v-card class="mt-3" elevation="0" outlined>
          <v-card-title class="d-flex justify-space-between">
            {{ $t("label.exam_organization") }} ({{ $t("optional") }})

            <v-select
              dense
              outlined
              required
              clearable
              v-model="form.examOrganizationId"
              :label="$t('label.choose_organization')"
              :placeholder="$t('label.choose_organization')"
              :items="examOrganizations"
              item-value="id"
              item-text="name"
              hide-details
              style="max-width: 230px"
              :disabled="loadingExamOrganizations"
              :loading="loadingExamOrganizations"
              @change="setExamOrganization" />
          </v-card-title>
        </v-card>

        <v-card class="mt-3" elevation="0" outlined>
          <v-card-title class="d-flex justify-space-between">
            {{ $t("label.has_documents_approved") }}

            <v-switch
              class="mt-0 py-2"
              height="20"
              flat
              inset
              dense
              hide-details
              v-model="form.hasDocumentsApproved"
              :loading="hasDocumentsApprovedLoading"
              :disabled="hasDocumentsApprovedLoading"
              @change="toggleHasDocumentsApproved" />
          </v-card-title>
          <v-card-text
            v-if="form.hasDocumentsApproved && !hasDocumentsApprovedLoading">
            <v-divider />

            <v-list v-if="loadingLicenses">
              <v-list-item dense>
                <v-list-item-content class="d-flex justify-space-between">
                  <v-skeleton-loader type="text" class="mt-1" max-width="30" />
                  <v-skeleton-loader
                    type="heading"
                    class="d-flex justify-end mr-2"
                    max-width="250" />
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <template v-else>
              <v-list>
                <v-list-item
                  v-for="license in licenses"
                  :key="license.id"
                  dense>
                  <v-list-item-content>
                    <v-list-item-title>{{ license.name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-chip
                      class="mr-2"
                      small
                      color="primary"
                      text-color="white"
                      @click="onLicenseClick(license)">
                      <v-icon small left>mdi-pencil</v-icon>
                      <span v-if="license.examiningPeriodStartedAt">{{
                        license.examiningPeriodStartedAt
                          | dateFormat("dd.MM.yyyy")
                      }}</span>
                      <span v-else>{{ $t("label.set_exam_status") }}</span>
                    </v-chip>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>

              <empty-box v-if="licenses.length === 0" />
            </template>
          </v-card-text>
        </v-card>

        <v-card class="mt-3" elevation="0" outlined>
          <v-card-title class="d-flex justify-space-between">
            {{ $t("label.readyForExam") }}
          </v-card-title>

          <v-card-text>
            <v-divider class="mb-3"/>

            <v-row dense :no-gutters="$vuetify.breakpoint.xsOnly">
              <v-col cols="12">
                <v-list v-if="loadingLicenses">
                  <v-list-item dense>
                    <v-list-item-content :style="{'flex-grow': 0, 'flex-basis': '60px'}">
                      <v-skeleton-loader type="text" class="mt-1" max-width="30" />
                    </v-list-item-content>
                    <v-list-item-content class="d-flex flex-nowrap justify-end">
                      <v-skeleton-loader type="heading" class="d-flex justify-end" max-width="230"/>
                      <v-skeleton-loader type="text" class="d-flex justify-end ml-1 mr-2 mt-1" max-width="30"/>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <template v-else>
                  <p v-if="requirePracticeExamLicenses.length > 0" class="text--secondary mt-1">
                    {{ $t("messages.when_enabled_is_in_read_for_exam_list") }}</p>

                  <v-list>
                    <v-list-item v-for="license in requirePracticeExamLicenses" :key="license.id" dense>
                      <v-list-item-content :style="{'flex-grow': 0, 'flex-basis': '60px'}">
                        <v-list-item-title>{{ license.name }}</v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-content>
                        <v-row dense no-gutters>
                          <v-col/>
                          <v-col cols="6" sm="3" class="d-flex justify-end mr-3">
                            <v-text-field
                                hide-details
                                outlined
                                dense
                                type="number"
                                :min="0"
                                :max="1000"
                                :value="readyForExamLicenses[license.id].score"
                                :label="$t('form.exam_priority_score')"
                                :placeholder="$t('form.exam_priority_score')"
                                :disabled="readyForExamLicenses[license.id].loading ||
                                !readyForExamLicenses[license.id].status"
                                :loading="readyForExamLicenses[license.id].loading"
                                @blur="() => setExamPriorityScore(license.id)"
                                @focus="event => setCurrentReadingForExamScore(event)"
                                oninput="if(!value || value < 0) value = 0;"
                                @input="value => sanitizeReadingForExamScore(license.id, value)"
                                @keypress="onlyInteger"
                            />
                          </v-col>
                          <v-col cols="3" sm="1" md="2" class="d-flex justify-end">
                            <v-switch
                                class="my-auto"
                                height="20"
                                flat
                                inset
                                dense
                                hide-details
                                v-model="readyForExamLicenses[license.id].status"
                                @change="value => toggleReadyFroExam(license.id, value)"
                                :disabled="readyForExamLicenses[license.id].loading"
                                :loading="readyForExamLicenses[license.id].loading"
                            />
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <empty-box v-if="requirePracticeExamLicenses.length === 0" />
                </template>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ExaminingPeriodStartDialog
      ref="examiningPeriodStartDateDialog"
      :student-id="studentId"
      @saved="onExaminingPeriodStartSaved"
    />

    <NoticeFormDialog
      ref="noticeFormDialog"
      :student-id="studentId"
      @saved="onNoteSaved"
    />
  </div>
</template>

<script>
import NoticeFormDialog from "@/components/student/NoticeFormDialog";
import studentsService from "@/services/studentsService";
import Note from "@/components/student/Note";
import StudentModel from "@/store/models/StudentModel";
import ExaminingPeriodStartDialog from "@/components/student/ExaminingPeriodStartDialog";
import LicenseStudentModel from "@/store/models/LicenseStudentModel";
import examOrganizationsService from "@/services/examOrganizationsService";
import input from "@/utils/mixins/input";

export default {
  name: "NotesTab",
  mixins: [input],
  props: {
    studentId: {
      type: [Number, String],
      required: true,
    },
  },
  components: { ExaminingPeriodStartDialog, Note, NoticeFormDialog },
  data: () => ({
    loadingNotes: false,
    loadingLicenses: false,
    loadingExamPriorityScore: false,
    hasGlassesLoading: false,
    loadingExamOrganizations: false,
    hasDocumentsCompleteLoading: false,
    hasDocumentsApprovedLoading: false,
    licenseExaminingPeriodStartDateLoading: false,
    showLicenseDateModal: false,
    notes: [],
    licenses: [],
    examOrganizations: [],
    readyForExamLicenses: {},
    currentReadingForExamScore: 0,
    form: {
      hasGlasses: false,
      hasDocumentsComplete: false,
      hasDocumentsApproved: false,
      examOrganizationId: null
    },
  }),
  computed: {
    sortedNotices() {
      const notes = this.notes;
      return notes.sort((prev, next) => {
        return new Date(next.createdAt) - new Date(prev.createdAt);
      });
    },
    student() {
      return StudentModel.find(this.studentId) || {};
    },
    requirePracticeExamLicenses() {
      return this.licenses.filter(item => item.practiceExamRequired)
    }
  },
  mounted() {
    this.form.hasGlasses = this.student.hasGlasses;
    this.form.hasDocumentsComplete = this.student.hasDocumentsComplete;
    this.form.hasDocumentsApproved = this.student.hasDocumentsApproved;
    this.form.examOrganizationId = this.student.examOrganizationId;

    this.loadingNotes = true;
    studentsService
      .loadNotes(this.studentId)
      .then((response) => (this.notes = response.data || []))
      .finally(() => (this.loadingNotes = false));

    this.loadingExamOrganizations = true;
    examOrganizationsService
      .load()
      .then((response) => (this.examOrganizations = response.data || []))
      .finally(() => (this.loadingExamOrganizations = false));

    this.loadingLicenses = true;
    this.loadStudentLicenses().finally(() => (this.loadingLicenses = false));
  },
  methods: {
    loadStudentLicenses() {
      return studentsService
        .licenses(this.studentId)
        .then((response) => {
          this.licenses = (response.data || []).filter((license) => license.forTheoryExam || license.forPractice);
          this.licenses.forEach((license) => {
            this.$set(this.readyForExamLicenses, license.id, {
              loading: false,
              status: license.readyForExam || false,
              score: license.priorityScore || 0,
            });
          });
        });
    },
    openNoticeForm() {
      this.$refs.noticeFormDialog.open();
    },
    toggleHasGlasses(value) {
      this.hasGlassesLoading = true;
      studentsService
        .setHasGlasses(this.studentId, { status: value })
        .then(() =>
          StudentModel.update({
            where: this.studentId,
            data: { hasGlasses: value },
          })
        )
        .finally(() => (this.hasGlassesLoading = false));
    },
    toggleHasDocumentsComplete(value) {
      this.hasDocumentsCompleteLoading = true;
      studentsService
        .setHasDocumentsComplete(this.studentId, { status: value })
        .then(() =>
          StudentModel.update({
            where: this.studentId,
            data: { hasDocumentsComplete: value },
          })
        )
        .finally(() => (this.hasDocumentsCompleteLoading = false));
    },
    toggleHasDocumentsApproved(value) {
      this.hasDocumentsApprovedLoading = true;
      studentsService
        .setHasDocumentsApproved(this.studentId, { status: value })
        .then(() => {
          if (value) {
            this.loadingLicenses = true;
            this.loadStudentLicenses().finally(
              () => (this.loadingLicenses = false)
            );
          }
          StudentModel.update({
            where: this.studentId,
            data: { hasDocumentsApproved: value },
          });
        })
        .finally(() => (this.hasDocumentsApprovedLoading = false));
    },
    toggleReadyFroExam(licenseId, value) {
      this.readyForExamLicenses[licenseId].loading = true
      studentsService
          .setReadyForExam(this.studentId, licenseId, value)
          .catch(error => {
            const responseData = error.response.data || {};

            if (responseData.message) {
              this.$snackbar.error(responseData.message);
            }
          })
          .finally(() => (this.readyForExamLicenses[licenseId].loading = false));
    },
    allowOnlyDigits(event) {
      const allowedKeyCode = (event.ctrlKey || event.altKey
        || (47 < event.keyCode && event.keyCode < 58 && event.shiftKey === false)
        || (95 < event.keyCode && event.keyCode < 106)
        || (event.keyCode === 8) || (event.keyCode === 9)
        || (event.keyCode > 34 && event.keyCode < 40)
        || (event.keyCode === 46));

      if (!allowedKeyCode) event.preventDefault();
    },
    setCurrentReadingForExamScore(event) {
      const value = event.target.value;
      this.currentReadingForExamScore = value ? parseInt(value) : value;
    },
    sanitizeReadingForExamScore(licenseId, value) {
      this.readyForExamLicenses[licenseId].score = parseInt(value);
    },
    setExamPriorityScore(licenseId) {
      const score = this.readyForExamLicenses[licenseId].score;
      if (score === this.currentReadingForExamScore) return;

      this.readyForExamLicenses[licenseId].loading = true
      studentsService
        .setExamPriorityScore(this.studentId, licenseId, score || 0)
          .then(() => {
            this.readyForExamLicenses[licenseId].score = score || 0;
            this.$snackbar.success(this.$t("messages.successfully_updated"))
          })
          .catch(error => {
            const responseData = error.response.data || {};

            if (responseData.message) {
              this.$snackbar.error(responseData.message);
            }
          })
          .finally(() => (this.readyForExamLicenses[licenseId].loading = false));
    },
    setExamOrganization(value) {
      this.loadingExamOrganizations = true;
      studentsService
        .setExamOrganization(this.studentId, { examOrganizationId: value })
        .then(() =>
          StudentModel.update({
            where: this.studentId,
            data: { examOrganizationId: value },
          })
        )
        .finally(() => (this.loadingExamOrganizations = false));
    },
    onNoteSaved(savedNote) {
      const noteIndex = this.notes.findIndex(
        (note) => note.id === savedNote.id
      );

      if (noteIndex !== -1) {
        this.$set(this.notes, noteIndex, savedNote);
      } else {
        this.notes.unshift(savedNote);
      }
    },
    onEditNote(note) {
      this.$refs.noticeFormDialog.open(note);
    },
    onDeleteNote(note) {
      studentsService
        .destroyNote(this.studentId, note.id)
        .then(() => {
          const noteIndex = this.notes.findIndex((item) => item.id === note.id);
          if (noteIndex !== -1) this.notes.splice(noteIndex, 1);
        })
        .catch((error) => {
          const responseData = error.response.data;
          const errors = responseData.errors || [];
          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });
          }
        });
    },
    onLicenseClick(license) {
      this.$refs.examiningPeriodStartDateDialog.open(license);
    },
    onExaminingPeriodStartSaved() {
      studentsService
        .studentExamsProgress(this.studentId)
        .then((resp) => LicenseStudentModel.insert({ data: resp.data }));
      this.loadStudentLicenses().finally(() =>
        this.$refs.examiningPeriodStartDateDialog.close()
      );
    },
  },
};
</script>

<style scoped></style>
