<template>
  <v-dialog
    transition="dialog-bottom-transition"
    scrollable
    max-width="750px"
    :value="show"
    @click:outside="close"
    @keydown.esc="close">
    <v-card :loading="loading">
      <v-card-title class="justify-space-between">
        {{ $t("label.reply_ticket") }}
        <v-btn icon text @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form class="mt-3">
          <v-textarea
            solo
            :placeholder="$t('form.reply')"
            rows="4"
            v-model="replyData.text" />
          <v-file-input
            v-model="replyData.attachments"
            chips
            counter
            multiple
            show-size
            truncate-length="16"
            solo
            :placeholder="$t('form.ticket_attachments')" />
          <v-checkbox
            v-model="replyData.closeTicket"
            :label="$t('form.close_ticket')"></v-checkbox>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text @click="close">{{ $t("btn.close") }}</v-btn>
        <v-btn
          color="primary"
          @click="reply"
          :disabled="loading"
          :loading="loading">
          {{ $t("btn.reply_ticket") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import supportTicketsService from "@/services/supportTicketsService";

export default {
  name: "TicketReplyModal",
  props: {
    ticket: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      replyData: {
        text: "",
        attachments: [],
        closeTicket: false,
      },
    };
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    reply() {
      this.loading = true;
      const formData = new FormData();
      formData.append("text", this.replyData.text);
      if (this.replyData.closeTicket) {
        formData.set("close", this.replyData.closeTicket);
      }
      this.replyData.attachments.forEach((item) => {
        formData.append("attachments[]", item);
      });
      supportTicketsService
        .reply({ ticket: this.ticket, data: formData })
        .then((resp) => {
          this.$emit("reply", resp.data);
          this.close();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
