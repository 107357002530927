import SUPPORTED_LOCALES from "@/const/locales";
import i18n from "@/plugins/i18n";
import de from "date-fns/locale/de";
import en from "date-fns/locale/en-GB";
import ro from "date-fns/locale/ro";
import ru from "date-fns/locale/ru";

export default class LocalizationManager {
  static get supportedLocales() {
    return SUPPORTED_LOCALES.map((locale) => locale.code);
  }

  static get savedLocale() {
    const savedLocale = localStorage.getItem("locale");
    const fallbackLocale = process.env.VUE_APP_LANGUAGE;

    return this.supportedLocales.includes(savedLocale)
      ? savedLocale
      : fallbackLocale;
  }

  static init() {
    this.saveLocale(this.savedLocale);
  }

  static set(locale, vuetify = null) {
    this.saveLocale(locale);
    if (this.supportedLocales.includes(locale)) {
      if (vuetify) vuetify.lang.current = locale;
    }
  }

  static getCurrent() {
    return i18n.locale;
  }

  static saveLocale(locale) {
    if (this.supportedLocales.includes(locale)) {
      localStorage.setItem("locale", locale);
      i18n.locale = locale;
    }
  }

  static get dateFnsLocale() {
    const dateFnsLocales = { de, en, ru, ro };

    return dateFnsLocales[this.savedLocale];
  }
}
