<template>
  <v-dialog v-model="dialog" persistent max-width="960">
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>
          {{
            $t("label.create_or_edit_learning_material", {
              createOrEdit: createOrEditTranslation,
            })
          }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="mt-5">
        <ValidationObserver ref="form" rules="required">
          <v-row>
            <v-col :cols="!this.isEdit ? 8 : 12">
              <ValidationProvider v-slot="{ errors }" name="name">
                <v-text-field
                  :label="$t('form.name')"
                  :placeholder="$t('form.name')"
                  v-model="productData.name"
                  outlined
                  dense
                  :error="!!errors.length"
                  hide-details></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col v-if="!this.isEdit" cols="4">
              <ValidationProvider v-slot="{ errors }" name="price">
                <v-text-field
                  :label="$t('form.price')"
                  v-model="productData.price"
                  placeholder="0,00"
                  outlined
                  dense
                  suffix="€"
                  :error="!!errors.length"
                  v-currency="{ currency: null }"
                  hide-details></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ValidationProvider v-slot="{ errors }" name="description">
                <v-textarea
                  :label="$t('form.description')"
                  :placeholder="$t('form.description')"
                  v-model="productData.description"
                  outlined
                  dense
                  :error="!!errors.length"
                  hide-details
                  no-resize
                  rows="3"></v-textarea>
              </ValidationProvider>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>

      <v-card-actions>
        <v-btn text @click="close">
          <v-icon v-if="$vuetify.breakpoint.mobile">mdi-close</v-icon>
          <span v-else>{{ $t("btn.close") }}</span>
        </v-btn>

        <v-spacer/>

        <v-btn
          :loading="loading"
          :disabled="loading"
          class="tile"
          color="primary"
          @click="isEdit ? update() : store()"
        >
          <v-icon v-if="$vuetify.breakpoint.mobile">mdi-content-save{{isEdit ? '-edit' : ''}}</v-icon>
          <span v-else>{{ isEdit ? $t("btn.edit") : $t("btn.create") }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LearningMaterialModel from "@/store/models/LearningMaterialModel";
import learningMaterialsService from "@/services/learningMaterialsService";
import currency from "@/utils/mixins/currency";

export default {
  name: "LearningMaterialDialog",
  data: () => ({
    dialog: false,
    loading: false,
    isEdit: false,
    initialData: {
      name: "",
      price: "",
      description: "",
    },
    productData: {
      name: "",
      price: "",
      description: "",
    },
    productId: null,
    priceCategoryId: null,
    licenseId: null
  }),
  mixins: [currency],
  computed: {
    createOrEditTranslation() {
      return (
        this.isEdit ? this.$t("label.edit") : this.$t("label.create")
      ).toLowerCase();
    },
  },
  created() {
    this.initialData = { ...this.productData };
  },
  methods: {
    store() {
      const data = {
        ...this.productData,
        licenseId: this.licenseId,
        priceCategoryId: this.priceCategoryId,
        price: this.sanitizePrice(this.productData.price, "."),
      };

      this.loading = true;
      learningMaterialsService
        .store(data)
        .then((response) => {
          LearningMaterialModel.insert({ data: response.data });
          this.close();
        })
        .catch((error) => {
          const responseData = error.response.data || {};
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message)
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });

          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      const data = {
        ...this.productData,
        licenseId: this.licenseId,
        priceCategoryId: this.priceCategoryId,
      };

      this.loading = true;
      learningMaterialsService
        .update(this.productId, data)
        .then((response) => {
          LearningMaterialModel.insert({ data: response.data });
          this.close();
        })
        .catch((error) => {
          const responseData = error.response.data || {};
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message)
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });

          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openForCreate(priceCategoryId, licenseId) {
      this.dialog = true;
      this.priceCategoryId = priceCategoryId
      this.licenseId = licenseId
    },
    openForEdit(id) {
      this.dialog = true;

      if (id) {
        const product = LearningMaterialModel.find(id) || {};

        this.productId = id;
        this.productData.name = product.name;
        this.productData.price = product.price;
        this.productData.description = product.description;
        this.isEdit = true;
      }
    },
    close() {
      this.dialog = false;
      this.isEdit = false;
      this.productData = { ...this.initialData };
      this.productId = null;
      this.priceCategoryId = null
      this.licenseId = null
    },
  },
};
</script>

<style scoped></style>
