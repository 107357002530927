import { render, staticRenderFns } from "./AllowedLicenseField.vue?vue&type=template&id=523f4971&scoped=true"
import script from "./AllowedLicenseField.vue?vue&type=script&lang=js"
export * from "./AllowedLicenseField.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "523f4971",
  null
  
)

export default component.exports