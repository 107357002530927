import api from "@/utils/api";

export default {
  load(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/theory-topics", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  create(params) {
    return new Promise((resolve, reject) => {
      api
        .post("/theory-topics", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  update(itemId, params) {
    params._method = "PUT";

    return new Promise((resolve, reject) => {
      api
        .post(`/theory-topics/${itemId}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  destroy(theoryTopicId, params) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/theory-topics/${theoryTopicId}`, { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  changeOrder(params = {}) {
    params._method = "PUT";
    return new Promise((resolve, reject) => {
      api
        .post("/theory-topics/update-order", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
