<template>
  <div class="no-teachers">
    <v-card :loading="loading">
      <template v-if="loading">
        <v-card-title>
          {{ $t("loading") }}
        </v-card-title>
        <v-card-text>
          {{ $t("fetching_teachers_please_wait") }}
        </v-card-text>
      </template>
      <template v-else>
        <v-card-title>
          {{ $t("no_teacher_selected") }}
        </v-card-title>
        <v-card-text>
          {{ $t("select_teacher_from_sidebar") }}
        </v-card-text>
      </template>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "NoTeachers",
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.no-teachers {
  background: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 220px;
}
</style>
