<template>
  <div class="students-index" @keyup.ctrl.space="focusSearch">
    <top-bar class="top-bar"/>

    <tree-settings-dialog class="tree-view-settings"/>

    <tree-view class="tree-view"/>

    <content-tabs class="content-tabs"/>

    <content-body class="content-body" v-resize="onContentBodyResize" />

    <filters-dialog/>

    <student-form-dialog
      ref="studentFormDialog"
      @create="load"
      @update="reloadStudent"
      :redirect-after-creation="false"
      :after-created-handler="openStudentAfterCreation"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import TopBar from "@/components/students-directory/main-grid/TopBar.vue";
import TreeView from "@/components/students-directory/main-grid/TreeView.vue";
import ContentTabs from "@/components/students-directory/main-grid/ContentTabs.vue";
import ContentBody from "@/components/students-directory/main-grid/ContentBody.vue";
import FiltersDialog from "@/components/students-directory/FiltersDialog.vue";
import TreeSettingsDialog from "@/components/students-directory/TreeSettingsDialog.vue";
import StudentFormDialog from "@/components/student/StudentFormDialog.vue";
import {EventBus} from "@/EventBus";

export default {
  name: "StudentsIndexV2",
  mixins: [],
  components: {
    StudentFormDialog,
    TreeSettingsDialog, FiltersDialog, TopBar, TreeView, ContentBody, ContentTabs
  },
  data() {
    return {
      searchQuery: null,
    };
  },
  computed: {
    ...mapGetters('studentsDirectory', ['openedStudents', 'activeTab', 'appliedFilters']),
  },
  mounted() {
    window.addEventListener('keyup', this.focusSearch);

    const savedGroupBy = localStorage.getItem('students-directory-group-by');
    this.loadGroupTypes().then(data => {
      const savedValueIsValid = data.some(item => item.value === savedGroupBy)
      this.setGroupBy(savedValueIsValid ? savedGroupBy : data[0].value)
      this.load();
    })
    EventBus.$on('open-student-form-dialog', this.openStudentFormDialog);

    this.parseAndSetFilters()
  },
  beforeDestroy() {
    EventBus.$off('open-student-form-dialog', this.openStudentFormDialog);

    window.removeEventListener('keyup', this.focusSearch);
  },
  methods: {
    ...mapActions('studentsDirectory', ['load', 'loadGroupTypes', 'openStudent', 'closeStudent', 'setGroupBy', 'setContentBodyWidth', 'resetFilter', 'setFilter']),
    openStudentFormDialog(studentId = null) {
      this.$refs.studentFormDialog.open(studentId ? {id: studentId} : null)
    },
    openStudentAfterCreation(student) {
      const {id, firstName, lastName} = student
      this.openStudent({id, name: `${lastName} ${firstName}`, loading: true})
    },
    reloadStudent(student) {
      EventBus.$emit('reload-student', student.id)
    },
    focusSearch(event) {
      if (event.ctrlKey && event.keyCode === 32) { // 32 is the keyCode for space
        // Emit an event or perform an action
        EventBus.$emit('focus-search-input')
        // Prevent default action to stop any other behavior linked to the keypress
        event.preventDefault();
      }
    },
    onContentBodyResize() {
      const newWidth = document.querySelector('.content-body').clientWidth || 0;

      this.setContentBodyWidth(newWidth)
    },
    parseFilters(filtersParam) {
      const filters = {};
      const filterComponents = filtersParam.split(';').filter(component => component);

      filterComponents.forEach(filterComponent => {
        const [key, valueString] = filterComponent.split('(');
        let value = valueString?.replace(')', '');

        if (value && value.includes(',')) {
          value = value.split(','); // Split into array for multiple values
        } else if (!value) {
          value = true; // Set boolean filters to true
        }

        filters[key] = value;
      });

      return filters;
    },
    setFilters(filters) {
      Object.keys(filters).forEach(key => {
        this.setFilter({key, value: filters[key]})
      })
    },
    parseAndSetFilters() {
      const clearStatus = this.$route.params.clear
      if (clearStatus) this.resetFilter(Object.keys(this.appliedFilters))

      const queryParams = new URLSearchParams(window.location.search);
      const filtersParam = queryParams.get('filters');
      if (filtersParam) {
        const filters = this.parseFilters(filtersParam);
        this.setFilters(filters);
      }
      return {};
    },
  },
};
</script>

<style lang="scss" scoped>
$border-value: solid 1px #eee;

.students-index {
  background: white;
  display: grid;
  height: 100%;
  max-width: 100%;
  grid-template-areas:  'top-bar top-bar'
                        'tree-view-settings content-tabs'
                        'tree-view content-body';
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 300px 1fr;

  & > .top-bar {
    grid-area: top-bar;
  }

  & > .tree-view-settings {
    grid-area: tree-view-settings;
    border-bottom: $border-value;
  }

  & > .tree-view {
    grid-area: tree-view;
    overflow: auto;
  }

  & > .tree-actions {
    grid-area: tree-actions;
    border-top: $border-value;
  }

  & > .content-tabs {
    border-left: $border-value;
    grid-area: content-tabs;
    overflow: hidden;
  }

  & > .content-body {
    border-left: $border-value;
    grid-area: content-body;
    overflow-y: auto;
    overflow-x: hidden;
  }

  & > .footer {
    grid-area: footer;
    border-top: $border-value;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    font-size: 12px;
  }
}
</style>
