<template>
  <v-autocomplete
    v-model="model.secondaryVehicleId"
    :disabled="disabled"
    :label="$t('form.secondary_vehicle')"
    :items="vehicles"
    item-value="id"
    :item-text="vehicleName"
    outlined
    hide-details
    dense
    clearable
    :append-icon="!disabled ? 'mdi-menu-down' : ''">
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title :class="{ 'grey--text': data.item.isDeleted }">{{
          vehicleName(data.item)
        }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-icon v-if="data.item.isDeleted"
        ><v-icon small color="grey">mdi-delete</v-icon></v-list-item-icon
      >
    </template>
  </v-autocomplete>
</template>

<script>
import user from "@/utils/mixins/user";
import { mapGetters } from "vuex";

export default {
  name: "SecondaryVehicleField",
  mixins: [user],
  data() {
    return {
      text: "",
    };
  },
  props: {
    vehicles: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
  },
  methods: {
    vehicleName(item) {
      return `${item.make} ${item.model} (${item.licensePlate})`;
    },
  },
};
</script>
