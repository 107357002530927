<template>
  <div>
    <!-- Trigger slot -->
    <slot name="trigger" :open="open"></slot>
    <v-dialog v-model="dialogState" :width="width" @click:outside="handleClickOutside" :scrollable="scrollable">
      <v-card>
        <v-toolbar class="mb-4" color="primary" dark dense>
          <v-toolbar-title>
            <slot name="title" />
          </v-toolbar-title>
          <v-spacer/>
          <v-btn dark icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <slot />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <slot name="actions-start" :close="close" />
          <v-spacer/>
          <slot name="actions-end" :close="close" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ReusableDialog",
  props: {
    width: {
      type: Number,
      default: 750
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      dialogState: false,
    }
  },
  methods: {
    handleClickOutside() {
      if (! this.closeOnClickOutside) return;

      this.close();
    },
    open() {
      this.dialogState = true;
    },
    close(callback) {
      return new Promise((resolve) => {
        // If a callback is provided, execute it before closing
        if (callback && typeof callback === 'function') {
          const result = callback();
          // If the callback returns a promise, wait for it to resolve
          if (result && typeof result.then === 'function') {
            result.then(() => {
              this.dialogState = false;
              resolve();
            });
          } else {
            // If the callback doesn't return a promise, close immediately
            this.dialogState = false;
            resolve();
          }
        } else {
          // Close the dialog immediately if no callback is provided
          this.dialogState = false;
          resolve();
        }
      });
    }
  },
}
</script>

<style scoped>
</style>
