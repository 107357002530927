<script>

import TemplateOverallProgressByStudentLicenseLoader from "@/components/skeleton-loaders/TemplateOverallProgressByStudentLicenseLoader.vue";
import InsightsTemplatePdfDialog from "@/components/student/InsightsTemplatePdfDialog.vue";
import insightsService from '@/services/insightsService'
import FileSaver from '@/helpers/fileSaver'
import StudentModel from '@/store/models/StudentModel'
import user from '@/utils/mixins/user'
import { format } from "date-fns";

export default {
  name: "TemplateOverallProgressByStudentLicense",
  components: { TemplateOverallProgressByStudentLicenseLoader, InsightsTemplatePdfDialog },
  mixins: [user],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    to: {
      type: Object,
    },
    overallProgress: {
      type: [String, Number],
      default: 0
    },
    hasEvaluatedAppointment: {
      type: Boolean,
      default: false
    },
    averageScore: {
      type: [String, Number],
      default: 0
    },
    teachersIds: {
      type: Array,
      default: null,
    },
    studentId: {
      type: [String, Number],
      default: null
    },
    licenseId: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      downloading: false
    }
  },
  computed: {
    isEducationLicenseInsightsRoute() {
      return this.$route.name === 'students.student.education.license.insights'
    },
    student() {
      return StudentModel.find(this.studentId) || {};
    }
  },
  methods: {
    getInsightsTemplatePDF(signatureId) {
      this.downloading = true

      insightsService.downloadLicenseTemplatePDF(this.studentId, this.licenseId, { signatureId })
        .then((response) => {
          const fileSaver = new FileSaver([response.data])

          const studentName = StudentModel.find(this.studentId)?.fullName || "";
          const generateDate = format(new Date(), "dd.MM.yyyy");
          fileSaver.setType("application/pdf").saveToDevice(`Diagrammkarte ${studentName} ${generateDate}.pdf`);
        })
        .finally(() => {
          this.downloading = false
          this.$refs.certificateDialog.close()
        })
    },
    click() {
      if (this.hasEvaluatedAppointment && this.isSchoolAdministrator) {
        this.$refs.certificateDialog.open(this.licenseId)
      } else {
        this.getInsightsTemplatePDF()
      }
    }
  }
}
</script>

<template>
  <TemplateOverallProgressByStudentLicenseLoader v-if="loading" />
  <v-card v-else class="mx-auto mb-3" outlined rounded>
    <v-card-title>{{ $t('insights.feature_name') }}</v-card-title>
    <v-card-text>
      <v-row :dense="$vuetify.breakpoint.smAndDown">
        <v-col cols="12" sm="6" md="4">
          <div class="text-overline">{{ $t('insights.overall_progress') }}</div>
          <v-progress-linear :value="overallProgress" height="25" dark rounded>{{overallProgress}}%</v-progress-linear>
        </v-col>
        <v-col cols="12" sm="6" md="4" :class="$vuetify.breakpoint.sm ? 'px-5' : ''">
          <div class="text-overline">{{ $t('insights.average_score') }}</div>
          <div class="subtitle-1 font-weight-regular">{{averageScore}}</div>
        </v-col>
        <v-col class="d-flex align-end justify-end">
          <v-btn v-if="to" text :to="to" color="primary">{{ $t('insights.btn.view_insights') }}</v-btn>
          <v-btn
              v-if="studentId && licenseId && isEducationLicenseInsightsRoute"
              text
              @click="click"
              color="primary"
              :loading="downloading"
              :disabled="downloading"
          >
            {{ $t('insights.btn.insights_pdf') }}
            <v-icon color="primary">mdi-download</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <InsightsTemplatePdfDialog
      @emitInsightsTemplatePDF="getInsightsTemplatePDF"
      ref="certificateDialog"
      :teachers-ids="teachersIds"
      :student="student"
      :loading="downloading"
    />
  </v-card>
</template>

<style scoped>

</style>
