<template>
  <g>
    <text
      v-for="(v, i) in months"
      :key="i"
      :x="v.x"
      :y="padY / 2"
      :style="styles.text"
      >{{ v.month }}</text
    >
  </g>
</template>

<script>
export default {
  name: "Months",
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    size: {
      type: [Number, String],
    },
    space: {
      type: [Number, String],
    },
    padX: {
      type: [Number, String],
    },
    padY: {
      type: [Number, String],
    },
    styles: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    months() {
      const MONTH = [
        "Jan",
        "Feb",
        "Mär",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dez",
      ];
      const s = this.size + this.space * 2;
      const s2 = s * 2;

      const months = [];
      this.values.forEach((group, i) => {
        group.forEach((d, j) => {
          if (j === 0 && d.day === 0) {
            const month = d.date.getMonth();
            const x = i * s + this.padX + this.space;
            const last = months.slice(-1).pop();
            if (!last || (month !== last.month && x - last.x > s2)) {
              months.push({ month, x });
            }
          }
        });
      });
      return months.map((month) => ({ month: MONTH[month.month], x: month.x }));
    },
  },
};
</script>

<style scoped></style>
