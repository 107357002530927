import AppointmentNotification from '@/notifications/events/appointmentNotification'

export default class TheoryCreatedNotification extends AppointmentNotification {
  icon() {
    return "mdi-calendar";
  }

  wildCardsData() {
    return {
      title: {},
      message: {},
    };
  }
}
