import { addWeeks, eachDayOfInterval, format, parseISO, startOfWeek } from 'date-fns'
import { getHolidayByDate, getHolidays, isHoliday } from "feiertagejs";
import DateRange from '@/helpers/dateRange'

export default class Holiday {
  getAllHolidaysHolidays(year, regions = ["BUND"]) {
    let allHolidays = this.getNationalHolidays(year);
    if (regions) {
      regions.forEach((region) => {
        const regionHolidays = getHolidays(year, region);
        const uniqueRegionHolidays = regionHolidays.filter(
          (all) =>
            !allHolidays.find(
              (current) => all.dateString === current.dateString
            )
        );
        allHolidays = [...allHolidays, ...uniqueRegionHolidays];
      });
    }
    return allHolidays.sort(
      (a, b) => parseISO(a.dateString) - parseISO(b.dateString)
    );
  }

  getNationalHolidays(year) {
    return getHolidays(year, "BUND");
  }

  isHoliday(date, regions = ["BUND"]) {
    for (let i = 0; i < regions.length; i++) {
      if (isHoliday(date, regions[i])) {
        return isHoliday(date, regions[i]);
      }
    }
  }

  getHoliday(date, regions = ["BUND"]) {
    for (let i = 0; i < regions.length; i++) {
      if (getHolidayByDate(date, regions[i])) {
        const result = getHolidayByDate(date, regions[i]);
        return result.translate("de");
      }
    }
  }

  getHolidaysForPeriod(date, nrOfWeeks, regions = ["BUND"]) {
    const rangeStart = startOfWeek(date, { weekStartsOn: 1 })
    const rangeEnd = addWeeks(rangeStart, nrOfWeeks)
    const interval = eachDayOfInterval({ start: rangeStart, end: rangeEnd })
    const holidays = interval.filter((day) => new DateRange().isHoliday(day, regions))
    return holidays.map((day) => format(day, "yyyy-MM-dd"))
  }
}
