<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <DeletedAtAlert v-if="item.isDeleted" entityType="student" :entity="item" />

    <v-row dense v-if="$vuetify.breakpoint.mobile">
      <v-col cols="12">
        <StudentInfoCard orientation="horizontal" :student-id="item.id"/>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <div class="d-flex">
          <div style="width: 300px" v-if="!$vuetify.breakpoint.mobile">
            <StudentInfoCard :student-id="item.id"/>

            <v-toolbar color="white" rounded class="mt-2" elevation="1">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="openMessengerRoom(item)" :disabled="item.isDeleted">
                    <v-icon>mdi-chat-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{$t("chat.write_a_message")}}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="openOneTimePasswordModal(item)"
                      :disabled="item.isDeleted"
                  >
                    <v-icon>mdi-account-key</v-icon>
                  </v-btn>
                </template>
                <span>{{$t("label.access_data")}}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="editItem(item)" :disabled="item.isDeleted">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{$t("btn.edit")}}</span>
              </v-tooltip>

              <v-spacer />

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      v-if="isSchoolAdministrator"
                      @click="deleteItem(item)"
                      :disabled="item.isDeleted"
                  >
                    <v-icon>mdi-archive-arrow-down</v-icon>
                  </v-btn>
                </template>
                <span>
                  {{$t("btn.archive")}}
                  <span v-if="item.createdUsingCredits && item.deletionWillRefundCredits">
                    ({{$tc("label.credits_will_be_refunded", 1, {credits: 1})}})
                  </span>
                </span>
              </v-tooltip>
            </v-toolbar>

            <BalanceWidget :student="item" v-if="item.bookAndPay" />

            <v-list rounded dense width="100%" color="white" elevation="1" flat class="mt-2 no-list-group-padding">
              <InsightsWidget v-if="school.hasInsights && isSchoolAdministrator" :student="item"/>
              <ClickAndLearnWidget
                  :student="item"
                  v-if="school.hasClickAndLearn && school.clickAndLearnApiKey && isSchoolAdministrator"/>
              <StudentDocumentsWidget :student="item" :is-school-administrator="isSchoolAdministrator"/>
            </v-list>
            <JokerWidget :studentId="item.id" v-if="isSchoolAdministrator && school.hasJoker" />
          </div>

          <div
            :class="{ 'pl-3': !$vuetify.breakpoint.mobile }"
            :style="$vuetify.breakpoint.mobile ? 'width: 100%;' : 'width: calc(100% - 300px);'"
          >
            <v-card v-show="$route.name === 'students.student'">
              <v-toolbar class="px-2" elevation="3">
                <v-btn class="mr-4" :to="{ name: 'students' }" icon exact>
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>

                <span class="title">{{ $t("btn.back") }}</span>
              </v-toolbar>

              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" lg="4" v-for="tab in tabs" :key="tab.value">
                    <component
                      :is="tab.component"
                      :student-id="item.id"
                      :disabled="item.isDeleted"
                      :params="tab.params" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <router-view />
          </div>
        </div>
      </v-col>
    </v-row>

    <StudentFormDialog ref="formItem" />
    <TeachersListDialog ref="teachersDialog" />
    <StudentChangePasswordDialog ref="changePasswordDialog" />
  </v-container>
</template>

<script>
import StudentContract from "@/components/student/StudentContract";
import studentsService from "@/services/studentsService";
import StudentFormDialog from "@/components/student/StudentFormDialog";
import StudentInvoicesCard from "@/components/student/StudentInvoicesCard";
import StudentChangePasswordDialog from "@/components/student/StudentChangePasswordDialog";
import LessonTypeModel from "@/store/models/LessonTypeModel";
import EventModel from "@/store/models/EventModel";
import TeacherModel from "@/store/models/TeacherModel";
import user from "@/utils/mixins/user";
import StudentModel from "@/store/models/StudentModel";
import BalanceWidget from "@/components/student/BalanceWidget";
import JokerWidget from "@/components/student/JokerWidget";
import VaccinationWidget from "@/components/student/VaccinationWidget";
import StudentEducationStatus from "@/components/student/StudentEducationStatus";
import TeachersListDialog from "@/components/teacher/TeachersListDialog";
import messenger from "@/utils/mixins/messenger";
import responsive from "@/utils/mixins/responsive";
import StudentEducationCard from "@/components/student/StudentEducationCard";
import StudentPaymentsCard from "@/components/student/StudentPaymentsCard";
import StudentDocumentsCard from "@/components/student/StudentDocumentsCard";
import StudentNotesCard from "@/components/student/StudentNotesCard";
import StudentAgendaCard from "@/components/student/StudentAgendaCard";
import DeletedAtAlert from "@/components/student/DeletedAtAlert";
import { mapActions, mapGetters } from "vuex";
import BalanceLabel from "@/components/BalanceLabel";
import StudentInfoCard from "@/components/student/StudentInfoCard";
import StudentApologyLetter from "@/components/student/StudentApologyLetter";
import SchoolDataLoader from '@/helpers/schoolDataLoader'
import productsService from '@/services/productsService'
import OneTimeProductModel from '@/store/models/OneTimeProductModel'
import ClickAndLearnWidget from "@/components/clickandlearn/ClickAndLearnWidget.vue";
import StudentDocumentsWidget from "@/components/student/StudentDocumentsWidget.vue";
import InsightsWidget from '@/components/insights/InsightsWidget.vue'
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: "Student",
  mixins: [user, messenger, responsive],
  components: {
    InsightsWidget,
    StudentDocumentsWidget,
    ClickAndLearnWidget,
    StudentApologyLetter,
    StudentInfoCard,
    BalanceLabel,
    DeletedAtAlert,
    StudentChangePasswordDialog,
    TeachersListDialog,
    BalanceWidget,
    JokerWidget,
    VaccinationWidget,
    StudentEducationStatus,
    StudentFormDialog,
    StudentEducationCard,
    StudentPaymentsCard,
    StudentDocumentsCard,
    StudentNotesCard,
    StudentAgendaCard,
    StudentInvoicesCard,
    StudentContract
  },
  data: () => ({
    menu: false,
    teachers: [],
    loading: true,
    activeTab: 0,
    password: "",
    sendEmail: false,
    loadingDocuments: true,
    documentType: "",
    documentFile: null,
    loadingTeachers: false,
    loadingOneTimePasswordPDF: false,
    loadingOnTimePassword: false,
    isOpenChangePasswordModal: false,
  }),
  computed: {
    ...mapGetters("school", ["school"]),
    studentId() {
      return this.$route.params.id;
    },
    item() {
      return StudentModel.find(this.$route.params.id) || {};
    },
    events() {
      return EventModel.query().with("teacher").get();
    },
    lessonTypes() {
      const byId = {};
      LessonTypeModel.all().forEach((lessonType) => {
        if (!(lessonType.id in byId)) byId[lessonType.id] = lessonType;
      });
      return byId;
    },
    teachersAsOptions() {
      return TeacherModel.all().map((teacher) => ({
        text: teacher.fullName,
        value: teacher.id,
      }));
    },
    tabs() {
      return [
        { value: "education", component: "StudentEducationCard", isShow: true },
        { value: "finances", component: "StudentPaymentsCard", isShow: this.item.bookAndPay || (this.isSchoolAdministrator && this.item.priceCategoryId) },
        { value: "documents", component: "StudentDocumentsCard", isShow: true },
        { value: "agenda", component: "StudentAgendaCard", isShow: true },
        { value: "notes", component: "StudentNotesCard", isShow: true },
        {
          value: "invoices",
          component: "StudentInvoicesCard",
          isShow: this.school.isInvoicingEnabled && this.item.priceCategoryId,
          params: {
            excludedFromAutomation: this.item.isExcludedFromAutomation,
            isInvoicesAutomationEnabled: this.school.isInvoicesAutomationEnabled,
            servicesCount: this.item.servicesCount,
          },
        },
      ].filter((item) => item.isShow);
    },
  },
  beforeRouteEnter(to, from, next) {
    const studentId = to.params.id;

    studentsService
      .loadItem(studentId)
      .then((resp) => {
        const responseData = resp.data || {};

        const student = StudentModel.find(studentId);

        if (student && student.orderIndex) {
          responseData.orderIndex = student.orderIndex;
        }

        StudentModel
          .insert({ data: responseData })
          .then((response) => {
            const student = response.students[0] || {};
            StudentModel.commit((state) => state.activeStudentId = student.id);
            return next();
          });
      })
      .catch((error) => {
        const response = error.response || {};
        const { status, data } = response;

        if (status && [404, 403].includes(status)) {
          next((vm) => {
            vm.$router.push({ name: "students" });
            vm.$snackbar.show({
              message: data ? data.message : "",
              color: "error",
              timeout: 5000,
            });
          });
        }
      });
  },
  mounted() {
    SchoolDataLoader.load({ method: 'insert', only: ['pc', 'v'] })
    productsService
        .oneTimeProducts()
        .then(response => OneTimeProductModel.create({ data: response.data || [] }))
  },
  methods: {
    ...mapActions("messenger", { selectRoom: "selectRoom" }),
    editItem(item) {
      this.$refs.formItem.open(item);
    },
    deleteItem(item) {
      this.loadingAction = true;
      showConfirm(
        this.$t('btn.confirm'),
        this.$t("messages.are_your_sure_archive"),
        () => {
          studentsService
            .destroy(item.id)
            .then(() => {
              studentsService
                .loadItem(item.id)
                .then((resp) =>
                  StudentModel.update({ where: item.id, data: resp.data || {} })
                );
            })
            .catch((error) => console.log(error))
            .finally(() => {
              this.loadingAction = false;
            });
        },
        () => {
          this.loadingAction = false;
        }
      );
    },
    openMessengerRoom(student) {
      this.$router.push({ name: "messenger" }).then(() => this.openDialogRoom(student));
    },
    onTabClick(tab) {
      if (this.$route.query.tab !== tab) {
        this.$router
          .push({ name: "students.student", query: { tab: tab } })
          .catch((error) => console.log(error));
      } else {
        this.$router
          .push({ name: "students.student" })
          .catch((error) => console.log(error));
      }
    },
    openOneTimePasswordModal() {
      this.$refs.changePasswordDialog.open(this.studentId);
    },
    loadImage(url) {
      return new Promise(function (resolve, reject) {
        var img = new Image();
        img.onload = function () {
          resolve(url);
        };
        img.onerror = function () {
          reject(url);
        };
        img.src = url;
      });
    }
  },
};
</script>

<style lang="scss">
</style>
