<script>
import examListStortingCriteriaService from "@/services/examListStortingCriteriaService";
import SettingsResponsiveRow from "@/components/settings/SettingsResponsiveRow.vue";
import SettingsResponsiveRowLoader from '@/components/skeleton-loaders/SettingsResponsiveRowLoader.vue'
import input from '@/utils/mixins/input'

export default {
  name: "ExamListSortingCriteriaDialog",
  mixins: [input],
  components: {
    SettingsResponsiveRowLoader,
    SettingsResponsiveRow},
  data: () => {
    return {
      dialog: false,
      loading: false,
      saving: false,
      resetting: false,
      criteria: {},
    }
  },
  methods: {
    open() {
      this.dialog = true
      this.loading = true

      examListStortingCriteriaService
          .load()
          .then(resp => {
            this.criteria = resp.data
          })
          .finally(() => {
            this.loading = false
          })
    },
    close() {
      this.dialog = false
    },
    save() {
      this.saving = true
      examListStortingCriteriaService
      .update(this.criteria)
      .then(() => {
        this.close()
        this.$emit('preferencesUpdated')
      })
      .catch(error => {
        this.$refs.form.reset()

        const responseData = error.response.data || {};
        const errors = responseData.errors || [];

        if (Object.keys(errors).length === 0 && responseData.message)
          this.$snackbar.show({
            message: responseData.message,
            color: "error",
          });

        this.$refs.form.setErrors(errors);
      })
      .finally(() => {
        this.saving = false;
      })
    },
    reset() {
      this.resetting = true
      examListStortingCriteriaService.reset().finally(() => {
        this.resetting = false;
        this.close()
        this.$emit('preferencesUpdated')
      })
    },
    sanitizeCriterion(criterion, value) {
      this.criteria[criterion] = value ? parseInt(value) : value;
    },
  },
  computed: {
    criteriaList() {
      return Object.keys(this.criteria)
    }
  }
}
</script>

<template>
  <v-dialog
      scrollable
      v-model="dialog"
      width="850"
      @click:outside="close"
  >
    <v-card>
      <v-toolbar color="primary" dark dense>
        <v-toolbar-title>{{ $t('label.exam_list_sorting_preferences') }}</v-toolbar-title>
        <v-spacer/>
        <v-btn dark icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4">
        <p>{{ $t('label.exam_list_sorting_explanation') }}</p>
        <p class="font-weight-bold">{{ $t('label.exam_list_sorting_explanation_2') }}</p>

        <template v-if="criteriaList.length === 0 && loading">
          <settings-responsive-row-loader v-for="item in [1,2,3]" :key="item"/>
        </template>

        <template v-if="criteriaList.length > 0">
          <ValidationObserver ref="form">
            <settings-responsive-row
                v-for="(criterion, idx) in criteriaList"
                :title="$t(`examListSortingCriteria.${criterion}.title`)"
                :description="$t(`examListSortingCriteria.${criterion}.description`)"
                :key="criterion"
                :divider-after="idx < criteriaList.length - 1"
            >
              <ValidationProvider v-slot="{ errors }" :name="criterion">
                <v-text-field
                    outlined
                    dense
                    :hide-details="errors.length === 0"
                    placeholder="0"
                    :label="$t('label.criterion_value')"
                    oninput="if(!value || value < 0) value = 0;"
                    :value="criteria[criterion]"
                    type="number"
                    min="0"
                    max="10"
                    :error-messages="errors"
                    :disabled="loading"
                    @input="value => sanitizeCriterion(criterion, value)"
                    @keypress="onlyInteger"
                />
              </ValidationProvider>
            </settings-responsive-row>
          </ValidationObserver>
          <p class="mt-5" v-html="$t('label.exam_list_sorting_examining_period_explanation')" />
        </template>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-btn text @click="close">{{ $t('btn.close') }}</v-btn>
        <v-spacer/>
        <v-btn color="error" @click="reset" :disabled="loading || saving || resetting" :loading="resetting">
          <v-icon left>mdi-restore</v-icon>
          {{ $t('btn.resetToDefault') }}
        </v-btn>
        <v-btn color="primary" @click="save" :disabled="loading || saving || resetting" :loading="saving">
          <v-icon left>mdi-content-save</v-icon>
          {{ $t('btn.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>
