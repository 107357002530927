<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-bind="attrs"
        v-on="on"
        v-model="licenseName"
        :disabled="disabled"
        :label="$t('form.notifications')"
        readonly
        outlined
        dense
        hide-details
        clearable
        @click:clear="notificationLicenses = []"
      />
    </template>
    <v-card>
      <v-treeview
        v-model="notificationLicenses"
        :items="licenseList"
        selectable
        dense
      />
    </v-card>
  </v-menu>
</template>

<script>
import LicenseModel from "@/store/models/LicenseModel";

export default {
  name: "PlaceholderNotificationField",
  data: () => ({
    licenseName: '',
  }),
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    licenseList: {
      type: Array,
      required: true,
    },
    notificationLicenseProp: {
      type: Array,
      required: true,
    },
  },
  computed: {
    notificationLicenses: {
      set(value) {
        this.$emit("emitNotificationLicenseSelect", value);
        const licenses = LicenseModel.query().whereIdIn(value).get() || []
        this.licenseName = licenses.map((license) => license.name).join(', ');
      },
      get() {
        return this.notificationLicenseProp;
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
