import api from "@/utils/api";

const types = {
  OFFTIME: 'offtimes',
  PLACEHOLDER: 'placeholders',
  PRACTICE: 'practices',
  SPECIAL: 'specials',
  THEORY: 'theories',
  SIMULATOR: 'simulators',
}

export default {
  load: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("appointments", { params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  list: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("appointments/list", { params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  listExport: (params) => {
    return new Promise((resolve, reject) => {
      api
        .post("appointments/list/export", { ...params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  listExportInfo: () => {
    return new Promise((resolve, reject) => {
      api
        .get("appointments/list/export")
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  listExportFileDownload: () => {
    return new Promise((resolve, reject) => {
      api
        .get("appointments/list/export/download", { responseType: "blob" })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  deleteListExportFile: (params = {}) => {
    return new Promise((resolve, reject) => {
      api
        .delete("appointments/list/export", { params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  store: (data, type) => {
    return new Promise((resolve, reject) => {
      api
        .post(`appointments/${types[type]}`, data)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  update: (data, type) => {
    return new Promise((resolve, reject) => {
      api
        .put(`appointments/${types[type]}/${data.id}`, data)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  delete: (id, type) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`appointments/${types[type]}/${id}`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  restore: (id, type) => {
    return new Promise((resolve, reject) => {
      api
        .post(`appointments/${types[type]}/${id}/restore`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  drag_n_drop: (data) => {
    return new Promise((resolve, reject) => {
      api
        .put(`appointments/${data.id}/drag-n-drop`, data)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  get: (eventId) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/appointments/${eventId}`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  make_placeholder_request: (id, data) => {
    return new Promise((resolve, reject) => {
      api
        .post(`appointments/placeholders/${id}/request`, data)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  confirm_practice_request: (id, data) => {
    return new Promise((resolve, reject) => {
      api
        .put(`appointments/practices/${id}/confirm`, data)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  decline_practice_request: (id) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`appointments/practices/${id}/decline`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  confirm_placeholder_request: (id, data) => {
    return new Promise((resolve, reject) => {
      api
        .put(`appointments/placeholders/${id}/confirm`, data)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  decline_placeholder_request: (id) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`appointments/placeholders/${id}/decline`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  subscribe_to_theory: (id, data) => {
    return new Promise((resolve, reject) => {
      api
        .post(`appointments/theories/${id}/subscribe`, data)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  unsubscribe_from_theory: (id, data) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`appointments/theories/${id}/unsubscribe`, { data })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  confirm_presence: (id, data) => {
    return new Promise((resolve, reject) => {
      api
        .put(`appointments/theories/${id}/confirm`, data)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  get_theory_attendance_report: (id) => {
    return new Promise((resolve, reject) => {
      api
        .get(`appointments/theories/${id}/attendance-report`, {
          responseType: "blob",
        })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  subscribe_to_simulator: (id, data) => {
    return new Promise((resolve, reject) => {
      api
        .post(`appointments/simulators/${id}/subscribe`, data)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  unsubscribe_from_simulator: (id, data) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`appointments/simulators/${id}/unsubscribe`, { data })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  get_deleted_count: () => {
    return new Promise((resolve, reject) => {
      api
        .get(`recycle-bin/appointments/count`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  get_deleted_list: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`recycle-bin/appointments/list`,{ params: params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  set_missed_status: (id) => {
    return new Promise((resolve, reject) => {
      api
        .post(`appointments/practices/${id}/missed`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },

  refund_practice: (id, params) => {
    return new Promise((resolve, reject) => {
      api
        .post(`appointments/practices/${id}/refund`, params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },

  getSignature: (id) => {
    return new Promise((resolve, reject) => {
      api
        .get(`appointments/${id}/signature`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  sign: (id, params) => {
    return new Promise((resolve, reject) => {
      api
        .post(`appointments/${id}/signature`, params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  deleteMultiple: (params) => {
    return new Promise((resolve, reject) => {
      api
          .delete("appointments", { data: params })
          .then((resp) => resolve(resp))
          .catch(reject);
    });
  },
  copyWeek: (params) => {
    return new Promise((resolve, reject) => {
      api
          .post("appointments/week-copy", params)
          .then((resp) => resolve(resp))
          .catch(reject);
    });
  },
  getUpcomingTheoriesByTopic: (topicId, params = null) => {
    return new Promise((resolve, reject) => {
      api
        .get(`theory-topics/${topicId}/upcoming-appointments`, { params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  }
};
