<script>

import EvaluationModel from '@/store/models/insights/EvaluationModel'
import EvaluationPreviewDialog from "@/components/insights/EvaluationPreviewDialog.vue";

export default {
  name: 'BottomBar',
  components: {EvaluationPreviewDialog},
  data: () => ({
    loading: false,
    showDialog: false,
  }),
  props: {
    loadingEvaluation: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    appointmentId () {
      return this.$route.params.id
    },
    evaluation () {
      return EvaluationModel.query().with('items').where('appointmentId', parseInt(this.appointmentId)).first()
    }
  },
}
</script>

<template>
  <div class="d-flex px-1 align-center justify-center">
    <v-spacer></v-spacer>

    <v-btn
      :disabled="loadingEvaluation || loading || evaluation?.items.length === 0"
      :loading="loadingEvaluation || loading"
      color="primary"
      small
      :dark="!loadingEvaluation && !loading && evaluation?.items.length !== 0"
      @click="$refs.evaluationPreviewDialog.open(evaluation)"
    >
      <v-icon left>mdi-format-list-checks</v-icon>
      {{ $t('insights.btn.preview') }} <span v-if="evaluation?.items.length">({{ evaluation?.items.length }})</span>
    </v-btn>

    <evaluation-preview-dialog ref="evaluationPreviewDialog" />
  </div>
</template>

<style lang="scss" scoped>

</style>
