<template>
  <g>
    <g v-for="(v, i) in values" :key="i">
      <rect
        v-for="d in v"
        :key="d.date.toString()"
        class="cg-day"
        :x="x(i)"
        :y="d.day * s + y0"
        :width="size"
        :height="size"
        :fill="colorFun(d)"
        :data-count="d.count"
        :data-date="d.date"
        @click="onClick(d)"
        @mouseover="onHover(d)">
        <title>{{ date(d.date) + " - " + d.count }}</title>
      </rect>
    </g>
  </g>
</template>

<script>
import { format } from "date-fns";
import LocalizationManager from "@/helpers/LocalizationManager";

export default {
  name: "Days",
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    size: {
      type: [Number, String],
    },
    space: {
      type: [Number, String],
    },
    padX: {
      type: [Number, String],
    },
    padY: {
      type: [Number, String],
    },
    colorFun: {
      type: Function,
      default: () => {},
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    onHover: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    y0() {
      return this.padY + this.space;
    },
    s() {
      return this.size + this.space * 2;
    },
  },
  methods: {
    x(i) {
      return this.padX + this.s * i + this.space;
    },
    date(day) {
      return format(new Date(day), "dd.MM.yyyy", { locale: LocalizationManager.dateFnsLocale });
    },
  },
};
</script>

<style scoped></style>
