<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <span class="subtitle-1">{{ $t('calendar.teachers_filter') }}</span>
    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <template v-if="teachers.length > 0">
        <v-list dense style="max-height: 250px; overflow-y: auto">
          <template v-for="teacher in teachers">
            <v-list-item
                :disabled="!activeTeachers.includes(teacher.id) && disabledFilters || loaderGetter"
                :key="teacher.id"
                @click="$emit('calendarToggle', teacher.id)">
              <v-list-item-content>
                <v-list-item-title class="d-flex align-center">
                  <v-icon v-if="teacherColorLine" class="mr-3" :color="teacher.color">mdi-record</v-icon>
                  {{ teacher.fullName }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="mx-1 my-2">
                <CheckmarkBox :checked="activeTeachers.includes(teacher.id)" />
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>

        <div class="abs" v-if="this.$vuetify.breakpoint.smAndUp && this.isSchoolAdministrator">
          <v-divider />
          <v-list-item class="px-2">
            <v-list-item-content>
              <v-list-item-title class="d-flex align-center" style="font-size: 0.9rem">
                {{ $t("label.separate_view") }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch v-model="separateTeachersStatus" inset dense />
            </v-list-item-action>
          </v-list-item>
        </div>
      </template>

      <empty-box v-else />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import CheckmarkBox from "@/components/calendar/CheckmarkBox.vue";
import { mapGetters, mapActions } from "vuex";
import user from "@/utils/mixins/user";

export default {
  name: "TeachersFilter",
  mixins: [user],
  components: { CheckmarkBox },
  props: {
    teachers: {
      type: Array,
      required: true,
    },
    activeTeachers: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["calendarLayout", "separateTeachersGetter", "loaderGetter"]),
    ...mapGetters('school', ['teacherColorLine']),
    disabledFilters() {
      switch (this.calendarLayout) {
        case "LAYOUT_DAY":
          return false;
        case "LAYOUT_THREE_DAYS":
          return this.activeTeachers.length >= 10;
        case "LAYOUT_WEEK":
          return this.activeTeachers.length >= 5;
      }
      return false;
    },
    separateTeachersStatus: {
      set(value) {
        this.toggleSeparateTeachers(value);
      },
      get() {
        return this.separateTeachersGetter;
      },
    },
  },
  methods: {
    ...mapActions(["toggleSeparateTeachers"]),
  },
};
</script>
