export const SET_ACTIVE_DATE = "SET_ACTIVE_DATE";
export const SET_ACTIVE_MONTH = "SET_ACTIVE_MONTH";
export const SET_CALENDAR_LAYOUT = "SET_CALENDAR_LAYOUT";
export const SET_CALENDAR_LOADER = "SET_CALENDAR_LOADER";
export const SET_FORCE_RELOAD = "SET_FORCE_RELOAD";
export const SET_SCHOOL_REGIONS = "SET_SCHOOL_REGIONS";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const TOGGLE_SEPARATE_TEACHERS = "TOGGLE_SEPARATE_TEACHERS";
export const SET_CALENDAR_WEEK = "SET_CALENDAR_WEEK";
export const SET_CALENDAR_MONTH = "SET_CALENDAR_MONTH";
export const SET_CALENDAR_INTERVAL = "SET_CALENDAR_INTERVAL";
