<template>
  <div>
    <v-row>
      <LicenseField
        :cols="4"
        :activeLicenses="schoolLicenses"
        :disabled="isPast || disabledLicense"
        :clearable="!model.id && !!model.studentsIds"
        :validation="validationState" />
      <AllStudentsField
        :cols="8"
        clearable
        :disabled="isPast || disabledStudentField"
        :model="model"
        :students="studentsByLicense"
        :multiple="false"
        :validation="validationState" />
    </v-row>
    <TimeFields
      :disabled="isPast"
      :model="model"
      :eventDuration="eventDuration" />
    <v-row>
      <v-col cols="6">
        <ValidationProvider
          name="simulatorName"
          rules="required"
          v-slot="{ errors }">
          <v-text-field
            :error="errors.length > 0"
            label="Simulator Name"
            v-model="model.name"
            :disabled="isPast"
            outlined
            hide-details
            dense />
        </ValidationProvider>
      </v-col>
      <OfficesField
        :cols="6"
        :model="model"
        :offices="offices"
        :disabled="isPast"
        validation />
    </v-row>
    <CopyCurrentEvent
      :model="model"
      :disabled="isPast || validationState"
      :infinitePlaceholders="false" />
  </div>
</template>

<script>
import AllStudentsField from "@/components/calendar/eventModal/fields/AllStudentsField";
import TimeFields from "@/components/calendar/eventModal/fields/TimeFields";
import LicenseField from "@/components/calendar/eventModal/fields/LicenseField";
import OfficesField from "@/components/calendar/eventModal/fields/OfficesField";
import CopyCurrentEvent from "@/components/calendar/eventModal/fields/CopyCurrentEvent";
import user from "@/utils/mixins/user";
import OfficeModel from "@/store/models/OfficeModel";
import StudentModel from "@/store/models/StudentModel";
import LicenseModel from "@/store/models/LicenseModel";
import LessonTypeModel from "@/store/models/LessonTypeModel";
import PriceCategoryLessonTypeStatusModel from "@/store/models/PriceCategoryLessonTypeStatusModel";
import EventModel from "@/store/models/EventModel";
import { mapGetters } from "vuex";

export default {
  name: "SimulatorForm",
  mixins: [user],
  components: {
    AllStudentsField,
    TimeFields,
    OfficesField,
    LicenseField,
    CopyCurrentEvent,
  },
  props: {
    eventDuration: {
      type: Number,
      required: true,
    },
    filteredTeachers: {
      type: Array,
      required: true,
    },
    isPast: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    schoolLicenses() {
      return LicenseModel.all() || [];
    },
    lessonType() {
      return LessonTypeModel.find(this.currentAppointment.lessonTypeId);
    },
    studentsByLicense() {
      return StudentModel.query()
        .where((student) => student.licensesIds.includes(this.model.licenseId))
        .get()
        .map((student) => {
          let lessonTypeDoesNotHavePrice = false
          if (this.lessonType?.isPayable && student.priceCategoryId) {
            const lessonTypeStatus = PriceCategoryLessonTypeStatusModel.query()
            .where("priceCategoryId", student.priceCategoryId)
            .where("licenseId", this.currentAppointment.licenseId)
            .where("lessonTypeId", this.lessonType.id)
            .first();

            lessonTypeDoesNotHavePrice = !lessonTypeStatus
          }

          const disabled = (student.bookAndPay && !student.priceCategoryId) || lessonTypeDoesNotHavePrice

          return { ...student, disabled, lessonTypeDoesNotHavePrice }
        })
    },
    offices() {
      return OfficeModel.all();
    },
    validationState() {
      return !!this.model.studentsIds.length || !!this.model.licenseId;
    },
    disabledLicense() {
      const event = EventModel.find(this.model.id) || {};
      return (
        (!!event.id && !!event.studentsIds && !!event.licenseId) ||
        !this.model.studentsIds
      );
    },
    disabledStudentField() {
      const event = EventModel.find(this.model.id) || {};
      return (
        (!!event.id && !!event.studentsIds && !!event.licenseId) ||
        !this.model.licenseId
      );
    },
  },
};
</script>
