<template>
<v-main>
  <slot />
</v-main>
</template>

<script>
export default {
  name: "Default",
};
</script>

<style scoped></style>
