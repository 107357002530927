<script>
import InsightsCategoryItems from "@/components/insights/InsightsCategoryItems.vue";
import InsightsIcon from '@/components/insights/InsightsIcon.vue'

export default {
  name: "InsightsCategory",
  components: {
    InsightsIcon,
    InsightsCategoryItems
  },
  props: {
    category: Object,
    expandedState: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.expanded = this.expandedState
  },
  data() {
    return {
      expanded: false
    }
  },
  methods: {
    expand() {
      if (!this.isNotEmpty) return;

      this.expanded = !this.expanded
    }
  },
  computed: {
    isNotEmpty() {
      return (
        (this.category.subcategories && this.category.subcategories.length > 0) ||
        (this.category.items && this.category.items.length > 0)
      );
    },
    isRootCategory() {
      return this.category.parentId === null
    },
    expanderIcon() {
      return this.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'
    },
    expanderIconColor() {
      return this.isNotEmpty ? 'grey' : 'grey lighten-2'
    },
    categoryColor() {
      // return '#ffffff';
      const color = this.category.color ?? '#ffffff'
      return this.category.parentId === null ? `${color}` : `${color}50`
    },
    styleAttribute() {
      return {
        // 'border-left': this.isRootCategory ? `solid 5px ${this.categoryColor}` : '',
      }
    },
    categoryProgress() {
      return this.category.progress;
    },
    progressColor() {
      if (this.categoryProgress >= 80) return 'success';

      if (this.categoryProgress >= 20 && this.categoryProgress < 80) return 'warning';

      return 'error'
    },
    categoryCompleted() {
      return this.categoryProgress === 100;
    }
  }
}
</script>

<template>
  <div class="insights-category py-2" :class="{expanded}" :style="styleAttribute" v-if="isNotEmpty">
    <div
      class="d-flex flex-row align-center px-2 category-title"
      :class="{'pb-2 expanded': expanded}"
      @click="expand"
      style="transition-duration: 0.2s"
    >
      <InsightsIcon :icon="category.icon" :alt="category.name" :size="40" />

      <div class="category-title d-flex flex-column flex-shrink-1 flex-grow-1 pl-2" style="min-width: 0">
        <span class="d-inline-block text-truncate font-weight-bold">
          {{ category.name }}
        </span>
          <span class="d-inline-block text-truncate" v-if="category.description">
          {{ category.description }}
        </span>
      </div>

      <v-progress-circular v-if="categoryProgress !== null" style="min-width: 32px" :value="categoryProgress" :color="progressColor" size="32">
        <v-icon v-if='categoryCompleted' color="secondary" size="16">mdi-check</v-icon>
        <span v-else style="font-size: 11px">{{ categoryProgress }}%</span>
      </v-progress-circular>

      <div class="expander d-flex align-center">
        <v-icon :color="expanderIconColor">{{ expanderIcon }}</v-icon>
      </div>
    </div>

    <slide-up-down :active="expanded" :duration="200">
      <div class="category-content pl-4 pt-1" v-if="isNotEmpty">
        <div v-if="category.subcategories" class="subcategories">
          <insights-category
            :expanded-state="expandedState"
            v-for="subcategory in category.subcategories"
            :category="subcategory"
            :key="subcategory.id"
          />
        </div>
        <template v-if="category.items">
          <insights-category-items :items="category.items"/>
        </template>
      </div>
    </slide-up-down>
  </div>
</template>

<style scoped lang="scss">
.category-title {
  border-bottom: 1px #eee;
  border-bottom-style: none;
  transition: 0.2s;
  font-size: 14px;
  cursor: pointer;
  &.expanded {
    border-bottom-style: solid;
  }
}
</style>
