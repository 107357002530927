<template>
<ValidationProvider v-slot="{ errors }" :name="name">
  <v-dialog
    v-model="menu"
    :close-on-content-click="false"
    persistent
    max-width="290px"
    @input="onDialogInput"
  >
  <template v-slot:activator="{ on, attrs }">
    <v-text-field
      dense
      :value="dateFormat"
      :label="label"
      :placeholder="placeholder"
      :disabled="disabled"
      readonly
      outlined
      v-bind="attrs"
      v-on="on"
      :error-messages="errors"
      clearable
      @click:clear="$emit('clear')"
      :hide-details="hideDetails"
    />
  </template>

  <v-date-picker
    v-if="menu"
    :min="min"
    :max="max"
    ref="birthDatePicker"
    v-model="date"
    no-title
    scrollable
    first-day-of-week="1"
  >
      <v-spacer/>
      <v-btn text color="primary" @click="cancel">
          {{ $t('btn.cancel') }}
      </v-btn>
      <v-btn text color="primary" @click="save">
          {{ $t('btn.ok') }}
      </v-btn>
  </v-date-picker>
</v-dialog>
</ValidationProvider>
</template>

<script>
import { format } from 'date-fns'

export default {
  name: 'DatePickerField',
  props: {
    name: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    value: {
      type: [Array, String]
    },
    min: {
      type: String
    },
    max: {
      type: String
    },
    hideDetails: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu: false,
      date: null
    }
  },
  computed: {
    dateFormat () {
      return this.value
        ? format(new Date(this.value), 'dd.MM.yyyy')
        : ''
    }
  },
  methods: {
    onDialogInput(value) {
      if (value) {
        this.date = this.value
      }
    },
    cancel() {
      this.menu = false
      this.date = null
    },
    onInput(value) {
      this.date = value
    },
    save() {
      this.$emit('save', this.date)
      this.menu = false
    }
  }
}
</script>

<style scoped>

</style>
