import api from "@/utils/api";

export default {
  load(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/student-service-templates", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  create(params) {
    return new Promise((resolve, reject) => {
      api
        .post("/student-service-templates", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  update(itemId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/student-service-templates/${itemId}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  delete(itemId) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/student-service-templates/${itemId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
