<template>
  <div class="d-flex flex-column">
    <template v-if="siblings">
      <router-link
        v-for="sibling in siblings"
        :key="sibling.id"
        :to="{name: 'calendar.appointment.evaluation.category', params: { categoryId: sibling.id }}"
        class="btn d-flex align-center justify-center"
        :class="sibling.id === activeCategory.id ? 'active' : ''"
      >
        <v-badge
          v-if="sibling.evaluatedItemsAmount"
          class="absolute-badge"
          color="error"
          dot
        />
        <img :src="categoryIcon(sibling)" :alt="sibling.name"/>
      </router-link>
    </template>

    <sidebar-item-loader v-else :count="7"></sidebar-item-loader>
  </div>
</template>

<script>

import CategoryModel from '@/store/models/insights/CategoryModel'
import SidebarItemLoader from '@/components/skeleton-loaders/evaluations/SidebarItemLoader.vue'

export default {
  name: 'Sidebar',
  components: { SidebarItemLoader },
  data: () => ({
    items: []
  }),
  methods: {
    categoryIcon(category) {
      try {
        return require(`@/assets/insights/icons/${category.icon}`)
      } catch (e) {
        return require(`@/assets/insights/icons/no-icon.svg`)
      }
    }
  },
  computed: {
    activeCategory () {
      const categoryId = parseInt(this.$route.params.categoryId)
      return CategoryModel.query().whereId(categoryId).with(['items', 'children']).first()
    },
    siblings () {
      return this.activeCategory?.siblings
    }
  }
}
</script>

<style lang="scss" scoped>
.btn{
  border-bottom: 1px solid #ccc;
  //border-radius: 4px;
  width: 100%;
  height: 64px;
  cursor: pointer;
  transition: .5s;
  position: relative;
  & > img {
    width: 55px;
    height: 55px;
  }
  &.active, &:hover {
    background: #eee;
  }
  .absolute-badge{
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
</style>
