<template>
  <v-bottom-sheet v-model="showAppointmentError" :inset="!$vuetify.breakpoint.mobile" persistent scrollable max-height="300px">
    <v-card tile>
      <v-card-title class="d-flex mb-3" :class="{'justify-center subtitle-1 ': $vuetify.breakpoint.mobile}">
        <v-icon class="mr-3" :class="{'mb-2': $vuetify.breakpoint.mobile}" color="warning" large>mdi-alert-outline</v-icon>
        {{ appointmentErrorMessage }}
        <v-spacer/>
        <v-btn icon large @click="close" v-if="! $vuetify.breakpoint.mobile">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <template v-if="$vuetify.breakpoint.mobile">
        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="close">{{ $t('btn.close') }}</v-btn>
        </v-card-actions>
      </template>

    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { mapState } from 'vuex'
import { HIDE_CALENDAR_ERRORS } from '@/store/mutation-types/vehiclesCalendar'

export default {
  name: "VehiclesErrorBottomSheet",
  props: {
    show: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState('vehiclesCalendar', ['showAppointmentError', 'appointmentErrorMessage']),
  },
  methods: {
    close() {
      this.$store.commit(`vehiclesCalendar/${HIDE_CALENDAR_ERRORS}`)
    },
  }
};
</script>

<style scoped>
.scrollable {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}
</style>
