<template>
  <v-container>
    <v-card elevation="2">
      <v-card-text class="text-center">
        <v-icon class="mr-2" size="150" disabled>mdi-account-off</v-icon>
        <div class="text-h5 mt-10 mb-4">
          {{ $t("messages.inactive_account") }}
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import user from "@/utils/mixins/user";

export default {
  name: "InactiveAccount",
  mixins: [user],
  beforeRouteEnter(from, to, next) {
    next((vm) => {
      if (vm.currentUser.active === true) {
        vm.$router.push({ name: "dashboard" });
      }
    });
  },
};
</script>

<style scoped lang="scss">
.error-code {
  font-family: monospace;
  font-size: 270px !important;
  margin: 0;
  font-weight: 200;
  color: #ddd;
}
.error-text {
  color: #444;
  font-family: monospace;
}
</style>
