import api from "@/utils/api";

export default {
  load() {
    return new Promise((resolve, reject) => {
      api
        .get("children")
        .then(resp => resolve(resp))
        .catch(reject);
    });
  },
  loadItem(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`children/${id}`)
        .then(resp => resolve(resp))
        .catch(reject);
    });
  },
  toggleNotificationsStatus: (id, params) => {
    return new Promise((resolve, reject) => {
      api
        .patch(`children/${id}`, params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  unlinkStudentFromParent: (id) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`children/${id}`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
}
