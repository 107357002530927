import Notification from "@/notifications/notification";
import appointmentService from "@/services/appointmentService";
import FileSaver from "@/helpers/fileSaver";

export default class AppointmentsListExportCompletedNotification extends Notification {
  icon() {
    return "mdi-file-outline";
  }

  wildCardsData() {
    const data = this.notification.data;

    return {
      message: {
        fileName: data.fileName,
        date: data.date
      }
    };
  }

  action() {
    appointmentService
      .listExportInfo()
      .then(response => {
        const exportInfo = response.data || {};
        appointmentService.listExportFileDownload()
          .then(response => {
            const fileSaver = new FileSaver([response.data]);
            fileSaver
              .setType("text/csv")
              .saveToDevice(exportInfo.fileName + ".csv", "appointments");
          });
      })
      .catch(error => {
        const response = error.response || {};
        const responseData = response.data || {};

        if (responseData.message) {
          this.$snackbar.show({ message: responseData.message, color: "error" });
        }
      });
  }
}
