<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'school-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>
        {{ $t("route.schoolSettings.children.invoices") }}
      </v-card-title>
    </v-toolbar>
    <v-card-text>
      <v-alert type="info" border="right">
        {{ $t("label.invoicing_explanation") }}
      </v-alert>
      <v-switch
        :loading="invoicingStatusChanging"
        :disabled="invoicingStatusChanging"
        :input-value="invoicingLocalStatus"
        :label="$t('label.enable_invoicing')"
        @change="handleInvoicingStatusChange" />
      <template v-if="this.school.isInvoicingEnabled">
        <v-divider />
        <v-switch
          :loading="invoicingAutomationStatusChanging"
          :disabled="invoicingAutomationStatusChanging"
          :input-value="invoicingAutomationLocalStatus"
          :label="$t('label.enable_invoicing_automation')"
          @change="handleInvoicingAutomationStatusChange" />
        <template v-if="this.school.isInvoicesAutomationEnabled">
          <v-radio-group v-model="invoiceAutomationSchedule">
            <template v-slot:label>
              <div>{{ $t("label.select_your_preferred_schedule") }}</div>
            </template>
            <v-radio :value="1">
              <template v-slot:label>
                <div>
                  <strong>{{ $t("label.generate_invoices_weekly") }}</strong> -
                  {{ $t("label.generate_invoices_weekly_explanation") }}
                </div>
              </template>
            </v-radio>
            <v-radio :value="2">
              <template v-slot:label>
                <div>
                  <strong>{{ $t("label.generate_invoices_daily") }}</strong> -
                  {{ $t("label.generate_invoices_daily_explanation") }}
                </div>
              </template>
            </v-radio>
          </v-radio-group>

          <v-btn
            text
            color="primary"
            :loading="loading"
            small
            @click="saveAutomationSchedule"
          >
            {{ $t('btn.confirm_selection') }}
          </v-btn>
        </template>
      </template>
    </v-card-text>
  </div>
</template>

<script>
import schoolService from "@/services/schoolService";
import responsive from '@/utils/mixins/responsive'

export default {
  name: "Invoices",
  mixins: [responsive],
  data() {
    return {
      invoicingLocalStatus: undefined,
      invoicingAutomationLocalStatus: undefined,
      invoicingStatusChanging: true,
      invoicingAutomationStatusChanging: true,
      invoiceAutomationSchedule: 1,
      loading: false,
    };
  },
  mounted() {
    this.invoicingLocalStatus = this.school.isInvoicingEnabled;
    this.invoicingAutomationLocalStatus = this.school.isInvoicesAutomationEnabled;
    this.invoiceAutomationSchedule = this.school.invoiceAutomationSchedule;
    this.invoicingStatusChanging = this.invoicingAutomationStatusChanging = false;
  },
  methods: {
    handleInvoicingStatusChange(newState) {
      this.invoicingLocalStatus = newState;
      this.invoicingStatusChanging = true;
      schoolService
        .updateInvoicingStatusSettings(newState)
        .then(() => {
          this.$store.commit("school/UPDATE_INVOICING_STATUS", newState);
          this.$snackbar.success(this.$t("messages.successfully_updated"))
        })
        .finally(() => {
          this.invoicingStatusChanging = false;
        });
    },
    handleInvoicingAutomationStatusChange(newState) {
      this.invoicingAutomationLocalStatus = newState;
      this.invoicingAutomationStatusChanging = true;
      schoolService
        .updateInvoicingAutomationStatusSettings(newState)
        .then(() => {
          this.$store.commit("school/UPDATE_INVOICING_AUTOMATION_STATUS", newState);
          this.$snackbar.success(this.$t("messages.successfully_updated"))
        })
        .finally(() => {
          this.invoicingAutomationStatusChanging = false;
        });
    },
    saveAutomationSchedule() {
      this.loading = true;
      schoolService
        .updateInvoiceAutomationSchedule(this.invoiceAutomationSchedule)
        .then(() => {
          this.$snackbar.success(this.$t("messages.successfully_updated"))
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    school() {
      return this.$store.state.school;
    },
  },
};
</script>

<style scoped lang="scss"></style>
