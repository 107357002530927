<template>
<v-list-item-icon style="margin-right: 15px;">
  <SidebarBadge :badge="badge" color="red">
    <v-img contain :width="iconWidth" :height="iconHeight" :src="iconSrc"/>
  </SidebarBadge>
</v-list-item-icon>
</template>

<script>
import SidebarBadge from '@/components/sidebar/SidebarBadge.vue'
import { mapState } from 'vuex'

export default {
  name: 'SidebarListItemIcon',
  components: { SidebarBadge },
  props: {
    tooltip: {
      type: String,
      default: ''
    },
    iconSrc: {
      type: String,
      required: true
    },
    iconWidth: {
      type: [String, Number],
      default: 25
    },
    iconHeight: {
      type: [String, Number],
      default: 25
    },
    badge: {
      default: undefined
    }
  },
  computed: {
    ...mapState("main", ["isSidebarMinimized"])
  }
}
</script>

<style scoped lang="scss">
</style>