<script>
import {defineComponent} from 'vue'
import {mapGetters} from "vuex";
import studentsService from "@/services/studentsService";

export default defineComponent({
  name: "StudentLimits",
  data() {
    return {
      state: false,
      loadingTeachers: false,
      teachers: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    hasAnyLimits() {
      return this.hasAppointmentLimits || this.hasTeacherLimits
    },
    hasAppointmentLimits() {
      return this.currentUser.maxLessonsAmountPerDay
        || this.currentUser.maxLessonsAmountPerWeek
        || this.currentUser.maxLessonsDurationPerDay
    },
    hasTeacherLimits() {
      return this.teachers.length > 0 && this.teachers.some(t => t.bookingLimitWeeks)
    },
    expandIcon() {
      return this.state ? 'mdi-chevron-up' : 'mdi-chevron-down'
    }
  },
  async mounted() {
    this.state = JSON.parse(localStorage.getItem('studentLimitsWidgetState'))
    this.loadTeachers()
  },
  methods: {
    toggle() {
      this.state = !this.state
      localStorage.setItem('studentLimitsWidgetState', this.state)
    },
    loadTeachers() {
      this.loadingTeachers = true
      studentsService
        .loadStudentTeachers(this.currentUser.id)
        .then((resp) => {
          this.teachers = resp.data
        })
        .finally(() => {
          this.loadingTeachers = false
        })
    }
  }
})
</script>

<template>
  <v-row v-if="hasAnyLimits" dense>
    <v-col>
      <v-card>
        <v-card-title @click="toggle" class="py-2 d-flex justify-space-between">
          <div class="d-flex flex-column">
            <span>{{ $t('label.your_limits') }}</span>
          </div>
          <v-btn icon small @click.stop="toggle">
            <v-icon>{{ expandIcon }}</v-icon>
          </v-btn>
        </v-card-title>
        <template v-if="state">
          <v-divider/>
            <v-card-text class="pa-0">
              <v-list >
                <template v-if="hasAppointmentLimits">
                  <v-subheader>{{ $t('label.appointments_limits') }}</v-subheader>

                  <v-list-item  v-if="currentUser.maxLessonsAmountPerDay">
                    <v-list-item-avatar>
                      <v-icon>mdi-calendar</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('label.max_lesson_per_day') }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ currentUser.maxLessonsAmountPerDay }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item  v-if="currentUser.maxLessonsAmountPerWeek">
                    <v-list-item-avatar>
                      <v-icon>mdi-calendar-week</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('label.max_lesson_per_week') }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ currentUser.maxLessonsAmountPerWeek }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item  v-if="currentUser.maxLessonsDurationPerDay">
                    <v-list-item-avatar>
                      <v-icon>mdi-calendar-clock</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('label.max_driving_minutes_per_day') }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ currentUser.maxLessonsDurationPerDay }} min.
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-if="hasTeacherLimits">
                  <v-subheader class="d-flex justify-space-between">
                    {{ $t('label.teachers_limits') }}
                    <v-btn
                      :loading="loadingTeachers"
                      icon
                      text
                      x-small
                      @click="loadTeachers"
                    >
                      <v-icon>mdi-reload</v-icon>
                    </v-btn>
                  </v-subheader>
                  <v-list-item dense three-line v-for="teacher in teachers" :key="teacher.id">
                    <v-list-item-content>
                      <v-list-item-title>{{ teacher.fullName }}</v-list-item-title>
                      <v-list-item-subtitle>{{ $t('label.working_hours') }}: {{ teacher.startTime }} - {{ teacher.endTime }}</v-list-item-subtitle>
                      <v-list-item-subtitle >
                        {{ $t('label.booking_limit') }}:
                        <span v-if="teacher.bookingLimitWeeks">{{ teacher.bookingLimitDate }}</span>
                        <span v-else>{{ $t('label.unlimited') }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
            </v-list>
            </v-card-text>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped>

</style>
