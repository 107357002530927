import { Model } from "@vuex-orm/core";
import { v4 as uuid4 } from "uuid";

export default class PriceCategoryLessonTypeStatusModel extends Model {
  static entity = "priceCategoryLessonTypeStatuses";

  static fields() {
    return {
      id: this.uid(() => uuid4()),
      priceCategoryId: this.attr(null),
      licenseId: this.attr(null),
      lessonTypeId: this.attr(null),
      isEnabled: this.boolean(true),
      type: this.attr(null),
    };
  }
}
