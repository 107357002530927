import { render, staticRenderFns } from "./VehicleExpenses.vue?vue&type=template&id=3006883c&scoped=true"
import script from "./VehicleExpenses.vue?vue&type=script&lang=js"
export * from "./VehicleExpenses.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3006883c",
  null
  
)

export default component.exports