<template>
  <div :id="`${this.withDot ? 'timeLine' : 'redLine'}`" :style="{ top: position + 'px' }"></div>
</template>

<script>
import { mapGetters } from "vuex";
import { differenceInMinutes, format, parseISO } from "date-fns";
import responsive from "@/utils/mixins/responsive";
import timeLineMixin from '@/utils/mixins/timeLineMixin'

export default {
  name: "TimeIndicator",
  mixins: [responsive, timeLineMixin],

  props: {
    scrollTo: {
      type: Boolean,
      required: true,
    },
    withDot: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    if (!this.scrollTo) return
    this.$nextTick(function () {
      const element = document.getElementById("timeLine");
      if (element) element.scrollIntoView({ block: "center", behavior: "smooth" });
    })
  },
  computed: {
    ...mapGetters(["hours"]),
    dayStartTime() {
      return format(new Date(), "yyyy-MM-dd " + this.hours[0]);
    },
    indicatorPosition() {
      const ratio = this.withDot ? -45 : 0;
      return differenceInMinutes(new Date(), parseISO(this.dayStartTime)) * this.oneMinuteSize - ratio;
    },
  },
};
</script>

<style lang="scss" scoped>
#redLine {
  width: 100%;
  height: 2px;
  background: tomato;
  position: absolute;
  z-index: 4;
  top: 0;
  pointer-events: none;
  &:before {
    display: block;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    background: tomato;
    position: relative;
    left: -7px;
    top: -6px;
    content: "";
  }
}
.time {
  position: relative;
  top: -20px;
  right: -18px;
  font-size: 0.8rem;
  color: tomato;
}
#timeLine {
  opacity: 0;
  position: absolute;
  z-index: 4;
  top: 0;
  pointer-events: none;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid tomato;
}
</style>
