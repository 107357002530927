<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'personal-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>
        {{ $t("route.personalSettings.children.profile") }}
      </v-card-title>

      <v-spacer/>

      <v-btn
          text
          color="primary"
          @click="save"
          :loading="formLoading"
          :disabled="formLoading">
        {{ $t("btn.save") }}
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <ValidationObserver ref="form">
        <v-tabs v-model="activeTab">
          <v-tab href="#general">{{ $t("label.general") }}</v-tab>
          <v-tab href="#contacts">{{ $t("label.contact") }}</v-tab>

          <v-tab-item value="general">
            <v-row class="mt-3">
              <v-col cols="12" lg="9">
                <v-row>
                  <v-col cols="12" sm="6" md="12" lg="12">
                    <ValidationProvider v-slot="{ errors }" name="firstName">
                      <v-text-field
                        dense
                        v-model="form.firstName"
                        :label="$t('form.first_name')"
                        :placeholder="$t('form.first_name')"
                        outlined
                        :error-messages="errors"
                        required></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      dense
                      v-slot="{ errors }"
                      name="lastName">
                      <v-text-field
                        dense
                        v-model="form.lastName"
                        :label="$t('form.last_name')"
                        :placeholder="$t('form.last_name')"
                        outlined
                        :error-messages="errors"></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ errors }" name="birthDate">
                      <v-menu
                        v-model="menuBirthDate"
                        :close-on-content-click="false"
                        :nudge-bottom="-20"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            :value="birthDateFormat"
                            :label="$t('form.birthday')"
                            :placeholder="$t('form.birthday')"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="errors"
                            clearable
                            @click:clear="form.birthDate = ''" />
                        </template>
                        <v-date-picker
                          ref="birthDatePicker"
                          @input="onInputBirthDate"
                          :value="minDateOfBirthFromToday"
                          no-title
                          scrollable
                          :max="minDateOfBirthFromToday" />
                      </v-menu>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="3">
                <v-row>
                  <v-col>
                    <UploadBtn
                        class="mb-2"
                        @file-picked="onFilePick"
                        accept="image/jpeg,image/png"
                        input-name="avatar"
                    >
                      <UserAvatar class="elevation-3" size="150" :path="avatarImage" :alt="currentUser.fullName" />
                    </UploadBtn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="d-flex justify-center">
                    <v-btn outlined class="mb-2" :color="currentUser.hasSignature ? 'green' : 'grey darken-2'" @click="openSignatureDialog">
                      <v-icon :left="!$vuetify.breakpoint.mobile">mdi-signature-freehand</v-icon>
                      {{ $t("label.signature") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item value="contacts">
            <v-row class="mt-3">
              <v-col cols="12" sm="6" md="12" lg="12">
                <ValidationProvider v-slot="{ errors }" name="email">
                  <v-text-field
                    dense
                    v-model="form.email"
                    :label="$t('form.email')"
                    :placeholder="$t('form.email')"
                    outlined
                    :error-messages="errors"
                    required
                    :disabled="!!currentUser.email"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </ValidationObserver>
    </v-card-text>

    <SignatureDialog type="user" ref="signatureDialog" @saved="signatureSaved" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import localizationService from "@/services/localizationService";
import userService from "@/services/userService";
import UploadBtn from "@/components/UploadBtn";
import responsive from "@/utils/mixins/responsive";
import UserAvatar from '@/components/UserAvatar'
import SignatureDialog from '@/components/SignatureDialog.vue'
import user from '@/utils/mixins/user'
import { format, sub } from 'date-fns'

export default {
  name: "SchoolAdministratorSettingsGeneral",
  mixins: [responsive, user],
  components: {
    SignatureDialog,
    UserAvatar,
    UploadBtn,
  },
  data: () => ({
    activeTab: null,
    formLoading: false,
    form: {
      avatar: null,
      firstName: "",
      lastName: "",
      birthDate: "",
      email: "",
      localeId: "",
    },
    languages: [],
    avatarImage: "",
    menuBirthDate: false
  }),
  computed: {
    ...mapState("auth", { currentUser: "authUser" }),
    environmentType() {
      return process.env.NODE_ENV;
    },
    birthDateFormat() {
      return this.form.birthDate
        ? format(new Date(this.form.birthDate), "dd.MM.yyyy")
        : "";
    },
    minDateOfBirthFromToday() {
      return format(sub(new Date(), { years: 18 }), "yyyy-MM-dd");
    },
  },
  mounted() {
    localizationService
      .locales()
      .then((resp) => {
        const languages = resp.data || {};

        this.languages = Object.keys(languages).map((id) => {
          return { value: id, text: languages[id] };
        });
      })

    this.form.firstName = this.currentUser.firstName;
    this.form.lastName = this.currentUser.lastName;
    this.form.birthDate = this.currentUser.birthDate;
    this.form.email = this.currentUser.email;

    this.avatarImage = this.currentUser.avatar;
  },
  watch: {
    menuBirthDate (val) {
      val &&
      setTimeout(() => (this.$refs.birthDatePicker.activePicker = "YEAR"));
    },
  },
  methods: {
    ...mapActions("auth", ["getAuthUser"]),
    onFilePick(url, file) {
      this.avatarImage = url;
      this.form.avatar = file;
    },
    onInputBirthDate(value) {
      this.form.birthDate = value;
      this.menuBirthDate = false;
    },
    save() {
      const formData = new FormData();

      Object.keys(this.form).forEach((field) => {
        const value = this.form[field];

        if (!value) return;

        if (Array.isArray(value)) {
          value.map((item) => formData.append(field + "[]", item));
        } else {
          formData.set(field, value);
        }
      });

      formData.set("_method", "PUT");

      this.formLoading = true;
      userService
        .updateGeneralInfo(formData)
        .then(() => {
          this.getAuthUser();
          this.$snackbar.show({
            message: this.$t("messages.successfully_sent"),
            color: "success",
            timeout: 2000,
          });
        })
        .catch((error) => {
          if (!error.response) console.log(error);

          const responseData = error.response.data;
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message)
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });

          Object.keys(errors).forEach((fieldName) =>
            this.$refs.form.setErrors({ [fieldName]: errors[fieldName] })
          );
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    openSignatureDialog() {
      this.$refs.signatureDialog.open()
    },
    signatureSaved() {
      this.getAuthUser();
    }
  },
};
</script>

<style scoped></style>
