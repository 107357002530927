<template>
  <v-dialog
    v-model="dialog"
    width="500"
    scrollable
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    @click:outside="close"
    @keydown.esc="close"
    eager
  >
    <v-card max-height="800">
      <v-toolbar class="mb-2 flex-grow-0" dark color="primary" dense>
        <v-toolbar-title>{{ $t('label.daily_report_as_pdf') }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-date-picker
        v-model="dates"
        :key="refreshPicker"
        full-width
        multiple
        no-title
        first-day-of-week="1"
        :allowed-dates="date => dateWithData.includes(date)"
        :disabled="loadingWorkedtime"
        @update:picker-date="monthChange"
      />

      <v-divider/>

      <v-card-text>
        <div class="my-5" v-if="isThereSelectedDaysToGenerateAReport">
          {{ $t('messages.teacher_daily_report_worked_hours_info') }}
          <br />
          {{ $t('messages.teacher_daily_report_signature_info') }}
        </div>

        <v-list v-else>
          <v-subheader>{{ $t('label.teacher_daily_report_details') }}</v-subheader>

          <v-list-item v-for="(workTime, date) in workedTimesBySelectedDates" :key="date">
            <v-list-item-content>
              <v-list-item-title>{{ date | dateFormat('dd.MM.yyyy') }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ $t('label.driving_working_time') }}: {{ workTime.drivingWorkedMinutes }},
                {{ $t('label.overall_working_time') }}: {{ workTime.totalWorkedMinutes }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn small text color="error" @click="removeDate(date)">{{ $t('btn.remove') }}</v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn @click="close" text>{{ $t("btn.close") }}</v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          download
          @click="getReport"
          :loading="loadingReports"
          :disabled="loadingReports || isThereSelectedDaysToGenerateAReport"
        >
          <v-icon left>mdi-file-document</v-icon>
          {{ $t("form.generate") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import teachersService from "@/services/teachersService";
import user from "@/utils/mixins/user";
import FileSaver from "@/helpers/fileSaver";
import { format, max, min, parseISO } from "date-fns";

export default {
  name: "TeacherDailyEvents",
  mixins: [user],
  data() {
    return {
      loadingWorkedtime: false,
      loadingReports: false,
      dialog: false,
      dates: [],
      loadedMonths: [],
      workTimeByDate: [],
      refreshPicker: false
    };
  },
  props: {
    teacher: {
      type: Object,
      require: true,
    },
  },
  computed: {
    dateWithData() {
      return Object.keys(this.workTimeByDate).filter(date => {
        return this.workTimeByDate[date].drivingWorkedMinutes > 0 ||
          this.workTimeByDate[date].totalWorkedMinutes > 0
      })
    },
    isThereSelectedDaysToGenerateAReport() {
      return Object.keys(this.workedTimesBySelectedDates).length === 0
    },
    workedTimesBySelectedDates () {
      return Object.keys(this.workTimeByDate)
      .sort((prev, next) => new Date(prev) - new Date(next))
      .filter(key => this.dates.includes(key))
      .reduce((obj, key) => {
        obj[key] = this.workTimeByDate[key];
        return obj;
      }, {})
    },
    fileName() {
      if (this.dates.length === 0) return "";

      const parsedISODates = this.dates.map(date => parseISO(date));
      const minDate = min(parsedISODates);
      const maxDate = max(parsedISODates);

      let fileName = `Tagesübersicht ${this.teacher.fullName} ${format(minDate, "dd.MM.yyyy")}`;

      if (this.dates.length > 1) {
        fileName = fileName + ` ${format(maxDate, "dd.MM.yyyy")}`;
      }

      return fileName;
    }
  },
  methods: {
    open() {
      this.dialog = true

      this.refreshDatePicker()
    },
    close() {
      this.dialog = false

      setTimeout(() => {
        this.refreshDatePicker()

        this.loadedMonths = []
        this.workTimeByDate = []
        this.dates = []
      }, 200)
    },
    refreshDatePicker() {
      this.$nextTick(() => (this.refreshPicker = !this.refreshPicker));
    },
    loadWorkedTime(yearMonth = null) {
      if (this.loadedMonths.includes(yearMonth)) return

      const date = (new Date(yearMonth + '-1'))
      const year = date.getFullYear()
      const month = date.getMonth() + 1

      this.loadingWorkedtime = true
        teachersService
        .workedTime(this.teacher.id, { year, month })
        .then(response => {
          const responseData = response.data || []

          this.workTimeByDate = { ...responseData, ...this.workTimeByDate }

          if (yearMonth !== null) {
            this.loadedMonths.push(yearMonth)
          }
        })
        .finally(() => {
          this.loadingWorkedtime = false
        })
    },
    changeDate(val) {
      this.dates = val;
    },
    getWorkTimeByMonth() {
      teachersService.workTime(this.teacher)
    },
    getReport: async function () {
      this.loadingReports = true;
      teachersService
        .downloadDailyWorkTimeReport(this.teacher.id, { dates: this.dates })
        .then(response => {
          this.saveAsFile(response.data)
          this.close();
        })
        .catch(error => {
          const response = error.response || {}
          const responseData = response.data || {};

          if (responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error", });
          }
        })
        .finally(() => (this.loadingReports = false));
    },
    saveAsFile(file) {
      const fileSaver = new FileSaver([file])
      fileSaver.setType("application/pdf").saveToDevice(`${this.fileName}.pdf`);
    },
    monthChange(yearMonth) {
      if (! this.dialog) return

      this.loadWorkedTime(yearMonth)
    },
    removeDate(date) {
      this.dates = this.dates.filter(item => item !== date)
    }
  },
};
</script>

<style scoped></style>
