<script>
import i18n from "@/plugins/i18n";
import studentsService from "@/services/studentsService";

export default {
  name: "FileUploadDialog",
  props: {
    studentId: {
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      fileType: null,
      documentsTypes: {
        BIOMETRIC_PASSPORT_PICTURE: i18n.t("label.biometric_passport"),
        FIRST_AID: i18n.t("label.first_aid"),
        EYE_TEST_CERTIFICATE: i18n.t("label.eyes_test"),
      },
      form: {
        key: null,
        title: '',
        file: null,
      },
      emptyForm: {
        key: null,
        title: '',
        file: null,
      },
      uploading: false,
    }
  },
  methods: {
    open(fileType = null) {
      this.fileType = fileType
      this.dialog = true
      if (fileType && fileType in this.documentsTypes) {
        this.form.title = this.documentsTypes[fileType]
        this.form.key = fileType
      }
      this.$nextTick(() => {
        this.$refs.form.reset()
      })
    },
    close() {
      this.fileType = null;
      this.dialog = false;
      this.form = {...this.emptyForm}
    },
    uploadFile() {
      const formData = new FormData();

      if (this.fileType && this.fileType in this.documentsTypes) {
        formData.set("key", this.fileType);
      }

      formData.set("title", this.form.title);
      formData.set("file", this.form.file);

      this.uploading = true
      studentsService
          .uploadDocument(this.studentId, formData)
          .then(resp => {
            if (! resp) {
              throw new Error('File too large')
            }

            this.$emit('fileUploaded', resp.data)
            this.close()
          })
          .catch(error => {
            this.$refs.form.reset()

            const responseData = error.response?.data || {};
            const errors = responseData?.errors || [];

            if (Object.keys(errors).length === 0 && responseData.message) {
              this.$snackbar.show({ message: responseData.message, color: "error", });
            }

            this.$refs.form.setErrors(errors);
            console.log(error)
          })
          .finally(() => {
            this.uploading = false;
          })
    }
  }
}
</script>

<template>
<v-dialog v-model="dialog" max-width="600" persistent @keydown.esc="close">
  <v-card tile>
    <v-toolbar class="mb-2 flex-grow-0" dark color="primary" dense>
      <v-toolbar-title>{{ $t("label.upload_a_file") }}</v-toolbar-title>
      <v-spacer/>
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pt-4">
      <ValidationObserver ref="form">
        <ValidationProvider v-slot="{ errors }" name="title">
          <v-text-field
            :label="$t('form.title')"
            :placeholder="$t('form.title')"
            outlined
            v-model="form.title"
            :error-messages="errors"
            :disabled="fileType in documentsTypes"
          />
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" name="file">
          <v-file-input
            v-model="form.file"
            :error-messages="errors"
            :placeholder="$t('documents.upload_document')"
            :label="$t('documents.upload_document')"
            outlined
            prepend-icon=""
            prepend-inner-icon="mdi-attachment"
          />
        </ValidationProvider>
        {{ $t('label.allowed_files') }}
      </ValidationObserver>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn text>{{ $t('btn.close') }}</v-btn>
      <v-spacer />
      <v-btn color="primary" @click="uploadFile" :loading="uploading" :disabled="uploading">
        <v-icon left>mdi-file-upload</v-icon>
        {{ $t('btn.upload') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<style scoped>

</style>