<template>
<v-container :fluid="$vuetify.breakpoint.lgAndDown">
  <DeletedAtAlert v-if="item.isDeleted" :entity="item" entityType="vehicle" />

  <v-row v-if="$vuetify.breakpoint.mobile" dense>
    <v-col cols="12">
      <VehicleInfoCard :vehicle-id="item.id" orientation="horizontal" />
    </v-col>
  </v-row>

  <v-row dense>
    <v-col cols="12">
      <div class="d-flex">
        <div v-if="!$vuetify.breakpoint.mobile" style="width: 300px">
          <VehicleInfoCard :vehicle-id="item.id" />

          <v-list
              class="mt-2 mb-2"
              dense
              nav
              width="100%"
              color="white"
              elevation="1"
              rounded
          >
            <v-list-item
                @click="downloadDriverAssistantSystemsReport(item)"
                :disabled="item.isDeleted || loadingDriverAssistantSystemsReport"
            >
              <v-list-item-icon><v-icon>mdi-file-document</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("btn.fas_pdf") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="editItem(item)" :disabled="item.isDeleted">
              <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("btn.edit") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="isSchoolAdministrator" @click="deleteItem(item)" :disabled="item.isDeleted">
              <v-list-item-icon><v-icon>mdi-archive-arrow-down</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("btn.archive") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-card class="mt-2">
              <v-card-title>{{$t('label.assigned_teachers')}}</v-card-title>

              <v-card-subtitle v-if="itemTeachers.length > 5">
                  {{ itemTeachers.slice(0, 5).length }} {{ $t('label.of') }} {{ itemTeachers.length }}
              </v-card-subtitle>

              <v-divider/>

              <v-card-text class="pa-0">
                  <empty-box v-if="itemTeachers.length === 0"></empty-box>

                  <v-list class="pa-0" v-else dense>
                      <v-list-item v-for="teacher in itemTeachers.slice(0, 5)" :key="teacher.id">
                          <v-list-item-content>
                              <v-list-item-title v-text="teacher.fullName"/>
                          </v-list-item-content>
                      </v-list-item>
                  </v-list>
              </v-card-text>

              <v-card-actions>
                  <v-btn
                    class="flex-grow-1 my-1"
                    small
                    color="primary"
                    outlined
                    @click="openAssignTeacherDialog"
                    :disabled="item.isDeleted"
                  >
                  <v-icon left>mdi-account-multiple</v-icon>
                      {{ $t('btn.edit') }}
                  </v-btn>
              </v-card-actions>
            </v-card>
          </div>

          <div :class="{ 'pl-3': !$vuetify.breakpoint.mobile }" :style="$vuetify.breakpoint.mobile ? 'width: 100%;' : 'width: calc(100% - 300px);'">
            <v-card v-show="$route.name === 'vehicles.vehicle'">
              <v-toolbar class="px-2" elevation="3">
                <v-btn class="mr-4" :to="{name: 'vehicles'}" icon exact>
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>

                <span class="title">{{ $t("btn.back") }}</span>
              </v-toolbar>

              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" lg="4" v-for="tab in tabs" :key="tab.value">
                    <component
                      :is="tab.component"
                      :student-id="item.id"
                      :disabled="item.isDeleted"
                      :params="tab.params"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <router-view />
          </div>
      </div>
    </v-col>
  </v-row>

  <VehicleFormDialog ref="formItem" />
  <AssignTeacherDialog ref="assignTeacherDialog" />
  <VehicleMileageDialog ref="vehicleMileageDialog" />
  <VehicleRefuelDialog ref="vehicleRefuelDialog" />
  <VehicleExpensesDialog ref="vehicleExpensesDialog" />
</v-container>
</template>

<script>
import DeletedAtAlert from '@/components/student/DeletedAtAlert.vue'
import VehicleAvatar from '@/components/VehicleAvatar.vue'
import VehicleMileageCard from '@/components/vehicle/cards/VehicleMileageCard.vue'
import VehicleFuelingCard from '@/components/vehicle/cards/VehicleFuelingCard.vue'
import VehicleServiceCard from '@/components/vehicle/cards/VehicleServiceCard.vue'
import VehicleReservationsCard from '@/components/vehicle/cards/VehicleReservationsCard.vue'
import VehicleExpensesCard from '@/components/vehicle/cards/VehicleExpensesCard.vue'

import user from '@/utils/mixins/user'
import VehicleFormDialog from '@/components/vehicle/VehicleFormDialog.vue'
import vehicleService from '@/services/vehiclesService'
import VehicleModel from '@/store/models/VehicleModel'
import TeacherModel from '@/store/models/TeacherModel'
import UserAvatar from '@/components/UserAvatar.vue'
import AssignTeacherDialog from '@/components/vehicle/AssignTeacherDialog.vue'
import VehicleMileageDialog from "@/components/vehicle/VehicleMileageDialog.vue";
import VehicleRefuelDialog from "@/components/vehicle/VehicleRefuelDialog.vue";
import VehicleExpensesDialog from "@/components/vehicle/VehicleExpensesDialog.vue";
import VehicleInfoCard from "@/components/vehicle/VehicleInfoCard.vue";
import { mapGetters } from 'vuex'
import FileSaver from "@/helpers/fileSaver";
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: 'Vehicle',
  components: {
    VehicleInfoCard,
    VehicleExpensesDialog,
    VehicleRefuelDialog,
    VehicleMileageDialog,
    AssignTeacherDialog,
    UserAvatar,
    VehicleFormDialog,
    VehicleAvatar,
    DeletedAtAlert,
    VehicleMileageCard,
    VehicleFuelingCard,
    VehicleReservationsCard,
    VehicleExpensesCard
  },
  mixins: [user],
  data() {
    return {
      deleting: false,
      loadingDriverAssistantSystemsReport: false
    }
  },
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    item() {
      return VehicleModel.find(this.$route.params.id) || {};
    },
    tabs() {
      return [
        { value: "mileage", component: VehicleMileageCard, isShow: true },
        { value: "fueling", component: VehicleFuelingCard, isShow: true },
        { value: "service", component: VehicleServiceCard, isShow: true },
        { value: "reservations", component: VehicleReservationsCard, isShow: this.schoolSettings.isVehicleReservationsEnabled },
        { value: "expenses", component: VehicleExpensesCard, isShow: true }
      ].filter((item) => item.isShow);
    },
    itemTeachers() {
      return TeacherModel
        .query()
        .where(item => this.item.teachersIds.includes(item.id))
        .get()
    }
  },
  beforeRouteEnter(to, from, next) {
    vehicleService
      .loadItem(to.params.id)
      .then((resp) => {
        VehicleModel
          .insert({ data: resp.data || {} })
          .then(() => next());
      })
      .catch((error) => {
        const response = error.response || {};
        const { status, data } = response;

        if (status && [404, 403].includes(status)) {
          next((vm) => {
            vm.$router.push({ name: "vehicles" });
            vm.$snackbar.show({
              message: data ? data.message : "",
              color: "error",
              timeout: 5000
            });
          });
        }
      });
  },
  methods: {
    downloadDriverAssistantSystemsReport(item) {
      this.loadingDriverAssistantSystemsReport = true
      vehicleService.getVehicleDriverAssistanceSystemsPdf(item.id)
        .then((response) => {
          const fileSaver = new FileSaver([response.data])
          fileSaver.setType('application/pdf').saveToDevice(`Fahrerassistenzsystemen-${item.licensePlate}.pdf`)
        })
        .finally(() => {
          this.loadingDriverAssistantSystemsReport = false
        })
    },
    editItem(item) {
      this.$refs.formItem.open(item);
    },
    deleteItem(item) {
      this.deleting = true;
      showConfirm(
        this.$t('btn.confirm'),
        this.$t("messages.are_your_sure_archive"),
        () => {
          vehicleService
            .destroy(item.id)
            .then(() => {
              vehicleService
                .loadItem(item.id)
                .then((resp) =>
                  VehicleModel.update({ where: item.id, data: resp.data || {} })
                );
            })
            .finally(() => {
              this.deleting = false;
            });
        },
        () => {
          this.deleting = false;
        }
      );
    },
    openAssignTeacherDialog() {
      this.$refs.assignTeacherDialog.open(this.item.id)
    }
  }
}
</script>

<style scoped>

</style>
