<template>
  <v-container>
    <v-card class="mb-4">
      <v-toolbar elevation="3">
        <v-card-title>{{
          $t("messages.what_do_you_want_to_restore")
        }}</v-card-title>
        <v-spacer />
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="3">
            <RecyclableStudentsCard />
          </v-col>

          <v-col cols="12" md="3">
            <RecyclableEventsCard />
          </v-col>

          <v-col cols="12" md="3">
            <RecyclableVehiclesCard />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import RecyclableStudentsCard from "@/components/recycle-bin/RecyclableStudentsCard";
import RecyclableEventsCard from "@/components/recycle-bin/RecyclableEventsCard";
import RecyclableVehiclesCard from "@/components/recycle-bin/RecyclableVehiclesCard.vue";

export default {
  name: "RecycleBin",
  components: { RecyclableVehiclesCard, RecyclableEventsCard, RecyclableStudentsCard }
};
</script>

<style scoped></style>
