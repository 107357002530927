<template>
  <div class="d-flex justify-center progress-centered">
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "ProgressCentered",
  props: {
    mini: {
      styles: Object,
    },
  },
};
</script>

<style scoped lang="scss">
.progress-centered {
  width: 100%;
  height: 100%;
  position: absolute;

  .v-progress-circular {
    margin-top: 15%;
  }
}
</style>
