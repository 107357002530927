<template>
  <v-col :cols="cols">
    <ValidationProvider
      name="offices"
      :rules="`${validation ? 'required' : ''}`"
      v-slot="{ errors }">
      <v-select
        :error="errors.length > 0"
        :label="$t('form.office')"
        item-value="id"
        :items="offices"
        v-model="model.officeId"
        :item-text="getOfficeLabel"
        :disabled="disabled"
        outlined
        hide-details
        dense
        clearable
        :append-icon="!disabled ? 'mdi-menu-down' : ''"
        :menu-props="{ offsetY: true }" />
    </ValidationProvider>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OfficesField",
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    cols: {
      type: Number,
      default: 6,
    },
    offices: {
      type: Array,
      required: true,
    },
    validation: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
  },
  methods: {
    getOfficeLabel(item) {
      return item.name;
    },
  },
};
</script>

<style lang="scss" scoped></style>
