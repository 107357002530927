<template>
  <ValidationProvider
    name="offices"
    :rules="`${validation ? 'required' : ''}`"
    v-slot="{ errors }">
    <v-select
      :error="errors.length > 0"
      :label="$t('form.office')"
      item-value="id"
      :items="officesList"
      v-model="officeId"
      :item-text="getOfficeLabel"
      :disabled="disabled"
      outlined
      hide-details
      dense
      clearable
      :append-icon="!disabled ? 'mdi-menu-down' : ''"
      :menu-props="{ offsetY: true }" />
  </ValidationProvider>
</template>

<script>
export default {
  name: "OfficesField",
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    validation: {
      type: Boolean,
      required: true,
    },
    officesList: {
      type: Array,
      required: true,
    },
    officeIdProp: {
      type: Number,
    },
  },
  computed: {
    officeId: {
      set(value) {
        this.$emit("emitOfficeChange", value);
      },
      get() {
        return this.officeIdProp;
      },
    },
  },
  methods: {
    getOfficeLabel(item) {
      return item.name;
    },
  },
};
</script>

<style lang="scss" scoped></style>
