<template>
  <v-expansion-panels accordion disabled>
    <v-expansion-panel v-for="item in count" :key="item">
      <v-expansion-panel-header class="pa-2">
        <v-row>
          <v-col :cols="mdLandscapeAndUp ? 1 : 6">
            <v-skeleton-loader type="avatar"></v-skeleton-loader>
          </v-col>
          <v-col v-if="mdLandscapeAndUp" cols="1">
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
          </v-col>
          <v-col v-if="mdLandscapeAndUp" cols="2">
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
          </v-col>
          <v-col v-if="mdLandscapeAndUp" cols="2"></v-col>
          <v-col>
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import responsive from '@/utils/mixins/responsive'

export default {
  name: "StudentEducationTabLoader",
  mixins: [responsive],
  props: {
    count: {
      type: Number,
      default: 2,
    },
  },
};
</script>

<style scoped></style>
