import i18n from "@/plugins/i18n";
import { Clipboard } from "@capacitor/clipboard";

export default {
  methods: {
    copyToClipboard(value) {
      Clipboard.write({string: value})
        .then(() => {
          this.$snackbar.show({
            message: i18n.t('label.copied_to_clipboard'),
            timeout: 1500,
          });
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    async readFromClipboard() {
      const { value } = await Clipboard.read();

      return value;
    }
  },
};
