import router from "@/router";

export default class OpenStudentDocuments {
    constructor(studentId) {
        this.studentId = studentId;
    }

    run() {
        router.push({ name: "students.student.documents", params: { id: this.studentId } });
    }
}