<template>
  <v-container>
    <v-card :loading="loading">
      <v-toolbar elevation="3">
        <v-card-title>{{ $t('route.serviceTemplates') }}</v-card-title>
        <v-spacer />
        <v-btn text color="primary" @click="openCreateServiceDialog(null)">
          {{ $t('label.create')  }}
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12" lg="3">
            <v-select
              :items="licensesItems"
              :placeholder="$t('label.filter_by_license')"
              item-value="id"
              item-text="name"
              v-model="filter.license"
              solo
              dense
              clearable
              hide-details
            />
          </v-col>
          <v-col cols="12" lg="3">
            <v-text-field
              v-model="filter.name"
              :placeholder="$t('label.filter_by_name')"
              solo
              dense
              clearable
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>

      <template v-if="!loading">
        <v-simple-table v-if="filteredTemplates.length" dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th style="min-width: 160px">{{ $t("list.name") }}</th>
              <th class="text-center">{{ $t("list.licenses") }}</th>
              <th class="text-center">{{ $t("list.tax_rate") }}</th>
              <th class="text-center">{{ $t("list.cost") }}</th>
              <th style="width: 70px"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, key) in filteredTemplates" :key="key" class="table-row">
              <td>{{ item.description }}</td>
              <td class="text-center">{{ licenseName(item.licenseId) }}</td>
              <td class="text-center">{{ item.taxRate }}%</td>
              <td class="text-center">
                {{ item.price | currency }}
              </td>
              <td style="width: 160px">
                <v-chip
                  class="hidden-until-row-hovered mx-2"
                  link
                  label
                  x-small
                  @click="openCreateServiceDialog(item)">
                  <v-icon x-small>mdi-pencil</v-icon>
                </v-chip>
                <v-chip
                  class="hidden-until-row-hovered mx-2"
                  link
                  label
                  x-small
                  @click="deleteTemplate(item.id)">
                  <v-icon x-small>mdi-close</v-icon>
                </v-chip>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

        <empty-box v-else />
      </template>

      <empty-box v-else />
    </v-card>

    <ServiceTemplateDialog
      ref="ServiceTemplateDialog"
      @create="createTableRow"
      @update="updateTableRow"
    />
  </v-container>
</template>

<script>

import studentServiceTemplatesService from "@/services/studentServiceTemplatesService"
import ServiceTemplateDialog from '@/components/service-templates/ServiceTemplateDialog.vue'
import LicenseModel from '@/store/models/LicenseModel'
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: "ServiceTemplates",
  components: { ServiceTemplateDialog },
  data: () => ({
    loading: true,
    filtering: false,
    templates: [],
    filter: {
      license: null,
      name: null,
    }
  }),
  mounted () {
    studentServiceTemplatesService.load()
      .then((resp) => {
        this.templates = resp.data
      })
      .finally(() => {
        this.loading = false
      })
  },
  computed: {
    filteredTemplates() {
      if (!this.filter.license && !this.filter.name) return this.templates

      let filteredItems = [...this.templates]
      if (this.filter.license) {
        filteredItems = filteredItems.filter(item => item.licenseId === this.filter.license)
      }
      if (this.filter.name) {
        filteredItems = filteredItems.filter(item => {
          return item.description.toLowerCase().trim().includes(this.filter.name.toLowerCase().trim())
        })
      }
      return filteredItems
    },
    licensesItems() {
      return LicenseModel.all() || {};
    },
  },
  methods: {
    licenseName(val) {
      const license = LicenseModel.find(val) || {};
      return license.name;
    },
    openCreateServiceDialog(data) {
      this.$refs.ServiceTemplateDialog.open(data);
    },
    createTableRow(service) {
      this.templates.unshift(service)
    },
    updateTableRow(service) {
      const objIndex = this.templates.findIndex(obj => obj.id === service.id);
      this.templates.splice(objIndex, 1, service);
    },
    deleteTemplate(id) {
      showConfirm(
        this.$t('btn.confirm'),
        this.$t("messages.are_your_sure_delete"),
        () => {
          studentServiceTemplatesService.delete(id)
            .then(() => {
              const objIndex = this.templates.findIndex(obj => obj.id === id);
              this.templates.splice(objIndex, 1);
            })
            .finally(() => {
              this.loading = false
            })
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.hidden-until-row-hovered {
  display: none;
}
.table-row:hover > td > .hidden-until-row-hovered {
  display: inline-flex;
}
</style>
