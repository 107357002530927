<template>
  <div v-context-outside="closeMenu">
    <slot :showItemMenu="showItemMenu" />

    <v-menu
      v-if="item.id === itemMenu.itemId"
      v-model="itemMenu.show"
      :position-x="itemMenu.x"
      :position-y="itemMenu.y"
      absolute
      offset-y
      :transition="false"
    >
      <v-sheet class="py-0">
        <v-list class="py-0" dense>
          <slot name="menu">{{ $t("noItems") }}</slot>
        </v-list>
      </v-sheet>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  directives: {
    contextOutside: {
      bind: function (el, binding, vnode) {
        window.event = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("contextmenu", window.event);
      },
      unbind: function () {
        document.body.removeEventListener("contextmenu", window.event);
      },
    },
  },
  name: "RightClickMenu",
  data: () => ({
    itemMenu: {
      itemId: "",
      show: false,
      x: 0,
      y: 0,
    },
  }),
  methods: {
    showItemMenu(e, item) {
      e.preventDefault();

      this.itemMenu = {
        itemId: item.id,
        show: false,
        x: e.clientX,
        y: e.clientY,
      };

      this.$nextTick(() => {
        setTimeout(() => {
          this.itemMenu.show = true;
        }, 50);
      });
    },
    closeMenu() {
      this.itemMenu.show = false;
    },
  },
};
</script>

<style scoped></style>
