<script>
import {defineComponent} from 'vue'
import api from "@/utils/api";
import {Browser} from "@capacitor/browser";
import DemoModeToolTipWrapper from '@/views/DemoModeToolTipWrapper.vue'

export default defineComponent({
  name: "CreditsPurchaseForm",
  components: { DemoModeToolTipWrapper },
  data() {
    return {
      amount: null,
      loading: false,
      pricePerCredit: 5,
    }
  },
  computed: {
    paymentPossible() {
      return this.amount > 0
    },
    isDemoModeEnabled () {
      return this.$store.state.school.isDemoModeEnabled
    },
    buttonActive() {
      return this.paymentPossible && !this.loading && !this.isDemoModeEnabled
    },
    totalNetto () {
      return this.amount * this.pricePerCredit
    },
    totalBrutto () {
      return this.totalNetto * 1.19
    },
  },
  methods: {
    async checkoutSession() {
      this.loading = true;
      this.disabled = true;
      const { data } = await api.post("/school-checkout-session", {
        amount: this.amount,
      });
      return data;
    },
    async checkout() {
      try {
        const session = await this.checkoutSession();
        await Browser.open({
          url: session.url,
          windowName: "_self",
          presentationStyle: "fullscreen",
        });
      } catch (error) {
        const responseData = error.response?.data || {};
        this.$snackbar.error(responseData.message);
      } finally {
        this.loading = false;
        this.disabled = false;
      }
    },
  }
})
</script>

<template>
  <v-card outlined elevation="5">
    <v-img
      :src="require('@/assets/svg/stripe.svg')"
      width="50"
      height="auto"
      aspect-ratio="2.3925"
      contain
      eager
      style="position: absolute; top: 10px; right: 10px; opacity: 0.6" />
    <v-card-title>
      {{ $t("label.add_funds") }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t("label.checkout_with_stripe") }}
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="12" offset="0" md="8" offset-md="2">
          <v-text-field
            ref="amountField"
            :label="$t('label.enter_credits_amount')"
            type="number"
            inputmode="numeric"
            step="5"
            placeholder="0"
            solo
            class="mb-0"
            min="0"
            v-model="amount"
            color="green"
            hide-details
          />
          <v-chip-group class="mt-0">
            <v-chip
              small
              class="ma-2"
              color="primary"
              pill
              v-for="shortcut in [25, 50, 100, 200, 500]"
              :key="shortcut"
              @click="amount = shortcut">
              <v-icon left small class="ml-1 mr-2"> $coins </v-icon>
              {{ shortcut }}
            </v-chip>
          </v-chip-group>
        </v-col>
        <v-col v-if="paymentPossible" cols="12" offset="0" md="8" offset-md="2">
          <table style="width: 100%">
            <tr>
              <td>{{ $t('label.cost_per_credit') }}</td>
              <td style="width: 10%;" class="text-right">{{ pricePerCredit | currency}}</td>
            </tr>
            <tr>
              <td>{{ $t('label.price_without_tax') }}</td>
              <td style="width: 10%;" class="text-right">{{ totalNetto | currency}}</td>
            </tr>
            <tr>
              <td>{{ $t('label.tax_rate') }} 19%</td>
              <td style="width: 10%;" class="text-right">{{ totalBrutto - totalNetto | currency }}</td>
            </tr>
            <tr>
              <th class="text-left">{{ $t('label.total') }}</th>
              <th class="text-right">{{ totalBrutto | currency }}</th>
            </tr>
          </table>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="d-flex justify-center"
          cols="12"
          offset="0"
          md="8"
          offset-md="2">
          <DemoModeToolTipWrapper :disabled="!isDemoModeEnabled" :tooltipText="$t('label.demo_mode_inactive_button')">
            <v-btn large color="secondary" :disabled="!buttonActive" @click="checkout">
              {{ $t("btn.continue_to_checkout") }}
              <v-icon right>mdi-cart-check</v-icon>
            </v-btn>
          </DemoModeToolTipWrapper>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped>
</style>
