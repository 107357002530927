<template>
  <v-container class="d-flex" :fluid="$vuetify.breakpoint.lgAndDown">
    <v-card
      class="menu align-self-start"
      :class="{ 'mobile-menu': !mdLandscapeAndUp, 'mr-2': mdLandscapeAndUp }"
      rounded
      v-if="showMobileMenu || mdLandscapeAndUp">
      <v-list dense>
        <div
          class="d-flex justify-content-between align-center pa-4"
          v-if="!mdLandscapeAndUp"
        >
          <v-badge
            class="mr-4"
            :color="currentUser.color"
            bottom
            overlap
            dot
            offset-x="48"
            offset-y="5"
            size="50"
            :value="('color' in currentUser)"
          >
            <UserAvatar lass="elevation-3" size="60" :path="currentUser.avatar" :alt="currentUser.fullName" />
          </v-badge>

          <div class="d-flex flex-column">
            <span class="text-h6">{{
              currentUser.firstName + " " + currentUser.lastName
            }}</span>
            <span class="text-subtitle-2">{{ currentUser.email }}</span>
          </div>
        </div>

        <v-list-item-group color="primary">
          <v-divider v-if="!mdLandscapeAndUp" />

          <v-list-item :to="{ name: 'personal-settings.profile' }">
            <v-list-item-icon class="ml-2">
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ $t("route.personalSettings.children.profile") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :to="{ name: 'personal-settings.password' }">
            <v-list-item-icon class="ml-2">
              <v-icon>mdi-lock</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ $t("route.personalSettings.children.changePassword") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="isTeacher"
            :to="{ name: 'personal-settings.teacher-settings' }">
            <v-list-item-icon class="ml-2">
              <v-icon>mdi-account-tie</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ $t("route.personalSettings.children.teacher") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="!isElter" :to="{ name: 'personal-settings.notifications' }">
            <v-list-item-icon class="ml-2">
              <v-icon>mdi-bell</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ $t("route.personalSettings.children.notifications") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="isTeacher"
            :to="{ name: 'personal-settings.calendar-settings' }">
            <v-list-item-icon class="ml-2">
              <v-icon>mdi-calendar-edit</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ $t("route.personalSettings.children.calendarSettings") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="isExactlyStudent"
            :to="{ name: 'personal-settings.parents' }">
            <v-list-item-icon class="ml-2">
              <v-icon>mdi-human-male-child</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ $t("route.personalSettings.children.parents") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>

    <v-card
      class="flex-grow-1"
      color="white"
      elevation="1"
      rounded
      v-if="(!mdLandscapeAndUp && !showMobileMenu) || mdLandscapeAndUp"
    >
      <v-fade-transition mode="out-in" :hide-on-leave="$vuetify.breakpoint.mobile">
        <router-view />
      </v-fade-transition>
    </v-card>
  </v-container>
</template>

<script>
import userMixin from "@/utils/mixins/user";
import { mapGetters } from "vuex";
import responsive from "@/utils/mixins/responsive";
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  name: "Index",
  mixins: [userMixin, responsive],
  components: { UserAvatar },
  data: () => ({}),
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.name === "personal-settings") {
        vm.redirectToIndexPage(from)
      }
    });
  },
  beforeRouteUpdate (to, from, next) {
    if (to.name === "personal-settings") {
      this.redirectToIndexPage(from)
    }
    next()
  },
  created() {
    window.addEventListener("orientationchange", () => this.redirectToIndexPage(this.$route));
  },
  beforeDestroy () {
    window.removeEventListener("orientationchange", () => this.redirectToIndexPage(this.$route));
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    showMobileMenu() {
      return this.$route.name === "personal-settings";
    },
  },
  methods: {
    redirectToIndexPage(from) {
      if (this.$route.name !== 'personal-settings') return

      if (from.name !== "personal-settings.profile" && this.mdLandscapeAndUp) {
        this.$router.push({ name: "personal-settings.profile" });
      }
    }
  },
};
</script>

<style scoped lang="scss">
.menu {
  width: 250px;
}

.mobile-menu {
  width: 100% !important;
}
</style>
