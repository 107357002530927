<template>
  <v-dialog
    max-width="750px"
    :value="createEventModalGetter"
    @click:outside="finishEventCreation"
    @keydown.esc="finishEventCreation"
    :fullscreen="isMobile"
    :hide-overlay="isMobile"
    style="padding-top:0!important;"
    scrollable>
    <v-card :tile="isMobile">
      <TopBar
        class="flex-shrink-0 flex-grow-0"
        :eventDuration="eventDuration"
        :color="eventColor"
        :eventType="eventModel.type"
        :happyHour="false" />

      <v-overlay :value="loader" absolute opacity="0.1" z-index="9">
        <v-progress-circular indeterminate size="64" color="primary" />
      </v-overlay>

      <ModalOverlay
        :event-model="eventModel"
        @close="finishEventCreation"
        @update="updateEventModel" />

      <v-card-text>
        <EventTypesSelect v-if="isMobile" @emitTypeChange="typeChanged" />
        <EventTypesTabs v-else @emitTypeChange="typeChanged" />

        <ValidationObserver ref="observer">
          <component
            :is="formComponent"
            :eventDuration="eventDuration"
            :teachers="teachers"
            :filteredTeachers="teachers"
            :isPast="isPast" />
        </ValidationObserver>

        <v-row>
          <v-col>
            <v-alert class="mt-5 mb-0" v-if="alert.status" type="error" dense>
              {{ alert.message }}
              <br />
              <div v-if="alertExtraMessage">
                <ul v-for="(item, index) in alertExtraMessage" :key="index">
                  <li>{{ item.teacherName }} - {{ item.teacherPhone }}</li>
                </ul>
              </div>
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>

      <!--CONTROL BUTTONS-->
      <v-divider v-if="isMobile" />

      <v-card-actions>
        <v-spacer />
        <ModalActionButton
          @buttonClick="eventCancel"
          :label="$t('btn.close')"
          color="black"
          :disabled="false" />
        <template v-if="requestedPractice">
          <ModalActionButton
            v-if="!isPast && eventModel.id"
            @buttonClick="eventDecline(eventModel)"
            :label="$t('btn.decline')"
            color="red"
            :disabled="false" />
          <ModalActionButton
            v-if="!isPast"
            @buttonClick="eventConfirm(eventModel)"
            :label="$t('btn.confirm')"
            color="green"
            :disabled="false" />
        </template>
        <template v-else-if="requestedPlaceholder">
          <ModalActionButton
            v-if="!isPast && eventModel.id"
            @buttonClick="placeholderDecline(eventModel)"
            :label="$t('btn.decline')"
            color="red"
            :disabled="false" />
          <ModalActionButton
            v-if="!isPast"
            @buttonClick="placeholderConfirm(eventModel)"
            :label="$t('btn.confirm')"
            color="green"
            :disabled="false" />
        </template>
        <template v-else-if="eventModel.lessonTypeGroup === 'exam'">
          <ModalActionButton
            v-if="eventModel.id && !hideDeleteButton"
            @buttonClick="eventDelete(eventModel)"
            :label="$t('btn.delete')"
            color="red"
            :disabled="false" />
          <ModalActionButton
            v-if="!hideOkButton"
            @buttonClick="eventSave"
            :label="$t('btn.ok')"
            color="green"
            :disabled="!eventModel.studentsIds" />
        </template>
        <template
          v-else-if="
            eventModel.type === 'SPECIAL' &&
            eventModel.specialType !== 'STANDARD'
          ">
          <ModalActionButton
            v-if="eventModel.id && !hideDeleteButton"
            @buttonClick="eventDelete(eventModel)"
            :label="$t('btn.delete')"
            color="red"
            :disabled="false" />
          <ModalActionButton
            v-if="!hideOkButton"
            @buttonClick="eventSave"
            :label="$t('btn.ok')"
            color="green"
            :disabled="false" />
        </template>
        <template v-else-if="bookedSimulator && !isPast">
          <ModalActionButton
            @buttonClick="simulatorDecline(eventModel)"
            :label="$t('btn.decline')"
            color="red"
            :disabled="false" />
          <ModalActionButton
            @buttonClick="eventSave"
            :label="$t('btn.ok')"
            color="green"
            :disabled="!eventModel.studentsIds" />
        </template>
        <template v-else>
          <LessonMissedMenu
            v-if="showPracticeMissedButton"
            :missedStatus="missedStatus"
            :disabledJoker="disabledJoker"
            @setMiss="setMissedEventStatus(eventModel.id)"
            @refund="refund(eventModel.id, $event)" />
          <ModalActionButton
            v-if="eventModel.id && !hideDeleteButton && !missedStatus"
            @buttonClick="eventDelete(eventModel)"
            :label="$t('btn.delete')"
            color="red"
            :disabled="false" />
          <ModalActionButton
            v-if="!hideOkButton"
            @buttonClick="eventSave"
            :label="$t('btn.ok')"
            color="green"
            :disabled="
              !eventModel.teacher && eventModel.type !== 'SIMULATOR'
            " />
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OfftimeForm from "@/components/calendar/eventModal/forms/OfftimeForm";
import PlaceholderForm from "@/components/calendar/eventModal/forms/PlaceholderForm";
import PracticeForm from "@/components/calendar/eventModal/forms/PracticeForm";
import SimulatorForm from "@/components/calendar/eventModal/forms/SimulatorForm";
import SchoolSpecialForm from "@/components/calendar/eventModal/forms/SchoolSpecialForm";
import TheoryLessonForm from "@/components/calendar/eventModal/forms/TheoryLessonForm";
import TheoryExamForm from "@/components/calendar/eventModal/forms/TheoryExamForm";
import TopBar from "@/components/calendar/eventModal/TopBar";
import LessonMissedMenu from "@/components/calendar/eventModal/LessonMissedMenu";
import ModalActionButton from "@/components/calendar/eventModal/ModalActionButton";

import eventModalMixins from "@/utils/mixins/eventModalMixins";
import happyHourMixins from "@/utils/mixins/happyHourMixins";
import ModalOverlay from "@/components/calendar/eventModal/ModalOverlay";
import { mapGetters, mapState } from "vuex";
import EventTypesTabs from "@/components/calendar/eventModal/EventTypesTabs";
import EventTypesSelect from "@/components/calendar/eventModal/EventTypesSelect";

export default {
  name: "CreateEventModal",
  mixins: [eventModalMixins, happyHourMixins],
  components: {
    EventTypesSelect,
    ModalOverlay,
    ModalActionButton,
    TopBar,
    LessonMissedMenu,
    EventTypesTabs,
    TheoryLessonForm,
    TheoryExamForm,
    OFFTIME: OfftimeForm,
    PLACEHOLDER: PlaceholderForm,
    PRACTICE: PracticeForm,
    SIMULATOR: SimulatorForm,
    SPECIAL: SchoolSpecialForm,
  },
  watch: {
    createEventModalGetter() {
      this.loader = false;
      this.alert.status = false;
      this.alert.message = "";
      this.alert.extra_message = "";
      this.eventModel = this.newEventModelGetter;
    },
  },
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapState("appointments", {
      eventModelUpdated: (state) => state.newEventModel.wasUpdated,
    }),
  },
  methods: {
    typeChanged(type) {
      const lessonsAmount = this.eventModel.lessonsAmount
        ? this.eventModel.lessonsAmount
        : 1;
      const lessonDuration = this.eventModel.lessonDuration
        ? this.eventModel.lessonDuration
        : this.eventModel.duration;
      this.eventModel = {
        ...this.newEventModelGetter,
        type,
        lessonsAmount,
        lessonDuration,
      };
    },
    updateEventModel() {
      this.eventModel = { ...this.newEventModelGetter };
    },
  },
};
</script>

<style lang="scss">
.duration-amount input {
  text-align: center;
  cursor: default;
}
.v-input__prepend-inner,
.v-input__append-inner {
  cursor: pointer;
}
.justify-center {
  display: flex;
  justify-content: space-between;
}
</style>
