<template>
  <div :class="`wrapper ${isClickable ? 'clickable' : ''}`" @click="getPdf">
    <div v-if="holiday" class="holiday-child text-caption text-overflow-ellipsis">
      {{ holiday }}
    </div>
    <div class="d-flex justify-center" v-else>
      <strong :style="`color: ${drivingColor}`">
        <span v-if="!loading">{{ drivingWorkedMinutes }}</span>
        <v-skeleton-loader v-else class="skeleton-item" type="image" width="30" height="10"/>
      </strong>
      <span> / </span>
      <strong :style="`color: ${totalColor}`">
        <span v-if="!loading">{{ totalWorkedMinutes }}</span>
        <v-skeleton-loader v-else class="skeleton-item" type="image" width="30" height="10"/>
      </strong>
    </div>

    <v-btn
        v-if="drivingWorkedMinutes || totalWorkedMinutes"
        class="download-icon"
        color="primary"
        icon
        x-small
        :loading="downloading"
    >
      <v-icon>mdi-download-outline</v-icon>
    </v-btn>
  </div>
</template>

<script>

export default {
  name: "WorkTimeDaySlot",
  data: () => ({
    downloading: false,
  }),
  props: {
    date: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    holiday: {
      type: String,
    },
    drivingWorkedMinutes: {
      type: Number,
      default: 0
    },
    totalWorkedMinutes: {
      type: Number,
      default: 0
    },
  },
  computed: {
    drivingColor() {
      return this.drivingWorkedMinutes === 0
          ? '#858585'
          : this.drivingWorkedMinutes > 495 ? 'red' : 'green'
    },
    totalColor() {
      return this.totalWorkedMinutes === 0
          ? '#858585'
          : this.totalWorkedMinutes > 600 ? 'red' : 'green'
    },
    isClickable() {
      return this.drivingWorkedMinutes > 0 || this.totalWorkedMinutes > 0
    }
  },
  methods: {
    stop() {
      this.downloading = false
    },
    getPdf() {
      if(!this.isClickable || this.disabled) return
      this.downloading = true
      this.$emit("dailyPdf")
    }
  }
};
</script>

<style scoped>
.wrapper{
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  left: 0;
}
.clickable{
  cursor: pointer;
}
.download-icon{
  position: absolute;
  bottom: 2px;
  right: 2px;
}
.holiday-child{
  width: 95%;
  color: red;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.skeleton-item{
  position: relative;
  top: 6px;
  margin: 0 2px;
}
.progress-line{
  position: absolute;
  top: 0;
}
</style>
