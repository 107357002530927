import { format, parseISO } from 'date-fns'
import * as mutationTypes from "./../mutation-types/events"
import router from "@/router"
import Vue from 'vue'
import AppointmentModel from "@/store/models/AppointmentModel"
import StudentModel from '@/store/models/StudentModel'
import AppointmentFields from '@/helpers/appointmentFields'
import Holiday from '@/helpers/holiday'
import clearEvaluation from '@/helpers/appointmentEvaluation'
import appointmentService from "@/services/appointmentService"
import insightsService from '@/services/insightsService'

const defaultAppointmentState = () => {
  return {
    $id: null,
    id: null,
    type: "OFFTIME",
    start: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
    duration: 0,
    lessonDuration: null,
    lessonsAmount: 1,
    teacherId: null,
    studentsIds: [],
    lessonTypeId: null,
    group: null,
    isManualLesson: false,
    status: false,
    licenseId: null,
    allowedLicenses: [],
    notificationLicenses: [],
    hasMultipleRequestsAllowed: false,
    primaryVehicleId: null,
    secondaryVehicleId: null,
    hasSignature: false,
    roomSize: null,
    theoryTopicId: null,
    simulatorName: null,
    officeId: null,
    specialName: null,
    specialType: "STANDARD",
    isWorkTime: false,
    meetingPlace: "",
    notes: "",
    isSubscribed: false,
    isRequested: false,
    copyForNrOfWeeks: null,
    showError: false,
    isAvailableForEvaluation: false,
  }
}

const defaultEventCreationMenu = () => {
  return {
    id: null,
    x: null,
    y: null,
    visible: false,
    tempAppointment: {},
  }
}

const defaultButtonLoaders = () => {
  return {
    createLoader: false,
    updateLoader: false,
    deleteLoader: false,
    requestLoader: false,
    confirmLoader: false,
    declineLoader: false,
    subscribeLoader: false,
    unsubscribeLoader: false,
    signatureLoader: false,
    refundLoader: false,
    jokerRefundLoader: false,
  }
}

const state = () => ({
  showModal: false,
  appointmentLoader: false,
  initialAppointment: null,
  currentAppointment: defaultAppointmentState(),
  eventCreationMenu: defaultEventCreationMenu(),
  buttonLoaders: defaultButtonLoaders(),
  activeEvents: [],
  hoverEvent: null,
  shadowAppointment: false,
  draggedEvent: false,
  hoveredDate: null,
  initialDuration: 0,
  loadingEvent: 0,
  multiDeleteStatus: false,
  multiDeleteEvents: [],
  signatureState: "",
  filters: {},
  datesFilterApplied: false
})

const getters = {
  showModalGetter: (state) => state.showModal,
  currentAppointmentGetter: (state) => state.currentAppointment,
  loadingEventGetter: (state) => state.loadingEvent,
  activeEventsGetter: (state) => state.activeEvents,
  hoverEventGetter: (state) => state.hoverEvent,
  shadowEventGetter: (state) => state.shadowAppointment,
  draggedEventGetter: (state) => state.draggedEvent,
  hoveredDateGetter: (state) => state.hoveredDate,
  createEventModalGetter: (state) => state.createEventModal,
  newEventModelGetter: (state) => state.newEventModel,
  initialDurationGetter: (state) => state.initialDuration,
  eventCreationMenuGetter: (state) => state.eventCreationMenu,
  eventErrorAlertGetter: (state) => state.eventErrorAlert,
  multiDeleteStatusGetter: (state) => state.multiDeleteStatus,
  multiDeleteEventsGetter: (state) => state.multiDeleteEvents,
  activeTeachers(state, getters, rootState, rootGetters) {
    return rootGetters['teachers/activeIds']
  },
  currentUser(state, getters, rootState, rootGetters) {
    return rootGetters['auth/currentUser']
  },
  schoolSettings(state, getters, rootState, rootGetters) {
    return rootGetters['school/school']
  },

  exceptedDays(state, getters) {
    if (['PLACEHOLDER', 'SIMULATOR'].includes(state.currentAppointment.type) && state.currentAppointment.copyForNrOfWeeks) {
      return new Holiday().getHolidaysForPeriod(
        parseISO(state.currentAppointment.start),
        state.currentAppointment.copyForNrOfWeeks,
        getters.schoolSettings.regions?.split(', ')
      )
    } else {
      return []
    }
  }
}

const actions = {
  createAppointment: ({ commit, dispatch, state, getters }, payload)  => {
    commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER, { createLoader: true })
    const normalizedObject = new AppointmentFields({ ...state.currentAppointment, exceptedDays: getters.exceptedDays })
      .formObject(state.currentAppointment.type, 'create')
    const skipObj = payload ? { skipEmptyPlaceholders: payload.skipEmptyPlaceholders } : null

    commit('main/toggle-error-bottom-sheet', {status: 'pending'}, { root: true })
    appointmentService
      .store({ ...normalizedObject, ... skipObj}, state.currentAppointment.type)
      .then((response) => {
        const { items, overlapIds } = response.data
        if (overlapIds) overlapIds.forEach(id => AppointmentModel.delete(id))
        if (payload) AppointmentModel.delete(payload.oldPlaceholderId)
        AppointmentModel.insert({ data: items ? items : response.data || {} })
        dispatch("activateTeacher", response.data.teacherId)
        dispatch("closeAppointmentModal")
      })
      .catch((err) => {
        commit('main/toggle-error-bottom-sheet', { status: 'show', ...err.response.data }, { root: true })
      })
      .finally(() => commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER))
  },

  updateAppointment: ({ commit, dispatch, state, getters })  => {
    commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER, { updateLoader: true })
    const normalizedObject = new AppointmentFields({ ...state.currentAppointment, exceptedDays: getters.exceptedDays })
      .formObject(state.currentAppointment.type, 'update')

    commit('main/toggle-error-bottom-sheet', {status: 'pending'}, { root: true })
    appointmentService
      .update(normalizedObject, state.currentAppointment.type)
      .then((response) => {
        if (clearEvaluation(state.initialAppointment, state.currentAppointment)) {
          insightsService.deleteAppointmentEvaluation(state.currentAppointment.id)
            .then(() => AppointmentModel.update({
                where: state.currentAppointment.id,
                data: { evaluation: { id: null, isPublished: null } }
              })
            )
        }

        const { items, overlapIds } = response.data
        if (overlapIds) overlapIds.forEach(id => AppointmentModel.delete(id))
        AppointmentModel.insert({
          where: state.currentAppointment.id,
          data: items ? items : response.data || {}
        })
        dispatch("activateTeacher", response.data.teacherId)
        dispatch("closeAppointmentModal")
      })
      .catch((err) => commit('main/toggle-error-bottom-sheet', { status: 'show', ...err.response.data }, { root: true }))
      .finally(() => commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER))
  },

  deleteAppointment: ({ commit, dispatch, state }, evaluationClear = false)  => {
    if (evaluationClear) insightsService.deleteAppointmentEvaluation(state.currentAppointment.id)
    commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER, { deleteLoader: true })
    commit('main/toggle-error-bottom-sheet', {status: 'pending'}, { root: true })
    appointmentService
      .delete(state.currentAppointment.id, state.currentAppointment.type)
      .then((response) => {
        AppointmentModel.delete(state.currentAppointment.id)
        if (response.data.created.length) AppointmentModel.insert({ data: response.data.created || {} })
        dispatch("closeAppointmentModal")
      })
      .catch((err) => commit('main/toggle-error-bottom-sheet', { status: 'show', ...err.response.data }, { root: true }))
      .finally(() => commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER))
  },

  requestPlaceholder: ({ commit, dispatch, state }, payload)  => {
    commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER, { requestLoader: true })
    let normalizedObject = new AppointmentFields(state.currentAppointment).formObject(state.currentAppointment.type, 'request')
    normalizedObject = { ...normalizedObject, studentsIds: [payload] }

    commit('main/toggle-error-bottom-sheet', {status: 'pending'}, { root: true })
    appointmentService
      .make_placeholder_request(state.currentAppointment.id, normalizedObject)
      .then((response) => {
        AppointmentModel.update({ where: state.currentAppointment.id,  data: response.data || {} })
        dispatch("activateTeacher", response.data.teacherId)
        dispatch("closeAppointmentModal")
      })
      .catch((err) => commit('main/toggle-error-bottom-sheet', { status: 'show', ...err.response.data }, { root: true }))
      .finally(() => commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER))
  },

  confirmPlaceholder: ({ commit, dispatch, state }, payload)  => {
    commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER, { confirmLoader: true })
    let normalizedObject = new AppointmentFields(state.currentAppointment).formObject(state.currentAppointment.type, 'confirm')
    normalizedObject = { ...normalizedObject, studentsIds: [payload] }

    commit('main/toggle-error-bottom-sheet', {status: 'pending'}, { root: true })
    appointmentService
      .confirm_placeholder_request(state.currentAppointment.id, normalizedObject)
      .then((response) => {
        AppointmentModel.update({ where: state.currentAppointment.id,  data: response.data || {} })
        dispatch("activateTeacher", response.data.teacherId)
        dispatch("closeAppointmentModal")
      })
      .catch((err) => commit('main/toggle-error-bottom-sheet', { status: 'show', ...err.response.data }, { root: true }))
      .finally(() => commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER))
  },

  declinePlaceholder: ({ commit, dispatch, state })  => {
    commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER, { declineLoader: true })

    commit('main/toggle-error-bottom-sheet', {status: 'pending'}, { root: true })
    appointmentService
      .decline_placeholder_request(state.currentAppointment.id)
      .then((response) => {
        AppointmentModel.update({ where: state.currentAppointment.id,  data: response.data || {} })
        dispatch("activateTeacher", response.data.teacherId)
        commit(mutationTypes.UPDATE_APPOINTMENT_FIELD, response.data)
      })
      .catch((err) => commit('main/toggle-error-bottom-sheet', { status: 'show', ...err.response.data }, { root: true }))
      .finally(() => commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER))
  },

  confirmPractice: ({ commit, dispatch, state })  => {
    commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER, { confirmLoader: true })
    const normalizedObject = new AppointmentFields(state.currentAppointment).formObject(state.currentAppointment.type, 'confirm')

    commit('main/toggle-error-bottom-sheet', {status: 'pending'}, { root: true })
    appointmentService
      .confirm_practice_request(state.currentAppointment.id, normalizedObject)
      .then((response) => {
        AppointmentModel.update({ where: state.currentAppointment.id,  data: response.data || {} })
        dispatch("activateTeacher", response.data.teacherId)
        dispatch("closeAppointmentModal")
      })
      .catch((err) => commit('main/toggle-error-bottom-sheet', { status: 'show', ...err.response.data }, { root: true }))
      .finally(() => commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER))
  },

  declinePractice: ({ commit, dispatch, state })  => {
    commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER, { declineLoader: true })

    commit('main/toggle-error-bottom-sheet', {status: 'pending'}, { root: true })
    appointmentService
      .decline_practice_request(state.currentAppointment.id)
      .then(response => {
        const data = response.data || {}

        data.deleted.forEach(id => {
          AppointmentModel.delete(id)
        })

        data.created.forEach(appointment => {
          AppointmentModel.insert({ data: appointment })
        })

        dispatch("activateTeacher", state.currentAppointment.teacherId)
        dispatch("closeAppointmentModal")
      })
      .catch((err) => commit('main/toggle-error-bottom-sheet', { status: 'show', ...err.response.data }, { root: true }))
      .finally(() => commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER))
  },

  subscribeToTheory: ({ commit, dispatch, state })  => {
    commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER, { subscribeLoader: true })

    commit('main/toggle-error-bottom-sheet', {status: 'pending'}, { root: true })
    appointmentService
      .subscribe_to_theory(state.currentAppointment.id, {studentsIds: [getters.currentUser.id]})
      .then((response) => {
        AppointmentModel.update({ where: state.currentAppointment.id,  data: response.data || {} })
        dispatch("activateTeacher", response.data.teacherId)
        dispatch("closeAppointmentModal")
      })
      .catch((err) => commit('main/toggle-error-bottom-sheet', { status: 'show', ...err.response.data }, { root: true }))
      .finally(() => commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER))
  },

  unsubscribeFromTheory: ({ commit, dispatch, state, getters })  => {
    commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER, { unsubscribeLoader: true })

    commit('main/toggle-error-bottom-sheet', {status: 'pending'}, { root: true })
    appointmentService
      .unsubscribe_from_theory(state.currentAppointment.id, {studentsIds: [getters.currentUser.id]})
      .then((response) => {
        AppointmentModel.update({ where: state.currentAppointment.id,  data: response.data || {} })
        dispatch("activateTeacher", response.data.teacherId)
        dispatch("closeAppointmentModal")
      })
      .catch((err) => commit('main/toggle-error-bottom-sheet', { status: 'show', ...err.response.data }, { root: true }))
      .finally(() => commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER))
  },

  subscribeToSimulator: ({ commit, dispatch, state, getters })  => {
    commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER, { subscribeLoader: true })

    commit('main/toggle-error-bottom-sheet', {status: 'pending'}, { root: true })
    appointmentService
      .subscribe_to_simulator(state.currentAppointment.id, {
        licenseId: state.currentAppointment.licenseId,
        lessonTypeId: state.currentAppointment.lessonTypeId,
        studentsIds: [getters.currentUser.id]
      })
      .then((response) => {
        AppointmentModel.update({ where: state.currentAppointment.id,  data: response.data || {} })
        dispatch("activateTeacher", response.data.teacherId)
        dispatch("closeAppointmentModal")
      })
      .catch((err) => commit('main/toggle-error-bottom-sheet', { status: 'show', ...err.response.data }, { root: true }))
      .finally(() => commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER))
  },

  unsubscribeFromSimulator: ({ commit, state })  => {
    commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER, { unsubscribeLoader: true })

    commit('main/toggle-error-bottom-sheet', {status: 'pending'}, { root: true })
    appointmentService
      .unsubscribe_from_simulator(state.currentAppointment.id)
      .then((response) => {
        AppointmentModel.update({ where: state.currentAppointment.id,  data: response.data || {} })
        commit(mutationTypes.UPDATE_APPOINTMENT_FIELD, response.data)
        // dispatch("closeAppointmentModal")
      })
      .catch((err) => commit('main/toggle-error-bottom-sheet', { status: 'show', ...err.response.data }, { root: true }))
      .finally(() => commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER))
  },

  setMissedStatus: ({ commit, state }, evaluationClear = false)  => {
    commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER, { unsubscribeLoader: true })
    commit('main/toggle-error-bottom-sheet', {status: 'pending'}, { root: true })
    appointmentService
      .set_missed_status(state.currentAppointment.id)
      .then((response) => {
        if (evaluationClear) {
          insightsService.deleteAppointmentEvaluation(state.currentAppointment.id)
            .then(() => AppointmentModel.update({
                where: state.currentAppointment.id,
                data: { evaluation: { id: null, isPublished: null } }
              })
            )
        }
        AppointmentModel.update({ where: state.currentAppointment.id,  data: response.data || {} })
        commit(mutationTypes.UPDATE_APPOINTMENT_FIELD, response.data)
      })
      .catch((err) => commit('main/toggle-error-bottom-sheet', { status: 'show', ...err.response.data }, { root: true }))
      .finally(() => commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER))
  },

  refundPractice: ({ commit, state }, payload)  => {
    if (payload) {
      commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER, { jokerRefundLoader: true })
    } else {
      commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER, { refundLoader: true })
    }
    appointmentService
      .refund_practice(state.currentAppointment.id, { useJoker: payload })
      .then((response) => {
        AppointmentModel.update({ where: state.currentAppointment.id,  data: response.data || {} })
        commit(mutationTypes.UPDATE_APPOINTMENT_FIELD, response.data)
        StudentModel.update({
          where: state.currentAppointment.studentsIds[0],
          data: { joker: !payload }
        })
      })
      .finally(() => commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER))
  },

  saveSignature: ({ commit, state }, payload)  => {
    commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER, { unsubscribeLoader: true })

    commit('main/toggle-error-bottom-sheet', {status: 'pending'}, { root: true })
    appointmentService
      .sign(state.currentAppointment.id, { signature: payload })
      .then((response) => {
        AppointmentModel.update({ where: state.currentAppointment.id,  data: response.data || {} })
        commit(mutationTypes.UPDATE_APPOINTMENT_FIELD, response.data)
      })
      .catch((err) => commit('main/toggle-error-bottom-sheet', { status: 'show', ...err.response.data }, { root: true }))
      .finally(() => commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER))
  },

  getSignature: ({ commit, state })  => {
    commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER, { signatureLoader: true })

    commit('main/toggle-error-bottom-sheet', {status: 'pending'}, { root: true })
    appointmentService
      .getSignature(state.currentAppointment.id)
      .then((response) => {
        commit(mutationTypes.SET_SIGNATURE, response.data)
      })
      .catch((err) => commit('main/toggle-error-bottom-sheet', { status: 'show', ...err.response.data }, { root: true }))
      .finally(() => commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER))
  },


  /************************************************************************/
  activateTeacher: ({ commit, getters }, teacherId) => {
    if (teacherId && !getters.activeTeachers.includes(teacherId)) {
      commit('teachers/enable', teacherId, { root: true })
    }
  },
  toggleAppointmentLoader: ({ commit }, payload) => {
    commit(mutationTypes.TOGGLE_APPOINTMENT_LOADER, payload)
  },
  appointmentFilterToggle: ({ commit }, type) => {
    commit(mutationTypes.APPOINTMENT_FILTER_TOGGLE, type)
  },
  eventBeenHovered: ({ commit }, eventId) => {
    commit(mutationTypes.HOVER_EVENT, eventId)
  },
  shadowAppointmentToggle: ({ commit }, status) => {
    commit(mutationTypes.SHADOW_APPOINTMENT_TOGGLE, status)
  },
  dragActionOn: ({ commit }) => {
    commit(mutationTypes.DRAGGED_EVENT_ON)
  },
  dragActionOff: ({ commit }) => {
    commit(mutationTypes.DRAGGED_EVENT_OFF)
  },
  hoverDateAction: ({ commit }, date) => {
    commit(mutationTypes.SET_HOVER_DATE, date)
  },
  eventCreationMenuOn: ({ commit }, payload) => {
    commit(mutationTypes.SHOW_EVENT_CREATION_MENU, payload)
  },
  eventCreationMenuOff: ({ commit }) => {
    commit(mutationTypes.HIDE_EVENT_CREATION_MENU)
    AppointmentModel.delete("AAAAAA")
  },
  showAppointmentModal: ({ state, commit, dispatch }, appointment) => {
    state.initialDuration = appointment.duration
    state.initialAppointment = appointment
    dispatch("updateAppointmentModel", appointment)
    commit(mutationTypes.SHOW_APPOINTMENT_MODAL)
    commit(mutationTypes.HIDE_EVENT_CREATION_MENU)
    if (appointment.id) router.replace({ query: { event_id: appointment.id } }).catch(() => {})
  },
  closeAppointmentModal: ({ commit, dispatch }) => {
    dispatch("eventCreationMenuOff")
    commit(mutationTypes.CLOSE_APPOINTMENT_MODAL)
    commit(mutationTypes.RESET_APPOINTMENT_FORM)
    router.replace({ path: router.currentRoute.path }).catch(() => {})
  },

  updateAppointmentModel: ({ commit }, appointment) => {
    commit(mutationTypes.UPDATE_APPOINTMENT_FIELD, appointment)
  },
  toggleLoadingEvent: ({ commit }, payload) => {
    commit(mutationTypes.LOADING_EVENT_TOGGLE, payload)
  },
  assignEventTypes: ({ commit }, types) => {
    commit(mutationTypes.ASSIGN_EVENT_TYPES, types)
  },
  multiDeleteAdd: ({ commit }, type) => {
    commit(mutationTypes.MULTI_DELETE_ADD, type)
  },
  multiDeleteRemove: ({ commit }, type) => {
    commit(mutationTypes.MULTI_DELETE_REMOVE, type)
  },
  multiDeleteClear: ({ commit }, type) => {
    commit(mutationTypes.MULTI_DELETE_CLEAR, type)
  },
  applyFilters: ({ commit }, filters) => {
    const cleanFilters = {}
    for(const key in filters) {
      if (Array.isArray(filters[key]) && filters[key].length === 0) continue;
      if (
          filters[key] === null ||
          filters[key] === undefined ||
          filters[key] === "" ||
          filters[key] === false
      )
        continue;

      if (!(key in cleanFilters)) {
        cleanFilters[key] = filters[key]
      }
    }
    commit(mutationTypes.SET_FILTER, cleanFilters)
  }
}

const mutations = {
  [mutationTypes.SET_SIGNATURE]: (state, data) => (state.signatureState = data),
  [mutationTypes.TOGGLE_APPOINTMENT_LOADER]: (state, data) => {
    if (data) {
      Vue.set(state, "appointmentLoader", true)
      Object.assign(state.buttonLoaders, data)
    } else {
      Vue.set(state, "appointmentLoader", false)
      Object.assign(state.buttonLoaders, defaultButtonLoaders())
    }
  },
  [mutationTypes.SHOW_APPOINTMENT_MODAL]: (state) => {
    Vue.set(state, "showModal", true)
  },
  [mutationTypes.CLOSE_APPOINTMENT_MODAL]: (state) => {
    Vue.set(state, "showModal", false)
  },
  [mutationTypes.LOADING_EVENT_TOGGLE]: (state, id) =>
    (state.loadingEvent = id),
  [mutationTypes.ASSIGN_EVENT_TYPES]: (state, types) =>
    (state.activeEvents = types.map((type) => type.id)),
  [mutationTypes.APPOINTMENT_FILTER_TOGGLE]: (state, type) => {
    if (!state.activeEvents.includes(type)) {
      state.activeEvents.push(type)
    } else {
      const index = state.activeEvents.indexOf(type)
      state.activeEvents.splice(index, 1)
    }
  },
  [mutationTypes.HOVER_EVENT]: (state, eventId) => (state.hoverEvent = eventId),
  [mutationTypes.SHADOW_APPOINTMENT_TOGGLE]: (state, status) => (state.shadowAppointment = status),
  [mutationTypes.DRAGGED_EVENT_ON]: (state) => (state.draggedEvent = true),
  [mutationTypes.DRAGGED_EVENT_OFF]: (state) => (state.draggedEvent = false),
  [mutationTypes.SET_HOVER_DATE]: (state, date) => (state.hoveredDate = date),

  [mutationTypes.START_APPOINTMENT_CREATION]: (state, newModel) => {
    Object.assign(state.currentAppointment, newModel)
    state.initialDuration = newModel.duration
    state.eventCreationMenu = {
      id: null,
      x: null,
      y: null,
      visible: false,
      tempAppointment: null,
    }
  },
  [mutationTypes.UPDATE_APPOINTMENT_FIELD]: (state, data) => {
    Object.assign(state.currentAppointment, data)
  },
  [mutationTypes.RESET_APPOINTMENT_FORM]: (state) => {
    state.initialDuration = 0
    state.initialAppointment = null
    Object.assign(state.currentAppointment, defaultAppointmentState())
  },
  [mutationTypes.SHOW_EVENT_CREATION_MENU]: (state, eventData) => {
    Object.assign(state.eventCreationMenu, eventData.menu)
  },
  [mutationTypes.HIDE_EVENT_CREATION_MENU]: (state) => {
    Object.assign(state.eventCreationMenu, defaultEventCreationMenu())
  },

  [mutationTypes.MULTI_DELETE_STATUS_TOGGLE]: (state) => {
    state.multiDeleteStatus = !state.multiDeleteStatus
    state.multiDeleteEvents = []
  },
  [mutationTypes.MULTI_DELETE_ADD]: (state, type) => {
    if (!state.multiDeleteEvents.includes(type)) {
      state.multiDeleteEvents.push(type)
    }
  },
  [mutationTypes.MULTI_DELETE_REMOVE]: (state, type) => {
    if (state.multiDeleteEvents.includes(type)) {
      const index = state.multiDeleteEvents.indexOf(type)
      state.multiDeleteEvents.splice(index, 1)
    }
  },
  [mutationTypes.MULTI_DELETE_CLEAR]: (state) => (state.multiDeleteEvents = []),
  [mutationTypes.SET_UPDATED_TYPE]: (state, type) => (state.newEventModel.updateType = type),
  [mutationTypes.SET_FILTER]: (state, filters) => {
    if (filters.startDates) state.datesFilterApplied = true;
    state.filters = filters;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
