<template>
<v-simple-table dense>
  <v-skeleton-loader v-if="loading" class="mx-auto" type="table-row@3" />

  <template v-else>
    <template v-if="items.length > 0">
      <thead>
      <tr>
        <th style="width: 400px;">{{ $t("list.name") }}</th>
        <th>{{ $t("label.address") }}</th>
        <th style="width: 150px;">{{ $t("label.students") }}</th>
        <th style="width: 150px;">{{ $t("label.teachers") }}</th>
        <th style="width: 120px"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in items" :key="item.id" class="table-row">
        <td>{{ item.name }}</td>
        <td>{{ address(item) }}</td>
        <td>
          <span v-if="item.studentsIds.length > 0" class="text-decoration-underline-dotted" @click="openStudentsList(item.studentsIds)">
            {{ `${item.nrOfActiveStudents}/${item.studentsIds.length}` }}
          </span>
          <template v-else>&mdash;</template>
        </td>
        <td>
          <span v-if="item.teachersIds.length > 0" class="text-decoration-underline-dotted" @click="openTeachersList(item.teachersIds)">
            {{ `${item.nrOfActiveTeachers}/${item.teachersIds.length}` }}
          </span>
          <template v-else>&mdash;</template>
        </td>
        <td>
          <v-progress-linear
              indeterminate
              color="primary"
              v-if="[...deleting].includes(item.id)"
          />

          <template v-else>
            <v-chip
                class="hidden-until-row-hovered mx-1"
                link
                label
                x-small
                @click="$emit('edit', item)"
            >
              <v-icon x-small>mdi-pencil</v-icon>
            </v-chip>
            <v-chip
                class="hidden-until-row-hovered mx-1"
                link
                label
                x-small
                :disabled="item.nrOfActiveStudents > 0 || item.nrOfActiveTeachers > 0"
                @click="destroy(item.id)"
            >
              <v-icon x-small>mdi-close</v-icon>
            </v-chip>
          </template>
        </td>
      </tr>
      </tbody>
    </template>

    <v-alert v-else>
      <empty-box />
    </v-alert>
  </template>

<StudentsListDialog ref="studentsDialog" show-active-ratio :show-active-teachers="false" avatar-size="35"/>
<TeachersListDialog ref="teachersDialog" show-active-ratio :show-active-students="false" avatar-size="35"/>
</v-simple-table>
</template>

<script>
import { showConfirm } from '@/helpers/dialogs'

import officesService from '@/services/officesService'
import StudentsListDialog from '@/components/student/StudentsListDialog.vue'
import TeachersListDialog from '@/components/teacher/TeachersListDialog.vue'
import OfficeModel from '@/store/models/OfficeModel'
import searchAddress from '@/utils/mixins/address'

export default {
  name: 'OfficesTable',
  props: {
    searchText: {
      type: String,
      default: ''
    }
  },
  mixins: [searchAddress],
  components: { TeachersListDialog, StudentsListDialog },
  data() {
    return {
      loading: true,
      deleting: []
    }
  },
  computed: {
    items() {
      const query = OfficeModel.query()

      if (this.searchText) {
        query.where(item => {
          return item.name.toLowerCase().includes(this.searchText) || this.searchByFullAddress(this.searchText, item)
        })
      }

      return query.get()
    }
  },
  mounted () {
    this.loading = true
    officesService.load()
      .then((resp) => OfficeModel.create({ data: resp.data || [] }))
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    destroy(id) {
      showConfirm(
        this.$t('btn.confirm'),
        this.$t("messages.are_your_sure_delete"),
          () => {
          this.deleting.push(id)
          officesService
            .destroy(id)
            .then(() => OfficeModel.delete(id))
            .catch((error) => {
              const responseData = error.response.data || {};
              const errors = responseData.errors || [];

              if (Object.keys(errors).length === 0 && responseData.message) {
                this.$snackbar.show({
                  message: responseData.message,
                  color: "error",
                });
              }
            })
            .finally(() => {
              this.deleting = this.deleting.filter(item => item !== id)
            })
        }
      );
    },
    openStudentsList(studentsIds) {
      this.$refs.studentsDialog.open(studentsIds)
    },
    openTeachersList(teachersIds) {
      this.$refs.teachersDialog.open(teachersIds)
    }
  }
}
</script>

<style scoped>

</style>
