import { Dialog } from '@capacitor/dialog';

export async function showAlert(title, message) {
  await Dialog.alert({ title, message });
}

export async function showConfirm(title, message, callback, okButtonTitle = null, cancelButtonTitle = null) {
  const confirmed = await Dialog.confirm({ title, message, okButtonTitle, cancelButtonTitle });
  if (confirmed.value) {
    callback && callback();
  }
  return confirmed.value
}

export async function showPrompt(title, message) {
  const response = await Dialog.prompt({ title, message });
  return response.cancelled ? false : response.value;
}

