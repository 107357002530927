<template>
  <v-card-text>
    <div>
      <v-row dense class="mb-2">
        <v-col>
          <h4>{{ $t('label.welcome_message_heading') }}</h4>
          <p class="text--secondary mt-1">{{ $t('label.welcome_message_explanation') }}</p>
        </v-col>
      </v-row>

      <ValidationObserver ref="form">
        <ValidationProvider v-slot="{ errors }" name="welcomeTitle">
          <v-text-field
            v-model="welcomeTitle"
            :disabled="loading"
            outlined
            dense
            :placeholder="$t('label.title')"
            :label="$t('label.title')"
            :error-messages="errors"
          />
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" name="welcomeText">
          <v-textarea
            v-model="welcomeText"
            :disabled="loading"
            outlined
            dense
            rows="12"
            :placeholder="$t('label.message')"
            :label="$t('label.message')"
            :error-messages="errors"
          />
        </ValidationProvider>
      </ValidationObserver>
    </div>
  </v-card-text>
</template>

<script>

export default {
  name: 'WelcomeMessage',
  props: {
    loading: {
      type: Boolean
    },
    messageData: {
      type: Object,
    },
  },
  computed: {
    welcomeTitle: {
      set(val) {
        this.$emit('setFieldValue', { welcomeTitle: val })
      },
      get() {
        return this.messageData.welcomeTitle
      }
    },
    welcomeText: {
      set(val) {
        this.$emit('setFieldValue', { welcomeText: val })
      },
      get() {
        return this.messageData.welcomeText
      }
    }
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    setErrors(errors) {
      if (!errors.welcomeText && !errors.welcomeTitle) return
      this.$refs.form.setErrors(errors);
    }
  }
}
</script>

<style scoped></style>
