<template>
  <div>
    <v-alert :type="alertType" dismissible v-if="alertType !== null">
      {{ resultMessage }}
    </v-alert>
    <v-card outlined elevation="5">
      <v-img
        :src="require('@/assets/svg/stripe.svg')"
        width="50"
        height="auto"
        aspect-ratio="2.3925"
        contain
        eager
        style="position: absolute; top: 10px; right: 10px; opacity: 0.6" />
      <v-card-title>
        {{ $t("label.add_funds") }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t("label.checkout_with_stripe") }}
      </v-card-subtitle>
      <v-card-text>
        <template v-if="!isMobileApp">
          <v-row>
            <v-col cols="12" offset="0" md="8" offset-md="2">
              <v-text-field
                ref="amountField"
                :label="$t('label.enter_money_amount')"
                placeholder="0,00"
                solo
                append-icon="mdi-currency-eur"
                class="mb-0"
                hide-details
                v-model="amount"
                v-currency="{
                  currency: null,
                  allowNegative: false,
                  valueRange: { min: 0, max: 10000 },
                  valueAsInteger: false,
                  precision: 2,
                }" />
              <span v-show="showMinAmountNotification" class="red--text">
                {{ $t("label.payment_form_min_amount_notification") }}
              </span>
              <v-chip-group class="mt-0" v-if="shortcuts">
                <v-chip
                  small
                  class="ma-2"
                  :color="
                    shortcut.bonus && isPaymentBonusAvailable
                      ? 'green'
                      : 'primary'
                  "
                  outlined
                  pill
                  v-for="shortcut in shortcutValues"
                  :key="shortcut.value"
                  @click="setAmount(shortcut.value)">
                  {{ shortcut.value }}
                  <v-icon
                    v-if="shortcut.bonus && isPaymentBonusAvailable"
                    right
                    small
                    >mdi-percent</v-icon
                  >
                  <v-icon right small> mdi-currency-eur </v-icon>
                </v-chip>
              </v-chip-group>
              <p v-if="isPaymentBonusAvailable">
                {{ $t("label.payment_form_bonus_explanation") }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="d-flex justify-center"
              cols="12"
              offset="0"
              md="8"
              offset-md="2">
              <v-btn
                large
                color="primary"
                @click="checkout"
                :loading="loading"
                :disabled="checkoutButtonDisabled">
                {{ $t("btn.continue_to_checkout") }}
                <v-icon right>mdi-cash-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row>
            <v-col cols="12" offset="0" md="8" offset-md="2">
              Um Zahlungen vorzunehmen, logge Dich bitte auf der Weboberfläche ein.
            </v-col>
            <v-col
              class="d-flex justify-center"
              cols="12"
              offset="0"
              md="8"
              offset-md="2">
              <v-btn @click="openBrowserForPayment">Zur Zahlung</v-btn>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import api from "@/utils/api";
import { Capacitor } from "@capacitor/core";
import { mapGetters } from "vuex";

import { Browser } from "@capacitor/browser";
import { Device } from "@capacitor/device";

export default {
  name: "PaymentForm",
  props: {
    shortcuts: {
      type: Boolean,
      default: true,
    },
    initial: {
      type: Number,
    },
    platform: {
      type: String,
      default: "web",
    },
  },
  data() {
    return {
      amount: '0,00',
      shortcutValues: [
        { value: 50, bonus: false },
        { value: 100, bonus: false },
        { value: 200, bonus: false },
        { value: 500, bonus: false },
        { value: 1000, bonus: true },
      ],
      stripe: null,
      cardElement: null,
      loading: false,
      disabled: true,
      cardholderName: "",
      alertType: null,
      resultMessage: "",
      device: { platform: "" },
      isPaymentBonusEnabled: false,
    };
  },
  methods: {
    setAmount(val) {
      this.amount = val.toString();
      this.$refs.amountField.focus();
    },
    async checkoutSession() {
      this.loading = true;
      this.disabled = true;
      const { data } = await api.post("/checkout-session", {
        amount: this.numberAmount,
        platform: this.platform,
        reason: this.$route.query.reason,
      });
      return data;
    },
    async checkout() {
      try {
        const session = await this.checkoutSession();
        await Browser.open({
          url: session.url,
          windowName: "_self",
          presentationStyle: "fullscreen",
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.disabled = false;
      }
    },
    openBrowserForPayment() {
      Browser.open({ url: this.paymentUrl, presentationStyle: "fullscreen" });
    },
  },
  async mounted() {
    setTimeout(() => {
      if (this.initial) {
        this.setAmount(this.initial.toString().replace(".", ","))
      }
    }, 200);

    this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);
    this.isPaymentBonusEnabled = this.$store.state.school.isPaymentBonusEnabled;
    this.disabled = false;
    this.device = await Device.getInfo();
  },
  computed: {
    paymentUrl() {
      const platform = Capacitor.getPlatform();
      const env = localStorage.getItem('appenv');
      const baseUrl = env === 'prod' || !env ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_STAGE;
      let url = `${baseUrl}/payment-login.html?platform=${platform}&accessToken=${this.authToken}`;

      const selectedStudentId = localStorage.getItem("parentSelectedStudentId");

      if (this.currentUser.type === "elter" && selectedStudentId) {
        url += `&studentId=${selectedStudentId}`;
      }

      // append the query parameter to the url
      if (this.$route.query) {
        const query = Object.keys(this.$route.query)
          .map((key) => `${key}=${this.$route.query[key]}`)
          .join("&");
        if (query) url += `&${query}`;
      }

      return url;
    },
    numberAmount() {
      return parseFloat(this.amount.replace(".", "").replace(",", ".")) || null;
    },
    checkoutButtonDisabled() {
      return this.disabled || this.amountInvalid;
    },
    amountInvalid() {
      return this.amount === null
        || this.amount === ""
        || this.amount === 0.00
        || this.amount === '0,00'
        || this.amount && this.numberAmount < 1;
    },
    showMinAmountNotification() {
      return this.amount && this.numberAmount < 1 && this.numberAmount > 0;
    },
    isMobileApp() {
      return ["ios", "android", "electron"].includes(this.device.platform);
    },
    isPaymentBonusAvailable() {
      return this.isPaymentBonusEnabled && ! this.currentUser.isPaymentBonusApplied
    },
    ...mapGetters("auth", ["authToken", "currentUser"]),
  },
};
</script>

<style scoped lang="scss">
.payment-method {
  padding: 15px;
  border: solid 1px #d0d0d0;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > span {
    pointer-events: none;
  }

  &.selected {
    border-color: gold;
    border-width: 2px;
    background: #d2ffe4;
  }
}
</style>
