<template>
  <v-list-group
    v-model="expanded"
    no-action
    eager
  >
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title>{{ $t('label.documents') }}</v-list-item-title>
      </v-list-item-content>
    </template>

    <student-credentials-list-item :student="student" v-if="student.oneTimePassword" />

    <StudentDocumentListItem
        v-if="isSchoolAdministrator"
        icon="mdi-file-account-outline"
        :label="$t('label.education_contract').toString()"
        :is-student-deleted="student.isDeleted"
        @click.native="openDocumentsDialog('education_contract')"
    />

    <StudentEducationStatus :student="student" />
    <StudentEducationStatus :student="student" document-type="theory_certificate" />
    <StudentEducationStatus :student="student" document-type="practice_certificate" />

    <StudentDocumentListItem
        icon="mdi-file-document-outline"
        :label="$t('label.apology_letter').toString()"
        :is-student-deleted="student.isDeleted"
        @click.native="openDocumentsDialog('apology_letter')"
    />

    <template v-if="isSchoolAdministrator">
      <StudentDocumentListItem
          v-for="license in licenses"
          :key="license.id"
          icon="mdi-book-education-outline"
          :label="$t('label.certificate_license', { license: license.name }).toString()"
          :is-student-deleted="student.isDeleted"
          @click.native="openDocumentsDialog('certificate_license', license)"
      />
    </template>

    <StudentDocumentsDialog ref="studentDocuments" :student="student"/>
  </v-list-group>
</template>

<script>
import StudentEducationStatus from "@/components/student/StudentEducationStatus.vue";
import LicenseModel from '@/store/models/LicenseModel'
import StudentDocumentsDialog from '@/components/student/StudentDocumentsDialog.vue'
import StudentDocumentListItem from '@/components/student/StudentDocumentListItem.vue'
import StudentCredentialsListItem from "@/components/student/StudentCredentialsListItem.vue";

export default {
  name: "StudentDocumentsWidget",
  components: {StudentCredentialsListItem, StudentDocumentListItem, StudentDocumentsDialog, StudentEducationStatus },
  props: {
    student: {
      type: Object,
      require: true,
    },
    isSchoolAdministrator: {
      type: Boolean,
      require: true,
    },
  },
  data () {
    return {
      expanded: false
    }
  },
  computed: {
    licenses() {
      return LicenseModel.query()
          .whereIdIn(this.student.licensesIds)
          .where('name', name => ['B96', 'B196', 'B197'].includes(name))
          .get()
    }
  },
  methods: {
    openDocumentsDialog(documentType, license) {
      this.$refs.studentDocuments.open(documentType, license)
    }
  }
}
</script>

<style scoped>

</style>
