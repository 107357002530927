<template>
  <v-dialog
    v-model="isOpenChangePasswordModal"
    persistent
    max-width="500"
    @click:outside="close"
    @keydown.esc="close">
    <v-card>
      <v-toolbar class="mb-2" dark color="primary" dense>
        <v-toolbar-title>{{ $t("label.password_one_time") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="py-4">
        <v-alert
            border="left"
            colored-border
            type="warning"
            elevation="2"
        >
          {{ $t("messages.are_your_sure_update_password") }}
        </v-alert>

        <ValidationObserver ref="resetPasswordForm">
          <v-row dense class="mt-5">
            <v-col cols="12">
              <ValidationProvider v-slot="{ errors }" name="password">
                <v-text-field
                  outlined
                  dense
                  v-model="password"
                  :label="$t('form.password_one_time')"
                  :placeholder="$t('form.password_one_time')"
                  type="text"
                  required
                  :error-messages="errors"
                  :hint="$t('messages.system_generate_credentials')"
                  persistent-hint
                  autocomplete="new_password">
                  <template v-slot:append>
                    <v-icon
                      :class="{ 'mr-2': password.length }"
                      @click="refreshPassword"
                      >mdi-refresh</v-icon
                    >
                  </template>
                </v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense v-if="student && !!student.email">
            <v-col cols="12">
              <v-sheet class="py-2 px-3" outlined rounded>
                <v-switch
                  dense
                  inset
                  hide-details
                  class="mt-0"
                  v-model="sendEmail"
                  :label="$t('label.send_by_email')"
                  color="primary" />
              </v-sheet>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-btn text @click="close">
          {{ $t("btn.close") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="updateOnTimePassword"
          :loading="loadingOnTimePassword"
          :disabled="loadingOnTimePassword"
        >
          <v-icon left>mdi-content-save{{sendEmail ? '-move' : ''}}</v-icon>
          {{ $t("btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import studentsService from "@/services/studentsService";
import StudentModel from "@/store/models/StudentModel";
import Password from "@/helpers/password";

export default {
  name: "StudentChangePasswordDialog",
  data: () => ({
    studentId: "",
    password: "",
    sendEmail: false,
    loadingOnTimePassword: false,
    isOpenChangePasswordModal: false,
  }),
  computed: {
    student() {
      return StudentModel.find(this.studentId) || {};
    },
  },
  methods: {
    open(studentId) {
      this.studentId = studentId;
      this.isOpenChangePasswordModal = true;
    },
    close() {
      this.password = "";
      this.isOpenChangePasswordModal = false;
    },
    refreshPassword() {
      this.password = Password.generate(8);
    },
    updateOnTimePassword() {
      this.loadingOnTimePassword = true;
      studentsService
        .updateOnTimePassword(this.student.id, {
          password: this.password,
          sendEmail: this.sendEmail,
        })
        .then(() => {
          this.password = "";

          this.isOpenChangePasswordModal = false;
          studentsService.loadItem(this.student.id).then((response) => {
            const responseData = response.data || {};
            StudentModel.update({ where: this.student.id, data: responseData });
          });
        })
        .catch((error) => {
          const responseData = error.response.data || {};
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });
          }

          this.$refs.resetPasswordForm.setErrors(errors);
        })
        .finally(() => {
          this.loadingOnTimePassword = false;
        });
    },
  },
};
</script>

<style scoped></style>
