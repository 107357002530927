import { Model } from "@vuex-orm/core";
import CategoryModel from '@/store/models/insights/CategoryModel'
import ItemModel from '@/store/models/insights/ItemModel'

export default class TemplateModel extends Model {
  static entity = "InsightsTemplates"

  static fields() {
    return {
      id: this.attr(null),
      name: this.string(""),
      description: this.string(""),
      created: this.attr(null),
      updated: this.attr(null),
      isDeleted: this.boolean(false),
      licenses: this.attr([]),
      categories: this.hasMany(CategoryModel, "templateId"),
      items: this.hasManyThrough(ItemModel, CategoryModel, "templateId", "categoryId"),
      finishedItems: this.attr([])
    };
  }

  get licensesNames () {
    return this.licenses.map(license => {
      return license.name
    })
  }

  isAvailableForLicense(licenseId) {
    return this.licenses.some(license => license.id === licenseId)
  }
}
