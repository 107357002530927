import api from "@/utils/api";

export default {
  load(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/notifications", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  markAsRead(params) {
    return new Promise((resolve, reject) => {
      api
        .put("/notifications/mark-as-read", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  userNotificationsSettings: (userId, params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`notifications/user/${userId}/settings`, { params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  schoolNotificationsSettings: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("notifications/school/settings", { params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  updateUserNotificationsSettings: (userId, params) => {
    return new Promise((resolve, reject) => {
      api
        .put(`notifications/user/${userId}/settings`, params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  updateSchoolNotificationsSettings: (params) => {
    return new Promise((resolve, reject) => {
      api
        .put("notifications/school/settings", params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
};
