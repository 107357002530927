<template>
  <v-card class="mb-5" elevation="0" outlined>
    <v-card-title>{{ $t("label.practice_progress") }}</v-card-title>
    <v-card-subtitle>
      <div v-if="progress.practiceInstructionsShould ">
        {{ $tc('lesson_type.instruction', 2) }} - {{ practiceInstructionAmount }}
        <template v-if="progress.practiceInstructionsShould">/ {{ progress.practiceInstructionsShould }}</template>
        <EducationPracticePopup
          class="ml-1"
          v-if="progress.practices.instructions.length"
          :apps="progress.practices.instructions"
          :lesson-duration="progress.specialLessonDuration"
          :total-duration="progress.practiceInstructionsInMinutes"
        />
      </div>

      <div>
        {{ $tc('lesson_type.normal', 2) }} - {{ practiceNormalAmount }}
        <template v-if="progress.practiceNormalsShould">/ {{ progress.practiceNormalsShould }}</template>
        <EducationPracticePopup
          class="ml-1"
          v-if="progress.practices.normals.length"
          :apps="progress.practices.normals"
          :lesson-duration="progress.normalLessonDuration"
          :total-duration="progress.practiceNormalsInMinutes"
        />
      </div>
    </v-card-subtitle>

    <v-card-text>
      <v-progress-linear
          :value="progress.practiceProgress"
          height="25"
          class="my-2"
          color="secondary"
          rounded
      >
        {{ progress.practiceProgress }}%
      </v-progress-linear>

      <v-divider class="my-4" />

      <div class="mb-3">
        <strong>{{ $tc("label.special_lessons") }}: </strong>
        <strong>{{ totalProgress }}</strong>
      </div>

      <SegmentedProgressBar
          :total-value="progress.practiceOverlandsInMinutes"
          :segment-length="progress.specialLessonDuration"
          :required-segments="progress.practiceOverlandsShould"
          color="red"
          dark
          :label="$tc('lesson_type.overland', 2)"
          class="mb-2"
      >
        <EducationPracticePopup
            slot="label-append"
            class="ml-1"
            v-if="progress.practices.overlands.length"
            :apps="progress.practices.overlands"
            :total-duration="progress.practiceOverlandsInMinutes"
            :lesson-duration="progress.specialLessonDuration"
        />
      </SegmentedProgressBar>

      <SegmentedProgressBar
          :total-value="progress.practiceHighwaysInMinutes"
          :segment-length="progress.specialLessonDuration"
          :required-segments="progress.practiceHighwaysShould"
          :label="$tc('lesson_type.highway', 2)"
          dark
          color="blue"
          class="mb-2"
      >
        <EducationPracticePopup
            slot="label-append"
            class="ml-1"
            v-if="progress.practices.highways.length"
            :apps="progress.practices.highways"
            :total-duration="progress.practiceHighwaysInMinutes"
            :lesson-duration="progress.specialLessonDuration"
        />
      </SegmentedProgressBar>

      <SegmentedProgressBar
          :total-value="progress.practiceNightsInMinutes"
          :segment-length="progress.specialLessonDuration"
          :required-segments="progress.practiceNightsShould"
          dark
          color="indigo"
          :label="$tc('lesson_type.night', 2)"
          class="mb-2"
      >
        <EducationPracticePopup
            slot="label-append"
            class="ml-1"
            v-if="progress.practices.nights.length"
            :apps="progress.practices.nights"
            :total-duration="progress.practiceNightsInMinutes"
            :lesson-duration="progress.specialLessonDuration"
        />
      </SegmentedProgressBar>


      <template v-if="progress.practiceRequiredMTLsShould">
        <v-divider class="my-4"/>

        <div class="text--secondary label flex-shrink-1 text-overflow-ellipsis d-flex">
          <span>{{ $tc('lesson_type.manual_lessons', 2) }}</span>
          <EducationPracticePopup
              class="ml-1"
              v-if="progress.practices.manualLessons.length"
              :apps="progress.practices.manualLessons"
          />
        </div>

        <v-progress-linear :value="mtlProgress" color="orange" height="23" rounded>
          {{ progress.practiceRequiredMTLsIs.toLocaleString('de-De') }}
          <span v-if="progress.practiceRequiredMTLsShould">/{{progress.practiceRequiredMTLsShould}}</span>
        </v-progress-linear>
      </template>

      <v-divider class="my-4"/>

      <div class="mb-3">
        <strong>{{ $t("event_type.SIMULATOR") }} </strong>

        <div class="d-flex">
          {{ $tc("lesson_type.simulator", 2) }} - {{ progress.practiceSimulatorsIs }}
          <EducationPracticePopup
              class="ml-1"
              v-if="progress.practices.simulators.length"
              :apps="progress.practices.simulators"
              is-simulator
          />
        </div>
        <div class="d-flex">
          {{ $tc("lesson_type.vr", 2) }} - {{ progress.practiceVRsIs }}
          <EducationPracticePopup
              class="ml-1"
              v-if="progress.practices.vrs.length"
              :apps="progress.practices.vrs"
              is-simulator
          />
        </div>
      </div>

    </v-card-text>
  </v-card>
</template>

<script>
import EducationPracticePopup from "@/components/student/progress/EducationPracticePopup";
import SegmentedProgressBar from "@/components/SegmentedProgressBar.vue";
import {mapGetters} from "vuex";

export default {
  name: "EducationPracticeProgress",
  components: { SegmentedProgressBar, EducationPracticePopup },
  props: {
    progress: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('school', ['school']),
    practiceInstructionAmount() {
      const { practiceInstructionsIs, practiceInstructionsInMinutes } = this.progress
      if (practiceInstructionsInMinutes === 0) return 0
      return practiceInstructionsIs < 1 ? '<1' : Math.floor(practiceInstructionsIs)
    },
    practiceNormalAmount() {
      const { practiceNormalsIs, practiceNormalsInMinutes } = this.progress
      if (practiceNormalsInMinutes === 0) return 0
      return practiceNormalsIs < 1 ? '<1' : Math.floor(practiceNormalsIs)
    },
    totalProgress() {
      const {
        practiceOverlandsIs, practiceOverlandsShould,
        practiceHighwaysIs, practiceHighwaysShould,
        practiceNightsIs, practiceNightsShould
      } = this.progress
      const isValue = Math.floor(Math.min(practiceOverlandsIs, practiceOverlandsShould)) +
          Math.floor(Math.min(practiceHighwaysIs, practiceHighwaysShould)) +
          Math.floor(Math.min(practiceNightsIs, practiceNightsShould))
      const shouldValue = practiceOverlandsShould + practiceHighwaysShould + practiceNightsShould

      return shouldValue
          ? (isValue + '/' + shouldValue)
          : this.$t('label.not_required')
    },
    mtlProgress () {
      if (this.progress.practiceRequiredMTLsShould === 0) return 0

      return (parseFloat(this.progress.practiceRequiredMTLsIs) * 100) / this.progress.practiceRequiredMTLsShould
    }
  }
};
</script>

<style scoped>
.disabled{
  opacity: .5;
}
</style>
