import { mapActions, mapGetters } from 'vuex'
import laravelEcho from "@/plugins/laravel-echo";
import NotificationModel from "@/store/models/NotificationModel";
import SnackbarModel from "@/store/models/SnackbarModel";
import ChildModel from '@/store/models/ChildModel'
import { EventBus } from '@/EventBus'
import ParentModel from '@/store/models/ParentModel'
import * as authMutationTypes from '@/store/mutation-types/auth'
import StudentModel from "@/store/models/StudentModel";

export default {
  computed: {
    ...mapGetters("auth", ["currentUser", "parentSelectedStudentId", "parentSelectedStudent"]),
    isSchoolAdministrator() {
      return (
        this.currentUser && this.currentUser.type === "school-administrator"
      );
    },
    isTeacher() {
      return this.currentUser && this.currentUser.type === "teacher";
    },
    isStudent() {
      return (this.currentUser && this.currentUser.type === "student" || this.isElterWithSelectedStudent);
    },
    isExactlyStudent() {
      return this.isStudent && !this.isElterWithSelectedStudent
    },
    isStudentRegistered() {
      return (
        this.isStudent && this.currentUser.educationStatus === "registered"
      );
    },
    isElter() {
      return this.currentUser && this.currentUser.type === "elter";
    },
    isElterWithSelectedStudent() {
      return this.isElter && !!this.parentSelectedStudentId;
    },
    isElterWithoutSelectedStudent() {
      return this.isElter && !this.parentSelectedStudentId;
    },
    hasPaymentAbility() {
      return this.currentUser.hasPaymentAbility;
    },
    currentUserOrElterSelectedStudent() {
      return this.isElterWithSelectedStudent ? this.parentSelectedStudent : this.currentUser;
    }
  },
  methods: {
    ...mapActions("auth", ["clearParentSelectedStudent"]),
    listenForStudentBalanceUpdate() {
      laravelEcho
        .private(`user.${this.currentUserOrElterSelectedStudent.userId}`)
        .listen("StudentBalanceUpdated", (response) => {
          this.$store.commit("auth/USER_UPDATE", {
            balance: response.balance,
            availableBalance: response.availableBalance
          });
        })
        .listen("StudentAvailableBalanceUpdated", data => {
          this.$store.commit("auth/USER_UPDATE", {
            balance: data.balance,
            availableBalance: data.availableBalance
          });
        })
      ;
    },
    listenCurrentUserNotifications() {
      if (this.isElter) return

      laravelEcho
        .private(`user.${this.currentUser.userId}`)
        .notification((notification) => {
          NotificationModel.commit((state) => state.totalUnreadCount++);
          NotificationModel.insert({ data: notification })
            .then((response) => {
              const notification = response.notifications[0];
              const isAppointmentsExportListMenuOpen =
                notification.type === "AppointmentsListExportCompletedNotification" && this.$route.name === "appointments";

              if (!this.$store.state.main.showNotificationMenu && !isAppointmentsExportListMenuOpen) {
                this.$snackbar.show({
                  timeout: 4000,
                  type: SnackbarModel.TOAST,
                  icon: notification.icon,
                  title: notification.titleByType,
                  message: notification.messageByType,
                  position: { top: true, right: true },
                });
              }
              const notificationData = notification.data || {}
              if (notification.type === 'StudentDocumentUploaded' && notificationData.requiresStudentSignature) {
                this.$store.commit(
                  `auth/${authMutationTypes.SET_UNSIGNED_DOCUMENTS_COUNT}`,
                  this.currentUser.unsignedDocumentsCount + 1
                );
              }
            });
        });
    },
    listenForSchoolPrivateEvents() {
      if(!this.isSchoolAdministrator) return;

      laravelEcho
        .private(`school-private-channel.${this.currentUser.schoolId}`)
        .listen('SchoolCreditsBalanceUpdated', (data) => {
          this.$store.commit('school/UPDATE_CREDITS_AMOUNT', data.credits);
        })
        .listen('NewAppVersionPublished', (data) => {
          this.$store.dispatch('updateNotificationDialog/setVersion', data.version);
          this.$store.dispatch('updateNotificationDialog/openDialog');
        })
    },
    listenForSchoolChannelEvents() {
      if (this.isElterWithoutSelectedStudent) return;

      laravelEcho
        .private(`school-channel.${this.currentUser.schoolId}`)
        .listen("StudentBalanceUpdated", (response) => {
          StudentModel.update({
            where: response.studentId,
            data: { balance: response.balance, availableBalance: response.availableBalance },
          });
        })
        .listen("StudentAvailableBalanceUpdated", (response) => {
          const { studentId, balance, availableBalance } = response
          StudentModel.update({
            where: studentId,
            data: { balance, availableBalance },
          });
        })
    },
    listenForParentalControlEvents() {
      if (this.isElter) {
        laravelEcho.private(`user.${this.currentUser.userId}`)
          .listen("ChildAccountUnlinked", data => {
            ChildModel.delete(data.studentId)

            if (this.parentSelectedStudentId === data.studentId) {
              this.clearParentSelectedStudent();
              this.$router.push({ name: "children-accounts" });
            }
          })
          .listen('ChildAccountLinked', data => {
            EventBus.$emit('child-account-linked')
            ChildModel.insert({ data })
          })
      }

      if (this.isStudent) {
        laravelEcho.private(`user.${this.currentUser.userId}`)
          .listen("ElterAccountUnlinked", data => {
            ParentModel.delete(data.elterId);
          });
      }
    }
  },
};
