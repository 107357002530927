<template>
  <v-dialog
    @click:outside="closeDialog"
    v-model="eventErrorModal"
    max-width="290">
    <v-card>
      <v-card-title>{{ $t("label.something_gone_wrong") }}</v-card-title>
      <v-card-text>{{ eventErrorMessage }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="closeDialog">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ErrorModal",
  computed: {
    ...mapGetters(["eventErrorModal", "eventErrorMessage"]),
  },
  methods: {
    closeDialog() {
      this.$store.commit("EVENT_ERROR_MODAL_OFF");
    },
  },
};
</script>
