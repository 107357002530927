<template>
  <v-card :disabled="disabled">
    <v-img height="100px" :src="image" eager />
    <v-card-title>{{ $t('label.teacher_work_time') }}</v-card-title>

    <v-card-subtitle>{{ $t('label.teacher_work_time_card_description') }}</v-card-subtitle>

    <v-card-text v-if="!disabled">
      <v-list>
        <v-list-item class="pa-0 mb-2">
          <v-list-item-content class="pa-0">
            <v-list-item-title> {{ $t("label.active") + " " + $tc("label.student_plurable") }}</v-list-item-title>
            <v-list-item-subtitle>{{ activeStudents }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>

    <v-card-actions>
      <v-btn class="ml-auto" small text :to="{ name: `teachers.teacher.work-time` }">
        {{ $t("btn.open") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>

import TeacherModel from '@/store/models/TeacherModel'

export default {
  name: "TeacherWorkTimeCard",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    image: require("@/assets/img/education.jpg"),
  }),
  computed: {
    activeStudents() {
      const teachers = TeacherModel.find(this.$route.params.id) || {}
      return teachers.activeStudentsIds.length
    }
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped></style>
