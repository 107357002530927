<template>
  <div
    class="d-flex align-center justify-center flex-column px-2"
    style="height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));">
    <v-card style="width: 100%" flat class="mb-2">
      <v-card-title>
        {{ currentUser.fullName }}
      </v-card-title>
      <v-card-subtitle>
        {{ currentUser.email }}
      </v-card-subtitle>
      <v-card-text>
        <child-info-card v-if="isElter" />
        <span class="overline d-flex justify-space-between" v-else>
          {{ $t('label.balance') }}
          <BalanceLabel :student="currentUser" />
        </span>
      </v-card-text>
    </v-card>
    <payment-form :platform="platform" style="width: 100%" :initial="initialFormValue" />
  </div>
</template>
<script>
import PaymentForm from "@/components/student/PaymentForm";
import user from "@/utils/mixins/user";
import currency from "../utils/mixins/currency";
import BalanceLabel from "@/components/BalanceLabel.vue";
import ChildInfoCard from "@/components/ChildInfoCard.vue";

export default {
  components: {ChildInfoCard, BalanceLabel, PaymentForm },
  mixins:[user],
  computed: {
    currency() {
      return currency
    },
    platform() {
      return this.$route.query.platform;
    },
    initialFormValue () {
      return this.$route.query.prefill && this.currentUser.balance < 0
          ? parseFloat(Math.abs(this.currentUser.balance))
          : null
    },
  },
};
</script>
