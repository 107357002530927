<template>
  <div>
    <div v-if="!isStudent && requests.length === 1 && requests[0].student">
        {{ requests[0].license.name }}
        <span v-if="showStudentName">- {{ requests[0].student.fullName }}</span>
    </div>

    <div v-if="isStudent">{{ studentLicense }}</div>

    <div class="mt-1 d-flex" v-if="appointment.meetingPlace">
      <v-icon size="19">mdi-map-marker</v-icon>
      <div class="text-caption text-truncate mr-10">{{ appointment.meetingPlace }}</div>
    </div>
  </div>
</template>

<script>
import user from "@/utils/mixins/user";
import StudentModel from "@/store/models/StudentModel";
import LicenseModel from "@/store/models/LicenseModel";

export default {
  name: "PlaceholderCard",
  mixins: [user],
  props: {
    appointment: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    showStudentName () {
      return this.$route.name !== "students.student.agenda"
    },
    requests() {
      return this.appointment.studentsIds.map(request => {
        return {
          student: StudentModel.find(request.id) || null,
          license: LicenseModel.find(request.licenseId) || null
        }
      })
    },
    studentLicense() {
      const item =  this.appointment.studentsIds.find(item => item.id === this.currentUser.id)
      return LicenseModel.find(item.licenseId).name
    }
  }
}
</script>

<style scoped>

</style>
