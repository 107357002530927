<template>
  <v-card
    elevation="0"
    class="event-card"
    @click="eventClicked(event)"
    :style="`border-left: 10px solid ${eventColor.bodyColor};`">
    <div class="flex-time">
      <div>
        {{ event.formattedDate }} | {{ event.startTime }} - {{ event.endTime }}
      </div>
      <div>{{ event.duration }} <v-icon size="small">mdi-clock</v-icon></div>
    </div>
    <div class="font-weight-bold" v-if="event.teacher && !isTeacher">
      {{ event.teacher.fullName }}
    </div>
    <div v-if="!isStudent || (isStudent && !blockedEvent.status)">
      <div
        v-if="
          !isStudent && event.eventType !== 'THEORY' && event.studentsIds.length
        ">
        {{ studentsLabel }}
      </div>
      <div v-if="event.eventType !== 'PRACTICE'">{{ eventLabel }}</div>
      <div v-else>
        <div class="text-overflow-ellipsis">
          {{ licenseName }}
          <span v-if="lessonTypeLabel" class="text-uppercase"
            >— {{ lessonTypeLabel }}</span
          >
          <span v-if="event.manualLesson" class="ml-1">S</span>
        </div>
      </div>
    </div>
    <div v-if="event.eventType === 'SPECIAL'" class="text-overflow-ellipsis">
      {{ event.title }}
    </div>

    <div v-if="event.eventType === 'THEORY'" class="text-overflow-ellipsis">
      <v-icon size="15" v-if="event.theoryTopicId">
        {{
          theoryTopic.type === "basic" ? "mdi-alpha-g-box" : "mdi-alpha-z-box"
        }}
      </v-icon>
      {{ theoryTopicLabel }}
    </div>

    <div class="icon-box d-flex justify-end">
      <v-icon v-if="isSpecialSignature" class="sing-icon" size="15"
        >mdi-signature-freehand</v-icon
      >

      <v-icon v-if="studentHasSubscribed" class="ml-2" size="15"
        >mdi-check-circle</v-icon
      >
      <v-icon
        v-if="isStudent && (requestedPractice || requestedByStudent)"
        class="ml-2"
        size="15"
        >mdi-help-circle</v-icon
      >
      <v-icon
        v-if="
          !isStudent &&
          event.eventType === 'SIMULATOR' &&
          event.studentsIds.length
        "
        class="ml-2"
        size="15"
        >mdi-account-circle</v-icon
      >
      <v-icon
        v-if="
          isStudent &&
          event.eventType === 'SIMULATOR' &&
          event.studentsIds.length
        "
        class="ml-2"
        size="15"
        >mdi-check-circle</v-icon
      >
      <v-icon
        v-if="
          !isStudent &&
          event.eventType === 'PRACTICE' &&
          event.status === 'REQUESTED'
        "
        class="ml-1 icon"
        size="15"
        >mdi-help-circle</v-icon
      >
      <v-icon
        v-if="
          !isStudent &&
          event.eventType === 'PRACTICE' &&
          (event.status === 'MISSED' || event.status === 'MISSED_REFUNDED')
        "
        class="ml-1 icon"
        size="15"
        >mdi-cancel</v-icon
      >

      <EventSubscribedStudents
        v-if="
          !isStudent && event.eventType === 'THEORY' && event.group === 'lesson'
        "
        :label="`${event.studentsIds.length}/${event.roomSize}`"
        :eventColor="eventColor" />
      <EventSubscribedStudents
        v-if="
          !isStudent &&
          event.eventType === 'THEORY' &&
          event.group === 'exam' &&
          event.studentsIds.length
        "
        :label="event.studentsIds.length"
        :eventColor="eventColor" />
      <EventSubscribedStudents
        v-if="
          !isStudent &&
          event.eventType === 'PLACEHOLDER' &&
          event.requests.length
        "
        :label="event.requests.length"
        :eventColor="{}" />
      <EventSubscribedStudents
        v-if="
          !isStudent &&
          event.eventType === 'SPECIAL' &&
          event.studentsIds.length > 1
        "
        :label="event.studentsIds.length"
        :eventColor="eventColor" />
    </div>

    <div v-if="event.meetingPlace" class="text-caption text-truncate">
      {{ event.meetingPlace }}
    </div>

    <div v-if="event.primaryVehicleId" class="vehicles">
      <div
        :style="`background: ${primaryVehicle.color}; color: ${invertColor(
          primaryVehicle.color
        )}`">
        {{ primaryVehicle.label }}
      </div>
      <div
        v-if="event.secondaryVehicleId"
        :style="`background: ${secondaryVehicle.color}; color: ${invertColor(
          secondaryVehicle.color
        )}`">
        {{ secondaryVehicle.label }}
      </div>
    </div>

    <div
      v-if="
        !isStudent && event.eventType === 'PLACEHOLDER' && event.requests.length
      "
      class="pt-1">
      <div
        style="font-size: 0.6rem"
        class="text-overflow-ellipsis"
        v-for="(student, index) in requestedPlaceholderStudents"
        :key="index">
        {{ student }}
      </div>
    </div>
  </v-card>
</template>

<script>
import user from "@/utils/mixins/user";
import { mapActions } from "vuex";
import eventMixin from "@/utils/mixins/eventMixin";
import responsive from "@/utils/mixins/responsive";

export default {
  name: "EventMobile",
  mixins: [user, responsive, eventMixin],
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isSpecialSignature() {
      return this.event.signature;
    },
  },
  methods: {
    ...mapActions("appointments", ["eventOpenAction"]),
    eventClicked(event) {
      if (this.disabled) return;
      this.eventShow(event);
    },
  },
};
</script>

<style lang="scss" scoped>
.event-card {
  padding: 0 5px 5px 5px;
  margin: 5px 0;
  font-size: 0.7rem;
  background: #fff !important;
  .flex-time {
    display: flex;
    justify-content: space-between;
  }
  & > * {
    pointer-events: none;
    font-size: 12px;
    padding: 0 5px;
  }
  .request-mark {
    position: absolute;
    bottom: 3px;
    right: 3px;
  }
  .icon-box {
    position: absolute;
    right: 5px;
    bottom: 5px;
    color: #333;
  }
  .vehicles {
    width: 100%;
  }
}
</style>
