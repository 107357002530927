<script>
export default {
  name: "InsightsTemplateCard",
  props: {
    template: {
      type: Object,
      required: true
    }
  }
}
</script>

<template>
  <v-card outlined>
    <v-card-title>{{ template.name }}</v-card-title>
    <v-card-subtitle>{{ template.description }}</v-card-subtitle>
    <v-card-text>
      <v-chip-group>
        <v-chip v-for="license in template.licensesNames" :key="license" >
          {{ license }}
        </v-chip>
      </v-chip-group>
    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>