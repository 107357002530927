<script>
import ExpandablePanel from "@/components/ExpandablePanel.vue";
import {mapActions, mapGetters} from "vuex";
import SendChatMessageDialog from "@/components/students-directory/bulk-actions/SendChatMessageDialog.vue";

export default {
  name: "BulkStudentsActions",
  components: {SendChatMessageDialog, ExpandablePanel},
  computed: {
    ...mapGetters('studentsDirectory', ['isTreeSelectable', 'selectedTreeItems']),
    noItemsSelected () {
      return this.selectedTreeItems.length === 0
    }
  },
  methods: {
    ...mapActions('studentsDirectory', ['clearSelectedStudents'])
  }
}
</script>

<template>
  <expandable-panel panel-name="bulkStudentActionsPanel" content-in-container>
    <template v-slot:title="{expanded}">
      {{ $t('label.bulk_actions') }}
      <v-badge inline color="grey lighten-1" :value="! noItemsSelected && ! expanded" :content="selectedTreeItems.length"/>
    </template>
    <template v-slot:end>
      <v-btn
        rounded
        outlined
        small
        color="red"
        class="ml-3"
        @click.stop="clearSelectedStudents"
        :disabled="noItemsSelected"
      >
        {{ $t('btn.clear_selection') }}
      </v-btn>
    </template>
    <p>{{ $tc('label.count_students_selected', selectedTreeItems.length, {count: selectedTreeItems.length}) }}</p>
    <v-row dense>
      <v-col cols="12" sm="4" md="4" lg="3">
        <v-list dense :disabled="noItemsSelected">
          <v-subheader>Messaging</v-subheader>

          <send-chat-message-dialog />

          <v-list-item @click.stop two-line>
            <v-list-item-avatar>
              <v-list-item-icon>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Send in-app message</v-list-item-title>
              <v-list-item-subtitle>Sent in seconds to all selected students</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click.stop two-line>
            <v-list-item-avatar>
              <v-list-item-icon>
                <v-icon>mdi-email</v-icon>
              </v-list-item-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Send an email with custom text</v-list-item-title>
              <v-list-item-subtitle>Only students with an set email are eligible</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>

      <v-col cols="12" sm="4" md="4" lg="3">
        <v-list dense :disabled="noItemsSelected">
          <v-subheader>Finances</v-subheader>
          <v-list-item @click.stop two-line>
            <v-list-item-avatar>
              <v-list-item-icon>
                <v-icon>mdi-cash-check</v-icon>
              </v-list-item-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Change price-list</v-list-item-title>
              <v-list-item-subtitle>
                Directly change the price list for selected students.
                Can be set individually or for all at once
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop two-line>
            <v-list-item-avatar>
              <v-list-item-icon>
                <v-icon>mdi-cash-clock</v-icon>
              </v-list-item-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Schedule price-list change</v-list-item-title>
              <v-list-item-subtitle>
                Set a date and a price list to be changed to.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop>
            <v-list-item-icon>
              <v-icon>mdi-autorenew-off</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Manage invoices automation</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>

      <v-col cols="12" sm="4" md="4" lg="3">
        <v-list dense :disabled="noItemsSelected" rounded>
          <v-subheader>Education</v-subheader>
          <v-list-item @click.stop>
            <v-list-item-icon>
              <v-icon>mdi-account-tie-voice</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Assign teachers</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop>
            <v-list-item-icon>
              <v-icon>mdi-account-badge</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Change education status</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop>
            <v-list-item-icon>
              <v-icon>mdi-alphabetical-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Assign license</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>

      <v-col cols="12" sm="4" md="4" lg="3">
        <v-list dense :disabled="noItemsSelected" rounded flat>
          <v-subheader>Notifications</v-subheader>
          <v-list-item-group color="purple">
            <v-list-item @click.stop>
              <v-list-item-icon>
                <v-icon>mdi-cash-minus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Send negative balance notification</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click.stop>
              <v-list-item-icon>
                <v-icon>mdi-cellphone-message</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Custom push notification</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </expandable-panel>
</template>

<style scoped>

</style>