<template>
  <div class="d-flex align-content-center justify-center" style="width: 24px">
    <v-tooltip
      right
      v-if="
        service.appointmentMissing ||
        service.deleted ||
        (service.appointmentCost && service.cost !== service.appointmentCost) ||
        service.appointmentIsMissed ||
        service.appointmentIsRefunded ||
        service.appointmentIsPartlyRefunded
    ">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-if="service.appointmentIsMissed" v-bind="attrs" v-on="on">mdi-call-missed</v-icon>
        <v-icon
            v-else-if="service.appointmentIsRefunded || service.appointmentIsPartlyRefunded"
            v-bind="attrs" v-on="on"
            :color="!service.appointmentIsPartlyRefunded ? 'red' : ''"
        >
          mdi-cash-refund
        </v-icon>
        <v-icon v-else v-bind="attrs" v-on="on" color="red" small>mdi-alert</v-icon>
      </template>
      <span v-if="service.deleted">{{$t("label.service_deleted")}}</span>
      <span v-else-if="service.appointmentMissing">{{$t("label.event_deleted")}}</span>
      <span v-else-if="service.appointmentIsMissed">{{ $t("label.appointment_is_missed") }}</span>
      <span v-else-if="service.appointmentIsPartlyRefunded">
        {{$t("label.appointment_is_partly_refunded")}}
        ({{service.appointmentRefundedAmount | currency}}/{{ service.appointmentCost | currency }})
      </span>
      <span v-else-if="service.appointmentIsRefunded">{{$t("label.appointment_is_refunded")}}</span>
      <span v-else-if="
        service.appointmentCost &&
        service.cost !== service.appointmentCost - service.appointmentRefundedAmount
      ">
        {{ $t("label.price_mismatch") }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "ServiceTooltip",
  props: {
    service: {
      type: Object,
      required: true,
    },
  }
}
</script>

<style scoped>

</style>