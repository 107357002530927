import { Model } from "@vuex-orm/core";

export default class NotificationsSettings extends Model {
  static entity = "schoolsNotificationsSettings";

  static notificationGroups = {
    balance: ["StudentBalanceUpdated"],
    events: [
      "EventCreated",
      "EventChanged",
      "EventDeleted",
      "TheoryCancelled",
      "PlaceholderByLicenseCreated",
    ],
    requests: [
      "EventRequested",
      "EventRequestAccepted",
      "EventRequestDeclined",
      "TheoryAttended",
      "SimulatorSubscribed",
    ],
    reminders: ["EventReminder"],
    documents: ["StudentDocumentUploaded"],
    education_process: ["StudentEducationStarted", "StudentEducationFinished"],
    students: ["StudentRegistered"],
    student_balance: ["StudentReplenishedBalance"],
    birthday_wishes: ["StudentBirthdayWishesNotification"]
  };

  static notificationsByUserType = {
    "school-administrator": [
      "TheoryAttended",
      "SimulatorSubscribed",
      "StudentDocumentUploaded",
      "StudentRegistered",
      "StudentReplenishedBalance"
    ],
    teacher: [
      "EventCreated",
      "EventChanged",
      "EventDeleted",
      "EventRequested",
      "TheoryAttended"
    ],
    student: [
      "EventCreated",
      "EventChanged",
      "EventReminder",
      "TheoryCancelled",
      "EventRequestAccepted",
      "EventRequestDeclined",
      "StudentBalanceUpdated",
      "StudentDocumentUploaded",
      "StudentEducationStarted",
      "StudentEducationFinished",
      "PlaceholderByLicenseCreated",
      "StudentBirthdayWishesNotification"
    ],
  };

  static fields() {
    return {
      id: this.attr(null),
      settingsArea: this.string(""),
      userType: this.string(""),
      notificationGroup: this.string(""),
      sendMethod: this.attr([]),
    };
  }
}
