<template>
  <div>
    <v-row>
      <TeachersField
        v-if="!isTeacher"
        :cols="12"
        :disabled="hasRequests || isPast"
        :model="model"
        :eventDuration="eventDuration"
        :filteredTeachers="filteredTeachers" />
      <SubscribedStudentsField
        :disabled="isPast"
        v-if="hasRequests"
        :studentsList="subscribers"
        :model="model"
        :eventDuration="eventDuration"
        :filteredTeachers="filteredTeachers" />
    </v-row>

    <TimeFields
      :disabled="disabled || isPast"
      :model="model"
      :eventDuration="eventDuration"
      adaptiveTime />
    <AmountDurationFields
      :disabled="disabled || isPast"
      :model="model"
      :eventDuration="eventDuration" />

    <template v-if="hasRequests">
      <v-row>
        <LicenseField :disabled="isPast" :activeLicenses="activeLicenses" />
        <LessonTypesField
          :disabled="disabledLessonType || isPast"
          :model="model"
          eventType="PRACTICE" />
      </v-row>

      <v-row>
        <v-col cols="6">
          <PrimaryVehicleField
            :disabled="primaryDisabled"
            :model="model"
            :vehicles="primaryVehicles" />
        </v-col>
        <v-col cols="6">
          <SecondaryVehicleField
            :disabled="secondaryDisabled"
            :model="model"
            :vehicles="secondaryVehicles" />
        </v-col>
      </v-row>
    </template>

    <v-row v-else>
      <AllowedLicenseField
        :disabled="isPast || !model.teacher"
        :model="model"
        :licenses="allowedLicenses" />
      <PlaceholderNotificationField
        :disabled="isPast || !model.teacher"
        :model="model" />
    </v-row>

    <MeetingPlaceField
      :disabled="disabled || isPast"
      :model="model"
      :validation="false" />
    <TextField :disabled="disabled || isPast" :model="model" />
    <CopyCurrentEvent :model="model" :disabled="hasRequests || isPast" />

    <v-row>
      <v-col cols="12">
        <MultiplePlaceholderSwitch
          :disabled="hasRequests || isPast"
          :model="model" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LicenseModel from "@/store/models/LicenseModel";
import TeacherModel from "@/store/models/TeacherModel";
import SubscribedStudentsField from "@/components/calendar/eventModal/fields/SubscribedStudentsField";
import TeachersField from "@/components/calendar/eventModal/fields/TeachersField";
import TimeFields from "@/components/calendar/eventModal/fields/TimeFields";
import AmountDurationFields from "@/components/calendar/eventModal/fields/AmountDurationFields";
import LicenseField from "@/components/calendar/eventModal/fields/LicenseField";
import AllowedLicenseField from "@/components/calendar/eventModal/fields/AllowedLicenseField";
import PlaceholderNotificationField from "@/components/calendar/eventModal/fields/PlaceholderNotificationField";
import LessonTypesField from "@/components/calendar/eventModal/fields/LessonTypesField";
import MeetingPlaceField from "@/components/calendar/eventModal/fields/MeetingPlaceField";
import MultiplePlaceholderSwitch from "@/components/calendar/eventModal/fields/MultiplePlaceholderSwitch";
import CopyCurrentEvent from "@/components/calendar/eventModal/fields/CopyCurrentEvent";
import TextField from "@/components/calendar/eventModal/fields/TextField";
import activeLicenseMixins from "@/utils/mixins/activeLicenseMixins";
import user from "@/utils/mixins/user";
import happyHourMixins from "@/utils/mixins/happyHourMixins";
import PrimaryVehicleField from "@/components/calendar/eventModal/fields/PrimaryVehicleField";
import SecondaryVehicleField from "@/components/calendar/eventModal/fields/SecondaryVehicleField";
import VehicleModel from "@/store/models/VehicleModel";
import { mapGetters } from "vuex";

export default {
  name: "PlaceholderForm",
  mixins: [activeLicenseMixins, user, happyHourMixins],
  components: {
    SecondaryVehicleField,
    PrimaryVehicleField,
    TeachersField,
    SubscribedStudentsField,
    TimeFields,
    AmountDurationFields,
    LicenseField,
    AllowedLicenseField,
    PlaceholderNotificationField,
    LessonTypesField,
    MeetingPlaceField,
    TextField,
    MultiplePlaceholderSwitch,
    CopyCurrentEvent,
  },
  props: {
    eventDuration: {
      type: Number,
      required: true,
    },
    filteredTeachers: {
      type: Array,
      required: true,
    },
    isPast: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    disabled() {
      return !this.isTeacher && !this.model.teacher;
    },
    disabledLessonType() {
      return !this.model.licenseId;
    },
    hasRequests() {
      return !!(this.model.requests && this.model.requests.length);
    },
    subscribers() {
      return this.model.requests;
    },
    allowedPracticeCreation() {
      return (
        !this.model.teacher ||
        (this.model.teacher && this.model.teacher.studentCanCreatePractice)
      );
    },
    allowedLicenses() {
      const teacher = TeacherModel.find(this.model.teacherId) || {};
      return LicenseModel.query().whereIdIn(teacher.licensesIds).get() || [];
    },
    primaryVehicles() {
      return (
        VehicleModel.query()
          .where(
            (vehicle) =>
              !vehicle.isDeleted || vehicle.id === this.model.primaryVehicleId
          )
          .where("teachersIds", (teachersIds) =>
            teachersIds.includes(this.model.teacherId)
          )
          .where("licensesIds", (licensesIds) =>
            licensesIds.includes(this.model.licenseId)
          )
          .get() || []
      );
    },
    secondaryVehicles() {
      return (
        VehicleModel.query()
          .where("isDeleted", false)
          .where((value) => value.id !== this.model.primaryVehicleId)
          .get() || []
      );
    },
    primaryDisabled() {
      return !this.model.teacherId || !this.model.licenseId || this.isPast;
    },
    secondaryDisabled() {
      return (
        !this.model.teacherId ||
        !this.model.licenseId ||
        !this.model.primaryVehicleId ||
        this.isPast
      );
    },
  },
};
</script>
