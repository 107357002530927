<template>
  <div
    class="list-wrapper"
    v-if="(itemsView === 'list' && mdLandscapeAndUp) || pageView"
  >
    <div class="list">
      <div class="list__header">
        <slot name="header" />
      </div>
      <div class="list__body pt-2 px-2">
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<script>
import responsive from "@/utils/mixins/responsive";
import { mapState } from "vuex";

export default {
  name: "ListView",
  props: {
    pageView: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [responsive],
  computed: {
    ...mapState("main", ["itemsView"]),
  },
};
</script>

<style scoped lang="scss"></style>
