<template>
  <v-dialog v-model="dialog" max-width="1000px" scrollable>
    <v-card>
      <v-card-title class="headline">{{ $t('label.failed_exams_list') }}</v-card-title>
      <v-card-text class="pa-0">
        <v-simple-table v-if="list.length" dense>
          <thead>
          <tr>
            <th style="width: 180px">{{ $t('label.date') }}</th>
            <th style="width: 250px;">{{ $t('label.student') }}</th>
            <th style="width: 50px">{{ $t('label.license') }}</th>
            <th>{{ $t('label.only_notes') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in list" :key="item.id">
            <td>{{ item.date | dateFormat }}</td>
            <td>{{ item.studentName }}</td>
            <td>{{ item.licenseName }}</td>
            <td>{{ item.reason }}</td>
          </tr>
          </tbody>
        </v-simple-table>
        <empty-box v-else />
      </v-card-text>


      <v-card-actions>
        <v-btn text @click="closeDialog">{{ $t('btn.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TeacherFailedExamsListDialog",
  props: {
    list: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>
