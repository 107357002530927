<template>
  <div
    class="d-flex align-center justify-center flex-column"
    style="height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));">
    <v-icon color="red" size="200">mdi-close-circle</v-icon>
    <h1 class="text-h4">Zahlung abgebrochen!</h1>
    <v-btn :to="{ name: 'balance' }" v-if="isMobileApp">Zurück</v-btn>
    <v-btn :href="url" v-else class="mt-16" @click="closeBrowser">Zurück zur App</v-btn>
  </div>
</template>

<script>
import isMobileApp from "@/utils/mixins/isMobileApp";

export default {
  name: "PaymentFailed",
  mixins: [isMobileApp],
};
</script>

<style scoped lang="scss"></style>
