<script>
import {EventBus} from "@/EventBus";
import {defineComponent} from 'vue'
import accountLinkingCodeService from "@/services/accountLinkingCodeService";

export default defineComponent({
  name: "AddChildAccountDialog",
  data: () => ({
    show: false,
    loading: false,
    code: "",
    codeId: null,
    seconds: 0,
    timer: null,
    deleting: false,
  }),
  created () {
    EventBus.$on('child-account-linked', this.accountLinked)
  },
  destroyed () {
    EventBus.$off('child-account-linked', this.accountLinked)
  },
  methods: {
    open() {
      this.show = true;
      this.fetchNewCode();
    },
    async close(deleteCode = true) {
      if (this.codeId && deleteCode) {
        try {
          this.deleting = true
          await accountLinkingCodeService.deleteCode(this.codeId)
        } catch (e) {
          console.log('There was an error deleting the code', e)
        } finally {
          this.deleting = false
        }
      }

      this.$emit('close');
      this.show = false;
      this.stopTimer();
    },
    accountLinked() {
      this.$snackbar.show({
        message: this.$t('messages.successfully_linked'),
        color: 'success',
        timeout: 2000
      });
      this.close(false);
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.seconds--;
        if (this.seconds <= 0) {
          this.stopTimer();
          accountLinkingCodeService.deleteCode(this.codeId).then(() => {
            this.fetchNewCode();
          });
        }
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    async fetchNewCode() {
      this.loading = true;

      try {
        const resp = await accountLinkingCodeService.getCode();
        this.code = resp.data.code.toString();
        this.codeId = resp.data.id;
        this.seconds = resp.data.expiresIn;
      } finally {
        this.loading = false;
      }

      this.startTimer();
    },
  },
  computed: {
    digits() {
      return this.code ? this.code.split("") : null;
    },
    percentage() {
      return (this.seconds / 300) * 100;
    },
    color() {
      return this.seconds > 150 ? "primary" : this.seconds > 60 ? "warning" : "error";
    },
    remainingTime() {
      const minutes = Math.floor(this.seconds / 60);
      const seconds = this.seconds % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }
  },
})
</script>

<template>
  <v-dialog width="500" :value="show" persistent>
    <v-card>
      <v-card-title primary-title>
        <v-toolbar-title>{{ $t('label.add_child') }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon @click="close" :loading="deleting"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-subtitle v-if="!loading">{{ $t("messages.explanation_how_to_link_a_child") }}</v-card-subtitle>
      <v-card-text v-if="loading" class="d-flex align-center justify-center pt-5 pb-10">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-card-text>
      <v-card-text v-else>
        <div class="code d-flex flex-row justify-center align-center">
          <div v-for="(digit, index) in digits" :key="index" class="digit text-monospace">{{ digit }}</div>
        </div>
        <v-progress-linear height="25" :color="color" :value="percentage" dark class="mt-5" >
          {{ remainingTime }}
        </v-progress-linear>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn text @click="close">{{ $t('btn.cancel') }}</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
 .digit {
   border: solid 1px #ccc;
   border-radius: 4px;
   font-size: 30px;
   padding: 5px;
   width: 35px;
   height: 60px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 0 3px;
   background: #eeeeee;
 }
</style>
