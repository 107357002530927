<template>
  <div
      :class="`drop-zone ${focused && 'focused'}`"
      @dragenter.stop="() => focused = true"
      @dragleave.stop="() => focused = false"
  >
    <div class="border d-flex align-center justify-center">
      <div class="content">
        <v-icon color="#ccc">mdi-vector-selection</v-icon>
        <br/>
        {{ $t('label.drop_here') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropZone',
  data: () => ({
    focused: false
  }),
}
</script>

<style lang="scss" scoped>
.drop-zone {
  z-index: 3;
  background: #fff;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;

  &.focused{
    background: #e6f6ff;
    .border{
      border: 1px dashed var(--v-primary-base);
    }
  }
}
.border{
  height: 100%;
  width: 100%;
  border: 1px dashed #ccc;
  border-radius: 5px;
  pointer-events: none;
  color: #ccc;
}
</style>
