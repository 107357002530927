import { Model } from "@vuex-orm/core";

export default class ParentModel extends Model {
  static entity = "parents";

  static fields() {
    return {
      id: this.attr(null),
      name: this.string(""),
      avatar: this.attr(null),
      isPermissionGranted: this.boolean(false),
    };
  }
}
