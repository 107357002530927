<template>
  <v-chip class="mr-2" small :input-value="true">
    <v-icon
      left
      size="15"
      :color="hasGlasses ? 'green' : 'gray'"
      :disabled="!hasGlasses">
      mdi-eye
    </v-icon>
    <v-icon
      left
      size="14"
      :color="hasDocumentsComplete ? 'green' : 'gray'"
      :disabled="!hasDocumentsComplete"
      class="ml-1">
      mdi-file-document-multiple-outline
    </v-icon>
    <v-icon
      right
      size="15"
      :color="hasNotes ? 'green' : 'gray'"
      :disabled="!hasNotes"
      class="ml-1">
      mdi-pencil-outline
    </v-icon>
  </v-chip>
</template>

<script>
export default {
  name: "NotesIcons",
  props: {
    hasGlasses: {
      type: Boolean,
      default: false,
    },
    hasDocumentsComplete: {
      type: Boolean,
      default: false,
    },
    hasNotes: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
