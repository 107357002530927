<template>
  <div>
    <PatchNotesDialog ref="patchNotesDialog" />
  </div>
</template>

<script>
import patchNotesService from "@/services/patchNotesService";
import PatchNotesDialog from "@/components/PatchNotesDialog";
import {Device} from "@capacitor/device";

export default {
  name: "PatchNotesChecker",
  components: {PatchNotesDialog},
  data() {
    return {
      showUpdateChecker: true
    }
  },

  async mounted() {
    const { platform } = await Device.getInfo();

    patchNotesService
      .load({ platform, unseen: true })
      .then(resp => {
        const data = resp.data || [];
        if (data.length && this.$route.name !== "patch-notes") {
          this.$refs.patchNotesDialog.open(data);
        }
      })
      .finally(() => {
        this.showUpdateChecker = false;
      });
  }
}
</script>

<style scoped>
</style>
