<template>
<div>
  <template v-for="student in appointment.students">
    <v-list-item :key="student.id" dense>
      <v-list-item-avatar class="my-0" size="30">
        <UserAvatar size="30" :path="student.avatar" :alt="student.name"/>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{student.name}}</v-list-item-title>
        <v-list-item-subtitle>
          {{student.chosenLicense}}
          <span v-if="appointment.isManualLesson">, {{$t('lesson_type.manual_lessons')}}</span>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-btn
            small
            text
            color="error"
            @click="declinePractice"
            :disabled="declineLoading || appointmentIsPast"
            :loading="declineLoading"
        >
          {{ $t('btn.decline') }}
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </template>
</div>
</template>

<script>
export default {
  name: 'StudentsList'
}
</script>

<style scoped>

</style>
