<template>
  <v-card :loading="loading" :disabled="disabled">
    <v-img height="100px" :src="image" eager />
    <v-card-title>{{ $t("label.notes") }}</v-card-title>

    <v-card-subtitle class="student-card">
      {{ $t('label.student_card_notes_description') }}
    </v-card-subtitle>

    <v-card-text v-if="!disabled">
      <v-skeleton-loader v-if="loading" type="list-item-two-line" />
      <v-list v-else>
        <v-list-item class="pa-0 mb-2">
          <v-list-item-content class="pa-0">
            <v-list-item-title>Details</v-list-item-title>
            <v-list-item-subtitle>
              <v-icon v-if="hasGlasses" small color="green">mdi-check</v-icon>
              {{ $t('label.has_glasses') }},
              <v-icon v-if="hasDocumentsComplete" small color="green">mdi-check</v-icon>
              {{ $t('label.has_documents_complete') }},
              {{ $t('label.notes') }}: {{ notesCount }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>

    <v-card-actions>
      <v-btn
        class="ml-auto"
        small
        text
        :to="{ name: `students.student.notes` }"
      >
        {{ $t("btn.open") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import cardsService from "@/services/cardsService";

export default {
  name: "StudentNotesCard",
  props: {
    studentId: {
      type: [Number, String],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    image: require("@/assets/img/notes.jpg"),
    hasGlasses: false,
    hasDocumentsComplete: false,
    notesCount: 0,
  }),
  watch: {
    disabled: function (value) {
      if (!value) this.init();
    },
  },
  mounted() {
    if (!this.disabled) this.init();
  },
  methods: {
    init() {
      this.loading = true;
      cardsService
        .studentNotes(this.studentId)
        .then((response) => {
          const responseData = response.data || {};
          this.hasGlasses = responseData.hasGlasses;
          this.hasDocumentsComplete = responseData.hasDocumentsComplete;
          this.notesCount = responseData.notesCount;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
