<script>
import StudentEvaluationDialog from '@/components/insights/StudentEvaluationDialog.vue'
import AwaitingEvaluationAppointments from '@/components/widgets/dashboard/AwaitingEvaluationAppointments.vue'
import AppointmentModel from '@/store/models/AppointmentModel'
import user from '@/utils/mixins/user'

export default {
  name: "StudentEvaluations",
  components: { StudentEvaluationDialog, AwaitingEvaluationAppointments },
  mixins: [user],
  props: {
    evaluations: {
      type: Array,
      default: () => ([])
    },
    isSelfEvaluated: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    selfEvaluatedForStudent() {
      return this.isStudent && this.isSelfEvaluated
    },
    mappedEvaluations() {
      return this.evaluations
        .map(evaluation => ({...evaluation, appointment: new AppointmentModel(evaluation.appointmentData)}))
    },
    evaluationsWithAppointments() {
      if (this.selfEvaluatedForStudent) {
        return this.mappedEvaluations.filter((ev) => ev.isPublishedByStudent)
      } else {
        return this.mappedEvaluations
      }
    },

    awaitingEvaluation() {
      return this.mappedEvaluations.filter((ev) => !ev.isPublishedByStudent)
    }
  }
}
</script>

<template>
<v-card class="mb-3" outlined rounded>
  <v-card-title>{{ $t('insights.evaluated_appointments') }}</v-card-title>

  <v-card-text>
    <div class="mb-2" v-if="selfEvaluatedForStudent && awaitingEvaluation.length">
      <div class="text-overline">{{ $t('insights.awaiting_your_evaluation') }}</div>
      <AwaitingEvaluationAppointments :evaluations="awaitingEvaluation" />
    </div>

    <div class="mt-2" v-if="evaluationsWithAppointments.length">
      <div v-if="isStudent && this.isSelfEvaluated" class="text-overline">{{ $t('insights.already_evaluated') }}</div>
      <v-row dense>
        <v-col cols="12" sm="6" mb="4" lg="3" v-for="evaluation in evaluationsWithAppointments" :key="evaluation.id">
          <v-card
            class="fill-height"
            outlined
            :style="`border-left: 10px solid ${evaluation.appointment.bodyColor};`"
            @click="$refs.studentEvaluationDialog.open(evaluation.id, evaluation.appointment)"
          >
            <v-card-text class="px-2 py-1 subtitle-2">
              <div class="d-flex justify-space-between">
            <span>
              {{ evaluation.appointment.date }} &middot; {{ evaluation.appointment.startTime }}
            </span>

                <div class="d-flex align-center">
                  <span>{{evaluation.score}}</span>
                  <v-icon small class="ml-1">mdi-star-outline</v-icon>
                </div>
              </div>
              <div>{{evaluation.appointment.teacherName}}, {{$tc(evaluation.appointment.lessonType.name)}}</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <StudentEvaluationDialog ref="studentEvaluationDialog"/>
    </div>
  </v-card-text>

</v-card>
</template>

<style scoped>

</style>
