import api from "@/utils/api";

export default {
    autocomplete(query) {
        return new Promise((resolve, reject) => {
            api.post('/address-autocomplete', { query })
                .then(resolve)
                .catch(reject)
        })
    }
}