<template>
  <div id="splash-screen">
    <div class="content px-5">
      <v-img :src="require('@/assets/svg/book-and-pay.svg')" contain class="logo"/>
      <div class="mt-5">
        <v-progress-linear indeterminate color="grey" height="8" rounded />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SplashScreen"
}
</script>

<style scoped lang="scss">
#splash-screen {
  position: absolute;
  top: calc(0px - env(safe-area-inset-top));
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  background: #00435e;
  z-index: 999999;
  user-select: none;

  .content {
    max-width: 450px;
    width: 100%;
  }
}

.logo {
  animation: pulse-red 1.12s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.98);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.98);
  }
}
</style>
