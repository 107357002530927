<template>
  <v-alert dense outlined color="#eee" elevation="1" class="pa-0" @click.native="toggleShowDetails">
    <div class="d-flex align-center flex-column flex-lg-row pa-2" style="width: 100%">
      <div :style="{ width: $vuetify.breakpoint.lgAndUp ? '50%' : '100%' }" class="d-flex flex-row">
        <div class="d-flex flex-column text-center justify-center text--secondary" style="width: 35px;">
          <span class="text-h5">{{ student.start | dateFormat("dd") }}</span>
          <span class="text-caption">{{ student.start | dateFormat("HH:mm")}}</span>
        </div>

        <v-divider vertical class="mx-2" />

        <div class="flex-grow-1 d-flex text--secondary align-center">
          <UserAvatar class="mr-2 flex-shrink-1 d-none d-md-block" size="50" :path="student.avatar" :alt="student.fullName" />
          <div class="d-flex flex-column flex-grow-1" style="min-width: 90px; width: 100%">
            <div class="d-flex">
              <div :class="{ 'font-italic': student.isDeleted }">
                <v-icon v-if="!$vuetify.breakpoint.lgAndUp">
                  {{ detailsShown ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
                <v-tooltip top :disabled="!student.isDeleted">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ student.fullName }}</span>
                  </template>
                  <span>{{ $t("label.deleted_student") }}</span>
                </v-tooltip>
              </div>
            </div>
            <BalanceLabel
              v-if="student.bookAndPay"
              class="mt-1 mr-2"
              :student="student"
            />
          </div>
          <LicenseIcon class="flex-shrink-1" :name="student.license.name" :icon="student.license.icon" width="120" height="44" />
        </div>
      </div>

      <template v-if="$vuetify.breakpoint.lgAndUp || detailsShown">
        <v-divider
            style="width: 100%;"
            :class="$vuetify.breakpoint.lgAndUp ? 'mx-2' : 'my-2'"
            :vertical="$vuetify.breakpoint.lgAndUp"
        />

        <div :style="{ width: $vuetify.breakpoint.lgAndUp ? '50%' : '100%' }">
          <StudentProgress :student="student"/>
        </div>
      </template>
    </div>
  </v-alert>
</template>

<script>
import StudentProgress from "@/components/StudentProgress";
import UserAvatar from "@/components/UserAvatar";
import BalanceLabel from "@/components/BalanceLabel";
import LicenseIcon from "@/components/LicenseIcon";

export default {
  name: "NextStudent",
  components: {LicenseIcon, BalanceLabel, UserAvatar, StudentProgress },
  props: {
    student: {
      required: true
    }
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    detailsShown() {
      return this.show;
    }
  },
  methods: {
    toggleShowDetails() {
      this.show = !this.show
    }
  }
}
</script>

<style scoped>
</style>
