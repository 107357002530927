<template>
  <div class="grid">
    <div></div>
  </div>
</template>

<script>
export default {
  name: "WorkTimeByDayYearly",
};
</script>

<style scoped></style>
