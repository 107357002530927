<template>
  <div class="timepicker-parent">
    <v-text-field
      :disabled="disabled"
      :label="label"
      @click="showPicker(fieldType)"
      v-model="this.model[fieldType]"
      menu-props="auto"
      outlined
      readonly
      hide-details
      dense></v-text-field>
    <div
      class="time-menu"
      v-if="this.visiblePicker"
      v-click-outside="onClickOutside">
      <ul>
        <li
          :key="index"
          v-for="(item, index) in timeFields"
          @click="selectHour(item.value)"
          :class="item.disabled && 'disabled'">
          <span :class="`hours ${pickedHour ? 'picked' : ''}`">{{
            item.hours
          }}</span
          >:<span :class="`minutes ${pickedHour ? 'picked' : ''}`">{{
            item.minutes
          }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { format, parseISO, addMinutes, subMinutes } from "date-fns";
import { ClickOutside } from "vuetify/lib/directives";
import { mapGetters } from "vuex";
export default {
  name: "TimePicker",
  directives: { ClickOutside },
  data() {
    return {
      datePicker: false,
      visiblePicker: false,
      pickedHour: null,
      pickedField: null,
    };
  },
  props: {
    eventDuration: {
      type: Number,
      required: true,
    },
    adaptiveTime: {
      type: Boolean,
      default: false,
    },
    fieldType: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "currentAppointment" }),
    timeFields() {
      const timeArr = [];
      if (!this.pickedHour) {
        for (let i = 0; i < 24; i++) {
          const zeroFormat = i < 10 ? `0${i}` : i;
          const isoStart = parseISO(`${this.model.date} ${zeroFormat}`);
          const isoEnd = parseISO(
            `${this.model.date} ${zeroFormat}:${
              this.model.endTime.split(":")[1]
            }`
          );
          timeArr.push({
            value: zeroFormat,
            hours: zeroFormat,
            minutes: "00",
            disabled: this.disabledTime(isoStart, isoEnd),
          });
        }
      } else {
        for (let i = 0; i < 60; i = i + 5) {
          const zeroFormat = i < 10 ? `0${i}` : i;
          const isoTime = parseISO(
            `${this.model.date} ${this.pickedHour}:${zeroFormat}`
          );
          timeArr.push({
            value: `${this.pickedHour}:${zeroFormat}`,
            hours: this.pickedHour,
            minutes: zeroFormat,
            disabled: this.disabledTime(isoTime, isoTime),
          });
        }
      }
      return timeArr;
    },
  },
  methods: {
    disabledTime(isoStart, isoEnd) {
      let disabled = false;
      let limitTime;
      if (!this.adaptiveTime) {
        if (this.pickedField === "startTime") {
          limitTime = parseISO(`${this.model.date} ${this.model.endTime}`);
          disabled = isoStart >= limitTime;
        } else {
          limitTime = parseISO(`${this.model.date} ${this.model.startTime}`);
          disabled = isoEnd <= limitTime;
        }
      }
      return disabled;
    },
    onClickOutside() {
      this.visiblePicker = false;
      this.pickedHour = null;
      this.pickedField = null;
    },
    showPicker(field) {
      this.visiblePicker = true;
      this.pickedField = field;
    },
    selectHour(value) {
      if (!this.pickedHour) {
        this.pickedHour = value;
      } else {
        this.model[this.fieldType] = value;
        this[`${this.fieldType}Change`]();
        this.visiblePicker = false;
        this.pickedHour = null;
        this.pickedField = null;
      }
    },
    startTimeChange() {
      this.model.start = this.model.date + " " + this.model.startTime + ":00";
      this.$nextTick(() => {
        if (this.adaptiveTime) {
          const endTime = addMinutes(
            parseISO(this.model.start),
            this.eventDuration
          );
          this.model.endTime = format(endTime, "HH:mm");
        }
      });
    },
    endTimeChange() {
      const endDate = this.model.date + " " + this.model.endTime + ":00";
      this.$nextTick(() => {
        if (this.adaptiveTime) {
          const endTime = subMinutes(parseISO(endDate), this.eventDuration);
          this.model.start = format(endTime, "yyyy-MM-dd HH:mm:ss");
          this.model.startTime = format(endTime, "HH:mm");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.timepicker-parent {
  position: relative;
  z-index: 5;
  background: #fff;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
  text-decoration: line-through;
}

.time-menu {
  height: 245px;
  width: 100%;
  position: absolute;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
  left: 0;
  top: 40px;
  background: #fff;
  overflow-x: auto;
  z-index: 99;

  .hours {
    color: #333;
    &.picked {
      font-weight: bold;
    }
  }

  .minutes {
    color: #c7c7c7;
    &.picked {
      color: #333;
    }
  }

  ul {
    width: 100%;
    list-style: none;
    text-align: center;
    padding: 0;

    li {
      cursor: pointer;
      border-bottom: 1px solid #eee;
      padding: 2px;

      &:hover {
        background: #eee;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
