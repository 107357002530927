<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "TeacherVehiclesListLoader",
  props: {
    count: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    array() {
      return [...Array(this.count)];
    },
  },
})
</script>

<template>
  <v-list>
    <v-list-item v-for="index in array" :key="index">
      <v-list-item-avatar>
          <v-skeleton-loader type="avatar"></v-skeleton-loader>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title><v-skeleton-loader type="text" width="60" /></v-list-item-title>
        <v-list-item-subtitle><v-skeleton-loader type="text" width="60" /></v-list-item-subtitle>
        <v-list-item-subtitle><v-skeleton-loader type="text" width="60" /></v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-skeleton-loader type="button" height="50" />
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<style scoped>

</style>