<template>
  <v-dialog
      v-model="dialog"
      width="500"
      @click:outside="close"
      @keydown.esc="close"
  >
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>{{ $t("label.apology_letter") }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-card-title>
        {{ $t('label.create_apology_letter') }}
      </v-card-title>

      <v-card-subtitle>
        {{ student.fullName }}
      </v-card-subtitle>

      <v-card-text>
        <ValidationObserver ref="form">
          <v-row dense>
            <v-col>
              <v-menu
                  v-model="datepicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <h4>{{ $t('label.for_date') }}</h4>

                  <v-text-field
                      v-model="dateFormatted"
                      :placeholder="$t('label.select_a_date')"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                      outlined
                      dense
                      @blur="date = parseDate(dateFormatted)"
                      :loading="fetching"
                      :disabled="fetching"
                  />
                </template>

                <v-date-picker
                  v-model="date"
                  first-day-of-week="1"
                  no-title
                  scrollable
                  @change="fetchAppointmentsForDate"
                  @input="datepicker = false"
                />
              </v-menu>
            </v-col>
          </v-row>

          <template v-if="date && appointmentsForDate.length">
            <v-row dense>
              <v-col>
                <h4>{{ $t('label.select_an_appointment') }}</h4>

                <v-sheet elevation="1" class="pa-2" rounded>
                  <ValidationProvider v-slot="{ errors }" name="appointmentId">
                    <v-radio-group
                        class="mt-0"
                        v-model="selectedAppointment"
                        :error-messages="errors"
                        :hide-details="! $refs.form.errors.appointmentId || ! $refs.form.errors.appointmentId.length"
                    >
                      <v-radio
                          v-for="appointment in appointmentsForDate"
                          :key="appointment.id"
                          :label="appointmentCaption(appointment)"
                          :value="appointment.id"
                          @click="updateStartEndTime(appointment)"
                      />
                    </v-radio-group>
                  </ValidationProvider>

                  <v-row class="mt-2" dense>
                    <v-col>
                      <v-menu
                          ref="startTimePicker"
                          v-model="startTimePicker"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="startTime"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider v-slot="{ errors }" name="startTime">
                            <v-text-field
                                v-model="startTime"
                                :label="$t('label.start_time')"
                                prepend-inner-icon="mdi-clock-time-eight-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                required
                                outlined
                                dense
                                :error-messages="errors"
                                :hide-details="! $refs.form.errors.startTime || ! $refs.form.errors.startTime.length"
                            />
                          </ValidationProvider>
                        </template>
                        <v-time-picker
                            v-if="startTimePicker"
                            v-model="startTime"
                            format="24hr"
                            full-width
                            @click:minute="$refs.startTimePicker.save(startTime)"
                        />
                      </v-menu>
                    </v-col>

                    <v-col>
                      <v-menu
                          ref="endTimePicker"
                          v-model="endTimePicker"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="endTime"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider v-slot="{ errors }" name="endTime">
                            <v-text-field
                                v-model="endTime"
                                :label="$t('label.end_time')"
                                prepend-inner-icon="mdi-clock-time-five-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense
                                outlined
                                required
                                :error-messages="errors"
                                :hide-details="! $refs.form.errors.endTime || ! $refs.form.errors.endTime.length"
                            />
                          </ValidationProvider>
                        </template>
                        <v-time-picker
                            v-if="endTimePicker"
                            v-model="endTime"
                            format="24hr"
                            full-width
                            @click:minute="$refs.endTimePicker.save(endTime)"
                        />
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>

            <v-row v-if="isSchoolAdministrator && selectedAppointment !== null" dense>
              <v-col>
                <SignatureAutocompleteField
                    document="apology-letter"
                    :related-teacher-id="selectedAppointmentTeacherId"
                    @change="onSignatureSelected"
                />
              </v-col>
            </v-row>
          </template>

          <v-row v-else-if="date && !appointmentsForDate.length && ! fetching" dense>
            <v-col>
              <v-sheet elevation="1" class="pa-2" rounded>
                <empty-box :text="$t('label.no_appointments_on_the_selected_day')" />
              </v-sheet>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn text @click="close">{{$t('btn.close')}}</v-btn>
        <v-spacer />
        <v-btn color="primary" :loading="loading" :disabled="submitDisabled" @click="submit">
          <v-icon left>mdi-file-document</v-icon>
          {{ $t("btn.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import studentsService from "@/services/studentsService";
import SignatureAutocompleteField from '@/components/SignatureAutocompleteField.vue'
import user from '@/utils/mixins/user'

export default {
  name: "StudentApologyLetter",
  mixins: [user],
  components: { SignatureAutocompleteField },
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      fetching: false,
      date: null,
      dateFormatted: null,
      datepicker: false,
      appointmentsForDate: [],
      selectedAppointment: null,
      startTime: null,
      endTime: null,
      startTimePicker: false,
      endTimePicker: false
    };
  },
  watch: {
    date () {
      this.dateFormatted = this.formatDate(this.date)
    },
  },
  computed: {
    submitDisabled() {
      return this.startTime === null || this.endTime === null || this.selectedAppointment === null || this.loading
    },
    selectedAppointmentTeacherId () {
      const appointment = this.appointmentsForDate.find(item => item.id === this.selectedAppointment)

      return appointment?.teacherId || null
    }
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.date = this.startTime = this.endTime = this.selectedAppointment = this.signatureId = null
      });
    },
    appointmentCaption(appointment) {
      const values = [];

      values.push(`${appointment.startTime} - ${appointment.endTime}`)

      if (appointment.license) {
        values.push(appointment.license)
      }

      if (appointment.lessonType) {
        values.push(this.$tc(appointment.lessonType))
      } else if (appointment.specialType) {
        values.push(`${this.$t('event_type.SPECIAL_TYPES.' + appointment.specialType)} (${appointment.specialName})`)
      }

      return values.join(', ')
    },
    updateStartEndTime(appointment) {
      this.startTime = appointment.startTime
      this.endTime = appointment.endTime
    },
    fetchAppointmentsForDate () {
      this.fetching = true
      this.startTime = this.endTime = this.selectedAppointment = null
      this.appointmentsForDate = []
      studentsService
        .fetchAppointmentsForDate(this.student.id, this.date)
        .then(resp => {
          this.appointmentsForDate = resp.data
        })
        .finally(() => {
          this.fetching = false
        })
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    submit() {
      this.$refs.form.reset()

      console.log('Create apology letter')

      this.loading = true
      studentsService
          .createApologyLetter(this.student.id, {
            startTime: this.startTime,
            endTime: this.endTime,
            appointmentId: this.selectedAppointment,
            signatureId: this.signatureId
          })
          .then(response => {
            this.$emit('created', response.data || {})
            this.close()
          })
          .catch(error => {
            const response = error.response || {};
            const responseData = response.data || {};

            if (responseData.message) {
              this.$snackbar.show({ message: responseData.message, color: "error", });
            }
          })
          .finally(() => {
            this.loading = false
          })
    },
    onSignatureSelected(value) {
      this.signatureId = value
    }
  }
}
</script>

<style scoped>

</style>
