<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'school-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>
        {{ $t("route.schoolSettings.children.notifications") }}
      </v-card-title>
    </v-toolbar>

    <v-card-text>
      <v-tabs v-model="selectedUserType">
        <v-tab v-for="userTypeKey in userTypesKeys" :key="userTypeKey">{{userTypes[userTypeKey] }}</v-tab>
      </v-tabs>

      <v-tabs-items class="pt-4" v-model="selectedUserType">
        <v-tab-item
          class="px-1"
          v-for="userTypeKey in userTypesKeys"
          :key="userTypeKey"
          :transition="false"
        >
          <v-skeleton-loader v-if="loading" type="list-item-three-line" />
          <v-expansion-panels
            v-else-if="notificationsGroupsByUserType[userTypeKey].length > 0"
            accordion
            multiple
          >
            <NotificationGroup
              v-for="notificationGroup in notificationsGroupsByUserType[
                userTypeKey
              ]"
              :key="notificationGroup"
              :settings-area="settingsArea"
              :user-type="userTypeKey"
              :notification-group="notificationGroup"
              :disabled="loading"
            />
          </v-expansion-panels>

          <empty-box v-else />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </div>
</template>

<script>
import notificationsService from "@/services/notificationsService";
import NotificationGroup from "@/components/school/settings/NotificationGroup";
import NotificationsSettings from "@/store/models/NotificationsSettings";
import i18n from "@/plugins/i18n";
import responsive from '@/utils/mixins/responsive'

export default {
  name: "Notifications",
  components: { NotificationGroup },
  mixins: [responsive],
  data: () => ({
    loading: true,
    settings: [],
    settingsArea: "school",
    administratorPanel: "",
    teachersPanel: "",
    studentsPanel: "",
    selectedUserType: "school-administrator",
    userTypes: {
      "school-administrator": i18n.t("user_type.school-administrator"),
      teacher: i18n.t("user_type.teacher"),
      student: i18n.t("user_type.student"),
    },
  }),
  computed: {
    notificationsGroupsByUserType() {
      const byUserType = {};

      Object.keys(this.userTypes).forEach((userType) => {
        if (!(userType in byUserType)) {
          byUserType[userType] = NotificationsSettings.query()
            .where("settingsArea", this.settingsArea)
            .where("userType", userType)
            .get()
            .map((item) => item.notificationGroup);
        }
      });

      return byUserType;
    },
    userTypesKeys() {
      return Object.keys(this.userTypes);
    },
  },
  mounted() {
    notificationsService
      .schoolNotificationsSettings()
      .then((reponse) => {
        NotificationsSettings.create({ data: reponse.data || [] });
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped></style>
