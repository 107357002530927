<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-toolbar class="mb-2" dark color="primary" dense>
        <v-toolbar-title>{ {$t("label.connect_bank_account")}}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="pb-0">
        <ValidationObserver ref="form">
          <v-row class="mt-5" dense>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('form.country')"
                outlined
                dense
                v-model="bankAccount.country"
                disabled />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('form.currency')"
                outlined
                dense
                v-model="bankAccount.currency"
                disabled />
            </v-col>
            <v-col cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                name="account_number"
                rules="required">
                <v-text-field
                  :label="$t('form.account_number')"
                  outlined
                  dense
                  v-model="bankAccount.iban"
                  :error-messages="errors" />
              </ValidationProvider>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">
          {{ $t("btn.cancel") }}
        </v-btn>
        <v-btn color="primary" text @click="submit" :loading="loading">
          {{ $t("btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/utils/api";

export default {
  name: "BankAccountModal",
  data() {
    return {
      dialog: false,
      loading: false,
      bankAccount: {
        country: "DE",
        currency: "eur",
        iban: "",
      },
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    submit() {
      this.error = null;
      this.loading = true;
      api
        .post("stripe/create-external-account", this.bankAccount)
        .then(() => {
          this.dialog = this.loading = false;
          window.location.reload();
        })
        .catch((err) => {
          this.error = err.response.data.message;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
