import api from "@/utils/api";

export default {
  load: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("exam-list-sorting-criteria", {params})
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  update: (data) => {
    return new Promise((resolve, reject) => {
      api
        .put("exam-list-sorting-criteria", data)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  reset: () => {
    return new Promise((resolve, reject) => {
      api
        .delete("exam-list-sorting-criteria")
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  }
}
