<template>
  <div>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
        <SimulatorNameField
          :validatin="true"
          :disabled="true"
          :simulatorNameProp="currentAppointment.simulatorName" />
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
        <OfficesField
          :validation="true"
          :disabled="true"
          :officeIdProp="currentAppointment.officeId"
          :officesList="schoolOffices" />
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
        <LicenseField
            @emitLicenseSelect="(val) => updateFields({ licenseId: val })"
            :disabled="currentAppointment.isSubscribed || isPast || simulatorStudentHasNoPriceList || isElter"
            :validation="true"
            :multiple="false"
            :licenseProp="currentAppointment.licenseId"
            :licenseIdsList="studentLicenses" />
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
        <LessonTypesField
            @emitLessonTypeSelect="(val) => updateFields({ lessonTypeId: val })"
            :lessonTypeProp="currentAppointment.lessonTypeId"
            :lessonTypesList="lessonTypesList"
            :disabled="true"
            :validation="true"
            :mandatory="false"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { isPast, parseISO } from 'date-fns'
import { mapGetters } from "vuex";

import LicenseField from "@/components/calendar/appointmentModal/fields/LicenseField";
import SimulatorNameField from "@/components/calendar/appointmentModal/fields/SimulatorNameField";
import OfficesField from "@/components/calendar/appointmentModal/fields/OfficesField";

import StudentModel from "@/store/models/StudentModel";
import OfficeModel from "@/store/models/OfficeModel";
import user from '@/utils/mixins/user'
import LessonTypesField from '@/components/calendar/appointmentModal/fields/LessonTypesField.vue'
import LessonTypeModel from '@/store/models/LessonTypeModel'
import LicenseModel from '@/store/models/LicenseModel'
export default {
  name: "SimulatorForm",
  mixins: [user],
  components: {
    LessonTypesField,
    LicenseField,
    SimulatorNameField,
    OfficesField,
  },
  computed: {
    ...mapGetters("appointments", {
      currentAppointment: "currentAppointmentGetter",
    }),
    studentLicenses() {
      const availableLicenses = LicenseModel.query().where('forSimulation', true).get().map(license => license.id)
      const licensesIds = StudentModel.find(this.currentUser.id).licensesIds || [];

      return licensesIds.filter(id => availableLicenses.includes(id))
    },
    schoolOffices() {
      return OfficeModel.all() || [];
    },
    isLicenseSelected() {
      return !!this.currentAppointment.licenseId;
    },
    isPast() {
      return isPast(parseISO(this.currentAppointment.start))
    },
    simulatorStudentHasNoPriceList() {
      const student = StudentModel.find(this.currentUser.id) || {}
      return this.currentAppointment.type === 'SIMULATOR' && student.bookAndPay && !student.priceCategoryId
    },
    lessonTypesList() {
      return LessonTypeModel.query().where('eventTypeId', 'SIMULATOR').get()
    }
  },
  methods: {
    updateFields(value) {
      this.$store.commit(`appointments/UPDATE_APPOINTMENT_FIELD`, value);
    },
  },
};
</script>
