<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "LastActivityInDaysIndicator",
  props: {
    days: {
      type: Number,
      required: true,
    },
    top: {
      type: Boolean,
      default: true
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    topValue() {
      return ! this.left && !this.right && !this.bottom;
    },
    filledCircles() {
      if (this.days <= 2) {
        return 1;
      } else if (this.days <= 10) {
        return Math.ceil((this.days - 2) / 2);
      } else {
        return 5;
      }
    },
    color() {
      if (this.filledCircles === 1) {
        return 'green';
      } else if (this.filledCircles < 5) {
        return 'orange';
      } else {
        return 'red';
      }
    },
  },
})
</script>

<template>
  <v-tooltip :top="topValue" :bottom="bottom" :left="left" :right="right">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="d-flex flex-row align-center justify-center">
        <div class="circle" v-for="i in Array.from({ length: filledCircles }, (v, i) => i)" :key="i" :class="color" />
        <div class="circle" v-for="i in Array.from({ length: 5 - filledCircles }, (v, i) => i)" :key="i" />
      </div>
    </template>
    <span>{{ days === 0 ? $t('label.today') : $tc('label.days_ago', days, { days }) }}</span>
  </v-tooltip>
</template>

<style scoped lang="scss">
  .circle {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    border: solid 1px #ccc;
    margin: 0 1px;
    &.green {
      background-color: green;
    }
    &.orange {
      background-color: orange;
    }
    &.red {
      background-color: red;
    }
  }
</style>