import api from "@/utils/api";

export default {
  getCode() {
    return new Promise((resolve, reject) => {
      api
        .get("account-linking-code")
        .then(resp => resolve(resp))
        .catch(reject);
    });
  },
  deleteCode(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`account-linking-code/${id}`)
        .then(resp => resolve(resp))
        .catch(reject);
    });
  }
}