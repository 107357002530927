<template>
  <div class="justify-center">
    <v-btn-toggle
      v-if="$vuetify.breakpoint.mdAndUp"
      group
      dense
      borderless
      mandatory
      tile
      :color="color"
      v-model="type">
      <v-btn
        v-for="type in eventTypes"
        :key="type.id"
        :value="type.id"
        :disabled="isTypeDisabled(type)"
        text>
        {{ eventTypeLabel(type) }}
      </v-btn>
    </v-btn-toggle>

    <v-row v-else class="my-1 mx-3">
      <v-col>
        <v-select
            v-model="type"
            :items="eventTypes"
            item-value="id"
            :label="$t('form.event_type')"
            :item-text="eventTypeLabel"
            :item-disabled="isTypeDisabled"
            outlined
            hide-details
            dense />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import user from "@/utils/mixins/user"
import { mapGetters } from "vuex"
import { parseISO } from 'date-fns'
import DateRange from "@/helpers/dateRange"

import EventTypeModel from "@/store/models/EventTypeModel"

export default {
  name: "AppointmentTypesTabs",
  mixins: [user],
  props: {
    color: {
      type: String
    },
    createdPlaceholderApp: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("appointments", { currentAppointment: "currentAppointmentGetter", }),
    type: {
      set(type) {
        this.$store.commit(`appointments/UPDATE_APPOINTMENT_FIELD`, {
          type: type,
          lessonTypeId: null,
          teacherId: this.isTeacher ? this.currentUser.id :
              this.createdPlaceholderApp ? this.createdPlaceholderApp.teacherId : null,
          studentsIds: this.isStudent ? [this.currentUser.id] : [],
          licenseId: null,
          copyForNrOfWeeks: null,
          notificationLicenses: [],
          hasMultipleRequestsAllowed: false,
          primaryVehicleId: null,
          secondaryVehicleId: null,
          specialName: null,
          isWorkTime: false,
          officeId: null,
          theoryTopicId: null,
          roomSize: null,
          allowedLicenses: [],
          simulatorName: null,
          notes: this.createdPlaceholderApp ? this.createdPlaceholderApp.notes : '',
          meetingPlace: this.createdPlaceholderApp ? this.createdPlaceholderApp.meetingPlace : '',
        });
      },
      get() {
        return this.currentAppointment.type
      },
    },
    eventTypes() {
      const allTypes = EventTypeModel.all() || {}
      return this.isSchoolAdministrator ? allTypes : allTypes.filter((type) => type.id !== "SIMULATOR")
    },
    activeEventTypes() {
      return this.eventTypes.filter((type) => !this.isTypeDisabled(type.id))
    },
    isHoliday() {
      const dateRange = new DateRange()
      const regions = this.schoolSettings.regions
      const date = this.currentAppointment.start ? parseISO(this.currentAppointment.start) : new Date()

      return regions?.length === 0 ? dateRange.isHoliday(date) : dateRange.isHoliday(date, regions?.split(", "))
    },
  },
  methods: {
    eventTypeLabel(item) {
      return this.$t(`event_type.${item.id}`)
    },
    isTypeDisabled(item) {
      const wasPlaceholder = this.createdPlaceholderApp
      const isPlaceholderCase = !!this.currentAppointment.id && !wasPlaceholder ||
          (this.currentAppointment.type === 'PLACEHOLDER' && this.currentAppointment.isRequested ||
              (wasPlaceholder && item.id === 'SIMULATOR'))
      const isHolidayCase = this.isHoliday && !['OFFTIME', 'SPECIAL'].includes(item.id)

      /*commented past exam logic*/
      // const isPastCase = !this.schoolSettings.pastEventsAllowed && isPast(parseISO(this.currentAppointment.start))
      const isPastCase = false
      return isPlaceholderCase || isHolidayCase || isPastCase
    },
  },
};
</script>

<style lang="scss" scoped></style>
