<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'school-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>
        {{ $t("route.schoolSettings.children.general") }}
      </v-card-title>

      <v-spacer />

      <v-btn
          text
          color="primary"
          @click="save"
          :loading="formLoading"
          :disabled="formLoading || loading"
      >
        {{ $t("btn.save") }}
      </v-btn>
    </v-toolbar>

    <v-progress-circular
      class="d-flex ma-auto mt-10"
      v-if="loading"
      style="min-height: 200px"
      color="primary"
      indeterminate
    />

    <v-card-text v-show="!loading">
      <ValidationObserver ref="form">
        <v-tabs v-model="activeTab" :color="hasActiveTabErrors ? 'error' : 'primary'">
          <v-tab href="#general" @click="onTabClick('general')">
              <v-badge
                icon="mdi-check"
                :color="invalidTabFields['general'].length ? 'error' : 'primary'"
                :content="invalidTabFields['general'].length"
                :value="!!invalidTabFields['general'].length"
              >
                  <span :class="{ 'error--text' :  invalidTabFields['general'].length}">
                      {{ $t("label.general") }}
                  </span>
              </v-badge>
          </v-tab>
          <v-tab href="#contacts" @click="onTabClick('contacts')">
              <v-badge
                icon="mdi-check"
                :color="invalidTabFields['contact'].length ? 'error' : 'primary'"
                :content="invalidTabFields['contact'].length"
                :value="!!invalidTabFields['contact'].length"
              >
                <span :class="{ 'error--text' :  invalidTabFields['contact'].length}">
                  {{ $t("label.contact") }}
                </span>
              </v-badge>
          </v-tab>
          <v-tab href="#address" @click="onTabClick('address')">
              <v-badge
                icon="mdi-check"
                :color="invalidTabFields['address'].length ? 'error' : 'primary'"
                :content="invalidTabFields['address'].length"
                :value="!!invalidTabFields['address'].length"
              >
                <span :class="{ 'error--text' :  invalidTabFields['address'].length}">
                  {{ $t("label.address") }}
                </span>
              </v-badge>
          </v-tab>
          <v-tab href="#calculation" @click="onTabClick('calculation')">
              <v-badge
                icon="mdi-check"
                :color="invalidTabFields['calculation'].length ? 'error' : 'primary'"
                :content="invalidTabFields['calculation'].length"
                :value="!!invalidTabFields['calculation'].length"
              >
                <span :class="{ 'error--text' :  invalidTabFields['calculation'].length}">
                  {{ $t("label.calculation") }}
                </span>
              </v-badge>
          </v-tab>
          <v-tab href="#documents" @click="onTabClick('documents')">
              <v-badge
                icon="mdi-check"
                :color="invalidTabFields['generalDocuments'].length ? 'error' : 'primary'"
                :content="invalidTabFields['generalDocuments'].length"
                :value="!!invalidTabFields['generalDocuments'].length"
              >
                <span :class="{ 'error--text' :  invalidTabFields['generalDocuments'].length}">
                  {{ $t("label.general_documents") }}
                </span>
              </v-badge>
          </v-tab>
          <v-tab href="#bank" @click="onTabClick('bank')">
              <v-badge
                icon="mdi-check"
                :color="invalidTabFields['finances'].length ? 'error' : 'primary'"
                :content="invalidTabFields['finances'].length"
                :value="!!invalidTabFields['finances'].length"
              >
                <span :class="{ 'error--text' :  invalidTabFields['finances'].length}">
                  {{ $t("label.finances")}}
                </span>
              </v-badge>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="activeTab">
          <v-tab-item value="general" eager>
            <v-row class="my-3">
              <v-col cols="12" sm="6" md="12" lg="9">
                <ValidationProvider v-slot="{ errors }" name="name">
                  <v-text-field
                    dense
                    outlined
                    required
                    v-model="form.name"
                    :label="$t('form.name')"
                    :placeholder="$t('form.name')"
                    :error-messages="errors"
                    autocomplete="no-name"
                  />
                </ValidationProvider>

                <v-text-field
                    dense
                    outlined
                    :label="$t('form.represented_by')"
                    :placeholder="$t('form.represented_by')"
                    v-model="form.representedBy"
                    persistent-hint
                    hint="Changing name will erase current signature."
                >
                  <template v-slot:append>
                    <v-icon
                        @click="openSignatureDialog('represented-by')"
                        :color="school.hasRepresentedBySignature ? 'green' : ''"
                        :disabled="!(form.representedBy && school.representedBy)"
                    >
                      {{ 'mdi-signature-freehand' }}
                    </v-icon>
                  </template>
                </v-text-field>

                <v-text-field
                  dense
                  outlined
                  :label="$t('form.main_driving_instructor')"
                  :placeholder="$t('form.main_driving_instructor')"
                  v-model="form.mainDrivingInstructor"
                  persistent-hint
                  hint="Changing name will erase current signature."
                >
                  <template v-slot:append>
                    <v-icon
                        ref="mainDrivingInstructorIcon"
                        @click="openSignatureDialog('main-driving-instructor')"
                        :color="school.hasMainDrivingInstructorSignature ? 'green' : ''"
                        :disabled="!(form.mainDrivingInstructor && school.mainDrivingInstructor)"
                    >
                      {{ 'mdi-signature-freehand' }}
                    </v-icon>
                  </template>
                </v-text-field>

                <v-text-field
                  ref="headquarters"
                  dense
                  outlined
                  :label="$t('form.headquarters')"
                  :placeholder="$t('form.headquarters')"
                  v-model="form.headquarters"
                />

                <v-text-field
                  ref="registerUrl"
                  dense
                  outlined
                  readonly
                  :label="$t('form.registration_url')"
                  :placeholder="$t('form.registration_url')"
                  :value="`https://planer.drivolino.de/registration?school=${resourceId}`"
                  @click="selectRegisterUrl"
                />

                <v-card outlined v-if="supportedLocales.length">
                  <v-card-title>{{$t('label.additional_localizations')}}</v-card-title>
                  <v-card-subtitle>{{$t('label.additional_localizations_description')}}</v-card-subtitle>

                  <v-card-text>
                    <v-checkbox
                      v-for="locale in supportedLocales"
                      :key="locale.code"
                      :value="locale.code"
                      :label="locale.name"
                      v-model="form.enabledLocales"
                      dense
                      hide-details
                      :ripple="false"
                    />
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" sm="6" md="12" lg="3">
                <UploadBtn @file-picked="onFilePick" accept="image/jpeg,image/png" input-name="avatar">
                  <v-avatar class="elevation-2" size="150" rounded>
                    <v-img v-if="avatarImage" :src="avatarImage" contain />
                    <v-icon v-else size="100">mdi-image-area</v-icon>
                  </v-avatar>
                </UploadBtn>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item value="contacts" eager>
            <v-row class="mt-3" dense>
              <v-col cols="12" sm="6">
                <ValidationProvider v-slot="{ errors }" name="phone">
                  <v-text-field
                    outlined
                    dense
                    v-model="form.phone"
                    :label="$t('form.phone_landline')"
                    :placeholder="$t('form.phone_landline')"
                    type="text"
                    required
                    :error-messages="errors" />
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" name="mobile">
                  <v-text-field
                    outlined
                    dense
                    v-model="form.mobile"
                    :label="$t('form.phone_mobile')"
                    :placeholder="$t('form.phone_mobile')"
                    type="text"
                    required
                    :error-messages="errors" />
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" name="fax">
                  <v-text-field
                    outlined
                    dense
                    v-model="form.fax"
                    :label="$t('form.fax')"
                    :placeholder="$t('form.fax')"
                    type="text"
                    required
                    :error-messages="errors" />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item value="address" eager>
            <div class="mt-3 mb-1">
              <span class="text-subtitle-1 font-weight-medium">{{ $t("settings.invoice_address") }}</span>
              <v-divider />
            </div>

            <v-row class="mt-3" dense no-gutters>
              <v-col cols="12" sm="6">
                <v-row dense>
                  <v-col cols="12" sm="6" md="9">
                    <ValidationProvider v-slot="{ errors }" name="invoiceStreet">
                      <v-text-field
                        outlined
                        dense
                        v-model="form.invoiceStreet"
                        :label="$t('form.street')"
                        :placeholder="$t('form.street')"
                        type="text"
                        required
                        :error-messages="errors" />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <ValidationProvider v-slot="{ errors }" name="invoiceHouseNr">
                      <v-text-field
                        outlined
                        dense
                        v-model="form.invoiceHouseNr"
                        :label="$t('form.house')"
                        :placeholder="$t('form.house')"
                        type="text"
                        required
                        :error-messages="errors" />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="6" md="3">
                    <ValidationProvider v-slot="{ errors }" name="invoicePostcode">
                      <v-text-field
                        outlined
                        dense
                        v-model="form.invoicePostcode"
                        :label="$t('form.plz')"
                        :placeholder="$t('form.plz')"
                        type="text"
                        required
                        :error-messages="errors" />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6" md="9">
                    <ValidationProvider v-slot="{ errors }" name="invoiceCity">
                      <v-text-field
                        outlined
                        dense
                        v-model="form.invoiceCity"
                        :label="$t('form.city')"
                        :placeholder="$t('form.city')"
                        type="text"
                        required
                        :error-messages="errors" />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <div class="mb-1">
              <span class="text-subtitle-1 font-weight-medium">{{$t("settings.main_address")}}</span>
              <v-divider />
            </div>

            <v-row class="mt-3" dense no-gutters>
              <v-col cols="12" sm="6">
                <v-row dense>
                  <v-col cols="12" sm="6" md="9">
                    <ValidationProvider v-slot="{ errors }" name="mainStreet">
                      <v-text-field
                        outlined
                        dense
                        v-model="form.mainStreet"
                        :label="$t('form.street')"
                        :placeholder="$t('form.street')"
                        type="text"
                        required
                        :error-messages="errors" />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <ValidationProvider v-slot="{ errors }" name="mainHouseNr">
                      <v-text-field
                        outlined
                        dense
                        v-model="form.mainHouseNr"
                        :label="$t('form.house')"
                        :placeholder="$t('form.house')"
                        type="text"
                        required
                        :error-messages="errors" />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="6" md="3">
                    <ValidationProvider v-slot="{ errors }" name="mainPostcode">
                      <v-text-field
                        outlined
                        dense
                        v-model="form.mainPostcode"
                        :label="$t('form.plz')"
                        :placeholder="$t('form.plz')"
                        required
                        :error-messages="errors" />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6" md="9">
                    <ValidationProvider v-slot="{ errors }" name="mainCity">
                      <v-text-field
                        outlined
                        dense
                        v-model="form.mainCity"
                        :label="$t('form.city')"
                        :placeholder="$t('form.city')"
                        type="text"
                        required
                        :error-messages="errors" />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <div class="mb-1">
              <span class="text-subtitle-1 font-weight-medium">{{$t("label.location")}}</span>
              <v-divider />
            </div>

            <v-row class="mt-3" dense no-gutters>
              <v-col cols="12" sm="6">
                <v-row dense>
                  <v-col cols="12" sm="6" md="6">
                    <ValidationProvider v-slot="{ errors }" name="lat">
                      <v-text-field
                        outlined
                        dense
                        v-model="form.lat"
                        :label="$t('form.lat')"
                        :placeholder="$t('form.lat')"
                        type="text"
                        required
                        :error-messages="errors"
                        :hint="
                          munichLat === form.lat
                            ? 'Breitengrad von München'
                            : ''
                        "
                        persistent-hint />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <ValidationProvider v-slot="{ errors }" name="long">
                      <v-text-field
                        outlined
                        dense
                        v-model="form.long"
                        :label="$t('form.long')"
                        :placeholder="$t('form.long')"
                        type="text"
                        required
                        :error-messages="errors"
                        :hint="
                          munichLong === form.long
                            ? 'Längengrad von München'
                            : ''
                        "
                        persistent-hint />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <div class="mb-1">
              <span class="text-subtitle-1 font-weight-medium">{{$t("label.social_links")}}</span>
              <v-divider />
            </div>

            <v-row class="mt-3" no-gutters>
              <v-col cols="12" sm="6">
                <ValidationProvider v-slot="{ errors }" name="facebookLink">
                  <v-text-field
                    outlined
                    dense
                    required
                    type="text"
                    prepend-inner-icon="mdi-facebook"
                    v-model="form.facebookLink"
                    :label="$t('form.facebook_link')"
                    :placeholder="$t('form.facebook_link')"
                    :error-messages="errors" />
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" name="facebookLink">
                  <v-text-field
                    outlined
                    dense
                    required
                    type="text"
                    prepend-inner-icon="mdi-instagram"
                    v-model="form.instagramLink"
                    :label="$t('form.instagram_link')"
                    :placeholder="$t('form.instagram_link')"
                    :error-messages="errors" />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item value="calculation" eager>
            <SettingsResponsiveRow
              :title="$t('settings.general.lesson_duration')"
              :description="$t('settings.general.lesson_duration_description')"
              divider-after
            >
              <ValidationProvider v-slot="{ errors }" name="lessonDuration">
                <v-text-field
                    class="mt-1"
                    type="number"
                    :label="$t('form.default_lesson_duration')"
                    v-model="form.lessonDuration"
                    outlined
                    dense
                    hide-details
                    readonly
                />
                <v-slider
                    class="align-center mr-0"
                    v-model="form.lessonDuration"
                    :min="30"
                    :max="60"
                    :step="5"
                    hide-details
                />
                <span class="red--text" v-if="errors.length">{{ errors[0] }}</span>
              </ValidationProvider>
            </SettingsResponsiveRow>

            <SettingsResponsiveRow
                :title="$t('settings.general.normal_lesson_duration')"
                :description="$t('settings.general.normal_lesson_duration_description')"
                divider-after
            >
              <template #description>
                <v-alert class="mt-1" border="left" colored-border type="info" dense elevation="1">
                  <v-list-item-title>
                    {{ $t('settings.general.for_example') }}
                  </v-list-item-title>
                  <span class="text--secondary" v-html="htmlFromMarkdown($t('settings.general.normals_given_example'))"/>

                  <SegmentedProgressBar
                      :total-value="180"
                      :segment-length="form.normalLessonDuration"
                      color="secondary"
                      dark
                      class="mb-1"
                      :label="$tc('lesson_type.normal', 2)"
                  />
                </v-alert>
              </template>
              <ValidationProvider v-slot="{ errors }" name="normalLessonDuration">
                <v-text-field
                    class="mt-1"
                    type="number"
                    :label="$t('form.normal_lesson_duration')"
                    v-model="form.normalLessonDuration"
                    outlined
                    dense
                    hide-details
                    readonly
                />
                <v-slider
                    class="align-center mr-0"
                    v-model="form.normalLessonDuration"
                    :min="30"
                    :max="60"
                    :step="5"
                    hide-details
                />
                <span class="red--text" v-if="errors.length">{{ errors[0] }}</span>
              </ValidationProvider>
            </SettingsResponsiveRow>

            <SettingsResponsiveRow
                :title="$t('settings.general.special_lesson_duration')"
                :description="$t('settings.general.special_lesson_duration_description')"
            >
              <template #description>
                <v-alert class="mt-1" border="left" colored-border type="info" dense elevation="1">
                  <v-list-item-title>
                    {{ $t('settings.general.for_example') }}
                  </v-list-item-title>
                  <span class="text--secondary" v-html="htmlFromMarkdown($t('settings.general.specials_given_example'))"/>
                  <SegmentedProgressBar
                      :total-value="135"
                      :segment-length="form.specialLessonDuration"
                      :required-segments="5"
                      color="red"
                      dark
                      class="mb-1"
                      :label="$tc('lesson_type.overland', 2)"
                  />
                  <SegmentedProgressBar
                      :total-value="0"
                      :segment-length="form.specialLessonDuration"
                      :required-segments="4"
                      color="blue"
                      class="mb-1"
                      :label="$tc('lesson_type.highway', 2)"
                  />
                  <SegmentedProgressBar
                    :total-value="135"
                    :segment-length="form.specialLessonDuration"
                    :required-segments="3"
                    dark
                    color="indigo"
                    :label="$tc('lesson_type.night', 2)"
                  />
                </v-alert>
              </template>
              <ValidationProvider v-slot="{ errors }" name="specialLessonDuration">
                <v-text-field
                    class="mt-1"
                    type="number"
                    :label="$t('form.special_lesson_duration')"
                    v-model="form.specialLessonDuration"
                    outlined
                    dense
                    hide-details
                    readonly
                />
                <v-slider
                    class="align-center mr-0"
                    v-model="form.specialLessonDuration"
                    :min="30"
                    :max="60"
                    :step="5"
                    hide-details
                />
                <span class="red--text" v-if="errors.length">{{ errors[0] }}</span>
              </ValidationProvider>
            </SettingsResponsiveRow>

            <SettingsResponsiveRow
                :title="$t('settings.general.missed_appointments_as_worktime')"
                :description="$t('settings.general.missed_appointments_as_worktime_description')"
                :divider-after="false"
            >
              <v-switch
                v-model="form.includeMissedLessonsInWorkingTime"
                :label="$t('label.enabled')"
              />
            </SettingsResponsiveRow>

            <v-row class="my-3" dense no-gutters>
              <v-col cols="12" sm="6">
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item value="documents" eager>
            <v-card
              elevation="3"
              class="mb-4 mt-5"
              dark
              color="blue-grey darken-1">
              <v-card-subtitle>
                Hier können Sie die Allgemeinen Geschäftsbedingungen oder
                andere allgemeine Informationen Ihres Unternehmens hochladen.
                Diese Dokumente stehen dann allen Fahrschülern zur Verfügung.
              </v-card-subtitle>
            </v-card>

            <div class="mb-5">
              <ValidationObserver ref="documentsForm">
                <v-row dense v-for="document in documents" :key="document.id">
                  <v-col
                    cols="12"
                    class="d-flex"
                    :lg="!document.createdAt ? 3 : 7">
                    <ValidationProvider
                      v-if="!document.createdAt"
                      v-slot="{ errors }"
                      :vid="'document.' + document.id + '.title'"
                      name="title"
                      class="flex-grow-1">
                      <v-text-field
                        :label="$t('form.title')"
                        v-model="document.title"
                        outlined
                        dense
                        hide-no-data
                        hide-details
                        :error="!!errors.length"
                        :disabled="document.loading" />

                      <span
                        v-if="errors.length > 0"
                        class="text-caption red--text"
                        >{{ errors[0] }}</span
                      >
                    </ValidationProvider>

                    <v-sheet
                      v-if="document.createdAt"
                      class="py-2 px-3 flex-grow-1"
                      outlined
                      rounded>
                      <span>{{ document.title }}</span>
                    </v-sheet>

                    <div
                      class="d-flex justify-end ml-2"
                      v-if="document.createdAt">
                      <v-btn
                        :href="document.file"
                        download
                        target="_blank"
                        height="42"
                        class="mr-2"
                        color="primary"
                        text
                        outlined>
                        <v-icon size="25">mdi-download</v-icon>
                      </v-btn>
                      <v-progress-circular
                        class="mt-1 mx-5"
                        v-if="document.loading"
                        color="primary"
                        indeterminate
                        size="25" />
                      <v-btn
                        height="42"
                        text
                        outlined
                        v-if="
                          !document.loading && document.type !== 'CREDENTIALS'
                        "
                        @click="deleteDocument(document)">
                        <v-icon size="25" color="red">mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </v-col>

                  <v-col
                    class="d-flex"
                    v-if="!document.createdAt"
                    cols="12"
                    lg="4">
                    <ValidationProvider
                      v-if="!document.createdAt"
                      v-slot="{ errors }"
                      :vid="'document.' + document.id + '.file'"
                      name="file"
                      class="flex-grow-1">
                      <v-file-input
                        :ref="'document.' + document.id + '.file'"
                        v-model="document.file"
                        :placeholder="$t('documents.upload_document')"
                        :label="$t('documents.upload_document')"
                        outlined
                        dense
                        hide-details
                        @change="(file) => uploadDocument(document, file)"
                        :disabled="document.loading"
                        prepend-icon=""
                        prepend-inner-icon="mdi-attachment"
                        :error="!!errors.length" />

                      <span
                        v-if="errors.length > 0"
                        class="text-caption red--text"
                        >{{ errors[0] }}</span
                      >
                    </ValidationProvider>
                    <div class="d-flex justify-end ml-2">
                      <v-progress-circular
                        class="mt-1 mx-2"
                        v-if="document.loading"
                        color="primary"
                        indeterminate
                        size="25" />
                      <v-btn
                        text
                        outlined
                        v-if="
                          !document.loading && document.type !== 'CREDENTIALS'
                        "
                        @click="deleteDocument(document)">
                        <v-icon size="25" color="red">mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <empty-box v-if="!documents.length" />
              </ValidationObserver>
            </div>
          </v-tab-item>

          <v-tab-item value="bank" eager>
            <v-row class="my-3">
              <v-col cols="12" sm="6" md="12" lg="9">
                <v-text-field
                  outlined
                  dense
                  v-model="form.bank"
                  :label="$t('form.bankName')"
                  :placeholder="$t('form.bankName')" />
                <v-text-field
                  outlined
                  dense
                  v-model="form.iban"
                  :label="$t('form.iban')"
                  :placeholder="$t('form.iban')"
                  type="text" />
                <v-text-field
                  outlined
                  dense
                  v-model="form.blz_bic"
                  :label="$t('form.blz_bic')"
                  :placeholder="$t('form.blz_bic')"
                  type="text" />
                <v-text-field
                  outlined
                  dense
                  v-model="form.taxNumber"
                  :label="$t('form.tax_number')"
                  :placeholder="$t('form.tax_number')"
                  type="text" />
                <v-text-field
                  outlined
                  dense
                  v-model="form.vatIdNumber"
                  :label="$t('form.vat_id_number')"
                  :placeholder="$t('form.vat_id_number')"
                  type="text" />
                <v-text-field
                  outlined
                  dense
                  v-model="form.creditorIdNumber"
                  :label="$t('form.creditor_id_number')"
                  :placeholder="$t('form.creditor_id_number')"
                  type="text" />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </ValidationObserver>
    </v-card-text>

    <v-card-actions v-if="!loading" class="pt-0 px-4 pb-4">
      <v-btn
        v-if="activeTab === 'documents'"
        class="text-subtitle-2"
        color="primary"
        @click="addNewDocument"
        >{{ $t("btn.new_document") }}</v-btn
      >
    </v-card-actions>

    <SignatureDialog type="school" :title="signatureDialogTitle" ref="signatureDialog" @saved="signatureSaved" />
  </div>
</template>

<script>
import schoolService from "@/services/schoolService";
import UploadBtn from "@/components/UploadBtn";
import { v4 as uuid4 } from "uuid";
import SettingsResponsiveRow from "@/components/settings/SettingsResponsiveRow";
import SUPPORTED_LOCALES from "@/const/locales";
import markdownParser from "@/utils/mixins/markdownParser";
import SegmentedProgressBar from "@/components/SegmentedProgressBar.vue";
import SignatureDialog from '@/components/SignatureDialog.vue'
import { mapActions, mapGetters } from 'vuex'
import responsive from '@/utils/mixins/responsive'

export default {
  name: "General",
  mixins: [markdownParser, responsive],
  components: {
    SignatureDialog,
    SegmentedProgressBar,
    SettingsResponsiveRow,
    UploadBtn,
  },
  data: () => ({
    loading: true,
    formLoading: false,
    activeTab: "general",
    supportedLocales: SUPPORTED_LOCALES.filter(locale => locale.code !== 'de'),
    form: {
      name: "",
      representedBy: "",
      mainDrivingInstructor: "",
      headquarters: "",
      enabledLocales: [],
      avatar: "",
      phone: "",
      mobile: "",
      fax: "",
      invoiceStreet: "",
      invoiceHouseNr: "",
      invoicePostcode: "",
      invoiceCity: "",
      mainStreet: "",
      mainHouseNr: "",
      mainPostcode: "",
      mainCity: "",
      lat: "",
      long: "",
      // workStartTime: '',
      // workEndTime: '',
      facebookLink: "",
      instagramLink: "",
      lessonDuration: 45,
      normalLessonDuration: 45,
      specialLessonDuration: 45,
      includeMissedLessonsInWorkingTime: false,
      bank: "",
      iban: "",
      blz_bic: "",
      taxNumber: "",
      vatIdNumber: "",
      creditorIdNumber: "",
    },
    resourceId: "",
    avatarImage: "",
    munichLat: "48.1351250",
    munichLong: "11.5819810",
    documents: [{ id: uuid4(), type: "", file: null, loading: false }],
    signatureDialogTitle: '',
    loadingSignature: false,
    formObserver: { errors: {} },
  }),
  computed: {
    ...mapGetters("school", ["school"]),
    formObserverErrors() {
      return this.$refs.form.errors['name']
    },
    invalidTabFields() {
      const tabsFields = {
        general: ["name", "avatar"],
        contact: [],
        address: ["mainStreet", "mainPostcode", "mainHouseNr", "mainCity", "invoiceStreet", "invoicePostcode", "invoiceHouseNr", "invoiceCity"],
        calculation: ["lessonDuration"],
        generalDocuments: [],
        finances: [],
      };

      Object.keys(tabsFields).forEach((tab) => {
        tabsFields[tab] = tabsFields[tab].filter((field) => {

          return this.formObserver.errors[field]
            ? !!this.formObserver.errors[field].length > 0
            : false;
        });
      });

      return tabsFields;
    },
    hasActiveTabErrors() {
      return this.invalidTabFields[this.activeTab]?.length > 0;
    },
  },
  async mounted() {
    this.formObserver = this.$refs.form;

    if (this.$route.query.tab) this.activeTab = this.$route.query.tab;
    this.loading = true;

    try {
      const responseSchool = await schoolService.load();

      const schoolData = responseSchool.data || {};

      this.form.name = schoolData.name;
      this.form.phone = schoolData.phone;
      this.form.mobile = schoolData.mobile;
      this.form.representedBy = schoolData.representedBy;
      this.form.mainDrivingInstructor = schoolData.mainDrivingInstructor;
      this.form.headquarters = schoolData.headquarters;
      this.form.enabledLocales = schoolData.enabledLocales || []

      this.form.fax = schoolData.fax;
      this.form.invoiceStreet = schoolData.invoiceStreet;
      this.form.invoiceHouseNr = schoolData.invoiceHouseNr;
      this.form.invoicePostcode = schoolData.invoicePostcode;
      this.form.invoiceCity = schoolData.invoiceCity;
      this.form.mainStreet = schoolData.mainStreet;
      this.form.mainHouseNr = schoolData.mainHouseNr;
      this.form.mainPostcode = schoolData.mainPostcode;
      this.form.mainCity = schoolData.mainCity;
      this.form.lat = schoolData.lat;
      this.form.long = schoolData.long;
      // this.form.workStartTime = schoolData.workStartTime
      // this.form.workEndTime = schoolData.workEndTime
      this.form.facebookLink = schoolData.facebookLink;
      this.form.instagramLink = schoolData.instagramLink;
      this.form.lessonDuration = schoolData.lessonDuration;
      this.form.normalLessonDuration = schoolData.normalLessonDuration;
      this.form.specialLessonDuration = schoolData.specialLessonDuration;
      this.form.includeMissedLessonsInWorkingTime = schoolData.includeMissedLessonsInWorkingTime
      this.form.bank = schoolData.bank;
      this.form.iban = schoolData.iban;
      this.form.blz_bic = schoolData.blz_bic;
      this.form.taxNumber = schoolData.taxNumber;
      this.form.vatIdNumber = schoolData.vatIdNumber;
      this.form.creditorIdNumber = schoolData.creditorIdNumber;

      this.resourceId = schoolData.resourceId;
      this.avatarImage = schoolData.avatar;

      const responseDocuments = await schoolService.documents();
      this.documents = [...this.documents, ...(responseDocuments.data || [])];
    } catch (e) {
      console.log(e);
    }

    this.loading = false;
  },
  methods: {
    ...mapActions("school", { loadSchool: "load" }),
    save() {
      const formData = new FormData();

      Object.keys(this.form).forEach((field) => {
        const value = this.form[field];

        if (!value) return;

        if (Array.isArray(value)) {
          value.map((item) => formData.append(field + "[]", item));
        } else {
          formData.set(field, value);
        }
      });

      formData.set("_method", "PUT");

      this.formLoading = true;
      schoolService
        .updateSettings(formData)
        .then((response) => {
          this.$store.commit('school/set', response.data)
          this.$snackbar.show({ message: this.$t("messages.successfully_sent"), color: "success", timeout: 2000 });
        })
        .catch((error) => {
          const responseData = error.response.data;
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error" });
          }

          this.$refs.form.setErrors(errors)
          this.$set(this.formObserver, 'errors', errors)
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    onFilePick(url, file) {
      this.avatarImage = url;
      this.form.avatar = file;
    },
    selectRegisterUrl(event) {
      event.target.select();
      event.target.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.$snackbar.show({
        message: this.$t("messages.copied_to_clipboard"),
        timeout: 4000,
        color: "success",
      });
    },
    onTabClick(tab) {
      this.$router
        .push({ name: "school-settings.general", query: { tab: tab } })
        .catch((error) => console.log(error));
    },
    addNewDocument() {
      this.documents.unshift({
        id: uuid4(),
        type: "",
        file: null,
        loading: false,
      });
    },
    async deleteDocument(document) {
      const documentIndex = this.documents.findIndex(
        (item) => item.id === document.id
      );

      if (documentIndex !== -1) {
        this.$set(this.documents[documentIndex], "loading", true);

        try {
          if (document.createdAt)
            await schoolService.deleteDocument(document.id);

          if (documentIndex !== -1) {
            this.documents.splice(documentIndex, 1);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    uploadDocument(document, file) {
      const formData = new FormData();

      formData.set("requestId", document.id);
      if (document.title) formData.set("title", document.title);
      formData.set("file", file);

      const documentIndex = this.documents.findIndex(
        (item) => item.id === document.id
      );

      this.$set(this.documents[documentIndex], "loading", true);
      schoolService
        .uploadDocument(formData)
        .then((response) => {
          const responseData = response.data || {};
          const documentIndex = this.documents.findIndex(
            (item) => item.id === responseData.requestId
          );

          if (documentIndex !== -1) {
            this.$set(this.documents, documentIndex, { ...responseData, loading: false });
          }
        })
        .catch((error) => {
          const responseData = error.response.data;
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });
          }

          Object.keys(errors).forEach((key) => {
            let fieldName = key;
            const fieldErrorMessage = errors[fieldName];

            if (fieldName === "key") fieldName = "title";

            this.$refs.documentsForm.setErrors({
              ["document." + document.id + "." + fieldName]: fieldErrorMessage,
            });

            const docEl = this.$refs["document." + document.id + ".file"][0];
            if (docEl.value) docEl.clearableCallback();
          });

          const docEl = this.$refs["document." + document.id + ".file"][0];
          if (docEl.value) docEl.clearableCallback();
        })
        .finally(() => {
          this.$set(this.documents[documentIndex], "loading", false);
        });
    },
    openSignatureDialog(signatureType) {
      this.signatureDialogTitle = this.$t('label.sign_as_represented_by')

      if (signatureType === 'main-driving-instructor') {
        this.signatureDialogTitle = this.$t('label.sign_as_main_driving_instructor')
      }

      this.$refs.signatureDialog.open(signatureType)
    },
    signatureSaved() {
      this.loadSchool()
    }
  },
};
</script>

<style scoped lang="scss"></style>
