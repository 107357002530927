<template>
  <div v-if="coordinates" class="sun-phases">
    <span v-if="sunrise" class="sunrise">
      <v-icon small color="orange">mdi-weather-sunset-up</v-icon>
      {{ this.sunrise }}
    </span>
    <span v-if="sunset" class="sunset">
      <v-icon small color="blue">mdi-weather-sunset-down</v-icon>
      {{ this.sunset }}
    </span>
  </div>
</template>

<script>
import sunsetSunriseMixin from "@/utils/mixins/sunsetSunriseMixin";
import { format } from "date-fns";

export default {
  name: "SunPhases",
  mixins: [sunsetSunriseMixin],
  props: {
    day: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sunrise() {
      if (!this.sunriseTime) return;

      return format(this.sunriseTime, "HH:mm") + " Uhr";
    },
    sunset() {
      if (!this.sunsetTime) return;

      return format(this.sunsetTime, "HH:mm") + " Uhr";
    },
  },
};
</script>

<style lang="scss" scoped>
.sun-phases {
  font-size: 0.6em;
  display: flex;
  flex-direction: column;
  & > .sunrise {
    color: #999;
  }
  & > .sunset {
    color: #999;
    border-top: solid 1px #eee;
  }
}
</style>
