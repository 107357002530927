<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'school-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>
        {{ $t("route.schoolSettings.children.payments") }}
      </v-card-title>
    </v-toolbar>

    <v-card-text>
      <v-card
        elevation="3"
        class="mb-4"
        dark
        color="blue-grey darken-1"
        :loading="generatingLink"
        v-if="!loading && !account.payouts_enabled">
        <v-card-title>{{
          $t("label.setup_your_payment_account")
        }}</v-card-title>
        <v-card-subtitle>
          {{ $t("messages.setup_your_payment_account") }}
        </v-card-subtitle>
        <v-card-actions>
          <v-btn
            text
            dark
            color="yellow"
            v-if="externalAccountLeft"
            @click="connectExternalAccount">
            {{ $t("btn.connect_bank_account") }}
          </v-btn>
          <v-btn
            text
            dark
            color="yellow"
            :loading="generatingLink"
            v-else
            @click="onboard">
            <span v-if="!account.id">
              {{ $t("btn.start_account_creation") }}
            </span>
            <span v-else>
              {{ $t("btn.continue_account_creation") }}
            </span>
            <v-icon right>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card elevation="0" tile outlined :loading="loading">
        <v-card-title>
          {{ $t("label.your_stripe_account") }}
          <div class="ml-1">
            <template v-if="!loading">
              <v-icon color="green" v-if="account.payouts_enabled"
                >mdi-checkbox-marked-circle-outline</v-icon
              >
              <v-icon color="red" v-else>mdi-close-circle-outline</v-icon>
            </template>
          </div>
        </v-card-title>
        <v-card-text>
          <div v-if="account.id">
            <template v-if="account.requirements.errors.length">
              <v-alert
                outlined
                type="error"
                v-for="error in account.requirements.errors"
                :key="error.requirement">
                <div>{{ $t(`messages.${error.requirement}`) }}</div>
              </v-alert>
            </template>

            <v-alert
              color="warning"
              outlined
              v-if="account.requirements.past_due.length > 1">
              <v-row align="center">
                <v-col class="grow">
                  {{ $t("messages.actions_to_finish_account_activation") }}
                  ({{ account.requirements.past_due.length }} items)
                </v-col>
                <v-col class="shrink">
                  <v-btn @click="onboard" :loading="generatingLink"
                    >Take action</v-btn
                  >
                </v-col>
              </v-row>
            </v-alert>
            <v-alert
              color="warning"
              outlined
              v-if="account.external_accounts.data.length === 0">
              <v-row align="center">
                <v-col class="grow">
                  {{ $t("messages.payouts_and_transfers_disabled") }}
                  {{ $t("btn.take_action") }}
                </v-col>
                <v-col class="shrink">
                  <v-btn @click="connectExternalAccount">{{
                    $t("btn.take_action")
                  }}</v-btn>
                </v-col>
              </v-row>
            </v-alert>

            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("label.account_id")
                }}</v-list-item-title>
                <v-list-item-subtitle>{{ account.id }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="account.business_profile.name"
                  >{{ account.business_profile.name }} ({{
                    account.business_profile.url
                  }})</v-list-item-subtitle
                >
                <v-list-item-subtitle v-else>{{
                  account.business_profile.url
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("label.payouts_status")
                }}</v-list-item-title>
                <v-list-item-subtitle v-if="account.payouts_enabled">
                  <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                  {{ $t("label.enabled") }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  <v-icon> mdi-close-circle-outline </v-icon>
                  {{ $t("label.disabled") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <template v-if="account.external_accounts.data.length">
              <v-list-item
                two-line
                v-for="(bankAccount, idx) in account.external_accounts.data"
                :key="bankAccount.id">
                <v-list-item-content>
                  <v-list-item-title
                    >{{ $t("label.external_address") }}
                    <v-chip label x-small>{{
                      idx + 1
                    }}</v-chip></v-list-item-title
                  >
                  <v-list-item-subtitle
                    >{{ bankAccount.bank_name }},
                    {{ bankAccount.country }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle>{{
                    bankAccount.id
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item three-line v-if="'company' in account">
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("label.company_address")
                }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  [
                    account.company.address.line1,
                    account.company.address.line2,
                    account.company.address.postal_code,
                  ].join(", ")
                }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{
                  [
                    account.company.address.city,
                    account.company.address.country,
                  ].join(", ")
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("label.payouts_schedule")
                }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{
                    $t(
                      `messages.payouts.${account.settings.payouts.schedule.interval}`,
                      {
                        day: $t(
                          `days.${account.settings.payouts.schedule.weekly_anchor}`
                        ),
                        days: account.settings.payouts.schedule.delay_days,
                      }
                    )
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div v-else>
            {{ !loading ? $t("messages.have_no_set_up_payment_account") : "" }}
          </div>
          <v-skeleton-loader type="list-item-three-line" v-show="loading" />
        </v-card-text>
      </v-card>

      <BankAccountModal ref="bankAccountModal" />
    </v-card-text>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/utils/api";
import BankAccountModal from "@/views/SchoolSettings/BankAccountModal";
import responsive from '@/utils/mixins/responsive'

export default {
  name: "Payments",
  components: { BankAccountModal },
  mixins: [responsive],
  computed: {
    ...mapGetters("school", { school: "school" }),
    disabledReason() {
      return this.account.requirements.disabled_reason !== null;
    },
    externalAccountLeft() {
      return (
        this.account.id &&
        this.account.requirements.past_due.length === 1 &&
        this.account.requirements.past_due.includes("external_account")
      );
    },
  },
  data() {
    return {
      account: { id: null },
      loading: true,
      generatingLink: false,
    };
  },
  methods: {
    onboard() {
      this.generatingLink = true;
      api.post("stripe/onboard").then((resp) => {
        window.location = resp.data.url;
      });
    },
    connectExternalAccount() {
      this.$refs.bankAccountModal.open();
    },
  },
  mounted() {
    api.get("stripe/account-info").then((resp) => {
      this.account = resp.data;
      this.loading = false;
    });
  },
};
</script>

<style scoped></style>
