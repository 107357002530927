<script>
import studentsService from "@/services/studentsService";
import FileSaver from "@/helpers/fileSaver";

export default {
  name: "StudentCredentialsListItem",
  props: {
    student: {
      required: true
    }
  },
  data: () => {
    return {
      loading: false
    }
  },
  methods: {
    downloadOneTimePasswordPDF() {
      this.loading = true
      studentsService
          .downloadOnTimePassword(this.student.id)
          .then((response) => {
            const fileSaver = new FileSaver([response.data])
            fileSaver
                .setType('application/pdf')
              .saveToDevice(`Einmalpasswort ${this.student.fullName}`)
          })
          .catch((error) => console.log(error))
          .finally(() => {
            this.loading = false
          });
    },
  }
}
</script>

<template>
  <v-list-item v-if="student.oneTimePassword" :disabled="loading" link @click="downloadOneTimePasswordPDF">
    <v-list-item-icon :disabled="loading">
      <v-progress-circular v-if="loading" indeterminate size="21" width="3" />
      <v-icon v-else>mdi-account-key</v-icon>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title>{{$t("label.access_data")}}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<style scoped>

</style>
