import Notification from "@/notifications/notification";

export default class StudentRegistered extends Notification {
  icon() {
    return "mdi-account-arrow-left-outline";
  }

  wildCardsData() {
    const data = this.notification.data;

    return {
      message: {
        studentName: data.studentName,
        chosenLicense: data.chosenLicense,
      },
    };
  }
}
