import {mapGetters} from "vuex";
import StudentBalanceBreakdownDialog from "@/components/student/StudentBalanceBreakdownDialog";

export default {
    components: { StudentBalanceBreakdownDialog },
    props: {
        student: {
            type: Object,
            required: true
        },
    },
    computed: {
        ...mapGetters("school", ['balanceLimit']),
        color() {
            const amount = parseFloat(this.student.balance);

            if (this.disabled) return "grey";

            if (amount <= 200 && amount > 50) {
                return "warning";
            }

            if (amount > 200) return "success";

            return "error";
        },
        iconValue () {
            if (! this.balanceLimit.enabled)  return 'mdi-cash';
            if (this.student.balance !== this.student.availableBalance) return 'mdi-cash-lock';

            return 'mdi-cash-check'
        },
        hasBlockedOrInsufficientFunds() {
            return this.balanceLimit.enabled &&
                (
                    this.student.balance !== this.student.availableBalance ||
                    this.hasInsufficientFunds
                );
        },
        hasInsufficientFunds() {
            return this.balanceLimit.enabled && this.student.availableBalance < this.balanceLimit.requiredAmount
        },
        cursor () {
            return {
                cursor: this.hasBlockedOrInsufficientFunds ? 'pointer' : ''
            }
        }
    },
    methods: {
        openBalanceBreakdown () {
            if (! this.hasBlockedOrInsufficientFunds) return;
            this.$refs.StudentBalanceBreakdownDialog.open();
        }
    }
}
