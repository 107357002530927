<template>
  <v-autocomplete
    v-model="vehicleId"
    :disabled="disabled"
    :label="label"
    :loading="loading"
    :items="vehiclesList"
    item-value="id"
    :item-text="vehicleName"
    item-disabled="isDeleted"
    :hide-no-data="showNoData"
    outlined
    hide-details
    dense
    clearable
    :append-icon="!disabled ? 'mdi-menu-down' : ''"
    :menu-props="{ contentClass: 'fit-parent-width' }"
    @focus="makeRequest"
  >
    <template v-slot:item="{ item }">
      <template v-if="getVehicleData(item.id)?.isAvailable">
        <v-list-item-content>
          <v-list-item-title :class="{ 'grey--text': item.isDeleted }">
            {{ item.name }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon v-if="item.isDeleted">
          <v-icon small color="grey">mdi-delete</v-icon>
        </v-list-item-icon>
      </template>

      <template v-else>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="grey--text">
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <span style="color: red">{{ $t(`label.reason_${getVehicleData(item.id)?.reason}`) }} - </span>
              <span v-if="getVehicleData(item.id)?.teacherId" style="color: red">
                {{ teacherData(getVehicleData(item.id).teacherId, 'fullName') }}
              </span>
              <span v-if="getVehicleData(item.id)?.end" style="color: red">
                {{ getEndDate(getVehicleData(item.id).end) }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon v-if="teacherData(getVehicleData(item.id)?.teacherId, 'phone')">
            <v-btn
              icon
              color="green"
              @click="callTo(teacherData(getVehicleData(item.id).teacherId, 'phone'))"
            >
              <v-icon>mdi-phone</v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>

      </template>

    </template>
  </v-autocomplete>
</template>

<script>

import { showConfirm } from "@/helpers/dialogs"
import TeacherModel from '@/store/models/TeacherModel'
import { format, parseISO } from 'date-fns'

export default {
  name: "VehicleFieldDynamic",
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showNoData: {
      type: Boolean,
      default: true,
    },
    validation: {
      type: Boolean,
      default: false,
    },
    vehiclesList: {
      type: Array,
      required: true,
    },
    vehiclesData: {
      type: Array,
    },
    vehicleProp: {
      type: Number,
    },
  },
  computed: {
    vehicleId: {
      set(value) {
        this.$emit("emitVehicleChange", value);
      },
      get() {
        return this.vehicleProp;
      },
    },
  },
  methods: {
    getVehicleData(id) {
      return this.vehiclesData?.find((item) => item.id === id) || null
    },
    getEndDate(date) {
      const parsedDate = parseISO(date)
      return format(parsedDate, 'dd.MM.yyyy HH:mm')
    },
    teacherData(id, field) {
      const teacher = TeacherModel.find(id)
      return teacher ? teacher[field] : null
    },
    makeRequest() {
      this.$emit("emitRequestVehicles");
    },
    vehicleName(item) {
      return `${item.make} ${item.model} (${item.licensePlate})`;
    },
    callTo(phone) {
      showConfirm(
        this.$t('btn.confirm'),
        this.$t('messages.do_you_want_to_call'),
        () => window.open(`tel:${phone}`, '_self')
      )
    }
  },
};
</script>
