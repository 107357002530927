<template>
  <v-alert class="mt-6" dense color="info" colored-border border="left" elevation="1">
    <v-row align="center">
      <v-col class="grow">
        <v-skeleton-loader v-if="loading" type="text" />
        <template v-else>
          {{ evaluation?.id
            ? $t('insights.appointment_has_been_evaluated')
            : $t('insights.appointment_is_available_for_evaluation') }}
        </template>
      </v-col>
      <v-col class="shrink">
        <v-btn
            @click="evaluationClick"
            :loading="loading"
            text="text"
            color="primary"
        >
          {{ evaluation?.id ? $t('insights.btn.preview') : $t('insights.btn.evaluate') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>

import insightsService from '@/services/insightsService'

export default {
  name: "AppointmentEvaluationBox",
  data: () => ({
    loading: true,
    evaluation: null
  }),
  props: {
    appointmentId: {
      type: Number,
      required: true
    }
  },
  mounted() {
    insightsService
        .checkAppointmentEvaluation(this.appointmentId)
        .then(resp => {
          const { evaluation } = resp.data
          if (evaluation) {
            this.evaluation = evaluation
          }
        })
        .finally(() => {
          this.loading = false
        })
  },
  methods: {
    evaluationClick() {
      sessionStorage.setItem('from', 'calendar');
      if (this.evaluation?.id) {
        this.$router.push({ name: 'calendar.appointment', params: { id: this.appointmentId } })
      } else {
        this.$router.push({ name: 'calendar.appointment.evaluation', params: { id: this.appointmentId } })
      }
    },
  }
};
</script>

<style lang="scss" scoped></style>
