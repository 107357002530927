var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.alertType !== null)?_c('v-alert',{attrs:{"type":_vm.alertType,"dismissible":""}},[_vm._v(" "+_vm._s(_vm.resultMessage)+" ")]):_vm._e(),_c('v-card',{attrs:{"outlined":"","elevation":"5"}},[_c('v-img',{staticStyle:{"position":"absolute","top":"10px","right":"10px","opacity":"0.6"},attrs:{"src":require('@/assets/svg/stripe.svg'),"width":"50","height":"auto","aspect-ratio":"2.3925","contain":"","eager":""}}),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("label.add_funds"))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t("label.checkout_with_stripe"))+" ")]),_c('v-card-text',[(!_vm.isMobileApp)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12","offset":"0","md":"8","offset-md":"2"}},[_c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:({
                currency: null,
                allowNegative: false,
                valueRange: { min: 0, max: 10000 },
                valueAsInteger: false,
                precision: 2,
              }),expression:"{\n                currency: null,\n                allowNegative: false,\n                valueRange: { min: 0, max: 10000 },\n                valueAsInteger: false,\n                precision: 2,\n              }"}],ref:"amountField",staticClass:"mb-0",attrs:{"label":_vm.$t('label.enter_money_amount'),"placeholder":"0,00","solo":"","append-icon":"mdi-currency-eur","hide-details":""},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMinAmountNotification),expression:"showMinAmountNotification"}],staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t("label.payment_form_min_amount_notification"))+" ")]),(_vm.shortcuts)?_c('v-chip-group',{staticClass:"mt-0"},_vm._l((_vm.shortcutValues),function(shortcut){return _c('v-chip',{key:shortcut.value,staticClass:"ma-2",attrs:{"small":"","color":shortcut.bonus && _vm.isPaymentBonusAvailable
                    ? 'green'
                    : 'primary',"outlined":"","pill":""},on:{"click":function($event){return _vm.setAmount(shortcut.value)}}},[_vm._v(" "+_vm._s(shortcut.value)+" "),(shortcut.bonus && _vm.isPaymentBonusAvailable)?_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("mdi-percent")]):_vm._e(),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v(" mdi-currency-eur ")])],1)}),1):_vm._e(),(_vm.isPaymentBonusAvailable)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("label.payment_form_bonus_explanation"))+" ")]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","offset":"0","md":"8","offset-md":"2"}},[_c('v-btn',{attrs:{"large":"","color":"primary","loading":_vm.loading,"disabled":_vm.checkoutButtonDisabled},on:{"click":_vm.checkout}},[_vm._v(" "+_vm._s(_vm.$t("btn.continue_to_checkout"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-cash-plus")])],1)],1)],1)]:[_c('v-row',[_c('v-col',{attrs:{"cols":"12","offset":"0","md":"8","offset-md":"2"}},[_vm._v(" Um Zahlungen vorzunehmen, logge Dich bitte auf der Weboberfläche ein. ")]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","offset":"0","md":"8","offset-md":"2"}},[_c('v-btn',{on:{"click":_vm.openBrowserForPayment}},[_vm._v("Zur Zahlung")])],1)],1)]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }