<template>
  <v-badge
    :color="color"
    :offset-y="offsetY"
    :offset-x="offsetX"
    :content="badge"
    :value="!!badge"
  >
    <slot />
  </v-badge>
</template>

<script>
export default {
  name: "SidebarBadge",
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    offsetY: {
      type: [Number, String],
      default: undefined
    },
    badge: {
      default: undefined
    },
  },
  computed: {
    offsetX() {
      return this.badge && this.badge.toString().length * 6;
    },
  },
};
</script>

<style scoped></style>
