<template>
  <div :class="`insights ${!isInsightsStartPage || 'start-page'}`">
    <TopBar class="ins-header" />
    <Sidebar v-if="!isInsightsStartPage" :loadingEvaluation="loadingEvaluation" class="ins-sidebar"/>
    <div class="ins-body">
      <categories-loader v-if="loadingEvaluation && isInsightsStartPage" class="pa-2"/>
      <RouterTransition v-else>
        <template v-if="isInsightsStartPage && rootCategories">
          <EvaluationCategoryGroup class="pa-2" :categories="rootCategories" />
        </template>
        <v-fade-transition v-else mode="out-in" :hide-on-leave="$vuetify.breakpoint.mobile">
          <router-view />
        </v-fade-transition>
      </RouterTransition>
    </div>
  </div>
</template>

<script>

import RouterTransition from '@/components/RouterTransition.vue'
import TopBar from '@/components/insights/TopBar.vue'
import Sidebar from '@/components/insights/Sidebar.vue'
import CategoryModel from '@/store/models/insights/CategoryModel'
import EvaluationCategoryGroup from '@/components/insights/EvaluationCategoryGroup.vue'
import CategoriesLoader from '@/components/skeleton-loaders/evaluations/CategoriesLoader.vue'
import insightsService from '@/services/insightsService'
import EvaluationModel from '@/store/models/insights/EvaluationModel'
import EvaluationItemModel from '@/store/models/insights/EvaluationItemModel'
import TemplateModel from '@/store/models/insights/TemplateModel'
import user from '@/utils/mixins/user'

export default {
  name: 'AppointmentEvaluationProcess',
  components: { EvaluationCategoryGroup, TopBar, Sidebar, RouterTransition, CategoriesLoader },
  mixins: [user],
  data: () => ({
    loadingEvaluation: true,
    finishedItems: [],
  }),
  props: {
    appointmentId: {
      type: Number,
      required: true
    }
  },
  mounted() {
    insightsService.getAppointmentEvaluation(this.appointmentId)
      .then(async (response) => {
        const data = response.data
        this.appointment = data.appointmentData
        EvaluationModel.create({ data })
        EvaluationItemModel.create({ data: data.items })
        // TODO: Handle the case when the templateId might be null
        const templateRequestData = {}
        if (data?.appointmentData) {
          templateRequestData.appointmentId = this.appointmentId
          templateRequestData.awaitingEvaluation = this.isStudent
        }

        if (!this.isStudent) {
          await insightsService
            .getCompletedItems(data?.appointmentData?.studentsIds[0], data?.appointmentData?.licenseId)
            .then((resp) => {
              this.finishedItems = resp.data
            })
        }

        insightsService.getTemplate(data.templateId, templateRequestData)
          .then((response) => {
            TemplateModel.create({ data: {...response.data, finishedItems: this.finishedItems} })
          })
          .finally(() => {
            this.loadingEvaluation = false
          })

      })
      .catch(() => {
        this.loadingEvaluation = false
      })
  },
  computed: {
    isInsightsStartPage() {
      return !this.$route.params.categoryId
    },
    rootCategories() {
      return CategoryModel.query().where('parentId', null).with(['children', 'items']).orderBy('order').get()
    },
  },
}

</script>

<style lang="scss" scoped>
.insights {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  overflow: hidden;
  grid-template-areas: 'header header'
                       'sidebar body';
  grid-auto-columns: 64px auto;
  grid-template-rows: 48px auto;

  &.start-page {
    grid-template-areas: 'header'
                         'body';
    grid-auto-columns: auto;
  }

  .ins-header{
    grid-area: header;
    border-bottom: 1px solid #ccc !important;
    background: #fff;
  }
  .ins-sidebar{
    grid-area: sidebar;
    border-right: 1px solid #ccc;
    text-align: center;
    background: #fff;
  }
  .ins-body{
    overflow: auto;
    background: #fff;
    grid-area: body;
  }
  .ins-footer{
    grid-area: footer;
    border-top: 1px solid #ccc;
    background: #fff;
  }

  .bordered{
    border: 2px solid #ccc;
  }
}
</style>
