<script>

export default ({
  name: "CategoriesLoader",
})
</script>
<template>
  <div class="categories-grid">
    <div v-for="n in 7 " :key="n" class="category-item d-flex flex-column align-center justify-start pa-2 text-decoration-none text-center">

      <v-skeleton-loader type="avatar" height="64" />

      <span class="mt-3 text-center d-block" style="font-size: 0.8rem !important;">
        <v-skeleton-loader type="text" width="70"/>
      </span>

      <span class="d-block text-center grey--text" style="font-size: 0.7rem !important;">
        <v-skeleton-loader type="text" width="100"/>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.categories-grid {
  grid-gap: .5em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px,1fr));
  padding: 0;
}
.category-item{
  border-radius: 5px;
  border: solid 1px #ccc;
  position: relative;
}
.absolute-badge{
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
