<template>
  <div class="page"><slot /></div>
</template>

<script>
export default {
  name: "Page",
};
</script>

<style scoped>
.page {
  display: flex;
  flex-direction: column;
  height: inherit;
}
</style>
