<template>
  <div class="overline mt-4" style="width: 360px">
    <v-img :src="require('@/assets/svg/drivolino-name-logo.svg')" contain height="16" class="mb-3" />
    drivolino GmbH &copy; {{ currentYear }}
    <div>
      <a class="text-uppercase mr-2" href="https://www.drivolino.de/impressum.php" target="_blank">Impressum</a>
      <a class="text-uppercase" href="https://www.drivolino.de/datenschutz" target="_blank">Datenschutz</a>
    </div>
    <v-tooltip top v-if="appVersion" >
      <template v-slot:activator="{ on, attrs }">
        <div class="text--secondary ma-0 pa-0" v-bind="attrs" v-on="on">
          v{{ packageJsonVersion }}
        </div>
      </template>
      <div>Appstore version: {{ appVersion }}</div>
    </v-tooltip>
    <v-tooltip>

    </v-tooltip>

  </div>
</template>

<script>
import appVersion from "@/utils/mixins/appVersion";
import packageInfo from '@/../package.json'
export default {
  name: "CopyrightAndVersion",
  mixins: [appVersion],
  data () {
    return {
      currentYear: (new Date()).getFullYear(),
    }
  },
  computed: {
    packageJsonVersion() {
      return packageInfo.version
    }
  }
}
</script>

<style scoped>

</style>
