<template>
<div>
  <v-row dense :no-gutters="$vuetify.breakpoint.xsOnly">
    <v-col cols="12" sm="7" md="6" lg="8">
      <v-skeleton-loader type="heading" />
      <v-skeleton-loader class="mt-1" type="text@1" />
    </v-col>

    <v-col cols="1" v-if="$vuetify.breakpoint.smAndUp" class="text-center"><v-divider vertical/></v-col>

    <v-col cols="12" sm="4" md="5" lg="3">
      <v-skeleton-loader type="image" height="40" width="100%" />
    </v-col>
  </v-row>

  <v-divider v-if="dividerAfter" class="my-5" />
</div>
</template>

<script>
export default {
  name: "SettingsResponsiveRowLoader",
  props: {
    dividerAfter: {
      type: Boolean,
      default: true
    }
  }
}
</script>


<style scoped>

</style>
