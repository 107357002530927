<template>
  <v-snackbar
    multi-line
    class="v-snack--toast"
    elevation="1"
    :value="true"
    color="secondary"
    :timeout="snackbar.timeout"
    :left="snackbar.position.left"
    :right="snackbar.position.right"
    :top="snackbar.position.top"
    :bottom="snackbar.position.bottom"
    transition="slide-x-transition"
  >
    <div class="d-flex">
      <div class="d-flex align-start px-2 pt-1">
        <v-icon v-if="snackbar.icon" size="35">{{ snackbar.icon }}</v-icon>
      </div>

      <div class="d-flex flex-column ml-1">
        <span class="title">{{ snackbar.title }}</span>

        <span class="text-body-1">{{ snackbar.message }}</span>
      </div>
    </div>

    <template v-slot:action>
      <div style="height: 100%">
        <v-btn
          class="mr-1 mb-auto"
          icon
          small
          text
          @click="deleteSnackbar(snackbar.id)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackbarToast",
  props: {
    snackbar: {
      type: Object,
      required: true,
    },
  },
  methods: {
    deleteSnackbar(notificationId) {
      this.$snackbar.delete(notificationId);
    },
  },
};
</script>

<style lang="scss">
.v-snack--toast {
  max-width: 450px !important;
  margin-top: calc(50px + env(safe-area-inset-top)) !important;

  .v-snack__action {
    padding-top: 10px;
    align-self: flex-start !important;
  }
}
</style>
