<template>
<v-dialog width="600" :value="show" @click:outside="close" @keydown.esc="close" scrollable>
  <v-card v-if="license">
    <v-toolbar class="mb-4" dark color="primary" dense>
      <v-toolbar-title>{{ $t('insights.templates_for_license', { license: license.name }) }}</v-toolbar-title>
      <v-spacer/>
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-list three-line>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t('label.select_insights_template') }}</v-list-item-title>
            <v-list-item-subtitle></v-list-item-subtitle>
            <ValidationObserver ref="form">
              <ValidationProvider v-slot="{ errors }" name="template">
                <v-select
                  class="mt-1"
                  dense
                  :error-messages="errors"
                  :hide-details="! $refs.form?.errors?.template || ! $refs.form?.errors?.template?.length"
                  v-model="form.template"
                  :items="filteredTemplates"
                  item-text="name"
                  item-value="id"
                  :label="$t('form.template')"
                  :placeholder="$t('form.template')"
                  outlined
                />
              </ValidationProvider>
            </ValidationObserver>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t('label.collect_student_self_evaluation') }}</v-list-item-title>
            <v-list-item-subtitle>{{ $t('label.student_self_evaluation_explanation') }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch v-model="form.selfEvaluation" dense hide-details :ripple="false" />
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-btn text @click="close">{{ $t("btn.close") }}</v-btn>
      <v-spacer/>
      <v-btn color="primary" @click="save" :loading="assigning" :disabled="assigning">
        {{ $t('insights.btn.assign_template') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>
<script>
import LicenseModel from '@/store/models/LicenseModel'
import StudentModel from '@/store/models/StudentModel'

import insightsService from '@/services/insightsService'

export default {
  name: 'InsightsTemplateLicenseDialog',
  props: {
    studentId: {
      type: [Number, String],
      required: true
    },
    templates: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      show: false,
      assigning: false,
      licenseId: null,
      form: {
        template: null,
        selfEvaluation: true,
      }
    }
  },
  computed: {
    filteredTemplates() {
      return this.templates.filter(item => item.licenses.map(item => item.id).includes(this.licenseId));
    },
    license() {
      return LicenseModel.find(this.licenseId)
    },
    student() {
      return StudentModel.find(this.studentId)
    }
  },
  methods: {
    open(licenseId) {
      this.show = true
      this.licenseId = licenseId
      this.form.template = this.filteredTemplates[0]?.id || null
    },
    close() {
      this.show = false
      setTimeout(() => {
        this.form.template = null
        this.form.selfEvaluation = true
      }, 200)
    },
    save() {
      this.assigning = true
      const { template, selfEvaluation } = this.form
      insightsService
        .assignTemplateToStudentLicense(this.studentId, this.licenseId, { template, selfEvaluation })
        .then(response => {
            StudentModel.update({where: this.studentId, data: {
              hasInsightsEnabled: true,
                licensesWithInsightsTemplate: [...this.student.licensesWithInsightsTemplate, response.data]
            }})
            this.$snackbar.success(this.$t('messages.template_assigned_successfully'));
            this.close()
          })
          .catch((error) => {
            console.log(error)

            const responseData = error?.response?.data || {};

            if (responseData.message) this.$snackbar.error(responseData.message);
          })
          .finally(() => {
            this.assigning = false
          })
    }
  }
}
</script>
