<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th style="width: 150px">{{ $t("label.payout_id") }}</th>
          <th style="width: 100px">{{ $t("label.status") }}</th>
          <th>{{ $t("label.amount") }}</th>
          <th>{{ $t("label.created") }}</th>
          <th>{{ $t("label.estArrival") }}</th>
          <th style="width: 70px">{{ $t("label.view") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="payout in data" :key="payout.id" class="table-row">
          <td style="width: 10px !important">
            <v-chip
              label
              small
              class="text-monospace"
              link
              @click="copyToClipboard(payout.id)">
              {{ payout.id | ellipsis }}
            </v-chip>
          </td>
          <td>
            <v-chip label small link :color="payout.statusColor" dark>
              {{ payout.statusText }}
            </v-chip>
          </td>
          <td>
            <span class="font-weight-bold">
              {{ payout.amount | currency }}
            </span>
          </td>
          <td>
            {{ payout.createdDisplayDate }}
          </td>
          <td>
            {{ payout.arrivedDisplayDate }}
          </td>
          <td style="width: 50px">
            <v-chip
              class="hidden-until-row-hovered"
              link
              label
              x-small
              :to="{ name: 'payouts.view', params: { id: payout.id } }">
              <v-icon x-small>mdi-arrow-right</v-icon>
            </v-chip>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import clipboard from "@/utils/mixins/clipboard";
import PayoutModel from "@/store/models/PayoutModel";

export default {
  name: "PayoutsTable",
  mixins: [clipboard],
  props: {
    payouts: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
    },
  },
  mounted() {
    PayoutModel.create({ data: this.payouts || [] });
  },
  computed: {
    data() {
      const query = PayoutModel.query();

      query.orderBy("created", "desc");
      if (this.limit) {
        query.limit(this.limit);
      }
      return query.get();
    },
  },
};
</script>

<style scoped lang="scss"></style>
