<template>
  <v-container fluid>
    <v-card :loading="loading">
      <v-toolbar elevation="3">
        <v-btn icon @click="$router.back()" class="ml-0" exact>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-card-title class="text-overflow-ellipsis">
          <v-skeleton-loader
            width="150"
            height="12"
            type="text@1"
            v-if="loading" />
          <span class="mr-2 text-overflow-ellipsis" v-else>
            {{ $t("label.invoice") }}
            <span class="text-monospace">{{ invoice.number }}</span>
          </span>
        </v-card-title>
        <v-spacer/>
        <v-btn
          icon
          color="primary"
          @click="downloadInvoice"
          :loading="downloadStarted"
          :disabled="downloadStarted"
        >
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-btn
          icon
          :color="invoice.isSentToStudent ? 'green' : 'blue'"
          v-if="invoice && !invoice.isCanceled"
          @click="sendInvoice"
          :loading="sendingStarted"
          :disabled="sendingStarted">
          <v-icon>{{invoice.isSentToStudent ? "mdi-send-check" : "mdi-send" }}</v-icon>
        </v-btn>
        <v-btn
          icon
          color="green"
          v-if="invoice && !invoice.isCanceled && !invoice.isPaid"
          :loading="markingAsPaidStarted"
          :disabled="markingAsPaidStarted"
          @click="markAsPaid"
        >
          <v-icon>mdi-cash-check</v-icon>
        </v-btn>
        <v-btn
          icon
          color="red"
          @click="cancelInvoice"
          v-if="invoice && !invoice.isCanceled"
          :disabled="cancelingStarted"
          :loading="cancelingStarted"
        >
          <v-icon>mdi-file-cancel</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="!loading">
        <v-row v-if="invoice.isCanceled">
          <v-col>
            <v-alert type="error">
              <v-row align="center">
                <v-col class="grow">
                  {{ $t("label.this_invoice_was_canceled") }}
                  <span class="caption" v-if="justCanceled && invoice.studentId">
                    <br />
                  {{ $t("label.cancellation_explanation") }}
                  </span>
                </v-col>
                <v-col class="shrink" v-if="justCanceled">
                  <v-btn
                      v-if="invoice.studentId"
                    :to="{
                      name: 'students.student.invoices',
                      params: { id: this.invoice.studentId }
                    }"
                  >
                    {{ $t("btn.to_services") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-list>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title v-if="invoice.companyName" class="font-weight-bold">{{ invoice.companyName }}</v-list-item-title>
                  <v-list-item-title class="font-weight-bold">{{ invoice.clientName }}</v-list-item-title>
                  <v-list-item-title>{{ invoice.addressLine1 }}</v-list-item-title>
                  <v-list-item-title>{{ invoice.addressLine2 }}</v-list-item-title>
                  <v-list-item-title v-if="invoice.country">{{ invoice.country }}</v-list-item-title>
                  <v-list-item-title v-if="invoice.email">
                    <a :href="`mailto:${invoice.email}`">
                      {{ invoice.email }}
                    </a>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t("label.paid") }}</v-list-item-subtitle>
                  <v-list-item-title v-if="invoice.isPaid">
                    <v-chip label outlined>
                      <v-icon color="green" left>mdi-cash-check</v-icon>
                      {{ invoice.paidAt }}
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          label
                          outlined
                          @click="markAsPaid"
                          :disabled="markingAsPaidStarted || invoice.isCanceled"
                          v-bind="attrs"
                          v-on="on">
                          <v-icon color="red" left>mdi-cash-remove</v-icon>
                          {{ $t("label.open") }}
                        </v-chip>
                      </template>
                      <span>{{ $t("label.mark_as_paid") }}</span>
                    </v-tooltip>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col>
            <v-list>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle>{{
                    $t("label.invoice_nr")
                  }}</v-list-item-subtitle>
                  <v-list-item-title>{{ invoice.number }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle>{{$t("label.invoice_period")}}</v-list-item-subtitle>
                  <v-list-item-title>{{`${invoice.startDate} - ${invoice.endDate}`}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item three-line v-if="invoice.studentId">
                <v-list-item-content>
                  <v-list-item-subtitle>{{$t("label.student")}}</v-list-item-subtitle>
                  <v-list-item-title>{{invoice.studentName}}</v-list-item-title>
                  <v-list-item-title>{{invoice.studentNumber}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle>{{$t("label.date")}}</v-list-item-subtitle>
                  <v-list-item-title>{{invoice.formattedDate}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <InvoiceServicesTable :services="invoice.services" />
    </v-card>
    <mark-invoice-as-paid-dialog ref="markInvoiceAsPaidDialog" @paid="handleInvoiceMarkedAsPaid" />
  </v-container>
</template>

<script>
import invoicesService from "@/services/invoicesService";
import InvoiceServicesTable from "@/components/invoices/InvoiceServicesTable";
import i18n from "@/plugins/i18n";
import FileSaver from "@/helpers/fileSaver";
import MarkInvoiceAsPaidDialog from "@/components/invoices/MarkInvoiceAsPaidDialog.vue";
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: "ViewInvoice",
  components: {MarkInvoiceAsPaidDialog, InvoiceServicesTable },
  data() {
    return {
      id: null,
      loading: true,
      invoice: null,
      downloadStarted: false,
      cancelingStarted: false,
      markingAsPaidStarted: false,
      sendingStarted: false,
      justCanceled: false,
    };
  },
  created() {
    this.id = this.$route.params.id;
    invoicesService
      .loadItem(this.id)
      .then((resp) => {
        this.invoice = resp.data;
      })
      .catch((error) => {
        const response = error.response || {};
        const { status } = response;

        if (status && status === 404) {
          this.$snackbar.show({
            message: "Payment not found!",
            color: "error",
          });
          this.$router.push({ name: "payments" });
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    downloadInvoice() {
      this.downloadStarted = true;
      invoicesService
        .downloadInvoice(this.id)
        .then((resp) => {
          const responseData = [resp.data] || [];
          const fileSaver = new FileSaver(responseData)
          fileSaver.setType('application/pdf').saveToDevice(this.invoice.fileName, 'Rechnungen')
        })
        .finally(() => {
          this.downloadStarted = false;
        });
    },
    cancelInvoice() {
      showConfirm(
        this.$t("label.invoice_cancellation_question"),
        this.invoice.studentId
            ? this.$t("label.invoice_cancellation_description")
            : this.$t("label.studentless_invoice_cancellation_description"),
        () => {
          this.cancelingStarted = true;
          invoicesService
            .cancel(this.id)
            .then((resp) => {
              this.invoice = resp.data;
              this.justCanceled = true;
            })
            .finally(() => {
              this.cancelingStarted = false;
            });
        }
      );
    },
    send() {
      this.sendingStarted = true;
      invoicesService
        .sendInvoice(this.id)
        .then((resp) => {
          this.invoice = resp.data;
          this.$snackbar.show({
            message: this.$t("label.invoice_sent"),
            color: "green",
            timeout: 5000,
          });
        })
        .catch((error) => {
          if (!error.response) console.log(error);

          const responseData = error.response.data;

          if (responseData.message) {
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });
          }
        })
        .finally(() => {
          this.sendingStarted = false;
        });
    },
    sendInvoice() {
      if (this.invoice.isSentToStudent) {
        showConfirm(
          i18n.t("label.invoice_already_sent"),
          i18n.t("label.do_you_want_to_send_invoice_again"),
          this.send
        );
      } else {
        this.send();
      }
    },
    markAsPaid() {
      this.$refs.markInvoiceAsPaidDialog.open(this.id);
    },
    handleInvoiceMarkedAsPaid(invoice) {
      this.invoice = invoice;
    }
  },
};
</script>

<style scoped lang="scss"></style>
