<script>
export default {
  name: 'StudentDocumentListItem',
  props: {
    isStudentDeleted: {
      type: Boolean,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    }
  }
}
</script>

<template>
  <v-list-item link :disabled="isStudentDeleted">
    <v-list-item-icon>
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title>{{ label }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<style scoped>

</style>
