import { Model } from "@vuex-orm/core";
import i18n from "@/plugins/i18n";

export default class PayoutModel extends Model {
  static entity = "payouts";

  static fields() {
    return {
      id: this.string(null),
      amount: this.number(0),
      destination: this.string(null),
      created: this.string(null),
      createdDisplayDate: this.string(null),
      arrived: this.string(null),
      arrivedDisplayDate: this.string(null),
      status: this.string(null),
      automatic: this.boolean(null),
    };
  }

  get statusColor() {
    const colors = {
      paid: "green",
      pending: "gray",
      failed: "red",
      canceled: "amber",
      in_transit: "blue",
    };
    return this.status in colors ? colors[this.status] : "gray";
  }

  get statusText() {
    const labels = {
      paid: i18n.t("payout_status.paid"),
      pending: i18n.t("payout_status.pending"),
      failed: i18n.t("payout_status.failed"),
      canceled: i18n.t("payout_status.canceled"),
      in_transit: i18n.t("payout_status.in_transit"),
      unknown: i18n.t("payout_status.unknown"),
    };

    return this.status in labels ? labels[this.status] : labels.unknown;
  }

  get statusIcon() {
    const icons = {
      paid: "mdi-check",
      pending: "mdi-clock",
      failed: "mdi-bank-remove",
      canceled: "mdi-cancel",
      in_transit: "mdi-bank-transfer-in",
      unknown: "mdi-progress-question",
    };
    return this.status in icons ? icons[this.status] : icons.unknown;
  }
}
