<script>
// import SettingsResponsiveRow from "@/components/settings/SettingsResponsiveRow.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "TreeSettingsDialog",
  // components: {SettingsResponsiveRow},
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapGetters('studentsDirectory', ['isTreeSelectable', 'selectedTreeItems', 'treeGroupTypes', 'groupByLabel'])
  },
  methods: {
    ...mapActions('studentsDirectory', ['setGroupBy', 'load', 'clearSelectedStudents']),
    changeGroupingType(value) {
      this.setGroupBy(value);
      this.load();
    },
    close () {
      this.dialog = false;
      if (! this.$store.state.studentsDirectory.isTreeSelectable) {
        this.clearSelectedStudents()
      }
    }
  }
}
</script>

<template>
  <div>
    <v-menu
      v-model="dialog"
      :close-on-content-click="false"
      offset-y
      transition="slide-y-transition"
      max-width="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          height="100%"
          block
          text
          tile
          depressed
          small
          class="d-flex justify-space-between"
          @click.stop="dialog = true"
          :loading="$store.state.studentsDirectory.globalLoading"
          v-bind="attrs"
          v-on="on"
        >
          <template v-slot:default>
            <span class="font-weight-bold">{{ groupByLabel }}</span>
            <v-icon right>mdi-filter-cog-outline</v-icon>
          </template>
          <template v-slot:loader>
            <v-progress-linear indeterminate height="100%" color="grey">
              {{ $t('loading') }}
            </v-progress-linear>
          </template>
        </v-btn>
      </template>

      <v-card flat>
        <v-card-text>
          <div class="overline">
            {{ $t('label.group_by') }}
          </div>
          <v-select
              outlined
              :items="treeGroupTypes"
              @change="changeGroupingType"
              v-model="$store.state.studentsDirectory.groupBy"
              @input="dialog = false"
              hide-details
          />

<!--          <div class="overline mt-2">-->
<!--            {{ $t('label.selection_mode') }}-->
<!--          </div>-->
<!--          <v-switch-->
<!--              hide-details-->
<!--              v-model="$store.state.studentsDirectory.isTreeSelectable"-->
<!--              :label="$t('label.active')"-->
<!--              class="mt-0"-->
<!--          />-->
        </v-card-text>
      </v-card>
    </v-menu>
  </div>

<!--  <div>-->
<!--    <v-dialog v-model="dialog" width="1100" @click:outside="close">-->
<!--      <v-card flat tile>-->
<!--        <v-toolbar dark dense color="primary" class="mb-4">-->
<!--          <v-toolbar-title>Tree settings</v-toolbar-title>-->
<!--          <v-spacer />-->
<!--          <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>-->
<!--        </v-toolbar>-->
<!--        <v-card-text>-->
<!--          <settings-responsive-row-->
<!--              title="Students grouping criteria"-->
<!--              description="You can choose the criteria how you want to group the students in the tree-view on the left. By default, they are grouped-->
<!--           alphabetically. You can choose from: By teacher, By license, By price list, By office"-->
<!--          >-->
<!--          </settings-responsive-row>-->
<!--          <settings-responsive-row-->
<!--              :divider-after="false"-->
<!--              title="Selection mode"-->
<!--              description="Enable selection mode. This will allow you to select several students in the tree-view on-->
<!--              the left and perform bulk actions with them"-->
<!--          >-->
<!--          </settings-responsive-row>-->
<!--        </v-card-text>-->
<!--        <v-divider />-->
<!--        <v-card-actions>-->
<!--          <v-btn @click="close" text>-->
<!--            {{ $t('btn.close') }}-->
<!--          </v-btn>-->
<!--        </v-card-actions>-->
<!--      </v-card>-->
<!--    </v-dialog>-->
<!--  </div>-->
</template>

<style scoped>

</style>