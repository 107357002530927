<script>
import {defineComponent} from 'vue'
import {mapActions, mapGetters} from "vuex";

export default defineComponent({
  name: "UpdateNotificationDialog",
  data: () => ({
    timer: null,
    timeout: null,
  }),
  computed: {
    ...mapGetters('updateNotificationDialog', ['dialogState', 'secondsAsMinutesAndSeconds', 'secondsLeft', 'newVersion']),
    currentVersion() {
      return process.env.VUE_APP_WEB_VERSION
    }
  },
  methods: {
    ...mapActions('updateNotificationDialog', ['openDialog', 'closeDialog', 'startTimer', 'stopTimer']),
    postpone() {
      this.closeDialog()
      this.timeout = setTimeout(() => {
        this.openDialog()
      }, 1000 * 300)
    },
    reload() {
      location.reload()
    }
  },
  watch: {
    secondsLeft(value) {
      if (value <= 0) {
        this.reload()
      }
    }
  },
})
</script>

<template>
  <v-dialog v-model="dialogState" max-width="700" persistent overlay-color="orange">
    <v-card>
      <v-toolbar color="info" dark dense>
        <v-toolbar-title>{{ $t('label.update_is_ready') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <p class="text-h6 pt-5">{{ $t('label.new_version_was_released') }}</p>
        <p>{{ $t('label.refresh_the_browser_to_get_the_latest_version') }}</p>
        <p class="">
          <span class="red--text">{{ $t('label.current_version') }}: {{ currentVersion }}</span> <br>
          <span class="green--text">{{ $t('label.new_version') }}: {{ newVersion ?? '?' }}</span>
        </p>
        <p>
          {{ $t('label.no_action_will_reload_the_app_automatically') }}
        </p>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-chip label class="text-monospace" outlined>
          <v-icon left>mdi-timer-refresh-outline</v-icon>
          {{secondsAsMinutesAndSeconds}}
        </v-chip>
        <v-spacer></v-spacer>
        <v-btn color="warning" text @click="postpone"><v-icon left>mdi-clock-alert</v-icon>{{ $t('btn.postpone') }}</v-btn>
        <v-btn color="primary" @click="reload"><v-icon left>mdi-reload</v-icon>{{ $t('btn.reload_now') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>