<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between py-2">{{ $t("label.students") }}</v-card-title>

    <v-divider />

    <v-card-text class="d-flex justify-space-between">
      <WidgetLink
        :title="$t('label.active')"
        :amount="active"
        :to="{
          name: 'students',
          query: { filters: this.formatFilters({ activityStatus: 'active' }) },
        }"
        :loading="loading"
      />

      <WidgetLink
        :title="$t('label.no_balance')"
        :amount="noBalance"
        :to="{
          name: 'students',
          query: { filters: this.formatFilters({ activityStatus: 'active', noBalance: true }) },
        }"
        :loading="loading"
      />

      <WidgetLink
          :title="$t('label.no_appointments')"
          :amount="noAppointments"
          :to="{
            name: 'students',
            query: { filters: this.formatFilters({ activityStatus: 'active', withoutAppointments: true, educationStatus: 'learning' }) },
          }"
          :loading="loading"
      />

    </v-card-text>
  </v-card>
</template>

<script>
import teacherService from "@/services/teacherService";
import listFilters from '@/utils/mixins/listFilters'
import WidgetLink from '@/components/widgets/WidgetLink.vue'

export default {
  name: "TeacherStudentsCount",
  components: { WidgetLink },
  mixins: [listFilters],
  data() {
    return {
      loading: true,
      active: null,
      noBalance: null,
      noAppointments: null,
    };
  },
  mounted() {
    teacherService
      .studentsStats()
      .then((response) => {
        const studentsStats = response.data || {};

        this.active = studentsStats.activeCount;
        this.noBalance = studentsStats.noBalanceCount;
        this.noAppointments = studentsStats.withoutAppointments;
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped></style>
