<template>
  <div class="d-flex">
    <v-menu bottom offset-y z-index="10">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            small
            text
            :disabled="loading"
            class="my-auto pagination__info text-lowercase"
            :class="{'ml-auto': !$vuetify.breakpoint.xs}"
            v-bind="attrs"
            v-on="on">
          {{ fromNrOfItems + " - " + toNrOfItems + ` ${$t('label.of')} ` + totalNrOfItems }}
        </v-btn>
      </template>

      <v-list dense>
        <template v-for="(interval, index) in pagination">
          <v-list-item :key="index" v-if="index !== 0">
            <v-list-item-title>...</v-list-item-title>
          </v-list-item>

          <v-list-item
            :input-value="currentPage === page"
            v-for="page in interval"
            :key="interval + page"
            @click="goToPage(page)"
          >
            <v-list-item-title>{{ $t("label.page") + " " + page }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>

    <v-pagination
      class="elevation-0"
      v-if="totalNrOfPages > 1"
      :length="totalNrOfPages"
      :total-visible="0"
      :disabled="loading"
      v-model="routeQueryPage"
    />
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    totalVisible: {
      type: Number,
      default: 5,
    },
    from: {
      type: [String, Number],
      default: 0,
    },
    to: {
      type: [String, Number],
      default: 0,
    },
    currentPage: {
      type: [String, Number],
      default: 1,
    },
    totalNrOfPages: {
      type: [String, Number],
      default: 1,
    },
    totalNrOfItems: {
      type: [String, Number],
      default: 1,
    },
  },
  computed: {
    routeQueryPage: {
      get() {
        let page = parseInt(this.$route.query.page) || 1;
        if (page > this.totalNrOfPages) page = this.totalNrOfPages;

        return page <= 0 ? 1 : page;
      },
      set(newPage) {
        if (this.currentPage === newPage) return false;

        this.setPage(newPage, () => this.$emit("pageChange"));
      },
    },
    fromNrOfItems() {
      return this.from || 0;
    },
    toNrOfItems() {
      return this.to || 0;
    },
    pagination() {
      return this.paginationIntervals(this.routeQueryPage, this.totalNrOfPages);
    }
  },
  methods: {
    paginationIntervals(currentPage, totalNrOfPages) {
      if (currentPage <= 0)
        throw new Error("currentPage must be a number greater than 0.");
      if (totalNrOfPages <= 0)
        throw new Error("totalNrOfPages must be a number greater than 0.");

      let paginationNumbers = Array.from(
        { length: totalNrOfPages },
        (v, i) => i + 1
      );

      if (totalNrOfPages <= 15) return [paginationNumbers];

      const startInterval = paginationNumbers.slice(0, 3);
      const endInterval = paginationNumbers.slice(
        Math.max(paginationNumbers.length - 3, 0)
      );

      const currentPageInterval = [currentPage];
      if (currentPage > 1) currentPageInterval.push(currentPage - 1);
      if (currentPage < totalNrOfPages)
        currentPageInterval.push(currentPage + 1);

      paginationNumbers = [
        ...startInterval,
        ...currentPageInterval,
        ...endInterval,
      ]
        .sort((prev, next) => prev - next)
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

      if (currentPage > 1 && currentPage < 6) {
        return [
          paginationNumbers.slice(0, currentPage + 1),
          paginationNumbers.slice(currentPage + 1, paginationNumbers.length),
        ];
      }

      if (currentPage > totalNrOfPages - 6 && currentPage < totalNrOfPages) {
        return [
          paginationNumbers.slice(0, 3),
          paginationNumbers.slice(3, paginationNumbers.length),
        ];
      }

      const intervals = [];
      const chunk = 3;
      for (let i = 0, j = paginationNumbers.length; i < j; i += chunk) {
        intervals.push(paginationNumbers.slice(i, i + chunk));
      }

      return intervals;
    },
    goToPage(page) {
      this.routeQueryPage = page;
    },
    setPage(page, onComplete = () => {
    }) {
      this.$router.replace(
        {
          query: { ...this.$route.query, page: page }
        },
        onComplete
      );
    }
  },
};
</script>

<style scoped lang="scss">
.pagination {
  &__info {
    font-size: 15px;
    font-weight: 500;
  }

  &__wrapper {
    position: absolute;
    width: 100%;
    bottom: 0;
    background: #fff;
    border-top: 1px solid #eee;
  }
}
</style>
