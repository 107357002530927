<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th style="width: 150px" colspan="2">{{ $t("label.payment_id") }}</th>
          <th style="width: 80px">{{ $t("label.time") }}</th>
          <th>{{ $t("label.student") }}</th>
          <th style="width: 100px">{{ $t("label.payment_method") }}</th>
          <th style="width: 160px">{{ $t("label.gross_amount") }}</th>
          <th style="width: 120px">{{ $t("label.tax_amount") }}</th>
          <th style="width: 150px">{{ $t("label.net_amount") }}</th>
          <th style="width: 70px">{{ $t("label.view") }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, index) in groupedByDay">
          <tr :key="index" style="background: rgb(250, 250, 250)">
            <th colspan="5">{{ index | dateFormat("dd.MM.Y") }}</th>
            <th>{{ totalByGroup(index).gross | currency }}</th>
            <th>{{ totalByGroup(index).fee | currency }}</th>
            <th>{{ totalByGroup(index).net | currency }}</th>
            <th style="width: 50px">&nbsp;</th>
          </tr>
          <tr
            v-for="payment in groupedByDay[index]"
            :key="payment.id"
            class="table-row">
            <td style="width: 10px !important">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :color="payment.statusColor"
                    >{{ payment.statusIcon }}</v-icon
                  >
                </template>
                <span>
                  {{ payment.statusText }}
                  <span v-if="payment.refunded"
                    >({{ payment.refundedOn }})</span
                  >
                </span>
              </v-tooltip>
            </td>
            <td style="width: 10px !important">
              <v-chip
                label
                small
                class="text-monospace"
                link
                @click="copyToClipboard(payment.payload.id)">
                {{ payment.payload.id | ellipsis }}
              </v-chip>
            </td>
            <td>{{ payment.time }}</td>
            <td>
              <span :class="payment.studentId ? '' : 'font-italic'">{{
                payment.studentName
              }}</span>
              <v-chip
                v-if="payment.studentId"
                class="ml-2 hidden-until-row-hovered"
                link
                label
                x-small
                :to="{
                  name: 'students.student.finances',
                  params: { id: payment.studentId },
                }">
                <v-icon x-small>mdi-arrow-right</v-icon>
              </v-chip>
              <v-tooltip right v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2 hidden-until-row-hovered">
                    mdi-contactless-payment
                  </v-icon>
                </template>
                <span>Kein Fahrschüler</span>
              </v-tooltip>
            </td>
            <td>
              <div class="d-flex flex-row">
                <img
                    v-if="payment.paymentMethodIcon"
                    :src="payment.paymentMethodIcon"
                    style="width: 20px; height: 20px"
                    :alt="payment.method"
                />
                <img
                    v-if="payment.walletIcon"
                    :src="payment.walletIcon"
                    class="ml-1"
                    style="width: 20px; height: 20px"
                    :alt="payment.wallet?.type"
                />
              </div>
            </td>
            <td>{{ payment.payload.grossAmount | currency }}</td>
            <td>{{ payment.payload.applicationFee | currency }}</td>
            <td>{{ payment.payload.netAmount | currency }}</td>
            <td style="width: 50px">
              <v-chip
                link
                label
                x-small
                :to="{ name: 'payments.view', params: { id: payment.id } }">
                <v-icon x-small>mdi-arrow-right</v-icon>
              </v-chip>
            </td>
          </tr>
        </template>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="5">Total</th>
          <th>{{ total.gross | currency }}</th>
          <th>{{ total.fee | currency }}</th>
          <th>{{ total.net | currency }}</th>
          <th></th>
        </tr>
      </tfoot>
    </template>
  </v-simple-table>
</template>

<script>
import clipboard from "@/utils/mixins/clipboard";
import PaymentModel from "@/store/models/PaymentModel";

export default {
  name: "PaymentsTable",
  mixins: [clipboard],
  props: {
    payments: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
    },
    user: {
      type: [String, Number],
    },
    except: {
      type: [String, Number],
    },
    fresh: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.fresh) {
      PaymentModel.create({ data: this.payments });
    } else {
      PaymentModel.insert({ data: this.payments });
    }
  },
  computed: {
    total() {
      return {
        gross: this.data
          .map((item) => item.payload)
          .reduce((sum, current) => {
            return sum + current.grossAmount;
          }, 0),
        fee: this.data
          .map((item) => item.payload)
          .reduce((sum, current) => {
            return sum + current.applicationFee;
          }, 0),
        net: this.data
          .map((item) => item.payload)
          .reduce((sum, current) => {
            return sum + current.netAmount;
          }, 0),
      };
    },
    groupedByDay() {
      return this.data.reduce((groups, payment) => {
        if (!groups[payment.date]) {
          groups[payment.date] = [];
        }
        groups[payment.date].push(payment);
        return groups;
      }, {});
    },
    data() {
      const query = PaymentModel.query();

      query.orderBy("date", "desc").orderBy("time", "desc");
      if (this.limit) {
        query.limit(this.limit);
      }

      if (this.except) {
        query.where((payment) => {
          return payment.id !== this.except;
        });
      }
      return query.get();
    },
  },
  methods: {
    totalByGroup(groupId) {
      return {
        gross: this.groupedByDay[groupId]
          .map((item) => item.payload)
          .reduce((sum, current) => {
            return sum + current.grossAmount;
          }, 0),
        fee: this.groupedByDay[groupId]
          .map((item) => item.payload)
          .reduce((sum, current) => {
            return sum + current.applicationFee;
          }, 0),
        net: this.groupedByDay[groupId]
          .map((item) => item.payload)
          .reduce((sum, current) => {
            return sum + current.netAmount;
          }, 0),
      };
    },
  },
};
</script>

<style scoped lang="scss">
.hidden-until-row-hovered {
  display: none;
}
.table-row:hover > td > .hidden-until-row-hovered {
  display: inline-flex;
}
</style>
