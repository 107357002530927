<script>
import StudentCertification from '@/components/student/StudentCertification.vue'
import StudentApologyLetter from '@/components/student/StudentApologyLetter.vue'
import StudentContract from '@/components/student/StudentContract.vue'

import user from '@/utils/mixins/user'
import documentsService from '@/services/documentsService'
import FileSaver from '@/helpers/fileSaver'
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: 'StudentDocumentsDialog',
  mixins: [user],
  components: { StudentContract, StudentApologyLetter, StudentCertification },
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    loading: false,
    downloading: null,
    deleting: null,
    type: null,
    documentType: null,
    documentIcon: 'mdi-file-document-outline',
    license: null,
    items: []
  }),
  computed: {
    title() {
      const data = {}
      if (this.license) data.license = this.license.name
      return this.$tc('student_documents_types.' + this.type, 2, data)
    },
    orderedItems() {
      return [...this.items].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    }
  },
  methods: {
    open(type, license) {
      this.dialog = true
      this.type = type
      this.license = license

      const documentType = {
        'education_contract': {
          icon: 'mdi-file-account-outline',
          type: 'education-contract',
        },
        'apology_letter': {
          icon: 'mdi-file-document-outline',
          type: 'apology-letter'
        },
        'certificate_license': {
          icon: 'mdi-book-education-outline',
          type: 'student-certificate-' + this.license?.name
        }
      }

      if (!documentType[this.type]) {
        console.error('There is no document type for ' + this.type)
        return
      }

      this.documentType = documentType[this.type].type
      this.documentIcon = documentType[this.type].icon

      this.fetch()
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        this.loading = false
        this.type = null
        this.documentType = null
        this.license = null
      }, 200)
    },
    fetch() {
      this.loading = true
      documentsService.list(this.student.id, { type: this.documentType })
        .then(response => {
          this.items = response.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    newItem() {
      const documentTypeRef = {
        education_contract: this.$refs.studentContract,
        apology_letter: this.$refs.studentApologyLetter,
        certificate_license: this.$refs.studentCertification,
      }

      if (!documentTypeRef[this.type]) {
        console.error('There is no component for document type ', this.type)
      }

      documentTypeRef[this.type].open(this.license?.id)
    },
    download(item) {
      this.downloading = item.id
      documentsService.download(this.student.id, item.id)
        .then(response => {
          const responseData = [response.data] || [];
          const fileSaver = new FileSaver(responseData);
          fileSaver
            .setType("application/pdf")
            .saveToDevice(item.downloadFileName, "Unterlagenmappe");
        })
        .finally(() => {
          this.downloading = null;
        })
    },
    deleteItem(item) {
      showConfirm(
          this.$t('btn.confirm'),
          this.$t("messages.are_your_sure_delete"),
          () => {
            this.deleting = item.id
            documentsService
                .destroy(this.student.id, item.id)
                .then(() => {
                  const index = this.items.findIndex(i => i.id === item.id)
                  if (index > -1) this.items.splice(index, 1)
                })
                .finally(() => {
                  this.deleting = null
                });
          }
      );
    },
    addToDocuments(doc) {
      const existingDoc = this.items.find(i => i.id === doc.id)
      if (existingDoc) return

      this.items.unshift(doc)
    }
  }
}
</script>

<template>
  <v-dialog v-model="dialog" width="900" @click:outside="close" @keydown.esc="close" scrollable>
    <v-card>
      <v-toolbar class="mb-2 flex-grow-0" dark color="primary" dense>
        <v-toolbar-title>{{ $t("label.documents") }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-title>
        {{ title }}
        <v-spacer/>
        <v-btn class="primary" text @click="newItem">{{ $t('btn.add') }}</v-btn>
      </v-card-title>

      <v-card-text class="pt-3">
        <v-list three-line v-if="loading">
          <v-list-item v-for="n in 2" :key="n">
            <v-list-item-icon class="mx-0">
              <v-skeleton-loader class="mr-4" type="button" width="40px" />
            </v-list-item-icon>

            <v-list-item-content>
              <div>
                <v-skeleton-loader type="text" width="350" />
                <v-skeleton-loader type="text" width="350" />
                <v-skeleton-loader type="text" width="180" />
              </div>
            </v-list-item-content>

            <v-list-item-action class="d-flex flex-row align-center">
              <v-skeleton-loader type="button" height="30px" />
              <v-skeleton-loader class="ml-4 mr-1" type="button" height="30px" width="30px" />
              <v-skeleton-loader type="button" height="30px" width="30px" />
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <div v-else>
          <v-list v-if="orderedItems.length > 0" three-line>
            <v-list-item v-for="item in orderedItems" :key="item.id">
              <v-list-item-icon class="mr-4">
                <v-icon large>{{ documentIcon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.fileName"/>

                <v-list-item-subtitle>
                  <span>{{ $t('label.created_by') }} {{ item.createdBy }}</span>
                  <span class="ml-2" v-if="item.isSignedBySchool">{{ $t('label.signed_by') }} {{ item.schoolSignatureName }}</span>
                </v-list-item-subtitle>

                <v-list-item-subtitle>{{ item.createdAt | dateFormat('dd.MM.yyyy HH:mm') }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action class="d-flex flex-row align-center">
                <v-chip :color="item.isSignedByStudent ? 'success' : 'warning'" label small v-if="item.isSignedByStudent || item.requiresStudentSignature">
                  {{ $t(item.isSignedByStudent ? 'label.signed' : 'label.unsigned') }}
                </v-chip>
                <v-btn icon @click="download(item)" class="ml-4 mr-1" :disabled="downloading === item.id" :loading="downloading === item.id">
                  <v-icon>mdi-download-outline</v-icon>
                </v-btn>
                <v-btn
                    icon @click="deleteItem(item)"
                    :disabled="downloading === item.id || deleting === item.id"
                    :loading="deleting === item.id"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <empty-box v-else />
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn text @click="close">{{$t('btn.close')}}</v-btn>
      </v-card-actions>
    </v-card>

    <StudentContract :student="student" v-if="isSchoolAdministrator" ref="studentContract" @created="addToDocuments" />
    <StudentApologyLetter :student="student" ref="studentApologyLetter" @created="addToDocuments" />
    <StudentCertification :student="student" ref="studentCertification" v-if="isSchoolAdministrator" @created="addToDocuments" />
  </v-dialog>
</template>

<style scoped>

</style>
