<script>
import invoicesService from "@/services/invoicesService";
import FileSaver from "@/helpers/fileSaver";
import user from "@/utils/mixins/user";

export default {
  name: "InvoiceListItem",
  props: {
    invoice: {
      type: Object,
      required: true,
    }
  },
  mixins: [user],
  data() {
    return {
      downloading: false,
    }
  },
  methods: {
    handleClick() {
      if (this.isStudent) {
        this.download();
      } else {
        this.$router.push({ name: "invoices.view", params: { id: this.invoice.id } });
      }
    },
    download() {
      if (this.invoice.isCanceled) {
        this.$snackbar.show({
          message: this.$t('label.this_invoice_was_canceled'),
          color: 'warning',
          timeout: 3000,
        })
        return;
      }
      this.downloading = true;
      invoicesService
          .downloadInvoice(this.invoice.id)
          .then((resp) => {
            const responseData = [resp.data] || [];
            const file = new FileSaver(responseData)
            file.setType('application/pdf').saveToDevice(this.invoice.fileName, 'Rechnungen');
          })
          .finally(() => {
            this.downloading = false;
          });
    },
  }
}
</script>

<template>
  <v-list-item
    :disabled="downloading"
    :style="{opacity: isStudent && invoice.isCanceled ? 0.3 : 1}"
    link
    @click="handleClick"
  >
    <v-list-item-avatar>
      <v-icon v-if="invoice.isCanceled" color="grey'">
        mdi-file-cancel
      </v-icon>
      <v-progress-circular v-else-if="downloading" size="24" class="ml-2" indeterminate color="primary" />
      <v-icon v-else :color="invoice.isPaid ? 'success' : 'warning'">
        {{ invoice.isPaid ? 'mdi-file-check' : 'mdi-file-clock' }}
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>{{ invoice.number }}</v-list-item-title>
      <v-list-item-subtitle>{{ invoice.date | dateFormat }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-chip label small :color="invoice.isCanceled ? 'grey' : (invoice.isPaid ? 'success' : 'warning')" dark>
        {{ invoice.amount | currency }}
      </v-chip>
    </v-list-item-action>
  </v-list-item>
</template>

<style scoped>

</style>
