<template>
  <v-bottom-sheet v-model="show" :inset="!$vuetify.breakpoint.mobile" persistent scrollable max-height="300px">
    <v-card tile>
      <v-card-title class="d-flex" :class="{'justify-center subtitle-1 ': $vuetify.breakpoint.mobile}">
        <v-icon class="mr-3" :class="{'mb-2': $vuetify.breakpoint.mobile}" color="warning" large>mdi-alert-outline</v-icon>
        {{ message }}
        <v-spacer/>
        <v-btn icon large @click="close" v-if="! $vuetify.breakpoint.mobile">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="text--secondary">
        <v-list class="py-0" v-if="errors.length > 0" dense>
          <v-list-item dense v-for="(error, index) in errors" :key="index">
            <v-list-item-content>{{ error }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <template v-if="$vuetify.breakpoint.mobile">
        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="close">{{ $t('btn.close') }}</v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: "ErrorBottomSheet",
  computed: {
    message() {
      return this.$store.state.main.errorBottomSheet?.message || ''
    },
    errors() {
      return Object
          .values(this.$store.state.main.errorBottomSheet?.errors || {})
          .flat()
          .filter(value => this.message !== value)
    },
    show() {
      return this.$store.state.main.errorBottomSheet.status === 'show';
    }
  },
  methods: {
    close() {
      this.$store.commit(
          'main/toggle-error-bottom-sheet',
          { status: null, message: '', errors: {} }
      );
    }
  }
};
</script>

<style scoped>
.scrollable {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}
</style>
