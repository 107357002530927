<template>
  <v-autocomplete
    v-model="vehicleId"
    :disabled="disabled"
    :label="label"
    :items="vehiclesList"
    item-value="id"
    :item-text="vehicleName"
    outlined
    hide-details
    dense
    clearable
    :append-icon="!disabled ? 'mdi-menu-down' : ''"
  >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title :class="{ 'grey--text': data.item.isDeleted }">
          {{ vehicleName(data.item) }}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-icon v-if="data.item.isDeleted">
        <v-icon small color="grey">mdi-delete</v-icon>
      </v-list-item-icon>
    </template>
  </v-autocomplete>
</template>

<script>

export default {
  name: "VehicleField",
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      required: true,
    },
    validation: {
      type: Boolean,
      default: false,
    },
    vehiclesList: {
      type: Array,
      required: true,
    },
    vehicleProp: {
      type: Number,
    },
  },
  computed: {
    vehicleId: {
      set(value) {
        this.$emit("emitVehicleChange", value);
      },
      get() {
        return this.vehicleProp;
      },
    },
  },
  methods: {
    vehicleName(item) {
      return `${item.make} ${item.model} (${item.licensePlate})`;
    },
  },
};
</script>
