<template>
  <v-tooltip
    bottom
    max-width="500"
    :open-on-hover="showTooltip && !!period.notes"
    :open-on-click="showTooltip && !!period.notes"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="service" :style="`${position}`" v-bind="attrs" v-on="on">{{ timeLabel }}</div>
    </template>
    <span v-if="period.notes">{{ serviceNotes }}</span>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex'
import { parseISO, differenceInMinutes, startOfDay, format, } from 'date-fns'
import TeacherModel from '@/store/models/TeacherModel'

export default {
  name: 'ServicePeriod',
  props: {
    period: {
      type: Object,
      required: true
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('vehiclesCalendar', ['oneMinuteSize']),
    teacher () {
      return TeacherModel.find(this.period.teacherId)
    },
    parsedStart () {
      return parseISO(this.period.from)
    },
    parsedEnd () {
      return parseISO(this.period.to)
    },
    duration () {
      return differenceInMinutes(this.parsedEnd, this.parsedStart)
    },
    positionLeft () {
      return differenceInMinutes(this.parsedStart, startOfDay(this.parsedStart))
    },
    opacity() {
      return `opacity: ${this.period.id === this.tempReservationPeriod.id ? 0 : 1};`
    },
    position () {
      return `width: ${this.duration * this.oneMinuteSize}px; left: ${this.positionLeft * this.oneMinuteSize}px;`
    },
    timeLabel () {
      const startDate = format(parseISO(this.period.labelFrom), 'dd.MM.yyyy')
      const endDate = format(parseISO(this.period.labelTo), 'dd.MM.yyyy')
      if (startDate === endDate) {
        const startTime = format(parseISO(this.period.from), 'HH:mm')
        const endTime = format(parseISO(this.period.to), 'HH:mm')
        return `${startTime} - ${endTime}`
      } else {
        return format(parseISO(this.period.labelFrom), 'dd.MM.yyyy HH:mm') + ' - ' + format(parseISO(this.period.labelTo), 'dd.MM.yyyy HH:mm')
      }
    },
    serviceNotes () {
      return this.period.notes.length > 200 ? this.period.notes.substring(0, 200) + '...' : this.period.notes
    }
  },
}
</script>

<style lang="scss" scoped>
.service {
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  font-size: 10px;
  padding: 5px;
  line-height: normal;
  color: var(--v-primary-base);
  //background: rgba(127, 127, 127, 0.2);
  border-left: 1px solid rgba(127, 127, 127, 0.3);
  border-right: 1px solid rgba(127, 127, 127, 0.3);
  background: url("../../assets/img/dots.png") !important;
  //cursor: not-allowed;
  pointer-events: auto;
}
</style>
