<script>

export default ({
  name: "SidebarItemLoader",
  props: {
    count: {
      type: Number,
      default: 2,
    },
  },
})
</script>
<template>
  <div>
    <div
      v-for="item in count"
      :key="item"
      class="border d-flex align-center justify-center"
    >
      <v-skeleton-loader type="avatar" height="50" width="50" />
    </div>
  </div>
</template>
<style scoped lang="scss">
.border {
  width: 100%;
  height: 64px;
  cursor: pointer;
  transition: .5s;
  position: relative;
  border-bottom: 1px solid #ccc;
}
</style>
