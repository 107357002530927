var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticStyle:{"padding-top":"0!important"},attrs:{"max-width":"700px","value":_vm.createEventModalGetter,"scrollable":_vm.isMobile || _vm.windowHeight < 800,"fullscreen":_vm.isMobile,"hide-overlay":_vm.isMobile},on:{"click:outside":_vm.finishEventCreation,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.finishEventCreation.apply(null, arguments)}}},[_c('v-card',[_c('TopBar',{staticClass:"mb-5 flex-shrink-0 flex-grow-0",attrs:{"eventDuration":_vm.eventDuration,"color":_vm.eventColor,"eventType":_vm.eventModel.type}}),_c('v-overlay',{attrs:{"value":_vm.loader,"absolute":true,"opacity":"0.1","z-index":"9"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1),_c('ModalOverlay',{attrs:{"event-model":_vm.eventModel},on:{"close":_vm.finishEventCreation,"update":_vm.updateEventModel}}),_c('v-card-text',[_c('ValidationObserver',{ref:"observer"},[_c(_vm.eventModel.type,{tag:"component",attrs:{"model":_vm.eventModel,"eventDuration":_vm.eventDuration,"teachers":_vm.teachers}}),(
            _vm.eventModel.eventType === 'THEORY' &&
            !_vm.eventModel.isTheoryFull &&
            _vm.isBalanceNegative
          )?_c('v-row',[_c('v-col',[_c('v-alert',{staticClass:"mt-5 mb-0",attrs:{"type":"error","dense":""}},[_vm._v(" "+_vm._s(_vm.$t("messages.has_negative_balance", { amount: parseFloat(_vm.currentUser.balance), }))+" ")])],1)],1):_vm._e(),(_vm.eventErrorAlertGetter.status)?_c('v-row',[_c('v-col',[_c('v-alert',{staticClass:"mt-5 mb-0",attrs:{"type":"error","dense":""}},[_vm._v(_vm._s(_vm.eventErrorAlertGetter.message))])],1)],1):_vm._e(),(_vm.teacherIsNotAvailableError)?_c('v-row',[_c('v-col',[_c('v-alert',{staticClass:"mt-5 mb-0",attrs:{"type":"error","dense":""}},[_vm._v(_vm._s(_vm.teacherIsNotAvailable.message))])],1)],1):_vm._e()],1)],1),(_vm.isMobile)?_c('v-divider'):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('ModalActionButton',{attrs:{"label":_vm.$t('btn.close'),"color":"black","disabled":false},on:{"buttonClick":_vm.eventCancel}}),(!_vm.isPast)?[(!_vm.eventModel.id)?_c('ModalActionButton',{attrs:{"label":_vm.$t('btn.ok'),"color":"green","disabled":!_vm.eventModel.teacher},on:{"buttonClick":_vm.eventSave}}):_vm._e(),(_vm.eventModel.id && _vm.eventModel.eventType === 'PRACTICE')?[(
              _vm.eventModel.status === 'ACCEPTED' && _vm.studentCanDeleteThisEvent
            )?_c('ModalActionButton',{attrs:{"label":_vm.$t('btn.delete'),"color":"red","disabled":false},on:{"buttonClick":function($event){return _vm.eventDelete(_vm.eventModel)}}}):_vm._e(),(_vm.eventModel.status === 'REQUESTED')?_c('ModalActionButton',{attrs:{"label":_vm.$t('btn.decline'),"color":"red","disabled":false},on:{"buttonClick":function($event){return _vm.eventDelete(_vm.eventModel)}}}):_vm._e(),(
              _vm.eventModel.status === 'ACCEPTED' && _vm.studentCanDeleteThisEvent
            )?_c('ModalActionButton',{attrs:{"label":_vm.$t('btn.ok'),"color":"green","disabled":!_vm.eventModel.teacher},on:{"buttonClick":_vm.eventSave}}):_vm._e()]:_vm._e(),(_vm.isStudent && _vm.eventModel.eventType === 'PLACEHOLDER')?[(_vm.subscribePlaceholderButtons)?_c('ModalActionButton',{attrs:{"label":_vm.$t('btn.book_placeholder'),"color":"green","disabled":false},on:{"buttonClick":function($event){return _vm.placeholderSubscribe(_vm.eventModel)}}}):_c('ModalActionButton',{attrs:{"label":_vm.$t('btn.abandon_placeholder'),"color":"red","disabled":false},on:{"buttonClick":function($event){return _vm.placeholderDecline(_vm.eventModel)}}})]:_vm._e(),(
            _vm.eventModel.eventType === 'THEORY' && _vm.theoryGroup === 'lesson'
          )?[(_vm.subscribeButtons)?_c('ModalActionButton',{attrs:{"color":"green","label":_vm.eventModel.isTheoryFull
                ? _vm.$t('messages.max_num_participants_reached')
                : _vm.$t('btn.attend_theory_lesson'),"disabled":_vm.eventModel.isTheoryFull || _vm.isBalanceNegative},on:{"buttonClick":function($event){return _vm.subscribeTheory(_vm.eventModel)}}}):_vm._e(),(!_vm.subscribeButtons && _vm.studentCanDeleteThisEvent)?_c('ModalActionButton',{attrs:{"label":_vm.$t('btn.abandon_theory_lesson'),"color":"red","disabled":false},on:{"buttonClick":function($event){return _vm.unsubscribeTheory(_vm.eventModel)}}}):_vm._e()]:_vm._e(),(_vm.eventModel.eventType === 'SIMULATOR')?[(_vm.subscribeButtons)?_c('ModalActionButton',{attrs:{"label":_vm.$t('btn.book_simulator'),"color":"green","disabled":false},on:{"buttonClick":function($event){return _vm.subscribeSimulator(_vm.eventModel)}}}):_vm._e(),(!_vm.subscribeButtons && _vm.studentCanDeleteThisEvent)?_c('ModalActionButton',{attrs:{"label":_vm.$t('btn.cancel_simulator'),"color":"red","disabled":false},on:{"buttonClick":function($event){return _vm.unsubscribeSimulator(_vm.eventModel)}}}):_vm._e()]:_vm._e()]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }