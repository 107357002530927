<template>
  <v-card class="pa-3 my-2">
    <v-radio :value="status.id" @change="$emit('changeStatus', status.id)">
      <template v-slot:label>
        <div
          :class="mdLandscapeAndUp ? 'grid' : 'mobile-card'"
          style="width: 100%">
          <div class="lbl">
            <h1 :style="`color: ${color}`">{{ status.name }}</h1>
          </div>
          <CovidStatusLabel
            class="area-1"
            name="Genesen"
            :status="status.recovered" />
          <CovidStatusLabel
            class="area-2"
            name="Geimpft"
            :status="status.vaccinated" />
          <CovidStatusLabel
            class="area-3"
            name="Ungeimpft"
            :status="status.unvaccinated" />
        </div>
      </template>
    </v-radio>
  </v-card>
</template>

<script>
import CovidStatusLabel from "@/components/covid2019/CovidStatusLabel";
import responsive from "@/utils/mixins/responsive";

export default {
  name: "CovidStatus",
  components: { CovidStatusLabel },
  mixins: [responsive],
  props: {
    status: {
      type: Object,
      required: true,
    },
  },
  computed: {
    color() {
      const arr = [
        this.status.recovered,
        this.status.vaccinated,
        this.status.unvaccinated,
      ];
      let color = "green";
      const yellow = arr.some((item) => !item);
      const red = arr.every((item) => !item);
      if (yellow) color = "#ffc107 ";
      if (red) color = "red";
      return color;
    },
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 0.3fr 1fr 1fr 1fr;
  align-content: center;
  align-items: center;
}
.mobile-card {
  display: grid;
  align-items: center;
  grid-template-areas:
    "label area-1"
    "label area-2"
    "label area-3";
  .lbl {
    grid-area: label;
  }
  .area-1 {
    grid-area: area-1;
  }
  .area-2 {
    grid-area: area-2;
  }
  .area-3 {
    grid-area: area-3;
  }
}
</style>
