<template>
  <div :style="`width: ${mdLandscapeAndUp ? 200 : 50}px`">
    <span class="mr-2" style="font-size: 0.8rem" v-if="mdLandscapeAndUp"
      >Prüfungsstatus:
    </span>
    <v-icon
      v-if="
        examsProgress.theoryExamPassedAt || examsProgress.practiceExamPassedAt
      "
      color="secondary">
      {{
        examsProgress.theoryExamPassedAt && examsProgress.practiceExamPassedAt
          ? "mdi-check-all"
          : "mdi-check"
      }}
    </v-icon>
  </div>
</template>

<script>
import LicenseStudentModel from "@/store/models/LicenseStudentModel";
import responsive from "@/utils/mixins/responsive";

export default {
  name: "TotalProgress",
  mixins: [responsive],
  props: {
    licenseId: {
      type: [Number, String],
      required: true,
    },
    studentId: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    examsProgress() {
      return (
        LicenseStudentModel.query()
          .where("studentId", parseInt(this.studentId))
          .where("licenseId", this.licenseId)
          .first() || []
      );
    },
  },
};
</script>

<style scoped></style>
