<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'personal-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>
        {{ $t("route.personalSettings.children.changePassword") }}
      </v-card-title>

      <v-spacer/>

      <v-btn
          text
          color="primary"
          @click="save"
          :loading="formLoading"
          :disabled="formLoading">
        {{ $t("btn.save") }}
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <ValidationObserver ref="form">
        <div>
          <v-card v-if="currentUser.username" elevation="3" dark color="blue-grey darken-1">
            <v-card-title>
              {{ $t("label.username") }}
            </v-card-title>
            <v-card-subtitle>
              {{ currentUser.username }}
            </v-card-subtitle>
          </v-card>

          <v-alert class="mt-4" color="warning" dark v-if="currentUser.isOneTimePassword">
            <div class="font-weight-bold">
              {{ $t("label.password_change_required") }}
            </div>
            <div>
              {{ $t("label.password_change_required_text") }}
            </div>
          </v-alert>

          <v-form class="mt-4">
            <v-row dense>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-if="!currentUser.isOneTimePassword"
                  rules="required|min:8"
                  v-slot="{ errors }"
                  name="oldPassword">
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="form.oldPassword"
                    :label="$t('form.current_password')"
                    :placeholder="$t('form.current_password')"
                    required
                    :error-messages="errors"
                    :type="!showOldPassword ? 'password' : 'text'"
                    :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showOldPassword = !showOldPassword"
                  />
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" name="password" rules="required|min:8">
                  <v-text-field
                    name="new-password"
                    autocomplete="new-password"
                    outlined
                    dense
                    v-model="form.password"
                    :label="$t('form.new_password')"
                    :placeholder="$t('form.new_password')"
                    required
                    :error-messages="errors"
                    :type="!showPassword ? 'password' : 'text'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                  />
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" name="passwordConfirmation">
                  <v-text-field
                    name="confirm-password"
                    autocomplete="new-password"
                    outlined
                    dense
                    v-model="form.passwordConfirmation"
                    :label="$t('form.password_confirmation')"
                    :placeholder="$t('form.password_confirmation')"
                    required
                    :error-messages="errors"
                    :type="!showPasswordConfirmation ? 'password' : 'text'"
                    :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPasswordConfirmation = !showPasswordConfirmation"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </ValidationObserver>
    </v-card-text>
  </div>
</template>

<script>
import { mapState } from "vuex";
import userService from "@/services/userService";
import i18n from "@/plugins/i18n";
import user from "@/utils/mixins/user";
import responsive from '@/utils/mixins/responsive'

export default {
  name: "Auth",
  mixins: [user, responsive],
  data: () => ({
    formLoading: false,
    showOldPassword: false,
    showPassword: false,
    showPasswordConfirmation: false,
    form: {
      username: "",
      oldPassword: "",
      password: "",
      passwordConfirmation: "",
      _method: "PUT",
    },
    initialForm: {},
    warningSnackBar: null,
  }),
  computed: {
    ...mapState("auth", { currentUser: "authUser" }),
  },
  created() {
    this.initialForm = { ...this.form };
    this.form.username = this.currentUser.username;
  },
  beforeRouteLeave(to, from, next) {
    if (this.currentUser?.isOneTimePassword) {
      if (this.warningSnackBar) {
        this.$snackbar.delete(this.warningSnackBar.id);
      }

      this.$snackbar
        .show({
          icon: "mdi-exclamation",
          message: i18n.t("messages.please_set_up_password"),
          color: "warning",
          timeout: 5000,
        })
        .then((warningSnackBar) => (this.warningSnackBar = warningSnackBar));
    } else next();
  },
  methods: {
    async save() {
      const validate = await this.$refs.form.validate()
      if (!validate) return

      this.formLoading = true;
      userService
        .resetPassword(this.form)
        .then(() => {
          const onTimePasswordChanged = this.currentUser.isOneTimePassword;
          this.$store.dispatch("auth/getAuthUser").then(() => {
            this.$refs.form.reset();
            this.form = { ...this.initialForm };
            this.$snackbar.show({
              message: this.$t("messages.successfully_updated"),
              color: "success",
              timeout: 2000,
            });
            if (onTimePasswordChanged) this.$router.push({ name: "dashboard" });
          });
        })
        .catch((error) => {
          this.$refs.form.reset();
          if (!error.response) console.log(error);

          const responseData = error.response.data;
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message)
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });

          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
