import { getSunrise, getSunset } from "sunrise-sunset-js";
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    coordinates() {
      return this.schoolSettings;
    },
    sunriseTime() {
      if (!this.coordinates) return new Date();
      return getSunrise(
        this.coordinates.lat,
        this.coordinates.long,
        new Date(this.day.originalDate)
      );
    },
    sunsetTime() {
      if (!this.coordinates) return new Date();
      return getSunset(
        this.coordinates.lat,
        this.coordinates.long,
        new Date(this.day.originalDate)
      );
    },
  },
};
