<template>
  <v-alert dense outlined type="error" class="mt-5">{{ text }}</v-alert>
</template>

<script>
export default {
  name: "HappyHourWarning",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
