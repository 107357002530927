<template>
  <div :class="`wrapper ${isClickable ? 'clickable' : ''}`" @click="getPdf">
    <div class="d-flex align-center">
      <v-icon class="mr-3">mdi-car-outline</v-icon>
      <strong :style="`min-width: 30px; color: ${drivingColor}`">
        <span v-if="!loading">{{ drivingWorkedMinutes }}</span>
        <v-skeleton-loader v-else type="image" width="30" height="10"/>
      </strong>
    </div>
    <div class="d-flex align-center">
      <v-icon class="mr-3">mdi-progress-clock</v-icon>
      <strong :style="`min-width: 30px; color: ${totalColor}`">
        <span v-if="!loading">{{ totalWorkedMinutes }}</span>
        <v-skeleton-loader v-else type="image" width="30" height="10"/>
      </strong>
    </div>

    <v-btn
        v-if="drivingWorkedMinutes || totalWorkedMinutes"
        class="download-icon"
        color="primary"
        icon
        x-small
        :loading="downloading"
    >
      <v-icon>mdi-download-outline</v-icon>
    </v-btn>
  </div>
</template>

<script>

export default {
  name: "WorkTImeSumSlot",
  data: () => ({
    downloading: false
  }),
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    drivingWorkedMinutes: {
      type: Number,
      default: 0
    },
    totalWorkedMinutes: {
      type: Number,
      default: 0
    },
  },
  computed: {
    drivingColor() {
      return this.drivingWorkedMinutes === 0
          ? '#858585'
          : this.drivingWorkedMinutes > 2970 ? 'red' : 'green'
    },
    totalColor() {
      return this.totalWorkedMinutes === 0
          ? '#858585'
          : this.totalWorkedMinutes > 3600 ? 'red' : 'green'
    },
    isClickable() {
      return this.drivingWorkedMinutes > 0 || this.totalWorkedMinutes > 0
    }
  },
  methods: {
    stop() {
      this.downloading = false
    },
    getPdf() {
      if(!this.isClickable || this.disabled) return
      this.downloading = true
      this.$emit('weeklyPdf')
    }
  }
};
</script>

<style scoped>
.wrapper{
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  left: 0;
}
.clickable{
  cursor: pointer;
}
.download-icon{
  position: absolute;
  bottom: 2px;
  right: 2px;
}
.progress-line{
  position: absolute;
  top: 0;
}
</style>
