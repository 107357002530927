<template>
  <div>
    <v-row v-if="!isTeacher">
      <v-col>
        <TeachersField
          @emitTeachersSelect="updateTeacherField"
          @emitRecalculation="recalculateAmount"
          :disabled="currentAppointment.isRequested"
          :validation="true"
          :filteredTeachers="teachers"
          :teacherProp="currentAppointment.teacherId"
        />
      </v-col>
    </v-row>

    <v-row v-if="studentsRequests.length">
      <v-col>
        <PlaceholderRequestsField
            @emitPickStudent="pickRequest"
            :disabled="loadingStudentProgress"
            :studentsList="studentsRequests"
        />
      </v-col>
    </v-row>

    <template>

    </template>
    <v-row v-if="!currentAppointment.isRequested">
      <v-col cols="6">
        <LicenseField
          @emitLicenseSelect="(val) => updateFields({ allowedLicenses: val })"
          @emitLicenseClear="() => updateFields({ allowedLicenses: [], notificationLicenses: [] })"
          :disabled="teacherIsNotSelected"
          :validation="false"
          :multiple="true"
          :licenseProp="currentAppointment.allowedLicenses"
          :licenseIdsList="allowedLicensesList"
          :label="$t('form.licence')"
        />
      </v-col>
      <v-col cols="6">
        <PlaceholderNotificationField
            @emitNotificationLicenseSelect="(val) => updateFields({ notificationLicenses: val })"
            :disabled="teacherIsNotSelected"
            :notificationLicenseProp="notificationLicensesArray"
            :licenseList="groupedNotificationLicenses"
        />
      </v-col>
    </v-row>

    <v-row v-if="licenseProgressData && !loadingStudentProgress" dense>
      <v-col>
        <v-sheet outlined rounded class="pa-2" style="border: 1px solid #9c9c9c">
          <SegmentedProgressBar
              :required-segments="shouldValue"
              :segment-length="progressSpecialDuration"
              :total-value="totalValue"
              :label="$tc(currentLessonType.name, 2)"
              :duration="currentAppointment.duration"
              color="secondary"
              dark
              class="mb-1"
          />
        </v-sheet>
      </v-col>
    </v-row>

    <template v-if="currentAppointment.isRequested">
      <v-row>
        <v-col cols="6">
          <LicenseField
              ref="licenseField"
              @studentProgress = getStudentProgress
              @emitLicenseSelect="updateLicenseField"
              @emitProgressLoader="updateProgress"
              :disabled="!placeholderRequestedStudent || studentHasNoPriceList"
              :validation="true"
              :multiple="false"
              :showLicenseProgress="true"
              :licenseProp="currentAppointment.licenseId"
              :licenseIdsList="activeLicensesIds"
              :licenseStatus="practiceLicensesStatus"
          />
        </v-col>
        <v-col cols="6">
          <LessonTypesField
              @emitLessonTypeSelect="updateLessonTypeField"
              :disabled="!placeholderRequestedStudent || studentHasNoPriceList"
              :lessonTypeProp="currentAppointment.lessonTypeId"
              :lessonTypesList="lessonTypesList"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
          <VehicleFieldDynamic
              @emitVehicleChange="(val) => updateFields({ primaryVehicleId: val })"
              @emitRequestVehicles="requestPrimaryVehicles"
              :disabled="!placeholderRequestedStudent || studentHasNoPriceList"
              :label="$t('form.primary_vehicle')"
              :loading="primaryVehiclesLoader"
              :showNoData="!primaryVehiclesIds"
              :vehiclesList="primaryVehicles"
              :vehiclesData="primaryVehiclesData"
              :vehicleProp="currentAppointment.primaryVehicleId"
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
          <VehicleFieldDynamic
              @emitVehicleChange="(val) => updateFields({ secondaryVehicleId: val })"
              @emitRequestVehicles="requestSecondaryVehicles"
              :disabled="!placeholderRequestedStudent || !currentAppointment.primaryVehicleId"
              :label="$t('form.secondary_vehicle')"
              :loading="secondaryVehiclesLoader"
              :showNoData="!secondaryVehiclesIds"
              :vehiclesList="secondaryVehicles"
              :vehiclesData="secondaryVehiclesData"
              :vehicleProp="currentAppointment.secondaryVehicleId"
          />
        </v-col>
      </v-row>
    </template>

    <v-row>
      <v-col>
        <MeetingPlaceField
            @emitMeetingPlaceSelect="(val) => updateFields({ meetingPlace: val })"
            :disabled="teacherIsNotSelected || studentHasNoPriceList"
            :validation="false"
            :meetingPlacesList="placesGroups"
            :meetingPlaceProp="currentAppointment.meetingPlace"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <NotesField
            @emitNotesChange="(val) => updateFields({ notes: val })"
            :disabled="teacherIsNotSelected || studentHasNoPriceList"
            :validation="false"
            :notesProp="currentAppointment.notes"
        />
      </v-col>
    </v-row>

    <CopyAppointment
        v-if="!currentAppointment.isRequested"
        @emitSetCopiedAmount="(val) => updateFields({ copyForNrOfWeeks: val })"
        :disabled="teacherIsNotSelected || currentAppointment.isRequested"
        :weeksAmount="25"
        :copyNrProp="currentAppointment.copyForNrOfWeeks"
    />

    <v-row>
      <v-col>
        <MultiplePlaceholderSwitch
            @emitSwitchStatus="(val) => updateFields({ hasMultipleRequestsAllowed: val })"
            :label="$t('form.multiple')"
            :disabled="teacherIsNotSelected || currentAppointment.isRequested"
            :statusProp="currentAppointment.hasMultipleRequestsAllowed"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import user from "@/utils/mixins/user"
import placeholderPracticeMixin from '@/utils/mixins/placeholderPracticeMixin'
import priceCategoryMixin from '@/utils/mixins/priceCategoryMixin'

import SegmentedProgressBar from "@/components/SegmentedProgressBar"
import TeachersField from "@/components/calendar/appointmentModal/fields/TeachersField"
import PlaceholderRequestsField from "@/components/calendar/appointmentModal/fields/PlaceholderRequestsField"
import LicenseField from "@/components/calendar/appointmentModal/fields/LicenseField"
import LessonTypesField from "@/components/calendar/appointmentModal/fields/LessonTypesField"
import PlaceholderNotificationField from "@/components/calendar/appointmentModal/fields/PlaceholderNotificationField"
import VehicleFieldDynamic from "@/components/calendar/appointmentModal/fields/VehicleFieldDynamic"
import MeetingPlaceField from "@/components/calendar/appointmentModal/fields/MeetingPlaceField"
import NotesField from "@/components/calendar/appointmentModal/fields/NotesField"
import CopyAppointment from "@/components/calendar/appointmentModal/fields/CopyAppointment"
import MultiplePlaceholderSwitch from "@/components/calendar/appointmentModal/fields/StatusSwitch"

import TeacherModel from "@/store/models/TeacherModel"
import LicenseModel from '@/store/models/LicenseModel'
import LicenseGroupModel from '@/store/models/LicenseGroupModel'
import StudentModel from '@/store/models/StudentModel'
import LessonTypeModel from '@/store/models/LessonTypeModel'

import SelectField from '@/helpers/selectField'

export default {
  name: "PlaceholderForm",
  mixins: [user, placeholderPracticeMixin, priceCategoryMixin],
  components: {
    SegmentedProgressBar,
    TeachersField,
    PlaceholderRequestsField,
    LicenseField,
    LessonTypesField,
    PlaceholderNotificationField,
    VehicleFieldDynamic,
    MeetingPlaceField,
    NotesField,
    CopyAppointment,
    MultiplePlaceholderSwitch,
  },
  data: () => ({
    initialDuration: 0,
    loadingStudentProgress: false,
  }),
  props: {
    teachers: {
      type: Array,
      required: true,
    },
    placeholderRequestedStudent: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters("appointments", { currentAppointment: "currentAppointmentGetter", }),
    studentHasNoPriceList() {
      if (!this.currentAppointment.isRequested) return false
      const pickedStudent = StudentModel.find(this.placeholderRequestedStudent) || null
      return pickedStudent?.bookAndPay && !pickedStudent?.priceCategoryId
    },
    activeLicensesIds() {
      const teacher = TeacherModel.find(this.currentAppointment.teacherId)
      const student = StudentModel.find(this.placeholderRequestedStudent)

      if (!teacher || !student) return []

      const practiceLicensesIds = LicenseModel.query().where('forPractice', true).get().map(license => license.id);

      return teacher.licensesIds
          .filter((licenseId) => practiceLicensesIds.includes(licenseId) && student.licensesIds.includes(licenseId))
    },
    studentsRequests() {
      return this.currentAppointment.studentsIds.map(request => {
        const license = LicenseModel.find(request.licenseId) || null
        const student = StudentModel.find(request.id) || null
        if (license) request.licenseName = license.name
        if (student) {
          request.studentName = student.fullName
          request.balance = student.balance
          request.bookAndPay = student.bookAndPay
          request.priceCategoryId = student.priceCategoryId
        }
        return request
      }) || []
    },
    notificationLicensesArray() {
      return this.currentAppointment.notificationLicenses || []
    },
    teacherIsNotSelected() {
      return !this.currentAppointment.teacherId
    },
    allowedLicensesList() {
      const teacher = TeacherModel.find(this.currentAppointment.teacherId)
      const licensesIds = LicenseModel.query().where('forPractice', true).get().map(license => license.id);

      return teacher ? teacher.licensesIds.filter(licenseId => licensesIds.includes(licenseId)) : []
    },
    groupedNotificationLicenses() {
      const teacher = TeacherModel.find(this.currentAppointment.teacherId) || {}
      const validLicenses = this.currentAppointment.allowedLicenses?.length
          ? this.currentAppointment.allowedLicenses
          : teacher.licensesIds
      const teacherLicenses = LicenseModel.query().whereIdIn(validLicenses).get() || []
      const teacherGroups = teacherLicenses.map((license) => license.groupId)
      const groups = LicenseGroupModel.query()
        .whereIdIn([...new Set(teacherGroups)])
        .where(q => q.name !== 'BKF')
        .get() || []

      return groups.map((groupItem) => {
        const parent = {
          ...groupItem,
          id: groupItem.id + "i",
          groupId: groupItem.id,
        }
        const license = LicenseModel.query().whereIdIn(validLicenses).where("groupId", parent.groupId).get() || []
        return { ...parent, children: license }
      })
    },
    student() {
      const student = this.currentAppointment.studentsIds.length === 1 ?
          StudentModel.find(this.currentAppointment.studentsIds[0].id) :
          StudentModel.find(this.placeholderRequestedStudent)
      return student || []
    },
    baseLessonTypes() {
      return LessonTypeModel.query()
          .where("eventTypeId", "PRACTICE")
          .where('group', groupItem => ["normal", "special", "instructions"].includes(groupItem))
          .get() || []
    },
  },
  methods: {
    updateProgress(val) {
      this.loadingStudentProgress = val
    },
    updateTeacherField(val) {
      this.updateFields({
        teacherId: val,
        studentsIds: [],
        licenseId: null,
        allowedLicenses: [],
        notificationLicenses: [],
      })
    },
    pickRequest(val) {
      this.updateFields({
        licenseId: val ? val.licenseId : null,
        meetingPlace: val ? val.meetingPlace : null,
        primaryVehicleId: null,
        secondaryVehicleId: null,
      })
      this.$emit('emitPickRequestedStudent', val ? val.id : null)
      this.$nextTick(() => {
        this.updateLicenseField(this.currentAppointment.licenseId, true)
        if(val && 'id' in val) {
          this.$refs.licenseField.loadProgressForStudent(val.id)
        }
      })
    },
    updateLicenseField(val, cascade = false) {
      this.updateFields({ licenseId: val })
      const lessonTypeVal = new SelectField(this.currentAppointment).lessonTypeSelector(this.lessonTypesList, cascade)
      this.$nextTick(() => {
        this.updateLessonTypeField(lessonTypeVal)
      })
    },
    updateLessonTypeField(val) {
      this.updateFields({ lessonTypeId: val })
    },
  },
}
</script>
