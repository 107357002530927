import api from "@/utils/api";

export default {
  create: (payload) => {
    return new Promise((resolve, reject) => {
      api
        .post("/invoice-services", payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  }
}
