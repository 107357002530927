<template>
<div>
  <h4>{{ $t('form.sign_as') }}</h4>

  <v-autocomplete
      dense
      outlined
      :placeholder="$t('form.select_signature')"
      :loading="loading"
      :disabled="loading || disabled"
      :menu-props="{ top: true, offsetY: true }"
      :items="options"
      v-model="signature"
      item-text="name"
      hide-details
      clearable
      @change="onChange"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item-content>

      <v-list-item-action v-if="item.signatureType" class="caption">
        {{ $t(`signature_types.${item.signatureType}`) }}
      </v-list-item-action>
    </template>
  </v-autocomplete>
</div>
</template>

<script>
import signaturesService from '@/services/signaturesService'
import user from '@/utils/mixins/user'

export default {
  name: 'SignatureAutocompleteField',
  mixins: [user],
  props: {
    document: String,
    signatureId: {
      type: Number,
      default: null
    },
    relatedTeacherId: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    teachersIds: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      schoolSignatures: [],
      schoolUsersSignatures: [],
      signature: null
    }
  },
  computed: {
    schoolAdministratorsSignatures() {
      return this.schoolUsersSignatures.filter(item => item.signableType === 'school-administrator')
    },
    teachersSignatures() {
      if (this.document === 'insights-result') {
        return this.schoolUsersSignatures.filter((item) => {
          return item.signableType === 'teacher' && this.teachersIds.includes(item.teacherId)
        })
      }
      if (this.document !== 'apology-letter') return []
      return this.schoolUsersSignatures.filter(item => item.signableType === 'teacher' && item.teacherId === this.relatedTeacherId)
    },
    options() {
      return [
        { header: this.$t('label.school'), items: this.schoolSignatures },
        { header: this.$t('user_type.school-administrator'), items: this.schoolAdministratorsSignatures },
        { header: this.$t('user_type.teacher'), items: this.teachersSignatures },
      ]
          .filter(item => item.items.length > 0)
          .map((item) => this.createListGroup(item.header, item.items))
          .flat();
    }
  },
  watch: {
    signatureId (value) {
      this.signature = value
    }
  },
  mounted () {
    signaturesService.load()
      .then(response => {
        const responseData = response.data || {}

        this.schoolSignatures = responseData.schoolSignatures || []
        this.schoolUsersSignatures = responseData.schoolUsersSignatures || []

        const currentUserSignature = this.schoolUsersSignatures
            .find(item => item.signableId === this.currentUser.userId)

        if (currentUserSignature) {
          this.signature = currentUserSignature.id
          this.onChange(currentUserSignature.id)
        }
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    createListGroup(header, items = []) {
      return [
        { header: header },
        { divider: true },
        ...items.map((item) => ({
          name: item.signableName,
          signatureType: item.signatureType,
          value: item.id,
        })),
      ];
    },
    onChange(value) {
      this.$emit('change', value)
    }
  }
}
</script>

<style scoped>

</style>
