<template>
  <div class="mobile-menu">
    <div class="mobile-menu__header">
      <v-img class="logo" :src="logo" :transition="false" />
    </div>

    <v-row class="mobile-menu__row mr-5 ml-5" no-gutters>
      <v-col
        class="mobile-menu__column"
        v-for="item in menuItems"
        :key="item.title"
        cols="6"
        :sm="smColumnSize">
        <!--        <v-badge color="red" :content="unseenMessagesCount" offset-y="5" offset-x="5" :value="!!item.meta.link.hasBadge && unseenMessagesCount">-->
        <img
          class="mobile-menu__link"
          :src="item.icon"
          :transition="false"
          @click="goTo(item.link)" />
        <!--        </v-badge>-->
        <span class="menu-item__text">{{ item.name }}</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import menu from "@/utils/mixins/menu";

export default {
  name: "MobileMenu",
  mixins: [menu],
  data() {
    return {
      logo: require("../assets/svg/book-and-pay.svg"),
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    smColumnSize() {
      return this.menuItems.length <= 4 ? 12 / this.menuItems.length : 3;
    },
    mdLandscapeAndDown() {
      return (
        this.$vuetify.breakpoint.mdAndDown &&
        !(this.$vuetify.breakpoint.md && window.innerHeight < window.innerWidth)
      );
    },
  },
  watch: {
    mdLandscapeAndDown(value) {
      if (!value && this.$route.name === "mobile.menu") this.$router.push("/");
    },
  },
  methods: {
    goTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped lang="scss">
.mobile-menu {
  &__header {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 30px;

    .logo {
      max-width: 60%;
      width: 200px;

      @media (orientation: landscape) {
        max-width: 30%;
      }
    }
  }
  &__column {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 5% 0;

    @media (orientation: landscape) {
      padding: 1% 0;
    }
  }
  &__row {
    margin-top: -15px;
    margin-bottom: 30px;

    @media (orientation: landscape) {
      margin-top: -10px;
    }
  }
  &__link {
    cursor: pointer;
    width: 50px;
    height: 50px;
    margin-bottom: 3px;

    @media (orientation: landscape) {
      width: 40px;
      height: 40px;
    }

    &__text {
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      font-weight: 500;
    }
  }
}
</style>
