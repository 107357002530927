<template>
  <div>
    <v-row>
      <v-col md="3" sm="9">
        <v-switch
          @change="resetCopy"
          v-model="active"
          :disabled="disabled"
          :label="$t('form.copy')"
          inset
          dense
          hide-details />
      </v-col>
      <v-col offset-md="5" md="4" sm="3">
        <v-select
          class="mt-0"
          v-model="model.copyForNrOfWeeks"
          :items="copyWeeks"
          :disabled="!active"
          :label="$t('form.week_numbers')"
          outlined
          dense
          hide-details
          :menu-props="{ offsetY: true }" />
      </v-col>
    </v-row>

    <v-alert v-if="active && copyForNrOfWeeks > 0" dense outlined type="info">
      {{ $t("form.lessons_amount", { count: copyForNrOfWeeks }) }}
    </v-alert>
  </div>
</template>

<script>
import responsive from "@/utils/mixins/responsive";
import { mapGetters } from "vuex";

export default {
  name: "CopyCurrentEvent",
  mixins: [responsive],
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      active: false,
      infinite: false,
      copyForNrOfWeeks: null,
    };
  },
  watch: {
    createEventModalGetter() {
      this.active = false;
      this.copyForNrOfWeeks = null;
    },
    active(value) {
      this.model.copyForNrOfWeeks = value ? 1 : null;
    },
    "model.licenseId"() {
      this.active = false;
    },
  },
  computed: {
    ...mapGetters("appointments", ["createEventModalGetter"]),
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    copyWeeks() {
      const arr = [];
      for (let i = 1; i <= 25; i++) arr.push(i);
      return arr;
    },
  },
  methods: {
    resetCopy() {
      this.model.copyForNrOfWeeks = null;
      this.infinite = false;
    },
    resetInfinite() {
      this.model.copyForNrOfWeeks = null;
      this.active = false;
    },
  },
};
</script>
