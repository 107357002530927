<template>
  <div class="mb-2 d-flex align-center">
    <v-row dense no-gutters>
      <v-col>
        <v-checkbox
            v-if="price.createdAt !== null"
            class="mt-0"
            :input-value="price.active"
            @click="togglePriceStatus(price.id, !price.active)"
            :disabled="loadingPriceStatus"
            hide-details
            readonly
        >
          <template v-slot:label>
            <span class="mt-1" :class="{'active': price.active, 'text-decoration-line-through' : !price.active}">
              {{ title }}
            </span>
          </template>
        </v-checkbox>

        <span v-else class="mt-1 subtitle-1">{{ title }}</span>
      </v-col>

      <v-col class="d-flex justify-end">
        <div v-if="price.createdAt !== null">
          <span class="font-weight-bold subtitle-1" :class="{ 'secondary--text': price.active, 'text--disabled': !price.active }">
            {{ price.price | currency }}
          </span>
        </div>

        <ValidationProvider
            v-else
            v-slot="{ errors }"
            :name="'lesson-type_' + id"
            class="flex-grow-1"
        >
          <v-text-field
              class="ml-auto"
              suffix="€"
              placeholder="0,00"
              :value="form.price"
              @input="onInputPriceValue"
              outlined
              dense
              hide-details
              v-currency="{
                currency: null,
                allowNegative: false,
                valueRange: { min: 0.0 },
              }"
              :style="{'max-width': '200px'}"
              :error="!!errors.length"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LessonTypeModel from "@/store/models/LessonTypeModel";
import currency from "@/utils/mixins/currency";
import pricesService from '@/services/pricesService'

export default {
  name: "ProductPrice",
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    type: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    price: {
      type: Object,
      default: () => ({
        id: null,
        price: 0.00,
        createdAt: null
      })
    }
  },
  mixins: [currency],
  data: () => ({
    loadingPriceStatus: false,
    form: {
      price: 0.0
    },
  }),
  watch: {
    "form.price": function (value) {
      if (value === "") this.form.price = 0.0;
    },
  },
  filters: {
    snackCaseToText: function (value) {
      return value ? value.replace("_", " ") : "";
    },
  },
  computed: {
    product() {
      return LessonTypeModel.query().with("prices").find(this.productId) || {};
    }
  },
  methods: {
    getFormData() {
      return {
        id: this.id,
        type: this.type,
        price: this.sanitizePrice(this.form.price, "."),
        createdAt: this.price.createdAt
      };
    },
    togglePriceStatus(id, status) {
      if (this.price.createdAt === null) {
        this.$emit('updateActiveStatus', { priceId: this.price.id, active: status })
        return
      }

      this.loadingPriceStatus = true;
      pricesService
        .setActiveStatus(id, status)
        .then(() => {
          this.$emit('updateActiveStatus', { priceId: this.price.id, active: status })
        })
        .finally(() => (this.loadingPriceStatus = false));
    },
    onInputPriceValue(value) {
      this.form.price = value
    }
  },
};
</script>

<style scoped></style>
