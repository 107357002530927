<script>
import SignatureAutocompleteField from '@/components/SignatureAutocompleteField.vue'
import user from '@/utils/mixins/user'

export default {
  name: 'InsightsTemplatePdfDialog',
  mixins: [user],
  components: { SignatureAutocompleteField },
  props: {
    student: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    teachersIds: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
    licenseId: null,
    signatureId: null,
    drivingLicenseNumber: ''
  }),
  computed: {
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        this.drivingLicenseNumber = null
        this.signatureId = null
      }, 200)
    },
    onSignatureSelected(value) {
      this.signatureId = value
    },
    submit() {
      this.$emit('emitInsightsTemplatePDF', this.signatureId)
    },
  }
}
</script>

<template>
  <v-dialog v-model="dialog" width="500" @click:outside="close" @keydown.esc="close">
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>{{ $t("label.insights_results") }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-card-title>
        {{ $t('label.insights_results_for') }}
      </v-card-title>

      <v-card-subtitle>
        {{ student.fullName }}
      </v-card-subtitle>

      <v-card-text>
        <v-row v-if="isSchoolAdministrator" dense>
          <v-col>
            <SignatureAutocompleteField
              document="insights-result"
              :teachers-ids="teachersIds"
              :key="dialog"
              @change="onSignatureSelected"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn text @click="close">{{$t('btn.close')}}</v-btn>
        <v-spacer />
        <v-btn color="primary" :loading="loading" :disabled="loading" @click="submit">
          <v-icon left>mdi-file-document</v-icon>
          {{ $t("btn.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>
