<script>
import {defineComponent} from 'vue'
import schoolBalanceLabel from "@/utils/mixins/schoolBalanceLabel";
import laravelEcho from "@/plugins/laravel-echo";

export default defineComponent({
  name: "SchoolCreditsBalanceLabel",
  mixins: [schoolBalanceLabel],
  mounted() {
    this.$nextTick(() => {
      laravelEcho
        .private(`school-private-channel.${this.id}`)
        .listen('SchoolCreditsBalanceUpdated', (data) => {
          this.change = data.change
          this.$nextTick(() => {
            this.$refs.animatedLabel?.classList?.add(data.change > 0 ? 'positive' : 'negative')
            setTimeout(() => {
              this.$refs.animatedLabel?.classList?.remove('positive', 'negative')
              this.change = null
            }, 800)
          })
        })
    })
  },
  data () {
    return {
      show: false,
      change: null,
      services: [
        {
          title: this.$t('credits_services.create_student_title'),
          description: this.$t('credits_services.create_student_description'),
          cost: -1,
          enabled: true
        },
        {
          title: this.$t('credits_services.register_license_title'),
          description: this.$t('credits_services.register_license_description'),
          cost: -3,
          enabled: true
        },
        {
          title: this.$t('credits_services.renew_license_title'),
          description: this.$t('credits_services.renew_license_description'),
          cost: -1,
          enabled: true
        },
        {
          title: this.$t("credits_services.assign_insights_template_title"),
          description: this.$t("credits_services.assign_insights_template_description"),
          cost: -1,
          enabled: this.$store.state.school.hasInsights
        }
      ]
    }
  },
  computed: {
    enabledServices() {
      return this.services.filter((service) => service.enabled)
    }
  }
})
</script>

<template>
  <v-dialog
    v-model="show"
    offset-y
    bottom
    transition="slide-y-transition"
    :close-on-content-click="false"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip label dark :color="color" small v-bind="attrs" v-on="on" style="position: relative; overflow: visible;">
        <v-icon left small>$coins</v-icon>
        {{ credits }}
        <span v-show="change" ref="animatedLabel" style="position: absolute; font-size: 18px; left: 50%; transform: translateX(-50%)">
          {{change > 0 ? '+' : ''}}{{ change }}
        </span>
      </v-chip>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        {{ $t("label.your_credits_balance") }}
      </v-card-title>
      <v-card-text>
        <v-list dense>
          <v-subheader>
            {{ $t("label.available_credits") }}
            <v-spacer />
            <span :class="`${color}--text`">{{ $tc("label.credits", credits, { credits: credits }) }}</span>
          </v-subheader>
          <v-subheader>{{ $t("label.services_costs") }}</v-subheader>
          <v-list-item v-for="(service, index) in enabledServices" :key="index" dense three-line>
            <v-list-item-content>
              <v-list-item-title>{{ service.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ service.description }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action-text class="warning--text">
              {{ $tc('label.credits', service.cost, {credits: service.cost}) }}
            </v-list-item-action-text>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" @click="show = false" :to="{name: 'school-balance'}">
          <v-icon left>$coins</v-icon>
          {{ $t("btn.credits_management") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
@keyframes positive {
  0% {
    opacity: 1;
    transform: translateX(-50%);
    font-size: 30px;
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(-50px);
    font-size: 18px;
  }
}

@keyframes negative {
  0% {
    opacity: 1;
    transform: translateX(-50%);
    font-size: 18px;
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(50px);
    font-size: 30px;
  }
}

.positive {
  animation: positive .8s linear forwards; /* adjust the time (2s here) as per your needs */
  color: #7bff7b;
  text-shadow: 0px 0px 3px black;
  top: 50px;
}

.negative {
  animation: negative .8s linear forwards; /* adjust the time (2s here) as per your needs */
  color: #ff7171;
  text-shadow: 0px 0px 3px black;
}
</style>
