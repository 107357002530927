const events = {
    AppointmentCreated: 'AppointmentCreated',
    AppointmentUpdated: 'AppointmentUpdated',
    AppointmentDeleted: 'AppointmentDeleted',
    AppointmentRestored: 'AppointmentRestored',
    PlaceholderRequested: 'PlaceholderRequested',
    PlaceholderConfirmed: 'PlaceholderConfirmed',
    PlaceholderDeclined: 'PlaceholderDeclined',
    PracticeRequestConfirmed: 'PracticeRequestConfirmed',
    PracticeRequestDeclined: 'PracticeRequestDeclined',
    TheoryParticipantAdded: 'TheoryParticipantAdded',
    TheoryParticipantRemoved: 'TheoryParticipantRemoved',
    SimulatorSubscribed: 'SimulatorSubscribed',
    SimulatorUnsubscribed: 'SimulatorUnsubscribed',
}

export default events;
