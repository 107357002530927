<template>
  <v-col :cols="cols">
    <ValidationProvider
      name="license"
      :rules="`${validation ? 'required' : ''}`"
      v-slot="{ errors }">
      <v-select
        @change="$emit('theoryStudentChange')"
        :error="errors.length > 0"
        :disabled="disabled"
        v-model="licenseId"
        :label="$t('form.licence')"
        :items="activeLicenses"
        item-value="id"
        :item-text="getLicenseLabel"
        :clearable="clearable"
        outlined
        hide-details
        dense
        :append-icon="!disabled ? 'mdi-menu-down' : ''"
        :menu-props="{ offsetY: true }" />
    </ValidationProvider>
    <v-checkbox
      v-if="manualLesson"
      v-model="model.manualLesson"
      label="Schalterstunde"
      hide-details />
  </v-col>
</template>

<script>
import LicenseModel from "@/store/models/LicenseModel";
import { mapGetters } from "vuex";

export default {
  name: "LicenseField",
  props: {
    activeLicenses: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    cols: {
      type: Number,
      default: 6,
    },
    validation: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.activeLicenses.length === 1)
      this.licenseId = this.activeLicenses[0].id;
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    licenseId: {
      get() {
        return this.model.subscriber
          ? this.model.subscriber.licenseId
          : this.model.licenseId;
      },
      set(val) {
        this.model.licenseId = val;
      },
    },
    manualLesson() {
      const license = LicenseModel.find(this.licenseId) || {};
      const validGroup =
        this.model.lessonTypeGroup === "normal" ||
        this.model.lessonTypeGroup === "special";
      return license.name === "B197" && validGroup;
    },
  },
  methods: {
    getLicenseLabel(item) {
      return item.name;
    },
  },
};
</script>

<style lang="scss" scoped></style>
