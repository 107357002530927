import { render, staticRenderFns } from "./StudentPriceLists.vue?vue&type=template&id=1524bce9&scoped=true"
import script from "./StudentPriceLists.vue?vue&type=script&lang=js"
export * from "./StudentPriceLists.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1524bce9",
  null
  
)

export default component.exports