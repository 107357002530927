<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "CashPaymentsTable",
  props: {
    payments: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
    },
    user: {
      type: [String, Number],
    },
    except: {
      type: [String, Number],
    },
    fresh: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    groupedByDay() {
      return this.payments.reduce((groups, payment) => {
        if (!groups[payment.date]) {
          groups[payment.date] = [];
        }
        groups[payment.date].push(payment);
        return groups;
      }, {});
    },
    total () {
      return this.payments.reduce((sum, current) => {
        return sum + parseFloat(current.moneyAmount);
      }, 0);
    }
  },
  methods: {
    totalByGroup(groupId) {
      return this.groupedByDay[groupId]
        .reduce((sum, current) => {
          return sum + parseFloat(current.moneyAmount);
        }, 0)
    },
  }
})
</script>

<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
      <tr>
        <th style="width: 80px">{{ $t("label.time") }}</th>
        <th>{{ $t("label.student") }}</th>
        <th  style="width: 250px">{{ $t("label.author") }}</th>
        <th style="width: 200px">{{ $t("label.amount") }}</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(item, index) in groupedByDay">
        <tr :key="index" style="background: rgb(250, 250, 250)">
          <th colspan="3">{{ index | dateFormat("dd.MM.Y") }}</th>
          <th>{{ totalByGroup(index) | currency }}</th>
        </tr>
        <tr
          v-for="payment in groupedByDay[index]"
          :key="payment.id"
          class="table-row"
        >
          <td>{{ payment.createdAt | dateFormat('HH:mm:ss') }}</td>
          <td>
            <span>{{ payment.studentName }}</span>
            <v-chip
                class="ml-2 hidden-until-row-hovered"
                link
                label
                x-small
                :to="{
                  name: 'students.student.finances',
                  params: { id: payment.studentId },
                }">
              <v-icon x-small>mdi-arrow-right</v-icon>
            </v-chip>
          </td>
          <td>{{ payment.author?.name ?? '-' }}</td>
          <td style="width: 50px">
            {{ payment.moneyAmount | currency }}
          </td>
        </tr>
      </template>
      </tbody>
      <tfoot>
      <tr>
        <th colspan="3">Total</th>
        <th>{{ total | currency }}</th>
      </tr>
      </tfoot>
    </template>
  </v-simple-table>
</template>

<style scoped>

</style>
