<template>
  <v-dialog
      v-model="show"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
      scrollable
      width="600"
  >
    <v-card>
      <v-toolbar class="flex-grow-0 mb-4" color="primary" dark dense>
        <v-toolbar-title>{{$t('label.service_appointment_overlap')}}</v-toolbar-title>
        <v-spacer />
        <v-btn dark icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-alert
            elevation="1"
            type="warning"
            colored-border
            border="left"
        >
          {{ $t('messages.service_appointment_overlap_with_appointments_1') }}
          {{ $t('messages.service_appointment_overlap_with_appointments_2') }}
        </v-alert>

        <v-list v-if="loading">
          <div>
            <v-skeleton-loader type="image" class="mx-4" height="42" />
          </div>
          <v-list-item two-line dense v-for="index in [1,2]" :key="index">
            <v-list-item-content>
              <v-list-item-title>
                <v-skeleton-loader type="image" height="50" />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list v-else dense>
          <v-list-item v-for="appointment in appointments" :key="appointment.id" dense>
            <v-list-item-content>
              <v-list-item-title>
                {{ appointment.date }} &middot; {{ appointment.startTime }} — {{ appointment.endTime }}
              </v-list-item-title>
              <v-list-item-subtitle >
                {{ appointment.teacherName }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="appointment.lessonType">
              {{ $tc(appointment.lessonType.name) }}
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn text @click="close">{{ $t("btn.close") }}</v-btn>
        <v-spacer />
        <v-btn
            color="primary"
            @click="confirm"
        >
          {{ $t('btn.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import appointmentService from '@/services/appointmentService'
import AppointmentModel from '@/store/models/AppointmentModel'

export default {
  name: "VehicleServiceAppointmentOverlapDialog",
  data() {
    return {
      show: false,
      loading: false
    }
  },
  computed: {
    appointments() {
      return AppointmentModel.all()
    }
  },
  methods: {
    open(overlapAppointmentsIds = []) {
      this.show = true

      this.loading = true
      appointmentService
        .load({ ids: overlapAppointmentsIds })
        .then((resp) => AppointmentModel.create({data: resp.data || []}))
        .finally(() => {
          this.loading = false
        })
    },
    close() {
      this.show = false
    },
    confirm() {
      const appointmentsIds = this.appointments.map((appointment) => appointment.id)
      this.$emit('confirm', { appointmentsIds })
      this.close()
    },
  }
}
</script>
