<template>
  <div>
    <div v-if="appointment.primaryVehicle" class="vehicles">
      <div class="caption">{{ appointment.primaryVehicle.label }}</div>
      <div class="caption" v-if="appointment.secondaryVehicle">
        {{ appointment.secondaryVehicle.label }}
      </div>
    </div>

    <div class="mt-1" v-if="appointment.meetingPlace">
      <div class="meeting-place d-flex">
        <v-icon size="19">mdi-map-marker</v-icon>
        <div class="text-caption text-truncate mr-10">{{ appointment.meetingPlace }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PracticeCard",
  props: {
    appointment: {
      type: Object,
      default: () => ({}),
    },
  }
}
</script>

<style scoped>

</style>
