<template>
<v-list-group no-action eager :disabled="student.isDeleted">
  <template v-slot:activator>
    <v-list-item-content>
      <v-list-item-title>{{ $t('insights.feature_name') }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-action-text>
      <v-chip label x-small :color="activeLabelColor" dark>{{ activeLabelText }}</v-chip>
    </v-list-item-action-text>
  </template>

  <v-sheet v-if="loadingInsightsTemplates" class="pa-5 d-flex align-center justify-center">
    <v-progress-circular indeterminate size="40" color="primary" />
  </v-sheet>

  <template v-else-if="templatesLicenses.length > 0">
    <template v-for="license in templatesLicenses">
      <v-list-item
        two-line
        dense
        :key="license.id + 'item'"
        :disabled="student.isDeleted"
        @click="assignTemplate(license)"
      >
        <v-list-item-content>
          <v-list-item-title>{{ license.name }}</v-list-item-title>
          <v-list-item-subtitle v-if="!license.hasTemplate">
            {{ $t("insights.btn.assign_template") }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else>{{ license.progress }}%</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action-text v-if="!license.hasTemplate">-1 cr.</v-list-item-action-text>

        <v-list-item-action v-else>
          <v-progress-circular :color="colorByPercentage(license.progress)" :value="license.progress" size="20" />
        </v-list-item-action>
      </v-list-item>
      <v-list-item
        v-if="license.hasTemplate && license.isRevocable"
        :key="license.id + 'revoke'"
        :disabled="student.isDeleted || revoking"
        dense
        style="min-height: 20px;"
        @click="revokeTemplate(license)"
      >
        <v-list-item-content>
          <v-list-item-subtitle>{{ $t("insights.btn.revoke_template") }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action-text>{{ $t("label.revocable_till", { date: license.revocableTill }) }} +1 cr.
        </v-list-item-action-text>
      </v-list-item>
    </template>
  </template>

  <empty-box v-else>
    {{ $t('insights.no_templates_available_for_this_license') }}
  </empty-box>

  <InsightsTemplateLicenseDialog ref="insightsTemplateLicenseDialog" :studentId="student.id" :templates="templates" />
</v-list-group>
</template>

<script>
import insightsService from '@/services/insightsService'
import { mapGetters } from 'vuex'
import LicenseModel from '@/store/models/LicenseModel'
import InsightsTemplateLicenseDialog from '@/components/insights/InsightsTemplateLicenseDialog.vue'
import StudentModel from "@/store/models/StudentModel";
import { showConfirm } from '@/helpers/dialogs'
import { addDays, format, isAfter, parseISO, endOfDay } from "date-fns";

export default {
  name: 'InsightsWidget',
  components: { InsightsTemplateLicenseDialog },
  props: {
    student: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      loadingInsightsTemplates: true,
      loadingLicensesProgress: true,
      licensesProgress: {},
      revoking: false,
      templates: []
    }
  },
  computed: {
    ...mapGetters("school", ["school"]),
    loading() {
      return this.loadingInsightsTemplates || this.loadingLicensesProgress
    },
    activeLabelColor () {
      return this.student.hasInsightsEnabled ? 'green' : 'red'
    },
    activeLabelText () {
      return this.student.hasInsightsEnabled ? this.$t('label.enabled') : this.$t('label.disabled')
    },
    templatesLicenses () {
      const templatesLicenses = [
          ...new Set(this.templates.map(template => template.licenses.map(item => item.id)).flat())
      ]
      const studentLicenses = this.student.licensesIds.filter(license => templatesLicenses.includes(license))

      return LicenseModel
          .query()
          .whereIdIn(studentLicenses)
          .get()
          .map(item => {
            const licensesWithInsightsTemplate = this.student.licensesWithInsightsTemplate
              .find(license => license.licenseId === item.id);

            const hasTemplate = licensesWithInsightsTemplate?.templateEnabledAt;
            const progress = this.licensesProgress[item.id] ? this.licensesProgress[item.id].overallProgress : 0
            const evaluations = this.licensesProgress[item.id] ? this.licensesProgress[item.id].evaluations : []
            const revocableTillDate = hasTemplate ?
              addDays(parseISO(licensesWithInsightsTemplate.templateEnabledAt), 7) : null;
            const revocableTill = hasTemplate ? format(revocableTillDate, "dd.MM.yyyy") : null;

            const isRevocable = hasTemplate ? isAfter(endOfDay(revocableTillDate), new Date) : false;

            return { ...item, hasTemplate, isRevocable, revocableTill, progress, evaluations };
          })
          .sort((prev, next) => {
            if (next.evaluations.length > 0 && !prev.hasTemplate) return 1;
            if (!next.evaluations.length > 0 && prev.hasTemplate) return -1;
            return 0;
          })
    }
  },
  mounted() {
    if (this.school.hasInsights) {
      const licenseProgressRequests = []
      this.student.licensesWithInsightsTemplate.forEach(item => {
        licenseProgressRequests.push(insightsService.getStudentLicenseProgress(this.student.id, item.licenseId));
      })

      this.loadingLicensesProgress = true
      Promise
        .all(licenseProgressRequests)
        .then(responses => {
          responses.forEach(response => {
            this.licensesProgress[response.data.licenseId] = response.data
          })
        })
        .finally(() => {
          this.loadingLicensesProgress = false

          insightsService
              .listTemplates()
            .then(resp => {
              this.templates = resp.data || [];
            })
              .finally(() => {
                this.loadingInsightsTemplates = false
              })
        })
    }
  },
  methods: {
    colorByPercentage(percentage) {
      if (percentage === 0 || percentage === null) return 'grey';

      return percentage < 50 ? 'red' : (percentage < 80 ? 'orange' : 'green');
    },
    assignTemplate(license) {
      if (!license.hasTemplate) {
        this.$refs.insightsTemplateLicenseDialog.open(license.id)
        return
      }

      if (license.evaluations.length > 0 || !license.isAssignable) {
        if (this.$route.params.licenseId === license.id) return;

        this.$router.push({
          name: 'students.student.education.license.insights',
          params: {
            id: this.student.id,
            licenseId: license.id
          }
        })
        .catch(error => console.log(error))
      }
    },
    revokeTemplate(license) {
      showConfirm(
        this.$t('btn.confirm'),
        this.$t('messages.are_your_sure_revoke_insights_template'),
        () => {
          this.revoking = true
          insightsService.revokeTemplateForStudentLicense(this.student.id, license.id)
            .then(() => {
              this.$snackbar.success(this.$t("messages.template_revoke_successfully"));

              const licensesWithInsightsTemplate = this.student.licensesWithInsightsTemplate
                .filter(item => item.licenseId !== license.id);

              StudentModel.update({
                where: this.student.id, data: {
                  hasInsightsEnabled: licensesWithInsightsTemplate.length > 0,
                  licensesWithInsightsTemplate: licensesWithInsightsTemplate
                }})
            })
            .catch(error => {
              console.log(error);

              const responseData = error?.response?.data || {};

              if (responseData.message) this.$snackbar.error(responseData.message);
            })
            .finally(() => {
              this.revoking = false;
            })
        }
      )
    }
  }
}
</script>

