<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'personal-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>
        {{ $t("route.personalSettings.children.notifications") }}
      </v-card-title>
    </v-toolbar>

    <v-card-text>
      <v-switch
        v-if="isStudent"
        :label="$t('form.email')"
        :input-value="hasEmailNotifications"
        :loading="saving"
        :disabled="saving"
        @change="switchEmailNotificationsStatus"
      />
      <template v-if="!isStudent">
        <v-skeleton-loader v-if="loading" type="list-item-three-line" />

        <v-expansion-panels
          v-else-if="notificationsGroups.length > 0"
          accordion
          multiple
        >
          <NotificationGroup
            v-for="notificationGroup in notificationsGroups"
            :key="notificationGroup"
            :settings-area="settingsArea"
            :user-type="currentUser.type"
            :notification-group="notificationGroup"
            :disabled="loading"
          />
        </v-expansion-panels>

        <empty-box v-else />
      </template>
    </v-card-text>
  </div>
</template>

<script>
import user from "@/utils/mixins/user";

import notificationsService from "@/services/notificationsService";
import NotificationGroup from "@/components/school/settings/NotificationGroup";
import NotificationsSettings from "@/store/models/NotificationsSettings";
import studentService from "@/services/studentService";
import responsive from '@/utils/mixins/responsive'

export default {
  name: "Notifications",
  components: { NotificationGroup },
  mixins: [user, responsive],
  data: () => ({
    loading: true,
    saving: false,
    settingsArea: "user",
    hasEmailNotifications: true,
  }),
  computed: {
    notificationsGroups() {
      return NotificationsSettings.query()
        .where("settingsArea", this.settingsArea)
        .get()
        .map((item) => item.notificationGroup);
    },
  },
  mounted() {
    if (this.isStudent)
      this.hasEmailNotifications = this.currentUser.hasEmailNotifications;

    notificationsService
      .userNotificationsSettings(this.currentUser.userId)
      .then((response) => NotificationsSettings.create({ data: response.data || [] }))
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    switchEmailNotificationsStatus(newState) {
      this.hasEmailNotifications = newState;

      this.saving = true;
      studentService
        .switchEmailNotifications({ hasEmailNotifications: this.hasEmailNotifications })
        .then(() => {
          this.$snackbar.show({
            message: this.$t("messages.successfully_updated"),
            color: "success",
            timeout: 2000,
          });
        })
        .finally(() => (this.saving = false));
    },
  },
};
</script>

<style scoped></style>
