<script>
import {mapActions, mapGetters} from "vuex";
import debounceThrottle from "@/utils/mixins/debounce-throttle";
import {EventBus} from "@/EventBus";
import StudentsIndexPreferencesDialog from "@/components/students-directory/StudentsIndexPreferencesDialog.vue";
import markdownParser from "@/utils/mixins/markdownParser";
import StudentsDirectoryTutorialDialog from "@/components/students-directory/StudentsDirectoryTutorialDialog.vue";
export default {
  name: "TopBar",
  components: {StudentsDirectoryTutorialDialog, StudentsIndexPreferencesDialog},
  mixins: [debounceThrottle, markdownParser],
  data() {
    return {
      debouncedSearch: null,
      searchInfoDialog: false,
      prevSearch: '',
      localSearchQuery: ''
    }
  },
  created() {
    this.debouncedSearch = this.debounce((newQuery) => {
      this.load();
      this.prevSearch = newQuery;
    }, 500);

    // Initialize localSearchQuery with Vuex state value
    this.localSearchQuery = this.searchQuery;
  },
  mounted() {
    EventBus.$on('focus-search-input', this.focusSearch)
  },
  beforeDestroy() {
    EventBus.$off('focus-search-input', this.focusSearch)
  },
  methods: {
    ...mapActions('studentsDirectory', ['openFiltersDialog', 'load']),
    handleSearch() {
      if (this.prevSearch !== this.localSearchQuery) {
        // Update Vuex state
        this.$store.commit('studentsDirectory/update_search_query', this.localSearchQuery);
        this.debouncedSearch(this.localSearchQuery);
      }
    },
    openStudentFormDialog () {
      EventBus.$emit('open-student-form-dialog')
    },
    focusSearch() {
      this.$nextTick(() => {
        const searchInput = this.$refs.searchInput;
        const nativeInput = searchInput.$el.querySelector('input') || searchInput.$el;
        nativeInput.focus();
        nativeInput.select();
      });
    },
    blur() {
      this.$nextTick(() => {
        this.$refs.searchInput.blur();
      });
    }
  },
  computed: {
    ...mapGetters('studentsDirectory', ['filtersDataLoading', 'searchQuery', 'appliedFiltersCount'])
  },
  watch: {
    searchQuery(newQuery, oldQuery) {
      const searchClearedExternally = this.$store.state.studentsDirectory.searchClearedExternally;

      if (newQuery !== oldQuery && this.prevSearch !== newQuery  && !searchClearedExternally) {
        this.debouncedSearch(newQuery);
      }

      if (searchClearedExternally) {
        // Reset the flag
        this.$store.commit('studentsDirectory/set_search_cleared_externally', false);
      }
    }
  },
}
</script>

<template>
  <v-app-bar dense color="secondary" elevation="0">
    <v-btn icon dark class="" @click="openStudentFormDialog">
      <v-icon>mdi-account-plus</v-icon>
    </v-btn>
    <v-btn icon dark class="" @click="load()">
      <v-icon>mdi-reload</v-icon>
    </v-btn>
    <v-text-field
        dense
        hide-details
        flat
        rounded
        v-model="$store.state.studentsDirectory.filters.search"
        :placeholder="$t('form.search')"
        @keydown.esc="blur"
        solo
        prepend-inner-icon="mdi-account-search"
        clearable
        ref="searchInput"
    >
      <template v-slot:append>
        <v-icon @click.stop="searchInfoDialog = true" color="info">mdi-information-outline</v-icon>

        <v-dialog v-model="searchInfoDialog" width="500">
          <v-card>
            <v-card-title class="text-h5">
              {{ $t('studentsDirectory.searchInstructions.how_it_works') }}
            </v-card-title>

            <v-card-text>
              <p>{{ $t('studentsDirectory.searchInstructions.search_uses_these_fields') }}</p>
              <ul>
                <li
                  v-for="field in $t('studentsDirectory.searchInstructions.search_fields').toString().split('|')"
                  :key="field"
                >
                  {{ field }}
                </li>
              </ul>
              <p class="pt-2" v-html="htmlFromMarkdown($t('studentsDirectory.searchInstructions.full_name_hint'))" />
              <p class="pt-2" v-html="htmlFromMarkdown($t('studentsDirectory.searchInstructions.keyboard_shortcut'))" />
            </v-card-text>

            <v-card-actions>
              <v-btn text @click="searchInfoDialog = false" >
                {{ $t('btn.ok') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-text-field>
    <v-badge
      class="ml-1"
      color="primary"
      bordered
      overlap
      :content="appliedFiltersCount"
      :value="appliedFiltersCount"
      offset-y="25"
      offset-x="20"
    >
      <v-btn icon dark @click="openFiltersDialog" :disabled="filtersDataLoading" :loading="filtersDataLoading">
        <v-icon>mdi-account-filter</v-icon>
      </v-btn>
    </v-badge>
    <students-index-preferences-dialog />
    <students-directory-tutorial-dialog />
  </v-app-bar>

</template>

<style scoped>

</style>