import api from "@/utils/api";

export default {
  oneTimeProducts() {
    return new Promise((resolve, reject) => {
      api
        .get("one-time-products")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  lessonTypes() {
    return new Promise((resolve, reject) => {
      api
        .get("lesson-types")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadPrices(type, params) {
    return new Promise((resolve, reject) => {
      api
        .get("prices", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  savePrices(params) {
    return new Promise((resolve, reject) => {
      api
        .post("prices/save", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadPriceCategories() {
    return new Promise((resolve, reject) => {
      api
        .get("prices/categories")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  createPriceCategory(params) {
    return new Promise((resolve, reject) => {
      api
        .post("prices/categories", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  addLicensesToPriceCategory(priceCategoryId, params) {
    return new Promise((resolve, reject) => {
      api
        .post(`prices/categories/${priceCategoryId}/add-licenses`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  makeDefault(priceCategoryId) {
    return new Promise((resolve, reject) => {
      api
        .put("prices/categories/make-default/" + priceCategoryId)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  toggleActiveStatus(itemId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/prices/${itemId}/toggle-active-status`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updatePriceCategory(priceCategoryId, params) {
    return new Promise((resolve, reject) => {
      api
        .put("prices/categories/" + priceCategoryId, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  deletePriceCategory(priceCategoryId) {
    return new Promise((resolve, reject) => {
      api
        .delete("prices/categories/" + priceCategoryId)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
