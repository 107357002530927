<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <div class="mb-3">
      <span class="title">{{ $t("label.print-media") }}</span>
    </div>

    <v-row>
      <v-col
        cols="12"
        lg="4"
        md="6"
        v-for="item in printMediaProducts"
        :key="item.type">
        <PrintMediaProduct :item="item" />
      </v-col>
    </v-row>

    <div v-if="orders.length > 0" class="my-3">
      <span class="title">{{ $t("label.my_orders") }}</span>
    </div>

    <v-card
      v-for="order in orders"
      :key="order.id"
      class="mb-3 pa-3 d-flex justify-space-between align-center">
      <div>Bestellt am {{ order.date }}</div>
      <div>{{ order.price_final_price | currency }}</div>
      <v-btn color="primary" small @click="previewOrder(order.id)">{{ $t("label.open") }}</v-btn>
    </v-card>

    <v-dialog v-model="showOrder" persistent max-width="700">
      <v-card>
        <v-toolbar class="mb-2" dark color="primary" dense>
          <v-toolbar-title>{{ $t("label.order_on", { date: order.date }) }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closePreview"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-container>
          <div class="pa-3 mb-5">
            <v-row class="mb-5">
              <v-col cols="6">
                <h4 class="mb-2">Rechnungsadresse</h4>
                <div>{{ order.school_address_name }}</div>
                <div>
                  {{ order.school_address_first_name }}
                  {{ order.school_address_last_name }}
                </div>
                <div>
                  {{ order.school_address_street }}
                  {{ order.school_address_house_nr }}
                </div>
                <div>
                  {{ order.school_address_postcode }}
                  {{ order.school_address_city }}
                </div>
              </v-col>
              <v-col cols="6">
                <h4 class="mb-2">Lieferadresse</h4>
                <template v-if="order.delivery_address_status">
                  <div>{{ order.delivery_address_name }}</div>
                  <div>
                    {{ order.delivery_address_first_name }}
                    {{ order.delivery_address_last_name }}
                  </div>
                  <div>
                    {{ order.delivery_address_street }}
                    {{ order.delivery_address_house_nr }}
                  </div>
                  <div>
                    {{ order.delivery_address_postcode }}
                    {{ order.delivery_address_city }}
                  </div>
                </template>
                <div v-else>identisch</div>
              </v-col>
            </v-row>

            <h4 class="mb-2">Bestellung:</h4>
            <div v-if="order.flyers" class="d-flex justify-space-between">
              <div>Quickstartflyer</div>
              <div>0,00 €</div>
            </div>
            <div
              v-if="order.stickers_amount"
              class="d-flex justify-space-between">
              <div>Aufkleber {{ order.stickers_amount }} Stk.</div>
              <div>{{ order.price_stickers_price | currency }}</div>
            </div>
            <div class="d-flex justify-space-between mb-3">
              <div>Versandkosten</div>
              <div>{{ order.price_delivery_price | currency }}</div>
            </div>

            <div class="d-flex justify-space-between">
              <div>Gesamtbetrag netto:</div>
              <div>{{ order.price_nett_price | currency }}</div>
            </div>
            <div class="d-flex justify-space-between">
              <div>+ 19% MwSt.</div>
              <div>{{ order.price_tax | currency }}</div>
            </div>
            <div class="d-flex justify-space-between mt-3">
              <h4>Rechnungsbetrag.</h4>
              <h4>{{ order.price_final_price | currency }}</h4>
            </div>
          </div>
        </v-container>
        <v-divider />
        <v-card-actions class="py-3 px-7 d-flex justify-end">
          <v-btn dark color="primary" @click="closePreview">{{
            $t("btn.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import schoolService from "@/services/schoolService";
import PrintMediaModel from "@/store/models/PrintMediaModel";
import PrintMediaProduct from "@/components/print-media/PrintMediaProduct";
export default {
  name: "PrintMediaDialog",
  components: { PrintMediaProduct },
  data: () => ({
    showOrder: false,
    order: {},
    printMediaProducts: [
      {
        type: "flyer",
        downloadType: "flyers-pdf",
        downloadFileName: "Quickstart Flyer Muster.pdf",
        image: "print-media-flyer.png",
        label: "Quickstart Flyer",
        priceText:
          "Wir schicken Ihnen pro Bestellung ca. 250 Stk. Quickstartflyer.",
        text: "Bestellen Sie unseren 8-Seiter für den Schnellstart in drivolino bookandpay. Vom Login bis zur Bezahlung werden die wichtigsten Funktionen einfach und schnell für den Fahrschüler erklärt.",
        pricePer500: null,
        pricePer1000: null,
        previewLabel: " Muster anschauen",
        previewLink: "http://api.drivolino.de/pdf/flyers-pdf.pdf",
      },
      {
        type: "sticker",
        downloadType: "stickers-pdf",
        downloadFileName: "Individualisierte Aufkleber Muster.pdf",
        image: "print-media-sticker.png",
        label: "Individualisierte Aufkleber",
        priceText: "Die Aufkleber sind in folgenden Stückzahlen erhältlich:",
        text: "Für die Rückseite der Quickstart Flyer bieten wir Ihnen die Möglichkeit den direkten Registrierungslink in Form einen QR-Codes zu erstellen und diesen als Aufkleber fertigen zu lassen. Zusätzlich besteht die Möglichkeit Ihr Firmenlogo unterzubringen.",
        pricePer500: "500 Stk. – 14,00 €",
        pricePer1000: "1.000 Stk. – 18,00 €",
        previewLabel: " Beispiele anschauen",
        previewLink: "http://api.drivolino.de/pdf/stickers-pdf.pdf",
      },
    ],
  }),
  mounted() {
    schoolService
      .getPrintMedia()
      .then((response) => PrintMediaModel.create({ data: response.data }))
      .catch((error) => console.error(error));
  },
  computed: {
    orders() {
      return PrintMediaModel.all() || [];
    },
  },
  methods: {
    previewOrder(orderId) {
      this.showOrder = true;
      this.order = PrintMediaModel.find(orderId) || {};
    },
    closePreview() {
      this.order = {};
      this.showOrder = false;
    },
  },
};
</script>

<style scoped lang="scss">
.control-btn {
  width: 250px;
}
.flex-content {
  display: flex;
  flex-direction: column;
  div {
    flex-grow: 1;
  }
}
</style>
