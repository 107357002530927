<template>
<v-dialog
  :value="show"
  max-width="800"
  @click:outside="close"
  @keydown.esc="close"
  scrollable>
  <v-card>
    <v-toolbar class="mb-2" dark color="primary" dense>
      <v-toolbar-title>{{ $t("label.students") }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-list v-if="loading" :two-line="showActiveTeachers">
            <template v-for="(item, index) in Array.from(Array(3).keys())">
              <v-divider :key="item + '_divider'" inset v-if="index !== 0" />
              <v-skeleton-loader
                :key="item"
                type="list-item-avatar-two-line"
              />
            </template>
          </v-list>

          <v-list v-else :two-line="showActiveTeachers">
            <template v-for="(student, index) in students">
              <v-divider
                  v-if="index !== 0"
                  :key="student.id + '_divider'"
                  inset
              />

              <v-list-item
                  :class="showActiveRatio && !student.active ? `red lighten-5`: ''"
                  :key="student.id"
                  :to="{
                name: 'students.student',
                params: { id: student.id }
              }"
              >
                <v-list-item-avatar>
                  <UserAvatar :path="student.avatar" :alt="student.fullName" :size="avatarSize" />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ student.fullName }}</v-list-item-title>
                  <v-list-item-subtitle class="mt-1" v-if="showActiveTeachers">
                    {{ student.activeTeachersIds.length }}
                    {{ $t("label.active") + " " + $tc("teacher", student.activeTeachersIds.length) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>

          <empty-box v-if="!loading && students.length === 0"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import StudentModel from "@/store/models/StudentModel";
import studentsService from "@/services/studentsService";
import UserAvatar from '@/components/UserAvatar'

export default {
  name: "StudentsListDialog",
  components: { UserAvatar },
  props: {
    avatarSize: {
      type: [Number, String],
      default: 40
    },
    showActiveRatio: {
      type: Boolean,
      default: false
    },
    showActiveTeachers: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    show: false,
    studentsIds: [],
    loading: false,
  }),
  computed: {
    students() {
      const studentsQuery = StudentModel.query().whereIdIn(this.studentsIds)

      if (this.showActiveRatio) {
        studentsQuery.orderBy('active', 'desc')
      }

      return studentsQuery.get();
    },
  },
  methods: {
    open(studentsIds = []) {
      this.show = true;
      this.studentsIds = studentsIds;

      const loadedStudentsIds = StudentModel.query()
          .where(item => studentsIds.includes(item.id))
          .get()
          .map(item => item.id);

      const notLoadedStudentsIds = studentsIds.filter(studentsId => ! loadedStudentsIds.includes(studentsId))

      if (notLoadedStudentsIds.length > 0) {
        this.loading = true;
        studentsService
          .load({ studentsIds: notLoadedStudentsIds })
          .then(response => StudentModel.insert({ data: response.data || [] }))
          .finally(() => {
            this.loading = false;
          })
      }
    },
    close() {
      this.studentsIds = [];
      this.show = false;
    },
  },
};
</script>

<style scoped></style>
