<template>
  <WorkStatisticsGrid
      :class="!mdLandscapeAndUp ? 'scrollable' : ''"
      :worked-time-per-day="workedTimePerDay"
      :key="workedTimePerDay.length"/>
</template>

<script>
import WorkStatisticsGrid from '@/components/teacher/WorkStatisticsGrid'
import teachersService from '@/services/teachersService'
import responsive from '@/utils/mixins/responsive'

export default {
  name: 'WorkStatisticsTab',
  mixins: [responsive],
  props: {
    teacherId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    workedTimePerDay: [],
  }),
  components: { WorkStatisticsGrid },
  mounted () {
    teachersService
        .workedTime(this.teacherId)
        .then((response) => {
          this.workedTimePerDay = response.data || []
        })
        .catch((error) => console.log(error))
  },
}
</script>

<style scoped>
.scrollable{
  overflow-x: auto;
  width: calc(100vw - 70px);
}
</style>
