import router from "@/router";

export default class OpenChat {
  constructor(chatRoomId) {
    this.chatRoomId = chatRoomId;
  }

  run() {
    router.push({ name: "messenger", query: { r: this.chatRoomId } });
  }
}
