<template>
  <Page>
    <PageNavBar>
      <PageToolBar
        is-search
        :create-btn-name="$t('btn.new_teacher')"
        @search="debouncedSearch"
        @clickCreateItem="createItem">
        <template v-slot:filters-body>
          <TeacherListFilters @applyFilters="fetch"/>
        </template>
      </PageToolBar>
    </PageNavBar>

    <PageContent :loading="loadingPage">
      <PageView :items-length="items.length" :loading="loadingItems">
        <GridView>
          <GridItem
            v-for="item in items"
            :key="item.id"
            :item="item"
            :to="
              !item.deletedAt
                ? { name: 'teachers.teacher', params: { id: item.id } }
                : ''
            ">
            <template v-slot:content>
              <div class="d-flex justify-space-between align-self-start">
                <v-badge
                  class="d-flex align-center justify-center"
                  :color="item.color"
                  bottom
                  overlap
                  dot
                  bordered
                  avatar
                  offset-x="36"
                  offset-y="13"
                  size="20">
                  <UserAvatar class="mr-3" size="40" :path="item.avatar" :alt="item.fullName" />
                </v-badge>

                <div
                  class="d-flex flex-column"
                  :style="{ 'max-width': textTruncateContainerWidth }">
                  <div
                    class="d-flex text-subtitle-1 font-weight-medium text-truncate">
                    <span class="text-truncate">{{ item.fullName }}</span>
                  </div>
                  <div class="text-caption">{{ item.email }}</div>
                </div>
              </div>

              <v-chip-group
                v-if="item.licensesIds.length > 0"
                class="mt-2"
                mandatory>
                <v-chip
                  v-for="licenseId in item.licensesIds"
                  :key="licenseId"
                  small>
                  <span v-if="licensesById[licenseId]">{{
                    licensesById[licenseId].name
                  }}</span>
                </v-chip>
              </v-chip-group>

              <v-row no-gutters class="mt-3" v-if="isSchoolAdministrator">
                <v-col cols="9">
                  <StudentsLabel :students-ids="item.activeStudentsIds" />
                </v-col>
                <v-col class="d-flex justify-end" cols="3">
                  <v-switch
                    class="mt-0 v-input--reverse"
                    flat
                    inset
                    dense
                    hide-details
                    v-model="item.active"
                    @change="(value) => toggleActive(item, value)"
                    :loading="item.loading"
                    :disabled="loadingAction"
                    @click.stop
                  />
                </v-col>
              </v-row>
            </template>

            <template v-slot:actions>
              <v-list-item @click="showDialog(item)">
                <v-list-item-icon class="mr-2"><v-icon>mdi-file-document</v-icon></v-list-item-icon>
                <v-list-item-title>{{ $t("label.daily_practices") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="viewItem(item)">
                <v-list-item-icon class="mr-2"><v-icon>mdi-eye</v-icon></v-list-item-icon>
                <v-list-item-title>{{ $t("label.view") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="editItem(item)">
                <v-list-item-icon class="mr-2"><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                <v-list-item-title>{{ $t("btn.edit") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteItem(item)">
                <v-list-item-icon class="mr-2"><v-icon>mdi-delete</v-icon></v-list-item-icon>
                <v-list-item-title>{{ $t("btn.delete") }}</v-list-item-title>
              </v-list-item>
            </template>
          </GridItem>
        </GridView>

        <ListView>
          <template v-slot:header>
            <ListColumn :text="$t('list.name')" />
            <ListColumn :text="$t('list.licenses')" />
            <ListColumn :text="$t('list.students')" />
            <ListColumn width="80" :text="$t('list.active')" />
            <ListColumn width="80" :text="$t('list.actions')" />
          </template>

          <template v-slot:body>
            <ListRow
              v-for="item in items"
              :key="item.id"
              :item="item"
              @click="editItem(item)"
              :to="{ name: 'teachers.teacher', params: { id: item.id } }"
            >
              <ListColumn>
                <div class="d-flex">
                  <v-badge
                    :color="item.color"
                    bottom
                    overlap
                    dot
                    bordered
                    avatar
                    offset-x="36"
                    offset-y="9"
                    size="20">
                    <UserAvatar class="mr-3" size="40" :path="item.avatar" :alt="item.fullName" />
                  </v-badge>

                  <div class="d-flex">
                    <div class="d-inline-flex flex-column" style="top: -10px">
                      <span>{{ item.fullName }}</span>
                      <div class="text-caption">
                        {{ item.email }}
                      </div>
                    </div>
                  </div>
                </div>
              </ListColumn>
              <ListColumn
                :text="
                  item.licensesIds
                    .map(
                      (licenseId) =>
                        licensesById[licenseId] && licensesById[licenseId].name
                    )
                    .join(', ')
                " />
              <ListColumn @click.native.stop>
                <StudentsLabel :students-ids="item.activeStudentsIds" />
              </ListColumn>
              <ListColumn
                width="80"
                v-if="isSchoolAdministrator"
                class="text-center"
                @click.native.stop>
                <v-switch
                  class="mt-0"
                  flat
                  inset
                  dense
                  hide-details
                  v-model="item.active"
                  @change="(value) => toggleActive(item, value)"
                  :loading="item.loading"
                  :disabled="loadingAction" />
              </ListColumn>
              <ListColumn width="80" class="text-center" @click.native.stop>
                <RightClickMenu :item="item">
                  <template v-slot="{ showItemMenu }">
                    <v-icon
                      size="25"
                      @click="(e) => showItemMenu(e, item)"
                      :disabled="loadingAction"
                      >mdi-dots-vertical</v-icon
                    >
                  </template>

                  <template v-slot:menu>
                    <v-list dense>
                      <v-list-item @click="showDialog(item)">
                        <v-list-item-icon class="mr-2">
                          <v-icon>mdi-file-document</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t("label.daily_practices") }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="viewItem(item)">
                        <v-list-item-icon class="mr-2"><v-icon>mdi-eye</v-icon></v-list-item-icon>
                        <v-list-item-title>{{ $t("label.view") }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="editItem(item)">
                        <v-list-item-icon class="mr-2"><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                        <v-list-item-title>{{ $t("label.edit") }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="isSchoolAdministrator" @click="deleteItem(item)">
                        <v-list-item-icon class="mr-2"><v-icon>mdi-delete</v-icon></v-list-item-icon>
                        <v-list-item-title>{{ $t("label.delete") }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </template>
                </RightClickMenu>
              </ListColumn>
            </ListRow>
          </template>
        </ListView>
      </PageView>
    </PageContent>

    <TeacherFormDialog ref="formItem" />
    <TeacherDailyEvents ref="dailyEvents" :teacher="pickedTeacher" />

    <v-btn
      v-if="$vuetify.breakpoint.mobile"
      fixed
      right
      bottom
      fab
      small
      class="mr-2"
      color="primary"
      @click="createItem"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </Page>
</template>

<script>
import TeacherFormDialog from "@/components/teacher/TeacherFormDialog";
import TeacherDailyEvents from "@/components/teacher/TeacherDailyEvents";
import teachersService from "@/services/teachersService";
import licensesService from "@/services/licensesService";
import responsive from "@/utils/mixins/responsive";
import Page from "@/components/page/Page";
import PageNavBar from "@/components/page/PageNavBar";
import PageToolBar from "@/components/page/PageToolBar";
import TeacherListFilters from "@/components/teacher/TeacherListFilters";
import GridView from "@/components/grid/GridView";
import GridItem from "@/components/grid/GridItem";
import ListView from "@/components/list/ListView";
import ListRow from "@/components/list/ListRow";
import ListColumn from "@/components/list/ListColumn";
import TeacherModel from "@/store/models/TeacherModel";
import LicenseModel from "@/store/models/LicenseModel";
import licenses from "@/utils/mixins/licenses";
import gridElementsHeaderWidth from "@/utils/mixins/gridElementsHeaderWidth";
import StudentModel from "@/store/models/StudentModel";
import listFilters from "@/utils/mixins/listFilters";
import RightClickMenu from "@/components/RightClickMenu";
import StudentsLabel from "@/components/student/StudentsLabel";
import PageContent from "@/components/page/PageContent";
import PageView from "@/components/page/PageView";

import { debounce } from "throttle-debounce";
import UserAvatar from '@/components/UserAvatar'
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: "Teachers",
  components: {
    UserAvatar,
    PageView,
    PageContent,
    Page,
    StudentsLabel,
    RightClickMenu,
    TeacherDailyEvents,
    ListColumn,
    ListRow,
    ListView,
    GridItem,
    GridView,
    TeacherListFilters,
    PageToolBar,
    TeacherFormDialog,
    PageNavBar,
  },
  mixins: [
    responsive,
    licenses,
    gridElementsHeaderWidth,
    listFilters,
  ],
  data() {
    return {
      pickedTeacher: null,
      loadingPage: true,
      loadingItems: false,
      loadingAction: false,
      searchText: "",
      licenses: [],
      offices: [],
      vehicles: [],
      isApplyFilters: false,
      filters: {
        office: null,
        licenses: [],
      },
      isOpenFilters: false,
      initialFilters: {},
    };
  },
  computed: {
    items() {
      return TeacherModel.query()
        .all()
        .sort((prev, next) => {
          return new Date(prev.createdAt) - new Date(next.createdAt);
        })
        .sort((prev, next) => {
          return prev.active === next.active ? 0 : prev.active ? -1 : 1;
        });
    },
    selectedItems() {
      return StudentModel.query().where("checked", true).get();
    },
    debouncedSearch() {
      return debounce(300, this.onSearch);
    },
  },
  mounted() {
    licensesService
      .load()
      .then((response) => {
        LicenseModel.insert({ data: response.data || [] });
      })
      .catch((error) => console.log(error));

    this.fetch().finally(() => {
      this.loadingPage = false;
    });

    this.initialFilters = { ...this.filters };
  },
  methods: {
    showDialog(teacher) {
      this.pickedTeacher = teacher;

      this.$nextTick(() => {
        this.$refs.dailyEvents.open();
      })
    },
    toggleListView() {
      this.$store.commit("main/toggle-items-view");
    },
    fetch() {
      const params = { searchText: this.searchText };
      return teachersService
        .load(params)
        .then((response) => {
          TeacherModel.create({ data: response.data || {} });
        })
        .catch((error) => console.log(error));
    },
    viewItem(item) {
      this.$router.push({ name: "teachers.teacher", params: { id: item.id } });
    },
    onSearch(searchText) {
      this.searchText = searchText;

      this.loadingItems = true;
      this.fetch().finally(() => {
        this.loadingItems = false;
      });
    },
    createItem() {
      this.$refs.formItem.open();
    },
    editItem(item) {
      this.$refs.formItem.open(item);
    },
    deleteItem(item) {
      this.loadingAction = true;
      showConfirm(
        this.$t('btn.confirm'),
        this.$t('messages.are_your_sure_delete'),
        () => {
          teachersService
            .destroy(item.id)
            .then(() => this.fetch())
            .catch(console.log)
            .finally(() => {
              this.loadingAction = false;
            });
        },
        () => {
          this.loadingAction = false;
        }
      )
    },
    toggleActive(item, value) {
      const teacher = TeacherModel.find(item.id);
      TeacherModel.update({ where: teacher.id, data: { loading: true } });
      teachersService
        .makeActive(teacher.id, { active: value })
        .then(() => {
          TeacherModel.update({ where: teacher.id, data: { active: value } });
          if (!value) {
            const activeTeachers = JSON.parse(localStorage.getItem("activeTeachers"));
            const updatedActiveTeachers = activeTeachers.filter((teacherId) => teacherId !== item.id);
            localStorage.setItem("activeTeachers", JSON.stringify(updatedActiveTeachers));
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          TeacherModel.update({ where: teacher.id, data: { loading: false } });
        });
    },
    clearFilters() {
      this.isApplyFilters = false;
      this.isOpenFilters = false;
      this.filters = this.initialFilters;
    },
    applyFilters() {
      this.isApplyFilters = true;
      this.isOpenFilters = false;
      this.fetch();
    },
    onListCheckboxChange(isChecked) {
      TeacherModel.update({
        where: (student) => {
          return student.checked !== isChecked;
        },
        data: { checked: isChecked },
      });
    },
    openDeleteSelectedDialog() {
      this.$refs.deleteMultipleUsersDialog.open(this.selectedItems);
    },
    onCheckItem(item, isChecked) {
      TeacherModel.update({ where: item.id, data: { checked: isChecked } });
    },
  },
};
</script>

<style lang="scss"></style>
