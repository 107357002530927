import { Model } from "@vuex-orm/core";

export default class LicenseModel extends Model {
  static entity = "licenses";

  static fields() {
    return {
      id: this.attr(null),
      name: this.string(""),
      icon: this.string(""),
      order: this.number(0),
      price: this.string(""),
      groupId: this.attr(null),
      requiredMtl: this.attr(null),
      forPractice: this.boolean(false),
      forTheory: this.boolean(false),
      forTheoryExam: this.boolean(false),
      forSimulation: this.boolean(false)
    };
  }

  get isB197() {
    return this.name === "B197";
  }

  get hasExam() {
    return this.forTheoryExam || this.forPractice;
  }
}
