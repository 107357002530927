<template>
  <v-dialog
    :value="show"
    max-width="800"
    @click:outside="close"
    @keydown.esc="close"
    scrollable>
    <v-card>
      <v-toolbar class="mb-2" dark color="primary" dense>
        <v-toolbar-title>{{ $t("label.teachers") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-list v-if="loading" :two-line="showActiveStudents">
              <template v-for="(item, index) in Array.from(Array(2).keys())">
                <v-divider :key="item + '_divider'" inset v-if="index !== 0" />
                <v-skeleton-loader
                  :key="item"
                  type="list-item-avatar-two-line" />
              </template>
            </v-list>

            <v-list v-else :two-line="showActiveStudents">
              <template v-for="(teacher, index) in studentTeachers">
                <v-divider
                  :key="teacher.id + '_divider'"
                  inset
                  v-if="index !== 0"
                />

                <v-list-item
                  :class="showActiveRatio && !teacher.active ? `red lighten-5`: ''"
                  :key="teacher.id"
                  :to="{
                    name: 'teachers.teacher',
                    params: { id: teacher.id },
                  }">
                  <v-list-item-avatar>
                    <UserAvatar :path="teacher.avatar" :alt="teacher.fullName" :size="avatarSize"/>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ teacher.fullName }}</v-list-item-title>
                    <v-list-item-subtitle class="mt-1" v-if="showActiveStudents">
                      {{ teacher.activeStudentsIds.length }}
                      {{ $t("label.active") + " " + $tc("label.student_plurable", teacher.activeStudentsIds.length) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>

            <empty-box v-if="!loading && studentTeachers.length === 0"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import TeacherModel from "@/store/models/TeacherModel";
import teachersService from "@/services/teachersService";
import UserAvatar from '@/components/UserAvatar'

export default {
  name: "TeachersListDialog",
  components: { UserAvatar },
  props: {
    avatarSize: {
      type: [Number, String],
      default: 40
    },
    showActiveRatio: {
      type: Boolean,
      default: false
    },
    showActiveStudents: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    show: false,
    teachersIds: [],
    loading: false,
  }),
  computed: {
    studentTeachers() {
      const teachersQuery = TeacherModel.query().whereIdIn(this.teachersIds)

      if (this.showActiveRatio) {
        teachersQuery.orderBy('active', 'desc')
      }

      return teachersQuery.get();
    },
  },
  methods: {
    open(teachersIds = []) {
      this.show = true;
      this.teachersIds = teachersIds;

      const loadedTeachersId = TeacherModel.query()
          .where(item => teachersIds.includes(item.id))
          .get()
          .map(item => item.id);

      const notLoadedTeachersIds = teachersIds.filter(teachersId => ! loadedTeachersId.includes(teachersId))

      if (notLoadedTeachersIds.length > 0) {
        this.loading = true;
        teachersService
          .load({ teachers: notLoadedTeachersIds })
          .then(response => TeacherModel.insert({ data: response.data || [] }))
          .finally(() => {
            this.loading = false;
          })
      }
    },
    close() {
      this.teachersIds = [];
      this.show = false;
    },
  },
};
</script>

<style scoped></style>
