import AppointmentModel from "@/store/models/AppointmentModel";
import events from '@/const/appointmentEvents'
import laravelEcho from "@/plugins/laravel-echo";
import TeacherModel from '@/store/models/TeacherModel'
import { isWithinInterval, parseISO, addWeeks } from 'date-fns'

export default class CalendarWebsocketsHandlers {
    constructor(user, isSchoolAdministrator = false, isTeacher = false, isStudent = false) {
        this.isSchoolAdministrator = isSchoolAdministrator;
        this.isTeacher = isTeacher;
        this.isStudent = isStudent;
        this.currentUser = user;
        this.allEvents = Object.values(events);
    }

    startListening(events = []) {
        if (! events.length) events = this.allEvents;

        events.forEach(event => {
            if (this.allEvents.includes(event)) {
                const handler = `handle${event}`
                if (handler in this) {
                    laravelEcho
                        .private("school-channel." + this.currentUser.schoolId)
                        .listen(event, appointment => {
                            this[handler](appointment);
                        })
                } else {
                    console.error(`Unable to handle ${event} event, method ${handler} does not exist!`);
                }
            }
        })
    }

    _schoolAndTeacherBehavior(appointment) {
        if (this.isSchoolAdministrator) {
            this._insert(appointment);
        }

        if (this.isTeacher) {
            if (appointment.teacherId === this.currentUser.id) {
                this._insert(appointment);
            } else {
                this._delete(appointment);
            }
        }
    }

    handleAppointmentCreatedOrUpdated(appointment) {
        this._schoolAndTeacherBehavior(appointment)

        const studentsIds = ['THEORY', 'PLACEHOLDER'].includes(appointment.type) ?
            appointment.studentsIds.map(item => item.id) :
            appointment.studentsIds;

        if (this.isStudent) {
            const theoryRoomsLeft = ! appointment.roomSize || appointment.roomSize > appointment.studentsCount
            const isTheoryLesson = appointment.type === "THEORY" && appointment.group === 'lesson'
            const IsTheoryExam = appointment.type === "THEORY" && appointment.group === 'exam'
            const isSimulator = appointment.type === "SIMULATOR"
            const isPlaceholder = appointment.type === "PLACEHOLDER"
            const isSpecial = appointment.type === "SPECIAL"
            const isRequested = appointment.isRequested
            const eventWithStudentTeachers = this.currentUser.teachersIds.includes(appointment.teacherId)
            const studentRelatedEvent = studentsIds?.includes(this.currentUser.id)

            const nonRelatedPretestSpecial = isSpecial && appointment.specialType === 'PRETEST' && !studentRelatedEvent
            const standardOfficeSpecials = isSpecial && ['STANDARD', 'OFFICE'].includes(appointment.specialType)

            if (((['OFFTIME', 'PRACTICE'].includes(appointment.type) || standardOfficeSpecials) && !eventWithStudentTeachers) || nonRelatedPretestSpecial) {
                this._delete(appointment)
                return
            }

            if(isTheoryLesson || isPlaceholder) {
                const isWithinAllowedLicenses = this.currentUser.licensesIds.some(studentLicense =>
                  appointment.allowedLicenses.includes(studentLicense))

                /* === PLACEHOLDER & THEORY match allowed licenses === */
                const licenseIsWithinPeriod = !appointment.allowedLicenses.length ||
                  (appointment.allowedLicenses.length && isWithinAllowedLicenses)
                /* === PLACEHOLDER & THEORY math allowed licenses === */

                /* === PLACEHOLDER are within allowed period === */
                const teachersBookingLimit = TeacherModel.find(appointment.teacherId).bookingLimitMax
                const checkIfIsWithin = isWithinInterval(parseISO(appointment.start),
                  {
                      start: new Date(),
                      end: addWeeks(new Date(), teachersBookingLimit)
                  })
                const isWithinTeacherPeriod = teachersBookingLimit === 0 ||
                  (teachersBookingLimit > 0 && checkIfIsWithin)
                /* === PLACEHOLDER are within allowed period === */

                if (isPlaceholder && (!licenseIsWithinPeriod || !isWithinTeacherPeriod || !eventWithStudentTeachers)) {
                    this._delete(appointment)
                    return
                }
                if (isTheoryLesson && theoryRoomsLeft) {
                    if (isWithinAllowedLicenses) {
                        this._insert({ ...appointment, isSubscribed: isTheoryLesson && studentRelatedEvent });
                    } else {
                        this._delete(appointment);
                    }
                } else if ((isPlaceholder && eventWithStudentTeachers && !isRequested) || studentRelatedEvent) {
                    this._insert({ ...appointment, isRequested: isPlaceholder && studentRelatedEvent });
                }
            } else if (isSimulator || IsTheoryExam) {
                const validSimulator = isSimulator && (!appointment.studentsIds.length || studentRelatedEvent)
                const validExam = IsTheoryExam && studentRelatedEvent

                if (validSimulator || validExam) {
                    this._insert({...appointment, isSubscribed: studentRelatedEvent });
                }
            } else {
                this._insert(appointment);
            }

        }
    }

    handlePlaceholderOrPracticeConfirmed(appointment) {
        this._schoolAndTeacherBehavior(appointment)

        if (this.isStudent) {
            const eventWithStudentTeachers = this.currentUser.teachersIds.includes(appointment.teacherId);
            const studentRelatedEvent = appointment.studentsIds?.includes(this.currentUser.id);

            if (eventWithStudentTeachers || studentRelatedEvent) {
                this._insert(appointment);
            }
        }
    }

    handleAppointmentCreated(appointment) {
        this.handleAppointmentCreatedOrUpdated(appointment);
    }

    handleAppointmentUpdated(appointment) {
        this.handleAppointmentCreatedOrUpdated(appointment);
    }

    handleAppointmentDeleted(appointment) {
        this._delete(appointment)
    }

    handleAppointmentRestored(appointment) {
        this.handleAppointmentCreatedOrUpdated(appointment)
    }

    handlePlaceholderRequested(appointment) {
        this._schoolAndTeacherBehavior(appointment)

        if (this.isStudent) {
            const studentsIds = appointment.studentsIds.map(item => item.id)
            const isForStudent = studentsIds.includes(this.currentUser.id)
            if (isForStudent) {
                this._insert(appointment)
            } else if (! appointment.hasMultipleRequestsAllowed) {
                this._delete(appointment);
            } else {
                this._insert({...appointment, isRequested: isForStudent})
            }
        }
    }

    handlePlaceholderConfirmed(appointment) {
        this.handlePlaceholderOrPracticeConfirmed(appointment)
    }

    handlePlaceholderDeclined(appointment) {
        const studentsIds = appointment.studentsIds.map(item => item.id)

        this._schoolAndTeacherBehavior({ ...appointment, isRequested: studentsIds.length > 0 });

        if (this.isStudent) {
            const isForStudent = studentsIds.includes(this.currentUser.id)
            const eventWithStudentTeachers = this.currentUser.teachersIds.includes(appointment.teacherId);

            if (eventWithStudentTeachers || isForStudent) {
                this._insert({ ...appointment, isRequested: isForStudent })
            }
        }
    }

    handlePracticeRequestConfirmed(appointment) {
        this.handlePlaceholderOrPracticeConfirmed(appointment)
    }

    handlePracticeRequestDeclined(appointment) {
        this._delete(appointment);
    }

    handleTheoryParticipantAdded(appointment) {
        this._schoolAndTeacherBehavior(appointment)

        if (this.isStudent) {
            const studentsIds = appointment.studentsIds.map(item => item.id)

            const isForStudent = studentsIds.includes(this.currentUser.id);
            const hasRoomLeft = !appointment.roomSize || appointment.roomSize > appointment.studentsCount
            if (isForStudent || hasRoomLeft) {
                this._insert({ ...appointment, isSubscribed: isForStudent })
            } else {
                this._delete(appointment)
            }
        }
    }

    handleTheoryParticipantRemoved(appointment){
        this._insert(appointment);
    }

    handleSimulatorSubscribed(appointment){
        const isForStudent = this.isStudent && appointment.studentsIds.includes(this.currentUser.id);

        if (this.isSchoolAdministrator || isForStudent) {
            this._insert(appointment);
        } else {
            this._delete(appointment);
        }
    }

    handleSimulatorUnsubscribed(appointment){
        if (this.isSchoolAdministrator || this.isStudent) {
            this._insert(appointment);
        }
    }

    _insert(appointment) {
        AppointmentModel.insert({ data: appointment })
    }

    _delete(appointment) {
        AppointmentModel.delete(appointment.id)
    }
}
