<script>
export default {
  name: "StudentLessonPrices",
  props: {
    student: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      pricesData: {},
    }
  },
  mounted() {
    this.pricesData = this.student.lessonPrices;
  },
  methods: {
    sortLessonTypes(a, b) {
      const order = [
        'PRACTICE:normal',
        'PRACTICE:special',
        'PRACTICE:instructions',
        'PRACTICE:exam',
        'PRACTICE:mtl_exam',
        'SIMULATOR:simulator',
        'THEORY:exam',
      ];
      let orderA = `${a.event_type_id}:${a.group}`;
      let orderB = `${b.event_type_id}:${b.group}`;

      return order.indexOf(orderA) - order.indexOf(orderB);
    },
    getLicenseName(licenseId) {
      // Get the license name based on the licenseId
      return this.licenses.find(l => l.id === licenseId)?.name || 'Unknown';
    },
    getPrice(prices, heading) {
      // Find the price for the given heading
      let priceItem = prices.find(p => p.name === heading);
      return priceItem ? priceItem.value : null;
    },
    getLessonPrice(lessonTypes, licenseId) {
      return lessonTypes[licenseId] || null;
    },
  },
  computed: {
    organizedData() {
      let organized = {};
      Object.keys(this.pricesData).forEach(licenseId => {
        this.pricesData[licenseId].forEach(price => {
          if (!organized[price.name]) {
            organized[price.name] = {};
          }
          organized[price.name][licenseId] = price.value;
        });
      });
      return organized;
    },
    licenses() {
      return this.student.licenses.map(license => {
        return {id: license.id, name: license.name}
      })
    },
    tableHeadings() {
      // Collect all unique headings
      let headings = new Set();
      Object.values(this.pricesData).forEach(group => {
        group.forEach(price => {
          headings.add(price.name);
        });
      });
      return Array.from(headings).sort(this.sortLessonTypes);
    },
    sortedPrices() {
      // Sort and organize your prices data as needed
      let sorted = {};
      Object.keys(this.pricesData).forEach(licenseId => {
        sorted[licenseId] = this.pricesData[licenseId].sort(this.sortLessonTypes);
      });
      return sorted;
    }
  }
}
</script>

<template>
  <div>
    <v-simple-table dense v-if="Object.keys(organizedData).length">
      <thead>
      <tr>
        <th>Lesson Type</th>
        <th v-for="license in licenses" :key="license.id">{{ license.name }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(lessonTypes, lessonTypeName) in organizedData" :key="lessonTypeName">
        <td>{{ $tc(lessonTypeName, 1) }}</td>
        <td v-for="license in licenses" :key="license.id">
          <span v-if="getLessonPrice(lessonTypes, license.id)">
            {{ getLessonPrice(lessonTypes, license.id) | currency }}
          </span>
          <span v-else>-</span>
        </td>
      </tr>
      </tbody>
    </v-simple-table>
    <empty-box v-else />
  </div>
</template>

<style scoped>

</style>