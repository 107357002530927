import Vue from "vue";

Vue.filter("camlCaseToSentence", (value) => {
  if (!value) return "";

  value = value.toString();
  value = value.replace(/([A-Z])/g, " $1").toLowerCase();

  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("capitalize", (value) => {
  if (typeof value !== "string") return "";

  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
});
