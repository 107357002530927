<template>
  <v-row>
    <v-col cols="12" lg="6" class="px-1">
      <EducationTheoryProgress :licenseId="licenseId" :requirements="licenseRequirements" />
    </v-col>
    <v-col cols="12" lg="6" class="px-1">
      <EducationPracticeProgress :licenseId="licenseId" :requirements="licenseRequirements" />
      <EducationProgressArea :licenseId="licenseId" :student-id="studentId" :requirements="licenseRequirements" />
    </v-col>
  </v-row>
</template>

<script>
import EducationTheoryProgress from "@/components/student/progress/EducationTheoryProgress";
import EducationPracticeProgress from "@/components/student/progress/EducationPracticeProgress";
import EducationProgressArea from "@/components/student/progress/EducationProgressArea";
import StudentModel from '@/store/models/StudentModel'
import LicenseRequirementModel from '@/store/models/LicenseRequirementModel'

export default {
  name: "EducationLicenseProgress",
  components: {
    EducationTheoryProgress,
    EducationPracticeProgress,
    EducationProgressArea,
  },
  props: {
    studentId: {
      type: [Number, String],
      required: true,
    },
    licenseId: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    licenseRequirements() {
      const student = StudentModel.find(this.studentId)
      const requirementsByLicense = student.licensesRequirements.find(item => item.licenseId === this.licenseId)
      return LicenseRequirementModel.find(requirementsByLicense.licenseRequirementId) || {}
    }
  }
};
</script>

<style scoped></style>
