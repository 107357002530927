<template>
  <v-chip x-small :outlined="outlined" label class="px-1" :color="progressColor" :dark="!outlined">
    <div :class="outlined ? `${progressColor}--text` : ''" style="font-size: 0.7rem">
      {{ index }}:{{ label }}
    </div>
  </v-chip>
</template>

<script>
export default {
  name: "LicenseProgressItem",
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    index: {
      type: String,
      required: true,
    },
    valueIs: {
      type: Number,
      default: 0,
    },
    valueShould: {
      type: Number,
      default: 0,
    },
    singleValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    label() {
      if (this.singleValue) return this.valueIs
      return this.valueShould ? `${this.valueIs}/${this.valueShould}` : '—'
    },
    progressColor() {
      if (!this.valueShould) return "grey";
      if (this.valueIs > 0 && this.valueIs < this.valueShould) return "orange";
      if (this.valueIs >= this.valueShould) return "green";
      return "red";
    }
  },
};
</script>
