import api from "@/utils/api";

export default {
  load(params) {
    return new Promise((resolve, reject) => {
      api
        .get("patch-notes", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  markAsSeen(versionsIds) {
    return new Promise((resolve, reject) => {
      api
        .post("patch-notes", { versionsIds })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getLatestVersion(params) {
    return new Promise((resolve, reject) => {
      api
        .get("latest-version", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  }
}