import router from "@/router";
import Notification from "@/notifications/notification";
import store from "@/store";

export default class StudentDocumentUploaded extends Notification {
  icon() {
    return "mdi-file-upload-outline";
  }

  action() {
    const data = this.notification.data;
    const currentUser = store.getters["auth/currentUser"];

    let route = { name: "student.documents" };
    if (currentUser && currentUser.type === "school-administrator") {
      route = { name: "students.student.documents", params: { id: data.studentId } };
    }

    return router.push(route).catch((error) => console.log(error));
  }

  wildCardsData() {
    const data = this.notification.data;

    return {
      message: {
        docTitle: data.docTitle,
        studentName: data.studentName,
      },
    };
  }
}
