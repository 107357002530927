<template>
  <div>
    <v-row>
      <v-col v-if="!isTeacher">
        <TeachersField
          @emitTeachersSelect="(val) => updateFields({ teacherId: val })"
          :disabled="!!createdPlaceholderApp"
          :validation="true"
          :filteredTeachers="teachers"
          :teacherProp="currentAppointment.teacherId" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <NotesField
          @emitNotesChange="(val) => updateFields({ notes: val })"
          :disabled="teacherIsNotSelected"
          :validation="false"
          :notesProp="currentAppointment.notes" />
      </v-col>
    </v-row>
    <CopyAppointment
      @emitSetCopiedAmount="(val) => updateFields({ copyForNrOfWeeks: val })"
      :disabled="teacherIsNotSelected"
      :weeksAmount="25"
      :copyNrProp="currentAppointment.copyForNrOfWeeks" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import user from '@/utils/mixins/user'

import TeachersField from "@/components/calendar/appointmentModal/fields/TeachersField";
import NotesField from "@/components/calendar/appointmentModal/fields/NotesField";
import CopyAppointment from "@/components/calendar/appointmentModal/fields/CopyAppointment";

export default {
  name: "OfftimeForm",
  mixins: [user],
  components: { TeachersField, NotesField, CopyAppointment },
  props: {
    teachers: {
      type: Array,
      required: true,
    },
    createdPlaceholderApp: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters("appointments", {
      currentAppointment: "currentAppointmentGetter",
    }),
    teacherIsNotSelected() {
      return !this.currentAppointment.teacherId;
    },
  },
  methods: {
    updateFields(value) {
      this.$store.commit(`appointments/UPDATE_APPOINTMENT_FIELD`, value);
    },
  },
};
</script>
