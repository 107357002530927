<template>
<v-avatar :left="left" :size="size" :style="styles" rounded>
  <v-img v-if="path" :src="path" :alt="alt" :contain="contain" eager />
  <v-icon v-else :size="size">mdi-car</v-icon>
</v-avatar>
</template>

<script>
export default {
  name: 'VehicleAvatar',
  props: {
    path: {
      type: [String, null],
      default: null
    },
    size: {
      type: [Number, String],
      default: 40,
    },
    alt: {
      type: String,
      default: '',
    },
    left: {
      type: Boolean,
      default: false
    },
    contain: {
      type: Boolean,
      default: false
    },
    showColor: {
      type: Boolean,
      default: false
    },
    color: {
      type: String
    }
  },
  computed: {
    styles() {
      return this.showColor ? {'border-left': `4px solid ${this.color}`} : {}
    }
  }
}
</script>

<style scoped>

</style>
