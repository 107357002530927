import Notification from "@/notifications/notification";
import router from "@/router";

export default class StudentNegativeBalanceReminder extends Notification {
  icon() {
    return "mdi-cash-minus";
  }

  action() {
    router.push({ name: "balance", query: {prefill: 'true', reason: 'negative_balance_notification'} });
  }
}