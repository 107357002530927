import api  from "@/utils/api";

export default {
  save: (data) => {
    return new Promise((resolve, reject) => {
      api
        .post("/privacy-policy", data)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  }
}