<template>
  <v-row
    :class="{ 'mt-5': !$vuetify.breakpoint.xsOnly }"
    no-gutters
    justify="center">
    <v-col cols="12" sm="8" md="8">
      <ValidationObserver ref="form">
        <v-stepper v-model="registrationStep" style="height: 100%">
          <v-stepper-header>
            <v-stepper-step
              step="1"
              :complete="registrationSteps.school.passed"
              :rules="[
                () => !Object.keys(registrationSteps.school.errors).length,
              ]">
              {{ $t("label.school") }}
            </v-stepper-step>

            <v-stepper-step
              step="2"
              :complete="registrationSteps.personalInfo.passed"
              :rules="[
                () =>
                  !Object.keys(registrationSteps.personalInfo.errors).length,
              ]">
              {{ $t("label.personal_info") }}
            </v-stepper-step>

            <v-stepper-step
              step="3"
              :complete="registrationSteps.profile.passed"
              :rules="[
                () => !Object.keys(registrationSteps.profile.errors).length,
              ]">
              {{ $t("label.profile") }}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-content step="1">
            <RegistrationStepSchool
              @beforeSubmit="onBeforeStepSubmit"
              @submitted="onStepSubmitted" />
          </v-stepper-content>

          <v-stepper-content step="2">
            <RegistrationStepPersonalInfo
              :form="form"
              @beforeSubmit="onBeforeStepSubmit"
              @submitted="onStepSubmitted"
              @changeStep="changeStep" />
          </v-stepper-content>

          <v-stepper-content step="3">
            <RegistrationStepProfile
              :form="form"
              @beforeSubmit="onBeforeStepSubmit"
              @submitted="onStepSubmitted"
              @changeStep="changeStep" />
          </v-stepper-content>
        </v-stepper>
      </ValidationObserver>
    </v-col>
  </v-row>
</template>

<script>
import RegistrationStepSchool from "@/components/registration/RegistrationStepSchool";
import RegistrationStepProfile from "@/components/registration/RegistrationStepProfile";
import RegistrationStepPersonalInfo from "@/components/registration/RegistrationStepPersonalInfo";

export default {
  name: "Registration",
  components: {
    RegistrationStepPersonalInfo,
    RegistrationStepProfile,
    RegistrationStepSchool,
  },
  data: () => ({
    registrationStep: 1,
    registrationSteps: {
      school: {
        errors: [],
        passed: false,
        responseMessage: "",
      },
      personalInfo: {
        errors: [],
        passed: false,
        responseMessage: "",
      },
      profile: {
        errors: [],
        passed: false,
        responseMessage: "",
      },
    },
    form: {},
  }),
  methods: {
    onBeforeStepSubmit() {
      Object.keys(this.registrationSteps).forEach((step) => {
        this.registrationSteps[step] = {
          ...this.registrationSteps[step],
          errors: [],
        };
      });
      this.$refs.form.reset();
    },
    onStepSubmitted(step, data) {
      this.$set(this.registrationSteps[step], "errors", data.errors);
      this.$set(this.registrationSteps[step], "passed", data.passed);

      this.form = { ...data.form, ...this.form };

      if (data.errors) {
        const errors = data.errors || [];
        const responseMessage = data.responseMessage || "";

        if (Object.keys(errors).length === 0 && errors.message)
          this.$snackbar.show({ message: responseMessage, color: "error" });

        Object.keys(errors).forEach((fieldName) =>
          this.$refs.form.setErrors({ [fieldName]: errors[fieldName] })
        );
      }

      if (data.passed) this.changeStep(this.stepIndexByName(step) + 1);
    },
    stepIndexByName(name) {
      return (
        Object.keys(this.registrationSteps).findIndex((item) => item === name) +
        1
      );
    },
    changeStep(step) {
      this.registrationStep = step;
    },
  },
};
</script>

<style scoped lang="scss"></style>
