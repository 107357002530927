<template>
<div>
  <span class="text-subtitle-1 font-weight-medium">{{ title }}</span>

  <v-divider class="mb-3" />

  <slot/>
</div>
</template>

<script>
export default {
  name: 'FieldsGroup',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>