<script>
export default {
  name: "FailedExamsListDialog"
}
</script>

<template>
<div></div>
</template>

<style scoped>

</style>
