<template>
  <v-card
    elevation="0"
    class="appointment-card"
    :class="{
          'in-progress' : appointment.inProgress,
          'evaluated': appointment.evaluation?.id && appointment.isPast,
          'draft': appointment.evaluation?.id && !appointment.evaluation.isPublished,
          'published': appointment.evaluation?.id && appointment.evaluation.isPublished
    }"
    :style="styles"
  >
    <div class="d-flex justify-space-between" style="z-index: 2">
      <div class="appointment-card__info">
        <div class="flex-time">
          <div>
            {{ appointment.date }} &middot; {{ appointment.startTime }} — {{ appointment.endTime }}
            <div class="dot" v-if="appointment.inProgress"/>
          </div>
        </div>

        <div v-if="appointment.teacherName && !isTeacher">
          <span class="font-weight-medium">{{ appointment.teacherName }}</span>
        </div>

        <span v-if="appointment.license">{{ appointment.license.name }} &middot; </span>
        <span v-if="appointment.lessonType">{{ $tc(appointment.lessonType.name) }}</span>
        <span v-if="showStudentName" class="ml-1">({{ student.fullName }})</span>

        <component :is="types[appointment.type]" :appointment="appointment"/>

        <div class="text-truncate mr-10" v-if="appointment.notes">
          {{ $t("label.notes") + ": " + appointment.notes }}
        </div>
      </div>

      <div class="d-flex flex-column justify-space-between align-end">
        <div>
          <span>{{ appointment.duration }}</span>
          <v-icon class="ml-1" size="small">mdi-clock</v-icon>
        </div>

        <div v-if="showStudentList">
          <span>{{ appointment.studentsIds.length }}</span>
          <v-icon class="ml-1 icon" size="15">mdi-account-multiple</v-icon>
        </div>

        <v-icon v-if="showPracticeRequestMark" size="small">mdi-help-circle</v-icon>

        <v-icon v-if="showMissedIcon" class="ml-1 icon" size="small">
          {{ ['MISSED_PARTIALLY_REFUNDED', 'MISSED_REFUNDED', 'JOKER_REFUNDED'].includes(appointment.status) ? 'mdi-cash-refund' : 'mdi-call-missed' }}
        </v-icon>
      </div>
    </div>
  </v-card>
</template>

<script>
import PracticeCard from "@/components/appointment/cards/PracticeCard";
import TheoryCard from "@/components/appointment/cards/TheoryCard";
import SimulatorCard from "@/components/appointment/cards/SimulatorCard";
import SpecialCard from "@/components/appointment/cards/SpecialCard";
import user from "@/utils/mixins/user";
import StudentModel from "@/store/models/StudentModel";
import PlaceholderCard from "@/components/appointment/cards/PlaceholderCard";
import OfftimeCard from "@/components/appointment/cards/OfftimeCard";

export default {
  name: "AppointmentCard",
  components: {PlaceholderCard, PracticeCard, TheoryCard, SimulatorCard, SpecialCard},
  mixins: [user],
  props: {
    appointment: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      types: {
        PRACTICE: PracticeCard,
        THEORY: TheoryCard,
        SIMULATOR: SimulatorCard,
        SPECIAL: SpecialCard,
        PLACEHOLDER: PlaceholderCard,
        OFFTIME: OfftimeCard,
      },
    }
  },
  computed: {
    showStudentName() {
      return this.student &&
        (this.appointment.isPractice || this.appointment.isSimulator) &&
        !this.isStudentAgenda && !this.isStudentDashboard
    },
    showStudentList() {
      return !this.isStudent && !this.appointment.isPractice && !this.appointment.isSimulator
    },
    showMissedIcon() {
      return !this.isStudent && this.appointment.type === 'PRACTICE'
        && (['MISSED', 'MISSED_PARTIALLY_REFUNDED', 'MISSED_REFUNDED', 'JOKER_REFUNDED'].includes(this.appointment.status));
    },
    isStudentAgenda() {
      return this.$route.name === "students.student.agenda"
    },
    isStudentDashboard() {
      return this.$route.name === "dashboard" && this.isStudent
    },
    student() {
      if (this.appointment.studentsIds.length === 0) return null;
      return StudentModel.find(this.appointment.studentsIds[0]);
    },
    showPracticeRequestMark() {
      return this.appointment.isPractice && this.appointment.status === 'REQUESTED'
    },
    styles() {
      return {
        'border-left': `10px solid ${this.appointment.bodyColor}`
      }
    }
  }
}
</script>

<style scoped lang="scss">
.appointment-card {
  padding: 0 5px 5px 5px;
  margin: 5px 0;
  font-size: 0.7rem;
  background: #fff !important;
  border-right: 1px solid #eee;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  position: relative;
  cursor: pointer;

  &__info {
    min-width: 0;
  }

  .flex-time {
    display: flex;
    justify-content: space-between;
  }

  & > * {
    pointer-events: none;
    font-size: 12px;
  }

  .request-mark {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  .vehicles {
    width: 100%;
  }

  .meeting-place {
    min-width: 0;
  }

  &.in-progress {
    background-color: rgba(86, 171, 47, 0.1) !important;
  }

  .students-list {
    position: absolute;
    right: 5px;
    bottom: 0;
  }

  &.evaluated {
    &.draft {
      background-image: url('../../assets/img/evaluation_draft.png') !important;
    }

    &.published {
      background-image: url('../../assets/img/evaluation_published.png') !important;
    }

    background-repeat: no-repeat !important;
    background-position: right bottom !important;
    background-size: 24px 24px !important;
    background-origin: content-box !important;
  }
}

.dot {
  width: 10px;
  height: 10px;
  border: 1px solid rgb(97, 214, 109);
  background-color: rgba(97, 214, 109, 0.5);
  border-radius: 100%;
  animation: pulsate 1.5s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.5;
  display: inline-block;
}

@keyframes pulsate {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    opacity: 0.5;
  }
}
</style>
