<template>
  <div>
    <v-progress-circular
      class="d-flex ma-auto mt-10"
      v-if="loading"
      style="min-height: 200px"
      color="primary"
      indeterminate />

    <div v-else>
      <v-card-title>
        {{ $t("route.schoolSettings.children.licensePrice") }}
      </v-card-title>

      <v-card-text>
        <ValidationObserver ref="form">
          <div class="mb-1">
            <v-row>
              <v-col
                cols="12"
                md="4"
                lg="2"
                class="ma-4"
                v-for="group in licensesGroups"
                :key="group.id">
                <div class="mb-2 text-subtitle-1 font-weight-medium">
                  {{ group.name }}
                </div>

                <v-row class="d-flex">
                  <v-col
                    cols="12"
                    class="d-flex"
                    v-for="license in licensesByGroup[group.id]"
                    :key="license.id"
                  >

                    <LicenseIcon
                      class="mr-2"
                      v-if="license.id"
                      :name="license.name"
                      :icon="license.icon"
                      width="50"
                      height="50"
                    />

                    <div>
                      <ValidationProvider
                        v-slot="{ errors }"
                        :name="'licensesPrices.' + license.id"
                        class="flex-grow-1"
                        rules="required">
                        <v-text-field
                          placeholder="0,00"
                          outlined
                          dense
                          @input="
                            (event) => updateLicensePrice(event, license.id)
                          "
                          :value="license.price"
                          suffix="€"
                          :error="!!errors.length"
                          v-currency="{ currency: null }"
                          hide-details />
                      </ValidationProvider>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </ValidationObserver>
      </v-card-text>

      <v-card-actions class="pt-0 px-4 pb-4">
        <v-btn
          color="primary"
          @click="save"
          :loading="formLoading"
          :disabled="formLoading"
          >{{ $t("btn.save") }}</v-btn
        >
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import licensesService from "@/services/licensesService";
import schoolService from "@/services/schoolService";
import LicenseModel from "@/store/models/LicenseModel";
import licenses from "@/utils/mixins/licenses";
import currency from "@/utils/mixins/currency";
import LicenseIcon from "@/components/LicenseIcon";

export default {
  name: "LicensePrice",
  components: { LicenseIcon },
  mixins: [licenses, currency],
  data: () => ({
    loading: false,
    formLoading: false,
    licenses: [],
    licensesGroups: [],
    licensesPrices: {},
    form: {
      licensesIds: [],
      licensesPrices: {},
    },
  }),
  computed: {
    licensesByGroup() {
      const byGroup = {};

      this.licensesGroups.forEach((group) => {
        const licensesByGroup = LicenseModel.query()
          .where("groupId", group.id)
          .get();

        if (licensesByGroup.length !== 0) {
          if (!(group.id in byGroup)) byGroup[group.id] = [];

          byGroup[group.id] = LicenseModel.query()
            .where("groupId", group.id)
            .get();
        }
      });

      return byGroup;
    },
  },
  async mounted() {
    this.loading = true;

    try {
      const responseLicensesGroups = await licensesService.groups();
      this.licensesGroups = responseLicensesGroups.data || [];

      const responseLicenses = await licensesService.load();
      const responsesLicensesData = responseLicenses.data || [];
      responsesLicensesData.map((license) => {
        license.price = this.sanitizePrice(license.price, ",");
        return license;
      });
      await LicenseModel.insert({ data: responsesLicensesData });

      this.form.licensesIds = LicenseModel.all().map((item) => {
        return item.id;
      });
      this.form.licensesPrices = { ...this.licensesPrices };
    } catch (e) {
      console.log(e);
    }

    this.loading = false;
  },
  methods: {
    async save() {
      const response = await this.$refs.form.validate();

      if (response) {
        this.formLoading = true;
        schoolService
          .updateLicensesPrices(this.form)
          .then(() => {
            this.$snackbar.show({
              message: this.$t("messages.successfully_sent"),
              color: "success",
              timeout: 2000,
            });
          })
          .catch((error) => {
            const responseData = error.response.data;
            const errors = responseData.errors || [];

            Object.keys(errors).forEach((fieldName) =>
              this.$refs.form.setErrors({ [fieldName]: errors[fieldName] })
            );
          })
          .finally(() => {
            this.formLoading = false;
          });
      }
    },
    updateLicensePrice(price, licenseId) {
      this.licensesPrices[licenseId] = price;
      this.form.licensesPrices[licenseId] = this.sanitizePrice(price);
    },
  },
};
</script>

<style scoped></style>
