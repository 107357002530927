<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'school-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>Covid19</v-card-title>

      <v-spacer/>

      <v-btn :disabled="loading || saving" :loading="saving" text color="primary" @click="submit">
        {{ $t("btn.save") }}
      </v-btn>
    </v-toolbar>

    <v-progress-circular
        class="d-flex ma-auto mt-10"
        v-if="loading"
        style="min-height: 200px"
        color="primary"
        indeterminate
    />

    <v-card-text v-else>
      <p>
        Sie können alle Fahrschüler über die aktuellen Covid19 Maßnahmen informieren,
        die in Ihrer Region angewandt werden müssen.
      </p>
      <div class="d-flex justify-end">
        <v-btn
            :loading="loading"
            v-if="this.covidStatusGetter"
            small
            color="red"
            style="color: #fff"
            class="d-flex"
            @click="clear">
          {{ $t("btn.clear") }}
          <v-icon small class="ml-2">mdi-close</v-icon>
        </v-btn>
      </div>

      <v-radio-group v-model="statusId">
        <CovidStatus
            v-for="status in statuses"
            :key="status.id"
            :status="status"
            @changeStatus="changed" />
      </v-radio-group>
      <h4>Zusatzinformationen (optional)</h4>
      <v-textarea v-model="message" solo hide-details dense />
    </v-card-text>
  </div>
</template>

<script>
import CovidStatus from "@/components/covid2019/CovidStatus";
import covidStatusService from "@/services/covidStatusService";
import schoolService from "@/services/schoolService";
import { mapActions, mapGetters } from "vuex";
import responsive from '@/utils/mixins/responsive'

const DEFAULT_MESSAGE =
  "Aktuell ist die Teilnahme am praktischen und theoretischen Unterricht nur unter den oben genannten Voraussetzungen möglich.";

export default {
  name: "Covid2019",
  components: { CovidStatus },
  mixins: [responsive],
  data: () => ({
    statuses: [],
    statusId: null,
    saving: false,
    message: null,
    loading: true,
  }),
  mounted() {
    covidStatusService.load().then((response) => {
      this.statuses = response.data;
      this.loadSchool()
        .then(() => {
          this.statusId = this.covidStatusGetter?.id;
          this.message = this.covidStatusGetter?.message || DEFAULT_MESSAGE;
        })
        .finally(() => (this.loading = false));
    });
  },
  computed: {
    ...mapGetters("school", ["covidStatusGetter"]),
  },
  methods: {
    ...mapActions("school", {
      loadSchool: "load",
      updateCovidStatus: "updateCovidStatus",
    }),
    changed(val) {
      schoolService.updateCovidStatus({ covidStatusId: val });
    },
    submit() {
      this.saving = true;
      schoolService
        .updateCovidStatus({
          covidStatusId: this.statusId,
          message: this.message,
        })
        .then(() => {
          const obj = this.statuses.find((item) => item.id === this.statusId);
          const newObj = { ...obj, message: this.message };
          this.updateCovidStatus(newObj);
        })
        .finally(() => (this.saving = false));
    },
    clear() {
      this.loading = true;
      schoolService
        .clearCovidStatus()
        .then(() => {
          this.updateCovidStatus(null);
          this.statusId = null;
          this.message = DEFAULT_MESSAGE;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
