export default class DefaultAction {
  constructor(props) {
    this.props = props;
  }

  run() {
    console.log(
      "`DefaultAction` Push action performed: " + JSON.stringify(this.props)
    );
  }
}
