export const SET = "set";
export const UPDATE_DEFAULT_LIMITS = "UPDATE_DEFAULT_LIMITS";
export const UPDATE_COVID_STATUS = "UPDATE_COVID_STATUS";
export const UPDATE_PAYMENT_BONUS_STATUS = "UPDATE_PAYMENT_BONUS_STATUS";
export const UPDATE_INVOICING_STATUS = "UPDATE_INVOICING_STATUS";
export const UPDATE_INVOICING_AUTOMATION_STATUS =
  "UPDATE_INVOICING_AUTOMATION_STATUS";
export const UPDATE_DIGITAL_ATTENDANCE_STATUS =
  "UPDATE_DIGITAL_ATTENDANCE_STATUS";

export const UPDATE_VEHICLES_NOTIFICATION_STATUS =
  "UPDATE_VEHICLES_NOTIFICATION_STATUS";

export const UPDATE_VEHICLES_RESERVATION_STATUS =
  "UPDATE_VEHICLES_RESERVATION_STATUS";

export const UPDATE_VEHICLES_NOTIFICATION_SETTINGS =
  "UPDATE_VEHICLES_NOTIFICATION_SETTINGS";
export const UPDATE_CLICKANDLEARN_API_KEY = "UPDATE_CLICKANDLEARN_API_KEY";

export const UPDATE_CREDITS_AMOUNT = "UPDATE_CREDITS_AMOUNT";
