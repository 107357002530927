<template>
  <v-tooltip top :disabled="tooltipMessage.length === 0">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        icon
        :color="color"
        @click="$emit('btnClick')">
        <v-icon>{{ sortIcon }}</v-icon>
      </v-btn>
    </template>
    <span class="caption">{{ tooltipMessage }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "SortButton",
  props: {
    color: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    order: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    tooltipMessage: {
      type: String,
      default: "",
    },
  },
  computed: {
    sortIcon() {
      return this.order === "asc"
        ? `${this.icon}-descending`
        : `${this.icon}-ascending`;
    },
  },
};
</script>

<style lang="scss" scoped></style>
