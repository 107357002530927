import { isPast, parseISO } from 'date-fns'
import LessonTypeModel from '@/store/models/LessonTypeModel'
import AppointmentModel from '@/store/models/AppointmentModel'

export default function clearEvaluation(initialAppointment, currentAppointment) {
  if (currentAppointment.type !== 'PRACTICE') return // only for PRACTICE

  const { id, licenseId, lessonTypeId, start } = currentAppointment
  const licenseNotChanged = initialAppointment.licenseId === licenseId
  const lessonType = LessonTypeModel.find(lessonTypeId)
  const evaluatedLessonType = ['normal', 'special', 'instructions', 'mtl_exam'].includes(lessonType.group) && lessonType.isPayable
  const isInPast = isPast(parseISO(start))
  const appointmentModel = AppointmentModel.find(id)

  if (!appointmentModel.evaluation?.id) return // has no evaluation
  if (isInPast && licenseNotChanged && evaluatedLessonType) return // license not changed and lesson type can be evaluated

  return true
}
