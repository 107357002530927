<template>
  <v-list-item class="pa-0">
    <v-list-item-content>
      <v-list-item-title class="d-flex align-center">
        {{ title }}

        <v-icon
          v-if="isLicenseRequired && hasExaminingPeriodInWeeksStatus"
          class="ml-1"
          :color="dotColor"
          small
        >
          mdi-checkbox-blank-circle
        </v-icon>
      </v-list-item-title>

      <v-list-item-subtitle v-if="isLicenseRequired && passedDate">{{ passedDate }}</v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-icon>
      <v-chip
        small
        :color="color"
        text-color="white"
        @click="withAbilityToSetStatus && !isStudent && $emit('itemClick')"
        :disabled="!isLicenseRequired"
      >
        <template v-if="isLicenseRequired">
          <v-icon v-if="withAbilityToSetStatus" small left>{{ icon }}</v-icon>
          {{ formatLabel }}
        </template>
        <template v-else>
          <v-icon small left>mdi-check-circle</v-icon>
          {{ $t('label.not_required') }}
        </template>
      </v-chip>
    </v-list-item-icon>
  </v-list-item>
</template>
<script>
import user from "@/utils/mixins/user";

export default {
  name: "EducationProgressItem",
  data() {
    return {};
  },
  mixins: [user],
  props: {
    title: {
      type: String,
      required: true,
    },
    btnLabel: {
      type: String,
      required: true,
    },
    btnPassLabel: {
      type: String,
      required: true,
    },
    passedDate: {
      type: String,
    },
    hasDot: {
      type: Boolean,
      default: false,
    },
    hasExaminingPeriodInWeeksStatus: {
      type: Boolean,
      default: false,
    },
    examiningPeriodInWeeks: {
      type: [Number, String],
      default: 0,
    },
    withAbilityToSetStatus: {
      type: Boolean,
      default: true
    },
    isLicenseRequired: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    color() {
      if (!this.isLicenseRequired) return "primary"

      if (!this.passedDate && (this.isStudent || !this.withAbilityToSetStatus)) {
        return 'grey'
      }

      return this.passedDate ? "secondary" : "primary";
    },
    icon() {
      return this.passedDate
        ? "mdi-checkbox-marked-circle"
        : !this.isStudent
        ? "mdi-plus-circle"
        : "mdi-close-circle";
    },
    formatLabel() {
      if (!this.withAbilityToSetStatus) {
        return this.btnLabel
      }

      return this.isStudent
        ? this.btnLabel
        : this.passedDate
        ? this.btnPassLabel
        : this.$t("label.set_exam_status");
    },
    dotColor() {
      if (this.examiningPeriodInWeeks > 12) return "green";
      if (this.examiningPeriodInWeeks <= 12 && this.examiningPeriodInWeeks > 4) return "yellow";

      return "red";
    },
  },
};
</script>

<style scoped></style>
