import api from "@/utils/api";

export default {
  load() {
    return new Promise((resolve, reject) => {
      api
        .get("/school")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  customMessages() {
    return new Promise((resolve, reject) => {
      api
        .get("/school/custom_messages")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateCustomMessages(params) {
    return new Promise((resolve, reject) => {
      api
        .post("/school/custom_messages/update", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  deleteBirthdayImage() {
    return new Promise((resolve, reject) => {
      api
        .delete("/school/custom_messages/delete-birthday-image")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },

  searchByResourceID(resourceId) {
    return new Promise((resolve, reject) => {
      api
        .get("/schools/search-by-resource-id", {
          params: { resourceId: resourceId }
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  studentsSignedIn: (params = {}) => {
    return new Promise((resolve, reject) => {
      api
        .get("/school/registered-students", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  saleStats: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("/school/sale-stats", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  studentsStats: () => {
    return new Promise((resolve, reject) => {
      api
        .get("/school/students-stats")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  studentsBalance: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("/school/students-balance", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  teachersStats: () => {
    return new Promise((resolve, reject) => {
      api
        .get("/school/teachers-stats")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  teachersWorkedTime: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("/widgets/teachers-worktime", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateSettings(params) {
    params._method = "PUT";
    return new Promise((resolve, reject) => {
      api
        .post("/school/settings", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateEventColorsSettings: (params) => {
    return new Promise((resolve, reject) => {
      api
        .put("school/settings/event-styles", params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  updateLicensesPrices: (params) => {
    return new Promise((resolve, reject) => {
      api
        .put("school/settings/licenses-prices", params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  updateEducationSettings: (params) => {
    return new Promise((resolve, reject) => {
      api
        .put("school/settings/education", params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  getPrintMedia: () => {
    return new Promise((resolve, reject) => {
      api
        .get("school/print-media")
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  createPrintMedia: (params) => {
    return new Promise((resolve, reject) => {
      api
        .post("school/print-media", params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  documents: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("school/documents", params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  uploadDocument(params) {
    return new Promise((resolve, reject) => {
      api
        .post("/school/documents", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  deleteDocument(documentId) {
    return new Promise((resolve, reject) => {
      api
        .delete("/school/documents/" + documentId)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  setRegions(params) {
    params._method = "PATCH";
    return new Promise((resolve, reject) => {
      api
        .patch("/school/set-regions", { regions: params.regions })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateCovidStatus(params) {
    params._method = "PUT";
    return new Promise((resolve, reject) => {
      api
        .post("/school/covid-status", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  clearCovidStatus(params) {
    return new Promise((resolve, reject) => {
      api
        .delete("/school/covid-status", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updatePaymentBonusSettings(status) {
    return new Promise((resolve, reject) => {
      api
        .patch("/school/settings/payment-bonus", { status })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateInvoicingStatusSettings(status) {
    return new Promise((resolve, reject) => {
      api
        .patch("/school/settings/invoicing", { invoicingStatus: status })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateInvoicingAutomationStatusSettings(status) {
    return new Promise((resolve, reject) => {
      api
        .patch("/school/settings/invoicing", {
          invoicingAutomationStatus: status,
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateInvoiceAutomationSchedule(schedule) {
    return new Promise((resolve, reject) => {
      api
        .patch("/school/settings/invoicing", {
          invoiceAutomationSchedule: schedule,
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateDigitalAttendanceSettings(status) {
    return new Promise((resolve, reject) => {
      api
        .patch("/school/settings/digital-attendance", { status })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateVehiclesNotificationSettings(data) {
    return new Promise((resolve, reject) => {
      api
        .patch("/school/settings/vehicles-notification",  data)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateVehiclesReservationSettings(data) {
    return new Promise((resolve, reject) => {
      api
        .patch("/school/settings/vehicle-reservations",  data)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },

  getSchoolInfo() {
    return new Promise((resolve, reject) => {
      api
        .get("/school/info")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getSchoolSettings() {
    return new Promise((resolve, reject) => {
      api
        .get("/school/settings")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getSchoolData(filters = []) {
    const config = filters.length ? { params: { filters: filters.join(',') } } : {};

    return new Promise((resolve, reject) => {
      api
        .get("/school/data", config)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getSignature(signatureType) {
    return new Promise((resolve, reject) => {
      api
        .get(`/school/signatures/${signatureType}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  saveSignature(signature, signatureType= null) {
    return new Promise((resolve, reject) => {
      api
        .post("/school/signatures", { signature, signatureType })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  deleteSignature(signatureType) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/school/signatures/${signatureType}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  dailySchedulePdf(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/school/vehicles/daily-schedule-pdf", {
          params,
          responseType: "blob",
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateClickAndLearnApiKey(params) {
    return new Promise((resolve, reject) => {
      api
        .patch("/school/settings/click-and-learn", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  deleteClickAndLearnApiKey() {
    return new Promise((resolve, reject) => {
      api
        .delete("/school/settings/click-and-learn")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadCreditsHistory(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/school/credits-history", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  resetDemoAccount() {
    return new Promise((resolve, reject) => {
      api
        .put("/school/reset-demo-account")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  switchToRealUsage() {
    return new Promise((resolve, reject) => {
      api
        .put("/school/switch-to-real-usage")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getUsers(params = {}) {
    return new Promise((resolve, reject) => {
      api
        .get("/school/users", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getDrivingAreas(params = {}) {
    return new Promise((resolve, reject) => {
      api
        .get("/school/driving-areas", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateDrivingArea(drivingAreaId, area) {
    return new Promise((resolve, reject) => {
      api
        .patch("/school/driving-areas/" + drivingAreaId, { area })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  deleteDrivingArea(drivingAreaId) {
    return new Promise((resolve, reject) => {
      api
        .delete("/school/driving-areas/" + drivingAreaId)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  }
}
