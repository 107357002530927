<template>
  <Page>
    <PageNavBar v-if="$vuetify.breakpoint.mdAndUp">
      <PageToolBar
        is-search
        :create-btn-name="$t('btn.new_administrator')"
        @search="debouncedSearch"
        @clickCreateItem="createItem" />
    </PageNavBar>

    <PageContent :loading="loadingPage">
      <PageView :items-length="items.length" :loading="loadingItems">
        <GridView>
          <GridItem v-for="item in items" :key="item.id" :item="item">
            <template v-slot:content>
              <div class="d-flex justify-space-between align-self-start">
                <div class="d-flex align-center justify-center">
                  <UserAvatar class="mr-3" size="40" :path="item.avatar" :alt="item.firstName + ' ' + item.lastName" />
                </div>

                <div
                  class="d-flex flex-column"
                  :style="{ 'max-width': textTruncateContainerWidth }">
                  <div
                    class="d-flex text-subtitle-1 font-weight-medium text-truncate">
                    <span>{{ item.firstName + " " + item.lastName }}</span>
                  </div>
                  <span class="text-caption">
                    {{ item.email }}
                  </span>
                </div>
              </div>

              <v-row no-gutters class="mt-3">
                <v-col cols="9">
                  <v-icon>mdi-phone</v-icon>
                  {{ item.phone }}
                </v-col>
                <v-col class="d-flex justify-end" cols="3">
                  <v-switch
                    height="20"
                    flat
                    inset
                    :loading="item.loading"
                    :disabled="loadingAction"
                    dense
                    hide-details
                    class="mt-0 v-input--reverse"
                    v-model="item.active"
                    @change="(value) => toggleActive(item, value)" />
                </v-col>
              </v-row>
            </template>

            <template v-slot:actions>
              <v-list dense>
                <v-list-item @click="editItem(item)">
                  <v-list-item-icon class="mr-2">
                    <v-icon>mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t("label.edit") }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteItem(item)">
                  <v-list-item-icon class="mr-2">
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t("label.delete") }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </template>
          </GridItem>
        </GridView>

        <ListView>
          <template v-slot:header>
            <ListColumn :text="$t('list.name')" />
            <ListColumn :text="$t('list.phone')" />
            <ListColumn class="active" :text="$t('list.active')" />
            <ListColumn width="80" :text="$t('list.actions')" />
          </template>

          <template v-slot:body>
            <ListRow v-for="item in items" :key="item.id" :item="item">
              <ListColumn>
                <div class="d-flex">
                  <UserAvatar class="mr-3" size="40" :path="item.avatar" :alt="item.firstName + ' ' + item.lastName" />

                  <span class="d-inline-flex flex-column" style="top: -10px">
                    {{ item.firstName + " " + item.lastName }}
                    <span class="text-caption">{{ item.email }}</span>
                  </span>
                </div>
              </ListColumn>
              <ListColumn :text="item.phone" />
              <ListColumn width="80" @click.native.stop>
                <v-switch
                  height="20"
                  flat
                  inset
                  :loading="item.loading"
                  :disabled="loadingAction"
                  dense
                  hide-details
                  class="mt-0"
                  @change="(value) => toggleActive(item, value)" />
              </ListColumn>
              <ListColumn width="80" class="text-center" @click.native.stop>
                <RightClickMenu :item="item">
                  <template v-slot="{ showItemMenu }">
                    <v-icon
                      size="25"
                      @click="(e) => showItemMenu(e, item)"
                      :disabled="loadingAction"
                      >mdi-dots-vertical</v-icon
                    >
                  </template>

                  <template v-slot:menu>
                    <v-list dense>
                      <v-list-item @click="editItem(item)">
                        <v-list-item-icon class="mr-2">
                          <v-icon>mdi-pencil</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{$t("label.edit") }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="deleteItem(item)">
                        <v-list-item-icon class="mr-2">
                          <v-icon>mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t("label.delete") }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </template>
                </RightClickMenu>
              </ListColumn>
            </ListRow>
          </template>
        </ListView>
      </PageView>
    </PageContent>

    <SchoolAdministratorFormDialog ref="formItem" @create="onCreateItem" @update="onUpdateItem"/>

    <v-btn
      v-if="!mdLandscapeAndUp"
      fixed
      right
      bottom
      fab
      small
      class="mr-2"
      color="primary"
      @click="createItem"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </Page>
</template>

<script>
import responsive from "@/utils/mixins/responsive";
import administratorsService from "@/services/administratorsService";
import SchoolAdministratorFormDialog from "@/components/school/administrators/SchoolAdministratorFormDialog";
import GridView from "@/components/grid/GridView";
import GridItem from "@/components/grid/GridItem";
import ListView from "@/components/list/ListView";
import ListRow from "@/components/list/ListRow";
import ListColumn from "@/components/list/ListColumn";
import PageNavBar from "@/components/page/PageNavBar";
import PageToolBar from "@/components/page/PageToolBar";

import gridElementsHeaderWidth from "@/utils/mixins/gridElementsHeaderWidth";
import RightClickMenu from "@/components/RightClickMenu";

import { debounce } from "throttle-debounce";
import Page from "@/components/page/Page";
import PageContent from "@/components/page/PageContent";
import PageView from "@/components/page/PageView";
import UserAvatar from '@/components/UserAvatar'
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: "Administrators",
  mixins: [responsive, gridElementsHeaderWidth],
  components: {
    UserAvatar,
    PageView,
    PageContent,
    Page,
    RightClickMenu,
    ListColumn,
    ListRow,
    ListView,
    GridItem,
    GridView,
    PageToolBar,
    PageNavBar,
    SchoolAdministratorFormDialog,
  },
  data: () => ({
    loadingPage: true,
    loadingItems: false,
    loadingAction: false,
    isOpenFilters: false,
    searchText: "",
    items: [],
    itemMenu: {
      itemId: "",
      show: false,
      x: 0,
      y: 0,
    },
    itemsView: localStorage.getItem("list-type") || "list",
  }),
  computed: {
    debouncedSearch() {
      return debounce(300, this.onSearch);
    },
  },
  async mounted() {
    await this.fetch().finally(() => {
      this.loadingPage = false;
    });
  },
  methods: {
    fetch() {
      const params = { searchText: this.searchText };

      return administratorsService
        .load(params)
        .then((resp) => {
          this.items = resp.data.map((item) => {
            return { ...item, menu: { show: false, x: 0, y: 0 } };
          });
        })
        .catch((error) => console.log(error));
    },
    toggleListView(view) {
      this.itemsView = view;
    },
    toggleActive(item, value) {
      const itemIndex = this.items.indexOf(item);

      this.$set(this.items, itemIndex, { ...item, loading: true });
      administratorsService
        .makeActive(item.id, { active: value })
        .then(() => {
          setTimeout(
            () => this.$set(this.items, itemIndex, { ...item, active: value }),
            0
          );
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.$set(this.items, itemIndex, { ...item, loading: false });
        });
    },
    showItemMenu(e, item) {
      e.preventDefault();

      this.itemMenu = {
        itemId: item.id,
        show: false,
        x: e.clientX,
        y: e.clientY,
      };

      this.$nextTick(() => {
        this.itemMenu.show = true;
      });
    },
    createItem() {
      this.$refs.formItem.open();
    },
    editItem(item) {
      this.$refs.formItem.open(item);
    },
    onSearch(searchText) {
      this.searchText = searchText;

      this.loadingItems = true;
      this.fetch().finally(() => {
        this.loadingItems = false;
      });
    },
    onCreateItem(createdItem) {
      this.items.unshift(createdItem);
    },
    onUpdateItem(updatedItem) {
      const item = this.items.find((item) => item.id === updatedItem.id);
      const itemIndex = this.items.indexOf(item);

      this.$set(this.items, itemIndex, { ...updatedItem });
    },
    deleteItem(item) {
      this.loadingAction = true;
      showConfirm(
        this.$t('btn.confirm'),
        this.$t('messages.are_your_sure_delete'),
        () => {
          administratorsService
            .destroy(item.id)
            .then(() => this.fetch())
            .catch(console.log)
            .finally(() => {
              this.loadingAction = false;
            });
        },
        () => {
          this.loadingAction = false;
        }
      )
    },
  },
};
</script>

<style scoped></style>
