<template>
  <v-dialog persistent :fullscreen="$vuetify.breakpoint.smAndDown" width="700" scrollable class="no-rounded-borders" :value="show" @keydown.esc="close">
    <v-card>
      <v-card-title primary-title>
        {{ $t('label.whats_new') }}
        <v-spacer/>
        <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>

      <v-card-text>
        <v-expansion-panels v-model="openedPanel" mandatory>
          <v-expansion-panel v-for="version in data" :key="version.id">
            <v-expansion-panel-header>
              <v-row no-gutters>
                <v-col cols="4">
                  <strong>{{ version.number }}</strong>
                </v-col>
                <v-col cols="8" class="text--secondary text-right pr-5">
                  {{ version.publishedAtFormatted }}
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <PatchNotesList :patch-notes="version.patchNotes" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-btn @click="redirectToPatchNotesList()" text class="mt-5">
          {{ $t('btn.view_all_patch_notes') }}
        </v-btn>
      </v-card-text>

      <v-divider/>
      <v-card-actions class="d-flex justify-end">
        <v-btn text @click="close">{{ $t("btn.close") }}</v-btn>
        <v-btn @click="markAsSeen" color="primary" :loading="markingAsSeen" :disabled="markingAsSeen">
          <v-icon left>mdi-check-all</v-icon>
          {{ $t("btn.mark_all_as_seen") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import patchNotesService from "@/services/patchNotesService";
import PatchNotesList from "@/components/PatchNotesList";
export default {
  name: "PatchNotesDialog",
  components: {PatchNotesList},
  data() {
    return {
      show: false,
      markingAsSeen: false,
      data: [],
      openedPanel: 0
    }
  },
  methods: {
    open(data) {
      this.data = data
      this.show = true
    },
    close() {
      this.show = false
      this.data = []
    },
    markAsSeen () {
      const ids = this.data.map(item => {
        return item.id
      })

      this.markingAsSeen = true
      patchNotesService.markAsSeen(ids).finally(() => {
        this.markingAsSeen = false
        this.close()
      })
    },
    redirectToPatchNotesList () {
      this.$router.push({name: 'patch-notes'}).then(() => this.markAsSeen())
    }
  }
}
</script>
