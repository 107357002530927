import { render, staticRenderFns } from "./PlaceholderAlert.vue?vue&type=template&id=61f5e31e&scoped=true"
import script from "./PlaceholderAlert.vue?vue&type=script&lang=js"
export * from "./PlaceholderAlert.vue?vue&type=script&lang=js"
import style0 from "./PlaceholderAlert.vue?vue&type=style&index=0&id=61f5e31e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f5e31e",
  null
  
)

export default component.exports