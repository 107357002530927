<template>
  <v-container>
    <v-card :loading="loading">
      <v-toolbar elevation="3">
        <v-card-title class="ellipsis">{{
          $t("label.terminal_payments")
        }}</v-card-title>
        <v-spacer />
        <!--        <v-btn icon text :loading="loading" color="primary" @click="loading = !loading"><v-icon>mdi-reload</v-icon></v-btn>-->
      </v-toolbar>
      <v-row>
        <v-col cols="12" offset="0" md="8" offset-md="2">
          <TerminalPaymentsLoader v-if="loading" class="mt-5" />
          <v-card-text v-else-if="locations.length">
            <v-card elevation="0">
              <v-card-title>{{
                $t("label.select_terminal_location")
              }}</v-card-title>
              <v-card-text>
                <v-select
                  solo
                  :items="
                    locations.map((item) => ({
                      text: item.name,
                      value: item.id,
                    }))
                  "
                  v-model="selectedLocation"
                  :loading="discoveringReaders"
                  :label="$t('label.select_a_location')"
                  @change="changeLocationHandler"
                  :disabled="
                    connectingToReader !== null || awaitingForTerminalPayment
                  " />
                <v-list
                  v-if="
                    discoveringReaders ||
                    (discoveredReaders && discoveredReaders.length) ||
                    !selectedLocation
                  ">
                  <v-list-item
                    v-for="reader in discoveredReaders"
                    :key="reader.serial_number"
                    two-line>
                    <v-list-item-avatar
                      ><v-img
                        :src="require('@/assets/img/WisePOS-E-Thumb.png')"
                        alt=""
                    /></v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ reader.label }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-badge
                          dot
                          inline
                          left
                          overlap
                          :color="
                            reader.status === 'online' ? 'green' : 'red'
                          " />
                        {{ reader.status }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        small
                        :color="
                          connectedReader === reader.id ? 'green' : 'primary'
                        "
                        :disabled="
                          connectingToReader !== null ||
                          connectedReader === reader.id ||
                          awaitingForTerminalPayment
                        "
                        :loading="connectingToReader === reader.id"
                        @click="connectReaderHandler(reader)">
                        {{
                          connectedReader === reader.id
                            ? $t("btn.connected")
                            : $t("btn.connect")
                        }}
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <empty-box
                  v-else
                  width="100"
                  height="100"
                  image="assets/img/WisePOS-E-Thumb.png" />
              </v-card-text>
            </v-card>

            <v-card
              v-if="connectedReader"
              elevation="0"
              :loading="awaitingForTerminalPayment">
              <v-card-title>{{ $t("label.accept_the_payment") }}</v-card-title>
              <v-card-subtitle>{{
                $t("label.accept_payment_instruction")
              }}</v-card-subtitle>
              <v-card-text>
                <v-text-field
                  ref="amountField"
                  :label="$t('label.amount_to_be_paid')"
                  placeholder="0,00"
                  solo
                  append-icon="mdi-currency-eur"
                  class="mb-0"
                  v-model="amount"
                  hide-details
                  v-currency="{
                    currency: null,
                    allowNegative: false,
                    valueRange: { min: 0 },
                    valueAsInteger: false,
                    precision: 2,
                  }" />

                <v-radio-group
                  v-model="clientType"
                  hide-details
                  :disabled="studentSelectReadOnly"
                  @change="clientTypeChangeHandler">
                  <v-radio
                    :label="$t('label.existing_student')"
                    value="student"
                    :ripple="false" />
                  <v-radio
                    :label="$t('label.other_client')"
                    value="guest"
                    :ripple="false" />
                </v-radio-group>

                <v-autocomplete
                  solo
                  :items="studentSelectItems"
                  :disabled="studentSelectReadOnly"
                  :menu-props="{ offsetY: true }"
                  v-model="selectedStudent"
                  v-if="clientType === 'student'"
                  :placeholder="$t('label.select_a_student')"
                  hide-details
                  class="mt-2">
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        data.item.text
                      }}</v-list-item-title>
                    </v-list-item-content>
                    <div class="other text--error">
                      <v-list-item-subtitle
                        >{{ data.item.balance }} €</v-list-item-subtitle
                      >
                    </div>
                  </template>
                </v-autocomplete>
                <v-text-field
                  solo
                  :label="$t('form.first_name') + ' ' + $t('form.last_name')"
                  :placeholder="
                    $t('form.first_name') + ' ' + $t('form.last_name')
                  "
                  class="mt-2"
                  hide-details
                  v-model="clientName"
                  v-else-if="clientType === 'guest'" />
                <v-btn
                  :disabled="collectButtonDisabled"
                  class="mt-5"
                  color="primary"
                  @click="collectPayment"
                  v-if="!awaitingForTerminalPayment">
                  {{ $t("btn.collect_payment") }}
                </v-btn>
                <v-alert v-else type="info" class="mt-5" border="right">
                  <v-row align="center">
                    <v-col class="grow">
                      {{ $t("label.continue_on_the_terminal") }}
                    </v-col>
                    <v-col class="shrink">
                      <v-btn small outlined @click="abortPayment">{{
                        $t("btn.cancel")
                      }}</v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-text v-else>
            <empty-box
              width="100"
              height="100"
              image="assets/img/WisePOS-E-Thumb.png"
              :text="$t('label.feature_not_available')" />
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import api from "@/utils/api";
import posTerminalLocationsService from "@/services/posTerminalLocationsService";
import TerminalPaymentsLoader from "@/components/skeleton-loaders/TerminalPaymentsLoader";
import studentsService from "@/services/studentsService";
import {showAlert} from "@/helpers/dialogs";

export default {
  name: "Terminal",
  components: { TerminalPaymentsLoader },
  data() {
    return {
      loading: true,
      terminal: null,
      discoveredReaders: null,
      paymentIntentId: null,
      locations: [],
      selectedLocation: null,
      discoveringReaders: false,
      connectingToReader: null,
      connectedReader: null,
      amount: null,
      awaitingForTerminalPayment: false,
      students: [],
      loadingStudents: true,
      clientType: "student",
      selectedStudent: null,
      clientName: null,
    };
  },
  async mounted() {
    posTerminalLocationsService
      .list()
      .then((resp) => {
        this.locations = resp.data;
        if (this.locations.length === 1) {
          this.selectedLocation = this.locations[0].id;
          this.changeLocationHandler(this.locations[0].id);
        }
      })
      .finally(() => {
        this.loading = false;
      });
    studentsService.load({ brief: true }).then((resp) => {
      this.students = resp.data;
      this.loadingStudents = false;
      if (this.$route.query.studentId) {
        const id = parseInt(this.$route.query.studentId);
        if (this.studentSelectItems.map((item) => item.value).includes(id)) {
          this.selectedStudent = id;
        }
      }
    });
  },
  methods: {
    changeLocationHandler(value) {
      if (this.terminal) {
        // If there is already a payment being processed - exit.
        if (this.terminal.getPaymentStatus() === "processing") return;

        // Check if the terminal is not in the `waiting for input` state, and abort if so.
        if (this.terminal.getPaymentStatus() === "waiting_for_input") {
          this.abortPayment();
        }

        // Before changing the location disconnect from the connected reader.
        if (this.terminal.getConnectionStatus() === "connected") {
          this.terminal.disconnectReader();
        }
      }

      // Re-create the terminal object by fetching a new token and discover new readers
      this.terminal = window.StripeTerminal.create({
        onFetchConnectionToken: this.fetchConnectionToken,
        onUnexpectedReaderDisconnect: this.unexpectedDisconnect,
      });
      this.discoveredReaders = [];
      this.connectedReader = null;
      this.discoverReaderHandler(value);
    },
    async fetchConnectionToken() {
      const { data } = await api.post("terminal/connection-token");
      return data.secret;
    },
    unexpectedDisconnect() {
      this.connectedReader = null;
      this.paymentIntentId = null;
      console.log("Unexpected disconnect");
    },
    async capture() {
      const { data } = await api.post("terminal/capture-payment-intent");
      return data;
    },
    discoverReaderHandler(location) {
      this.discoveringReaders = true;
      const config = { location };
      this.terminal.discoverReaders(config).then((discoverResult) => {
        this.discoveringReaders = false;
        if (discoverResult.error) {
          console.log("Failed to discover: ", discoverResult.error);
        } else if (discoverResult.discoveredReaders.length === 0) {
          console.log("No available readers.");
        } else {
          this.discoveredReaders = discoverResult.discoveredReaders;
          if (
            this.discoveredReaders.length === 1 &&
            this.discoveredReaders[0].status === "online"
          ) {
            this.connectReaderHandler(this.discoveredReaders[0]);
          }
        }
      });
    },
    connectReaderHandler(selectedReader) {
      if (this.terminal.getConnectionStatus() === "connected") {
        this.terminal.disconnectReader();
      }

      this.connectedReader = null;
      this.connectingToReader = selectedReader.id;
      this.terminal.connectReader(selectedReader).then((connectResult) => {
        this.connectingToReader = null;
        if (connectResult.error) {
          console.log("Failed to connect: ", connectResult.error);
          showAlert(this.$t("messages.error"), this.$t(`messages.${connectResult.error.code}`))
        } else {
          console.log("Connected to reader: ", connectResult.reader.label);
          this.connectedReader = connectResult.reader.id;
        }
      });
    },
    async fetchPaymentIntentClientSecret() {
      const { data } = await api.post("terminal/payment-intent", {
        amount: this.numberAmount,
        student: this.selectedStudent,
        clientName: this.clientName,
      });
      return data.client_secret;
    },
    async collectPayment() {
      this.awaitingForTerminalPayment = true;
      const clientSecret = await this.fetchPaymentIntentClientSecret();
      this.terminal.collectPaymentMethod(clientSecret).then((result) => {
        if (result.error) {
          this.awaitingForTerminalPayment = false;
          // Placeholder for handling result.error
        } else {
          this.terminal
            .processPayment(result.paymentIntent)
            .then((response) => {
              if (response.paymentIntent) {
                this.paymentIntentId = response.paymentIntent.id;
                this.capturePayment();
              }
            });
        }
      });
    },
    capturePayment() {
      if (this.paymentIntentId) {
        api
          .post("terminal/capture-payment-intent", {
            payment_intent_id: this.paymentIntentId,
          })
          .then(() => {
            showAlert(this.$t("messages.success"), this.$t("label.payment_succeeded"))
          })
          .finally(() => {
            this.awaitingForTerminalPayment = false;
            this.amount = 0;
            this.selectedStudent = null;
            this.clientName = null;
          });
      }
    },
    abortPayment() {
      if (this.awaitingForTerminalPayment) {
        this.terminal
          .cancelCollectPaymentMethod()
          .then(() => {
            this.awaitingForTerminalPayment = false;
          })
          .catch(() => {});
      }
    },
    clientTypeChangeHandler() {
      this.selectedStudent = null;
      this.clientName = null;
    },
  },
  computed: {
    studentSelectItems() {
      return this.students
        .filter((item) => item.bookAndPay)
        .map((item) => ({
          text: item.fullName,
          value: item.id,
          balance: item.balance,
        }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    studentSelectReadOnly() {
      return (
        this.$route.query.studentId &&
        this.studentSelectItems
          .map((item) => item.value)
          .includes(parseInt(this.$route.query.studentId))
      );
    },
    collectButtonDisabled() {
      return (
        this.amount === null ||
        parseFloat(this.amount) === 0 ||
        (this.clientType === "student" && this.selectedStudent === null) ||
        (this.clientType === "guest" && this.clientName === null) ||
        this.awaitingForTerminalPayment
      );
    },
    numberAmount() {
      return parseFloat(this.amount.replace(".", "").replace(",", ".")) || null;
    },
  },
};
</script>

<style scoped lang="scss"></style>
