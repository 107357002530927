import { Model } from "@vuex-orm/core";

export default class SchoolModel extends Model {
  static entity = "schools";

  static fields() {
    return {
      id: this.attr(null),
      name: this.string(""),
      lat: this.number(""),
      long: this.number(""),
      mobile: this.string(""),
      fax: this.string(""),
    };
  }
}
