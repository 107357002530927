<template>
  <div class="ma-0 py-2 d-flex justify-end">
    <v-dialog v-model="dialog" eager max-width="700" persistent>
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>
            {{ eventSignature ? $t("label.show_signature") : $t("label.sign") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('closeSignatureDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-sheet outlined rounded class="mt-5">
            <VueSignaturePad height="200px" width="100%" ref="signaturePad" />
          </v-sheet>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions v-if="!signature">
          <v-btn text color="primary" @click="$emit('closeSignatureDialog')">
            <v-icon v-if="$vuetify.breakpoint.mobile" left>mdi-close</v-icon>
            <span v-if="!$vuetify.breakpoint.mobile">{{$t("btn.close")}}</span>
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn v-if="!eventSignature" color="error" @click="clear">
            <v-icon :left="!$vuetify.breakpoint.mobile">{{ 'mdi-eraser-variant' }}</v-icon>
            <span v-if="!$vuetify.breakpoint.mobile">{{ $t(`btn.clear`) }}</span>
          </v-btn>
          <v-btn v-if="!eventSignature" color="primary" @click="save">
            <v-icon :left="!$vuetify.breakpoint.mobile">mdi-content-save</v-icon>
            <span v-if="!$vuetify.breakpoint.mobile">{{$t("btn.save")}}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "SignatureField",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    signature: {
      type: String,
      default: '',
    },
    eventSignature: {
      type: String,
    },
  },
  data: () => ({
    dialog: false,
  }),
  watch: {
    show(value) {
      this.dialog = value
    },
    signature(value) {
      if (value) {
        this.open(value)
      }
    },
    dialog(value) {
      if (value) {
        this.$nextTick(() => {
          this.clear();
          this.$refs.signaturePad.resizeCanvas();
        });
      }
    },
  },
  methods: {
    open(signature) {
      this.$refs.signaturePad.openSignaturePad();
      if (signature) {
        this.$nextTick(() => {
          this.$refs.signaturePad.resizeCanvas();
          this.$refs.signaturePad.fromDataURL(signature);
          this.$refs.signaturePad.lockSignaturePad();
        });
      }
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) return;
      this.$emit('saveSignature', data)
    },
  },
};
</script>
