<template>
  <v-system-bar
    dark
    :color="color.bodyColor"
    :style="`color: ${color.fontColor}`"
    window
    style="height: calc(32px + env(safe-area-inset-top))"
  >
    <span>{{ eventTypeTranslation }}</span>
    <span v-if="happyHour" class="ml-5">
      <v-icon :color="color.fontColor" @click="closeModal">mdi-gift</v-icon>
    </span>
    <v-spacer></v-spacer>
    <span>
      <v-icon :color="color.fontColor">mdi-clock</v-icon>
      {{ eventDuration }} {{ $tc("minutes", 2) }}
    </span>
    <span class="ml-5">
      <v-icon :color="color.fontColor" @click="closeModal">mdi-close</v-icon>
    </span>
  </v-system-bar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EventModel from "@/store/models/EventModel";

export default {
  name: "TopBar",
  props: {
    eventDuration: {
      type: Number,
      required: true,
    },
    color: {
      type: Object,
      required: true,
    },
    eventType: {
      type: String,
    },
    happyHour: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    eventTypeTranslation() {
      return EventModel.eventTypeTranslation(this.eventType);
    },
  },
  methods: {
    ...mapActions("appointments", ["finishEventCreation"]),
    closeModal() {
      this.finishEventCreation();
    },
  },
};
</script>

<style lang="scss" scoped></style>
