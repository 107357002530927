<template>
  <v-card color="primary" dark height="141">
    <v-card-title class="py-2">{{ $t("label.teachers") }}</v-card-title>
    <v-divider />
    <v-card-text class="d-flex justify-space-between white--text">
      <div
        class="d-flex flex-column white--text flex-grow-1 text-decoration-none">
        <span class="text-caption">{{ $t("label.total") }}</span>
        <v-skeleton-loader
          type="image"
          class="mt-3 mb-3"
          width="40"
          height="15"
          v-if="loading" />
        <span class="text-h4" v-else>{{ total || "—" }}</span>
      </div>
      <div
        class="d-flex flex-column white--text flex-grow-1 text-decoration-none">
        <span class="text-caption">{{ $t("label.active") }}</span>
        <v-skeleton-loader
          type="image"
          class="mt-3 mb-3"
          width="40"
          height="15"
          v-if="loading" />
        <span class="text-h4" v-else>{{ active || "—" }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import schoolService from "@/services/schoolService";

export default {
  name: "TeachersCount",
  data() {
    return {
      loading: true,
      total: null,
      active: null,
    };
  },
  mounted() {
    schoolService
      .teachersStats()
      .then((response) => {
        const teachersStats = response.data || {};

        this.total = teachersStats.totalCount;
        this.active = teachersStats.activeCount;
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped></style>
