<template>
  <div>
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('label.default_templates') }}</v-expansion-panel-header>
        <v-expansion-panel-content>

          <v-alert type="info" border="right">
            {{ $t("label.default_license_requirements_explanation") }}
          </v-alert>

          <v-simple-table v-if="defaultTemplates.length" dense>
            <template v-slot:default>
              <thead>
              <tr>
                <th>{{ $t('list.name') }}</th>
                <th>{{ $t('list.licenses') }}</th>
                <th class="text-center cell-size">{{ $t('form.basic') }}</th>
                <th class="text-center cell-size">{{ $t('form.special') }}</th>
                <th class="text-center cell-size">{{ $t('form.overland') }}</th>
                <th class="text-center cell-size">{{ $t('form.highway') }}</th>
                <th class="text-center cell-size">{{ $t('form.night') }}</th>
                <th class="text-center cell-size">{{ $t('form.instruction') }}</th>
                <th class="text-center cell-size">{{ $t('form.normal') }}</th>
                <th class="text-center cell-size">{{ $t('form.manual_lesson') }}</th>
                <th class="text-center cell-size">{{ $t('form.theory_exam_required') }}</th>
                <th class="text-center cell-size">{{ $t('form.practice_exam_required') }}</th>
                <th style="width: 110px"></th>
              </tr>
              </thead>

              <tbody>
                <tr v-for="item in defaultTemplates" :key="item.id" class="table-row">
                  <td>{{ item.name }}</td>
                  <td>{{ getLicenseName(item.licenses) }}</td>
                  <td class="text-center">{{ item.basicTheories }}</td>
                  <td class="text-center">{{ item.specialTheories }}</td>
                  <td class="text-center">{{ item.overlands }}</td>
                  <td class="text-center">{{ item.highways }}</td>
                  <td class="text-center">{{ item.nights }}</td>
                  <td class="text-center">{{ item.instructions || '—' }}</td>
                  <td class="text-center">{{ item.normals || '—' }}</td>
                  <td class="text-center">{{ getMTL(item.licenses) }}</td>
                  <td class="text-center">
                    <v-icon v-if="item.theoryExam">mdi-check</v-icon>
                    <template v-else>&mdash;</template>
                  </td>
                  <td class="text-center">
                    <v-icon v-if="item.practiceExam">mdi-check</v-icon>
                    <template v-else>&mdash;</template>
                  </td>
                  <th></th>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <EmptyBox v-else />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('label.custom_templates') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table v-if="customTemplates.length" dense>
            <template v-slot:default>
              <thead>
              <tr>
                <th>{{ $t('list.name') }}</th>
                <th>{{ $t('list.licenses') }}</th>
                <th class="text-center cell-size">{{ $t('form.basic') }}</th>
                <th class="text-center cell-size">{{ $t('form.special') }}</th>
                <th class="text-center cell-size">{{ $t('form.overland') }}</th>
                <th class="text-center cell-size">{{ $t('form.highway') }}</th>
                <th class="text-center cell-size">{{ $t('form.night') }}</th>
                <th class="text-center cell-size">{{ $t('form.instruction') }}</th>
                <th class="text-center cell-size">{{ $t('form.normal') }}</th>
                <th class="text-center cell-size">{{ $t('form.theory_exam_required') }}</th>
                <th class="text-center cell-size">{{ $t('form.practice_exam_required') }}</th>
                <th style="width: 120px"></th>
              </tr>
              </thead>

              <tbody>
                <tr v-for="item in customTemplates" :key="item.id" :class="`table-row ${!item.active  ? 'inactive' : ''}`">
                  <td>{{ item.name }}</td>
                  <td>
                    {{ getLicenseName(item.licenses) }}
                    <v-tooltip bottom v-if="item.mtls">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary ml-2" small dark v-bind="attrs" v-on="on">
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>
                        {{ $t('label.b197_info_with_count', { count: item.mtls }) }}
                      </span>
                    </v-tooltip>
                  </td>
                  <td class="text-center">{{ item.basicTheories }}</td>
                  <td class="text-center">{{ item.specialTheories }}</td>
                  <td class="text-center">{{ item.overlands }}</td>
                  <td class="text-center">{{ item.highways }}</td>
                  <td class="text-center">{{ item.nights }}</td>
                  <td class="text-center">{{ item.instructions || 0 }}</td>
                  <td class="text-center">{{ item.normals || 0 }}</td>
                  <td class="text-center">
                    <v-icon v-if="item.theoryExam">mdi-check</v-icon>
                    <template v-else>&mdash;</template>
                  </td>
                  <td class="text-center">
                    <v-icon v-if="item.practiceExam">mdi-check</v-icon>
                    <template v-else>&mdash;</template>
                  </td>
                  <td class="text-right">
                    <v-chip class="hidden-until-row-hovered mx-1" link label x-small :disabled="loading" @click="toggleStatus(item)">
                      <v-icon x-small>{{ item.active ? 'mdi-close' : 'mdi-check' }}</v-icon>
                    </v-chip>
                    <v-chip class="hidden-until-row-hovered mx-1" link label x-small :disabled="loading" @click="$emit('edit', item)">
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-chip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <EmptyBox v-else />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import EmptyBox from "@/components/EmptyBox"
import LicenseModel from '@/store/models/LicenseModel'
import LicenseRequirementModel from '@/store/models/LicenseRequirementModel'
import licenseRequirementsService from '@/services/licenseRequirementsService'

export default {
  name: "PreownedLicensesTemplatesTable",
  components: { EmptyBox },
  data: () => ({
    loading: false,
    panel: [1],
  }),
  props: {
    licensesTemplates: {
      type: Array,
      required: true
    }
  },
  computed: {
    defaultTemplates() {
      return this.licensesTemplates.filter((template) => template.isDefault)
    },
    customTemplates() {
      return this.licensesTemplates.filter((template) => !template.isDefault)
    },
  },
  methods: {
    getLicenseName (val) {
      const license = LicenseModel.query().whereIdIn(val).get()
      return license.map(license => license.name).join(', ')
    },
    getMTL (val) {
      const license = LicenseModel.find(val[0])
      return license.requiredMtl > 0 ? license.requiredMtl : '—'
    },
    toggleStatus(val) {
      this.loading = true
      licenseRequirementsService.toggleActiveStatus(val.id, { active: !val.active })
          .then(() => LicenseRequirementModel.update({
            where: val.id,
            data: { active: !val.active },
          }))
          .finally(() => this.loading = false)
    }
  },
};
</script>

<style scoped lang="scss">
table {
  width: 100%;
}
.hidden-until-row-hovered {
  display: none;
}
.table-row:hover > td > .hidden-until-row-hovered {
  display: inline-flex;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.inactive {
  background: #ffebee !important;
}
.cell-size{
  width: 100px !important;
}
</style>
