<template>
  <v-card :disabled="disabled" :loading="loading">
    <v-img :src="image" eager height="100px" />
    <v-card-title>{{ $t("route.vehicleFueling") }}</v-card-title>

    <v-card-subtitle class="student-card">
      {{ $t("label.vehicle_card_fueling_description") }}
    </v-card-subtitle>

    <v-card-text v-if="!disabled">
      <v-skeleton-loader v-if="loading" type="list-item-two-line" />
      <v-list v-else>
        <v-list-item class="pa-0 mb-2">
          <v-list-item-content class="pa-0">
            <v-list-item-title>{{ $t("label.last_refuel") }}</v-list-item-title>
            <v-list-item-subtitle>{{ lastRefuelString }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>

    <v-card-actions>
      <v-btn
        :to="{ name: `vehicles.vehicle.fueling` }"
        class="ml-auto"
        small
        text
      >
        {{ $t("btn.open") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import cardsService from "@/services/cardsService";
import { EventBus } from "@/EventBus";

export default {
  name: "VehicleFuelingCard",
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      lastRefuel: null,
      image: require("@/assets/img/fueling.jpg")
    };
  },
  mounted() {
    if (!this.disabled) this.init();
  },
  created() {
    EventBus.$on("refueling-created", this.init);
    EventBus.$on("refueling-deleted", this.init);
  },
  destroyed() {
    EventBus.$off("refueling-created", this.init);
    EventBus.$off("refueling-deleted", this.init);
  },
  computed: {
    lastRefuelString() {
      if (this.lastRefuel) {
        return this.lastRefuel.date + " - " + this.lastRefuel.fuelAmount + "l, " + this.lastRefuel.cost + "€";
      }
      return this.$t("no_data_provided");
    }
  },
  methods: {
    init() {
      this.loading = true;
      cardsService.vehicleFueling(this.$route.params.id)
        .then(response => {
          this.lastRefuel = response.data.lastFueling || null;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>

</style>
