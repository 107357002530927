import { Model } from "@vuex-orm/core";
import i18n from "@/plugins/i18n";
import PaymentChargeModel from "@/store/models/PaymentChargeModel";

export default class PaymentModel extends Model {
  static entity = "payments";

  static fields() {
    return {
      id: this.attr(null),
      amount: this.number(0),
      created: this.string(null),
      creditsAmount: this.attr(null),
      status: this.string("processing"),
      date: this.string(null),
      displayDate: this.string(null),
      time: this.string(null),
      payload: this.attr({}),
      paymentMethod: this.string(null),
      studentName: this.string(null),
      studentId: this.number(null),
      refunded: this.boolean(false),
      refundedOn: this.string(null),
      cardBrand: this.attr(null),
      last4: this.attr(null),
      wallet: this.attr(null),
      charges: this.hasMany(PaymentChargeModel, 'paymentId')
    };
  }

  get s() {
    return this.refunded ? "refunded" : this.status;
  }

  get statusIcon() {
    const statusIcon = {
      succeeded: "mdi-check-all",
      processing: "mdi-check",
      refunded: "mdi-undo-variant",
    };

    return this.s in statusIcon ? statusIcon[this.s] : statusIcon.processing;
  }

  get statusColor() {
    const statusColor = {
      succeeded: "green",
      processing: "amber",
      refunded: "warning darken-2",
    };

    return this.s in statusColor ? statusColor[this.s] : statusColor.processing;
  }

  get statusText() {
    const statusText = {
      succeeded: i18n.t("payment_status.succeeded"),
      processing: i18n.t("payment_status.processing"),
      refunded: i18n.t("payment_status.refunded"),
    };

    return this.s in statusText ? statusText[this.s] : statusText.processing;
  }

  get method() {
    const types = {
      card: i18n.t("payment_methods.card"),
      card_present: i18n.t("payment_methods.card_present"),
      sofort: i18n.t("payment_methods.sofort"),
      sepa_debit: i18n.t("payment_methods.sepa_debit"),
      giropay: i18n.t("payment_methods.giropay"),
    };

    return this.paymentMethod in types ? types[this.paymentMethod] : "?";
  }

  get paymentMethodIcon() {
    const icons = {
      card: getCardIconByBrand(this.cardBrand),
      card_present: getCardIconByBrand(this.cardBrand),
      sofort: require("@/assets/payment-methods/sofort.svg"),
      sepa_debit: require("@/assets/payment-methods/sepa.svg"),
      giropay: require("@/assets/payment-methods/giropay.svg"),
    };

    return this.paymentMethod in icons ? icons[this.paymentMethod] : require("@/assets/payment-methods/unknown.svg");
  }

  get walletIcon() {
    if (this.paymentMethod === 'card_present') return require("@/assets/payment-methods/card-present.svg");

    const type = this.wallet ? this.wallet?.type : null;
    const types = {
      apple_pay: require("@/assets/payment-methods/apple-pay.svg"),
      google_pay: require("@/assets/payment-methods/google-pay.svg"),
      link: require("@/assets/payment-methods/link.svg"),
    }

    return type in types ? types[type] : "";
  }
}

const getCardIconByBrand = brand => {
  const brands = {
    amex: require("@/assets/payment-methods/amex.svg"),
    mastercard: require("@/assets/payment-methods/mastercard.svg"),
    visa: require("@/assets/payment-methods/visa.svg"),
    unionpay: require("@/assets/payment-methods/unionpay.svg"),
  }

  return brand in brands ? brands[brand] : "";
}
