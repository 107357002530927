import {
  addDays,
  format,
  isSunday,
  parseISO,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek, isWithinInterval,
} from 'date-fns'
import { mapActions, mapGetters } from "vuex";
import calendarService from "@/services/calendarService";
import AppointmentModel from "@/store/models/AppointmentModel";
import * as calendarMutationTypes from "@/store/mutation-types/calendar";
import TeacherModel from "@/store/models/TeacherModel";
import user from '@/utils/mixins/user'
import DateRange from "@/helpers/dateRange"

export default {
  mixins: [user],
  computed: {
    ...mapGetters(["firstDayOfMonth", "activeWeek"]),
  },
  methods: {
    ...mapActions(["setCalendarDate"]),
    ...mapActions("appointments", ["showAppointmentModal"]),
    async loadAppointments(forceReload = false) {
      const teachers = TeacherModel.all().map((teacher) => teacher.id);
      const appointmentId = this.$route.query.event_id;

      if (appointmentId) {
        await calendarService.get(appointmentId)
          .then((response) => {
            this.setCalendarDate({ activeDate: format(parseISO(response.data.start), "yyyy-MM-dd") })
          })
      }
      if (this.isSchoolAdministrator) {
        const { activeDate, previousDate,  calendarLayout } = this.$store.state.calendar
        const range = (new DateRange(activeDate)).rangeFromTo(calendarLayout)
        const isWithin = isWithinInterval(parseISO(previousDate), { start: range.from, end: range.to })
        const isWeekView = calendarLayout === 'LAYOUT_WEEK'

        if (!isWeekView || !isWithin || forceReload) {
          const from = format(range.from, "yyyy-MM-dd")
          const to = format(range.to, "yyyy-MM-dd")
          this.requestAppointments({ from, to, teachers })
        }
      } else {
        const pickedMonth = this.$store.state.calendar.activeMonth
        const newMonthsIsPicked = this.$store.state.calendar.calendarMonth !== pickedMonth
        const activeDate = newMonthsIsPicked ? parseISO(pickedMonth) : parseISO(this.firstDayOfMonth)
        const activeDateIso = !isSunday(activeDate) ? activeDate : addDays(activeDate, 1);

        if (newMonthsIsPicked || forceReload) {
          const from = format(startOfWeek(startOfMonth(activeDateIso), { weekStartsOn: 1 }), "yyyy-MM-dd");
          const to = format(endOfWeek(addDays(endOfMonth(activeDateIso), 1), { weekStartsOn: 1 }), "yyyy-MM-dd");
          this.requestAppointments({ from, to, teachers, pickedMonth })
        }
      }
    },

    requestAppointments(data) {
      this.$store.commit(calendarMutationTypes.SET_CALENDAR_LOADER);
      const { from, to, teachers, pickedMonth } = data
      calendarService
        .load({ from, to, teachers })
        .then((resp) => {
          if (this.isSchoolAdministrator) {
            AppointmentModel.create({ data: resp.data });
          } else {
            AppointmentModel.insert({ data: resp.data });
            this.$store.commit(calendarMutationTypes.SET_CALENDAR_MONTH, pickedMonth);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.$store.commit(calendarMutationTypes.SET_CALENDAR_LOADER);
          const activeEventId = this.$route.query.event_id;
          if (activeEventId) {
            const event = AppointmentModel.find(activeEventId);
            if (event) {
              this.showAppointmentModal(event);
            } else {
              this.$router.replace({ ...this.$route.query });
            }
          }
        });
    }
  },
};
