export default {
  computed: {
    mdLandscapeAndUp() {
      return (
        (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.mdAndUp) &&
        window.innerHeight < window.innerWidth
      );
    },
    oneMinuteSize() {
      return (this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.mdAndUp) &&
        window.innerHeight < window.innerWidth
        ? 2
        : 1;
    },
  },
};
