<script>
import InsightsIcon from "@/components/insights/InsightsIcon.vue";
import InsightsItemEvaluations from "@/components/insights/InsightsItemEvaluations.vue";
import user from '@/utils/mixins/user'
import EvaluationModel from '@/store/models/insights/EvaluationModel'

export default {
  name: "InsightsItem",
  mixins: [user],
  components: {InsightsItemEvaluations, InsightsIcon},
  props: {
    item: Object,
    showItemName: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    itemLabel() {
      if (this.showItemName) {
        return this.item.name + (this.item.description ? ' - ' + this.item.description : '');
      } else {
        return this.item.description || this.item.name;
      }
    },
    evaluation() {
      return EvaluationModel.find(this.item.pivot?.evaluationId) || {}
    },
    itemCheckboxOffIcon () {
      const score = this.item.pivot?.score || 0;
      return `mdi-numeric-${score}-box-outline`
    },
    showBothScores() {
      return this.evaluation?.selfEvaluation && this.evaluation?.isPublishedByStudent
    },
    studentScoreIcon() {
      const studentScore = this.item.pivot?.studentScore || 0;
      return `mdi-numeric-${studentScore}-box-outline`
    },
    markAsFinal () {
      if (this.isStudent && this.evaluation?.selfEvaluation && !this.evaluation?.isPublishedByStudent) return
      return this.item.pivot.isFinal
    },
    showComment() {
      if (this.isStudent && this.evaluation?.selfEvaluation && !this.evaluation?.isPublishedByStudent) return
      return this.item.pivot && this.item.pivot.comment !== null
    }
  }
}
</script>

<template>
  <div class="d-flex flex-row align-center py-1 pr-2 template-item">
    <div class="d-flex flex-row flex-shrink-1 flex-grow-1">
      <div class="d-flex flex-column">
        <div class="d-flex flex-row align-center">
          <template v-if="item.pivot">
            <v-simple-checkbox
              class="custom-checkbox"
              readonly
              dense
              :value="item.pivot && markAsFinal"
              :ripple="false"
              color="secondary"
              on-icon="mdi-check-decagram"
              :off-icon="itemCheckboxOffIcon"
            />
            <span v-if="showBothScores" class="student-score">
              <v-icon>{{ studentScoreIcon }}</v-icon>
            </span>
          </template>
          <insights-icon :icon="item.icon" size="32" class="mr-2" v-else/>
          <div class="d-flex flex-column">
            <span class="item-label" :class="{'success--text': item.isFinal}">{{ itemLabel }}</span>
            <insights-item-evaluations
              v-if="item.evaluations && item.evaluations.length"
              :evaluations="item.evaluations"
            />
          </div>
        </div>
        <span style="font-size: 12px;" class="pre-wrap" v-if="showComment">
          {{ $t('insights.comment') }}: {{ item?.pivot?.comment }}
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.template-item {
  &:hover {
    //background: #eee;
  }
}
.item-score, .item-label {
  font-size: 14px !important;
}

.student-score{
  margin-left: -8px;
  margin-right: 8px;
}

.custom-checkbox .v-icon:not(.v-icon--on) {
  color: var(--v-secondary-base);
}
</style>
