<template>
  <v-container fluid>
    <v-card :loading="loading || filtering">
      <v-toolbar elevation="3">
        <v-card-title>{{ $t("label.students_who_didnt_pay") }}</v-card-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text v-if="!loading && !filtering">
        <v-expansion-panels v-model="activeStudent" v-if="students.items.length">
              <v-expansion-panel v-for="student in students.items" :key="student.id">
                <v-expansion-panel-header v-slot="{ open }">
                  <v-row no-gutters>
                    <v-col cols="4">
                      {{ student.fullName }}
                    </v-col>
                    <v-col cols="8" class="text--secondary">
                      <span v-if="open">{{ $t('label.priceList') }}: {{ student.priceListId  ? student.priceListName : $t('label.notSpecified') }}</span>
                      <v-row
                          v-else
                          no-gutters
                          style="width: 100%"
                      >
                        <v-col cols="6">
                          {{ $t('label.created') }} {{ student.createdAt }}
                        </v-col>
                        <v-col cols="6">
                        <span v-if="student.bookAndPay" :class="student.balance <= 0 ? 'red--text' : 'green--text'">
                          {{ student.balance | currency }}
                        </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <UnpaidStudentLicenseRow
                    v-for="license in student.licenses"
                    :key="license.id"
                    :student-id="student.id"
                    :price-category-id="student.priceListId"
                    :license="license"
                    @applied="applied"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
        <empty-box v-else />
      </v-card-text>
      <v-card-text v-else>
        <v-skeleton-loader type="table-row-divider@3" loading/>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import studentsService from "@/services/studentsService";
import pricesService from '@/services/pricesService'
import ProductPriceModel from '@/store/models/ProductPriceModel'
import productsService from '@/services/productsService'
import OneTimeProductModel from '@/store/models/OneTimeProductModel'
import UnpaidStudentLicenseRow from '@/components/UnpaidStudentLicenseRow.vue'
import keyBy from '@/helpers/keyBy'

export default {
  name: "UnpaidStudents",
  components: { UnpaidStudentLicenseRow },
  data() {
    return {
      filtering: false,
      students: {
        items: [],
        count: 0
      },
      models: {},
      activeStudent: null,
      paying: false,
      payingStudent: null,
      payingLicense: null,
      loadingStudents: true,
      loadingProducts: true,
      loadingPrices: true
    }
  },
  computed: {
    loading() {
      return this.loadingStudents || this.loadingProducts || this.loadingPrices
    }
  },
  mounted () {
    studentsService
      .unpaidStudents()
      .then(resp => {
        const data = resp.data
        // generate models object to be used as v-model values in every form
        const models = {}
        data.items.forEach(student => models[student.id] = keyBy(student.licenses, 'id'))
        this.models = models
        this.students = data
      })
      .finally(() => {
        this.loadingStudents = false
      })

    productsService
      .oneTimeProducts()
      .then(response => {
        OneTimeProductModel.create({data: response.data})
      })
      .finally(() => {
        this.loadingProducts = false
      })

    pricesService
      .load({
        productType: 'one-time-product'
      })
      .then(response => {
        ProductPriceModel.create({data: response.data})
      })
      .finally(() => {
        this.loadingPrices = false
      })
  },
  methods: {
    applied({ studentId, licenseId, basicPriceDisabled, learningMaterialDisabled }) {
      this.models[studentId][licenseId].basicPriceDisabled = basicPriceDisabled
      this.models[studentId][licenseId].learningMaterialDisabled = learningMaterialDisabled

      if (basicPriceDisabled && learningMaterialDisabled) {
        const studentIndex = this.students.items.findIndex(item => item.id === studentId)

        if (studentIndex !== -1) {
          const licenseIndex = this.students.items[studentIndex].licenses.findIndex(item => item.id === licenseId)
          this.students.items[studentIndex].licenses.splice(licenseIndex, 1)

          if(this.students.items[studentIndex].licenses.length === 0) {
            this.students.items.splice(studentIndex, 1)
            this.students.count--
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>