<template>
<v-menu
  v-if="type === 'menu'"
  v-model="value"
  :z-index="menuOptions.zIndex"
  :left="menuOptions.left"
  :right="menuOptions.right"
  :offset-x="menuOptions.offsetX"
  :offset-y="menuOptions.offsetY"
  :nudge-width="menuOptions.nudgeWidth"
  :nudge-right="menuOptions.nudgeRight"
  :nudge-left="menuOptions.nudgeLeft"
  :nudge-bottom="menuOptions.nudgeBottom"
  :close-on-content-click="menuOptions.closeOnContentClick"
  :max-width="menuOptions.maxWidth"
  :min-width="menuOptions.minWidth"
>
  <template v-slot:activator="{ on, attrs, value }">
    <slot name="activator" :on="on" :attr="attrs" :value="value"/>
  </template>

  <slot :close="close"/>
</v-menu>

<v-dialog
  v-else-if="type === 'dialog'"
  scrollable
  v-model="value"
  :max-width="dialogOptions.maxWidth"
  :transition="dialogOptions.transition"
  :fullscreen="dialogOptions.fullScreen"
>
  <template v-slot:activator="{ on, attrs }">
    <slot name="activator" :on="on" :attr="attrs"/>
  </template>

  <slot :close="close"/>
</v-dialog>
</template>

<script>
export default {
  name: 'ShowsAs',
  props: {
    type: {
      type: String,
      default: 'menu'
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      value: false,
      menuOptions: {
        zIndex: undefined,
        left: false,
        right: false,
        offsetX: false,
        offsetY: false,
        nudgeWidth: 0,
        nudgeLeft: 0,
        nudgeBottom: 0,
        closeOnContentClick: false,
        maxWidth: undefined,
        minWidth: undefined
      },
      dialogOptions: {
        maxWidth: undefined,
        transition: "dialog-transition",
        fullScreen: false
      }
    }
  },
  watch: {
    value(val) {
      this.$emit(val ? "open" : "close");
    }
  },
  mounted () {
    Object.keys(this.options).forEach(key => {
      if (key in this.menuOptions) {
        this.menuOptions[key] = this.options[key]
      }
      if (key in this.dialogOptions) {
        this.dialogOptions[key] = this.options[key]
      }
    })
  },
  methods: {
    open() {
      this.value = true
      this.$emit("open");
    },
    close() {
      this.value = false
      this.$emit("close");
    }
  }
}
</script>

<style scoped>
</style>
