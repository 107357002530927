<template>
  <v-row class="duration-amount">
    <v-col col="6" sm="6">
      <ValidationProvider
        name="lessons-amount"
        rules="required"
        v-slot="{ errors }">
        <v-text-field
          outlined
          readonly
          dense
          hide-details
          :label="$t('form.lessons_amount')"
          :error="errors.length > 0"
          :disabled="disabled"
          v-model="model.lessonsAmount">
          <v-icon
            @click="decreaseAmount"
            :disabled="disabled"
            slot="prepend-inner"
            color="red"
            >mdi-minus</v-icon
          >
          <v-icon
            @click="increaseAmount"
            :disabled="disabled"
            slot="append"
            color="green"
            >mdi-plus</v-icon
          >
        </v-text-field>
      </ValidationProvider>
    </v-col>
    <v-col col="6" sm="6">
      <ValidationProvider
        name="lesson-duration"
        rules="required"
        v-slot="{ errors }">
        <v-text-field
          outlined
          readonly
          dense
          hide-details
          :label="$t('form.lesson_duration')"
          :error="errors.length > 0"
          :disabled="disabled"
          v-model="model.lessonDuration">
          <v-icon
            @click="decreaseDuration"
            :disabled="disabled"
            slot="prepend-inner"
            color="red"
            >mdi-minus</v-icon
          >
          <v-icon
            @click="increaseDuration"
            :disabled="disabled"
            slot="append"
            color="green"
            >mdi-plus</v-icon
          >
        </v-text-field>
      </ValidationProvider>
    </v-col>
  </v-row>
</template>

<script>
import { addMinutes, format, parseISO } from "date-fns";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AmountDurationFields",
  data() {
    return {
      datePicker: false,
      visiblePicker: false,
      pickedHour: null,
    };
  },
  props: {
    eventDuration: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    "model.teacher"() {
      // default PLACEHOLDER & PRACTICE lessonsAmount is being set to 2
      if (
        this.model.lessonsAmount === 1 &&
        !this.model.id &&
        ["PLACEHOLDER", "PRACTICE"].includes(this.model.type)
      ) {
        this.model.lessonsAmount++;
      }
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
  },
  methods: {
    ...mapActions("appointments", ["eventErrorAlertToggle"]),
    calcEndTime() {
      this.$nextTick(() => {
        const endTime = addMinutes(
          parseISO(this.model.start),
          this.eventDuration
        );
        this.model.endTime = format(endTime, "HH:mm");
      });
    },
    decreaseAmount() {
      if (this.model.lessonsAmount > 1) {
        this.model.lessonsAmount--;
        this.calcEndTime();
      }
    },
    increaseAmount() {
      if (this.model.lessonsAmount < 5) {
        this.model.lessonsAmount++;
        this.calcEndTime();
      }
    },
    decreaseDuration() {
      if (this.model.lessonDuration > 15) {
        this.model.lessonDuration = this.model.lessonDuration - 5;
        this.calcEndTime();
      }
    },
    increaseDuration() {
      if (this.model.lessonDuration < 90) {
        this.model.lessonDuration = this.model.lessonDuration + 5;
        this.calcEndTime();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  text-align: center;
  &.disabled {
    opacity: 0.4;
  }
  i {
    margin-top: 5px;
    border: 1px solid #9e9e9e;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 4px;
    background: #fff;
    font-size: 1.3em;
    z-index: 5;
  }
  .line {
    border-bottom: 1px solid #9e9e9e;
    width: 300%;
    position: relative;
    left: -100%;
    top: -15px;
    z-index: 3;
  }
}
</style>
