<template>
    <v-alert colored-border border="left" elevation="4" :color="vehicle.color">
        <div class="text-h6">
            {{ vehicle.make }} {{ vehicle.model }}, {{ vehicle.year }}
        </div>
        {{ vehicle.licensePlate }}
    </v-alert>
</template>

<script>
export default {
  name: "VehicleAlert",
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  }
}
</script>

<style scoped>

</style>