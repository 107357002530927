import api from "@/utils/api";

export default {
  load: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("/school-administrators", { params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  create(params) {
    return new Promise((resolve, reject) => {
      api
        .post("/school-administrators", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);1
    });
  },
  loadFormData() {
    return new Promise((resolve, reject) => {
      api
        .get("/school-administrators/formData")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadItem(teacherId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/school-administrators/${teacherId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  update(schoolAdministratorId, params) {
    return new Promise((resolve, reject) => {
      api
        .post(`/school-administrators/${schoolAdministratorId}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  destroy(schoolAdministratorId) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/school-administrators/${schoolAdministratorId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  makeActive(schoolAdministratorId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(
          `/school-administrators/${schoolAdministratorId}/make-active`,
          params
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
