<template>
  <v-row v-if="is18yo && unapprovedParents.length" dense>
    <v-col>
      <v-card>
        <v-card-title class="py-2 d-flex justify-space-between warning-color">
          <div class="d-flex flex-column">
            <span>{{ $t('label.observation_permission') }}</span>
          </div>
        </v-card-title>

        <v-divider/>

        <v-card-text :class="$vuetify.breakpoint.mobile ? 'pa-3' : ''">
          <strong>{{ $t("messages.do_you_approve_that_users_can_view_account_data") }}</strong>
          <ParentsList :parents="unapprovedParents"/>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import user from '@/utils/mixins/user'
import ParentsList from '@/components/ParentsList.vue'
import ParentModel from '@/store/models/ParentModel'
import { mapActions } from 'vuex'

export default {
  name: "UnapprovedParentsWidget",
  components: { ParentsList },
  mixins: [user],
  mounted () {
    this.fetchParents()
  },
  computed: {
    unapprovedParents() {
      return ParentModel.query()
        .where(parent => !parent.isPermissionGranted)
        .get() || []
    },
    loading() {
      return this.$store.state.parents.loadingParents
    },
    is18yo () {
      return this.currentUser.is18yo;
    },
  },
  methods: {
    ...mapActions("parents", ["fetchParents"]),
  },
}
</script>

<style scoped>
  .warning-color {
    background-color: orange;
    color: #fff;
  }
</style>
