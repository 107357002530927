<template>
  <v-dialog
    ref="menu"
    v-model="datePicker"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    persistent
    max-width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :disabled="disabled"
        :label="$t('label.date')"
        v-model="dateFormatted"
        readonly
        outlined
        dense
        hide-details
        v-bind="attrs"
        v-on="on"></v-text-field>
    </template>

    <v-date-picker
      v-model="date"
      :allowed-dates="allowedDates"
      no-title
      scrollable
      first-day-of-week="1">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="datePicker = false">{{
        $t("btn.cancel")
      }}</v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date)">{{
        $t("btn.ok")
      }}</v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import { format, parseISO } from "date-fns";
import LocalizationManager from "@/helpers/LocalizationManager";

export default {
  name: "DateField",
  data() {
    return {
      datePicker: false,
    };
  },
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    dateProp: {
      type: String,
    },
    allowedDates: {
      type: Function,
      default: () => null,
    },
  },
  computed: {
    date: {
      set(value) {
        this.$emit("emitDateChange", value);
      },
      get() {
        return format(parseISO(this.dateProp), "yyyy-MM-dd");
      },
    },
    dateFormatted() {
      return format(parseISO(this.dateProp), "dd.MM.yyyy, EEEE", { locale: LocalizationManager.dateFnsLocale });
    },
  },
};
</script>

<style lang="scss" scoped></style>
