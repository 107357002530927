<template>
  <ValidationProvider
      name="simulator"
      :rules="`${validation ? 'required' : ''}`"
      v-slot="{ errors }"
  >
    <v-text-field
      :error="errors.length > 0"
      v-model="simulatorName"
      :disabled="disabled"
      :label="$t('form.device_name')"
      outlined
      dense
      hide-details
    />
  </ValidationProvider>
</template>

<script>
export default {
  name: "SimulatorNameField",
  props: {
    validation: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    simulatorNameProp: {
      type: String,
    },
  },
  computed: {
    simulatorName: {
      set(value) {
        this.$emit("emitSimulatorNameChange", value);
      },
      get() {
        return this.simulatorNameProp;
      },
    },
  },
};
</script>
