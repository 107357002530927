<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <template v-if="$route.name === 'student.education.progress'">
      <v-row v-if="loading">
        <v-col cols="12" md="6" lg="4" v-for="licensesId in currentUser.licensesIds" :key="licensesId">
          <EducationLicenseProgressCardLoader/>
        </v-col>
      </v-row>

      <template v-else-if="currentUser.licensesIds.length && !loading">
        <v-row>
          <v-col cols="12" md="6" lg="4" v-for="license in licenses" :key="license.id">
            <EducationLicenseProgressCard
                :license-id="license.id"
                :license-progress="licensesProgress[license.id]"
                :details-to="{name: 'student.education.license.progress', params: { id: currentUser.id, licenseId: license.id }}"
                :license-requirements="getLicenseRequirements(license.id)"
            />
          </v-col>
        </v-row>
      </template>

      <empty-box v-else/>
    </template>

    <v-card v-else>
      <v-toolbar elevation="3">
        <v-btn class="mr-4" icon @click="goBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <span class="title">{{ $t("label.education") }}</span>

        <div v-if="license" class="d-flex mt-1 text--secondary">
          <div class="subtitle-1">
            <span class="mx-2">&#x2022;</span>{{ license.name }}
          </div>
          <div v-if="isEducationLicenseInsightsRoute" class="subtitle-1">
            <span class="mx-2">&#x2022;</span>Insights
          </div>
        </div>
      </v-toolbar>

      <v-card-text>
        <router-view/>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import user from "@/utils/mixins/user";
import studentsService from '@/services/studentsService'
import EducationLicenseProgressCard from '@/components/student/EducationLicenseProgressCard.vue'
import EducationLicenseProgressCardLoader from '@/components/skeleton-loaders/EducationLicenseProgressCardLoader.vue'
import LicenseModel from '@/store/models/LicenseModel'

export default {
  name: "StudentEducationProgress",
  mixins: [user],
  components: {
    EducationLicenseProgressCardLoader,
    EducationLicenseProgressCard
  },
  data () {
    return {
      loading: true,
      licensesProgress: {}
    }
  },
  computed: {
    license() {
      return LicenseModel.find(this.$route.params.licenseId)
    },
    licenses() {
      return LicenseModel.findIn(this.currentUser.licensesIds) || []
    },
    isEducationLicenseRoute() {
      return this.$route.name === 'student.education.license.progress'
    },
    isEducationLicenseInsightsRoute() {
      return this.$route.name === 'student.education.license.insights'
    }
  },
  mounted () {
    studentsService
        .studentProgress(this.currentUser.id, { extended: true })
        .then((response) => {
          const progressData = {}
          response.data.forEach((item) => progressData[item.licenseId] = item)
          this.licensesProgress = progressData
        })
        .finally(() => this.loading = false)
    },
    methods: {
      goBack() {
        if (this.isEducationLicenseInsightsRoute) {
          this.$router.push({
            name: 'student.education.license.progress',
            params: {licenseId: this.$route.params.licenseId}
          })
          return
        }

        this.$router.push({ name: 'student.education.progress' })
      },
      getLicenseRequirements(licenseId) {
        return this.currentUser.licensesRequirements.find(item => item.licenseId === licenseId) || {}
      }
    }
  }
</script>

<style scoped></style>
