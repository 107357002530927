import { render, staticRenderFns } from "./EventSubscribedStudents.vue?vue&type=template&id=4957ab15&scoped=true"
import script from "./EventSubscribedStudents.vue?vue&type=script&lang=js"
export * from "./EventSubscribedStudents.vue?vue&type=script&lang=js"
import style0 from "./EventSubscribedStudents.vue?vue&type=style&index=0&id=4957ab15&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4957ab15",
  null
  
)

export default component.exports