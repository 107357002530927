<script>
export default {
  name: "TrueFalseWithTooltip",
  props: {
    checkValue: {
      type: Boolean,
      required: true
    },
    tooltipText: {
      required: true
    }
  }
}
</script>

<template>
  <v-tooltip top v-if="checkValue">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        color="success"
        v-bind="attrs"
        v-on="on"
      >
        mdi-check
      </v-icon>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
  <v-icon v-else color="error">mdi-close</v-icon>
</template>

<style scoped>

</style>