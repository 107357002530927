import router from "@/router";

export default class OpenStudentPayments {
  constructor(studentId) {
    this.studentId = studentId;
  }

  run() {
    router.push({ name: "students.student.finances", params: { id: this.studentId } });
  }
}
