<template>
  <div class="ma-0 py-2 d-flex justify-end">
    <v-dialog
      v-model="dialog"
      eager
      max-width="700"
      :persistent="!eventSignature">
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-toolbar-title>{{
            eventSignature ? $t("label.show_signature") : $t("label.sign")
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text class="mt-10">
          <v-sheet outlined rounded>
            <VueSignaturePad height="200px" width="100%" ref="signaturePad" />
          </v-sheet>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="!eventSignature" color="primary" text @click="save">{{
            $t("btn.save")
          }}</v-btn>
          <v-btn v-if="!eventSignature" color="primary" text @click="clear">{{
            $t("btn.clear")
          }}</v-btn>
          <v-btn color="primary" text @click="dialog = false">{{
            $t("btn.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn color="primary" @click="open(eventSignature)" depressed outlined>
      <v-icon class="mr-2">mdi-signature-freehand</v-icon>
      {{ eventSignature ? $t("label.show_signature") : $t("label.sign") }}
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import eventService from "@/services/eventService";
import EventModel from "@/store/models/EventModel";

export default {
  name: "SignatureField",
  props: {
    eventId: {
      type: [Number, String],
    },
    eventType: {
      type: String,
    },
    eventSignature: {
      type: String,
    },
  },
  data: () => ({
    dialog: false,
  }),
  watch: {
    dialog(value) {
      if (value) {
        this.$nextTick(() => {
          this.clear();
          this.$refs.signaturePad.resizeCanvas();
        });
      }
    },
  },
  methods: {
    ...mapActions("appointments", ["finishEventCreation"]),
    open(signature) {
      this.$refs.signaturePad.openSignaturePad();
      this.dialog = !this.dialog;
      if (signature) {
        this.$nextTick(() => {
          this.$refs.signaturePad.resizeCanvas();
          this.$refs.signaturePad.fromDataURL(signature);
          this.$refs.signaturePad.lockSignaturePad();
        });
      }
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) return;
      eventService.sign(this.eventId, { signature: data }).then((resp) => {
        EventModel.insert({ data: resp.data });
        this.dialog = false;
        this.finishEventCreation();
      });
    },
  },
};
</script>
