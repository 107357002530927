<script>
import {mapGetters} from "vuex";
import markdownParser from "@/utils/mixins/markdownParser";

export default {
  name: "StudentsDirectoryTutorialDialog",
  mixins: [markdownParser],
  data() {
    return {
      stepper: 1,
      dialog: false,
      openOnStartup: false,
    }
  },
  mounted() {
    const storedValue = localStorage.getItem('openStudentsDirectoryTutorial');
    this.openOnStartup = storedValue !== null ? storedValue === 'true' : false;
    if (! storedValue || this.openOnStartup) this.open();
  },
  computed: {
    ...mapGetters('studentsDirectory', ['treeGroupTypes'])
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
      localStorage.setItem('openStudentsDirectoryTutorial', this.openOnStartup)
    }
  }
}
</script>

<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="1300"
    @click:outside="close"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="white" icon v-bind="attrs" v-on="on">
        <v-icon>mdi-help-circle</v-icon>
      </v-btn>
    </template>
    <v-card flat tile>
      <v-stepper v-model="stepper" alt-labels elevation="0" flat>
        <v-stepper-header>
          <v-stepper-step
            :complete="stepper > 1"
            step="1"
            editable
            edit-icon="mdi-check"
          >
            {{ $t('studentsDirectory.tutorial.steps.welcome') }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="stepper > 2"
            step="2"
            editable
            edit-icon="mdi-check"
          >
            {{ $t('studentsDirectory.tutorial.steps.grouping') }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3" :complete="stepper > 3" editable edit-icon="mdi-check">
            {{ $t('studentsDirectory.tutorial.steps.searching') }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="4" :complete="stepper > 4" editable edit-icon="mdi-check">
            {{ $t('studentsDirectory.tutorial.steps.filtering') }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="5" :complete="stepper > 5" editable edit-icon="mdi-check">
            {{ $t('studentsDirectory.tutorial.steps.studentDetails') }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="6" :complete="stepper > 6" editable edit-icon="mdi-check">
            {{ $t('studentsDirectory.tutorial.steps.examList') }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items style="max-height: 500px; overflow: auto; margin-top: 1px">
          <v-stepper-content step="1" class="stepper-content">
            <p class="text-h6">{{ $t('studentsDirectory.tutorial.step_1.intro') }}</p>
            <p class="font-weight-bold" v-text="$t('studentsDirectory.tutorial.step_1.important')" />
            <p>{{ $t('studentsDirectory.tutorial.step_1.list_header') }}</p>

            <ul>
              <li
                v-for="i in [1, 2, 3, 4, 5, 6]"
                :key="`feature-${i}`"
                v-text="$t(`studentsDirectory.tutorial.step_1.feature_${i}`)"
                class="list-item"
              />
            </ul>
            <p class="mt-3">{{ $t('studentsDirectory.tutorial.step_1.outro') }}</p>
          </v-stepper-content>

          <v-stepper-content step="2" class="stepper-content">
            <v-row>
              <v-col>
                <p class="text-h6" v-text="$t('studentsDirectory.tutorial.step_2.intro')" />
                <p v-text="$t('studentsDirectory.tutorial.step_2.text_1')" />
                <p v-text="$t('studentsDirectory.tutorial.step_2.text_2')" />
                <p v-text="$t('studentsDirectory.tutorial.step_2.list_header')" />
                <ul>
                  <li
                    v-for="option in treeGroupTypes"
                    :key="option.value"
                    v-text="option.text"
                  />
                </ul>
                <p class="mt-3" v-text="$t('studentsDirectory.tutorial.step_2.outro')" />
              </v-col>
              <v-col cols="3" class="align-center">
                <v-img
                  eager
                  width="269"
                  :src="require('@/assets/img/students-directory-tutorial/step-2_1.png')"
                />
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="3" class="stepper-content">
            <p class="text-h6" v-text="$t('studentsDirectory.tutorial.step_3.intro')" />
            <p v-text="$t('studentsDirectory.tutorial.step_3.text_1')" />
            <v-img
              height="45"
              eager
              contain
              :src="require('@/assets/img/students-directory-tutorial/step-3_1.png')"
            />
            <p class="mt-3" v-text="$t('studentsDirectory.tutorial.step_3.text_2')" />
          </v-stepper-content>

          <v-stepper-content step="4" class="stepper-content">
            <p class="text-h6" v-text="$t('studentsDirectory.tutorial.step_4.intro')" />
            <p v-text="$t('studentsDirectory.tutorial.step_4.text_1')" />
            <p v-text="$t('studentsDirectory.tutorial.step_4.text_2')" />
            <p v-text="$t('studentsDirectory.tutorial.step_4.text_3')" />
            <v-img
              eager
              contain
              :src="require('@/assets/img/students-directory-tutorial/step-4_1.png')"
            />
            <p class="mt-3" v-text="$t('studentsDirectory.tutorial.step_4.text_4')" />
            <p v-text="$t('studentsDirectory.tutorial.step_4.text_5')" />
          </v-stepper-content>

          <v-stepper-content step="5" class="stepper-content">
            <p class="text-h6" v-text="$t('studentsDirectory.tutorial.step_5.intro')" />
            <p v-text="$t('studentsDirectory.tutorial.step_5.text_1')" />
            <p v-text="$t('studentsDirectory.tutorial.step_5.text_2')" />
            <v-img
              eager
              contain
              :src="require('@/assets/img/students-directory-tutorial/step-5_1.png')"
            />
            <p class="mt-3" v-text="$t('studentsDirectory.tutorial.step_5.text_3')" />
            <p v-text="$t('studentsDirectory.tutorial.step_5.text_4')" />
            <ul>
              <li
                v-for="i in [1, 2, 3, 4, 5, 6, 7]"
                :key="`block-${i}`"
                v-text="$t(`studentsDirectory.tutorial.step_5.block_${i}`)"
              />
            </ul>
            <p class="mt-3" v-text="$t('studentsDirectory.tutorial.step_5.text_5')" />
          </v-stepper-content>

          <v-stepper-content step="6">
            <p class="text-h6" v-text="$t('studentsDirectory.tutorial.step_6.intro')" />
            <v-alert type="info" outlined v-text="$t('studentsDirectory.tutorial.step_6.hint')" />
            <p v-text="$t('studentsDirectory.tutorial.step_6.text_1')" />
            <p v-text="$t('studentsDirectory.tutorial.step_6.text_2')" />
            <ol>
              <li
                  v-for="i in [1, 2, 3, 4, 5]"
                  :key="`block-${i}`"
                  v-html="htmlFromMarkdown($t(`studentsDirectory.tutorial.step_6.criterion_${i}`))"
              />
            </ol>
            <p v-text="$t('studentsDirectory.tutorial.step_6.text_3')" />
            <p v-text="$t('studentsDirectory.tutorial.step_6.text_4')" />
            <p v-text="$t('studentsDirectory.tutorial.step_6.text_5')" />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-divider />
      <v-card-actions class="align-center d-flex">
        <template v-if="stepper < 5">
          <v-btn  @click="stepper++" color="primary">
            {{ $t('btn.next') }}
          </v-btn>
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="close"
          >
            {{ $t('btn.close') }}
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            color="primary"
            @click="close"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-checkbox class="my-0 ml-4" hide-details v-model="openOnStartup" :label="$t('label.open_at_startup')" />
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.stepper-content {
  //max-height: 700px;
  //overflow-y: auto;
}
</style>
