<template>
  <v-col :cols="cols">
    <ValidationProvider
      name="students"
      :rules="`${validation ? 'required' : ''}`"
      v-slot="{ errors }">
      <v-autocomplete
        :error="errors.length > 0"
        v-model="studentsIds"
        :items="studentsList"
        item-value="id"
        :item-text="getFullName"
        deletable-chips
        :multiple="multiple"
        :small-chips="multiple"
        :clearable="clearable"
        :label="$tc('label.student_plurable', multiple ? 10 : 1)"
        :disabled="disabled"
        outlined
        dense
        hide-details
        :menu-props="menuProps">
        <template v-slot:item="data">
          <v-list-item-content>
            <v-list-item-title>{{ getFullName(data.item) }}</v-list-item-title>
            <v-list-item-subtitle v-if="data.item.bookAndPay">{{
              data.item.categoryName
            }}</v-list-item-subtitle>
          </v-list-item-content>
          <div class="other" v-if="data.item.bookAndPay">
            <v-list-item-subtitle>{{
              data.item.balance | currency
            }}</v-list-item-subtitle>
          </div>
        </template>
      </v-autocomplete>
    </ValidationProvider>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import ProductPriceModel from "@/store/models/ProductPriceModel";

export default {
  name: "AllStudentsField",
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    cols: {
      type: Number,
      required: true,
    },
    multiple: {
      type: Boolean,
      required: true,
    },
    clearable: {
      type: Boolean,
      required: true,
    },
    validation: {
      type: Boolean,
      default: false,
    },
    students: {
      type: Array,
    },
    menuProps: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("appointments", ["initialDurationGetter"]),
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    studentsIds: {
      get() {
        return this.multiple
          ? this.model.studentsIds
          : this.model.studentsIds
          ? this.model.studentsIds[0]
          : [];
      },
      set(val) {
        if (!this.multiple) {
          this.model.studentsIds = val ? [val] : [];
        } else {
          this.model.studentsIds = val;
        }
      },
    },
    studentsList() {
      const modelLicensesIds = [
        this.model.licenseId,
        ...this.model.licensesIds,
      ];

      if (
        !modelLicensesIds.length > 0 ||
        (this.model.type === "THEORY" && !this.model.lessonTypeId)
      )
        return [];

      const priceCategoriesIds = this.students.map(
        (student) => student.priceCategoryId
      );
      const products = ProductPriceModel.query()
        .where(
          (product) =>
            priceCategoriesIds.indexOf(product.priceCategoryId) !== -1
        )
        .where("licenseId", (licenseId) => modelLicensesIds.includes(licenseId))
        .where("productId", this.model.lessonTypeId)
        .get();

      const productsByCategory = {};
      products.forEach(
        (product) => (productsByCategory[product.priceCategoryId] = product)
      );

      return this.students.map((student) => {
        const product = productsByCategory[student.priceCategoryId] || {};
        const productPrice = product.price || 0;

        return {
          ...student,
          disabled:
            student.balance < productPrice &&
            this.model.lessonTypeGroup === "exam",
        };
      });
    },
  },
  methods: {
    getFullName(item) {
      return item.fullName;
    },
  },
};
</script>

<style lang="scss" scoped></style>
