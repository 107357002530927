<template>
  <v-dialog
    v-model="show"
    width="1010"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-toolbar class="mb-2" dark color="primary" dense>
        <v-toolbar-title>{{ $t("form.price_category") }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <ValidationObserver ref="form">
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                name="name"
                class="flex-grow-1"
                rules="required|min:3"
              >
                <v-text-field
                  class="list__search"
                  v-model="form.name"
                  :label="$t('form.name')"
                  :placeholder="$t('form.name')"
                  dense
                  outlined
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row v-if="!isEdit" class="ml-1" no-gutters>
            <v-col cols="12">
              <ValidationProvider v-slot="{ errors }" name="licensesIds">
                <div
                  class="text-body-1 mb-1"
                  :class="{ 'red--text': errors.length > 0 }">
                  {{ $t("form.licenses") }}
                </div>

                <v-chip-group v-model="form.licensesIds" column multiple>
                  <v-chip
                    class="text-uppercase"
                    :color="errors.length > 0 ? 'red' : ''"
                    filter
                    outlined
                    v-for="license in licensesOptions"
                    :key="license.value"
                    :value="license.value">
                    {{ license.text }}
                  </v-chip>
                </v-chip-group>

                <span class="red--text text-caption" v-if="errors.length > 0">{{ errors[0] }}</span>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-text>
      </ValidationObserver>

      <v-divider class="mt-auto" />

      <v-card-actions>
        <v-btn text @click="close">
          <v-icon v-if="$vuetify.breakpoint.mobile">mdi-close</v-icon>
          <span v-else>{{ $t("btn.close") }}</span>
        </v-btn>

        <v-spacer/>

        <v-btn
          color="primary"
          @click="save"
          :disabled="formLoading"
          :loading="formLoading"
        >
          <v-icon v-if="$vuetify.breakpoint.mobile">mdi-content-save</v-icon>
          <span v-else>{{ $t("btn.save") }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import currency from "@/utils/mixins/currency";
import licenses from "@/utils/mixins/licenses";
import ProductCategoryModel from "@/store/models/ProductCategoryModel";
import priceCategoriesService from '@/services/priceCategoriesService'
import productsService from '@/services/productsService'

export default {
  name: "PricesCategoryFormDialog",
  mixins: [licenses, currency],
  data: () => ({
    show: false,
    licenseId: null,
    products: [],
    formLoading: false,
    initialForm: {},
    isEdit: false,
    form: {
      id: null,
      name: "",
      licensesIds: [],
    },
  }),
  methods: {
    open(priceCat = null) {
      this.show = true;
      this.isEdit = priceCat !== null
      this.form.id = priceCat?.id ?? null

      this.$set(this.form, 'name', priceCat?.name ?? "")
    },
    close() {
      this.show = false;
      this.form = { ...this.initialForm };
      this.$refs.form.reset();
    },
    async save() {
      const response = await this.$refs.form.validate();

      if (response) {
        this.isEdit ? this.update() : this.create();
      }
    },
    create() {
      this.formLoading = true;
      priceCategoriesService
        .store({name: this.form.name, licensesIds: this.form.licensesIds})
        .then((response) => {
          ProductCategoryModel
            .insert({ data: response.data || {} })
            .then(response => {
              this.$emit("created", response.productCategories[0])
            });

          this.close();
        })
        .catch((error) => {
          const responseData = error.response.data || {};
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error", });
          }

          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    update() {
      this.formLoading = true;
      productsService
        .updatePriceCategory(this.form.id, { name: this.form.name })
        .then((response) => {
          this.$emit('update', response.data || {})
          this.close();
        })
        .catch((error) => {
          const responseData = error.response.data;
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error", });
          }

          this.$refs.form.setErrors(errors)
        })
        .finally(() => {
          this.formLoading = false;
        });
    }
  },
};
</script>

<style scoped></style>
