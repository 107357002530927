<template>
  <div class="page__view">
    <v-overlay class="align-start" :value="loading" absolute color="#ccc">
      <v-progress-circular
        class="mt-15"
        style="position: fixed"
        indeterminate
        color="primary"
      />
    </v-overlay>

    <slot v-if="itemsLength > 0" />

    <empty-box v-if="itemsLength === 0 && !loading" />
  </div>
</template>

<script>
export default {
  name: "PageView",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    itemsLength: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.page__view {
  position: relative;
  min-height: 100%;
  padding-bottom: 8px;
}
</style>
