<template>
  <div class="d-flex align-center flex-column">
    <h1 class="error-code">404</h1>
    <h3 class="error-text text--secondary mb-5">
      {{ $t("messages.page_not_found") }}
    </h3>

    <v-btn
      v-if="!authToken"
      :to="{ name: 'login' }"
      x-large
      color="primary"
      elevation="1">
      {{ $t("btn.to_sign_in") }}
    </v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "NotFound",
  computed: {
    ...mapState("auth", ["authToken"]),
  },
};
</script>

<style scoped lang="scss">
.error-code {
  font-family: monospace;
  font-size: 270px !important;
  margin: 0;
  font-weight: 200;
  color: #ddd;
}
.error-text {
  color: #444;
  font-family: monospace;
}
</style>
