<template>
  <div
    class="d-flex align-center justify-center flex-column"
    style="height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));">
    <v-icon color="green" size="200">mdi-check-circle</v-icon>
    <h1 class="text-h4">Zahlung erfolgreich</h1>
    <v-btn :to="{ name: 'balance' }" v-if="isMobileApp" class="mt-16"
      >Zurück</v-btn
    >
    <v-btn :href="url" v-else class="mt-16" @click="closeBrowser"
      >Zurück zur App</v-btn
    >
  </div>
</template>

<script>
import isMobileApp from "@/utils/mixins/isMobileApp";

export default {
  name: "PaymentSucceeded",
  mixins: [isMobileApp],
};
</script>

<style scoped lang="scss"></style>
