<script>
import ExpandablePanel from "@/components/ExpandablePanel.vue";

export default {
  name: "StudentNotes",
  components: {ExpandablePanel},
  props: {
    student: {
      type: Object,
      required: true
    },
  },
}
</script>

<template>
  <expandable-panel panel-name="studentClickAndLearnPanel">
    <template v-slot:title="{expanded}">
      {{ $t('label.only_notes') }}
      <v-badge inline color="grey lighten-1" :value="student.notes.length && ! expanded" :content="student.notes.length"/>
    </template>
    <v-simple-table dense v-if="student.notes.length">
      <tbody>
      <tr v-for="note in student.notes" :key="note.id">
        <td>{{ note.text }}</td>
      </tr>
      </tbody>
    </v-simple-table>
    <empty-box v-else />
  </expandable-panel>
</template>

<style scoped>

</style>