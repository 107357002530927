export default class SelectField {
  constructor(app, transform = false) {
    this.selectedLessonType = app?.id && app.type === 'PRACTICE' && !transform ?
      app.lessonTypeId :
      null
  }

  lessonTypeSelector(list, cascade) {
    if (this.selectedLessonType && cascade) return this.selectedLessonType

    const activeLessonType = list.find(type => type.disabled === false) || list[0]
    return activeLessonType.id
  }
}
