<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="true"
    offset-y
    bottom
    left
    z-index="99"
    v-if="enabledLocales.length"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon large v-on="on" v-bind="attrs" :disabled="saving">
        <FlagIcon :locale="$i18n.locale" />
      </v-btn>
    </template>

    <v-list dense>
      <v-list-item v-for="item in enabledLocales" :key="item.value" @click="setLocale(item.value)">
        <v-list-item-content>
          <v-list-item-title>
            {{ item.text }} &nbsp;
            <v-chip outlined color="warning" label x-small v-if="item.value !== 'de'">beta</v-chip>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon  small v-if="item.value === currentLocale">mdi-check</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import FlagIcon from "@/components/FlagIcon";
import SUPPORTED_LOCALES from "@/const/locales";
import LocalizationManager from "@/helpers/LocalizationManager";
import { mapGetters } from "vuex";
import user from "@/utils/mixins/user";
import userService from '@/services/userService'

export default {
  name: "LanguageSwitcher",
  mixins: [user],
  components: { FlagIcon },
  data() {
    return {
      menu: false,
      locales: SUPPORTED_LOCALES.map(item => ({ text: item.name, value: item.code })),
      saving: false
    };
  },
  methods: {
    setLocale(locale) {
      this.saving = true
      userService.updateLanguage(locale)
      .then(() => LocalizationManager.set(locale, this.$vuetify))
      .catch(error => {
        const responseData = error?.response?.data;

        if (responseData?.message) {
          this.$snackbar.show({ message: responseData.message, color: "error" });
        }
      })
      .finally(() => {
        this.saving = false
      })
    }
  },
  computed: {
    currentLocale() {
      return LocalizationManager.getCurrent()
    },
    enabledLocales() {
      if (this.isElter) return this.locales;

      return this.school.enabledLocales ?
        this.locales.filter(item => this.school.enabledLocales.includes(item.value) || item.value === "de") :
        [];
    },
    ...mapGetters('school', ['school'])
  },
  mounted() {
    if (! this.enabledLocales.map(item => item.value).includes(this.currentLocale)) {
      this.setLocale('de')
    }
  }
}
</script>

<style scoped>

</style>
