<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row dense>
      <v-col>
        <div class="school-card">
          <v-sheet class="pa-5 d-flex flex-column" rounded elevation="2">
            <div
              class="d-flex flex-column justify-space-between"
              style="width: 100%">
              <div
                class="d-flex"
                :class="{
                  'flex-column align-center': $vuetify.breakpoint.smAndDown,
                }">
                <div
                  style="width: 160px"
                  :class="{ 'mb-2': $vuetify.breakpoint.smAndDown }">
                  <v-img
                    v-if="school.avatar"
                    class="mx-auto"
                    :src="school.avatar"
                    contain
                    aspect-ratio="1.777"
                    width="100%"
                  />
                </div>

                <div class="d-flex flex-column text-center ml-4">
                  <span class="title">{{ school.name }}</span>
                  <span class="text-caption">{{ address }}</span>

                  <div
                    class="d-flex mt-2 justify-space-between"
                    style="width: 100%">
                    <v-btn icon small @click="openSchoolRoom"
                      ><v-icon>mdi-message</v-icon></v-btn
                    >
                    <v-btn
                      v-if="school.phone || school.mobile"
                      icon
                      small
                      :href="`tel:${school.phone || school.mobile}`">
                      <v-icon>mdi-phone</v-icon>
                    </v-btn>
                    <v-btn icon small :href="`mailto:${school.email}`"
                      ><v-icon>mdi-at</v-icon></v-btn
                    >
                    <!--  TEMPORARY ROUGH SOLUTION FOR Motorrad Fahrschule Köln SCHOOL. THIS MUST BE FIXED IN FUTURE -->
                    <template v-if="school.id === 26">
                      <v-btn
                        v-if="school.facebookLink"
                        icon
                        small
                        target="_blank"
                        :href="school.facebookLink"
                        ><v-icon>mdi-youtube</v-icon></v-btn
                      >
                      <v-btn
                        v-if="school.instagramLink"
                        icon
                        small
                        target="_blank"
                        :href="school.instagramLink"
                        ><v-icon>mdi-account-circle-outline</v-icon></v-btn
                      >
                    </template>

                    <template v-else>
                      <v-btn
                        v-if="school.facebookLink"
                        icon
                        small
                        target="_blank"
                        :href="school.facebookLink"
                        ><v-icon>mdi-facebook</v-icon></v-btn
                      >
                      <v-btn
                        v-if="school.instagramLink"
                        icon
                        small
                        target="_blank"
                        :href="school.instagramLink"
                        ><v-icon>mdi-instagram</v-icon></v-btn
                      >
                    </template>
                    <!--  TEMPORARY ROUGH SOLUTION FOR Motorrad Fahrschule Köln SCHOOL. THIS MUST BE FIXED IN FUTURE -->
                  </div>
                </div>
              </div>
            </div>
          </v-sheet>
        </div>
      </v-col>
    </v-row>

    <ChildInfoCard v-if="isElterWithSelectedStudent" />

    <UnapprovedParentsWidget v-if="isExactlyStudent" />

    <template v-if="isStudentRegistered">
      <v-row dense>
        <v-col>
          <div class="registered-student-alert">
            <v-card elevation="3">
              <v-card-title class="text-break">{{ $t("label.education_profile_not_ready") }}</v-card-title>
              <v-card-text>{{ $t("label.education_profile_not_ready_text") }}</v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <UnsignedDocumentsWidget />

      <v-row dense>
        <v-col>
          <UpcomingAppointments :daily="false"/>
        </v-col>
      </v-row>

      <awaiting-evaluation-widget />

      <student-limits />

      <v-row dense v-if="currentUser.hasClickAndLearn">
        <v-col>
          <student-credentials-widget />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-card :loading="reloadingLicenseStats">
            <v-card-title class="py-2 d-flex justify-space-between">
              <div class="d-flex flex-column">
                <span>{{ $t('label.progress_by_category') }}</span>
              </div>
              <v-btn
                icon
                small
                @click="reloadLicenseStats"
                :loading="reloadingLicenseStats"
                :disabled="reloadingLicenseStats"
              >
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text>
              <template v-if="currentUserLicensesIds.length > 0">
                <template v-if="loadingProgress">
                  <StudentDashboardProgressLoader  v-for="license in currentUser.licensesIds" :key="license" />
                </template>

                <LicenseProgressCard
                    v-else
                    v-for="progress in licensesProgress"
                    :key="progress.licenseId"
                    :student-id="currentUser.id"
                    :license-id="progress"
                    :progress="progress"
                />

              </template>
              <empty-box v-else />

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import user from "@/utils/mixins/user";
import messenger from "@/utils/mixins/messenger";
import studentsService from '@/services/studentsService'
import SchoolDataLoader from '@/helpers/schoolDataLoader'

import UnapprovedParentsWidget from "@/components/widgets/dashboard/UnapprovedParentsWidget.vue";
import UpcomingAppointments from "@/components/widgets/dashboard/UpcomingAppointments";
import AwaitingEvaluationWidget from "@/components/widgets/dashboard/AwaitingEvaluationWidget.vue";
import LicenseProgressCard from '@/components/student/LicenseProgress'
import StudentDashboardProgressLoader from '@/components/skeleton-loaders/StudentDashboardProgressLoader'
import StudentCredentialsWidget from "@/components/clickandlearn/StudentCredentialsWidget.vue";
import StudentLimits from "@/components/widgets/dashboard/StudentLimits.vue";
import ChildInfoCard from "@/components/ChildInfoCard.vue";
import UnsignedDocumentsWidget from '@/components/widgets/dashboard/UnsignedDocumentsWidget.vue'

export default {
  name: "StudentDashboard",
  mixins: [user, messenger],
  components: {
    UnsignedDocumentsWidget,
    ChildInfoCard,
    StudentLimits,
    StudentCredentialsWidget,
    LicenseProgressCard,
    UnapprovedParentsWidget,
    UpcomingAppointments,
    AwaitingEvaluationWidget,
    StudentDashboardProgressLoader
  },
  data: () => ({
    loadingProgress: false,
    licensesProgress: [],
    reloadingLicenseStats: false,
    licenses: [],
    eventTypes: [],
    nextEvents: [],
    loadingNextEvents: true,
  }),
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("school", ["school", "address"]),
    currentUserLicensesIds() {
      return this.currentUser?.licensesIds || []
    }
  },
  mounted() {
    this.loadingProgress = true;
    SchoolDataLoader.load({ only: ["v"] });
    this.loadStats()
  },
  methods: {
    ...mapActions("messenger", { selectRoom: "selectRoom" }),
    ...mapActions("school", { loadSchool: "load" }),
    loadStats() {
      studentsService
        .studentProgress(this.currentUser.id, this.currentUser.licensesIds)
        .then((response) => this.licensesProgress = response.data)
        .finally(() => {
          this.loadingProgress = false;
          this.reloadingLicenseStats = false;
        });
    },

    reloadLicenseStats() {
      this.reloadingLicenseStats = true
      this.loadStats()
    },
    async openSchoolRoom() {
      this.$router.push({ name: "messenger" }).then(() => {
        this.openDialogRoom({
          name: this.school.name,
          schoolId: this.school.id,
        });
      });
    }
  },
};
</script>

<style scoped lang="scss">
@import "~vuetify/src/styles/settings/_variables";

.page-grid {
  width: 100%;
  display: grid;
  grid-template-columns: max(350px) 1.5fr minmax(500px, auto);
  grid-template-rows: auto auto auto;
  grid-template-areas: "school licenses events";

  grid-gap: 10px;

  padding: 8px;

  .school-card {
    grid-area: school;
  }
  .licenses-cards {
    grid-area: licenses;
  }
  .events-card {
    grid-area: events;
  }
  .registered-student-alert {
    grid-column: 2 / span 3;
  }

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    grid-template-columns: 0.7fr 2fr 1.3fr;
    grid-template-areas:
      "school school school"
      "licenses licenses licenses"
      "events events events";
    .registered-student-alert {
      grid-column: 1 / span 3;
    }
  }
}
</style>
