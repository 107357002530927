<template>
  <v-card
    class="flex-grow-1 align-self-start"
    color="white"
    elevation="1"
    rounded
  >
    <v-toolbar elevation="3">
      <v-btn v-if="$vuetify.breakpoint.mobile && backRouteName" :to="{ name: backRouteName }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>{{ title }}</v-card-title>

      <v-spacer />

      <slot name="action">
        <v-btn
            text
            color="primary"
            @click="$emit('save')"
            :loading="saving"
            :disabled="loading || saving"
        >
          {{ $t("btn.save") }}
        </v-btn>
      </slot>
    </v-toolbar>

    <v-card-text>
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SettingsPageCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    backRouteName: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style scoped>

</style>
