<script>
import ExpandablePanel from "@/components/ExpandablePanel.vue";
import ExamListSortingCriteriaDialog from "@/components/students-directory/ExamListSortingCriteriaDialog.vue";
import examListService from "@/services/examListService";
import LicenseProgress from "@/components/calendar/appointmentModal/fields/LicenseProgress.vue";
import {mapActions} from "vuex";

export default {
  name: "ExamList",
  components: {LicenseProgress, ExamListSortingCriteriaDialog, ExpandablePanel},
  data: () => {
    return {
      loading: false,
      reloading: false,
      data: [],
      licenseFilter: '',
      examOrgFilter: ''
    }
  },
  mounted() {
    this.load();
  },
  computed: {
    examOrganizations() {
      return [...new Set(this.data.map(item => item.examOrganization))];
    },
    licenses() {
      return [...new Set(this.data.map(item => item.licenseName))];
    },
    filteredData() {
      if (! this.licenseFilter && ! this.examOrgFilter) return this.data;

      return this.data.filter(item => {
        const licenseMatch = this.licenseFilter ? item.licenseName === this.licenseFilter : true;
        const examOrgMatch = this.examOrgFilter ? item.examOrganization === this.examOrgFilter : true;
        return licenseMatch && examOrgMatch;
      });
    }
  },
  methods: {
    ...mapActions('studentsDirectory', ['openStudent']),
    reload() {
      this.reloading = true
      this.load();
    },
    load() {
      this.loading = true
      examListService
          .load()
          .then(resp => {
            this.data = resp.data
          })
          .finally(() => {
            this.loading = this.reloading = false
          })
    },
    expirationColorByWeeks(weeks) {
      if (weeks === null) return 'grey';

      const examiningPeriodInWeeks = parseInt(weeks);

      if (examiningPeriodInWeeks > 12) return "success";
      if (examiningPeriodInWeeks <= 12 && examiningPeriodInWeeks > 4) return "warning";

      return "error";
    },
  },

}
</script>

<template>
  <expandable-panel panel-name="bulkStudentActionsPanel">
    <template v-slot:title="{expanded}">
      {{ $t('label.exam_list') }}
      <v-badge inline color="grey lighten-1" :value="! expanded && data.length" :content="data.length"/>
    </template>
    <template v-slot:end="{expanded}">
      <v-btn v-if="expanded" icon color="primary" @click.stop="reload"><v-icon>mdi-reload</v-icon></v-btn>
      <v-btn v-if="expanded" icon color="primary" @click.stop="$refs.sortingPreferencesDialog.open()">
        <v-icon>mdi-sort-numeric-descending</v-icon>
      </v-btn>
    </template>
    <v-card-text v-if="! (loading || reloading) && data.length">
      <v-row dense>
        <v-col cols="12" sm="6" md="4" lg="2">
          <v-select
              outlined
              :placeholder="$t('label.license')"
              dense
              hide-details
              :items="licenses"
              v-model="licenseFilter"
              clearable
          />
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="2">
          <v-select
              outlined
              :placeholder="$t('label.exam_organization')"
              dense
              hide-details
              :items="examOrganizations"
              v-model="examOrgFilter"
              clearable
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-skeleton-loader type="table" v-if="loading || reloading" />
    <v-simple-table v-else-if="filteredData.length" dense>
      <thead>
      <tr>
        <th scope="col" class="text-left" style="width: 50px">{{ $t('label.score') }}</th>
        <th scope="col" class="text-left">{{ $t('label.student') }}</th>
        <th scope="col" class="text-left" style="width: 50px">{{ $t('label.license') }}</th>
        <th scope="col" class="text-center" style="width: 30px">{{ $t('label.examining_period_abbr') }}</th>
        <th scope="col" class="text-center" style="width: 30px">{{ $t('label.theory_exam_abbr') }}</th>
        <th scope="col" class="text-center" style="width: 30px">{{ $t('label.practice_exam_abbr') }}</th>
        <th scope="col" class="text-left" style="width: 350px">{{ $t('label.progress') }}</th>
        <th scope="col" class="text-left" style="width: 80px">{{ $t('label.exam_organization') }}</th>
      </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in filteredData"
          :key="`${item.license_id}_${item.student_id}`"
          style="cursor:pointer;"
          @click="openStudent({id: item.student_id, name: item.studentName})"
        >
          <td>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-chip small label link outlined>
                {{ item.score }}
                </v-chip>
              </span>
              </template>
              <ul>
                <li
                    v-for="(score, criterion) in item.score_breakdown"
                    :key="criterion"
                    v-html="`${$t(`examListSortingCriteria.${criterion}.title`)} - ${score}`"
                />
              </ul>
            </v-tooltip>
          </td>
          <td>{{ item.studentName }}</td>
          <td>{{ item.licenseName }}</td>
          <td class="text-center">
            <v-badge
                inline
                :color="expirationColorByWeeks(item.examining_period_in_weeks)"
                :value="item.examining_period_in_weeks"
                :content="item.examining_period_in_weeks"
                v-if="item.examining_period_in_weeks"
            />
            <v-icon v-else>mdi-calendar-question</v-icon>
          </td>
          <td>
            <v-chip
                :color="(item.progress.examResults.theoryExamPassedAt || !item.theoryExamRequired) ? 'success' : 'error'"
                label
                x-small
            >
              <template v-if="item.theoryExamRequired">
                <v-icon left x-small>{{ item.progress.examResults.theoryExamPassedAt != null ? "mdi-check" : "mdi-close"
                  }}
                </v-icon>
                {{ item.progress.examResults.theoryExamsCount }}
              </template>
              <template v-else>
                <v-icon left x-small>mdi-check</v-icon>
                &mdash;
              </template>
            </v-chip>
          </td>
          <td>
            <v-chip
                :color="(item.progress.examResults.practiceExamPassedAt || !item.practiceExamRequired) ? 'success' : 'error'"
                label
                x-small
            >
              <template v-if="item.practiceExamRequired">
                <v-icon left x-small>{{ item.progress.examResults.practiceExamPassedAt != null ? "mdi-check" : "mdi-close"
                  }}
                </v-icon>
                {{ item.progress.examResults.practiceExamsCount }}
              </template>
              <template v-else>
                <v-icon left x-small>mdi-check</v-icon>
                &mdash;
              </template>
            </v-chip>
          </td>
          <td>
            <div class="d-flex flex-row align-center-center">
              <div style="width: 60px;">
                <v-progress-linear
                    height="16"
                    color="grey"
                    rounded
                    :value="item.progress.practiceProgress"
                >
                  <span style="font-size: 12px;">{{ item.progress.practiceProgress }}%</span>
                </v-progress-linear>
              </div>
              <license-progress
                  class="ml-2"
                  :license-progress="item.progress"
                  :show-theory-progress="false"
                  :outlined="false"
              />
            </div>
          </td>
          <td>{{ item.examOrganization ?? '-' }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <empty-box v-else />
    <exam-list-sorting-criteria-dialog ref="sortingPreferencesDialog" @preferencesUpdated="reload" />
  </expandable-panel>
</template>

<style scoped>

</style>
