<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row dense>
      <v-col>
        <TeacherSchoolBlock />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" sm="6">
        <TeacherStudentsCount/>
      </v-col>
      <v-col cols="12" md="6">
        <TeacherWorkTime/>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <NextScheduledStudents />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <UpcomingAppointments/>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <teacher-vehicles-widget />
      </v-col>
    </v-row>
    <TeacherCreateEventModal
      :teachers="[currentUser]"
      :activeTeachers="[currentUser.id]"
      :isMobile="$vuetify.breakpoint.mobile" />
  </v-container>
</template>

<script>
import licenses from "@/utils/mixins/licenses";
import { mapGetters } from "vuex";
import user from "@/utils/mixins/user";

import TeacherSchoolBlock from "@/components/widgets/dashboard/TeacherSchoolBlock";
import TeacherStudentsCount from "@/components/widgets/dashboard/TeacherStudentsCount";
import NextScheduledStudents from "@/components/widgets/dashboard/NextScheduledStudents";
import TeacherWorkTime from "@/components/widgets/dashboard/TeacherWorkTime";
import TeacherCreateEventModal from "@/components/calendar/eventModal/TeacherCreateEventModal";
import UpcomingAppointments from "@/components/widgets/dashboard/UpcomingAppointments";
import SchoolDataLoader from "@/helpers/schoolDataLoader";
import TeacherVehiclesWidget from "@/components/widgets/dashboard/TeacherVehiclesWidget.vue";

export default {
  name: "TeacherDashboard",
  components: {
    TeacherVehiclesWidget,
    UpcomingAppointments,
    TeacherWorkTime,
    NextScheduledStudents,
    TeacherStudentsCount,
    TeacherSchoolBlock,
    TeacherCreateEventModal,
  },
  mixins: [user, licenses],
  data: () => ({
    dialog: false,
    loadingEvents: true,
    loadingNextEvents: true,
    eventTypes: [],
    nextEvents: [],
    cancelTokenSource: null,
  }),
  computed: {
    ...mapGetters("school", ["school", "address"]),
  },
  mounted() {
    SchoolDataLoader.load({ only: ['s','v'] })
  },
};
</script>

<style scoped lang="scss">

</style>
