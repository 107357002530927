import { Model } from "@vuex-orm/core";
import { v4 as uuid4 } from "uuid";
import StudentModel from "@/store/models/StudentModel";

export default class StudentDocuments extends Model {
  static entity = "studentDocuments";

  static fields() {
    return {
      id: this.uid(() => uuid4()),
      key: this.string(""),
      title: this.string(""),
      file: this.string(""),
      createdAt: this.attr(null),
      student: this.belongsTo(StudentModel, "studentId"),
    };
  }
}
