<template>
  <ListFiltersMenu @clearFilters="onClearFilters">
    <div>
      <v-row>
        <v-col cols="4">
          <v-select
            type="string"
            v-model="filters.activityStatus"
            :items="[
              { text: 'Active', value: 'active' },
              { text: 'Inactive', value: 'inactive' },
            ]"
            label="Active/Inactive"
            placeholder="Active/Inactive"
            dense
            outlined
            hide-details
            clearable
            @change="updateRouteQuery" />
        </v-col>
        <v-col cols="8">
          <v-select
            type="string"
            v-model="filters.licenses"
            :items="licensesAsOptions"
            label="Licenses"
            placeholder="Licenses"
            dense
            outlined
            hide-details
            multiple
            clearable
            @change="updateRouteQuery" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="filters.office"
            :items="officesAsOptions"
            label="Office"
            outlined
            dense
            clearable
            hide-details />
        </v-col>
      </v-row>
    </div>
  </ListFiltersMenu>
</template>

<script>
import OfficeModel from "@/store/models/OfficeModel";
import LicenseModel from "@/store/models/LicenseModel";
import ListFiltersMenu from "@/components/list/ListFiltersMenu";

import { mapState } from "vuex";
import listFilters from "@/utils/mixins/listFilters";

export default {
  name: "TeacherListFilters",
  mixins: [listFilters],
  components: { ListFiltersMenu },
  data: () => ({
    licenses: [],
    offices: [],
    initialFilters: {},
    filters: {
      active: false,
      office: "",
      licenses: [],
    },
  }),
  computed: {
    ...mapState("filters", ["isApplied"]),
    licensesAsOptions() {
      return LicenseModel.all().map((item) => ({
        text: item.name,
        value: item.id.toString(),
      }));
    },
    officesAsOptions() {
      return OfficeModel.all().map((item) => ({
        text: item.name,
        value: item.id.toString(),
      }));
    },
  },
  created() {
    this.initialFilters = { ...this.filters };
  },
  methods: {
    onAppliedFilters() {
      this.$store.commit("filters/set", this.appliedFilters);

      this.$emit("filtersApplied", this.filters);

      this.$router.push({
        query: {
          ...this.$route.query,
          ...this.filters,
        },
      });
    },
    onClearFilters() {
      this.filters = { ...this.initialFilters };
    },
    updateRouteQuery() {
      this.$router
        .replace({
          query: { filters: this.formatFilters(this.filters) || undefined },
        })
        .then(() => {
          this.$emit("applyFilters");
        });
    },
  },
};
</script>

<style scoped></style>
