import GoTo from "@/notifications/push/handlers/goTo";
import ViewEvent from "@/notifications/push/handlers/viewEvent";
import OpenChat from "@/notifications/push/handlers/openChat";
import ShowSupportTicket from "@/notifications/push/handlers/showSupportTicket";
import OpenStudentPayments from "@/notifications/push/handlers/openStudentPayments";
import OpenStudentDocuments from "@/notifications/push/handlers/openStudentDocuments";
import DefaultAction from "@/notifications/push/handlers/defaultAction";

const actions = {
  GoTo, // Navigate to a specified route. Argument: route name
  ViewEvent, // Open the specific event page to view details. Argument: event id
  OpenChat, // Open a specific conversation. Argument: room id
  ShowSupportTicket, // Open the specific support ticket page. Argument: ticket id
  OpenStudentPayments, // Open the student payments page. Argument: student id
  OpenStudentDocuments // Open the student documents page. Argument: student id
};

export default class PushNotificationActionHandler {
  constructor(action, ...args) {
    this.action = `${action[0].toUpperCase()}${action.slice(1)}`;
    return Object.keys(actions).includes(this.action)
      ? new actions[this.action](...args)
      : new DefaultAction(...args);
  }
}
