<template>
  <v-banner
    :class="$vuetify.breakpoint.xl ? 'pa-0' : 'pa-2'"
    icon="mdi-timer-sand"
    color="orange"
    dark>
    {{ $t("label.demo_mode_notification_text") }}
    <template v-slot:actions>
      <v-btn class="demo-mode-settings-btn" text @click="$router.push({ name: 'school-settings.demo-mode' })">
        {{ $t('label.go_to_demo_mode') }}
        <v-icon right>mdi-chevron-right</v-icon>
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
import responsive from "@/utils/mixins/responsive";

export default {
  name: "DemoModeNotification",
  mixins: [responsive],
};
</script>

<style lang="scss">
.v-banner__icon {
  margin-right: 4px !important;
}

.v-banner {
  //position: fixed;
  z-index: 9;
  width: 100%;
}

.v-banner__wrapper {
  padding-left: 8px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.v-banner__actions:has(> .demo-mode-settings-btn) {
  margin-bottom: 0 !important;
  align-self: center !important;

}
</style>
