<template>
  <v-card class="students-requests-card mb-1" :loading="reloading">
    <v-card-title class="py-2 d-flex justify-space-between">
      <div class="d-flex flex-column">
        <span>
          {{ $t("label.students_requests") }}
        </span>
      </div>
      <v-btn icon small @click="load(true)" :loading="reloading">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </v-card-title>

    <v-divider />

    <v-card-text>
      <AppointmentsRequestsLoader v-if="loading" :count="5" />
      <template v-else>
        <AppointmentCard
            v-for="appointment in requests"
            :key="appointment.id"
            :appointment="appointment"
            @click.native="$router.push({ name: 'calendar.appointment', params: { id: appointment.id } })"
        />
        <empty-box v-if="requests.length === 0"/>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import user from "@/utils/mixins/user";
import { mapActions } from "vuex";
import AppointmentsRequestsLoader from '@/components/skeleton-loaders/AppointmentsRequestsLoader'
import widgetsService from "@/services/widgetsService";
import AppointmentRequestModel from "@/store/models/AppointmentRequestModel";
import AppointmentCard from "@/components/appointment/AppointmentCard";
export default {
  name: "TeacherStudentsRequests",
  components: {
    AppointmentsRequestsLoader, AppointmentCard
  },
  data: () => ({
    loading: true,
    reloading: false
  }),
  mixins: [user],
  computed: {
    requests() {
      return AppointmentRequestModel
          .query()
          .with('teacher')
          .with('students')
          .orderBy('start')
          .get()
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions("appointments", ["eventOpenAction"]),
    load (reload = false) {
      this.reloading = reload
      widgetsService
          .appointmentsRequests()
          .then((response) => {
            AppointmentRequestModel.create({ data: response.data.items })
          })
          .finally(() => (this.loading = this.reloading = false));
    }
  },
};
</script>

<style scoped></style>
