<template>
  <div class="message__status text-caption d-flex justify-end align-end">
    <span class="message__time mr-1" v-if="!message.state !== 'loading'">{{
      createdAt
    }}</span>
    <v-icon v-if="message.userId === currentUser.userId" size="18">{{
      icon[message.state]
    }}</v-icon>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { format, isToday, parseISO } from "date-fns";

export default {
  name: "MessageStatus",
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icon: {
        loading: "mdi-clock-fast",
        arrived: "mdi-check",
        seen: "mdi-check-all",
        error: "mdi-alert",
      },
    };
  },
  computed: {
    ...mapState("auth", { currentUser: "authUser" }),
    createdAt() {
      const createdAt = parseISO(this.message.createdAt);

      return isToday(createdAt)
        ? format(createdAt, "HH:mm")
        : format(createdAt, "MMM dd");
    },
  },
};
</script>

<style scoped></style>
