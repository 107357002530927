<template>
<v-dialog
  v-model="show"
  width="600"
  @click:outside="close"
  @keydown.esc="close"
  :scrollable="true"
>
<v-card :loading="loading">
  <v-toolbar class="flex-grow-0 mb-4" color="primary" dark dense>
    <v-toolbar-title>
      {{ $t("label.create_or_edit_expense", { createOrEdit: expenseId ? $t("label.edit") : $t("label.create") }) }}
    </v-toolbar-title>

    <v-spacer />

    <v-btn icon dark @click="close">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-toolbar>

  <v-card-text v-if="loading">
    <div class="d-flex justify-center pt-5">
      <v-progress-circular color="primary" indeterminate />
    </div>
  </v-card-text>

  <v-card-text v-else>
    <VehicleAlert v-if="showDetailsAlert && vehicle" :vehicle="vehicle" />

    <ValidationObserver ref="form">
      <v-row class="mt-2" dense>
        <v-col cols="12">
          <ValidationProvider v-slot="{ errors }" name="type">
            <v-select
              v-model="form.type"
              :error-messages="errors"
              :items="types"
              :label="$t('filters.type')"
              :placeholder="$t('filters.type')"
              clearable
              dense
              outlined
            />
              </ValidationProvider>
          </v-col>
      </v-row>

      <v-row dense>
          <v-col cols="12">
            <ValidationProvider v-slot="{ errors }" name="appointmentId">
              <v-select
                :error-messages="errors"
                :items="orderedServiceAppointments"
                :label="$t('label.service_appointment')"
                :menu-props="{ contentClass: 'fit-parent-width' }"
                :placeholder="$t('label.service_appointment')"
                :value="form.vehicleServiceId"
                :disabled="loadingServiceAppointments"
                clearable
                dense
                item-value="id"
                outlined
                @click:clear="form.vehicleServiceId = null"
                @input="onVehicleServiceIdInput"
              >
                <template v-slot:selection="{ item }">
                  <span>{{ item.timeLabel }}</span>
                </template>

                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.timeLabel }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.notes }}</v-list-item-subtitle>
                  </v-list-item-content>
                    </template>
                  </v-select>
              </ValidationProvider>
          </v-col>
      </v-row>

      <v-row dense>
          <v-col cols="12" md="8">
            <DatePickerField
              :label="$t('label.date')"
              :placeholder="$t('label.date')"
              name="date"
              :value="form.date"
              @save="value => form.date = value"
              @clear="form.data = null"
            />
          </v-col>

          <v-col cols="12" md="4">
            <ValidationProvider v-slot="{ errors }" name="amount">
              <v-text-field
                suffix="€"
                :label="$t('label.expense_amount')"
                placeholder="0,00"
                v-model="form.amount"
                outlined
                dense
                v-currency="{
                  currency: null,
                  allowNegative: false,
                  valueRange: { min: 0.0 },
                }"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <ValidationProvider v-slot="{ errors }" name="description">
            <v-textarea
              v-model="form.description"
              dense
              outlined
              rows="3"
              :error-messages="errors"
              :label="$t('label.notes')"
              :placeholder="$t('label.notes')"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
    </ValidationObserver>
  </v-card-text>

  <v-divider />

  <v-card-actions>
      <v-btn text @click="close">{{$t('btn.close')}}</v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        :loading="saving"
        :disabled="saving || loadingServiceAppointments"
        @click="save"
      >
          <v-icon left>{{ `mdi-content-save${expenseId ? '-edit' : ''}` }}</v-icon>
          {{$t('btn.save')}}
      </v-btn>
  </v-card-actions>
</v-card>
</v-dialog>
</template>

<script>
import DatePickerField from "@/components/form/DatePickerField.vue";
import vehicleService from "@/services/vehiclesService";
import { format, isSameDay, parseISO } from "date-fns";
import currency from "@/utils/mixins/currency";
import { EventBus } from "@/EventBus";
import VehicleAlert from "@/components/vehicle/VehicleAlert.vue";
import VehicleModel from "@/store/models/VehicleModel";

export default {
  name: "VehicleExpensesDialog",
  props: {
    showDetailsAlert: {
      type: Boolean,
      default: false
    }
  },
  components: { VehicleAlert, DatePickerField },
  mixins: [currency],
  data() {
    return {
      show: false,
      loading: false,
      loadingServiceAppointments: false,
      saving: false,
      serviceAppointments: [],
      expenseId: null,
      vehicleId: null,
      form: {
        vehicleServiceId: null,
        type: null,
        date: null,
        amount: null,
        description: ""
      }
    };
  },
  computed: {
    orderedServiceAppointments() {
      const serviceAppointments = [...this.serviceAppointments]
      return serviceAppointments.sort((prev, next) => new Date(next.from) - new Date(prev.from))
    },
    vehicle() {
      return VehicleModel.find(this.vehicleId);
    },
    types() {
      const types = [
        "MAINTENANCE_AND_SERVICING",
        "INSURANCE_PREMIUMS",
        "REGISTRATION_FEES",
        "ANNUAL_TAX",
        "INSPECTION_FEES",
        "WIPER_REPLACEMENT",
        "CAR_WASH_DETAILING",
        "PARKING_FEES_TOLLS",
        "DEPRECIATION_AMORTIZATION",
        "LEASING_FINANCING",
        "EMERGENCY_ROADSIDE_ASSIST",
        "UPGRADES_MODIFICATIONS",
        "SPARE_PARTS_STORAGE",
        "OTHER"
      ];

      return types.map(type => {
        return {
          value: type,
          text: this.$t("vehicleExpenseType." + type)
        };
      });
    }
  },
  methods: {
    open(vehicleId, expense = null) {
      this.show = true;
      this.vehicleId = vehicleId;

      this.loadingServiceAppointments = true;
      vehicleService
        .loadServices(this.vehicleId, { passed: true })
        .then(response => {
          this.serviceAppointments = (response.data || []).map(item => {
            return { ...item, timeLabel: this.appointmentTimeLabel(item.labelFrom, item.labelTo) };
          });
        })
        .finally(() => {
          this.loadingServiceAppointments = false;
        });

      if (expense) this.initExpenseData(expense);
    },
    close() {
      this.show = false;

      setTimeout(() => {
        this.vehicleId = null;
        this.expenseId = null;

        this.$refs.form.reset();
        this.form = {
          vehicleServiceId: null,
          type: null,
          date: null,
          amount: null,
          description: ""
        };
      }, 200)
    },
    save() {
      this.form.amount = this.sanitizePrice(this.form.amount);

      this[this.expenseId ? "update" : "store"]()
        .catch(error => {
          const responseData = error.response.data;
          const errors = responseData.errors || [];
          if (Object.keys(errors).length === 0 && responseData.message)
            this.$snackbar.show({
              message: responseData.message,
              color: "error"
            });
          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    store() {
      this.saving = true;
      return vehicleService
        .storeExpense(this.vehicleId, this.form)
        .then(response => {
          this.close();
          this.$emit("created", response.data);
          EventBus.$emit("expense-created", response.data);
        });
    },
    update() {
      this.saving = true;
      return vehicleService
        .updateExpense(this.vehicleId, this.expenseId, this.form)
        .then(response => {
          this.close();
          this.$emit("updated", response.data);
          EventBus.$emit("expense-updated", response.data);
        });
    },
    appointmentTimeLabel(from, to) {
      const parsedISOFrom = parseISO(from);
      const parsedISOTo = parseISO(to);

      if (isSameDay(parsedISOFrom, parsedISOTo)) {
        const date = format(parsedISOFrom, "dd.MM.yyyy");
        const fromTime = format(parsedISOFrom, "HH:mm");
        const toTime = format(parsedISOTo, "HH:mm");

        return date + " " + fromTime + " - " + toTime;
      }

      return from + " - " + to;
    },
    initExpenseData(expense) {
      this.expenseId = expense.id;

      this.amount = expense.amount;

      this.form = {
        vehicleServiceId: expense.vehicleServiceId,
        type: expense.type,
        date: expense.date,
        amount: this.sanitizePrice(expense.amount, ','),
        description: expense.description
      };
    },
    onVehicleServiceIdInput(value) {
      const vehicleService = this.serviceAppointments.find(item => item.id === value)
      this.form.date = format(parseISO(vehicleService.from), "yyyy-MM-dd")
      this.form.vehicleServiceId = value
    }
  }
}
</script>

<style scoped>

</style>
