import { Model } from "@vuex-orm/core";
import { v4 as uuid4 } from "uuid";

export default class LicenseGroupModel extends Model {
  static entity = "licensesGroups";

  static fields() {
    return {
      id: this.uid(() => uuid4()),
      name: this.string(""),
    };
  }
}
