<template>
  <v-select
      :label="$t('label.templates')"
      :placeholder="$t('label.templates')"
      v-model="pickedTemplate"
      :items="templates"
      item-value="id"
      item-text="name"
      outlined
      required
      hide-details
      dense
      :disabled="disabled"
      :append-icon="isSchoolAdministrator ? 'mdi-plus' : ''"
      @click:append="$emit('clickAppend')"
  >
    <template v-slot:selection="{ item }">
      {{ item.isDefault ? $t('label.default') : item.name }}
    </template>

    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          <div>{{ item.isDefault ? $t('label.default') : item.name }}</div>
        </v-list-item-title>

        <v-list-item-subtitle class="mt-1 d-flex flex-gap">
          <v-chip x-small outlined label class="px-1">
            <div style="font-size: 0.7rem">{{ $t("label.basic_theories_abbr") }} : {{ listById(item.id).basicTheories }}</div>
          </v-chip>
          <v-chip x-small outlined label class="px-1">
            <div style="font-size: 0.7rem">{{ $t("label.special_theories_abbr") }} : {{ listById(item.id).specialTheories }}</div>
          </v-chip>
          <v-chip v-if="listById(item.id).instructions > 0" x-small outlined label class="px-1">
            <div style="font-size: 0.7rem">{{ $t("label.instructions_abbr") }} : {{ listById(item.id).instructions }}</div>
          </v-chip>
          <v-chip v-if="listById(item.id).normals > 0" x-small outlined label class="px-1">
            <div style="font-size: 0.7rem">{{ $t("label.normals_abbr") }} : {{ listById(item.id).normals }}</div>
          </v-chip>
          <v-chip x-small outlined label class="px-1">
            <div style="font-size: 0.7rem">{{ $t("label.overlands_abbr") }} : {{ listById(item.id).overlands }}</div>
          </v-chip>
          <v-chip x-small outlined label class="px-1">
            <div style="font-size: 0.7rem">{{ $t("label.highways_abbr") }} : {{ listById(item.id).highways }}</div>
          </v-chip>
          <v-chip x-small outlined label class="px-1">
            <div style="font-size: 0.7rem">{{ $t("label.nights_abbr") }} : {{ listById(item.id).nights }}</div>
          </v-chip>
          <v-chip v-if="item.mtls" x-small outlined label class="px-1">
            <div style="font-size: 0.7rem">{{ $t("label.mtls_abbr") }} : {{ item.mtls }}</div>
          </v-chip>
          <v-chip class="px-1" label outlined x-small>
            <div style="font-size: 0.7rem">
              {{ $t("label.theory_exam_abbr") }}:
              <v-icon v-if="listById(item.id).theoryExam" x-small>mdi-check</v-icon>
              <template v-else>&mdash;</template>
            </div>
          </v-chip>
          <v-chip class="px-1" label outlined x-small>
            <div style="font-size: 0.7rem">
              {{ $t("label.practice_exam_abbr") }}:
              <v-icon v-if="listById(item.id).practiceExam" x-small>mdi-check</v-icon>
              <template v-else>&mdash;</template>
            </div>
          </v-chip>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
import user from '@/utils/mixins/user'

import LicenseRequirementModel from '@/store/models/LicenseRequirementModel'

export default {
  name: "RequirementTemplateSelector",
  mixins: [user],
  props: {
    licenseId: {
      type: Number,
      required: true
    },
    templateId: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    pickedTemplate: {
      set(val) {
        this.$emit('templateSelect', val)
      },
      get() {
        return this.templateId || this.templates[0].id
      }
    },
    templates() {
      return LicenseRequirementModel.query()
          .where('active', true)
          .where(item => item.licenses.includes(this.licenseId))
          .get() || []
    },
  },
  methods: {
    listById(val) {
      return this.templates.find(item => item.id === val) || this.templates[0]
    }
  }
};
</script>

<style scoped lang="scss">
.flex-gap{
  gap: 5px;
}
</style>
