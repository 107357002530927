<template>
  <v-col :cols="cols">
    <ValidationProvider
      name="students"
      :rules="`${validation ? 'required' : ''}`"
      v-slot="{ errors }">
      <v-autocomplete
        :error="errors.length > 0"
        :disabled="disabled"
        @change="studentSelect"
        v-model="studentsIds"
        :items="filteredStudents"
        item-value="id"
        :item-text="getFullName"
        :multiple="multiple"
        :hide-selected="multiple"
        :small-chips="multiple"
        :deletable-chips="multiple"
        :clearable="multiple"
        outlined
        dense
        hide-details
        :label="$tc('label.student_plurable', multiple ? 10 : 1)">
        <template v-slot:item="data" v-if="!multiple">
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.fullName }}
              <v-icon v-if="data.item.isDeleted" small color="grey"
                >mdi-delete</v-icon
              >
            </v-list-item-title>
            <v-list-item-subtitle v-if="data.item.bookAndPay">{{
              data.item.categoryName
            }}</v-list-item-subtitle>
          </v-list-item-content>
          <div class="other" v-if="data.item.bookAndPay">
            <v-list-item-subtitle
              >{{ data.item.balance }} €</v-list-item-subtitle
            >
          </div>
        </template>
      </v-autocomplete>
    </ValidationProvider>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "StudentsField",
  props: {
    filteredStudents: {
      type: Array,
      required: true,
    },
    cols: {
      type: Number,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", ["initialDurationGetter"]),
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    studentsIds: {
      get() {
        return this.multiple
          ? this.model.studentsIds
          : this.model.studentsIds
          ? this.model.studentsIds[0]
          : [];
      },
      set(val) {
        if (!this.multiple) {
          this.model.studentsIds = val ? [val] : null;
        } else {
          this.model.studentsIds = val;
        }
      },
    },
  },
  methods: {
    studentSelect() {
      this.model.licenseId = null;
    },
    getFullName(item) {
      return `${item.firstName} ${item.lastName}`;
    },
  },
};
</script>

<style lang="scss" scoped></style>
