// import api from "@/utils/api";
// import laravelEcho from "@/plugins/laravel-echo";
import * as mutationTypes from "./../mutation-types/children";
import childrenAccountsService from '@/services/childrenAccountsService'
import ChildModel from '@/store/models/ChildModel'

const state = () => ({
  loadingChildren: false,
});

const getters = {

};

const actions = {
  fetchChildren: ({ commit }) => {
    commit(mutationTypes.LOADING_CHILDREN, true)
    childrenAccountsService.load()
      .then((resp) => {
        ChildModel.create({ data: resp.data })
      })
      .finally(() => {
        commit(mutationTypes.LOADING_CHILDREN, false)
      })
  },
};

const mutations = {
  [mutationTypes.LOADING_CHILDREN]: (state, data) => {
    state.loadingChildren = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
