import { Model } from "@vuex-orm/core";
import ProductPriceModel from "@/store/models/ProductPriceModel";

export default class LessonTypeModel extends Model {
  static entity = "lessonTypes";

  static fields() {
    return {
      id: this.attr(null),
      name: this.string(""),
      eventTypeId: this.string(""),
      payable: this.string(""),
      group: this.string(""),
      order: this.attr(null),
      bodyColor: this.string(""),
      fontColor: this.string(""),
      prices: this.hasMany(ProductPriceModel, "productId"),
      forLicenses: this.attr(null),
      licenseDuration: this.attr([]),
      multiplier: this.attr(null),
      defaultDuration: this.attr(null),
      canBeDeletedInPast: this.boolean(false),
      canBeEditedInPast: this.boolean(true),
      canBeMissed: this.boolean(false),
      canBeMovedInPast: this.boolean(true),
      canBeRefunded: this.boolean(true),
      canBeRefundedWithJoker: this.boolean(true),
      useTeacherDefaultDuration: this.boolean(false),
      isDrivingWorktime: this.boolean(false),
    };
  }

  get isPayable() {
    return this.payable !== "no_cost";
  }
}
