import api from "@/utils/api";

export default {
  studentPayments: (studentId) => {
    return new Promise((resolve, reject) => {
      api
        .get(`cards/students/${studentId}/payments`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  studentDocuments(studentId) {
    return new Promise((resolve, reject) => {
      api
        .get(`cards/students/${studentId}/documents`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  studentAgenda(studentId) {
    return new Promise((resolve, reject) => {
      api
        .get(`cards/students/${studentId}/agenda`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  studentNotes(studentId) {
    return new Promise((resolve, reject) => {
      api
        .get(`cards/students/${studentId}/notes`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  studentServices(studentId) {
    return new Promise((resolve, reject) => {
      api
        .get(`cards/students/${studentId}/services`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  vehicleMileages(vehicleId) {
    return new Promise((resolve, reject) => {
      api
        .get(`cards/vehicles/${vehicleId}/mileages`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  vehicleFueling(vehicleId) {
    return new Promise((resolve, reject) => {
      api
        .get(`cards/vehicles/${vehicleId}/fuelings`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  vehicleServices(vehicleId) {
    return new Promise((resolve, reject) => {
      api
        .get(`cards/vehicles/${vehicleId}/services`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  vehicleResetvations(vehicleId) {
    return new Promise((resolve, reject) => {
      api
        .get(`cards/vehicles/${vehicleId}/reservations`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  vehicleExpenses(vehicleId) {
    return new Promise((resolve, reject) => {
      api
        .get(`cards/vehicles/${vehicleId}/expenses`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  }
};
