import schoolService from "@/services/schoolService";
import * as mutationTypes from "./../mutation-types/school";

const state = () => ({
  loaded: false,
  avatar: "",
  fax: "",
  id: null,
  invoiceCity: null,
  invoiceHouseNr: null,
  invoicePostcode: null,
  invoiceStreet: null,
  lat: "",
  long: "",
  mainCity: "",
  mainHouseNr: "",
  mainPostcode: "",
  mainStreet: "",
  mobile: "",
  name: "",
  isDemoModeEnabled: false,
  isWithStripePayment: false,
  paymentSetupComplete: false,
  isPaymentBonusEnabled: false,
  isBalanceLimitEnabled: false,
  balanceLimitAmount: 0,
  isDigitalAttendanceEnabled: false,
  phone: "",
  showEventTeacherLine: true,
  maxLessonsAmountPerWeek: null,
  maxLessonsAmountPerDay: 2,
  maxLessonsDurationPerDay: 90,
  studentCanDeletePractice: false,
  nonDeletablePeriod: 2,
  pastEventsAllowed: false,
  regions: "",
  covidStatus: null,
  isInvoicingEnabled: false,
  dataLoaded: false,
  representedBy: "",
  mainDrivingInstructor: "",
  hasRepresentedBySignature: false,
  hasMainDrivingInstructorSignature: false,
  teacherOverallWorkTimePerDay: 0,
  teacherDrivingWorkTimePerDay: 0,
  includeMissedLessonsInWorkingTime: false,
  vehiclesScheduleNotificationEnabled: false,
  isVehicleReservationsEnabled: false,
  vehiclesScheduleNotificationEmail: null,
  vehiclesScheduleNotificationStartTime: null,
  vehiclesScheduleNotificationEndTime: null,
  hasClickAndLearn: false,
  clickAndLearnApiKey: null,
  hasJoker: false,
  hasHappyHours: false,
  hasInsights: false,
  subscriptionPlan: null,
  credits: 0,
  administratorsUsersIds: []
});

const getters = {
  school: (state) => state,
  address: (state) =>
    `${state.mainStreet} ${state.mainHouseNr}, ${state.mainPostcode} ${state.mainCity}`,
  paymentSetupComplete: (state) => state.paymentSetupComplete,
  covidStatusGetter: (state) => state.covidStatus,
  teacherColorLine: (state) => state.showEventTeacherLine,
  balanceLimit: (state) => {
    return { enabled: state.isBalanceLimitEnabled, requiredAmount: state.balanceLimitAmount }
  },
  id: (state) => state.id,
  credits: (state) => {
    return state.credits;
  },
  subscriptionPlan: (state) => {
    return state.subscriptionPlan;
  }
};

const actions = {
  load: ({ commit, rootState }) => {
    const authState = rootState.auth;
    const elterWithoutSelectedStudent = authState.authUser?.type === "elter" && !authState.parentSelectedStudentId;
    if (!localStorage.getItem("accessToken") || elterWithoutSelectedStudent) {
      commit("set_school_loaded", true);
      commit("set_school_data_loaded", true);
      return false;
    }

    return new Promise((resolve, reject) => {
      schoolService
        .load()
        .then((resp) => {
          commit(mutationTypes.SET, resp.data);
          resolve(resp.data);
        })
        .catch(reject)
        .finally(() => {
          commit('set_school_loaded', true)
        });
    });
  },
  updateDefaultLimits: ({ commit }, payload) => {
    commit(mutationTypes.UPDATE_DEFAULT_LIMITS, payload);
  },
  updateCovidStatus: ({ commit }, payload) => {
    commit(mutationTypes.UPDATE_COVID_STATUS, payload);
  },
  setPaymentBonusStatus: ({ commit }, payload) => {
    commit(mutationTypes.UPDATE_PAYMENT_BONUS_STATUS, payload);
  },
  setDigitalAttendanceStatus: ({ commit }, payload) => {
    commit(mutationTypes.UPDATE_DIGITAL_ATTENDANCE_STATUS, payload);
  },
};

const mutations = {
  [mutationTypes.SET]: (state, data) => {
    Object.assign(state, data);
  },
  [mutationTypes.UPDATE_DEFAULT_LIMITS]: (state, data) => {
    Object.assign(state, data);
  },
  [mutationTypes.UPDATE_COVID_STATUS]: (state, data) => {
    state.covidStatus = data;
  },
  [mutationTypes.UPDATE_PAYMENT_BONUS_STATUS]: (state, data) => {
    state.isPaymentBonusEnabled = data;
  },
  [mutationTypes.UPDATE_INVOICING_STATUS]: (state, data) => {
    state.isInvoicingEnabled = data;
  },
  [mutationTypes.UPDATE_INVOICING_AUTOMATION_STATUS]: (state, data) => {
    state.isInvoicesAutomationEnabled = data;
  },
  [mutationTypes.UPDATE_DIGITAL_ATTENDANCE_STATUS]: (state, data) => {
    state.isDigitalAttendanceEnabled = data;
  },
  [mutationTypes.UPDATE_VEHICLES_NOTIFICATION_STATUS]: (state, data) => {
    state.vehiclesScheduleNotificationEnabled = data;
  },
  [mutationTypes.UPDATE_VEHICLES_RESERVATION_STATUS]: (state, data) => {
    state.isVehicleReservationsEnabled = data;
  },
  [mutationTypes.UPDATE_VEHICLES_NOTIFICATION_SETTINGS]: (state, data) => {
    state.vehiclesScheduleNotificationEnabled = data.status;
    state.vehiclesScheduleNotificationEmail = data.email;
    state.vehiclesScheduleNotificationStartTime = data.startTime;
    state.vehiclesScheduleNotificationEndTime = data.endTime;
    state.isVehicleReservationsEnabled = data.reservationStatus;
  },
  [mutationTypes.UPDATE_CLICKANDLEARN_API_KEY]: (state, data) => {
    state.clickAndLearnApiKey = data;
  },
  [mutationTypes.UPDATE_CREDITS_AMOUNT]: (state, amount) => {
    state.credits = amount;
  },
  set_school_loaded: (state, value) => {
    state.loaded = value
  },
  set_school_data_loaded: (state, value) => {
    state.dataLoaded = value
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
