<script>
import documentsService from "@/services/documentsService";
import FileSaver from "@/helpers/fileSaver";
import user from "@/utils/mixins/user";
import SignaturePad from "@/components/SignaturePad.vue";
import studentsService from "@/services/studentsService";
import { showConfirm } from "@/helpers/dialogs";

export default {
  name: "DocumentListItem",
  components: {SignaturePad},
  mixins: [user],
  props: {
    document: {
      type: Object,
      required: true,
    }
  },
  data(){
    return {
      downloading: false,
    }
  },
  methods: {
    sign() {
      if (!this.canBeSigned && !this.isSchoolAdministrator) {
        this.$snackbar.show({
          message: this.$t('label.you_need_to_read_the_document_first'),
          color: 'warning',
          timeout: 5000
        })
        return;
      }
      this.$refs.signaturePad.open()
    },
    saveSignature(signature) {
      const studentId = this.isSchoolAdministrator ? this.document.studentId : this.currentUser.id;
      documentsService
        .sign(studentId, this.document.id, signature)
        .then(() => {
            this.$refs.signaturePad.close()
            this.$emit('signed', this.document.id)
          })
          .catch((err) => {
            console.error(err)
            this.$refs.signaturePad.loading = false
          })
    },
    download() {
      if (this.document.file) {
        window.open(this.document.file, '_blank');
      } else {
        this.downloading = true
        documentsService
            .download(this.document.studentId, this.document.id)
            .then((resp) => {
              const responseData = [resp.data] || [];
              const fileSaver = new FileSaver(responseData)
              fileSaver.setType('application/pdf').saveToDevice(`${this.document.downloadFileName}.pdf`)
              this.$emit('downloaded', this.document.id)
            })
            .finally(() => {
              this.downloading = false;
            })
      }
    },
    destroy() {
      showConfirm(this.$t('label.delete'), this.$t('messages.are_your_sure_delete'), () => {
        this.downloading = true
        studentsService
            .deleteDocument(this.document.studentId, this.document.id)
            .then(() => {
              this.$emit('deleted', this.document.id);
            })
            .finally(() => {
              this.downloading = false
            })
      })
    }
  },
  computed: {
    documentIcon() {
      return this.document.requiresStudentSignature ? 'mdi-file-document-edit' : 'mdi-file-document'
    },
    documentIconColor() {
      return this.document.requiresStudentSignature ? 'warning' : 'primary lighten-2'
    },
    canBeSigned() {
      return this.document.requiresStudentSignature && this.document.isPreviewed;
    },
    canBeDeleted() {
      return this.document.canBeDeleted && ! this.document.requiresStudentSignature
    },
    showSignBtn() {
      return this.document.requiresStudentSignature
        && (this.isSchoolAdministrator || this.isExactlyStudent || this.isElter && !this.parentSelectedStudent.is18yo);
    }
  }
}
</script>

<template>
  <v-list-item link :key="`student-doc-${document.id}`" @click="download" :disabled="downloading">
    <v-list-item-avatar>
      <v-icon :color="documentIconColor">
        {{ documentIcon }}
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ document.fileName }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ document.createdAt | dateFormat('dd.MM.yyyy HH:mm') }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class="d-flex flex-row align-center">
      <v-btn
        v-if="showSignBtn"
        :color="canBeSigned || isSchoolAdministrator ? 'primary' : 'grey lighten-2'"
        class="ml-2"
        icon
        @click.stop="sign"
      >
        <v-icon>mdi-signature-freehand</v-icon>
      </v-btn>
      <v-btn
          v-if="canBeDeleted && ! isElter"
          icon
          @click.stop="destroy"
          color="error"
          class="ml-2"
      >
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
    </v-list-item-action>

    <signature-pad ref="signaturePad" @sign="saveSignature" :close-on-save="false" />
  </v-list-item>
</template>

<style scoped>

</style>
