<script>
import { EventBus } from '../EventBus'
import { Network } from '@capacitor/network';

export default {
  name: 'NoInternetConnection',
  data() {
    return {
      loading: false,
      show: false
    }
  },
  mounted () {
    Network.getStatus().then(response => {
      if (!response.connected) this.open()
    });

    Network.addListener('networkStatusChange', status => {
      setTimeout(() => {
        if (!this.show && !status.connected) this.open()
      },1000);
    });
  },
  beforeDestroy () {
    Network.removeAllListeners()
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.show = false
    },
    reloadPage() {
      this.loading = true
      Network.getStatus().then(response => {
        if (response.connected) {
          this.close()
          EventBus.$emit("refresh-app");
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<template>
<v-dialog
    v-model="show"
    persistent
    max-width="400"
>
  <v-card>
    <v-toolbar class="mb-2 flex-grow-0" dark color="warning" dense>
      <v-icon left>mdi-wifi-off</v-icon>
      <v-toolbar-title>{{ $t('label.no_internet_connection') }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <div class="mt-4 text-center">
        {{ $t('messages.no_internet_connection') }}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn text @click="reloadPage" :loading="loading" :disabled="loading">
        <v-icon left>mdi-reload</v-icon>
        {{ $t('btn.try_again') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<style scoped>

</style>
