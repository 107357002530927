<template>
  <v-navigation-drawer v-model="show" touchless left fixed temporary>
    <CalendarFilters />
  </v-navigation-drawer>
</template>

<script>
import CalendarFilters from "@/components/calendar/CalendarFilters";
import TeacherModel from "@/store/models/TeacherModel";
import EventTypeModel from "@/store/models/EventTypeModel";

import responsive from "@/utils/mixins/responsive";
import * as mutationTypes from "@/store/mutation-types/main";
export default {
  name: "SidebarFilters",
  mixins: [responsive],
  components: {
    CalendarFilters,
  },
  computed: {
    show: {
      get() {
        return this.$store.state.main.showFiltersMenu;
      },
      set(value) {
        this.$store.commit(`main/${mutationTypes.TOGGLE_FILTERS_MENU}`, value);
      },
    },
    eventTypes() {
      return EventTypeModel.all();
    },
    teachers() {
      return TeacherModel.query().where("active", true).get();
    },
  },
};
</script>

<style lang="scss" scoped></style>
