<template>
  <v-card outlined :loading="loading">
    <v-card-title>
      {{ $t('label.student_base_payments') }}
      <v-spacer />
      <template v-if="editModeAvailable">
        <v-btn text @click="save" v-if="editMode" color="primary">
          <v-icon left>mdi-content-save-edit</v-icon> {{ $t('btn.save') }}
        </v-btn>
        <v-btn text @click="enableEditMode" v-else color="primary">
          <v-icon left>mdi-pencil</v-icon> {{ $t('btn.edit') }}
        </v-btn>
      </template>
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader type="table-row@3" v-if="loading" />
      <empty-box v-else-if="data.length === 0" />
      <template v-else>
        <v-alert color="warning" dark v-if="editModeAvailable">
          <div class="text-h6">
            {{ $t('label.missing_information') }}
          </div>
          <div>{{ $t('messages.missing_general_costs_of_education_information') }}</div>
        </v-alert>
        <v-simple-table dense>
          <thead>
          <tr>
            <th class="text-center" style="width: 190px">
            </th>
            <th class="text-center" style="calc(100% - 190px) / 3">
              {{ $t('one_time_product.basic_amount_full') }}
            </th>
            <th class="text-center" style="calc(100% - 190px) / 3">
              {{ $t('one_time_product.basic_amount_partial') }}
            </th>
            <th class="text-center" style="calc(100% - 190px) / 3">
              {{ $t('one_time_product.learning_material') }}
            </th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="item in data" :key="item.license">
              <td>
                <LicenseIcon height="50" transparent class="my-1" fluid :name="item.license" :icon="item.licenseIcon" />
              </td>
              <td class="text-center">
                <v-simple-checkbox
                  v-if="editMode && !item.paidCompletely"
                  :indeterminate="models[item.license].basePricePartial"
                  :disabled="models[item.license].basePricePartial || item.basePrice"
                  v-model="models[item.license].basePriceFull"
                />
                <template v-else>
                  <v-tooltip top v-if="item.basePriceFull">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="green" v-bind="attrs" v-on="on">mdi-check-circle</v-icon>
                    </template>
                    <span v-if="item.basePriceDate">{{ item.basePriceDate }}, </span>
                    <span>{{ item.basePriceAmount | currency }}</span>
                  </v-tooltip>
                  <v-icon color="grey" v-else-if="item.basePricePartial">mdi-minus</v-icon>
                  <v-icon color="red" v-else>mdi-close-circle</v-icon>
                </template>
              </td>
              <td class="text-center">
                <v-simple-checkbox
                  v-if="editMode && !item.paidCompletely"
                  v-model="models[item.license].basePricePartial"
                  :indeterminate="models[item.license].basePriceFull"
                  :disabled="models[item.license].basePriceFull || item.basePrice"
                />
                <template v-else>
                  <v-tooltip top v-if="item.basePricePartial">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="green" v-bind="attrs" v-on="on">mdi-check-circle</v-icon>
                    </template>
                    <span v-if="item.basePriceDate">{{ item.basePriceDate }}, </span>
                    <span>{{ item.basePriceAmount | currency }}</span>
                  </v-tooltip>
                  <v-icon color="grey" v-else-if="item.basePriceFull">mdi-minus</v-icon>
                  <v-icon color="red" v-else>mdi-close-circle</v-icon>
                </template>
              </td>
              <td class="text-center">
                <v-simple-checkbox
                  v-if="editMode && !item.paidCompletely"
                  v-model="models[item.license].learningMaterial"
                  :disabled="item.learningMaterial"
                />
                <template v-else>
                  <v-tooltip top v-if="item.learningMaterial">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="green" v-bind="attrs" v-on="on">mdi-check-circle</v-icon>
                    </template>
                    <span v-if="item.learningMaterialDate">{{ item.learningMaterialDate }}, </span>
                    <span>{{ item.learningMaterialAmount | currency }}</span>
                  </v-tooltip>
                  <v-icon color="red" v-else>mdi-close-circle</v-icon>
                </template>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import studentsService from "@/services/studentsService";
import LicenseIcon from "@/components/LicenseIcon.vue";
import laravelEcho from "@/plugins/laravel-echo";
import {mapGetters} from "vuex";

export default {
  name: "StudentBasePayments",
  components: {LicenseIcon},
  props: {
    student: {
      type: Object,
      require: true,
    },
  },
  data () {
    return {
      loading: false,
      editMode: false,
      data: [],
      models: {}
    }
  },
  computed: {
    ...mapGetters("school", ["school"]),
    editModeAvailable () {
      return this.student.createdBeforeBasePriceFeature && this.data.some(license => license.paidCompletely === false);
    }
  },
  mounted() {
    this.load()

    laravelEcho.private(`student.${this.student.id}`).listen('OneTimeProductBought', () => {
      this.load()
      console.log('EVENT RECEIVED')
    })
  },
  methods: {
    load() {
      this.loading = true
      studentsService
        .basePayments(this.student.id)
        .then(resp => {
          const data = resp.data
          const models = {}

          data.forEach(item => {
            if (item.paidCompletely) return
            const {basePriceFull, basePricePartial, learningMaterial, licenseId} = item
            models[item.license] = {
              licenseId,
              basePriceFull,
              basePricePartial,
              learningMaterial,
            }
          })

          this.models = models;

          this.data = data;
        })
        .finally(() => {
          this.loading = false
        })
    },
    enableEditMode() {
      if (! this.editModeAvailable) return;

      this.editMode = true
    },
    save() {
      if (! this.editModeAvailable) return;

      studentsService
        .markLicenseAsPaid(this.student.id, this.models)
        .then(() => {})
        .finally(() => {
          this.load()
          this.editMode = false
        })
    }
  },
  beforeDestroy() {
    laravelEcho.leave(`student.${this.student.id}`)
  },
}
</script>

<style scoped>
.v-input--selection-controls__input {
  margin: 0 !important;
}
</style>
