import StudentModel from "@/store/models/StudentModel";
import ProductPriceModel from "@/store/models/ProductPriceModel";

export default {
  computed: {
    studentBalance() {
      const studentIds = this.eventModel.studentsIds || [];
      const student = studentIds.length && StudentModel.find(studentIds[0]);
      return student ? student.balance : 0;
    },
    productPrice() {
      const studentIds = this.eventModel.studentsIds || [];
      const student = studentIds.length && StudentModel.find(studentIds[0]);
      let product = 0;
      if (
        student &&
        this.eventModel.licenseId &&
        this.eventModel.lessonTypeId
      ) {
        product = ProductPriceModel.query()
          .where("priceCategoryId", student.priceCategoryId)
          .where("licenseId", this.eventModel.licenseId)
          .where("productId", this.eventModel.lessonTypeId)
          .get();
        product = product[0].price;
      }
      return product;
    },
    totalPrice() {
      const pricePerMinute = this.productPrice / 45;
      return (
        this.eventModel.lessonDuration *
        this.eventModel.lessonsAmount *
        pricePerMinute
      );
    },
    remainedBalance() {
      return this.studentBalance - this.totalPrice;
    },
    returnedBalance() {
      return parseFloat(this.studentBalance) + parseFloat(this.totalPrice);
    },
  },
};
