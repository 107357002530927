<template>
  <v-dialog width="500" :value="show" persistent>
    <v-card>
      <v-card-title class="headline red lighten-1 white--text flex" primary-title>
        <v-icon class="mr-2" dark size="30">mdi-account-off</v-icon>
        {{ $t('label.restricted_access') }}
      </v-card-title>

      <v-card-text class="pl-10 pr-10 pt-3">
        <div class="subtitle-1 mb-2">{{ $t('messages.you_can_not_view_account_of_this_student') }}</div>
        <div class="subtitle-1">{{ $t("session_expired_redirect_message", { pageName: $t("route.childrenAccounts"), sec: counter }) }}</div>
      </v-card-text>

      <v-divider />
      <v-card-actions class="d-flex justify-end">
        <v-btn color="red" outlined small @click="removeSession">{{ $t("close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import * as authMutationTypes from "@/store/mutation-types/auth";
import * as mutationTypes from '@/store/mutation-types/children'

export default {
  name: "RestrictedAccessDialog",
  data: () => ({
    counter: 6,
    timerId: null,
    isUnauthorizedParent: false
  }),
  watch: {
    counter(value) {
      if (value === 0) this.removeSession();
    },
    show(value) {
      if (value) {
        this.$store.commit("school/set_school_loaded", true);
        this.$store.commit("school/set_school_data_loaded", true);
        this.isUnauthorizedParent = this.$store.state.auth.authUser?.type === "elter" && !!this.$store.state.auth.parentSelectedStudentId;

        this.startCounter();
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["getRestrictedAccess"]),
    show: {
      get() {
        return this.getRestrictedAccess;
      },
      set(value) {
        this.$store.commit(`auth/${authMutationTypes.RESTRICTED_ACCESS}`, value);
      }
    }
  },
  methods: {
    close() {
      this.show = false;
      this.clearData();
    },
    clearData() {
      clearInterval(this.timerId);
      this.counter = 6;
      this.timerId = null;
      this.isUnauthorizedParent = false;
    },
    startCounter() {
      if (this.isUnauthorizedParent) {
        this.$store.commit(`children/${mutationTypes.LOADING_CHILDREN}`, true)

        if (this.$route.name === "login") {
          this.redirectToChildrenAccounts();
          this.close();
        }
      }

      this.timerId = setInterval(() => this.counter--, 1000);
    },
    removeSession() {
      this.show = false;

      if (this.isUnauthorizedParent) {
        this.redirectToChildrenAccounts();
      }

      this.clearData();
    },
    redirectToChildrenAccounts() {
      this.$store.dispatch("auth/clearParentSelectedStudent").then(() => {
        if (this.$route.name !== "children-accounts") {
          this.$router.push({name: "children-accounts"})
              .then(() => this.$root.refresh())
        } else {
          this.$root.refresh()
        }
      })
    }
  }
};
</script>

<style scoped></style>
