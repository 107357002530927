import api from "@/utils/api";

export default {
  load: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("/lesson-types", { params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  amountByLicense: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("/lesson-types/amount-by-license", { params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
};
