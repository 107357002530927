import { Model } from "@vuex-orm/core";
import ProductPriceModel from '@/store/models/ProductPriceModel'
export default class OneTimeProductModel extends Model {
  static entity = "one-time-product";

  static TYPES = {
    OFFTIME: "OFFTIME",
    PLACEHOLDER: "PLACEHOLDER",
    PRACTICE: "PRACTICE",
  };

  static fields() {
    return {
      id: this.attr(null),
      order: this.attr(null),
      key: this.string(""),
      deletedAt: this.attr(null),
      prices: this.morphMany(ProductPriceModel,'productId','productType')
    };
  }

  getPrice(priceCategoryId, licenseId) {
    return this.prices.find(item => item.priceCategoryId === priceCategoryId && item.licenseId === licenseId)
  }
}
