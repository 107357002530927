<template>
  <ValidationProvider ref="validationProvider" class="text-center" v-slot="{ errors }" :name="inputName">
    <div :class="`upload-button mb-1 ${cssClass}`">
      <v-btn small fab bottom right color="primary" @click="changeFile">
        <v-icon>mdi-upload</v-icon>
      </v-btn>

      <v-btn
        class="clear-btn"
        v-if="clearable"
        small
        fab
        color="error"
        @click="onFileDelete"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <input
          type="file"
          :accept="accept"
          hidden
          ref="file"
          @change="onFilePick"
      />
      <slot />
    </div>

    <span class="error--text caption" v-if="errors.length > 0">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
export default {
  name: "UploadBtn",
  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    inputName: {
      type: String,
      default: ''
    },
    btnName: {
      type: String,
    },
    accept: {
      type: String,
    },
    cssClass: {
      type: String,
    },
  },
  methods: {
    onFilePick(e) {
      const files = e.target.files;

      if (files[0] !== undefined) {
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.$emit("file-picked", fr.result, files[0]);
        });
      }

      this.$refs.validationProvider.reset()
    },
    onFileDelete() {
      this.$emit("file-delete");
    },
    changeFile() {
      this.$refs.file.click();
    },
  },
};
</script>

<style scoped lang="scss">

.upload-button {
  position: relative;
  width: 150px;
  margin: auto;
  .v-btn {
    z-index: 1;
    position: absolute;
    bottom: 15px !important;
    right: 5px;
  }
  .clear-btn {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
</style>
