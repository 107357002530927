<template>
  <div>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown">
      <v-card :loading="loadingTheories">
        <v-toolbar elevation="3">
          <v-card-title>{{ $t("label.theories") }}</v-card-title>
          <v-spacer />
          <v-btn text exact color="primary" :to="{ name: 'theory-attendance' }">
            {{ $t("label.theory_attendance") }}
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-chip-group
                  class="mb-3"
                  dense
                  show-arrows
                  v-model="selectedLicense"
                  mandatory>
                <v-chip
                    v-for="license in licenses"
                    :key="license.id"
                    :value="license.id"
                    label>
                  {{ license.name }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>

          <div>
            <div class="d-flex justify-space-between mx-1 pb-2">
              <span class="title">{{ $t("label.normal") }}</span>
            </div>

            <TheoryTopicsListLoader v-if="loadingTheories" />

            <v-row v-else>
              <v-col cols="12">
                <div v-if="basicTopics.length > 0">
                  <ListRow
                    class="my-1"
                    v-for="item in basicTopics"
                    :key="item.id">
                    <ListColumn>
                      <div class="d-flex pl-2">
                        {{ $t("form.topic") + " " + item.orderIndex }}.
                        <span class="mx-2">{{ item.title }}</span>
                      </div>
                    </ListColumn>
                  </ListRow>
                </div>

                <EmptyBox v-else />
              </v-col>
            </v-row>
          </div>

          <div class="mt-3">
            <div class="d-flex justify-space-between mx-1 pb-2">
              <span class="title">{{ $t("label.special") }}</span>
            </div>

            <TheoryTopicsListLoader type="special" v-if="loadingTheories" />

            <v-row v-else>
              <v-col cols="12">
                <div v-if="specialTopics.length > 0">
                  <ListRow
                    class="my-1"
                    v-for="item in specialTopics"
                    :key="item.id">
                    <ListColumn>
                      <div class="d-flex pl-2">
                        {{ $t("form.topic") + " " + item.orderIndex }}.
                        <span class="mx-2">{{ item.title }}</span>
                      </div>
                    </ListColumn>
                  </ListRow>
                </div>

                <EmptyBox v-else />
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-container>

    <TheoryTopicFormDialog ref="form" />
  </div>
</template>

<script>
import responsive from "@/utils/mixins/responsive";
import LicenseModel from "@/store/models/LicenseModel";
import ListRow from "@/components/list/ListRow";
import ListColumn from "@/components/list/ListColumn";
import TheoryTopicFormDialog from "@/components/theory/TheoryTopicFormDialog";
import licenses from "@/utils/mixins/licenses";
import theoryTopicsService from "@/services/theoryTopicsService";
import TheoryTopicModel from "@/store/models/TheoryTopicModel";
import licensesService from "@/services/licensesService";
import EmptyBox from "@/components/EmptyBox";
import TheoryTopicsListLoader from "@/components/skeleton-loaders/TheoryTopicsListLoader";
import complexSort from '@/helpers/complexSort'

export default {
  name: "Theory",
  mixins: [responsive, licenses],
  components: {
    TheoryTopicsListLoader,
    TheoryTopicFormDialog,
    ListColumn,
    ListRow,
    EmptyBox,
  },
  data: () => ({
    types: ["basic", "special"],
    selectedType: "basic",
    selectedLicense: "",
    loadingAction: false,
    loadingTheories: false,
    form: {
      id: "",
      title: "",
    },
    orderedTopics: {},
    topicData: {},
  }),
  computed: {
    basicTopics() {
      const theoryTopicsQuery = TheoryTopicModel.query().where("type", "basic")
      if (this.selectedLicense) {
        theoryTopicsQuery.where("licensesIds", (licensesIds) => licensesIds.includes(this.selectedLicenseId));
      }
      return complexSort(theoryTopicsQuery.get(), 'orderIndex')
    },
    specialTopics() {
      const theoryTopicsQuery = TheoryTopicModel.query().where("type", "special");
      if (this.selectedLicense) {
        theoryTopicsQuery.where("licensesIds", (licensesIds) => licensesIds.includes(this.selectedLicenseId));
      }
      return complexSort(theoryTopicsQuery.get(), 'orderIndex')
    },
    licenses() {
      return LicenseModel.query().orderBy("order", "asc").get() || {};
    },
    selectedLicenseId() {
      return parseInt(this.selectedLicense);
    },
  },
  mounted() {
    licensesService.load().then((response) => {
      LicenseModel.insert({ data: response.data }).then(() => {
        const firstLicense =
          LicenseModel.query().orderBy("order").first() || {};
        this.selectedLicense = firstLicense.id;
      });
    });

    this.loadingTheories = true;
    theoryTopicsService
      .load()
      .then((response) => {
        TheoryTopicModel.create({ data: response.data });
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.loadingTheories = false;
      });
  },
  methods: {
    licenseName(id) {
      return LicenseModel.find(id)?.name;
    },
    createItem(type) {
      this.$refs.form.open(type);
    },
    onEditDialogOpen(item) {
      this.form.id = item.id;
      this.form.title = item.title;
    },
    updateTheoryTopic() {
      this.$snackbar.show({ message: "Loading..." }).then((loadingSnackBar) => {
        theoryTopicsService
          .update(this.form.id, { title: this.form.title })
          .then(() => {
            TheoryTopicModel.update({
              where: this.form.id,
              data: { title: this.form.title },
            });
          })
          .catch((error) => console.log(error))
          .finally(() => {
            this.$snackbar.delete(loadingSnackBar.id).then(() => {
              this.$snackbar.show({
                message: this.$t("messages.theory_saved"),
                color: "success",
                timeout: 2000,
              });
            });
          });
      });
    },
    editItem(item) {
      const componentByRef = this.$refs[`editDialogFor${item.id}`][0];

      if (componentByRef && componentByRef.$el) componentByRef.isActive = true;
    },
    updateTheoryOrder(theoryTopics) {
      const theoryIdsIndexes = theoryTopics.map((theoryTopic, index) => {
        return { id: theoryTopic.id, orderIndex: index + 1 };
      });

      TheoryTopicModel.update(theoryIdsIndexes);

      this.$snackbar.show({ message: "Loading..." }).then((loadingSnackBar) => {
        theoryTopicsService
          .changeOrder({ licenseId: null, theoryIdsIndexes: theoryIdsIndexes })
          .catch((error) => {
            const response = error.response || {};
            const responseData = response || {};

            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });
          })
          .finally(() => {
            this.$snackbar.delete(loadingSnackBar.id).then(() => {
              this.$snackbar.show({
                message: this.$t("messages.theory_saved"),
                color: "success",
                timeout: 2000,
              });
            });
          });
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
