<template>
  <v-card>
    <v-card-title class="py-2 d-flex justify-space-between">
      <div class="d-flex flex-column">
        <span>{{ $t("label.teachers_driving_time") }}</span>
        <span class="text-caption">
          {{ selectedRange.text }} {{ datesRange }}
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
            </template>
            <span>{{ $t('label.teachers_driving_time_explanation') }}</span>
          </v-tooltip>
        </span>
      </div>

      <v-menu offset-y left>
        <template v-slot:activator="{ attrs, on }">
          <v-btn icon small v-bind="attrs" v-on="on">
            <v-icon>mdi-filter-outline</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            @click="filterRange(item)"
            v-for="item in rangeFilter.selectOptions"
            :key="item.value"
            link>
            <v-list-item-title v-text="item.text" />
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>

    <v-divider />

    <TeachersWorkTimeLoader v-if="loading" :count="6" />
    <template v-else>
      <empty-box v-if="!teachersWorkedTime.itemsTotal" />
      <v-list v-else>
        <v-list-item
          v-for="teacher in teachersWorkedTime.items"
          :key="teacher.id"
          dense
          two-line
          @click="$router.push({ name: 'teachers.teacher.work-time', params: { id: teacher.id }})"
        >
          <v-list-item-content class="d-flex">
            <v-list-item-title>{{ teacher.firstName + " " + teacher.lastName }}</v-list-item-title>
            <v-list-item-subtitle :class="teacherWorkedHoursColor(teacher)">
              {{ $tc("label.minutes_worked", teacher.workedMinutesThisPeriod, { min: teacher.workedMinutesThisPeriod })
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="mx-0 my-0">
            <TrendingIndicator
              :current="parseInt(teacher.workedMinutesThisPeriod)"
              :past="parseInt(teacher.workedMinutesPastPeriod)" />
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
  </v-card>
</template>

<script>
import schoolService from "@/services/schoolService";
import i18n from "@/plugins/i18n";
import {endOfWeek, format, startOfWeek, isSameMonth, startOfMonth, endOfMonth} from "date-fns";
import TrendingIndicator from "@/components/TrendingIndicator";
import TeachersWorkTimeLoader from "@/components/skeleton-loaders/TeachersWorkTimeLoader";
import LocalizationManager from "@/helpers/LocalizationManager";

export default {
  name: "TeachersWorkTime",
  components: { TeachersWorkTimeLoader, TrendingIndicator },
  data() {
    return {
      loading: true,
      teachersWorkedTime: {},
      rangeFilter: {
        selectOptions: [
          { text: i18n.t("label.this_week"), value: "week" },
          { text: i18n.t("label.this_month"), value: "month" },
        ],
        selectedValue: "week",
      },
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      schoolService
        .teachersWorkedTime({ range: this.selectedRange.value })
        .then((response) => {
          this.teachersWorkedTime = response.data || {};
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    filterRange(filterItem) {
      this.rangeFilter.selectedValue = filterItem.value;
      this.load();
    },
    teacherWorkedHoursColor(teacher) {
      return teacher.workedMinutesThisPeriod > 0
        ? teacher.workedMinutesThisPeriod > 495
          ? "error--text"
          : "success--text"
        : "gray--text";
    },
  },
  computed: {
    selectedRange() {
      return this.rangeFilter.selectOptions.find(
        (item) => item.value === this.rangeFilter.selectedValue
      );
    },
    queryValue() {
      return this.selectedRange.text.toLocaleLowerCase().replaceAll(" ", "-");
    },
    mode() {
      const date = new Date();
      const week = this.rangeFilter.selectedValue === "week";

      const from = week ? startOfWeek(date) : startOfMonth(date);
      const to = week ? endOfWeek(date) : endOfMonth(date);

      return { from, to }
    },
    datesRange() {
      const date = new Date();

      if (this.rangeFilter.selectedValue === "week") {
        const startWeekDay = startOfWeek(date, { weekStartsOn: 1 });
        const endWeekDay = endOfWeek(date, { weekStartsOn: 1 });

        return isSameMonth(startWeekDay, endWeekDay) ?
            `(${format(startWeekDay, "dd")} - ${format(endWeekDay, "dd")} ${format(endWeekDay, "MMMM", { locale: LocalizationManager.dateFnsLocale })})` :
            `(${format(startWeekDay, "dd")} ${format(startWeekDay, "MMMM", { locale: LocalizationManager.dateFnsLocale })} - ${format(endWeekDay, "dd")} ${format(endWeekDay, "MMMM", { locale: LocalizationManager.dateFnsLocale })})`;
      } else {
        return `(${format(date, "MMMM Y", { locale: LocalizationManager.dateFnsLocale })})`;
      }
    },
  },
};
</script>
