<template>
  <div>
    <v-skeleton-loader v-for="item in Array.from(Array(3).keys())" :key="item" type="list-item-avatar-two-line" max-width="400" />
  </div>
</template>

<script>
export default {
  name: 'AppointmentDetailsLoader'
}
</script>

<style scoped>

</style>
