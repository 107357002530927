<template>
  <div class="d-flex text-center flex-column flex-md-row flex-grow-1 fill-height">
    <v-sheet class="layout-side mx-auto mx-md-1 d-none d-md-flex flex-md-column justify-space-between px-4">
      <div class="mt-6 mt-md-20 pa-8">
        <v-img :src="require('@/assets/svg/book-and-pay.svg')" />

        <div class="title my-2">
          Wir digitalisieren die Abläufe in Ihrer Fahrschule
        </div>

        <v-btn :to="{name: 'login'}" elevation="1" text>Zurück</v-btn>
      </div>

      <img :src="require('@/assets/img/calendar-screen.png')" style="width: 100%" alt=""/>
    </v-sheet>

    <div class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column">
      <div class="layout-content ma-auto" style="width: 100%">
        <v-card class="layout-content pa-2 ma-auto">
          <v-card-subtitle class="justify-center display-1 mb-2">
            <span class="text-h6 text-sm-h6 text-md-h4 text-lg-h3">{{ $t('label.create_parent_account') }}</span>
          </v-card-subtitle>

          <v-card-text>
            <ValidationObserver ref="form">
              <v-form @keyup.native.enter="submit">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="firstName"
                  rules="required"
                >
                  <v-text-field
                    dense
                    outlined
                    prepend-inner-icon="mdi-account-outline"
                    :label="$t('form.first_name')"
                    :placeholder="$t('form.first_name')"
                    v-model="form.firstName"
                    :error-messages="errors"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="lastName"
                  rules="required"
                >
                  <v-text-field
                    dense
                    outlined
                    prepend-inner-icon="mdi-account-outline"
                    :label="$t('form.last_name')"
                    :placeholder="$t('form.last_name')"
                    v-model="form.lastName"
                    :error-messages="errors"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="email"
                  rules="required"
                >
                  <v-text-field
                    dense
                    outlined
                    prepend-inner-icon="mdi-email-outline"
                    name="email"
                    inputmode="email"
                    type="email"
                    :label="$t('validation.fields.email')"
                    :placeholder="$t('validation.fields.email')"
                    v-model="form.email"
                    :error-messages="errors"
                  />
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" name="password" rules="required">
                  <v-text-field
                    autocomplete="new-password"
                    dense
                    outlined
                    prepend-inner-icon="mdi-lock-outline"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="!showPassword ? 'password' : 'text'"
                    :label="$t('validation.fields.password')"
                    :placeholder="$t('validation.fields.password')"
                    v-model="form.password"
                    :error-messages="errors"
                    @click:append="showPassword = !showPassword"
                  />
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" name="passwordConfirmation" rules="required">
                  <v-text-field
                    autocomplete="new-password"
                    dense
                    outlined
                    prepend-inner-icon="mdi-lock-outline"
                    type="password"
                    :label="$t('validation.fields.passwordConfirmation')"
                    :placeholder="$t('validation.fields.passwordConfirmation')"
                    v-model="form.passwordConfirmation"
                    :error-messages="errors"
                  />

                </ValidationProvider>

                <v-checkbox
                  dense
                  hide-details
                  :label="$t('validation.fields.accept_book_n_pay_policy')"
                  v-model="bookAndPayPolicyAccepted"
                />


              </v-form>
            </ValidationObserver>

            <v-btn
              class="mt-5"
              block
              x-large
              color="primary"
              elevation="1"
              :loading="loading"
              :disabled="loading || !bookAndPayPolicyAccepted"
              @click="submit"
            >
              {{ $t('btn.register') }}
            </v-btn>

            <div class="mt-5">
              <router-link :to="{name: 'login'}">{{ $t('btn.back_to_login') }}</router-link>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <CopyrightAndVersion/>
    </div>
  </div>
</template>

<script>
import authService from '@/services/authService'
import CopyrightAndVersion from '@/views/Auth/CopyrightAndVersion'

export default {
  name: 'CreateParentAccount',
  components: { CopyrightAndVersion },
  data () {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirmation: '',
      },
      showPassword: false,
      loading: false,
      bookAndPayPolicyAccepted: false,
    }
  },
  methods: {
    async submit () {
      this.loading = true

      await this.$recaptchaLoaded()
      const recaptchaToken = await this.$recaptcha("parent_registration");

      authService.elterRegistration({ ...this.form, recaptchaToken })
        .then((response) => {
          this.$store
            .dispatch('auth/setAccessToken', response.data.access_token)
            .then(() => {
              this.$router.push({ name: 'account-activation' })
            })
        })
        .catch(error => {
          this.$refs.form.reset()

          const responseData = error.response.data || {}
          const errors = responseData.errors || []

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({
              message: responseData.message,
              color: 'error',
            })
          }

          this.$refs.form.setErrors(errors)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.layout-side {
  width: 420px;
  height: 100vh;
}

.layout-content {
  max-width: 480px;
}
</style>
