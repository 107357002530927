<template>
  <v-card
      :loading="loaderGetter"
      :disabled="loaderGetter"
  >
    <v-card-title class="subtitle-1">{{ $t("label.hours_worked_this_week") }}</v-card-title>

    <v-card-subtitle class="caption">
      {{ $t("calendar.week") }} {{ startOfWeek | dateFormat('dd.MM.yyyy', false) }} - {{ endOfWeek | dateFormat('dd.MM.yyyy', false) }}
    </v-card-subtitle>

    <v-card-text>
      <div class="caption">{{ $t("label.driving_hours") }}</div>

      <v-progress-linear
          :color="drivingTime >= maxDrivingTime ? 'error' : 'primary'"
          :value="getProgressValue(drivingTime, maxDrivingTime)">
      </v-progress-linear>

      <div class="caption">
        {{ drivingTime }} / {{ maxDrivingTime }}
      </div>

      <div class="caption">{{ $t("label.total_hours") }}</div>

      <v-progress-linear
          :color="overallTime >= maxOverallTime ? 'error' : 'primary'"
          :value="getProgressValue(overallTime, maxOverallTime)">
      </v-progress-linear>

      <div class="caption">
        {{ overallTime }} / {{ maxOverallTime }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { parseISO, startOfWeek, endOfWeek } from "date-fns";
import AppointmentModel from '@/store/models/AppointmentModel'

export default {
  name: "WorkingHoursWeekly",
  computed: {
    ...mapGetters('school', ['school']),
    ...mapGetters(["activeDate", "loaderGetter"]),
    maxOverallTime() {
      return this.school.teacherOverallWorkTimePerDay * 6
    },
    maxDrivingTime() {
      return this.school.teacherDrivingWorkTimePerDay * 6
    },
    startOfWeek() {
      return startOfWeek(parseISO(this.activeDate), { weekStartsOn: 1 });
    },
    endOfWeek() {
      return endOfWeek(parseISO(this.activeDate), { weekStartsOn: 1 });
    },
    appointments() {
      return AppointmentModel.query()
          .where("startDate", (date) => {
            return  parseISO(date) >= this.startOfWeek && parseISO(date) <= this.endOfWeek
          })
          .get()
    },
    overallTime() {
      return this.getDurationSum(this.appointments.filter(item => item.isOverallWorkingTime))
    },
    drivingTime() {
      return this.getDurationSum(this.appointments.filter(item => item.isDrivingWorkingTime))
    },
  },
  methods: {
    getProgressValue(value, limit){
      return value / limit * 100
    },
    getDurationSum(appointments = []) {
      return appointments.reduce((sum, appointment) => sum + (appointment.duration || 0), 0)
    }
  }
};
</script>

<style scoped lang="scss">
</style>
