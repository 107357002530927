<template>
  <v-container>
    <v-card :loading="loading">
      <v-toolbar elevation="3">
        <v-card-title>{{ $t("label.my_tickets") }}</v-card-title>
        <v-spacer />
        <v-btn text color="primary" @click="$refs.createModal.open()">
          {{ $t("btn.new_ticket") }}
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <SupportTicketsListLoader v-if="loading" />

        <template v-else>
          <v-text-field
            class="mt-5"
            v-model="searchText"
            solo
            :placeholder="$t('form.search')"
            append-icon="mdi-magnify"
            v-if="tickets.length" />

          <v-list v-if="tickets.length">
            <v-list-item-group>
              <v-list-item
                v-for="ticket in filteredTickets"
                :key="ticket.id"
                :to="{
                  name: 'support.ticket.view',
                  params: { ticket: ticket.id },
                }">
                <v-list-item-avatar>
                  <v-icon :class="ticket.color" dark>{{ ticket.icon }}</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    <span class="text--disabled">#{{ ticket.id }}</span>
                    {{ ticket.topic }}
                  </v-list-item-title>

                  <v-list-item-subtitle>
                    <span class="mr-2">{{ ticket.updated }}</span
                    >&bull;
                    <span class="ml-1">{{ ticket.latestMessage.text }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-chip-group>
                    <v-chip label small :color="ticket.color" dark>
                      {{$t(`ticket_status.${ticket.status}`)}}
                    </v-chip>
                    <v-chip label small >
                      {{$t(`ticket_type.${ticket.type}`)}}
                    </v-chip>
                  </v-chip-group>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <empty-box v-else />
        </template>
      </v-card-text>
    </v-card>
    <TicketCreateModal ref="createModal" @ticketCreated="handleTicketCreated" />
  </v-container>
</template>

<script>
import supportTicketsService from "@/services/supportTicketsService";
import SupportTicketsListLoader from "@/components/skeleton-loaders/SupportTicketsListLoader";
import TicketCreateModal from "@/components/support/TicketCreateModal";
import laravelEcho from "@/plugins/laravel-echo";
import {mapGetters} from "vuex";

export default {
  name: "SupportCenterIndex",
  components: { TicketCreateModal, SupportTicketsListLoader },
  data() {
    return {
      loading: true,
      tickets: [],
      searchText: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    filteredTickets() {
      return this.tickets.filter((ticket) => {
        const searchWords = this.searchText.toLowerCase().trim().split(" ");

        return (
          searchWords.filter((word) => {
            return ticket.topic.toLowerCase().indexOf(word.toLowerCase()) >= 0;
          }).length > 0
        );
      });
    },
  },
  mounted() {
    this.loadTickets()
    laravelEcho
        .private("user." + this.currentUser.userId)
        .listen("TicketStatusChangedEvent", () => {
          this.loadTickets()
        });
  },
  beforeDestroy() {
    laravelEcho.leave("user." + this.currentUser.userId)
  },
  methods: {
    loadTickets () {
      supportTicketsService
          .load()
          .then((resp) => {
            this.tickets = resp.data;
            this.loading = false;
          })
          .catch((err) => this.$snackbar.show({ message: err }));
    },
    handleTicketCreated(ticket) {
      this.tickets.unshift(ticket);
    },
  },
};
</script>

<style scoped></style>
