import Vue from "vue";
import * as mutationTypes from "./../mutation-types/main";

const state = () => ({
  showSideBar: false,
  showFiltersMenu: false,
  showNotificationMenu: false,
  pageLoading: false,
  showPageAsNotFound: false,
  appContainerHeight: "",
  itemsView: localStorage.getItem("list-type") || "grid",
  isSidebarMinimized: JSON.parse(window.localStorage.getItem("isSidebarMinimized")) || false,
  errorBottomSheet: {
    status: null,
    message: "",
    errors: {}
  },
});

const actions = {
  minimizeMenu: ({ commit }, payload) => {
    window.localStorage.setItem("isSidebarMinimized", payload);
    commit(mutationTypes.MINIMIZE_SIDEBAR, payload);
  },
};

const getters = {
  showSplashScreen: (_, __, rootState) => {
    return ! (rootState.school.loaded && rootState.school.dataLoaded && rootState.auth.userLoaded);
  }
}

const mutations = {
  [mutationTypes.TOGGLE_ERROR_BOTTOM_SHEET]: (state, { status = null, message = '', errors = {} }) => {
    Vue.set(state, "errorBottomSheet", { status, message, errors });
  },
  [mutationTypes.TOGGLE_ITEMS_VIEW]: (state) => {
    state.itemsView = state.itemsView === "list" ? "grid" : "list";

    Vue.set(state, "itemsView", state.itemsView);
    localStorage.setItem("list-type", state.itemsView);
  },
  [mutationTypes.SHOW_SIDEBAR]: (state, value) => {
    Vue.set(state, "showSideBar", value);
  },
  [mutationTypes.MINIMIZE_SIDEBAR]: (state, value) => {
    Vue.set(state, "isSidebarMinimized", value);
  },
  [mutationTypes.PAGE_LOADING]: (state, value) => {
    Vue.set(state, "pageLoading", value);
  },
  [mutationTypes.TOGGLE_FILTERS_MENU]: (state, value) => {
    Vue.set(state, "showFiltersMenu", value);
  },
  [mutationTypes.TOGGLE_NOTIFICATION_MENU]: (state, value) => {
    Vue.set(state, "showNotificationMenu", value);
  },
  [mutationTypes.SET_APP_CONTAINER_HEIGHT]: (state, value) => {
    Vue.set(state, "appContainerHeight", value);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
