<template>
  <v-dialog
    max-width="700px"
    :value="createEventModalGetter"
    @click:outside="finishEventCreation"
    @keydown.esc="finishEventCreation"
    :scrollable="isMobile || windowHeight < 800"
    :fullscreen="isMobile"
    style="padding-top:0!important;"
    :hide-overlay="isMobile">
    <v-card>
      <TopBar
        class="mb-5 flex-shrink-0 flex-grow-0"
        :eventDuration="eventDuration"
        :color="eventColor"
        :eventType="eventModel.type" />

      <v-overlay :value="loader" :absolute="true" opacity="0.1" z-index="9">
        <v-progress-circular indeterminate size="64" color="primary" />
      </v-overlay>

      <ModalOverlay
        :event-model="eventModel"
        @close="finishEventCreation"
        @update="updateEventModel" />

      <v-card-text>
        <ValidationObserver ref="observer">
          <component
            :is="eventModel.type"
            :model="eventModel"
            :eventDuration="eventDuration"
            :teachers="teachers" />

          <v-row
            v-if="
              eventModel.eventType === 'THEORY' &&
              !eventModel.isTheoryFull &&
              isBalanceNegative
            ">
            <v-col>
              <v-alert class="mt-5 mb-0" type="error" dense>
                {{
                  $t("messages.has_negative_balance", {
                    amount: parseFloat(currentUser.balance),
                  })
                }}
              </v-alert>
            </v-col>
          </v-row>

          <v-row v-if="eventErrorAlertGetter.status">
            <v-col>
              <v-alert class="mt-5 mb-0" type="error" dense>{{
                eventErrorAlertGetter.message
              }}</v-alert>
            </v-col>
          </v-row>

          <v-row v-if="teacherIsNotAvailableError">
            <v-col>
              <v-alert class="mt-5 mb-0" type="error" dense>{{
                teacherIsNotAvailable.message
              }}</v-alert>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>

      <!--CONTROL BUTTONS-->
      <v-divider v-if="isMobile" />
      <v-card-actions>
        <v-spacer></v-spacer>
        <ModalActionButton
          @buttonClick="eventCancel"
          :label="$t('btn.close')"
          color="black"
          :disabled="false" />
        <template v-if="!isPast">
          <ModalActionButton
            v-if="!eventModel.id"
            @buttonClick="eventSave"
            :label="$t('btn.ok')"
            color="green"
            :disabled="!eventModel.teacher" />

          <template v-if="eventModel.id && eventModel.eventType === 'PRACTICE'">
            <ModalActionButton
              @buttonClick="eventDelete(eventModel)"
              v-if="
                eventModel.status === 'ACCEPTED' && studentCanDeleteThisEvent
              "
              :label="$t('btn.delete')"
              color="red"
              :disabled="false" />
            <ModalActionButton
              @buttonClick="eventDelete(eventModel)"
              v-if="eventModel.status === 'REQUESTED'"
              :label="$t('btn.decline')"
              color="red"
              :disabled="false" />
            <ModalActionButton
              @buttonClick="eventSave"
              v-if="
                eventModel.status === 'ACCEPTED' && studentCanDeleteThisEvent
              "
              :label="$t('btn.ok')"
              color="green"
              :disabled="!eventModel.teacher" />
          </template>

          <template v-if="isStudent && eventModel.eventType === 'PLACEHOLDER'">
            <ModalActionButton
              v-if="subscribePlaceholderButtons"
              @buttonClick="placeholderSubscribe(eventModel)"
              :label="$t('btn.book_placeholder')"
              color="green"
              :disabled="false"
            />
            <ModalActionButton
              v-else
              @buttonClick="placeholderDecline(eventModel)"
              :label="$t('btn.abandon_placeholder')"
              color="red"
              :disabled="false"
            />
          </template>

          <template
            v-if="
              eventModel.eventType === 'THEORY' && theoryGroup === 'lesson'
            ">
            <ModalActionButton
              color="green"
              v-if="subscribeButtons"
              :label="
                eventModel.isTheoryFull
                  ? $t('messages.max_num_participants_reached')
                  : $t('btn.attend_theory_lesson')
              "
              :disabled="eventModel.isTheoryFull || isBalanceNegative"
              @buttonClick="subscribeTheory(eventModel)" />
            <ModalActionButton
              v-if="!subscribeButtons && studentCanDeleteThisEvent"
              @buttonClick="unsubscribeTheory(eventModel)"
              :label="$t('btn.abandon_theory_lesson')"
              color="red"
              :disabled="false" />
          </template>

          <template v-if="eventModel.eventType === 'SIMULATOR'">
            <ModalActionButton
              v-if="subscribeButtons"
              @buttonClick="subscribeSimulator(eventModel)"
              :label="$t('btn.book_simulator')"
              color="green"
              :disabled="false" />
            <ModalActionButton
              v-if="!subscribeButtons && studentCanDeleteThisEvent"
              @buttonClick="unsubscribeSimulator(eventModel)"
              :label="$t('btn.cancel_simulator')"
              color="red"
              :disabled="false" />
          </template>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { addDays, differenceInMinutes, format, parseISO } from "date-fns";
import i18n from "@/plugins/i18n";
import eventService from "@/services/eventService";

import StudentPracticeForm from "@/components/calendar/eventModal/forms/StudentPracticeForm";
import StudentPlaceholderForm from "@/components/calendar/eventModal/forms/StudentPlaceholderForm";
import StudentSpecialForm from "@/components/calendar/eventModal/forms/StudentSpecialForm";
import StudentTheoryForm from "@/components/calendar/eventModal/forms/StudentTheoryForm";
import StudentSimulatorForm from "@/components/calendar/eventModal/forms/StudentSimulatorForm";
import TopBar from "@/components/calendar/eventModal/TopBar";
import ModalActionButton from "@/components/calendar/eventModal/ModalActionButton";

import EventModel from "@/store/models/EventModel";
import EventTypeModel from "@/store/models/EventTypeModel";
import LessonTypeModel from "@/store/models/LessonTypeModel";
import TeacherModel from "@/store/models/TeacherModel";
import user from "@/utils/mixins/user";
import ModalOverlay from "@/components/calendar/eventModal/ModalOverlay";

export default {
  name: "StudentCreateEventModal",
  mixins: [user],
  components: {
    ModalOverlay,
    ModalActionButton,
    TopBar,
    PRACTICE: StudentPracticeForm,
    PLACEHOLDER: StudentPlaceholderForm,
    SPECIAL: StudentSpecialForm,
    THEORY: StudentTheoryForm,
    SIMULATOR: StudentSimulatorForm,
  },
  data() {
    return {
      eventModel: {},
      loader: false,
      windowHeight: window.innerHeight,
      loadingRefresh: false,
    };
  },
  props: {
    activeTeachers: {
      type: Array,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    createEventModalGetter() {
      this.loader = false;
      this.eventErrorAlertToggle({ status: false, message: "" });
      const type = this.newEventModelGetter.type || "PRACTICE";

      this.eventModel = { ...this.newEventModelGetter, type };
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    teacherIsNotAvailable() {
      let error = {
        status: false,
        message: this.$t("alert.teacher_is_not_available"),
      };
      const teacher = this.eventModel.teacher;
      if (teacher) {
        const eventDay = format(parseISO(this.eventModel.start), "E");
        const workDayHours =
          teacher.availableWorkDayHours[eventDay.toLowerCase()];
        const isWithinPeriod =
          workDayHours.startTime <= this.eventModel.startTime &&
          workDayHours.endTime > this.eventModel.startTime;
        error = {
          ...error,
          status: !workDayHours.isAvailable || !isWithinPeriod,
        };
      }
      return error;
    },
    teacherIsNotAvailableError() {
      return (
        this.teacherIsNotAvailable.status &&
        !this.eventModel.id &&
        this.eventModel.type === "PRACTICE"
      );
    },
    studentCanDeleteThisEvent() {
      const { studentCanDeletePractice, nonDeletablePeriod } =
        this.schoolSettings;
      const startTime = parseISO(this.eventModel.start);
      const deleteLimit = addDays(new Date(), nonDeletablePeriod);
      return (
        studentCanDeletePractice &&
        (startTime > deleteLimit || nonDeletablePeriod === null)
      );
    },
    isPast() {
      return parseISO(this.eventModel.start) <= new Date();
    },
    teachers() {
      const query = TeacherModel.query()
        .where("active", true)
        .where("id", this.currentUser.teachersIds);
      if (!this.eventModel.id) query.where("onlyPlaceholdersBooking", false);
      return query.get();
    },
    theoryGroup() {
      const event = EventModel.find(this.eventModel.id) || {};
      return event.group;
    },
    activeButtons() {
      return !this.eventModel.id || this.eventModel.status === "REQUESTED";
    },
    subscribePlaceholderButtons() {
      const requests = this.eventModel.requests;
      let isSubscribed = false;
      if (requests && requests.length) {
        const subscribedStudents = requests.map((request) => request.studentId);
        isSubscribed = subscribedStudents.includes(this.currentUser.id);
      }
      return !isSubscribed;
    },
    subscribeButtons() {
      let isSubscribed = false;
      // const event = EventModel.find(this.eventModel.id)
      if (this.eventModel?.studentsIds && this.eventModel.studentsIds.length) {
        const subscribedStudents = this.eventModel.studentsIds.map(
          (request) => request
        );
        isSubscribed = subscribedStudents.includes(this.currentUser.id);
      }
      return !isSubscribed;
    },
    eventColor() {
      if (
        this.eventModel.type === "PRACTICE" ||
        this.eventModel.type === "THEORY"
      ) {
        if (
          this.eventModel.status === "REQUESTED" ||
          (!this.eventModel.id && !this.eventModel.lessonTypeId)
        )
          return (
            LessonTypeModel.query()
              .where("name", "lesson_type.normal")
              .first() || {}
          );
        const type = LessonTypeModel.find(this.eventModel.lessonTypeId) || {};
        if (this.isStudent && type.name === "lesson_type.gratis_exam")
          return (
            LessonTypeModel.query().where("name", "lesson_type.exam").first() ||
            {}
          ); // Gratis exams for students should look like paid exam
        return type;
      } else {
        return EventTypeModel.find(this.eventModel.type) || {};
      }
    },
    eventDuration() {
      if (
        (this.eventModel.type !== "PRACTICE" &&
          this.eventModel.type !== "PLACEHOLDER") ||
        !this.eventModel.teacher
      ) {
        const startISO = parseISO(this.eventModel.start);
        const endISO = parseISO(
          `${this.eventModel.date} ${this.eventModel.endTime}`
        );
        return differenceInMinutes(endISO, startISO);
      } else {
        return this.eventModel.lessonDuration * this.eventModel.lessonsAmount;
      }
    },
    isBalanceNegative() {
      return (
        this.currentUser.balance < 0 &&
        this.eventModel.lessonTypeGroup === "exam"
      );
    },
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("appointments", [
      "createEventModalGetter",
      "newEventModelGetter",
      "eventErrorAlertGetter",
    ]),
  },

  methods: {
    ...mapActions("appointments", [
      "finishEventCreation",
      "eventErrorAlertToggle",
    ]),
    ...mapActions("teachers", { calendarActivate: "enable" }),
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    buttonLoader() {
      this.loader = !this.loader;
    },
    async eventSave() {
      if (this.isPast) {
        this.eventErrorAlertToggle({
          status: true,
          message: i18n.t("alert.event_cant_be_created_in_past"),
        });
      } else {
        const validate = await this.$refs.observer.validate();
        if (validate) {
          this.buttonLoader();
          return this.eventModel.id
            ? this.eventEdit(this.eventModel)
            : this.eventStore(this.eventModel);
        }
      }
    },
    async eventStore(event) {
      const eventModel = {
        ...event,
        type: "PRACTICE",
        duration: this.eventDuration,
        studentsIds: event.studentsIds,
        meetingPlace: event.meetingPlace,
        text: event.eventText,
      };
      const response = await eventService
        .store(eventModel)
        .catch((err) =>
          this.eventErrorAlertToggle({
            status: true,
            message: err.response.data.message,
          })
        )
        .finally(() => this.buttonLoader());
      if (response) {
        EventModel.insert({ data: response.data }).finally(
          this.finishEventCreation
        );
        const teacherId = response.data.teacherId;
        if (!this.$props.activeTeachers.includes(teacherId))
          this.calendarActivate(teacherId);
      }
    },
    async eventUpdate(event) {
      const eventModel = {
        ...event,
        duration: this.eventDuration,
        studentsIds: event.studentsIds,
        meetingPlace: event.meetingPlace,
        text: event.eventText,
      };
      return new Promise((resolve, reject) => {
        eventService
          .update(eventModel.id, eventModel)
          .then((response) => {
            if (response) {
              EventModel.update({ where: eventModel.id, data: response.data });
              resolve();
            }
          })
          .catch((err) => {
            this.eventErrorAlertToggle({
              status: true,
              message: err.response.data.message,
            });
            reject(err);
          })
          .finally(() => this.buttonLoader());
      });
    },
    async eventEdit(event) {
      this.eventUpdate(event)
        .then(this.finishEventCreation)
        .catch((error) => console.log(error));
    },
    async placeholderSubscribe(event) {
      const validate = await this.$refs.observer.validate();
      if (!validate) return;
      this.buttonLoader();
      const params = {
        licenseId: event.licenseId,
        lessonTypeId: event.lessonTypeId,
        meetingPlace: event.meetingPlace,
      };
      const response = await eventService
        .placeholderRequest(event.id, params)
        .catch((err) =>
          this.eventErrorAlertToggle({
            status: true,
            message: err.response.data.message,
          })
        );

      if (response)
        EventModel.update({ where: event.id, data: response.data }).then(() =>
          this.finishEventCreation()
        );
      this.buttonLoader();
    },
    async placeholderDecline(event) {
      this.buttonLoader();
      const response = await eventService.placeholderDecline(event.id);
      if (response) {
        EventModel.update({ where: event.id, data: response.data }).then(
          this.finishEventCreation
        );
      }
      this.buttonLoader();
    },
    async subscribeTheory(event) {
      this.buttonLoader();
      const response = await eventService.theoryRequest(event.id).catch((err) =>
        this.eventErrorAlertToggle({
          status: true,
          message: err.response.data.message,
        })
      );
      if (response) {
        EventModel.update({ where: event.id, data: response.data }).then(
          this.finishEventCreation
        );
      }
      this.buttonLoader();
    },
    async unsubscribeTheory(event) {
      this.buttonLoader();
      const response = await eventService.declineTheoryStudent(event.id);
      if (response) {
        EventModel.update({ where: event.id, data: response.data }).then(
          this.finishEventCreation
        );
      }
      this.buttonLoader();
    },
    async subscribeSimulator(event) {
      const validate = await this.$refs.observer.validate();
      if (!validate) return;
      this.buttonLoader();
      const params = { licenseId: event.licenseId };
      const response = await eventService
        .simulatorSubscribe(event.id, params)
        .catch((err) =>
          this.eventErrorAlertToggle({
            status: true,
            message: err.response.data.message,
          })
        );
      if (response) {
        EventModel.update({ where: event.id, data: response.data }).then(
          this.finishEventCreation
        );
      }
      this.buttonLoader();
    },
    async unsubscribeSimulator(event) {
      this.buttonLoader();
      const response = await eventService.simulatorDecline(event.id);
      if (response)
        EventModel.update({ where: event.id, data: response.data }).then(
          this.finishEventCreation
        );
      this.buttonLoader();
    },
    async eventDelete(event) {
      this.buttonLoader();
      const response = await eventService.delete(event.id);
      if (response) {
        EventModel.delete(event.id).then(this.finishEventCreation);
        EventModel.insert({ data: response.data || {} });
      }
      this.buttonLoader();
    },
    eventCancel() {
      this.$refs.observer.reset();
      this.finishEventCreation();
    },
    updateEventModel() {
      this.eventModel = { ...this.newEventModelGetter };
    },
  },
};
</script>

<style>
.duration-amount input {
  text-align: center;
  cursor: default;
}
.v-input__prepend-inner,
.v-input__append-inner {
  cursor: pointer;
}
.justify-center {
  display: flex;
  justify-content: space-between;
}
</style>
