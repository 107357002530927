import api from "@/utils/api";

export default {
  load(params) {
    return new Promise((resolve, reject) => {
      api
        .get("covid-statuses", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
