<template>
  <div>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown">
      <v-card :loading="loading">
        <v-toolbar elevation="3">
          <v-card-title>{{ $t("label.preowned_license_templates") }}</v-card-title>
          <v-spacer />
          <v-btn text exact color="primary" @click="openDialog">{{ $t("label.create") }}</v-btn>
        </v-toolbar>
        <v-card-text v-if="!loading">
          <div class="grid mb-5 align-center">
            <v-select
                :label="$t('label.filter_by_license')"
                v-model="filterByLicenses"
                :items="licenses"
                item-value="id"
                item-text="name"
                clearable
                solo
                dense
                multiple
                chips
                small-chips
                deletable-chips
                hide-details
            />
            <v-text-field
                :label="$t('label.filter_by_name')"
                v-model="filterByName"
                clearable
                solo
                dense
                hide-details
            />
            <v-checkbox
                :label="$t('label.show_inactive')"
                v-model="showInactive"
                solo
                dense
                hide-details
                @change="filterByStatus"
            />
          </div>

          <PreownedLicensesTemplatesTable @edit="editRequirements" :licensesTemplates="filteredTemplates"/>
        </v-card-text>

        <v-card-text v-else>
          <v-skeleton-loader class="mx-auto" type="table-row@3" />
        </v-card-text>
      </v-card>
    </v-container>
    <LicenseTemplateCreateDialog ref="licenseDialog" />
  </div>
</template>

<script>

import PreownedLicensesTemplatesTable from "@/components/license-requirements/PreownedLicensesTemplatesTable"
import LicenseTemplateCreateDialog from "@/components/license-requirements/LicenseTemplateCreateDialog"
import LicenseModel from '@/store/models/LicenseModel'
import LicenseRequirementModel from '@/store/models/LicenseRequirementModel'

import licenseRequirementsService from '@/services/licenseRequirementsService'

export default {
  name: "LicenseRequirements",
  components: { PreownedLicensesTemplatesTable, LicenseTemplateCreateDialog },
  data: () => ({
    loading: true,
    filterByLicenses: [],
    filterByName: '',
    showInactive: true,
  }),
  mounted() {
    licenseRequirementsService.load()
        .then((response) => LicenseRequirementModel.create({ data: response.data }))
        .finally(() => this.loading = false)
  },
  computed: {
    filteredTemplates() {
      const query = LicenseRequirementModel.query()
      if (this.filterByLicenses.length) {
        query.where((item) => item.licenses.some(license => this.filterByLicenses.includes(license)))
      }
      if (this.filterByName) {
        query.where((item) => item.name.toLowerCase().includes(this.filterByName.toLowerCase()))
      }
      if (!this.showInactive) {
        query.where((item) => item.active)
      }
      return query.orderBy('active', 'desc').get() || []
    },
    licenses() {
      return LicenseModel.all()
    },
  },
  methods: {
    openDialog() {
      this.$refs.licenseDialog.open()
    },
    editRequirements(val) {
      this.$refs.licenseDialog.open(val)
    },
    filterByStatus(val) {
      this.showInactive = val
    },
  },
};
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  gap: 0 20px;
  grid-template-columns: 1fr 1.5fr 190px;
}
</style>
