<script>

export default ({
  name: "ItemsLoader",
})
</script>
<template>
  <div class="pa-2">
    <div class="items-grid mb-2">
      <div class="item d-flex flex-row pa-1 flex-shrink-1 flex-grow-1 align-center mb-2">
        <v-skeleton-loader type="avatar" min-width="50" height="50" width="50"  class="mr-2" />
        <div class="d-flex flex-column flex-shrink-1 flex-grow-1 justify-space-around fill-height">
          <v-skeleton-loader type="paragraph" width="150" class="mb-0"/>
        </div>
      </div>
    </div>
    <v-skeleton-loader type="text" width="150" class="mb-0"/>

    <div class="items-grid mb-2">
      <div v-for="n in 4" :key="n" class="item d-flex flex-row pa-1 flex-shrink-1 flex-grow-1 align-center mb-2">
        <v-skeleton-loader type="avatar" min-width="50" height="50" width="50"  class="mr-2" />
        <div class="d-flex flex-column flex-shrink-1 flex-grow-1 justify-space-around fill-height">
          <v-skeleton-loader type="paragraph" width="150" class="mb-0"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.items-grid {
  grid-gap: .5em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  padding: 0;
}
.item {
  height: 72px;
  border-radius: 5px;
  border: solid 1px #ccc;
  background: #fff;
  font-size: 1rem;
  transition: 1s;
  position: relative;
}
</style>
