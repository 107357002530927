import api from "@/utils/api";

export default {
  load() {
    return new Promise((resolve, reject) => {
      api
        .get("license-requirements")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  create(data) {
    return new Promise((resolve, reject) => {
      api
        .post("license-requirements", data)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  update(data) {
    return new Promise((resolve, reject) => {
      api
        .put(`license-requirements/${data.id}`, data)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  toggleActiveStatus(id, status) {
    return new Promise((resolve, reject) => {
      api
        .patch(`license-requirements/${id}/active-status`, status)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
}
