<script>
export default {
  name: 'InsightsIcon',
  props: {
    icon: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: ''
    },
    size: {
      type: [String, Number],
      default: 64
    },
    rounded: {
      type: Boolean,
      default: true
    },
    right: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    iconImage() {
      try {
        return require(`@/assets/insights/icons/${this.icon}`)
      } catch (e) {
        return require(`@/assets/insights/icons/no-icon.svg`)
      }
    },
  }
}
</script>

<template>
<v-avatar :right="right" :rounded="rounded" :min-width="size" :width="size" :height="size">
  <img :src="iconImage" :alt="alt">
</v-avatar>
</template>

<style scoped>

</style>
