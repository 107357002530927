<template>
  <div>
    <v-icon small="small" color="primary" v-if="checked">mdi-checkbox-marked-outline</v-icon>
    <v-icon small="small" v-else>mdi-checkbox-blank-outline</v-icon>
  </div>
</template>

<script>
export default {
  name: "CheckmarkBox",
  props: {
    checked: {
      type: Boolean,
      default: false,
      required: true,
    },
    small: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
