<template>
  <v-card>
    <v-sheet rounded class="d-flex justify-space-between align-center">
      <v-tabs :value="pickedFilter" dense height="49" style="border-top-left-radius: 4px" @change="changePickerFilter">
        <v-tab v-for="filter in filters" :key="filter" :href="`#${filter}`">
          <span style="font-size: 12px">{{ $t(`label.${filter}`) }}</span>
        </v-tab>
      </v-tabs>
      <v-btn icon small @click="load(true)" :loading="reloading" class="mr-2">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </v-sheet>

    <v-divider style="transform: translateY(-1px);"  />

    <v-card-text>
      <UpcomingAppointmentsLoader v-if="loading" :count="daily ? 5 : 3" :days="showDailyFilter" />
      <template v-else>
        <empty-box v-if="!appointments.count" :image="'assets/svg/calendar-dark.svg'" />
        <div v-else>
          <v-btn-toggle v-if="showDailyFilter" v-model="selectedDay" class="mb-3 d-flex align-stretch" dense mandatory>
            <v-btn
              v-for="day in days"
              :key="day.date"
              :style="{ opacity: !upcomingByDate(day.fullDate).length ? 0.6 : 1 }"
              class="py-5 d-flex flex-column"
              style="height: 30px; flex: 1;"
              x-small
            >
              {{ day.date }} <br> {{ day.name }}
            </v-btn>
          </v-btn-toggle>

          <template v-if="selectedDateAppointments.length">
            <AppointmentCard
              v-for="appointment in selectedDateAppointments"
              :key="appointment.id"
              :appointment="appointment"
              @click.native="$router.push({ name: 'calendar.appointment', params: { id: appointment.id } })"
            />
          </template>

          <empty-box v-else />
        </div>
      </template>
    </v-card-text>

    <template v-if="showMoreLessFunctional">
      <v-divider/>
      <v-card-actions class="py-3 px-7 d-flex justify-space-between">
        <span v-if="!showAllAppointments" class="text-subtitle-1">
          {{ $t("label.plus_some_more_items", {count: upcoming.length - maxAmountToShow})  }}
        </span>

        <v-spacer/>

        <v-btn text color="blue" @click="toggleShowAllAppointments">
          {{ showAllAppointments ? $t("label.less") : $t("label.more") }}
        </v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import {format, isSameDay, parseISO} from "date-fns";

import UpcomingAppointmentsLoader from "@/components/skeleton-loaders/UpcomingAppointmentsLoader";
import widgetsService from "@/services/widgetsService";
import AppointmentCard from "@/components/appointment/AppointmentCard";
import AppointmentModel from "@/store/models/AppointmentModel";
import LocalizationManager from "@/helpers/LocalizationManager";

export default {
  name: "UpcomingAppointments",
  props: {
    daily: {
      type: Boolean,
      default: true
    }
  },
  components: {AppointmentCard, UpcomingAppointmentsLoader},
  data() {
    return {
      filters: ['appointments', 'requests'],
      pickedFilter: localStorage.getItem('pickedFilterValue') || 'appointments',
      appointments: {
        items: [],
        count: 0,
        period: []
      },
      selectedDay: null,
      loading: true,
      reloading: false,
      maxAmountToShow: 5,
      showAllAppointments: false
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    changePickerFilter(val) {
      this.pickedFilter = val;
      localStorage.setItem('pickedFilterValue', val)
      this.load()
    },
    load(reload = false) {
      this.loading = ! reload
      this.reloading = reload
      const action = this.pickedFilter === 'requests'
        ? 'upcomingRequests'
        : 'upcomingAppointments'

      widgetsService[action]({ daily: this.daily && this.pickedFilter !== "requests" })
        .then(resp => {
          this.appointments.items = resp.data.items;
          this.appointments.count = resp.data.count;
          this.appointments.period = resp.data.period;
          if (!reload) this.selectedDay = resp.data.period[0];
          AppointmentModel.create({ data: resp.data.items });
        })
        .finally(() => {
          this.loading = this.reloading = false;
        });
    },
    upcomingByDate(date) {
      return this.upcoming.filter(item => {
        return isSameDay(parseISO(item.start), parseISO(date))
      })
    },
    toggleShowAllAppointments () {
      this.showAllAppointments = !this.showAllAppointments
    }
  },
  computed: {
    upcoming() {
      return AppointmentModel
          .query()
          .with('teacher')
          .with('students')
          .orderBy('start')
          .get()
    },
    selectedDateAppointments() {
      if (this.pickedFilter === "requests") return this.upcoming;

      if (this.daily) {
        return this.upcoming.filter(item => {
          return isSameDay(parseISO(item.start), parseISO(this.appointments.period[this.selectedDay]))
        })
      }

      return this.showAllAppointments ? this.upcoming : this.upcoming.slice(0, this.maxAmountToShow)
    },
    days() {
      return this.appointments.period.map(item => {
        const date = parseISO(item)
        return {
          date: format(date, 'd'),
          name: format(date, 'EEEEEE', {locale: LocalizationManager.dateFnsLocale}),
          fullDate: item
        }
      })
    },
    showDailyFilter() {
      return this.daily && this.pickedFilter !== "requests";
    },
    showMoreLessFunctional() {
      return !this.loading && !this.daily && this.pickedFilter !== "requests" && this.upcoming.length > this.maxAmountToShow;
    }
  }
}
</script>

<style scoped lang="scss">

</style>
