import api from "@/utils/api";

export default {
  data(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/teachers", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  load(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/teachers", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadItem(teacherId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/teachers/${teacherId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadFormData() {
    return new Promise((resolve, reject) => {
      api
        .get("/teachers/formData")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  create(params) {
    return new Promise((resolve, reject) => {
      api
        .post("/teachers", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  update(teacherId, params) {
    return new Promise((resolve, reject) => {
      api
        .post(`/teachers/${teacherId}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  destroy(teacherId) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/teachers/${teacherId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  makeActive(teacherId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/teachers/${teacherId}/make-active`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  downloadDailyWorkTimeReport(teacherId, params) {
    return new Promise((resolve, reject) => {
      api
        .get(`/teachers/${teacherId}/daily-worktime-pdf`, {
          params: params,
          responseType: "blob",
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  workedTime(teacherId, params = null) {
    return new Promise((resolve, reject) => {
      api
        .get(`/teachers/${teacherId}/worked-time`, { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
