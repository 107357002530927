<script>
import {defineComponent} from 'vue'
import clipboard from "@/utils/mixins/clipboard";
import markdownParser from "@/utils/mixins/markdownParser";
import clickAndLearnService from "@/services/clickAndLearnService";
import {Device} from "@capacitor/device";
import { AppLauncher } from '@capacitor/app-launcher';

export default defineComponent({
  name: "StudentCredentialsWidget",
  mixins: [clipboard, markdownParser],
  data() {
    return {
      loading: false,
      form: {
        username: null,
        password: null
      },
      platform: null,
      learningAppUrl: process.env.VUE_APP_CLICK_AND_LEARN_STUDENT_URL,
      state: false
    };
  },
  async mounted() {
    this.state = JSON.parse(localStorage.getItem('studentCredentialsWidgetState'))

    const { platform } = await Device.getInfo();
    this.platform = platform;
    this.loading = true
    clickAndLearnService
      .getStudentCredentials()
      .then(response => {
        this.form = response.data
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    async checkCanOpenUrl() {
      const { value } = await AppLauncher.canOpenUrl({ url: 'de.degener.clicklearn.forms' });

      return value
    },
    async appLauncherOpenClickAndLearn () {
      await AppLauncher.openUrl({ url: 'de.degener.clicklearn.forms' });
    },
    toggle() {
      this.state = !this.state
      localStorage.setItem('studentCredentialsWidgetState', this.state)
    },
    async openClickAndLearnApp() {
      const { platform } = await Device.getInfo();

      if (platform === 'android') {
        const { value: canOpenUrl } = await AppLauncher.canOpenUrl({ url: 'de.degener.clicklearn.forms' });

        if (canOpenUrl) {
          await AppLauncher.openUrl({ url: 'de.degener.clicklearn.forms' });
          return;
        }
      }

      const link = document.getElementById('clickAndLearnLink');
      link?.click()
    }
  },
  computed: {
    expandIcon() {
      return this.state ? 'mdi-chevron-up' : 'mdi-chevron-down'
    }
  }
})
</script>

<template>
  <v-card>
    <v-card-title @click="toggle" class="py-2 d-flex justify-space-between">
      <div class="d-flex flex-column">
        <span>{{ $t('clickandlearn.learning_app_credentials') }}</span>
      </div>
      <v-btn icon small @click.stop="toggle">
        <v-icon>{{ expandIcon }}</v-icon>
      </v-btn>
    </v-card-title>
    <template v-if="state">
      <v-divider/>
      <v-card-text>
        <p v-html="htmlFromMarkdown( $t('clickandlearn.student_instructions') )"></p>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              :disabled="loading"
              :loading="loading"
              :label="$t('label.username')"
              readonly
              :value="form.username"
              append-icon="mdi-content-copy"
              dense
              hide-details
              @click:append="copyToClipboard(form.username)"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :disabled="loading"
              :loading="loading"
              type="password"
              :label="$t('form.password')"
              readonly
              value="superpassword"
              append-icon="mdi-content-copy"
              dense
              hide-details
              @click:append="copyToClipboard(form.password)"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions v-if="form.username && form.password">
        <v-btn small text @click="openClickAndLearnApp">
          <v-icon left>$clickAndLearn</v-icon>
          {{ $t('clickandlearn.open_learning_app') }}
        </v-btn>
      </v-card-actions>
    </template>
    <a :href="learningAppUrl" target="_blank" style="display: none;" id="clickAndLearnLink">ClickLearn</a>
  </v-card>
</template>

<style scoped>

</style>