<template>
<v-card>
  <VehicleToolBar title="vehicleService" activeTab="service">
    <v-btn color="primary" text @click="$refs.vehicleServiceDialog.open($route.params.id)">
      <v-icon>mdi-plus</v-icon>
      {{ $t("btn.add") }}
    </v-btn>
  </VehicleToolBar>

  <v-card-text>
      <v-skeleton-loader v-if="loading" type="table" />
      <template v-else>
          <empty-box v-if="! services.length" />
          <v-simple-table v-else dense>
              <thead>
              <tr>
                <th class="text-left" style="width: 150px">{{ $t("filters.from") }}</th>
                <th class="text-left" style="width: 150px">{{ $t("filters.to") }}</th>
                <th class="text-left">{{ $t("label.notes") }}</th>
                <th style="width: 180px;text-align: right">
                  {{ $t("label.actions") }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                class="table-row"
                v-for="service in orderedServices"
                :key="service.id"
                @mouseenter="hovered = service.id"
                @mouseleave="hovered = null"
              >
                  <td>{{ service.from | dateFormat("dd.MM.yyyy HH:mm")}}</td>
                  <td>{{ service.to | dateFormat("dd.MM.yyyy HH:mm") }}</td>
                  <td>{{ service.notes || '&mdash;' }}</td>
                  <td style="text-align: right">
                      <v-progress-linear
                          indeterminate
                          color="primary"
                          v-if="[editing, deleting].includes(service.id)" />

                      <template v-else>
                        <v-chip
                          @click.stop="$refs.vehicleServiceExpensesDialog.open($route.params.id, service)"
                          class="hidden-until-row-hovered mx-1"
                          link
                          label
                          x-small
                          v-if="service.isPast"
                        >
                          <v-icon x-small>mdi-cash-minus</v-icon>
                        </v-chip>
                        <v-chip
                            :disabled="editing !== null"
                            @click.stop="edit(service.id)"
                            class="hidden-until-row-hovered mx-1"
                            link
                            label
                            x-small
                            :loading="editing === service.id">
                            <v-icon x-small>mdi-pencil</v-icon>
                        </v-chip>
                        <v-chip
                            :disabled="deleting !== null"
                            class="hidden-until-row-hovered mx-1"
                            link
                            label
                            x-small
                            dark
                            color="red"
                            @click.stop="destroy(service.id)"
                        >
                            <v-icon x-small>mdi-close</v-icon>
                        </v-chip>
                      </template>
                  </td>
              </tr>
              </tbody>
          </v-simple-table>
      </template>
  </v-card-text>

  <VehicleServiceDialog
    ref="vehicleServiceDialog"
    @created="addNewService"
    @updated="updateService"
  />
  <VehicleServiceExpensesDialog ref="vehicleServiceExpensesDialog" />
</v-card>
</template>

<script>
import VehicleServiceDialog from '@/components/vehicle/VehicleServiceDialog.vue'
import vehiclesService from '@/services/vehiclesService'
import { showConfirm } from '@/helpers/dialogs'
import VehicleServiceExpensesDialog from "@/components/vehicle/VehicleServiceExpensesDialog.vue";
import VehicleToolBar from '@/components/vehicle/VehicleToolBar.vue'

export default {
  name: 'VehicleService',
  components: { VehicleToolBar, VehicleServiceExpensesDialog, VehicleServiceDialog },
  data() {
    return {
      loading: false,
      services: [],
      hovered: null,
      deleting: null,
      editing: null
    }
  },
  computed: {
      orderedServices() {
          return [...this.services].sort((prev, next) => new Date(next.from) - new Date(prev.from))
      }
  },
  mounted () {
    this.loading = true;
    vehiclesService.loadServices(this.$route.params.id)
      .then(response => {
        this.services = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
  },
    methods: {
        addNewService(service) {
          this.services.push(service)
        },
        updateService(service) {
            const serviceIndex = this.services.findIndex(item => item.id === service.id)
            this.$set(this.services, serviceIndex, service)
        },
        edit(id) {
            this.$refs.vehicleServiceDialog.open(this.$route.params.id, id)
        },
        destroy(id) {
            showConfirm(
                this.$t('btn.confirm'),
                this.$t('messages.are_your_sure_delete'),
                () => {
                    this.deleting = true
                    vehiclesService
                        .deleteService(this.$route.params.id, id)
                        .then(() => {
                            this.services = this.services.filter(item => item.id !== id)
                        })
                        .finally(() => {
                            this.deleting = null
                        })
                }
            )
        }
    }
}
</script>

<style scoped>

</style>
