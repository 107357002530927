<template>
<ShowsAs
  :type="$vuetify.breakpoint.mobile ? 'dialog' : 'menu'"
  :options="{
    offsetY: true,
    right: true,
    zIndex: 10,
    nudgeWidth: 850,
    nudgeLeft: 40,
    nudgeBottom: 10,
    maxWidth: 850
  }"
  ref="showAs"
>
  <template v-slot:activator="{ on, attrs }">
    <v-badge
      color="primary"
      overlap
      :content="appliedFilters.length"
      :value="appliedFilters.length"
      offset-x="15"
      offset-y="15"
    >
      <v-btn icon color="primary" text v-bind="attrs" v-on="on">
        <v-icon>{{
          appliedFilters.length > 0
            ? "mdi-filter-plus-outline"
            : "mdi-filter-outline"
        }}</v-icon>
      </v-btn>
    </v-badge>
  </template>

  <v-card>
    <v-card-title>{{ $t("label.filters") }}</v-card-title>

    <v-card-text>
      <slot />
    </v-card-text>

    <v-divider/>

    <v-card-actions>
      <v-btn text color="red" @click="clear">{{ $t("btn.clear") }}</v-btn>

      <v-spacer />

      <v-btn text color="primary" @click="apply">{{ $t("btn.ok") }}</v-btn>
    </v-card-actions>
  </v-card>
</ShowsAs>
</template>

<script>
import ShowsAs from '@/components/ShowsAs.vue'

export default {
  components: { ShowsAs },
  name: "ListFiltersMenu",
  computed: {
    appliedFilters() {
      if (!this.$route.query.filters) return [];
      return this.$route.query.filters.slice(0, -1).split(";");
    },
  },
  methods: {
    apply() {
      this.$emit("apply");
      this.$refs.showAs.close()
    },
    clear() {
      this.$emit("clear");
    }
  },
};
</script>

<style scoped></style>
