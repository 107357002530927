import api from "@/utils/api";

export default {
  load(params) {
    return new Promise((resolve, reject) => {
      api
        .get("school/payments", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadItem(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`school/payments/${id}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  sameUserPayments(payment) {
    return new Promise((resolve, reject) => {
      api
        .get(`school/payments/${payment}/others`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  uniqueUsers(params) {
    return new Promise((resolve, reject) => {
      api
        .get("school/payments/unique-users", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  downloadReport(params) {
    return new Promise((resolve, reject) => {
      api
        .get("school/payments/report", {
          params: params,
          responseType: "blob",
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  downloadInvoice(paymentId) {
    return new Promise((resolve, reject) => {
      api
        .get(`school/payments/${paymentId}/invoice`, {
          responseType: "blob",
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
