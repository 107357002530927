import { render, staticRenderFns } from "./StudentPayments.vue?vue&type=template&id=502e381e&scoped=true"
import script from "./StudentPayments.vue?vue&type=script&lang=js"
export * from "./StudentPayments.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "502e381e",
  null
  
)

export default component.exports