<template>
  <div class="d-flex align-center" style="height: 100vh; width: 100%">
    <div class="d-flex flex-column ma-auto">
      <span class="title mb-2">Loading... Please wait</span>
      <v-progress-circular
        class="ma-auto align-self-center"
        color="primary"
        indeterminate
        size="50" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PaymentLogin",
  computed: {},
  mounted() {
    if (!this.$route.query.accessToken) {
      this.$router.push({ name: "login" }).catch((error) => console.log(error));
    }

    const { accessToken, platform, prefill, reason, studentId } = this.$route.query;

    this.removeAccessToken().finally(() => {
      this.setAccessToken(accessToken)
        .then(() => {
          localStorage.setItem("parentSelectedStudentId", studentId);

          this.$router
            .push({ name: "pay", query: { platform, prefill, reason } })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    });
  },
  methods: {
    ...mapActions("auth", ["setAccessToken", "removeAccessToken"]),
  },
};
</script>

<style scoped></style>
