<script>
import DocumentListItem from "@/components/DocumentListItem.vue";

export default {
  name: "StudentDocumentsList",
  props: {
    subheader: {
      type: String
    },
    documents: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: { DocumentListItem }
};
</script>

<template>
  <div v-if="loading">
    <v-skeleton-loader v-for="nr in 2" :key="nr" type="list-item" />
  </div>
  <v-list v-else-if="documents.length">
    <v-subheader v-if="subheader">{{ subheader }}</v-subheader>
    <template v-for="(document, key) in documents">
      <document-list-item
        :key="`${document.id}-${document.type}`"
        :document="document"
        @deleted="$emit('delete', document.id)"
        @downloaded="$emit('downloaded', document.id)"
        @signed="$emit('signed', document.id)"
      />
      <v-divider v-if="key < documents.length - 1" :key="`${document.id}-${document.createdAt}`"></v-divider>
    </template>
  </v-list>
  <empty-box v-else />
</template>

<style scoped>

</style>
