import { format, parseISO } from "date-fns";

export default {
  computed: {
    formattedDates() {
      if (this.filterValues.dates.length) {
        const localDate = this.filterValues.dates;
        const formatted = localDate
          .sort()
          .map((item) => format(parseISO(item), "dd.MM.yyyy"));

        return (formatted.length > 1) ? `vom ${formatted[0]} bis ${formatted[1]}` : formatted;
      }
      return this.filterValues.dates;
    }
  },
  methods: {
    clearFilterByDate() {
      this.filterValues.dates = [];
      this.dates = [];
    },
    setStartDateFilter() {
      this.filterValues.dates = this.dates;
      this.$refs.dialog.save(this.filterValues.dates.sort());
    }
  }
};
