<template>
  <v-app-bar class="app-bar" app dense dark>
    <v-badge class="absolute-badge" color="error" dot offset-y="15" offset-x="15" :value="showAppBarNavIcon">
      <v-app-bar-nav-icon id="nav-bar-icon" :key="showSideBar" @click.stop="toggleSideBar">
        <v-list-item-avatar v-if="isNavIconLogo" class="ml-5">
          <v-img :src="miniLogo" />
        </v-list-item-avatar>
      </v-app-bar-nav-icon>
    </v-badge>

    <v-toolbar-title class="nav-bar white--text pl-0 ml-2">{{pageTitle}}</v-toolbar-title>

    <v-spacer />

    <span
      v-if="isStudent && currentUser.hasPaymentAbility"
      class="balance mr-3 d-none d-lg-block">
      <BalanceLabel :student="currentUser" />
    </span>

    <span v-if="isSchoolAdministrator && subscriptionPlan === 'credit_based'" class="mr-2">
      <school-credits-balance-label />
    </span>

    <LanguageSwitcher />

    <Notifications v-if="!isElter" />

    <UserMenu class="ml-1" />
  </v-app-bar>
</template>

<script>
import UserMenu from "./UserMenu";
import Notifications from "@/components/Notifications";
import responsive from "@/utils/mixins/responsive";
import { mapGetters, mapState } from "vuex";
import user from "@/utils/mixins/user";
import BalanceLabel from "@/components/BalanceLabel";
import * as mutationTypes from "@/store/mutation-types/main";
import LanguageSwitcher from "@/components/LanguageSwitcher";
import SchoolCreditsBalanceLabel from "@/components/school/SchoolCreditsBalanceLabel.vue";

export default {
  name: "Navbar",
  mixins: [user, responsive],
  props: {
    isNavIconLogo: {
      type: Boolean,
      default: false,
    },
    exitIcon: require("../assets/svg/exit.svg"),
  },
  data: () => ({
    miniLogo: require("@/assets/svg/bookandpay_new_logo.svg"),
  }),
  components: {
    SchoolCreditsBalanceLabel,
    LanguageSwitcher,
    BalanceLabel,
    UserMenu,
    Notifications,
  },
  computed: {
    ...mapState("main", ["showSideBar", "isSidebarMinimized"]),
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("school", ["subscriptionPlan"]),
    ...mapState("messenger", ["unseenMessagesCount"]),
    showAppBarNavIcon() {
      return this.$vuetify.breakpoint.mobile &&
          (this.currentUser.unsignedDocumentsCount > 0 || this.unseenMessagesCount > 0);
    },
    showSideBar() {
      return this.$store.state.main.showSideBar;
    },
    pageTitle() {
      return this.$t(this.$route.meta.pageName) || this.$t("label.page_name");
    },
    mdLandscapeAndDown() {
      return (
        this.$vuetify.breakpoint.mdAndDown &&
        !(this.$vuetify.breakpoint.md && window.innerHeight < window.innerWidth)
      );
    },
    routeHasParentRoute() {
      return this.$route.matched.length > 1 || this.$route.meta.parent;
    },
    studentBalanceColor() {
      const balance = parseInt(this.currentUser.balance);
      if (balance <= 200 && balance > 50) return "yellow";
      if (balance > 100) return "green";
      return "red";
    },
  },
  methods: {
    toggleSideBar() {
      this.$store.commit(
        `main/${mutationTypes.SHOW_SIDEBAR}`,
        !this.showSideBar
      );
      this.$store.dispatch("main/minimizeMenu", !this.isSidebarMinimized);
    },
  },
};
</script>

<style scoped lang="scss">
.app-bar {
  background-color: #00435e !important;
}
.nav-bar,
.balance {
  font-size: 1.2rem !important;
}
</style>
