<template>
  <div>
    <TimeFields
      :disabled="true"
      :model="model"
      :eventDuration="eventDuration" />
    <v-row>
      <LicenseField
        :cols="6"
        :activeLicenses="studentLicenses"
        :clearable="false"
        :disabled="disabledLicense"
        :validation="true" />
      <v-col cols="6">
        <ValidationProvider
          name="simulatorName"
          rules="required"
          v-slot="{ errors }">
          <v-text-field
            :error="errors.length > 0"
            label="Simulator Name"
            v-model="model.name"
            outlined
            hide-details
            dense
            disabled />
        </ValidationProvider>
      </v-col>
      <OfficesField
        :cols="12"
        :disabled="true"
        :validation="true"
        :model="model"
        :offices="offices" />
    </v-row>
  </div>
</template>

<script>
import user from "@/utils/mixins/user";
import activeLicenseMixins from "@/utils/mixins/activeLicenseMixins";

import LicenseField from "@/components/calendar/eventModal/fields/LicenseField";
import TimeFields from "@/components/calendar/eventModal/fields/TimeFields";
import OfficesField from "@/components/calendar/eventModal/fields/OfficesField";
import OfficeModel from "@/store/models/OfficeModel";
import StudentModel from "@/store/models/StudentModel";
import LicenseModel from "@/store/models/LicenseModel";
import EventModel from "@/store/models/EventModel";
import { mapGetters } from "vuex";

export default {
  name: "StudentSimulatorForm",
  mixins: [user, activeLicenseMixins],
  components: { LicenseField, TimeFields, OfficesField },
  props: {
    eventDuration: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    schoolStudents() {
      return StudentModel.all() || {};
    },
    offices() {
      return OfficeModel.all();
    },
    studentLicenses() {
      return (
        LicenseModel.query()
          .where((license) => this.currentUser.licensesIds.includes(license.id))
          .get() || {}
      );
    },
    disabledLicense() {
      const event = EventModel.find(this.model.id) || {};
      return !!event.licenseId;
    },
  },
};
</script>
