<template>
  <v-menu
    ref="menu"
    v-model="datePicker"
    :close-on-content-click="false"
    :return-value.sync="model.date"
    transition="scale-transition"
    offset-y
    min-width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :disabled="disabled"
        :label="$t('label.date')"
        v-model="dateFormatted"
        readonly
        outlined
        dense
        hide-details
        v-bind="attrs"
        v-on="on"></v-text-field>
    </template>
    <v-date-picker
      v-model="model.date"
      :allowed-dates="model.eventType !== 'OFFTIME' ? allowedDates : null"
      @change="dayChanged"
      no-title
      scrollable
      first-day-of-week="1">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="datePicker = false">{{
        $t("btn.cancel")
      }}</v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(model.date)">{{
        $t("btn.ok")
      }}</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { format, parseISO } from "date-fns";
import responsive from "@/utils/mixins/responsive";
import { mapGetters } from "vuex";
import DateRange from "@/helpers/dateRange";

export default {
  name: "DateField",
  mixins: [responsive],
  data() {
    return {
      datePicker: false,
    };
  },
  watch: {
    date(day) {
      this.model.start = `${day} ${this.model.startTime}:00`;
    },
  },
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    date() {
      return this.model.date;
    },
    dateFormatted() {
      return format(parseISO(this.model.date), "dd.MM.yyyy");
    },
    allowedDates() {
      const regions = this.schoolSettings.regions?.split(", ");
      /*commented past exam logic*/
      // if (!this.schoolSettings.pastEventsAllowed) {
      //   return (val) =>
      //     !new DateRange().isHoliday(parseISO(val), regions) &&
      //     parseISO(val) >= new Date();
      // } else {
      //   return (val) => !new DateRange().isHoliday(parseISO(val), regions);
      // }
      return (val) => !new DateRange().isHoliday(parseISO(val), regions);
    },
  },
  methods: {
    dayChanged(day) {
      this.model.date = day;
    },
  },
};
</script>

<style lang="scss" scoped></style>
