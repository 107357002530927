<template>
  <div>
    <v-row>
      <v-col class="pt-1">
        <v-btn-toggle
          v-model="model.specialType"
          class="special-tabs"
          color="primary"
          group
          dense
          borderless
          mandatory
          tile
          @change="resetFields">
          <v-btn value="STANDARD" :disabled="disabledForSpecial" text small>{{
            $t("label.standard")
          }}</v-btn>
          <v-btn
            value="PRETEST"
            :disabled="disabledForSpecial || isDisabledOnSunday"
            text
            small
            >{{ $t("label.pretest") }}</v-btn
          >
          <v-btn value="OFFICE" :disabled="disabledForSpecial" text small>{{
            $t("label.office")
          }}</v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <TimeFields
      :disabled="disabledForStandard || isPast"
      :model="model"
      :eventDuration="eventDuration" />

    <template v-if="model.specialType === 'STANDARD'">
      <v-row>
        <TeachersField
          :cols="12"
          :model="model"
          :eventDuration="eventDuration"
          :filteredTeachers="filteredTeachers"
          :disabled="disabledForSpecial || isPast" />
        <StudentsField
          v-if="!holiday"
          :cols="12"
          :disabled="!model.teacherId || isPast"
          :model="model"
          :eventDuration="eventDuration"
          :filteredStudents="filteredStudents"
          :multiple="true"
          :validation="false" />
      </v-row>
      <TitleField :disabled="disabledForStandard || isPast" :model="model" />
      <TextField
        :disabled="disabledForStandard || isPast"
        :model="model"
        label="Title" />
      <v-row>
        <v-col>
          <v-switch
            v-model="model.isWorkTime"
            :disabled="isPast || isDisabledWorkTime"
            :label="$t('label.teacher_work_time')"
            inset
            hide-details></v-switch>
        </v-col>
        <v-col>
          <SignatureField
            v-if="showSignature"
            :eventId="model.id"
            :eventType="model.type"
            :eventSignature="model.signature" />
        </v-col>
      </v-row>
    </template>

    <template v-if="model.specialType === 'PRETEST'">
      <v-row>
        <StudentsField
          v-if="!holiday"
          :cols="12"
          :disabled="isPast"
          :model="model"
          :eventDuration="eventDuration"
          :filteredStudents="allStudents"
          :multiple="true"
          :validation="true" />

        <OfficesField
          :cols="12"
          :model="model"
          :offices="offices"
          :disabled="isPast"
          :validation="true" />
      </v-row>
      <v-row>
        <v-col>
          <v-combobox
            @update:search-input="updateTitle"
            v-model="pretestTitle"
            :disabled="disabledForStandard || isPast"
            :label="$t('form.title')"
            :items="titles"
            outlined
            hide-details
            dense
            :open-on-clear="true"
            :append-icon="!model.id ? 'mdi-menu-down' : ''" />
        </v-col>
      </v-row>
      <TextField
        :disabled="disabledForStandard || isPast"
        :model="model"
        label="Title" />
    </template>

    <template v-if="model.specialType === 'OFFICE'">
      <TitleField :disabled="disabledForStandard || isPast" :model="model" />
      <TextField
        :disabled="disabledForStandard || isPast"
        :model="model"
        label="Title" />
    </template>
  </div>
</template>

<script>
import TeachersField from "@/components/calendar/eventModal/fields/TeachersField";
import StudentsField from "@/components/calendar/eventModal/fields/StudentsField";
import TimeFields from "@/components/calendar/eventModal/fields/TimeFields";
import TitleField from "@/components/calendar/eventModal/fields/TitleField";
import TextField from "@/components/calendar/eventModal/fields/TextField";
import OfficesField from "@/components/calendar/eventModal/fields/OfficesField";
import SignatureField from "@/components/calendar/eventModal/fields/SignatureField";
import StudentModel from "@/store/models/StudentModel";
import DateRange from "@/helpers/dateRange";
import { isSunday, parseISO } from "date-fns";
import { mapGetters } from "vuex";
import OfficeModel from "@/store/models/OfficeModel";

export default {
  name: "SchoolSpecialForm",
  components: {
    TeachersField,
    StudentsField,
    TimeFields,
    TitleField,
    TextField,
    OfficesField,
    SignatureField,
  },
  props: {
    eventDuration: {
      type: Number,
      required: true,
    },
    filteredTeachers: {
      type: Array,
      required: true,
    },
    isPast: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    pretestTitle: {
      set(value) {
        this.model.title = value || "";
      },
      get() {
        return this.model.title || "Vortest";
      },
    },
    disabledForSpecial() {
      return !!this.model.id && this.model.eventType === "SPECIAL";
    },
    disabledForStandard() {
      if (this.model.specialType === "STANDARD") return !this.model.teacherId;
      return false;
    },
    disabled() {
      return !this.model.teacherId;
    },
    offices() {
      return OfficeModel.all();
    },
    holiday() {
      const regions = this.schoolSettings.regions?.split(", ");
      return new DateRange().isHoliday(parseISO(this.model.date), regions);
    },
    filteredStudents() {
      return (
        StudentModel.query()
          .where((student) => !student.isDeleted)
          .where("teachersIds", (teachersIds) =>
            teachersIds.includes(this.model.teacherId)
          )
          .get() || []
      );
    },
    allStudents() {
      return (
        StudentModel.query()
          .where((student) => !student.isDeleted)
          .get() || []
      );
    },
    titles() {
      return ["Vortest", "Anmeldung"];
    },
    isDisabledOnSunday() {
      return isSunday(parseISO(this.model.date));
    },
    showSignature() {
      return (
        this.model.id &&
        this.model.specialType === "STANDARD" &&
        this.model.isWorkTime
      );
    },
    isDisabledWorkTime() {
      return !!this.model.signature;
    },
  },
  methods: {
    resetFields() {
      if (this.model.id) return;
      this.model.teacher = null;
      this.model.teacherId = null;
      this.model.studentsIds = [];
      this.model.officeId = null;
      this.model.title = "";
      this.model.eventText = "";
      this.model.isWorkTime = false;
    },
    updateTitle(val) {
      this.model.title = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.special-tabs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  .v-btn--active {
  }
}
</style>
