import { Model } from "@vuex-orm/core";

export default class EvaluationItemModel extends Model {
  static entity = "InsightsEvaluationItems"
  static primaryKey = ["itemId", "evaluationId"]

  static fields() {
    return {
      itemId: this.attr(null),
      evaluationId: this.attr(null),
      score: this.number(0),
      studentScore: this.number(0),
      comment: this.attr(null),
      isFinal: this.boolean(false)
    };
  }
}
