<template>
  <v-card class="" rounded elevation="2">
    <v-card-title :class="{ 'justify-center': $vuetify.breakpoint.smAndDown }">
      {{ school.name }}
    </v-card-title>
    <v-card-subtitle
      :class="{ 'justify-center': $vuetify.breakpoint.smAndDown }">
      {{ officeAddress }}
    </v-card-subtitle>

    <v-divider />

    <v-card-text
      class="d-flex align-center"
      :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }">
      <div class="d-flex flex-column px-3" style="width: 150px">
        <v-img
          v-if="school.avatar"
          class="mb-5 mx-auto"
          :src="school.avatar"
          contain
          aspect-ratio="1.777"
          width="100%" />
      </div>

      <div class="flex-grow-1" style="width: 100%">
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header>{{
              $t("list.licenses")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="d-flex flex-column">
                <div class="licences-grid">
                  <div
                    v-for="licenseId in currentUser.licensesIds"
                    :key="licenseId"
                  >
                    <LicenseIcon
                      v-if="licensesById[licenseId]"
                      :name="licensesById[licenseId].name"
                      :icon="licensesById[licenseId].icon"
                      width="100%"
                      outlined
                      rounded
                    />
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-alert
          v-if="false"
          class="mt-4 pa-1"
          dense
          icon="mdi-clock"
          outlined
          style="max-width: 250px">
          <span class="text-subtitle-2"
            >{{ school.workStartTime }} - {{ school.workEndTime }}</span
          >
        </v-alert>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import user from "@/utils/mixins/user";
import licenses from "@/utils/mixins/licenses";
import { mapGetters } from "vuex";
import LicenseIcon from "@/components/LicenseIcon";

export default {
  name: "TeacherSchoolBlock",
  components: { LicenseIcon },
  mixins: [user, licenses],
  computed: {
    ...mapGetters("school", ["school", "address"]),
    officeAddress() {
      const officeAddress = this.currentUser.officeAddress || {};

      let address = officeAddress.street;

      if (officeAddress.houseNr) address += " " + officeAddress.houseNr;
      if (officeAddress.postcode) address += ", " + officeAddress.postcode;
      if (officeAddress.city) address += " " + officeAddress.city;

      return address;
    },
  },
};
</script>

<style scoped>
.licences-grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
}
</style>
