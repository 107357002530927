<script>
export default {
  name: "EvaluationsScoreLegend"
}
</script>

<template>
  <v-row>
    <v-col>
      <span class="caption">
        <v-icon color="secondary">mdi-circle-medium</v-icon>- {{ $t('user_type.teacher') }}
      </span>
      <span class="caption">
          <v-icon>mdi-circle-medium</v-icon>- {{ $t('user_type.student') }}
        </span>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss">

</style>
