<template>
  <v-container>
    <v-card>
      <v-card-title>{{ $t("route.accountActivation") }}</v-card-title>

      <v-card-text>
        <v-alert color="info" text>
          <div class="text-subtitle-1 font-weight-bold">
            {{ $t("label.account_activation_text_1") }}
          </div>
          <div class="text-subtitle-1 mt-2">
            <span v-if="currentUser.email">{{
              $t("label.account_activation_text_2", {
                email: currentUser.email,
              })
            }}</span>
            <span v-else>{{
              $t("label.account_activation_text_no_email")
            }}</span>
          </div>
        </v-alert>

        <v-btn v-if="currentUser.email" text @click="toggleResendForm">{{
          $t("btn.did_not_receive_the_email")
        }}</v-btn>

        <ValidationObserver
          ref="form"
          v-if="showResendForm || !currentUser.email">
          <div class="pt-5" v-if="!currentUser.emailVerifiedAt">
            <ValidationProvider v-slot="{ errors }" name="email">
              <v-text-field
                prepend-inner-icon="mdi-email-outline"
                dense
                v-model="form.email"
                :label="$t('form.email')"
                :placeholder="$t('form.email')"
                outlined
                type="email"
                required
                :error-messages="errors"
                inputmode="email" />
            </ValidationProvider>
          </div>
        </ValidationObserver>
      </v-card-text>

      <v-card-actions
        class="d-flex justify-end mx-4"
        v-if="showResendForm || !currentUser.email">
        <v-btn
          text
          color="primary"
          @click="send"
          :loading="loading"
          :disabled="loading"
          >{{ $t(`btn.${currentUser.email ? "resend" : "send"}`) }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import user from "@/utils/mixins/user";
import userService from "@/services/userService";
import { mapActions } from "vuex";

export default {
  name: "ProfileActivation",
  mixins: [user],
  data: () => ({
    loading: false,
    showPassword: false,
    showConfirmationPassword: false,
    showResendForm: false,
    form: {
      email: "",
      password: "",
      passwordConfirmation: "",
    },
  }),
  created() {
    if (this.currentUser.emailVerifiedAt) {
      this.$router.push("/");
    }

    this.form.email = this.currentUser.email;
  },
  methods: {
    ...mapActions("auth", ["getAuthUser"]),
    toggleResendForm() {
      this.showResendForm = !this.showResendForm;
    },
    send() {
      this.loading = true;
      userService
        .profileActivation(this.form)
        .then(() => {
          this.$store.dispatch("auth/getAuthUser").then(() => {
            if (
              !this.currentUser.emailVerifiedAt &&
              this.currentUser.emailVerificationSent
            ) {
              this.$snackbar.show({
                message: this.$t("messages.successfully_sent"),
                color: "success",
                timeout: 2000,
              });
            }
          });
        })
        .catch((error) => {
          console.log(error);

          const response = error.response || {};
          const responseData = response.data || {};
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message)
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });

          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
