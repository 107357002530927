import { format } from 'date-fns'

export default {
  data: () => ({
    position: 0,
  }),

  created() {
    this.startIndicator();
  },

  methods: {
    move() {
      this.position = this.position + this.oneMinuteSize;
    },
    startIndicator() {
      this.position = this.indicatorPosition;
      setTimeout(() => {
        this.move();
        this.startIteration();
      }, 60000 - format(new Date(), "s") * 1000);
    },
    startIteration() {
      setInterval(() => {
        this.move();
      }, 60000);
    },
  },
};
