<template>
  <div>
    <v-chip
      label
      :color="color"
      outlined
      dark
      small
      :disabled="disabled"
      @click.stop.native="openBalanceBreakdown"
      :style="cursor"
    >
      <v-icon left>{{ iconValue }}</v-icon>
      <span>
        {{ student.balance | currency }}
      </span>
      <v-icon color="warning" v-if="hasInsufficientFunds" right small>mdi-alert</v-icon>
    </v-chip>
    <StudentBalanceBreakdownDialog :student="student" ref="StudentBalanceBreakdownDialog"/>
  </div>
</template>

<script>
import studentBalanceLabel from "@/utils/mixins/studentBalanceLabel";

export default {
  name: "BalanceLabel",
  mixins: [studentBalanceLabel],
  props: {
    icon: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style scoped></style>
