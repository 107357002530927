<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.5 283.5"
    fill="currentColor"
    class="svg"
    xml:space="preserve">
    <g>
      <path
        d="M45.8,203.3l34.8,34.8l-23.9,23.9l7.4,7.4c0,0,4.2,3.7-0.9,8.8s-10.6,0-10.6,0L5.3,231c0,0-4.8-5.4,0-10.2s11.2-0.2,11.2-0.2l6.1,6.1L45.8,203.3z" />
      <path
        d="M68.8,157.5l57,57l-11.6,11.6l8.4,8.4c0,0,3.1,3.9-1.9,8.9c-5,5-9.2,1.6-9.2,1.6l-72.4-72.4c0,0-4.1-5.3,0-9.4s9.9-2.6,12.3-0.2c2.4,2.4,5.8,5.8,5.8,5.8L68.8,157.5z" />
      <path
        d="M225,106.4c-4.3-4.3-48.4-48.4-48.4-48.4s-5.4-3.2-8.6,0c-0.9,0.9-8.3,8.3-18.5,18.5c4.8,4.8,14.5,14.5,17.2,17.2c3.6,3.6,4.4,7.7,0,12c-4.5,4.4-10,1-10,1l-18.8-18.5c-3.7,3.7-7.6,7.6-11.5,11.5c4.8,4.8,14.5,14.5,17.2,17.2c3.6,3.6,4.4,7.7,0,12c-4.5,4.4-10,1-10,1l-18.8-18.5c-4,4-7.9,7.9-11.5,11.5c4.8,4.8,14.5,14.5,17.2,17.2c3.6,3.6,4.4,7.7,0,12c-4.5,4.4-10,1-10,1l-18.8-18.5c-6.6,6.6-10.8,10.8-10.8,10.8l57.6,57.6L225,116C225,116,229.4,110.7,225,106.4z" />
      <path
        d="M195.9,53.5l33.8,33.8l7.1-7.1c0,0,3.3-4.4,0-7.7c-3.3-3.3-7.2-7.2-7.2-7.2s46.7-46.7,50-50c3.3-3.3,2.4-8.5-0.8-11.7s-8.6-1.3-10.6,0.7s-49.6,49.6-49.6,49.6s-6.1-6.1-7.2-7.2c-1-1-6.5-2.2-8.8,0C200.3,49,195.9,53.5,195.9,53.5z" />
      <path
        d="M269.3,197.3c-7.9-6.9-2-11.4,0-13c2-1.6,11.7-12.9,11.7-19.2c0-6.3-0.8-14.6-7.8-14.6c-5.5,0-23.2,0-30.9,0c0,0-1.4,0-3.6,0c-7.7,0-25.4,0-30.9,0c-7,0-7.8,8.3-7.8,14.6c0,6.3,9.6,17.5,11.7,19.2s7.9,6.1,0,13c-7.9,6.9-11.7,18.6-11.7,23.7c0,5.1,0,10.5,0,10.5h38.7h3.6H281c0,0,0-5.4,0-10.5C281,215.8,277.2,204.1,269.3,197.3z" />
      <path
        d="M242,247.5h-3h-39c0,0,0,19.5,0,26c0,6.5,6.9,7,6.9,7H239h3h32.1c0,0,6.9-0.5,6.9-7c0-6.5,0-26,0-26H242z" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Vaccine",
};
</script>

<style scoped></style>
