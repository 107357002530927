<template>
  <div class="text--secondary">
    <span
      v-if="studentsIds.length > 0"
      class="ml-1 text-decoration-underline-dotted"
      @click.stop="showDialogWithStudents">
      <strong>{{ studentsIds.length }}</strong>
      <span class="ml-1">{{
        $t("label.active") +
        " " +
        $tc("label.student_plurable", studentsIds.length)
      }}</span>
    </span>
    <span v-else>{{ $t("messages.no_students") }}</span>

    <StudentsListDialog ref="studentsDialog" />
  </div>
</template>

<script>
import StudentsListDialog from "@/components/student/StudentsListDialog";

export default {
  name: "StudentsLabel",
  props: {
    studentsIds: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: { StudentsListDialog },
  methods: {
    showDialogWithStudents() {
      this.$refs.studentsDialog.open(this.studentsIds);
    },
  },
};
</script>

<style scoped></style>
