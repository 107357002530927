import { Model } from "@vuex-orm/core";
import i18n from "@/plugins/i18n";
import PaymentModel from "@/store/models/PaymentModel";

export default class PaymentChargeModel extends Model {
    static entity = "payment_charges";

    static fields() {
        return {
            id: this.attr(null),
            paymentId: this.attr(null),
            payment: this.belongsTo(PaymentModel, 'paymentId'),
            stripeChargeId: this.attr(null),
            status: this.string(''),
            amount: this.number(0),
            failureCode: this.attr(null),
            failureMessage: this.attr(null),
            outcome: this.attr(null),
            outcomeType: this.attr(null),
            paymentMethodType: this.attr(null),
            paymentMethod: this.attr(null),
            created: this.string(''),
            refunded: this.boolean(false)
        };
    }

    get statusIcon() {
        if (this.refunded) return 'mdi-undo-variant';

        const statusIcon = {
            succeeded: "mdi-check-all",
            pending: "mdi-clock-alert",
            failed: "mdi-close",
        };

        return this.status in statusIcon ? statusIcon[this.status] : 'mdi-question-mark';
    }

    get statusColor() {
        if (this.refunded) return 'warning darken-2';

        const statusColor = {
            succeeded: "green",
            pending: "amber",
            failed: "red",
        };

        return this.status in statusColor ? statusColor[this.status] : 'grey';
    }

    get outcomeLocalized() {
        return i18n.t(`payment_outcome.${this.outcomeType}`)
    }

    get outcomeReason() {
        if (! this.outcome.reason) return ''

        return i18n.t(`payment_outcome_reason.${this.outcome.reason}`)
    }

    get pm() {
        return this.paymentMethod[this.paymentMethod.type];
    }

    get pmCountry() {
        return this.pm.country
    }

    get pmLast4() {
        return this.pm.last4 ?? this.pm.iban_last4
    }

    get pmCardBrand() {
        return this.pm.brand
    }

    get pmIcon() {
        const icons = {
            card: getCardIconByBrand(this.pmCardBrand),
            card_present: getCardIconByBrand(this.pmCardBrand),
            sofort: require("@/assets/payment-methods/sofort.svg"),
            sepa_debit: require("@/assets/payment-methods/sepa.svg"),
            giropay: require("@/assets/payment-methods/giropay.svg"),
        };

        return this.paymentMethodType in icons ? icons[this.paymentMethodType] : require("@/assets/payment-methods/unknown.svg");
    }

    get pmWalletIcon() {
        if (this.paymentMethodType === 'card_present') return require("@/assets/payment-methods/card-present.svg");

        const type = this.pm.wallet ? this.pm.wallet?.type : null;
        const types = {
            apple_pay: require("@/assets/payment-methods/apple-pay.svg"),
            google_pay: require("@/assets/payment-methods/google-pay.svg"),
            link: require("@/assets/payment-methods/link.svg"),
        }

        return type in types ? types[type] : "";
    }
}

const getCardIconByBrand = brand => {
    const brands = {
        amex: require("@/assets/payment-methods/amex.svg"),
        mastercard: require("@/assets/payment-methods/mastercard.svg"),
        visa: require("@/assets/payment-methods/visa.svg"),
        unionpay: require("@/assets/payment-methods/unionpay.svg"),
    }

    return brand in brands ? brands[brand] : "";
}