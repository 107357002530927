<template>
  <v-row>
    <v-col>
      <ValidationProvider name="text" rules="required" v-slot="{ errors }">
        <v-autocomplete
          outlined
          required
          dense
          hide-details
          :error="errors.length > 0"
          v-model="model.theoryTopicId"
          :label="$t('label.topic')"
          :disabled="disabled"
          :items="theoryTopics"
          :item-text="topicLabel"
          item-value="id"
          @change="onChange">
          <template #item="data">
            <span style="max-width: 650px">{{ topicLabel(data.item) }}</span>
          </template>
        </v-autocomplete>
      </ValidationProvider>
    </v-col>
  </v-row>
</template>

<script>
import TheoryTopicModel from "@/store/models/TheoryTopicModel";
import i18n from "@/plugins/i18n";
import LicenseModel from "@/store/models/LicenseModel";
import { mapGetters } from "vuex";

export default {
  name: "TopicField",
  props: {
    disabled: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    licenses() {
      return LicenseModel.query().orderBy("order", "asc").get() || [];
    },
    basicTopics() {
      return TheoryTopicModel.query().where("type", "basic").get() || [];
    },
    specialTopics() {
      return TheoryTopicModel.query().where("type", "special").get() || [];
    },
    theoryTopics() {
      const topicsArray = [
        { header: i18n.t("label.normal") },
        { divider: true },
        ...this.basicTopics,
      ];

      if (this.specialTopics.length > 0) {
        const specialTopicsByGroup = [];
        const licensesNamesById = Object.fromEntries(
          this.licenses.map((license) => [license.id, license.name])
        );
        const licensesOrderById = Object.fromEntries(
          this.licenses.map((license) => [license.id, license.order])
        );

        this.specialTopics.forEach((specialTopic) => {
          const stringifiedLicensesIds = JSON.stringify(
            specialTopic.licensesIds
          );
          if (!specialTopicsByGroup[stringifiedLicensesIds])
            specialTopicsByGroup[stringifiedLicensesIds] = [];
          specialTopicsByGroup[stringifiedLicensesIds].push(specialTopic);
        });

        const specialTopicsGroups = [];
        Object.keys(specialTopicsByGroup).forEach((licenses) => {
          const licensesIds = JSON.parse(licenses) || [];
          const licensesNames =
            licensesIds.map((licenseId) => licensesNamesById[licenseId]) || [];
          const groupIndex = licensesOrderById[licensesIds.at(-1)];

          if (!specialTopicsGroups[groupIndex])
            specialTopicsGroups[groupIndex] = [];

          specialTopicsGroups[groupIndex] = {
            header: i18n.t("label.special") + " - " + licensesNames.join(", "),
            topics: [...specialTopicsByGroup[licenses]],
          };
        });

        specialTopicsGroups.forEach((specialTopicsGroup) => {
          return topicsArray.push(
            { header: specialTopicsGroup.header },
            { divider: true },
            ...specialTopicsGroup.topics
          );
        });
      }

      return topicsArray;
    },
  },
  methods: {
    topicLabel(topic) {
      return `${topic.orderIndex}. ${topic.title}`;
    },
    onChange(topicId) {
      const topic = TheoryTopicModel.find(topicId);
      this.model.licensesIds = topic ? topic.licensesIds : [];
    },
  },
};
</script>
