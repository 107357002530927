<template>
  <div
    v-if="coordinates"
    :class="`indicator ${this.period}-indicator`"
    :style="{ top: this.startIndicator + 'px' }" />
</template>

<script>
import { mapGetters } from "vuex";
import { differenceInMinutes, format, parseISO } from "date-fns";
import sunsetSunriseMixin from "@/utils/mixins/sunsetSunriseMixin";
import responsive from "@/utils/mixins/responsive";

export default {
  name: "SunIndicator",
  mixins: [sunsetSunriseMixin, responsive],
  props: {
    date: {
      type: String,
      required: true,
    },
    day: {
      type: Object,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["hours"]),
    dayStartTime() {
      return format(
        new Date(this.day.originalDate),
        "yyyy-MM-dd " + this.hours[0]
      );
    },
    indicatorPosition() {
      const period =
        this.period === "sunrise"
          ? this.sunriseTime
          : this.period === "sunset"
          ? this.sunsetTime
          : null;
      return (
        differenceInMinutes(period, parseISO(this.dayStartTime)) *
        this.oneMinuteSize
      );
    },
    startIndicator() {
      return this.indicatorPosition;
    },
  },
};
</script>

<style lang="scss" scoped>
.indicator {
  width: 100%;
  height: 1px;
  position: absolute;
  z-index: 4;
  top: 0;
  pointer-events: none;
}

.sunset-indicator {
  background: blue;
}

.sunrise-indicator {
  background: orange;
}
</style>
