<template>
  <v-hover :disabled="disabled" v-slot:default="{ hover }">
    <v-card
      :ripple="false"
      loader-height="8"
      :loading="loadingEventGetter === event.id"
      @mouseenter="eventHovered(event.id)"
      @mouseleave="eventHovered(null)"
      @mousedown.left="$emit('onDrag', $event)"
      @click="eventClicked(event, $event)"
      @dblclick="eventDoubleClick(event)"
      @click.right.prevent="!disabled && $emit('onRightClick')"
      :title="event.type"
      :elevation="hover ? 12 : 2"
      :class="`event ${pastEvent} ${blockedEvent.className} ${deletableEvent}`"
      :style="styles">
      <v-icon
        v-if="isSpecialSignature"
        class="sing-icon"
        size="15"
        :color="eventColor.fontColor"
        >mdi-signature-freehand</v-icon
      >
      <v-icon
        v-if="multiDeleteStatusGetter && canBeMultiDeleted"
        size="x-large"
        color="white"
        class="delete-selector"
        >{{ deleteIconStatus }}</v-icon
      >

      <div
        v-if="event.teacher && school.showEventTeacherLine"
        class="color-line"
        :style="teacherLineStyles" />
      <div v-else class="spacer" />

      <div class="event-content pt-1 px-1">
        <div class="text-overflow-ellipsis">
          {{ event.startTime }} - {{ event.endTime }}
        </div>
        <div
          class="font-weight-bold text-overflow-ellipsis"
          v-if="event.teacher && !isTeacher">
          {{ event.teacherFullName }}
        </div>
        <div
          class="text-overflow-ellipsis"
          v-if="isStudent && blockedEvent.status">
          {{ $t("label.blocked_time") }}
        </div>
        <div v-if="!isStudent || (isStudent && !blockedEvent.status)">
          <div
            class="d-flex"
            v-if="
              !isStudent &&
              !['THEORY', 'SPECIAL'].includes(event.eventType) &&
              eventStudent
            ">
            <span
              class="text-overflow-ellipsis"
              :class="{ 'font-italic': eventStudent.isDeleted }"
              >{{ studentsLabel }}</span
            >
          </div>
          <div
            class="text-overflow-ellipsis"
            v-if="event.eventType !== 'PRACTICE'">
            {{ eventLabel }}
          </div>
          <div v-else>
            <div class="text-overflow-ellipsis">
              {{ licenseName }}
              <span v-if="lessonTypeLabel" class="text-uppercase"
                >— {{ lessonTypeLabel }}</span
              >
              <span v-if="event.manualLesson" class="ml-1">S</span>
            </div>
          </div>
        </div>
        <div
          v-if="event.eventType === 'SPECIAL'"
          class="text-overflow-ellipsis">
          {{ event.title }}
        </div>
        <div
          v-if="event.eventType === 'THEORY'"
          class="text-overflow-ellipsis d-flex align-center">
          <v-icon
            color="white"
            class="mr-1"
            size="15"
            v-if="event.theoryTopicId">
            {{
              theoryTopic.type === "basic"
                ? "mdi-alpha-g-box"
                : "mdi-alpha-z-box"
            }}
          </v-icon>
          {{ theoryTopicLabel }}
        </div>
        <div v-if="event.meetingPlace" class="text-caption text-truncate">
          {{ event.meetingPlace }}
        </div>
      </div>

      <div v-if="showNameOfStudentsWithRequest" class="pt-1 px-2">
        <div
          class="text-overflow-ellipsis"
          v-for="(student, index) in requestedPlaceholderStudents"
          :key="index">
          {{ student }}
        </div>
      </div>

      <div class="event-footer">
        <div class="icon-box d-flex justify-end px-1">
          <EventSubscribedStudents
            v-if="
              !isStudent &&
              event.eventType === 'THEORY' &&
              event.group === 'lesson'
            "
            :event-type="event.eventType"
            :students-count="activeStudents.length"
            :room-size="event.roomSize" />
          <EventSubscribedStudents
            v-if="
              !isStudent &&
              event.eventType === 'THEORY' &&
              event.group === 'exam' &&
              activeStudents.length > 1
            "
            :event-type="event.eventType"
            :students-count="activeStudents.length" />
          <EventSubscribedStudents
            v-if="
              !isStudent &&
              event.eventType === 'SPECIAL' &&
              activeStudents.length > 1
            "
            :event-type="event.eventType"
            :students-ids="activeStudentsIds" />
          <v-icon
            v-if="studentHasSubscribed"
            class="ml-2"
            size="15"
            :color="eventColor.fontColor"
            >mdi-check-circle</v-icon
          >
          <v-icon
            v-if="isStudent && (requestedPractice || requestedByStudent)"
            class="ml-2"
            size="15"
            :color="eventColor.fontColor"
            >mdi-help-circle</v-icon
          >
          <v-icon
            v-if="
              isStudent &&
              event.eventType === 'SIMULATOR' &&
              event.studentsIds.length
            "
            class="ml-2"
            size="15"
            :color="eventColor.fontColor"
            >mdi-check-circle</v-icon
          >
          <v-icon
            v-if="
              !isStudent &&
              event.eventType === 'SIMULATOR' &&
              event.studentsIds.length
            "
            class="ml-2"
            size="15"
            :color="eventColor.fontColor"
            >mdi-account-circle</v-icon
          >
          <v-icon
            v-if="
              !isStudent &&
              event.eventType === 'PRACTICE' &&
              event.status === 'REQUESTED'
            "
            class="ml-1 icon"
            size="15"
            :color="eventColor.fontColor"
            >mdi-help-circle</v-icon
          >
          <v-icon
            v-if="
              !isStudent &&
              event.eventType === 'PRACTICE' &&
              (event.status === 'MISSED' || event.status === 'MISSED_REFUNDED')
            "
            class="ml-1 icon"
            size="15"
            :color="eventColor.fontColor"
            >mdi-cancel</v-icon
          >
        </div>

        <div v-if="event.primaryVehicleId" class="vehicles">
          <div class="px-1" :style="primaryVehicleStyles">
            {{ primaryVehicle.label }}
          </div>
          <div
            class="px-1"
            :style="secondaryVehicleStyles"
            v-if="event.secondaryVehicleId">
            {{ secondaryVehicle.label }}
          </div>
        </div>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
import responsive from "@/utils/mixins/responsive";
import user from "@/utils/mixins/user";
import colorMixin from "@/utils/mixins/colorMixin";
import { differenceInMinutes, parseISO } from "date-fns";
import EventModel from "@/store/models/EventModel";
import eventServices from "@/services/eventService";
import { mapActions, mapGetters } from "vuex";
import eventMixin from "@/utils/mixins/eventMixin";
import school from "@/utils/mixins/school";
import StudentModel from "@/store/models/StudentModel";

export default {
  name: "Event",
  mixins: [responsive, user, eventMixin, school, colorMixin],
  data() {
    return {
      checkIcon: false,
    };
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["hours"]),
    ...mapGetters("appointments", [
      "eventCreationMenuGetter",
      "loadingEventGetter",
      "multiDeleteStatusGetter",
      "multiDeleteEventsGetter",
    ]),
    styles() {
      return `height: ${this.getHeight}px;
          top: ${this.getTopOffset}px;
          left: ${this.event.left}%;
          width: ${this.event.width}%;
          background: ${this.eventColor.bodyColor};
          color: ${this.eventColor.fontColor};
          pointer-events: static;`;
    },
    teacherLineStyles() {
      return `height: 8px; background:${this.event.teacher.color}`;
    },
    primaryVehicleStyles() {
      return `background: ${
        this.primaryVehicle.color
      }; color: ${this.invertColor(this.primaryVehicle.color)}`;
    },
    secondaryVehicleStyles() {
      return `background: ${
        this.secondaryVehicle.color
      }; color: ${this.invertColor(this.secondaryVehicle.color)}`;
    },
    isSpecialSignature() {
      return this.event.signature;
    },
    deleteIconStatus() {
      return this.multiDeleteEventsGetter.includes(this.event.id)
        ? "mdi-radiobox-marked"
        : "mdi-radiobox-blank";
    },
    deletableEvent() {
      return this.multiDeleteStatusGetter ? "deletable" : "";
    },
    pastEvent() {
      return this.event.endDate <= new Date() ? "past-event" : "";
    },
    blockedEvent() {
      let result = { status: false, className: "normal-event" };
      const eventBelongsToStudent = this.event.studentsIds.includes(
        this.currentUser.id
      );

      if (this.isStudent) {
        switch (this.event.eventType) {
          case "OFFTIME": {
            result = { status: true, className: "blocked-time-event" };
            break;
          }
          case "PRACTICE": {
            if (!eventBelongsToStudent)
              result = { status: true, className: "blocked-time-event" };
            break;
          }
          case "SPECIAL": {
            if (!eventBelongsToStudent)
              result = { status: true, className: "blocked-time-event" };
            break;
          }
        }
      }
      return result;
    },
    getHeight() {
      return this.event.duration * this.oneMinuteSize - 1;
    },
    getTopOffset() {
      const startOfTheDay = parseISO(
        `${this.event.startDate} ${this.hours[0]}`
      );
      return (
        differenceInMinutes(parseISO(this.event.start), startOfTheDay) *
        this.oneMinuteSize
      );
    },
    canBeMultiDeleted() {
      const isPast = parseISO(this.event.startDate) <= new Date();
      // const requestedPractice = this.event.eventType === 'PRACTICE' && this.event.status === 'REQUESTED'
      const blockedInPastEvents = ["PRACTICE", "SPECIAL", "THEORY"].includes(
        this.event.eventType
      );
      const requestedPlaceholder =
        this.event.eventType === "PLACEHOLDER" && !!this.event.requests.length;
      const bookedSimulator =
        this.event.eventType === "SIMULATOR" && !!this.event.studentsIds.length;
      return (
        !isPast ||
        (isPast &&
          !blockedInPastEvents &&
          !requestedPlaceholder &&
          !bookedSimulator)
      );
    },
    activeStudents() {
      return StudentModel.query()
        .where((student) => !student.isDeleted)
        .whereIdIn(this.event.studentsIds)
        .get();
    },
    activeStudentsIds() {
      return this.activeStudents.map((student) => student.id);
    },
    showNameOfStudentsWithRequest() {
      return (
        !this.isStudent &&
        this.event.eventType === "PLACEHOLDER" &&
        this.event.requests.length
      );
    },
  },
  methods: {
    ...mapActions("appointments", [
      "eventBeenHovered",
      "finishEventCreation",
      "multiDeleteAdd",
      "multiDeleteRemove",
    ]),
    eventHovered(eventId) {
      this.eventBeenHovered(eventId);
    },
    eventDelete(event) {
      eventServices.delete(event.id).then(() => EventModel.delete(event.id));
    },
    eventClicked(event) {
      if (this.blockedEvent.status) return;
      if (this.multiDeleteStatusGetter && this.canBeMultiDeleted) {
        this.markForDelete(event.id);
      } else {
        this.eventShow(event);
      }
    },
    eventDoubleClick(event) {
      if (!this.blockedEvent.status)
        this.$router.push({ name: "calendar.appointment", params: { id: event.id } });
    },
    markForDelete(id) {
      if (!this.checkIcon) {
        this.multiDeleteAdd(id);
      } else {
        this.multiDeleteRemove(id);
      }
      this.checkIcon = !this.checkIcon;
    },
  },
};
</script>

<style lang="scss" scoped>
.event {
  overflow: hidden;
  color: #fff;
  pointer-events: all;
  z-index: 4;
  min-width: 10%;
  border-radius: 5px;
  position: absolute;
  cursor: grab;
  width: calc(100% - 10px);
  &.deletable {
    cursor: pointer !important;
  }
  &.past-event {
    filter: grayscale(60%);
    opacity: 0.8;
  }
  &.blocked-time-event {
    width: 100%;
    color: #333 !important;
    cursor: not-allowed;
    background: url("../../assets/img/dots.png") !important;
    i {
      display: none !important;
    }
  }
  & > * {
    pointer-events: none;
    font-size: 11px;
    line-height: 16px;
  }
  &.creating-new-event {
    pointer-events: none;
    opacity: 0.5;
    transition: opacity 0.3s;
  }
  .icon-box {
    position: relative;
    right: 0;
    bottom: 3px;
  }
  .spacer {
    height: 8px;
  }
  .vehicles {
    position: relative;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #fff;
    padding: 0;
  }
  .delete-selector {
    position: absolute;
    top: 12px;
    right: 0;
  }
  .sing-icon {
    position: absolute;
    top: 12px;
    right: 0;
  }
  &-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}
</style>
