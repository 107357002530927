<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-card color="white" elevation="1" width="100%">
      <v-card-title>
        {{ $t("label.documents_list") }}
        <v-spacer/>
        <v-btn v-if="!isElter" small color="primary" @click="$refs.fileUploadDialog.open()">{{ $t('btn.new_document') }}</v-btn>
      </v-card-title>

      <template  v-if="loadingDocuments">
        <v-skeleton-loader v-for="nr in 2" :key="nr" type="list-item" />
      </template>

      <template v-else>
        <v-list v-if="! allRequiredDocumentsUploaded && isExactlyStudent">
            <v-subheader>{{ $t('label.upload_your_documents') }}</v-subheader>

            <v-list-item link v-if="! passportUploaded" @click="$refs.fileUploadDialog.open('BIOMETRIC_PASSPORT_PICTURE')">
              <v-list-item-avatar>
                <v-icon color="blue">mdi-file-upload</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ $t("label.biometric_passport") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider v-if="! (firstAidUploaded && eyeTestUploaded) && ! passportUploaded" />

            <v-list-item link v-if="! firstAidUploaded" @click="$refs.fileUploadDialog.open('FIRST_AID')">
              <v-list-item-avatar>
                <v-icon color="blue">mdi-file-upload</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ $t("label.first_aid") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider v-if="! eyeTestUploaded && ! firstAidUploaded" />

            <v-list-item link v-if="! eyeTestUploaded" @click="$refs.fileUploadDialog.open('EYE_TEST_CERTIFICATE')">
              <v-list-item-avatar>
                <v-icon color="blue">mdi-file-upload</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ $t("label.eyes_test") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
        </v-list>

        <StudentDocumentsList
          v-if="documentsRequiringSignature.length"
          :documents="documentsRequiringSignature"
          :loading="loadingDocuments"
          :subheader="$t('label.requires_signature')"
          @delete="removeFromList"
          @downloaded="markAsPreviewed"
          @signed="markAsSigned"
        />

        <StudentDocumentsList
          v-if="studentDocuments.length > 0"
          :documents="studentDocuments"
          :subheader="$t('label.your_documents')"
          @delete="removeFromList"
        />

        <v-list v-if="schoolDocuments.length">
            <v-subheader>{{ $t('label.school_general_documents') }}</v-subheader>
            <template v-for="(document, key) in schoolDocuments">
              <document-list-item :document="document" :key="`${document.id}-school`"/>
              <v-divider v-if="key < schoolDocuments.length - 1" :key="`${document.id}-${document.createdAt}`"></v-divider>
            </template>
        </v-list>
      </template>
    </v-card>

    <v-card
      elevation="0"
      :loading="loadingInvoices"
      class="mt-3"
      v-if="$store.state.school.isInvoicingEnabled"
    >
      <v-card-title>
        {{ $t("label.invoices") }}
      </v-card-title>
      <template v-if="loadingInvoices">
        <v-skeleton-loader class="mx-auto" type="list-item@3" />
      </template>
      <template v-else>
        <StudentInvoicesList v-if="invoices.length" :open-invoices="openInvoices" :other-invoices="otherInvoices" />
        <empty-box v-else />
      </template>
    </v-card>

    <file-upload-dialog ref="fileUploadDialog" :student-id="currentUser.id" @fileUploaded="addToList" />
  </v-container>
</template>

<script>
import studentsService from "@/services/studentsService";
import user from "@/utils/mixins/user";
import EmptyBox from "@/components/EmptyBox";
import i18n from "@/plugins/i18n";

import DocumentListItem from "@/components/DocumentListItem.vue";
import FileUploadDialog from "@/components/FileUploadDialog.vue";
import StudentDocumentsList from "@/components/student/StudentDocumentsList.vue";
import StudentInvoicesList from "@/components/student/StudentInvoicesList.vue";

import * as authMutationTypes from '@/store/mutation-types/auth'
import { mapGetters } from 'vuex'

export default {
  name: "StudentDocuments",
  mixins: [user],
  components: {
    StudentInvoicesList,
    StudentDocumentsList,
    FileUploadDialog,
    DocumentListItem,
    EmptyBox,
  },
  data: () => ({
    loadingDocuments: true,
    loadingInvoices: true,
    documentType: "",
    documentFile: null,
    downloadingFile: false,
    documents: [],
    invoices: [],
    documentsTypes: [
      { value: "BIOMETRIC_PASSPORT_PICTURE", text: i18n.t("label.biometric_passport") },
      { value: "FIRST_AID", text: i18n.t("label.first_aid") },
      { value: "EYE_TEST_CERTIFICATE", text: i18n.t("label.eyes_test") },
    ],
  }),
  watch: {
    unsignedDocumentsCount() {
      this.fetchDocuments()
    },
  },
  mounted() {
    this.loadingDocuments = true;
    this.fetchDocuments()
      .finally(() => {
        this.loadingDocuments = false;
      });

    studentsService
      .invoices(this.currentUser.id)
      .then((resp) => {
        this.invoices = resp.data;
      })
      .finally(() => (this.loadingInvoices = false));
  },
  computed: {
    ...mapGetters("auth", ["unsignedDocumentsCount"]),
    schoolDocuments() {
      return this.documents.school || [];
    },
    studentDocuments() {
      const docs = this.documents.student.filter(item => !item.requiresStudentSignature);
      const createdDocs = docs.filter((document) => document.createdAt);
      return this.isExactlyStudent ? docs : createdDocs
    },
    documentsRequiringSignature() {
      return this.documents?.student?.filter(item => item.requiresStudentSignature) || []
    },
    passportUploaded() {
      return this.existingDocumentsByType.includes(
        "BIOMETRIC_PASSPORT_PICTURE"
      );
    },
    firstAidUploaded() {
      return this.existingDocumentsByType.includes("FIRST_AID");
    },
    eyeTestUploaded() {
      return this.existingDocumentsByType.includes("EYE_TEST_CERTIFICATE");
    },
    allRequiredDocumentsUploaded() {
      return this.passportUploaded && this.firstAidUploaded && this.eyeTestUploaded
    },
    existingDocumentsByType() {
      return this.documents?.student?.map((item) => item.type) || [];
    },
    openInvoices() {
      return this.invoices.filter(invoice => ! invoice.isPaid && ! invoice.isCanceled)
    },
    otherInvoices() {
      return this.invoices.filter(invoice => invoice.isPaid || invoice.isCanceled)
    }
  },
  methods: {
    fetchDocuments() {
      return studentsService
        .documents(this.currentUser.id)
        .then((documentResponse) => {
          this.documents = documentResponse.data || [];
        })
    },
    uploadDocument(document, file) {
      const formData = new FormData();

      formData.set("requestId", document.id);

      if (document.type instanceof Object && document.type.value) {
        formData.set("key", document.type.value);
      }

      formData.set("title", document.type);

      formData.set("file", file);

      const documentIndex = this.documents.findIndex(
        (item) => item.id === document.id
      );

      this.$set(this.documents[documentIndex], "loading", true);
      studentsService
        .uploadDocument(this.currentUser.id, formData)
        .then((response) => {
          const responseData = response.data || {};

          const documentIndex = this.documents.findIndex(
            (item) => item.id === responseData.requestId
          );

          if (documentIndex !== -1) {
            this.$set(this.documents, documentIndex, {
              ...responseData,
              loading: false,
            });
          }
        })
        .catch((error) => {
          const responseData = error.response.data;
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message)
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });

          Object.keys(errors).forEach((key) => {
            let fieldName = key;
            const fieldErrorMessage = errors[fieldName];

            if (fieldName === "key") fieldName = "title";

            this.$refs.documentsForm.setErrors({
              ["document." + document.id + "." + fieldName]: fieldErrorMessage,
            });

            const docEl = this.$refs["document." + document.id + ".file"][0];
            if (docEl.value) docEl.clearableCallback();
          });
        })
        .finally(() => {
          this.$set(this.documents[documentIndex], "loading", false);
        });
    },
    markAsPreviewed(id) {
      this.documents.student = this.documents.student.map(obj =>
          obj.id === id ? { ...obj, isPreviewed: true } : obj
      );
    },
    markAsSigned(id) {
      this.documents.student = this.documents.student.map(obj =>
          obj.id === id ? { ...obj, isSignedByStudent: true, requiresStudentSignature: false } : obj
      );
      this.$store.commit(
          `auth/${authMutationTypes.SET_UNSIGNED_DOCUMENTS_COUNT}`,
          this.currentUser.unsignedDocumentsCount - 1
      );
    },
    removeFromList(id) {
      this.documents.student = this.documents.student.filter(item => item.id !== id)
    },
    addToList(file) {
      this.documents.student.push(file)
    }
  },
};
</script>

<style scoped lang="scss">
</style>
