var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"color":"primary","dark":"","height":"141"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between py-2"},[_vm._v(_vm._s(_vm.$t("label.students")))]),_c('v-divider'),_c('v-card-text',{staticClass:"d-flex justify-space-between white--text"},[_c('router-link',{staticClass:"d-flex flex-column flex-grow-1 white--text text-decoration-none",staticStyle:{"flex-basis":"0"},attrs:{"to":{
        name: 'students',
        query: { filters: this.formatFilters({ activityStatus: 'active' }) },
        params: { clear: true }
      }}},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t("label.active")))]),(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mt-3 mb-3",attrs:{"type":"image","width":"40","height":"15"}}):_c('span',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.active || "—"))])],1),_c('router-link',{staticClass:"d-flex flex-column flex-grow-1 white--text text-decoration-none",staticStyle:{"flex-basis":"0"},attrs:{"to":{
         name: 'students',
         query: { filters: this.formatFilters({ activityStatus: 'active', noBalance: true }) },
         params: { clear: true }
      }}},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t("label.no_balance")))]),(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mt-3 mb-3",attrs:{"type":"image","width":"40","height":"15"}}):_c('span',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.noBalance || "—"))])],1),_c('router-link',{staticClass:"d-flex flex-column flex-grow-1 white--text text-decoration-none",staticStyle:{"flex-basis":"0"},attrs:{"to":{
        name: 'students',
        params: { clear: true }
      }}},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t("label.total")))]),(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mt-3 mb-3",attrs:{"type":"image","width":"40","height":"15"}}):_c('span',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.total || "—"))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }