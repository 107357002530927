<template>
  <svg :width="width" :height="height" :viewBox="box">
    <rect x="0" y="0" :width="width" :height="height" fill="#fff" />
    <Days
      :styles="styles"
      :values="values"
      :size="size"
      :space="space"
      :colorFun="rectColor"
      :padX="padX"
      :padY="padY"
      :onClick="onClick"
      :onHover="onHover" />
    <Months
      :styles="styles"
      :values="values"
      :size="size"
      :space="space"
      :colorFun="colorFun"
      :padX="padX"
      :padY="padY"
      :onClick="onClick"
      :onHover="onHover" />
    <DayTitles
      :styles="styles"
      :values="values"
      :size="size"
      :space="space"
      :colorFun="colorFun"
      :padX="padX"
      :padY="padY"
      :onClick="onClick"
      :onHover="onHover" />
  </svg>
</template>

<script>
import { format, differenceInDays, subYears } from "date-fns";

import Days from "./Days";
import Months from "./Months";
import DayTitles from "./DayTitles";

export default {
  name: "CalendarGraph",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    colorFun: {
      type: Function,
      default: null,
    },
    startDate: {
      type: Date,
      default: () => subYears(this.endDate, 1),
    },
    endDate: {
      type: Date,
      default: new Date(),
    },
    size: {
      type: [Number, String],
      default: 12,
    },
    space: {
      type: [Number, String],
      default: 1,
    },
    padX: {
      type: [Number, String],
      default: 20,
    },
    padY: {
      type: [Number, String],
      default: 20,
    },
    styleOptions: {
      type: Object,
      default: () => ({
        textColor: "#959494",
        fontSize: "12px",
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
      }),
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    onHover: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    group: 0,
    values: [],
  }),
  components: {
    Days,
    Months,
    DayTitles,
  },
  computed: {
    s() {
      return this.size + this.space * 2;
    },
    width() {
      return this.group * this.s + this.padX * 2;
    },
    height() {
      return 7 * this.s + this.padY + 10;
    },
    box() {
      return `0 0 ${this.width} ${this.height}`;
    },
    styles() {
      return this.getStyles(this.styleOptions);
    },
  },
  created() {
    const days = differenceInDays(this.endDate, this.startDate);
    const dataTmp = this.data.reduce((memo, v) => {
      memo[v.date] = parseInt(v.count);
      return memo;
    }, {});

    // Compute values
    for (let i = 0; i <= days; i += 1) {
      const date = new Date(this.startDate);
      date.setDate(date.getDate() + i);

      const day = date.getDay();
      const count = dataTmp[format(date, "yyyy-MM-dd")] || 0;

      if ((day === 0 && i !== 0) || i === 0) {
        this.values.push([]);
        this.group += 1;
      }

      this.values[this.group - 1].push({ count, date, day });
    }
  },
  methods: {
    getStyles({
      textColor = "#959494",
      fontSize = "12px",
      fontFamily = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    }) {
      const text = {
        fill: textColor,
        "font-size": fontSize,
        "font-family": fontFamily,
        "dominant-baseline": "central",
      };
      return {
        text,
        text2: {
          ...text,
          "text-anchor": "middle",
        },
      };
    },
    rectColor(v) {
      if (this.colorFun !== null) return this.colorFun(v);

      const colors = ["#eee", "#c6e48b", "#7bc96f", "#239a3b", "#196127"];

      if (!v.count) {
        return colors[0];
      }
      if (v.count > 45) {
        return colors[4];
      }
      if (v.count > 30) {
        return colors[3];
      }
      if (v.count > 15) {
        return colors[2];
      }

      return colors[1];
    },
  },
};
</script>

<style scoped></style>
