<template>
  <div
    class="calendar-body"
    @mouseup="eventCreationMenuOff"
    @scroll="eventCreationMenuOff"
    v-touch="{
      left: () => changeCalendarRange('forward'),
      right: () => changeCalendarRange('backward'),
    }">
    <div :class="`hours-column ${!mdLandscapeAndUp ? 'mobile' : ''} ${ isTeacher ? 'with-work-time' : ''}`">
      <div :class="`sticky spacer ${!mdLandscapeAndUp ? 'is-mobile' : ''}`">
        <v-avatar color="grey" :size="`${!mdLandscapeAndUp ? '28' : '35'}`">
          <span class="white--text headline">{{ weekNumber }}</span>
        </v-avatar>
      </div>
      <HourCell primary v-for="hour in hours" :key="hour">{{ hour }}</HourCell>
      <TimeIndicator :withDot="true" :scrollTo="true" />
    </div>

    <div class="events-column">
      <CalendarBody eventComponent="Event" @closed="calendarDeactivate" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { addDays, format, getISOWeek, lightFormat, parseISO } from "date-fns";
import HourCell from "@/components/calendar/HourCell";
import CalendarBody from "@/components/calendar/CalendarBody";
import responsive from "@/utils/mixins/responsive";
import user from "@/utils/mixins/user";
import loadAppointments from "@/utils/mixins/loadAppointments";
import TimeIndicator from '@/components/calendar/TimeIndicator'

export default {
  name: "CalendarGrid",
  mixins: [responsive, user, loadAppointments],
  components: {
    HourCell,
    CalendarBody,
    TimeIndicator
  },
  computed: {
    ...mapGetters(["hours", "activeDate", "calendarLayout"]),
    ...mapGetters("teachers", { activeTeachersIds: "activeIds" }),
    parsedActiveDate() {
      return parseISO(this.activeDate);
    },
    weekNumber() {
      return getISOWeek(parseISO(this.activeDate));
    },
  },
  methods: {
    ...mapActions(["setCalendarDate"]),
    ...mapActions("appointments", ["eventCreationMenuOff"]),
    ...mapActions("teachers", { calendarDeactivate: "disable" }),
    rangeSwitchDaysCount() {
      switch (this.calendarLayout) {
        case "LAYOUT_DAY":
          return 1;
        case "LAYOUT_THREE_DAYS":
          return 3;
        case "LAYOUT_WEEK":
          return 7;
        // case 'LAYOUT_MONTH': return 30
      }
      return 1;
    },
    changeCalendarRange(direction = "forward") {
      let dir = 1;
      if (direction === "backward") dir = -1;

      const date = lightFormat(
        addDays(this.parsedActiveDate, this.rangeSwitchDaysCount() * dir),
        "yyyy-MM-dd"
      );
      const month = format(parseISO(date), "yyyy-MM");
      this.setCalendarDate({ month: month, activeDate: date });
      this.loadAppointments();
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-body {
  grid-area: body;
  background: #fff;
  user-select: none;

  display: grid;
  grid-template-columns: var(--calendar-hours-column) auto;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  // hide scrollbars for the calendar-body `div` but keep it's functionality
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 5;
  background: #fff;
  &.is-mobile span {
    font-size: 1.2rem !important;
  }
}

.spacer {
  border-bottom: var(--default-border);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  flex-direction: column;
}

.hours-column {
  position: relative;
  display: grid;
  grid-template-rows: 50px auto;
  border-right: var(--default-border);
  &.with-work-time {
    grid-template-rows: 70px auto;
  }
  &.mobile {
    grid-template-rows: 42px auto;
    &.with-work-time {
      grid-template-rows: 65px auto;
    }
  }
}

.events-column {
  display: flex;
  position: relative;
}
</style>
