<template>
  <v-toolbar class="px-2" elevation="3">
    <v-btn
      class="mr-4"
      icon
      :to="{ name: 'vehicles.vehicle', id: $route.params.id }"
      exact
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <span class="title">{{ $t("route." + title) }}</span>

    <slot name="ignore-spacer"></slot>

    <v-spacer></v-spacer>

    <slot></slot>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon large v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item link v-for="(tab, index) in tabs" :key="index" :to="{ name: `vehicles.vehicle.`  + tab.value }">
          <v-list-item-title>{{ $t("route." + tab.label) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-toolbar>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: "VehicleToolBar",
  props: {
    activeTab: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("school", ["school"]),
    tabs() {
      return [
        { value: "mileage", label: 'vehicleMileage', isShow: true },
        { value: "fueling", label: 'vehicleFueling', isShow: true },
        { value: "service", label: 'vehicleService', isShow: true },
        { value: "reservations", label: 'vehicleReservations', isShow: this.school.isVehicleReservationsEnabled },
        { value: "expenses", label: 'vehicleExpenses', isShow: true }
      ].filter((item) => item.isShow);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
