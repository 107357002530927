import { Model } from "@vuex-orm/core";

export default class StudentTeacherModel extends Model {
  static entity = "student_teacher";

  static primaryKey = ["student_id", "teacher_id"];

  static fields() {
    return {
      id: this.attr(null),
      teacher_id: this.attr(null),
      student_id: this.attr(null),
    };
  }
}
