<template>
  <div class="room__message message text-body-2" :ref="'message_' + message.id">
    <div
      class="message__block"
      :class="{ 'message__block-personal': isPersonal, 'message__block-others': isOthers }">
      <div
        v-if="messageIndex === 0"
        class="mb-1"
        :class="{'font-weight-medium amber--text': isPersonal, 'font-weight-medium light-green--text': isOthers}">
        <template v-if="authenticatedUser.type !== 'school-administrator' && user.type === 'school-administrator'">
          {{ school.name }}
        </template>
        <template v-else>
          {{ user.firstName + " " + user.lastName }}
        </template>
      </div>

      <div class="d-flex" style="max-width: 500px">
        <span class="message__text mr-2" v-linkified>{{ message.message }}</span>
        <MessageStatus :message="message" />
      </div>
    </div>
  </div>
</template>

<script>
import MessageStatus from "@/components/messenger/MessageStatus";
import user from "@/utils/mixins/user";
import { mapGetters } from "vuex";

export default {
  name: "MessengerMessage",
  props: {
    messageIndex: {
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    message: {
      type: Object,
      required: true,
    },
  },
  mixins: [user],
  components: { MessageStatus },
  computed: {
    ...mapGetters("school", ["school"]),
    authenticatedUser() {
      return this.isElterWithSelectedStudent ? this.parentSelectedStudent : this.currentUser;
    },
    isPersonal() {
      return (this.authenticatedUser.type === 'school-administrator' && this.user.type === 'school-administrator') || this.message.userId === this.authenticatedUser.userId
    },
    isOthers() {
      return this.message.userId !== this.authenticatedUser.userId
    }
  },
};
</script>

<style lang="scss" scoped>
.room {
  &__message {
    display: flex;
    flex-direction: column;
    margin: 5px 0 !important;

    &:last-child {
      margin-bottom: 0 !important;
    }

    &_bar {
      position: sticky;
      bottom: 0;
      background-color: #fff;
      padding: 10px;
      align-items: flex-end;
    }
  }
}

.message {
  &__status {
    margin-bottom: -3px;
    margin-left: auto;
  }

  &__avatar {
    margin-right: 10px;
  }

  &__text {
    white-space: pre-wrap;
  }

  &__block {
    &-others {
      padding: 7px;
      background-color: #f6ffed;
      border: 1px solid #b7eb8f;
      align-self: flex-start;
    }

    &-personal {
      padding: 10px;
      background-color: #fffce6;
      border: 1px solid #ffe47a;
      align-self: flex-end;
    }
  }
}
</style>
