<template>
  <div>
    <SchoolDashboard v-if="isSchoolAdministrator" />
    <TeacherDashboard v-if="isTeacher" />
    <StudentDashboard v-if="isStudent || isElterWithSelectedStudent" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SchoolDashboard from "@/components/school/SchoolDashboard";
import TeacherDashboard from "@/components/teacher/TeacherDashboard";
import StudentDashboard from "@/components/student/StudentDashboard";
import user from "@/utils/mixins/user";

export default {
  name: "Dashboard",
  components: { SchoolDashboard, TeacherDashboard, StudentDashboard },
  mixins: [user],
  computed: {
    ...mapGetters("auth", ["currentUser"]),
  },
};
</script>

<style scoped></style>
