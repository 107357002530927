<template>
  <v-expansion-panel @click.once="loadDetailedProgress">
    <v-expansion-panel-header class="pa-2 d-flex justify-space-between">
      <LicenseIcon
          height="45"
          class="flex-grow-0"
          :name="license.name"
          :icon="license.icon"
          :row="mdLandscapeAndUp"
          :width="mdLandscapeAndUp ? 150 : 80"
      />

      <v-spacer/>

      <v-spacer v-if="!mdLandscapeAndUp"/>

      <div :style="`width: ${mdLandscapeAndUp ? 200 : 50}px`">
        <template v-if="hasExams">
          <span class="mr-2" style="font-size: 0.8rem" v-if="mdLandscapeAndUp">
            {{ $t('label.exam_status') }}:
          </span>
          <v-icon v-if="theoryExamPassed || practiceExamPassed" color="secondary">
            {{ theoryExamPassed && practiceExamPassed ? 'mdi-check-all' : 'mdi-check' }}
          </v-icon>
        </template>
      </div>

      <TotalProgress :total-progress="totalProgress"/>

    </v-expansion-panel-header>
      <v-expansion-panel-content class="content-wrapped">
        <v-row v-if="!loading">
          <v-col cols="12" :lg="hasExams ? '6' : '12'" class="px-1">
            <EducationTheoryProgress :progress="detailedProgress" :license-id="licenseId"/>
          </v-col>
          <v-col v-if="hasExams" cols="12" lg="6" class="px-1">
            <EducationPracticeProgress
                v-if="license.forPractice"
                :progress="detailedProgress"
                :licenseId="licenseId"
                :loading="loading"
            />
            <EducationProgressArea
                :progress="detailedProgress.examResults"
                :student-id="studentId"
                :license-id="licenseId"
                @emitExamPassed="setExamStatus"
            />
          </v-col>
        </v-row>

        <EducationTabContentLoader
            v-else
            :has-exams="hasExams"
            :basicTopicsCount="basicTopicsCount.length"
            :specialTopicsCount="specialTopicsCount.length"
        />

      </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import user from '@/utils/mixins/user'
import responsive from '@/utils/mixins/responsive'

import LicenseIcon from '@/components/LicenseIcon'
import TotalProgress from '@/components/student/progress/TotalProgress'
import EducationTheoryProgress from '@/components/student/progress/EducationTheoryProgress'
import EducationPracticeProgress from '@/components/student/progress/EducationPracticeProgress'
import EducationProgressArea from '@/components/student/progress/EducationProgressArea'
import EducationTabContentLoader from '@/components/skeleton-loaders/EducationTabContentLoader'

import StudentModel from '@/store/models/StudentModel'
import LicenseModel from '@/store/models/LicenseModel'
import TheoryTopicModel from '@/store/models/TheoryTopicModel'
import studentsService from '@/services/studentsService'

export default {
  name: 'EducationTab',
  components: {
    LicenseIcon,
    TotalProgress,
    EducationTheoryProgress,
    EducationPracticeProgress,
    EducationProgressArea,
    EducationTabContentLoader
  },
  data: () => ({
    loading: false,
    theoryExamPassed: false,
    practiceExamPassed: false,
    totalProgress: 0,
    detailedProgress: {},
  }),
  mixins: [responsive, user],
  props: {
    studentId: {
      type: [Number, String],
      required: true,
    },
    licenseId: {
      type: Number,
      required: true,
    },
    licenseProgress: {
      type: Object,
      required: true
    },
  },
  mounted () {
    this.theoryExamPassed = this.licenseProgress.examResults.theoryExamPassedAt
    this.practiceExamPassed = this.licenseProgress.examResults.practiceExamPassedAt
    this.totalProgress = this.licenseProgress.totalProgress
  },
  computed: {
    student () {
      return this.isStudent ? this.currentUser : StudentModel.find(this.studentId)
    },
    license () {
      return LicenseModel.find(this.licenseId) || {}
    },
    basicTopicsCount () {
      return TheoryTopicModel.query()
        .where('type', 'basic')
        .where(item => item.licensesIds.includes(this.licenseId))
        .orderBy('orderIndex', 'asc')
        .get()
    },
    specialTopicsCount () {
      return TheoryTopicModel.query()
        .where('type', 'special')
        .where(item => item.licensesIds.includes(this.licenseId))
        .orderBy('orderIndex', 'asc')
        .get()
    },
    hasExams() {
      return this.license.forPractice || this.license.forTheoryExam
    }
  },
  methods: {
    setExamStatus (val) {
      this.theoryExamPassed = !!val.theoryExamPassedAt
      this.practiceExamPassed = !!val.practiceExamPassedAt
    },
    loadDetailedProgress () {
      this.loading = true
      studentsService
          .studentProgressByLicense(this.studentId, this.licenseId, { extended: true })
          .then((response) => this.detailedProgress = response.data)
          .finally(() => this.loading = false)
    },
  }
}
</script>

<style lang="scss">
.content-wrapped .v-expansion-panel-content__wrap {
  padding: 0 16px 16px !important;
}
</style>
