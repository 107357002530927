<template>
  <div>
    <span class="text--secondary">
      <span
        class="text-decoration-underline-dotted"
        v-if="teachersIds.length > 0"
        @click.stop="showDialogWithTeachers">
        <strong>{{ teachersIds.length }}</strong>
        {{ $tc("teacher", teachersIds.length) }}
      </span>

      <span v-else>{{ $t("messages.no_teachers") }}</span>
    </span>
    <TeachersListDialog ref="teachersDialog" />
  </div>
</template>

<script>
import TeachersListDialog from "@/components/teacher/TeachersListDialog";

export default {
  components: { TeachersListDialog },
  props: {
    teachersIds: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  name: "TeachersLabel",
  methods: {
    showDialogWithTeachers() {
      this.$refs.teachersDialog.open(this.teachersIds);
    },
  },
};
</script>

<style scoped></style>
