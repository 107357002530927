import AppointmentModel from "@/store/models/AppointmentModel";
import TheoryTopicModel from "@/store/models/TheoryTopicModel";
import LicenseModel from "@/store/models/LicenseModel";

export default {
  computed: {
    manualB197() {
      const license = LicenseModel.find(this.licenseId) || {};

      return license.name === "B197";
    },
    manualLessonPlan() {
      const license = LicenseModel.find(this.licenseId) || {};

      return license.requiredMtl;
    },
    educationPlan() {
      return this.requirements || {}
    },

    /* PRACTICE */
    basicPracticeEvents() {
      return {
        events: [
          ...this.normalEventsDone.events,
          ...this.instructionEventsDone.events,
        ],
        amount:
          this.normalEventsDone.amount + this.instructionEventsDone.amount,
      };
    },
    simulatorEventsDone() {
      return AppointmentModel.query()
          .where("type", AppointmentModel.TYPES.SIMULATOR)
          .where("licenseId", this.licenseId)
          .where("deletedAt", deletedAt => deletedAt === null)
          .orderBy("start")
          .get() || []
    },
    normalEventsDone() {
      const query = this.getDoneEventsByLessonType("normal");

      return { events: query.get(), amount: query.sum("lessonsAmount") };
    },
    manualEventsDone() {
      const query = AppointmentModel.query()
        .where("type", AppointmentModel.TYPES.PRACTICE)
        .where("status", "ACCEPTED")
        .where("licenseId", this.licenseId)
        .where(item => item.group === "normal" || item.group === "special")
        .where("isManualLesson", true)
        .with("teacher")
        .where("deletedAt", (deletedAt) => deletedAt === null);

      return { events: query.get(), amount: query.sum("lessonsAmount") };
    },
    instructionEventsDone() {
      const query = this.getDoneEventsByLessonType(
        "special",
        "lesson_type.instruction"
      );
      return { events: query.get(), amount: query.sum("lessonsAmount") };
    },
    overlandEventsDone() {
      const query = this.getDoneEventsByLessonType(
        "special",
        "lesson_type.overland"
      );
      return { events: query.get(), amount: query.sum("lessonsAmount") };
    },
    highwayEventsDone() {
      const query = this.getDoneEventsByLessonType(
        "special",
        "lesson_type.highway"
      );
      return { events: query.get(), amount: query.sum("lessonsAmount") };
    },
    nightEventsDone() {
      const query = this.getDoneEventsByLessonType(
        "special",
        "lesson_type.night"
      );
      return { events: query.get(), amount: query.sum("lessonsAmount") };
    },

    practiceProgress() {
      const { overlands, highways, nights } = this.educationPlan || {}
      const done =
        this.calculateProportion(this.overlandEventsDone.amount, overlands) +
        this.calculateProportion(this.highwayEventsDone.amount, highways) +
        this.calculateProportion(this.nightEventsDone.amount, nights);

      const total = overlands + highways + nights;
      const doneFinal = done + this.calculateProportion(this.manualEventsDone.amount, this.manualLessonPlan);
      const totalFinal = Math.abs((total + this.manualLessonPlan) || 0);
      const progress = totalFinal > 0 ? Math.ceil((doneFinal / totalFinal) * 100) : 100

      return { done, total, progress };
    },

    /* THEORY */
    basicTopics() {
      const filteredTopics = [];
      const topics =
        TheoryTopicModel.query()
          .where("type", "basic")
          .orderBy("orderIndex", "asc")
          .get() || {};

      topics.forEach((topic) => {
        const passed = this.passedTheoryTopicsIds.includes(topic.id);
        const passedTimes = this.passedTheoryTopicsIds.reduce((a, v) => (v === topic.id ? a + 1 : a), 0);
        const isLastPassed = this.lastPassedTheoryTopic.theoryTopicId === topic.id;

        filteredTopics.push({ ...topic, passed, passedTimes, isLastPassed });
      });

      return filteredTopics;
    },
    specialTopics() {
      const filteredTopics = [];
      const topics =
        TheoryTopicModel.query()
          .where("type", "special")
          .where("licensesIds", (licensesIds) =>
            licensesIds.includes(this.licenseId)
          )
          .orderBy("orderIndex", "asc")
          .get() || {};

      topics.forEach((topic) => {
        const passed = this.passedTheoryTopicsIds.includes(topic.id);
        const passedTimes = this.passedTheoryTopicsIds.reduce((a, v) => (v === topic.id ? a + 1 : a), 0);
        const isLastPassed = this.lastPassedTheoryTopic.theoryTopicId === topic.id;

        filteredTopics.push({ ...topic, passed, passedTimes, isLastPassed });
      });

      return filteredTopics;
    },
    lastPassedTheoryTopic() {
      return AppointmentModel.query()
          .where("type", AppointmentModel.TYPES.THEORY)
          .where("group", (group) => group === "lesson")
          .where("deletedAt", (deletedAt) => deletedAt === null)
          .orderBy("start", "desc")
          .first() || {}
    },
    passedTheoryTopics() {
      return AppointmentModel
        .query()
        .where("type", AppointmentModel.TYPES.THEORY)
        .where("group", (group) => group === "lesson")
        .where("deletedAt", (deletedAt) => deletedAt === null)
        .where(appointment => appointment.studentsIds.filter(student => student.isPresent).length > 0)
        .get()
        .map((theory) => theory.theoryTopic);
    },
    passedTheoryTopicsIds() {
      return this.passedTheoryTopics.map(item => item.id)
    },
    passedBasics() {
      return this.passedTheoryTopics.filter((topic) => topic.type === 'basic');
    },
    passedTheoriesForLicense() {
      const topics =
        TheoryTopicModel.query()
          .whereIdIn(this.passedTheoryTopicsIds)
          .where("type", "special")
          .where("licensesIds", licenses => licenses.includes(parseInt(this.licenseId)))
          .orderBy("orderIndex", "asc")
          .get() || [];

      return topics.map((topic) => topic.id);
    },
    theoryProgress() {
      const { basicTheories, specialTheories } = this.educationPlan || {}
      const done =
        this.calculateProportion(this.passedBasics.length, basicTheories) +
        this.calculateProportion(this.passedTheoriesForLicense.length, specialTheories);

        const total = basicTheories + specialTheories;
        const progress = total > 0 ? Math.ceil((done / total) * 100) : 100;
        const basicProgress = basicTheories > 0 && `${this.passedBasics.length}/${basicTheories}`
        const specialProgress = specialTheories > 0 && `${this.passedTheoriesForLicense.length}/${specialTheories}`

      return { done, total, progress, basicProgress, specialProgress };
    },
    licenseTotalProgress() {
      const theoryProgress = this.theoryProgress.progress;
      const practiceProgress = this.practiceProgress.progress;
      const theoryPart = (50 / 100) * parseFloat(theoryProgress);
      const practicePart = (50 / 100) * parseFloat(practiceProgress);

      return theoryPart + practicePart || 0;
    },
  },
  methods: {
    calculateProportion(passedAmount, requiredAmount) {
      return passedAmount >= requiredAmount ? requiredAmount : passedAmount;
    },
    getDoneEventsByLessonType(group, type = null) {
      let eventsQuery = AppointmentModel.query()
        .where("type", AppointmentModel.TYPES.PRACTICE)
        .where("status", "ACCEPTED")
        .where("licenseId", this.licenseId)
        .with("teacher")
        .where("group", group)
        .where("deletedAt", (deletedAt) => deletedAt === null);

      if (type) eventsQuery = eventsQuery.where("lessonTypeName", type);

      return eventsQuery.orderBy("start");
    },
  },
};
