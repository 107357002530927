<template>
  <v-card :loading="loading">
    <StudentTabToolBar activeTab="invoices" >
      <v-btn
        v-if="isSchoolAdministrator"
        icon
        color="primary"
        @click="$refs.InvoicingSettingsDialog.open($route.params.id)"
      >
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </StudentTabToolBar>

    <v-card-text v-if="!loading">
      <v-row class="mt-1">
        <v-col cols="12" md="4">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                solo
                dense
                readonly
                :value="formattedDates"
                :label="$t('label.filter_by_date')"
                prepend-inner-icon="mdi-calendar"
                clearable
                @click:clear="date = []"
                v-bind="attrs"
                v-on="on">
              </v-text-field>
            </template>
            <v-date-picker v-model="date" scrollable range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                {{ $t("btn.cancel") }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(date.sort())"
              >
                {{ $t("btn.ok") }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col>
          <v-btn :loading="filtering" color="primary" text @click="filter">
            {{$t("btn.filter_results")}}
          </v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn color="primary" @click="openCreateServiceDialog">
            Neue Leistung
          </v-btn>
        </v-col>
      </v-row>
      <div v-if="data.length">
        <v-simple-table dense>
          <thead>
            <tr>
              <th v-if="isSchoolAdministrator" style="width: 10px">
                <v-checkbox
                  :indeterminate="isToggleAllIndeterminate"
                  :value="selectedServices.length === data.length"
                  dense
                  style="margin: 0"
                  hide-details
                  @click="toggleAll" />
              </th>
              <th style="width: 50px">{{ $t("label.date") }}</th>
              <th>{{ $t("label.description") }}</th>
              <th style="width: 60px">{{ $t("license") }}</th>
              <th style="width: 150px">{{ $t("label.price_without_tax") }}</th>
              <th style="width: 100px">{{ $t("label.tax_rate") }}</th>
              <th style="width: 140px">{{ $t("label.cost") }}</th>
              <th style="width: 100px"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="service in data"
              :key="service.id"
              class="table-row"
              @click="toggleService(service.id)"
            >
              <td v-if="isSchoolAdministrator" style="vertical-align: middle">
                <v-checkbox
                  :value="service.id"
                  v-model="selectedServices"
                  hide-details
                  class="ma-0"
                  :ripple="false"
                  dense
                  @click.stop />
              </td>
              <td class="text-monospace">{{ service.formattedDate }}</td>
              <td>{{ service.description }}</td>
              <td>{{ service.license }}</td>
              <td class="text-monospace">
                {{ service.costWithoutTax | currency }}
              </td>
              <td class="text-monospace">{{ service.taxRate }} %</td>
              <td class="text-monospace">{{ service.cost | currency }}</td>
              <td>
                <student-service-tooltip :service="service" />
                <v-btn
                  v-if="service.isDeletable"
                  x-small
                  text
                  color="error"
                  @click.stop="deleteService(service.id)"
                  :disabled="deletingService !== null"
                  :loading="deletingService === service.id"
                >
                  {{ $t("btn.delete") }}
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-alert class="mt-5" color="warning" dark prominent v-if="student.bookAndPay && student.balance < 0">
          <v-row align="center">
            <v-col class="grow">
              <div class="text-h6">
                {{ $t('messages.cant_create_invoice_when_student_balance_is_negative') }}
              </div>
              <div>{{ $t('messages.cant_create_invoice_when_student_balance_is_negative_explanation') }}</div>
            </v-col>
            <v-col class="shrink">
              <v-btn
                @click="sendNegativeBalanceReminder"
                :loading="sendingNotification"
                :disabled="sendingNotification"
              >
                {{ $t('btn.remind_student') }}
              </v-btn>
            </v-col>
          </v-row>

        </v-alert>
        <v-btn
          v-if="isSchoolAdministrator"
          color="primary"
          class="mt-2"
          :disabled="selectedServices.length === 0 || (student.bookAndPay && student.balance < 0)"
          @click="openCreateInvoiceDialog"
        >
          {{ $t("label.create_invoice") }}
        </v-btn>
      </div>
      <v-alert v-else>
        <empty-box />
      </v-alert>
    </v-card-text>
    <v-card-text v-else>
      <v-skeleton-loader class="mx-auto" type="table-row@3" />
    </v-card-text>

    <CreateServiceDialog
      ref="CreateServiceDialog"
      @newServiceCreated="pushCreatedService" />
    <CreateInvoiceDialog ref="CreateInvoiceDialog" />
    <InvoicingSettingsDialog ref="InvoicingSettingsDialog" />
  </v-card>
</template>

<script>
import studentsService from "@/services/studentsService";
import formattedDatesMixin from "@/utils/mixins/formattedDatesMixin";
import CreateServiceDialog from "@/components/student-services/CreateServiceDialog";
import CreateInvoiceDialog from "@/components/invoices/CreateInvoiceDialog";
import StudentModel from "@/store/models/StudentModel";

import store from "@/store";
import { mapGetters } from "vuex";
import InvoicingSettingsDialog from "@/components/student/InvoicingSettingsDialog";
import user from '@/utils/mixins/user'
import StudentServiceTooltip from "@/components/invoices/StudentServiceTooltip.vue";
import StudentTabToolBar from '@/components/student/StudentTabToolBar.vue'
import LicenseModel from "@/store/models/LicenseModel";
import {showConfirm} from "@/helpers/dialogs";

export default {
  name: "StudentInvoices",
  mixins: [user, formattedDatesMixin],
  components: {
    StudentTabToolBar,
    StudentServiceTooltip,
    InvoicingSettingsDialog,
    CreateServiceDialog,
    CreateInvoiceDialog,
  },
  data: function () {
    return {
      loading: true,
      filtering: false,
      sendingNotification: false,
      data: [],
      modal: false,
      selectedServices: [],
      deletingService: null,
    };
  },
  beforeRouteEnter(from, to, next) {
    const student = StudentModel.find(from.params.id);
    const allowToAccessInvoices =
      store.getters["school/school"].isInvoicingEnabled &&
      student.priceCategoryId;

    if ((student && student.isDeleted) || !allowToAccessInvoices) {
      next({ name: "students.student", params: { id: from.params.id } });
      return;
    }

    next();
  },
  computed: {
    ...mapGetters("school", ["school"]),
    isToggleAllIndeterminate() {
      return (
        this.selectedServices.length !== 0 &&
        this.selectedServices.length < this.data.length
      );
    },
    selectedServicesList() {
      return this.data.filter((item) =>
        this.selectedServices.includes(item.id)
      );
    },
    student() {
      return StudentModel.find(this.$route.params.id)
    }
  },
  methods: {
    toggleAll() {
      if (this.selectedServices.length < this.data.length) {
        this.selectedServices = this.data.map((item) => item.id);
      } else {
        this.selectedServices = [];
      }
      return 0;
    },
    toggleService(id) {
      if (this.selectedServices.includes(id)) {
        this.selectedServices.splice(this.selectedServices.indexOf(id), 1);
      } else {
        this.selectedServices.push(id);
      }
    },
    openCreateServiceDialog() {
      this.$refs.CreateServiceDialog.open(this.$route.params.id);
    },
    openCreateInvoiceDialog() {
      this.$refs.CreateInvoiceDialog.open(
        this.$route.params.id,
        this.selectedServicesList
      );
    },
    pushCreatedService(service) {
      this.data.push({
        ...service,
        license: LicenseModel.find(service.licenseId)?.name ?? "-"
      });
    },
    deleteService(serviceId) {
      showConfirm(this.$t('label.confirmation'), this.$t('messages.are_your_sure_delete'), () => {
        this.deletingService = serviceId;
        studentsService
            .deleteService(this.$route.params.id, serviceId)
            .then(() => {
              this.data.splice(
                  this.data.findIndex((service) => service.id === serviceId),
                  1
              );
            })
            .catch()
            .finally(() => {
              this.deletingService = null;
            });
      })
    },
    sendNegativeBalanceReminder () {
      this.sendingNotification = true
      studentsService.sendNegativeBalanceReminder(this.$route.params.id)
        .then(() => {
          this.$snackbar.show({
            message: this.$t('messages.reminder_sent'),
            color: 'success',
            timeout: 3000
          })
        })
        .catch((err) => {
          this.$snackbar.show({
            message: err.response.data.message,
            color: 'error',
            timeout: 5000
          })
        })
        .finally(() => {
          this.sendingNotification = false
        })
    },
    fetch() {
      return studentsService
        .services(this.$route.params.id, { dates: this.date })
        .then((resp) => {
          this.data = resp.data.map(item => ({
            ...item,
            license: LicenseModel.find(item.licenseId)?.name ?? "-"
          }));
        });
    },
    filter() {
      this.filtering = true;
      this.fetch()
        .finally(() => {
          this.filtering = false;
        });
    }
  },
  mounted() {
    this.fetch()
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped></style>
