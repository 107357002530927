<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'school-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>
        {{ $t("route.schoolSettings.children.digitalAttendance") }}
      </v-card-title>
    </v-toolbar>
    <v-card-text>
      <SettingsResponsiveRow
        :title="$t('label.enable_digital_attendance')"
        :description="$t('label.digital_attendance_explanation')"
        :divider-after="false"
      >
        <v-switch
            :loading="changing"
            :disabled="changing"
            :input-value="localState"
            :label="$t('label.enabled')"
            @change="handleChange"
        />
      </SettingsResponsiveRow>
    </v-card-text>
  </div>
</template>

<script>
import schoolService from "@/services/schoolService";
import SettingsResponsiveRow from "@/components/settings/SettingsResponsiveRow";
import responsive from '@/utils/mixins/responsive'
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: "DigitalAttendance",
  components: {SettingsResponsiveRow},
  mixins: [responsive],
  data() {
    return {
      localState: undefined,
      changing: true,
    };
  },
  mounted() {
    this.localState = this.$store.state.school.isDigitalAttendanceEnabled;
    this.changing = false;
  },
  methods: {
    handleChange(newState) {
      this.localState = newState;
      if (newState) {
        this.changeStatus(newState);
      } else {
        showConfirm(
          this.$t('btn.ok'),
          this.$t("messages.deactivate_digital_attendance_text"),
          () => this.changeStatus(newState),
          () => (this.localState = !newState)
        );
      }
    },
    changeStatus(status) {
      this.changing = true;
      schoolService
        .updateDigitalAttendanceSettings(status)
        .then(() => {
          this.$store.commit("school/UPDATE_DIGITAL_ATTENDANCE_STATUS", status)
          this.$snackbar.success(this.$t("messages.successfully_updated"))
        })
        .finally(() => (this.changing = false));
    },
  },
};
</script>

<style scoped lang="scss"></style>
