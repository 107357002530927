<template>
  <v-row class="mt-2">
    <v-col>
      <v-select
        v-if="activeEventTypes.length > 1"
        :item-disabled="isTypeDisabled"
        @change="$emit('emitTypeChange', model.type)"
        v-model="model.type"
        :label="$t('form.event_type')"
        :items="eventTypes"
        item-value="id"
        :item-text="eventTypeLabel"
        outlined
        hide-details
        dense />
    </v-col>
  </v-row>
</template>

<script>
import EventTypeModel from "@/store/models/EventTypeModel";
import DateRange from "@/helpers/dateRange";
import { parseISO } from "date-fns";
import EventModel from "@/store/models/EventModel";
import { mapGetters } from "vuex";

export default {
  name: "EventTypesSelect",
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    eventTypes() {
      const allTypes = EventTypeModel.all() || {};
      return this.isSchoolAdministrator
        ? allTypes
        : allTypes.filter((type) => type.id !== "SIMULATOR");
    },
    activeEventTypes() {
      return this.eventTypes.filter((type) => !this.isTypeDisabled(type));
    },
    isHoliday() {
      const dateRange = new DateRange();
      const regions = this.schoolSettings.regions;
      const date = this.model.date ? parseISO(this.model.date) : new Date();
      return regions.length === 0
        ? dateRange.isHoliday(date)
        : dateRange.isHoliday(date, regions.split(", "));
    },
    isModelTypeDisabled() {
      const model = EventModel.find(this.model.id) || {};
      const isPast = parseISO(model.start) <= new Date();
      const requestedPlaceholder =
        model.eventType === "PLACEHOLDER" && !!model.requests.length;
      const nonChangeableEvents = [
        "PRACTICE",
        "THEORY",
        "SIMULATOR",
        "SPECIAL",
      ].includes(model.eventType);
      return isPast || requestedPlaceholder || nonChangeableEvents;
    },
  },
  methods: {
    isTypeDisabled(item) {
      return (
        (this.isHoliday && item.id !== "OFFTIME" && item.id !== "SPECIAL") ||
        this.isModelTypeDisabled
      );
    },
    eventTypeLabel(item) {
      return this.$t(`event_type.${item.id}`);
    },
  },
};
</script>

<style scoped></style>
