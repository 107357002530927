import { render, staticRenderFns } from "./AppointmentHistoryDialog.vue?vue&type=template&id=13d9530e&scoped=true"
import script from "./AppointmentHistoryDialog.vue?vue&type=script&lang=js"
export * from "./AppointmentHistoryDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13d9530e",
  null
  
)

export default component.exports