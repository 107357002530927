import { addMinutes, differenceInMinutes, format, parseISO, startOfDay } from 'date-fns'
import LicenseModel from '@/store/models/LicenseModel'

export default {
  computed: {
    licenseName() {
      return LicenseModel.find(this.event.licenseId).name
    },
    parsedDate(){
      return parseISO(this.event.start)
    },
    startTime() {
      return format(this.parsedDate, 'HH:mm')
    },
    endTime() {
      return format(addMinutes(this.parsedDate, this.event.duration), 'HH:mm')
    },
    positionLeft() {
      const parsedDate = parseISO(this.event.start)
      return differenceInMinutes(parsedDate, startOfDay(parsedDate))
    },
    eventPosition() {
      return `width: ${this.event.duration * this.oneMinuteSize }px; left: ${this.positionLeft * this.oneMinuteSize}px;`
    },
    eventStyle() {
      return `backgroundColor: ${this.event.bodyColor}; color: ${this.event.fontColor};`
    },
  },
};
