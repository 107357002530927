<template>
<v-container>
  <v-card :loading="loadingHappyHours">
    <v-toolbar elevation="3">
      <v-card-title>{{ $t('route.happyHours') }}</v-card-title>
      <v-spacer />
      <v-btn text color="primary" @click="open">{{ $t('label.create_happy_hour')  }}</v-btn>
    </v-toolbar>

    <v-card-text>
      <template v-if="happyHours.length > 0">
        <v-tabs
            class="mb-2"
            dense
            show-arrows
            v-model="pickedLicense"
            @change="tabBeenClicked"
        >

          <v-tab>{{ $t("label.all") }}</v-tab>

          <v-tab
              v-for="license in licenses"
              :key="license.id"
              :href="`#${license.id}`"
          >
            {{ license.name }}
          </v-tab>
        </v-tabs>

        <v-card-text class="pt-0">
          <v-skeleton-loader v-if="loadingHappyHours" class="mx-auto" type="table-row@3" />

          <template v-else>
            <HappyHoursList :happyHours="happyHours" />
          </template>
        </v-card-text>
      </template>

      <empty-box v-else />
    </v-card-text>
  </v-card>

  <HappyHourFormDialog ref="happyHourFormDialog" @overlapError="onOverlapError"/>
  <HappyHoursErrorDialog ref="happyHourErrorDialog"/>
</v-container>
</template>

<script>
import LicenseModel from "@/store/models/LicenseModel";
import HappyHoursModel from "@/store/models/HappyHoursModel";
import happyHoursService from "@/services/happyHoursService";
import HappyHoursList from "@/components/happyHours/HappyHoursList";
import currency from "@/utils/mixins/currency";
import responsive from "@/utils/mixins/responsive";
import HappyHourFormDialog from '@/components/happyHours/HappyHourFormDialog'
import HappyHoursErrorDialog from "@/components/happyHours/HappyHoursErrorDialog";

export default {
  name: "HappyHours",
  components: { HappyHourFormDialog, HappyHoursList, HappyHoursErrorDialog },
  mixins: [currency, responsive],
  data: () => ({
    showDialog: false,
    pickedLicense: null,
    loadingLicenses: true,
    loadingHappyHours: true
  }),
  mounted() {
    happyHoursService
      .load()
      .then((response) => HappyHoursModel.create({ data: response.data }))
      .finally(() => {
        this.loadingHappyHours = false
      })
  },
  watch: {
    happyHours(val) {
      if (!val.length) this.pickedLicense = null;
    }
  },
  computed: {
    licenses() {
      const activeHappyHours = HappyHoursModel.all() || [];
      const activeLicense = activeHappyHours.map((item) => item.licenseId) || [];

      return (
        LicenseModel.query()
          .whereIdIn([...new Set(activeLicense)])
          .orderBy("groupId")
          .get() || []
      );
    },
    happyHours() {
      if (this.pickedLicense) {
        return (
          HappyHoursModel.query()
            .where("licenseId", parseInt(this.pickedLicense))
            .orderBy("lessonTypeId")
            .get() || []
        );
      } else {
        return HappyHoursModel.query().orderBy("licenseId").get() || [];
      }
    }
  },
  methods: {
    tabBeenClicked(val) {
      this.pickedLicense = val;
    },
    open() {
      this.$refs.happyHourFormDialog.open();
    },
    close() {
      this.$refs.happyHourFormDialog.close();
    },
    onOverlapError(overlappedData) {
      this.$refs.happyHourErrorDialog.open(overlappedData)
    }
  },
};
</script>

<style scoped lang="scss">
.mobile-grid {
  height: 100vh !important;
  display: grid !important;
  grid-template-rows: 48px auto 56px;
}
</style>
