<template>
  <div>
    <v-row class="mt-1">
      <v-col cols="12" md="1">
        <v-text-field
          label="ID"
          placeholder="ID"
          v-model="filters.id"
          solo
          dense
          clearable
        />
      </v-col>

      <v-col cols="12" md="4">
        <v-dialog
          ref="dialog"
          v-model="isDatesRangeShow"
          :return-value.sync="filters.start"
          persistent
          width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              solo
              dense
              readonly
              :value="formattedDates"
              :label="$t('label.filter_by_date')"
              :placeholder="$t('label.filter_by_date')"
              prepend-inner-icon="mdi-calendar"
              clearable
              @click:clear="filters.start = []"
              v-bind="attrs"
              v-on="on"
            />
          </template>

          <v-date-picker
            v-model="filters.start"
            scrollable
            range
            first-day-of-week="1"
          >
            <v-spacer />
            <v-btn text color="primary" @click="isDatesRangeShow = false">{{ $t("btn.cancel") }}</v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(filters.start.sort())"
            >
              {{ $t("btn.ok") }}
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-col cols="12" md="3">
        <v-select
          v-model="filters.teachers"
          :items="teachersAsOptions"
          :label="$t('label.filter_by_teacher')"
          :placeholder="$t('label.filter_by_teacher')"
          solo
          dense
          multiple
          chips
          small-chips
          deletable-chips />
      </v-col>

      <v-col>
        <v-btn text color="primary" @click="filter" :loading="loading">{{ $t("btn.filter_results") }}</v-btn>
      </v-col>

      <v-col>
        <Pagination
          v-if="pagination.total > 0"
          :loading="loading"
          :from="pagination.from"
          :to="pagination.to"
          :current-page="pagination.currentPage"
          :total-nr-of-pages="pagination.lastPage"
          :total-nr-of-items="pagination.total"
          @pageChange="onPageChange" />
      </v-col>
    </v-row>

    <v-skeleton-loader class="mx-auto" type="table-row@3" v-if="loading" />

    <template v-else>
      <v-simple-table v-if="items.length > 0" dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" style="width: 60px">ID</th>
              <th class="text-left" style="width: 120px">
                {{ $t("label.type") }}
              </th>
              <th class="text-left" style="width: 120px">
                {{ $t("label.date") }}
              </th>
              <th class="text-left" style="width: 120px">
                {{ $t("label.start") }} - {{ $t("label.end") }}
              </th>
              <th class="text-left" style="width: 130px">
                {{ $t("duration") }}
              </th>
              <th class="text-left" style="width: 50px">{{ $t("license") }}</th>
              <th class="text-left">{{ $tc("teacher", 1) }}</th>
              <th class="text-left">{{ $t("label.deleted_by") }}</th>
              <th class="text-left" style="width: 180px">
                {{ $t("archived_at") }}
              </th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id" class="table-row">
              <td class="text-monospace">{{ item.id }}</td>
              <td class="text-center">
                <v-chip
                  x-small
                  :color="eventColor(item).bodyColor"
                  :style="`color: ${eventColor(item).fontColor}`">
                  <span class="caption" v-if="item.lessonTypeId">{{
                    lessonTypeName(item.lessonTypeId)
                  }}</span>
                  <span class="caption" v-else>{{
                    $t("event_type." + item.type)
                  }}</span>
                </v-chip>
              </td>
              <td>{{ item.startDate | dateFormat('dd.MM.yyyy') }}</td>
              <td>{{ item.startTime }} - {{ item.endTime }}</td>
              <td>{{ item.duration }}</td>
              <td>{{ item.license || "-" }}</td>
              <td>{{ item.teacher || "-" }}</td>
              <td>{{ item.deletedBy || "-" }}</td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{
                      item.deletedAtForHumans
                    }}</span>
                  </template>
                  {{ item.deletedAt | dateFormat }}
                </v-tooltip>
              </td>
              <td style="width: 68px">
                <v-chip
                  class="hidden-until-row-hovered"
                  link
                  label
                  x-small
                  :to="{ name: 'calendar.appointment', params: { id: item.id } }">
                  <v-icon x-small>mdi-arrow-right</v-icon>
                </v-chip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-alert v-else>
        <empty-box />
      </v-alert>
    </template>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";

import appointmentService from "@/services/appointmentService";
import TeacherModel from "@/store/models/TeacherModel";
import Pagination from "@/components/Pagination";
import LessonTypeModel from "@/store/models/LessonTypeModel";
import EventTypeModel from "@/store/models/EventTypeModel";

export default {
  name: "RecyclableEventsList",
  components: { Pagination },
  data: () => ({
    loading: true,
    items: [],
    isDatesRangeShow: false,
    filters: {
      id: "",
      start: [],
      teachers: [],
    },
    pagination: {
      currentPage: 1,
      from: 0,
      lastPage: 1,
      to: 0,
      total: 0,
    },
  }),
  computed: {
    teachersAsOptions() {
      return TeacherModel.all().map((teacher) => {
        return { text: teacher.fullName, value: teacher.id };
      });
    },
    formattedDates() {
      if (this.filters.start.length) {
        const localDate = this.filters.start;
        const formatted = localDate
          .sort()
          .map((item) => format(parseISO(item), "dd.MM.yyyy"));

        if (formatted.length > 1)
          return `vom ${formatted[0]} bis ${formatted[1]}`;
        return formatted;
      }
      return this.filters.start;
    },
    filledFilters () {
      const filledFilters = {};

      if (this.filters.id) filledFilters.id = this.filters.id;
      if (this.filters.start.length > 0) filledFilters.start = this.filters.start;
      if (this.filters.teachers) filledFilters.teachers = this.filters.teachers;

      return filledFilters
    }
  },
  mounted() {
    this.fetch({ page: this.$route.query.page || 1 });
  },
  methods: {
    fetch(params = {}) {
      this.loading = true;
      params.perPage = 50;
      return appointmentService
        .get_deleted_list(params)
        .then((response) => {
          const responseData = response.data || {};
          this.items = responseData.data || [];
          this.pagination = responseData.pagination || {};
        })
        .catch(error => {
          const response = error.response || {};
          const responseData = response.data || {};

          if (responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error", });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filter() {
      this.fetch(this.filledFilters)
        .then(() => {
          this.$router
            .replace({ query: { ...this.$route.query, page: 1 } })
            .catch(console.log);
        });
    },
    onPageChange() {
      this.fetch({ ...this.filledFilters, page: this.$route.query.page || 1 });
    },
    eventColor(event) {
      if (event.eventType === "PRACTICE" || event.eventType === "THEORY") {
        if (event.status === "REQUESTED" || event.status === "DECLINED") {
          return (
            LessonTypeModel.query()
              .where("name", "lesson_type.normal")
              .first() || {}
          );
        }
        return LessonTypeModel.find(event.lessonTypeId) || {};
      } else {
        return EventTypeModel.find(event.type) || {};
      }
    },
    lessonTypeName(val) {
      const type = LessonTypeModel.find(val) || {};
      return this.$tc(type.name, 1);
    },
  },
};
</script>

<style scoped></style>
