<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th style="width: 10px"></th>
          <th style="width: 150px">{{ $t("label.transaction_id") }}</th>
          <th>{{ $t("label.student") }}</th>
          <th style="width: 150px">{{ $t("label.amount") }}</th>
          <th style="width: 150px">{{ $t("label.tax_amount") }}</th>
          <th style="width: 150px">{{ $t("label.net_amount") }}</th>
          <th class="text-right" style="width: 200px">
            {{ $t("label.created") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="transaction in data" :key="transaction.id" class="table-row">
          <td>
            <v-icon v-if="transaction.refunded">mdi-undo-variant</v-icon>
          </td>
          <td style="width: 10px !important">
            <v-chip
              label
              small
              class="text-monospace"
              link
              @click="copyToClipboard(transaction.id)">
              {{ transaction.id | ellipsis }}
            </v-chip>
          </td>
          <td>
            {{ transaction.clientName }} <span v-if="transaction.licenses.length">({{ transaction.licenses}})</span>
          </td>
          <td>
            <span class="font-weight-bold">
              {{ transaction.amount | currency }}
            </span>
          </td>
          <td>
            <span class="font-weight-bold">
              {{ transaction.fee | currency }}
            </span>
          </td>
          <td>
            <span class="font-weight-bold">
              {{ (transaction.amount - transaction.fee) | currency }}
            </span>
          </td>
          <td class="text-right">
            {{ transaction.created | dateFormat }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="3" class="text-right">Total</th>
          <th>{{ total.gross | currency }}</th>
          <th>{{ total.fee | currency }}</th>
          <th>{{ total.net | currency }}</th>
          <td colspan="2"></td>
        </tr>
      </tfoot>
    </template>
  </v-simple-table>
</template>

<script>
import clipboard from "@/utils/mixins/clipboard";
import TransactionModel from "@/store/models/TransactionModel";

export default {
  name: "TransactionsTable",
  mixins: [clipboard],
  props: {
    transactions: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    TransactionModel.create({ data: this.transactions || [] });
  },
  computed: {
    data() {
      const query = TransactionModel.query();
      query.orderBy("created", "desc");
      return query.get();
    },
    total() {
      return {
        gross: this.data.reduce((sum, current) => {
          return sum + current.amount;
        }, 0),
        fee: this.data.reduce((sum, current) => {
          return sum + current.fee;
        }, 0),
        net: this.data.reduce((sum, current) => {
          return sum + (current.amount - current.fee);
        }, 0),
      };
    },
  },
};
</script>

<style scoped lang="scss">
.hidden-until-row-hovered {
  display: none;
}
.table-row:hover > td > .hidden-until-row-hovered {
  display: inline-flex;
}
</style>
