<template>
  <v-list :class="`students-list ${disabled ? 'disabled' : ''}`">
    <v-list-item-group
        @change="(val) => $emit('emitPickStudent', val)"
        :mandatory="studentsList.length === 1"
        dense
    >
      <template v-for="(student, i) in studentsList">
        <v-divider v-if="i > 0" :key="`divider-${i}`"></v-divider>
        <v-list-item
            active-class="green--text green--text"
            :key="i"
            :value="student">
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-list-item-title>{{ student.licenseName }}</v-list-item-title>
            </v-list-item-action>
            <v-list-item-content>
              <div class="d-flex justify-space-between">
                <div>{{ student.studentName }}</div>
                <div v-if="student.bookAndPay">
                   <span v-if="!student.priceCategoryId" class="mr-5">
                      <v-icon color="orange" x-small left>mdi-alert</v-icon>
                      {{ $t('label.missing_price_list') }}
                  </span>
                  <span>{{ student.balance | currency }}</span>
                </div>
              </div>
            </v-list-item-content>
            <v-list-item-action>
              <v-checkbox :input-value="active" readonly></v-checkbox>
            </v-list-item-action>
          </template>
        </v-list-item>
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>

export default {
  name: "PlaceholderRequestsField",
  props: {
    studentsList: {
      type: Array,
      required: true,
    },
    studentsProp: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.students-list {
  border: 1px solid rgba(0, 0, 0, 0.38);
  margin: 0;
  padding: 0;
  border-radius: 5px;
  overflow-y: auto;
  max-height: 148px;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
