<template>
  <div class="progress-bar-wrapper">
    <div style="width: 100%">
      <div v-if="label" class="d-flex justify-space-between">
        <div class="text--secondary label flex-shrink-1 text-overflow-ellipsis d-flex">
          <span>{{ label }}</span>
          <slot name="label-append"></slot>
        </div>
        <span class="label flex-grow-1 text-right">
          {{ completedSegmentsCount }}<span v-if="requiredSegments">/{{requiredSegments}}</span>
          <span v-if="additionalMinutes > 0" class="text--secondary">
            +{{ $t('label.duration_in_minutes_shorthand', { min: additionalMinutes }) }}
          </span>
          &middot;
          <span class="text--secondary">{{ $t('label.duration_in_minutes_shorthand', { min: totalValue }) }}</span>
        </span>
      </div>
      <div class="segmented-progress-bar" :style="{'grid-template-columns': gridColumns}">
        <v-progress-linear
            v-if="requiredSegments === 0"
            height="100%"
            :color="color"
            :dark="dark"
        >
          {{ $t('label.not_required') }}
        </v-progress-linear>

        <template v-else>
          <v-progress-linear
              v-for="segment in segments"
              class="segment"
              :dark="dark"
              :key="segment"
              height="100%"
              :value="(segment === segments.length - 1)
            ? lastSegmentPercentage : 100"
              :color="color"
              style="transition-delay: 0s; transition-duration: 0s"
          >
            <span v-if="segment === segments.length - 1 && lastSegmentValue !== segmentLength">
              {{ lastSegmentValue }}m
            </span>
            <v-icon small color="white" v-else>mdi-checkbox-outline</v-icon>
          </v-progress-linear>

          <v-progress-linear
              v-for="emptySegment in remainingEmptySegments"
              class="segment"
              :key="`emptySegment${emptySegment}`"
              height="100%"
              :dark="dark"
              :color="color"
          >
          </v-progress-linear>
        </template>
      </div>
    </div>

    <v-icon :color="isProgressCompleted ? 'green' : 'red'" v-if="requiredSegments && showCompletionIcon">
      {{ isProgressCompleted ? 'mdi-check' : 'mdi-close' }}
    </v-icon>
    <slot name="footer"></slot>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
export default {
  name: "SegmentedProgressBar",
  props: {
    color: {
      type: String,
      default: 'secondary'
    },
    label: {
      type: String,
      required: false
    },
    segmentLabel: {
      type: String,
      default: i18n.tc('label.lessons', 2)
    },
    dark: {
      type: Boolean,
      default: false
    },
    totalValue: {
      type: Number,
      required: true
    },
    segmentLength: {
      type: Number,
      required: true
    },
    requiredSegments: {
      type: Number,
      required: false,
      default: null
    },
    showCompletionIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    segmentsCount () {
      return Math.ceil(this.totalValue / this.segmentLength)
    },
    completedSegmentsCount() {
      return Math.floor(this.totalValue / this.segmentLength)
    },
    segments() {
      const length = ! this.requiredSegments || (this.requiredSegments && this.segmentsCount <= this.requiredSegments)
          ? this.segmentsCount
          : this.requiredSegments;

      return Array.from(Array(length).keys())
    },
    remainingEmptySegments() {
      const length = this.requiredSegments && this.segmentsCount <= this.requiredSegments
          ?  this.requiredSegments - this.segmentsCount
          : 0;
      return Array.from(Array(length).keys())
    },
    lastSegmentPercentage() {
      return this.totalValue % this.segmentLength === 0 || this.segmentsCount > this.segments.length
          ? 100
          : (this.totalValue % this.segmentLength) * 100 / this.segmentLength
    },
    lastSegmentValue() {
      return this.totalValue % this.segmentLength === 0 || this.segmentsCount > this.segments.length
          ? this.segmentLength
          : this.totalValue % this.segmentLength
    },
    additionalMinutes () {
      return this.totalValue % this.segmentLength
    },
    gridColumns() {
      const count = this.requiredSegments !== null
          ? (this.requiredSegments === 0 && this.totalValue === 0 ? 1 : this.requiredSegments)
          : this.segmentsCount;
      return `repeat(${count}, 1fr)`
    },
    isProgressCompleted() {
      return this.completedSegmentsCount >= this.requiredSegments
    },
  }
}
</script>

<style scoped lang="scss">
.label {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
}
.progress-bar-wrapper {
  display: flex;
  width: 100%;
  align-items: self-end;
  flex-direction: column;
}

.segmented-progress-bar {
  flex-grow: 1;
  display: grid;
  border: solid 1px #eee;
  border-radius: 4px;
  background: transparent;
  overflow: hidden;
  height: 23px;
  width: 100%;
  padding: 0;
  margin: 0;

  &>.segment {
    border-right: solid 1px #eee;
    &:last-child {
      border-right: none;
    }
  }
}
</style>
