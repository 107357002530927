<template>
  <div
    :class="`text-center ${card ? 'card' : ''} ${
      mdLandscapeAndUp ? '' : 'mobile'
    }`">
    <div class="name">{{ name }}</div>
    <div class="status" v-if="status === 0">
      <v-icon class="lbl" color="red" large>mdi-close-circle-outline</v-icon>
    </div>
    <div class="status" v-if="status === 1">
      <v-icon class="lbl" color="secondary" large
        >mdi-check-circle-outline</v-icon
      >
    </div>
    <div class="status" v-if="status === 2">
      <v-chip class="lbl" label :small="!mdLandscapeAndUp" color="secondary"
        >Negativer Test</v-chip
      >
    </div>
    <div class="status" v-if="status === 3">
      <v-chip class="lbl" label :small="!mdLandscapeAndUp" color="secondary"
        >Negativer PCR-Test</v-chip
      >
    </div>
  </div>
</template>

<script>
import responsive from "@/utils/mixins/responsive";

export default {
  name: "CovidStatusLabel",
  mixins: [responsive],
  props: {
    name: {
      type: String,
      required: true,
    },
    status: {
      type: Number,
      required: true,
    },
    card: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  .name {
    display: flex;
    text-align: left;
    align-items: center !important;
    width: 150px !important;
  }
  .status {
    margin-left: auto !important;
    text-align: center !important;
  }
  &.mobile {
    display: flex;
    align-items: center;
    flex-direction: row !important;
    justify-content: space-between;
    .lbl {
      margin: 0 !important;
    }
    .status {
      width: 150px !important;
      text-align: center !important;
    }
    .name {
      width: auto !important;
    }
  }
}
.mobile {
  display: flex;
  flex-direction: row-reverse;
  .name {
    width: 100px;
  }
  .lbl {
    margin-right: 10px;
  }
}
</style>
