<template>
  <v-card>
    <v-card-title class="py-2 d-flex justify-space-between">
      <div>
        {{ $t("label.teacher_work_time") }}
        <span class="text-caption ml-1 mt-1">({{ $t("label.in_minutes") }})</span>
      </div>
    </v-card-title>
    <v-divider />
    <v-card-text class="d-flex justify-space-between">
      <div
        class="d-flex flex-column flex-grow-1 text-decoration-none text-overflow-ellipsis"
        style="flex-basis: 0">
        <span class="text-caption text-overflow-ellipsis">{{
          $t("label.this_week")
        }}</span>
        <v-skeleton-loader
          type="image"
          class="mt-3 mb-3"
          width="40"
          height="15"
          v-if="loading" />
        <span class="text-h5" v-else>{{ week || "—" }}</span>
      </div>
      <div
        class="d-flex flex-column flex-grow-1 text-decoration-none text-overflow-ellipsis"
        style="flex-basis: 0">
        <span class="text-caption text-overflow-ellipsis">{{
          $t("label.this_month")
        }}</span>
        <v-skeleton-loader
          type="image"
          class="mt-3 mb-3"
          width="40"
          height="15"
          v-if="loading" />
        <span class="text-h5" v-else>{{ month || "—" }}</span>
      </div>
      <div
        class="d-flex flex-column flex-grow-1 text-decoration-none text-overflow-ellipsis"
        @click="showDailyEventsDialog"
        style="flex-basis: 0; cursor: pointer"
      >
        <span class="text-caption text-overflow-ellipsis primary--text">Tagesnachweis als PDF</span>
        <span><v-icon color="primary">mdi-download</v-icon></span>
      </div>
    </v-card-text>

    <TeacherDailyEvents ref="dailyEvents" :teacher="currentUser" />
  </v-card>
</template>

<script>
import teacherService from "@/services/teacherService";
import TeacherDailyEvents from "@/components/teacher/TeacherDailyEvents";
import user from "@/utils/mixins/user";

export default {
  name: "TeacherWorkTime",
  mixins: [user],
  components: { TeacherDailyEvents },
  data() {
    return {
      loading: true,
      week: 0,
      month: 0,
    };
  },
  mounted() {
    teacherService
      .workedTime(this.currentUser.id)
      .then((response) => {
        const responseData = response.data || {};

        this.week = parseInt(responseData.currentWeek);
        this.month = parseInt(responseData.currentMonth);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    showDailyEventsDialog() {
      this.$refs.dailyEvents.open();
    },
  },
};
</script>

<style scoped></style>
