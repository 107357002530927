import studentsDirectoryService from "@/services/v2/studentsDirectoryService";
import Vue from "vue";
import Promise from "vue-social-auth/src/promise";
import i18n from "@/plugins/i18n";

const parseKeyName = key => {
    if (! key || key === '') return i18n.t('label.no_data')

    return key;
}

const state = () => ({
    globalLoading: false,
    treeGroupTypes: [],
    groupBy: '',
    tree: {},
    filters: {
        search: "",
        office: "",
        licenses: [],
        teachers: [],
        priceCategory: "",
        createdAtDates: [],
        activityStatus: "active",
        educationStatus: "",
        gender: "",
        noAppointmentsRange: 7,
        withoutAppointments: false,
        noBalance: false,
        automaticInvoicingDisabled: false,
        hasClickAndLearn: false,
    },
    searchClearedExternally: false,
    openedStudents: {},
    openedStudentsOrder: [],
    activeTab: 'home',
    isTreeSelectable: false,
    selectedTreeItems: [],
    loadingStudents: {},
    filtersDataLoading: false,
    filtersDialogState: false,
    filtersData: {
        priceLists: [],
        teachers: [],
        offices: [],
        licenses: []
    },
    contentBodyWidth: 0,
});

const getters = {
    treeGroupTypes: state => state.treeGroupTypes,
    groupBy: state => state.groupBy,
    filters: state => state.filters,
    searchQuery: state => state.filters.search,
    appliedFilters: state => {
        const getFilledValues = (obj) => {
            const filledValues = {};
            for (const key in obj) {
                if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                    const nestedFilledValues = getFilledValues(obj[key]);
                    if (Object.keys(nestedFilledValues).length !== 0) {
                        filledValues[key] = nestedFilledValues;
                    }
                } else if (Array.isArray(obj[key]) && obj[key].length > 0) {
                    filledValues[key] = obj[key];
                } else if (obj[key] && typeof obj[key] !== 'object') {
                    filledValues[key] = obj[key];
                }
            }
            return filledValues;
        };

        const filters = state.filters;

        return getFilledValues(filters)
    },
    // eslint-disable-next-line no-unused-vars
    appliedFiltersCount: (_, getters) => {
        const excludeKeys = ['noAppointmentsRange']; // Add more keys here if needed
        const filterKeys = Object.keys(getters.appliedFilters);

        // Filter out the keys to exclude and then count
        return filterKeys.filter(key => !excludeKeys.includes(key)).length;
    },
    groupByLabel: state => state.treeGroupTypes.filter(item => item.value === state.groupBy)[0]?.text,
    tree: (state) => {
        const { tree } = state;

        return Object.keys(tree).map(key => {
            const group = tree[key];
            let children = [];

            // Check if the group is an array (single level) or an object (two levels)
            if (Array.isArray(group)) {
                // Single level: Direct leaves under the root
                children = group.map((child) => ({
                    id: child.id, // Keep the original student ID for leaf nodes
                    name: `${child.last_name} ${child.first_name}`,
                    active: child.active
                }));
            } else {
                // Two levels: Iterate over sublevels
                Object.keys(group).forEach(subKey => {
                    const subGroup = group[subKey];
                    children.push({
                        id: `${key}-${subKey}`, // Unique ID for sublevel
                        name: parseKeyName(subKey),
                        count: subGroup.length,
                        children: subGroup.map((child) => ({
                            id: child.id, // Keep the original student ID for leaf nodes
                            name: `${child.last_name} ${child.first_name}`,
                            active: child.active
                        }))
                    });
                });
            }

            return {
                id: key,
                name: parseKeyName(key), // Assuming parseKeyName is a function to format the key name
                count: Array.isArray(group) ? group.length : children.reduce((acc, child) => acc + child.count, 0),
                children: children
            };
        });
    },
    isTreeSelectable: state => state.isTreeSelectable,
    selectedTreeItems: state => state.selectedTreeItems,
    openedStudents: state => {
        return state.openedStudentsOrder.map(id => state.openedStudents[id]);
    },
    activeTab: state => state.activeTab,
    isStudentOpened: state => state.activeTab !== 'home',
    openedStudentID: state => state.activeTab !== 'home' ? parseInt(state.activeTab) : null,
    filtersDialogState: state => state.filtersDialogState,
    filtersDataLoading: state => state.filtersDataLoading,
    filtersData: state => state.filtersData,
    studentFileStackedCondition: state => state.contentBodyWidth < 1160
};

const actions = {
    loadGroupTypes: ({ commit }) => {
        return new Promise((resolve, reject) => {
            studentsDirectoryService
                .loadTreeGroupTypes()
                .then(resp => {
                    const data = resp.data || [];
                    commit('set_tree_group_types', data)
                    resolve(data)
                })
                .catch(reject)
        })
    },
    load: ({ commit, getters }, silent = false) => {
        if (! silent) {
            commit('set_global_loader', true)
        }
        return new Promise((resolve, reject) => {
            studentsDirectoryService
                .load({ ...getters.appliedFilters, group: getters.groupBy })
                .then(resp => {
                    const data = resp.data || {}
                    commit('set_tree', data)
                    resolve(data)
                })
                .catch(reject)
                .finally(() => {
                    if (! silent) {
                        commit('set_global_loader', false)
                    }
                })
        })
    },
    openStudent: ({ commit, getters }, student) => {
        if (!getters.openedStudents.some(s => s.id === student.id)) {
            commit('open_student', student)
        }

        commit('set_active_student_tab', student.id)
    },
    closeStudent: ({ commit }, studentId) => {
        commit('close_student', studentId)
    },
    startStudentDataLoading: ({ commit }, studentId) => {
        commit('start_student_data_loading', studentId)
    },
    stopStudentDataLoading: ({ commit }, studentId) => {
        commit('stop_student_data_loading', studentId)
    },
    openFiltersDialog: ({commit}) => {
        commit('set_filters_data_loading', true)
        studentsDirectoryService
            .loadFiltersData()
            .then(resp => {
                const filtersData = resp.data || {}
                commit('set_filters_data', filtersData)
                commit('set_filters_dialog_state', true)
            })
            .catch(() => {
                Vue.$snackbar.show({message: 'Error loading filters!'})
            })
            .finally(() => {
                commit('set_filters_data_loading', false)
            })
    },
    closeFiltersDialog: ({commit, dispatch}) => {
        commit('set_filters_dialog_state', false)
        dispatch('load')
    },
    setGroupBy: ({ commit }, value) => {
        commit('set_group_by', value)
    },
    clearSelectedStudents: ({ commit }) => {
        commit('set_selected_tree_items', [])
    },
    resetFilter: ({ commit }, payload) => {
        let filterKeys = Array.isArray(payload) ? payload : [payload];
        return new Promise(resolve => {
            filterKeys.forEach(item => {
                if (typeof item === 'object' && item !== null) {
                    // If the item is an object, it contains both key and value
                    commit('update_filter', item);
                } else {
                    // If the item is not an object, just reset the filter to its default state
                    commit('reset_filter', item);
                }
            });
            resolve();
        });
    },
    setFilter: ({commit}, filter) => {
        commit('update_filter', filter)
    },
    setContentBodyWidth: ({ commit }, width) => {
        commit('set_content_body_width', width)
    }
};

const mutations = {
    set_global_loader: (state, value) => {
        state.globalLoading = value
    },
    set_tree_group_types: (state, data) => {
        state.treeGroupTypes = data;
    },
    set_tree: (state, treeData) => {
        state.tree = treeData;
    },
    set_tree_selectable: (state, value) => {
        state.isTreeSelectable = value
    },
    open_student: (state, student) => {
        Vue.set(state.openedStudents, student.id, {...student, loading: false});
        if (!state.openedStudentsOrder.includes(student.id)) {
            state.openedStudentsOrder.push(student.id);
        }
    },
    close_student: (state, studentId) => {
        Vue.delete(state.openedStudents, studentId);
        const index = state.openedStudentsOrder.indexOf(studentId);
        if (index !== -1) {
            state.openedStudentsOrder.splice(index, 1);
        }
    },
    set_active_student_tab: (state, studentId) => {
        state.activeTab = studentId.toString();
    },
    start_student_data_loading: (state, studentId) => {
        Vue.set(state.openedStudents[studentId], 'loading', true)
    },
    stop_student_data_loading: (state, studentId) => {
        Vue.set(state.openedStudents[studentId], 'loading', false)
    },
    set_filters_dialog_state: (state, filtersDialogState) => {
        state.filtersDialogState = filtersDialogState;
    },
    set_filters_data_loading: (state, loading) => {
        state.filtersDataLoading = loading;
    },
    set_filters_data: (state, filtersData) => {
        state.filtersData = {...filtersData}
    },
    set_group_by: (state, value) => {
        if (state.groupBy !== value) {
            state.groupBy = value;
        }

        localStorage.setItem('students-directory-group-by', value)
    },
    set_selected_tree_items: (state, value) => {
        state.selectedTreeItems = value
    },
    update_filter: (state, { key, value }) => {
        if (key in state.filters) {
            state.filters[key] = value;
        }
    },
    reset_filter: (state, filterKey) => {
        if (filterKey === 'search') {
            state.searchClearedExternally = true
        }

        if (filterKey === 'noAppointmentsRange') {
            state.filters[filterKey] = 7
        } else if (Array.isArray(state.filters[filterKey])) {
            state.filters[filterKey] = [];
        } else if (typeof state.filters[filterKey] === 'boolean') {
            state.filters[filterKey] = false;
        } else {
            state.filters[filterKey] = '';
        }
    },
    set_content_body_width: (state, width) => {
        state.contentBodyWidth = width
    },
    update_search_query: (state, newQuery) => {
        state.filters.search = newQuery
    },
    set_search_cleared_externally(state, value) {
        state.searchClearedExternally = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
