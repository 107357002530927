<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="9.34cm"
    height="8.2cm"
    viewBox="0 0 264.75 232.5"
    fill="currentColor"
    class="svg">
    <path
      class="blue"
      d="M27.43,159.08a18.6,18.6,0,0,0-4,.44c.42-4.27,2.44-14.73,12.18-16.47,12.29-2.2,30.73,1.75,31,33.95,0,0,11.42,4.83,13.76,8.19,0,0,14.39-19,27.56-2.58,0,0,13.27-15.51,25.07,0,0,0,11-15.32,24.78-.29l7.61-5.32s-5-45.66-40.39-71.27S54.5,81.15,34.46,91,1.24,118.61,1.38,134.12c.14,15,3.44,22.61,10.26,33.67a18.68,18.68,0,1,0,15.79-8.71Z"
      transform="translate(-1.38 -1.4)" />
    <path
      class="blue"
      d="M209.11,184c-2.63,0-16.75,2.65-25.75,16.44,0,0-2.86-13.08-13-16.44,0,0-9.52,3.46-12.37,16.44,0,0-2.12-12.5-12.36-16.44,0,0-10.79,4.34-12.64,16.44,0,0-2.66-13.3-12.46-16.44,0,0-11.71,4.92-12.29,16.44,0,0-1.54-12.06-12.44-16.44,0,0-10.47,3.39-12.66,16.44,0,0-11.78-16.16-26-16.44a3.14,3.14,0,0,0-3,3.18v43.42s.25,3.29,2.81,3.29H209.11s2.78-.75,2.78-3.29v-43S211.75,184,209.11,184Z"
      transform="translate(-1.38 -1.4)" />
    <path
      class="blue"
      d="M263.87,123.68C255.38,99.19,228.16,84.43,206,84.54s-45.08,5-66.15,22.65c0,0,29.27,25.27,33.85,68.88,0,0,7.52,2.83,11.61,9.12,0,0,6.83-7.46,13.76-8.14,0,0-.49-24.39,14.24-31.71,14.3-7.1,30.16-1,29.65,14a18.68,18.68,0,1,0,12.51,8.16C259.46,162.16,271,144.15,263.87,123.68Z"
      transform="translate(-1.38 -1.4)" />
    <path
      class="blue"
      d="M133.82,101.24c14.93-13.85,40.49-21.46,40.49-21.46s.29-7.41.29-13.85.78-17.56,11-21.66c8.33-3.34,15.44.44,17.8,2a18.68,18.68,0,1,0,19.89-6.56C215,10,183.13,3.56,175.09,2.41c-8.2-1.17-39.61-5.07-67.32,17.37S83.41,77.83,83.41,77.83C106.26,79.49,133.82,101.24,133.82,101.24Z"
      transform="translate(-1.38 -1.4)" />
  </svg>
</template>

<script>
export default {
  name: "JokerHat",
};
</script>

<style scoped></style>
