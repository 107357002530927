import { Device } from '@capacitor/device'
import { Browser } from "@capacitor/browser";
import {mapGetters} from "vuex";
export default {
  data() {
    return {
      device: { platform: "" },
    };
  },
  async mounted() {
    this.device = await Device.getInfo();
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
    isMobileApp() {
      return ["ios", "android"].includes(this.device.platform);
    },
    url() {
      const baseURL =
        this.platform === "android"
          ? "de.drivolino.planer://"
          : `${location.protocol}//${location.host}/`;
      const balancePage = this.currentUser.type === 'school-administrator' ? 'school-balance' : 'balance';
      return `${baseURL}${balancePage}`;
    },
    platform() {
      return this.$route.query.platform;
    },
  },
  methods: {
    closeBrowser() {
      Browser.close();
    },
  },
};
