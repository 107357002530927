<script>
import responsive from '@/utils/mixins/responsive'

export default {
  name: 'EducationOverallProgress',
  mixins: [responsive],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    progress: {
      type: [String, Number],
      default: 0
    },
  }
}
</script>

<template>
<div class="d-flex justify-space-between align-center">
  <template v-if="loading">
    <v-skeleton-loader width="20%" type="list-item"/>
    <v-skeleton-loader width="40%" type="list-item"/>
  </template>

  <v-sheet v-else width="100%">
    <v-row dense>
      <v-col cols="12" lg="6">
        <span class="text-h6">{{ $t('label.education_progress') }}</span>
      </v-col>
      <v-col cols="12" lg="6">
        <v-progress-linear
            class="my-2"
            rounded
            :value="progress"
            :height='mdLandscapeAndUp ? 25 : 18'
            color="secondary"
        >
          {{ progress }}%
        </v-progress-linear>
      </v-col>
    </v-row>
  </v-sheet>
</div>
</template>

<style scoped>

</style>
