<template>
  <div>
    <SnackbarToast
      v-for="snackbar in toastSnackbars"
      :key="snackbar.id"
      :snackbar="snackbar" />
    <SnackbarDefault
      v-for="snackbar in defaultSnackbars"
      :key="snackbar.id"
      :snackbar="snackbar" />
  </div>
</template>
<script>
import SnackbarModel from "@/store/models/SnackbarModel";
import SnackbarToast from "@/components/snackbars/SnackbarToast";
import SnackbarDefault from "@/components/snackbars/SnackbarDefault";

export default {
  name: "Snackbars",
  components: { SnackbarDefault, SnackbarToast },
  computed: {
    snackbars() {
      return SnackbarModel.query().get();
    },
    toastSnackbars() {
      return SnackbarModel.query().where("type", SnackbarModel.TOAST).get();
    },
    defaultSnackbars() {
      return SnackbarModel.query().where("type", SnackbarModel.DEFAULT).get();
    },
  },
};
</script>

<style lang="scss"></style>
