<template>
  <v-card id="studentHistory" elevation="0" outlined :loading="loading">
    <v-card-title class="d-flex justify-space-between">
      <div>{{ $t("label.history") }}</div>
      <div>
        <v-select
          v-model="pickedFilter"
          style="width: 180px"
          :items="filters"
          label="Filtern"
          dense
          outlined
          hide-details
          clearable
        />
      </div>
    </v-card-title>

    <v-skeleton-loader v-if="loading" type="list" />
    <template v-else>
      <v-card-text>
        <div class="text-body-1 mb-2 d-flex align-center" v-if="filterBy !== null">
          Besichtigungstermin
          <span class="text-decoration-underline-dotted" @click="copyToClipboard(filterBy)">
          #{{ filterBy }}
        </span>
          <v-btn @click="filterBy = null" small text class="ml-2">{{$t('btn.clear')}}</v-btn>
        </div>
      </v-card-text>
      <div v-if="history && allHistoryRecords.length">
        <v-expansion-panels tile accordion multiple :value="[0]">
          <v-expansion-panel v-for="(item, index) in groupedByDay" :key="index">
            <v-expansion-panel-header class="px-4">
              <template v-slot:default>
                <v-row no-gutters>
                  <v-col cols="4" md="8">
                    {{ index }}
                  </v-col>
                  <v-col cols="4" md="2" class="text-right pr-1">
                  <span class="green--text d-inline-block" v-if="incomeByGroup(groupedByDay[index])">
                    +{{incomeByGroup(groupedByDay[index]) | currency}}
                  </span>
                  </v-col>
                  <v-col cols="4" md="2" class="text-left pl-1">
                  <span class="red--text d-inline-block" v-if="expensesByGroup(groupedByDay[index])">
                    {{expensesByGroup(groupedByDay[index]) | currency}}
                  </span>
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="no-padding">
              <HistoryEntry
                  v-for="item in groupedByDay[index]"
                  :key="item.id"
                  :item="item"
                  :class="highlightClass(item.eventId)"
                  @openEventDialog="openEventDialog"
                  @filterById="filterById"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <empty-box v-else image="assets/svg/history.svg" :text="$t('label.no_actions_yet')"/>
    </template>
    <v-card-text>
      <span v-if="student.oldSystemBalance" class="text-caption text--primary mt-5">
        {{ $t(`label.transferred_credit`) }} {{ student.oldSystemBalance | currency }}
      </span>
    </v-card-text>
    <BriefEventInfoDialog ref="eventInfoDialog" />
  </v-card>
</template>

<script>
import studentsService from "@/services/studentsService";
import StudentHistoryModel from "@/store/models/StudentHistoryModel";
import BriefEventInfoDialog from "@/components/event/BriefEventInfoDialog";
import i18n from "@/plugins/i18n";
import HistoryEntry from "@/components/HistoryEntry";
import clipboard from "@/utils/mixins/clipboard";
import laravelEcho from "@/plugins/laravel-echo";

export default {
  name: "StudentHistory",
  components: {
    HistoryEntry,
    BriefEventInfoDialog,
  },
  mixins: [clipboard],
  data() {
    return {
      history: {},
      loading: true,
      filters: [
        { text: i18n.t("form.by_online_payment"), value: "by_online_payment" },
        { text: i18n.t("form.by_manual_funds"), value: "by_manual_funds" },
        { text: i18n.t("form.by_events"), value: "by_events" },
        { text: i18n.t("form.by_special_products"), value: "by_special_products" },
        { text: i18n.t("form.by_basic_amount"), value: "by_basic_amount" },
        { text: i18n.t("form.by_learning_materials"), value: "by_learning_materials" },
      ],
      pickedFilter: null,
      filterBy: null,
      hoveredEventId: null,
    };
  },
  props: {
    student: {
      type: Object,
      require: true,
    },
  },
  created() {
    studentsService
      .studentHistory(this.student.id)
      .then((resp) => {
        StudentHistoryModel.create({ data: resp.data });
      })
      .finally(() => (this.loading = false));
  },
  mounted() {
    laravelEcho.private(`student.${this.student.id}`).listen('StudentHistoryEntryCreated', history => {
      StudentHistoryModel.insert({ data: history })
    })
  },
  beforeDestroy() {
    laravelEcho.leave(`student.${this.student.id}`)
  },
  computed: {
    allHistoryRecords() {
      let query = StudentHistoryModel.query().with('license');

      if (this.pickedFilter)
        query = query.where((item) => item.historyType === this.pickedFilter);

      if (this.filterBy)
        query = query.where((item) => item.eventId === this.filterBy);

      return query
          .orderBy("createdAt", "desc")
          .orderBy('id', 'desc')
          .get();
    },
    groupedByDay() {
      return this.allHistoryRecords.reduce((groups, historyEntry) => {
        if (!groups[historyEntry.formattedDate]) {
          groups[historyEntry.formattedDate] = [];
        }
        groups[historyEntry.formattedDate].push(historyEntry);
        return groups;
      }, {});
    },
  },
  methods: {
    openEventDialog(eventId) {
      this.$refs.eventInfoDialog.open(eventId);
    },
    filterById(eventId) {
      this.filterBy = this.filterBy === eventId ? null : eventId;
    },
    valuesByGroup() {
      const values = {
        income: 0,
        expenses: 0,
      };
      // values.income = group.filter(item => item.moneyAmount > 0).map(item => item.moneyAmount).sum()
      return values;
    },
    incomeByGroup(group) {
      return group
        .filter((item) => item.moneyAmount > 0)
        .map((item) => parseFloat(item.moneyAmount))
        .reduce((acc, a) => acc + a, 0);
    },
    expensesByGroup(group) {
      return group
        .filter((item) => item.moneyAmount < 0)
        .map((item) => parseFloat(item.moneyAmount))
        .reduce((acc, a) => acc + a, 0);
    },
    highlightByEventId(eventId) {
      this.hoveredEventId = eventId;
    },
    stopHighlighting() {
      this.hoveredEventId = null;
    },
    highlightClass(eventId) {
      if (this.hoveredEventId === null || this.filterBy !== null) return;
      return eventId === this.hoveredEventId ? "highlighted" : "dimmed";
    },
  },
};
</script>

<style lang="scss" scoped>
#studentHistory {
  //height: calc(100vh - 300px);
  //overflow-y: auto;
}
.label {
  background: #eee;
  padding: 5px 0;
  margin-bottom: 10px;
}
.highlighted {
  background: #eee;
  transition: ease-in 0.1s;
}

.dimmed {
  opacity: 0.5;
  transition: ease-in 0.1s;
}
</style>
