<template>
  <div class="mt-1">
    <!--    <div class="d-flex flex-column">-->
    <!--      <div>-->
    <!--        <v-btn class="mr-2 mb-2" color="primary" outlined block @click="registerWith('facebook')" :disabled="registrationWithLoading">-->
    <!--          <v-icon class="mr-2">mdi-facebook</v-icon>Facebook-->
    <!--        </v-btn>-->
    <!--        <v-btn color="primary" outlined block @click="registerWith('google')" :disabled="registrationWithLoading">-->
    <!--          <v-icon class="mr-2">mdi-gmail</v-icon>Google-->
    <!--        </v-btn>-->
    <!--      </div>-->

    <!--      <span class="text-body-2 my-2 text-center">{{ $t('label.or') }}</span>-->
    <!--    </div>-->

    <v-row>
      <v-col cols="12" md="12" lg="12">
        <ValidationProvider v-slot="{ errors }" name="firstName">
          <v-text-field
            dense
            v-model="stepForm.firstName"
            :label="$t('form.first_name')"
            :placeholder="$t('form.first_name')"
            outlined
            :error-messages="errors"
            required />
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <ValidationProvider dense v-slot="{ errors }" name="lastName">
          <v-text-field
            dense
            v-model="stepForm.lastName"
            :label="$t('form.last_name')"
            :placeholder="$t('form.last_name')"
            outlined
            :error-messages="errors" />
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <ValidationProvider v-slot="{ errors }" name="email">
          <v-text-field
            prepend-inner-icon="mdi-email-outline"
            dense
            v-model="stepForm.email"
            :label="$t('form.email')"
            :placeholder="$t('form.email')"
            outlined
            type="email"
            required
            :error-messages="errors"
            inputmode="email" />
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <ValidationProvider v-slot="{ errors }" name="password">
          <v-text-field
            prepend-inner-icon="mdi-lock-outline"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="!showPassword ? 'password' : 'text'"
            outlined
            dense
            v-model="stepForm.password"
            :label="$t('form.password')"
            :placeholder="$t('form.password')"
            required
            :error-messages="errors"
            @click:append="showPassword = !showPassword"
            autocomplete="new-password" />
        </ValidationProvider>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" lg="12">
        <ValidationProvider v-slot="{ errors }" name="passwordConfirmation">
          <v-text-field
            prepend-inner-icon="mdi-lock-outline"
            type="password"
            outlined
            dense
            v-model="stepForm.passwordConfirmation"
            :label="$t('form.password_confirmation')"
            :placeholder="$t('form.password_confirmation')"
            required
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
    </v-row>

    <div class="mt-4">
      <v-btn
        class="mr-2"
        color="primary"
        @click="submit"
        :loading="stepFormLoading"
        :disabled="stepFormLoading || registrationWithLoading">
        {{ $t("btn.save") }}
      </v-btn>
      <v-btn text @click="$emit('changeStep', 2)">
        {{ $t("btn.back") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import authService from "@/services/authService";
import api from "@/utils/api";

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  name: "RegistrationStepProfile",
  data: () => ({
    showPassword: false,
    stepFormLoading: false,
    registrationWithLoading: false,
    stepForm: {
      registrationStep: "profile",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirmation: "",
    },
  }),
  methods: {
    submit() {
      this.$emit("beforeSubmit");

      const form = { ...this.form, ...this.stepForm };

      this.stepFormLoading = true;
      authService
        .registration(form)
        .then((response) => {
          this.$store
            .dispatch("auth/setAccessToken", response.data.access_token)
            .then(() => {
              this.$router.push({ name: "dashboard" });
            });
        })
        .catch((error) => {
          if (!error.response) {
            console.log(error);

            return false;
          }

          const responseData = error.response.data;
          const errors = responseData.errors || [];

          this.$emit("submitted", responseData.step, {
            form: this.form,
            responseMessage: responseData.message,
            errors: { ...errors },
            passed: false,
          });
        })
        .finally(() => {
          this.stepFormLoading = false;
        });
    },
    registerWith(provider) {
      this.registrationWithLoading = true;
      this.$auth
        .authenticate(provider)
        .then((response) => {
          this.socialRegister(provider, response);
        })
        .catch((error) => console.log(error));
    },
    socialRegister(provider, response) {
      return api
        .post("/registration/" + provider, { ...response, ...this.form })
        .then((response) => {
          this.$store
            .dispatch("auth/setAccessToken", response.data.access_token)
            .then(() => {
              this.$router.push({ name: "dashboard" });
            });
        })
        .catch((error) => {
          if (!error.response) {
            console.log(error);

            return false;
          }

          const responseData = error.response.data;

          this.$snackbar.show({
            message: responseData.message,
            color: "error",
            timeout: 6000,
          });
        })
        .finally(() => {
          this.registrationWithLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
