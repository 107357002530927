<template>
  <v-sheet
    class="pa-3 mt-2 d-flex align-center justify-space-between"
    color="white"
    elevation="1"
    width="100%"
    rounded>
    <div class="d-flex align-center">
      <v-icon class="mr-3" size="30" color="primary" :title="$t('noItems')"
        >$jokerHat</v-icon
      >
      <h5>{{ $t("label.joker_status") }}</h5>
    </div>
    <div>
      <v-icon
        class="mr-3"
        v-if="!student.joker"
        @click="reactivateJoker"
        :disabled="student.isDeleted"
        >mdi-refresh</v-icon
      >
      <v-icon :disabled="student.isDeleted">{{
        student.joker
          ? "mdi-checkbox-marked-outline"
          : "mdi-checkbox-blank-outline"
      }}</v-icon>
    </div>
  </v-sheet>
</template>

<script>
import studentsService from "@/services/studentsService";
import StudentModel from "@/store/models/StudentModel";
import {showConfirm} from "@/helpers/dialogs";

export default {
  props: {
    studentId: {
      type: Number,
      require: true,
    },
  },
  name: "JokerWidget",
  computed: {
    student() {
      return StudentModel.find(this.studentId) || {};
    },
  },
  methods: {
    reactivateJoker() {
      if (this.student.joker) return;
      showConfirm(
          this.$i18n.t("alert.are_you_sure"),
          this.$i18n.t("messages.are_your_sure_joker"),
          () => {
            studentsService.reactivateJoker(this.student.id).then(() =>
                StudentModel.update({
                  where: this.studentId,
                  data: { joker: true },
                })
            );
          }
      );
    },
  },
};
</script>

<style scoped></style>
