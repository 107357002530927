<template>
  <div class="patch-note-item">
    <h3>• {{ item.caption }}</h3>
    <v-chip label small :color="chipColor" dark class="mb-2 mt-1">{{ $t(`label.patch_note_item.${item.type}`) }}</v-chip>
    <AttachmentPreview v-if="item.attachment" :url="item.attachment" :mime="item.attachmentMime" />
    <div v-html="htmlFromMarkdown(item.text)"></div>
  </div>
</template>

<script>
import AttachmentPreview from "@/components/attachment-viewer/AttachmentPreview";
import markdownParser from "@/utils/mixins/markdownParser";

export default {
  name: "PatchNoteItem",
  components: {AttachmentPreview},
  mixins: [markdownParser],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    chipColor () {
      const typeColor = {
        bugfix: 'red',
        new_feature: 'green',
        feature_update: 'blue'
      }

      return typeColor[this.item.type]
    }
  }
}
</script>

<style scoped>

</style>