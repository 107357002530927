import { render, staticRenderFns } from "./Days.vue?vue&type=template&id=54e2527b&scoped=true"
import script from "./Days.vue?vue&type=script&lang=js"
export * from "./Days.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54e2527b",
  null
  
)

export default component.exports