import { parseISO, startOfWeek, endOfWeek } from "date-fns";
import EventModel from "@/store/models/EventModel";
import i18n from "@/plugins/i18n";
import user from "@/utils/mixins/user";

export default {
  mixins: [user],
  data() {
    return {
      weeklyLessonsAmountLimitReached: false,
      dailyLessonsAmountReached: false,
      dailyLessonsDurationReached: false,
    };
  },
  methods: {
    checkStudentLimits(date, duration, amount, eventId = null) {
      this.eventErrorAlertToggle({ status: false, message: "" });
      if (this.currentUser.maxLessonsAmountPerWeek !== null)
        this.studentWeeklyLimit(date, amount, eventId);
      if (this.currentUser.maxLessonsDurationPerDay !== null)
        this.studentDailyLimits(date, duration, amount, eventId);
      if (this.weeklyLessonsAmountLimitReached)
        this.eventErrorAlertToggle({
          status: true,
          message: i18n.t("alert.weekly_lessons_amount_limit_reached"),
        });
      if (this.dailyLessonsAmountReached)
        this.eventErrorAlertToggle({
          status: true,
          message: i18n.t("alert.daily_lessons_amount_limit_reached", {
            lessons: this.currentUser.maxLessonsAmountPerDay,
          }),
        });
      if (this.dailyLessonsDurationReached)
        this.eventErrorAlertToggle({
          status: true,
          message: i18n.t("alert.daily_lessons_duration_limit_reached", {
            minutes: this.currentUser.maxLessonsDurationPerDay,
          }),
        });
    },

    studentWeeklyLimit(date, amount, eventId) {
      const weekStart = startOfWeek(parseISO(date), { weekStartsOn: 1 });
      const weekEnd = endOfWeek(parseISO(date), { weekStartsOn: 1 });
      let thisWeekEventsQuery = EventModel.query()
        .where(
          "startDate",
          (date) => parseISO(date) >= weekStart && parseISO(date) <= weekEnd
        )
        .where(
          (event) =>
            (event.eventType === "PRACTICE" &&
              event.studentsIds.includes(this.currentUser.id)) ||
            (event.eventType === "PLACEHOLDER" &&
              event.requests.some(
                (req) => req.studentId === this.currentUser.id
              ))
        );
      if (eventId)
        thisWeekEventsQuery = thisWeekEventsQuery.where(
          (event) => event.id !== eventId
        );
      const thisWeekLessonsAmount =
        thisWeekEventsQuery.sum("lessonsAmount") || 0;
      this.weeklyLessonsAmountLimitReached =
        thisWeekLessonsAmount + amount >
        this.currentUser.maxLessonsAmountPerWeek;
    },

    studentDailyLimits(date, duration, amount, eventId = null) {
      let thisDayEventsQuery = EventModel.query()
        .where("startDate", date)
        .where(
          (event) =>
            (event.eventType === "PRACTICE" &&
              event.studentsIds.includes(this.currentUser.id)) ||
            (event.eventType === "PLACEHOLDER" &&
              event.requests.some(
                (req) => req.studentId === this.currentUser.id
              ))
        );
      if (eventId)
        thisDayEventsQuery = thisDayEventsQuery.where(
          (event) => event.id !== eventId
        );
      const thisDayPracticesTotalDuration =
        thisDayEventsQuery.sum("duration") || 0;
      const thisDayEventsAmount = thisDayEventsQuery.sum("lessonsAmount") || 0;
      this.dailyLessonsDurationReached =
        thisDayPracticesTotalDuration + duration >
        this.currentUser.maxLessonsDurationPerDay;
      this.dailyLessonsAmountReached =
        thisDayEventsAmount + amount > this.currentUser.maxLessonsAmountPerDay;
    },
  },
};
