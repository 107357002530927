<template>
  <v-skeleton-loader type="paragraph" />
</template>

<script>
export default {
  name: "PatchNotesLoader"
}
</script>

<style scoped>

</style>