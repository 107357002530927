<template>
  <v-card flat class="mt-2">
    <v-card-title>
      <v-skeleton-loader type="text@1" width="90%" />
    </v-card-title>
    <v-card-subtitle>
      <v-skeleton-loader type="text@1" max-width="300px" />
    </v-card-subtitle>
    <v-card-text>
      <v-row
        v-for="message in data"
        :no-gutters="$vuetify.breakpoint.smAndDown"
        :key="message.id"
        class="mt-2"
        :class="{ 'flex-row-reverse': message.type === 'response' }">
        <v-col
          cols="12"
          md="2"
          :class="{ 'text-right': message.type === 'reply' }"
          class="flex-row flex-md-column">
          <div class="text-body-1">
            <v-skeleton-loader type="text@1" width="90%" />
          </div>
          <div class="text-body-2">
            <v-skeleton-loader type="text@1" width="70%" />
          </div>
        </v-col>
        <v-col cols="12" md="10">
          <v-sheet
            elevation="1"
            class="pa-4"
            :color="
              message.type === 'response' ? 'grey lighten-4' : 'grey lighten-5'
            "
            rounded>
            <div class="text-body-1 preline">
              <v-skeleton-loader type="paragraph" />
            </div>
          </v-sheet>
          <div
            :class="{ 'text-right': message.type === 'reply' }"
            class="text--disabled">
            <v-skeleton-loader type="text@1" class="mt-1" width="100px" />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SupportTicketContentLoader",
  data() {
    return {
      data: [
        {
          id: 1,
          type: "reply",
        },
        {
          id: 2,
          type: "response",
        },
        {
          id: 3,
          type: "reply",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss"></style>
