import api from "@/utils/api";
import Teacher from "../../helpers/teacher";
import * as mutationTypes from "./../mutation-types/teachers";

const state = () => ({
  byId: {},
  allIds: [],
  activeIds: JSON.parse(localStorage.getItem("activeTeachers")) || [],
});

const getters = {
  find: (state) => (id) => state.byId[id],
  list: (state, getters) =>
    state.allIds.map((id) => new Teacher(getters.find(id), getters.events(id))),
  events: (state, getters, rootState, rootGetters) => (id) => {
    return rootGetters["events/byTeacher"](id);
  },
  active: (state, getters) => {
    return getters.list.filter((item) => state.activeIds.includes(item.id));
  },
  activeIds: (state) => state.activeIds,
};

const actions = {
  load: ({ commit }) => {
    return new Promise((resolve, reject) => {
      api
        .get("/teachers")
        .then((resp) => {
          commit(mutationTypes.SET, resp.data);
          resolve();
        })
        .catch(reject);
    });
  },
  set: ({ commit }, data) => {
    commit(mutationTypes.SET, data);
  },
  enable: ({ commit }, id) => {
    commit(mutationTypes.ENABLE, id);
  },
  disable: ({ commit }, id) => {
    commit(mutationTypes.DISABLE, id);
  },
};

const mutations = {
  [mutationTypes.SET]: (state, items) => {
    state.activeIds = items;
    localStorage.setItem("activeTeachers", JSON.stringify(state.activeIds));
  },
  [mutationTypes.ENABLE]: (state, id) => {
    if (!state.activeIds.includes(id)) {
      state.activeIds.push(id);
    }
    localStorage.setItem(
      "activeTeachers",
      JSON.stringify(state.activeIds.sort())
    );
  },
  [mutationTypes.DISABLE]: (state, id) => {
    if (state.activeIds.includes(id)) {
      const index = state.activeIds.indexOf(id);
      state.activeIds.splice(index, 1);
    }
    localStorage.setItem(
      "activeTeachers",
      JSON.stringify(state.activeIds.sort())
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
