import EventTypeModel from "@/store/models/EventTypeModel";
import LessonTypeModel from "@/store/models/LessonTypeModel";
import Color from "color";

export default {
  computed: {
    eventColor() {
      if (this.event.type === "PLACEHOLDER" && this.event.isRequested) {
        const placeholderColors = EventTypeModel.find(this.event.type) || {};
        const color = Color(placeholderColors.bodyColor);
        return {
          fontColor: placeholderColors.fontColor,
          bodyColor: color.lighten(0.5).hex(),
        };
      }
      if ( ['PRACTICE', 'THEORY', 'SIMULATOR'].includes(this.event.type)) {
        if (['REQUESTED', 'DECLINED'].includes(this.event.status)) {
          return LessonTypeModel.query().where("name", "lesson_type.normal").first() || {};
        }
        const type = LessonTypeModel.find(this.event.lessonTypeId) || {};
        if (this.isStudent && type.name === "lesson_type.gratis_exam") {
          return LessonTypeModel.query().where("name", "lesson_type.exam").first() || {};
        }
        return type;
      } else {
        return EventTypeModel.find(this.event.type) || {};
      }
    },
    appointmentColor() {
      if (
        ['PRACTICE', 'THEORY', 'SIMULATOR'].includes(this.currentAppointment.type) &&
        this.currentAppointment.lessonTypeId !== null
      ) {
        const lessonType = LessonTypeModel.find(this.currentAppointment.lessonTypeId) || {}
        if (this.isStudent && lessonType.name === "lesson_type.gratis_exam") {  // gratis exam case for student
          return LessonTypeModel.query().where("name", "lesson_type.exam").first()
        }
        return lessonType
      } else {
        return EventTypeModel.find(this.currentAppointment.type) || {}
      }
    },
  },
  methods: {
    invertColor(hex) {
      if (hex && hex?.indexOf("#") === 0) {
        hex = hex.slice(1);
      }
      // convert 3-digit hex to 6-digits.
      if (hex && hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex && hex.length !== 6) {
        throw new Error("Invalid HEX color.");
      }

      const r = parseInt(hex?.slice(0, 2), 16);
      const g = parseInt(hex?.slice(2, 4), 16);
      const b = parseInt(hex?.slice(4, 6), 16);

      // http://stackoverflow.com/a/3943023/112731
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
    },
  },
};
