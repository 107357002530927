<template>
  <div :class="classes" class="history-entry">
    <div class="d-flex">
      <v-icon left size="30" :color="item.moneyAmount > 0 ? 'green' : ''">
        {{ item.icon }}
      </v-icon>

      <div class="flex-grow-1">
        <v-row no-gutters justify="space-between">
          <v-col cols="3">
            <span class="grey--text text--lighten-1">{{ item.time }}</span>
          </v-col>

          <v-col class="text-right" cols="9">
            <span :class="{ 'green--text font-weight-bold': item.moneyAmount > 0 }">
              <span v-if="item.moneyAmount > 0">+</span>
              {{ signedValue | currency }}
              <v-tooltip top v-if="item.balanceAfter">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                </template>
                <span>Guthaben nach dieser Transaktion: {{ item.balanceAfter | currency }}</span>
              </v-tooltip>
            </span>
          </v-col>
        </v-row>
        <div class="d-flex justify-space-between flex-column flex-sm-row">
          <span class="d-flex align-center">
            <span class="d-block"></span>
            <span class="mr-1">{{ $t(`history_action.${item.action}`) }}</span>
            <span v-if="item.license">({{ item.license.name }})</span>
          </span>

          <v-chip-group v-if="item.eventId">
            <v-chip
              label
              dark
              color="primary"
              v-if="item.eventId"
              class="ml-1"
              small
              @click="$emit('filterById', item.eventId)"
            >
              #{{ item.eventId }}
            </v-chip>

            <v-chip
              v-if="item.eventId"
              color="primary"
              small
              label
              @click="$emit('openEventDialog', item.eventId)"
            >
              {{ $t("btn.view_event") }}
            </v-chip>
          </v-chip-group>
        </div>

        <span>{{ item.note || learningMaterialProduct }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import LearningMaterialModel from "@/store/models/LearningMaterialModel";
import LicenseModel from "@/store/models/LicenseModel";

export default {
  name: "HistoryEntry",
  props: {
    item: {
      type: Object,
      required: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      const classes = [];
      if (!this.dense) classes.push("my-3", "pa-1");
      return classes;
    },
    signedValue() {
      return this.item.formattedAmount > 0
        ? `+${this.item.formattedAmount}`
        : this.item.formattedAmount * -1;
    },
    learningMaterialProduct() {
      if (this.item.action === "learning_material_bought") {
        const product = LearningMaterialModel.find(this.item.productId) || {};
        const license = LicenseModel.find(product.licenseId) || {};
        return `${license.name}: ${product.name}`;
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
.history-entry {
  border-radius: 5px;

  &:hover {
    background-color: #eeeeee;
  }
}
</style>
