<template>
  <v-card :color="color" dark :loading="loading" class="card">
    <v-card-subtitle class="my-0 pb-0">{{ title }}</v-card-subtitle>
    <v-card-title class="my-0 pt-0" v-if="loading">{{$t("loading") }}</v-card-title>
    <v-card-title class="my-0 pt-0" v-else><slot></slot></v-card-title>
    <v-icon v-if="icon && !loading && type === 'icon'" x-large class="icon">{{icon}}</v-icon>
    <v-img
      v-if="icon && !loading && type === 'image'"
      aspect-ratio="1:1"
      style="border-radius: 0"
      width="45"
      class="icon"
      :src="icon"
    />
  </v-card>
</template>

<script>
export default {
  name: "TwoRowsIconCard",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "secondary",
    },
    icon: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "icon",
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  position: relative;
  .icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}
</style>
