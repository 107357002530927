import api from "@/utils/api";

export default {
  registration: (params) => {
    return new Promise((resolve, reject) => {
      api
        .post("/registration", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  sendResetPasswordLink: (params) => {
    return new Promise((resolve, reject) => {
      api
        .post("password/send-reset-link", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  resetPassword: (params) => {
    return new Promise((resolve, reject) => {
      api
        .post("password", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  elterRegistration: (params) => {
    return new Promise((resolve, reject) => {
      api
        .post("/elter-registration", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
