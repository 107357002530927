<template>
  <ValidationProvider
    name="lessons-amount"
    rules="required"
    v-slot="{ errors }">
    <v-text-field
      class="lesson-amount"
      outlined
      readonly
      dense
      hide-details
      :label="$t('form.lessons_amount')"
      :error="errors.length > 0"
      :disabled="disabled"
      v-model="lessonsAmount">
      <v-icon
        @click="$emit('emitDecreaseAmount')"
        :disabled="disabled"
        slot="prepend-inner"
        color="red"
        >mdi-minus</v-icon
      >
      <v-icon
        @click="$emit('emitIncreaseAmount')"
        :disabled="disabled"
        slot="append"
        color="green"
        >mdi-plus</v-icon
      >
    </v-text-field>
  </ValidationProvider>
</template>

<script>
export default {
  name: "LessonsAmountFields",
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    empty: {
      type: Boolean,
      default: false,
    },
    lessonsAmountProp: {
      type: Number,
    },
  },
  computed: {
    lessonsAmount() {
      if (this.empty) return null
      return this.lessonsAmountProp;
    },
  },
};
</script>

<style lang="scss">
.lesson-amount input {
  text-align: center;
  cursor: default;
}
</style>
