import * as mutationTypes from "./../mutation-types/vehiclesCalendar"
import { endOfDay, format } from 'date-fns'

import appointmentService from '@/services/appointmentService'
import vehiclesService from '@/services/vehiclesService'
import AppointmentModel from '@/store/models/AppointmentModel'
import VehicleModel from '@/store/models/VehicleModel'

const defaultState = () => ({
  shorthands: {
    'lesson_type.normal': 'ÜB',
    'lesson_type.overland': 'ÜL',
    'lesson_type.highway': 'AB',
    'lesson_type.night': 'NF',
    'lesson_type.mtl_exam': 'ÜS',
    'lesson_type.instruction': 'UW',
    'lesson_type.exam': 'PR',
    'lesson_type.repeated_exam': 'WPR',
    'lesson_type.gratis_exam': 'PR',
  },
  date: new Date(),
  hours: [...Array(24).keys()],
  hourSize: parseInt(localStorage.getItem('hourCellSize')) || 100,
  gridSize: 0,
  activeVehiclesIds: [],
  vehicleOrder: 'asc',
  leftSidebar: 300,
  rightSidebar: 200,
  showAppointmentError: false,
  appointmentErrorMessage: '',
  initialDataLoader: true,
  appointmentsLoader: false,
  hoveredAppointmentId: null,
  draggableAppointment: {},
  validVehiclesIds: [],
  disableAppointment: false,
  draggingProcess: false
});

const state = () => defaultState();

const getters = {
  minWidth: (state) => state.hours.length * state.hourSize,
  oneMinuteSize: (state) => state.gridSize / state.hours.length / 60,
  dateAsLabel: (state) => format(state.date, "dd.MM.yyyy"),
  formattedDate: (state) => format(state.date, "yyyy-MM-dd"),
  appointments: (state, getters) => {
    return AppointmentModel.query()
      .where((app) => app.startDate === format(state.date, "yyyy-MM-dd"))
      .where((app) => app.group !== 'planned_exam')
      .where((app) => app.status === 'ACCEPTED')
      .where((app) => {
        const vehiclesIds = getters.allVehicles.map((item) => item.id)
        return !app.primaryVehicleId || vehiclesIds.includes(app.primaryVehicleId)
      })
      .orderBy('start', 'asc')
      .get() || []
  },
  activePdfButton: (state, getters) => getters.appointments.some((app) => app.primaryVehicleId),
  allVehicles: () => {
    return VehicleModel.query()
      .where((item) => !item.isDeleted)
      .where((item) => item.licensesIds.length > 0)
      .get() || []
  },
};

const actions = {
  increaseCellSize: ({ commit }) => {
    commit(mutationTypes.INCREASE_CELL_SIZE)
  },
  decreaseCellSize: ({ commit }) => {
    commit(mutationTypes.DECREASE_CELL_SIZE)
  },
  toggleLeftSidebar: ({ commit }) => {
    commit(mutationTypes.TOGGLE_LEFT_SIDEBAR)
  },
  toggleRightSidebar: ({ commit }) => {
    commit(mutationTypes.TOGGLE_RIGHT_SIDEBAR)
  },
  setDate: ({ commit, dispatch }, value)  => {
    commit(mutationTypes.SET_DATE, value)
    dispatch("fetchAppointments")
    dispatch('vehiclesReservation/fetchVehiclesReservations', value, {root:true})
    dispatch('vehiclesReservation/fetchVehiclesServices', value, {root:true})
  },
  fetchAppointments: ({ commit, state })  => {
    commit(mutationTypes.SET_APPOINTMENTS_LOADER, true)
    const params = {
      from: format(state.date, "yyyy-MM-dd"),
      to: format(endOfDay(state.date), "yyyy-MM-dd"),
      type: ['PRACTICE']
    }
    return appointmentService
      .load(params)
      .then((resp) => AppointmentModel.create({ data: resp.data }))
      .catch((error) => console.log(error))
      .finally(() => commit(mutationTypes.SET_APPOINTMENTS_LOADER, false))
  },
  fetchVehicles: ()  => {
    return vehiclesService
      .load()
      .then((resp) => VehicleModel.create({ data: resp.data }))
      .catch((error) => console.log(error))
  },
  startAppointmentDragging: ({ commit }, { appointment, updateOrDelete })  => {
    if (updateOrDelete) commit(mutationTypes.DRAGGING_PROCESS, true)
    commit(mutationTypes.SET_DRAGGABLE_APPOINTMENT, appointment)
    const { teacherId, licenseId, primaryVehicleId, secondaryVehicleId } = appointment
    const query = VehicleModel.query()
      .where((vehicle) => !vehicle.isDeleted)
      .where("teachersIds", (teachersIds) => teachersIds.includes(teacherId))
      .where("licensesIds", (licensesIds) => licensesIds.includes(licenseId))
    if (updateOrDelete) {
      query.where((vehicle) => ![primaryVehicleId, secondaryVehicleId].includes(vehicle.id))
    }
    const getQuery = query.get() || []
    const validVehicles = getQuery.map((vehicle) => vehicle.id)
    commit(mutationTypes.SET_VALID_VEHICLES_FOR_ASSIGNMENT, validVehicles)
  },
  stopAppointmentDragging: ({ commit }) => {
    commit(mutationTypes.SET_VALID_VEHICLES_FOR_ASSIGNMENT, [])
    commit(mutationTypes.DRAGGING_PROCESS, false)
  },
  stopTouch: ({ commit }) => {
    commit(mutationTypes.SET_VALID_VEHICLES_FOR_ASSIGNMENT, [])
    commit(mutationTypes.DRAGGING_PROCESS, false)
    commit(mutationTypes.SET_DRAGGABLE_APPOINTMENT, {})
    commit(mutationTypes.DISABLE_APPOINTMENT, false)
    commit(mutationTypes.HOVER_APPOINTMENT, null)
  },
  appointmentDrop: ({ state, commit, dispatch }, vehicleId) => {
    if (vehicleId && !state.validVehiclesIds.includes(vehicleId)) return
    const prevData = { ...state.draggableAppointment }
    dispatch("stopAppointmentDragging")
    commit(mutationTypes.DISABLE_APPOINTMENT, true)
    AppointmentModel.update({
      where: state.draggableAppointment.id,
      data: {
        primaryVehicleId: vehicleId,
        secondaryVehicleId: vehicleId ? prevData.secondaryVehicleId : null
      } || {}
    })
    const params = vehicleId
      ? { primaryVehicleId: vehicleId }
      : { primaryVehicleId: null, secondaryVehicleId: null }
    appointmentService
      .update({ ...state.draggableAppointment, ...params }, 'PRACTICE')
      .catch((error) => {
        state.showAppointmentError = true
        state.appointmentErrorMessage = error.response.data.message

        AppointmentModel.update({
          where: state.draggableAppointment.id,
          data: {
            primaryVehicleId: prevData.primaryVehicleId,
            secondaryVehicleId: prevData.secondaryVehicleId
          } || {}
        })
      })
      .finally(() => {
        commit(mutationTypes.SET_DRAGGABLE_APPOINTMENT, {})
        commit(mutationTypes.DISABLE_APPOINTMENT, false)
        commit(mutationTypes.HOVER_APPOINTMENT, null)
      })
  },
};

const mutations = {
  [mutationTypes.RESET_STATE]: (state) => {
    Object.assign(state, defaultState())
  },

  [mutationTypes.HIDE_CALENDAR_ERRORS]: (state) => {
    state.showAppointmentError = false
    state.appointmentErrorMessage = ''
  },

  [mutationTypes.DRAGGING_PROCESS]: (state, value) => {
    state.draggingProcess = value
  },

  [mutationTypes.HOVER_APPOINTMENT]: (state, value) => {
    state.hoveredAppointmentId = value
  },
  [mutationTypes.DISABLE_APPOINTMENT]: (state, value) => {
    state.disableAppointment = value
  },
  [mutationTypes.SET_DRAGGABLE_APPOINTMENT]: (state, value) => {
    state.draggableAppointment = value
  },
  [mutationTypes.SET_VALID_VEHICLES_FOR_ASSIGNMENT]: (state, value) => {
    state.validVehiclesIds = value
  },
  [mutationTypes.TOGGLE_LEFT_SIDEBAR]: (state) => {
    state.leftSidebar = state.leftSidebar === 300 ? 60 : 300
  },
  [mutationTypes.TOGGLE_RIGHT_SIDEBAR]: (state) => {
    state.rightSidebar = state.rightSidebar === 200 ? 0 : 200
  },
  [mutationTypes.SET_INITIAL_LOADER]: (state, value) => {
    state.initialDataLoader = value
  },
  [mutationTypes.SET_APPOINTMENTS_LOADER]: (state, value) => {
    state.appointmentsLoader = value
  },
  [mutationTypes.SET_DATE]: (state, value) => {
    state.date = value
  },
  [mutationTypes.SET_GRID_SIZE]: (state, value) => {
    state.gridSize = value
  },
  [mutationTypes.ON_RESIZE_HANDLER]: (state, { clientWidth, minWidth }) => {
    state.gridSize = clientWidth >= minWidth ? clientWidth : minWidth
  },
  [mutationTypes.INCREASE_CELL_SIZE]: (state) => {
    state.hourSize += 10
    localStorage.setItem('hourCellSize', state.hourSize)
  },
  [mutationTypes.DECREASE_CELL_SIZE]: (state) => {
    state.hourSize -= 10
    localStorage.setItem('hourCellSize', state.hourSize)
  },
  [mutationTypes.SET_ACTIVE_VEHICLES_IDS]: (state, value) => {
    state.activeVehiclesIds = value
  },
  [mutationTypes.SET_VEHICLES_ORDER]: (state) => {
    state.vehicleOrder = state.vehicleOrder === 'asc' ? 'desc' : 'asc'
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
