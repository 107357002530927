<template>
  <v-menu
      v-model="isOpenFilters"
      :close-on-content-click="false"
      offset-y
      right
      nudge-bottom="2"
      z-index="999"
      :content-class="external ? 'style-menu' : ''"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon text color="primary" v-bind="attrs" v-on="on" :disabled="disabled">
        <v-icon>mdi-filter-outline</v-icon>
      </v-btn>
    </template>

    <v-card class="menu">
      <v-card-title>{{ $t("label.filters") }}</v-card-title>
      <v-card-text style="max-height: 700px; overflow-y: auto">
        <v-treeview
            v-model="activeVehicles"
            :items="groupedVehicles"
            selected-color="primary"
            dense
            selectable
            open-all
        />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LicenseGroupModel from '@/store/models/LicenseGroupModel'
import LicenseModel from '@/store/models/LicenseModel'
import { SET_ACTIVE_VEHICLES_IDS } from '@/store/mutation-types/vehiclesCalendar'

export default {
  name: "VehiclesFiltersMenu",
  data: () => ({
    isOpenFilters: false,
  }),
  props: {
    external: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('vehiclesCalendar', ['activeVehiclesIds']),
    ...mapGetters('vehiclesCalendar', ['allVehicles']),
    activeVehicles: {
      set(val) {
        this.$store.commit(`vehiclesCalendar/${SET_ACTIVE_VEHICLES_IDS}`, val)
      },
      get() {
        return this.activeVehiclesIds
      }
    },
    vehiclesWithGroup() {
      return this.allVehicles?.map((item) => {
        return {
          ...item,
          group: LicenseModel.find(item.licensesIds[0]).groupId,
          name: item.make + ' ' + item.model
        }
      })
    },

    groupedVehicles() {
      const groups = LicenseGroupModel.all()
      const grouped = groups.map((groupItem) => {
        const children = this.vehiclesWithGroup.filter((item) =>  item.group === groupItem.id)
        return {
          ...groupItem,
          id: groupItem.id + 'i',
          children
        }
      })
      return grouped?.filter(item => item.children.length)
    },
  }
}
</script>

<style lang="scss" scoped>
.menu{
  width: 300px;
  left: 0;
}
.style-menu{
  left: 0 !important;
}
.list{
  max-height: 500px;
  overflow-y: auto
}
</style>
