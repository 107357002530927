<template>
  <v-edit-dialog
      large
      :return-value.sync="priceCategoryName"
      @save="updatePriceCategoryName"
      :cancel-text="$t('btn.cancel')"
      :save-text="$t('btn.save')"
  >
    <slot/>

    <template v-slot:input>
      <v-text-field
          class="mt-3"
          v-model="priceCategoryName"
          :label="$t('form.price_category_name')"
          :placeholder="$t('form.price_category_name')"
          outlined
          dense
          hide-details
          style="width: 400px"
      />
    </template>
  </v-edit-dialog>
</template>

<script>
import productsService from '@/services/productsService'
import ProductCategoryModel from '@/store/models/ProductCategoryModel'

export default {
  name: 'PriceCategoryNameEditDialog',
  data() {
    return {
      show: false,
      priceCategoryName: '',
      priceCategoryId: null
    }
  },
  methods: {
    open(priceCategoryId, priceCategoryName) {
      this.show = true
      this.priceCategoryId = priceCategoryId
      this.priceCategoryName = priceCategoryName
    },
    close() {
      this.show = false
      this.priceCategoryName = ''
      this.priceCategoryId = null
    },
    updatePriceCategoryName() {
      productsService
        .updatePriceCategory(this.selectedPriceCategory.id, { name: this.form.priceCategoryName })
        .then((response) => {
          ProductCategoryModel.update({ where: this.selectedPriceCategory.id, data: response.data });
        })
        .catch((error) => {
          const responseData = error.response.data;
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error", });
          }

          this.$refs.form.setErrors(errors)
        });
    }
  }
}
</script>

<style scoped>

</style>
