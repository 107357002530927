<template>
  <ValidationProvider
    name="title"
    :rules="`${validation ? 'required' : ''}`"
    v-slot="{ errors }">
    <v-combobox
      @update:search-input="onChange"
      :error="errors.length > 0"
      v-model="specialName"
      :disabled="disabled"
      :label="$t('form.title')"
      :items="itemsList"
      hide-details
      outlined
      dense
      :append-icon="itemsList ? 'mdi-menu-down' : ''" />
  </ValidationProvider>
</template>

<script>
export default {
  name: "SpecialNameField",
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    validation: {
      type: Boolean,
      default: false,
    },
    itemsList: {
      type: Array,
    },
    specialNameProp: {
      type: String,
    },
  },
  computed: {
    specialName: {
      set(value) {
        this.onChange(value);
      },
      get() {
        return this.specialNameProp;
      },
    },
  },
  methods: {
    onChange(value) {
      this.$emit("emitSpecialNameChange", value);
    },
  },
};
</script>
