<script>
import {defineComponent} from 'vue'
import passwordGenerator from "@/utils/mixins/passwordGenerator";
import clickAndLearnService from "@/services/clickAndLearnService";

export default defineComponent({
  name: "ChangePasswordDialog",
  mixins: [passwordGenerator],
  data: () => ({
    show: false,
    license: null,
    loading: false,
    form: {
      password: null
    }
  }),
  methods: {
    open(license) {
      this.show = true
      this.license = license
      this.form.password = this.generatePassword()
    },
    close() {
      this.show = false
      this.license = null
    },
    save() {
      this.$refs.form.reset()
      this.loading = true
      clickAndLearnService
        .updateStudentPassword(this.license.id, this.form)
        .catch((error) => {
          const responseData = error.response.data || {};
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error" });
          }

          this.$refs.form.setErrors(errors);
        })
        .then(response => {
          this.$emit('password-changed', response.data)
          this.close()
          this.$snackbar.show({ message: this.$t('clickandlearn.password_changed'), color: "success", timeout: 3000 })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
})
</script>

<template>
  <v-dialog width="500" :value="show" @click:outside="close" @keydown.esc="close">
    <v-card>
      <v-toolbar class="mb-4" dark color="primary" dense>
        <v-toolbar-title>{{ $t('clickandlearn.change_password') }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <ValidationObserver ref="form">
          <ValidationProvider v-slot="{ errors }" name="password">
            <v-text-field
              outlined
              :disabled="loading"
              :hide-details="! $refs.form.errors.password || ! $refs.form.errors.password.length"
              :error-messages="errors"
              v-model="form.password"
              :label="$t('form.password')"
              required
              dense
              append-icon="mdi-reload"
              @click:append="form.password = generatePassword()"
            />
          </ValidationProvider>
        </ValidationObserver>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn text :disabled="loading" @click="close">{{ $t('btn.close') }}</v-btn>
        <v-spacer />
        <v-btn color="primary" @click="save" :disabled="! form.password || loading" :loading="loading">
          <v-icon left>mdi-content-save</v-icon>
          {{ $t('btn.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>