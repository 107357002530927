<template>
  <v-container>
    <v-card :loading="loading">
      <v-toolbar elevation="3">
        <v-btn icon :to="{ name: 'payouts' }" class="ml-0" exact>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-card-title class="text-overflow-ellipsis">
          <v-skeleton-loader
            width="150"
            height="12"
            type="text@1"
            v-if="loading" />
          <span class="mr-2 text-overflow-ellipsis" v-else>
            {{ $t("label.payout") }}
            <v-chip class="text-monospace" label small>{{ payoutId | ellipsis }}</v-chip>
          </span>
        </v-card-title>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" lg="3">
            <TwoRowsIconCard
              :title="$t('label.status')"
              :color="loading ? 'secondary' : payout.statusColor"
              :loading="loading"
              :icon="payout.statusIcon"
            >
              <span v-if="!loading">{{ payout.statusText }}</span>
            </TwoRowsIconCard>
          </v-col>
          <v-col cols="12" md="4" lg="3">
            <TwoRowsIconCard
              :title="$t('label.amount')"
              :loading="loading"
              icon="mdi-currency-eur"
            >
              <span v-if="!loading">{{ payout.amount | currency }}</span>
            </TwoRowsIconCard>
          </v-col>
          <v-col cols="12" md="4" lg="3">
            <TwoRowsIconCard
              :title="$t('label.created')"
              :loading="loading"
              icon="mdi-calendar-clock"
            >
              <span v-if="!loading">{{ payout.createdDisplayDate }}</span>
            </TwoRowsIconCard>
          </v-col>
          <v-col cols="12" md="4" lg="3">
            <TwoRowsIconCard
              :title="$t('label.estArrival')"
              :loading="loading"
              icon="mdi-calendar-check">
              <span v-if="!loading">{{ payout.arrivedDisplayDate }}</span>
            </TwoRowsIconCard>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-title>
        {{ $t("label.summary") }}
      </v-card-title>
      <v-simple-table dense v-if="!loading">
        <thead>
          <tr>
            <th></th>
            <th style="width: 15%; text-align: right">
              {{ $t("label.count") }}
            </th>
            <th style="width: 15%; text-align: right">
              {{ $t("label.amount") }}
            </th>
            <th style="width: 15%; text-align: right">
              {{ $t("label.tax_amount") }}
            </th>
            <th style="width: 15%; text-align: right">
              {{ $t("label.net_amount") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ $t("label.payments") }}</td>
            <td style="text-align: right">
              {{ paymentTransactions.length }}
            </td>
            <td style="text-align: right">
              {{ paymentsTotalAmount | currency }}
            </td>
            <td style="text-align: right">
              {{ paymentsTotalFee | currency }}
            </td>
            <td style="text-align: right">
              {{ paymentsTotalNet | currency }}
            </td>
          </tr>
          <tr>
            <td>{{ $t("label.refunds") }}</td>
            <td style="text-align: right">
              {{ refundTransactions.length }}
            </td>
            <td style="text-align: right">
              {{ refundsTotalAmount | currency }}
            </td>
            <td style="text-align: right">{{ 0 | currency }}</td>
            <td style="text-align: right">
              {{ refundsTotalAmount | currency }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="4">{{ $t("label.payouts") }}</th>
            <th style="text-align: right">
              {{ payout.amount | currency }}
            </th>
          </tr>
        </tfoot>
      </v-simple-table>
      <v-card-text v-else>
        <v-skeleton-loader class="mx-auto" type="table-row@3" />
      </v-card-text>


      <v-card-title>
        {{ $t("label.transactions") }}
      </v-card-title>
        <v-card-text class="d-flex justify-end" v-if="!loading">
          <v-btn
            @click="download"
            :loading="generatingReport"
            :disabled="generatingReport"
            color="primary"
            elevation="0"
            class="mb-2"
          >
            <v-icon left>mdi-download</v-icon>
            {{ $t('btn.download') }}
          </v-btn>
        </v-card-text>
        <TransactionsTable
          :transactions="transactions"
          v-if="!loading" />
      <v-card-text  v-else>
        <v-skeleton-loader class="mx-auto" type="table-row@3" />
      </v-card-text>


        <v-card-title>
          {{ $t("label.payments_included_in_the_payout") }}
        </v-card-title>
        <PaymentsTable :payments="payments" fresh v-if="!loading" />
        <v-card-text v-else>
          <v-skeleton-loader class="mx-auto" type="table-row@3" />
        </v-card-text>

    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import payoutsService from "@/services/payoutsService";
import PayoutModel from "@/store/models/PayoutModel";
import PaymentsTable from "@/components/payments/PaymentsTable";
import TransactionsTable from "@/components/payments/TransactionsTable";
import TwoRowsIconCard from "@/components/widgets/TwoRowsIconCard";
import FileSaver from "@/helpers/fileSaver";

export default {
  name: "ViewPayout",
  components: { TwoRowsIconCard, TransactionsTable, PaymentsTable },
  data() {
    return {
      loading: true,
      payoutId: this.$route.params.id,
      payments: [],
      transactions: [],
      recordsLimit: 10,
      generatingReport: false,
    };
  },
  mounted() {
    payoutsService
      .loadItem(this.payoutId)
      .then((resp) => {
        const payout = resp.data.payout;
        this.payments = resp.data.payments;
        this.transactions = resp.data.transactions;
        PayoutModel.create({ data: [payout] || [] });
      })
      .catch(() => {
        this.$router.push({ name: "payouts" });
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    download() {
      this.generatingReport = true;
      payoutsService
        .downloadReport(this.payoutId)
        .then((resp) => {
          const responseData = [resp.data] || [];
          const fileSaver = new FileSaver(responseData)
          fileSaver.setType('application/pdf').saveToDevice(`Umsätze drivolino — ${this.payout.arrivedDisplayDate}.pdf`, 'Umsätze')
        })
        .finally(() => {
          this.generatingReport = false;
        });
    },
  },
  computed: {
    ...mapGetters("auth", { currentUser: "currentUser" }),
    payout() {
      return PayoutModel.query().find(this.payoutId);
    },
    totalAmount() {
      return this.payments.reduce((sum, current) => {
        return sum + current.amount;
      }, 0);
    },
    totalFee() {
      return this.payments.reduce((sum, current) => {
        return sum + current.fee;
      }, 0);
    },
    paymentTransactions() {
      return this.transactions.filter((item) => item.type === "payment");
    },
    refundTransactions() {
      return this.transactions.filter((item) => item.type === "payment_refund");
    },
    paymentsTotalAmount() {
      return this.paymentTransactions.reduce((prev, current) => {
        return prev + current.amount;
      }, 0);
    },
    paymentsTotalFee() {
      return this.paymentTransactions.reduce((prev, current) => {
        return prev + current.fee;
      }, 0);
    },
    paymentsTotalNet() {
      return this.paymentsTotalAmount - this.paymentsTotalFee;
    },
    refundsTotalAmount() {
      return this.refundTransactions.reduce((prev, current) => {
        return prev + current.amount;
      }, 0);
    },
  },
};
</script>

<style scoped>
.hidden-until-row-hovered {
  display: none;
}
.table-row:hover > td > .hidden-until-row-hovered {
  display: inline-flex;
}
</style>
