<script>
import EvaluationPreview from "@/components/insights/EvaluationPreview.vue";
import insightsService from "@/services/insightsService";
import DrivingAreasCombobox from "@/components/DrivingAreasCombobox.vue";
import user from '@/utils/mixins/user'
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: "EvaluationPreviewDialog",
  components: { DrivingAreasCombobox, EvaluationPreview },
  mixins: [user],
  data() {
    return {
      state: false,
      comment: '',
      drivingArea: "",
      saving: false,
      evaluation: null,
      evaluationId: null,
      appointmentId: null,
    }
  },
  methods: {
    open(evaluation) {
      this.evaluation = evaluation;
      this.evaluationId = evaluation.id;
      this.appointmentId = evaluation.appointmentId;
      this.comment = evaluation.comment || '';
      this.drivingArea = evaluation.drivingArea || "";
      this.state = true;
    },
    close() {
      this.state = false;
      setTimeout(() => {
        this.evaluation = null;
        this.evaluationId = null;
        this.appointmentId = null;
      }, 0);
    },
    handlePublish() {
      if (!this.isStudent && this.evaluation?.selfEvaluation) {
        showConfirm(
          this.$t('btn.confirm'),
          this.$t('insights.publish_evaluation_for_self_evaluation_confirm'),
          async () => this.publish());
      } else {
        this.publish()
      }
    },
    publish() {
      this.saving = true;
      insightsService.publishAppointmentEvaluation(
        this.appointmentId,
        { comment: this.comment, drivingArea: this.drivingArea }
      )
        .then(() => {
          this.saving = false
          this.$router.push({ name: 'calendar.appointment' })
          this.close()
        })
        .catch(error => {
          const response = error.response || {};
          const responseData = response.data || {};

          if (responseData.message) this.$snackbar.show({ message: responseData.message, color: "error", });
        })
        .finally(() => {
          this.saving = false;
        })
    }
  }
}
</script>

<template>
  <v-dialog
    v-model="state"
    persistent
    fullscreen
    scrollable
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card tile>
      <v-toolbar color="primary" dark class="flex-grow-0">
        <v-toolbar-title>
          {{ $t('insights.evaluation_preview') }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-5" v-if="evaluationId">
        <v-textarea v-if="!isStudent" v-model="comment" outlined :label="$t('insights.comment')" rows="3"/>
        <DrivingAreasCombobox v-if="!isStudent" :value="drivingArea" @input="value => drivingArea = value" />
        <EvaluationPreview :evaluation-id="evaluationId" />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="close">
          {{  $t('btn.close') }}
        </v-btn>
        <v-spacer/>
        <v-btn :loading="saving" :disabled="saving" color="primary" @click="handlePublish">
          <v-icon left>mdi-content-save</v-icon>
          {{ $t('insights.btn.publish_evaluation') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>
