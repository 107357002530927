<template>
  <div>
    <v-row>
      <v-col v-if="isSchoolAdministrator" :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
        <TeachersField
          @emitTeachersSelect="updateTeacherField"
          @emitRecalculation="recalculateAmount"
          :disabled="isMissed || hasDeletedStudent || isExamInPast || !!createdPlaceholderApp"
          :validation="true"
          :filteredTeachers="teachers"
          :teacherProp="currentAppointment.teacherId"
        />
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp && isSchoolAdministrator ? 6 : 12">
        <StudentsField
          @emitStudentChange="updateStudentField"
          :disabled="
              teacherIsNotSelected ||
              (!!currentAppointment.id && !createdPlaceholderApp) ||
              isMissed ||
              hasDeletedStudent ||
              isExamInPast"
          :validation="true"
          :multiple="false"
          :decorated="true"
          :studentsProp="currentAppointment.studentsIds"
          :studentsList="activeStudentsList"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <LicenseField
            ref="licenseField"
            @studentProgress = getStudentProgress
            @emitLicenseSelect="updateLicenseField"
            :disabled="studentIsNotSelected || isMissed || hasDeletedStudent || isExamInPast"
            :validation="true"
            :multiple="false"
            :showLicenseProgress="true"
            :licenseProp="currentAppointment.licenseId"
            :licenseIdsList="activeLicensesIds"
            :licenseStatus="practiceLicensesStatus"
            :label="$t('form.licence')"
        />

        <ManualLessonCheckboxField
            v-if="showIsManualLesson"
            @emitManualStatusChange="(val) => updateFields({ isManualLesson: val })"
            :disabled="isMissed || hasDeletedStudent || isExamInPast"
            label="Schalterstunde"
            :statusProp="this.currentAppointment.isManualLesson"
        />
      </v-col>
      <v-col cols="6">
        <LessonTypesField
          @emitLessonTypeSelect="updateLessonTypeField"
          :disabled="studentIsNotSelected ||
                    !currentAppointment.licenseId ||
                    isMissed ||
                    hasDeletedStudent ||
                    isExamInPast"
          :validation="true"
          :createdPlaceholderApp="!!createdPlaceholderApp"
          :lessonTypeProp="currentAppointment.lessonTypeId"
          :lessonTypesList="lessonTypesList"
        />
      </v-col>
    </v-row>

    <v-row v-if="licenseProgressData" dense>
      <v-col>
        <v-sheet outlined rounded class="pa-2" style="border: 1px solid #9c9c9c">
          <SegmentedProgressBar
              :required-segments="shouldValue"
              :segment-length="progressSpecialDuration"
              :total-value="totalValue"
              :duration="currentAppointment.duration"
              :label="$tc(currentLessonType.name, 2)"
              color="secondary"
              dark
              class="mb-1"
          />
        </v-sheet>
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
        <VehicleFieldDynamic
          @emitVehicleChange="(val) => updateFields({ primaryVehicleId: val })"
          @emitRequestVehicles="requestPrimaryVehicles"
          :disabled="studentIsNotSelected ||
                    !currentAppointment.licenseId ||
                    isMissed ||
                    hasDeletedStudent ||
                    isExamInPast"
          :label="$t('form.primary_vehicle')"
          :loading="primaryVehiclesLoader"
          :showNoData="!primaryVehiclesIds"
          :vehiclesList="primaryVehicles"
          :vehiclesData="primaryVehiclesData"
          :vehicleProp="currentAppointment.primaryVehicleId"
        />
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
        <VehicleFieldDynamic
          @emitVehicleChange="(val) => updateFields({ secondaryVehicleId: val })"
          @emitRequestVehicles="requestSecondaryVehicles"
          :disabled="studentIsNotSelected ||
                    !currentAppointment.licenseId ||
                    !currentAppointment.primaryVehicleId ||
                    isMissed ||
                    hasDeletedStudent ||
                    isExamInPast"
          :label="$t('form.secondary_vehicle')"
          :loading="secondaryVehiclesLoader"
          :showNoData="!secondaryVehiclesIds"
          :vehiclesList="secondaryVehicles"
          :vehiclesData="secondaryVehiclesData"
          :vehicleProp="currentAppointment.secondaryVehicleId"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <MeetingPlaceField
          @emitMeetingPlaceSelect="(val) => updateFields({ meetingPlace: val })"
          :disabled="studentIsNotSelected || isMissed || hasDeletedStudent || isExamInPast"
          :validation="false"
          :meetingPlacesList="placesGroups"
          :meetingPlaceProp="currentAppointment.meetingPlace" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <NotesField
          @emitNotesChange="(val) => updateFields({ notes: val })"
          :disabled="studentIsNotSelected || hasDeletedStudent"
          :validation="false"
          :notesProp="currentAppointment.notes" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import user from '@/utils/mixins/user'
import placeholderPracticeMixin from '@/utils/mixins/placeholderPracticeMixin'
import priceCategoryMixin from '@/utils/mixins/priceCategoryMixin'

import SegmentedProgressBar from "@/components/SegmentedProgressBar"
import TeachersField from "@/components/calendar/appointmentModal/fields/TeachersField"
import StudentsField from "@/components/calendar/appointmentModal/fields/StudentsField"
import LicenseField from "@/components/calendar/appointmentModal/fields/LicenseField"
import ManualLessonCheckboxField from "@/components/calendar/appointmentModal/fields/ManualLessonCheckboxField"
import LessonTypesField from "@/components/calendar/appointmentModal/fields/LessonTypesField"
import VehicleFieldDynamic from "@/components/calendar/appointmentModal/fields/VehicleFieldDynamic.vue"
import MeetingPlaceField from "@/components/calendar/appointmentModal/fields/MeetingPlaceField"
import NotesField from "@/components/calendar/appointmentModal/fields/NotesField"

import LicenseModel from "@/store/models/LicenseModel"
import TeacherModel from "@/store/models/TeacherModel"
import StudentModel from "@/store/models/StudentModel"
import LessonTypeModel from "@/store/models/LessonTypeModel"
import VehicleModel from '@/store/models/VehicleModel'

import SelectField from '@/helpers/selectField'

export default {
  name: "PracticeForm",
  mixins: [user, placeholderPracticeMixin, priceCategoryMixin],
  components: {
    SegmentedProgressBar,
    TeachersField,
    StudentsField,
    LicenseField,
    ManualLessonCheckboxField,
    LessonTypesField,
    VehicleFieldDynamic,
    MeetingPlaceField,
    NotesField,
  },
  data: () => ({
    initialDuration: 0,
    loadingStudentProgress: false,
  }),
  props: {
    teachers: {
      type: Array,
      required: true,
    },
    createdPlaceholderApp: {
      type: Object,
    },
  },
  watch: {
    "currentAppointment.primaryVehicleId"(val) {
      if (!val) this.updateFields({ secondaryVehicleId: null })
    },
  },
  mounted () {
    if (this.currentAppointment.id && this.currentAppointment.studentsIds.length) {
      this.$refs.licenseField.loadProgressForStudent(this.currentAppointment.studentsIds[0])
    }
  },
  computed: {
    ...mapGetters("appointments", { currentAppointment: "currentAppointmentGetter", }),
    student() {
      return StudentModel.find(this.currentAppointment.studentsIds[0]) || {}
    },
    isMissed() {
      return ['MISSED', 'MISSED_PARTIALLY_REFUNDED', 'MISSED_REFUNDED', 'JOKER_REFUNDED'].includes(this.currentAppointment.status)
    },
    hasDeletedStudent() {
      return this.student.isDeleted
    },
    isExamInPast() {
      /*commented past exam logic*/
      // const { id, lessonTypeId, start } = this.currentAppointment
      // const lessonType = LessonTypeModel.find(lessonTypeId) || {}
      // return !!id && isPast(parseISO(start)) && (lessonType && !lessonType.canBeMovedInPast)
      return false
    },
    baseLessonTypes() {
      return LessonTypeModel.query()
          .where("eventTypeId", "PRACTICE")
          .orderBy('order')
          .get() || []
    },
    showIsManualLesson() {
      const activeLicense = LicenseModel.find(this.currentAppointment.licenseId) || {};
      return activeLicense.name === "B197";
    },
    activeLicensesIds() {
      const teacher = TeacherModel.find(this.currentAppointment.teacherId)
      const student = StudentModel.find(this.currentAppointment.studentsIds[0])

      if (!teacher || !student) return []

      const practiceLicensesIds = LicenseModel.query().where('forPractice', true).get().map(license => license.id);

      return teacher.licensesIds
          .filter((licenseId) => practiceLicensesIds.includes(licenseId) && student.licensesIds.includes(licenseId))
    },

    teacherIsNotSelected() {
      return !this.currentAppointment.teacherId
    },
    studentIsNotSelected() {
      return this.currentAppointment.studentsIds.length === 0
    },
    activeStudentsList() {
      const query = StudentModel.query().where("teachersIds", (teachersIds) => teachersIds.includes(this.currentAppointment.teacherId))
      if (!this.currentAppointment.id || !!this.createdPlaceholderApp) query.where("active", true).where('educationStatus', 'learning')
      return query.get()
    },
  },
  methods: {
    updateTeacherField(val) {
      this.updateFields({
        teacherId: val,
        studentsIds: this.currentAppointment.id ? this.currentAppointment.studentsIds : [],
        primaryVehicleId: null,
        secondaryVehicleId: null,
      });
      this.$nextTick(() => {
        this.updateLicenseField(this.activeLicensesIds.length === 1 ? this.activeLicensesIds[0] : null, true)
        if(this.currentAppointment.id) {
          this.$refs.licenseField.loadProgressForStudent(this.currentAppointment.studentsIds[0])
        }
      })
    },
    updateStudentField(val) {
      this.updateFields({ studentsIds: val })
      this.$nextTick(() => {
        this.updateLicenseField(this.activeLicensesIds.length === 1 ? this.activeLicensesIds[0] : null, true)
        if(val.length) {
          this.$refs.licenseField.loadProgressForStudent(val[0])
        }
      })
    },
    updateLicenseField(val, cascade = false) {
      const currentPrimaryVehicle = VehicleModel.find(this.currentAppointment.primaryVehicleId)
      const keepVehicle = currentPrimaryVehicle?.licensesIds.includes(val)
      this.updateFields({
        licenseId: val,
        isManualLesson: !cascade ? this.currentAppointment.isManualLesson : false,
        primaryVehicleId: !cascade && keepVehicle ? this.currentAppointment.primaryVehicleId : null,
        secondaryVehicleId:  !cascade && keepVehicle ? this.currentAppointment.secondaryVehicleId : null,
      })
      const lessonTypeVal = new SelectField(this.currentAppointment, this.createdPlaceholderApp)
          .lessonTypeSelector(this.lessonTypesList, cascade)
      this.updateLessonTypeField(lessonTypeVal)
    },
    updateLessonTypeField(val) {
      this.updateFields({ lessonTypeId: this.currentAppointment.licenseId ? val : this.lessonTypes[0].id })
    }
  }
};
</script>
