import LicenseModel from "@/store/models/LicenseModel";
import user from "@/utils/mixins/user";

export default {
  name: "LicensesMixin",
  mixins: [user],
  computed: {
    licensesOptions() {
      return LicenseModel.all().map((license) => {
        return {
          text: license.name,
          value: license.id,
        };
      });
    },
    licensesById() {
      const byId = {};

      LicenseModel.all().forEach((item) => {
        if (!(item.id in byId)) byId[item.id] = item;
      });

      return byId;
    },
  },
};
