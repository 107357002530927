<template>
    <v-dialog
            v-model="show"
            :scrollable="true"
            width="500"
            @click:outside="close"
            @keydown.esc="close"
    >
        <v-card :loading="loading">
          <v-toolbar class="flex-grow-0 mb-4" color="primary" dark dense>
            <v-toolbar-title>
              {{ $t("label.create_or_edit_reservation", { createOrEdit: reservationId ? $t("label.edit") : $t("label.create") })
              }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn dark icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text v-if="loading">
            <div class="d-flex justify-center pt-5">
              <v-progress-circular color="primary" indeterminate />
            </div>
          </v-card-text>

          <v-card-text v-else>
            <VehicleAlert v-if="showDetailsAlert && vehicle" :vehicle="vehicle" />

            <v-alert
              v-if="teacherIsDisabled"
              dense
              colored-border
              border="left"
              elevation="4"
              type="warning"
              icon="mdi-alert-outline"
            >
              {{ $t('messages.warning_reservation_teacher_is_deactivated') }}
            </v-alert>

            <ValidationObserver ref="form">
              <ValidationProvider v-slot="{ errors }" name="teacherId">
                <v-row class="mt-2" dense>
                  <v-col cols="12">
                    <v-select
                      v-model="form.teacherId"
                      :error-messages="errors"
                      :items="teachersItems"
                      :label="$t('form.teacher')"
                      :disabled="loadTeachers || teacherIsDisabled"
                      item-text="fullName"
                      item-value="id"
                      outlined
                      dense
                    />
                  </v-col>
              </v-row>

              <v-row dense>
                  <v-col cols="12">
                      <DatePickerField
                        :label="$t('label.date')"
                        :placeholder="$t('label.date')"
                        :value="form.date"
                        :disabled="teacherIsDisabled"
                        name="date"
                        @save="value => form.date = value"
                      />
                  </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="6">
                  <TimePickerField
                    :allowedMinutes="m => m % 15 === 0"
                    :label="$t('label.start_time')"
                    :placeholder="$t('label.start_time')"
                    :value="form.timeFrom"
                    :disabled="teacherIsDisabled"
                    name="timeFrom"
                    offset-overflow
                    @save="setTimeFrom"
                    @clear="form.timeFrom = null"
                  />
                </v-col>
                <v-col cols="6">
                  <TimePickerField
                    :allowedMinutes="m => m % 15 === 0"
                    :label="$t('label.end_time')"
                    :min="minTimeTo"
                    :placeholder="$t('label.end_time')"
                    :value="form.timeTo"
                    :disabled="teacherIsDisabled"
                    name="timeTo"
                    offset-overflow
                    @save="value => form.timeTo = value"
                    @clear="form.timeTo = null"
                  />
                </v-col>
              </v-row>
            </ValidationProvider>
          </ValidationObserver>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text @click="close">{{ $t('btn.close') }}</v-btn>
          <v-spacer/>
          <v-btn
            v-if="showDeleteButton && reservationId"
            :disabled="loading || saving"
            :loading="saving"
            dark
            color="red"
            @click="confirmDelete"
          >
            <v-icon left>mdi-delete</v-icon>
            {{ $t('btn.delete') }}
          </v-btn>
          <v-btn
            :disabled="loading || saving || teacherIsDisabled"
            :loading="saving"
            color="primary"
            @click="save"
          >
            <v-icon left>{{ `mdi-content-save${reservationId ? '-edit' : ''}` }}</v-icon>
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import {EventBus} from "@/EventBus"
import { showConfirm } from "@/helpers/dialogs"
import { addMinutes, format, isAfter, isEqual, parseISO } from 'date-fns'
import DatePickerField from '@/components/form/DatePickerField.vue'
import TimePickerField from '@/components/form/TimePickerField.vue'
import vehiclesService from '@/services/vehiclesService'
import TeacherModel from "@/store/models/TeacherModel";
import VehicleModel from '@/store/models/VehicleModel'
import VehicleAlert from "@/components/vehicle/VehicleAlert.vue"

export default {
  name: 'VehicleReservationDialog',
  props: {
    showDeleteButton: {
      type: Boolean,
      default: true
    },
    showDetailsAlert: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VehicleAlert,
    TimePickerField,
    DatePickerField
  },
  data () {
    return {
      loading: false,
      saving: false,
      loadTeachers: false,
      show: false,
      vehicleId: null,
      reservationId: null,
      form: {
        teacherId: null,
        teacherName: null,
        date: null,
        timeFrom: null,
        timeTo: null
      }
    }
  },
  computed: {
    vehicle() {
      return VehicleModel.find(this.vehicleId)
    },
    minTimeTo () {
      if (!this.form.timeFrom) return undefined

      const date = format(this.form.date ?parseISO(this.form.date) : new Date(), 'yyyy-MM-dd')

      return format(addMinutes(parseISO(date + ' ' + this.form.timeFrom), 15), "HH:mm");
    },
    teachers () {
        if (! this.vehicle) return

        return TeacherModel
          .query()
          .where(item => this.vehicle.teachersIds.includes(item.id))
          .get()
    },
    teachersItems () {
      if (this.teacherIsDisabled) {
        return [{ id: this.form.teacherId, fullName: this.form.teacherName }]
      }
      return this.teachers
    },
    teacherIsDisabled () {
      const pluckIds = this.teachers?.map(teacher => teacher.id) || []
      return this.reservationId && !pluckIds.includes(this.form.teacherId)
    }
  },

  created() {
    EventBus.$on('open-dialog', this.handleOpen);
  },
  destroyed() {
    EventBus.$off('open-dialog', this.handleOpen);
  },

  methods: {
    handleOpen (data) {
      this.open(data)
    },
    open (reservation) {
      this.show = true

      if (reservation) this.initReservationData(reservation);
    },
    close () {
      this.show = false

      this.$emit('closed')

      setTimeout(() => {
        this.$refs.form.reset();
        this.vehicleId = null;
        this.reservationId = null;

        this.form = {
          teacherId: null,
          teacherName: null,
          date: null,
          timeFrom: null,
          timeTo: null
        };
      }, 200)
    },
    setTimeFrom (value) {
      this.form.timeFrom = value;

      const date = this.form.date ?? format(new Date(), "yyyy-MM-dd");

      if (!this.form.timeFrom || !this.form.timeTo) return;

      const from = parseISO(date + " " + this.form.timeFrom);
      const to = parseISO(date + " " + this.form.timeTo);

      if (isEqual(from, to) || isAfter(from, to)) {
        this.form.timeTo = format(addMinutes(from, 15), "HH:mm");
      }
    },
    initReservationData(reservation) {
      this.vehicleId = reservation.vehicleId;
      this.reservationId = reservation.reservationId;

      const date = reservation.from ? format(parseISO(reservation.from), "yyyy-MM-dd") : null;
      const timeFrom = reservation.from ? format(parseISO(reservation.from), "HH:mm") : null;
      const timeTo = reservation.to ? format(parseISO(reservation.to), "HH:mm") : null;

      this.form = {
        teacherId: reservation.teacherId,
        teacherName: reservation.teacherName,
        date,
        timeFrom,
        timeTo
      };
    },
    confirmDelete () {
      showConfirm(
        this.$t('btn.confirm'),
        this.$t("messages.are_your_sure_delete"),
        () => this.deleted()
      )
    },

    deleted () {
      this.$emit('loadingStatus', true)
      vehiclesService
        .deleteReservation(this.vehicleId, this.reservationId)
        .then(() => {
          this.$emit('deleted', this.reservationId)
        })
        .finally(() => {
          this.$emit('loadingStatus', false)
          this.close()
        })
    },
    async save () {
      const action = this.reservationId ? 'update' : 'store'
      this.$emit('loadingStatus', true)
      this.saving = true
      this[action](this.form)
        .catch((error) => {
          this.$refs.form.reset();

          const responseData = error.response.data || {}
          const errors = responseData.errors || []

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({
              message: responseData.message,
              color: 'error',
            })
          }

          this.$refs.form.setErrors(errors)
        })
        .finally(() => {
          this.$emit('loadingStatus', false)
          this.saving = false
        })
    },
    store (formData) {
      return vehiclesService
        .storeReservation(this.vehicleId, formData)
        .then(response => {
          this.$emit('created', response.data)
          this.close()
        })
    },
    update (formData) {
      return vehiclesService
        .updateReservation(this.vehicleId, this.reservationId, formData)
        .then(response => {
          this.$emit('updated', response.data)
          this.close()
        })
    }
  }
}
</script>

<style scoped>

</style>
