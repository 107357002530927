<template>
  <div>
    <v-row>
      <v-col>
        <ValidationProvider
          name="school-name"
          rules="required"
          v-slot="{ errors }">
          <v-text-field
            v-model="school.name"
            :error="errors.length > 0"
            label="School Name"
            outlined
            hide-details
            dense />
        </ValidationProvider>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <ValidationProvider
          name="first-name"
          :rules="`${validation ? 'required' : ''}`"
          v-slot="{ errors }">
          <v-text-field
            v-model="school.firstName"
            :error="errors.length > 0"
            label="First Name"
            outlined
            hide-details
            dense />
        </ValidationProvider>
      </v-col>
      <v-col cols="6">
        <ValidationProvider
          name="last-name"
          :rules="`${validation ? 'required' : ''}`"
          v-slot="{ errors }">
          <v-text-field
            v-model="school.lastName"
            :error="errors.length > 0"
            label="Last Name"
            outlined
            hide-details
            dense />
        </ValidationProvider>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="8">
        <ValidationProvider name="street" rules="required" v-slot="{ errors }">
          <v-text-field
            v-model="school.street"
            :error="errors.length > 0"
            label="Street"
            outlined
            hide-details
            dense />
        </ValidationProvider>
      </v-col>
      <v-col cols="4">
        <ValidationProvider
          name="house-nr"
          rules="required"
          v-slot="{ errors }">
          <v-text-field
            v-model="school.houseNr"
            :error="errors.length > 0"
            label="House Nr"
            outlined
            hide-details
            dense />
        </ValidationProvider>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4">
        <ValidationProvider
          name="postcode"
          rules="required"
          v-slot="{ errors }">
          <v-text-field
            v-model="school.postcode"
            :error="errors.length > 0"
            label="Plz"
            outlined
            hide-details
            dense />
        </ValidationProvider>
      </v-col>
      <v-col cols="8">
        <ValidationProvider name="city" rules="required" v-slot="{ errors }">
          <v-text-field
            v-model="school.city"
            :error="errors.length > 0"
            label="City"
            outlined
            hide-details
            dense />
        </ValidationProvider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PrintMediaContactForm",
  props: {
    validation: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("school", ["school"]),
  },
};
</script>

<style lang="scss"></style>
