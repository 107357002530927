import { Model } from "@vuex-orm/core";
import TeacherModel from "./TeacherModel";
import LessonTypeModel from "./LessonTypeModel";
import LicenseModel from "@/store/models/LicenseModel";
import EventTypeModel from "@/store/models/EventTypeModel";
import i18n from "@/plugins/i18n";
import store from "@/store";

import { addMinutes, format, parseISO } from "date-fns";

export default class EventModel extends Model {
  static entity = "events";

  static TYPES = {
    OFFTIME: "OFFTIME",
    PLACEHOLDER: "PLACEHOLDER",
    PRACTICE: "PRACTICE",
    THEORY: "THEORY",
    SPECIAL: "SPECIAL",
    SIMULATOR: "SIMULATOR",
  };

  static state() {
    return {
      loading: false,
    };
  }

  static fields() {
    return {
      id: this.attr(null),
      eventType: this.string(""),
      duration: this.attr(null),
      start: this.string(new Date()),
      schoolId: this.attr(null),
      teacherId: this.attr(null),
      teacherFullName: this.string(""),
      studentsIds: this.attr([]),
      eventText: this.attr(""),
      isTrashed: this.boolean(false),
      teacher: this.belongsTo(TeacherModel, "teacherId"),
      deletedAt: this.attr(null),
      typeEntity: this.attr(""),
    };
  }

  get primaryVehicleId() {
    return this.typeEntity.primary_vehicle_id;
  }

  get secondaryVehicleId() {
    return this.typeEntity.secondary_vehicle_id;
  }

  get theoryTopicId() {
    return this.typeEntity.theory_topic_id;
  }

  get theorySignedStudents() {
    return this.typeEntity.theory_signed_students;
  }

  get cost() {
    return this.typeEntity.cost || 0;
  }

  get signature() {
    return this.typeEntity.signature;
  }

  get isWorkTime() {
    return this.typeEntity.is_work_time;
  }

  get allowMultipleRequests() {
    return this.typeEntity.allow_multiple_requests;
  }

  get officeId() {
    return this.typeEntity.office_id;
  }

  get specialType() {
    return this.typeEntity.special_type || "STANDARD";
  }

  get requests() {
    return this.typeEntity.requests;
  }

  get status() {
    return this.typeEntity.status;
  }

  get lessonTypeId() {
    return this.typeEntity.lesson_type_id;
  }

  get lessonType() {
    return LessonTypeModel.find(this.typeEntity.lesson_type_id);
  }

  get group() {
    const lessonTypeObj = LessonTypeModel.find(this.typeEntity.lesson_type_id) || {};
    return lessonTypeObj.group;
  }

  get lessonTypeName() {
    const lessonTypeObj = LessonTypeModel.find(this.typeEntity.lesson_type_id) || {};
    return lessonTypeObj.name;
  }

  get roomSize() {
    return this.typeEntity.room_size;
  }

  get meetingPlace() {
    return this.typeEntity.meeting_place;
  }

  get licenseId() {
    return this.typeEntity.license_id;
  }

  get licensesIds() {
    return this.typeEntity.licenses_ids || [];
  }

  get allowedLicenses() {
    return this.typeEntity.licenses_ids || [];
  }

  get manualLesson() {
    return this.typeEntity.manual_lesson;
  }

  get license() {
    return LicenseModel.find(this.typeEntity.license_id);
  }

  get lessonsAmount() {
    return this.typeEntity.lessons_amount;
  }

  get lessonDuration() {
    return this.typeEntity.lesson_duration;
  }

  get title() {
    return this.typeEntity.title;
  }

  get name() {
    return this.typeEntity.name;
  }

  get startDate() {
    return format(parseISO(this.start), "yyyy-MM-dd");
  }

  get startTime() {
    return format(parseISO(this.start), "HH:mm");
  }

  get endDate() {
    const startDate = parseISO(`${this.startDate} ${this.startTime}`);
    return addMinutes(startDate, this.duration);
  }

  get endTime() {
    return format(this.endDate, "HH:mm");
  }

  get formattedDateTime() {
    return format(parseISO(this.start), "dd.MM.yyyy HH:mm");
  }

  get formattedDate() {
    return format(parseISO(this.start), "dd.MM.yyyy");
  }

  get practiceColors() {
    const currentUser = this.$store().state.auth.authUser;
    const isStudent = currentUser.type === "student";
    const nonBlocked = this.studentsIds.includes(currentUser.id);
    if (!isStudent || (isStudent && nonBlocked)) {
      switch (this.typeEntity.status) {
        case "ACCEPTED":
          return "lesson-event";
        case "REQUESTED":
          return "requested-event";
        case "DECLINED":
          return "declined-event";
        default:
          return "requested-event";
      }
    } else {
      return "blocked-time-event";
    }
  }

  get eventTypeModel() {
    return EventTypeModel.find(this.eventType) || {};
  }

  static eventTypeTranslation(eventType) {
    const currentUser = store.state.auth.authUser;

    if (currentUser.type === "student" && eventType === "PLACEHOLDER") {
      return i18n.t("event_type.student.PLACEHOLDER");
    }

    return i18n.t(`event_type.${eventType}`);
  }

  get eventTypeTranslation() {
    return EventModel.eventTypeTranslation(this.eventType);
  }

  get isTheoryFull() {
    return this.typeEntity.room_size === this.studentsIds.length;
  }
}
