<template>
  <v-row>
    <v-col cols="12">
      <div v-if="type === 'normal'">
        <v-skeleton-loader
          v-for="item in Array.from(Array(6).keys())"
          :key="item"
          type="text"
          width="100%"
          height="30" />
      </div>

      <div v-else>
        <div class="d-flex mb-3">
          <v-skeleton-loader
            class="mr-2"
            v-for="item in Array.from(Array(8).keys())"
            :key="item"
            type="button"
            height="32"
            width="40" />
        </div>

        <v-skeleton-loader
          v-for="item in Array.from(Array(3).keys())"
          :key="item"
          type="text"
          width="100%"
          height="30" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TheoryTopicsListLoader",
  props: {
    type: {
      type: String,
      default: "normal",
    },
  },
};
</script>

<style scoped></style>
