import { render, staticRenderFns } from "./NextStudentSkeletonLoader.vue?vue&type=template&id=419b0f39&scoped=true"
import script from "./NextStudentSkeletonLoader.vue?vue&type=script&lang=js"
export * from "./NextStudentSkeletonLoader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "419b0f39",
  null
  
)

export default component.exports