<template>
  <div class="mt-3 mb-3">
    <EmptyBox v-if="learningMaterials.length === 0" />

    <v-sheet
        outlined
        rounded
        class="d-flex justify-space-between align-center mb-3 pa-2"
        v-for="learningMaterial in learningMaterials"
        :key="learningMaterial.id"
    >
      <div>
        <div class="title text--secondary">{{ learningMaterial.name }}</div>
        <div class="text-caption">{{ learningMaterial.description }}</div>
      </div>

      <div>
        <div class="subtitle-1" style="text-align: center">
          {{ learningMaterial.price | currency }}
        </div>

        <div class="ml-5 mr-3 d-flex justify-space-between">
          <v-icon
              color="blue"
              @click="editLearningMaterial(learningMaterial.id)"
              :disabled="loadingAction"
          >
            mdi-pencil
          </v-icon>

          <v-icon
              class="ml-2"
              color="red"
              @click="deleteLearningMaterial(learningMaterial.id)"
              :disabled="loadingAction"
          >
            mdi-delete
          </v-icon>
        </div>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import learningMaterialsService from "@/services/learningMaterialsService";
import LearningMaterialModel from "@/store/models/LearningMaterialModel";
import EmptyBox from "@/components/EmptyBox";
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: "LearningMaterials",
  props: {
    learningMaterials: {
      type: Array,
      required: true,
    }
  },
  components: { EmptyBox, },
  data: () => ({
    loadingAction: false,
  }),
  methods: {
    editLearningMaterial(id) {
      this.$emit('edit', id)
    },
    deleteLearningMaterial(id) {
      showConfirm(
        this.$t('btn.confirm'),
        this.$t("messages.are_your_sure_delete"),
        () => {
          this.loadingAction = true
          learningMaterialsService
            .delete(id)
            .then(() => LearningMaterialModel.delete(id))
            .catch((error) => console.log(error))
            .finally(() => {
              this.loadingAction = false;
            });
        }
      );
    },
  },
};
</script>

<style scoped></style>
