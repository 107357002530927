<template>
<v-card :loading="loading" :disabled="disabled">
  <v-img height="100px" :src="image" eager />
  <v-card-title>{{ $t("label.education") }}</v-card-title>

  <v-card-subtitle class="student-card">
    {{ $t('label.student_card_education_description') }}
  </v-card-subtitle>

  <v-card-text v-if="!disabled">
    <v-skeleton-loader v-if="loading" type="list-item-two-line" />
    <v-list v-else>
      <v-list-item class="pa-0 mb-2">
        <v-list-item-content class="pa-0">
          <v-list-item-title>{{ $t("form.licenses") }}</v-list-item-title>
          <v-list-item-subtitle>{{ licensesNames }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card-text>

  <v-card-actions>
    <v-btn
      class="ml-auto"
      small
      text
      :to="{ name: `students.student.education` }"
    >
      {{ $t("btn.open") }}
    </v-btn>
  </v-card-actions>
</v-card>
</template>

<script>
import LicenseModel from '@/store/models/LicenseModel'
import StudentModel from '@/store/models/StudentModel'

export default {
  name: "StudentEducationCard",
  props: {
    studentId: {
      type: [Number, String],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    image: require("@/assets/img/education.jpg"),
    licenses: [],
  }),
  watch: {
    disabled(value) {
      if (!value) this.init();
    },
  },
  computed: {
    student() {
      return StudentModel.find(this.studentId)
    },
    licensesNames() {
      return LicenseModel.query().whereIdIn(this.student.licensesIds).get().map(item => item.name).join(", ");
    },
  }
};
</script>

<style scoped></style>
