
<template>
  <AppointmentEvaluationProcess :appointment-id="appointmentId" />
</template>

<script>
import AppointmentEvaluationProcess from '@/components/insights/AppointmentEvaluationProcess.vue'
import EvaluationModel from '@/store/models/insights/EvaluationModel'
import EvaluationItemModel from '@/store/models/insights/EvaluationItemModel'
import TemplateModel from '@/store/models/insights/TemplateModel'
export default {
  name: "AppointmentEvaluationPage",
  components: { AppointmentEvaluationProcess },
  computed: {
    appointmentId() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    EvaluationModel.deleteAll()
    EvaluationItemModel.deleteAll()
    TemplateModel.deleteAll()
  },
}

</script>


<style lang="scss" scoped>

</style>
