<template>
<v-dialog v-model="show" eager max-width="700" @click:outside="close" @keydown.esc="close">
  <v-card>
    <v-toolbar dark color="primary" dense>
      <v-toolbar-title>
        {{ title || $t('label.signature') }}
      </v-toolbar-title>

      <v-spacer/>

      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-sheet outlined rounded class="mt-5">
        <v-skeleton-loader v-if="loading" type="image"/>
        <VueSignaturePad
            v-show="!loading"
            :key="loading"
            v-resize="redrawInsert"
            height="200px"
            width="100%"
            ref="signaturePad"
            :options="{ onEnd: onEnd }"
        />
      </v-sheet>
    </v-card-text>

    <v-divider/>

    <v-card-actions>
      <v-btn text color="primary" @click="close">
        <v-icon v-if="$vuetify.breakpoint.mobile" left>mdi-close</v-icon>
        <span v-if="!$vuetify.breakpoint.mobile">{{$t("btn.close")}}</span>
      </v-btn>

      <v-spacer/>

      <v-btn color="error" :disabled="isSignatureEmpty || deleting" :loading="deleting" @click="destroy">
        <v-icon :left="!$vuetify.breakpoint.mobile">
          {{ 'mdi-eraser-variant' }}
        </v-icon>
        <span v-if="!$vuetify.breakpoint.mobile">
          {{ $t(`btn.clear`) }}
        </span>
      </v-btn>

      <v-btn color="primary" :loading="saving" :disabled="loading || saving || hasSignature || isSignatureEmpty" @click="save">
        <v-icon :left="!$vuetify.breakpoint.mobile">mdi-content-save</v-icon>
        <span v-if="!$vuetify.breakpoint.mobile">{{$t("btn.save")}}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import userService from '@/services/userService'
import schoolService from '@/services/schoolService'
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: 'SignatureDialog',
  props: {
    title: {
      type: String
    },
    type: {
      type: String,
      default: 'user'
    }
  },
  data() {
    return {
      loading: false,
      show: false,
      signature: '',
      signatureType: null,
      isSignatureEmpty: true,
      saving: false,
      deleting: false
    }
  },
  computed: {
    loadMethod() {
      const methodsByType = {
        user: 'loadUserSignature',
        school: 'loadSchoolSignature'
      }

      const type = this.type in methodsByType ? this.type : 'user'

      return this[methodsByType[type]]
    },
    saveMethod() {
      const methodsByType = {
        user: 'saveUserSignature',
        school: 'saveSchoolSignature'
      }

      const type = this.type in methodsByType ? this.type : 'user'

      return this[methodsByType[type]]
    },
    deleteMethod() {
      const methodsByType = {
        user: 'deleteUserSignature',
        school: 'deleteSchoolSignature'
      }

      const type = this.type in methodsByType ? this.type : 'user'

      return this[methodsByType[type]]
    },
    hasSignature () {
      return this.signature.length > 0
    }
  },
  methods: {
    open(signatureType = null) {
      this.show = true
      this.signatureType = signatureType

      this.loading = true
      this.loadMethod()
        .then(response => {
          this.loading = false

          const signature = response.data

          this.signature = signature
          this.$refs.signaturePad.openSignaturePad();

          if (signature) {
            this.$nextTick(() => {
              this.$refs.signaturePad.resizeCanvas();
              this.$refs.signaturePad.fromDataURL(signature);
              this.$refs.signaturePad.lockSignaturePad();
              this.updateEmptyStatusOfSignature();
            })
          }
      }).finally(() => {
        this.loading = false
      })
    },
    loadSchoolSignature() {
      return schoolService.getSignature(this.signatureType)
    },
    loadUserSignature() {
      return userService.getSignature()
    },
    close() {
      this.show = false

      setTimeout(() => this.clear(),200)
    },
    clear() {
      this.signature = ''
      this.isSignatureEmpty = true

      this.$refs.signaturePad.clearSignature();
      this.updateEmptyStatusOfSignature()
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) return;

      this.saving = true
      this.saveMethod(data)
        .then(() => {
          this.signature = data

          this.$emit('saved', data)
          this.close()

          this.$snackbar.show({
            message: this.$t("messages.signature_successfully_saved"),
            color: "success",
            timeout: 3000,
          });
        })
        .catch(error => {
          const response = error.response || {};
          const responseData = response.data || {};

          if (responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error", });
          }

          this.clear()
        })
        .finally(() => {
          this.saving = false
        })
    },
    destroy() {
      if (this.signature) {
        showConfirm(
            this.$t('btn.confirm'),
            this.$t('messages.are_your_sure_delete'),
            () => {
              this.deleting = true
              this.deleteMethod()
                .then(() => {
                  this.clear();
                  this.$emit('saved')
                })
                .catch(error => {
                  const response = error.response || {};
                  const responseData = response.data || {};

                  if (responseData.message) {
                    this.$snackbar.show({ message: responseData.message, color: "error", });
                  }
                })
                .finally(() => {
                  this.deleting = false
                })
            }
        )
      } else {
        this.clear()
      }
    },
    saveUserSignature(data) {
      return userService.saveSignature(data)
    },
    saveSchoolSignature(data) {
      return schoolService.saveSignature(data, this.signatureType)
    },
    deleteUserSignature() {
      return userService.deleteSignature()
    },
    deleteSchoolSignature() {
      return schoolService.deleteSignature(this.signatureType)
    },
    onEnd() {
      this.updateEmptyStatusOfSignature()
    },
    updateEmptyStatusOfSignature() {
      const { isEmpty } = this.$refs.signaturePad.saveSignature();
      this.isSignatureEmpty = isEmpty

      if (isEmpty) {
        this.$refs.signaturePad.openSignaturePad();
      }
    },
    redrawInsert () {
      this.$refs.signaturePad.resizeCanvas();
      this.$refs.signaturePad.fromDataURL(this.signature);
    }
  }
}
</script>

<style scoped>

</style>
