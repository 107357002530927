<template>
  <div>
    <v-card elevation="0" outlined>
      <v-card-title>{{ $t('exams_title') }}</v-card-title>
      <v-card-subtitle class="d-flex justify-space-between">
        {{ examsDone }}
      </v-card-subtitle>
      <v-card-text>
        <v-divider class="my-0"/>
        <v-list dense>
          <EducationProgressItem
              v-if="license.forTheoryExam"
              :title="$t('lesson_type.theory_exam')"
              :btnLabel="$t('lesson_type.theory_exam')"
              :btnPassLabel="$t('label.exam_passed')"
              :passedDate="theoryExamEndDate"
              :has-examining-period-in-weeks-status="!theoryExamEndDate && student.hasDocumentsApproved"
              :examining-period-in-weeks="examiningPeriodInWeeks"
              :is-license-required="licenseRequirements.theoryExamRequired"
              @itemClick="openDialog('theory_exam', theoryExamEvents, theoryExamEndDate)"
          />
          <EducationProgressItem
            v-if="license.isB197"
            :btnLabel="$t('lesson_type.mtl_exam')"
            :btnPassLabel="$t('label.exam_passed')"
            :examining-period-in-weeks="examiningPeriodInWeeks"
            :has-examining-period-in-weeks-status="!mtlExamEndDate && student.hasDocumentsApproved"
            :passedDate="mtlExamEndDate"
            :title="$t('lesson_type.mtl_exam')"
            @itemClick="openDialog('mtl_exam', mtlExamsEvents, mtlExamEndDate )"
          />
          <EducationProgressItem
              v-if="license.forPractice"
              :title="$t('lesson_type.practice_exam')"
              :btnLabel="$t('lesson_type.practice_exam')"
              :btnPassLabel="$t('label.exam_passed')"
              :passedDate="practiceExamEndDate"
              :has-examining-period-in-weeks-status="!practiceExamEndDate && student.hasDocumentsApproved"
              :examining-period-in-weeks="examiningPeriodInWeeks"
              :is-license-required="licenseRequirements.practiceExamRequired"
              @itemClick="openDialog('practice_exam', practiceExamEvents, practiceExamEndDate )"
          />
        </v-list>
      </v-card-text>
    </v-card>

    <v-card elevation="0" outlined class="mt-5">
      <v-card-title>{{ $t('label.end_of_education') }}</v-card-title>
      <v-card-subtitle>{{ educationDone }}/2</v-card-subtitle>
      <v-card-text>
        <v-divider class="my-0"/>
        <v-list dense>
          <EducationProgressItem
              v-if="license.forTheoryExam"
              :title="$t('label.theory_education')"
              :btnLabel="$t('label.theory_education')"
              :btnPassLabel="$t('label.education_ended')"
              :passedDate="theoryEducationEndDate"
              @itemClick="openDialog('theory_education', educationTheoryAppointments, theoryEducationEndDate)"
          />
          <EducationProgressItem
              v-if="license.forPractice"
              :title="$t('label.practice_education')"
              :btnLabel="$t('label.practice_education')"
              :btnPassLabel="$t('label.education_ended')"
              :passedDate="practiceEducationEndDate"
              @itemClick="openDialog('practice_education', educationPracticeAppointments, practiceEducationEndDate)"
          />
          <EducationPassedSignature
              v-if="!isStudent"
              @emitSign="setSignature"
              :licenseId="licenseId"
              :studentId="parseInt(studentId)"
              :sign="educationSignature"
          />
        </v-list>
      </v-card-text>
    </v-card>

    <EducationExamDialog
      :events="dialogEvents"
      :finishDate="passedDate"
      :progress="examsProgress"
      :type="type"
      :licenseId="licenseId"
      :student-id="parseInt(studentId)"
      ref="EducationExamDialog"
      @emitSubmit="changeEducationStatus"
      @emitClear="changeEducationStatus"
      @close="closeDialog"
    />
  </div>
</template>

<script>
import { format, parseISO } from "date-fns"
import EducationExamDialog from "@/components/student/progress/EducationExamDialog"
import EducationProgressItem from "@/components/student/progress/EducationProgressItem"
import EducationPassedSignature from "@/components/student/progress/EducationPassedSignature"
import user from "@/utils/mixins/user"
import StudentModel from "@/store/models/StudentModel"
import AppointmentModel from "@/store/models/AppointmentModel"
import LicenseModel from '@/store/models/LicenseModel'
import LessonTypeModel from "@/store/models/LessonTypeModel";

export default {
  name: "EducationProgressArea",
  components: {
    EducationExamDialog,
    EducationProgressItem,
    EducationPassedSignature,
  },
  mixins: [user],
  data() {
    return {
      dialogEvents: [],
      type: "",
      passedDate: "",
      educationSignature: '',
      examiningPeriodInWeeks: 0,
      practiceEducationEndedAt: null,
      practiceEducationEventId: null,
      practiceExamEventId: null,
      practiceExamPassedAt: null,
      mtlExamEventId: null,
      mtlExamPassedAt: null,
      theoryEducationEndedAt: null,
      theoryEducationEventId: null,
      theoryExamEventId: null,
      theoryExamPassedAt: null,
    };
  },
  props: {
    progress: {
      type: Object,
      default: () => {}
    },
    studentId: {
      type: [Number, String],
      required: true,
    },
    licenseId: {
      type: [Number, String],
      required: true
    },
  },
  mounted () {
    this.educationSignature = this.progress.educationSignature
    this.examiningPeriodInWeeks = this.progress.examiningPeriodInWeeks
    this.practiceEducationEndedAt = this.progress.practiceEducationEndedAt
    this.practiceEducationEventId = this.progress.practiceEducationEventId
    this.practiceExamEventId = this.progress.practiceExamEventId
    this.practiceExamPassedAt = this.progress.practiceExamPassedAt
    this.mtlExamEventId = this.progress.mtlExamEventId;
    this.mtlExamPassedAt = this.progress.mtlExamPassedAt;
    this.theoryEducationEndedAt = this.progress.theoryEducationEndedAt
    this.theoryEducationEventId = this.progress.theoryEducationEventId
    this.theoryExamEventId = this.progress.theoryExamEventId
    this.theoryExamPassedAt = this.progress.theoryExamPassedAt
  },
  computed: {
    examsProgress() {
      return {
        theoryExamEventId: this.theoryExamEventId,
        theoryExamPassedAt: this.theoryExamPassedAt,
        practiceExamEventId: this.practiceExamEventId,
        practiceExamPassedAt: this.practiceExamPassedAt,
        mtlExamEventId: this.mtlExamEventId,
        mtlExamPassedAt: this.mtlExamPassedAt,
        theoryEducationEventId: this.theoryEducationEventId,
        theoryEducationEndedAt: this.theoryEducationEndedAt,
        practiceEducationEventId: this.practiceEducationEventId,
        practiceEducationEndedAt: this.practiceEducationEndedAt,
      }
    },
    student() {
      return StudentModel.find(this.studentId) || {};
    },
    license() {
      return LicenseModel.find(this.licenseId) || {}
    },
    licenseRequirements() {
      const requirements = this.isStudent ? this.currentUser.licensesRequirements : this.student.licensesRequirements;
      return requirements.find(item => item.licenseId === this.license.id)
    },
    theoryExamEvents() {
      return AppointmentModel.query()
          .where('type', 'THEORY')
          .where('group', 'exam')
        .where("licenseId", parseInt(this.licenseId))
          .orderBy('start', 'desc')
          .get() || []
    },
    practiceExamEvents() {
      return AppointmentModel.query()
          .where('type', 'PRACTICE')
          .where('group', 'exam')
        .where("licenseId", parseInt(this.licenseId))
          .orderBy('start', 'desc')
          .get() || []
    },
    mtlExamsEvents() {
      const licenseB197 = LicenseModel.query().where("name", "B197").first();
      const lessonTypeMTLExam = LessonTypeModel.query().where("name", "lesson_type.mtl_exam").first();

      return AppointmentModel.query()
        .where("type", AppointmentModel.TYPES.PRACTICE)
        .where("licenseId", licenseB197.id)
        .where("lessonTypeId", lessonTypeMTLExam.id)
        .where("status", "ACCEPTED")
        .orderBy("start", "desc")
        .get() || []
    },
    educationTheoryAppointments() {
      return AppointmentModel.query()
          .where('type', AppointmentModel.TYPES.THEORY)
        .where("allowedLicenses", (licensesIds) => licensesIds.includes(parseInt(this.licenseId)))
          .where('group', 'lesson')
          .orderBy('start', 'desc')
          .limit(5)
          .get() || []
    },
    educationPracticeAppointments() {
      return (
        AppointmentModel.query()
          .where('type', AppointmentModel.TYPES.PRACTICE)
          .where("licenseId", parseInt(this.licenseId))
          .where((e) => {
            return e.group !== 'planned_exam' && e.lessonTypeName !== 'lesson_type.planned_driving'
          })
          .where('status', 'ACCEPTED')
          .orderBy('start', 'desc')
          .limit(5)
          .get() || []
      );
    },
    theoryEducationEndDate() {
      return this.theoryEducationEndedAt
        ? format(
            parseISO(this.theoryEducationEndedAt),
            "dd.MM.yyyy"
          )
        : null;
    },
    practiceEducationEndDate() {
      return this.practiceEducationEndedAt
        ? format(
            parseISO(this.practiceEducationEndedAt),
            "dd.MM.yyyy"
          )
        : null;
    },
    educationDone() {
      return (
        (this.theoryEducationEndDate ? 1 : 0) +
        (this.practiceEducationEndDate ? 1 : 0)
      );
    },
    theoryExamEndDate() {
      return this.theoryExamPassedAt
        ? format(parseISO(this.theoryExamPassedAt), "dd.MM.yyyy")
        : null;
    },
    mtlExamEndDate() {
      return this.mtlExamEventId &&
      this.mtlExamPassedAt
        ? format(
          parseISO(this.mtlExamPassedAt),
          "dd.MM.yyyy"
        )
        : null;
    },
    practiceExamEndDate() {
      return this.practiceExamEventId &&
        this.practiceExamPassedAt
        ? format(
            parseISO(this.practiceExamPassedAt),
            "dd.MM.yyyy"
          )
        : null;
    },
    examsDone() {
      const examsProperties = ["theoryExamRequired", "practiceExamRequired", "mtlExamRequired"];

      if (examsProperties.every(item => !this.licenseRequirements[item])) {
        return this.$t('label.not_required')
      }

      let amount = (this.theoryExamEndDate ? 1 : 0) +
        (this.practiceExamEndDate ? 1 : 0) +
        (this.mtlExamEndDate ? 1 : 0);

      let requiredAmount = (this.license.isB197 ? 1 : 0) +
        (this.licenseRequirements.theoryExamRequired ? 1 : 0) +
        (this.licenseRequirements.practiceExamRequired ? 1 : 0);

      return amount + "/" + requiredAmount;
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    }
  },
  methods: {
    changeEducationStatus(val) {
      this.practiceEducationEndedAt = val.practiceEducationEndedAt
      this.practiceEducationEventId = val.practiceEducationEventId
      this.practiceExamEventId = val.practiceExamEventId
      this.practiceExamPassedAt = val.practiceExamPassedAt;
      this.mtlExamEventId = val.mtlExamEventId;
      this.mtlExamPassedAt = val.mtlExamPassedAt;
      this.theoryEducationEndedAt = val.theoryEducationEndedAt
      this.theoryEducationEventId = val.theoryEducationEventId
      this.theoryExamEventId = val.theoryExamEventId
      this.theoryExamPassedAt = val.theoryExamPassedAt

      this.$emit('emitExamPassed', {
        theoryExamPassedAt: val.theoryExamPassedAt,
        practiceExamPassedAt: val.practiceExamPassedAt,
      })
    },
    setSignature(val) {
      this.educationSignature = val
    },
    openDialog(typeLabel, events, passedDate = "") {
      this.dialogEvents = events;
      this.type = typeLabel;
      this.passedDate = passedDate;
      this.$refs.EducationExamDialog.open();
    },
    closeDialog() {
      setTimeout(() => {
        this.dialogEvents = [];
        this.type = "";
        this.passedDate = "";
      }, 200)
    },
  },
};
</script>

<style scoped></style>
