<template>
  <div>
    <v-row>
      <TeachersField
        v-if="!isTeacher"
        :disabled="disabledTeachers || missedStatus"
        :cols="mdLandscapeAndUp ? 6 : 12"
        :model="model"
        :eventDuration="eventDuration"
        :filteredTeachers="teachers" />
      <StudentsField
        :cols="mdLandscapeAndUp && !isTeacher ? 6 : 12"
        :disabled="disabledStudents || missedStatus"
        :model="model"
        :eventDuration="eventDuration"
        :filteredStudents="filteredStudents"
        :validation="true" />
    </v-row>

    <TimeFields
      :disabled="disabled || isPast || missedStatus"
      :model="model"
      :eventDuration="eventDuration"
      adaptiveTime />
    <AmountDurationFields
      :disabled="disabled || isPast || missedStatus"
      :model="model"
      :eventDuration="eventDuration" />

    <v-row>
      <LicenseField
        :disabled="disabled || noStudent || isPast || missedStatus"
        :activeLicenses="activeLicenses" />
      <LessonTypesField
        :disabled="disabled || disabledLessonType || missedStatus"
        :model="model"
        eventType="PRACTICE" />
    </v-row>

    <v-row>
      <v-col cols="6">
        <PrimaryVehicleField
          :disabled="primaryDisabled || missedStatus"
          :model="model"
          :vehicles="primaryVehicles" />
      </v-col>
      <v-col cols="6">
        <SecondaryVehicleField
          :disabled="secondaryDisabled || missedStatus"
          :model="model"
          :vehicles="secondaryVehicles" />
      </v-col>
    </v-row>

    <MeetingPlaceField
      :disabled="disabled || isPast || missedStatus"
      :model="model"
      :validation="false" />
    <TextField :disabled="disabled || isPast" :model="model" />

    <v-row>
      <v-col>
        <SignatureField
          v-if="showSignature"
          :eventId="model.id"
          :eventType="model.type"
          :eventSignature="model.signature" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TeachersField from "@/components/calendar/eventModal/fields/TeachersField";
import StudentsField from "@/components/calendar/eventModal/fields/StudentsField";
import TimeFields from "@/components/calendar/eventModal/fields/TimeFields";
import AmountDurationFields from "@/components/calendar/eventModal/fields/AmountDurationFields";
import LicenseField from "@/components/calendar/eventModal/fields/LicenseField";
import LessonTypesField from "@/components/calendar/eventModal/fields/LessonTypesField";
import MeetingPlaceField from "@/components/calendar/eventModal/fields/MeetingPlaceField";
import PrimaryVehicleField from "@/components/calendar/eventModal/fields/PrimaryVehicleField";
import SecondaryVehicleField from "@/components/calendar/eventModal/fields/SecondaryVehicleField";
import TextField from "@/components/calendar/eventModal/fields/TextField";
import SignatureField from "@/components/calendar/eventModal/fields/SignatureField";
import EventModel from "@/store/models/EventModel";

import activeLicenseMixins from "@/utils/mixins/activeLicenseMixins";
import happyHourMixins from "@/utils/mixins/happyHourMixins";
import responsive from "@/utils/mixins/responsive";
import user from "@/utils/mixins/user";
import StudentModel from "@/store/models/StudentModel";
import VehicleModel from "@/store/models/VehicleModel";
import { mapGetters } from "vuex";

export default {
  name: "PracticeForm",
  mixins: [activeLicenseMixins, responsive, user, happyHourMixins],
  components: {
    SignatureField,
    TeachersField,
    StudentsField,
    TimeFields,
    AmountDurationFields,
    LicenseField,
    LessonTypesField,
    MeetingPlaceField,
    PrimaryVehicleField,
    SecondaryVehicleField,
    TextField,
  },
  props: {
    eventDuration: {
      type: Number,
      required: true,
    },
    filteredTeachers: {
      type: Array,
      required: true,
    },
    isPast: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    missedStatus() {
      const event = EventModel.find(this.model.id) || {};
      return event.status === "MISSED" || event.status === "MISSED_REFUNDED";
    },
    disabledTeachers() {
      const event = EventModel.find(this.model.id) || {};
      return (
        this.isPast ||
        (event.eventType === "PRACTICE" && event.status === "REQUESTED")
      );
    },
    disabledStudents() {
      const event = EventModel.find(this.model.id) || {};
      return event.eventType === "PRACTICE" || this.disabled;
    },
    requestedPractice() {
      return (
        this.model.eventType === "PRACTICE" && this.model.status === "REQUESTED"
      );
    },
    disabled() {
      return !this.model.teacherId;
    },
    noStudent() {
      return !this.model.studentsIds.length;
    },
    disabledLessonType() {
      const event = EventModel.find(this.model.id) || {};
      return this.noStudent || (this.isPast && event.status !== "ACCEPTED");
    },
    showSignature() {
      const event = EventModel.find(this.model.id) || {};
      const notMissed =
        event.status !== "MISSED" && event.status !== "MISSED_REFUNDED";
      const isPracticeLesson = ["normal", "special"].includes(event.group);
      const isPracticeExam =
        event.eventType === "PRACTICE" && event.group === "exam";
      return (
        this.model.id &&
        !this.isStudent &&
        !this.requestedPractice &&
        ((isPracticeLesson && notMissed) || isPracticeExam)
      );
    },
    filteredStudents() {
      return (
        StudentModel.query()
          .where(
            (student) =>
              !student.isDeleted || this.model.studentsIds.includes(student.id)
          )
          .where("teachersIds", (teachersIds) =>
            teachersIds.includes(this.model.teacherId)
          )
          .get() || []
      );
    },
    teachers() {
      const event = EventModel.find(this.model.id) || {};
      if (event.id && event.eventType === "PRACTICE") {
        const student = StudentModel.find(event.studentsIds[0]) || {};
        return this.filteredTeachers.filter((teacher) =>
          student.teachersIds.includes(teacher.id)
        );
      } else {
        return this.filteredTeachers;
      }
    },
    primaryVehicles() {
      return (
        VehicleModel.query()
          .where(
            (vehicle) =>
              !vehicle.isDeleted || vehicle.id === this.model.primaryVehicleId
          )
          .where("teachersIds", (teachersIds) =>
            teachersIds.includes(this.model.teacherId)
          )
          .where("licensesIds", (licensesIds) =>
            licensesIds.includes(this.model.licenseId)
          )
          .get() || []
      );
    },
    secondaryVehicles() {
      return (
        VehicleModel.query()
          .where(
            (vehicle) =>
              !vehicle.isDeleted || vehicle.id === this.model.primaryVehicleId
          )
          .where((value) => value.id !== this.model.primaryVehicleId)
          .get() || []
      );
    },
    primaryDisabled() {
      return !this.model.teacherId || !this.model.licenseId || this.isPast;
    },
    secondaryDisabled() {
      return (
        !this.model.teacherId ||
        !this.model.licenseId ||
        !this.model.primaryVehicleId ||
        this.isPast
      );
    },
  },
};
</script>
