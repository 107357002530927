<template>
  <v-sheet>
    <v-row>
      <v-col cols="12" md="4">
        <DateField
          @emitDateChange="changeDate"
          :dateProp="currentAppointment.start"
          :disabled="disabledDate"
          :allowedDates="currentAppointment.type !== 'OFFTIME' ? allowedDates : null"
        />
      </v-col>
      <v-col cols="12" md="4">
        <ClockTimePicker
            @emitTimeChange="changeStartTime"
            :duration="currentAppointment.duration"
            :timeProp="startTime"
            :disabled="disabledTime"
            :color="color"
            :label="$t('label.start')" />
      </v-col>

      <v-col cols="12" md="4">
        <LessonDurationField
            @emitDurationChange="changeDuration"
            :disabled="disabledDuration || teacherIsNotSelectedForPractice"
            :empty="false"
            :durationProp="currentAppointment.duration"
        />
      </v-col>
    </v-row>

  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
import user from '@/utils/mixins/user'
import DateRange from "@/helpers/dateRange";
import {
  format,
  parseISO,
  addMinutes,
  startOfDay,
  endOfDay,
  differenceInMinutes,
} from 'date-fns'

import DateField from "@/components/calendar/appointmentModal/fields/DateField";
import ClockTimePicker from "@/components/calendar/appointmentModal/fields/ClockTimePicker";
import LessonDurationField from "@/components/calendar/appointmentModal/fields/LessonDurationField";
// import LessonTypeModel from '@/store/models/LessonTypeModel'
import TeacherModel from '@/store/models/TeacherModel'

export default {
  name: "FormDateTimeArea",
  mixins: [user],
  components: {
    DateField,
    ClockTimePicker,
    LessonDurationField,
  },
  data: () => ({
    initialStart: null,
  }),
  props: {
    color: {
      type: String,
    },
    disabledDate: {
      type: Boolean,
      default: false,
    },
    disabledTime: {
      type: Boolean,
      default: false,
    },
    disabledDuration: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    "currentAppointment.teacherId"() {
      const { id, teacherId, type, duration } = this.currentAppointment
      const teacher = TeacherModel.find(teacherId)
      const exceedsDefaultDuration = duration < (teacher?.lessonDuration * 2)

      if (!id && ["PLACEHOLDER", "PRACTICE"].includes(type) && exceedsDefaultDuration) {
        this.$store.commit(`appointments/UPDATE_APPOINTMENT_FIELD`, { duration: teacher.lessonDuration * 2, })
      }
    },
    "currentAppointment.duration"(val) {
      const endTime = addMinutes(parseISO(this.currentAppointment.start), val)
      const dayEnd = addMinutes(endOfDay(parseISO(this.currentAppointment.start)), 1)
      if (endTime > dayEnd) {
        const diff = differenceInMinutes(dayEnd, parseISO(this.currentAppointment.start))
        this.$store.commit(`appointments/UPDATE_APPOINTMENT_FIELD`, { duration: diff })
      } else {
        this.$store.commit(`appointments/UPDATE_APPOINTMENT_FIELD`, { duration: parseInt(val) })
      }
    }
  },
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("appointments", {
      currentAppointment: "currentAppointmentGetter",
    }),
    teacherIsNotSelectedForPractice() {
      return ['PRACTICE', 'PLACEHOLDER'].includes(this.currentAppointment.type) && !this.currentAppointment.teacherId
    },
    allowedDates() {
      if (this.currentAppointment.type === "OFFTIME") return null
      const regions = this.schoolSettings.regions?.split(", ")
      /*commented past exam logic*/
      // if (!this.schoolSettings.pastEventsAllowed) {
      //   return (val) => !new DateRange().isHoliday(parseISO(val), regions) && !isPast(parseISO(val))
      // } else {
      //   return (val) => {
      //     const appGroup = LessonTypeModel.find(this.currentAppointment.lessonTypeId) || {}
      //     const isPastExam = isPast(endOfDay(parseISO(val))) && appGroup?.group === 'exam'
      //     return !new DateRange().isHoliday(parseISO(val), regions) && (!isPastExam)
      //   }
      // }
        return (val) => !new DateRange().isHoliday(parseISO(val), regions)
    },

    startTime() {
      return parseISO(this.currentAppointment.start)
    },
    endTime() {
      return addMinutes(this.startTime, this.currentAppointment.duration)
    },
  },
  methods: {
    changeDuration(duration) {
      // const endTime = addMinutes(parseISO(this.currentAppointment.start), duration)
      // const dayEnd = addMinutes(endOfDay(parseISO(this.currentAppointment.start)), 1)
      // if (endTime > dayEnd) return
      this.$store.commit(`appointments/UPDATE_APPOINTMENT_FIELD`, { duration: duration })
    },

    changeDate(date) {
      const start = `${date} ${format(parseISO(this.currentAppointment.start), "HH:mm:ss")}`
      this.$store.commit(`appointments/UPDATE_APPOINTMENT_FIELD`, { start, })
    },

    changeStartTime(start) {
      let duration = this.currentAppointment.duration
      const end = addMinutes(start, duration)
      const dayStart = startOfDay(start)
      const dayEnd = addMinutes(endOfDay(start), 1)
      if (start < dayStart || end > dayEnd) return
      this.$store.commit(`appointments/UPDATE_APPOINTMENT_FIELD`, { start: format(start, "yyyy-MM-dd HH:mm:ss"), duration })
    },

    // checkTimeLimit(val) {
    //   const endTime = addMinutes(parseISO(this.currentAppointment.start), val)
    //   const dayEnd = addMinutes(endOfDay(parseISO(this.currentAppointment.start)), 1)
    //   if (endTime > dayEnd) {
    //     const diff = differenceInMinutes(dayEnd, parseISO(this.currentAppointment.start))
    //     this.$store.commit(`appointments/UPDATE_APPOINTMENT_FIELD`, { duration: diff })
    //   } else {
    //     this.$store.commit(`appointments/UPDATE_APPOINTMENT_FIELD`, { duration: parseInt(val) })
    //   }
    // },
  },
};
</script>

<style lang="scss">

</style>
