import api from "@/utils/api";

export default {
  all: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("/licenses", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  load: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("/licenses/school", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  groups: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("/licenses/groups", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
