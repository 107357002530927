<template>
  <v-card elevation="0" class="event-card" @click="$emit('open')">
    <div class="flex-time">
      <div>
        {{ event.startDate }} | {{ event.startTime }} - {{ event.endTime }}
      </div>
      <div>{{ event.duration }} <v-icon size="small">mdi-clock</v-icon></div>
    </div>

    <span
      v-if="!isStudent && studentsIds.length === 1 && student"
      :class="{ 'font-italic': student.isDeleted }"
      >{{ student.fullName }}</span
    >

    <div v-if="event.specialType">
      {{ $t(`event_type.SPECIAL`) }} ({{
        $t(`label.${event.specialType.toLowerCase()}`)
      }})
    </div>

    <div v-if="event.teacher && !isTeacher">{{ event.teacher }}</div>

    <span>{{ event.title }}</span>

    <div
      class="icon-box d-flex justify-end"
      v-if="!isStudent && studentsIds.length > 1">
      <label>{{ studentsIds.length }}</label>
      <v-icon class="ml-1 icon" size="15">mdi-account-multiple</v-icon>
    </div>
  </v-card>
</template>

<script>
import user from "@/utils/mixins/user";
import StudentModel from "@/store/models/StudentModel";

export default {
  name: "SimulatorSpecial",
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [user],
  computed: {
    studentsIds() {
      return this.event.studentsIds || [];
    },
    student() {
      if (this.$route.name === "students.student.agenda") return null;
      if (this.event.studentsIds.length === 0) return null;
      return StudentModel.find(this.studentsIds[0]);
    },
  },
};
</script>

<style scoped lang="scss">
.event-card {
  padding: 0 5px 5px 5px;
  margin: 5px 0;
  font-size: 0.7rem;
  background: #fff !important;
  border-right: 1px solid #eee;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  .flex-time {
    display: flex;
    justify-content: space-between;
  }
  & > * {
    pointer-events: none;
    font-size: 12px;
    padding: 0 5px;
  }
  .icon-box {
    position: absolute;
    right: 5px;
    bottom: 5px;
    color: #333;
  }
}
</style>
