<template>
  <v-card elevation="0" class="event-card" @click="$emit('open')">
    <div class="flex-time">
      <div>
        {{ event.startDate }} | {{ event.startTime }} - {{ event.endTime }}
      </div>
      <div>{{ event.duration }} <v-icon size="small">mdi-clock</v-icon></div>
    </div>

    <div v-if="event.teacher && !isTeacher">{{ event.teacher }}</div>
    <span v-if="!isStudent && studentsIds.length === 1 && student"
          :class="{ 'font-italic': student.isDeleted }">
      {{ student.fullName }}
    </span>

    <div>
      {{ $t("event_type.PLACEHOLDER") }}
      <span class="mr-2" v-if="licenses.length">
        ({{ licenses.join(", ") }})
      </span>
    </div>

    <div class="mt-1 d-flex" v-if="event.meetingPlace">
      <div class="meeting-place d-flex mr-4">
        <v-icon size="19">mdi-map-marker</v-icon>
        <div class="text-caption text-truncate">{{ event.meetingPlace }}</div>
      </div>

      <div class="d-flex ml-auto">
        <label>{{ studentsIds.length }}</label>
        <v-icon class="ml-1 icon" size="15">mdi-account-multiple</v-icon>
      </div>
    </div>
  </v-card>
</template>

<script>
import user from "@/utils/mixins/user";
import licenses from "@/utils/mixins/licenses";
import StudentModel from "@/store/models/StudentModel";

export default {
  name: "PlaceholderAlert",
  mixins: [user, licenses],
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    studentsIds() {
      return this.event.requests.map((request) => request.studentId);
    },
    student() {
      if (this.$route.name === "students.student.agenda") return null;
      if (this.event.studentsIds.length === 0) return null;
      return StudentModel.find(this.studentsIds[0]);
    },
    licenses() {
      if (!this.licensesById) return [];

      return this.event.requests.map((request) => {
        return this.licensesById[request.licenseId]?.name;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.event-card {
  padding: 0 5px 5px 5px;
  margin: 5px 0;
  font-size: 0.7rem;
  background: #fff !important;
  border-right: 1px solid #eee;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  .flex-time {
    display: flex;
    justify-content: space-between;
  }
  & > * {
    pointer-events: none;
    font-size: 12px;
    padding: 0 5px;
  }
  .request-mark {
    position: absolute;
    bottom: 3px;
    right: 3px;
  }
  .vehicles {
    width: 100%;
  }
  .meeting-place {
    min-width: 0;
  }
}
</style>
