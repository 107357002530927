<template>
  <v-row>
    <v-col>
      <ValidationProvider name="title" rules="required" v-slot="{ errors }">
        <v-text-field
          :error="errors.length > 0"
          v-model="model.title"
          :disabled="disabled"
          :label="$t('form.title')"
          hide-details
          outlined
          dense></v-text-field>
      </ValidationProvider>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TitleField",
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
  },
};
</script>
