import { Model } from "@vuex-orm/core";
import { format, formatISO, parseISO } from "date-fns";
import LicenseModel from '@/store/models/LicenseModel'
export default class StudentHistoryModel extends Model {
  static entity = "studentHistory";

  static historyGroups = {
    events: ["event_created", "event_deleted", "event_updated", "theory_cancelled", "simulator_cancelled"],
    funds: ["manual_funded", "manual_withdrawn"],
    stripe_income: ["funds_added"],
    basicAmount: ["basic_amount_bought"],
    specialProduct: ["special_product_bought", "service_created", "service_deleted"],
    learningMaterials: ["learning_material_bought"],
  };

  static fields() {
    return {
      id: this.attr(null),
      author: this.attr(null),
      createdAt: this.string(formatISO(new Date())),
      eventId: this.attr(null),
      action: this.string(""),
      moneyAmount: this.attr(null),
      balanceAfter: this.attr(null),
      note: this.attr(null),
      productId: this.attr(null),
      licenseId: this.attr(null),
      license: this.belongsTo(LicenseModel, 'licenseId')
    };
  }

  get date() {
    return format(parseISO(this.createdAt), "yyyy-MM-dd");
  }

  get time() {
    return format(parseISO(this.createdAt), "HH:mm");
  }

  get formattedDate() {
    return format(parseISO(this.createdAt), "dd.MM.yyyy");
  }

  get formattedAmount() {
    return this.moneyAmount;
  }

  get historyType() {
    const groups = {
      events: "by_events",
      funds: "by_manual_funds",
      stripe_income: "by_online_payment",
      basicAmount: "by_basic_amount",
      specialProduct: "by_special_products",
      learningMaterials: "by_learning_materials"
    };

    for (let group in groups) {
      if (StudentHistoryModel.historyGroups[group].includes(this.action)) {
        return groups[group];
      }
    }
    return "";
  }

  get icon() {
    const icons = {
      event_created: "mdi-calendar-plus",
      event_deleted: "mdi-calendar-minus",
      event_updated: "mdi-calendar-refresh",
      event_restored: "mdi-restore",
      funds_added: "mdi-cash-plus",
      joker_refunded: "mdi-cash-refund",
      special_product_bought: "mdi-book-plus",
      manual_funded: "mdi-cash-plus",
      manual_withdrawn: "mdi-cash-minus",
      refund: "mdi-cash-refund",
      simulator_cancelled: "mdi-calendar-minus",
      theory_cancelled: "mdi-calendar-minus",
      payment_refunded: "mdi-cash-refund",
      learning_material_bought: "mdi-book-open-page-variant-outline",
      basic_amount_bought: "mdi-cash-register",
      unknown: "mdi-exclamation-thick",
    };
    return this.action in icons ? icons[this.action] : icons.unknown;
  }

  get color() {
    const colors = {
      event_created: "red",
      event_deleted: "green",
      event_updated: this.moneyAmount > 0 ? "green" : "red",
      funds_added: "green",
      joker_refunded: "green",
      special_product_bought: "red",
      manual_funded: "green",
      manual_withdrawn: "red",
      refund: "green",
      simulator_cancelled: "green",
      theory_cancelled: "green",
      payment_refunded: "red",
      learning_material_bought: "red",
      basic_amount_bought: "red",
      unknown: "amber",
    };
    return this.action in colors ? colors[this.action] : colors.unknown;
  }
}
