<template>
<v-dialog
  :value="show"
  max-width="800"
  @click:outside="close"
  @keydown.esc="close"
  scrollable
>
  <v-card>
    <v-toolbar class="mb-2" dark color="primary" dense>
      <v-toolbar-title>{{ title || $t("label.students") }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-list v-if="loading">
            <template v-for="(item, index) in Array.from(Array(3).keys())">
              <v-divider :key="item + '_divider'" inset v-if="index !== 0" />
              <v-skeleton-loader :key="item" type="list-item-avatar" />
            </template>
          </v-list>

          <v-list v-else dense>
            <v-subheader>Active</v-subheader>

            <template v-for="(student, index) in students">
              <v-divider
                v-if="index !== 0"
                :key="student.id + '_divider'"
                inset
              />

              <v-list-item
                :key="student.id"
                :to="{
                  name: 'students.student',
                  params: { id: student.id },
                }"
              >
                <v-list-item-avatar>
                  <UserAvatar :path="student.avatar" :alt="student.fullName" />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ student.fullName }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>

          <empty-box v-if="!loading && students.length === 0"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import UserAvatar from '@/components/UserAvatar.vue'
import StudentModel from '@/store/models/StudentModel'
import studentsService from '@/services/studentsService'
import axios from 'axios'

export default {
  name: 'StudentsActiveRatioListDialog',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  components: { UserAvatar },
  data: () => ({
    show: false,
    studentsIds: [],
    loading: true,
  }),
  computed: {
    students() {
      return StudentModel.query().whereIdIn(this.studentsIds).get();
    },
  },
  methods: {
    open(studentsIds = []) {
      this.show = true;
      this.studentsIds = studentsIds;

      const notLoadedStudentsIds = studentsIds.filter(studentId => !StudentModel.find(studentId));
      const studentsRequests = notLoadedStudentsIds.map((studentId) => studentsService.loadItem(studentId));

      this.loading = true;
      axios
          .all(studentsRequests)
          .then(
              axios.spread((...responses) => {
                StudentModel.insert({ data: responses.map((response) => response.data) });
              })
          )
          .finally(() => (this.loading = false));
    },
    close() {
      this.studentsIds = [];
      this.show = false;
    },
  },
}
</script>

<style scoped>

</style>