<template>
  <v-dialog v-model="show" max-width="700" persistent>
    <v-sheet elevation="2" class="pa-5" style="position: relative">
      <v-system-bar dark color="red" absolute height="10" />
      <p class="mb-5">{{ $t("alert.happy_hours_overlaps") }}</p>
      <table class="error-table">
        <tr v-for="item in overlappedData" :key="item.name" class="table-row">
          <td>{{ licenseName(item.licenseId) }}</td>
          <td class="text-center">{{ lessonTypeName(item.lessonTypeId) }}</td>
          <td class="text-center">{{ `${daysLine(item.days)}` }}</td>
          <td class="text-center">{{ `${item.timeFrom} - ${item.timeTo}` }}</td>
        </tr>
      </table>

      <v-btn icon class="close-error-btn" @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-sheet>
  </v-dialog>
</template>

<script>
import LessonTypeModel from "@/store/models/LessonTypeModel";
import LicenseModel from "@/store/models/LicenseModel";

export default {
  name: "HappyHoursErrorDialog",
  data() {
    return {
      show: false,
      overlappedData: []
    };
  },
  computed: {},
  methods: {
    open(overlappedData) {
      this.show = true;
      this.overlappedData = overlappedData
    },
    close() {
      this.$emit("closeErrorDialog");
      this.show = false;
      this.overlappedData = []
    },
    licenseName(val) {
      const license = LicenseModel.find(val) || {};
      return license.name;
    },
    lessonTypeName(val) {
      const type = LessonTypeModel.find(val) || {};
      return this.$tc(type.name, 1);
    },
    daysLine(val) {
      return val.map((day) => this.$t(`days.${day}`));
    },
  },
};
</script>

<style scoped lang="scss">
.lt {
  font-weight: bold;
}
.error-table {
  width: 95%;
  font-size: 0.8rem;
}
.close-error-btn {
  position: absolute;
  right: 5px;
  top: 10px;
}
</style>
