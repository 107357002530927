<template>
  <v-dialog
      v-model="show"
      persistent
      max-width="500"
      @click:outside="close"
      @keydown.esc="close"
  >
    <v-card :loading="loading">
      <v-card-title class="justify-space-between">
        {{ $t('label.approve_request') }}
        <v-btn icon text @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-subtitle>
        {{ $t('label.for') }} {{ student.name }}, {{ student.chosenLicense }}
      </v-card-subtitle>

      <v-divider/>

      <v-card-text>
        <ValidationObserver ref="form">
          <v-row class="mt-5">
            <v-col cols="12">
              <ValidationProvider
                  name="license"
                  rules="required"
                  v-slot="{ errors }">
                <v-select
                    v-model="form.licenseId"
                    @change="$emit('theoryStudentChange')"
                    :error="errors.length > 0"
                    :label="$t('form.licence')"
                    :placeholder="$t('form.licence')"
                    :items="licenses"
                    item-value="id"
                    item-text="name"
                    dense
                    hide-details
                    outlined
                    :disabled="loading"
                    :menu-props="{ offsetY: true }" />
              </ValidationProvider>

              <v-checkbox
                  v-if="form.licenseId === B197licenseId"
                  v-model="form.isManualLesson"
                  :disabled="loading"
                  :label="$t('lesson_type.manual_lessons')"
                  hide-details
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <ValidationProvider name="lessonTypeId" rules="required" v-slot="{ errors }">
                <v-select
                    v-model="form.lessonTypeId"
                    :error="errors.length > 0"
                    :label="$t('form.lesson_type')"
                    :placeholder="$t('form.lesson_type')"
                    :items="lessonTypes"
                    item-value="id"
                    item-text="name"
                    dense
                    hide-details
                    outlined
                    :disabled="loading"
                    :menu-props="{ offsetY: true }" />
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense class="mt-5">
            <v-col cols="12">
              <VehicleFieldDynamic
                  @emitVehicleChange="(val) => form.primaryVehicleId = val"
                  @emitRequestVehicles="requestPrimaryVehicles"
                  :disabled="loading"
                  :label="$t('form.primary_vehicle')"
                  :loading="primaryVehiclesLoader"
                  :showNoData="!primaryVehiclesIds"
                  :vehiclesList="primaryVehicles"
                  :vehiclesData="primaryVehiclesData"
                  :vehicleProp="appointment.primaryVehicleId"
              />
            </v-col>
          </v-row>

          <v-row dense class="mt-5">
            <v-col cols="12">
              <VehicleFieldDynamic
                  @emitVehicleChange="(val) => form.secondaryVehicleId = val"
                  @emitRequestVehicles="requestSecondaryVehicles"
                  :disabled="loading || !form.primaryVehicleId"
                  :label="$t('form.secondary_vehicle')"
                  :loading="secondaryVehiclesLoader"
                  :showNoData="!secondaryVehiclesIds"
                  :vehiclesList="secondaryVehicles"
                  :vehiclesData="secondaryVehiclesData"
                  :vehicleProp="appointment.secondaryVehicleId"
              />
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-spacer/>
        <v-btn text @click="close">
          {{ $t("btn.cancel") }}
        </v-btn>
        <v-btn color="primary" @click="confirm" :disabled="loading || submitLoading" :loading="submitLoading">
          <v-icon left>mdi-check</v-icon>
          {{ $t("btn.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LicenseModel from '@/store/models/LicenseModel'
import LessonTypeModel from '@/store/models/LessonTypeModel'
import AppointmentFields from '@/helpers/appointmentFields'

import appointmentService from '@/services/appointmentService'
import eventService from '@/services/eventService'
import licensesService from '@/services/licensesService'
import user from '@/utils/mixins/user'
import TeacherModel from '@/store/models/TeacherModel'
import VehicleModel from '@/store/models/VehicleModel'
import { addMinutes, format, parseISO } from 'date-fns'
import VehicleFieldDynamic from '@/components/calendar/appointmentModal/fields/VehicleFieldDynamic.vue'
import vehiclesService from '@/services/vehiclesService'
import SchoolDataLoader from '@/helpers/schoolDataLoader'

export default {
  name: 'AppointmentConfirmDialog',
  components: { VehicleFieldDynamic },
  mixins: [user],
  data: () => ({
    show: false,
    appointment: {},
    student: {},
    licensesLoading: false,
    lessonTypesLoading: false,
    availableVehiclesLoading: false,
    teacherLoading: false,
    studentLoading: false,
    vehicleId: null,
    submitLoading: false,
    initialForm: {},
    form: {
      licenseId: null,
      lessonTypeId: null,
      primaryVehicleId: null,
      secondaryVehicleId: null,
      isManualLesson: false,
      studentsIds: []
    },
    primaryVehiclesIds: null,
    primaryVehiclesData: [],
    secondaryVehiclesIds: null,
    secondaryVehiclesData: [],
    primaryVehiclesLoader: false,
    secondaryVehiclesLoader: false,
  }),
  computed: {
    licenses() {
      const teacherLicenses = this.teacher?.licensesIds || []
      const studentLicenses = this.student?.licensesIds || []
      const teachersAndStudentsLicenses = teacherLicenses.filter(license => studentLicenses.includes(license))

      return LicenseModel.query().whereIdIn( teachersAndStudentsLicenses || []).get()
    },
    lessonTypes() {
      const practiceLessonTypes = LessonTypeModel.query().where('eventTypeId', 'PRACTICE').get() || []

      return practiceLessonTypes.map(lessonType => {
        return {
          ...lessonType,
          name: this.$tc(lessonType.name, 1),
          disabled: false
        }
      })
    },
    loading () {
      return this.licensesLoading ||
          this.lessonTypesLoading ||
          this.availableVehiclesLoading ||
          this.teacherLoading
    },
    B197licenseId () {
      return LicenseModel.query().where('name', 'B197').first()?.id
    },
    teacher() {
      return TeacherModel.find(this.isTeacher ? this.currentUser.id : this.appointment.teacherId)
    },
    primaryVehicles() {
      if (this.primaryVehiclesLoader) return []
      if (!this.primaryVehiclesIds) return VehicleModel.all()
      return VehicleModel.query()
          .where((vehicle) => {
            return (this.primaryVehiclesIds.includes(vehicle.id) && !vehicle.isDeleted)
                || (vehicle.isDeleted && vehicle.id === this.appointment?.primaryVehicleId)
          })
          .get() || []
    },
    secondaryVehicles() {
      if (this.secondaryVehiclesLoader) return []
      const query = VehicleModel.query().where((value) => value.id !== this.form.primaryVehicleId)
      if (!this.secondaryVehiclesIds) return query.get() || []
      return query
          .where((vehicle) => {
            return (this.secondaryVehiclesIds.includes(vehicle.id) && !vehicle.isDeleted)
                || (vehicle.isDeleted && vehicle.id === this.appointment?.secondaryVehicleId)
          })
          .get() || []
    }
  },
  created() {
    this.initialForm = { ...this.form };
  },
  methods: {
    open (appointment, student) {
      this.appointment = appointment
      this.student = student
      this.form.studentsIds = [student.id]
      this.form.isManualLesson = appointment.isManualLesson

      this.show = true;

      this.licensesLoading = true;
      licensesService
          .load()
          .then(response => {
            LicenseModel.create({ data: response.data }).then(() => {
              this.$set(this.form, 'licenseId', LicenseModel.query().where('name', student.chosenLicense).first().id)
            })
          })
          .finally(() => this.licensesLoading = false);

      SchoolDataLoader.load({ method: 'insert', only: ['v'] })

      this.lessonTypesLoading = true;
      eventService
          .lessonTypes()
          .then(response => {
            LessonTypeModel.create({ data: response.data }).then(() => {
              this.$set(this.form, 'lessonTypeId', LessonTypeModel.query().first()?.id)
            })
          })
          .finally(() => this.lessonTypesLoading = false);
    },
    close () {
      this.$refs.form.reset();
      this.show = false;
      setTimeout(() => {
        this.appointment = {}
        this.student = {}
        this.submitLoading = false;
        this.form = { ...this.initialForm };
      }, 200);
    },
    confirm() {
      if (!['PLACEHOLDER', 'PRACTICE'].includes(this.appointment.type)) {
        console.error('Appointment type have to be PLACEHOLDER or PRACTICE')
        return
      }

      const appointmentFields = new AppointmentFields(this.appointment).formObject(this.appointment.type, 'confirm');
      const serviceMethod = `confirm_${this.appointment.type.toLowerCase()}_request`

      this.submitLoading = true;
      appointmentService[serviceMethod](this.appointment.id, { ...appointmentFields, ...this.form })
          .then(() => {
            this.close();
            this.$emit('confirmed');
          })
          .catch((error) => {
            const responseData = error.response.data || {};
            const errors = responseData.errors || [];
            if (Object.keys(errors).length === 0 && responseData.message)
              this.$snackbar.show({
                message: responseData.message,
                color: "error",
              });
            this.$refs.form.setErrors(errors);
          })
          .finally(() => {
            this.submitLoading = false;
          });
    },
    getVehiclesAvailableStatusRequestData(appointment, vehiclesType = 'primary') {
      const exceptAppointmentId = appointment.id
      const teacher = appointment.teacherId
      const teachers = [teacher]
      const from = format(parseISO(appointment.start), 'yyyy-MM-dd HH:mm')
      const to = format(addMinutes(parseISO(appointment.start), appointment.duration), 'yyyy-MM-dd HH:mm')

      const params = { exceptAppointmentId, teachers, teacher, from, to }

      if (vehiclesType === 'primary') {
        params['licenses'] = [this.form.licenseId]
      }

      return params
    },
    clearVehiclesAvailableStatusData(vehicleType = 'primary') {
      if (vehicleType === 'primary') {
        this.primaryVehiclesIds = null
        this.primaryVehiclesData = []
      }
      this.secondaryVehiclesIds = null
      this.secondaryVehiclesData = []
    },
    requestPrimaryVehicles() {
      this.clearVehiclesAvailableStatusData()

      this.primaryVehiclesLoader = true
      vehiclesService
          .getVehiclesWithStatus(this.getVehiclesAvailableStatusRequestData(this.appointment))
          .then((response) => {
            const pluckedIds = response.data.map(vehicle => vehicle.id) || []
            this.primaryVehiclesIds = pluckedIds.filter(vehicleId => vehicleId !== this.appointment.secondaryVehicleId)
            this.primaryVehiclesData = response.data || []
          })
          .finally(() => this.primaryVehiclesLoader = false)
    },
    requestSecondaryVehicles() {
      this.clearVehiclesAvailableStatusData('secondary')

      this.secondaryVehiclesLoader = true
      vehiclesService
          .getVehiclesWithStatus(this.getVehiclesAvailableStatusRequestData(this.appointment,'secondary'))
          .then((response) => {
            const pluckedIds = response.data.map(vehicle => vehicle.id) || []
            this.secondaryVehiclesIds = pluckedIds
                .filter(vehicleId => vehicleId !== this.appointment.secondaryVehicleId)
            this.secondaryVehiclesData = response.data || []
          })
          .finally(() => this.secondaryVehiclesLoader = false)
    },
  }
}
</script>

<style scoped>

</style>
