<template>
  <v-system-bar dark window :color="color" :style="styles">
    <v-icon
        v-if="currentAppointment.id"
        :color="fontColor"
        @click="goToDetailsPage">
      mdi-open-in-new
    </v-icon>
    <span>{{ eventTypeTranslation }}</span>
    <span v-if="happyHour" class="ml-5">
      <v-icon :color="fontColor">mdi-gift</v-icon>
    </span>
    <v-spacer></v-spacer>
    <span class="time-line d-flex">
      <v-icon small :color="fontColor">mdi-clock</v-icon>
      {{ timeLine }}
    </span>
    <span class="ml-5">
      <v-icon :color="fontColor" @click="closeModal">mdi-close</v-icon>
    </span>
  </v-system-bar>
</template>

<script>
import { addMinutes, format, parseISO } from 'date-fns'
import { mapActions, mapGetters } from "vuex";

import colorMixin from "@/utils/mixins/colorMixin";
import user from '@/utils/mixins/user'
import responsive from '@/utils/mixins/responsive'

import AppointmentModel from '@/store/models/AppointmentModel'

export default {
  name: "TopBar",
  mixins: [colorMixin, user, responsive],
  props: {
    color: {
      type: String,
    },
    happyHour: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("appointments", {
      currentAppointment: "currentAppointmentGetter",
    }),
    timeLine() {
      const start = format(parseISO(this.currentAppointment.start), 'HH:mm')
      const end = format(addMinutes(parseISO(this.currentAppointment.start), this.currentAppointment.duration), 'HH:mm')
      const time = `${start} - ${end}`
      const duration = this.mdLandscapeAndUp ? `(${this.currentAppointment.duration} ${this.$tc("minutes", 2)})` : ''
      return `${time} ${duration}`
    },
    fontColor() {
      return this.invertColor(this.color);
    },
    eventTypeTranslation() {
      return AppointmentModel.eventTypeTranslation(
        this.currentAppointment.type
      );
    },
    isFullScreen() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    styles () {
      const styles = this.isFullScreen ? {
        height: 'calc(32px + env(safe-area-inset-top))',
        paddingTop: 'env(safe-area-inset-top)'
      } : {
        height: '32px',
      }

      styles['color'] = this.fontColor;

      return styles
    }
  },
  methods: {
    ...mapActions("appointments", ["closeAppointmentModal"]),
    closeModal() {
      this.closeAppointmentModal();
    },
    goToDetailsPage() {
      sessionStorage.setItem('from', 'calendar');
      this.$router.push({name: 'calendar.appointment', params: {id: this.currentAppointment.id}})
    }
  },
};
</script>

<style lang="scss" scoped>
.time-line{
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
