import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters("school", ['credits', 'id']),
    color() {
      const amount = parseInt(this.credits);

      if (amount >= 30) return 'success';

      if (amount > 10) return 'warning';

      return "error";
    },
  }
}