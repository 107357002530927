<template>
  <v-menu
    v-model="showNotificationMenu"
    :close-on-content-click="false"
    :nudge-width="375"
    :nudge-bottom="5"
    offset-y
    offset-x
    z-index="10"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon large v-on="on">
        <v-badge
          color="red"
          :content="unreadNotificationsCount"
          :value="unreadNotificationsCount > 0"
          overlap
          offset-y="13"
          offset-x="22">
          <v-icon size="23">mdi-bell-ring-outline</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card max-width="420" outlined>
      <v-list-item
        dense
        class="text-uppercase d-flex justify-space-between pr-1"
      >
        <span>{{ $t("notifications.title") }}</span>

        <v-spacer/>

        <v-btn icon :to="{ name: 'personal-settings.notifications' }"><v-icon>mdi-cog</v-icon></v-btn>
      </v-list-item>

      <v-divider />

      <div class="d-flex" v-if="loading" style="min-height: 96px">
        <v-progress-circular
          class="ma-auto align-self-center"
          color="primary"
          indeterminate
          size="30" />
      </div>

      <div
        v-else
        class="notifications"
        ref="notificationsList"
        @scroll="onScrollNotifications">
        <v-list class="pt-0 pb-0" three-line>
          <EmptyBox
            v-if="notifications.length === 0"
            image="assets/svg/megaphone.svg"
            text="notifications.noNotifications" />

          <v-list-item-group v-else multiple>
            <v-list-item
              v-for="notification in notifications"
              :key="notification.id"
              :disabled="!!notification.readAt || isElter"
              @click="onNotificationClick(notification.id)">
              <v-list-item-icon class="mr-3 mt-3">
                <v-icon
                    class="primary--text"
                    :dark="!isElter"
                    size="28"
                    :disabled="isElter"
                >
                  {{ notification.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-if="notification.titleByType" class="mb-2 d-flex justify-space-between">
                  {{ notification.titleByType }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="notification.subtitleByType"
                  class="mb-2 d-flex flex-column">
                  {{ notification.subtitleByType }}
                </v-list-item-subtitle>
                <v-list-item-content
                  v-if="notification.messageByType"
                  class="pa-0 text-body-2">
                  {{ notification.messageByType }}
                </v-list-item-content>
                <v-list-item-subtitle class="mt-2">
                  {{ notification.timeAgo }} {{ $t("ago") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

          <div
            class="py-3 d-flex justify-center progress-block"
            v-if="loadingMore">
            <v-progress-circular
              class="mr-2"
              color="primary"
              indeterminate
              size="23" />
          </div>
        </v-list>
      </div>

      <v-btn v-if="notifications.length > 0" block small @click="markAsReadAll">
        {{ $t("notifications.markAllAsRead") }}
      </v-btn>
    </v-card>
  </v-menu>
</template>

<script>
import responsive from "@/utils/mixins/responsive";
import user from "@/utils/mixins/user";
import notificationsService from "@/services/notificationsService";
import NotificationModel from "@/store/models/NotificationModel";
import EmptyBox from "@/components/EmptyBox";

export default {
  name: "Notifications",
  components: { EmptyBox },
  mixins: [responsive, user],
  data() {
    return {
      loading: false,
      loadingMore: false,
      pagination: {
        currentPage: 1,
        lastPage: 1,
      },
    };
  },
  computed: {
    showNotificationMenu: {
      set(value) {
        this.$store.commit("main/toggle-notification-menu", value);
      },
      get() {
        return this.$store.state.main.showNotificationMenu;
      },
    },
    notifications() {
      return NotificationModel.query().orderBy("createdAt", "desc").get();
    },
    unreadNotificationsCount() {
      return this.$store.state.entities.notifications.totalUnreadCount;
    },
  },
  created() {
    this.loading = true;
    this.fetchNotifications({type: 'create'}).finally(() => {
      this.loading = false;
    });

    NotificationModel.commit((state) => state.totalUnreadCount = this.currentUser.unreadNotificationsCount);
  },
  methods: {
    fetchNotifications({type = 'insert', params = {}}) {
      return new Promise((resolve, reject) => {
        notificationsService
          .load({ ...params, pagination: true })
          .then((response) => {
            const responseData = response.data || {};
            const items = responseData.data || {};

            this.pagination = responseData.pagination || {
              currentPage: 1,
              lastPage: 1,
            };

            NotificationModel[type]({ data: items });

            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    markAsReadAll() {
      notificationsService.markAsRead().then(() => {
        NotificationModel.deleteAll();
        NotificationModel.commit((state) => {
          state.totalUnreadCount = 0;
        });
      });
    },
    markAsRead(notificationId) {
      notificationsService
        .markAsRead({ notificationId: notificationId })
        .then(() => {
          NotificationModel.delete(notificationId);
          NotificationModel.commit((state) => {
            state.totalUnreadCount = this.unreadNotificationsCount - 1;
          });
        })
        .catch((error) => console.log(error));
    },
    onScrollNotifications() {
      const notificationsList = this.$refs.notificationsList;
      const scrollPosition =
        notificationsList.offsetHeight + notificationsList.scrollTop;
      const scrollHeight = notificationsList.scrollHeight;

      if (this.showNotificationMenu) {
        if (
          this.pagination.currentPage < this.pagination.lastPage &&
          !this.loadingMore
        ) {
          if (scrollPosition === scrollHeight) {
            this.loadingMore = true;
            const params = { page: this.pagination.currentPage + 1 }
            this.fetchNotifications({params})
              .finally(() => {
                this.loadingMore = false;

                this.$nextTick(() => {
                  notificationsList.scrollTop =
                    notificationsList.scrollHeight - scrollHeight;
                });
              });
          }
        }
      }
    },
    onNotificationClick(notificationId) {
      const notification = NotificationModel.find(notificationId);

      if (notification) {
        notification.actionByType();
        this.markAsRead(notification.id);
        this.menu = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.progress-block {
  width: 100%;
}
.notifications {
  max-height: calc(100vh - 220px);
  overflow-y: auto;
}
</style>
