<script>
import LicenseModel from '@/store/models/LicenseModel'
import SignatureAutocompleteField from '@/components/SignatureAutocompleteField.vue'
import user from '@/utils/mixins/user'
import studentsService from '@/services/studentsService'

export default {
  name: 'StudentCertification',
  mixins: [user],
  components: { SignatureAutocompleteField },
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    licenseId: null,
    signatureId: null,
    loading: false,
    drivingLicenseNumber: ''
  }),
  computed: {
    license() {
      return this.licenses.find(item => item.id === this.licenseId)
    },
    licenses() {
      return LicenseModel.query()
          .whereIdIn(this.student.licensesIds)
          .where('name', name => ['B96', 'B196', 'B197'].includes(name))
          .get()
    }
  },
  methods: {
    open(licenseId) {
      this.dialog = true
      this.licenseId = licenseId
      this.drivingLicenseNumber = this.student.additionalDetails?.drivingLicenseNumber
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        this.drivingLicenseNumber = null
        this.signatureId = null
        this.loading = false
        this.licenseId = null
      }, 200)
    },
    setActiveLicense(licenseId) {
      this.licenseId = licenseId
    },
    onSignatureSelected(value) {
      this.signatureId = value
    },
    submit() {
      this.loading = true
      studentsService
        .createCertificate(this.student.id, {
          forLicense: this.license.name,
          signatureId: this.signatureId,
          drivingLicenseNumber: this.drivingLicenseNumber
        })
        .then(response => {
          this.$emit('created', response.data || {})

          this.close()
        })
        .catch(error => {
          const response = error.response || {};
          const responseData = response.data || {};

          if (responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error", });
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  }
}
</script>

<template>
  <v-dialog v-model="dialog" width="500" @click:outside="close" @keydown.esc="close" :key="licenses.length">
    <v-card v-if="license">
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>{{ $t("label.certificate_license", { license: license.name }) }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-card-title>
        {{ $t('label.certificate_for') }}
      </v-card-title>

      <v-card-subtitle>
        {{ student.fullName }}
      </v-card-subtitle>

      <v-card-text>
        <v-row v-if="license.name === 'B196'">
          <v-col>
            <h4>{{ $t("label.driving_license_number") }}</h4>

            <v-text-field
                outlined
                dense
                v-model="drivingLicenseNumber"
                :placeholder="$t('form.driving_license_number')"
                autocomplete="cc-csc"
                hide-details
            />
          </v-col>
        </v-row>

        <v-row v-if="isSchoolAdministrator">
          <v-col>
            <SignatureAutocompleteField :key="dialog" @change="onSignatureSelected"/>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn text @click="close">{{$t('btn.close')}}</v-btn>
        <v-spacer />
        <v-btn color="primary" :loading="loading" :disabled="loading" @click="submit">
          <v-icon left>mdi-file-document</v-icon>
          {{ $t("btn.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>
