<script>
import ExpandablePanel from "@/components/ExpandablePanel.vue";
import LastActivityInDaysIndicator from "@/components/LastActivityInDaysIndicator.vue";

export default {
  name: "StudentClickAndLearn",
  components: {LastActivityInDaysIndicator, ExpandablePanel},
  props: {
    student: {
      type: Object,
      required: true
    },
  },
  computed: {
    licenseCode () {
      return this.student.clickAndLearnLicense || {}
    }
  },
  methods: {
    colorByPercentage(percentage) {
      if (percentage === 0 || percentage === null) return 'grey';

      return percentage < 50 ? 'red' : (percentage < 80 ? 'orange' : 'green');
    }
  }
}
</script>

<template>
  <expandable-panel panel-name="studentClickAndLearnPanel" v-if="student.clickAndLearnEnabled">
    <template v-slot:title>
      {{ $t('clickandlearn.feature_name') }}
    </template>
    <template v-slot:end>
      <last-activity-in-days-indicator left :days="licenseCode.lastActivityInDays" />
    </template>
    <v-simple-table dense>
      <tbody>
      <tr>
        <td style="width: 60%">{{ $t("clickandlearn.last_activity") }}</td>
        <td class="pl-0 text-left">{{ licenseCode.lastProgressUpdate }}</td>
      </tr>
      <tr>
        <td>{{ $t("clickandlearn.learning_by_topics") }}</td>
        <td class="pl-0 text-right">
          <v-progress-linear
              dark
              :value="licenseCode.progressByTopics"
              :color="colorByPercentage(licenseCode.progressByTopics)"
              height="20"
          >
            {{ licenseCode.progressByTopics }}%
          </v-progress-linear>
        </td>
      </tr>
      <tr>
        <td>{{ $t("clickandlearn.learning_by_flashcards") }}</td>
        <td class="pl-0 text-right">
          <v-progress-linear
              dark
              :value="licenseCode.progressByFlashcards"
              :color="colorByPercentage(licenseCode.progressByFlashcards)"
              height="20"
          >
            {{ licenseCode.progressByFlashcards }}%
          </v-progress-linear>
        </td>
      </tr>
      <tr>
        <td>{{ $t("clickandlearn.exam_simulation") }}</td>
        <td class="pl-0 text-right">
          <v-progress-linear
              dark
              :value="licenseCode.progressByExams"
              :color="colorByPercentage(licenseCode.progressByExams)"
              height="20"
          >
            {{ licenseCode.progressByExams }}%
          </v-progress-linear>
        </td>
      </tr>
      <tr>
        <td>{{ $t("clickandlearn.license_code") }}</td>
        <td class="text-right pl-0 text-monospace">
          <v-chip small label style="width: 100%" class="d-flex align-center justify-center">
            {{ licenseCode.code }}
          </v-chip>
        </td>
      </tr>
      <tr>
        <td>{{ $t("clickandlearn.license_code_assigned_at") }}</td>
        <td class="text-left pl-0">{{ licenseCode.assignedAt }}</td>
      </tr>
      </tbody>
    </v-simple-table>
  </expandable-panel>
</template>

<style scoped>

</style>