<template>
<ShowsAs
  :type="$vuetify.breakpoint.mobile ? 'dialog' : 'menu'"
  :options="{
    offsetY: true,
    offsetX: true,
    zIndex: 99,
    maxWidth: 300,
    transition: 'dialog-top-transition'
  }"
  ref="showAs"
>
  <template v-slot:activator="{ on: { click } }">
    <v-btn icon large>
      <UserAvatar @click.native="click" :path="currentUser.avatar" :alt="currentUser.firstName + ' ' + currentUser.lastName" />
    </v-btn>
  </template>

  <template v-slot:default="{ close }">
    <v-card outlined>
      <v-row no-gutters dense>
        <v-col cols="11">
          <v-list v-if="currentUser">
            <v-list-item>
              <v-list-item-avatar>
                <UserAvatar :path="currentUser.avatar" :alt="currentUser.fullName" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="mb-1">{{ currentUser.fullName }}</v-list-item-title>
                <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col class="d-flex justify-end" cols="1">
          <v-btn v-if="$vuetify.breakpoint.mobile" class="my-2 mx-2" icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-divider/>

      <v-card-text class="pa-0">
        <ChildrenAccountsList v-if="isElterWithSelectedStudent" @unlinked="onChildUnlinked"/>

        <v-list-item
          :to="{ name: $vuetify.breakpoint.mobile ? 'personal-settings' : 'personal-settings.profile' }"
          @click="close"
        >
          <v-list-item-icon style="margin-right: 15px">
            <v-img width="25" height="25" contain :src="settingsIcon"/>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.personalSettings") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isSchoolAdministrator" :to="{ name: 'support' }" @click="close">
          <v-list-item-icon style="margin-right: 15px">
            <v-img
                contain
                width="25"
                height="25"
                :src="require('@/assets/svg/technical-support-gray.svg')"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.support") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item target="_blank" :href="tutorialLink">
          <v-list-item-icon style="margin-right: 15px">
            <v-img
                contain
                width="25"
                height="25"
                :src="require('@/assets/svg/youtube.svg')"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.videoTutorials") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider/>

        <template v-if="otherAccountsExist">
          <OtherAccountsList/>

          <v-divider/>
        </template>

        <v-list-item :to="{name: 'patch-notes'}" @click="close">
          <v-list-item-icon style="margin-right: 15px">
            <v-img
              :src="require('@/assets/svg/system-update.svg')"
              contain
              height="25"
              width="25"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("route.patch-notes") }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="caption">v.{{ appVersion }}</v-list-item-action>
        </v-list-item>

        <v-list-item @click="logoutUser">
          <v-list-item-icon style="margin-right: 15px">
            <v-img :src="exitIcon" contain height="25" width="25" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("menu.logout") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
    </v-card>
  </template>
</ShowsAs>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import user from "@/utils/mixins/user";
import responsive from "@/utils/mixins/responsive";
import UserAvatar from "@/components/UserAvatar";
import ShowsAs from "@/components/ShowsAs.vue";
import appVersion from "@/utils/mixins/appVersion";
import OtherAccountsList from "@/components/OtherAccountsList.vue";
import ChildrenAccountsList from './ChildrenAccountsList.vue'


export default {
  name: "UserMenu",
  components: { ChildrenAccountsList, OtherAccountsList, ShowsAs, UserAvatar },
  mixins: [responsive, user, appVersion],
  data() {
    return {
      switching: null,
      unlinking: null,
      settingsIcon: require("../assets/svg/gears-gray.svg"),
      exitIcon: require("../assets/svg/exit-gray.svg"),
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    tutorialLink() {
      let href =
        "https://www.youtube.com/playlist?list=PLr4ebpQfu3_WUecDS25lC6KQ9zajXItXN";
      if (this.isTeacher)
        href =
          "https://www.youtube.com/playlist?list=PLr4ebpQfu3_WM5wGQr83yQz5yfGpn_HNr";
      if (this.isStudent)
        href =
          "https://www.youtube.com/playlist?list=PLr4ebpQfu3_UMDhrmxb7tYUJz7DlznsDO";
      return href;
    },
    otherAccountsExist() {
      return localStorage.getItem("otherAccounts") !== null;
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    async logoutUser() {
      try {
        await this.logout();

        if (this.$refs.showAs) this.$refs.showAs.close();

        await this.$router.push({ name: "login" });
      } catch (error) {
        console.log("error", error);
        const responseData = error.response.data;

        if (responseData.message) {
          this.$snackbar.show({ message: responseData.message, color: "error" });
        }
      }
    },
    onChildUnlinked() {
      this.$refs.showAs.close();
    },
  },
};
</script>
