<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "ClickAndLearnWhite",
  computed: {
    clickAndLearn() {
      return require('@/assets/img/ClickAndLearn-logo-white.png')
    }
  },
})
</script>

<template>
  <img :src="clickAndLearn" alt="">
</template>
