<template>
  <v-dialog
    v-model="show"
    width="1010"
    @click:outside="close"
    @keydown.esc="close"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    scrollable
    eager
  >
    <v-card :loading="loading">
      <v-toolbar class="mb-2 flex-grow-0" dark color="primary" dense>
        <v-toolbar-title>{{ form.id ? $t("label.edit_service_template") : $t("label.create_service_template") }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-card-text>
        <ValidationObserver ref="form">
          <v-row class="mt-5" dense>
            <v-col cols="12">
              <ValidationProvider v-slot="{ errors }" name="description">
                <v-text-field
                  v-model="form.description"
                  :placeholder="$t('label.description')"
                  solo
                  :error-messages="errors"
                  required
                />
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" lg="4">
              <ValidationProvider v-slot="{ errors }" name="price">
                <v-text-field
                  solo
                  :placeholder="$t('label.cost')"
                  prepend-inner-icon="mdi-currency-eur"
                  v-model="form.price"
                  :error-messages="errors"
                  v-currency="{
                     currency: null,
                    allowNegative: false,
                    valueAsInteger: false,
                    precision: 2,
                  }"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" lg="4">
              <ValidationProvider v-slot="{ errors }" name="taxRate">
                <v-select
                  solo
                  :placeholder="$t('label.tax_rate')"
                  prepend-inner-icon="mdi-percent-outline"
                  :items="percentages"
                  v-model="form.taxRate"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" lg="4">
              <ValidationProvider v-slot="{ errors }" name="licenseId">
                <v-select
                  clearable
                  solo
                  :placeholder="$t('label.license')"
                  prepend-inner-icon="mdi-caps-lock"
                  :items="licensesItems"
                  item-value="id"
                  item-text="name"
                  v-model="form.licenseId"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
          </v-row>

        </ValidationObserver>
      </v-card-text>

      <v-divider class="mt-auto" />

      <v-card-actions>
        <v-btn class="mr-2" text @click="close">{{ $t("btn.close") }}</v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          @click="clickBtn"
          :disabled="loading"
          :loading="loading"
        >
          <v-icon left>{{ form.id ? 'icon mdi-content-save-edit' : 'mdi-content-save' }}</v-icon>
          {{ $t("btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import LicenseModel from "@/store/models/LicenseModel";
import studentServiceTemplatesService from '@/services/studentServiceTemplatesService'
import currency from '@/utils/mixins/currency'

export default {
  name: "ServiceTemplateDialog",
  mixins: [currency],
  data: () => ({
    show: false,
    loading: false,
    percentages: [
      { text: "19%", value: 19 },
      { text: "7%", value: 7 },
      { text: "0%", value: 0 },
    ],
    licenses: [],
    form: {
      id: null,
      description: null,
      price: null,
      taxRate: null,
      licenseId: null,
    },
  }),
  methods: {
    open(data) {
      this.show = true;
      if (data) {
        this.form.id = data.id
        this.form.description = data.description
        this.form.taxRate = data.taxRate
        this.form.price = this.sanitizePrice(data.price, ',')
        this.form.licenseId = data.licenseId
      }
    },
    close() {
      this.$refs.form.reset();
      this.form.id = null
      this.form.description = null
      this.form.taxRate = null
      this.form.price = null
      this.form.licenseId = null
      this.loading = this.show = false;
    },
    clickBtn() {
      this.form.id ? this.update() : this.save()
    },

    save() {
      this.loading = true;
      const price = (this.form.price === null || this.form.price === '') ? null : this.sanitizePrice(this.form.price)
      const payload = { ...this.form, price: price}
      studentServiceTemplatesService
        .create(payload)
        .then((resp) => {
          this.$emit("create", resp.data);
          this.close();
        })
        .catch((error) => {
          this.$refs.form.setErrors(error.response.data.errors);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.loading = true;
      const price = (this.form.price === null || this.form.price === '') ? null : this.sanitizePrice(this.form.price)
      const payload = { ...this.form, price: price}
      studentServiceTemplatesService
        .update(this.form.id, payload)
        .then((resp) => {
          this.$emit("update", resp.data);
          this.close();
        })
        .catch((error) => {
          this.$refs.form.setErrors(error.response.data.errors);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    licensesItems() {
      return LicenseModel.all() || {};
    },
  },
};
</script>

<style scoped lang="scss"></style>
