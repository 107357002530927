<template>
  <v-card :loading="loading" :disabled="disabled">
    <v-img height="100px" :src="image" eager />
    <v-card-title>{{ $t("label.documents") }}</v-card-title>

    <v-card-subtitle class="student-card">
      {{ $t('label.student_card_documents_description') }}
    </v-card-subtitle>

    <v-card-text v-if="!disabled">
      <v-skeleton-loader v-if="loading" type="list-item-two-line" />
      <v-list v-else>
        <v-list-item class="pa-0 mb-2">
          <v-list-item-content class="pa-0">
            <v-list-item-title>{{ $t('label.uploaded_documents') }}</v-list-item-title>
            <v-list-item-subtitle>
              <span v-for="documentType in documentsTypes" :key="documentType.value">
                <v-icon
                  v-if="isDocumentUploaded(documentType)"
                  small
                  color="green"
                >
                  mdi-check
                </v-icon>
                {{ $t(documentType.text) }},
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>

    <v-card-actions>
      <v-btn
        class="ml-auto"
        small
        text
        :to="{ name: `students.student.documents` }"
      >
        {{ $t("btn.open") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import cardsService from "@/services/cardsService";

export default {
  name: "StudentDocumentsCard",
  props: {
    studentId: {
      type: [Number, String],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    image: require("@/assets/img/documents.jpg"),
    documentsTypes: [
      { value: "BIOMETRIC_PASSPORT_PICTURE", text: "label.biometric_passport" },
      { value: "FIRST_AID", text: "label.first_aid" },
      { value: "EYE_TEST_CERTIFICATE", text: "label.eyes_test" },
    ],
    requiredDocuments: [],
  }),
  watch: {
    disabled(value) {
      if (!value) this.init();
    },
  },
  mounted() {
    if (!this.disabled) this.init();
  },
  methods: {
    init() {
      this.loading = true;
      cardsService
        .studentDocuments(this.studentId)
        .then((response) => {
          const responseData = response.data || {};
          this.requiredDocuments = responseData.requiredDocuments || [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    isDocumentUploaded(document) {
      return this.requiredDocuments.find(
        (requiredDocument) => requiredDocument.key === document.value
      );
    },
  },
};
</script>

<style scoped></style>
