export default {
  methods: {
    sanitizePrice(price, decimal = ".") {
      if (!price) return 0;

      const stringPrice = String(price)

      if (decimal === ",") {
        return stringPrice.replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
      return parseFloat(stringPrice.replace(".", "").replace(",", "."));
    },
  },
};
