<template>
  <div class="timepicker-parent">
    <ValidationProvider v-slot="{ errors }" :name="name">
      <v-text-field
        :disabled="disabled"
        :label="label"
        @click="showPicker"
        v-model="time"
        menu-props="auto"
        outlined
        readonly
        hide-details
        dense
        :error="!!errors.length" />

      <span class="red--text text-caption" v-if="errors.length > 0">{{
        errors[0]
      }}</span>
    </ValidationProvider>
    <div
      class="time-menu"
      v-if="visiblePicker"
      v-click-outside="onClickOutside">
      <ul>
        <li
          :key="index"
          v-for="(item, index) in timeFields"
          @click="selectHour(item.value)"
          :class="item.disabled && 'disabled'">
          <span :class="`hours ${pickedHour ? 'picked' : ''}`">{{
            item.hours
          }}</span
          >:<span :class="`minutes ${pickedHour ? 'picked' : ''}`">{{
            item.minutes
          }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ClickOutside } from "vuetify/lib/directives";
export default {
  name: "TimePicker",
  directives: { ClickOutside },
  data() {
    return {
      datePicker: false,
      visiblePicker: false,
      pickedHour: null,
      pickedField: null,
      eventDuration: 45,
      date: new Date(),
      time: "",
    };
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    error: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "07:00",
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    timeFields() {
      const timeArr = [];
      if (!this.pickedHour) {
        for (let i = 0; i < 24; i++) {
          const zeroFormat = i < 10 ? `0${i}` : i;

          timeArr.push({ value: zeroFormat, hours: zeroFormat, minutes: "00" });
        }
      } else {
        for (let i = 0; i < 60; i = i + 5) {
          const zeroFormat = i < 10 ? `0${i}` : i;

          timeArr.push({
            value: `${this.pickedHour}:${zeroFormat}`,
            hours: this.pickedHour,
            minutes: zeroFormat,
          });
        }
      }
      return timeArr;
    },
  },
  created() {
    this.time = this.value;
  },
  methods: {
    onClickOutside() {
      this.visiblePicker = false;
      this.pickedHour = null;
      this.pickedField = null;
    },
    showPicker(field) {
      this.visiblePicker = true;
      this.pickedField = field;
    },
    selectHour(value) {
      if (!this.pickedHour) {
        this.pickedHour = value;
      } else {
        this.time = value;
        this.visiblePicker = false;
        this.pickedHour = null;
        this.pickedField = null;

        this.$emit("input", this.time);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.timepicker-parent {
  width: 100%;
  position: relative;
  background: #fff;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
  text-decoration: line-through;
}

.time-menu {
  height: 245px;
  width: 100%;
  position: absolute;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
  left: 0;
  top: 40px;
  background: #fff;
  overflow-x: auto;
  z-index: 99;

  .hours {
    color: #333;
    &.picked {
      font-weight: bold;
    }
  }

  .minutes {
    color: #c7c7c7;
    &.picked {
      color: #333;
    }
  }

  ul {
    width: 100%;
    list-style: none;
    text-align: center;
    padding: 0;

    li {
      cursor: pointer;
      border-bottom: 1px solid #eee;
      padding: 2px;

      &:hover {
        background: #eee;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
