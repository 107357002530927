<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    @keydown.esc="close"
    scrollable
  >
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>{{ $t("label.education_contract") }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-card-title>
        {{ $t('label.education_contract_for') }}
      </v-card-title>

      <v-card-subtitle>
        {{ student.fullName }}
      </v-card-subtitle>

      <v-card-text>
        <v-row v-if="!student.priceCategoryId" dense>
          <v-col>
            <h4>{{ $t('label.price_category') }}</h4>

            <v-select
              :placeholder="$t('label.select_price_category')"
              :items="priceCategories"
              v-model="form.priceCategoryId"
              item-value="id"
              item-text="name"
              outlined
              dense
              hide-details
              @change="setPickedLicenses(form.pickedLicenseIds)"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <h4>{{ $t("form.for_license") }}</h4>

            <v-select
              class="mb-3"
              multiple
              outlined
              dense
              hide-details
              :placeholder="$t('form.select_category')"
              :items="availableLicense"
              :value="form.pickedLicenseIds"
              item-value="id"
              item-text="name"
              :loading="loadingPrices"
              :disabled="loadingPrices"
              @change="setPickedLicenses"
            />
          </v-col>
        </v-row>

        <template v-if="pickedLicenses.length">
          <v-row :dense="$vuetify.breakpoint.mdAndDown">
            <v-col cols="12" md="4">
              <h4 class="text-truncate">{{ $t('one_time_product.basic_amount_full') }}</h4>

              <v-text-field
                  class="mb-1"
                  placeholder="0,00"
                  outlined
                  dense
                  v-model="form.costs[pickedLicenses[0].id]['basicAmountFull']"
                  :label="pickedLicenses[0].name"
                  append-icon="mdi-currency-eur"
                  v-currency="{
                    currency: null,
                    allowNegative: false,
                    valueRange: { min: 0 },
                  }"
                  hide-details
              />

              <h4 class="text-truncate" v-if="pickedLicenses.slice(1).length > 0">{{ $t('one_time_product.basic_amount_partial') }}</h4>

              <v-text-field
                v-for="license in pickedLicenses.slice(1)"
                :key="license.id"
                class="mb-3"
                placeholder="0,00"
                outlined
                dense
                v-model="form.costs[license.id]['basicAmountPartial']"
                :label="license.name"
                append-icon="mdi-currency-eur"
                v-currency="{
                  currency: null,
                  allowNegative: false,
                  valueRange: { min: 0 },
                }"
                hide-details
              />
            </v-col >

            <v-divider v-if="$vuetify.breakpoint.mdAndUp" class="mt-7 mb-6" vertical/>

            <v-col cols="12" md="4">
              <h4 class="text-truncate">{{ $t('one_time_product.learning_material') }}</h4>

              <template v-for="(license, index) in pickedLicenses" >
                <v-text-field
                  :key="license.id"
                  :class="index === 0 && $vuetify.breakpoint.mdAndUp ? 'mb-1' : 'mb-3'"
                  placeholder="0,00"
                  outlined
                  dense
                  v-model="form.costs[license.id]['learningMaterialAmount']"
                  :label="license.name"
                  append-icon="mdi-currency-eur"
                  v-currency="{
                    currency: null,
                    allowNegative: false,
                    valueRange: { min: 0.0 },
                  }"
                  hide-details
                />

                <h4 v-if="index === 0 && $vuetify.breakpoint.mdAndUp" :key="'h4' + license.id">&nbsp;</h4>
              </template>
            </v-col>

            <v-divider v-if="$vuetify.breakpoint.mdAndUp" class="mt-7 mb-6" vertical/>

            <v-col cols="12" md="4">
              <h4 class="text-truncate">{{ $t('label.missed') }}</h4>

              <template v-for="(license, index) in pickedLicenses" >
                <v-text-field
                    :key="license.id"
                    :class="index === 0 && $vuetify.breakpoint.mdAndUp ? 'mb-1' : 'mb-3'"
                    placeholder="0,00"
                    outlined
                    dense
                    v-model="form.costs[license.id]['missed']"
                    :label="license.name"
                    append-icon="mdi-currency-eur"
                    v-currency="{
                      currency: null,
                      allowNegative: false,
                      valueRange: { min: 0.0 },
                    }"
                    hide-details
                />

                <h4 v-if="index === 0 && $vuetify.breakpoint.mdAndUp" :key="'h4' + license.id">&nbsp;</h4>
              </template>
            </v-col>

          </v-row>

          <v-row dense>
            <v-col>
              <SignatureAutocompleteField :signatureId="form.signatureId" @change="onSignatureSelected"/>
            </v-col>
          </v-row>
        </template>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn text @click="close">{{$t('btn.close')}}</v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          download
          :loading="loading"
          @click="getContract"
          :disabled="submitDisabled"
        >
          <v-icon left>mdi-file-document</v-icon>
          {{ $t("btn.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import studentsService from "@/services/studentsService";
import currency from "@/utils/mixins/currency";
import SignatureAutocompleteField from '@/components/SignatureAutocompleteField.vue'
import LicenseModel from "@/store/models/LicenseModel";
import ProductCategoryModel from "@/store/models/ProductCategoryModel";
import OneTimeProductModel from '@/store/models/OneTimeProductModel'
import pricesService from '@/services/pricesService'
import ProductPriceModel from '@/store/models/ProductPriceModel'

export default {
  name: "StudentContract",
  components: { SignatureAutocompleteField },
  mixins: [currency],
  props: {
    student: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      loadingPrices: false,
      licenses: [],
      form: {
        costs: {},
        pickedLicenseIds: [],
        priceCategoryId: null,
        signatureId: null
      }
    };
  },
  watch: {
    dialog(value) {
      if (value) {
        this.form.priceCategoryId = this.student.priceCategoryId;

        this.loadingPrices = true;
        pricesService
          .load({
            priceCategoryId: this.student.priceCategoryId,
            licensesIds: this.student.licensesIds,
            productType: "one-time-product"
          })
          .then(response => {
            ProductPriceModel.create({ data: response.data });
          })
          .finally(() => {
            this.loadingPrices = false
          })
      }
    }
  },
  computed: {
    pickedLicenses() {
      return this.availableLicense
        .filter(license => this.form.pickedLicenseIds.includes(license.id))
        .sort((prev, next) => {
          return this.form.pickedLicenseIds.indexOf(prev.id) - this.form.pickedLicenseIds.indexOf(next.id)
        })
        .map(license => ({
          id: license.id,
          name: license.name,
          prices: this.licenses.find(item => item.id === license.id)?.prices || []
        }))
    },
    submitDisabled() {
      return (
        (!this.student.priceCategoryId && !this.form.priceCategoryId) ||
        this.form.pickedLicenseIds.length === 0 ||
        this.loading
      );
    },
    availableLicense() {
      const studentLicenses = this.student?.licensesIds || []
      return LicenseModel.query().where((item) => studentLicenses.includes(item.id)).get()
    },
    priceCategories() {
      return ProductCategoryModel.all();
    },
  },
  methods: {
    setPickedLicenses(value) {
      this.form.pickedLicenseIds = value

      this.form.costs = {}
      this.pickedLicenses.forEach((item, index) => {
        // Use this.$set() to ensure reactivity when updating the form.costs object
        this.$set(this.form.costs, item.id, {
          basicAmountFull: index === 0 ? this.getProductPrice(item.id, 'basic_amount_full') : 0,
          basicAmountPartial: index > 0 ? this.getProductPrice(item.id, 'basic_amount_partial') : 0,
          learningMaterialAmount: this.getProductPrice(item.id, 'learning_material'),
          missed: this.getProductPrice(item.id, 'missed')
        })
      })
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.loading = false;
      setTimeout(() => {
        this.form = {
          costs: {},
          pickedLicenseIds: [],
          priceCategoryId: null,
          signatureId: null
        }
      }, 200);
    },
    onSignatureSelected(value) {
      this.form.signatureId = value
    },
    getProductPrice(licenseId, productKey) {
      const product = OneTimeProductModel.query().with('prices').where('key', productKey).first()

      if (!product) return null

      const price = product.prices.
        filter(item => item.licenseId === licenseId && item.priceCategoryId === this.form.priceCategoryId)[0]?.price

      return parseFloat(price) ? this.sanitizePrice(price,',') : null
    },
    getContract() {
      this.loading = true;
      studentsService
        .downloadEducationContract(this.student.id, {
          licenses: this.form.pickedLicenseIds.map(item => ({
            id: item,
            basicAmountFull: this.sanitizePrice(this.form.costs[item]['basicAmountFull'],'.'),
            basicAmountPartial: this.sanitizePrice(this.form.costs[item]['basicAmountPartial'],'.'),
            learningMaterialAmount: this.sanitizePrice(this.form.costs[item]['learningMaterialAmount'],'.'),
            missed: this.form.costs[item]['missed'] ? this.sanitizePrice(this.form.costs[item]['missed'],'.') : null
          })),
          priceCategoryId: this.form.priceCategoryId,
          signatureId: this.form.signatureId
        })
        .then((response) => {
          this.$emit('created', response.data || {})
          this.close()
        })
        .catch((error) => {
          const responseData = error.response.data || {};

          if (responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error" });
          }
        })
        .finally(() => {
          this.close()
        });
    }
  },
};
</script>

<style scoped lang="scss"></style>
