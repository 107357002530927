import { Model } from "@vuex-orm/core";
import { addMinutes, format, isPast, parseISO } from 'date-fns'
import LessonTypeModel from "@/store/models/LessonTypeModel";
import LicenseModel from "@/store/models/LicenseModel";
import EventTypeModel from "@/store/models/EventTypeModel";
import store from "@/store";
import i18n from "@/plugins/i18n";
import TeacherModel from "@/store/models/TeacherModel";
import TheoryTopicModel from "@/store/models/TheoryTopicModel";
import VehicleModel from '@/store/models/VehicleModel'
import StudentModel from "@/store/models/StudentModel";
// import StudentModel from "@/store/models/StudentModel";

export default class AppointmentModel extends Model {
  static entity = "appointments";

  static TYPES = {
    OFFTIME: "OFFTIME",
    PLACEHOLDER: "PLACEHOLDER",
    PRACTICE: "PRACTICE",
    THEORY: "THEORY",
    SPECIAL: "SPECIAL",
    SIMULATOR: "SIMULATOR",
  };

  static state() {
    return {
      loading: false,
    };
  }

  static fields() {
    return {
      id: this.attr(null),
      type: this.string(""),
      start: this.string(""),
      duration: this.attr(null),
      lessonDuration: this.attr(null),
      lessonsAmount: this.attr(null),
      teacherId: this.attr(null),
      teacherName: this.attr(null),
      teacher: this.belongsTo(TeacherModel, "teacherId"),
      studentId: this.attr(null),
      student: this.belongsTo(StudentModel, "studentId"),
      studentsIds: this.attr([]),
      studentName: this.string(""),
      lessonTypeId: this.attr(null),
      group: this.attr(null),
      isManualLesson: this.attr(null),
      status: this.attr(null),
      licenseId: this.attr(null),
      allowedLicenses: this.attr([]),
      notificationLicenses: this.attr([]),
      hasMultipleRequestsAllowed: this.attr(null),
      isRequested: this.boolean(false),
      primaryVehicleId: this.attr(null),
      secondaryVehicleId: this.attr(null),
      hasSignature: this.attr(null),
      roomSize: this.attr(0),
      isSubscribed: this.attr(null),
      theoryTopicId: this.attr(null),
      simulatorName: this.attr(null),
      officeId: this.attr(null),
      specialName: this.attr(null),
      specialType: this.attr(null),
      isWorkTime: this.attr(null),
      meetingPlace: this.attr(null),
      notes: this.attr(""),
      deletedAt: this.attr(null),
      inProgress: this.boolean(false),
      evaluation: this.attr(null),
      isAvailableForEvaluation: this.boolean(false),
      passedExam: this.boolean(false),
      createdAt: this.attr(null),
      createdBy: this.attr(null),
      boughtAt: this.attr(null)
    };
  }

  get teacherFullName() {
    return this.teacher?.fullName;
  }

  get lessonType() {
    const currentUser = this.$store().state.auth.authUser;
    const isStudent = currentUser.type === "student" || currentUser.type === "elter";
    const lessonType = LessonTypeModel.find(this.lessonTypeId);

    if (isStudent && lessonType?.name === 'lesson_type.gratis_exam') {
      return LessonTypeModel
        .query()
        .where('name', 'lesson_type.exam')
        .first()
    }

    return lessonType
  }

  get lessonTypeName() {
    return LessonTypeModel.find(this.lessonTypeId)?.name
  }

  get primaryVehicle() {
    return VehicleModel.find(this.primaryVehicleId) || null;
  }

  get secondaryVehicle () {
    return VehicleModel.find(this.secondaryVehicleId) || null;
  }

  get theoryTopic() {
    return TheoryTopicModel.find(this.theoryTopicId) || null
  }

  get license() {
    return LicenseModel.find(this.licenseId);
  }

  get allowedLicensesList() {
    return LicenseModel.query()
      .where(item => this.allowedLicenses.includes(item.id.toString()))
      .get();
  }

  get isManualB197() {
    return this.isManualLesson && this.license.isB197
  }

  get startTime() {
    return format(parseISO(this.start), "HH:mm");
  }
  get endTime() {
    return format(this.endDate, "HH:mm");
  }

  get startDate() {
    return format(parseISO(this.start), "yyyy-MM-dd");
  }
  get endDate() {
    const startDate = parseISO(`${this.startDate} ${this.startTime}`);
    return addMinutes(startDate, this.duration);
  }

  get date() {
    return format(parseISO(this.start), "dd.MM.yyyy");
  }

  get formattedDate() {
    return format(parseISO(this.start), "dd.MM.yyyy");
  }

  get formattedDateTime() {
    return format(parseISO(this.start), "dd.MM.yyyy HH:mm");
  }

  get bodyColor() {
    if (this.lessonType) return this.lessonType.bodyColor;
    return EventTypeModel.find(this.type).bodyColor
  }
  get fontColor() {
    if (this.lessonType) return this.lessonType.fontColor;
    return EventTypeModel.find(this.type).fontColor
  }

  get practiceColors() {
    const currentUser = this.$store().state.auth.authUser;
    const isStudent = currentUser.type === "student";
    const nonBlocked = this.studentsIds.includes(currentUser.id);
    if (!isStudent || (isStudent && nonBlocked)) {
      switch (this.status) {
        case "ACCEPTED":
          return "lesson-event";
        case "REQUESTED":
          return "requested-event";
        case "DECLINED":
          return "declined-event";
        default:
          return "requested-event";
      }
    } else {
      return "blocked-time-event";
    }
  }

  get eventTypeModel() {
    return EventTypeModel.find(this.type) || {};
  }

  static eventTypeTranslation(eventType) {
    const currentUser = store.state.auth.authUser;

    if (currentUser.type === "student" && eventType === "PLACEHOLDER") {
      return i18n.t("event_type.student.PLACEHOLDER");
    }

    return i18n.t(`event_type.${eventType}`);
  }

  get isTrashed() {
    return this.deletedAt !== null;
  }

  get eventTypeTranslation() {
    return AppointmentModel.eventTypeTranslation(this.type);
  }

  get isTheoryFull() {
    return this.roomSize === this.studentsIds.length;
  }

  get isPast() {
    return isPast(parseISO(this.start));
  }

  get isOfftime() {
    return this.type === AppointmentModel.TYPES.OFFTIME;
  }

  get isPlaceholder() {
    return this.type === AppointmentModel.TYPES.OFFTIME;
  }

  get isPractice() {
    return this.type === AppointmentModel.TYPES.PRACTICE;
  }

  get isSpecial() {
    return this.type === AppointmentModel.TYPES.SPECIAL;
  }

  get isSimulator() {
    return this.type === AppointmentModel.TYPES.SIMULATOR;
  }

  get isTheory() {
    return this.type === AppointmentModel.TYPES.THEORY;
  }

  get isAccepted() {
    return this.status === "ACCEPTED";
  }

  get isMissed() {
    return this.status === "MISSED";
  }

  get isMissedRefunded() {
    return ["MISSED_REFUNDED", "MISSED_PARTIALLY_REFUNDED", "JOKER_REFUNDED"].includes(this.status);
  }

  get placeholderStudentsIds() {
    return this.studentsIds.map(item => item.id)
  }

  get isDrivingWorkingTime() {
    return !this.isNotWorkingTime &&
      this.type === "PRACTICE" &&
      this.status === "ACCEPTED" &&
      this.lessonType?.isDrivingWorktime
  }

  get isOverallWorkingTime() {
    const isTheory = this.type === "THEORY";
    const isSpecial = this.type === "SPECIAL" && this.isWorkTime;

    let isPractice = this.type === "PRACTICE" && this.isAccepted;
    if (this.$store().state.school.includeMissedLessonsInWorkingTime) {
      isPractice = this.type === "PRACTICE" && (this.isAccepted || this.isMissed || this.isMissedRefunded);
    }

    return !this.isNotWorkingTime && (isTheory || isPractice || isSpecial);
  }

  get isIncludedInNormalDurationTime () {
    return this.group === 'normal' && this.status === 'ACCEPTED';
  }

  get isIncludedInSpecialDurationTime () {
    return this.group === 'special' ||
    this.type === 'PLACEHOLDER' ||
    this.status === 'REQUESTED';
  }

  get isNotWorkingTime() {
    return this.lessonType?.name === 'lesson_type.planned_exam'
  }

  isPlaceholderRequested() {
    return this.type === "PLACEHOLDER" && this.isRequested;
  }

  isPracticeRequested() {
    return this.type === "PRACTICE" && this.status === "REQUESTED";
  }

  get practiceStatusIcon() {
    const isRefundStatus = ['MISSED_PARTIALLY_REFUNDED', 'MISSED_REFUNDED', 'JOKER_REFUNDED'].includes(this.status)

    if (isRefundStatus) return 'mdi-cash-refund'
    if (this.status === 'MISSED') return 'mdi-call-missed'

    return ''
  }
}
