<script>
import user from '@/utils/mixins/user'
import UserAvatar from './UserAvatar.vue'
import { showConfirm } from '@/helpers/dialogs'
import childrenAccountsService from '@/services/childrenAccountsService'
import ChildModel from '@/store/models/ChildModel'
import { mapActions } from 'vuex'
import AddChildAccountDialog from './parent/AddChildAccountDialog.vue'

export default {
  name: 'ChildrenAccountsList',
  components: { AddChildAccountDialog, UserAvatar },
  mixins: [user],
  data() {
    return {
      switching: null,
      unlinking: null,
    }
  },
  computed: {
    loading() {
      return this.$store.state.children.loadingChildren
    },
    children() {
      return ChildModel.query().get() || []
    },
  },
  mounted() {
    this.fetchChildren()
  },
  methods: {
    ...mapActions("children", ['fetchChildren']),
    ...mapActions("auth", ["clearParentSelectedStudent", "setParentSelectedStudentId"]),
    isCurrentlySelectedChild(childId) {
      return parseInt(this.$store.state.auth.parentSelectedStudentId) === childId
    },
    approvedStatus(child) {
      return child.isPermissionGranted || !child.is18yo;
    },
    openAddChildAccountDialog() {
      this.$refs.addChildAccountDialog.open();
    },
    async switchChild(studentId) {
      this.switching = studentId;

      this.setParentSelectedStudentId(studentId);

      this.$nextTick(() => {
        location.reload()
      })
    },
    unlinkChild(studentId) {
      showConfirm(
          this.$t("btn.confirm"),
          this.$t("messages.are_your_sure_unlink_child"),
          () => {
            this.unlinking = studentId;
            childrenAccountsService
                .unlinkStudentFromParent(studentId)
                .then(() => {
                  ChildModel.delete(studentId)
                  this.$emit('unlinked')
                  this.$root.refresh()
                  if (this.parentSelectedStudentId === studentId) {
                    this.clearParentSelectedStudent();
                    this.$router.push({ name: "children-accounts" });
                  }
                })
                .catch((error) => {
                  const response = error.response || {};
                  const responseData = response.data || {};

                  if (responseData.message) {
                    this.$snackbar.show({ message: responseData.message, color: "error" });
                  }
                })
                .finally(() => {
                  this.unlinking = null;
                });
          });
    },
    isSwitchToDisabled(child) {
      return !!this.switching ||
          this.isCurrentlySelectedChild(child.id) ||
          !this.approvedStatus(child) ||
          !child.privacyPolicyAccepted
    }
  }
}
</script>

<template>
<v-list dense subheader>
  <v-subheader>{{ $t('label.children_accounts') }}</v-subheader>

  <v-list-group
      v-for="child in children"
      :key="child.id"
      :class="{'red lighten-5': !child.privacyPolicyAccepted}"
      no-action
      @click.stop.native
  >
    <template v-slot:activator>
      <v-list-item-avatar>
        <UserAvatar :alt="child.name" :path="child.avatar" :size="30" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title class="mb-1">{{ child.name }}</v-list-item-title>
      </v-list-item-content>
    </template>

    <v-list-item
        class="white"
        :disabled="isSwitchToDisabled(child)"
        @click="switchChild(child.id)"
    >
      <v-list-item-title>{{ $t('label.switch_to') }}</v-list-item-title>
      <v-list-item-icon>
        <v-icon>mdi-account-switch</v-icon>
      </v-list-item-icon>
    </v-list-item>

    <v-list-item
        class="white"
        :disabled="!!unlinking"
        @click="unlinkChild(child.id)"
    >
      <v-list-item-title>{{ $t("btn.unlink") }}</v-list-item-title>
      <v-list-item-icon>
        <v-icon>mdi-account-remove</v-icon>
      </v-list-item-icon>
    </v-list-item>
  </v-list-group>

  <v-list-item
      v-if="isElter"
      dense
      @click="openAddChildAccountDialog"
      @click.stop.native
  >
    <v-list-item-icon style="margin-right: 15px">
      <v-icon size="20">mdi-account-plus-outline</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ $t("btn.add") }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>

  <add-child-account-dialog ref="addChildAccountDialog" @close="fetchChildren" />
</v-list>
</template>

<style scoped>

</style>
