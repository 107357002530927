<template>
  <v-chip-group show-arrows>
    <v-chip
      v-for="license in licenses"
      :key="license.id"
      :color="color(license)"
      :dark="license.hasExam && license.examiningPeriodInWeeks > 0"
      class="license"
      small
      @click.native.stop
      :to="{name: 'students.student.education.license', params: { id: student.id, licenseId: license.id }}"
    >
      <v-icon
        v-if="license.hasExam && student.hasDocumentsApproved && license.examiningPeriodInWeeks <= 0"
        class="license-warn-icon"
        small
        color="red"
      >
        mdi-alert-outline
      </v-icon>
      {{ license.name }}
    </v-chip>
  </v-chip-group>
</template>

<script>
import LicenseModel from "@/store/models/LicenseModel";

export default {
  name: "StudentLicenseChipGroup",
  props: {
    student: {
      type: Object,
      required: true,
    },
  },
  computed: {
    licenses() {
      return LicenseModel.query()
        .whereIdIn(this.student.licensesIds)
        .get()
        .map((license) => ({
          ...license,
          hasExam: license.hasExam,
          examiningPeriodInWeeks:
            this.student.licensesIdsWithExaminingPeriodInWeeks[license.id] || 0
        }));
    },
  },
  methods: {
    color(license) {
      const examiningPeriodInWeeks = parseInt(license.examiningPeriodInWeeks);

      if (!license.hasExam || examiningPeriodInWeeks <= 0) return undefined;

      if (examiningPeriodInWeeks > 12) return "green";
      if (examiningPeriodInWeeks <= 12 && examiningPeriodInWeeks > 4) return "yellow";

      return "red";
    },
  },
};
</script>

<style scoped lang="scss">
.license {
  overflow: initial;

  &-warn-icon {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 9999;
  }
}
</style>
