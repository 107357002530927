import { format, parseISO } from "date-fns";
import HappyHoursModel from "@/store/models/HappyHoursModel";

export default {
  computed: {
    checkIfHappyHour() {
      const thisDay = format(
        parseISO(this.eventModel.date),
        "eee"
      ).toLowerCase();
      return (
        HappyHoursModel.query()
          .where("licenseId", this.eventModel.licenseId)
          .where("lessonTypeId", this.eventModel.lessonTypeId)
          .where((item) => item.days.includes(thisDay))
          .where((item) => {
            const eventStart = parseISO(this.eventModel.start);
            const hhStart = parseISO(
              this.eventModel.date + " " + item.timeFrom + ":00"
            );
            const hhEnd = parseISO(
              this.eventModel.date + " " + item.timeTo + ":00"
            );
            return eventStart >= hhStart && eventStart < hhEnd;
          })
          .get() || {}
      );
    },
  },
};
