var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between py-2"},[_vm._v(_vm._s(_vm.$t("label.students")))]),_c('v-divider'),_c('v-card-text',{staticClass:"d-flex justify-space-between"},[_c('WidgetLink',{attrs:{"title":_vm.$t('label.active'),"amount":_vm.active,"to":{
        name: 'students',
        query: { filters: this.formatFilters({ activityStatus: 'active' }) },
      },"loading":_vm.loading}}),_c('WidgetLink',{attrs:{"title":_vm.$t('label.no_balance'),"amount":_vm.noBalance,"to":{
        name: 'students',
        query: { filters: this.formatFilters({ activityStatus: 'active', noBalance: true }) },
      },"loading":_vm.loading}}),_c('WidgetLink',{attrs:{"title":_vm.$t('label.no_appointments'),"amount":_vm.noAppointments,"to":{
          name: 'students',
          query: { filters: this.formatFilters({ activityStatus: 'active', withoutAppointments: true, educationStatus: 'learning' }) },
        },"loading":_vm.loading}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }