<template>
  <div class="chackable-avatar d-flex align-center justify-center">
    <v-checkbox
      v-if="!onlyAvatar"
      class="checkbox flex-shrink-0 flex-grow-0"
      :class="{ 'checkbox--checked': isChecked, 'checkbox--mobile': $vuetify.breakpoint.mobile }"
      hide-details
      style="position: absolute; z-index: 1; left: 8px;"
      :input-value="isChecked"
      @click.native.stop
      @change="(value) => $emit('checkItem', value)"
    />

    <UserAvatar class="mr-3" size="40" :path="avatar" :alt="name" />
  </div>
</template>

<script>
import UserAvatar from '@/components/UserAvatar'

export default {
  name: "CheckableAvatar",
  props: {
    showAvatar: {
      type: Boolean,
      default: true,
    },
    avatar: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    onlyAvatar: {
      type: Boolean,
      default: false,
    }
  },
  components: { UserAvatar },
};
</script>

<style scoped lang="scss">
.chackable-avatar {
  position: relative;
}
.checkbox {
  opacity: 0;
  margin-top: 0;

  &--checked {
    opacity: 1;
  }
}
</style>
