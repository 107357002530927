<template>
  <v-chip-group class="align-center">
    <v-chip v-if="displayDifference" :color="color" label>
      <v-icon left>mdi-chevron-{{ trendingIcon }}</v-icon>
      {{ (current - past) | signedNumber }}
    </v-chip>
    <v-chip
      label
      outlined
      :color="color"
      style="border: none"
      @mouseenter="displayDifference = true"
      @mouseleave="displayDifference = false">
      {{ percentage }}%
      <v-icon right>mdi-trending-{{ trendingIcon }}</v-icon>
    </v-chip>
  </v-chip-group>
</template>

<script>
export default {
  name: "TrendingIndicator",
  filters: {
    signedNumber(value) {
      value = parseInt(value);
      if (value === 0) return "—";
      else return `${value < 0 ? "-" : "+"}${Math.abs(value)}`;
    },
  },
  props: {
    past: {
      type: Number,
      required: true,
    },
    current: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      displayDifference: false,
    };
  },
  computed: {
    color() {
      return this.percentage > 100
        ? "success"
        : this.percentage === 100
        ? "gray"
        : "error";
    },
    trendingIcon() {
      return this.percentage > 100
        ? "up"
        : this.percentage === 100
        ? "neutral"
        : "down";
    },
    percentage() {
      if (this.past === 0) return 100;
      return Math.floor((this.current / this.past) * 100);
    },
  },
};
</script>

<style scoped></style>
