<template>
  <div>
    <v-row>
      <v-col>
        <StudentsField
          @emitStudentChange="(val) => updateFields({ studentsIds: val })"
          :disabled="teacherIsNotSelected"
          :validation="false"
          :multiple="true"
          :clearable="true"
          :decorated="false"
          :studentsProp="currentAppointment.studentsIds"
          :studentsList="teacherStudents" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <SpecialNameField
          @emitSpecialNameChange="(val) => updateFields({ specialName: val })"
          :disabled="teacherIsNotSelected"
          :validation="true"
          :specialNameProp="currentAppointment.specialName" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <NotesField
          @emitNotesChange="(val) => updateFields({ notes: val })"
          :disabled="teacherIsNotSelected"
          :validation="false"
          :notesProp="currentAppointment.notes" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <WorkTimeStatusSwitch
          @emitSwitchStatus="(val) => updateFields({ isWorkTime: val })"
          :label="$t('label.teacher_work_time')"
          :disabled="teacherIsNotSelected"
          :statusProp="currentAppointment.isWorkTime" />
      </v-col>
      <v-col>
        <SignatureField v-if="signature" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import StudentsField from "@/components/calendar/appointmentModal/fields/StudentsField";
import SpecialNameField from "@/components/calendar/appointmentModal/fields/SpecialNameField";
import NotesField from "@/components/calendar/appointmentModal/fields/NotesField";
import WorkTimeStatusSwitch from "@/components/calendar/appointmentModal/fields/StatusSwitch";
import SignatureField from "@/components/calendar/appointmentModal/fields/SignatureField";

import StudentModel from "@/store/models/StudentModel";

export default {
  name: "TeacherSpecialForm",
  components: {
    StudentsField,
    SpecialNameField,
    NotesField,
    WorkTimeStatusSwitch,
    SignatureField,
  },
  data: () => ({
    titles: ["Vortest", "Anmeldung"],
    title: "",
    isWorkTime: true,
    signature: true,
  }),
  props: {
    teachers: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", {
      currentAppointment: "currentAppointmentGetter",
    }),
    teacherIsNotSelected() {
      return !this.currentAppointment.teacherId;
    },
    teacherStudents() {
      return (
        StudentModel.query()
            .where("teachersIds", (teachersIds) => teachersIds.includes(this.currentAppointment.teacherId))
            .get() || []
      );
    },
    specialType: {
      set(value) {
        this.updateFields({
          specialType: value,
          teacherId: null,
          studentsIds: [],
          isWorkTime: false,
          officeId: null,
          specialName: "",
          notes: "",
        });
      },
      get() {
        return this.currentAppointment.specialType;
      },
    },
  },
  methods: {
    updateFields(value) {
      this.$store.commit(`appointments/UPDATE_APPOINTMENT_FIELD`, value);
    },
  },
};
</script>

<style lang="scss" scoped>
.special-tabs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  .v-btn--active {
  }
}
</style>
