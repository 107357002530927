<template>
<div class="d-flex flex-column flex-grow-1 align-start" style="flex-basis: 0;">
    <span
        class="text-caption"
        :class="hasAction ? 'primary--text': ''"
        style="cursor: pointer"
        @click="doAction"
    >
      {{ title }}
    </span>

    <v-skeleton-loader
        v-if="loading"
        type="image"
        class="mt-3 mb-3"
        width="40"
        height="15"
        loading
    />

    <span
        v-else
        class="text-h5" :class="hasAction ? 'primary--text': ''"
        style="cursor: pointer"
        @click="doAction"
    >
      {{ amount || "—" }}
    </span>
</div>
</template>

<script>
export default {
  name: 'WidgetLink',
  props: {
    title: {
      type: String,
      default: ''
    },
    amount: {
      type: [String, Number],
      default: 0
    },
    to: {
      type: Object
    },
    action: {
      type: Function
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasAction() {
      return this.to || this.action
    }
  },
  methods: {
    doAction() {
      if (this.to) {
        this.$router.push(this.to)
      }

      if (typeof this.action === 'function') {
        this.action()
      }
    }
  }
}
</script>

<style scoped>

</style>