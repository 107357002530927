var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[(_vm.isSchoolAdministrator)?_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 6 : 12}},[_c('TeachersField',{attrs:{"disabled":_vm.isMissed || _vm.hasDeletedStudent || _vm.isExamInPast || !!_vm.createdPlaceholderApp,"validation":true,"filteredTeachers":_vm.teachers,"teacherProp":_vm.currentAppointment.teacherId},on:{"emitTeachersSelect":_vm.updateTeacherField,"emitRecalculation":_vm.recalculateAmount}})],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp && _vm.isSchoolAdministrator ? 6 : 12}},[_c('StudentsField',{attrs:{"disabled":_vm.teacherIsNotSelected ||
            (!!_vm.currentAppointment.id && !_vm.createdPlaceholderApp) ||
            _vm.isMissed ||
            _vm.hasDeletedStudent ||
            _vm.isExamInPast,"validation":true,"multiple":false,"decorated":true,"studentsProp":_vm.currentAppointment.studentsIds,"studentsList":_vm.activeStudentsList},on:{"emitStudentChange":_vm.updateStudentField}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('LicenseField',{ref:"licenseField",attrs:{"disabled":_vm.studentIsNotSelected || _vm.isMissed || _vm.hasDeletedStudent || _vm.isExamInPast,"validation":true,"multiple":false,"showLicenseProgress":true,"licenseProp":_vm.currentAppointment.licenseId,"licenseIdsList":_vm.activeLicensesIds,"licenseStatus":_vm.practiceLicensesStatus,"label":_vm.$t('form.licence')},on:{"studentProgress":_vm.getStudentProgress,"emitLicenseSelect":_vm.updateLicenseField}}),(_vm.showIsManualLesson)?_c('ManualLessonCheckboxField',{attrs:{"disabled":_vm.isMissed || _vm.hasDeletedStudent || _vm.isExamInPast,"label":"Schalterstunde","statusProp":this.currentAppointment.isManualLesson},on:{"emitManualStatusChange":(val) => _vm.updateFields({ isManualLesson: val })}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"6"}},[_c('LessonTypesField',{attrs:{"disabled":_vm.studentIsNotSelected ||
                  !_vm.currentAppointment.licenseId ||
                  _vm.isMissed ||
                  _vm.hasDeletedStudent ||
                  _vm.isExamInPast,"validation":true,"createdPlaceholderApp":!!_vm.createdPlaceholderApp,"lessonTypeProp":_vm.currentAppointment.lessonTypeId,"lessonTypesList":_vm.lessonTypesList},on:{"emitLessonTypeSelect":_vm.updateLessonTypeField}})],1)],1),(_vm.licenseProgressData)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-sheet',{staticClass:"pa-2",staticStyle:{"border":"1px solid #9c9c9c"},attrs:{"outlined":"","rounded":""}},[_c('SegmentedProgressBar',{staticClass:"mb-1",attrs:{"required-segments":_vm.shouldValue,"segment-length":_vm.progressSpecialDuration,"total-value":_vm.totalValue,"duration":_vm.currentAppointment.duration,"label":_vm.$tc(_vm.currentLessonType.name, 2),"color":"secondary","dark":""}})],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 6 : 12}},[_c('VehicleFieldDynamic',{attrs:{"disabled":_vm.studentIsNotSelected ||
                  !_vm.currentAppointment.licenseId ||
                  _vm.isMissed ||
                  _vm.hasDeletedStudent ||
                  _vm.isExamInPast,"label":_vm.$t('form.primary_vehicle'),"loading":_vm.primaryVehiclesLoader,"showNoData":!_vm.primaryVehiclesIds,"vehiclesList":_vm.primaryVehicles,"vehiclesData":_vm.primaryVehiclesData,"vehicleProp":_vm.currentAppointment.primaryVehicleId},on:{"emitVehicleChange":(val) => _vm.updateFields({ primaryVehicleId: val }),"emitRequestVehicles":_vm.requestPrimaryVehicles}})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 6 : 12}},[_c('VehicleFieldDynamic',{attrs:{"disabled":_vm.studentIsNotSelected ||
                  !_vm.currentAppointment.licenseId ||
                  !_vm.currentAppointment.primaryVehicleId ||
                  _vm.isMissed ||
                  _vm.hasDeletedStudent ||
                  _vm.isExamInPast,"label":_vm.$t('form.secondary_vehicle'),"loading":_vm.secondaryVehiclesLoader,"showNoData":!_vm.secondaryVehiclesIds,"vehiclesList":_vm.secondaryVehicles,"vehiclesData":_vm.secondaryVehiclesData,"vehicleProp":_vm.currentAppointment.secondaryVehicleId},on:{"emitVehicleChange":(val) => _vm.updateFields({ secondaryVehicleId: val }),"emitRequestVehicles":_vm.requestSecondaryVehicles}})],1)],1),_c('v-row',[_c('v-col',[_c('MeetingPlaceField',{attrs:{"disabled":_vm.studentIsNotSelected || _vm.isMissed || _vm.hasDeletedStudent || _vm.isExamInPast,"validation":false,"meetingPlacesList":_vm.placesGroups,"meetingPlaceProp":_vm.currentAppointment.meetingPlace},on:{"emitMeetingPlaceSelect":(val) => _vm.updateFields({ meetingPlace: val })}})],1)],1),_c('v-row',[_c('v-col',[_c('NotesField',{attrs:{"disabled":_vm.studentIsNotSelected || _vm.hasDeletedStudent,"validation":false,"notesProp":_vm.currentAppointment.notes},on:{"emitNotesChange":(val) => _vm.updateFields({ notes: val })}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }