<template>
  <v-dialog width="500" :value="show" persistent>
    <v-card>
      <v-card-title class="headline red lighten-1 white--text flex" primary-title>
        <v-icon class="mr-2" dark size="30">mdi-clock-fast</v-icon>
        {{ $t('session_expired') }}
      </v-card-title>

      <v-card-text class="pl-10 pr-10 pt-3">
        <div class="subtitle-1 mb-2">{{ $t('your_session_has_expired') }}</div>
        <div class="subtitle-1">{{ $t("session_expired_redirect_message", { pageName: $t("route.signIn"), sec: counter }) }}</div>
      </v-card-text>

      <v-divider />
      <v-card-actions class="d-flex justify-end">
        <v-btn color="red" outlined small @click="removeSession">{{ $t("close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import * as authMutationTypes from "@/store/mutation-types/auth";

export default {
  name: "SessionExpired",
  data: () => ({
    counter: 6,
    timerId: null
  }),
  watch: {
    counter(value) {
      if (value === 0) this.removeSession();
    },
    show(value) {
      if (value) this.startCounter();
    }
  },
  computed: {
    ...mapGetters("auth", ["getSessionExpired"]),
    show: {
      get() {
        return this.getSessionExpired;
      },
      set(value) {
        this.$store.commit(`auth/${authMutationTypes.SESSION_EXPIRED}`, value);
      }
    }
  },
  methods: {
    close() {
      this.show = false;
      this.clearData();
    },
    clearData() {
      clearInterval(this.timerId);
      this.counter = 6;
      this.timerId = null;
    },
    startCounter() {
      this.timerId = setInterval(() => this.counter--, 1000);

    },
    removeSession() {
      this.show = false;

      this.$store.dispatch("auth/removeSessionData").then(() => {
        this.$router.push({ name: "login" });
      });

      this.clearData();
    }
  }
};
</script>

<style scoped></style>
