<template>
  <v-menu top offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="mr-2" :outlined="true" color="grey darken-2">
        <v-icon :left="!isMobile">mdi-cash-refund</v-icon>
        <span v-if="!isMobile">{{ $t("btn.refund") }}</span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="$emit('emitRefund', false)">
        <v-list-item-title>{{ $t("btn.full_refund") }}</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="showJoker" @click="$emit('emitRefund', true)" :disabled="disabledJoker">
        <v-list-item-title>{{ $t("btn.joker_refund") }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "LessonMissedMenu",
  props: {
    showJoker: {
      type: Boolean,
      required: true,
    },
    disabledJoker: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
};
</script>

<style lang="scss" scoped></style>
