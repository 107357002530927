import api from "@/utils/api";

export default {
  getAvailableVehicles(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/vehicles/available", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },

  getVehiclesWithStatus(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/vehicles/available-status", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },

  load(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/vehicles", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadFormData() {
    return new Promise((resolve, reject) => {
      api
        .get("/vehicles/formData")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadItem(vehicleId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/vehicles/${vehicleId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  create(params) {
    return new Promise((resolve, reject) => {
      api
        .post("/vehicles", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  update(vehicleId, params) {
    return new Promise((resolve, reject) => {
      api
        .post(`/vehicles/${vehicleId}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  destroy(vehicleId) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/vehicles/${vehicleId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  restore(vehicleId) {
    return new Promise((resolve, reject) => {
      api
        .patch(`/vehicles/${vehicleId}/restore`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getDeletedCount: () => {
    return new Promise((resolve, reject) => {
      api
        .get(`recycle-bin/vehicles/count`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  getDeletedList: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`recycle-bin/vehicles/list`,{ params: params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  availableVehicles(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/vehicles/available", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadMileages(vehicleId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/vehicles/${vehicleId}/mileages`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadReservations(vehicleId, params) {
    return new Promise((resolve, reject) => {
      api
        .get(`/vehicles/${vehicleId}/reservations`, { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getAllVehiclesReservations(params) {
    return new Promise((resolve, reject) => {
      api
        .get(`/vehicles/reservations`, { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getReservation(vehicleId, id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/vehicles/${vehicleId}/reservations/${id}`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  storeReservation(vehicleId, params) {
    return new Promise((resolve, reject) => {
      api
        .post(`/vehicles/${vehicleId}/reservations`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateReservation(vehicleId, reservationId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/vehicles/${vehicleId}/reservations/${reservationId}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  deleteReservation(vehicleId, reservationId) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/vehicles/${vehicleId}/reservations/${reservationId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getLastMileage(vehicleId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/vehicles/${vehicleId}/mileages/last`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  storeMileage(vehicleId, params) {
    return new Promise((resolve, reject) => {
      api
        .post(`/vehicles/${vehicleId}/mileages`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  deleteMileage(vehicleId, mileageId) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/vehicles/${vehicleId}/mileages/${mileageId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadRefuelings(vehicleId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/vehicles/${vehicleId}/refueling`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  storeRefueling(vehicleId, params) {
    return new Promise((resolve, reject) => {
      api
        .post(`/vehicles/${vehicleId}/refueling`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  deleteRefueling(vehicleId, refuelingId) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/vehicles/${vehicleId}/refueling/${refuelingId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  teachers(vehicleId, params) {
    return new Promise((resolve, reject) => {
      api
        .patch(`/vehicles/${vehicleId}/teachers`, params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },

  getAllVehiclesServices(params) {
    return new Promise((resolve, reject) => {
      api
        .get(`/vehicles/services`, { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadServices(vehicleId, params) {
    return new Promise((resolve, reject) => {
      api
        .get(`/vehicles/${vehicleId}/services`, { params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  storeService(vehicleId, params) {
    return new Promise((resolve, reject) => {
      api
        .post(`/vehicles/${vehicleId}/services`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getService(vehicleId, id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/vehicles/${vehicleId}/services/${id}`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  updateService(vehicleId, serviceId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/vehicles/${vehicleId}/services/${serviceId}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  deleteService(vehicleId, id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/vehicles/${vehicleId}/services/${id}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadExpenses(vehicleId, params) {
    return new Promise((resolve, reject) => {
      api
        .get(`/vehicles/${vehicleId}/expenses`, { params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  storeExpense(vehicleId, params) {
    return new Promise((resolve, reject) => {
      api
        .post(`/vehicles/${vehicleId}/expenses`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateExpense(vehicleId, expenseId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/vehicles/${vehicleId}/expenses/${expenseId}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getExpense(vehicleId, id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/vehicles/${vehicleId}/expenses/${id}`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  deleteExpense(vehicleId, id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/vehicles/${vehicleId}/expenses/${id}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadVehiclesForCurrentTeacher(params){
    return new Promise((resolve, reject) => {
      api
        .get(`/vehicles/for-current-teacher`, { params })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  getVehicleDriverAssistanceSystemsPdf(vehicleId) {
    return new Promise((resolve, reject) => {
      api
          .get(`/vehicles/${vehicleId}/assistants-list-pdf`, {
            responseType: "blob",
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch(reject);
    });
  }
};
