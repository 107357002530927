<template>
  <div>
    <!--  Push Notification Initialization  -->
  </div>
</template>

<script>
import FcmService from "@/services/fcmService";
const fcmService = new FcmService();

export default {
  name: "PushNotificationInit",
  async mounted() {
    try {
      await fcmService.initPush();
    } catch (e) {
      console.error(e)
    }
  },
  beforeDestroy() {
    fcmService.removeListeners();
  },
};
</script>
