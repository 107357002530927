<template>
  <v-col cols="12">
    <v-list :class="`students-list ${disabled ? 'disabled' : ''}`">
      <v-list-item-group
        v-model="model.subscriber"
        dense
        :mandatory="studentsList.length === 1">
        <template v-for="(student, i) in filteredStudents">
          <v-divider v-if="i > 0" :key="`divider-${i}`"></v-divider>
          <v-list-item
            active-class="green--text green--text"
            :key="i"
            :value="student">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-list-item-title>{{
                  licenseName(student.licenseId)
                }}</v-list-item-title>
              </v-list-item-action>
              <v-list-item-content>
                <div class="d-flex justify-space-between">
                  <div>{{ studentName(student.studentId) }}</div>
                  <div v-if="student.balance">
                    {{ student.balance | currency }}
                  </div>
                </div>
              </v-list-item-content>
              <v-list-item-action>
                <v-checkbox :input-value="active" readonly></v-checkbox>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-col>
</template>

<script>
import StudentModel from "@/store/models/StudentModel";
import LicenseModel from "@/store/models/LicenseModel";
import { mapGetters } from "vuex";

export default {
  name: "SubscribedStudentsField",
  props: {
    studentsList: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    filteredStudents() {
      const updatedList = [];
      this.studentsList.forEach((item) => {
        const student = StudentModel.find(item.studentId) || {};
        const obj = {
          ...item,
          balance: student.bookAndPay ? student.balance : null,
        };
        updatedList.push(obj);
      });
      return updatedList;
    },
  },
  methods: {
    studentName(id) {
      const student = StudentModel.find(id);
      return student ? `${student.firstName} ${student.lastName}` : {};
    },
    licenseName(id) {
      const license = LicenseModel.find(id);
      return license.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.students-list {
  border: 1px solid rgba(0, 0, 0, 0.38);
  margin: 0;
  padding: 0;
  border-radius: 5px;
  overflow-y: auto;
  max-height: 148px;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
