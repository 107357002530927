<template>
<v-menu
    v-if="isSidebarMinimized && !$vuetify.breakpoint.mobile"
    open-on-hover
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
    rounded="0"
>
  <template v-slot:activator="{ on, attrs }">
    <v-list-item
      link
      v-bind="attrs"
      v-on="on"
      :input-value="inputValue"
      :value="value"
      :to="to"
      :disabled="disabled"
      :class="{'disabled': disabled}"
    >
      <v-list-item-icon style="margin-right: 15px">
        <v-badge color="red" :content="badge" offset-y="5" offset-x="5" :value="!!badge">
          <v-img :width="iconWidth" :height="iconHeight" contain :src="iconSrc" />
        </v-badge>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title v-text="name" />
      </v-list-item-content>
    </v-list-item>
  </template>

  <v-sheet class="py-0 v-list" color="primary">
    <slot type="menu"/>
  </v-sheet>
</v-menu>

<v-list-group v-else :value="value" :disabled="disabled" :color="color" :class="{'disabled': disabled}">
  <template v-slot:activator>
    <v-list-item-icon style="margin-right: 15px">
      <v-badge color="red" :content="badge" offset-y="5" offset-x="5" :value="!!badge">
        <v-img :width="iconWidth" :height="iconHeight" contain :src="iconSrc"/>
      </v-badge>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title v-text="name"/>
    </v-list-item-content>
  </template>

  <slot type="list-group"/>
</v-list-group>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SidebarListGroup',
  props: {
    to: {
      type: [String, Object],
      default: undefined
    },
    inputValue: {
      default: undefined
    },
    value: {
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "white"
    },
    name: {
      type: String,
      default: ''
    },
    iconSrc: {
      type: String,
      required: true
    },
    iconWidth: {
      type: [String, Number],
      default: 25
    },
    iconHeight: {
      type: [String, Number],
      default: 25
    },
    badge: {
      default: undefined
    }
  },
  computed: {
    ...mapState("main", ["isSidebarMinimized"])
  }
}
</script>

<style scoped lang="scss">
</style>