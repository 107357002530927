<template>
  <div>
    <v-alert class="mb-1" type="warning" prominent :icon="false">
      <v-row class="my-auto">
        <v-col cols="12" md="9">
          <span v-if="isAppointmentPermanentlyDeleted">
           {{ $t("messages.permanent_deleted_at_info", { entityName: entityName, time: deletedAt }) }}
          </span>
          <span v-else>{{ $t("messages.archived_at_info", { entityName: entityName, time: deletedAt }) }}</span>
        </v-col>

        <v-col cols="12" md="3" class="text-right">
          <v-btn
            v-if="!isStudent && !isAppointmentPermanentlyDeleted"
            small
            color="secondary"
            @click="restore"
            :loading="loading"
            :disabled="loading"
          >
            <v-icon left small>mdi-archive-arrow-up</v-icon>
            {{ $t("btn.unarchive") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <AppointmentOverlapErrorDialog ref="appointmentOverlapErrorDialog" @restore="restore"/>
  </div>
</template>

<script>
import { format } from "date-fns";
import studentsService from "@/services/studentsService";
import StudentModel from "@/store/models/StudentModel";
import appointmentService from "@/services/appointmentService";
import responsive from "@/utils/mixins/responsive";
import user from "@/utils/mixins/user";
import AppointmentModel from "@/store/models/AppointmentModel";
import AppointmentOverlapErrorDialog from "@/components/appointment/AppointmentOverlapErrorDialog";
import vehiclesService from "@/services/vehiclesService";
import VehicleModel from "@/store/models/VehicleModel";

export default {
  name: "DeletedAtAlert",
  components: { AppointmentOverlapErrorDialog },
  mixins: [responsive, user],
  props: {
    entityType: {
      type: String,
      required: true
    },
    entity: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    loadingOverlappedAppointments: false,
    eventOverlaps: []
  }),
  computed: {
    entityName() {
      return this.$t(`label.${this.entityType}`).toLowerCase();
    },
    deletedAt() {
      const deletedAt = new Date(this.entity.deletedAt);
      const date = format(deletedAt, "dd.MM.yyyy");
      const time = format(deletedAt, "HH:mm:ss");

      return this.$t(`label.on_date_at_time`, { date: date, time: time });
    },
    isPracticeRequested() {
      return this.entity.type === 'PRACTICE' && this.entity.status === 'REQUESTED'
    },
    isAppointmentPermanentlyDeleted () {
      return [AppointmentModel.TYPES.OFFTIME, AppointmentModel.TYPES.PLACEHOLDER]
          .includes(this.entity.type) || this.isPracticeRequested
    }
  },
  methods: {
    restore() {
      this.eventOverlaps = [];
      const methodByEntityType = {
        student: this.studentRestore,
        event: this.eventRestore,
        vehicle: this.vehicleRestore
      };
      methodByEntityType[this.entityType](this.entity);
    },
    studentRestore(item) {
      this.loading = true;
      studentsService
        .restore(item.id)
        .then(() => {
          studentsService.loadItem(item.id).then((resp) => {
            StudentModel.update({ where: item.id, data: resp.data || {} }).then(
              () => {
                this.$emit("restored", StudentModel.find(item.id));
                this.loading = false;
              }
            );
          });
        })
        .catch((error) => {
          const response = error.response || {};
          const responseData = response.data || {};

          if (responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error" });
          }

          this.loading = false;
        })
    },
    eventRestore(item) {
      this.loading = true;
      appointmentService
        .restore(item.id, item.type)
        .then(() => (this.$emit("restored")))
          .catch((error) => {
            const response = error.response || {};
            const responseData = response.data || {};
            const responseParams = responseData.params || {};
            const overlapsIds = responseParams.overlapIds || [];

            if (overlapsIds.length > 0) {
              this.$refs.appointmentOverlapErrorDialog.open(responseData.message, overlapsIds);
            } else {
              this.$snackbar.show({ message: responseData.message, color: "error" });
            }
          })
        .finally(() => (this.loading = false));
    },
    vehicleRestore(item) {
      this.loading = true;
      vehiclesService
        .restore(item.id, item.type)
        .then(() => {
          vehiclesService.loadItem(item.id).then((resp) => {
            VehicleModel.update({ where: item.id, data: resp.data || {} }).then(
              () => {
                this.$emit("restored", VehicleModel.find(item.id));
                this.loading = false;
              }
            );
          });
        })
        .catch((error) => {
          const response = error.response || {};
          const responseData = response.data || {};

          this.$snackbar.show({ message: responseData.message, color: "error" });
          this.loading = false;
        });
    }
  },
};
</script>

<style scoped></style>
