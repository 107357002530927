<script>
import documentsService from '@/services/documentsService'
import FileSaver from "@/helpers/fileSaver";
import user from '@/utils/mixins/user'
import DocumentListItem from '@/components/DocumentListItem.vue'
import * as authMutationTypes from '@/store/mutation-types/auth'
import { mapGetters } from 'vuex'

export default {
  name: 'UnsignedDocumentsWidget',
  components: { DocumentListItem },
  mixins: [user],
  data() {
    return {
      loadingDocuments: false,
      documents: [],
    }
  },
  watch: {
    unsignedDocumentsCount() {
      this.fetch()
    },
  },
  computed: {
    ...mapGetters("auth", ["unsignedDocumentsCount"]),
    filteredDocuments() {
      return this.documents.filter(document => document.isSignedByStudent === false)
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch() {
      this.loadingDocuments = true;
      documentsService
          .list(this.currentUser.id,{ signedByStudent: false })
          .then((documentResponse) => {
            this.documents = documentResponse.data || [];
            this.$store.commit(`auth/${authMutationTypes.SET_UNSIGNED_DOCUMENTS_COUNT}`, this.filteredDocuments.length);
          })
          .finally(() => {
            this.loadingDocuments = false;
          });
    },
    download(document) {
      if (document.file) {
        window.open(document.file, '_blank');
      } else {
        documentsService
          .download(this.currentUser.id, document.id)
          .then((resp) => {
            const responseData = [resp.data] || [];
            const fileSaver = new FileSaver(responseData)
            fileSaver.setType('application/pdf').saveToDevice(document.downloadFileName)
          });
      }
    },
    sign(document) {
      if (! document.isPreviewed) {
        this.$snackbar.show({
          message: this.$t('label.you_need_to_read_the_document_first'),
          color: 'warning',
          timeout: 5000
        })
        return;
      }
    },
    markAsPreviewed(id) {
      this.documents = this.documents.map(obj =>
          obj.id === id ? { ...obj, isPreviewed: true } : obj
      );
    },
    markAsSigned(id) {
      this.documents = this.documents.map(obj =>
          obj.id === id ? { ...obj, isSignedByStudent: true, requiresStudentSignature: false } : obj
      );
      this.$store.commit(
          `auth/${authMutationTypes.SET_UNSIGNED_DOCUMENTS_COUNT}`,
          this.currentUser.unsignedDocumentsCount - 1
      );
    }
  }
}
</script>

<template>
<v-row dense v-if="filteredDocuments.length > 0">
  <v-col>
    <v-card>
      <v-card-title class="py-2 d-flex justify-space-between warning-color">
        <div class="d-flex flex-column">
          <span>{{ $t('label.documents_required_signature') }}</span>
        </div>
      </v-card-title>

      <v-divider/>

      <v-list class="py-0">
        <template v-for="(document, key) in filteredDocuments">
          <document-list-item
              :document="document"
              :key="document.id"
              @downloaded="markAsPreviewed"
              @signed="markAsSigned"
          />
          <v-divider v-if="key < documents.length - 1" :key="`divider-${document.id}`"></v-divider>
        </template>
      </v-list>

      <v-divider/>

      <v-card-actions>
        <v-btn color="primary" text :to="{ name: 'student.documents' }">{{ $t('label.view_all_documents') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</v-row>
</template>

<style scoped>

</style>
