export default {
  data() {
    return {
      textTruncateContainerWidth: "",
    };
  },
  updated() {
    this.updateTruncateContainerWidth();
    window.addEventListener("orientationchange", () => {
      setTimeout(this.updateTruncateContainerWidth, 0);
    });
  },
  methods: {
    updateTruncateContainerWidth() {
      const gridItem = document.querySelector(".grid-item");

      this.textTruncateContainerWidth = gridItem
        ? `${gridItem.clientWidth - 125}px`
        : "190px";
    },
  },
};
