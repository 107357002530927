import StudentModel from "@/store/models/StudentModel";
import LicenseModel from "@/store/models/LicenseModel";
import { mapGetters } from "vuex";
import user from "@/utils/mixins/user";
import TeacherModel from "@/store/models/TeacherModel";

export default {
  mixins: [user],
  computed: {
    licenses() {
      return LicenseModel.all();
    },
    activeLicenses() {
      const teacher = TeacherModel.find(this.model.teacherId);

      const modelStudentId = this.model.subscriber
        ? this.model.subscriber.studentId
        : this.model.studentsIds[0];
      const student = StudentModel.find(
        this.isStudent ? this.currentUser.id : modelStudentId
      );

      if (!teacher || !student) return [];

      const teacherLTs = teacher.licensesIds;
      const studentLTs = student.licensesIds;
      const mergedLTs = teacherLTs.filter((e) => studentLTs.includes(e));
      const mergedLicenses = mergedLTs.map((lt) =>
        this.licenses.find((license) => license.id === lt)
      );
      const requests = this.model.requests;

      if (this.isStudent) {
        const subscribedPlaceholder =
          requests && requests.length
            ? requests.find(
                (request) => request.studentId === this.currentUser.id
              )
            : false;
        if (subscribedPlaceholder) {
          // Student has subscribed to placeholder
          this.setActiveLicense(subscribedPlaceholder.licenseId);
        }
      } else {
        if (this.model.subscriber) {
          this.setActiveLicense(this.model.subscriber.licenseId);
        } else if (mergedLicenses.length === 1) {
          this.setActiveLicense(mergedLicenses[0].id);
        } else {
          this.setActiveLicense(this.model.licenseId);
        }
      }

      return mergedLicenses;
    },
    placeholderAllowedLicenses() {
      if (!this.model.allowedLicenses.length) {
        return this.activeLicenses;
      } else {
        const license = this.model.allowedLicenses.filter((license) =>
          this.currentUser.licensesIds.includes(license)
        );
        return LicenseModel.query().whereIdIn(license).get() || [];
      }
    },
    ...mapGetters("auth", ["currentUser"]),
  },
  methods: {
    setActiveLicense(id) {
      this.model.licenseId = id;
    },
  },
};
