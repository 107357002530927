import { Model } from "@vuex-orm/core";

export default class EducationPlanModel extends Model {
  static entity = "educationModels";

  static fields() {
    return {
      id: this.attr(null),
      licenseId: this.attr(null),
      lessonTypes: this.attr(null),
    };
  }

  get overland() {
    return this.lessonTypes["lesson_type.overland"];
  }

  get night() {
    return this.lessonTypes["lesson_type.night"];
  }

  get highway() {
    return this.lessonTypes["lesson_type.highway"];
  }
}
