const codeTypes = {
  'PM': {
    type: 'premium',
    ebook: true,
    allLanguages: true,
    allClasses: true,
    simdrive: true,
    flashcards: true,
    languages: ['en','de','tr','es','ru','ro','pl','hr','fr','pt','it','el','ar'],
    classes: ['A', 'A1', 'A2', 'AM', 'Mofa', 'L', 'T', 'B', 'C', 'CE', 'C1', 'D', 'D1']
  },
  'BE': {
    type: 'basic',
    ebook: false,
    allLanguages: false,
    allClasses: true,
    simdrive: false,
    flashcards: false,
    languages: ['de'],
    classes: ['A', 'A1', 'A2', 'AM', 'Mofa', 'L', 'T', 'B', 'C', 'CE', 'C1', 'D', 'D1']
  },
  'BM': {
    type: 'basic',
    ebook: false,
    allLanguages: true,
    allClasses: true,
    simdrive: false,
    flashcards: false,
    languages: ['en','de','tr','es','ru','ro','pl','hr','fr','pt','it','el','ar'],
    classes: ['A', 'A1', 'A2', 'AM', 'Mofa', 'L', 'T', 'B', 'C', 'CE', 'C1', 'D', 'D1']
  },
  'MM': {
    type: 'basic',
    ebook: false,
    allLanguages: true,
    allClasses: false,
    simdrive: false,
    flashcards: false,
    languages: ['en','de','tr','es','ru','ro','pl','hr','fr','pt','it','el','ar'],
    classes: ['Mofa']
  },
  'EB': {
    type: 'basic',
    ebook: false,
    allLanguages: false,
    allClasses: false,
    simdrive: false,
    flashcards: false,
    languages: ['de'],
    classes: ['B']
  },
  'MB': {
    type: 'basic',
    ebook: false,
    allLanguages: true,
    allClasses: false,
    simdrive: false,
    flashcards: false,
    languages: ['en','de','tr','es','ru','ro','pl','hr','fr','pt','it','el','ar'],
    classes: ['B']
  },
  'AB': {
    type: 'basic',
    ebook: false,
    allLanguages: true,
    allClasses: false,
    simdrive: false,
    flashcards: false,
    languages: ['en','de','tr','es','ru','ro','pl','hr','fr','pt','it','el','ar'],
    classes: ['A', 'A1', 'A2', 'AM', 'Mofa', 'B', 'L']
  },
  'AR': {
    type: 'basic',
    ebook: false,
    allLanguages: false,
    allClasses: true,
    simdrive: false,
    flashcards: false,
    languages: ['ar'],
    classes: ['A', 'A1', 'A2', 'AM', 'Mofa', 'L', 'T', 'B', 'C', 'CE', 'C1', 'D', 'D1']
  },
  'PE': {
    type: 'basic',
    ebook: false,
    allLanguages: false,
    allClasses: true,
    simdrive: false,
    flashcards: false,
    languages: ['de'],
    classes: ['A', 'A1', 'A2', 'AM', 'Mofa', 'L', 'T', 'B', 'C', 'CE', 'C1', 'D', 'D1']
  },
  'PR': {
    type: 'basic',
    ebook: true,
    allLanguages: true,
    allClasses: true,
    simdrive: true,
    flashcards: true,
    languages: ['en','de','tr','es','ru','ro','pl','hr','fr','pt','it','el','ar'],
    classes: ['A', 'A1', 'A2', 'AM', 'Mofa', 'L', 'T', 'B', 'C', 'CE', 'C1', 'D', 'D1']
  },
}
 export default codeTypes;