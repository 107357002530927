<template>
  <v-dialog width="500" :value="show" @click:outside="close" @keydown.esc="close">
    <v-card>
      <v-toolbar class="mb-4" dark color="primary" dense>
        <v-toolbar-title>{{ $t('label.login_into_your_other_account') }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-card-text>
        <ValidationObserver ref="form">
          <v-form @keyup.native.enter="signIn">
            <ValidationProvider v-slot="{ errors }" name="username" rules="required|min:4">
              <v-text-field
                  inputmode="email"
                  :label="$t('validation.fields.username')"
                  :placeholder="$t('validation.fields.username')"
                  outlined
                  v-model="form.username"
                  :error-messages="errors"
                  autocomplete="username"
              />
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" name="password" rules="required|min:6">
              <v-text-field
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="!showPassword ? 'password' : 'text'"
                  :label="$t('validation.fields.password')"
                  :placeholder="$t('validation.fields.password')"
                  outlined
                  v-model="form.password"
                  :error-messages="errors"
                  @click:append="showPassword = !showPassword"
                  autocomplete="new-password"
              />
            </ValidationProvider>

            <v-switch v-model="form.switchAfterLogin" class="mt-0" color="primary" hide-details :label="$t('label.switch_after_login')"/>
          </v-form>
        </ValidationObserver>
      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="signIn" :loading="loading" :disabled="loading">{{$t("sign_in")}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import otherAccountsService from '@/services/otherAccountsService'

export default {
  name: 'AddAccountDialog',
  data: () => ({
    show: false,
    showPassword: false,
    loading: false,
    otherAccounts: JSON.parse(localStorage.getItem('otherAccounts')) || [],
    form: {
      username: '',
      password: '',
      switchAfterLogin: false
    }
  }),
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.show = false
      this.form = {
        username: '',
        password: '',
        switchAfterLogin: false
      }
    },
    signIn () {
      this.$refs.form.validate().then(response => {
        if (response) {
          this.loading = true
          otherAccountsService.login({
            username: this.form.username,
            password: this.form.password
          })
            .then(response => {
              this.$emit('success', { user: response.data || {}, switchAfterLogin: this.form.switchAfterLogin })
              this.close()
            })
            .catch(error => {
              const responseData = error.response.data || {};
              const errors = responseData.errors || [];

              if (Object.keys(errors).length === 0 && responseData.message) {
                this.$snackbar.show({ message: responseData.message, color: "error" });
              }

              this.$refs.form.setErrors(errors);
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
