<template>
<v-card>
  <VehicleToolBar title="vehicleReservations" activeTab="reservations">
    <v-btn color="primary" text @click="openReservationDialog">
      <v-icon>mdi-plus</v-icon>
      {{ $t("btn.add") }}
    </v-btn>
  </VehicleToolBar>

  <v-card-text>
      <v-skeleton-loader v-if="loading" type="table" />
      <template v-else>
          <v-row class="mt-1">
            <v-col cols="12" lg="2">
              <v-dialog
                  ref="fromDialog"
                  v-model="fromDialog"
                  :return-value.sync="filters.from"
                  persistent
                  width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      solo
                      dense
                      readonly
                      :value="formattedFrom"
                      :label="$t('filters.from')"
                      prepend-inner-icon="mdi-calendar"
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="filters.from = null"
                  >
                  </v-text-field>
                </template>
                <v-date-picker :value="filters.from" @input="setFilterFrom" scrollable>
                  <v-spacer/>
                  <v-btn text color="primary" @click="fromDialog = false">{{ $t("btn.cancel") }}</v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.fromDialog.save(filters.from)"
                  >
                    {{ $t("btn.ok") }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" lg="2">
              <v-dialog
                ref="toDialog"
                v-model="toDialog"
                :return-value.sync="filters.to"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      solo
                      dense
                      readonly
                      :value="formattedTo"
                      :label="$t('filters.to')"
                      prepend-inner-icon="mdi-calendar"
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="filters.to = null"
                  >
                  </v-text-field>
                </template>
                <v-date-picker v-model="filters.to" :min="filters.from" scrollable>
                  <v-spacer/>
                  <v-btn text color="primary" @click="toDialog = false">{{ $t("btn.cancel") }}</v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.toDialog.save(filters.to)"
                  >
                    {{ $t("btn.ok") }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" lg="1">
              <v-btn text color="primary" @click="filter" :loading="loading">{{ $t("btn.filter_results") }}</v-btn>
            </v-col>
          </v-row>

          <empty-box v-if="! reservations.length" />
          <v-simple-table v-else dense>
              <thead>
              <tr>
                  <th class="text-left" style="width: 30%">{{ $t("label.teacher") }}</th>
                  <th class="text-left">{{ $t("label.date") }}</th>
                  <th class="text-left">{{ $t("label.start_time") }}</th>
                  <th class="text-left">{{ $t("label.end_time") }}</th>
                <th style="width: 165px;text-align: right">{{ $t("label.actions") }}</th>
              </tr>
              </thead>
              <tbody>
              <tr
                class="table-row"
                      v-for="reservation in sortedReservations"
                      :key="reservation.id"
                      @mouseenter="hovered = reservation.id"
                      @mouseleave="hovered = null"
              >
                  <td>{{ reservation.teacherName }}</td>
                  <td>{{ reservation.from | dateFormat('dd.MM.yyyy') }}</td>
                  <td>{{ reservation.from | dateFormat('HH:mm') }}</td>
                  <td>{{ reservation.to | dateFormat('HH:mm') }}</td>
                <td style="text-align: right">
                  <v-progress-linear
                    indeterminate
                    color="primary"
                    v-if="[editing, deleting].includes(reservation.id)" />

                  <template v-else>
                    <v-chip
                      :disabled="editing !== null"
                      @click.stop="edit(reservation.id)"
                      class="hidden-until-row-hovered mx-1"
                      link
                      label
                      x-small
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-chip>
                    <v-chip
                      :disabled="deleting !== null"
                      class="hidden-until-row-hovered mx-1"
                      link
                      label
                      x-small
                      dark
                      color="red"
                      @click.stop="destroy(reservation.id)"
                    >
                      <v-icon x-small>mdi-close</v-icon>
                    </v-chip>
                  </template>
                </td>
              </tr>
              </tbody>
          </v-simple-table>
      </template>
  </v-card-text>

  <VehicleReservationDialog ref="vehicleReservationDialog" :show-delete-button="false" @created="addNewReservation" @updated="updateReservation"/>
</v-card>
</template>

<script>
import VehicleReservationDialog from '@/components/vehicle/VehicleReservationDialog.vue'
import vehiclesService from '@/services/vehiclesService'
import { showConfirm } from "@/helpers/dialogs";
import { EventBus } from "@/EventBus";
import { compareDesc, format, isBefore, parseISO } from 'date-fns'
import VehicleToolBar from '@/components/vehicle/VehicleToolBar.vue'

export default {
  name: 'VehicleReservations',
  components: { VehicleToolBar, VehicleReservationDialog },
  data() {
    return {
      loading: true,
      reservations: [],
      hovered: null,
      editing: null,
      deleting: null,
      fromDialog: false,
      toDialog: false,
      filters: {
        from: null,
        to: null
      }
    }
  },
  computed: {
    formattedFrom() {
      return this.filters.from ? format(parseISO(this.filters.from), "dd.MM.yyyy") : undefined
    },
    formattedTo() {
      return this.filters.to ? format(parseISO(this.filters.to), "dd.MM.yyyy") : undefined
    },
    sortedReservations() {
      const reservations = [...this.reservations]

      return reservations.sort((first, second) => {
        return compareDesc(parseISO(second.from), parseISO(first.from))
      })
    }
  },
  mounted () {
    this.filters.from = format(new Date(), "yyyy-MM-dd")
    this.fetch()
  },
  methods: {
    fetch() {
      this.loading = true;
      vehiclesService.loadReservations(this.$route.params.id, this.filters)
          .then(response => {
            this.reservations = response.data;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    openReservationDialog() {
      const data = { vehicleId: this.$route.params.id }
      this.$refs.vehicleReservationDialog.open(data)
    },
    edit(id) {
      this.$refs.vehicleReservationDialog.loading = true;
      this.$refs.vehicleReservationDialog.open({reservationId: id});
      vehiclesService
        .getReservation(this.$route.params.id, id)
        .then(response => {
          this.$refs.vehicleReservationDialog.initReservationData({
            ...response.data,
            vehicleId: this.$route.params.id,
            reservationId: id
          });
        })
        .finally(() => {
          this.$refs.vehicleReservationDialog.loading = false;
        });
    },
    addNewReservation(data) {
      if (! this.checkIfReservationPassFilter(data)) return
      this.reservations.push(data)
    },
    updateReservation(data) {
      const reservationIndex = this.reservations.findIndex(item => item.id === data.id)

      if (! this.checkIfReservationPassFilter(data)) {
        this.reservations.splice(reservationIndex, 1)
        return
      }

      this.$set(this.reservations, reservationIndex, data)
    },
    destroy(id) {
      showConfirm(
        this.$t('btn.confirm'),
        this.$t('messages.are_your_sure_delete'),
        () => {
          this.deleting = true
          vehiclesService
            .deleteReservation(this.$route.params.id, id)
            .then(() => {
              this.reservations = this.reservations.filter(item => item.id !== id);
              EventBus.$emit("reservation-deleted");
            })
            .finally(() => {
              this.deleting = null
            })
        }
      )
    },
    setFilterFrom(value) {
      this.filters.from = value

      if (this.filters.to && isBefore(parseISO(this.filters.to), parseISO(this.filters.from))) {
        this.filters.to = this.filters.from
      }
    },
    filter() {
      this.fetch()
    },
    checkIfReservationPassFilter(reservation) {
      let passed = true

      if (reservation.from && this.filters.from) {
        passed = passed && reservation.from >= this.filters.from
      }

      if (reservation.to && this.filters.to) {
        passed = passed && reservation.to <= this.filters.to
      }

      return passed
    }
  }
}
</script>

<style scoped>

</style>
