<template>
  <div>
    <v-row v-if="currentAppointment.teacherId">
      <v-col>
        <TeachersField
            :disabled="true"
            :filteredTeachers="teachers"
            :teacherProp="currentAppointment.teacherId" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <SpecialNameField
            :disabled="true"
            :validation="true"
            :specialNameProp="currentAppointment.specialName" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <NotesField
            :disabled="true"
            :validation="false"
            :notesProp="currentAppointment.notes" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import TeachersField from "@/components/calendar/appointmentModal/fields/TeachersField";
import SpecialNameField from "@/components/calendar/appointmentModal/fields/SpecialNameField";
import NotesField from "@/components/calendar/appointmentModal/fields/NotesField";

export default {
  name: "StudentSpecialForm",
  components: {
    TeachersField,
    SpecialNameField,
    NotesField,
  },
  props: {
    teachers: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { currentAppointment: "currentAppointmentGetter", }),
  },
};
</script>

<style lang="scss" scoped>
.special-tabs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  .v-btn--active {
  }
}
</style>
