<template>
  <v-container>
    <school-credits-balance-widget />
    <credits-purchase-form class="mt-2" />
    <school-credits-history class="mt-2" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import SchoolCreditsBalanceWidget from "@/components/school/SchoolCreditsBalanceWidget.vue";
import CreditsPurchaseForm from "@/components/school/CreditsPurchaseForm.vue";
import SchoolCreditsHistory from "@/components/school/SchoolCreditsHistory.vue";

export default {
  name: "Balance",
  components: {SchoolCreditsHistory, CreditsPurchaseForm, SchoolCreditsBalanceWidget},
  computed: {
    ...mapGetters("auth", { currentUser: "currentUser" }),
    ...mapGetters("school", ["school"]),
  },
};
</script>
