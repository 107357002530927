import * as middleware from "@/router/middleware";
import store from "@/store";

const routes = [
    {
        path: "/login",
        name: "login",
        meta: {
            pageName: "route.signIn",
        },
        component: () => import("@/views/Auth/Login.vue"),
        beforeEnter: middleware.isNotAuthenticated,
        alias: '/',
    },
    {
        path: "/login-as",
        name: "login-as",
        meta: {
            pageName: "route.loginAs",
        },
        component: () => import("@/views/Auth/LoginAs.vue"),
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        meta: {
            pageName: "route.forgotPassword",
        },
        component: () => import("@/views/Auth/ForgotPassword.vue"),
    },
    {
        path: "/reset-password/:token",
        name: "reset-password",
        meta: {
            pageName: "route.resetPassword",
        },
        component: () => import("@/views/Auth/ResetPassword.vue"),
    },
    {
        path: "/auth/:provider/callback",
        component: {
            template: '<div class="auth-component"></div>',
        },
    },
    {
        path: "/registration",
        name: "registration",
        meta: {
            pageName: "route.registration",
        },
        component: () => import("@/views/Auth/Registration.vue"),
        beforeEnter: middleware.isNotAuthenticated,
    },
    {
        path: "/account-activation",
        name: "account-activation",
        meta: {
            pageName: "route.accountActivation",
            layout: "layouts-main",
        },
        component: () => import("@/views/Auth/AccountActivation.vue"),
        beforeEnter: middleware.isAuthenticated,
    },
    {
        path: "/create-parent-account",
        name: "create-parent-account",
        meta: {
            pageName: "route.createParentAccount",
        },
        component: () => import("@/views/Auth/CreateParentAccount.vue"),
    },
    {
        path: "/children-accounts",
        name: "children-accounts",
        meta: {
            pageName: "route.childrenAccounts",
            hasUserTypes: ["elter"],
            layout: "layouts-main",
        },
        component: () => import("@/views/Auth/ChildrenAccounts.vue"),
        beforeEnter: middleware.isAuthenticated,
    },
    {
        path: "/dashboard",
        name: "dashboard",
        meta: {
            pageName: "route.dashboard",
            layout: "layouts-main",
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Dashboard"),
    },
    {
        path: "/calendar",
        name: "calendar",
        meta: {
            pageName: "route.calendar",
            layout: "layouts-main",
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Calendar"),
    },
    {
        path: "/calendar/appointment/:id",
        name: "calendar.appointment",
        meta: {
            pageName: "route.appointmentDetails",
            layout: "layouts-main",
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/AppointmentDetails"),
    },
    {
        path: "/calendar/appointment/:id/evaluation",
        name: "calendar.appointment.evaluation",
        meta: {
            pageName: "route.appointmentEvaluation",
            layout: "layouts-main",
        },
        children: [
            {
                path: ":categoryId",
                name: "calendar.appointment.evaluation.category",
                meta: { pageName: "route.appointmentEvaluation" },
                component: () => import("@/components/insights/EvaluationCategory.vue"),
            },
        ],
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/AppointmentEvaluationPage.vue"),
    },
    {
        path: "/messenger",
        name: "messenger",
        meta: {
            pageName: "route.messenger",
            layout: "layouts-main",
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Messenger"),
    },
    {
        path: "/personal-settings",
        name: "personal-settings",
        meta: {
            pageName: "route.personalSettings.title",
            layout: "layouts-main",
        },
        children: [
            {
                path: "profile",
                name: "personal-settings.profile",
                meta: { pageName: "route.personalSettings.children.profile" },
                beforeEnter: middleware.isAuthenticated,
                component: () => import("@/views/PersonalSettings/Profile"),
            },
            {
                path: "change-password",
                name: "personal-settings.password",
                meta: {
                    pageName: "route.personalSettings.children.changePassword",
                },
                component: () => import("@/views/PersonalSettings/Auth"),
            },
            {
                path: "teacher-settings",
                name: "personal-settings.teacher-settings",
                meta: { pageName: "route.personalSettings.children.teacher" },
                component: () => import("@/views/PersonalSettings/Teacher"),
            },
            {
                path: "notifications",
                name: "personal-settings.notifications",
                meta: {
                    pageName: "route.personalSettings.children.notifications",
                },
                component: () => import("@/views/PersonalSettings/Notifications"),
            },
            {
                path: "calendar-settings",
                name: "personal-settings.calendar-settings",
                meta: {
                    pageName: "route.personalSettings.children.calendarSettings",
                },
                beforeEnter: middleware.isAuthenticated,
                component: () => import("@/views/PersonalSettings/CalendarSettings"),
            },
            {
                path: "parents",
                name: "personal-settings.parents",
                meta: { pageName: "route.personalSettings.children.parents" },
                component: () => import("@/views/PersonalSettings/Parents.vue"),
            },
        ],
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/PersonalSettings/Index"),
    },
    {
        path: "/scanner/:id",
        name: "scanner",
        meta: {
            pageName: "Scanner",
            hasUserTypes: ["teacher"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Scanner"),
    },
    {
        path: "/settings",
        name: "school-settings",
        meta: {
            pageName: "route.schoolSettings.title",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"]
        },
        children: [
            {
                path: "demo-mode",
                name: "school-settings.demo-mode",
                meta: {
                    pageName: "route.schoolSettings.children.demoMode",
                    hasUserTypes: ["school-administrator"]
                },
                component: () => import("@/views/SchoolSettings/DemoMode"),
                beforeEnter: (to, from, next) => {
                    return to.name === "school-settings.demo-mode" &&
                    !store.state.school.isDemoModeEnabled
                      ? next('general')
                      : next();
                },
            },

            {
                path: "general",
                name: "school-settings.general",
                meta: {
                    pageName: "route.schoolSettings.children.general",
                    hasUserTypes: ["school-administrator"]
                },
                component: () => import("@/views/SchoolSettings/General"),
            },
            {
                path: "limits",
                name: "school-settings.limits",
                meta: {
                    pageName: "route.schoolSettings.children.limits",
                    hasUserTypes: ["school-administrator"]
                },
                component: () => import("@/views/SchoolSettings/Limits"),
            },
            {
                path: "holidays",
                name: "school-settings.holidays",
                meta: {
                    pageName: "route.schoolSettings.children.holidays",
                    hasUserTypes: ["school-administrator"]
                },
                component: () => import("@/views/SchoolSettings/Holidays"),
            },
            {
                path: "license-price",
                name: "school-settings.licensePrice",
                meta: {
                    pageName: "route.schoolSettings.children.licensePrice",
                    hasUserTypes: ["school-administrator"]
                },
                component: () => import("@/views/SchoolSettings/LicensePrice"),
            },
            {
                path: "event-styles",
                name: "school-settings.event-styles",
                meta: {
                    pageName: "route.schoolSettings.children.eventStyles",
                    hasUserTypes: ["school-administrator"]
                },
                component: () => import("@/views/SchoolSettings/EventStyles"),
            },
            {
                path: "payments",
                name: "school-settings.payments",
                meta: {
                    pageName: "route.schoolSettings.children.payments",
                    hasUserTypes: ["school-administrator"]
                },
                component: () => import("@/views/SchoolSettings/Payments"),
                beforeEnter: (to, from, next) => {
                    return to.name === "school-settings.payments" &&
                    !store.state.school.isWithStripePayment
                      ? next(from.path)
                      : next();
                },
            },
            {
                path: "payment-bonus",
                name: "school-settings.payment-bonus",
                meta: {
                    pageName: "route.schoolSettings.children.paymentBonus",
                    hasUserTypes: ["school-administrator"]
                },
                component: () => import("@/views/SchoolSettings/PaymentBonus"),
                beforeEnter: (to, from, next) => {
                    return to.name === "school-settings.payment-bonus" &&
                    !store.state.school.isWithStripePayment
                      ? next(from.path)
                      : next();
                },
            },
            {
                path: "digital-attendance",
                name: "school-settings.digital-attendance",
                meta: {
                    pageName: "route.schoolSettings.children.digitalAttendance",
                    hasUserTypes: ["school-administrator"]
                },
                component: () => import("@/views/SchoolSettings/DigitalAttendance"),
            },
            {
                path: "notifications",
                name: "school-settings.notifications",
                meta: {
                    pageName: "route.schoolSettings.children.notifications",
                    hasUserTypes: ["school-administrator"]
                },
                component: () => import("@/views/SchoolSettings/Notifications"),
            },
            {
                path: "custom-messages",
                name: "school-settings.custom-messages",
                meta: {
                    pageName: "route.schoolSettings.children.customMessages",
                    hasUserTypes: ["school-administrator"]
                },
                component: () => import("@/views/PersonalSettings/CustomMessages"),
            },
            {
                path: "invoices",
                name: "school-settings.invoices",
                meta: {
                    pageName: "route.schoolSettings.children.invoices",
                    hasUserTypes: ["school-administrator"]
                },
                component: () => import("@/views/SchoolSettings/Invoices"),
            },
            {
                path: "cars-park",
                name: "school-settings.cars-park",
                meta: {
                    pageName: "route.schoolSettings.children.carsPark",
                    hasUserTypes: ["school-administrator"]
                },
                component: () => import("@/views/SchoolSettings/CarsPark"),
            },
            {
                path: "click-and-learn",
                name: "school-settings.clicklearn",
                meta: {
                    pageName: "route.schoolSettings.children.clicklearn",
                    hasUserTypes: ["school-administrator"]
                },
                component: () => import("@/views/SchoolSettings/ClickAndLearn"),
            },
            // {
            //     path: "covid-2019",
            //     name: "school-settings.covid-2019",
            //     meta: {
            //         pageName: "route.schoolSettings.children.covid-2019",
            //         hasUserTypes: ["school-administrator"]
            //     },
            //     component: () => import("@/views/SchoolSettings/Covid2019"),
            // },
        ],
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/SchoolSettings/Index")
    },
    {
        path: "/administrators",
        name: "administrators",
        meta: {
            pageName: "route.administrators",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/SchoolAdministrators/Administrators"),
    },
    {
        path: "/teachers",
        name: "teachers",
        meta: {
            pageName: "route.teachers",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Teachers/Index"),
    },
    {
        path: "/teachers/:id",
        name: "teachers.teacher",
        meta: {
            pageName: "route.teacherDetails",
            layout: "layouts-main",
            parent: "teachers",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Teachers/Teacher"),
        children: [
            {
                path: "work-time",
                name: "teachers.teacher.work-time",
                meta: {
                    pageName: "route.teacherWorkTime",
                    layout: "layouts-main",
                    parent: "teachers.teacher",
                    hasUserTypes: ["school-administrator", "teacher"],
                },
                beforeEnter: middleware.isAuthenticated,
                component: () => import("@/views/Teachers/TeacherWorkTime"),
            },
        ],
    },
    // {
    //     path: "/teachers/:id/work-time",
    //     name: "teachers.teacher.work-time",
    //     meta: {
    //         pageName: "route.teacherWorkTime",
    //         layout: "layouts-main",
    //         parent: "teachers.teacher",
    //         hasUserTypes: ["school-administrator", "teacher"],
    //     },
    //     beforeEnter: middleware.isAuthenticated,
    //     component: () => import("@/views/Teachers/TeacherWorkTime"),
    // },
    {
        path: "/students",
        name: "students",
        meta: {
            pageName: "route.students",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator", "teacher"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Students/Index"),
    },
    {
        path: "/students/:id",
        name: "students.student",
        meta: {
            pageName: "route.studentDetails",
            layout: "layouts-main",
            parent: "students",
            hasUserTypes: ["school-administrator", "teacher"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Students/Student"),
        children: [
            {
                path: "education",
                name: "students.student.education",
                meta: {
                    pageName: "route.studentEducation",
                    layout: "layouts-main",
                    parent: "students.student",
                    hasUserTypes: ["school-administrator", "teacher"],
                },
                beforeEnter: middleware.isAuthenticated,
                component: () => import("@/views/Students/StudentEducation"),
                children: [
                    {
                        path: "license/:licenseId",
                        name: "students.student.education.license",
                        meta: {
                            pageName: "route.studentEducationLicense",
                            layout: "layouts-main",
                            parent: "students.student.education",
                            hasUserTypes: ["school-administrator", "teacher"],
                        },
                        beforeEnter: middleware.isAuthenticated,
                        component: () => import("@/views/Students/StudentEducationLicense"),
                    },
                    {
                        path: "license/:licenseId/insights",
                        name: "students.student.education.license.insights",
                        meta: {
                            pageName: "route.studentEducationLicenseInsights",
                            layout: "layouts-main",
                            parent: "students.student.education",
                            hasUserTypes: ["school-administrator", "teacher"],
                        },
                        beforeEnter: middleware.isAuthenticated,
                        component: () => import("@/views/Students/Insights/StudentLicenseInsights"),
                    },
                ]
            },
            {
                path: "/students/:id/finances",
                name: "students.student.finances",
                meta: {
                    pageName: "route.studentPayments",
                    layout: "layouts-main",
                    parent: "students.student",
                    hasUserTypes: ["school-administrator", "teacher"],
                },
                beforeEnter: middleware.isAuthenticated,
                component: () => import("@/views/Students/StudentPayments"),
            },
            {
                path: "/students/:id/finances/overview",
                name: "students.student.finances.overview",
                meta: {
                    pageName: "route.studentPaymentsOverview",
                    layout: "layouts-main",
                    parent: "students.student",
                    hasUserTypes: ["school-administrator"],
                },
                beforeEnter: middleware.isAuthenticated,
                component: () => import("@/views/Students/StudentCostsOverview"),
            },
            {
                path: "/students/:id/agenda",
                name: "students.student.agenda",
                meta: {
                    pageName: "route.studentAgenda",
                    layout: "layouts-main",
                    parent: "students.student",
                    hasUserTypes: ["school-administrator", "teacher"],
                },
                beforeEnter: middleware.isAuthenticated,
                component: () => import("@/views/Students/StudentAgenda"),
            },
            {
                path: "/students/:id/documents",
                name: "students.student.documents",
                meta: {
                    pageName: "route.studentDocuments",
                    layout: "layouts-main",
                    parent: "students.student",
                    hasUserTypes: ["school-administrator", "teacher"],
                },
                beforeEnter: middleware.isAuthenticated,
                component: () => import("@/views/Students/StudentDocuments"),
            },
            {
                path: "/students/:id/notes",
                name: "students.student.notes",
                meta: {
                    pageName: "route.studentNotes",
                    layout: "layouts-main",
                    parent: "students.student",
                    hasUserTypes: ["school-administrator", "teacher"],
                },
                beforeEnter: middleware.isAuthenticated,
                component: () => import("@/views/Students/StudentNotes"),
            },
            {
                path: "/students/:id/invoices",
                name: "students.student.invoices",
                meta: {
                    pageName: "route.studentInvoices",
                    layout: "layouts-main",
                    parent: "students.student",
                    hasUserTypes: ["school-administrator", "teacher"],
                },
                beforeEnter: middleware.isAuthenticated,
                component: () => import("@/views/Students/StudentInvoices"),
            },
        ],
    },
    {
        path: "/documents",
        name: "student.documents",
        meta: {
            pageName: "route.studentDocuments",
            layout: "layouts-main",
            hasUserTypes: ["student"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/StudentDocuments"),
    },
    {
        path: "/student-education-progress",
        name: "student.education.progress",
        meta: {
            pageName: "route.studentEducationProgress",
            layout: "layouts-main",
            hasUserTypes: ["student"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/StudentEducationProgress"),
        children: [
            {
                path: "license/:licenseId",
                name: "student.education.license.progress",
                meta: {
                    pageName: "route.studentEducationLicense",
                    layout: "layouts-main",
                    parent: "student.education.progress",
                    hasUserTypes: ["student"],
                },
                beforeEnter: middleware.isAuthenticated,
                component: () => import("@/views/Students/StudentEducationLicense"),
            },
            {
                path: "license/:licenseId/insights",
                name: "student.education.license.insights",
                meta: {
                    pageName: "route.studentEducationLicenseInsights",
                    layout: "layouts-main",
                    parent: "students.student.education",
                    hasUserTypes: ["student"],
                },
                beforeEnter: middleware.isAuthenticated,
                component: () => import("@/views/Students/Insights/StudentLicenseInsights"),
            },
        ]
    },
    {
        path: "/price-lists",
        name: "price-lists",
        meta: {
            pageName: "route.priceLists",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/PriceLists/Index"),
    },
    {
        path: "/price-lists/:id",
        name: "price-list",
        meta: {
            pageName: "route.priceList",
            layout: "layouts-main",
            parent: "price-lists",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/PriceLists/PriceList")
    },
    {
        path: "/happy-hours",
        name: "happy-hours",
        meta: {
            pageName: "route.happyHours",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/HappyHours"),
    },
    {
        path: "/service-templates",
        name: "service-templates",
        meta: {
            pageName: "route.serviceTemplates",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/ServiceTemplates"),
    },
    {
        path: "/print-media",
        name: "print-media",
        meta: {
            pageName: "route.print-media",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/PrintMedia"),
    },
    {
        path: "/click-and-learn",
        name: "click-and-learn",
        meta: {
            pageName: "route.clickAndLearn",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/ClickAndLearn.vue"),
    },
    {
        path: "/insights",
        name: "insights",
        meta: {
            pageName: "route.insights",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator", "teacher"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Insights/Index.vue"),
    },
    {
        path: "/insights/:template",
        name: "insights.template",
        meta: {
            pageName: "route.insightsTemplate",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator", "teacher"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Insights/ViewTemplate.vue"),
    },
    {
        path: "/theory",
        name: "theory",
        meta: {
            pageName: "route.theory",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Theory"),
    },
    {
        path: "/theory/attendance",
        name: "theory-attendance",
        meta: {
            pageName: "route.theory",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/TheoryAttendance"),
    },
    {
        path: "/license-requirements",
        name: "license-requirements",
        meta: {
            pageName: "route.licenseRequirements",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/LicenseRequirements"),
    },
    {
        path: "/addresses",
        name: "addresses",
        meta: {
            pageName: "route.addresses",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Addresses"),
    },
    {
        path: "/appointments",
        name: "appointments",
        meta: {
            pageName: "route.appointments",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Appointments"),
    },
    {
        path: "/exam-statistics",
        name: "exam-statistics",
        meta: {
            pageName: "route.examStatistics",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/ExamStatistics"),
    },
    {
        path: "/vehicles/overview",
        name: "vehicles",
        meta: {
            pageName: "route.vehiclesOverview",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"]
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Vehicles/Index"),
    },
    {
        path: "/vehicles/calendar",
        name: "vehicles.calendar",
        meta: {
            pageName: "route.vehiclesCalendar",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Vehicles/VehiclesCalendar.vue"),
    },

    {
        path: "/vehicles/overview/:id",
        name: "vehicles.vehicle",
        meta: {
            pageName: "route.vehicleDetails",
            layout: "layouts-main",
            parent: "vehicles",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Vehicles/Vehicle"),
        children: [
            {
                path: "mileage",
                name: "vehicles.vehicle.mileage",
                meta: {
                    pageName: "route.vehicleMileage",
                    layout: "layouts-main",
                    parent: "vehicles.vehicle",
                    hasUserTypes: ["school-administrator"],
                },
                beforeEnter: middleware.isAuthenticated,
                component: () => import("@/views/Vehicles/Vehicle/VehicleMileage"),
            },
            {
                path: "fueling",
                name: "vehicles.vehicle.fueling",
                meta: {
                    pageName: "route.vehicleFueling",
                    layout: "layouts-main",
                    parent: "vehicles.vehicle",
                    hasUserTypes: ["school-administrator"],
                },
                beforeEnter: middleware.isAuthenticated,
                component: () => import("@/views/Vehicles/Vehicle/VehicleFueling"),
            },
            {
                path: "service",
                name: "vehicles.vehicle.service",
                meta: {
                    pageName: "route.vehicleService",
                    layout: "layouts-main",
                    parent: "vehicles.vehicle",
                    hasUserTypes: ["school-administrator"],
                },
                beforeEnter: middleware.isAuthenticated,
                component: () => import("@/views/Vehicles/Vehicle/VehicleService"),
            },
            {
                path: "reservations",
                name: "vehicles.vehicle.reservations",
                meta: {
                    pageName: "route.vehicleReservations",
                    layout: "layouts-main",
                    parent: "vehicles.vehicle",
                    hasUserTypes: ["school-administrator"],
                },
                beforeEnter: middleware.isAuthenticated,
                component: () => import("@/views/Vehicles/Vehicle/VehicleReservations"),
            },
            {
                path: "expenses",
                name: "vehicles.vehicle.expenses",
                meta: {
                    pageName: "route.vehicleExpenses",
                    layout: "layouts-main",
                    parent: "vehicles.vehicle",
                    hasUserTypes: ["school-administrator"],
                },
                beforeEnter: middleware.isAuthenticated,
                component: () => import("@/views/Vehicles/Vehicle/VehicleExpenses"),
            },
        ]
    },
    {
        path: "/payments",
        name: "payments",
        meta: {
            pageName: "route.payments",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Payments/Index"),
    },
    {
        path: "/payouts",
        name: "payouts",
        meta: {
            pageName: "route.payouts",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Payments/Payouts"),
    },
    {
        path: "/payments/:id",
        name: "payments.view",
        meta: {
            pageName: "route.payments",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Payments/ViewPayment"),
    },
    {
        path: "/payouts/:id",
        name: "payouts.view",
        meta: {
            pageName: "route.payouts",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Payments/ViewPayout"),
    },
    {
        path: "/invoices",
        name: "invoices",
        meta: {
            pageName: "route.invoices",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Invoices/Index"),
    },
    {
        path: "/invoices/:id",
        name: "invoices.view",
        meta: {
            pageName: "route.invoices",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Invoices/ViewInvoice"),
    },
    {
        path: "/balance",
        name: "balance",
        meta: {
            pageName: "route.balance",
            layout: "layouts-main",
            hasUserTypes: ["student"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Balance"),
    },
    {
        path: "/school-balance",
        name: "school-balance",
        meta: {
            pageName: "route.schoolBalance",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/SchoolBalance"),
    },
    {
        path: "/pay",
        name: "pay",
        meta: {
            pageName: "route.balance",
            hasUserTypes: ["student"],
        },
        component: () => import("@/views/Pay"),
    },
    {
        path: "/payment-succeeded",
        name: "payment-succeeded",
        meta: {
            pageName: "route.balance",
            hasUserTypes: ["student"],
        },
        component: () => import("@/views/PaymentSucceeded"),
    },
    {
        path: "/payment-failed",
        name: "pay-failed",
        meta: {
            pageName: "route.balance",
            hasUserTypes: ["student"],
        },
        component: () => import("@/views/PaymentFailed"),
    },
    {
        path: "/costs-overview",
        name: "costs-overview",
        meta: {
            pageName: "route.costsOverview",
            layout: "layouts-main",
            hasUserTypes: ["student"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/CostsOverview"),
    },
    {
        path: "/support-center",
        name: "support",
        meta: {
            pageName: "route.support",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/SupportCenter/Index"),
    },
    {
        path: "/support-center/ticket/:ticket",
        name: "support.ticket.view",
        meta: {
            pageName: "route.viewTicket",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/SupportCenter/ViewTicket"),
    },
    {
        path: "/patch-notes",
        name: "patch-notes",
        meta: {
            pageName: "route.patch-notes",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator", "teacher", "student", 'elter']
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/PatchNotes"),
    },
    {
        path: "/inactive-account",
        name: "inactive.account",
        meta: {
            pageName: "route.inactive_account",
            layout: "layouts-main",
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Errors/InactiveAccount"),
    },
    {
        path: "/recycle-bin",
        name: "recycle-bin",
        meta: {
            pageName: "route.recycleBin",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/RecycleBin/Index"),
    },
    {
        path: "/recycle-bin/:recyclable",
        name: "recycle-bin.recyclable",
        meta: {
            pageName: "route.recycleBin",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/RecycleBin/Recyclable"),
    },
    {
        path: "/terminal",
        name: "terminal",
        meta: {
            pageName: "route.terminal",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/Terminal"),
    },
    {
        path: "/unpaid-students",
        name: "unpaid-students",
        meta: {
            pageName: "route.unpaidStudents",
            layout: "layouts-main",
            hasUserTypes: ["school-administrator"],
        },
        beforeEnter: middleware.isAuthenticated,
        component: () => import("@/views/UnpaidStudents"),
    },
    {
        path: "/accept-privacy-policy",
        name: "accept-privacy-policy",
        meta: {
            pageName: "route.acceptPrivacyPolicy",
            layout: "layouts-main",
        },
        component: () => import("@/views/AcceptPrivacyPolicy.vue"),
        beforeEnter: middleware.isAuthenticated,
    },
    {
        path: "*",
        name: "404",
        meta: {
            pageName: "route.not_found",
        },
        beforeEnter: (to, from, next) => {
            if (store.state.auth.authToken) to.meta.layout = "layouts-main";
            return next();
        },
        component: () => import("@/views/Errors/NotFound"),
    },
];

export default routes;
