const supportedLocales = [
    {
        code: "de",
        name: "Deutsch"
    },
    {
        code: "en",
        name: "English"
    },
    {
        code: "fr",
        name: "Français"
    },
    {
        code: "ro",
        name: "Română"
    },
    {
        code: "ru",
        name: "Русский"
    }
]

export default supportedLocales