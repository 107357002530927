export default function normalize(input, key, relations = []) {
  const byId = {};
  const allIds = [];
  const rel = {};

  input.forEach((item) => {
    byId[item[key]] = item;
    allIds.push(item[key]);

    relations.forEach((key) => {
      if (key in item) {
        const relKey = `idsBy${toPascalCase(key)}`;
        if (!(relKey in rel)) rel[relKey] = {};
        if (item[key] in rel[relKey]) rel[relKey][item[key]].push(item.id);
        else rel[relKey][item[key]] = [item.id];
      }
    });
  });
  return {
    byId,
    allIds,
    ...rel,
  };
}

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const toPascalCase = (s) => {
  return s.split("_").map(capitalize).join("");
};
