<template>
  <div></div>
</template>
<script>
// import { eachDayOfInterval, format } from 'date-fns'

export default {
  name: "LineChart",
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  computed: {
    // daysLabels () {
    //   let days = this.data.map(item => new Date(item.day))
    //
    //   if (days.length > 1) {
    //     const startDay = days[0]
    //     const endDay = days[days.length - 1]
    //
    //     days = eachDayOfInterval({ start: startDay, end: endDay })
    //   }
    //
    //   return days
    // },
    // daysValues () {
    //   const daysValues = []
    //
    //   this.daysLabels.forEach(dayLabel => {
    //     const day = format(dayLabel, 'yyyy-MM-dd')
    //     const dataItem = this.data.find(item => {
    //       return item.day === day
    //     }) || {}
    //
    //     const moneyAmount = Math.abs(dataItem.moneyAmount)
    //
    //     daysValues.push(moneyAmount || 0)
    //   })
    //
    //   return daysValues
    // }
  },
  mounted() {
    // this.drawChart()
  },
  methods: {
    // drawChart () {
    //   this.renderChart({
    //     labels: this.daysLabels.map(item => {
    //       return format(item, 'LLL d')
    //     }),
    //     datasets: [{
    //       data: this.daysValues,
    //       fill: false,
    //       borderColor: 'rgba(54, 162, 235, 1)',
    //       borderWidth: 2
    //     }]
    //   }, {
    //     legend: {
    //       display: false
    //     },
    //     bezierCurve: false,
    //     scales: {
    //       xAxes: [{
    //         ticks: {
    //           autoSkip: false,
    //           callback: function (value, index, values) {
    //             if (index === 0 || index === values.length - 1) return value
    //
    //             return ''
    //           }
    //         },
    //         gridLines: {
    //           display: false
    //         }
    //       }],
    //       yAxes: [{
    //         ticks: {
    //           display: false
    //         },
    //         gridLines: {
    //           display: false
    //         }
    //       }]
    //     }
    //   })
    // }
  },
};
</script>

<style scoped></style>
