<template>
  <v-card-text>
    <v-skeleton-loader type="text" width="300" />
    <v-skeleton-loader type="image" width="100%" height="45px" class="mt-3" />
    <v-list-item class="mt-5">
      <v-list-item-avatar>
        <v-skeleton-loader type="avatar" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-skeleton-loader type="list-item-two-line" />
      </v-list-item-content>
      <v-list-item-action>
        <v-skeleton-loader type="button" />
      </v-list-item-action>
    </v-list-item>
    <v-list-item>
      <v-list-item-avatar>
        <v-skeleton-loader type="avatar" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-skeleton-loader type="list-item-two-line" />
      </v-list-item-content>
      <v-list-item-action>
        <v-skeleton-loader type="button" />
      </v-list-item-action>
    </v-list-item>
  </v-card-text>
</template>

<script>
export default {
  name: "TerminalPaymentsLoader",
};
</script>

<style scoped lang="scss"></style>
