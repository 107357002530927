<template>
  <div class="bg-container">
    <v-overlay absolute opacity="0">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary" />
    </v-overlay>
  </div>
</template>

<script>
import appointmentService from "@/services/appointmentService";
import AppointmentModel from "@/store/models/AppointmentModel";
import { CapacitorBarcodeScanner } from "@capacitor/barcode-scanner";

export default {
  name: "Scanner",
  data: () => {
    return {
      loader: false,
      eventId: "",
    };
  },
  mounted() {
    this.eventId = this.$route.params.id;
    this.startScanner();
  },
  methods: {
    async startScanner() {
      await CapacitorBarcodeScanner
        .scanBarcode({})
        .then(result => {
          if (result.ScanResult) {
            this.loader = true;
            const studentId = parseInt(result.ScanResult);
            const appointment = AppointmentModel.find(this.eventId) || {};
            const payload = { studentsIds: [studentId], wasScannedWithQR: true };
            const pluckIds = appointment.studentsIds.map(student => student.id);
            const action = pluckIds.includes(studentId) ? "confirm_presence" : "subscribe_to_theory";

            appointmentService[action](appointment.id, payload).then((resp) => {
              this.$router
                .push({
                  path: "/calendar?event_id=" + this.eventId,
                  query: { return_to: "calendar", updated_students: resp.data.studentsIds }
                })
                .then(() => this.$snackbar.show({
                  message: this.$t("lesson_attendance.successfully_code_scan"),
                  color: "success",
                  type: "toast",
                  timeout: 5000,
                  position: { top: true, right: true }
                }));
            })
              .catch(err => {
                const { data } = err.response;

                if (data?.message) {
                  this.$snackbar.show({ message: data?.message, color: "error" });
                }

                this.$router
                  .push({ path: "/calendar?event_id=" + this.eventId, query: { return_to: "calendar" } })
                  .then(err => console.log(err));
              });
          }
        })
        .catch(error => {
          console.log("error", error);

          if (error && error.code === "OS-PLUG-BARC-0006") {
            this.$router.push({ path: "/calendar?event_id=" + this.eventId });
            return;
          }

          // In case user will choose "Ok" he is sure that he does not want give camera permissions and there is no need for a message
          if (error && error.code !== "OS-PLUG-BARC-0007") {
            this.$snackbar.show({ message: this.$i18n.t("label.something_gone_wrong"), color: "error" });
          }

          this.$router.go(-1);
        })
    }
  },
};
</script>

<style lang="scss">
</style>
