<template>
  <div class="appointments-box" @dragenter.stop="enter" @dragleave.stop="leave">
    <template v-if="appointments.length">
      <VehicleAppointment
          v-for="appointment in appointments"
          :key="appointment.id"
          :class="`vehicle-event ${eventIsHovered(appointment.id)}`"
          :loading="appointmentLoader(appointment.id)"
          :appointment="appointment"
          :vehicleId="vehicle.id"
          @mouseenter.native="hoverIn(appointment)"
          @mouseleave.native="hoverOut()"
          :draggable="!disableAppointment && canBeDragged && !reservationMode"
          :disabled="disableAppointment"
          v-touch="{ start: (e) => touchAppointment(e, appointment) }"
          @dragstart.native="startAppointmentDragging({ appointment, updateOrDelete: true })"
          @dragend.native="stopAppointmentDragging"
      />
    </template>
    <template v-if="reservations.length">
      <ReservedPeriod
          v-for="reservation in reservations"
          :key="reservation.id"
          :period = reservation
          :onlyClickable="checkIfTeacherIsDisabled(reservation)"
          :isStatic="!reservationMode"
          @movePeriod="startDragging($event, reservation)"
          @resizeStart="startResize($event, reservation, 'from')"
          @resizeEnd="startResize($event, reservation, 'to')"
      />
    </template>

    <template v-if="services.length">
      <ServicePeriod
        v-for="service in services" :key="service.id + 'i'"
        :period=service
        :showTooltip="reservationMode"
      />
    </template>

    <TempReservedPeriod
        v-if="tempReservationPeriod?.vehicleId === vehicle.id"
        :period="tempFocusedReservation"
        :temp="true"
        :loading="reservationsLoader"
    />

    <div v-if="isDropZone || reservationDropZone" class="drop-zone d-flex align-center justify-center" @mouseover="hoverVehicleLine">
      <ShadowAppointment v-if="isDropZone" :appointment="draggableAppointment" :focused="focused"/>
    </div>

    <div class="bottom-line"></div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { addMinutes, parseISO, format, isWithinInterval, startOfDay, addSeconds, endOfDay } from 'date-fns'

import VehicleAppointment from '@/components/vehicles-calendar/VehicleAppointment.vue'
import ShadowAppointment from '@/components/vehicles-calendar/ShadowAppointment.vue'
import ServicePeriod from '@/components/vehicles-calendar/ServicePeriod.vue'
import ReservedPeriod from '@/components/vehicles-calendar/ReservedPeriod.vue'
import TempReservedPeriod from '@/components/vehicles-calendar/ReservedPeriod.vue'
import { HOVER_APPOINTMENT } from '@/store/mutation-types/vehiclesCalendar'
import {
  HOVER_VEHICLE_LINE,
  TOGGLE_DRAGGING_STATUS,
  TOGGLE_RESIZING_STATUS,
  SET_RESERVATION_FIELDS,
} from '@/store/mutation-types/vehiclesReservation'
import TeacherModel from '@/store/models/TeacherModel'
import AppointmentModel from '@/store/models/AppointmentModel'
import VehicleReservationModel from '@/store/models/VehicleReservationModel'
import VehicleServiceModel from '@/store/models/VehicleServiceModel'
import { EventBus } from '@/EventBus'

export default {
  name: 'VehicleAppointmentsRow',
  components: { VehicleAppointment, ShadowAppointment, ReservedPeriod, ServicePeriod, TempReservedPeriod },
  data: () => ({
    threshold: 10,
    focused: false,
    canBeDragged: true,

    initialPeriodOBj: {},
    startPointCoords: null,
    initialClickCoords: {},
    clickGap: null,
    resizeSide: null,
  }),
  watch: {
    'validVehiclesIds'(val) {
      if (!val.length) this.focused = false
    }
  },
  props: {
    appointments: {
      type: Array,
    },
    reservations: {
      type: Array,
    },
    services: {
      type: Array,
    },
    vehicle: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('vehiclesCalendar', [
      'activeVehiclesIds',
      'validVehiclesIds',
      'hoveredAppointmentId',
      'draggableAppointment',
      'disableAppointment',
      'draggingProcess',
    ]),
    ...mapState('vehiclesReservation', ['reservationMode', 'tempReservationPeriod', 'hoveredVehicleId', 'reservationsLoader', 'draggingStatus']),
    ...mapGetters('vehiclesCalendar', ['oneMinuteSize']),
    ...mapGetters('vehiclesReservation', ['tempFocusedReservation']),
    reservationDropZone () {
      if (!this.draggingStatus) return
      const teacher = TeacherModel.find(this.tempReservationPeriod.teacherId)
      return this.hoveredVehicleId && teacher?.vehiclesIds.includes(this.vehicle.id)
    },

    isDropZone () {
      const reservations = this.reservations.filter((item) => item.teacherId !== this.draggableAppointment.teacherId)
      const itemObj = {
        id: this.draggableAppointment.id,
        from: parseISO(this.draggableAppointment.start),
        to: addMinutes(parseISO(this.draggableAppointment.start), this.draggableAppointment.duration),
      }
      return this.validVehiclesIds.includes(this.vehicle.id)
          && !this.checkForOverlaps(itemObj, this.appointments)
          && !this.checkForOverlaps(itemObj, reservations)
          && !this.checkForOverlaps(itemObj, this.services)
    },
  },
  methods: {
    ...mapActions('vehiclesCalendar', ['startAppointmentDragging', 'stopAppointmentDragging']),
    ...mapActions('vehiclesReservation', ['updateReservationStop', 'resetStatuses']),

    checkForOverlaps(item, array = []) {
      const croppedStart = addMinutes(item.from, 1)
      const croppedEnd = addMinutes(item.to, - 1)
      const filteredArray = array.filter(arrItem => arrItem.id !== item.id)

      return filteredArray.some((app) => {
        const start = parseISO(app.start)
        const end = addMinutes(start, app.duration)
        return isWithinInterval(croppedStart, { start, end })
          || isWithinInterval(croppedEnd, { start, end })
          || (croppedStart <= start && croppedEnd >= end)
      })
    },

    checkIfTeacherIsDisabled(reservation) {
      const pluckIds = TeacherModel.all().map(teacher => teacher.id) || []
      return reservation.id && !pluckIds.includes(reservation.teacherId)
    },

    startResize(e, reservation, side) {
      if (!this.reservationMode) return
      if (this.checkIfTeacherIsDisabled(reservation)) return
      this.initialPeriodOBj = reservation
      this.resizeSide = side
      const rect = e.target.closest(".period").getBoundingClientRect()
      this.startPointCoords = side === 'from' ? rect.left : rect.right

      this.$store.commit(`vehiclesReservation/${TOGGLE_RESIZING_STATUS}`, true)
      this.$store.commit(`vehiclesReservation/${SET_RESERVATION_FIELDS}`, reservation)

      window.addEventListener("mousemove", this.resizing);
      window.addEventListener("mouseup", this.stopManipulations);
    },
    resizing(e) {
      const diffInMinutes = (e.x - this.startPointCoords) / this.oneMinuteSize
      const operator = this.resizeSide === 'from' ? 'floor' : 'ceil'
      const snappedMinutes = Math[operator](diffInMinutes / 15) * 15
      const startTime = addMinutes(parseISO(this.initialPeriodOBj[this.resizeSide]), snappedMinutes)

      if (!this.dateIsWithinInterval(startTime)) return

      const fields = { [this.resizeSide]: format(startTime, "yyyy-MM-dd HH:mm") }
      this.$store.commit(`vehiclesReservation/${SET_RESERVATION_FIELDS}`, { ...this.tempReservationPeriod, ...fields })
    },

    startDragging(e, reservation) {
      if (!this.reservationMode) return
      this.initialPeriodOBj = reservation
      const rect = e.target.closest(".period").getBoundingClientRect()
      this.startPointCoords = rect.left
      this.initialClickCoords = e
      this.clickGap = e.x - rect.left

      this.$store.commit(`vehiclesReservation/${HOVER_VEHICLE_LINE}`, this.vehicle.id)
      this.$store.commit(`vehiclesReservation/${SET_RESERVATION_FIELDS}`, reservation)

      if (!this.checkIfTeacherIsDisabled(reservation)) {
        window.addEventListener("mousemove", this.dragging)
      }
      window.addEventListener("mouseup", this.stopManipulations);
    },
    dragging(e) {
      if (!this.draggingStatus) this.$store.commit(`vehiclesReservation/${TOGGLE_DRAGGING_STATUS}`, true)
      const diffInMinutes = (e.x - this.startPointCoords - this.clickGap) / this.oneMinuteSize
      const snappedMinutes = Math.floor(diffInMinutes / 15) * 15
      const startTime = addMinutes(parseISO(this.initialPeriodOBj.from), snappedMinutes)
      const endTime = addMinutes(startTime, this.initialPeriodOBj.duration)
      const optionalObj = this.dateIsWithinInterval(startTime) && this.dateIsWithinInterval(endTime)
        ? { from: format(startTime, "yyyy-MM-dd HH:mm"), to: format(endTime, "yyyy-MM-dd HH:mm"), }
        : {}
      const fields = { vehicleId: this.$store.state.vehiclesReservation.hoveredVehicleId, ...optionalObj }

      this.$store.commit(`vehiclesReservation/${SET_RESERVATION_FIELDS}`, { ...this.tempReservationPeriod, ...fields })
    },
    stopManipulations(e) {
      const diffX = (Math.abs(this.initialClickCoords.x - e.x))
      const diffY = (Math.abs(this.initialClickCoords.y - e.y))

      const modTempObj = { ...this.tempReservationPeriod, id: this.initialPeriodOBj.id }
      const isEqualPeriod = JSON.stringify({ ...this.initialPeriodOBj }) === JSON.stringify(modTempObj)

      const initialParams = {
        reservationId: this.initialPeriodOBj.id,
        vehicleId: this.initialPeriodOBj.vehicleId,
        teacherId: this.initialPeriodOBj.teacherId,
        teacherName: this.initialPeriodOBj.teacherName,
        from: this.initialPeriodOBj.from,
        to: this.initialPeriodOBj.to,
      }

      this.resetData()

      // OVERLAPPING CHECK LOGIC
      const { vehicleId, teacherId } = this.tempReservationPeriod
      const appointment = AppointmentModel.query()
        .where((item) => {
          return item.teacherId !== teacherId && (item.primaryVehicleId === vehicleId || item.secondary === vehicleId)
        })
        .get()
      const reservations = VehicleReservationModel.query()
        .where((item) => item.vehicleId === vehicleId)
        .get()
      const services = VehicleServiceModel.query().where((item) => item.vehicleId === vehicleId).get()
      const itemObj = {
        id: this.tempReservationPeriod.id,
        from: parseISO(this.tempReservationPeriod.from),
        to: parseISO(this.tempReservationPeriod.to),
      }
      const overlapsWithAppointment = this.checkForOverlaps(itemObj, appointment)
      const overlapsWithReservation = this.checkForOverlaps(itemObj, reservations)
      const overlapsWithServices = this.checkForOverlaps(itemObj, services)

      if (overlapsWithAppointment || overlapsWithReservation || overlapsWithServices) {
        this.$store.commit(`vehiclesReservation/${SET_RESERVATION_FIELDS}`, {})
        this.resetStatuses()
        return
      }

      if(diffX <= this.threshold && diffY <= this.threshold) {
        EventBus.$emit('open-dialog', initialParams)
      }

      this.updateReservationStop(isEqualPeriod)
    },


    resetData() {
      this.initialPeriodOBj = null
      this.startPointCoords = null
      this.clickGap = null
      this.resizeSide = null

      window.removeEventListener("mousemove", this.dragging, false);
      window.removeEventListener("mousemove", this.resizing, false);
      window.removeEventListener("mouseup", this.stopManipulations, false);
    },

    dateIsWithinInterval(date) {
      const interval = {
        start: startOfDay(parseISO(this.tempReservationPeriod.from)),
        end: addSeconds(endOfDay(parseISO(this.tempReservationPeriod.from)), 1)
      }
      return isWithinInterval(date, interval)
    },

    hoverVehicleLine() {
      this.$store.commit(`vehiclesReservation/${HOVER_VEHICLE_LINE}`, this.vehicle.id)
    },
    touchAppointment(e, appointment) {
      e.stopPropagation()
      this.startAppointmentDragging({ appointment, updateOrDelete: true })
    },
    appointmentLoader(appointmentId) {
      return this.disableAppointment && this.draggableAppointment && this.draggableAppointment.id === appointmentId
    },
    eventIsHovered(eventId) {
      if (!this.hoveredAppointmentId && !this.reservationMode) return
      return this.reservationMode || this.hoveredAppointmentId !== eventId ? 'grayscale' : ''
    },
    enter () {
      this.focused = this.isDropZone
    },
    leave () {
      this.focused = false
    },
    hoverIn(appointment) {
      this.canBeDragged = appointment.primaryVehicleId === this.vehicle.id
      this.$store.commit(`vehiclesCalendar/${HOVER_APPOINTMENT}`, appointment.id)
    },
    hoverOut() {
      this.canBeDragged = true
      this.$store.commit(`vehiclesCalendar/${HOVER_APPOINTMENT}`, null)
    },
  }
}
</script>

<style lang="scss" scoped>
.bottom-line{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #cccc;
  pointer-events: none;
}
.appointments-box {
  pointer-events: auto;
  height: 60px;
  position: relative;
  //border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.drop-zone {
  pointer-events: auto;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 5px;
  background: rgba(131, 212, 255, 0.2);
  color: #858585;
  padding: 2px 5px;
  box-sizing: border-box;
  font-size: 12px;
  & * {
    pointer-events: none;
  }
}
.grayscale{
  opacity: 0.5;
  pointer-events: none;
}
</style>
