<template>
  <div class="theory-subscribers">
    <div v-if="roomSize" class="label">
      {{ `${$t("label.participants")} ${students.length}/${roomSize}` }}
    </div>
    <div v-else class="label">
      {{ `${$t("label.participants")} ${students.length}` }}
    </div>
    <div class="subscribers-wrapper pa-1">
      <v-list class="pa-0">
        <v-list-item
          v-for="student in students"
          :key="student.id"
          class="pl-1 pr-1 item">
          <v-list-item-content class="pa-0">
            <v-list-item-title>
              <v-icon v-if="schoolSettings.isDigitalAttendanceEnabled" :color="student.isPresent ? 'secondary' : ''">
                {{ student.isPresent ? 'mdi-account-check' : 'mdi-account' }}
              </v-icon>
              {{ getFullName(student.id) }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="ma-0" v-if="isSchoolAdministrator">
            <div class="d-flex justify-space-between">
              <div class="ml-2">
                <v-btn
                  v-if="availableToSetPresentStatus && !student.isPresent"
                  :disabled="disabled"
                  :loading="loading && confirmLoader === student.id"
                  class="ml-1"
                  color="success"
                  x-small
                  @click="setPresent(student.id)"
                >
                  {{ $t("btn.confirm") }}
                </v-btn>
                <v-btn
                  :color="isNewAppointment ? 'info' : 'error'"
                  :disabled="disabled"
                  :loading="loading && cancelLoader === student.id"
                  class="ml-1"
                  x-small
                  @click="cancel(student.id)"
                >
                  {{ $t("btn.remove") }}
                </v-btn>
              </div>
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import StudentModel from "@/store/models/StudentModel";
import { mapGetters } from 'vuex'
import user from '@/utils/mixins/user'

export default {
  name: "TheorySignedStudents",
  mixins: [user],
  data: () => ({
    confirmLoader: null,
    cancelLoader: null,
  }),
  props: {
    isNewAppointment: {
      type: Boolean,
      required: true,
    },
    roomSize: {
      type: Number,
    },
    students: {
      type: Array
    },
    disabled: {
      type: Boolean,
      required: true
    },
    theoryType: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    availableToSetPresentStatus() {
      return this.isSchoolAdministrator &&
            this.theoryType === 'lesson' &&
            this.schoolSettings.isDigitalAttendanceEnabled &&
            !this.isNewAppointment
    }
  },
  methods: {
    getFullName(studentId) {
      const student = StudentModel.find(studentId)
      return student ? student.fullName : '';
    },
    setPresent(studentId) {
      this.clearLoaders();
      this.$emit("emitSetPresent", studentId);
      this.confirmLoader = studentId;
    },
    cancel(studentId) {
      this.clearLoaders();
      this.$emit("emitUnsubscribe", studentId);
      this.cancelLoader = studentId;
    },
    clearLoaders() {
      this.confirmLoader = null;
      this.cancelLoader = null;
    }
  },
};
</script>

<style lang="scss">
.theory-subscribers {
  border: thin solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  position: relative;
  .label {
    position: absolute;
    top: -8px;
    left: 12px;
    line-height: 12px;
    z-index: 3;
    background: #fff;
    padding: 0 2px;
    font-size: 0.8rem;
  }
  .subscribers-wrapper {
    overflow-x: auto;
    max-height: 187px;
  }
  .item {
    min-height: 35px !important;
    border-bottom: thin solid rgba(0, 0, 0, 0.38);
    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
