<template>
  <v-card outlined :loading="loading">
    <v-card-title>
      {{ $t('label.student_price_lists') }}
    </v-card-title>

    <v-card-text v-if="loading" >
      <v-skeleton-loader type="table-row@3" />
    </v-card-text>
    <empty-box v-else-if="data.length === 0" />
    <template v-else>
      <v-simple-table dense>
        <thead>
        <tr>
          <th>{{ $t('label.pricelist_name') }}</th>
          <th>{{ $t('label.pricelist_assigned_at') }}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="priceList in data" :key="priceList.since">
          <td :class="{'font-italic': priceList.isScheduled}">{{ priceList.name }}</td>
          <td :class="{'font-italic': priceList.isScheduled}" style="width: 200px;">{{ priceList.since | dateFormat}}</td>
          <td style="width: 50px;">
            <v-chip color="success" small label  v-if="priceList.isCurrent">
              <v-icon left small>mdi-check</v-icon>
              {{ $t('label.pricelist_is_current') }}
            </v-chip>
            <v-chip color="info" small label  v-if="priceList.isScheduled">
              <v-icon left small>mdi-clock-outline</v-icon>
              {{ $t('label.pricelist_is_scheduled') }}
            </v-chip>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </template>
  </v-card>
</template>

<script>
import studentsService from "@/services/studentsService";

export default {
  name: "StudentPriceLists",
  props: {
    student: {
      type: Object,
      require: true,
    },
  },
  data () {
    return {
      loading: false,
      data: [],
    }
  },
  mounted() {
    this.loading = true
    studentsService
        .priceLists(this.student.id)
        .then(resp => {
          this.data = resp.data;
        })
        .finally(() => {
          this.loading = false
        })
  }
}
</script>

<style scoped>

</style>