<template>
  <v-row>
    <v-col>
      <div class="events-card">
        <v-card class="mb-1">
          <v-card-title>{{ $t("label.your_next_appointments") }}</v-card-title>

          <v-divider />

          <v-card-text>
            <v-progress-circular
              size="30"
              class="d-flex ma-auto"
              v-if="loading"
              indeterminate />

            <div v-else>
              <div v-if="events.length > 0">
                <EventAlert
                  v-for="event in shownEvents"
                  :key="event.id"
                  :event="event"
                  :color="eventBodyColorByType(event.lessonType)"
                  @open="
                    $router.push({
                      name: 'calendar.appointment',
                      params: { id: event.id },
                    })
                  " />
              </div>
              <empty-box v-else />
            </div>

            <v-btn
              v-if="events.length > 5"
              class="mt-3"
              color="primary"
              block
              text
              x-small
              @click="() => (toggle = !toggle)">
              <v-icon class="mr-2">{{
                toggle ? "mdi-chevron-down" : "mdi-chevron-up"
              }}</v-icon>
              {{ toggle ? $t("label.more") : $t("label.less") }}
            </v-btn>
          </v-card-text>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import EventAlert from "@/components/event/EventAlert";
import studentService from "@/services/studentService";
import eventService from "@/services/eventService";
import LessonTypeModel from "@/store/models/LessonTypeModel";

export default {
  name: "StudentNextAppointments",
  data: () => ({
    loading: true,
    events: [],
    eventTypes: [],
    toggle: true,
  }),
  components: { EventAlert },
  computed: {
    shownEvents() {
      const allEvents = this.events || [];
      return this.toggle ? allEvents.slice(0, 5) : allEvents;
    },
  },
  methods: {
    eventBodyColorByType(type) {
      const color = LessonTypeModel.query().where("name", type).get() || {};
      return color[0].bodyColor;
    },
  },
  mounted() {
    eventService
      .lessonTypes()
      .then((resp) => LessonTypeModel.insert({ data: resp.data }));
    studentService
      .nextEvents()
      .then((response) => {
        this.events = response.data || [];
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped></style>
