import api from "@/utils/api";
export default {
  registerLicense(data) {
    return new Promise((resolve, reject) => {
      api
        .post(`clickandlearn/license-codes/register`, data)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  addExistingLicense(data) {
    return new Promise((resolve, reject) => {
      api
        .post(`clickandlearn/license-codes/add-existing`, data)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  revokeLicense(licenseCodeId) {
    return new Promise((resolve, reject) => {
      api
        .put(`clickandlearn/license-codes/${licenseCodeId}/revoke`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  renewLicense(licenseCodeId, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`clickandlearn/license-codes/${licenseCodeId}/renew`, data)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateStudentPassword(licenseCodeId, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`clickandlearn/license-codes/${licenseCodeId}/password`, data)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getLicenseStatus(licenseCodeId) {
    return new Promise((resolve, reject) => {
      api
        .get(`clickandlearn/license-codes/${licenseCodeId}/status`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getLicenseProgress(licenseCodeId) {
    return new Promise((resolve, reject) => {
      api
        .get(`clickandlearn/license-codes/${licenseCodeId}/progress`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getLicense(licenseCodeId) {
    return new Promise((resolve, reject) => {
      api
        .get(`clickandlearn/license-codes/${licenseCodeId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getUnassignedLicenses() {
    return new Promise((resolve, reject) => {
      api
        .get(`clickandlearn/license-codes?isAssigned=false`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getAllLicenseCodes() {
    return new Promise((resolve, reject) => {
      api
        .get(`clickandlearn/license-codes`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getAssignedLicenseCodes() {
    return new Promise((resolve, reject) => {
      api
        .get(`clickandlearn/license-codes?isAssigned=true`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getRenewalCodes() {
    return new Promise((resolve, reject) => {
      api
        .get(`clickandlearn/renewal-codes`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getLicenseCodesCount() {
    return new Promise((resolve, reject) => {
      api
        .get(`clickandlearn/license-codes?count=true`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getRenewalCodesCount() {
    return new Promise((resolve, reject) => {
      api
        .get(`clickandlearn/renewal-codes?count=true`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getPendingOrders() {
    return new Promise((resolve, reject) => {
      api
        .get(`clickandlearn/orders?pending=true`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  placeOrder(data) {
    return new Promise((resolve, reject) => {
      api
        .post(`clickandlearn/orders`, data)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getStudentCredentials() {
    return new Promise((resolve, reject) => {
      api
        .get(`clickandlearn/student-credentials`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  downloadInvoiceForOrder(orderId) {
    return new Promise((resolve, reject) => {
      api
        .get(`clickandlearn/orders/${orderId}/invoice`,{
          responseType: "blob",
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  }
}