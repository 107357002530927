<template>
  <div class="range-controls">
    <v-btn
      :disabled="loaderGetter"
      text
      icon
      small
      color="primary"
      @click="changeCalendarRange('backward')"
      ><v-icon>mdi-chevron-left</v-icon></v-btn
    >
    <v-btn
      class="mx-2"
      :disabled="loaderGetter"
      text
      small
      color="primary"
      :outlined="mdLandscapeAndUp"
      :icon="!mdLandscapeAndUp"
      @click="goToday">
      <v-icon :left="mdLandscapeAndUp">mdi-calendar-today</v-icon
      >{{ mdLandscapeAndUp ? $t("today") : "" }}
    </v-btn>
    <v-btn
      :disabled="loaderGetter"
      text
      icon
      small
      color="primary"
      @click="changeCalendarRange('forward')"
      ><v-icon>mdi-chevron-right</v-icon></v-btn
    >
  </div>
</template>

<script>
import responsive from "@/utils/mixins/responsive";
import { lightFormat, addDays, parseISO, format } from "date-fns";
import { mapGetters, mapActions } from "vuex";
import loadAppointments from "@/utils/mixins/loadAppointments";

export default {
  name: "CalendarRangeControls",
  mixins: [responsive, loadAppointments],
  computed: {
    ...mapGetters(["activeDate", "calendarLayout", "loaderGetter"]),
    parsedActiveDate() {
      return parseISO(this.activeDate);
    },
    today() {
      return format(new Date(), "yyyy-MM-dd");
    },
    rangeSwitchDaysCount() {
      switch (this.calendarLayout) {
        case "LAYOUT_DAY":
          return 1;
        case "LAYOUT_THREE_DAYS":
          return 3;
        case "LAYOUT_WEEK":
          return 7;
          // case 'LAYOUT_MONTH': return 30
      }
      return 1;
    },
  },
  methods: {
    ...mapActions(["setCalendarDate"]),

    changeCalendarRange(direction = "forward") {
      let dir = 1;
      if (direction === "backward") dir = -1;

      const date = lightFormat(addDays(this.parsedActiveDate, this.rangeSwitchDaysCount * dir), "yyyy-MM-dd");
      const month = format(parseISO(date), "yyyy-MM");
      this.setCalendarDate({ month: month, activeDate: date });
      this.loadAppointments();
    },
    goToday() {
      this.setCalendarDate({
        month: format(new Date(), "yyyy-MM"),
        activeDate: format(new Date(), "yyyy-MM-dd"),
      });
      this.loadAppointments();
    },
  },
};
</script>

<style lang="scss">
.range-controls {
  display: flex;
  align-items: center;
}
</style>
