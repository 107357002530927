<template>
  <div class="timepicker-parent">
    <v-text-field
      :disabled="disabled"
      :label="label"
      @click="showPicker"
      v-model="time"
      menu-props="auto"
      outlined
      readonly
      hide-details
      dense></v-text-field>
    <div
      class="time-menu"
      v-if="this.visiblePicker"
      v-click-outside="this.resetTimePicker">
      <ul>
        <li
          :key="index"
          v-for="(item, index) in timeFields"
          @click="changeTime(item.value)"
          :class="item.disabled && 'disabled'">
          <span :class="`hours ${pickedHour ? 'picked' : ''}`">{{
            item.hours
          }}</span
          >:<span :class="`minutes ${pickedHour ? 'picked' : ''}`">{{
            item.minutes
          }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  format,
  parseISO,
  addMinutes,
  addHours,
  isWithinInterval,
} from "date-fns";
import { ClickOutside } from "vuetify/lib/directives";
export default {
  name: "TimePicker",
  directives: { ClickOutside },
  data() {
    return {
      visiblePicker: false,
      pickedHour: null,
    };
  },
  props: {
    timeProp: {
      type: Date,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    disabledPeriod: {
      type: Object,
    },
  },
  computed: {
    time() {
      return format(this.timeProp, "HH:mm");
    },
    zeroTimeFormat() {
      return format(this.timeProp, "yyyy-MM-dd 00:00:00");
    },
    timeFields() {
      const timeArr = [];
      if (!this.pickedHour) {
        for (let hr = 0; hr < 24; hr++) {
          const isoFormat = addHours(parseISO(this.zeroTimeFormat), hr);
          timeArr.push({
            value: isoFormat,
            hours: format(isoFormat, "HH"),
            minutes: format(isoFormat, "mm"),
            disabled: this.disabledPeriod ? isWithinInterval(isoFormat, {
              start: this.disabledPeriod.from,
              end: this.disabledPeriod.to,
            }) : null,
          });
        }
      } else {
        for (let min = 0; min < 60; min = min + 5) {
          const isoFormat = addMinutes(this.pickedHour, min);
          timeArr.push({
            value: isoFormat,
            hours: format(isoFormat, "HH"),
            minutes: format(isoFormat, "mm"),
            disabled: this.disabledPeriod ? isWithinInterval(isoFormat, {
              start: this.disabledPeriod.from,
              end: this.disabledPeriod.to,
            }) : null,
          });
        }
      }
      return timeArr;
    },
  },
  methods: {
    showPicker() {
      this.visiblePicker = true;
    },
    resetTimePicker() {
      this.visiblePicker = false;
      this.pickedHour = null;
    },
    changeTime(value) {
      if (!this.pickedHour) {
        this.$emit("emitInitialStart");
        this.pickedHour = value;
      } else {
        this.$emit("emitTimeChange", value);
        this.resetTimePicker();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.timepicker-parent {
  position: relative;
  z-index: 5;
  background: #fff;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
  text-decoration: line-through;
}

.time-menu {
  height: 190px;
  width: 100%;
  position: absolute;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
  left: 0;
  top: 40px;
  background: #fff;
  overflow-x: auto;
  z-index: 99;

  .hours {
    color: #333;
    &.picked {
      font-weight: bold;
    }
  }

  .minutes {
    color: #c7c7c7;
    &.picked {
      color: #333;
    }
  }

  ul {
    width: 100%;
    list-style: none;
    text-align: center;
    padding: 0;

    li {
      cursor: pointer;
      border-bottom: 1px solid #eee;
      padding: 2px;

      &:hover {
        background: #eee;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
