import Notification from "@/notifications/notification";

export default class StudentEducationStarted extends Notification {
  wildCardsData() {
    const data = this.notification.data;

    return {
      message: {
        educationStatus: data.educationStatus,
      },
    };
  }
}
