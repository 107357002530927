import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import de from "vuetify/es5/locale/de";
import en from "vuetify/es5/locale/en";
import ro from "vuetify/es5/locale/ro";
import ru from "vuetify/es5/locale/ru";
import "@mdi/font/css/materialdesignicons.css";
import Vaccine from "@/components/icons/Vaccine";
import JokerHat from "@/components/icons/JokerHat";
import ClickAndLearn from "@/components/icons/ClickAndLearn";
import ClickAndLearnWhite from "@/components/icons/ClickAndLearnWhite";
import Coins from "@/components/icons/Coins.vue";
import LocalizationManager from "@/helpers/LocalizationManager";

Vue.use(Vuetify);

const savedLocale = LocalizationManager.savedLocale
const locale = LocalizationManager.supportedLocales.includes(savedLocale) ?
    savedLocale :
    process.env.VUE_APP_LANGUAGE

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: {
      vaccine: { component: Vaccine },
      jokerHat: { component: JokerHat },
      clickAndLearn: { component: ClickAndLearn },
      clickAndLearnWhite: { component: ClickAndLearnWhite },
      coins: { component: Coins }
    },
  },
  theme: {
    themes: {
      light: {
        primary: "#00435E",
        secondary: "#76ab26",
      },
    },
    options: {
      customProperties: true,
    },
  },
  lang: {
    locales: { de, en, ro, ru },
    current: locale,
  },
});
