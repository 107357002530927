import { Model } from "@vuex-orm/core";

export default class OfficeModel extends Model {
  static entity = "offices";

  static fields() {
    return {
      id: this.attr(null),
      name: this.string(""),
      city: this.string(""),
      houseNr: this.string(""),
      postcode: this.string(""),
      street: this.string(""),
      nrOfActiveStudents: this.number(0),
      studentsIds: this.attr([]),
      nrOfActiveTeachers: this.number(0),
      teachersIds: this.attr([]),
    };
  }

  get address() {
    return (
      this.street + " " + this.houseNr + ", " + this.postcode + " " + this.city
    );
  }
}
