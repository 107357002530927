<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ContentTabs",
  computed: {
    ...mapGetters('studentsDirectory', ['openedStudents', 'activeTab'])
  },
  methods: {
    ...mapActions('studentsDirectory', ['closeStudent']),
  }
}
</script>

<template>
  <v-tabs
    v-model="$store.state.studentsDirectory.activeTab"
    show-arrows
    next-icon="mdi-arrow-right-bold"
    prev-icon="mdi-arrow-left-bold"
    center-active
    height="35"
    background-color="#eeeeee"
  >
    <v-tabs-slider color="secondary"></v-tabs-slider>
    <v-tab href="#home" @click.middle.prevent>
      <v-icon left>mdi-home</v-icon> Startseite
    </v-tab>
    <v-tab :href="`#${student.id}`" v-for="student in openedStudents" :key="student.id" @click.middle.prevent="closeStudent(student.id)">
      {{ student.name }}
      <v-btn class="ml-2" color="red" :loading="student.loading" :disabled="student.loading" icon x-small @click="closeStudent(student.id)">
        <template v-slot:loader>
          <v-progress-circular indeterminate size="12" color="primary" width="2"/>
        </template>
        <v-icon x-small>mdi-close</v-icon>
      </v-btn>
    </v-tab>
  </v-tabs>

</template>

<style scoped>

</style>