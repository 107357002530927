<template>
  <v-card-text>
    <v-row>
      <v-col>
        <div class="d-flex">
          <v-skeleton-loader v-for="item in [1, 2, 3, 4]" class="mr-2 d-flex align-center" :key="item" type="chip" height="48"/>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <div class="mb-5">
            <span class="title font-weight-medium text--secondary">
               <v-skeleton-loader width="150" height="12" type="text@1"/>
            </span>
        </div>

        <div class="mt-3 mb-3">
          <v-row dense no-gutters v-for="item in [1, 2, 3]" :key="item" >
            <v-col>
              <v-skeleton-loader type="table-cell"/>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-skeleton-loader type="table-cell"/>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col cols="12" md="4">
        <div class="mb-5">
            <span class="title font-weight-medium text--secondary">
               <v-skeleton-loader width="150" height="12" type="text@1"/>
            </span>
        </div>

        <div class="mt-3 mb-3">
          <v-row dense no-gutters v-for="item in [1, 2, 3, 4]" :key="item" >
            <v-col>
              <v-skeleton-loader type="table-cell"/>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-skeleton-loader type="table-cell"/>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col cols="12" md="4">
        <div class="mb-5">
          <span class="title font-weight-medium text--secondary">
             <v-skeleton-loader width="150" height="12" type="text@1"/>
          </span>
        </div>

        <div class="mt-3 mb-3">
          <v-row dense no-gutters v-for="item in [1, 2]" :key="item" >
            <v-col>
              <v-skeleton-loader type="table-cell"/>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-skeleton-loader type="table-cell"/>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-row class="d-flex justify-space-between mb-7">
          <v-col cols="12" md="3">
            <span class="title font-weight-medium text--secondary">
               <v-skeleton-loader width="150" height="12" type="text@1"/>
            </span>
          </v-col>
        </v-row>

        <v-skeleton-loader v-for="item in [1, 2]" class="mb-3" :key="item" type="image" height="70"/>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: 'PriceListLoader'
}
</script>

<style scoped>

</style>
