<template>
<v-chip
    style="width: 100%"
    :color="progressColor(amountIs, amountShould)"
    label
    dark
    small
>
  <span class="mr-1">{{ label }}</span>
  <span class="text-truncate">{{ name }}</span>
</v-chip>
</template>

<script>
export default {
  name: 'StudentProgressChip',
  props: {
    name: {
      type: String,
      required: true
    },
    amountShould: {
      type: Number,
      default: 0
    },
    amountIs: {
      type: Number,
      default: 0
    },
    singleValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    label() {
      if (this.singleValue) return this.amountIs
      return this.amountShould ? `${this.amountIs}/${this.amountShould}` : '—'
    }
  },
  methods: {
    progressColor(amountIs, amountShould) {
      if (amountShould === 0) return "grey";
      if (amountIs > 0 && amountIs < amountShould) return "orange";
      if (amountIs >= amountShould) return "green";

      return "red";
    },
  },
}
</script>

<style scoped>

</style>
