<script>
import {defineComponent} from 'vue'
import clickAndLearnService from "@/services/clickAndLearnService";

export default defineComponent({
  name: "RenewLicenseDialog",
  data: () => ({
    show: false,
    license: null,
    loading: false,
    // loadingCodes: false,
    // availableRenewalCodes: [],
    // selectedRenewalCode: null
  }),
  computed: {
    // renewalCodes() {
    //   return this.availableRenewalCodes.map(c => {
    //     return {
    //       text: c.code,
    //       value: c.id
    //     }
    //   })
    // },
    savingDisabled() {
      return this.loading || !this.renewalPossible
    },
    renewalsMade() {
      return this.license?.status?.numberOfRenewals || 0
    },
    renewalPossible() {
      return this.renewalsMade < 4
    },
    renewalsInTheSystem() {
      return this.license?.renewals?.length || 0
    },
    renewalsCountMismatch() {
      return this.renewalsInTheSystem !== this.renewalsMade && this.license?.wasCreatedBySchool
    }
  },
  methods: {
    open(license) {
      this.show = true
      this.license = license
      // this.loadingCodes = true
      // clickAndLearnService
      //   .getRenewalCodes()
      //   .then(response => {
      //     this.availableRenewalCodes = response.data
      //     if (this.availableRenewalCodes.length > 0) {
      //       this.selectedRenewalCode = this.availableRenewalCodes[0].id
      //     }
      //   })
      //   .finally(() => {
      //     this.loadingCodes = false
      //   })
    },
    close() {
      this.show = false
      // prevent errors when closing the dialog
      setTimeout(() => {
        this.license = null
        // this.selectedRenewalCode = null
      }, 500)
    },
    save() {
      this.loading = true
      clickAndLearnService.renewLicense(this.license.id)
        .then(response => {
          this.$emit('license-renewed', response.data)
          this.close()
          this.$snackbar.success(this.$t('clickandlearn.license_renewed'))
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
})
</script>

<template>
  <v-dialog width="300" scrollable :value="show" @click:outside="close" @keydown.esc="close">
    <v-card>
      <v-toolbar class="mb-4" dark color="primary" dense>
        <v-toolbar-title>{{ $t('clickandlearn.renew_license') }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-alert colored-border border="left" elevation="1" :type="renewalsMade < 4 ? 'info' : 'error'">
          <span v-if="renewalPossible">{{ $t('clickandlearn.renewals_possible', {count: 4 - renewalsMade}) }}</span>
          <span v-else>{{ $t('clickandlearn.renewal_not_possible') }}</span>
        </v-alert>
<!--        <v-select-->
<!--          class="mt-1"-->
<!--          outlined-->
<!--          :disabled="loading"-->
<!--          :loading="loadingCodes"-->
<!--          :items="renewalCodes"-->
<!--          hide-details-->
<!--          required-->
<!--          :label="$t('clickandlearn.renewal_code')"-->
<!--          v-model="selectedRenewalCode"-->
<!--          prepend-inner-icon="mdi-key-arrow-right"-->
<!--        />-->
        <v-list v-if="this.license?.renewals?.length" dense>
          <v-subheader>{{ $t('clickandlearn.previous_renewals') }}</v-subheader>
          <v-list-item dense two-line v-for="renewal in license?.renewals" :key="renewal.id">
            <v-list-item-avatar><v-icon>mdi-key-arrow-right</v-icon></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ renewal.code }}</v-list-item-title>
              <v-list-item-subtitle>{{ renewal.assignedAt }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <span v-if="renewalsCountMismatch">
          {{$t('label.renewals_outside_bookandpay_notice')}}
        </span>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn text :disabled="loading" @click="close">{{ $t('btn.close') }}</v-btn>
        <v-spacer />
        <v-btn color="primary" @click="save" :disabled="savingDisabled" :loading="loading">
          <v-icon left>mdi-key-arrow-right</v-icon>
          {{ $t('btn.renew') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>