<template>
  <v-card>
    <StudentTabToolBar activeTab="education" >
      <template #ignore-spacer>
        <div v-if="license" class="d-flex align-center text--secondary">
          <div class="subtitle-1">
            <span class="mx-2">&#x2022;</span>{{ license.name }}
          </div>
          <div v-if="isEducationLicenseInsightsRoute" class="subtitle-1">
            <span class="mx-2">&#x2022;</span>{{ $t('route.insights') }}
          </div>
        </div>
      </template>
    </StudentTabToolBar>

    <v-card-text>
      <div v-show="$route.name === 'students.student.education'">
        <v-row v-if="loadingProgress">
          <v-col cols="12" md="6" lg="4" v-for="license in licenses" :key="license.id">
            <EducationLicenseProgressCardLoader/>
          </v-col>
        </v-row>

        <template v-else-if="licensesIds.length > 0">
          <v-row>
            <v-col cols="12" md="6" lg="4" v-for="license in licenses" :key="license.id">
              <EducationLicenseProgressCard
                  :license-id="license.id"
                  :license-progress="licensesProgress[license.id]"
                  :license-requirements="getLicenseRequirements(license.id)"
                  :details-to="{name: 'students.student.education.license', params: { id: $route.params.id, licenseId: license.id }}"
              />
            </v-col>
          </v-row>
        </template>

        <empty-box v-else />
      </div>

      <router-view :key="license && license.id" />
    </v-card-text>
  </v-card>
</template>

<script>
import StudentTabToolBar from '@/components/student/StudentTabToolBar.vue'
import studentsService from '@/services/studentsService'
import StudentModel from "@/store/models/StudentModel"
import AppointmentModel from "@/store/models/AppointmentModel"
import responsive from '@/utils/mixins/responsive'
import LicenseModel from '@/store/models/LicenseModel'
import EducationLicenseProgressCard from '@/components/student/EducationLicenseProgressCard.vue'
import EducationLicenseProgressCardLoader from '@/components/skeleton-loaders/EducationLicenseProgressCardLoader.vue'

export default {
  name: "StudentEducation",
  mixins: [responsive],
  components: { StudentTabToolBar, EducationLicenseProgressCardLoader, EducationLicenseProgressCard },
  data: () => ({
    student: {},
    loadingProgress: true,
    loadingEvents: true,
    licensesProgress: {},
  }),
  mounted () {
    studentsService
        .studentProgress(this.student.id)
        .then((response) => {
          const progressData = {}
          response.data.forEach((item) => progressData[item.licenseId] = item)
          this.licensesProgress = progressData
        })
      .finally(() => this.loadingProgress = false)

    AppointmentModel.commit((state) => state.loading = true);

    const params = { withTrashed: true };
    if (this.$route.params.licenseId) {
      params.license = this.$route.params.licenseId;
    }
    studentsService
      .studentsEvents(this.student.id, params)
      .then((eventsResponse) => {
        AppointmentModel.create({ data: eventsResponse.data || [] });
      })
      .finally(() => {
        this.loadingEvents = false;
        AppointmentModel.commit((state) => state.loading = false);
      });
  },
  beforeRouteEnter(from, to, next) {
    const student = StudentModel.find(from.params.id);

    if (student && student.isDeleted) {
      next({ name: "students.student", params: { id: from.params.id } });
      return
    }
    next(vm => vm.student = student)
  },
  computed: {
    licensesIds() {
      return this.student.licensesIds || []
    },
    license() {
      return LicenseModel.find(this.$route.params.licenseId)
    },
    licenses() {
      return LicenseModel.findIn(this.licensesIds) || []
    },
    isEducationLicenseInsightsRoute() {
      return this.$route.name === 'students.student.education.license.insights'
    }
  },
  methods: {
    getLicenseRequirements(licenseId) {
      return this.student.licensesRequirements.find(item => item.licenseId === licenseId) || {}
    }
  }
};
</script>

<style scoped></style>
