import Notification from "@/notifications/notification";
import router from "@/router";

export default class StudentInsufficientBalanceReminder extends Notification {
  icon() {
    return "mdi-cash-lock";
  }

  action() {
    router.push({ name: "balance", query: {reason: 'insufficient_balance_notification'} });
  }
}