<template>
  <div>
    <v-list>
      <v-list-item two-line dense v-for="index in array" :key="index">
        <v-list-item-content>
          <v-list-item-title>
            <v-skeleton-loader type="image" height="50" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "TeachersWorkTimeLoader",
  props: {
    count: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    array() {
      return [...Array(this.count)];
    },
  },
};
</script>
