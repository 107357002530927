<script>
import { defineComponent } from "vue";
import VehicleModel from "@/store/models/VehicleModel";
import VehicleExpensesDialog from "@/components/vehicle/VehicleExpensesDialog.vue";
import AssignTeacherDialog from "@/components/vehicle/AssignTeacherDialog.vue";
import VehicleRefuelDialog from "@/components/vehicle/VehicleRefuelDialog.vue";
import VehicleMileageDialog from "@/components/vehicle/VehicleMileageDialog.vue";
import VehicleFormDialog from "@/components/vehicle/VehicleFormDialog.vue";
import VehicleDetailsList from "@/components/vehicle/VehicleDetailsList.vue";

import vehicleService from "@/services/vehiclesService";
import TeacherModel from '@/store/models/TeacherModel'
import FileSaver from '@/helpers/fileSaver'
import { showConfirm } from '@/helpers/dialogs'

export default defineComponent({
  name: "VehicleSidebar",
  components: {
    VehicleFormDialog,
    VehicleMileageDialog,
    VehicleRefuelDialog,
    AssignTeacherDialog,
    VehicleExpensesDialog,
    VehicleDetailsList
  },
  props: {
    vehicleId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      showSidebar: false
    };
  },
  computed: {
    item() {
      return VehicleModel.find(this.vehicleId) || {};
    },
    itemTeachers() {
      return TeacherModel
        .query()
        .where(item => this.item.teachersIds.includes(item.id))
        .get()
    }
  },
  methods: {
    open() {
      this.showSidebar = true;
    },
    close() {
      this.showSidebar = false;
    },
    deleteItem(item) {
      this.loadingAction = true;
      showConfirm(
        this.$t('btn.confirm'),
        this.$t("messages.are_your_sure_archive"),
        () => {
          vehicleService
            .destroy(item.id)
            .then(() => {
              vehicleService
                .loadItem(item.id)
                .then((resp) =>
                  VehicleModel.update({ where: item.id, data: resp.data || {} })
                );
            })
            .finally(() => {
              this.loadingAction = false;
            });
        },
        () => {
          this.loadingAction = false;
        }
      );
    },
    downloadDriverAssistantSystemsReport(item) {
      vehicleService.getVehicleDriverAssistanceSystemsPdf(item.id)
        .then((response) => {
          const fileSaver = new FileSaver([response.data])
          fileSaver.setType('application/pdf').saveToDevice(`Fahrerassistenzsystemen-${item.licensePlate}.pdf`)
        })
    },
  }
});
</script>

<template>
  <v-navigation-drawer
    v-model="showSidebar"
    class="px-2"
    fixed
    right
    temporary
    touchless
    width="280"
  >
    <v-list dense>
      <v-subheader class="d-flex justify-space-between">
        <span>{{ $t("label.actions") }}</span>
      </v-subheader>

      <v-list-item :disabled="item.isDeleted" @click="downloadDriverAssistantSystemsReport(item)">
        <v-list-item-icon><v-icon>mdi-file-document</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("btn.fas_pdf") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :disabled="item.isDeleted" @click="$refs.formItem.open(item)">
        <v-list-item-icon>
          <v-icon>mdi-pencil</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("btn.edit") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :disabled="item.isDeleted" @click="deleteItem(item)">
        <v-list-item-icon>
          <v-icon>mdi-archive-arrow-down</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("btn.archive") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list dense>
      <v-subheader class="d-flex justify-space-between">
        <span>{{ $t("label.teachers") }}</span>
        <span v-if="itemTeachers.length > 5">{{ itemTeachers.slice(0, 5).length }} {{ $t('label.of') }} {{ itemTeachers.length }}</span>
      </v-subheader>


      <v-list-item :disabled="item.isDeleted" @click="$refs.assignTeacherDialog.open(item.id)">
        <v-list-item-icon>
          <v-icon>mdi-account-multiple</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ $t("label.edit") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-for="teacher in itemTeachers.slice(0, 5)" :key="teacher.id">
        <v-list-item-content>
          <v-list-item-title v-text="teacher.fullName"/>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <VehicleDetailsList :vehicle="item" :sidebar="true" />

    <VehicleFormDialog ref="formItem" />
    <AssignTeacherDialog ref="assignTeacherDialog" />
    <VehicleMileageDialog ref="vehicleMileageDialog" />
    <VehicleRefuelDialog ref="vehicleRefuelDialog" />
    <VehicleExpensesDialog ref="vehicleExpensesDialog" />
  </v-navigation-drawer>
</template>

<style scoped>

</style>
