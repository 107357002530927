<template>
  <v-dialog
    transition="dialog-bottom-transition"
    scrollable
    max-width="750px"
    :value="show"
    @click:outside="close"
    @keydown.esc="close">
    <v-card>
      <v-card-title class="justify-space-between">
        {{ $t("label.new_ticket") }}
        <v-btn icon text @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form class="mt-3">
          <ValidationObserver ref="form">
            <v-select
              :loading="loadingTypes"
              :disabled="types.length === 0"
              :items="types"
              :placeholder="$t('label.select_issue_type')"
              v-model="ticket.type"
              solo
            />

            <ValidationProvider v-slot="{ errors }" name="topic">
              <v-text-field
                solo
                v-model="ticket.topic"
                :placeholder="$t('form.ticket_topic')"
                :error-messages="errors" />
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" name="text">
              <v-textarea
                solo
                :placeholder="$t('form.ticket_text')"
                rows="4"
                v-model="ticket.text"
                :error-messages="errors" />
            </ValidationProvider>

            <v-file-input
              v-model="ticket.attachments"
              chips
              counter
              multiple
              show-size
              truncate-length="16"
              solo
              :placeholder="$t('form.ticket_attachments')" />
          </ValidationObserver>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text @click="close">
          {{ $t("btn.close") }}
        </v-btn>
        <v-btn
          color="primary"
          @click="create"
          :disabled="loading"
          :loading="loading">
          {{ $t("btn.create_ticket") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import supportTicketsService from "@/services/supportTicketsService";
import appVersion from "@/utils/mixins/appVersion";

export default {
  name: "TicketCreateModal",
  mixins: [appVersion],
  data() {
    return {
      show: false,
      loading: false,
      loadingTypes: false,
      types: [],
      ticket: {
        topic: "",
        text: "",
        attachments: [],
        type: null,
      },
    };
  },
  methods: {
    open() {
      this.show = true;
      this.loadingTypes = true;
      supportTicketsService.getTypes().then((resp) => {
        this.types = resp.data.map((item) => {
          return {
            text: this.$t(`ticket_type.${item}`),
            value: item,
          };
        });
      }).finally(() => {
        this.loadingTypes = false;
      });
    },
    close() {
      this.show = false;
      this.ticket = {
        topic: "",
        text: "",
        attachments: []
      }
    },
    create() {
      this.loading = true;
      const formData = new FormData();
      formData.append("type", this.ticket.type);
      formData.append("topic", this.ticket.topic);
      formData.append("text", this.ticket.text);
      formData.append("appVersion", this.appVersion);
      this.ticket.attachments.forEach((item) => {
        formData.append("attachments[]", item);
      });
      supportTicketsService
        .create(formData)
        .then((resp) => {
          this.$emit("ticketCreated", resp.data);
          this.close();
        })
        .catch((error) => {
          const responseData = error.response.data || {};
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message)
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });

          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
