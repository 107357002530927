<template>
  <v-card outlined class="card" :class="cardClass" :loading="loading">
    <div class="card__title">
      <h2>{{ title }}</h2>
    </div>
    <div class="card__icon">
      <v-icon>mdi-cash-multiple</v-icon>
    </div>
    <div class="card__amount">
      <h3
        class="font-weight-thin"
        :class="$vuetify.breakpoint.mdAndDown ? 'headline' : 'display-1'">
        {{ amount | currency }}
      </h3>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "BalanceCard",
  props: {
    amount: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    colorful: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cardClass() {
      const classes = [];
      if (this.colorful) {
        if (this.amount > 0) classes.push("card--positive");
        else if (this.amount < 0) classes.push("card--negative");
      }

      if (this.inline) {
        classes.push("card--inline");
      }

      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  display: grid;
  grid-template-areas: "title title" "icon amount";
  grid-template-columns: 40px 1fr;
  &--positive {
    background: linear-gradient(90deg, #56ab2f, #a8e063);
    color: #fff;
    border: none !important;
  }
  &--negative {
    background: #dd1818; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to left,
      #e27266,
      #dd1818
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to left,
      #e27266,
      #dd1818
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    color: white;
    border: none !important;
  }
  &--inline {
    padding: 5px 10px;
    grid-template-areas: "icon title amount";
    grid-template-columns: 40px auto 1fr;
  }
  &__icon {
    grid-area: icon;
    align-self: center;
    & > i {
      font-size: 30px;
      color: inherit;
    }
  }
  &__amount {
    grid-area: amount;
    margin-left: auto;
    align-self: center;
  }
  &__title {
    grid-area: title;
    align-self: center;
  }
  &:hover {
    /*box-shadow: ;*/
  }
}
</style>
