var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{staticClass:"list__row py-1 text-body-2",class:{
    'text--disabled': _vm.disabled,
    'list__row-bordered': _vm.leftBorderColor,
  },style:({
    'border-left-color': _vm.leftBorderColor,
    border: 'thin solid rgba(0, 0, 0, 0.12) !important',
  }),attrs:{"rounded":"","outlined":"","color":_vm.color},on:{"click":_vm.onRowClick}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }