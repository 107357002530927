<template>
  <div>
    <SchoolAdministratorSettingsGeneral v-if="isSchoolAdministrator" />
    <TeacherSettingsGeneral v-if="isTeacher" />
    <StudentSettingsGeneral v-if="isExactlyStudent" />
    <ParentSettingsGeneral v-if="isElter" />
  </div>
</template>

<script>
import SchoolAdministratorSettingsGeneral from "@/components/school/administrators/SchoolAdministratorSettingsGeneral";
import StudentSettingsGeneral from "@/components/student/StudentSettingsGeneral";
import TeacherSettingsGeneral from "@/components/teacher/TeacherSettingsGeneral";
import ParentSettingsGeneral from "@/components/parent/ParentSettingsGeneral";
import user from "@/utils/mixins/user";

import { mapActions, mapGetters } from "vuex";
import responsive from "@/utils/mixins/responsive";

export default {
  name: "Profile",
  components: {
    TeacherSettingsGeneral,
    SchoolAdministratorSettingsGeneral,
    StudentSettingsGeneral,
    ParentSettingsGeneral,
  },
  mixins: [user, responsive],
  data: () => ({
    isEmailSuccessfullyVerified: false,
    isVerificationEmailSending: false,
  }),
  computed: {
    ...mapGetters("auth", ["currentUser"]),
  },
  mounted() {
    if (this.$route.query["profile-activation"]) {
      this.getAuthUser().finally(() => {
        const query = Object.assign({}, this.$route.query);

        if (Object.prototype.hasOwnProperty.call(query, "profile-activation")) {
          delete query["profile-activation"];
          this.$router.replace({ query });
        }

        this.isEmailSuccessfullyVerified = true;
      });
    }
  },
  methods: {
    ...mapActions("auth", ["getAuthUser"]),
  },
};
</script>

<style scoped></style>
