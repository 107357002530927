<template>
  <v-row v-if="newStudents.items.length > 0" ref="newStudentsRow" class="mb-1" dense>
    <StudentFormDialog ref="formStudent" />
    <v-col cols="12" lg="12">
      <v-card>
        <v-card-title class="py-2 d-flex justify-space-between">
          <div class="d-flex flex-column">
            <span>{{ $t("label.new_students") }}</span>
          </div>
        </v-card-title>

        <v-divider />

        <v-list>
          <v-list-item
            v-for="student in newStudents.items"
            :key="student.id"
            dense
            two-line
            @click="$refs.formStudent.open(student)">
            <v-list-item-content class="d-flex">
              <v-list-item-title>
                <span class="text-subtitle-1">{{
                    student.firstName + " " + student.lastName
                  }}</span>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ student.createdAt | dateFormat("dd.MM.yyyy HH:mm") }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="student.selectedLicense">
              <div class="d-flex justify-space-between">
                <LicenseIcon :icon="student.selectedLicense.icon" :name="student.selectedLicense.name" />
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <template v-if="newStudents.totalItems > showCount">
          <v-divider />
          <v-card-actions class="py-3 px-7">
            <span v-if="(!showAll && !loading) || (showAll && loading)" class="text-subtitle-1">
              {{ $t("label.plus_some_more_items", { count: newStudents.totalItems - showCount }) }}
            </span>
            <v-spacer />
            <v-btn
              :disabled="loading"
              color="blue"
              text
              :loading="loading"
              @click="toggleViewAll"
            >
              {{ $t("label.view") + " " + (showAll ? $t("label.less") : $t("label.all")) }}
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import schoolService from "@/services/schoolService";
import StudentFormDialog from "@/components/student/StudentFormDialog";
import LicenseIcon from "@/components/LicenseIcon";
import listFilters from "@/utils/mixins/listFilters";

export default {
  name: "NewStudents",
  components: { LicenseIcon, StudentFormDialog },
  mixins: [listFilters],
  data() {
    return {
      showCount: 5,
      showAll: false,
      newStudents: {
        items: [],
        totalItems: 0
      },
      loading: true
    };
  },
  mounted() {
    this.fetchStudents();
  },
  methods: {
    fetchStudents() {
      this.loading = true;
      schoolService
        .studentsSignedIn(this.showAll ? {} : { showCount: this.showCount })
        .then((response) => {
          const responseData = response.data || {};
          const totalItems = responseData.itemsTotal || 0;
          const items = responseData.items || [];

          this.newStudents.totalItems = totalItems;

          this.newStudents.items = items.map((item) => {
            return { ...item, selectedLicense: item.selectedLicense };
          });

          if (!this.showAll && this.$refs.newStudentsRow) {
            const appContent = document.getElementById("app-content");
            const newStudentsRowRef = this.$refs.newStudentsRow;

            appContent.scrollTo({
              top: appContent.scrollTop > newStudentsRowRef.offsetTop ?
                newStudentsRowRef.offsetTop : appContent.scrollTop
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggleViewAll() {
      this.showAll = !this.showAll;
      this.fetchStudents();
    }
  }
};
</script>

<style scoped></style>
