<template>
  <v-dialog
    v-model="show"
    width="1010"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>{{ $t("label.confirmation") }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="pt-3">
        <v-row>
          <v-col v-if="Object.keys(onTimeProductsPrices).length > 0" cols="12" md="4">
            <h4>{{ $t('label.one_time_product')}}</h4>

            <table style="width: 100%;">
              <tr v-for="product in onTimeProductsPrices" :key="product.id">
                <td>{{ $t(`one_time_product.${product.key}`) }}</td>
                <td class="text-right" :class="!Number(product.price) ?'red--text':'green--text'">{{ product.price | currency }}</td>
              </tr>
            </table>
          </v-col>

          <v-col v-if="Object.keys(lessonsPrices).length > 0" cols="12" md="4">
            <h4>{{$t('label.lesson_types')}}</h4>

            <table style="width: 100%;">
              <tr v-for="product in lessonsPrices" :key="product.id">
                <td>{{ $tc(product.name, 1) }}</td>
                <td class="text-right" :class="!Number(product.price) ?'red--text':'green--text'">{{ product.price | currency }}</td>
              </tr>
            </table>
          </v-col>

          <v-col v-if="Object.keys(examsPrices).length > 0" cols="12" md="4">
            <h4>{{$t('exams_title')}}</h4>

            <table style="width: 100%;">
              <tr v-for="product in examsPrices" :key="product.id">
                <td>{{ $tc(product.name, 1) }}</td>
                <td class="text-right" :class="!Number(product.price) ?'red--text':'green--text'">{{ product.price | currency }}</td>
              </tr>
            </table>
          </v-col>
        </v-row>

        <template v-if="hasNonCompletedPrices">
          <v-divider class="my-2"/>

          <v-radio-group v-model="form.isCompleted" dense>
            <template v-slot:label>
              {{$t('messages.not_all_lesson_type_have_price')}}
            </template>

            <v-radio :value="false" :label="$t('form.save_but_complete_later')" :disabled="areAllPricesNotCompleted"/>
            <v-radio :value="true" :label="$t('form.save_and_mark_licenses_as_completed')"/>
          </v-radio-group>

          <v-alert
              elevation="1"
              :type="form.isCompleted ? 'warning' : 'info'"
              colored-border
              border="left"
          >
            <span v-if="form.isCompleted">
              {{$t("messages.check_before_confirmation")}}
            </span>
            <span v-else>
              {{$t('messages.update_price_list_later_info')}}
            </span>
          </v-alert>
        </template>

        <template v-else>
          <v-divider class="my-2"/>
          <v-alert
              elevation="1"
              type="warning"
              colored-border
              border="left"
          >
            {{$t("messages.check_before_confirmation")}}
          </v-alert>
        </template>
      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-btn text @click="close">
          <v-icon v-if="$vuetify.breakpoint.mobile">mdi-close</v-icon>
          <span v-else>{{ $t("btn.close") }}</span>
        </v-btn>

        <v-spacer/>

        <v-btn
          class="tile"
          color="primary"
          @click="confirm"
          :disabled="formLoading"
          :loading="formLoading"
        >
          <v-icon v-if="$vuetify.breakpoint.mobile">mdi-check</v-icon>
          <span v-else>{{ $t("btn.confirm") }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import keyBy from '@/helpers/keyBy'

import currency from "@/utils/mixins/currency";
import LessonTypeModel from "@/store/models/LessonTypeModel";
import productsService from "@/services/productsService";
import OneTimeProductModel from '@/store/models/OneTimeProductModel'

export default {
  name: "PricesConfirmationDialog",
  mixins: [currency],
  data: () => ({
    show: false,
    formLoading: false,
    form: {
      priceCategoryId: null,
      licenseId: null,
      isCompleted: false,
      products: []
    },
    radios: null
  }),
  computed: {
    oneTimeProducts() {
      return OneTimeProductModel.all()
    },
    lessonTypes() {
      return LessonTypeModel.query().where('payable', value => value !== 'no_cost').get();
    },
    lessons() {
      return this.lessonTypes.filter(item => item.group !== 'exam')
    },
    exams() {
      return this.lessonTypes.filter(item => item.group === 'exam')
    },
    areAllPricesNotCompleted() {
      return this.form.products.length === this.form.products.filter(price => parseFloat(price.price) === 0).length
    },
    hasNonCompletedPrices() {
      return this.form.products.filter(price => parseFloat(price.price) === 0).length > 0
    },
    onTimeProductsPrices() {
      const prices = keyBy(this.form.products.filter(item => item.type === 'one-time-product'),'id')

      return this.getProductsWithPrices(this.oneTimeProducts, prices)
    },
    lessonTypesPrices() {
      return this.form.products.filter(item => item.type === 'lesson-type')
    },
    lessonsPrices() {
      const prices = keyBy(this.lessonTypesPrices.filter(price => this.lessons.some(item => item.id === price.id)), 'id')

      return this.getProductsWithPrices(this.lessons, prices)
    },
    examsPrices() {
      const prices = keyBy(this.lessonTypesPrices.filter(price => this.exams.some(item => item.id === price.id)), 'id')

      return this.getProductsWithPrices(this.exams, prices)
    }
  },
  methods: {
    open(priceCategoryId = null, licenseId = null, products = []) {
      this.show = true;

      this.form.priceCategoryId = priceCategoryId;
      this.form.licenseId = licenseId;
      this.form.products = products;

      this.form.isCompleted = this.areAllPricesNotCompleted
    },
    close() {
      this.show = false;

      setTimeout(() => {
        this.form.priceCategoryId = null;
        this.form.licenseId = null;
        this.form.products = [];
        this.isCompleted = false
      }, 200)
    },
    confirm() {
      this.formLoading = true;
      productsService
        .savePrices(this.form)
        .then((response) => {
          const responseData = response.data || {}

          this.$emit('confirm', {
            licenseId: this.form.licenseId,
            isPriceListCompleted: responseData.isPriceListCompleted,
            prices: responseData.prices || []
          })
          this.close();
        })
        .catch((error) => {
          const responseData = error.response.data;

          if (responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error", });
          }
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    getProductsWithPrices(products, prices, ) {
      return products
          .filter(product => Object.keys(prices).includes(Number(product.id).toString()))
          .map(item => ({ ...item, price: prices[item.id].price }))
    }
  },
};
</script>

<style scoped></style>
