<template>
  <v-dialog
      v-model="show"
      width="700"
      @click:outside="close"
      @keydown.esc="close"
      scrollable
      :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card :loading="loading">
      <v-card-title primary-title>
        {{ $t('dialogs.balance.title') }}
        <v-spacer />
        <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <AppointmentsRequestsLoader :count="3" v-if="loading" />
        <template v-else>
          <table style="width: 100%" class="mb-2">
            <tr>
              <td><span>{{ $t('dialogs.balance.total_balance') }}</span></td>
              <th style="width: 100px;" class="text-right"><span>{{ data.totalBalance | currency }}</span></th>
            </tr>
            <tr>
              <td><span>{{ $t('dialogs.balance.blocked_amount') }}</span></td>
              <th class="text-right"><span>{{ data.requests.length * balanceLimit.requiredAmount | currency }}</span></th>
            </tr>
            <tr>
              <td><span>{{ $t('dialogs.balance.available_amount') }}</span></td>
              <th class="text-right">
                <span :class="data.availableBalance >= balanceLimit.requiredAmount ? 'green--text' : 'red--text'">
                  {{ data.availableBalance | currency }}
                </span>
              </th>
            </tr>
          </table>

          <v-alert v-if="balanceLimit.enabled" type="info" border="left" colored-border elevation="1">
            {{ $t('dialogs.balance.required_amount_is', { amount: balanceLimit.requiredAmount }) }}
          </v-alert>
          <v-alert
            type="error"
            border="left"
            colored-border
            elevation="1"
            v-if="balanceLimit.enabled && data.availableBalance < balanceLimit.requiredAmount"
          >
            <span v-if="! isStudent">
              {{ $t('dialogs.balance.student_cant_request', { student: data.studentName }) }}
            </span>
            <span v-else>{{ $t('dialogs.balance.you_cant_request') }}</span>
          </v-alert>
          <v-alert
            v-else
            type="success"
            border="left"
            colored-border
            elevation="1"
          >
            <span v-if="! isStudent">
              {{ $t('dialogs.balance.student_can_request_count', {
                  student: data.studentName,
                  count: requestsAvailableToMake
                })
              }}
            </span>
            <span v-else>{{ $t('dialogs.balance.you_can_request_count', { count: requestsAvailableToMake }) }}</span>
          </v-alert>

          <v-expansion-panels accordion v-if="requests.length" :disabled="isTeacher">
            <v-expansion-panel>
              <v-expansion-panel-header>{{ $t('dialogs.balance.active_requests', {count : data.requests.length}) }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <AppointmentCard
                    v-for="appointment in requests"
                    :appointment="appointment"
                    :key="appointment.id"
                    @click.native="$router.push({ name: 'calendar.appointment', params: { id: appointment.id } })"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-btn text @click="close">{{ $t('btn.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import studentsService from "@/services/studentsService";
import AppointmentsRequestsLoader from "@/components/skeleton-loaders/AppointmentsRequestsLoader";
import {mapGetters} from "vuex";
import AppointmentCard from "@/components/appointment/AppointmentCard";
import AppointmentRequestModel from "@/store/models/AppointmentRequestModel";
import user from "@/utils/mixins/user";

export default {
  name: "StudentBalanceBreakdownDialog",
  components: {AppointmentCard, AppointmentsRequestsLoader },
  mixins: [user],
  props: {
    student: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show: false,
      loading: false,
      data: {
        studentName: '',
        totalBalance: 0,
        availableBalance: 0,
        requests: []
      }
    }
  },
  methods: {
    open() {
      this.show = true;
      this.loading = true;
      studentsService
          .practiceRequests(this.student.id)
          .then(resp => {
            this.data = resp.data
            AppointmentRequestModel.create({ data: resp.data.requests })
          })
          .finally(() => {
            this.loading = false
          })
    },
    close() {
      this.show = false
    }
  },
  computed: {
    requests () {
      return AppointmentRequestModel.query().with('teacher').get()
    },
    requestsAvailableToMake() {
      return Math.floor(this.data.availableBalance / this.balanceLimit.requiredAmount)
    },
    ...mapGetters("school", ['balanceLimit']),
  }
}
</script>

<style scoped lang="scss">

</style>