export default {
    data() {
        return {
            expanded: false,
            panel: 'default'
        }
    },
    mounted () {
        this.panel = this.panelName ?? this.panel
        const storedValue = localStorage.getItem(this.panel);
        this.expanded = storedValue !== null ? storedValue === 'true' : true;
    },
    methods: {
        toggleExpandedState() {
            this.expanded = ! this.expanded
            localStorage.setItem(this.panel, this.expanded)
        }
    }
}