<template>
  <div class="list__header">
    <slot />
  </div>
</template>

<script>
import responsive from "@/utils/mixins/responsive";
import { mapState } from "vuex";

export default {
  name: "ListHeader",
  props: {
    pageView: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [responsive],
  computed: {
    ...mapState("main", ["itemsView"]),
  },
};
</script>

<style scoped></style>
