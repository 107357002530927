<template>
  <v-card>
    <StudentTabToolBar activeTab="agenda" />
    <v-card-text>
      <AgendaTab :studentId="$route.params.id" />
    </v-card-text>
  </v-card>
</template>

<script>
import AgendaTab from "@/components/student/AgendaTab";
import StudentModel from "@/store/models/StudentModel";
import StudentTabToolBar from '@/components/student/StudentTabToolBar.vue'

export default {
  name: "StudentAgenda",
  components: { StudentTabToolBar, AgendaTab },
  beforeRouteEnter(from, to, next) {
    const student = StudentModel.find(from.params.id);

    if (student && student.isDeleted) {
      next({ name: "students.student", params: { id: from.params.id } });
      return;
    }

    next();
  },
};
</script>

<style scoped></style>
