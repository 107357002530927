<template>
  <div class="d-flex flex-gap align-center">
    <LicenseProgressItem
        v-if="showTheoryProgress"
        :index="$t('label.basic_theories_abbr')"
        :value-is="licenseProgress.theoriesNormalsIs"
        :value-should="licenseProgress.theoriesNormalsShould"
        :outlined="outlined"
    />
    <LicenseProgressItem
        v-if="showTheoryProgress"
        :index="$t('label.special_theories_abbr')"
        :value-is="licenseProgress.theoriesSpecialsIs"
        :value-should="licenseProgress.theoriesSpecialsShould"
        :outlined="outlined"
    />
    <LicenseProgressItem
      v-if="licenseProgress.practiceInstructionsShould"
        :index="$t('label.instructions_abbr')"
        :value-is="licenseProgress.practiceInstructionsIs"
        :value-should="licenseProgress.practiceInstructionsShould"
        :single-value="licenseProgress.practiceInstructionsShould === 0"
        :outlined="outlined"
    />
    <LicenseProgressItem
        :index="$t('label.normals_abbr')"
        :value-is="licenseProgress.practiceNormalsIs"
        :value-should="licenseProgress.practiceNormalsShould"
        :single-value="licenseProgress.practiceNormalsShould === 0"
        :outlined="outlined"
    />
    <LicenseProgressItem
        :index="$t('label.overlands_abbr')"
        :value-is="licenseProgress.practiceOverlandsIs"
        :value-should="licenseProgress.practiceOverlandsShould"
        :outlined="outlined"
    />
    <LicenseProgressItem
        :index="$t('label.highways_abbr')"
        :value-is="licenseProgress.practiceHighwaysIs"
        :value-should="licenseProgress.practiceHighwaysShould"
        :outlined="outlined"
    />
    <LicenseProgressItem
        :index="$t('label.nights_abbr')"
        :value-is="licenseProgress.practiceNightsIs"
        :value-should="licenseProgress.practiceNightsShould"
        :outlined="outlined"
    />
    <LicenseProgressItem
        v-if="licenseProgress.practiceRequiredMTLsShould"
        :index="$t('label.mtls_abbr')"
        :value-is="licenseProgress.practiceRequiredMTLsIs"
        :value-should="licenseProgress.practiceRequiredMTLsShould"
        :outlined="outlined"
    />
  </div>
</template>

<script>
import LicenseProgressItem from '@/components/calendar/appointmentModal/fields/LicenseProgressItem'

export default {
  name: "LicenseProgress",
  components: { LicenseProgressItem },
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    licenseProgress: {
      type: Object,
    },
    showTheoryProgress: {
      type: Boolean,
      default: false
    }
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
.flex-gap{
  gap: 5px;
}
</style>
