export default class Pagination {
  constructor (data = {}) {
    this.perPage = data.perPage || 15
    this.tableRowHeight = data.tableRowHeight || 32

    this.page = 1
    this.currentPage = 1
    this.lastPage = 1
    this.total = 0
  }

  set (data) {
    this.currentPage = data.currentPage
    this.lastPage = data.lastPage
    this.total = data.total
  }

  setPage (nr) {
    this.page = parseInt(nr)
  }

  tableHeight() {
    return (this.perPage - 5) * this.tableRowHeight
  }

  canGoNext() {
    return this.currentPage < this.lastPage
  }
}
