<template>
<v-dialog
  v-model="show"
  width="800"
  @click:outside="close"
  @keydown.esc="close"
  :fullscreen="$vuetify.breakpoint.mdAndDown"
  scrollable
>
  <v-card loading tile>
    <v-toolbar class="flex-grow-0" dark color="primary" dense>
      <v-toolbar-title>{{ $t("label.vehicle_teachers") }}</v-toolbar-title>
      <v-spacer />
      <v-btn dark icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-row class="mt-4" dense>
            <v-col v-for="teacher in teachers" :key="teacher.id" cols="12" md="4">
                <v-sheet outlined rounded>
                    <v-list-item @click="selectTeacher(teacher.id)">
                        <v-list-item-content>
                            <v-list-item-title>{{ teacher.fullName }}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-checkbox :input-value="selectedTeachers.some(item => item === teacher.id)" color="primary"/>
                        </v-list-item-action>
                    </v-list-item>
                </v-sheet>
            </v-col>
        </v-row>
    </v-card-text>

    <v-divider />

    <v-card-actions>
      <v-btn text @click="close">{{$t('btn.close')}}</v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        download
        :loading="saving"
        :disabled="saving"
        @click="save"
      >
          <v-icon left>mdi-content-save-edit</v-icon>
          {{ $t("btn.save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import TeacherModel from '@/store/models/TeacherModel'
import VehicleModel from '@/store/models/VehicleModel'
import vehiclesService from '@/services/vehiclesService'

export default {
  name: 'AssignTeacherDialog',
  data() {
    return {
      saving: false,
      show: false,
      vehicleId: null,
      selectedTeachers: []
    }
  },
  computed: {
    vehicle() {
      return VehicleModel.find(this.vehicleId)
    },
    vehicleTeachers () {
      const teachersIds = this.vehicle?.teachersIds || []
      return this.teachers.filter(item => teachersIds.includes(item.id))
    },
    teachers() {
      return TeacherModel.all()
    }
  },
  methods: {
    open(vehicleId) {
      this.show = true
      this.vehicleId = vehicleId
      this.selectedTeachers = this.vehicleTeachers.map(item => item.id)
    },
    close() {
      this.show = false

      setTimeout(() => {
        this.vehicleId = null
        this.selectedTeachers = []
      },200)
    },
    save() {
      this.saving = true
      vehiclesService
        .teachers(this.vehicleId, {teachers: this.selectedTeachers})
        .then(() => {
          VehicleModel.update({ where: this.vehicleId, data: {teachersIds: this.selectedTeachers} })
          this.close()
        })
        .finally(() => {
          this.saving = false
        })
    },
    selectTeacher(teacherId) {
      this.selectedTeachers = this.selectedTeachers.includes(teacherId) ?
        this.selectedTeachers.filter(item => item !== teacherId) :
        [...this.selectedTeachers, teacherId]
    }
  }
}
</script>

<style scoped>

</style>
