<template>
  <ValidationProvider name="theoryTopicId" rules="required" v-slot="{ errors }">
    <v-autocomplete
      outlined
      required
      dense
      hide-details
      :error="errors.length > 0"
      :label="$t('label.topic')"
      :disabled="disabled"
      v-model="theoryTopicId"
      :items="topicsList"
      :item-text="topicLabel"
      item-value="id">
      <template #item="data">
        <span style="max-width: 650px">{{ topicLabel(data.item) }}</span>
      </template>
    </v-autocomplete>
  </ValidationProvider>
</template>

<script>
import TheoryTopicModel from "@/store/models/TheoryTopicModel";

export default {
  name: "TopicsField",
  props: {
    disabled: {
      type: Boolean,
    },
    topicsList: {
      type: Array,
      required: true,
    },
    topicProp: {
      type: Number,
    },
  },
  computed: {
    theoryTopicId: {
      set(value) {
        this.$emit("emitTopiSelect", value);
        const topic = TheoryTopicModel.find(value);
        this.$emit("emitLicenseSelect", topic ? topic.licensesIds : []);

        // this.$emit("emitTopiSelect", {
        //   theoryTopicId: value,
        //   licenseId: topic ? topic.licensesIds : [],
        // });
      },
      get() {
        return this.topicProp;
      },
    },
  },
  methods: {
    topicLabel(topic) {
      return `${topic.orderIndex}. ${topic.title}`;
    },
  },
};
</script>
