<template>
  <div>
    <v-row v-if="teacher && theoryGroup === 'lesson'">
      <v-col cols="12">
        <v-textarea
          v-model="teacher.fullName"
          disabled
          auto-grow
          outlined
          dense
          hide-details
          label="Teacher"
          rows="1" />
      </v-col>
    </v-row>

    <TimeFields disabled :model="model" :eventDuration="eventDuration" />

    <template v-if="theoryGroup === 'lesson'">
      <v-row>
        <v-col cols="4">
          <v-text-field
            disabled
            :label="$t('form.meeting_place')"
            item-value="id"
            :value="licensesNames"
            outlined
            hide-details
            dense />
        </v-col>
        <v-col cols="8">
          <v-text-field
            disabled
            :label="$t('form.office')"
            item-value="id"
            :value="office.name"
            outlined
            hide-details
            dense />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            v-model="topicLabel"
            disabled
            auto-grow
            outlined
            hide-details
            dense
            :label="$t('form.topic')"
            rows="1" />
        </v-col>
      </v-row>
    </template>

    <template v-if="theoryGroup === 'exam'">
      <v-row>
        <v-col>
          <v-text-field
            append-icon=""
            disabled
            :label="$t('form.meeting_place')"
            item-value="id"
            :value="model.meetingPlace"
            outlined
            hide-details
            dense />
        </v-col>
      </v-row>
      <TextField disabled :model="model" />
    </template>
  </div>
</template>

<script>
import TimeFields from "@/components/calendar/eventModal/fields/TimeFields";
import TextField from "@/components/calendar/eventModal/fields/TextField";
import TeacherModel from "@/store/models/TeacherModel";
import LicenseModel from "@/store/models/LicenseModel";
import OfficeModel from "@/store/models/OfficeModel";
import user from "@/utils/mixins/user";
import EventModel from "@/store/models/EventModel";
import TheoryTopicModel from "@/store/models/TheoryTopicModel";
import { mapGetters } from "vuex";

export default {
  name: "StudentTheoryForm",
  mixins: [user],
  components: { TimeFields, TextField },
  props: {
    eventDuration: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    teacher() {
      return TeacherModel.find(this.model.teacherId) || {};
    },
    licenses() {
      return LicenseModel.query().whereIdIn(this.model.licensesIds).get() || [];
    },
    licensesNames() {
      return this.licenses.map((license) => license.name).join(", ");
    },
    theoryGroup() {
      const event = EventModel.find(this.model.id) || {};
      return event.group;
    },
    office() {
      return OfficeModel.find(this.model.officeId) || {};
    },
    topicLabel() {
      const topic = TheoryTopicModel.find(this.model.theoryTopicId) || {};
      return `${topic.orderIndex}. ${topic.title}`;
    },
  },
};
</script>
