<template>
  <v-card :disabled="disabled">
    <v-img height="100px" :src="image" eager />
    <v-card-title>
      {{ $t("label.invoices") }}
      <template v-if="params.isInvoicesAutomationEnabled">
        <v-spacer/>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :color="params.excludedFromAutomation ? 'red' : 'green'"
              v-bind="attrs"
              v-on="on"
            >
              {{params.excludedFromAutomation ? 'mdi-autorenew-off' : 'mdi-autorenew'}}
            </v-icon>
          </template>
          <span v-if="params.excludedFromAutomation">{{ $t('label.automatic_invoicing_is_disabled') }}</span>
          <span v-else>{{ $t('label.automatic_invoicing_is_enabled') }}</span>
        </v-tooltip>
      </template>
    </v-card-title>

    <v-card-subtitle class="student-card">
      {{ $t('label.student_card_invoices_description') }}
    </v-card-subtitle>

    <v-card-text v-if="!disabled">
      <v-list>
        <v-list-item class="pa-0 mb-2">
          <v-list-item-content class="pa-0">
            <v-list-item-title>{{ $t('label.unbilled_services') }}</v-list-item-title>
            <v-list-item-subtitle>{{ params.servicesCount }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>

    <v-card-actions>
      <v-btn
        class="ml-auto"
        small
        text
        :to="{ name: `students.student.invoices` }"
      >
        {{ $t("btn.open") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "StudentInvoicesCard",
  props: {
    studentId: {
      type: [Number, String],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
    },
  },
  data: () => ({
    loading: false,
    image: require("@/assets/img/invoices.jpg")
  })
};
</script>

<style scoped></style>
