<script>
export default {
  name: "InsightsItemEvaluations",
  props: {
    evaluations: {
      type: Array
    }
  },
  methods: {
    itemColor(item) {
      const { score, requireStudentScore } = item;
      if (!score && requireStudentScore) return 'disabled'

      if (score === 5 || item.isFinal) return 'success'

      if (score >= 3 && score <= 4) return 'warning';

      return 'error'
    }
  },
}
</script>

<template>
<div class="d-flex flex-row align-center">
  <v-tooltip v-for="evaluation in evaluations" :key="evaluation.id" top max-width="300" z-index="99">
    <template v-slot:activator="{ on, attrs }">
      <v-icon  v-bind="attrs" v-on="on" x-small :color='itemColor(evaluation)' class="d-flex">
        {{ evaluation.isFinal && !evaluation.requireStudentScore ? 'mdi-check-decagram' : 'mdi-decagram-outline' }}
      </v-icon>
    </template>
    <div v-if="evaluation.score">
      {{ $t('user_type.teacher') }}: {{ evaluation.score }}
      <span v-if="evaluation.comment"> - {{ evaluation.comment }}</span>
    </div>
    <div v-if="evaluation.studentScore">{{ $t('user_type.student') }}: {{ evaluation.studentScore }}</div>
    <span v-if="evaluation.requireStudentScore">
      {{ $t('label.add_self_evaluation') }}
    </span>
  </v-tooltip>
</div>
</template>
