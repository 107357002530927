<template>
    <v-dialog
      v-model="show"
      width="500"
      @click:outside="close"
      @keydown.esc="close"
    >
        <v-card :loading="loading">
          <v-toolbar class="flex-grow-0 mb-4" color="primary" dark dense>
            <v-toolbar-title>{{ $t("label.register_mileage") }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon dark @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
            <v-card-text v-if="loading">
                <div class="d-flex justify-center pt-5">
                    <v-progress-circular color="primary" indeterminate />
                </div>
            </v-card-text>
          <v-card-text v-else>
            <VehicleAlert v-if="showDetailsAlert && vehicle" :vehicle="vehicle" />
            <ValidationObserver ref="form">
              <label class="mt-2" for="">{{ $t("label.enter_previous_mileage") }}</label>
              <mileage-input v-model="newMileage" :value="newMileage" class="my-2" />
              <v-checkbox v-model="serviceReset" :label="$t('label.service_reset')" />
              <vehicle-new-mileage-validation
                v-if="!serviceReset"
                :last-mileage="lastMileage"
                :new-mileage="newMileage"
              />
            </ValidationObserver>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn text @click="close">{{$t('btn.close')}}</v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              :loading="saving"
              :disabled="savingDisabled"
              @click="save"
            >
            <v-icon left>mdi-content-save</v-icon>
              {{$t('btn.save')}}
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import VehicleModel from "@/store/models/VehicleModel";
import MileageInput from "@/components/vehicle/MileageInput.vue";
import vehiclesService from "@/services/vehiclesService";
import VehicleAlert from "@/components/vehicle/VehicleAlert.vue";
import {EventBus} from "@/EventBus";
import markdownParser from "@/utils/mixins/markdownParser";
import VehicleNewMileageValidation from "@/components/vehicle/VehicleNewMileageValidation.vue";

export default {
  name: "VehicleMileageDialog",
  props: {
    showDetailsAlert: {
      type: Boolean,
      default: false
    }
  },
  components: { VehicleNewMileageValidation, VehicleAlert, MileageInput },
  mixins: [markdownParser],
  data() {
    return {
      saving: false,
      show: false,
      loading: false,
      vehicleId: null,
      newMileage: 0,
      lastMileage: 0,
      serviceReset: false
    }
  },
  computed: {
    vehicle() {
      return VehicleModel.find(this.vehicleId)
    },
    savingDisabled() {
      return this.saving || this.loading || (!this.serviceReset && this.newMileage <= this.lastMileage);
    },
  },
  methods: {
    open(vehicleId, serviceReset = false) {
      this.show = true
      this.loading = true
      this.vehicleId = vehicleId
      this.serviceReset = serviceReset
      vehiclesService.getLastMileage(this.vehicleId).then((response) => {
        this.newMileage = this.lastMileage = response.data;
      }).finally(() => {
        this.loading = false;
      })
    },
    close() {
      this.show = this.loading = false
      this.$nextTick(() => {
        this.vehicleId = null
      })
    },
    save() {
      this.saving = true;
      vehiclesService.storeMileage(this.vehicleId, {
        mileage: this.newMileage,
        serviceReset: this.serviceReset
      })
        .then((resp) => {
          const mileage = resp.data.mileage;
          this.$emit("created", mileage);
          EventBus.$emit("mileage-created", mileage);
          this.close();
        })
        .catch((error) => {
          this.$refs.form.reset();

          const responseData = error.response.data || {};
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({
              message: responseData.message,
              color: "error"
            });
          }

          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.saving = false;
        });
    },
  }
}
</script>

<style scoped>

</style>
