<template>
  <div>
    <template v-if="patchNotes.length">
      <PatchNoteItem v-for="item in patchNotes" :key="item.id" :item="item" />
    </template>
    <p v-else><em>{{ $t('label.no_visible_updates_for_user_type') }}</em></p>
  </div>
</template>

<script>
import PatchNoteItem from "@/components/PatchNoteItem";
export default {
  name: "PatchNotesList",
  components: {PatchNoteItem},
  props: {
    patchNotes: {
      type: Array,
      required: true
    }
  }
}
</script>
<style scoped lang="scss">
.patch-note-item {
  border-bottom: dashed 1px #eee;
  margin-bottom: 10px;
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
}
</style>