<template>
  <div class="reservation-mode d-flex justify-center align-center">
    <v-switch dense v-model="status" :label="$t('label.reservation')"></v-switch>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { TOGGLE_RESERVATION_MODE } from '@/store/mutation-types/vehiclesReservation'
export default {
  name: 'ReservationModeSwitch',
  computed: {
    ...mapState('vehiclesReservation', ['reservationMode']),
    status: {
      set(val) {
        this.$store.commit(`vehiclesReservation/${TOGGLE_RESERVATION_MODE}`, val)
      },
      get() {
        return this.reservationMode
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-mode{
  height: 50px;
  border-top: 1px solid #ccc;
}
</style>
