<template>
  <v-menu
    class="mb-2"
    :close-on-content-click="false"
    :nudge-width="300"
    nudge-top="100"
    :position-y="100"
    offset-y
    z-index="10"
    offset-overflow>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" small>
        <v-icon size="28" color="primary">mdi-emoticon-happy-outline</v-icon>
      </v-btn>
    </template>

    <v-card
      class="mb-4"
      max-width="350"
      height="500"
      outlined
      style="overflow-y: auto">
      <div class="pa-3 text-justify">
        <span
          class="text-h6 emoji"
          v-for="emoji in emojiByCategory"
          :key="emoji.key"
          @click="$emit('select', emoji)"
          >{{ emoji.emoji }}</span
        >
      </div>

      <div
        class="d-flex justify-space-around pa-2"
        style="
          position: fixed;
          bottom: 0;
          width: 100%;
          background: #fff;
          border-top: 1px solid #eee;
        ">
        <span
          class="text-h6 emoji"
          v-for="emojiCategory in emojiCategories"
          :key="emojiCategory.icon"
          @click="changeEmojiGroup(emojiCategory.name)"
          >{{ emojiCategory.icon }}</span
        >
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { emojis } from "vue-chat-emoji";

export default {
  name: "EmojiMenu",
  data: () => ({
    emojis: emojis.all(),
    emojiCategory: "Smileys & Emotion",
  }),
  computed: {
    emojiByCategory() {
      return [
        ...emojis.getEmojiByCategoryAndSkinTone(
          "no skin tone",
          this.emojiCategory
        ),
      ];
    },
    emojiCategories() {
      return [
        { name: "Smileys & Emotion", icon: "😀" },
        { name: "People & Body", icon: "🧑" },
        { name: "Animals & Nature", icon: "🐈" },
        { name: "Food & Drink", icon: "🍕" },
        { name: "Travel & Places", icon: "🚖" },
        { name: "Activities", icon: "🏉" },
        { name: "Objects", icon: "💡" },
        { name: "Symbols", icon: "♻️" },
        { name: "Flags", icon: "🏴" },
      ];
    },
  },
  methods: {
    changeEmojiGroup(name) {
      this.emojiCategory = name;
    },
  },
};
</script>

<style scoped></style>
