<template>
  <v-dialog
    v-model="show"
    width="1000"
    @click:outside="close"
    @keydown.esc="close"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    content-class="theory-dialog">
    <v-card loading>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>Create topics</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="pt-5">
        <ValidationObserver ref="form">
          <v-row dense>
            <v-col class="d-flex justify-space-between" cols="12">
              <span class="title text-capitalize">{{ form.type }}s</span>

              <v-btn
                small
                class="text-subtitle-2"
                color="primary"
                @click="addTopic"
                >Add topic</v-btn
              >
            </v-col>
          </v-row>

          <v-alert
            v-if="uncompletedLicense.length > 0"
            class="mt-2 mb-3"
            dense
            border="left"
            type="warning"
            outlined>
            Uncompleted topics in licenses
            {{ this.uncompletedLicense.join(", ") }} will not be saved.
          </v-alert>

          <v-tabs
            v-if="form.type === 'special'"
            class="mb-5"
            dense
            show-arrows
            v-model="selectedLicense">
            <v-tab
              v-for="license in licenses"
              :key="license.id"
              :href="`#${license.id}`">
              <v-badge
                :color="
                  uncompletedTopicsByLicenseId[license.id] !== 0
                    ? 'warning'
                    : 'success'
                "
                :icon="
                  uncompletedTopicsByLicenseId[license.id] !== 0
                    ? 'mdi-exclamation'
                    : 'mdi-check'
                "
                :content="uncompletedTopicsByLicenseId[license.id]"
                :value="true">
                {{ license.name }}
              </v-badge>
            </v-tab>
          </v-tabs>

          <v-row class="mb-2" dense v-for="topic in formTopics" :key="topic.id">
            <v-col cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                :name="`topics.${topic.id}.title`">
                <div class="d-flex">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    v-model="topic.title"
                    label="Title"
                    placeholder="Title"
                    type="text"
                    required
                    :error-messages="errors" />

                  <div class="ml-4 d-flex flex-column" v-if="!isLastTopic">
                    <v-icon
                      class="my-auto"
                      size="25"
                      color="red"
                      @click="deleteTopic(topic.id)"
                      :disabled="loadingItem"
                      >mdi-delete</v-icon
                    >
                  </div>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>

      <v-divider class="mt-auto" />

      <v-card-actions class="d-flex justify-end mx-4">
        <v-btn class="mr-2" width="80" outlined text @click="close">{{
          $t("btn.cancel")
        }}</v-btn>
        <v-btn
          class="tile"
          width="80"
          color="primary"
          @click="save"
          :loading="loadingForm"
          :disabled="loadingForm || loadingItem"
          >{{ $t("btn.save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import licenses from "@/utils/mixins/licenses";
import theoryTopicsService from "@/services/theoryTopicsService";
import TheoryTopicModel from "@/store/models/TheoryTopicModel";
import LicenseModel from "@/store/models/LicenseModel";
import { v4 as uuid4 } from "uuid";

export default {
  name: "TheoryTopicFormDialog",
  mixins: [licenses],
  data: () => ({
    show: false,
    isEdit: false,
    loadingForm: false,
    loadingItem: false,
    types: ["basic", "special"],
    selectedLicense: "",
    itemId: "",
    form: {
      type: "basic",
      topics: [],
    },
    initialForm: {},
  }),
  created() {
    this.initialForm = JSON.parse(JSON.stringify(this.form));
  },
  watch: {
    "form.type": function () {
      this.form.topics = [];
    },
    selectedLicense: function (value) {
      this.deleteFirstEmptyTopic();

      if (this.formTopics.length === 0 && value) this.addTopic();
    },
  },
  computed: {
    licenses() {
      return LicenseModel.all() || {};
    },
    formTopics() {
      return this.form.topics.filter((topic) => {
        return this.selectedLicense
          ? topic.licenseId === this.selectedLicense
          : true;
      });
    },
    isLastTopic() {
      return this.formTopics.length === 1;
    },
    uncompletedLicense() {
      return Object.keys(this.uncompletedTopicsByLicenseId)
        .filter((licenseId) => this.uncompletedTopicsByLicenseId[licenseId] > 0)
        .map((licenseId) => {
          return this.licensesById[licenseId].name;
        });
    },
    uncompletedTopicsByLicenseId() {
      const byLicenseId = {};

      this.licenses.forEach((license) => {
        if (!(license.id in byLicenseId)) {
          byLicenseId[license.id] = this.form.topics.filter((topic) => {
            const topicLicense = parseInt(topic.licenseId);

            return topicLicense === license.id && topic.title === "";
          }).length;
        }
      });

      return byLicenseId;
    },
  },
  mounted() {
    if (licenses.length > 0) this.selectedLicense = licenses[0].id;
  },
  methods: {
    open(type) {
      this.show = true;
      this.form.type = this.types[0];

      if (this.types.includes(type)) this.form.type = type;

      this.addTopic();

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    close() {
      this.show = false;

      setTimeout(() => {
        this.form = JSON.parse(JSON.stringify(this.initialForm));
      }, 200);
    },
    addTopic() {
      this.$nextTick(() => {
        const topicData = { id: uuid4(), title: "" };

        if (this.form.type === "special") {
          if (!this.selectedLicense) return false;

          topicData.licenseId = this.selectedLicense;
        }

        this.$set(this.form, "topics", [...this.form.topics, { ...topicData }]);
      });
    },
    deleteTopic(id) {
      const itemIndex = this.form.topics.findIndex((topic) => topic.id === id);

      this.form.topics.splice(itemIndex, 1);
    },
    deleteFirstEmptyTopic() {
      this.licenses.forEach((license) => {
        const formTopicsByLicense = this.form.topics.filter((topic) => {
          const topicLicense = parseInt(topic.licenseId);

          return topicLicense === license.id;
        });

        if (formTopicsByLicense.length === 1) {
          const firstTopic = formTopicsByLicense[0];

          if (firstTopic.title === "") this.deleteTopic(firstTopic.id);
        }
      });
    },
    save() {
      this.loadingForm = true;
      theoryTopicsService
        .create(this.form)
        .then((response) => {
          TheoryTopicModel.insert({ data: response.data }).then(
            (modelResponse) => {
              this.$emit("create", modelResponse.theoryTopics[0]);
            }
          );

          this.close();
        })
        .catch((error) => {
          const responseData = error.response.data || {};
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message)
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });

          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
  },
};
</script>

<style lang="scss">
.theory-dialog {
  position: absolute !important;
  top: 120px;
}
</style>
