<template>
  <v-card :loading="loading" :disabled="disabled">
    <v-img height="100px" :src="image" eager />
    <v-card-title>{{ $t("label.agenda") }}</v-card-title>

    <v-card-subtitle class="student-card">
      {{ $t('label.student_card_agenda_description') }}
    </v-card-subtitle>

    <v-card-text v-if="!disabled">
      <v-skeleton-loader v-if="loading" type="list-item-two-line" />
      <v-list v-else>
        <v-list-item class="pa-0 mb-2">
          <v-list-item-content class="pa-0">
            <v-list-item-title>{{ $t('label.appointments_in_the_last_month') }}</v-list-item-title>
            <v-list-item-subtitle>{{ countOfEvensInLastMonth }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>

    <v-card-actions>
      <v-btn
        class="ml-auto"
        small
        text
        :to="{ name: `students.student.agenda` }"
      >
        {{ $t("btn.open") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import cardsService from "@/services/cardsService";

export default {
  name: "StudentAgendaCard",
  props: {
    studentId: {
      type: [Number, String],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    image: require("@/assets/img/agenda.jpg"),
    countOfEvensInLastMonth: 0,
  }),
  watch: {
    disabled(value) {
      if (!value) this.init();
    },
  },
  mounted() {
    if (!this.disabled) this.init();
  },
  methods: {
    init() {
      this.loading = true;
      cardsService
        .studentAgenda(this.studentId)
        .then((response) => {
          const responseData = response.data || [];
          this.countOfEvensInLastMonth =
            responseData.countOfEventsInLastMonth || 0;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
