<template>
<div>
  <v-toolbar elevation="3">
    <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'personal-settings' }" icon exact>
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <v-card-title>
      {{ $t("route.personalSettings.children.teacher") }}
    </v-card-title>

    <v-spacer />

    <v-btn text color="primary" @click="save" :disabled="formLoading" :loading="formLoading">{{ $t("btn.save") }}</v-btn>
  </v-toolbar>

  <v-card-text>
    <ValidationObserver ref="form">
      <SettingsResponsiveRow
        :title="$t('settings.teacher.lesson_duration')"
        :description="$t('settings.teacher.lesson_duration_description')"
      >
        <ValidationProvider v-slot="{ errors }" name="lessonDuration">
          <v-text-field
              class="mt-0 pt-0"
              type="number"
              :label="$t('form.lesson_duration')"
              v-model="form.lessonDuration"
              outlined
              dense
              hide-details
              readonly
          />
          <v-slider
              class="align-center mr-0"
              v-model="form.lessonDuration"
              :min="30"
              :max="90"
              :step="5"
              hide-details
          />

          <span class="red--text" v-if="errors.length">{{errors[0] }}</span>
        </ValidationProvider>
      </SettingsResponsiveRow>

      <SettingsResponsiveRow
          :title="$t('settings.teacher.no_confirmation')"
          :description="$t('settings.teacher.no_confirmation_description')"
      >
        <v-switch
            class="mt-0"
            dense
            v-model="form.studentCanCreatePractice"
            :label="$t('label.enabled')"
            hide-details
            inset
        />
      </SettingsResponsiveRow>

      <SettingsResponsiveRow
          :title="$t('settings.teacher.only_placeholders')"
          :description="$t('settings.teacher.only_placeholders_description')"
      >
        <v-switch
            class="mt-0"
            dense
            v-model="form.onlyPlaceholdersBooking"
            :label="$t('label.enabled')"
            hide-details
            inset
        />
      </SettingsResponsiveRow>

      <SettingsResponsiveRow
          :title="$t('settings.teacher.show_busy_time')"
          :description="$t('settings.teacher.show_busy_time_description')"
      >
        <template #description>
          <v-alert v-if="! form.onlyPlaceholdersBooking" border="left" colored-border type="info" dense elevation="1">
            <span class="text--secondary">
              {{ $t('settings.teacher.show_busy_time_warn') }}
            </span>
          </v-alert>
        </template>

        <v-switch
            class="mt-0"
            dense
            v-model="form.showStudentsAllTeachersEvents"
            :disabled="!form.onlyPlaceholdersBooking"
            :label="$t('label.enabled')"
            hide-details
            inset
        />
      </SettingsResponsiveRow>
      <SettingsResponsiveRow
        :title="$t('settings.teacher.automatic_placeholders')"
        :description="$t('settings.teacher.automatic_placeholders_description')"
      >
        <v-switch
            class="mt-0"
            dense
            v-model="form.automaticPlaceholder"
            :label="$t('label.enabled')"
            hide-details
            inset
        />
      </SettingsResponsiveRow>

      <SettingsResponsiveRow
        :title="$t('settings.teacher.working_hours')"
        :description="$t('settings.teacher.working_hours_description')"
        :divider-after="false"
      >
        <v-select
            :label="$t('form.available_work_days')"
            :placeholder="$t('form.available_work_days')"
            :items="availableWorkHoursTypesOptions"
            outlined
            dense
            hide-details
            v-model="form.availableWorkHoursType"
        />

        <v-row dense v-if="form.availableWorkHoursType === 'WEEKLY'" class="mt-3">
          <v-col cols="12" sm="6">
            <TimePicker
                name="weeklyAvailableWorkHours.startTime"
                class="mr-1"
                :label="$t('start_time')"
                :disabled="false"
                :value="form.weeklyAvailableWorkHours.startTime"
                @input="(val) => (form.weeklyAvailableWorkHours.startTime = val)"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <TimePicker
                name="weeklyAvailableWorkHours.endTime"
                class="mr-1"
                :label="$t('end_time')"
                :disabled="false"
                :value="form.weeklyAvailableWorkHours.endTime"
                @input="(val) => (form.weeklyAvailableWorkHours.endTime = val)"
            />
          </v-col>
        </v-row>

        <div v-if="form.availableWorkHoursType === 'CUSTOM'">
          <v-row class="my-3">
            <v-col cols="12">
              <v-sheet outlined rounded>
                <div class="px-3">
                  <v-chip-group v-model="form.availableWorkDays" column multiple>
                    <v-chip
                        class="text-uppercase"
                        small
                        filter
                        outlined
                        v-for="day in shortWeekDays"
                        :key="day"
                        :value="day"
                    >
                      {{ $t(`days.${day}`) }}
                    </v-chip>
                  </v-chip-group>
                </div>
              </v-sheet>
            </v-col>
          </v-row>

          <div class="d-flex align-content-stretch mb-2">
            <TimePicker
                style="margin-left: 46px"
                class="mr-1"
                :label="$t('start_time')"
                :disabled="false"
                :value="customStartTime"
                @input="onChangeCustomStartTime"
            />

            <TimePicker
                class="mr-1"
                :label="$t('end_time')"
                :disabled="false"
                :value="customEndTime"
                @input="onChangeCustomEndTime"
            />
          </div>

          <v-divider class="my-3" />

          <div class="d-flex align-content-stretch mb-2" v-for="(item, index) in form.customAvailableWorkHours" :key="index">
            <v-chip
                class="work-day__title"
                outlined
                label
                :input-value="true"
                color="primary"
                :disabled="form.availableWorkDays.indexOf(index) === -1"
            >
              <span class="text-uppercase">{{ $t(`days.${index}`) }}</span>
            </v-chip>

            <TimePicker
                :name="`customAvailableWorkHours.${index}.startTime`"
                class="mr-1"
                :label="$t('start_time')"
                :value="item.startTime"
                @input="(val) => (form.customAvailableWorkHours[index].startTime = val)"
                :key="item.startTime"
                :disabled="form.availableWorkDays.indexOf(index) === -1"
            />

            <TimePicker
                :name="`customAvailableWorkHours.${index}.endTime`"
                class="mr-1"
                :label="$t('end_time')"
                :value="item.endTime"
                @input="(val) => (form.customAvailableWorkHours[index].endTime = val)"
                :key="item.endTime"
                :disabled="form.availableWorkDays.indexOf(index) === -1"
            />
          </div>
        </div>
      </SettingsResponsiveRow>
    </ValidationObserver>
  </v-card-text>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { addDays, format, startOfWeek } from "date-fns";
import TimePicker from "@/components/TimePicker";
import teacherService from "@/services/teacherService";
import i18n from "@/plugins/i18n";
import SettingsResponsiveRow from "@/components/settings/SettingsResponsiveRow";
import responsive from '@/utils/mixins/responsive'

export default {
  name: "Teacher",
  mixins: [responsive],
  components: {SettingsResponsiveRow, TimePicker },
  data: () => ({
    formLoading: false,
    showOldPassword: false,
    showPassword: false,
    showPasswordConfirmation: false,
    availableWorkHoursTypesOptions: [
      { text: i18n.t("work_days_types.weekly"), value: "WEEKLY" },
      { text: i18n.t("work_days_types.custom"), value: "CUSTOM" },
    ],
    customStartTime: "07:00",
    customEndTime: "18:00",
    form: {
      lessonDuration: 30,
      studentCanCreatePractice: false,
      onlyPlaceholdersBooking: false,
      showStudentsAllTeachersEvents: false,
      automaticPlaceholder: false,
      availableWorkDays: [],
      availableWorkHoursType: "WEEKLY",
      weeklyAvailableWorkHours: {
        startTime: "07:00",
        endTime: "18:00",
      },
      customAvailableWorkHours: {},
    },
  }),
  watch: {
    "form.onlyPlaceholdersBooking"(val) {
      if (!val) this.form.showStudentsAllTeachersEvents = false;
    },
  },
  computed: {
    ...mapState("auth", { currentUser: "authUser" }),
    shortWeekDays() {
      const firstDOW = startOfWeek(new Date(), { weekStartsOn: 1 });

      return Array.from(Array(7)).map((e, i) =>
        format(addDays(firstDOW, i), "EEE").toLowerCase()
      );
    },
  },
  created() {
    const startTime = "07:00";
    const endTime = "18:00";
    const teacherAvailableWorkDayHours = this.currentUser.availableWorkDayHours;

    this.form.lessonDuration = this.currentUser.lessonDuration;
    this.form.studentCanCreatePractice =
      this.currentUser.studentCanCreatePractice;
    this.form.onlyPlaceholdersBooking =
      this.currentUser.onlyPlaceholdersBooking;
    this.form.showStudentsAllTeachersEvents =
      this.currentUser.showStudentsAllTeachersEvents;
    this.form.automaticPlaceholder = this.currentUser.automaticPlaceholder;
    this.form.availableWorkHoursType = this.currentUser.availableWorkHoursType;
    this.form.availableWorkDays = this.currentUser.availableWorkDays;

    const customAvailableWorkHours = {};

    this.shortWeekDays.forEach((item) => {
      const day = teacherAvailableWorkDayHours[item] || {};

      customAvailableWorkHours[item] = {
        startTime: day ? day.startTime : startTime,
        endTime: day ? day.endTime : endTime,
      };
    });

    this.$set(this.form, "customAvailableWorkHours", {
      ...customAvailableWorkHours,
    });
    this.$set(this.form, "weeklyAvailableWorkHours", {
      startTime: this.currentUser.availableWorkHoursStartTime || startTime,
      endTime: this.currentUser.availableWorkHoursEndTime || endTime,
    });
  },
  methods: {
    ...mapActions("auth", ["getAuthUser"]),
    save() {
      this.$refs.form.reset();

      this.formLoading = true;
      teacherService
        .updatePreferences(this.form)
        .then(() => {
          this.getAuthUser();
          this.$snackbar.show({
            message: this.$t("messages.successfully_sent"),
            color: "success",
            timeout: 2000,
          });
        })
        .catch((error) => {
          if (!error.response) console.log(error);

          const responseData = error.response.data;
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message)
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });
          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    onChangeCustomStartTime(startTime) {
      this.shortWeekDays.forEach((item) => {
        this.$set(
          this.form.customAvailableWorkHours[item],
          "startTime",
          startTime
        );
      });
    },
    onChangeCustomEndTime(endTime) {
      this.shortWeekDays.forEach((item) => {
        this.$set(this.form.customAvailableWorkHours[item], "endTime", endTime);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.work-day__title {
  display: block;
  height: 40px;
  min-width: 42px;
  padding: 1px;
  margin-right: 4px;
  text-align: center;
}
</style>
