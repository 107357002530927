import api from "@/utils/api";

export default {
  studentsStats: () => {
    return new Promise((resolve, reject) => {
      api
        .get("/teacher/students-stats")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  nextScheduledStudents: () => {
    return new Promise((resolve, reject) => {
      api
        .get("/widgets/teacher-next-scheduled-students")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updatePreferences(params) {
    params._method = "PUT";
    return new Promise((resolve, reject) => {
      api
        .post("/teacher/settings/preferences", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateBookingLimit(params) {
    params._method = "PUT";
    return new Promise((resolve, reject) => {
      api
        .post("/teacher/settings/booking-limits", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  nextEvents: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("/teacher/next-events", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  workedTime(teacherId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/widgets/teachers-worktime/${teacherId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  downloadDailyWorktimeReport(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/teacher/daily-worktime-pdf", {
          params: params,
          responseType: "blob",
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
