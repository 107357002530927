<template>
  <v-dialog
      ref="dialog"
      v-model="dialog"
      :close-on-content-click="false"
      persistent
      max-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          class="clock"
          @click="$emit('emitInitialStart')"
          :disabled="disabled"
          :label="label"
          v-model="time"
          menu-props="auto"
          outlined
          readonly
          hide-details
          v-bind="attrs"
          v-on="on"
          dense
      >

        <v-icon slot="prepend-inner" :disabled="disabled" color="red" @click="subMin(5)">
          mdi-chevron-double-left
        </v-icon>
        <v-icon slot="prepend-inner" :disabled="disabled" color="red" @click="subMin(1)">
          mdi-chevron-left
        </v-icon>

        <v-icon slot="append" color="green" :disabled="disabled" @click="addMin(1)">
          mdi-chevron-right
        </v-icon>
        <v-icon slot="append" color="green" :disabled="disabled" @click="addMin(5)">
          mdi-chevron-double-right
        </v-icon>

      </v-text-field>
    </template>
    <v-time-picker
        v-if="dialog"
        v-model="time"
        format="24hr"
        :header-color="color"
        :allowed-hours="allowedHours"
        :allowed-minutes="allowedMinutes"
        @click:hour="presetHour"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="dialog = false">
        {{ $t('btn.cancel') }}
      </v-btn>
      <v-btn text color="primary" @click="setTime">
        {{ $t('btn.ok') }}
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
import { addMinutes, differenceInMinutes, endOfDay, format, getHours, getMinutes, parseISO, subMinutes, setHours } from 'date-fns'
import { ClickOutside } from "vuetify/lib/directives";
export default {
  name: "ClockTimePicker",
  directives: { ClickOutside },
  data() {
    return {
      dialog: false,
      finalTime: null,
      setHour: null,
    };
  },
  props: {
    color: {
      type: String,
    },
    timeProp: {
      type: Date,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    duration: {
      type: Number,
    },
  },
  mounted() {
    this.setHour = getHours(this.timeProp)
  },
  computed: {
    time: {
      set(val) {
        this.finalTime = val
      },
      get() {
        return format(this.timeProp, "HH:mm")
      }
    },
    startTimeLimit() {
      const dayEnd = addMinutes(endOfDay(this.tempTime), 1)
      return subMinutes(dayEnd, this.duration)
    },
    freeMinutesLeftThisDay() {
      const endTIme = addMinutes(this.tempTime, this.duration)
      const dayEnd = addMinutes(endOfDay(this.tempTime), 1)
      return differenceInMinutes(dayEnd, endTIme)
    },
    tempTime() {
      return setHours(this.timeProp, this.setHour)
    },
  },
  methods: {
    presetHour(val) {
      this.setHour = val
    },
    allowedHours(val) {
      return val <= getHours(this.startTimeLimit)
    },
    allowedMinutes(val) {
      if (this.freeMinutesLeftThisDay >= 60) {
        return val >= 0
      }
      return val <= getMinutes(this.startTimeLimit)
    },
    subMin(minutes) {
      this.$emit("emitTimeChange", subMinutes(this.timeProp, minutes))
    },
    addMin(minutes) {
      this.$emit("emitTimeChange", addMinutes(this.timeProp, minutes))
    },
    setTime() {
      const date = format(this.timeProp, "yyyy-MM-dd")
      const time = parseISO(date + ' ' + this.finalTime)
      this.$emit("emitTimeChange", time)
      this.dialog = false
    }
  }
};
</script>

<style lang="scss">
.clock input {
  text-align: center;
  cursor: default;
}
</style>
