<template>
  <v-col :cols="cols">
    <ValidationProvider name="lesson-type" rules="required" v-slot="{ errors }">
      <v-select
        @change="resetTeacher"
        :error="errors.length > 0"
        :disabled="disabled"
        v-model="model.lessonTypeId"
        :label="$t('form.lesson_type')"
        :items="lessonTypes"
        item-value="id"
        item-text="name"
        outlined
        hide-details
        dense
        :append-icon="!disabled ? 'mdi-menu-down' : ''"
        :menu-props="{ offsetY: true }" />
    </ValidationProvider>
  </v-col>
</template>

<script>
import LessonTypeModel from "@/store/models/LessonTypeModel";
import user from "@/utils/mixins/user";
import { parseISO } from "date-fns";
import EventModel from "@/store/models/EventModel";
import { mapGetters } from "vuex";

export default {
  name: "LessonTypesField",
  mixins: [user],
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    eventType: {
      type: String,
      required: true,
    },
    cols: {
      type: Number,
      default: 6,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    lessonTypes() {
      const event = EventModel.find(this.model.id) || {};
      const isPast = parseISO(event.start) <= new Date();
      let lessonTypes =
        LessonTypeModel.query().where("eventTypeId", this.eventType).get() ||
        {};
      lessonTypes =
        this.isTeacher && this.eventType === "THEORY"
          ? [lessonTypes[0]]
          : lessonTypes;
      if (this.isStudent) {
        if (!this.model.id) {
          lessonTypes = lessonTypes.filter((type) =>
            ["normal", "special", "instructions"].includes(type.group)
          );
        } else {
          const gratisExamIndex = lessonTypes.findIndex(
            (type) => type.name === "lesson_type.gratis_exam"
          ); // Gratis exams for students should look like paid exam
          lessonTypes[gratisExamIndex].name = "lesson_type.exam";
        }
      }
      this.preselectLessonType(this.model.lessonTypeId || lessonTypes[0].id);
      return lessonTypes.map((lessonType) => {
        const disabled =
          isPast && (lessonType.group === "exam" || event.group === "exam");
        return {
          ...lessonType,
          disabled: disabled,
          name: this.$tc(lessonType.name, 1),
        };
      });
    },
  },
  methods: {
    preselectLessonType(id) {
      const lessonTypes = LessonTypeModel.find(id) || {};
      this.model.lessonTypeId = id;
      this.model.lessonTypeGroup = lessonTypes.group;
    },
    resetTeacher() {
      if (this.isSchoolAdministrator && this.model.type === "THEORY") {
        this.$nextTick(() => {
          this.model.teacher = null;
          this.model.teacherId = null;
          this.model.theoryTopicId = null;
          this.model.licenseId = null;
          this.model.licensesIds = [];
          this.model.studentsIds = [];
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
