<template>
  <div class="calendar-top-menu">
    <div class="app-controls">
      <v-btn class="ma-auto" text icon @click="$emit('sidebar')">
        <v-icon size="22">{{
          isSidebarEnabled
            ? "mdi-format-indent-decrease"
            : "mdi-format-indent-increase"
        }}</v-icon>
      </v-btn>
    </div>

    <div :class="`calendar-controls ${!mdLandscapeAndUp ? 'mobile' : ''}`">
      <v-btn class="mr-2"
             icon small
             :disabled="loaderGetter"
             @click="loadAppointments(true)">
        <v-icon>mdi-calendar-refresh</v-icon>
      </v-btn>
      <CalendarRangeControls />

      <label class="ml-2 current-month">{{ currentMonth }}</label>

      <v-spacer />

      <div class="end mr-3">
        <template v-if="!isStudent">
          <v-btn
            text
            icon
            class="ml-1"
            @click="openCopyWeeksDialog"
            :disabled="disabledCopyWeeks || multiDeleteStatusGetter">
            <v-icon size="21">mdi-content-copy</v-icon>
          </v-btn>
          <v-btn class="mx-1" text icon @click="deleteEventsDialog">
            <v-icon>{{
              multiDeleteStatusGetter
                ? "mdi-delete-off-outline"
                : "mdi-delete-outline"
            }}</v-icon>
          </v-btn>
        </template>
      </div>

      <CalendarLayoutControls v-if="mdLandscapeAndUp" />
    </div>

    <CopyWeeksDialog ref="copyWeekDialog" :activeDate="activeDate" />
  </div>
</template>

<script>
import * as eventsMutationTypes from "@/store/mutation-types/events";
import {format, isBefore, parseISO, startOfWeek} from "date-fns";
import user from "@/utils/mixins/user";
import responsive from "@/utils/mixins/responsive";
import loadAppointments from '@/utils/mixins/loadAppointments';

import CalendarRangeControls from "@/components/calendar/CalendarRangeControls.vue";
import CalendarLayoutControls from "@/components/calendar/CalendarLayoutControls.vue";
import CopyWeeksDialog from "@/components/calendar/CopyWeeksDialog.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import LocalizationManager from "@/helpers/LocalizationManager";

export default {
  name: "CalendarTopMenu",
  mixins: [responsive, user, loadAppointments],
  components: {
    CalendarRangeControls,
    CalendarLayoutControls,
    CopyWeeksDialog,
  },
  data: () => ({
    copyDialog: false,
  }),
  computed: {
    ...mapState({
      isSidebarEnabled: (state) => state.calendar.isSidebarEnabled,
    }),
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters(["activeDate", "loaderGetter"]),
    ...mapGetters("appointments", ["multiDeleteStatusGetter"]),
    parsedDate() {
      return parseISO(this.activeDate)
    },
    disabledCopyWeeks() {
      return isBefore(this.parsedDate, startOfWeek(new Date()))
    },
    currentMonth() {
      return format(this.parsedDate, "MMMM, yyyy", { locale: LocalizationManager.dateFnsLocale });
    },
    calendarSidebarEnabled() {
      return localStorage.getItem("calendarSidebarEnabled");
    },
  },
  methods: {
    ...mapActions(["toggleCalendarSidebar"]),
    deleteEventsDialog() {
      this.$store.commit(`appointments/${eventsMutationTypes.MULTI_DELETE_STATUS_TOGGLE}`);
    },
    openCopyWeeksDialog() {
      this.$refs.copyWeekDialog.open();
    },
  },
};
</script>

<style scoped lang="scss">
.calendar-top-menu {
  grid-area: top;
  border-bottom: var(--default-border);
  display: grid;
  grid-template-columns: 50px auto;
}
.app-controls {
  display: flex;
  align-items: center;
}
.calendar-controls {
  display: flex;
  align-items: center;
  padding: 0 10px;
  &.mobile {
    justify-content: space-between;
  }
}
</style>
