<template>
  <div class="list-action-bar px-3 pt-2">
    <v-row class="mb-2" no-gutters v-if="isTopBar">
      <v-col cols="12" md="6" lg="8" class="d-flex align-center">
        <v-btn
          v-if="!$vuetify.breakpoint.mobile"
          class="text-subtitle-2 mr-2"
          color="primary"
          @click="$emit('clickCreateItem')"
        >
          {{ createBtnName }}
        </v-btn>
      </v-col>

      <v-col cols="12" md="12" lg="4" class="justify-end">
        <div class="d-flex justify-end align-center">
          <slot name="filters-body" v-if="isFilters" />

          <v-text-field
            v-if="isSearch"
            class="ml-2 mr-2"
            v-model="searchText"
            :label="$t('search')"
            hide-details
            @input="search"
            dense
            outlined
            full-width
            clearable
            @click:clear="searchText = ''"
          >
            <template v-slot:append>
              <v-icon v-if="!searchText">mdi-magnify</v-icon>
            </template>
          </v-text-field>

          <ListChangeViewBtn v-if="hasToggleListView" />

          <students-index-preferences-dialog :dark="false" v-if="hasStudentsOverviewToggler" />
        </div>
      </v-col>
    </v-row>

    <v-divider
        class="mb-1"
        v-if="isTopBar && isBottomBar"
    />

    <v-row class="mx-3 mb-1 pb-2" no-gutters v-if="isBottomBar" style="overflow-y: auto;">
      <v-col cols="12" class="d-flex justify-space-between align-center">
        <div class="d-flex">
          <v-simple-checkbox
            v-if="isMultiSelect"
            class="mt-0"
            :ripple="false"
            :value="totalItemsCount !== 0 && selectedItemsCount === totalItemsCount"
            :indeterminate="selectedItemsCount !== 0 && selectedItemsCount < totalItemsCount"
            @click="(value) => $emit('checkboxChange', value)"
          />
          <v-icon v-if="selectedItemsCount > 0" @click="$emit('deleteSelected')">mdi-trash-can-outline</v-icon>
        </div>

        <div class="d-flex align-center">
          <template v-if="hasSortButtons">
            <SortButton
              v-for="(btn, index) in sortButtons"
              :key="index"
              :disabled="loadingOrder"
              :color="btn.active ? 'primary' : 'grey'"
              :order="btn.order"
              :icon="btn.icon"
              :tooltip-message="btn.tooltipMessage"
              @btnClick="sortBy(btn.sortBy, btn.order, index)"
            />
            <v-divider vertical class="mx-3" />
          </template>

          <Pagination
            v-if="isPagination"
            :from="pagination.from"
            :to="pagination.to"
            :current-page="pagination.currentPage"
            :total-nr-of-pages="pagination.lastPage"
            :total-nr-of-items="pagination.total"
            :loading="loadingPagination"
            @pageChange="$emit('pageChange')"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import responsive from "@/utils/mixins/responsive";
import LicenseModel from "@/store/models/LicenseModel";
import Pagination from "@/components/Pagination";
import SortButton from "@/components/SortButton";

import user from "@/utils/mixins/user";
import ListChangeViewBtn from "@/components/list/ListChangeViewBtn";
import i18n from "@/plugins/i18n";
import StudentsIndexPreferencesDialog from "@/components/students-directory/StudentsIndexPreferencesDialog.vue";

export default {
  name: "PageToolBar",
  mixins: [responsive, user],
  components: {StudentsIndexPreferencesDialog, ListChangeViewBtn, Pagination, SortButton },
  data: () => ({
    searchText: "",
    sortButtons: [
      {
        sortBy: "name",
        order: "desc",
        active: false,
        icon: "mdi-sort-alphabetical",
        tooltipMessage: i18n.t("label.sort_by_name"),
      },
      {
        sortBy: "date",
        order: "desc",
        active: false,
        icon: "mdi-sort-clock",
        tooltipMessage: i18n.t("label.sort_by_created_date"),
      },
    ],
  }),
  props: {
    hasStudentsOverviewToggler: {
      type: Boolean,
      default: false,
    },
    loadingSearch: {
      type: Boolean,
      default: false,
    },
    loadingOrder: {
      type: Boolean,
      default: false,
    },
    loadingPagination: {
      type: Boolean,
      default: false,
    },
    isTopBar: {
      type: Boolean,
      default: true,
    },
    isBottomBar: {
      type: Boolean,
      default: false,
    },
    isFilters: {
      type: Boolean,
      default: false,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    isPagination: {
      type: Boolean,
      default: false,
    },
    isMultiSelect: {
      type: Boolean,
      default: false,
    },
    hasToggleListView: {
      type: Boolean,
      default: true,
    },
    totalItemsCount: {
      type: Number,
      default: 0,
    },
    selectedItemsCount: {
      type: Number,
      default: 0,
    },
    createBtnName: {
      type: String,
    },
    pagination: {
      type: Object,
      default: () => {},
    },
    hasSortButtons: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const initialSorting = this.$route.query.sort_type;
    if (initialSorting) {
      const sortingName = initialSorting.split("_")[0];
      const sortingDirection = initialSorting.split("_")[1];
      this[`${sortingName}Sorting`] =
        sortingDirection === "asc" ? "desc" : "asc";
    }
  },
  computed: {
    licenses() {
      return LicenseModel.all() || [];
    },
  },
  methods: {
    sortBy(type, order, btnIndex) {
      this.sortButtons.forEach((btn) => {
        btn.order = "desc";
        btn.active = false;
      });
      this.sortButtons[btnIndex].order = order === "asc" ? "desc" : "asc";
      this.sortButtons[btnIndex].active = true;

      this.$router
        .replace({
          query: {
            filters: this.$route.query.filters || undefined,
            sort_type: type + "_" + order,
          },
        })
        .then(() => this.$emit("applyOrder"));
    },
    search() {
      this.$emit("search", this.searchText);
    },
    onCloseFilter(filter) {
      const query = Object.assign({}, this.$route.query);

      if (Object.prototype.hasOwnProperty.call(query, filter.key)) {
        if (Array.isArray(query[filter.key])) {
          const filterItemArray = query[filter.key];
          const filterValueIndex = filterItemArray.indexOf(filter.value);

          if (filterValueIndex !== -1)
            query[filter.key] = filterItemArray.splice(filterValueIndex, 1);
        } else {
          delete query[filter.key];
        }
      }

      this.$router.replace({ query });
    },
  },
};
</script>

<style scoped>
.list-action-bar {
  border-bottom: 1px solid #eee;
}
</style>
