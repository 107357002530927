<template>
  <v-dialog
    scrollable
    transition="dialog-bottom-transition"
    max-width="600"
    :value="show"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <v-skeleton-loader v-if="loading" class="d-flex align-center" width="100" height="32" type="text@1" />
        <div class="d-flex align-center justify-space-between" style="width: 100%" v-else>
          <span>{{ $t(`event_type.${event.type}`) }}</span>

          <v-chip v-if="!loading && event.isTrashed" color="error" small label>
            <v-icon left>mdi-delete-variant</v-icon>
            {{ $t("label.deleted") }}
          </v-chip>
        </div>
      </v-card-title>

      <v-divider/>

      <v-card-text>
        <v-list-item two-line v-if="!loading && event.lessonType">
          <v-list-item-icon><v-icon>mdi-calendar</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("label.lesson_type") }}</v-list-item-title>
            <v-list-item-subtitle v-if="loading">
              <v-skeleton-loader width="200" type="text@1"/>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else>
              {{ $tc(event.lessonType, 1) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-icon><v-icon>mdi-calendar</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("label.start") }}</v-list-item-title>
            <v-list-item-subtitle v-if="loading"
              ><v-skeleton-loader width="200" type="text@1"
            /></v-list-item-subtitle>
            <v-list-item-subtitle v-else>
              {{ event.start | dateFormat }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-icon><v-icon>mdi-clock</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("label.duration") }}</v-list-item-title>
            <v-list-item-subtitle v-if="loading"
              ><v-skeleton-loader width="100" type="text@1"
            /></v-list-item-subtitle>
            <v-list-item-subtitle v-else>
              {{ $tc("label.duration_in_minutes", event.duration, { min: event.duration, }) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="!loading && event.teacherName">
          <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("label.teacher") }}</v-list-item-title>
            <v-list-item-subtitle>{{ event.teacherName  }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line v-if="!loading && event.meetingPlace">
          <v-list-item-icon><v-icon>mdi-map-marker</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("label.meeting_place") }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ event.meetingPlace }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-icon><v-icon>mdi-currency-eur</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("label.cost") }}</v-list-item-title>
            <v-list-item-subtitle v-if="loading">
              <v-skeleton-loader width="60" type="text@1"/>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else>
              <strong>{{ event.cost | currency }}</strong>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-btn text @click="close">
          <v-icon v-if="$vuetify.breakpoint.mobile">mdi-close</v-icon>
          <span v-else>{{ $t("btn.close") }}</span>
        </v-btn>
        <v-spacer/>
        <v-btn color="primary" @click="openHistory">
          <v-icon :left="! $vuetify.breakpoint.mobile">mdi-history</v-icon>
          <span v-if="! $vuetify.breakpoint.mobile">{{ $t("btn.view_history") }}</span>
        </v-btn>
        <v-btn color="primary" :to="{ name: 'calendar.appointment', params: { id: eventId } }">
          <v-icon :left="! $vuetify.breakpoint.mobile">mdi-calendar</v-icon>
          <span v-if="! $vuetify.breakpoint.mobile">{{ $t("btn.view_event") }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>

    <AppointmentHistoryDialog ref="appointmentHistoryDialog"/>
  </v-dialog>
</template>

<script>
import appointmentService from '@/services/appointmentService'
import AppointmentHistoryDialog from '@/components/appointment/AppointmentHistoryDialog'

export default {
  name: "BriefEventInfo",
  components: { AppointmentHistoryDialog },
  data() {
    return {
      show: false,
      loading: true,
      eventId: null,
      event: null
    };
  },
  methods: {
    open(eventId) {
      appointmentService
        .get(eventId)
        .then((response) => {
          this.event = response.data || {};
        })
        .finally(() => {
          this.loading = false;
        });

      this.show = true;
      this.eventId = eventId;
    },
    close() {
      this.show = false;
      this.event = null;
      this.eventId = null;
      this.loading = true
    },
    openHistory() {
      this.$refs.appointmentHistoryDialog.open(this.eventId)
    }
  }
};
</script>

<style scoped></style>
