<template>
  <v-row dense class="ma-2">
    <v-col cols="12" lg="9">
      <v-row dense class="mb-1">
        <v-col cols="12" sm="6">
          <SchoolStudentsCount />
        </v-col>
        <v-col cols="12" sm="6">
          <TeachersCount />
        </v-col>
      </v-row>

      <NewStudents />

      <v-row dense>
        <v-col cols="12" lg="6">
          <v-row dense>
            <v-col cols="12">
              <StudentsWithoutAppointments />
            </v-col>
            <v-col cols="12">
              <BalanceStats />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6">
          <v-row dense>
            <v-col cols="12" lg="12">
              <TeachersWorkTime />
            </v-col>
            <v-col cols="12" lg="12">
              <UnpaidStudents />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" lg="3">
      <v-row dense>
        <v-col cols="12">
          <SchoolUpcomingAppointments />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

import SchoolStudentsCount from "@/components/widgets/dashboard/SchoolStudentsCount";
import TeachersCount from "@/components/widgets/dashboard/TeachersCount";
import NewStudents from "@/components/widgets/dashboard/NewStudents";
import StudentsWithoutAppointments from "@/components/widgets/dashboard/StudentsWithoutAppointments";
import TeachersWorkTime from "@/components/widgets/dashboard/TeachersWorkTime";
import BalanceStats from "@/components/widgets/dashboard/BalanceStats";
import SchoolUpcomingAppointments from "@/components/widgets/dashboard/UpcomingAppointments";
import SchoolDataLoader from "@/helpers/schoolDataLoader";
import UnpaidStudents from "@/components/widgets/dashboard/UnpaidStudents.vue";

export default {
  name: "SchoolDashboard",
  components: {
    UnpaidStudents,
    SchoolUpcomingAppointments,
    BalanceStats,
    TeachersWorkTime,
    StudentsWithoutAppointments,
    NewStudents,
    TeachersCount,
    SchoolStudentsCount,
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
  },
  mounted() {
    SchoolDataLoader.load({ only: ['s','v'] })
  }
};
</script>

<style scoped lang="scss"></style>
