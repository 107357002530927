<script>
import InvoiceListItem from "@/components/InvoiceListItem.vue";

export default {
  name: "StudentInvoicesList",
  props: {
    openInvoices: {
      type: Array,
      required: true
    },
    otherInvoices: {
      type: Array,
      required: true
    }
  },
  components: { InvoiceListItem }
};
</script>

<template>
  <v-list>
    <template v-if="openInvoices.length">
      <v-subheader>{{ $t("label.open_invoices") }}</v-subheader>
      <template v-for="(invoice, key) in openInvoices">
        <invoice-list-item :key="`${invoice.id}-invoice`" :invoice="invoice" />
        <v-divider v-if="key < openInvoices.length - 1" :key="`${invoice.id}-divider`"></v-divider>
      </template>
      <v-subheader v-if="otherInvoices.length">{{ $t("label.other_invoices") }}</v-subheader>
    </template>

    <template v-for="(invoice, key) in otherInvoices">
      <invoice-list-item :key="`${invoice.id}-invoice`" :invoice="invoice" />
      <v-divider v-if="key < otherInvoices.length - 1" :key="`${invoice.id}-divider`"></v-divider>
    </template>
  </v-list>
</template>

<style scoped>

</style>
