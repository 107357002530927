<script>
import EvaluationModel from '@/store/models/insights/EvaluationModel'
import TemplateRenderer from '@/components/insights/TemplateRenderer.vue'
import EvaluationsScoreLegend from '@/components/insights/EvaluationsScoreLegend.vue'

export default {
  name: 'StudentEvaluationDialog',
  components: { TemplateRenderer, EvaluationsScoreLegend},
  data() {
    return {
      state: false,
      saving: false,
      evaluationId: null,
      appointmentId: null,
      appointment: null
    }
  },
  computed: {
    evaluation() {
      return EvaluationModel.query().with(['items', 'items.category']).where('id', this.evaluationId).first() || {}
    },
    evaluationItems() {
      return this.evaluation.items || []
    }
  },
  methods: {
    open(evaluationId, appointment) {
      this.evaluationId = evaluationId;
      this.appointment = appointment;
      this.state = true;
    },
    close() {
      this.state = false;
      setTimeout(() => {
        this.evaluationId = null;
        this.appointmentId = null;
      }, 200)
    }
  }
}
</script>

<template>
<v-dialog
    :value="state"
    max-width="1400"
    scrollable
    hide-overlay
    @keydown.esc="close"
    @click:outside="close"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    transition="dialog-bottom-transition"
>
  <v-card tile>
    <v-card-title primary-title>
      {{ $t('insights.feature_name') }}
    </v-card-title>
    <v-card-text>
     <v-row class="text-caption">
       <v-col cols="12" lg="4">
         <div class="mb-3" v-if="appointment">
           <span class="subtitle-1">{{ $t('insights.appointment_details') }}</span><br>
           <span class="caption">{{ appointment.date }} &middot; {{ appointment.startTime }} — {{ appointment.endTime }}</span><br>
           <span class="caption">{{ appointment.teacherName }}</span><br>
           <span v-if="appointment.lessonType" class="caption">{{$tc(appointment.lessonType.name)}}</span>
         </div>
       </v-col>
       <v-col cols="12" lg="8">
         <div v-if="evaluation.comment">
           <span class="subtitle-1">{{ $t('insights.comment') }}</span><br>
           <span class="caption">{{ evaluation.comment }}</span>
         </div>
         <div v-if="evaluation.drivingArea">
           <span class="subtitle-1">{{ $t("label.driving_area") }}</span><br>
           <span class="caption">{{ evaluation.drivingArea }}</span>
         </div>
       </v-col>
     </v-row>

      <EvaluationsScoreLegend v-if="evaluation?.selfEvaluation" />

     <v-row class="mt-3" dense>
       <v-col>
         <template-renderer
             v-if="evaluationItems.length > 0"
             tile
             expanded
             :show-description="false"
             :show-toolbar="false"
             :template-id="evaluation.templateId"
             :evaluation="evaluation"
         />
         <empty-box v-else :text="$t('insights.evaluation_is_empty')"/>
       </v-col>
     </v-row>
    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-spacer/>
      <v-btn text @click="close">
        {{  $t('btn.close') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<style scoped>

</style>
