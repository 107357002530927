<template>
<v-container>
  <v-card :loading="loading">
    <v-toolbar elevation="3">
      <v-card-title>{{ $t('route.priceLists') }}</v-card-title>
      <v-spacer />
      <v-btn text color="primary" @click="createPriceCategory">{{ $t("btn.new_price_category")  }}</v-btn>
    </v-toolbar>

      <v-skeleton-loader v-if="loading" class="mx-auto" type="table-row@3" />

      <template v-else>
        <v-simple-table v-if="categories.length" dense class="mt-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th>{{$t('list.name')}}</th>
                <th class="text-center" style="width: 50px;">{{$t('list.licenses')}}</th>
                <th class="text-center" style="width: 50px;">{{$t('list.students')}}</th>
                <th class="text-center" style="width: 120px;">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon left small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>{{$t('messages.default_price_category_status_info')}}</span>
                  </v-tooltip>
                  {{$t('list.default')}}
                </th>
                <th style="width: 110px"></th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="item in categories" :key="item.id" class="table-row">
              <td>{{ item.name }}</td>
              <td class="text-center">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                        :color="item.hasIncompleteLicenses ? 'warning' : 'success'"
                        dark
                        label
                        small
                        link
                        v-bind="attrs"
                        v-on="on"
                        style="min-width: 55px;"
                    >
                      <v-icon left small>
                        {{ item.hasIncompleteLicenses ? 'mdi-alert' : 'mdi-check' }}
                      </v-icon>
                      {{ item.licensesIds.length }}
                    </v-chip>
                  </template>
                  <span>
                    {{ item.licensesNames.join(', ') }}
                  </span>
                </v-tooltip>
              </td>
              <td class="text-center">{{ item.studentsCount }}</td>
              <td class="text-center">
                <v-hover v-if="item.isDefault" v-slot="{ hover }">
                  <v-icon :color="hover ? 'error' : 'success'" @click="setDefaultStatus(item.id,false)">
                    {{ hover ? 'mdi-close' : 'mdi-check' }}
                  </v-icon>
                </v-hover>

                <v-chip
                    v-else
                    @click="setDefaultStatus(item.id,true)"
                    class="hidden-until-row-hovered"
                    link
                    label
                    x-small
                >
                  <v-icon x-small>mdi-check</v-icon>
                </v-chip>
              </td>
              <td class="d-flex justify-space-between align-center">
                <v-chip
                    class="hidden-until-row-hovered"
                    link
                    label
                    x-small
                    :to="{ name: 'price-list', params: { id: item.id }}"
                >
                  <v-icon x-small>mdi-pencil</v-icon>
                </v-chip>
                <v-chip
                    v-if="item.studentsCount === 0"
                    class="hidden-until-row-hovered"
                    link
                    label
                    x-small
                    @click="deleteItem(item.id)"
                >
                  <v-icon x-small>mdi-close</v-icon>
                </v-chip>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <empty-box v-else />
      </template>
  </v-card>

  <PricesCategoryFormDialog ref="pricesCategoryFormDialog" @created="onPriceCategoryCreated"/>
</v-container>
</template>

<script>
import { showConfirm } from '@/helpers/dialogs'

import LicenseModel from '@/store/models/LicenseModel'
import productsService from '@/services/productsService'
import ProductCategoryModel from '@/store/models/ProductCategoryModel'
import PricesCategoryFormDialog from '@/components/products/PricesCategoryFormDialog'
import priceCategoriesService from '@/services/priceCategoriesService'

export default {
  name: 'Index',
  components: { PricesCategoryFormDialog },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    licenses() {
      return LicenseModel.all();
    },
    categories() {
      return ProductCategoryModel.all().map(priceCategory => {
        const licensesIds = priceCategory.licensesIds.map(licenseId => licenseId.id)

        return {
          ...priceCategory,
          licensesNames: LicenseModel.query().whereIdIn(licensesIds).get().map(license => license.name),
          hasIncompleteLicenses: priceCategory.licensesIds.some(licenseId => licenseId.completed === false)
        }
      })
    }
  },
  mounted () {
    this.loading = true;
    priceCategoriesService
      .load()
      .then((response) => {
        ProductCategoryModel.create({ data: response.data || [] })
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    deleteItem(itemId) {
      showConfirm(
          this.$t('btn.confirm'),
          this.$t("messages.are_your_sure_delete"),
          () => {
            productsService
              .deletePriceCategory(itemId)
              .then(() => {
                ProductCategoryModel.delete(itemId)
              }).catch(error => {
                const response = error.response || {};
                const responseData = response.data || {};

                if (responseData.message) {
                  this.$snackbar.show({ message: responseData.message, color: "error", });
                }
              });
          }
      );
    },
    setDefaultStatus(itemId, isDefault) {
      priceCategoriesService
          .setDefault(itemId, isDefault)
          .then(() => {
            ProductCategoryModel.update({
              where: (selectedPriceCategory) => selectedPriceCategory.id !== itemId,
              data: { isDefault: 0 },
            }).then(() =>
                ProductCategoryModel.update({ where: itemId, data: { isDefault: isDefault }, })
            );
          })
          .catch((error) => {
            const response = error.response || {};
            const responseData = response.data || {};

            if (responseData.message) {
              this.$snackbar.show({ message: responseData.message, color: "error", });
            }
          })
    },
    createPriceCategory() {
      this.$refs.pricesCategoryFormDialog.open();
    },
    onPriceCategoryCreated(priceCategory) {
      this.$router.push({name: 'price-list', params: {id: priceCategory.id}})
    }
  }
}
</script>

<style scoped>

</style>
