<template>
  <v-sheet class="my-3" style="background-color: white" outlined color="#eee" rounded elevation="2">
    <div class="d-flex flex-column pa-3 flex-md-row"  @click="isOpen = !isOpen">
      <div class="d-flex flex-column justify-center text--secondary text-center">
        <LicenseIcon
          :name="license.name"
          :icon="license.icon"
          width="100"
          height="44"
          :row="$vuetify.breakpoint.smAndDown"
          :reverse="$vuetify.breakpoint.smAndDown"
          :fluid="$vuetify.breakpoint.smAndDown"
          active
        />
      </div>

      <v-divider class="mx-3" v-if="$vuetify.breakpoint.mdAndUp" vertical />

      <div class="d-flex flex-column text--secondary mt-3 mt-md-0" style="width: 100%">
        <div class="d-flex flex-column">
          <v-row :dense="$vuetify.breakpoint.smAndDown" class="mt-3">
            <v-col cols="12" :md="progress.practiceInstructionsShould ? 3 : 4">
              <LessonTypeProgress
                  class="flex-grow-1"
                  :image="require('@/assets/img/lesson-types/theory.png')"
                  :title="$t('label.theories')"
                  :value-is="theoryValueIs"
                  :value-should-be="theoryValueShould"
                  :progress-value="progress.theoryProgress"
                  :color="progressChipColor(theoryValueIs, theoryValueShould)"
              />
            </v-col>
            <v-col cols="12" md="3" v-if="progress.practiceInstructionsShould">
              <LessonTypeProgress
                  class="flex-grow-1"
                  :image="require('@/assets/img/lesson-types/driving-instruction.png')"
                  :title="$t('lesson_type.instruction')"
                  :value-is="progress.practiceInstructionsIs"
                  :value-should-be="progress.practiceInstructionsShould > 0
                    ? progress.practiceInstructionsShould
                    : undefined"
                  :progress-value="progress.practiceInstructionsShould > 0 ? instructionsProgress : 0"
                  :color="progressChipColor(progress.practiceInstructionsIs, progress.practiceInstructionsShould)"
              />
            </v-col>
            <v-col cols="12" :md="progress.practiceInstructionsShould ? 3 : 4">
              <LessonTypeProgress
                  class="flex-grow-1"
                  :image="require('@/assets/img/lesson-types/driving.png')"
                  :title="$t('label.normal_lessons')"
                  :value-is="progress.practiceNormalsIs"
                  :value-should-be="progress.practiceNormalsShould > 0 ? progress.practiceNormalsShould : undefined"
                  :progress-value="progress.practiceNormalsShould > 0 ? normalsProgress : 0"
                  :color="progressChipColor(progress.practiceNormalsIs, progress.practiceNormalsShould)"
              />
            </v-col>
            <v-col cols="12" :md="progress.practiceInstructionsShould ? 3 : 4">
              <LessonTypeProgress
                  class="flex-grow-1"
                  :image="require('@/assets/img/lesson-types/driving-special.png')"
                  :title="$t('label.special_lessons')"
                  :value-is="practiceValueIs"
                  :value-should-be="practiceValueShould"
                  :progress-value="practiceValueShould > 0 ? progress.practiceProgress : 100"
                  :color="progressChipColor(practiceValueIs, practiceValueShould)"
              />
            </v-col>
          </v-row>

          <div v-if="isOpen">
            <v-divider class="my-3" />
            <v-row :dense="$vuetify.breakpoint.smAndDown">
              <v-col cols="12" :md="progress.practiceRequiredMTLsShould ? 3 : 4">
                <LessonTypeProgress
                    class="flex-grow-1"
                    :image="require('@/assets/img/lesson-types/overland.png')"
                    :title="$tc('lesson_type.overland')"
                    :value-is="progress.practiceOverlandsIs"
                    :value-should-be="progress.practiceOverlandsShould"
                    :progress-value="overlandsProgress"
                    :color="progressChipColor(progress.practiceOverlandsIs, progress.practiceOverlandsShould)"
                />
              </v-col>
              <v-col cols="12" :md="progress.practiceRequiredMTLsShould ? 3 : 4">
                <LessonTypeProgress
                    class="flex-grow-1"
                    :image="require('@/assets/img/lesson-types/highway.png')"
                    :title="$tc('lesson_type.highway')"
                    :value-is="progress.practiceHighwaysIs"
                    :value-should-be="progress.practiceHighwaysShould"
                    :progress-value="highwaysProgress"
                    :color="progressChipColor(progress.practiceHighwaysIs, progress.practiceHighwaysShould)"
                />
              </v-col>
              <v-col cols="12" :md="progress.practiceRequiredMTLsShould ? 3 : 4">
                <LessonTypeProgress
                    class="flex-grow-1"
                    :image="require('@/assets/img/lesson-types/night.png')"
                    :title="$tc('lesson_type.night')"
                    :value-is="progress.practiceNightsIs"
                    :value-should-be="progress.practiceNightsShould"
                    :progress-value="nightsProgress"
                    :color="progressChipColor(progress.practiceNightsIs, progress.practiceNightsShould)"
                />
              </v-col>

              <v-col cols="12" md="3" v-if="progress.practiceRequiredMTLsShould">
                <LessonTypeProgress
                    class="flex-grow-1"
                    :image="require('@/assets/img/lesson-types/mtl.png')"
                    :title="$tc('lesson_type.mtl')"
                    :value-is="progress.practiceRequiredMTLsIs"
                    :value-should-be="progress.practiceRequiredMTLsShould"
                    :progress-value="mtlProgress"
                    :color="progressChipColor(progress.practiceRequiredMTLsIs, progress.practiceRequiredMTLsShould)"
                />
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%;" class="d-flex align-center justify-center">
      <v-btn
        class="align-self-center"
        block
        x-small
        color="grey"
        elevation="0"
        text
        @click.stop="isOpen = !isOpen"
      >
        <v-icon>{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </div>
  </v-sheet>
</template>

<script>
import LicenseIcon from '@/components/LicenseIcon'
import LessonTypeProgress from "@/components/student/progress/LessonTypeProgress";
import LicenseModel from '@/store/models/LicenseModel'
export default {
  name: 'LicenseProgressCard',
  props: {
    studentId: {
      type: Number,
      required: true
    },
    progress: {
      type: Object,
      required: true
    },
  },
  components: {LessonTypeProgress, LicenseIcon },
  data () {
    return {
      isOpen: false,
    }
  },
  computed: {
    license() {
      return LicenseModel.find(this.progress.licenseId) || {}
    },
    theoryValueIs() {
      const { theoriesNormalsIs, theoriesNormalsShould, theoriesSpecialsIs, theoriesSpecialsShould } = this.progress
      return Math.min(theoriesNormalsIs, theoriesNormalsShould) + Math.min(theoriesSpecialsIs, theoriesSpecialsShould)
    },
    theoryValueShould() {
      return this.progress.theoriesNormalsShould + this.progress.theoriesSpecialsShould
    },
    practiceValueIs() {
      const {
        practiceOverlandsIs, practiceOverlandsShould,
        practiceHighwaysIs, practiceHighwaysShould,
        practiceNightsIs, practiceNightsShould
      } = this.progress
      return (
          Math.min(practiceOverlandsIs, practiceOverlandsShould) +
          Math.min(practiceHighwaysIs, practiceHighwaysShould) +
          Math.min(practiceNightsIs, practiceNightsShould)
      )
    },
    practiceValueShould() {
      const { practiceOverlandsShould, practiceHighwaysShould, practiceNightsShould } = this.progress
      return practiceOverlandsShould + practiceHighwaysShould + practiceNightsShould
    },
    instructionsProgress() {
      const isValue = Math.min(this.progress.practiceInstructionsIs, this.progress.practiceInstructionsShould)
      return Math.round((isValue / this.progress.practiceInstructionsShould) * 100)
    },
    normalsProgress() {
      const isValue = Math.min(this.progress.practiceNormalsIs, this.progress.practiceNormalsShould)
      return Math.round((isValue / this.progress.practiceNormalsShould) * 100)
    },
    overlandsProgress() {
      const isValue = Math.min(this.progress.practiceOverlandsIs, this.progress.practiceOverlandsShould)
      return Math.round((isValue / this.progress.practiceOverlandsShould) * 100)
    },
    highwaysProgress() {
      const isValue = Math.min(this.progress.practiceHighwaysIs, this.progress.practiceHighwaysShould)
      return Math.round((isValue / this.progress.practiceHighwaysShould) * 100)
    },
    nightsProgress() {
      const isValue = Math.min(this.progress.practiceNightsIs, this.progress.practiceNightsShould)
      return Math.round((isValue / this.progress.practiceNightsShould) * 100)
    },
    mtlProgress() {
      const isValue = Math.min(this.progress.practiceRequiredMTLsIs, this.progress.practiceRequiredMTLsShould)
      return Math.round((isValue / this.progress.practiceRequiredMTLsShould) * 100)
    },
  },
  methods: {
    progressChipColor(amount, requiredAmount) {
      if (!requiredAmount) return "primary";
      if (amount > 0 && amount < requiredAmount) return "orange";
      if (amount >= requiredAmount) return "green";

      return "red";
    }
  }
}
</script>

<style scoped>

</style>
