<template>
  <div>
    <ValidationProvider
      name="license"
      :rules="`${validation ? 'required' : ''}`"
      v-slot="{ errors }">
      <v-select
        class="license-select-ellipsis"
        :error="errors.length > 0"
        :disabled="disabled"
        v-model="licenseId"
        :label="label"
        :items="licensesList"
        item-value="id"
        :item-text="getLicenseLabel"
        @click:clear="$emit('emitLicenseClear');"
        :clearable="multiple"
        :multiple="multiple"
        outlined
        hide-details
        dense
        :append-icon="!disabled ? 'mdi-menu-down' : ''"
        :menu-props="{ offsetY: true }">

        <template v-if="multiple" v-slot:selection="{ index }">
          <span v-if="index === 0" class="text-overflow-ellipsis">{{ licensesLine }}</span>
        </template>

        <template v-else-if="licenseStatus" v-slot:selection="{ item }">
          {{ item.name }}
          <!-- <v-icon v-if="!getLicenseStatus(item.id)" class="ml-1" color="orange" small>mdi-alert</v-icon> -->
        </template>

        <template v-if="!multiple" v-slot:item="{ item }">
          <v-list-item-content style="display: block">
            <v-list-item-title class="d-flex justify-space-between align-center">
              <div style="width: 40px">{{ item.name }}</div>

              <!-- <div class="d-flex align-center" style="gap: 10px"> -->
                <!-- <div class="text--secondary" v-if="licenseStatus && !getLicenseStatus(item.id)">
                  {{ $t('label.missing_prices') }} <v-icon color="orange" small>mdi-alert</v-icon>
                </div> -->
              <!-- </div> -->

              <template v-if="showLicenseProgress">
                <v-progress-linear
                    v-if="licenseProgress[item.id]"
                    rounded
                    height="15px"
                    style="width: 100px;"
                    :value="licenseProgress[item.id].practiceProgress"
                    :color="progressColor(licenseProgress[item.id].practiceProgress)"
                    dark
                >
                  <span style="font-size: 0.6rem">{{ licenseProgress[item.id].practiceProgress }}</span>
                </v-progress-linear>
                <div class="d-flex text-right" v-else>
                  <v-progress-linear
                      indeterminate
                      rounded
                      color="grey"
                      height="15px"
                      style="width: 100px;"
                  />
                </div>
              </template>
            </v-list-item-title>
            <template v-if="showLicenseProgress ">
              <v-list-item-subtitle v-if="licenseProgress && licenseProgress[item.id]">
                <LicenseProgress class="mt-1" :license-progress="licenseProgress[item.id]" />
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else>{{ $t('progress_loading') }}</v-list-item-subtitle>
            </template>
          </v-list-item-content>
        </template>

      </v-select>
    </ValidationProvider>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import LicenseModel from '@/store/models/LicenseModel'
import LicenseProgress from '@/components/calendar/appointmentModal/fields/LicenseProgress'
import studentsService from '@/services/studentsService'

export default {
  name: "LicenseField",
  components: { LicenseProgress },
  data: () => ({
    licenseProgress: {},
  }),
  props: {
    label: {
      type: String,
      default: i18n.t('form.licence')
    },
    licenseIdsList: {
      type: Array,
      required: true,
    },
    licenseProp: {
      type: [Number, Array],
    },
    licenseStatus: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    validation: {
      type: Boolean,
      required: false,
    },
    multiple: {
      type: Boolean,
      required: true,
    },
    showLicenseProgress: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    if (this.licenseIdsList.length === 1 && !this.multiple) {
      this.$emit("emitLicenseSelect", this.licenseIdsList[0])
    }
  },
  computed: {
    licenseId: {
      set(value) {
        this.$emit("emitLicenseSelect", value);
      },
      get() {
        return this.licenseProp;
      },
    },
    licensesList() {
      return LicenseModel.query().whereIdIn(this.licenseIdsList).get() || [];
    },
    licensesLine() {
      if (!this.multiple) return;

      const availableLicenseIds = this.licensesList.map((item) => item.id);
      const mapped = this.licenseId
          .filter(id => availableLicenseIds.includes(id))
          .map((id) => LicenseModel.find(id).name);

      return mapped.join(", ") || "";
    },
  },
  methods: {
    loadProgressForStudent(studentId) {
      if(!studentId) return
      this.$emit('emitProgressLoader', true)
      this.licenseProgress = {}

      studentsService.studentProgress(studentId, { licenses: this.licenseIdsList, withFutureAppointments: true })
          .then((response) => {
            const progressData = {}
            response.data.forEach((item) => progressData[item.licenseId] = item)
            this.licenseProgress = progressData
            this.$emit('studentProgress', progressData)
          })
          .finally(() => this.$emit('emitProgressLoader', false))
    },
    getLicenseLabel(item) {
      return item.name;
    },
    getLicenseStatus(licenseId) {
      if (!this.licenseStatus || !this.licenseStatus.length) return
      return this.licenseStatus.find(item => item.id === licenseId)?.completed || false
    },

    progressColor(amount) {
      if (amount > 0 && amount < 100) return "orange";
      if (amount >= 100) return "green";
      return "red";
    }
  },
};
</script>

<style lang="scss" scoped>
.license-select-ellipsis {
  input[type="text"] {
    display: none !important;
  }
}
.flex-gap{
  gap: 5px;
}
</style>
