<template>
  <v-list-item>
    <v-list-item-content class="d-flex text-center my-1 my-md-4">
      <v-img
        class="mb-3"
        :width="width"
        :height="height"
        :contain="true"
        :src="imagePath"
        :alt="$t('noItems')" />
      <slot>
        <span class="body-2 text-center">{{ $t(text) }}</span>
      </slot>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "EmptyBox",
  props: {
    width: {
      default: 40,
    },
    height: {
      default: 40,
    },
    image: {
      default: "assets/svg/empty_box.svg",
    },
    text: {
      default: "noItems",
    },
  },
  computed: {
    imagePath() {
      return require(`@/${this.image}`);
    },
  },
};
</script>

<style scoped></style>
