<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <span class="subtitle-1">{{ $t('calendar.title') }}</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="relative">
      <SmallCalendar :loader="loaderGetter" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import SmallCalendar from "@/components/calendar/SmallCalendar";
import { mapGetters } from "vuex";

export default {
  name: "SidebarCalendar",
  components: { SmallCalendar },
  computed: {
    ...mapGetters(["loaderGetter"]),
  },
};
</script>

<style lang="scss" scoped>
.relative {
  position: relative;
}
</style>
