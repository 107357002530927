<template>
<v-dialog
    v-model="show"
    persistent
    max-width="500"
    @click:outside="close"
    @keydown.esc="close"
>
  <v-card :loading="loading">
    <v-card-title class="justify-space-between">
      {{ $t('label.request') }}
      <v-btn icon text @click="close"><v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>

    <v-divider/>

    <v-card-text>
      <ValidationObserver ref="form">
        <v-row class="mt-5">
          <v-col cols="12">
            <ValidationProvider
                name="license"
                rules="required"
                v-slot="{ errors }"
            >
              <v-select
                  v-model="form.licenseId"
                  @change="$emit('theoryStudentChange')"
                  :error="errors.length > 0"
                  :label="$t('form.licence')"
                  :placeholder="$t('form.licence')"
                  :items="licensesAsOptions"
                  item-value="id"
                  item-text="name"
                  dense
                  hide-details
                  outlined
                  :disabled="loading"
                  :menu-props="{ offsetY: true }"
              />
            </ValidationProvider>

            <v-checkbox
                v-if="form.licenseId === B197licenseId"
                v-model="form.isManualLesson"
                :disabled="loading"
                :label="$t('lesson_type.manual_lessons')"
                hide-details
            />
          </v-col>
        </v-row>

        <template v-if="teacher && teacher.studentCanCreatePractice">
          <v-row>
            <v-col cols="12">
              <ValidationProvider name="lessonTypeId" rules="required" v-slot="{ errors }">
                <v-select
                    dense
                    hide-details
                    outlined
                    v-model="form.lessonTypeId"
                    :error="errors.length > 0"
                    :label="$t('form.lesson_type')"
                    :placeholder="$t('form.lesson_type')"
                    :items="lessonTypes"
                    item-value="id"
                    item-text="name"
                    :disabled="loading"
                    :menu-props="{ offsetY: true }"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ValidationProvider name="meetingPlace" v-slot="{ errors }">
                <v-combobox
                    dense
                    outlined
                    hide-details
                    open-on-clear
                    @update:search-input="onPlaceSelect"
                    :error="errors.length > 0"
                    v-model="form.meetingPlace"
                    :disabled="loading"
                    :label="$t('form.meeting_place')"
                    :items="meetingPlacesAsOptions"
                    item-text="value"
                    item-value="value"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title v-if="data.item.name">{{ data.item.name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ data.item.address }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-combobox>
              </ValidationProvider>
            </v-col>
          </v-row>
        </template>
      </ValidationObserver>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn text @click="close">{{ $t("btn.cancel") }}</v-btn>
      <v-btn color="primary" @click="request" :disabled="loading || submitLoading" :loading="submitLoading">{{ $t("btn.book") }}</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import i18n from '@/plugins/i18n'

import StudentModel from '@/store/models/StudentModel'
import licensesService from '@/services/licensesService'
import AppointmentFields from '@/helpers/appointmentFields'
import appointmentService from '@/services/appointmentService'
import OfficeModel from '@/store/models/OfficeModel'
import MeetingPlaceModel from '@/store/models/MeetingPlaceModel'
import TeacherModel from '@/store/models/TeacherModel'
import LessonTypeModel from '@/store/models/LessonTypeModel'
import LicenseModel from '@/store/models/LicenseModel'

export default {
  name: 'AppointmentRequestDialog',
  data: () => ({
    show: false,
    loading: false,
    submitLoading: false,
    appointment: {},
    student: {},
    offices: [],
    meetingPlaces: [],
    licenses: [],
    form: {
      licenseId: null,
      isManualLesson: false,
      lessonTypeId: null,
      meetingPlace: null
    },
  }),
  computed: {
    licensesAsOptions() {
      const teacherLicenses = this.teacher.licensesIds || []
      const studentLicenses = this.student.licensesIds || []

      const teachersAndStudentsLicenses = teacherLicenses.filter(license => studentLicenses.includes(license))

      let licenses = this.licenses.filter(license => teachersAndStudentsLicenses.includes(license.id))

      const allowedLicenses = this.appointment?.allowedLicenses || []
      if (allowedLicenses.length > 0) {
        licenses = licenses.filter(license => allowedLicenses.includes(license.name))
      }

      return licenses
    },
    lessonTypes() {
      const practiceLessonTypes = LessonTypeModel.query().where('eventTypeId', 'PRACTICE').get() || []

      return practiceLessonTypes.map(lessonType => ({
        ...lessonType,
        name: this.$tc(lessonType.name, 1),
        disabled: false
      }))
    },
    meetingPlacesAsOptions() {
      return [
        ...this.createPlaceListGroup(i18n.t("label.school_offices"), OfficeModel.all() || []),
        ...this.createPlaceListGroup(i18n.t("label.meeting_place"), MeetingPlaceModel.all() || []),
        ...this.createPlaceListGroup(i18n.t("label.student_address"),[this.student]),
      ];
    },
    teacher() {
      return TeacherModel.find(this.appointment.teacherId)
    },
    B197licenseId () {
      return LicenseModel.query().where('name', 'B197').first()?.id
    }
  },
  created() {
    this.initialForm = { ...this.form };
  },
  methods: {
    async open (appointment, currentUser) {
      this.show = true
      this.loading = true

      this.appointment = appointment
      this.student = new StudentModel(currentUser)

      const licensesResponse = await licensesService.load()
      this.licenses = licensesResponse.data || []

      this.form.licenseId = this.licensesAsOptions[0].id
      this.form.meetingPlace = this.appointment.meetingPlace || this.meetingPlacesAsOptions[2].value

      this.loading = false
    },
    close () {
      this.show = false
      setTimeout(() => {
        this.appointment = {}
        this.student = {}
        this.submitLoading = false;
        this.form = { ...this.initialForm };
        this.$refs.form.reset();
      }, 200);
    },
    createPlaceListGroup(header, places = []) {
      return [
        { header: header },
        { divider: true },
        ...places.map((place) => ({
          name: place.name,
          address: place.address,
          value: place.name ? place.name + ", " + place.address : place.address,
        })),
      ];
    },
    onPlaceSelect (item) {
      this.form.meetingPlace = item
    },
    request () {
      const requestData = new AppointmentFields(this.appointment).formObject(this.appointment.type, 'request');

      requestData.licenseId = this.form.licenseId
      if (this.teacher.studentCanCreatePractice) {
        requestData.isManualLesson = this.form.isManualLesson
        requestData.lessonTypeId = this.form.lessonTypeId
        requestData.meetingPlace = this.form.meetingPlace
      }

      this.submitLoading = true;
      appointmentService
          .make_placeholder_request(this.appointment.id, requestData)
          .then(() => {
            this.$emit('requested')
            this.close()
          })
          .catch(error => {
            const responseData = error.response.data || {};
            const errors = responseData.errors || [];
            if (Object.keys(errors).length === 0 && responseData.message)
              this.$snackbar.show({
                message: responseData.message,
                color: "error",
              });
            this.$refs.form.setErrors(errors);
          })
          .finally(() => {
            this.submitLoading = false;
          });
    }
  }
}
</script>

<style scoped>

</style>
