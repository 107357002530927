<template>
  <v-dialog
    :value="show"
    width="1400"
    @click:outside="close"
    @keydown.esc="close"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    eager
    scrollable
  >
    <v-card loading>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>{{
          $t("label.create_or_edit_vehicle", {
            createOrEdit: isEdit ? $t("label.edit") : $t("label.create"),
          })
        }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="mt-5" style="height: 100%">
        <div :class="loadingItem ? 'd-flex' : 'd-none'" class="justify-center pt-5">
          <v-progress-circular color="primary" indeterminate />
        </div>

        <ValidationObserver v-show="!loadingItem" ref="form">
          <v-row class="mt-2">
            <v-col cols="12" md="3">
              <v-row dense>
                <v-col cols="12" class="d-flex justify-center mt-3">
                  <UploadBtn
                    input-name="photo"
                    @file-picked="onFilePick"
                    accept="image/jpeg,image/png"
                  >
                    <VehicleAvatar class="elevation-2" size="150" :contain="!avatarImage" :path="avatarImage" />
                  </UploadBtn>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="d-flex justify-center">
                  <ValidationProvider v-slot="{ errors }" name="color">
                    <v-sheet class="d-flex py-2 px-3" outlined rounded>
                      <span class="v-label mr-2">{{ $t('form.color') }}</span>

                      <div style="width: 150px">
                        <v-menu
                          class="mb-2"
                          v-model="colorMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <div class="btn__color-picker">
                              <div
                                style="
                                  padding: 7px;
                                  width: 100%;
                                  border-radius: 4px;
                                "
                                :style="{ 'background-color': form.color }"
                                v-bind="attrs"
                                v-on="on"
                              />
                            </div>
                            <span v-if="errors.length" class="red--text">{{ errors[0] }}</span>
                          </template>

                          <v-color-picker
                            v-model="form.color"
                            mode="hexa"
                            show-swatches
                            hide-canvas
                            hide-sliders
                          />
                        </v-menu>
                      </div>
                    </v-sheet>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12">
                  <ValidationProvider v-slot="{ errors }" name="vehicleId">
                    <v-text-field
                        outlined
                        dense
                        v-model="form.vehicleId"
                        :label="$t('form.vehicle_ID')"
                        :placeholder="$t('form.vehicle_ID')"
                        autocomplete="cc-csc"
                        :error-messages="errors"
                    />
                  </ValidationProvider>

                  <ValidationProvider v-if="!isEdit" v-slot="{ errors }" name="initialMileage">
                    <v-text-field
                        outlined
                        dense
                        v-model="form.initialMileage"
                        :label="$t('form.current_mileage')"
                        :placeholder="$t('form.current_mileage')"
                        autocomplete="cc-csc"
                        :error-messages="errors"
                    />
                  </ValidationProvider>

                  <ValidationProvider v-slot="{ errors }" name="vin">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.vin"
                      type="text"
                      :label="$t('form.vin_number')"
                      :placeholder="$t('form.vin_number')"
                      :error-messages="errors"
                    />
                  </ValidationProvider>

                  <ValidationProvider v-slot="{ errors }" name="hsn">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.hsn"
                      type="text"
                      :label="$t('form.hsn')"
                      :placeholder="$t('form.hsn')"
                      :error-messages="errors"
                    />
                  </ValidationProvider>

                  <ValidationProvider v-slot="{ errors }" name="tsn">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.tsn"
                      type="text"
                      :label="$t('form.tsn')"
                      :placeholder="$t('form.tsn')"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                  <ValidationProvider v-slot="{ errors }" name="type">
                    <v-text-field
                        outlined
                        dense
                        type="text"
                        v-model="form.type"
                        :label="$t('filters.type')"
                        :placeholder="$t('filters.type')"
                        :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="9">
              <v-tabs
                v-model="activeTab"
                :color="hasActiveTabErrors ? 'error' : 'primary'"
                :show-arrows="false"
                prev-icon=""
              >
                <v-tab href="#general">
                  <v-badge
                    :color="invalidTabFields['general'].length ? 'error' : 'primary'"
                    :content="invalidTabFields['general'].length"
                    :value="!!invalidTabFields['general'].length"
                    icon="mdi-check"
                  >
                    <span :class="{ 'error--text' :  invalidTabFields['general'].length}">
                      {{ $t("label.general_information") }}
                    </span>
                  </v-badge>
                </v-tab>

                <v-tab href="#fas">
                  <v-badge
                    :color="invalidTabFields['fas'].length ? 'error' : 'primary'"
                    :content="invalidTabFields['fas'].length"
                    :value="!!invalidTabFields['fas'].length"
                    icon="mdi-check"
                  >
                    <span :class="{ 'error--text' :  invalidTabFields['fas'].length}">
                      {{ $t("label.fas") }}
                    </span>
                  </v-badge>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="activeTab">
                <v-tab-item value="general">
                  <FieldsGroup :title="$t('label.general')">
                    <v-row dense>
                      <v-col cols="12" sm="6" lg="4">
                        <ValidationProvider v-slot="{ errors }" name="make">
                          <v-combobox
                            dense
                            outlined
                            v-model="form.make"
                            :items="makesItems"
                            :label="$t('form.make')"
                            :placeholder="$t('form.make')"
                            @keyup="onKeyupMake"
                            :error-messages="errors" />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="6" lg="4">
                        <ValidationProvider v-slot="{ errors }" name="model">
                          <v-text-field
                            dense
                            outlined
                            v-model="form.model"
                            :label="$t('form.model')"
                            :placeholder="$t('form.model')"
                            :error-messages="errors"
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="2" lg="1">
                        <ValidationProvider v-slot="{ errors }" name="year">
                          <v-text-field
                            outlined
                            dense
                            required
                            v-model="form.year"
                            :label="$tc('form.year')"
                            :placeholder="$tc('form.year')"
                            :error-messages="errors"
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="10" lg="3">
                        <ValidationProvider v-slot="{ errors }" name="licensePlate">
                          <v-text-field
                            outlined
                            dense
                            v-model="form.licensePlate"
                            :label="$t('label.license_plate')"
                            :placeholder="$t('label.license_plate')"
                            required
                            :error-messages="errors"
                          >
                          </v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" md="4">
                        <v-select
                          type="string"
                          v-model="form.gearboxType"
                          :items="gearboxTypesItems"
                          :label="$t('form.transmission_type')"
                          :placeholder="$t('form.transmission_type')"
                          dense
                          outlined
                        />
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-select
                          type="string"
                          v-model="form.fuelType"
                          :items="fuelTypesItems"
                          :label="$t('form.fuel_type')"
                          :placeholder="$t('form.fuel_type')"
                          dense
                          outlined
                        />
                      </v-col>
                      <v-col cols="12" md="4">
                        <ValidationProvider v-slot="{ errors }" name="servicePeriod">
                          <v-text-field
                            outlined
                            dense
                            v-model="form.servicePeriod"
                            type="number"
                            step="1000"
                            min="0"
                            :label="$t('form.service_period')"
                            :placeholder="$t('form.service_period')"
                            :error-messages="errors"
                          />
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </FieldsGroup>

                  <FieldsGroup :title="$t('label.school')">
                    <v-row dense>
                      <v-col cols="12">
                        <v-select
                          dense
                          multiple
                          outlined
                          :items="selectTeachers"
                          :label="$t('label.teacher')"
                          :placeholder="$t('label.teacher')"
                          v-model="form.teachers" />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" md="9">
                        <v-row no-gutters>
                          <v-col cols="12">
                            <ValidationProvider v-slot="{ errors }" name="licenses">
                              <v-select
                                dense
                                outlined
                                :items="licenseGroups"
                                v-model="form.licensesGroup"
                                item-value="id"
                                item-text="name"
                                :label="$t('label.license_group')"
                                :placeholder="$t('label.license_group')"
                                :error-messages="errors"
                              />
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="2" v-for="license in licenses" :key="license.id" class="mt-0">
                            <v-checkbox
                              class="mt-0 mr-3"
                              v-model="form.licenses"
                              :label="license.name"
                              :value="license.id"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          outlined
                          dense
                          v-model="form.priceModifier"
                          :label="$t('label.price_modifier')"
                          placeholder="0,00"
                          required
                          append-icon="mdi-currency-eur"
                          oninput="if(!this.value) this.value = 0;"
                          v-currency="{
                        currency: null,
                        allowNegative: false,
                        valueRange: { min: 0.0 },
                      }"
                        />
                      </v-col>
                    </v-row>
                  </FieldsGroup>
                </v-tab-item>
                <v-tab-item value="fas">
                  <v-row dense>
                    <v-col v-for="assistant in driverAssistants" :key="assistant.id" cols="12" sm="6" >
                      <v-checkbox
                        v-model="form.driverAssistants"
                        :label="$t(assistant.translation_key_name)"
                        :value="assistant.id"
                        :hint="$t(assistant.translation_key_description)"
                        persistent-hint
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>
      <v-divider class="mt-auto" />
      <v-card-actions v-if="!loadingItem">
        <v-btn text @click="close">{{ $t("btn.close") }}</v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          @click="save"
          :loading="loadingForm"
          :disabled="loadingForm || loadingItem"
        >
          <v-icon left>mdi-content-save{{isEdit ? '-edit' : ''}}</v-icon>
          {{ $t("btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import vehiclesService from "@/services/vehiclesService";
import UploadBtn from "@/components/UploadBtn";
import TeacherModel from "@/store/models/TeacherModel";
import currency from "@/utils/mixins/currency";
import FieldsGroup from '@/components/form/FieldsGroup.vue'
import VehicleAvatar from '@/components/VehicleAvatar.vue'
import VehicleModel from "@/store/models/VehicleModel";

export default {
  name: "VehicleFormDialog",
  mixins: [currency],
  components: {
    VehicleAvatar,
    FieldsGroup,
    UploadBtn,
  },
  data: () => {
    return {
      show: false,
      isEdit: false,
      item: null,
      activeTab: "",
      loadingItem: false,
      loadingForm: false,
      manufacturers: [],
      licenseGroups: [],
      avatarImage: "",
      colorMenu: false,
      fuelTypes: [],
      gearboxTypes: [],
      driverAssistants: [],
      form: {
        vehicleId: null,
        photo: "",
        make: "",
        color: "#ff0000",
        model: "",
        year: "",
        fuelType: null,
        gearboxType: null,
        licensePlate: "",
        priceModifier: 0,
        licensesGroup: null,
        licenses: [],
        teachers: [],
        driverAssistants: [],
        initialMileage: null,
        servicePeriod: null,
        vin: null,
        hsn: null,
        tsn: null,
        type: ""
      },
      formObserver: { errors: {} }
    };
  },
  created() {
    this.initialForm = { ...this.form };
  },
  methods: {
    open(item) {
      this.show = true;

      this.loadFormData();

      if (item) {
        this.isEdit = true;
        this.loadingItem = true;
        vehiclesService
          .loadItem(item.id)
          .then((resp) => {
            const data = resp.data;
            this.item = resp.data;

            this.avatarImage = data.photo;

            this.form.make = data.make;
            this.form.model = data.model;
            this.form.color = data.color;
            this.form.year = data.year;
            this.form.licensePlate = data.licensePlate;
            this.form.gearboxType = data.gearboxType;
            this.form.fuelType = data.fuelType;
            this.form.vehicleId = data.vehicleId;
            this.form.servicePeriod = data.servicePeriod;
            this.form.priceModifier = this.sanitizePrice(data.priceModifier, ",");
            this.form.vin = data.vin;
            this.form.hsn = data.hsn;
            this.form.tsn = data.tsn;
            this.form.type = data.type;
            this.form.licenses = data.licensesIds || [];
            this.form.teachers = data.teachersIds || [];
            this.form.driverAssistants = data.driverAssistantsIds || [];

            const licenseGroup = this.licenseGroups.find((licenseGroup) => {
              return licenseGroup.licenses.find((license) => this.form.licenses.indexOf(license.id) !== -1);
            }) || {};

            if (licenseGroup) {
              this.form.licensesGroup = licenseGroup.id;
            }
          })
          .finally(() => {
            this.loadingItem = false;
          });
      }
    },
    close() {
      this.show = false;

      setTimeout(() => {
        this.isEdit = false;
        this.form = { ...this.initialForm };
        this.avatarImage = "";
        this.item = null;
        this.licensesGroup = "";
        this.$refs.form.reset()
      }, 200);
    },
    loadFormData() {
      vehiclesService
        .loadFormData()
        .then((resp) => {
          this.manufacturers = resp.data.manufacturers || [];
          this.fuelTypes = resp.data.fuelTypes || [];
          this.gearboxTypes = resp.data.gearboxTypes || [];
          this.driverAssistants = resp.data.driverAssistants || [];

          this.licenseGroups = (resp.data.licenseGroups || [])
              .filter(licenseGroup => licenseGroup.licenses.filter(license => license.forPractice).length)
        })
    },
    save() {
      const formData = new FormData();
      setTimeout(() => {
        Object.keys(this.form).forEach((field) => {
          let value = this.form[field];

          if (!value) return;

          if (field === "priceModifier") {
            value = this.sanitizePrice(this.form.priceModifier, ".");
          }

          if (Array.isArray(value)) {
            value.map((item) => formData.append(field + "[]", item));
          } else {
            formData.set(field, value);
          }
        });

        if (this.item) {
          formData.set("_method", "PUT");
        }

        this.loadingForm = true;
        this[this.isEdit ? "update" : "store"](formData)
          .catch((error) => {
            const responseData = error.response.data;
            const errors = responseData.errors || [];

            if (Object.keys(errors).length === 0 && responseData.message) {
              this.$snackbar.show({ message: responseData.message, color: "error" });
            }

            const formErrors = {};
            Object.keys(this.form).map((field) => {
              if (!(field in formErrors)) {
                if (errors[field]) formErrors[field] = errors[field];
                const arrayErrorKey = Object.keys(errors).find(item => item.indexOf(field + ".") !== -1);
                if (!formErrors[field] && arrayErrorKey) formErrors[field] = errors[arrayErrorKey];
              }
            });
            this.$refs.form.setErrors(formErrors);
          })
          .finally(() => {
            this.loadingForm = false;
          })
      }, 0);
    },
    store(data) {
      return vehiclesService
        .create(data)
        .then((resp) => {
          this.$emit("create", resp.data);
          this.close();
        });
    },
    update(data) {
      return vehiclesService
        .update(this.item.id, data)
        .then((resp) => {
          VehicleModel.update({
            where: this.item.id,
            data: {
            ...resp.data
            }
          })
          this.$emit("update", resp.data);
          this.close();
        })
    },
    onFilePick(url, file) {
      this.avatarImage = url;
      this.form.photo = file;
    },
    onKeyupMake() {
      this.$set(this.form, "model", "");
    },
  },
  computed: {
    invalidTabFields() {
      const tabsFields = {
        general: [
          "make",
          "model",
          "year",
          "color",
          "licensePlate",
          "priceModifier",
          "licensesGroup",
          "licenses",
          "teachers",
          "fuelType",
          "gearboxType",
          "servicePeriod"
        ],
        fas: []
      };

      Object.keys(tabsFields).forEach((tab) => {
        tabsFields[tab] = tabsFields[tab].filter((field) => {
          return this.formObserver.errors[field]?.length > 0;
        });
      });

      return tabsFields;
    },
    hasActiveTabErrors() {
      return (this.invalidTabFields[this.activeTab] || []).length > 0;
    },
    gearboxTypesItems() {
      return this.gearboxTypes.map((item) => {
        return {
          text: this.$t(`gearboxType.${item}`),
          value: item
        }
      });
    },
    fuelTypesItems() {
      return this.fuelTypes.map((item) => {
        return {
          text: this.$t(`fuelType.${item}`),
          value: item
        }
      });
    },
    licenses() {
      const licensesGroup = this.licenseGroups
          .find((licenseGroup) => licenseGroup.id === this.form.licensesGroup) || {};

      return (licensesGroup.licenses || []).filter(license => license.forPractice)
    },
    makesItems() {
      return this.manufacturers.map((item) => item.make);
    },
    modelsItems() {
      const selectedMake = this.manufacturers.filter((item) => item.make === this.form.make);
      return selectedMake.length === 1 ? selectedMake[0].models : [];
    },
    selectTeachers() {
      return TeacherModel.query()
        .get()
        .map((teacher) => ({
          text: teacher.fullName,
          value: teacher.id,
        }));
    },
  },
  mounted() {
    this.formObserver = this.$refs.form;
  }
};
</script>

<style scoped></style>
