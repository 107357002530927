<template>
  <v-btn
    class="mr-3"
    text
    @click.left="$emit('buttonClick')"
    :color="color"
    :disabled="disabled">
    {{ label }}
  </v-btn>
</template>

<script>
export default {
  name: "ModalActionButton",
  props: {
    label: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped></style>
