<script>
import ReusableDialog from "@/components/ReusableDialog.vue";

export default {
  name: "SendChatMessageDialog",
  components: {ReusableDialog},
  inject: ["closeDialog", "openDialog"],
  data () {
    return {
      studentsIds: []
    }
  },
  methods: {
    open(studentsIds) {
      this.studentsIds = studentsIds
      this.openDialog()
    }
  }
}
</script>

<template>
  <reusable-dialog width="900">
    <template v-slot:trigger>
      <v-btn @click="open">Open me</v-btn>
    </template>
    <template v-slot:title>
      Sent a message
    </template>
    <v-textarea  />

    <template v-slot:actions-end >
      <v-btn>Send</v-btn>
    </template>
  </reusable-dialog>
</template>
