<template>
<v-tooltip
  right
  transition="0"
  :value="false"
  :disabled="show"
>
  <template v-slot:activator="{ on, attrs }">
    <v-list-item
      v-bind="attrs"
      v-on="on"
      :class="{...listClass, 'disabled': disabled}"
      :to="to"
      @click="click"
      :disabled="disabled"
      :dense="dense"
      :value="value"
      :input-value="inputValue"
    >
      <slot/>
    </v-list-item>
  </template>
  <span>{{ tooltip }}</span>
</v-tooltip>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SidebarListItem',
  props: {
    to: {
      type: [String, Object],
      default: undefined
    },
    click: {
      type: Function,
      default: () => {}
    },
    value: {
      default: undefined
    },
    inputValue: {
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: ''
    },
    listClass: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState("main", ["isSidebarMinimized"]),
    show() {
      return !this.$vuetify.breakpoint.mobile && !this.isSidebarMinimized || !this.tooltip.length > 0
    }
  }
}
</script>

<style scoped lang="scss">
</style>