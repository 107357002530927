<template>
  <v-dialog v-model="show" persistent width="600">
    <v-card>
      <v-toolbar class="mb-2" dark color="primary" dense>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-radio-group v-model="pickedMethod" mandatory @change="resetRadios">
          <v-card
            v-if="hasSetDateField"
            class="pa-2 my-2"
            :disabled="hasBeenSet"
            elevation="0"
            outlined
            rounded
          >
            <v-radio :label="optionsLabels.manual" value="manual_assignment" class="mb-5"/>

            <div>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :disabled="pickedMethod !== 'manual_assignment'"
                    v-model="formattedDate"
                    :label="$t('date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  :key="menu"
                  v-model="date"
                  min="1950-01-01"
                  @change="save"
                />
              </v-menu>
            </div>
          </v-card>

          <v-card
            :disabled="hasBeenSet"
            elevation="0"
            outlined
            rounded
            class="pa-2 my-2"
          >
            <v-radio :label="optionsLabels.event" value="event_assignment" class="mb-5" />

            <div class="scroll-box" v-if="events.length">
              <v-card
                hover
                v-for="event in formattedEvents"
                :key="event.id"
                class="my-2 pa-2 d-flex justify-space-between exam"
                :disabled="pickedMethod !== 'event_assignment' && type !== 'practice_exam'"
                @click="pickExam(event)"
              >

                <div>
                  {{ event.format_date }}

                  <span v-if="event.lessonType">- {{$tc(event.lessonType.name, 1)}}</span>

                  {{ `(${appendix})` }}
                </div>

                <v-icon v-if="pickedExam === event.id" color="secondary" size="18">
                  mdi-checkbox-marked-circle-outline
                </v-icon>
              </v-card>
            </div>

            <EmptyBox v-else text="calendar.no_active_events" />
          </v-card>
        </v-radio-group>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer/>

        <v-btn v-if="hasBeenSet" color="primary" text @click="clearStatus">
          {{ $t("btn.clear") }}
        </v-btn>

        <v-btn text color="primary" :disabled="!readyToSubmit || hasBeenSet" @click="submitExam">
          {{ $t("btn.ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { addMinutes, format, parseISO } from 'date-fns'
import studentsService from "@/services/studentsService";
import EmptyBox from "@/components/EmptyBox";

export default {
  name: "EducationExamDialog",
  components: { EmptyBox },
  data() {
    return {
      show: false,
      pickedExam: null,
      pickedMethod: "manual_assignment",
      activePicker: null,
      date: null,
      menu: false,
      form: {
        eventId: null,
        passedDate: null,
      },
      readyToSubmit: false,
    };
  },
  props: {
    studentId: {
      type: [Number, String],
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
    licenseId: {
      type: [Number, String],
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    finishDate: {
      type: String,
    },
    progress: {
      type: Object,
    }
  },
  computed: {
    formattedEvents() {
      return this.events.map((event) => {
        return {
          ...event,
          lessonType: event.lessonType,
          format_date: `${event.formattedDateTime} - ${format(addMinutes(parseISO(event.start), event.duration), 'HH:mm')}`,
        };
      });
    },
    hasBeenSet() {
      return !!this.finishDate;
    },
    isTheoryType() {
      return this.type === "theory_exam" || this.type === "theory_education"
    },
    appendix() {
      return this.isTheoryType ? this.$t("label.theory") : this.$t("label.practice");
    },
    formattedDate() {
      return this.date ? format(parseISO(this.date), "dd.MM.yyyy") : "";
    },
    optionsLabels() {
      if (this.type.includes("_exam")) {
        return {
          manual: this.$t("label.exam_manual_option"),
          event: this.$t("label.exam_event_option"),
        };
      }

      return {
        manual: this.$t("label.education_manual_option"),
        event: this.$t("label.education_event_option"),
      };
    },
    title() {
      let title = this.$t("label.mtl_exam_status");

      if (this.type !== "mtl_exam") {
        title = `${this.appendix}  ${this.type.includes("_exam") ? this.$t("label.exam_status") : this.$t("label.end_of_education")}`;
      }

      return title;
    },
    hasSetDateField() {
      return !["practice_exam", "mtl_exam"].includes(this.type);
    }
  },
  watch: {
    finishDate(val) {
      if (val) this.setExamDataByType();
    },
    show(visible) {
      if (!visible) {
        this.pickedExam = null;
        this.pickedMethod = "manual_assignment";
        this.activePicker = null;
        this.date = null;
        this.menu = false;

        this.form.eventId = null;
        this.form.date = null;
        this.form.readyToSubmit = false;
      }
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
      this.readyToSubmit = true;
      this.form.passedDate = `${date} 00:00:00`;
    },
    pickExam(exam) {
      this.pickedExam = this.pickedExam !== exam.id ? exam.id : null;
      this.readyToSubmit = this.pickedExam;
      this.form.eventId = exam.id;
      this.form.passedDate = exam.start;
    },
    resetRadios() {
      this.pickedExam = null;
      this.date = null;
      this.readyToSubmit = false;
      this.form.eventId = null;
      this.form.passedDate = null;
   },
    submitExam() {
      const params = {
        educationType: this.type,
        licenseId: this.licenseId,
        eventId: this.form.eventId,
        passedAt: this.form.passedDate,
      };
      studentsService
        .studentExamResults(this.studentId, params)
        .then((resp) => (this.$emit('emitSubmit', resp.data || {})))
        .finally(this.close);
    },
    clearStatus() {
      const params = { educationType: this.type, licenseId: this.licenseId };

      studentsService
        .resetEducationStatus(this.studentId, params)
        .then((resp) => (this.$emit('emitClear', resp.data || {})))
        .finally(this.close);
    },
    setExamDataByType() {
      const typeProgressProperties = {
        theory_exam: {
          examEventId: this.progress.theoryExamEventId,
          passedAt: this.progress.theoryExamPassedAt
        },
        mtl_exam: {
          examEventId: this.progress.mtlExamEventId,
          passedAt: this.progress.mtlExamPassedAt
        },
        practice_exam: {
          examEventId: this.progress.practiceExamEventId,
          passedAt: this.progress.practiceExamPassedAt
        },
        theory_education: {
          examEventId: this.progress.theoryEducationEventId,
          passedAt: this.progress.theoryEducationEndedAt
        },
        practice_education: {
          examEventId: this.progress.practiceEducationEventId,
          passedAt: this.progress.practiceEducationEndedAt
        }
      };

      this.pickedExam = typeProgressProperties[this.type].examEventId;
      this.pickedMethod = typeProgressProperties[this.type].examEventId
        ? "event_assignment"
        : "manual_assignment";
      this.date = !typeProgressProperties[this.type].examEventId
        ? format(parseISO(typeProgressProperties[this.type].passedAt), "yyyy-MM-dd")
        : null;
    },
    open() {
      this.show = true;
    },
    close() {
      this.$emit("close");
      this.show = false;
      this.form.eventId = null;
      this.form.passedDate = null;
      this.readyToSubmit = false;
    },
  },
};
</script>

<style scoped>
.scroll-box{
  max-height: 465px;
  overflow-y: auto;
}
.exam {
  border-left: 8px solid #76ab26;
}
.disabled > * {
  filter: grayscale(100);
}
</style>
