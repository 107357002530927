<template>
  <div class="calendar-mobile">
    <SidebarFilters />

    <NavbarCalendarMobile
      class="flex-grow-0"
      :gridView="gridView"
      @toggle-view="toggleView"
      ref="navBar"
    />

    <CalendarGrid v-if="gridView" id="gridView" class="pa-0 ma-0 flex-grow-1" />

    <v-container v-else id="mobileCalendarLayout" class="px-0 ma-0 flex-grow-1" fluid>
      <SmallCalendar :loader="loaderGetter" />
      <div id="eventsArea" class="pl-3 pr-3">
        <div v-if="loaderGetter">{{ $t("loading") }}</div>
        <div v-else-if="Object.keys(mobileEvents).length">
          <div class="group mb-1" v-for="(group, index) in mobileEvents" :key="index">
            <span class="custom-overline">{{ $t(`label.${index}`) }}</span>
            <div v-if="true">
              <AppointmentMobile v-for="event in group" :event="event" :key="event.id" />
            </div>
          </div>
        </div>
        <div v-else>{{ $t("calendar.no_active_events") }}</div>
      </div>
    </v-container>

    <StudentAppointmentModal v-if="isStudent" :currentAppointment="this.currentAppointmentGetter" />
    <AppointmentModal v-else :currentAppointment="this.currentAppointmentGetter" />

    <v-dialog
      eager
      v-model="dateDialog"
      v-if="!isElter && ((!gridView && !studentCanOnlyBook && !isPast) || (isPast && !isStudent))"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          class="mr-2"
          small
          fixed
          right
          fab
          rigth
          bottom
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-date-picker
        :value="activeDate"
        first-day-of-week="1"
        full-width
        no-title
        :allowed-dates="this.isStudent ? allowedDates : null"
        @change="goSelectInterval"
        @update:picker-date="monthSelected"
        event-color="primary"
      />
    </v-dialog>

    <v-dialog v-model="timeDialog" scrollable>
      <v-card>
        <v-card-title class="text-center" style="display: block">
          <v-btn
              color="primary"
              absolute
              left
              x-small
              fab
              @click="backToDate"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>

          {{ $t("calendar.select_time_interval") }}
        </v-card-title>

        <v-card-text align="center">
          <v-btn-toggle
            color="primary"
            mandatory
            dense
            @change="changeDate"
            v-if="gridView && this.days.length === 3">
            <v-btn
              style="font-size: 0.8rem"
              text
              v-for="(day, index) in daysRange"
              :key="index"
              :value="day">
              {{ day }}
            </v-btn>
          </v-btn-toggle>

          <v-list dense align="center">
            <v-list-item-group>
              <template v-for="(interval, index) in activeIntervals">
                <v-list-item :key="index" @click="goSelectLesson(interval.startTime)">
                  <v-list-item-content>
                    <v-list-item-title>{{`${interval.start} - ${interval.end}`}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="typeDialog" scrollable>
      <v-card>
        <v-card-title class="text-center" style="display: block">
          <v-btn
              color="primary"
              absolute
              left
              x-small
              fab
              @click="backToInterval"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ $t("calendar.select_lesson_type") }}
        </v-card-title>

        <v-card-text>
          <v-list dense align="center">
            <v-list-item-group>
              <template v-for="(type, i) in eventTypes">
                <v-list-item
                  v-if="
                    (!holiday ||
                      (holiday &&
                        (type.id === 'OFFTIME' || type.id === 'SPECIAL'))) &&
                    (!isPastPicker || (isPastPicker && type.id === 'OFFTIME'))
                  "
                  :key="i"
                  @click="showModal(type.id)">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t(`event_type.${type.id}`).toUpperCase()
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import user from "@/utils/mixins/user"
import eventsOverlapMixins from "@/utils/mixins/eventsOverlapMixins"
import { mapActions, mapGetters } from "vuex"
import {
  addDays,
  addMinutes,
  differenceInMinutes,
  format,
  parseISO,
  toDate,
} from "date-fns"
import AppointmentModel from "@/store/models/AppointmentModel"
import TeacherModel from "@/store/models/TeacherModel"
import EventTypeModel from "@/store/models/EventTypeModel"
import LessonTypeModel from "@/store/models/LessonTypeModel"

import SmallCalendar from "@/components/calendar/SmallCalendar"
import NavbarCalendarMobile from "@/components/NavbarCalendarMobile"
import SidebarFilters from "@/components/SidebarFilters"
import CalendarGrid from "@/components/calendar/CalendarGrid"
import AppointmentMobile from "@/components/calendar/AppointmentMobile"
import AppointmentModal from "@/components/calendar/appointmentModal/AppointmentModal"
import StudentAppointmentModal from "@/components/calendar/appointmentModal/StudentAppointmentModal"
import responsive from "@/utils/mixins/responsive"
import loadAppointments from "@/utils/mixins/loadAppointments"
import DateRange from "@/helpers/dateRange"

export default {
  name: "CalendarMobile",
  mixins: [user, responsive, eventsOverlapMixins, loadAppointments],
  data() {
    return {
      daysRange: [],
      gridView: false,
      dateDialog: false,
      timeDialog: false,
      typeDialog: false,
      pickedDate: null,
      pickedInterval: null,
      selectedDay: "",
    }
  },
  components: {
    SmallCalendar,
    CalendarGrid,
    NavbarCalendarMobile,
    SidebarFilters,
    AppointmentMobile,
    AppointmentModal,
    StudentAppointmentModal,
  },
  created() {
    this.selectedDay = this.pickedDate = this.activeDate
  },
  watch: {
    timeDialog(newCount) {
      if (newCount) {
        this.daysRange = [
          this.activeDate,
          format(addDays(parseISO(this.activeDate), 1), "yyyy-MM-dd"),
          format(addDays(parseISO(this.activeDate), 2), "yyyy-MM-dd"),
        ]
      } else {
        this.daysRange = []
      }
    },
  },
  computed: {
    ...mapGetters(["activeDate", "hours", "days", "loaderGetter"]),
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("appointments", [
      "currentAppointmentGetter",
      "activeEventsGetter",
      "newEventModelGetter",
    ]),
    ...mapGetters("teachers", {
      activeTeachers: "active",
      activeTeachersIds: "activeIds",
    }),
    isPastPicker() {
      /*commented past exam logic*/
      // return !this.schoolSettings.pastEventsAllowed
      //     && format(parseISO(this.pickedDay), "yyyy-MM-dd") < format(new Date(), "yyyy-MM-dd")
      return false
    },
    isPast() {
      /*commented past exam logic*/
      // return !this.schoolSettings.pastEventsAllowed
      //     && format(parseISO(this.activeDate), "yyyy-MM-dd") < format(new Date(), "yyyy-MM-dd")
      return false
    },
    studentCanOnlyBook() {
      let status = false
      if (this.isStudent) {
        const teachers =
          TeacherModel.query()
            .where("active", true)
            .whereIdIn(this.currentUser.teachersIds)
            .get() || {}
        status = teachers.every((teacher) => teacher.onlyPlaceholdersBooking)
      }
      return status
    },
    allowedDates() {
      let regions = ["BUND"]
      if (this.schoolSettings.regions)
        regions = this.schoolSettings.regions.split(", ")
      return (val) => !new DateRange().isHoliday(parseISO(val), regions)
    },
    holiday() {
      let regions = ["BUND"]
      if (this.schoolSettings.regions)
        regions = this.schoolSettings.regions.split(", ")
      return new DateRange().isHoliday(parseISO(this.activeDate), regions)
    },
    pickedDay() {
      return this.gridView && this.days.length === 3
        ? this.selectedDay
        : this.activeDate
    },
    eventTypes() {
      const allTypes = EventTypeModel.all() || {}
      return this.isSchoolAdministrator
        ? allTypes
        : allTypes.filter((type) => type.id !== "SIMULATOR")
    },
    practiceTypes() {
      return LessonTypeModel.query().where("eventTypeId", "PRACTICE").get()
    },
    theoryTypes() {
      return LessonTypeModel.query().where("eventTypeId", "THEORY").get()
    },
    teachers() {
      return TeacherModel.query().where("active", true).get()
    },
    currentDayEvents() {
      const eventsQuery = AppointmentModel.query().where("startDate", this.pickedDay).with('teacher')
      if (this.isSchoolAdministrator) {
        eventsQuery.where("teacherId", (teacherId) => this.activeTeachersIds.includes(teacherId) || teacherId === null)
      }
      if (this.isTeacher) {
        eventsQuery.where("teacherId", [this.currentUser.id])
      }
      if (this.isStudent) {
        const foreignTeachers = TeacherModel.all().filter((item) => !this.currentUser.teachersIds.includes(item.id))
        const foreignTeachersIds = foreignTeachers.map((teacher) => teacher.id)
        eventsQuery.where((event) => this.activeTeachersIds.includes(event.teacherId) ||
            foreignTeachersIds.includes(event.teacherId) ||
            event.teacherId === null)
            .orWhere('eventType', AppointmentModel.TYPES.THEORY)
      }
      return eventsQuery.where("type", this.activeEventsGetter).orderBy("start", "asc").get()
    },

    filteredSchoolTeacherAppointments() {
      const requested = this.currentDayEvents.filter(event => event.isRequested || event.status === 'REQUESTED')
      const otherApps = this.currentDayEvents.filter(event => !event.isRequested && event.status !== 'REQUESTED')

      let filteredApps = {}
      if (requested.length) filteredApps = { ...filteredApps, ...{ "requested_appointments": requested } }
      if (otherApps.length) filteredApps = { ...filteredApps, ...{ "other_appointments": otherApps } }
      return filteredApps
    },

    filteredStudentAppointments() {
      const events = this.currentDayEvents.filter(
        (event) => (event.studentsIds.includes(this.currentUser.id) && event.group !== 'planned_exam') ||
            ["PLACEHOLDER", "THEORY", "SIMULATOR"].includes(event.type) ||
            (event.type === 'THEORY' && event.group === 'exam')
      )
      const requested = events.filter(event => event.isRequested || event.status === 'REQUESTED')
      const myApps = events.filter(event => (
          (event.studentsIds.includes(this.currentUser.id) || event.isSubscribed) && event.status !== 'REQUESTED'
      ))
      const otherApps = events.filter((event) => (
          event.type === 'PLACEHOLDER' && !event.isRequested ||
          (['THEORY', 'SIMULATOR'].includes(event.type) && !event.isSubscribed)
      ))

      let filteredApps = {}
      if (requested.length) filteredApps = { ...filteredApps, ...{ "requested_appointments": requested } }
      if (myApps.length) filteredApps = { ...filteredApps, ...{ "my_appointments": myApps } }
      if (otherApps.length) filteredApps = { ...filteredApps, ...{ "other_appointments": otherApps } }

      return filteredApps
    },
    mobileEvents() {
      return this.isStudent ? this.filteredStudentAppointments : this.filteredSchoolTeacherAppointments
    },
    activeIntervals() {
      let baseInterval = {
        start: parseISO(`${this.pickedDay} ${this.hours[0]}`),
        end: addMinutes(parseISO(`${this.pickedDay} ${this.hours[this.hours.length - 1]}`), 60),
      }
      let finalData = []
      const freeTimeIntervals = []

      if (this.currentDayEvents.length) {
        const busyIntervals = this.busyTimeIntervals(this.currentDayEvents)
        busyIntervals.map((event) => {
          const difference = differenceInMinutes(event.start, baseInterval.start)
          freeTimeIntervals.push({ start: baseInterval.start, end: addMinutes(baseInterval.start, difference), })
          baseInterval = { start: event.end, end: baseInterval.end }
        })
        freeTimeIntervals.push(baseInterval)
        finalData = this.formatIntervals(freeTimeIntervals)
      } else {
        finalData = [
          {
            startTime: baseInterval.start,
            start: format(baseInterval.start, "HH:mm"),
            end: format(baseInterval.end, "HH:mm") !== '00:00' ? format(baseInterval.end, "HH:mm") : '23:59',
          },
        ]
      }
      return finalData
    },
  },
  methods: {
    ...mapActions(["setCalendarDate"]),
    ...mapActions("appointments", ["showAppointmentModal",]),
    filterEventsByTeachersStatus(events) {
      return events.filter(
        (event) =>
          !event.teacher.onlyPlaceholdersBooking ||
          event.type === "PLACEHOLDER" ||
          event.type === "THEORY" ||
          (event.teacher.onlyPlaceholdersBooking &&
            event.type !== "OFFTIME" &&
            event.studentsIds.includes(this.currentUser.id))
      )
    },
    changeDate(date) {
      if (this.timeDialog) this.selectedDay = date
    },
    toggleView(params) {
      this.gridView = params
    },
    formatIntervals(intervals) {
      const formattedData = []
      intervals.map((interval) => {
        if (!differenceInMinutes(interval.end, interval.start)) return
        formattedData.push({
          startTime: interval.start,
          start: format(interval.start, "HH:mm"),
          end: format(interval.end, "HH:mm") !== '00:00' ? format(interval.end, "HH:mm") : '23:59',
        })
      })
      return formattedData
    },
    busyTimeIntervals(events) {
      const thisDayEventsArrays = this.groupOverlappingEventElements(events)
      const arr = []
      const finalBlockedArr = []
      thisDayEventsArrays.map((blocks) => {
        if (blocks.length === 1) {
          const bigOne = []
          blocks.map((singleBlock) => {
            bigOne.push({ start: parseISO(singleBlock[0].start), end: singleBlock[0].endDate, })
          })
          arr.push(bigOne)
        } else {
          const bigArray = []
          blocks.map((group) => {
            group.map((item) => {
              bigArray.push({ start: parseISO(item.start), end: item.endDate })
            })
          })
          arr.push(bigArray)
        }
      })
      arr.map((sub) => {
        if (sub.length > 1) {
          const getStart = Math.min.apply(
            Math,
            sub.map((date) => {
              return date.start
            })
          )
          const getEnd = Math.max.apply(
            Math,
            sub.map((date) => {
              return date.end
            })
          )
          finalBlockedArr.push({
            start: toDate(getStart),
            end: toDate(getEnd),
          })
        } else {
          finalBlockedArr.push(sub[0])
        }
      })

      return finalBlockedArr
    },
    monthSelected(month) {
      this.setCalendarDate({ month: month })
    },
    goToday() {
      this.setCalendarDate({
        month: format(new Date(), "yyyy-MM"),
        activeDate: format(new Date(), "yyyy-MM-dd"),
      })
      this.loadAppointments()
    },
    eventTypeLabel(item) {
      return item.id
    },
    backToDate() {
      this.dateDialog = true
      this.timeDialog = false
      this.pickedInterval = null
    },
    backToInterval() {
      this.timeDialog = true
      this.typeDialog = false
      this.pickedInterval = null
    },
    goSelectInterval(val) {
      this.setCalendarDate({
        month: format(parseISO(val), "yyyy-MM"),
        activeDate: val,
      })
      this.loadAppointments()
      this.dateDialog = false
      this.timeDialog = true
    },
    goSelectLesson(val) {
      this.timeDialog = false
      this.typeDialog = true
      this.pickedInterval = val
      if (this.isStudent) this.showModal("PRACTICE", val)
    },
    showModal(type, interval) {
      const vaf = this.isStudent ? interval : this.pickedInterval
      const newEventModel = {
        ...this.currentAppointmentGetter,
        type: type,
        studentsIds: this.isStudent ? [this.currentUser.id] : [],
        start: format(vaf, "yyyy-MM-dd HH:mm") + ":00",
        duration: 45,
      }
      this.showAppointmentModal(newEventModel)
      this.typeDialog = false
      this.pickedInterval = null
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-overline{
  font-size: 0.6rem !important;
  font-weight: 500;
  letter-spacing: 0.1666666667em !important;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif !important;
}
.calendar-mobile {
  display: flex;
  flex-direction: column;
  height: 100%;
}
#gridView {
  .v-date-picker-table {
    height: 250px !important;
  }
}
#mobileCalendarLayout {
  background: #fff;
  box-sizing: border-box;
  overflow: hidden;
  display: grid;
  gap: 5px;
  grid-template-rows: auto 1fr;
  .v-date-picker-table {
    height: 250px !important;
  }
}
#eventsArea {
  border-top: 1px solid #eee;
  overflow-x: auto;
  padding-bottom: 20px;
  .event-card {
    padding: 0 5px 5px 5px;
    margin: 5px 0;
    font-size: 0.7rem;
    border-right: 1px solid #eee;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    .flex-time {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
