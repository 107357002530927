<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "Coins",
  computed: {
    coins() {
      return require('@/assets/img/coins.png')
    }
  },
})
</script>

<template>
  <img :src="coins" alt="">
</template>
