import api from "@/utils/api";

export default {
  login(params) {
    return new Promise((resolve, reject) => {
      api
        .post("/other-accounts", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  logout(params) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/other-accounts`, {
          data: params
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  }
}
1
