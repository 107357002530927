<template>
  <v-card>
    <StudentTabToolBar activeTab="finances" >
      <v-btn
        v-if="isSchoolAdministrator && student.bookAndPay"
        text
        :to="{ name: 'students.student.finances.overview', id: $route.params.id }"
        exact
      >
        {{$t('btn.overview')}}
      </v-btn>
    </StudentTabToolBar>


    <v-card-text>
      <PaymentsTab :studentId="$route.params.id" />
    </v-card-text>
  </v-card>
</template>

<script>
import PaymentsTab from "@/components/student/PaymentsTab";
import StudentModel from "@/store/models/StudentModel";
import user from "@/utils/mixins/user";
import StudentTabToolBar from '@/components/student/StudentTabToolBar.vue'

export default {
  name: "StudentPayments",
  mixins: [user],
  components: { StudentTabToolBar, PaymentsTab },
  computed: {
    student() {
      return StudentModel.find(this.$route.params.id) || {};
    },
  },
  beforeRouteEnter(from, to, next) {
    const student = StudentModel.find(from.params.id);

    if ((student && student.isDeleted)) {
      next({ name: "students.student", params: { id: from.params.id } });
      return;
    }

    next();
  },
};
</script>

<style scoped></style>
