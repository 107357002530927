<template>
  <v-dialog
    v-model="show"
    width="1000"
    @click:outside="close"
    @keydown.esc="close"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    scrollable>
    <v-card loading>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>{{
          $t("label.create_or_edit_meeting_place", {
            createOrEdit: isEdit ? $t("label.edit") : $t("label.create"),
          })
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="pt-5" style="height: 100%">
        <div class="d-flex justify-center pt-5" v-if="loadingItem">
          <v-progress-circular color="primary" indeterminate />
        </div>
        <v-row v-else>
          <v-col cols="12">
            <ValidationObserver ref="form">
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" name="name">
                    <v-text-field
                      v-model="form.name"
                      :label="$t('form.name')"
                      :placeholder="$t('form.name')"
                      :error-messages="errors"
                      required
                      dense
                      outlined></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <div class="mb-1">
                <span class="text-subtitle-1 font-weight-medium">{{
                  $t("label.address")
                }}</span>
                <v-divider />
              </div>

              <v-row class="mt-3" dense>
                <v-col cols="12" sm="6" md="5">
                  <ValidationProvider v-slot="{ errors }" name="street">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.street"
                      :label="$t('form.street')"
                      :placeholder="$t('form.street')"
                      type="text"
                      required
                      :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <ValidationProvider v-slot="{ errors }" name="houseNr">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.houseNr"
                      :label="$t('form.house')"
                      :placeholder="$t('form.house')"
                      type="text"
                      required
                      :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <ValidationProvider v-slot="{ errors }" name="postcode">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.postcode"
                      :label="$t('form.plz')"
                      :placeholder="$t('form.plz')"
                      type="text"
                      required
                      :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <ValidationProvider v-slot="{ errors }" name="city">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.city"
                      :label="$t('form.city')"
                      :placeholder="$t('form.city')"
                      type="text"
                      required
                      :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </ValidationObserver>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider class="mt-auto" />
      <v-card-actions v-if="!loadingItem">
        <v-btn text @click="close">{{$t("btn.close")}}</v-btn>
        <v-spacer/>
        <v-btn
          color="primary"
          @click="save"
          :loading="loadingForm"
          :disabled="loadingForm || loadingItem"
        >
          <v-icon left>mdi-content-save{{isEdit ? '-edit' : ''}}</v-icon>
          {{ $t("btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import meetingPlacesService from "@/services/meetingPlacesService";

export default {
  name: "MeetingPlaceFormDialog",
  data: () => ({
    show: false,
    isEdit: false,
    item: {},
    initialForm: {},
    avatarImage: "",
    form: {
      name: "",
      city: "",
      street: "",
      houseNr: "",
      postcode: "",
    },
    loadingItem: false,
    loadingForm: false,
  }),
  created() {
    this.initialForm = { ...this.form };
  },
  methods: {
    open(item) {
      this.show = true;

      if (item) {
        this.isEdit = true;
        this.loadingItem = true;
        meetingPlacesService
          .loadItem(item.id)
          .then((resp) => {
            const data = resp.data;

            this.item = resp.data;

            this.form.name = data.name;
            this.form.city = data.city;
            this.form.street = data.street;
            this.form.houseNr = data.houseNr;
            this.form.postcode = data.postcode;
          })
          .catch(console.log)
          .finally(() => {
            this.loadingItem = false;
          });
      }
    },
    close() {
      this.show = false;
      this.isEdit = false;

      setTimeout(() => {
        this.form = { ...this.initialForm };
        this.$refs.form.reset()
      }, 200);
    },
    save() {
      this.isEdit ? this.update(this.form) : this.store(this.form);
    },
    store(data) {
      this.loadingForm = true;
      meetingPlacesService
        .create(data)
        .then((resp) => {
          this.formDialog = false;
          this.$emit("create", resp.data);
          this.close();
        })
        .catch((error) => {
          const responseData = error.response.data;
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message)
            this.$refs.form.setErrors({ username: responseData.message });

          Object.keys(errors).forEach((fieldName) =>
            this.$refs.form.setErrors({ [fieldName]: errors[fieldName] })
          );
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    update(data) {
      this.loadingForm = true;
      meetingPlacesService
        .update(this.item.id, data)
        .then((resp) => {
          this.formDialog = false;
          this.$emit("update", resp.data);
          this.close();
        })
        .catch((error) => {
          const responseData = error.response.data;
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message)
            this.$refs.form.setErrors({ username: responseData.message });

          Object.keys(errors).forEach((fieldName) =>
            this.$refs.form.setErrors({ [fieldName]: errors[fieldName] })
          );
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
  },
};
</script>

<style scoped></style>
