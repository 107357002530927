var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{class:{ 'mt-5': !_vm.$vuetify.breakpoint.xsOnly },attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('ValidationObserver',{ref:"form"},[_c('v-stepper',{staticStyle:{"height":"100%"},model:{value:(_vm.registrationStep),callback:function ($$v) {_vm.registrationStep=$$v},expression:"registrationStep"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"step":"1","complete":_vm.registrationSteps.school.passed,"rules":[
              () => !Object.keys(_vm.registrationSteps.school.errors).length,
            ]}},[_vm._v(" "+_vm._s(_vm.$t("label.school"))+" ")]),_c('v-stepper-step',{attrs:{"step":"2","complete":_vm.registrationSteps.personalInfo.passed,"rules":[
              () =>
                !Object.keys(_vm.registrationSteps.personalInfo.errors).length,
            ]}},[_vm._v(" "+_vm._s(_vm.$t("label.personal_info"))+" ")]),_c('v-stepper-step',{attrs:{"step":"3","complete":_vm.registrationSteps.profile.passed,"rules":[
              () => !Object.keys(_vm.registrationSteps.profile.errors).length,
            ]}},[_vm._v(" "+_vm._s(_vm.$t("label.profile"))+" ")])],1),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('RegistrationStepSchool',{on:{"beforeSubmit":_vm.onBeforeStepSubmit,"submitted":_vm.onStepSubmitted}})],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('RegistrationStepPersonalInfo',{attrs:{"form":_vm.form},on:{"beforeSubmit":_vm.onBeforeStepSubmit,"submitted":_vm.onStepSubmitted,"changeStep":_vm.changeStep}})],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('RegistrationStepProfile',{attrs:{"form":_vm.form},on:{"beforeSubmit":_vm.onBeforeStepSubmit,"submitted":_vm.onStepSubmitted,"changeStep":_vm.changeStep}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }