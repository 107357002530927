import BaseEvent from "./baseEvent";

export default class Event extends BaseEvent {
  getCssClass() {
    switch (this.type) {
      case "EVENT_LESSON":
        return "lesson-event";
      case "EVENT_EXAM":
        return "exam-event";
      case "EVENT_PLACEHOLDER":
        return "placeholder-event";
      case "EVENT_SPECIAL":
        return "special-event";
      case "EVENT_OFFTIME":
      default:
        return "offtime-event";
    }
  }
}
