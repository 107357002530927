import { parseISO } from "date-fns";

export default {
  computed: {
    overlappedEvents() {
      const eventsArr = [];
      const groupsList = this.groupOverlappingEventElements(this.eventsByDate);
      groupsList.forEach((curGroups) => {
        curGroups.forEach((curGroup, groupIndex) => {
          const newWidth = curGroups.length > 1 ? 100 / curGroups.length : 95;
          const newLeft = groupIndex * newWidth;
          curGroup.forEach((event) => {
            event.width = newWidth;
            event.left = newLeft;
            eventsArr.push(event);
          });
        });
      });
      return eventsArr;
    },
  },
  methods: {
    groupOverlappingEventElements(sortedEvents) {
      let lastEndTime = new Date(0, 0, 0);
      const groups = [];
      let curGroups = [];

      sortedEvents.map((event) => {
        if (curGroups.length > 0) {
          if (lastEndTime <= parseISO(event.start)) {
            groups.push(curGroups);
            curGroups = [];
          }
        }

        for (let groupIndex = 0; groupIndex < curGroups.length; groupIndex++) {
          if (curGroups[groupIndex].length > 0) {
            if (
              curGroups[groupIndex][curGroups[groupIndex].length - 1].endDate <=
              parseISO(event.start)
            ) {
              curGroups[groupIndex].push(event);
              if (lastEndTime < event.endDate) {
                lastEndTime = event.endDate;
              }
              return;
            }
          }
        }
        curGroups.push([event]);
        if (lastEndTime < event.endDate) {
          lastEndTime = event.endDate;
        }
      });
      if (curGroups.length > 0) {
        groups.push(curGroups);
      }
      return groups;
    },
  },
};
