<template>
  <div>
    <v-row v-if="model.teacherId">
      <TeachersField
        :disabled="true"
        :cols="12"
        :model="model"
        :eventDuration="eventDuration"
        :filteredTeachers="teachers" />
    </v-row>
    <TimeFields
      :disabled="true"
      :model="model"
      :eventDuration="eventDuration" />
    <TitleField :disabled="true" :model="model" />
    <TextField :disabled="true" :model="model" label="Title" />
  </div>
</template>

<script>
import TeachersField from "@/components/calendar/eventModal/fields/TeachersField";
import TimeFields from "@/components/calendar/eventModal/fields/TimeFields";
import TitleField from "@/components/calendar/eventModal/fields/TitleField";
import TextField from "@/components/calendar/eventModal/fields/TextField";
import { mapGetters } from "vuex";

export default {
  name: "StudentSpecialForm",
  components: { TeachersField, TimeFields, TitleField, TextField },
  props: {
    eventDuration: {
      type: Number,
      required: true,
    },
    teachers: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
  },
};
</script>
