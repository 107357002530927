import { Model } from "@vuex-orm/core";
import LicenseModel from "@/store/models/LicenseModel";

export default class LearningMaterial extends Model {
  static entity = "learningMaterial";

  static fields() {
    return {
      id: this.attr(null),
      name: this.string(""),
      description: this.string(""),
      licenseId: this.attr(null),
      license: this.belongsTo(LicenseModel, "licenseId"),
      priceCategoryId: this.attr(null),
      price: this.string(""),
    };
  }
}
