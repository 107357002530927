export default {
  computed: {
    appliedFilters() {
      if (!this.$route.query.filters) return [];
      return this.$route.query.filters.slice(0, -1).split(";");
    },
    appliedSorting() {
      if (!this.$route.query.sort_type) return null;
      return this.$route.query.sort_type;
    },
    appliedFiltersValues() {
      const appliedFiltersValues = {};
      this.appliedFilters.forEach((appliedFilter) => {
        const appliedFilterKey = appliedFilter.split("(");
        if (!(appliedFilterKey[0] in appliedFiltersValues)) {
          const appliedFilterBracketsValue = appliedFilter.match(/\((.*)\)/i);
          appliedFiltersValues[appliedFilterKey[0]] = appliedFilterBracketsValue
            ? appliedFilterBracketsValue[1]
            : true;

          const appliedFilterValue = appliedFiltersValues[appliedFilterKey[0]];
          if (
            typeof appliedFilterValue === "string" &&
            appliedFilterValue.indexOf(",") !== -1
          ) {
            appliedFiltersValues[appliedFilterKey[0]] =
              appliedFilterValue.split(",");
          }
        }
      });
      return { ...appliedFiltersValues, sort_type: this.appliedSorting };
    },
  },
  methods: {
    formatFilters(filters) {
      let formattedFilters = "";
      Object.keys(filters).forEach((key) => {
        if (!filters[key]) return false;
        if (Array.isArray(filters[key]) && filters[key].length === 0)
          return false;

        if (typeof filters[key] === "boolean") {
          formattedFilters += `${key};`;
        } else if (Array.isArray(filters[key])) {
          formattedFilters += `${key}(${filters[key].join(",")});`;
        } else {
          formattedFilters += `${key}(${filters[key]});`;
        }
      });
      return formattedFilters;
    },
  },
};
