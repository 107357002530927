<template>
<ValidationProvider v-slot="{ errors }" name="mileage">
  <label class="mt-4" :class="{'error--text': errors.length > 0}" for="">{{ $t("label.mileage") }}</label>

  <div class="mileage-input">
    <div class="mileage-input-fields">
      <div v-for="(digit, index) in digits" :key="index" class="input-wrapper">
        <v-btn class="mb-1" icon small text @click="increment(index)">
          <v-icon color="green">mdi-plus</v-icon>
        </v-btn>
        <v-text-field
            :value="digit"
            class="centered-text text-monospace"
            dense
            height="20px"
            hide-details
            maxlength="1"
            readonly
            solo
            type="text"
            @input="handleInput(index, $event.target.value)"
        />
        <v-btn :ripple="false" class="mt-1" icon small text @click="decrement(index)">
          <v-icon color="red">mdi-minus</v-icon>
        </v-btn>
      </div>
    </div>

    <span v-if="errors.length > 0" class="error--text">{{ errors[0] }}</span>
  </div>
</ValidationProvider>
</template>

<script>
export default {
  name: "MileageInput",
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      digits: [],
    };
  },
  methods: {
    handleInput(index, value) {
      if (value === "" || /^[0-9]$/.test(value)) {
        this.digits.splice(index, 1, value);
        this.emitValue();
      }
    },
    increment(index) {
      if (this.digits[index] === "") {
        if (index === this.digits.length - 1 || this.digits[index + 1] !== "") {
          this.digits.splice(index, 1, "1");
        }
      } else {
        this.digits.splice(index, 1, (parseInt(this.digits[index]) + 1) % 10);
      }
      this.emitValue();
    },
    decrement(index) {
      if (this.digits[index] === "") {
        if (index === this.digits.length - 1 || this.digits[index + 1] !== "") {
          this.digits.splice(index, 1, "9");
        }
      } else {
        this.digits.splice(index, 1, (parseInt(this.digits[index]) - 1 + 10) % 10);
      }
      this.emitValue();
    },
    emitValue() {
      const filteredDigits = this.digits.filter((digit) => digit !== '');
      const mileage = filteredDigits.length > 0 ? parseInt(filteredDigits.join(''), 10) : 0;
      this.$emit("input", mileage);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        const digitsString = newValue.toString().padStart(6, "0");
        this.digits = digitsString.split("");
      },
    },
  },
};
</script>

<style lang="scss">
.mileage-input {
  display: flex;
  flex-direction: column;
  &-fields {
    display: flex;
    justify-content: center;
  }
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 5px;
  align-items: center;
}

.centered-text.v-input__control {
  display: flex;
  justify-content: center;
}
.centered-text.v-input{
  &.text-monospace input {
    font-family: 'Share Tech Mono', monospace;
  }
  & input {
    text-align: center;
  }
}
</style>
