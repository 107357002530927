<script>
export default {
  name: 'DemoModeToolTipWrapper',
  props: {
    tooltipText: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<template>
  <v-tooltip bottom :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <div class="cursor-pointer" v-bind="attrs" v-on="on">
        <slot />
      </div>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<style scoped>

</style>
