<template>
  <v-overlay
    class="refresh-overlay mt-8 pa-5"
    :value="withoutUpdatedType"
    :absolute="true"
    z-index="9"
    opacity="0.5">
    <div class="d-flex flex-column align-center">
      <v-icon class="mb-1" size="45">mdi-information</v-icon>
      <span class="title mb-3">{{ typeMessages[eventModelUpdateType] }}</span>
      <div>
        <v-btn v-if="wasDeleted" @click="$emit('close')">{{
          $t("btn.close")
        }}</v-btn>
        <v-btn
          v-else-if="wasUpdated"
          @click="refresh(eventModel.id)"
          :disabled="loadingRefresh"
          :loading="loadingRefresh"
          >{{ $t("btn.refresh") }}</v-btn
        >
      </div>
    </div>
  </v-overlay>
</template>

<script>
import eventService from "@/services/eventService";
import EventModel from "@/store/models/EventModel";
import * as eventsMutationTypes from "@/store/mutation-types/events";
import i18n from "@/plugins/i18n";

import { mapState } from "vuex";

export default {
  name: "ModalOverlay",
  props: {
    eventModel: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loadingRefresh: false,
    typeMessages: {
      edited: i18n.t("messages.event_data_was_updated"),
      deleted: i18n.t("messages.event_was_deleted"),
    },
  }),
  computed: {
    ...mapState("appointments", {
      eventModelUpdateType: (state) => state.newEventModel.updateType,
    }),
    withoutUpdatedType() {
      return this.eventModelUpdateType !== "";
    },
    wasUpdated() {
      return this.eventModelUpdateType === "edited";
    },
    wasDeleted() {
      return this.eventModelUpdateType === "deleted";
    },
  },
  methods: {
    refresh(eventId) {
      this.loadingRefresh = true;
      eventService
        .get(eventId)
        .then((response) => {
          const event = response.data.event || {};
          EventModel.update({ where: event.id, data: event }).then(
            (response) => {
              const event = EventModel.query()
                .with("teacher")
                .find(response.id);

              this.$store
                .dispatch("events/eventUpdate", event)
                .then(() => this.$emit("update"));
              this.$store.commit(
                "events/" + eventsMutationTypes.SET_UPDATED_TYPE,
                ""
              );
            }
          );
        })
        .catch((error) => {
          this.$snackbar.show({
            message: error.response.data?.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loadingRefresh = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.refresh-overlay {
  margin-top: 32px;
  border-radius: 0;
  .v-overlay__scrim {
    /*background-color: transparent !important;*/
    background: url("../../../assets/img/dots-red.png");
  }
}
</style>
