<script>
import {showConfirm} from "@/helpers/dialogs";

export default {
  name: "StudentsIndexPreferencesDialog",
  data() {
    return {
      dialog: false,
      actualValue: null,
      newValue: null,
      loading: false
    }
  },
  props: {
    dark: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    const storedValue = localStorage.getItem('legacyStudentDirectoryPreferred');
    this.actualValue = storedValue !== null ? storedValue === 'true' : false;
    this.newValue = this.actualValue;
  },
  methods: {
    apply() {
      if (this.newValue) {
        showConfirm(this.$t('alert.are_you_sure'), this.$t('messages.are_you_sure_disabling_new_students_view'), () => {
          this.savePreferenceAndReload()
        })
      } else {
        this.savePreferenceAndReload()
      }
    },
    savePreferenceAndReload() {
      localStorage.setItem('legacyStudentDirectoryPreferred', this.newValue)
      this.loading = true;
      location.reload()
    }
  }
}
</script>

<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :color="dark ? 'white' : 'primary'" icon :dark="dark" v-bind="attrs" v-on="on">
        <v-icon>mdi-view-dashboard-edit-outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        {{ $t('label.default_students_overview') }}
        <v-spacer/>
        <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>

      <v-card-text>
        <p>
          {{ $t('label.select_your_preferred_students_overview') }}
        </p>
        <v-radio-group v-model="newValue">
          <v-radio :value="false" :label="$t('label.prefer_new_students_overview')"/>
          <v-radio :value="true" :label="$t('label.prefer_old_students_overview')" />
        </v-radio-group>
        <v-alert border="left" elevation="1" colored-border type="info" v-if="actualValue !== newValue">
          {{ $t('alert.app_must_reload_to_apply_changes') }}
        </v-alert>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="apply" :disabled="actualValue === newValue || loading" :loading="loading">
          {{ $t('btn.apply') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>