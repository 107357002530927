<template>
  <div>
    <video @ended="fullWidth = false" @play="fullWidth = true" :src="url" :style="{ 'max-width': maxWidth }" controls v-if="isVideo"/>
    <v-img :max-width="maxWidth" contain :src="url" alt="" v-else @click.native="fullWidth = !fullWidth" />
  </div>
</template>

<script>
export default {
  name: "AttachmentPreview",
  props: {
    url: {
      type: String,
      required: true
    },
    mime: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      fullWidth: false
    }
  },
  computed: {
    defaultMaxWidth () {
      return this.$vuetify.breakpoint.smAndDown ? '100%' : '300px'
    },
    fullWidthValue () {
      return this.$route.name === 'patch-notes' ? '50%' : '100%'
    },
    maxWidth () {
      return this.fullWidth ? this.fullWidthValue : this.defaultMaxWidth
    },
    isVideo () {
      return this.mime.startsWith('video')
    }
  }
}
</script>

<style scoped>
</style>