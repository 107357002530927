<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'school-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>
        {{ $t("route.schoolSettings.children.paymentBonus") }}
      </v-card-title>
    </v-toolbar>

    <v-card-text>
      <SettingsResponsiveRow
        :divider-after="false"
        :title="$t('label.enable_bonus')"
        :description="$t('label.payment_bonus_explanation')"
      >
        <v-switch
            :loading="changing"
            :disabled="changing"
            :input-value="localState"
            :label="$t('label.enabled')"
            @change="handleChange"
        />
      </SettingsResponsiveRow>
    </v-card-text>
  </div>
</template>

<script>
import schoolService from "@/services/schoolService";
import SettingsResponsiveRow from "@/components/settings/SettingsResponsiveRow";
import responsive from '@/utils/mixins/responsive'

export default {
  name: "PaymentBonus",
  components: {SettingsResponsiveRow},
  mixins: [responsive],
  data() {
    return {
      localState: undefined,
      changing: true,
    };
  },
  mounted() {
    this.localState = this.$store.state.school.isPaymentBonusEnabled;
    this.changing = false;
  },
  methods: {
    handleChange(newState) {
      this.localState = newState;
      this.changing = true;
      schoolService
        .updatePaymentBonusSettings(newState)
        .then(() => {
          this.$store.commit("school/UPDATE_PAYMENT_BONUS_STATUS", newState);
          this.$snackbar.success(this.$t("messages.successfully_updated"))
        })
        .finally(() => {
          this.changing = false;
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
