<template>
<v-dialog v-model="show" width="600">
  <v-card>
    <v-toolbar class="mb-2" dark color="primary" dense>
      <v-toolbar-title>
        {{ $t('label.reset_password') }}
      </v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-alert
          class="my-auto mt-5"
          type="success"
          dense
          text
          icon="mdi-email"
      >
        {{ $t('messages.reset_password_instruction_sent') }}
        <br>
        {{ $t('messages.please_check_your_inbox') }}
      </v-alert>
    </v-card-text>

    <v-divider/>

    <v-card-actions>
      <v-btn text @click="close">
        {{$t("btn.close")}}
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  name: 'ResetPasswordLinkSuccessDialog',
  data () {
    return {
      show: false
    }
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.show = false
    }
  }
}
</script>

<style scoped>

</style>
