<template>
  <v-container>
    <template-renderer :template-id="templateId" />
  </v-container>
</template>

<script>
import TemplateRenderer from "@/components/insights/TemplateRenderer.vue";

export default {
  name: "ViewTemplate",
  components: {TemplateRenderer},
  data() {
    return {
      expanded: false,
    }
  },
  computed: {
    templateId () {
      return parseInt(this.$route.params.template)
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
