import { render, staticRenderFns } from "./PlaceholderLoader.vue?vue&type=template&id=0fe101ad"
import script from "./PlaceholderLoader.vue?vue&type=script&lang=js"
export * from "./PlaceholderLoader.vue?vue&type=script&lang=js"
import style0 from "./PlaceholderLoader.vue?vue&type=style&index=0&id=0fe101ad&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports