<template>
  <div class="list__column" :style="{ width: `${width}px` }">
    <slot :item="item">{{ text || "-" }}</slot>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
    },
    width: {
      type: [Number, String],
    },
  },
  name: "ListColumn",
};
</script>

<style scoped></style>
