<template>
  <v-card elevation="0" class="event-card" @click="$emit('open')">
    <div class="flex-time">
      <div>
        {{ event.startDate }} | {{ event.startTime }} - {{ event.endTime }}
      </div>
      <div>{{ event.duration }} <v-icon size="small">mdi-clock</v-icon></div>
    </div>

    <span
      v-if="!isStudent && student"
      :class="{ 'font-italic': student.isDeleted }"
      >{{ student.fullName }}</span
    >

    <div>
      {{ $t("event_type.SIMULATOR") }}
      ({{ event.license }}
      <span v-if="event.name" class="text-uppercase">— {{ event.name }}</span
      >)
    </div>

    <div class="mt-1 d-flex" v-if="event.office">
      <v-icon size="19">mdi-map-marker</v-icon>
      <div class="text-caption text-truncate" style="max-width: 250px">
        {{ event.office }}
      </div>
    </div>
  </v-card>
</template>

<script>
import user from "@/utils/mixins/user";
import StudentModel from "@/store/models/StudentModel";

export default {
  name: "SimulatorAlert",
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [user],
  computed: {
    studentsIds() {
      return this.event.studentsIds || [];
    },
    student() {
      if (this.$route.name === "students.student.agenda") return null;
      if (this.event.studentsIds.length === 0) return null;
      return StudentModel.find(this.event.studentsIds[0]);
    },
  },
};
</script>

<style scoped lang="scss">
.event-card {
  padding: 0 5px 5px 5px;
  margin: 5px 0;
  font-size: 0.7rem;
  background: #fff !important;
  border-right: 1px solid #eee;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  .flex-time {
    display: flex;
    justify-content: space-between;
  }
  & > * {
    pointer-events: none;
    font-size: 12px;
    padding: 0 5px;
  }
  .request-mark {
    position: absolute;
    bottom: 3px;
    right: 3px;
  }
  .icon-box {
    position: absolute;
    right: 5px;
    bottom: 5px;
    color: #333;
  }
}
</style>
