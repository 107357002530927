import { Model } from "@vuex-orm/core";

export default class ChatParticipant extends Model {
  static entity = "chatParticipants";
  static primaryKey = "userId";

  static state() {
    return {
      loading: false,
      searchText: "",
    };
  }

  static fields() {
    return {
      userId: this.attr(null),
      avatar: this.attr(null),
      firstName: this.string(""),
      lastName: this.string(""),
      type: this.string("school-administrator"),
    };
  }

  get online() {
    return this.$store().state.messenger.onlineParticipantsIds.includes(
      this.userId
    );
  }

  get fullName() {
    return this.firstName + " " + this.lastName;
  }
}
