<template>
  <v-card>
    <StudentTabToolBar activeTab="notes" />

    <v-card-text>
      <NotesTab :studentId="$route.params.id" />
    </v-card-text>
  </v-card>
</template>

<script>
import NotesTab from "@/components/student/NotesTab";
import StudentModel from "@/store/models/StudentModel";
import StudentTabToolBar from '@/components/student/StudentTabToolBar.vue'

export default {
  name: "StudentNotes",
  components: { StudentTabToolBar, NotesTab },
  beforeRouteEnter(from, to, next) {
    const student = StudentModel.find(from.params.id);

    if (student && student.isDeleted) {
      next({ name: "students.student", params: { id: from.params.id } });
      return;
    }

    next();
  },
};
</script>

<style scoped></style>
