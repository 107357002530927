<script>
import { defineComponent } from "vue";
import { EventBus } from "@/EventBus";

export default defineComponent({
  name: "TableTh",
  props: {
    value: {
      type: String,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    width: {
      type: [Number, String]
    },
    sortable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    formattedWidth() {
      return typeof this.width === 'number' ? this.width + "px" : this.width;
    },
    styles() {
      return `width: ${this.formattedWidth};${this.sortable ? "cursor:pointer" : ""};`;
    }
  },
  created() {
    EventBus.$on("sort-applied", this.onSortApplied);
  },
  destroyed() {
    EventBus.$off("sort-applied", this.onSortApplied);
  },
  methods: {
    onClick() {
      this.$emit("input", this.value === "desc" ? null : (this.value === "asc" ? "desc" : "asc"));
      EventBus.$emit("sort-applied", { name: this.name });
    },
    onSortApplied(data) {


      if (data.name !== this.name) {
        this.$emit("input", null);
      }
    }
  }
});
</script>

<template>
  <th :class="{'active': value}" :style="styles" @click="onClick">
    <slot />
    <v-icon :class="{'d-none': !value}" small>
      {{ this.value === "desc" ? "mdi-arrow-down" : "mdi-arrow-up" }}
    </v-icon>
  </th>
</template>

<style lang="scss" scoped>
th.active {
  color: #000 !important;

  .v-icon {
    color: #000 !important;
  }
}

th:hover {
  color: #000 !important;

  .v-icon {
    display: inline-flex !important;
  }
}
</style>
