<template>
  <v-card elevation="0">
    <v-card-title class="mb-3 d-flex justify-space-between">
      <span class="title">{{ $t("label.documents_list") }}</span>
      <v-btn
        text
        color="primary"
        small
        @click="addNewDocument"
        :disabled="loadingDocuments"
        >{{ $t("btn.new_document") }}</v-btn
      >
    </v-card-title>

    <div v-if="loadingDocuments">
      <v-skeleton-loader
        class="pa-0"
        v-for="nr in 2"
        :key="nr"
        type="list-item" />
    </div>

    <div v-else>
      <v-row class="mb-3" v-if="!!student.hasOneTimePassword">
        <v-col class="d-flex" cols="12">
          <v-sheet class="py-2 px-3 flex-grow-1" outlined rounded>
            <span>{{ $t("label.credentials") }}</span>
          </v-sheet>

          <v-btn
            height="42"
            class="ml-2"
            color="primary"
            text
            outlined
            @click="downloadOneTimePasswordPDF"
            :loading="loadingOneTimePasswordPDF">
            <v-icon size="25">mdi-download</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <ValidationObserver ref="documentsForm">
        <v-row dense v-for="document in documents" :key="document.id">
          <v-col class="d-flex" cols="12" :lg="!document.createdAt ? 4 : 12">
            <ValidationProvider
              v-if="!document.createdAt"
              v-slot="{ errors }"
              :vid="'document.' + document.id + '.title'"
              name="title"
              class="flex-grow-1"
              rules="required">
              <v-combobox
                :label="$t('form.title')"
                v-model="document.type"
                :items="documentsTypes"
                outlined
                dense
                hide-no-data
                hide-details
                :error="!!errors.length"
                :disabled="document.loading" />

              <span v-if="errors.length > 0" class="text-caption red--text">{{
                errors[0]
              }}</span>
            </ValidationProvider>

            <v-sheet
              v-if="document.createdAt"
              class="py-2 px-3 flex-grow-1"
              outlined
              rounded>
              <span>{{ document.title }}</span>
            </v-sheet>

            <div class="d-flex justify-end ml-2" v-if="document.createdAt">
              <v-btn
                :href="document.file"
                download
                target="_blank"
                height="42"
                class="mr-2"
                color="primary"
                text
                outlined>
                <v-icon size="25">mdi-download</v-icon>
              </v-btn>
              <v-progress-circular
                class="mt-1 mx-5"
                v-if="document.loading"
                color="primary"
                indeterminate
                size="25" />
              <v-btn
                height="42"
                text
                outlined
                v-if="!document.loading && document.type !== 'CREDENTIALS'"
                @click="deleteDocument(document)">
                <v-icon size="25" color="red">mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col class="d-flex" v-if="!document.createdAt" cols="12" lg="8">
            <ValidationProvider
              v-if="!document.createdAt"
              v-slot="{ errors }"
              :vid="'document.' + document.id + '.file'"
              name="title"
              class="flex-grow-1">
              <v-file-input
                :ref="'document.' + document.id + '.file'"
                v-model="document.file"
                :placeholder="$t('documents.upload_document')"
                :label="$t('documents.upload_document')"
                outlined
                dense
                hide-details
                @change="(file) => uploadDocument(document, file)"
                :disabled="document.loading"
                prepend-icon=""
                prepend-inner-icon="mdi-attachment"
                :error="!!errors.length" />
            </ValidationProvider>
            <div class="d-flex justify-end ml-2">
              <v-progress-circular
                class="mt-1 mx-2"
                v-if="document.loading"
                color="primary"
                indeterminate
                size="25" />
              <v-btn
                height="42"
                text
                outlined
                v-if="!document.loading && document.type !== 'CREDENTIALS'"
                @click="deleteDocument(document)">
                <v-icon size="25" color="red">mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <empty-box v-if="!documents.length" />
      </ValidationObserver>
    </div>
  </v-card>
</template>

<script>
import { v4 as uuid4 } from "uuid";
import studentsService from "@/services/studentsService";
import StudentModel from "@/store/models/StudentModel";

export default {
  name: "DocumentsTab",
  props: {
    studentId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      loadingDocuments: true,
      documentType: "",
      documentFile: null,
      loadingOneTimePasswordPDF: false,
      documents: [{ id: uuid4(), type: "", file: null, loading: false }],
      documentsTypes: [
        { value: "BIOMETRIC_PASSPORT_PICTURE", text: "Biometrisches Passbild" },
        { value: "FIRST_AID", text: "Erste Hilfe" },
        { value: "EYE_TEST_CERTIFICATE", text: "Sehtest-Bescheinigung" },
      ],
    };
  },
  computed: {
    student() {
      return StudentModel.find(this.studentId) || {};
    },
  },
  mounted() {
    studentsService
      .documents(this.studentId)
      .then((documentsResponse) => {
        this.documents = [...this.documents, ...(documentsResponse.data || [])];
      })
      .finally(() => {
        this.loadingDocuments = false;
      });
  },
  methods: {
    addNewDocument() {
      this.documents.unshift({
        id: uuid4(),
        type: "",
        file: null,
        loading: false,
      });
    },
    async deleteDocument(document) {
      const documentIndex = this.documents.findIndex(
        (item) => item.id === document.id
      );

      this.$set(this.documents[documentIndex], "loading", true);

      try {
        if (document.createdAt) {
          await studentsService.deleteDocument(this.studentId, document.id);
        }

        const documentIndex = this.documents.findIndex(
          (item) => item.id === document.id
        );

        if (documentIndex !== -1) {
          this.documents.splice(documentIndex, 1);
        }
      } catch (error) {
        console.log(error);
      }

      this.$set(this.documents[documentIndex], "loading", false);
    },
    uploadDocument(document, file) {
      const formData = new FormData();

      formData.set("requestId", document.id);
      if (document.type instanceof Object && document.type.value) {
        formData.set("key", document.type.value);
      }
      formData.set("title", document.type || "");
      formData.set("file", file);

      const documentIndex = this.documents.findIndex(
        (item) => item.id === document.id
      );

      this.$set(this.documents[documentIndex], "loading", true);
      studentsService
        .uploadDocument(this.studentId, formData)
        .then((response) => {
          const responseData = response.data || {};
          const documentIndex = this.documents.findIndex(
            (item) => item.id === responseData.requestId
          );

          if (documentIndex !== -1)
            this.$set(this.documents, documentIndex, {
              ...responseData,
              loading: false,
            });
        })
        .catch((error) => {
          const responseData = error.response.data;
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });
          }

          Object.keys(errors).forEach((key) => {
            let fieldName = key;
            const fieldErrorMessage = errors[fieldName];

            if (fieldName === "key") fieldName = "title";

            this.$refs.documentsForm.setErrors({
              ["document." + document.id + "." + fieldName]: fieldErrorMessage,
            });

            const docEl = this.$refs["document." + document.id + ".file"][0];
            if (docEl.value) docEl.clearableCallback();
          });
        })
        .finally(() => {
          this.$set(this.documents[documentIndex], "loading", false);
        });
    },
    downloadOneTimePasswordPDF() {
      this.loadingOneTimePasswordPDF = true;
      studentsService
        .downloadOnTimePassword(this.studentId)
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const blobURL = URL.createObjectURL(blob);
          window.open(blobURL);
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loadingOneTimePasswordPDF = false;
        });
    },
  },
};
</script>

<style scoped></style>
