import api from "@/utils/api";

export default {
  load(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/invoices", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadItem(itemId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/invoices/${itemId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  create(params) {
    return new Promise((resolve, reject) => {
      api
        .post("/invoices", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  createStudentless(params) {
    return new Promise((resolve, reject) => {
      api
        .post("/studentless-invoices", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  markAsPaid(itemId, date) {
    return new Promise((resolve, reject) => {
      api
        .patch(`/invoices/${itemId}`, { isPaid: true, date })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  cancel(itemId) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/invoices/${itemId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  downloadInvoice(itemId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/invoices/${itemId}/download`, {
          responseType: "blob",
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  sendInvoice(itemId) {
    return new Promise((resolve, reject) => {
      api
        .post(`/invoices/${itemId}/send`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
