var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"event-card",style:(`border-left: 10px solid ${_vm.eventColor.bodyColor};`),attrs:{"elevation":"0"},on:{"click":function($event){return _vm.eventClicked(_vm.event)}}},[_c('div',{staticClass:"flex-time"},[_c('div',[_vm._v(" "+_vm._s(_vm.event.formattedDate)+" | "+_vm._s(_vm.event.startTime)+" - "+_vm._s(_vm.event.endTime)+" ")]),_c('div',[_vm._v(_vm._s(_vm.event.duration)+" "),_c('v-icon',{attrs:{"size":"small"}},[_vm._v("mdi-clock")])],1)]),(_vm.event.teacher && !_vm.isTeacher)?_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.event.teacher.fullName)+" ")]):_vm._e(),(!_vm.isStudent || (_vm.isStudent && !_vm.blockedEvent.status))?_c('div',[(
        !_vm.isStudent && _vm.event.eventType !== 'THEORY' && _vm.event.studentsIds.length
      )?_c('div',[_vm._v(" "+_vm._s(_vm.studentsLabel)+" ")]):_vm._e(),(_vm.event.eventType !== 'PRACTICE')?_c('div',[_vm._v(_vm._s(_vm.eventLabel))]):_c('div',[_c('div',{staticClass:"text-overflow-ellipsis"},[_vm._v(" "+_vm._s(_vm.licenseName)+" "),(_vm.lessonTypeLabel)?_c('span',{staticClass:"text-uppercase"},[_vm._v("— "+_vm._s(_vm.lessonTypeLabel))]):_vm._e(),(_vm.event.manualLesson)?_c('span',{staticClass:"ml-1"},[_vm._v("S")]):_vm._e()])])]):_vm._e(),(_vm.event.eventType === 'SPECIAL')?_c('div',{staticClass:"text-overflow-ellipsis"},[_vm._v(" "+_vm._s(_vm.event.title)+" ")]):_vm._e(),(_vm.event.eventType === 'THEORY')?_c('div',{staticClass:"text-overflow-ellipsis"},[(_vm.event.theoryTopicId)?_c('v-icon',{attrs:{"size":"15"}},[_vm._v(" "+_vm._s(_vm.theoryTopic.type === "basic" ? "mdi-alpha-g-box" : "mdi-alpha-z-box")+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.theoryTopicLabel)+" ")],1):_vm._e(),_c('div',{staticClass:"icon-box d-flex justify-end"},[(_vm.isSpecialSignature)?_c('v-icon',{staticClass:"sing-icon",attrs:{"size":"15"}},[_vm._v("mdi-signature-freehand")]):_vm._e(),(_vm.studentHasSubscribed)?_c('v-icon',{staticClass:"ml-2",attrs:{"size":"15"}},[_vm._v("mdi-check-circle")]):_vm._e(),(_vm.isStudent && (_vm.requestedPractice || _vm.requestedByStudent))?_c('v-icon',{staticClass:"ml-2",attrs:{"size":"15"}},[_vm._v("mdi-help-circle")]):_vm._e(),(
        !_vm.isStudent &&
        _vm.event.eventType === 'SIMULATOR' &&
        _vm.event.studentsIds.length
      )?_c('v-icon',{staticClass:"ml-2",attrs:{"size":"15"}},[_vm._v("mdi-account-circle")]):_vm._e(),(
        _vm.isStudent &&
        _vm.event.eventType === 'SIMULATOR' &&
        _vm.event.studentsIds.length
      )?_c('v-icon',{staticClass:"ml-2",attrs:{"size":"15"}},[_vm._v("mdi-check-circle")]):_vm._e(),(
        !_vm.isStudent &&
        _vm.event.eventType === 'PRACTICE' &&
        _vm.event.status === 'REQUESTED'
      )?_c('v-icon',{staticClass:"ml-1 icon",attrs:{"size":"15"}},[_vm._v("mdi-help-circle")]):_vm._e(),(
        !_vm.isStudent &&
        _vm.event.eventType === 'PRACTICE' &&
        (_vm.event.status === 'MISSED' || _vm.event.status === 'MISSED_REFUNDED')
      )?_c('v-icon',{staticClass:"ml-1 icon",attrs:{"size":"15"}},[_vm._v("mdi-cancel")]):_vm._e(),(
        !_vm.isStudent && _vm.event.eventType === 'THEORY' && _vm.event.group === 'lesson'
      )?_c('EventSubscribedStudents',{attrs:{"label":`${_vm.event.studentsIds.length}/${_vm.event.roomSize}`,"eventColor":_vm.eventColor}}):_vm._e(),(
        !_vm.isStudent &&
        _vm.event.eventType === 'THEORY' &&
        _vm.event.group === 'exam' &&
        _vm.event.studentsIds.length
      )?_c('EventSubscribedStudents',{attrs:{"label":_vm.event.studentsIds.length,"eventColor":_vm.eventColor}}):_vm._e(),(
        !_vm.isStudent &&
        _vm.event.eventType === 'PLACEHOLDER' &&
        _vm.event.requests.length
      )?_c('EventSubscribedStudents',{attrs:{"label":_vm.event.requests.length,"eventColor":{}}}):_vm._e(),(
        !_vm.isStudent &&
        _vm.event.eventType === 'SPECIAL' &&
        _vm.event.studentsIds.length > 1
      )?_c('EventSubscribedStudents',{attrs:{"label":_vm.event.studentsIds.length,"eventColor":_vm.eventColor}}):_vm._e()],1),(_vm.event.meetingPlace)?_c('div',{staticClass:"text-caption text-truncate"},[_vm._v(" "+_vm._s(_vm.event.meetingPlace)+" ")]):_vm._e(),(_vm.event.primaryVehicleId)?_c('div',{staticClass:"vehicles"},[_c('div',{style:(`background: ${_vm.primaryVehicle.color}; color: ${_vm.invertColor(
        _vm.primaryVehicle.color
      )}`)},[_vm._v(" "+_vm._s(_vm.primaryVehicle.label)+" ")]),(_vm.event.secondaryVehicleId)?_c('div',{style:(`background: ${_vm.secondaryVehicle.color}; color: ${_vm.invertColor(
        _vm.secondaryVehicle.color
      )}`)},[_vm._v(" "+_vm._s(_vm.secondaryVehicle.label)+" ")]):_vm._e()]):_vm._e(),(
      !_vm.isStudent && _vm.event.eventType === 'PLACEHOLDER' && _vm.event.requests.length
    )?_c('div',{staticClass:"pt-1"},_vm._l((_vm.requestedPlaceholderStudents),function(student,index){return _c('div',{key:index,staticClass:"text-overflow-ellipsis",staticStyle:{"font-size":"0.6rem"}},[_vm._v(" "+_vm._s(student)+" ")])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }