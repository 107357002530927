import { Model } from "@vuex-orm/core";
import ProductCategoryModel from "./ProductCategoryModel";
import { formatISO } from "date-fns";
import TeacherModel from "@/store/models/TeacherModel";
import i18n from "@/plugins/i18n";

export default class StudentModel extends Model {
  static entity = "students";

  static state() {
    return {
      searchText: "",
      pagination: {
        currentPage: 1,
        from: 0,
        lastPage: 1,
        to: 0,
        total: 0,
      },
      activeStudentId: this.attr(null)
    };
  }

  static fields() {
    return {
      id: this.attr(null),
      checked: this.boolean(false),
      loading: this.boolean(false),
      userId: this.attr(null),
      avatar: this.attr(null),
      email: this.attr(null),
      birthDate: this.attr(null),
      age: this.attr({}),
      firstName: this.string(""),
      lastName: this.string(""),
      fullName: this.string(""),
      active: this.boolean(false),
      joker: this.boolean(false),
      vaccinationStatus: this.attr(""),
      licensesIds: this.attr([]),
      licensesRequirements: this.attr([]),
      licensesIdsWithTheoryExam: this.attr([]),
      licensesIdsWithExaminingPeriodInWeeks: this.attr({}),
      balance: this.attr(null),
      availableBalance: this.attr(null),
      oldSystemBalance: this.attr(null),
      priceCategoryId: this.attr(null),
      phone: this.attr(null),
      street: this.attr(null),
      houseNr: this.attr(null),
      postcode: this.attr(null),
      city: this.attr(null),
      createdAt: this.string(formatISO(new Date())),
      deletedAt: this.attr(null),
      teachersIds: this.attr([]),
      activeTeachersIds: this.attr([]),
      teachers: this.belongsTo(TeacherModel, "teachersIds"),
      hasOneTimePassword: this.boolean(false),
      bookAndPay: this.boolean(false),
      oneTimePassword: this.attr(null),
      educationStatus: this.string(""),
      loadingOneTimePasswordPDF: this.boolean(false),
      isPushActive: this.boolean(false),
      hasEmailNotifications: this.boolean(false),
      hasGlasses: this.boolean(false),
      hasDocumentsComplete: this.boolean(false),
      hasDocumentsApproved: this.boolean(false),
      hasNotes: this.boolean(false),
      orderIndex: this.attr(null),
      isExcludedFromAutomation: this.boolean(false),
      examOrganizationId: this.attr(null),
      examOrganization: this.attr(null),
      maxLessonsAmountPerWeek: this.attr(null),
      maxLessonsAmountPerDay: this.attr(null),
      maxLessonsDurationPerDay: this.attr(null),
      progress: this.attr([]),
      invoiceAddressId: this.attr(null),
      createdBeforeBasePriceFeature: this.boolean(false),
      servicesCount: this.attr(null),
      clickAndLearnEnabled: this.boolean(false),
      clickAndLearnLicenseId: this.attr(null),
      educationStartedAt: this.attr(null),
      deletionWillRefundCredits: this.boolean(false),
      createdUsingCredits: this.boolean(false),
      hasInsightsEnabled: this.boolean(false),
      licensesWithInsightsTemplate: this.attr([]),
      additionalDetails: this.attr(null)
    };
  }

  get licenseProgress() {
    const progress = []
    this.progress.forEach(item => {
      progress.push({ license: item.id, progress: item.pivot })
    })
    return progress
  }

  get categoryName() {
    const categoryObj = ProductCategoryModel.find(this.priceCategoryId) || {};
    return categoryObj.name;
  }

  get address() {
    let address = this.street;

    if (this.houseNr) address += " " + this.houseNr;
    if (this.postcode) address += ", " + this.postcode;
    if (this.city) address += " " + this.city;

    return address;
  }

  get isDeleted() {
    return this.deletedAt !== null;
  }

  get ageInYearsAndMonths() {
    return `${this.age.y} ${i18n.tc('label.years', this.age.y)}, ${this.age.m} ${i18n.tc('label.months', this.age.m)}`
  }

  get invoiceAddress() {
    return {
      id: null,
      name: this.fullName,
      clientFirstName: this.firstName,
      clientLastName: this.lastName,
      street: this.street,
      houseNr: this.houseNr,
      postcode: this.postcode,
      city: this.city
    }
  }
}
