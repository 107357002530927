<template>
  <v-dialog
    v-model="show"
    width="1300"
    persistent
    @keydown.esc="close"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    scrollable
  >
    <v-card loading>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>{{
          $t("label.create_or_edit_teacher", {
            createOrEdit: isEdit ? $t("label.edit") : $t("label.create"),
          })
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="mt-5" style="height: 100%">
        <div class="d-flex justify-center pt-5" v-if="loadingItem">
          <v-progress-circular color="primary" indeterminate />
        </div>

        <ValidationObserver ref="form" v-show="!loadingItem">
          <v-row class="ma-0">
            <v-col cols="12" sm="12" md="4" lg="4">
              <v-row>
                <v-col cols="12" class="d-flex justify-center mt-3">
                  <UploadBtn @file-picked="onFilePick" accept="image/jpeg,image/png" input-name="avatar">
                    <v-avatar class="elevation-2" size="150">
                      <v-img v-if="avatarImage" :src="avatarImage"></v-img>
                      <v-icon v-else size="100">mdi-account</v-icon>
                    </v-avatar>
                  </UploadBtn>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="d-flex justify-center">
                  <ValidationProvider v-slot="{ errors }" name="color">
                    <v-sheet class="d-flex py-2 px-3" outlined rounded>
                      <span class="v-label mr-2">Color</span>

                      <div style="width: 150px">
                        <v-menu
                          class="mb-2"
                          v-model="colorMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <div class="btn__color-picker">
                              <div
                                style="
                                  padding: 7px;
                                  width: 100%;
                                  border-radius: 4px;
                                "
                                :style="{ 'background-color': form.color }"
                                v-bind="attrs"
                                v-on="on"></div>
                            </div>
                            <span v-if="errors.length" class="red--text">{{
                              errors[0]
                            }}</span>
                          </template>
                          <v-color-picker
                            v-model="form.color"
                            hide-inputs></v-color-picker>
                        </v-menu>
                      </div>
                    </v-sheet>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <div class="mb-2">
                <span class="text-subtitle-1 font-weight-medium">{{
                  $t("label.profile")
                }}</span>
                <v-divider />
              </div>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-text-field
                    outlined
                    dense
                    v-model="form.personalId"
                    :label="$t('form.teacher_ID')"
                    :placeholder="$t('form.teacher_ID')"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <ValidationProvider v-slot="{ errors }" name="email">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.email"
                      :label="$t('form.email')"
                      :placeholder="$t('form.email')"
                      type="email"
                      required
                      :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <ValidationProvider v-slot="{ errors }" name="password">
                    <v-text-field
                      outlined
                      dense
                      v-model="form.password"
                      :label="$t('form.password')"
                      :placeholder="$t('form.password')"
                      type="text"
                      required
                      :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-sheet class="py-2 px-3" outlined rounded>
                    <v-switch
                      class="ml-1 mt-0"
                      dense
                      v-model="form.active"
                      inset
                      hide-details
                      :label="$t('list.active')"
                      placeholder=""
                      color="primary"></v-switch>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="12" md="8" lg="8">
              <v-tabs v-model="activeTab" :show-arrows="false" :color="hasActiveTabErrors ? 'error' : 'primary'">
                <v-tab>
                  <v-badge
                      icon="mdi-check"
                      :color="invalidTabFields['personal'].length ? 'error' : 'primary'"
                      :content="invalidTabFields['personal'].length"
                      :value="!!invalidTabFields['personal'].length"
                  >
                    <span :class="{ 'error--text' :  invalidTabFields['personal'].length}">
                      {{ $t("label.personal_info") }}
                    </span>
                  </v-badge>
                </v-tab>
                <v-tab>
                  <v-badge
                      icon="mdi-check"
                      :color="invalidTabFields['school'].length ? 'error' : 'primary'"
                      :content="invalidTabFields['school'].length"
                      :value="!!invalidTabFields['school'].length"
                  >
                    <span :class="{ 'error--text' :  invalidTabFields['school'].length}">
                      {{$t("label.school") }}
                    </span>
                  </v-badge>
                </v-tab>
                <v-tab>
                    {{ $t("label.appointments_settings") }}
                </v-tab>
                <v-tabs-items v-model="activeTab">
                  <v-tab-item eager :transition="false" class="pt-5">
                    <div class="mb-3">
                      <span class="text-subtitle-1 font-weight-medium">{{ $t("label.personal_info") }}</span>
                      <v-divider />
                    </div>

                    <v-row dense>
                      <v-col cols="12" sm="6" md="12" lg="6">
                        <ValidationProvider v-slot="{ errors }" name="firstName">
                          <v-text-field
                              outlined
                              dense
                              v-model="form.firstName"
                              :label="$t('form.first_name')"
                              :placeholder="$t('form.first_name')"
                              :error-messages="errors"
                              required></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="6" md="12" lg="6">
                        <ValidationProvider v-slot="{ errors }" name="lastName">
                          <v-text-field
                              outlined
                              dense
                              v-model="form.lastName"
                              :label="$t('form.last_name')"
                              :placeholder="$t('form.last_name')"
                              :error-messages="errors"></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <ValidationProvider v-slot="{ errors }" name="birthDate">
                          <v-menu
                              v-model="menuBirthDate"
                              :close-on-content-click="false"
                              :nudge-bottom="-20"
                              transition="scale-transition"
                              offset-y
                              min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  dense
                                  :value="birthDateFormat"
                                  :label="$t('form.birthday')"
                                  :placeholder="$t('form.birthday')"
                                  readonly
                                  outlined
                                  v-bind="attrs"
                                  v-on="on"
                                  :error-messages="errors"
                                  clearable
                                  @click:clear="form.birth_date = ''" />
                            </template>
                            <v-date-picker
                                ref="birthDatePicker"
                                @input="onInputBirthDate"
                                :value="minDateOfBirthFromToday"
                                no-title
                                scrollable
                                :max="minDateOfBirthFromToday" />
                          </v-menu>
                        </ValidationProvider>
                      </v-col>
                    </v-row>

                    <div class="mb-3">
                      <span class="text-subtitle-1 font-weight-medium">{{$t("label.contact") }}</span>
                      <v-divider />
                    </div>

                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <ValidationProvider v-slot="{ errors }" name="phone">
                          <v-text-field
                              outlined
                              dense
                              v-model="form.phone"
                              :label="$t('form.phone_mobile')"
                              :placeholder="$t('form.phone_mobile')"
                              type="phone"
                              required
                              :error-messages="errors"></v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>

                    <div class="mb-3">
                      <span class="text-subtitle-1 font-weight-medium">{{$t("label.address")}}</span>
                      <v-divider />
                    </div>

                    <v-row dense>
                      <v-col cols="12" sm="6" md="5">
                        <ValidationProvider v-slot="{ errors }" name="street">
                          <v-text-field
                              outlined
                              dense
                              v-model="form.street"
                              :label="$t('form.street')"
                              :placeholder="$t('form.street')"
                              type="text"
                              required
                              :error-messages="errors"/>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <ValidationProvider v-slot="{ errors }" name="houseNr">
                          <v-text-field
                              outlined
                              dense
                              v-model="form.houseNr"
                              :label="$t('form.house')"
                              :placeholder="$t('form.house')"
                              type="text"
                              required
                              :error-messages="errors"/>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        <ValidationProvider v-slot="{ errors }" name="postcode">
                          <v-text-field
                              outlined
                              dense
                              v-model="form.postcode"
                              :label="$t('form.plz')"
                              :placeholder="$t('form.plz')"
                              type="text"
                              required
                              :error-messages="errors"/>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <ValidationProvider v-slot="{ errors }" name="city">
                          <v-text-field
                              outlined
                              dense
                              v-model="form.city"
                              :label="$t('form.city')"
                              :placeholder="$t('form.city')"
                              type="text"
                              required
                              :error-messages="errors"/>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item eager  :transition="false" class="pt-5">
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <ValidationProvider v-slot="{ errors }" name="officeId">
                          <v-select
                              outlined
                              dense
                              v-model="form.officeId"
                              :label="$t('form.office')"
                              :placeholder="$t('form.office')"
                              :items="offices"
                              required
                              :error-messages="errors"></v-select>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <ValidationProvider v-slot="{ errors }" name="licensesIds">
                          <v-select
                              outlined
                              dense
                              v-model="form.licensesIds"
                              :label="$t('form.licence')"
                              :placeholder="$t('form.licence')"
                              :items="licenses"
                              multiple
                              required
                              clearable
                              :error-messages="errors"></v-select>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <ValidationProvider v-slot="{ errors }" name="lessonDuration">
                          <v-text-field
                              :label="$t('form.standard_lesson_duration')"
                              :placeholder="$t('form.standard_lesson_duration')"
                              outlined
                              dense
                              v-model="form.lessonDuration"
                              class="mt-0 pt-0"
                              hide-details
                              type="number"
                              :error="errors.length > 0"
                              readonly
                          />
                          <v-slider
                              label=""
                              v-model="form.lessonDuration"
                              class="align-center"
                              :max="90"
                              :min="30"
                              :step="5"
                              :error-messages="errors"
                          />
                        </ValidationProvider>
                      </v-col>
                      <!--                  <v-col cols="12" sm="12">-->
                      <!--                    <ValidationProvider v-slot="{ errors }" name="vehiclesIds">-->
                      <!--                      <v-select outlined dense v-model="form.vehiclesIds" label="Vehicles" placeholder="Vehicles" :items="vehicles" multiple required clearable :error-messages="errors"></v-select>-->
                      <!--                    </ValidationProvider>-->
                      <!--                  </v-col>-->
                    </v-row>
                  </v-tab-item>
                  <v-tab-item eager  :transition="false" class="pt-5 px-2">
                    <SettingsResponsiveRow
                        :title="$t('settings.teacher.no_confirmation')"
                        :description="$t('settings.teacher.no_confirmation_description')"
                    >
                      <v-switch
                          class="mt-0"
                          dense
                          v-model="form.studentCanCreatePractice"
                          :label="$t('label.enabled')"
                          hide-details
                          inset
                      />
                    </SettingsResponsiveRow>
                    <SettingsResponsiveRow
                        :title="$t('settings.teacher.only_placeholders')"
                        :description="$t('settings.teacher.only_placeholders_description')"
                        compact
                    >
                      <v-switch
                          class="mt-0"
                          dense
                          v-model="form.onlyPlaceholdersBooking"
                          :label="$t('label.enabled')"
                          hide-details
                          inset
                      />
                    </SettingsResponsiveRow>

                    <SettingsResponsiveRow
                        :title="$t('settings.teacher.show_busy_time')"
                        :description="$t('settings.teacher.show_busy_time_description')"
                        compact
                    >
                      <template #description>
                        <v-alert class="mt-1" v-if="! form.onlyPlaceholdersBooking" border="left" colored-border type="info" dense elevation="1">
                          <span class="text--secondary">
                            {{ $t('settings.teacher.show_busy_time_warn') }}
                          </span>
                        </v-alert>
                      </template>

                      <v-switch
                          class="mt-0"
                          dense
                          v-model="form.showStudentsAllTeachersEvents"
                          :disabled="!form.onlyPlaceholdersBooking"
                          :label="$t('label.enabled')"
                          hide-details
                          inset
                      />
                    </SettingsResponsiveRow>
                    <SettingsResponsiveRow
                        :title="$t('settings.teacher.automatic_placeholders')"
                        :description="$t('settings.teacher.automatic_placeholders_description')"
                        :divider-after="false"
                        compact
                    >
                      <v-switch
                          class="mt-0"
                          dense
                          v-model="form.automaticPlaceholder"
                          :label="$t('label.enabled')"
                          hide-details
                          inset
                      />
                    </SettingsResponsiveRow>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>
      <v-divider class="mt-auto" />
      <v-card-actions v-if="!loadingItem">
        <v-btn text @click="close">
          {{ $t("btn.close") }}
        </v-btn>

        <v-spacer />

        <v-btn
          color="primary"
          @click="save"
          :loading="loadingForm"
          :disabled="loadingForm || loadingItem"
        >
          <v-icon left>mdi-content-save{{isEdit ? '-edit' : ''}}</v-icon>
          {{ $t("btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import teachersService from "@/services/teachersService";
import UploadBtn from "@/components/UploadBtn";
import { format, sub } from "date-fns";
import TeacherModel from "@/store/models/TeacherModel";
import SettingsResponsiveRow from "@/components/settings/SettingsResponsiveRow";
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: "TeacherFormDialog",
  components: {
    UploadBtn,
    SettingsResponsiveRow
  },
  data: () => ({
    show: false,
    isEdit: false,
    item: null,
    offices: [],
    licenses: [],
    vehicles: [],
    initialForm: {},
    avatarImage: "",
    username: "",
    activeTab: "",
    form: {
      personalId: "",
      avatar: "",
      color: "#EEEEEE",
      firstName: "",
      lastName: "",
      birthDate: "",
      email: "",
      phone: "",
      password: "",
      city: "",
      street: "",
      houseNr: "",
      postcode: "",
      officeId: "",
      licensesIds: [],
      vehiclesIds: [],
      carsIds: [],
      active: true,
      lessonDuration: 0,
      studentCanCreatePractice: false,
      onlyPlaceholdersBooking: false,
      showStudentsAllTeachersEvents: false,
      automaticPlaceholder: false,
    },
    menuBirthDate: false,
    colorMenu: false,
    loadingItem: false,
    loadingForm: false,
    imageUrl: "",
    imageFile: "",
    formObserver: { errors: {} },
  }),
  watch: {
    menuBirthDate(val) {
      val &&
        setTimeout(() => (this.$refs.birthDatePicker.activePicker = "YEAR"));
    },
    "form.onlyPlaceholdersBooking"(val) {
      if (!val) this.form.showStudentsAllTeachersEvents = false;
    },
  },
  computed: {
    imageAvatar() {
      if (typeof this.form.avatar === "string") return this.form.avatar;

      return this.imageURL;
    },
    birthDateFormat() {
      return this.form.birthDate
        ? format(new Date(this.form.birthDate), "dd.MM.yyyy")
        : "";
    },
    minDateOfBirthFromToday() {
      return format(sub(new Date(), { years: 18 }), "yyyy-MM-dd");
    },
    hasActiveTabErrors () {
      const invalidTabKeys = Object.keys(this.invalidTabFields) || [];
      const invalidTabErrors = this.invalidTabFields[invalidTabKeys[this.activeTab]] || [];

      return invalidTabErrors.length > 0;
    },
    invalidTabFields() {
      const tabsFields = {
        personal: ["firstName", "lastName"],
        school: ["licensesIds"],
        appointmentSettings: [],
      };

      Object.keys(tabsFields).forEach((tab) => {
        tabsFields[tab] = tabsFields[tab].filter((field) => {
          return this.formObserver.errors[field]
              ? !!this.formObserver.errors[field].length > 0
              : false;
        });
      });

      return tabsFields;
    }
  },
  created() {
    this.initialForm = { ...this.form };
  },
  methods: {
    open(item) {
      this.show = true;

      this.loadFormData();

      if (item) {
        this.isEdit = true;
        this.loadingItem = true;
        teachersService
          .loadItem(item.id)
          .then((resp) => {
            const data = resp.data;

            this.item = resp.data;

            this.username = data.username;

            this.form.personalId = data.personalId;
            this.form.color = data.color;
            this.form.firstName = data.firstName;
            this.form.lastName = data.lastName;
            this.form.birthDate = data.birthDate;
            this.form.email = data.email;
            this.form.phone = data.phone;
            this.form.active = data.active;
            this.form.city = data.city;
            this.form.street = data.street;
            this.form.houseNr = data.houseNr;
            this.form.postcode = data.postcode;
            this.form.officeId = data.officeId;
            this.form.vehiclesIds = data.vehiclesIds.map((item) =>
              item.toString()
            );
            this.form.licensesIds = data.licensesIds.map((item) =>
              item.toString()
            );
            this.form.lessonDuration = data.lessonDuration.toString();
            this.form.studentCanCreatePractice = data.studentCanCreatePractice;
            this.form.onlyPlaceholdersBooking = data.onlyPlaceholdersBooking;
            this.form.showStudentsAllTeachersEvents =
              data.showStudentsAllTeachersEvents;
            this.form.automaticPlaceholder = data.automaticPlaceholder;

            this.avatarImage = data.avatar;
          })
          .catch(console.log)
          .finally(() => {
            this.loadingItem = false;
          });
      }
    },
    close() {
      this.show = false;

      setTimeout(() => {
        this.isEdit = false;
        this.form = { ...this.initialForm };
        this.avatarImage = "";
        this.item = null;
        this.$refs.form.reset();
      }, 200);
    },
    loadFormData() {
      teachersService
        .loadFormData()
        .then((resp) => {
          this.formObserver = this.$refs.form

          this.offices = resp.data.offices;
          this.licenses = resp.data.licenses;
          this.vehicles = resp.data.vehicles;

          this.offices = this.offices.map((office) => ({
            text: office.address,
            value: office.id,
          }));
          this.licenses = Object.keys(this.licenses).map((id) => ({
            text: this.licenses[id],
            value: id,
          }));
          this.vehicles = Object.keys(this.vehicles).map((id) => ({
            text: this.vehicles[id],
            value: id,
          }));
        })
        .catch(console.log);
    },
    save() {
      const formData = new FormData();
      Object.keys(this.form).forEach((field) => {
        const value = this.form[field];

        if (!value) return;

        if (Array.isArray(value)) {
          value.map((item) => formData.append(field + "[]", item));
        } else {
          formData.set(field, value);
        }
      });

      if (this.item) {
        formData.set("user_id", this.item.userId);
        formData.set("_method", "PUT");
      }

      this.loadingForm = true;
      this[this.isEdit ? "update" : "store"](formData)
        .catch((error) => {
          const responseData = error.response.data;
          const errors = responseData.errors || [];

          if (responseData.error === 'email_in_use_by_deleted_user') {
            showConfirm(
                this.$t('label.email_address_conflict'),
                this.$t('messages.user_with_same_email_already_exists', {userType: this.$t('user_type.teacher')}),
                () => {
                  this.form.releaseUsedEmail = true
                  this.save()
                },
            )
            return
          }

          if (Object.keys(errors).length === 0 && responseData.message)
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });

          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    store(data) {
      return teachersService
        .create(data)
        .then((response) => {
          TeacherModel.insert({ data: response.data });

          this.$emit("create", response.data);
          this.close();
        })
    },
    update(data) {
      return teachersService
        .update(this.item.id, data)
        .then((response) => {
          TeacherModel.update({ where: this.item.id, data: response.data });

          this.$emit("update", response.data);
          this.close();
        })
    },
    onFilePick(url, file) {
      this.avatarImage = url;
      this.form.avatar = file;
    },
    onInputBirthDate(value) {
      this.form.birthDate = value;
      this.menuBirthDate = false;
    },
  },
};
</script>

<style scoped></style>
