<template>
  <ValidationProvider name="lesson-type" :rules="`${validation ? 'required' : ''}`" v-slot="{ errors }">
    <v-select
      :error="errors.length > 0"
      :disabled="disabled"
      v-model="lessonTypeId"
      :label="$t('form.lesson_type')"
      :items="lessonTypesList"
      item-value="id"
      :item-text="lessonTypeName"
      outlined
      hide-details
      dense
      :menu-props="{ offsetY: true }"
    >
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title :class="item.disabled ? 'text-decoration-line-through' : ''">
            {{ $tc(item.name, 1) }}
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action v-if="item.doesNotHavePrice">
          <v-list-item-action-text>
            {{ $t('label.missing_prices') }}
            <v-icon color="orange" small>mdi-alert</v-icon>
          </v-list-item-action-text>
        </v-list-item-action>
      </template>
    </v-select>
  </ValidationProvider>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LessonTypesField",
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    lessonTypesList: {
      type: Array,
      required: true,
    },
    lessonTypeProp: {
      type: Number,
    },
    validation: {
      type: Boolean,
      required: false,
    },
    mandatory: {
      type: Boolean,
      default: true
    },
    createdPlaceholderApp: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.mandatory) {
      const lessonType = this.currentAppointment.id && !this.createdPlaceholderApp && this.lessonTypeProp
        ? this.lessonTypeProp
        : this.lessonTypesList[0].id
      this.$emit("emitLessonTypeSelect", lessonType);
    }
  },
  computed: {
    ...mapGetters("appointments", { currentAppointment: "currentAppointmentGetter" }),
    lessonTypeId: {
      set(value) {
        this.$emit("emitLessonTypeSelect", value);
      },
      get() {
        return this.lessonTypeProp;
      },
    },
  },
  methods: {
    lessonTypeName(item) {
      return this.$tc(item.name, 1)
    }
  }
};
</script>

<style lang="scss" scoped></style>
