<template>
  <v-row v-if="evaluationsWithAppointments.length" dense>
    <v-col>
      <v-card>
        <v-sheet rounded class="d-flex justify-space-between align-center">
          <v-card-title class="py-2">
            {{ $t('insights.awaiting_your_evaluation') }}
          </v-card-title>
          <div>
            <v-btn icon small @click="$refs.instructionsDialog.open()" class="mr-2">
              <v-icon>mdi-information</v-icon>
            </v-btn>
            <v-btn icon small @click="load(true)" :loading="reloading" class="mr-2">
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </div>

        </v-sheet>

        <v-divider />

        <v-card-text>
          <UpcomingAppointmentsLoader v-if="loading" :count="3" :days="false" />
          <template v-else>
            <empty-box v-if="!evaluationsWithAppointments.length" :image="'assets/svg/calendar-dark.svg'"/>
            <v-row v-else dense>
              <v-col cols="12" sm="6" mb="4" lg="3" v-for="evaluation in evaluationsWithAppointments" :key="evaluation.id">
                <v-card
                  class="fill-height"
                  outlined
                  :style="`border-left: 10px solid ${evaluation.appointment.bodyColor};`"
                  @click="handleEvaluationClick(evaluation)"
                >
                  <v-card-text class="px-2 py-1 subtitle-2">
                    <div class="d-flex justify-space-between">
                      <span>
                        {{ evaluation.appointment.date }} &middot; {{ evaluation.appointment.startTime }}
                      </span>
                    </div>
                    <div>
                      {{ evaluation.appointment.teacherName }},
                      {{ $tc(evaluation.appointment.license.name) }} - {{ $tc(evaluation.appointment.lessonType.name) }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
          <StudentEvaluationDialog ref="studentEvaluationDialog"/>
        </v-card-text>
      </v-card>
    </v-col>
    <student-feature-instructions-dialog ref="instructionsDialog"/>
  </v-row>
</template>

<script>
import UpcomingAppointmentsLoader from "@/components/skeleton-loaders/UpcomingAppointmentsLoader"
import insightsService from '@/services/insightsService'
import user from '@/utils/mixins/user'
import AppointmentModel from '@/store/models/AppointmentModel'
import StudentEvaluationDialog from '@/components/insights/StudentEvaluationDialog.vue'
import StudentFeatureInstructionsDialog from "@/components/insights/StudentFeatureInstructionsDialog.vue";

export default {
  name: "AwaitingEvaluationWidget",
  mixins: [user],
  components: {StudentFeatureInstructionsDialog, StudentEvaluationDialog, UpcomingAppointmentsLoader },
  data() {
    return {
      progress: [],
      loading: true,
      reloading: false,
    }
  },
  mounted () {
    this.load()
  },
  computed: {
    evaluationsWithAppointments() {
      return this.progress
        .map(evaluation => ({...evaluation, appointment: new AppointmentModel(evaluation.appointmentData)}))
    }
  },
  methods: {
    handleEvaluationClick(evaluation) {
      this.$router.push({name: 'calendar.appointment', params: {id: evaluation.appointmentId}})
    },
    load(reload = false) {
      this.loading = ! reload
      this.reloading = reload
      insightsService.getStudentLicensesProgress(this.currentUser.id)
        .then(resp => {
          this.progress = resp.data || {}
        })
        .finally(() => {
          this.loading = false
          this.reloading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">

</style>
