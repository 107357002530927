<template>
  <div class="calendar-filters pa-2">
    <v-expansion-panels accordion :value="0">
      <SidebarCalendar v-if="mdLandscapeAndUp" class="mb-3" :titleFontSize="1"/>
    </v-expansion-panels>

    <StudentBalanceWidget
        v-if="isStudent && hasPaymentAbility && $vuetify.breakpoint.mobile"
        :student="currentUser"
        class="mb-3"
    />

    <v-expansion-panels accordion multiple :value="[0, 1]" class="mb-3">
      <TeachersFilter
        v-if="!isTeacher"
        :teachers="teachers"
        :activeTeachers="activeTeachersIds"
        @calendarToggle="toggleUserCalendarState"
      />

      <EventsFilter
        v-if="!isStudent"
        :eventTypes="eventTypes"
        @eventsToggle="toggleEvent"
      />
    </v-expansion-panels>

    <WorkingHoursWeekly v-if="isTeacher"/>

    <StudentEventsLimitCard
        v-if="currentStudentHasAnyLimits"
        :student="currentUser"
        :date="activeDate"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SidebarCalendar from "@/components/calendar/SidebarCalendar";
import TeachersFilter from "@/components/calendar/TeachersFilter";
import EventsFilter from "@/components/calendar/EventsFilter";
import WorkingHoursWeekly from "@/components/calendar/WorkingHoursWeekly";
import TeacherModel from "@/store/models/TeacherModel";
import EventTypeModel from "@/store/models/EventTypeModel";
import user from "@/utils/mixins/user";
import responsive from "@/utils/mixins/responsive";
import StudentModel from "@/store/models/StudentModel";
import StudentEventsLimitCard from "@/components/calendar/StudentEventsLimitCard";
import loadAppointments from "@/utils/mixins/loadAppointments";
import StudentBalanceWidget from '@/components/student/StudentBalanceWidget'
import * as teachersMutationTypes from '@/store/mutation-types/teachers'

export default {
  name: "CalendarFilters",
  mixins: [user, responsive, loadAppointments],
  components: {
    SidebarCalendar,
    StudentBalanceWidget,
    TeachersFilter,
    EventsFilter,
    WorkingHoursWeekly,
    StudentEventsLimitCard,
  },
  mounted () {
    const teachers = TeacherModel.all();
    const teachersIds = teachers.map((teacher) => teacher.id);
    const activeTeachersIds = this.activeTeachersIds.filter((activeTeacherId) => teachersIds.includes(activeTeacherId));
    this.$store.commit(`teachers/${teachersMutationTypes.SET}`, activeTeachersIds);

    if (this.isStudent) {
      const teachers = TeacherModel.query()
          .where('active', true)
          .whereIdIn(this.currentUser.teachersIds)
          .get() || []
      if (!this.activeTeachersIds.length) teachers.forEach((teacher) => this.calendarActivate(teacher.id));
    } else {
      const teachers = TeacherModel.query().where('active', true).get() || []
      if (!this.activeTeachersIds.length) this.calendarActivate(teachers[0].id);
    }
  },
  computed: {
    ...mapGetters(["activeDate"]),
    ...mapGetters("appointments", ["activeEventsGetter"]),
    ...mapGetters("teachers", {
      activeTeachers: "active",
      activeTeachersIds: "activeIds",
    }),
    eventTypes() {
      const allTypes = EventTypeModel.all() || {};
      return this.isSchoolAdministrator
        ? allTypes
        : allTypes.filter((type) => type.id !== "SIMULATOR");
    },
    teachers() {
      if (this.isStudent || this.isElterWithSelectedStudent) {
        return TeacherModel.query()
          .where("id", this.currentUser.teachersIds)
          .where("active", true)
          .get();
      } else {
        return TeacherModel.query().where("active", true).get();
      }
    },
    studentBalance() {
      const student = StudentModel.find(this.currentUser.id) || {};
      return { total: Number(student.balance), available: Number(student.availableBalance) };
    },
    currentStudentHasAnyLimits() {
      return this.isStudent &&
          (this.currentUser.maxLessonsAmountPerWeek !== null ||
          this.currentUser.maxLessonsAmountPerDay !== null ||
          this.currentUser.maxLessonsDurationPerDay !== null)
    }
  },
  methods: {
    ...mapActions("appointments", ["appointmentFilterToggle"]),
    ...mapActions("teachers", {
      calendarActivate: "enable",
      calendarDeactivate: "disable",
    }),

    onNavigationDrawerInput(value) {
      this.$store.commit("main/toggle-filters-menu", value);
    },
    toggleUserCalendarState(teacherId) {
      if (this.activeTeachersIds.includes(teacherId)) {
        if (this.activeTeachersIds.length > 1) {
          this.calendarDeactivate(teacherId)
        }
      } else {
        this.calendarActivate(teacherId);
      }
    },
    toggleEvent(type) {
      this.appointmentFilterToggle(type);
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-filters::v-deep {
  .v-expansion-panel-header {
    padding: 10px 20px !important;
    min-height: auto !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 0 10px !important;
  }
}
</style>
