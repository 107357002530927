<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <v-card>
      <v-card-title class="headline">{{ $t('label.yearly_overview') }}</v-card-title>
      <v-card-text>
        <div class="d-flex flex-row" style="width: 100%;">
          <apexchart
              type="bar"
              :options="chartOptions"
              :series="barSeries"
              height="350"
              class="flex-grow-1"
          />
          <div class="flex-shrink-1 ml-2" style="width: 250px">
            <v-select
                solo
                :items="years"
                v-model="selectedYear"
                @change="fetchData"
            ></v-select>
            <apexchart
                type="pie"
                :options="pieChartOptions"
                :series="pieSeries"
                height="100"
            />
            <v-list dense>
              <v-list-item dense>
                <v-list-item-title class="font-weight-bold">{{ $t('label.pass_rate') }}</v-list-item-title>
                <v-list-item-action-text>{{ passRate }}%</v-list-item-action-text>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-title>{{ $t('label.exams_total') }}</v-list-item-title>
                <v-list-item-action-text>{{ totalExams }}</v-list-item-action-text>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-title>{{ $t('label.exams_passed') }}</v-list-item-title>
                <v-list-item-action-text>{{ totalPassed }}</v-list-item-action-text>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-title>{{ $t('label.exams_failed') }}</v-list-item-title>
                <v-list-item-action-text>{{ totalFailed }}</v-list-item-action-text>
              </v-list-item>
            </v-list>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="closeDialog">{{ $t('btn.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import examStatisticsService from "@/services/examStatisticsService";

export default {
  name: "TeacherYearlyStatisticsDialog",
  components: {apexchart: VueApexCharts},
  props: {
    teacherId: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      dialog: false,
      selectedYear: new Date().getFullYear(),
      rawData: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: false,
          toolbar: {
            show: false,
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '80%',
            endingShape: 'rounded'
          },
        },
        xaxis: {
          categories: [
            '01', '02', '03', '04', '05', '06',
            '07', '08', '09', '10', '11', '12'
          ],
        },
        yaxis: {
          title: {
            text: this.$i18n.t('label.number_of_exams')
          },
          forceNiceScale: true
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center'
        },
        colors: ['#00435e', '#ff5252'],
      },
      pieChartOptions: {
        labels: [this.$i18n.t('label.passed'), this.$i18n.t('label.failed')],
        colors: ['#00435e', '#ff5252'],
        dataLabels: {
          enabled: false,
        },
      },
    };
  },
  computed: {
    years() {
      const currentYear = new Date().getFullYear();
      const startYear = 2015;
      const years = [];
      for (let year = currentYear; year >= startYear; year--) {
        years.push(year);
      }
      return years;
    },
    barSeries() {
      if (!this.rawData.length) return [];

      const passedExams = [];
      const failedExams = [];
      const categories = this.chartOptions.xaxis.categories;

      categories.forEach((month) => {
        const monthData = this.rawData.find((data) => data.month === month);
        passedExams.push({
          x: month,
          y: monthData?.passed ?? 0,
        });
        failedExams.push({
          x: month,
          y: monthData?.failed ?? 0,
        });
      });

      return [
        {
          name: this.$i18n.t('label.passed'),
          data: passedExams,
        },
        {
          name: this.$i18n.t('label.failed'),
          data: failedExams,
        }
      ];
    },
    pieSeries() {
      const passed = this.rawData.reduce((sum, data) => sum + data.passed, 0);
      const failed = this.rawData.reduce((sum, data) => sum + data.failed, 0);
      return [passed, failed];
    },
    totalExams() {
      return this.rawData.reduce((sum, data) => sum + data.passed + data.failed, 0);
    },
    totalPassed() {
      return this.rawData.reduce((sum, data) => sum + data.passed, 0);
    },
    totalFailed() {
      return this.rawData.reduce((sum, data) => sum + data.failed, 0);
    },
    passRate() {
      const total = this.totalExams;
      const passed = this.totalPassed;
      return total ? ((passed / total) * 100).toFixed(2) : 0;
    },
  },
  methods: {
    openDialog() {
      this.dialog = true;
      this.fetchData();
    },
    closeDialog() {
      this.dialog = false;
    },
    async fetchData() {
      // Fetch data based on the selected year.
      // This is a placeholder for your data fetching logic.
      // Replace this with your API call.
      const data = await this.getDataForYear(this.selectedYear);

      this.rawData = data;
    },
    async getDataForYear(year) {
      console.log(this.teacherId, year)
      const { data } = await examStatisticsService.monthlyByTeacherAndYear(this.teacherId, year)
      return data
    },
  },
};
</script>
