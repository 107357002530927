<template>
  <v-dialog
    v-model="show"
    width="700"
    @click:outside="close"
    @keydown.esc="close">
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>{{ $t("label.invoice_creation_settings") }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <div class="justify-center pt-10" :class="loading ? 'd-flex' : 'd-none'">
          <v-progress-circular color="primary" indeterminate />
        </div>
        <v-list v-show="!loading" dense>
          <v-list-item v-if="school.isInvoicesAutomationEnabled" two-line>
            <v-list-item-avatar>
              <v-icon dark class="grey lighten-1">mdi-autorenew-off</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("label.exclude_from_automation") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("label.exclude_from_automation_description") }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch
                v-model="isExcludedFromAutomation"
                :loading="loading"
                :disabled="loading"
                dense
                hide-details
                :ripple="false"
                label=""
              />
            </v-list-item-action>
          </v-list-item>

          <v-list-item three-line>
            <v-list-item-avatar>
              <v-icon dark class="grey lighten-1">mdi-map-marker-star-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("label.default_invoicing_address") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("label.default_invoicing_address_description") }}
              </v-list-item-subtitle>
              <v-autocomplete
                dense
                clearable
                outlined
                autocomplete="default-address"
                v-model="invoiceAddressId"
                :loading="loadingAddresses"
                :disabled="loadingAddresses"
                :menu-props="{ offsetY: true, contentClass: 'fit-parent-width' }"
                :items="addresses"
                item-text="name"
                item-value="id"
                hide-details
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <template v-if="item.id !== null">{{ `${item.clientFirstName} ${item.clientLastName}, ` }}</template>
                      {{ formattedAddress(item) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-list-item-subtitle>
                {{ selectedAddressLabel }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="saving"
          :loading="saving"
          color="primary"
          @click="save"
        >
          <v-icon left>mdi-content-save</v-icon>
          {{ $t("btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import studentsService from "@/services/studentsService";
import StudentModel from "@/store/models/StudentModel";
import invoiceAddressesService from '@/services/invoiceAddressesService'
import { mapGetters } from 'vuex'

export default {
  name: "InvoicingSettingsDialog",
  data: () => ({
    show: false,
    loading: true,
    saving: false,
    loadingAddresses: true,
    studentId: null,
    student: null,
    isExcludedFromAutomation: null,
    invoiceAddressId: null,
    invoiceAddresses: []
  }),
  computed: {
    ...mapGetters("school", ["school"]),
    selectedAddressLabel() {
      let address = this.addresses.find(item => item.id === this.invoiceAddressId)
      if (! address) return ''
      return (address.id !== null ? `${address.clientFirstName} ${address.clientLastName}, `: '') +
          `${address.street} ${address.houseNr}, ${address.postcode}, ${address.city}` +
          (address.country ? `, ${address.country}` : '')
    },
    addresses () {
      const addresses = []
      if (this.student) {
        addresses.push({
          id: null,
          name: this.student.fullName,
          clientFirstName: this.student.firstName,
          clientLastName: this.student.lastName,
          street: this.student.street,
          houseNr: this.student.houseNr,
          postcode: this.student.postcode,
          city: this.student.city
        })
      }
      return [...addresses, ...this.invoiceAddresses]
    }
  },
  methods: {
    open(studentId) {
      this.show = this.loading = true;
      this.studentId = studentId
      studentsService
        .loadItem(studentId)
        .then((resp) => {
          this.isExcludedFromAutomation = resp.data.isExcludedFromAutomation;
          this.student = resp.data
          this.invoiceAddressId = resp.data.invoiceAddressId
        })
        .finally(() => {
          this.loading = false;
        });

      this.loadingAddresses = true
      invoiceAddressesService
        .load()
        .then(response => {
          this.invoiceAddresses = response.data || []
        })
        .finally(() => {
          this.loadingAddresses = false
        })
    },
    close() {
      this.show = false;

      setTimeout(() => {
        this.isExcludedFromAutomation = null;
        this.invoiceAddressId = null
      }, 200)
    },
    save() {
      this.saving = true;
      studentsService
        .updateInvoicingSettings(this.studentId, {
          isExcludeFromAutomation: this.isExcludedFromAutomation,
          invoiceAddressId: this.invoiceAddressId,
        })
        .then((resp) => {
          StudentModel.update({ data: resp.data });
          this.close();
        })
        .finally(() => {
          this.saving = false;
        });
    },
    formattedAddress(item) {
      let address = `${item.street} ${item.houseNr}, ${item.postcode}, ${item.city}`

      if (item.country) {
        address += `, ${item.country}`
      }

      return address
    }
  },
};
</script>

<style scoped lang="scss"></style>
