<template>
  <v-col cols="6" md="6" sm="12">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-bind="attrs"
          v-on="on"
          v-model="licenseName"
          :disabled="disabled"
          :label="$t('form.notifications')"
          @click:clear="clearNotificationLicenses"
          readonly
          outlined
          dense
          hide-details
          clearable />
      </template>
      <v-card>
        <v-treeview
          v-model="model.notificationLicenses"
          :items="groupedLicenses"
          selectable
          dense />
      </v-card>
    </v-menu>
  </v-col>
</template>

<script>
import LicenseGroupModel from "@/store/models/LicenseGroupModel";
import LicenseModel from "@/store/models/LicenseModel";
import TeacherModel from "@/store/models/TeacherModel";
import { mapGetters } from "vuex";

export default {
  name: "PlaceholderNotificationField",
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    "model.allowedLicenses"(val) {
      if (!val || !val.length) this.model.notificationLicenses = [];
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    groupedLicenses() {
      const teacher = TeacherModel.find(this.model.teacherId) || {};
      const validLicenses =
        this.model.allowedLicenses && this.model.allowedLicenses.length
          ? this.model.allowedLicenses
          : teacher.licensesIds;
      const teacherLicenses =
        LicenseModel.query().whereIdIn(validLicenses).get() || [];
      const teacherGroups = teacherLicenses.map((license) => license.groupId);
      const groups =
        LicenseGroupModel.query()
          .whereIdIn([...new Set(teacherGroups)])
          .get() || [];

      return groups.map((groupItem) => {
        const parent = {
          ...groupItem,
          id: groupItem.id + "i",
          groupId: groupItem.id,
        };
        const license =
          LicenseModel.query()
            .whereIdIn(validLicenses)
            .where("groupId", parent.groupId)
            .get() || [];
        return { ...parent, children: license };
      });
    },
    licenseName: {
      get() {
        const licenses =
          LicenseModel.query()
            .whereIdIn(this.model.notificationLicenses)
            .get() || [];
        return licenses.map((license) => license.name);
      },
      set(val) {
        return val;
      },
    },
  },
  methods: {
    clearNotificationLicenses() {
      this.model.notificationLicenses = [];
    },
  },
};
</script>

<style lang="scss" scoped></style>
