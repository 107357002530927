<script>
import appointment from "@/components/calendar/Appointment.vue";

export default {
  name: 'AppointmentsTable',
  computed: {
    appointment() {
      return appointment;
    }
  },
  props: {
    appointments: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    practiceStatus: ['REQUESTED', 'MISSED', 'MISSED_REFUNDED', 'MISSED_PARTIALLY_REFUNDED', 'JOKER_REFUNDED'],
    practiceStatusIcon: {
      REQUESTED: 'mdi-help-circle',
      MISSED: 'mdi-call-missed',
      MISSED_REFUNDED: 'mdi-cash-refund',
      MISSED_PARTIALLY_REFUNDED: 'mdi-cash-refund',
      JOKER_REFUNDED: 'mdi-cash-refund',
    }
  })
}
</script>

<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
      <tr>
        <th style="width: 100px">{{ $t("label.date") }}</th>
        <th style="width: 130px">{{ $t("label.time") }}</th>
        <th style="width: 10px">{{ $t("label.duration") }}</th>
        <th style="width: 120px;">{{ $t('filters.type') }}</th>
        <th style="width: 160px;">{{ $t("label.lesson_type") }}</th>
        <th style="width: 160px;">{{ $t("label.license") }}</th>
        <th>{{ $t('label.teacher') }}</th>
        <th>{{ $t("label.students") }}</th>
        <th style="width: 40px;">{{ $t("label.status") }}</th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="appointment in appointments"
        :key="appointment.id"
        class="table-row cursor-pointer"
        @click="$router.push({name: 'calendar.appointment', params: {id: appointment.id}})"
      >
        <td :style="{'border-left': `3px solid ${appointment.bodyColor}`}">
          {{ appointment.date }}
        </td>
        <td>{{ appointment.startTime }} - {{ appointment.endTime }}</td>
        <td>{{ appointment.duration }}</td>
        <td>{{ $t("event_type." + appointment.type) }}</td>
        <td class="text-truncate" style="max-width: 140px">
          <span v-if="appointment.type === 'SPECIAL'">{{ $t(`event_type.SPECIAL_TYPES.${appointment.specialType}`)
            }}</span>
          <span v-else-if="appointment.lessonType">{{ $tc(appointment.lessonType.name) }}</span>
          <span v-else>&mdash;</span>
        </td>
        <td>
          <span v-if="appointment.license">{{ appointment.license.name }}</span>
          <span v-if="appointment.type === 'THEORY' && appointment.theoryTopic?.type === 'basic'">{{ $t("label.all")
            }}</span>
          <span
            v-if="!appointment.license && appointment.allowedLicenses.length > 0 && appointment.theoryTopic?.type !== 'basic'">
            {{ appointment.allowedLicensesList.map(license => license.name).join(", ") }}
          </span>
          <span v-if="!appointment.license && appointment.allowedLicenses.length === 0">&mdash;</span>
        </td>
        <td>
          <template v-if="appointment.teacher">
              <span :class="{'font-italic disabled': appointment.teacher.deletedAt || !appointment.teacher.active}">
                {{ appointment.teacher.fullName }}
              </span>
            <v-icon v-if="appointment.teacher.deletedAt" class="mb-1 ml-1 disabled" small>mdi-trash-can</v-icon>
          </template>
          <span v-else>&mdash;</span>
        </td>
        <td>
          <span v-if="appointment.studentsIds.length > 0 && appointment.student">
            <span :class="{'font-italic disabled': appointment.student.deletedAt || !appointment.student.active}">
              {{ appointment.student.fullName }}
            </span>
            <v-icon v-if="appointment.student.deletedAt" class="mb-1 ml-1 disabled" small>mdi-trash-can</v-icon>
            <span v-if="appointment.studentsIds.length > 1">+ {{ appointment.studentsIds.length }}</span>
          </span>
          <span v-else>&mdash;</span>
        </td>
        <td>
          <div class="d-flex">
            <v-tooltip v-if="appointment.passedExam" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-check-decagram</v-icon>
              </template>
              {{ $t("label.passed_exam") }}
            </v-tooltip>
            <v-tooltip v-if="practiceStatus.includes(appointment.status)" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-if="practiceStatusIcon[appointment.status]" v-bind="attrs" v-on="on">
                  {{ practiceStatusIcon[appointment.status] }}
                </v-icon>
                <span v-else>{{ appointment.status }}</span>
              </template>
              {{ $t("eventStatus." + appointment.status) }}
            </v-tooltip>
            <v-tooltip v-if="appointment.isManualB197" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-alpha-s-box</v-icon>
              </template>
              {{ $t("lesson_type.manual_lessons") }}
            </v-tooltip>
            <v-tooltip v-if="appointment.isWorkTime" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-timer-outline</v-icon>
              </template>
              {{ $t("label.working_time") }}
            </v-tooltip>
            <v-tooltip v-if="appointment.boughtAt" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-currency-eur</v-icon>
              </template>
              {{ $t("label.charged") }}
            </v-tooltip>
          </div>
        </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<style scoped lang="scss">
.hidden-until-row-hovered {
  display: none;
}
.table-row:hover > td > .hidden-until-row-hovered {
  display: inline-flex;
}
</style>
