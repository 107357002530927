<script>
import {defineComponent} from 'vue'
import clickAndLearnService from "@/services/clickAndLearnService";

export default defineComponent({
  name: "AddExistingLicenseDialog",
  data() {
    return {
      show: false,
      student: null,
      loading: false,
      datepicker: false,
      form: {
        licenseCode: null,
        assignedAt: null,
        username: null,
      }
    }
  },
  methods: {
    open(student) {
      this.show = true
      this.student = student
      this.$refs.form?.reset()
    },
    close() {
      this.show = false
      setTimeout(() => {
        this.form.licenseCode = null
        this.form.assignedAt = null
        this.form.username = null
      }, 200)
    },
    save() {
      this.$refs.form?.reset()
      this.loading = true
      clickAndLearnService.addExistingLicense({...this.form, studentId: this.student.id})
        .then(response => {
          this.$emit('license-added', response.data)
          this.close()
        })
        .catch((error) => {
          const responseData = error.response.data || {};
          const errors = responseData.errors || [];
          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error" });
          }

          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    savingDisabled() {
      return !this.student || !this.form.licenseCode || !this.form.assignedAt || !this.form.username
    }
  }
})
</script>

<template>
  <v-dialog width="600" :value="show" @click:outside="close" @keydown.esc="close" scrollable>
    <v-card>
      <v-toolbar class="mb-4" dark color="primary" dense>
        <v-toolbar-title>{{ $t('clickandlearn.add_existing_license') }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <p>{{ $t('clickandlearn.add_existing_license_explanation_line_1') }}</p>
        <p>{{ $t('clickandlearn.add_existing_license_explanation_line_2') }}</p>
        <p>{{ $t('clickandlearn.add_existing_license_explanation_line_3') }}</p>
        <p class="font-weight-bold">{{ $t('clickandlearn.add_existing_license_explanation_line_4') }}</p>

        <ValidationObserver ref="form">
          <v-row dense class="mt-1">
            <v-col>
              <ValidationProvider v-slot="{ errors }" name="username">
                <v-text-field
                  outlined
                  :error-messages="errors"
                  :disabled="loading"
                  :hide-details="! $refs.form?.errors?.username || ! $refs.form?.errors?.username?.length"
                  v-model="form.username"
                  :label="$t('label.username')"
                  required
                  dense
                  autocomplete="off"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <ValidationProvider v-slot="{ errors }" name="licenseCode">
                <v-text-field
                  outlined
                  :error-messages="errors"
                  :disabled="loading"
                  :hide-details="! $refs.form?.errors?.licenseCode || ! $refs.form?.errors?.licenseCode?.length"
                  v-model="form.licenseCode"
                  :label="$t('clickandlearn.license_code')"
                  required
                  dense
                  placeholder="z.B. PM-12345678"
                  autocomplete="off"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                ref="menu"
                v-model="datepicker"
                :close-on-content-click="false"
                :return-value.sync="form.assignedAt"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider v-slot="{ errors }" name="assignedAt">
                    <v-text-field
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      prepend-inner-icon="mdi-calendar"
                      :error-messages="errors"
                      :disabled="loading"
                      :hide-details="! $refs.form?.errors?.assignedAt || ! $refs.form?.errors?.assignedAt?.length"
                      v-model="form.assignedAt"
                      :label="$t('clickandlearn.license_code_assigned_at')"
                      required
                      dense
                      readonly
                    />
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="form.assignedAt"
                  reactive
                  no-title
                  scrollable
                  :max="new Date().toISOString().substr(0, 10)"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="datepicker = false"
                  >
                    {{ $t('btn.cancel') }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(form.assignedAt)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn text :disabled="loading" @click="close">{{ $t('btn.close') }}</v-btn>
        <v-spacer />
        <v-btn color="primary" @click="save" :disabled="savingDisabled" :loading="loading">
          <v-icon left>mdi-key-plus</v-icon>
          {{ $t('btn.register') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>
