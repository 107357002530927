import api from "@/utils/api";
import laravelEcho from "@/plugins/laravel-echo";

export default {
  loadRooms(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/messenger/rooms", { params: params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadRoom(roomId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/messenger/rooms/${roomId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadParticipants(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/messenger/participants", { params: params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadParticipant(userId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/messenger/participant/${userId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadMessages(params) {
    return new Promise((resolve, reject) => {
      const roomId = params.roomId;

      if (params.roomId) delete params.roomId;

      api
        .get(`/messenger/rooms/${roomId}/messages`, { params: params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  openRoom(params) {
    return new Promise((resolve, reject) => {
      api
        .post("/messenger/rooms/dialog", params)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch(reject);
    });
  },
  sendMessage(params) {
    return new Promise((resolve, reject) => {
      api
        .post(
          `/messenger/rooms/${params.roomId}/messages/store`,
          {
            id: params.id,
            message: params.message,
            createdAt: params.createdAt,
          },
          {
            headers: {
              "X-Socket-Id": laravelEcho.socketId(),
            },
          }
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
