<template>
<Page>
  <PageNavBar>
    <PageToolBar
        is-filters
        is-search
        :has-toggle-list-view="false"
        :create-btn-name="$t('label.create_address')"
        @search="fillSearchText"
        @clickCreateItem="openAddressDialog"
    >
      <template v-slot:filters-body></template>
    </PageToolBar>
  </PageNavBar>

  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="py-2 d-flex justify-space-between">
            <div class="d-flex flex-column">
              <span>{{ $t("route.offices") }}</span>
            </div>
          </v-card-title>

          <v-divider/>

          <v-card-text>
            <OfficesTable :search-text="filter.text" @edit="id => openAddressDialog('office', id)"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="py-2 d-flex justify-space-between">
            <div class="d-flex flex-column">
              <span>{{ $t("route.meetingPlaces") }}</span>
            </div>
          </v-card-title>

          <v-divider/>

          <v-card-text>
            <MeetingPlaceTable :search-text="filter.text" @edit="id => openAddressDialog('meetingPlace', id)"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="py-2 d-flex justify-space-between">
            <div class="d-flex flex-column">
              <span>{{ $t("label.invoice_address") }}</span>
            </div>
          </v-card-title>

          <v-divider/>

          <v-card-text>
            <InvoiceAddressesTable :search-text="filter.text" @edit="id => openAddressDialog('invoiceAddress', id)"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <AddressFormDialog ref="addressFormDialog"/>
  </v-container>

  <v-btn
    v-if="$vuetify.breakpoint.mobile"
    fixed
    right
    bottom
    fab
    small
    class="mr-2"
    color="primary"
    @click="openAddressDialog"
  >
    <v-icon>mdi-plus</v-icon>
  </v-btn>
</Page>
</template>

<script>
import AddressFormDialog from '@/components/AddressFormDialog.vue'
import MeetingPlaceTable from '@/components/meetingPlace/MeetingPlaceTable.vue'
import OfficesTable from '@/components/office/OfficesTable.vue'
import PageNavBar from '@/components/page/PageNavBar.vue'
import Page from '@/components/page/Page.vue'
import PageToolBar from '@/components/page/PageToolBar.vue'
import InvoiceAddressesTable from '@/components/invoices/InvoiceAddressesTable.vue'

export default {
  name: 'Addresses',
  components: {
    InvoiceAddressesTable,
    Page,
    PageToolBar,
    PageNavBar,
    OfficesTable,
    MeetingPlaceTable,
    AddressFormDialog
  },
  data () {
    return {
      activeTab: 'offices',
      filter: {
        text: ''
      }
    }
  },
  methods: {
    openAddressDialog(type = null, item = null) {
      this.$refs.addressFormDialog.open(type, item)
    },
    fillSearchText(value) {
      this.filter.text = value ? value.trim().toLowerCase() : null
    }
  }
}
</script>

<style scoped>

</style>
