import { Model } from "@vuex-orm/core";
export default class EventTypeModel extends Model {
  static entity = "eventTypes";

  static fields() {
    return {
      id: this.attr(null),
      bodyColor: this.string(""),
      fontColor: this.string(""),
    };
  }
}
