import { obj } from './fieldsByAppointmentType';

export default class AppointmentFields {
  constructor (appData) {
    this.data = appData;
  }

  formObject (type, action) {
    const requiredFields = type in obj && action in obj[type] ? obj[type][action] : [];
    return this.pick(this.data, requiredFields);
  }

  pick(object, keys) {
    return keys.reduce((obj, key) => {
      if (object && key in object) {
        obj[key] = object[key];
      }
      return obj;
    }, {});
  }
}
