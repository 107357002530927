<template>
  <v-container fluid>
    <v-card :loading="loading || filtering">
      <v-toolbar elevation="3">
        <v-card-title>{{ $t("label.invoices") }}</v-card-title>
        <v-spacer />
        <v-btn text color="primary" @click="$refs.createInvoiceDialog.openStudentless()">{{$t('btn.create')}}</v-btn>
      </v-toolbar>

      <v-card-text v-if="loading || filtering">
        <v-skeleton-loader class="mx-auto" type="table-row@3" />
      </v-card-text>
      <template v-else>
        <v-card-text >
          <v-row class="my-1">
            <v-col cols="12" lg="4" class="d-flex">
              <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="date"
                  persistent
                  width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      solo
                      dense
                      readonly
                      hide-details
                      :value="formattedDates"
                      :label="$t('label.filter_by_date')"
                      prepend-inner-icon="mdi-calendar"
                      clearable
                      @click:clear="date = []"
                      v-bind="attrs"
                      v-on="on">
                  </v-text-field>
                </template>
                <v-date-picker v-model="date" scrollable range>
                  <v-spacer/>
                  <v-btn text color="primary" @click="modal = false">{{ $t("btn.cancel") }}</v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(date.sort())"
                  >
                    {{ $t("btn.ok") }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>

              <v-menu
                  :close-on-content-click="false"
                  offset-y
                  v-model="filterMenuOpened">
                <template v-slot:activator="{ on, attrs }">
                  <v-badge
                      color="primary"
                      overlap
                      :content="filtersCount"
                      :value="filtersCount"
                  >
                    <v-btn class="ml-3" color="primary" text v-bind="attrs" v-on="on" icon>
                      <v-icon>mdi-filter-outline</v-icon>
                    </v-btn>
                  </v-badge>
                </template>

                <v-card>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t("label.creation_mode") }}</v-list-item-title>
                        <v-radio-group
                            v-model="filterValues.isManuallyCreated"
                            dense
                            hide-details>
                          <v-radio
                              :value="1"
                              :ripple="false"
                              :label="$t('label.manual')" />
                          <v-radio
                              :value="0"
                              :ripple="false"
                              :label="$t('label.automatic')" />
                        </v-radio-group>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t("label.payment_status") }}</v-list-item-title>
                        <v-radio-group
                            v-model="filterValues.isPaid"
                            dense
                            hide-details>
                          <v-radio
                              :value="1"
                              :ripple="false"
                              :label="$t('label.paid')" />
                          <v-radio
                              :value="0"
                              :ripple="false"
                              :label="$t('label.open')" />
                        </v-radio-group>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t("label.belongs_to_a_student") }}</v-list-item-title>
                        <v-radio-group
                            v-model="filterValues.hasUser"
                            dense
                            hide-details>
                          <v-radio
                              :value="1"
                              :ripple="false"
                              :label="$t('btn.yes')" />
                          <v-radio
                              :value="0"
                              :ripple="false"
                              :label="$t('btn.no')" />
                        </v-radio-group>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t("label.cancellation_status") }}</v-list-item-title>
                        <v-radio-group
                            v-model="filterValues.isCanceled"
                            dense
                            hide-details>
                          <v-radio
                              :value="0"
                              :ripple="false"
                              :label="$t('label.valid')" />
                          <v-radio
                              :value="1"
                              :ripple="false"
                              :label="$t('label.canceled')" />
                        </v-radio-group>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn x-small text color="red" @click="clearFilters">{{ $t("btn.clear") }}</v-btn>
                    <v-spacer />
                    <v-btn text color="primary" @click="filter">{{ $t("btn.ok") }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="7" lg="5">
              <v-btn
                  text
                  color="primary"
                  :loading="loading || filtering"
                  :disabled="filtering"
                  @click="filter"
              >
                {{ $t("btn.filter_results") }}
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  :disabled="filtering || downloadingArchive"
                  :loading="downloadingArchive"
                  v-if="selectedInvoices.length"
                  @click="archive">
                <v-icon left>mdi-download</v-icon>
                {{ $t("btn.create_archive") }}
                <span v-if="selectedInvoices.length">({{ selectedInvoices.length }})</span>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="5" lg="3">
              <Pagination
                  v-if="pagination.total > 0"
                  :loading="loading"
                  :from="pagination.from"
                  :to="pagination.to"
                  :current-page="pagination.currentPage"
                  :total-nr-of-pages="pagination.lastPage"
                  :total-nr-of-items="pagination.total"
                  @pageChange="onPageChange"
              />
            </v-col>
          </v-row>

          <v-alert
              type="info"
              v-if="selectedInvoices.length > 5 && downloadingArchive"
          >
            Sie haben viele Rechnungen zum Archivieren ausgewählt. Dies kann
            einige Zeit dauern. Bitte haben Sie Geduld...
          </v-alert>
        </v-card-text>

        <InvoicesTable
            v-if="invoices.length"
            :invoices="invoices"
            :totalSelectedInvoices="selectedInvoices"
            @selectionChange="onSelectionChange"
            @invoiceSent="updateInvoicesArray"
            @invoiceCancelled="updateInvoicesArray"
        />

        <v-alert v-else>
          <empty-box />
        </v-alert>
      </template>
    </v-card>
    <create-invoice-dialog ref="createInvoiceDialog" studentless  />
  </v-container>
</template>

<script>
import formattedDatesMixin from "@/utils/mixins/formattedDatesMixin";
import invoicesService from "@/services/invoicesService";
import InvoicesTable from "@/components/invoices/InvoicesTable";
import { format, parseISO, startOfMonth } from "date-fns";
import axios from "axios";
import JSZip from "jszip";
import FileSaver from "@/helpers/fileSaver";
import Pagination from '@/components/Pagination.vue'
import CreateInvoiceDialog from "@/components/invoices/CreateInvoiceDialog.vue";

export default {
  name: "Index",
  components: {CreateInvoiceDialog, Pagination, InvoicesTable },
  mixins: [formattedDatesMixin],
  data() {
    return {
      loading: true,
      filtering: false,
      modal: false,
      invoices: [],
      filterMenuOpened: false,
      filterValues: {
        isManuallyCreated: null,
        isPaid: null,
        isCanceled: 0,
        hasUser: null,
      },
      selectedInvoices: [],
      downloadingArchive: false,
      pagination: {
        currentPage: 1,
        from: 0,
        lastPage: 1,
        to: 0,
        total: 0,
      },
    };
  },
  mounted() {
    if (this.$route.query.filters) {
      const filters = structuredClone(this.$route.query.filters);
      const filterDates = [];
      if ("startDate" in filters) {
        filterDates.push(format(parseISO(filters.startDate), "yyyy-MM-dd"));
      }
      if ("endDate" in filters) {
        filterDates.push(format(parseISO(filters.endDate), "yyyy-MM-dd"));
      }
      if (filterDates.length > 0) this.date = filterDates;

      if ("isManuallyCreated" in filters) {
        this.filterValues.isManuallyCreated = parseInt(
          filters.isManuallyCreated
        );
      }

      if ("isPaid" in filters) {
        this.filterValues.isPaid = parseInt(filters.isPaid);
      }

      if ("isCanceled" in filters) {
        this.filterValues.isCanceled = parseInt(filters.isCanceled);
      }

      if ("hasUser" in filters) {
        this.filterValues.hasUser = parseInt(filters.hasUser);
      }
    } else {
      const startDate = format(startOfMonth(new Date()), "yyyy-MM-dd");
      const endDate = format(new Date(), "yyyy-MM-dd");
      this.date = [startDate, endDate];
    }

    this.filter();
  },
  computed: {
    filters() {
      const params = {};
      if (this.startDate) params.startDate = this.startDate;
      if (this.endDate) params.endDate = this.endDate;
      if (this.filterValues.isManuallyCreated !== null)
        params.isManuallyCreated = parseInt(
          this.filterValues.isManuallyCreated
        );
      if (this.filterValues.isPaid !== null)
        params.isPaid = parseInt(this.filterValues.isPaid);
      if (this.filterValues.isCanceled !== null)
        params.isCanceled = parseInt(this.filterValues.isCanceled);
      if (this.filterValues.hasUser !== null)
        params.hasUser = parseInt(this.filterValues.hasUser);

      return params;
    },
    filtersCount() {
      return this.date.length === 1
        ? Object.keys(this.filters).length - 1
        : Object.keys(this.filters).length;
    },
    filtersURLEncoded() {
      const queryStringParams = [];
      Object.keys(this.filters).forEach((key) => {
        queryStringParams.push(`filters[${key}]=${this.filters[key]}`);
      });
      return Object.keys(this.filters).length
        ? `?${queryStringParams.join("&")}`
        : "";
    },
  },
  methods: {
    loadInvoices(params = {}) {
      invoicesService
        .load({ ...params, perPage: 50})
        .then((resp) => {
          const responseData = resp.data || {};

          this.invoices = responseData.data || [];
          this.pagination = responseData.pagination || {};
        })
        .finally(() => {
          this.loading = this.filtering = false;
        });
    },
    filter() {
      this.filtering = true;
      this.filterMenuOpened = false;
      this.loadInvoices(this.filters);
      this.selectedInvoices = [];
      const page = `${this.filtersURLEncoded ? '&' : '?'}page=1`
      this.$router.replace(this.$route.path + this.filtersURLEncoded + page);
    },
    clearFilters() {
      this.filterValues = {
        isManuallyCreated: null,
        isPaid: null,
        isCanceled: null,
        hasUser: null,
      };
    },
    archive() {
      if (!this.selectedInvoices.length) return false;

      this.downloadingArchive = true;

      const archiveRequests = this.selectedInvoices
          .reverse()
          .map((invoice) => invoicesService.downloadInvoice(invoice.id));

      axios
        .all(archiveRequests)
        .then(
          axios.spread((...responses) => {
            if (responses.length > 1) {
              const zip = new JSZip();

              responses.forEach((response, index) => {
                const invoice = this.selectedInvoices[index];
                zip.file(invoice.fileName, response.data || {});
              });

              zip.generateAsync({ type: "blob" }).then((content) => {
                const file = new FileSaver([content])
                file.setType('blob').saveToDevice(`Rechnungen_${responses.length}.zip`, 'Rechnungen')
              });
            } else if (responses.length === 1) {
              const invoice = this.selectedInvoices[0]
              const fileSaver = new FileSaver([responses[0].data])

              fileSaver.setType('application/pdf').saveToDevice(invoice.fileName, 'Anwesenheitsliste')
            }
          })
        )
        .catch((err) => console.log(err))
        .finally(() => {
          this.downloadingArchive = false;
        });
    },
    updateInvoicesArray(obj) {
      this.invoices[obj.index] = obj.invoice;
    },
    onPageChange() {
      this.loading = true
      this.loadInvoices({ ...this.filters, page: this.$route.query.page || 1 });
    },
    onSelectionChange(selectedInvoices) {
      this.selectedInvoices = selectedInvoices
    }
  },
};
</script>

<style scoped lang="scss"></style>
