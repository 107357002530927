<template>
  <v-dialog
    min-width="750px"
    max-width="850px"
    :value="showModalGetter"
    :persistent="isMobile"
    @click:outside="close"
    :fullscreen="isMobile"
    :hide-overlay="isMobile"
    @keydown.esc="close"
    scrollable
  >
    <v-card :tile="isMobile" style="padding-top:0!important;">
      <TopBar class="flex-shrink-0 flex-grow-0" :color="appointmentColor.bodyColor" :happyHour="false" />

      <AppointmentTypesTabs :color="appointmentColor.bodyColor" :createdPlaceholderApp="createdPlaceholderApp"
                            class="my-2" />

      <v-divider/>

      <v-card-text class="mt-3 pt-1">
        <template v-if="skeleton">
          <component :is="skeletonComponent" />
        </template>

        <template v-else>
          <FormDateTimeArea
              :color="appointmentColor.bodyColor"
              :disabledDate="areDisabledDateAndDurationFields"
              :disabledTime="isDisabledTimeField"
              :disabledDuration="areDisabledDateAndDurationFields"
          />
          <ValidationObserver ref="observer">
            <component
                class="mt-3"
                :is="formComponent"
                :teachers="teachers"
                @emitPickRequestedStudent="pickRequestedStudent"
                :placeholderRequestedStudent=placeholderRequestedStudent
                :createdPlaceholderApp="createdPlaceholderApp"
            />
          </ValidationObserver>
        </template>

        <AppointmentEvaluationBox
            v-if="currentAppointment.isAvailableForEvaluation"
            :appointmentId="currentAppointment.id"
        />

        <v-alert
            v-if="simulatorStudentHasNoPriceList || placeholderStudentHasNoPriceList || practiceStudentWarning"
            class="mt-9"
            dense
            type="warning"
            border="left"
            colored-border
            elevation="1"
        >
          {{ alertText }}
        </v-alert>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <Button
          @click.native="closeAppointmentModal"
          :text="true" icon="mdi-close"
          :label="$t('btn.close')" :disabled="appointmentLoader"
        />

        <v-spacer />

        <v-btn
          v-if="isQRScanningAvailable"
          :disabled="!isWithinValidScanTimeRange"
          :to="`/scanner/${currentAppointment.id}`"
          color="grey darken-2" outlined
        >
          <v-icon>mdi-qrcode-scan</v-icon>
        </v-btn>

        <Button
            v-if="showPracticeMissedBtn"
            @click.native="setMissedApp"
            color="grey darken-2"
            icon="mdi-call-missed"
            :label="$t('btn.missed')"
            :disabled="false"
            :loading="false"
            outlined
        />

        <template v-if="showPracticeRefundBtn">
          <Button
            v-if="showJokerRefundButton"
            @click.native="refundWithJoker"
            color="grey darken-2"
            icon="$jokerHat"
            :label="$t('btn.joker_refund')"
            :disabled="disabledJoker || appointmentLoader"
            :loading="buttonLoaders.jokerRefundLoader"
            outlined
          />

          <Button
            @click.native="$refs.refundDialog.open(currentAppointment.id)"
            color="grey darken-2"
            icon="mdi-cash-refund"
            :label="$t('btn.refund')"
            :disabled="appointmentLoader"
            :loading="buttonLoaders.refundLoader"
            outlined
          />
        </template>


        <template v-if="showSignatureBtn">
          <Button
            @click.native="signatureClick"
            class="mr-2"
            color="grey darken-2"
            icon="mdi-signature-freehand"
            :label="currentAppointment.hasSignature ? $t('label.show_signature') : $t('label.sign')"
            :disabled="appointmentLoader"
            :loading="buttonLoaders.signatureLoader"
            outlined
          />
          <SignatureField
            :appointmentId="currentAppointment.id"
            :show="showSignatureDialog"
            :signature="signatureState"
            @closeSignatureDialog="showSignatureDialog = false"
            @saveSignature="saveAppSignature"
          />
        </template>

        <Button
            v-if="showUpdateBtn && isMissedOrRefunded && !hasDeletedStudent"
            @click.native="updateApp"
            color="primary"
            icon="mdi-content-save-edit"
            :label="$t('btn.update')"
            :disabled="appointmentLoader"
            :loading="buttonLoaders.updateLoader"
        />

        <template v-if="!isMissedOrRefunded && !hasDeletedStudent">
          <Button
              v-if="showDeleteBtn && showExamDeleteButton"
              @click.native="deleteApp"
              color="error" :icon="currentAppointment.type === 'PLACEHOLDER' ? 'mdi-delete' : 'mdi-archive-arrow-down'"
              :label="$t(currentAppointment.type === 'PLACEHOLDER' ? 'btn.delete' : 'btn.archive')"
              :disabled="appointmentLoader"
              :loading="buttonLoaders.deleteLoader"
          />

          <Button
              v-if="showSimulatorDeclineBtn"
              @click.native="declineSimulatorApp"
              color="error" icon="mdi-minus-circle"
              :label="$t('btn.decline')"
              :disabled="appointmentLoader"
              :loading="buttonLoaders.unsubscribeLoader"
          />

          <Button
              v-if="showUpdateBtn && !showTransformPlaceholderBtn"
              @click.native="updateApp"
              color="primary" icon="mdi-content-save-edit"
              :label="$t('btn.update')"
              :disabled="appointmentLoader || simulatorStudentHasNoPriceList"
              :loading="buttonLoaders.updateLoader"
          />

          <Button
              v-if="showCreateBtn || showTransformPlaceholderBtn"
              @click.native="createApp"
              color="primary" icon="mdi-content-save"
              :label="showTransformPlaceholderBtn ? $t('btn.update') : $t('btn.create')"
              :disabled="appointmentLoader"
              :loading="buttonLoaders.createLoader"
          />

          <template v-if="showRequestedPlaceholderBtn">
            <Button
                @click.native="declinePlaceholderApp"
                color="error" icon="mdi-minus-circle"
                :label="$t('btn.decline')"
                :disabled="appointmentLoader"
                :loading="buttonLoaders.declineLoader"
            />

            <Button
                @click.native="confirmPlaceholderApp"
                color="primary" icon="mdi-check-circle"
                :label="$t('btn.confirm')"
                :disabled="appointmentLoader || placeholderStudentHasNoPriceList"
                :loading="buttonLoaders.confirmLoader"
            />
          </template>

          <template v-if="showRequestedPracticeBtn">
            <Button
                @click.native="declinePracticeApp"
                color="error" icon="mdi-minus-circle"
                :label="$t('btn.decline')"
                :disabled="appointmentLoader"
                :loading="buttonLoaders.declineLoader"
            />

            <Button
                @click.native="confirmPracticeApp"
                color="primary" icon="mdi-check-circle"
                :label="$t('btn.confirm')"
                :disabled="appointmentLoader || practiceStudentHasNoPriceList"
                :loading="buttonLoaders.confirmLoader"
            />
          </template>
        </template>
      </v-card-actions>

    </v-card>

    <AppointmentRefundDialog ref="refundDialog" @refunded="partiallyRefundedSuccess" />
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import { parseISO, isPast, subMinutes, addMinutes, isWithinInterval, addDays } from 'date-fns'
import user from '@/utils/mixins/user'
import colorMixin from '@/utils/mixins/colorMixin'
import isMobileApp from '@/utils/mixins/isMobileApp'
import priceCategoryMixin from '@/utils/mixins/priceCategoryMixin'
import { showConfirm } from "@/helpers/dialogs"
import clearEvaluation from '@/helpers/appointmentEvaluation'

import AppointmentRefundDialog from "@/components/appointment/AppointmentRefundDialog"
import SignatureField from "@/components/calendar/appointmentModal/fields/SignatureField"
import Button from "@/components/calendar/appointmentModal/Button"
import TopBar from "@/components/calendar/appointmentModal/TopBar"
import FormDateTimeArea from "@/components/calendar/appointmentModal/fields/FormDateTimeArea"
import AppointmentTypesTabs from "@/components/calendar/appointmentModal/AppointmentTypesTabs"
import OfftimeForm from "@/components/calendar/appointmentModal/forms/OfftimeForm"
import PlaceholderForm from "@/components/calendar/appointmentModal/forms/PlaceholderForm"
import PracticeForm from "@/components/calendar/appointmentModal/forms/PracticeForm"
import SimulatorForm from "@/components/calendar/appointmentModal/forms/SimulatorForm"
import SpecialForm from "@/components/calendar/appointmentModal/forms/SpecialForm"
import TheoryForm from "@/components/calendar/appointmentModal/forms/TheoryForm"

import OfftimeLoader from "@/components/skeleton-loaders/appointment-form/OfftimeLoader"
import PlaceholderLoader from "@/components/skeleton-loaders/appointment-form/PlaceholderLoader"

import TeacherModel from "@/store/models/TeacherModel"
import StudentModel from '@/store/models/StudentModel'
import LicenseModel from '@/store/models/LicenseModel'
import AppointmentModel from '@/store/models/AppointmentModel'
import LessonTypeModel from '@/store/models/LessonTypeModel'
import AppointmentEvaluationBox from '@/components/calendar/appointmentModal/AppointmentEvaluationBox.vue'

export default {
  name: "AppointmentModal",
  mixins: [user, priceCategoryMixin, colorMixin, isMobileApp],
  components: {
    AppointmentEvaluationBox,
    AppointmentRefundDialog,
    SignatureField,
    Button,
    TopBar,
    AppointmentTypesTabs,
    FormDateTimeArea,

    OFFTIME_FORM: OfftimeForm,
    PLACEHOLDER_FORM: PlaceholderForm,
    PRACTICE_FORM: PracticeForm,
    SIMULATOR_FORM: SimulatorForm,
    SPECIAL_FORM: SpecialForm,
    THEORY_FORM: TheoryForm,

    OFFTIME_LOADER: OfftimeLoader,
    PLACEHOLDER_LOADER: PlaceholderLoader,
  },
  data: () => ({
    showSignatureDialog: false,
    skeleton: false,
    placeholderRequestedStudent: null,
  }),
  props: {
    currentAppointment: {
      type: Object,
      required: true,
    }
  },
  watch: {
    showModalGetter() {
      this.$refs.observer?.reset()
      this.skeleton = false
      this.placeholderRequestedStudent = null
      if (this.isTeacher) this.$store.commit(`appointments/UPDATE_APPOINTMENT_FIELD`, { teacherId: this.currentUser.id })
    },
    signatureState(val) {
      if (val) this.showSignatureDialog = true
    },
  },

  computed: {
    ...mapState("appointments", ["buttonLoaders", "signatureState", "initialAppointment"]),
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("appointments", ["showModalGetter", "initialDurationGetter"]),
    lessonType() {
      return LessonTypeModel.find(this.currentAppointment.lessonTypeId)
    },
    createdPlaceholderApp() {
      const app = AppointmentModel.find(this.currentAppointment.id)
      return app && app.type === 'PLACEHOLDER' ? app : null
    },
    showTransformPlaceholderBtn() {
      return this.createdPlaceholderApp && this.currentAppointment.type !== 'PLACEHOLDER'
    },
    licenseName() {
      return LicenseModel.find(this.currentAppointment.licenseId).name || ''
    },
    student() {
      return StudentModel.find(this.currentAppointment.studentsIds[0]) || {}
    },
    studentHasNoPriceList() {
      return this.student.bookAndPay && !this.student.priceCategoryId
    },
    simulatorStudentHasNoPriceList() {
      return this.currentAppointment.type === 'SIMULATOR' && this.studentHasNoPriceList
    },
    placeholderStudentHasNoPriceList() {
      const pickedStudent = StudentModel.find(this.placeholderRequestedStudent) || null
      return this.currentAppointment.type === 'PLACEHOLDER' && pickedStudent?.bookAndPay && !pickedStudent?.priceCategoryId
    },
    practiceStudentHasNoPriceList() {
      return this.showRequestedPracticeBtn && this.studentHasNoPriceList && this.lessonType?.payable !== 'no_cost'
    },
    practiceStudentWarning() {
      return this.currentAppointment.type === 'PRACTICE' && this.studentHasNoPriceList
    },
    alertText() {
      return this.$t('alert.add_price_list_for_student')
    },
    isQRScanningAvailable() {
      return this.isTeacher &&
          this.currentAppointment.type === 'THEORY' &&
          this.isMobileApp &&
          this.schoolSettings.isDigitalAttendanceEnabled &&
          this.currentAppointment.id
    },
    isWithinValidScanTimeRange() {
      const now = new Date()
      const start = subMinutes(parseISO(this.currentAppointment.start), 30)
      const end = addMinutes(parseISO(this.currentAppointment.start), this.currentAppointment.duration + 30)
      return isWithinInterval(now, { start, end })
    },
    isExamInPast() {
      /*commented past exam logic*/
      // const { id, start } = this.currentAppointment
      // return !!id && isPast(parseISO(start)) && (this.lessonType && !this.lessonType.canBeMovedInPast)
      return false
    },
    hasDeletedStudent() {
      return this.student.isDeleted
    },
    showSignatureBtn() {
      const { id, type, status } = this.currentAppointment
      const { isWorkTime } = AppointmentModel.find(id) || false
      return id && !this.isMissedOrRefunded && !this.hasDeletedStudent &&
          ((type === 'PRACTICE' && status === 'ACCEPTED') || type === 'SPECIAL' && isWorkTime)
    },
    showDeleteBtn() {
      return this.currentAppointment.id &&
          !this.showRequestedPracticeBtn &&
          !this.showRequestedPlaceholderBtn &&
          !this.showSimulatorDeclineBtn
    },
    showExamDeleteButton() {
      return this.isSchoolAdministrator || (this.isTeacher && !this.isExamInPast)
    },
    isMissedOrRefunded() {
      return ['MISSED', 'MISSED_PARTIALLY_REFUNDED', 'MISSED_REFUNDED', 'JOKER_REFUNDED'].includes(this.currentAppointment.status)
    },
    showPracticeMissedBtn() {
      const { id, status, hasSignature, start } = this.currentAppointment
      const createdAppointment = AppointmentModel.find(id)
      const { nonDeletablePeriod, studentCanDeletePractice } = this.schoolSettings
      const missedUntilDate = addDays(new Date(), nonDeletablePeriod)

      return createdAppointment?.lessonType?.canBeMissed
          && id
          && !hasSignature
          && (isPast(parseISO(start)) || studentCanDeletePractice && parseISO(start) < missedUntilDate)
          && status === 'ACCEPTED'
    },
    showPracticeRefundBtn () {
      return this.student.bookAndPay
        && this.lessonType?.canBeMissed
        && this.lessonType?.canBeRefunded
        && ['MISSED', 'MISSED_PARTIALLY_REFUNDED'].includes(this.currentAppointment.status)
    },
    showJokerRefundButton() {
      return this.schoolSettings.hasJoker && this.currentAppointment.status === 'MISSED' && this.lessonType?.canBeRefundedWithJoker
    },
    disabledJoker() {
      return !this.student.joker
    },
    showSimulatorDeclineBtn() {
      return this.currentAppointment.type === 'SIMULATOR' && this.currentAppointment.isSubscribed
    },
    showRequestedPlaceholderBtn() {
      return this.currentAppointment.type === 'PLACEHOLDER' && this.currentAppointment.isRequested
    },
    showRequestedPracticeBtn() {
      return this.currentAppointment.type === 'PRACTICE' && this.currentAppointment.status === 'REQUESTED'
    },
    showCreateBtn() {
      return !this.currentAppointment.id
    },
    showUpdateBtn() {
      return this.currentAppointment.id && !this.showRequestedPlaceholderBtn && !this.showRequestedPracticeBtn
    },
    appointmentLoader() {
      return this.$store.state.appointments.appointmentLoader || false
    },
    teachers() {
      if (this.currentAppointment.studentsIds.length && this.currentAppointment.type === 'PRACTICE') {
        const studentsTeachersIds = this.student.teachersIds
        return TeacherModel.query().whereIdIn(studentsTeachersIds).where("active", true).get()
      } else {
        return TeacherModel.query().where("active", true).get()
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    formComponent() {
      return this.currentAppointment.type + "_FORM"
    },
    skeletonComponent() {
      return this.currentAppointment.type + "_LOADER"
    },
    areDisabledDateAndDurationFields() {
      return this.isMissedOrRefunded
          || this.hasDeletedStudent
          || this.isExamInPast
          || this.placeholderStudentHasNoPriceList
    },
    isDisabledTimeField() {
      return this.isMissedOrRefunded
          || this.hasDeletedStudent
          || this.placeholderStudentHasNoPriceList
    },
  },
  methods: {
    ...mapActions("appointments", [
          "closeAppointmentModal",
          "createAppointment",
          "updateAppointment",
          "deleteAppointment",
          "confirmPlaceholder",
          "declinePlaceholder",
          "confirmPractice",
          "declinePractice",
          "unsubscribeFromSimulator",
          "setMissedStatus",
          "refundPractice",
          "saveSignature",
          "getSignature",
    ]),

    pickRequestedStudent (val) {
      this.placeholderRequestedStudent = val
    },

    async createApp () {
      const validate = await this.$refs.observer.validate()
      if (validate) this.createAppointment(this.showTransformPlaceholderBtn ?
          { oldPlaceholderId: this.createdPlaceholderApp.id, skipEmptyPlaceholders: true } : null)
    },

    async updateApp () {
      const validate = await this.$refs.observer.validate()
      if (validate) {
        const message = clearEvaluation(this.initialAppointment, this.currentAppointment)
          ? 'messages.are_your_sure_update_clear_evaluation'
          : 'messages.are_your_sure_update'
        await showConfirm(
            this.$t('btn.confirm'),
            this.$t(message),
            () => this.updateAppointment()
        )
      }
    },
    deleteApp () {
      const message = this.initialAppointment.evaluation?.id
        ? 'messages.are_your_sure_archive_clear_evaluation'
        : 'messages.are_your_sure_archive'

      showConfirm(
          this.$t('btn.confirm'),
          this.$t(this.currentAppointment.type === 'PLACEHOLDER' ? 'messages.are_your_sure_delete' : message),
          () => this.deleteAppointment(!!this.initialAppointment.evaluation?.id)
      )
    },
    async confirmPlaceholderApp () {
      const validate = await this.$refs.observer.validate()
      if (validate) {
        await showConfirm(
            this.$t('btn.confirm'),
            this.$t('messages.are_your_sure_confirm_placeholder'),
            () => this.confirmPlaceholder(this.placeholderRequestedStudent)
        )
      }
    },
    declinePlaceholderApp () {
      showConfirm(
          this.$t('btn.confirm'),
          this.$t('messages.are_your_sure_decline_placeholder'),
          () => this.declinePlaceholder()
      )
    },
    async confirmPracticeApp () {
      const validate = await this.$refs.observer.validate()
      if (validate) {
        await showConfirm(
            this.$t('btn.confirm'),
            this.$t('messages.are_your_sure_confirm_practice'),
            () => this.confirmPractice()
        )
      }
    },

    declinePracticeApp () {
      showConfirm(
          this.$t('btn.confirm'),
          this.$t('messages.are_your_sure_decline_practice'),
          () => this.declinePractice()
      )
    },

    declineSimulatorApp () {
      showConfirm(
          this.$t('btn.confirm'),
          this.$t('messages.are_your_sure_unsubscribe_simulator'),
          () => this.unsubscribeFromSimulator()
      )
    },

    setMissedApp() {
      const message = this.initialAppointment.evaluation?.id
        ? 'messages.are_your_sure_miss_clear_evaluation'
        : 'messages.are_your_sure_set_missed_practice'
      showConfirm(
          this.$t('btn.confirm'),
          this.$t(message),
          () => this.setMissedStatus(!!this.initialAppointment.evaluation?.id)
      )
    },

    refundWithJoker() {
      showConfirm(
          this.$t('btn.confirm'),
          this.$t('messages.are_your_sure_refund_with_joker'),
          () => this.refundPractice(true)
      )
    },

    saveAppSignature(signature) {
      showConfirm(
          this.$t('btn.confirm'),
          this.$t('messages.are_your_sure_sign_this_appointment'),
          () => this.saveSignature(signature)
      )
      this.showSignatureDialog = false
    },

    async signatureClick() {
      this.$store.commit(`appointments/SET_SIGNATURE`, '')
      if (this.currentAppointment.hasSignature) {
        this.getSignature();
      } else {
        this.showSignatureDialog = true;
      }
    },

    partiallyRefundedSuccess(response) {
      AppointmentModel.insert({ where: this.currentAppointment.id, data: response || {} });
      this.$store.commit(`appointments/UPDATE_APPOINTMENT_FIELD`, response);
    },

    close() {
      if (this.isMobile) return;

      this.closeAppointmentModal();
    }
  },
}
</script>

<style lang="scss" scoped>
.v-input__prepend-inner,
.v-input__append-inner {
  cursor: pointer;
}
.justify-center {
  display: flex;
  justify-content: space-between;
}
</style>
