<template>
  <div>
    <v-row>
      <v-col>
        <TeachersField
            @emitTeachersSelect="updateTeacherField"
            @emitRecalculation="recalculateAmount"
            :validation="true"
            :disabled="!!currentAppointment.id || isElter"
            :filteredTeachers="teachers"
            :teacherProp="currentAppointment.teacherId" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <LicenseField
            @emitLicenseSelect="updateLicenseField"
            :disabled="teacherIsNotSelected || !!currentAppointment.id || studentHasNoPriceList || isElter"
            :validation="true"
            :multiple="false"
            :licenseProp="currentAppointment.licenseId"
            :licenseIdsList="activeLicensesIds"
            :licenseStatus="practiceLicensesStatus"
        />
        <ManualLessonCheckboxField
            v-if="showIsManualLesson"
            @emitManualStatusChange="(val) => updateFields({ isManualLesson: val })"
            :disabled="teacherIsNotSelected || !!currentAppointment.id || studentHasNoPriceList || isElter"
            :statusProp="this.currentAppointment.isManualLesson" />
      </v-col>
      <v-col cols="6" v-if="showLessonTypesField">
        <LessonTypesField
            @emitLessonTypeSelect="updateLessonTypeField"
            :disabled="teacherIsNotSelected || licenseIsNotSelected || !!currentAppointment.id || isElter"
            :validation="true"
            :lessonTypeProp="currentAppointment.lessonTypeId"
            :lessonTypesList="lessonTypesList" />
      </v-col>
    </v-row>

    <v-row v-if="currentAppointment.primaryVehicleId">
      <v-col>
        <VehicleField
            :disabled="true"
            :label="$t('form.primary_vehicle')"
            :vehiclesList="primaryVehicles"
            :vehicleProp="currentAppointment.primaryVehicleId"
        />
      </v-col>
      <v-col>
        <VehicleField
            :disabled="true"
            :label="$t('form.secondary_vehicle')"
            :vehiclesList="secondaryVehicles"
            :vehicleProp="currentAppointment.secondaryVehicleId"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <MeetingPlaceField
            @emitMeetingPlaceSelect="(val) => updateFields({ meetingPlace: val })"
            :disabled="teacherIsNotSelected || !!currentAppointment.id || studentHasNoPriceList || isElter"
            :validation="false"
            :meetingPlacesList="placesGroups"
            :meetingPlaceProp="currentAppointment.meetingPlace" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <NotesField
            @emitNotesChange="(val) => updateFields({ notes: val })"
            :disabled="teacherIsNotSelected || !!currentAppointment.id || studentHasNoPriceList || isElter"
            :validation="false"
            :notesProp="currentAppointment.notes" />
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapGetters } from "vuex"
import user from '@/utils/mixins/user'
import placeholderPracticeMixin from '@/utils/mixins/placeholderPracticeMixin'
import priceCategoryMixin from '@/utils/mixins/priceCategoryMixin'

import TeachersField from "@/components/calendar/appointmentModal/fields/TeachersField"
import LicenseField from "@/components/calendar/appointmentModal/fields/LicenseField"
import ManualLessonCheckboxField from "@/components/calendar/appointmentModal/fields/ManualLessonCheckboxField"
import LessonTypesField from "@/components/calendar/appointmentModal/fields/LessonTypesField"
import VehicleField from "@/components/calendar/appointmentModal/fields/VehicleField"
import MeetingPlaceField from "@/components/calendar/appointmentModal/fields/MeetingPlaceField"
import NotesField from "@/components/calendar/appointmentModal/fields/NotesField"

import LicenseModel from "@/store/models/LicenseModel";
import TeacherModel from "@/store/models/TeacherModel";
import StudentModel from "@/store/models/StudentModel";
import LessonTypeModel from "@/store/models/LessonTypeModel";

import SelectField from '@/helpers/selectField'

export default {
  name: "StudentPracticeForm",
  mixins: [user, placeholderPracticeMixin, priceCategoryMixin],
  components: {
    TeachersField,
    LicenseField,
    ManualLessonCheckboxField,
    LessonTypesField,
    VehicleField,
    MeetingPlaceField,
    NotesField,
  },
  data: () => ({
    initialDuration: 0,
  }),
  props: {
    teachers: {
      type: Array,
      required: true,
    },
  },
  watch: {
    "currentAppointment.primaryVehicleId"(val) {
      if (!val) this.updateFields({ secondaryVehicleId: null });
    },
  },
  computed: {
    ...mapGetters("appointments", { currentAppointment: "currentAppointmentGetter", }),
    student() {
      return StudentModel.find(this.currentUser.id) || {}
    },
    studentCanCreatePractice() {
      const teacher = TeacherModel.find(this.currentAppointment.teacherId) || {};
      return teacher.studentCanCreatePractice || false
    },
    studentHasNoPriceList() {
      return this.student.bookAndPay &&
          !this.student.priceCategoryId &&
          this.studentCanCreatePractice
    },
    baseLessonTypes() {
      const query = LessonTypeModel.query().where("eventTypeId", "PRACTICE")
      if (!this.currentAppointment.id) {
        query.where('group', groupItem => ["normal", "special", "instructions"].includes(groupItem))
      }
      return query.get() || []
    },
    showIsManualLesson() {
      const activeLicense = LicenseModel.find(this.currentAppointment.licenseId) || {};
      const isB197 = activeLicense.name === "B197"
      return isB197 && (this.currentAppointment.id || this.studentCanCreatePractice)
    },
    activeLicensesIds() {
      const teacher = TeacherModel.find(this.currentAppointment.teacherId);
      const student = StudentModel.find(this.currentUser.id);

      if (!teacher || !student) return [];

      const practiceLicensesIds = LicenseModel.query().where('forPractice', true).get().map(license => license.id);

      return teacher.licensesIds
          .filter((licenseId) => practiceLicensesIds.includes(licenseId) && student.licensesIds.includes(licenseId));
    },
    teacherIsNotSelected() {
      return !this.currentAppointment.teacherId;
    },
    licenseIsNotSelected() {
      return !this.currentAppointment.licenseId;
    },
    studentIsNotSelected() {
      return this.currentAppointment.studentsIds.length === 0;
    },
    showLessonTypesField() {
      return this.studentCanCreatePractice ||
          (!this.studentCanCreatePractice && this.currentAppointment.status === "ACCEPTED")
    },
  },
  methods: {
    updateTeacherField(val) {
      this.updateFields({ teacherId: val });
      this.$nextTick(() => {
        this.updateLicenseField(this.activeLicensesIds.length === 1 ? this.activeLicensesIds[0] : null, true)
      })
    },
    updateLicenseField(val, cascade = false) {
      this.updateFields({ licenseId: val });
      const lessonTypeVal = new SelectField(this.currentAppointment).lessonTypeSelector(this.lessonTypesList, cascade)
      this.updateLessonTypeField(lessonTypeVal)
    },
    updateLessonTypeField(val) {
      this.updateFields({ lessonTypeId: val });
    },
  },
};
</script>
