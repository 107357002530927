// import api from "@/utils/api";
import api from '@/utils/api'

export default {
  list: (studentId, params= {}) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/students/${studentId}/documents`, { params })
        .then((resp) => resolve(resp))
        .catch(reject);
    })
  },
  download(studentId, documentId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/students/${studentId}/documents/${documentId}/download`,{ responseType: 'blob' })
        .then((resp) => resolve(resp))
        .catch(reject);
    })
  },
  destroy(studentId, documentId) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/students/${studentId}/documents/${documentId}`)
        .then((resp) => resolve(resp))
        .catch(reject);
    })
  },
  sign(studentId, documentId, signature) {
    return new Promise((resolve, reject) => {
      api
          .post(`/students/${studentId}/documents/${documentId}/sign`,{signature})
          .then((resp) => resolve(resp))
          .catch(reject);
    })
  },
  downloadPDF(pdf) {
    return new Promise((resolve, reject) => {
      api
        .get(`/download/pdf`, { params: { pdf }, responseType: "blob" })
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  }
}
