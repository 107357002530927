<script>
import expandable from "@/utils/mixins/expandable";

export default {
  name: "ExpandablePanel",
  mixins: [expandable],
  props: {
    panelName: {
      type: String,
      required: true
    },
    outlined: {
      type: Boolean,
      default: true
    },
    dividerAfterTitle: {
      type: Boolean,
      default: false
    },
    contentInContainer: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <v-card :outlined="outlined">
    <v-card-title @click="toggleExpandedState" style="cursor: pointer">
      <slot name="title" :expanded="expanded"></slot>
      <v-spacer />
      <slot name="end" :expanded="expanded"></slot>
      <v-btn icon class="ml-2" @click.stop="toggleExpandedState">
        <v-icon>{{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-title>

    <v-divider v-if="dividerAfterTitle && expanded" />

    <slide-up-down :active="expanded" :duration="200">
      <v-card-text v-if="contentInContainer">
        <slot />
      </v-card-text>
      <div v-else>
        <slot />
      </div>
    </slide-up-down>
  </v-card>
</template>

<style scoped>

</style>