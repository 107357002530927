<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'school-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>{{ $t("label.regions") }}</v-card-title>

      <v-spacer />

      <v-btn
          text
          color="primary"
          @click="save"
          :loading="saving"
          :disabled="loading || saving"
      >
        {{ $t("btn.save") }}
      </v-btn>
    </v-toolbar>

    <v-skeleton-loader class="mx-5" type="table-row@4" v-if="loading" />

    <v-card-text v-else>
      <v-card outlined>
        <v-card-title>Select regions</v-card-title>
        <v-card-text>
          <v-row dense no-gutters>
            <v-col
                v-for="region in regions"
                :key="region.short"
                cols="12"
                md="6"
                lg="3"
            >
              <v-checkbox
                  v-model="schoolRegions"
                  :label="region.name"
                  :value="region.short"
                  :disabled="saving"
                  dense
                  hide-details
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <div class="controls my-3 d-flex align-center">
        <v-btn @click="decrease" icon :disabled="loading">
          <v-icon dark>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon @click="today" :disabled="loading">
          <v-icon>mdi-calendar-today</v-icon>
        </v-btn>
        <v-btn @click="increase" icon :disabled="loading">
          <v-icon dark>mdi-chevron-right</v-icon>
        </v-btn>
        <span class="ml-3 font-weight-bold">{{ year }}</span>
      </div>

      <v-row>
        <v-col>
          <v-card outlined>
            <v-card-title>{{ $t("label.national_holidays") }}</v-card-title>
            <v-card-text>
              <v-list dense>
                <v-list-item v-for="(day, index) in nationalHolidays" :key="index">
                  <v-list-item-avatar>
                    <v-icon>mdi-calendar</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ dateFormat(day.date) }} - {{ dayName(day.date) }}</v-list-item-title>
                    <v-list-item-subtitle>{{ day.translate("de") }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="regionalHolidays.length">
          <v-card outlined>
            <v-card-title>{{ $t("label.regional_holidays") }}</v-card-title>
            <v-card-text>
              <v-list dense>
                <v-list-item v-for="(day, index) in regionalHolidays" :key="index">
                  <v-list-item-avatar>
                    <v-icon>mdi-calendar</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ dateFormat(day.date) }} - {{ dayName(day.date) }}</v-list-item-title>
                    <v-list-item-subtitle>{{ day.translate("de") }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import HolidayClass from "@/helpers/holiday";
import { getYear, format } from "date-fns";
import schoolService from "@/services/schoolService";
import responsive from "@/utils/mixins/responsive";

export default {
  name: "Holidays",
  mixins: [responsive],
  components: {},
  data: () => ({
    year: getYear(new Date()),
    schoolRegions: [],
    loading: false,
    saving: false,
    regions: [
      { short: "BW", name: "Baden-Württemberg" },
      { short: "BY", name: "Bayern" },
      { short: "BE", name: "Berlin" },
      { short: "BB", name: "Brandenburg" },
      { short: "HB", name: "Hansestadt Bremen" },
      { short: "HE", name: "Hessen" },
      { short: "HH", name: "Hansestadt Hamburg" },
      { short: "MV", name: "Mecklenburg Vorpommern" },
      { short: "NI", name: "Niedersachsen" },
      { short: "NW", name: "Nordrhein-Westfalen" },
      { short: "RP", name: "Rheinland-Pfalz" },
      { short: "SL", name: "Saarland" },
      { short: "SN", name: "Sachsen" },
      { short: "ST", name: "Sachsen-Anhalt" },
      { short: "SH", name: "Schleswig-Holstein" },
      { short: "TH", name: "Thüringen" },
    ],
  }),
  mounted() {
    this.loading = true;
    this.loadSchool()
      .then((resp) => {
        this.schoolRegions = resp.regions ? resp.regions.split(", ") : [];
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters(["activeDate"]),
    regionalHolidays() {
      const datesArr = this.nationalHolidays.map((day) => day.dateString);
      return this.allHolidays.filter(
        (day) => !datesArr.includes(day.dateString)
      );
    },
    nationalHolidays() {
      return new HolidayClass().getNationalHolidays(this.year) || [];
    },
    allHolidays() {
      return new HolidayClass().getAllHolidaysHolidays(
        this.year,
        this.schoolRegions
      );
    },
  },
  methods: {
    ...mapActions("school", { loadSchool: "load" }),
    dateFormat(date) {
      return format(date, "dd.MM.yyyy");
    },
    dayName(date) {
      const fmDate = format(date, "EEEE").toLowerCase();
      return this.$t("days." + fmDate);
    },
    decrease() {
      this.year--;
    },
    increase() {
      this.year++;
    },
    today() {
      this.year = getYear(new Date());
    },
    save() {
      this.saving = true;
      const string = this.schoolRegions.join(", ");
      schoolService
        .setRegions({ regions: string })
        .then(() => this.$snackbar.success(this.$t("messages.successfully_updated")))
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<style scoped></style>
