<template>
  <Page>
    <PageNavBar>
      <PageToolBar
        is-filters
        is-search
        is-bottom-bar
        is-pagination
        has-sort-buttons
        :has-students-overview-toggler="isSchoolAdministrator"
        :create-btn-name="$t('btn.new_student')"
        :is-multi-select="isSchoolAdministrator"
        :totalItemsCount="items.length"
        :selectedItemsCount="selectedItems.length"
        :pagination="pagination"
        @search="debouncedSearch"
        @clickCreateItem="createItem"
        @checkboxChange="onListCheckboxChange"
        @deleteSelected="openDeleteSelectedDialog"
        @applyOrder="onApplyOrder"
        @pageChange="onPageChange"
      >
        <template v-slot:filters-body>
          <StudentListFilters @applyFilters="onFiltersApply" />
        </template>
      </PageToolBar>
    </PageNavBar>

    <PageContent :loading="loadingPage">
      <PageView :items-length="items.length" :loading="loadingItems">
        <GridView>
          <GridItem
            v-for="item in items"
            :key="item.id"
            :item="item"
            :to="{ name: 'students.student', params: { id: item.id } }"
            :has-background="item.clickAndLearnEnabled"
          >
            <template v-slot:content>
              <div class="d-flex justify-space-between align-self-start">
                <CheckableAvatar
                  :avatar="item.avatar"
                  :name="item.fullName"
                  :isChecked="item.checked"
                  :only-avatar="!isSchoolAdministrator"
                  @checkItem="(value) => onCheckItem(item, value)"
                />

                <div class="d-flex flex-column" :style="{ 'max-width': textTruncateContainerWidth }">
                  <div class="d-flex font-weight-medium">
                    <v-icon small :color="item.isPushActive ? 'green' : 'gray'" :disabled="!item.isPushActive">
                      mdi-cellphone-message
                    </v-icon>
                    <v-icon
                      small
                      class="mr-1"
                      :color="item.hasEmailNotifications ? 'green' : 'gray'"
                      :disabled="!item.hasEmailNotifications">
                      {{
                        item.hasEmailNotifications
                          ? "mdi-email-outline"
                          : "mdi-email-off-outline"
                      }}
                    </v-icon>
                    <v-icon class="mr-1" v-if="item.clickAndLearnEnabled">$clickAndLearn</v-icon>
                    <span class="text-truncate">{{ item.lastName }}, {{ item.firstName }}</span>
                  </div>

                  <div class="font-weight-medium">
                    <v-chip
                      outlined
                      small
                      label
                      color="warning"
                      class="text-uppercase"
                      v-if="item.educationStatus === 'registered'"
                    >
                      {{ $t("education_status.registered") }}
                    </v-chip>

                    <div v-else class="d-flex">
                      <BalanceLabel v-if="item.bookAndPay" class="mr-1" :student="item"/>

                      <v-chip
                        v-if="school.isInvoicingEnabled && item.priceCategoryId"
                        small
                        label
                        outlined
                        color="grey"
                        class="mr-1"
                      >
                        <v-icon left small :color="item.isExcludedFromAutomation ? 'red' : 'green'">
                          {{item.isExcludedFromAutomation ? 'mdi-autorenew-off' : 'mdi-autorenew'}}
                        </v-icon>
                        <span class="text--secondary">{{ item.servicesCount }}</span>
                      </v-chip>
                    </div>
                  </div>
                </div>
              </div>

              <v-row class="mt-2" no-gutters dense>
                <v-col class="text-caption d-flex justify-space-between">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="mr-1" v-bind="attrs" v-on="on">{{ item.createdAt | dateFormat("dd.MM.yyyy") }}</span>
                    </template>
                    <span>{{$t('label.education_started_on')}}: {{ item.educationStartedAt | dateFormat("dd.MM.yyyy") }}</span>
                  </v-tooltip>
                  <template v-if="item.examOrganization">
                    <span :style="{ color: item.examOrganization.color }">{{ item.examOrganization.name }}</span>
                  </template>
                </v-col>
              </v-row>

              <v-row no-gutters dense>
                <v-col>
                  <StudentLicenseChipGroup v-if="item.licensesIds.length > 0" :student="item"/>
                </v-col>
              </v-row>

              <v-row dense class="flex-grow-0">
                <v-col cols="9" class="d-flex">
                  <NotesIcons
                    :has-glasses="item.hasGlasses"
                    :has-documents-complete="item.hasDocumentsComplete"
                    :has-notes="item.hasNotes"
                  />
                  <TeachersLabel v-if="isSchoolAdministrator" :teachers-ids="item.activeTeachersIds"/>
                </v-col>

                <v-col v-if="isSchoolAdministrator" class="d-flex justify-end" cols="3">
                  <v-switch
                    class="mt-1 v-input--reverse"
                    height="20"
                    flat
                    inset
                    v-model="item.active"
                    :loading="item.loading"
                    :disabled="loadingAction"
                    dense
                    hide-details
                    @change="(value) => toggleActive(item, value)"
                    @click.stop
                  />
                </v-col>
              </v-row>
            </template>

            <template v-slot:actions>
              <v-list-item
                v-if="item.oneTimePassword"
                :loading="item.loadingOneTimePasswordPDF"
                :disabled="item.loadingOneTimePasswordPDF"
                @click="downloadOneTimePasswordPDF(item.id)">
                <v-list-item-icon class="mr-2"><v-icon>mdi-form-textbox-password</v-icon></v-list-item-icon>
                <v-list-item-title>{{$t("label.password_one_time") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="viewItem(item)">
                <v-list-item-icon class="mr-2"><v-icon>mdi-eye</v-icon></v-list-item-icon>
                <v-list-item-title>{{ $t("label.view") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="editItem(item)">
                <v-list-item-icon class="mr-2"><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                <v-list-item-title>{{ $t("label.edit") }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="isSchoolAdministrator"
                @click="deleteItem(item)">
                <v-list-item-icon class="mr-2"><v-icon>mdi-archive-arrow-down</v-icon></v-list-item-icon>
                <v-list-item-title>{{ $t("btn.archive") }}</v-list-item-title>
              </v-list-item>
            </template>
          </GridItem>
        </GridView>

        <ListView>
          <template v-slot:header>
            <ListColumn :text="$t('list.name')" />
            <ListColumn :text="$t('list.email')" />
            <ListColumn :text="$t('list.licenses')" />
            <ListColumn :text="$t('list.phone')" />
            <ListColumn
              width="180"
              v-if="isSchoolAdministrator"
              :text="$t('list.teachers')"
            />
            <ListColumn width="100" :text="$t('list.created')" />
            <ListColumn width="100" :text="$t('list.exam_organization')" />
            <ListColumn width="100" :text="$t('list.notifications')" />
            <ListColumn
              width="80"
              v-if="isSchoolAdministrator"
              :text="$t('list.active')"
            />
            <ListColumn width="80" :text="$t('list.actions')" />
          </template>

          <template v-slot:body>
            <ListRow
              v-for="item in items"
              :key="item.id"
              :item="item"
              :to="{ name: 'students.student', params: { id: item.id } }"
              :class="{ 'list__row-active': item.checked }"
              :has-background="item.clickAndLearnEnabled"
            >
              <ListColumn>
                <div class="d-flex">
                  <CheckableAvatar
                    :avatar="item.avatar"
                    :name="item.fullName"
                    :isChecked="item.checked"
                    :only-avatar="!isSchoolAdministrator"
                    @checkItem="(value) => onCheckItem(item, value)"
                  />

                  <div class="d-flex">
                    <div class="d-inline-flex flex-column" style="top: -10px">
                      <span>{{ item.lastName }}, {{ item.firstName }}</span>
                      <div class="text-caption">
                        <v-chip
                          outlined
                          small
                          label
                          color="warning"
                          class="my-auto text-uppercase"
                          v-if="item.educationStatus === 'registered'">
                          {{ $t("education_status.registered") }}
                        </v-chip>
                        <div v-else class="d-flex">
                          <BalanceLabel v-if="item.bookAndPay" class="mr-2" :student="item"/>

                          <v-chip
                            v-if="school.isInvoicingEnabled && item.priceCategoryId"
                            small
                            label
                            outlined
                          >
                            <v-icon left small :color="item.isExcludedFromAutomation ? 'red' : 'green'">
                              {{item.isExcludedFromAutomation ? 'mdi-autorenew-off' : 'mdi-autorenew'}}
                            </v-icon>
                            <span class="text--secondary">{{ item.servicesCount }}</span>
                          </v-chip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ListColumn>
              <ListColumn>
                <div v-if="item.email">{{ item.email }}</div>
                <span v-else>-</span>
              </ListColumn>
              <ListColumn>
                <StudentLicenseChipGroup
                  class="mt-2"
                  v-if="item.licensesIds.length > 0"
                  :student="item" />
              </ListColumn>
              <ListColumn :text="item.phone" />
              <ListColumn
                width="180"
                v-if="isSchoolAdministrator"
                @click.native.stop>
                <TeachersLabel :teachers-ids="item.activeTeachersIds" />
              </ListColumn>
              <ListColumn width="100">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="mr-1" v-bind="attrs" v-on="on">{{ item.createdAt | dateFormat("dd.MM.yyyy") }}</span>
                  </template>
                  <span>{{$t('label.education_started_on')}}: {{ item.educationStartedAt | dateFormat("dd.MM.yyyy") }}</span>
                </v-tooltip>
              </ListColumn>
              <ListColumn width="100">
                <template v-if="item.examOrganization">
                  <span :style="{ color: item.examOrganization.color }">{{
                    item.examOrganization.name
                  }}</span>
                </template>
              </ListColumn>
              <ListColumn width="100">
                <div class="d-flex align-center">
                  <v-icon
                    :color="item.isPushActive ? 'green' : 'gray'"
                    :disabled="!item.isPushActive"
                    >mdi-cellphone-message</v-icon
                  >
                  <v-icon
                    class="mr-1"
                    :color="item.hasEmailNotifications ? 'green' : 'gray'"
                    :disabled="!item.hasEmailNotifications">
                    {{
                      item.hasEmailNotifications
                        ? "mdi-email-outline"
                        : "mdi-email-off-outline"
                    }}
                  </v-icon>
                </div>
              </ListColumn>
              <ListColumn
                v-if="isSchoolAdministrator"
                width="80"
                @click.native.stop>
                <v-switch
                  class="mt-0"
                  height="20"
                  flat
                  inset
                  v-model="item.active"
                  :loading="item.loading"
                  :disabled="loadingAction"
                  dense
                  hide-details
                  @change="(value) => toggleActive(item, value)" />
              </ListColumn>
              <ListColumn width="80" class="text-center" @click.native.stop>
                <RightClickMenu :item="item">
                  <template v-slot="{ showItemMenu }">
                    <v-icon
                      size="25"
                      @click="(e) => showItemMenu(e, item)"
                      :disabled="loadingAction"
                      >mdi-dots-vertical</v-icon>
                  </template>

                  <template v-slot:menu>
                    <v-list-item
                      v-if="item.oneTimePassword"
                      :disabled="item.loadingOneTimePasswordPDF"
                      :loading="item.loadingOneTimePasswordPDF"
                      @click="downloadOneTimePasswordPDF(item.id)">
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-form-textbox-password</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ $t("label.password_one_time") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="viewItem(item)">
                      <v-list-item-icon class="mr-2"><v-icon>mdi-eye</v-icon></v-list-item-icon>
                      <v-list-item-title>{{$t("label.view")}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="editItem(item)">
                      <v-list-item-icon class="mr-2"><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                      <v-list-item-title>{{$t("label.edit")}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="isSchoolAdministrator" @click="deleteItem(item)">
                      <v-list-item-icon class="mr-2"><v-icon>mdi-archive-arrow-down</v-icon></v-list-item-icon>
                      <v-list-item-title>{{$t("btn.archive")}}</v-list-item-title>
                    </v-list-item>
                  </template>
                </RightClickMenu>
              </ListColumn>
            </ListRow>
          </template>
        </ListView>
      </PageView>
    </PageContent>

    <StudentFormDialog
      ref="formItem"
      @create="onCreateItem"
      @update="onUpdateItem"
    />

    <DeleteMultipleUsersDialog
      ref="deleteMultipleUsersDialog"
      :loading="loadingDeleteStudents"
      @deleteUsers="onDeleteStudents"
    />

    <v-btn
      v-if="$vuetify.breakpoint.mobile"
      fixed
      right
      bottom
      fab
      small
      class="mr-2"
      color="primary"
      @click="createItem"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </Page>
</template>

<script>
import StudentListFilters from "@/components/student/StudentListFilters";
import StudentFormDialog from "@/components/student/StudentFormDialog";
import studentsService from "@/services/studentsService";
import user from "@/utils/mixins/user";
import licensesService from "@/services/licensesService";

import OfficeModel from "@/store/models/OfficeModel";
import LicenseModel from "@/store/models/LicenseModel";
import StudentModel from "@/store/models/StudentModel";

import gridElementsHeaderWidth from "@/utils/mixins/gridElementsHeaderWidth";
import RightClickMenu from "@/components/RightClickMenu";
import DeleteMultipleUsersDialog from "@/components/DeleteMultipleUsersDialog";
import CheckableAvatar from "@/components/CheckableAvatar";

import listFilters from "@/utils/mixins/listFilters";
import TeachersLabel from "@/components/teacher/TeachersLabel";

import Page from "@/components/page/Page";
import PageContent from "@/components/page/PageContent";
import PageView from "@/components/page/PageView";
import PageNavBar from "@/components/page/PageNavBar";
import PageToolBar from "@/components/page/PageToolBar";

import ListView from "@/components/list/ListView";
import ListColumn from "@/components/list/ListColumn";
import ListRow from "@/components/list/ListRow";
import GridView from "@/components/grid/GridView";
import GridItem from "@/components/grid/GridItem";
import FileSaver from "@/helpers/fileSaver";

import axios from "axios";
import { debounce } from "throttle-debounce";
import responsive from "@/utils/mixins/responsive";
import NotesIcons from "@/components/student/NotesIcons";
import StudentLicenseChipGroup from "@/components/student/StudentLicenseChipGroup";
import BalanceLabel from "@/components/BalanceLabel";
import laravelEcho from "@/plugins/laravel-echo";
import priceCategoriesService from '@/services/priceCategoriesService'
import ProductCategoryModel from '@/store/models/ProductCategoryModel'
import { mapGetters } from 'vuex'
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: "StudentsLegacyIndex",
  mixins: [user, responsive, gridElementsHeaderWidth, listFilters],
  components: {
    BalanceLabel,
    StudentLicenseChipGroup,
    NotesIcons,
    PageView,
    PageContent,
    Page,
    PageToolBar,
    TeachersLabel,
    CheckableAvatar,
    DeleteMultipleUsersDialog,
    RightClickMenu,
    StudentListFilters,
    GridItem,
    GridView,
    ListRow,
    ListColumn,
    ListView,
    StudentFormDialog,
    PageNavBar,
  },
  data() {
    return {
      loadingPage: true,
      loadingItems: false,
      loadingAction: false,
      loadingDeleteStudents: false,
      teachers: [],
      formDialog: false,
      formType: "teacher",
      searchText: "",
      filteredLicenses: [],
      initialFilters: {},
      filters: {},
      deleteFunction: studentsService.destroy,
    };
  },
  computed: {
    ...mapGetters("school", ["school"]),
    items() {
      return StudentModel.query().orderBy("orderIndex").get();
    },
    selectedItems() {
      return StudentModel.query().where("checked", true).get();
    },
    debouncedSearch() {
      return debounce(300, this.onSearch);
    },
    activeFilters() {
      const activeFilters = [];
      Object.keys(this.filters).forEach((filter) => {
        if (Array.isArray(this.filters[filter])) activeFilters.push();
      });
      return activeFilters;
    },
    offices() {
      return OfficeModel.all();
    },
    pagination() {
      return this.$store.state.entities.students.pagination;
    },
  },
  mounted() {
    licensesService
      .load()
      .then((response) => {
        LicenseModel.insert({ data: response.data || [] });
      })

    priceCategoriesService
      .load()
      .then((response) => {
        ProductCategoryModel.create({ data: response.data || [] })
      })

    this.fetch().finally(() => {
      this.loadingPage = false;
    });

    laravelEcho
      .private("school-channel." + this.currentUser.schoolId)
      .listen("StudentBalanceUpdated", (response) => {
        StudentModel.update({
          where: response.studentId,
          data: { balance: response.balance, availableBalance: response.availableBalance },
        });
      });
  },
  methods: {
    onSearch(searchText) {
      this.searchText = searchText;
      const appliedFilters = this.$route.query.filters

      if (Number(this.$route.query.page) !== 1) {
        this.$router.push({
          query: {
            page: 1,
            ...(appliedFilters && { filters: appliedFilters })
          }
        }).then(() => this.load())
      } else {
        this.load()
      }
    },
    onFiltersApply() {
      this.load()
    },
    onPageChange() {
      this.load()
    },
    load() {
      this.loadingItems = true;
      this.fetch().finally(() => (this.loadingItems = false));
    },
    fetch() {
      return studentsService
        .load({
          pagination: true,
          searchText: this.searchText,
          licenses: this.filteredLicenses,
          page: this.$route.query.page || 1,
          perPage: 50,
          ...this.appliedFiltersValues
        })
        .then((resp) => {
          const data = resp.data.data || [];
          StudentModel.create({
            data: data.map((item, index) => ({ ...item, orderIndex: index })),
          });
          StudentModel.commit(
            (state) => (state.pagination = resp.data.pagination)
          );
        })
    },
    viewItem(item) {
      this.$router.push({ name: "students.student", params: { id: item.id } });
    },
    createItem() {
      this.$refs.formItem.open();
    },
    editItem(item) {
      this.$refs.formItem.open(item);
    },
    deleteItem(item) {
      this.loadingAction = true;
      showConfirm(
        this.$t('btn.confirm'),
        this.$t("messages.are_your_sure_archive"),
        () => {
          studentsService
            .destroy(item.id)
            .then(() => this.fetch())
            .catch(console.log)
            .finally(() => {
              this.loadingAction = false;
            });
        },
        () => {
          this.loadingAction = false;
        }
      );
    },
    onCreateItem(createdItem) {
      const pagination = this.$store.state.entities.students.pagination;

      this.items.unshift(createdItem);
      if (pagination.lastPage > 1) this.items.splice(-1, 1);
    },
    onUpdateItem(updatedItem) {
      StudentModel.update({ where: updatedItem.id, data: updatedItem || {} });
    },
    toggleActive(item, value) {
      const student = StudentModel.find(item.id);
      StudentModel.update({ where: student.id, data: { loading: true } });
      studentsService
        .makeActive(student.id, { active: value })
        .then(() => {
          StudentModel.update({ where: student.id, data: { active: value } });
        })
        .catch((error) => console.log(error))
        .finally(() =>
          StudentModel.update({ where: student.id, data: { loading: false } })
        );
    },
    downloadOneTimePasswordPDF(studentId) {
      const student = StudentModel.find(studentId);

      StudentModel.update({
        where: student.id,
        data: { loadingOneTimePasswordPDF: true },
      });
      studentsService
        .downloadOnTimePassword(student.id)
        .then((response) => {
          const fileSaver = new FileSaver([response.data]);
          fileSaver
            .setType("application/pdf")
            .saveToDevice(`Einmalpasswort ${student.fullName}`);
        })
        .catch((error) => {
          const response = error.response || {};
          const responseData = response.data || {};

          if (responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error" });
          }
        })
        .finally(() => {
          StudentModel.update({
            where: student.id,
            data: { loadingOneTimePasswordPDF: false },
          });
        });
    },
    onCheckItem(item, isChecked) {
      StudentModel.update({ where: item.id, data: { checked: isChecked } });
    },
    onListCheckboxChange() {
      const areSelectedInCountOfTotal =
        this.selectedItems.length === this.items.length;
      StudentModel.update({
        where: (student) =>
          student.checked ===
          (areSelectedInCountOfTotal || this.selectedItems.length > 0),
        data: {
          checked: !(areSelectedInCountOfTotal || this.selectedItems.length),
        },
      });
    },
    openDeleteSelectedDialog() {
      this.$refs.deleteMultipleUsersDialog.open(
        StudentModel.query().where("checked", true).get()
      );
    },
    onDeleteStudents() {
      this.loadingDeleteStudents = true;
      const deleteRequests = this.selectedItems.map((user) =>
        studentsService.destroy(user.id)
      );
      axios.all(deleteRequests).finally(() => {
        this.fetch().finally(() => {
          this.loadingDeleteStudents = false;
          this.$refs.deleteMultipleUsersDialog.close();
        });
      });
    },
    onApplyOrder() {
      this.load()
    },
  },
};
</script>

<style lang="scss"></style>
