<template>
  <div></div>
</template>

<script>
export default {
  name: "OfftimeCard",
  props: {
    appointment: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style scoped>

</style>