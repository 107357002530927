<script>
import studentsDirectoryService from "@/services/v2/studentsDirectoryService";
import {mapActions, mapGetters} from "vuex";
import {EventBus} from "@/EventBus";
import clipboard from "@/utils/mixins/clipboard";
import MainInformation from "@/components/students-directory/student-file/MainInformation.vue";
import StudentLicenses from "@/components/students-directory/student-file/StudentLicenses.vue";
import StudentTeachers from "@/components/students-directory/student-file/StudentTeachers.vue";
import StudentFinances from "@/components/students-directory/student-file/StudentFinances.vue";
import StudentClickAndLearn from "@/components/students-directory/student-file/StudentClickAndLearn.vue";
import StudentNotes from "@/components/students-directory/student-file/StudentNotes.vue";
import StudentMiscellaneous from "@/components/students-directory/StudentMiscellaneous.vue";
import RightMenu from "@/components/students-directory/student-file/RightMenu.vue";
export default {
  name: "StudentFile",
  components: {
    RightMenu,
    StudentMiscellaneous,
    StudentNotes, StudentClickAndLearn, StudentFinances, StudentTeachers, StudentLicenses, MainInformation},
  mixins: [clipboard],
  props: {
    student: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      studentData: null,
    }
  },
  mounted() {
    this.loadStudent()

    EventBus.$on('reload-student', this.loadStudent)
  },
  beforeDestroy() {
    EventBus.$off('reload-student', this.loadStudent)
  },
  methods: {
    ...mapActions('studentsDirectory', ['startStudentDataLoading', 'stopStudentDataLoading', 'load']),
    loadStudent(id = null) {
      if (id && id !== this.student.id) return;

      this.startStudentDataLoading(this.student.id)
      studentsDirectoryService
          .loadStudentFile(this.student.id)
          .then(resp => {
            this.studentData = resp.data || {}
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            this.stopStudentDataLoading(this.student.id)
          })
    },
    changeActiveStatus(status) {
      this.studentData.active = status;
      this.load(true)
    }
  },
  computed: {
    ...mapGetters('studentsDirectory', ['studentFileStackedCondition'])
  }
}
</script>

<template>
  <div v-if="student.loading" class="d-flex align-center justify-center">
    <v-progress-linear indeterminate color="secondary" height="2"/>
  </div>
  <div class="student-file" v-else-if="studentData">
    <div class="content">
      <main-information :student="studentData" class="mb-3" @active-status-changed="changeActiveStatus" />

      <div class="info-cards" :class="{stacked: studentFileStackedCondition}">
        <div class="wide">
          <student-licenses :student="studentData"  />
          <student-finances :student="studentData"  />
        </div>
        <div class="narrow">
          <student-miscellaneous :student="studentData" />
          <student-teachers :student="studentData"  />
          <student-notes :student="studentData"  />
          <student-click-and-learn :student="studentData"  />
        </div>
      </div>
    </div>
    <right-menu class="right-menu" :student="studentData" />
  </div>
  <div v-else>
    <v-alert type="error">
      {{ $t('label.error_loading_the_student') }}
    </v-alert>
  </div>
</template>

<style scoped lang="scss">
.student-file {
  display: grid;
  grid-template-areas: "content right-menu";
  grid-template-columns: 1fr 64px;
  // give the main div a fixed height so the scroll is only applied to the inner (content) part of the student file, not to the whole div.
  height: calc(100vh - 131px); // 131px is the height of the tabs + searchbar + main appbar
  overflow: hidden;
  & > .content {
    grid-area: content;
    padding: 10px;
    height: 100%;
    overflow-y: auto;
  }
  & > .right-menu {
    grid-area: right-menu;
    border-left: solid 1px #eee;
  }
}

.info-cards {
  display: grid;
  grid-template-areas: "wide narrow";
  grid-template-columns: 1fr 350px;
  grid-gap: 12px;
  align-items: start;

  & > .wide {
    grid-area: wide;
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  & > .narrow {
    grid-area: narrow;
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  &.stacked {
    grid-template-areas: "wide" "narrow";
    grid-template-columns: 1fr;

    & > .narrow {
      grid-template-columns: repeat(auto-fill,minmax(350px,1fr));
      align-items: start;
    }
  }
}
</style>