export default {
  methods: {
    removeFromAppliedFilters(filterName, filterId) {
      const filters = { ...this.filters };

      if (!filters[filterName]) return;

      if (Array.isArray(filters[filterName])) {
        filters[filterName] = filters[filterName]
          .filter((item, index, arr) => {
            return item !== filterId ||
              (new Set(arr).size !== arr.length && index === arr.indexOf(filterId));
          });
        if (filters[filterName].length === 0) delete filters[filterName];
        this.filterValues[filterName] = filters[filterName] || [];
      } else {
        delete filters[filterName];
        this.filterValues[filterName] = null;
      }

      this.applyFilters(filters);
    },
  }
};
