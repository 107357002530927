<template>
  <div class="hidden">
    <div class="absolute" :style="`right: ${scrollLeft}px`">

      <TimeIndicator v-if="showTimeLine" :key="gridSize"/>
      <div class="hours" ref="hoursLine">
        <div
            v-for="(index, hour) in hours"
            :key="index"
            class="hour"
            :style="`flex: 1 0 ${hourSize}px`"
        >
          <div class="time">{{ hour }}:00</div>
          <div class="lines">
            <div class="line" v-for="n in 3" :key="n"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TimeIndicator from '@/components/vehicles-calendar/TimeIndicator.vue'
import { isToday } from 'date-fns'

export default {
  name: "HoursLine",
  components: { TimeIndicator },
  computed: {
    ...mapState('vehiclesCalendar', ['hours', 'hourSize', 'gridSize', 'date']),
    showTimeLine() {
      return isToday(this.date)
    }
  },
  props: {
    scrollLeft: {
      type: Number,
      default: 0
    }
  },
  watch: {
    scrollLeft(val) {
      this.$refs.hoursLine.scrollLeft = val;
    }
  }
}
</script>

<style lang="scss" scoped>
.hidden {
  overflow: hidden;
  background: #f1f1f1;
}
.absolute {
  width: 100%;
  position: absolute;
}
.hours {
  display: flex;
  .hour{
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    align-content: center;
    background: #fff;
    border-bottom: 1px solid #ccc;
    .time{
      font-size: 14px;
      font-weight: 900;
      color: #999;
      box-sizing: border-box;
      padding-left: 5px;
    }
  }
  .lines{
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-evenly;
    border-left: 1px solid #ccc;
    .line{
      height: 100%;
      width: 1px;
      top: 40px;
      position: relative;
      left: 1px;
      border-left: 1px dashed rgba(225, 225, 225, 0.5);
    }
  }
}
</style>
