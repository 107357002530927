var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-content-center justify-center",staticStyle:{"width":"24px"}},[(
      _vm.service.appointmentMissing ||
      _vm.service.deleted ||
      (_vm.service.appointmentCost && _vm.service.cost !== _vm.service.appointmentCost) ||
      _vm.service.appointmentIsMissed ||
      _vm.service.appointmentIsRefunded ||
      _vm.service.appointmentIsPartlyRefunded
  )?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.service.appointmentIsMissed)?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-call-missed")]):(_vm.service.appointmentIsRefunded || _vm.service.appointmentIsPartlyRefunded)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":!_vm.service.appointmentIsPartlyRefunded ? 'red' : ''}},'v-icon',attrs,false),on),[_vm._v(" mdi-cash-refund ")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-alert")])]}}],null,false,1202561163)},[(_vm.service.deleted)?_c('span',[_vm._v(_vm._s(_vm.$t("label.service_deleted")))]):(_vm.service.appointmentMissing)?_c('span',[_vm._v(_vm._s(_vm.$t("label.event_deleted")))]):(_vm.service.appointmentIsMissed)?_c('span',[_vm._v(_vm._s(_vm.$t("label.appointment_is_missed")))]):(_vm.service.appointmentIsPartlyRefunded)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("label.appointment_is_partly_refunded"))+" ("+_vm._s(_vm._f("currency")(_vm.service.appointmentRefundedAmount))+"/"+_vm._s(_vm._f("currency")(_vm.service.appointmentCost))+") ")]):(_vm.service.appointmentIsRefunded)?_c('span',[_vm._v(_vm._s(_vm.$t("label.appointment_is_refunded")))]):(
      _vm.service.appointmentCost &&
      _vm.service.cost !== _vm.service.appointmentCost - _vm.service.appointmentRefundedAmount
    )?_c('span',[_vm._v(" "+_vm._s(_vm.$t("label.price_mismatch"))+" ")]):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }