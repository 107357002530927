<template>
  <div>
    <div class="theory-topic d-flex" v-if="appointment.theoryTopic">
      <v-icon class="mb-1" size="15">
        {{ appointment.theoryTopic.type === "basic" ? "mdi-alpha-g-box" : "mdi-alpha-z-box" }}
      </v-icon>
      <div class="text-truncate mr-10">{{ $t("form.topic") + " " + appointment.theoryTopic.orderIndex + '. ' + appointment.theoryTopic.title }}</div>
    </div>
  </div>
</template>

<script>
import user from "@/utils/mixins/user";

export default {
  name: "TheoryCard",
  mixins: [user],
  props: {
    appointment: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style scoped>

</style>
