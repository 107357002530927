<template>
  <v-dialog
      content-class="dialog-overflow"
      v-model="show"
      max-width="700"
      @click:outside="close"
      @keydown.esc="close"
      :fullscreen="$vuetify.breakpoint.mobile"
      scrollable
  >
    <v-card :tile="$vuetify.breakpoint.mobile">
      <v-toolbar class="mb-2 flex-grow-0" dark color="primary" dense>
        <v-toolbar-title>
          {{ $t("label.create_or_edit_license_requirement_template", { createOrEdit: form.id ? $t("label.edit") : $t("label.create") }) }}
        </v-toolbar-title>

        <v-spacer/>

        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <ValidationObserver ref="observer">
          <v-card-text>
            <v-row dense>
              <v-col>
                <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                  <v-text-field
                    @change="showErrorDetailsForNameField = false"
                    v-model="form.name"
                    class="mt-0 pt-0"
                    type="text"
                    :label="$t('form.name')"
                    outlined
                    dense
                    :error="errors.length > 0"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <ValidationProvider name="licenses" rules="required" v-slot="{ errors }">
                  <v-select
                      v-model="form.licenses"
                      :items="licenses"
                      :label="$t('form.licenses')"
                      item-value="id"
                      item-text="name"
                      :clearable="true"
                      :multiple="true"
                      outlined
                      dense
                      menu-props="offsetY"
                      :error="errors.length > 0"
                      :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>

            <div class="text-subtitle-1 font-weight-medium my-2">
              {{ $t('label.theory') }}
            </div>

            <v-row dense>
              <v-col>
                <ValidationProvider name="basicTheories" rules="required|min_value:0" v-slot="{ errors }">
                  <v-text-field
                      oninput="if(!this.value || this.value < 0) this.value = 0;"
                      @keypress="onlyInteger"
                      :error="errors.length > 0"
                      v-model.number="form.basicTheories"
                      type="number"
                      :label="$t('form.basic')"
                      outlined
                      dense
                      hide-details
                  />
                </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider name="specialTheories" rules="required|min_value:0" v-slot="{ errors }">
                  <v-text-field
                      oninput="if(!this.value || this.value < 0) this.value = 0;"
                      @keypress="onlyInteger"
                      :error="errors.length > 0"
                      v-model.number="form.specialTheories"
                      type="number"
                      :label="$t('form.special')"
                      outlined
                      dense
                      hide-details
                  />
                </ValidationProvider>
              </v-col>
            </v-row>

            <div class="text-subtitle-1 font-weight-medium my-2">
              {{ $t('label.practice') }}
            </div>

            <v-row dense>
              <v-col>
                <ValidationProvider name="overlands" rules="required|min_value:0" v-slot="{ errors }">
                  <v-text-field
                      oninput="if(!this.value || this.value < 0) this.value = 0;"
                      @keypress="onlyInteger"
                      :error="errors.length > 0"
                      v-model.number="form.overlands"
                      type="number"
                      :label="$t('form.overland')"
                      outlined
                      dense
                      hide-details
                  />
                </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider name="highways" rules="required|min_value:0" v-slot="{ errors }">
                  <v-text-field
                      oninput="if(!this.value || this.value < 0) this.value = 0;"
                      @keypress="onlyInteger"
                      :error="errors.length > 0"
                      v-model.number="form.highways"
                      type="number"
                      :label="$t('form.highway')"
                      outlined
                      dense
                      hide-details
                  />
                </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider name="nights" rules="required|min_value:0" v-slot="{ errors }">
                  <v-text-field
                      oninput="if(!this.value || this.value < 0) this.value = 0;"
                      @keypress="onlyInteger"
                      :error="errors.length > 0"
                      v-model.number="form.nights"
                      type="number"
                      :label="$t('form.night')"
                      outlined
                      dense
                      hide-details
                  />
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-checkbox v-model="form.requireInstructionPractices" hide-details dense :label="$t('label.require_instruction_practices')"/>
              </v-col>
              <v-col v-if="form.requireInstructionPractices">
                <ValidationProvider name="instructions" v-slot="{ errors }">
                  <v-text-field
                    oninput="if(!value || value < 0) value = 0;"
                    @keypress="onlyInteger"
                    :error-messages="errors"
                    :error="errors.length > 0"
                    v-model.number="form.instructions"
                    type="number"
                    :label="$t('form.instruction')"
                    outlined
                    dense
                  />
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-checkbox v-model="form.requireNormalPractices" hide-details dense :label="$t('label.require_normal_practices')"/>
              </v-col>
              <v-col v-if="form.requireNormalPractices">
                <ValidationProvider name="normals" v-slot="{ errors }">
                  <v-text-field
                      oninput="if(!value || value < 0) value = 0;"
                      @keypress="onlyInteger"
                      :error-messages="errors"
                      :error="errors.length > 0"
                      v-model.number="form.normals"
                      type="number"
                      :label="$t('form.normal')"
                      outlined
                      dense
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-checkbox v-model="form.requireTheoryExam" hide-details dense :label="$t('label.require_theory_exam')"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-checkbox v-model="form.requirePracticeExam" hide-details dense :label="$t('label.require_practice_exam')"/>
              </v-col>
            </v-row>
          </v-card-text>

        </ValidationObserver>
      </v-card-text>

      <v-divider class="mt-auto" />

      <v-card-actions>
        <v-btn @click="close" text>
          {{ $t("btn.close")  }}
        </v-btn>

        <v-spacer/>

        <v-btn color="primary" @click="save(form.id)" :disabled="loading" :loading="loading">
          <v-icon left dark>
            {{ form.id ? 'mdi-content-save-edit' : 'mdi-content-save' }}
          </v-icon>
          {{ $t("btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import responsive from '@/utils/mixins/responsive'
import input from "@/utils/mixins/input";
import LicenseModel from '@/store/models/LicenseModel'

import licenseRequirementsService from '@/services/licenseRequirementsService'
import LicenseRequirementModel from '@/store/models/LicenseRequirementModel'

export default {
  name: 'LicenseTemplateCreateDialog',
  mixins: [responsive, input],
  data() {
    return {
      showErrorDetailsForNameField: false,
      show: false,
      loading: false,
      licenseId: null,
      form: {
        id: null,
        active: true,
        name: '',
        licenses: [],
        basicTheories: 0,
        specialTheories: 0,
        overlands: 0,
        highways: 0,
        nights: 0,
        instructions: 0,
        normals: 0,
        requireInstructionPractices: false,
        requireNormalPractices: false,
        requireTheoryExam: false,
        requirePracticeExam: false
      },
    }
  },
  computed: {
    licenses() {
      return LicenseModel.all()
    }
  },
  methods: {
    open(val) {
      this.reset()
      this.show = true

      if (val?.id) {
        this.form.id = val.id
        this.form.active = val.active
        this.form.name = val.name
        this.form.licenses = val.licenses
        this.form.basicTheories = val.basicTheories || 0
        this.form.specialTheories = val.specialTheories || 0
        this.form.overlands = val.overlands || 0
        this.form.highways = val.highways || 0
        this.form.nights = val.nights || 0
        this.form.instructions = val.instructions || 0
        this.form.normals = val.normals || 0
        this.form.requireTheoryExam = !!val.theoryExam
        this.form.requirePracticeExam = !!val.practiceExam

        this.$set(this.form, 'requireInstructionPractices', val.instructions > 0)
        this.$set(this.form, 'requireNormalPractices', val.normals > 0)
      }
    },
    openForLicense(licenseId) {
      this.reset()
      this.show = true
      this.form.licenses = [licenseId]
      this.licenseId = licenseId
    },
    close() {
      this.show = false

      setTimeout(() => {
        this.reset()
        this.$refs.observer.reset();
      }, 200)
    },
    reset() {
      this.showErrorDetailsForNameField = false
      this.form = {
        id: null,
        active: true,
        name: '',
        licenses: [],
        basicTheories: 0,
        specialTheories: 0,
        overlands: 0,
        highways: 0,
        nights: 0,
        instructions: 0,
        normals: 0,
      }
    },
    async save(val) {
      const validate = await this.$refs.observer.validate();

      if (! validate) return

      const requiredInstructionsAmount = this.form.instructions
      const requiredNormalsAmount = this.form.normals

      if (!this.form.requireInstructionPractices) this.form.instructions = 0
      if (!this.form.requireNormalPractices) this.form.normals = 0

      this.loading = true
      this[val ? 'update' : 'store']()
        .catch((error) => {
          this.$refs.observer.reset()

          this.form.instructions = requiredInstructionsAmount
          this.form.normals = requiredNormalsAmount

          const responseData = error.response.data || {};
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error"});
          }

          this.$refs.observer.setErrors(errors);
          this.showErrorDetailsForNameField = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    store() {
      return licenseRequirementsService.create(this.form)
          .then((response) => {
            const responseData = response.data

            LicenseRequirementModel
                .insert({ data: responseData })
                .then(() => {
                  this.$emit('createdForLicense', { licenseId: this.licenseId, data: responseData})
                })

            this.close()
          })
    },
    update() {
      return licenseRequirementsService.update(this.form)
        .then((response) => {
          LicenseRequirementModel.update({where: this.form.id, data: response.data})
          this.close()
        })
    },
  }
}
</script>

<style scoped lang="scss">
.dialog-overflow {
  overflow: visible;
}
.overflowed {
  max-height: 600px;
  overflow-y: auto;
}
</style>
