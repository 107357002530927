<script>
import AppointmentsTable from '@/components/appointment/AppointmentsTable.vue'
import appointmentService from '@/services/appointmentService'
import AppointmentModel from '@/store/models/AppointmentModel'
import Pagination from "@/components/Pagination.vue";
import EventTypeModel from '@/store/models/EventTypeModel'
import LessonTypeModel from '@/store/models/LessonTypeModel'
import LicenseModel from '@/store/models/LicenseModel'
import FileSaver from '@/helpers/fileSaver'
import { format, parseISO, startOfMonth, endOfMonth } from "date-fns";
import ShowsAs from "@/components/ShowsAs.vue";
import loadAppointments from "@/utils/mixins/loadAppointments";
import { mapActions, mapState } from 'vuex'
import usersFilters from "@/utils/mixins/filters/usersFilters";
import laravelEcho from "@/plugins/laravel-echo";
import user from "@/utils/mixins/user";
import schoolService from "@/services/schoolService";
import StudentModel from "@/store/models/StudentModel";

export default {
  name: 'Appointments',
  mixins: [user, usersFilters],
  components: { Pagination, ShowsAs, AppointmentsTable },
  data() {
    return {
      startDates: [],
      dateFilterDialog: false,
      loading: true,
      loadingExport: false,
      deletingExportList: false,
      exportInfo: {},
      filtering: false,
      changingPage: false,
      page: 1,
      perPage: 50,
      loadingStudents: false,
      loadingTeachers: false,
      searchStudentText: '',
      teachers: [],
      pagination: {},
      filterValues: {
        startDates: [],
        type: null,
        lessonTypes: [],
        licenses: [],
        teacher: null,
        students: [],
        missed: false,
        requested: false,
        passedExam: false
      },
      initialFilters: {},
      debouncedSearch: null,
      testUser: null,
      downloading: false,
      showExportMenu: false
    }
  },
  computed: {
    ...mapState("appointments", ["filters", "datesFilterApplied"]),
    loadAppointments() {
      return loadAppointments;
    },
    isExportListBtnDisabled() {
      return this.loading ||
        this.filtering ||
        this.changingPage ||
        this.loadingExport ||
        this.appointments.length === 0 ||
        ["pending", "cancelling"].includes(this.exportInfo.status);
    },
    loadingExportBtn() {
      return this.filtering || this.loadingExport || ["pending", "cancelling"].includes(this.exportInfo.status);
    },
    appointments() {
      return AppointmentModel.query().with("teacher").with("student").orderBy("start", "desc").get();
    },
    students() {
      const students = StudentModel.all();

      if (this.searchStudentText.length === 0) return students;

      const searchText = this.searchStudentText.toLowerCase();

      return students.filter(student => student.fullName.toLowerCase().includes(searchText));
    },
    teachersOptions() {
      const teachers = this.teachers.map(teacher => ({
        active: teacher.active,
        deletedAt: teacher.deletedAt,
        fullName: teacher.fullName,
        id: teacher.id
      }))
      return this.getUsersFiltersOptionsWithHeaders(teachers)
    },
    appointmentTypesOptions() {
      return EventTypeModel.query()
        .where(item => item.id !== "OFFTIME")
        .get()
        .map(type => ({
          text: this.$t(`event_type.${type.id}`),
          value: type.id
        }));
    },
    lessonTypeOptions() {
      let lessonTypesQuery = LessonTypeModel.query();

      if (this.filterValues.type) {
        lessonTypesQuery = lessonTypesQuery
          .where("eventTypeId", this.filterValues.type);
      }

      return lessonTypesQuery.orderBy('order').get()
        .map(type => ({
          text: this.$tc(type.name),
          eventTypeId: type.eventTypeId,
          value: type.id
        }));
    },
    licensesOptions() {
      return LicenseModel.all().map(license => ({
        text: license.name,
        value: license.id
      }))
    },
    studentsOptions() {
      const students = this.students.map(student => ({
        active: student.active,
        deletedAt: student.deletedAt,
        fullName: student.fullName,
        id: student.id
      }))
      return this.getUsersFiltersOptionsWithHeaders(students)
    },
    formattedDates() {
      let startDates = this.filterValues.startDates ?? [];
      startDates = Array.isArray(startDates) ? startDates : [startDates];

      const formatted = startDates
        .sort()
        .map((item) => format(parseISO(item), "dd.MM.yyyy"));

      return formatted.length > 1 ? `vom ${formatted[0]} bis ${formatted[1]}` : formatted;
    },
    filtersCount() {
      return Object.values(this.filters).length;
    },
    appliedFilters() {
      return {
        startDates: this.filters?.startDates || [],
        type: EventTypeModel.find(this.filters?.type),
        lessonTypes: LessonTypeModel.findIn(this.filters?.lessonTypes),
        licenses: LicenseModel.findIn(this.filters?.licenses),
        teacher: this.teachers.find(item => item.id === this.filters?.teacher),
        students: this.students.filter(item => this.filters?.students?.includes(item.id)),
        missed: this.filters?.missed,
        requested: this.filters?.requested,
        passedExam: this.filters?.passedExam
      };
    }
  },
  created () {
    this.initialFilters = { ...this.filterValues };

    Object.keys(this.filters).forEach(key => {
      this.filterValues[key] = this.filters[key];
    });

    if (!this.datesFilterApplied) {
      const currentDate = new Date();
      this.startDates = [
        format(startOfMonth(currentDate), "yyyy-MM-dd"),
        format(endOfMonth(currentDate), "yyyy-MM-dd")
      ];

      this.$set(this.filterValues, "startDates", this.startDates);
      this.applyFilters(this.filterValues);
    }

    this.loadListExportFileInfo();

    laravelEcho
      .private(`user.${this.currentUser.userId}`)
      .listen("AppointmentsListExportCompleted", (event) => this.verifyAndDownloadExportedFile(event));
  },
  mounted () {
    this.fetchTeachers();
    this.fetchStudents();
    this
      .fetch()
      .finally(() => {
        this.loading = false
      })
  },
  destroyed() {
    this.exportInfo = {};

    laravelEcho.private(`user.${this.currentUser.userId}`).stopListening("AppointmentsListExportCompleted");
  },
  methods: {
    ...mapActions('appointments', ['applyFilters']),
    fetch(params = { resetPage: true }) {
      const page = this.$route.query.page ?? 1;

      return appointmentService
        .list({
          ...this.filters,
          pagination: true,
          perPage: this.perPage,
          page: params.resetPage ? 1 : page
        })
        .then(resp => {
          const responseData = resp.data || [];

          this.pagination = responseData.pagination || {}

          if (params.resetPage && this.pagination.lastPage > 0 && Number(this.$route.query.page) !== 1) {
            this.$router.replace({ query: { ...this.$route.query, page: 1 } });
          }

          const appointments = (responseData.data || [])
            .map(item => ({
              ...item,
              allowedLicenses: item.allowedLicenses?.split(",") || [],
              studentsIds: item.studentsIds?.split(",") || []
            }));

          AppointmentModel.create({ data: appointments });
        })
    },
    fetchTeachers() {
      this.loadingTeachers = true;
      schoolService
        .getUsers({ type: "teacher" })
        .then(response => {
          this.teachers = response.data || [];
        })
        .finally(() => {
          this.loadingTeachers = false;
        });
    },
    fetchStudents() {
      this.loadingStudents = true;
      schoolService
        .getUsers({ type: "student" })
        .then(response => StudentModel.insert({ data: response.data || [] }))
        .finally(() => {
          this.loadingStudents = false;
        });
    },
    setSearchStudentText(searchText) {
      this.searchStudentText = searchText ?? "";
    },
    loadListExportFileInfo() {
      return appointmentService
        .listExportInfo()
        .then(response => {
          this.exportInfo = response.data || {};
        })
        .catch(error => {
          const response = error.response || {};
          const responseData = response.data || {};

          if (responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error" });
          }
        });
    },
    changePage() {
      this.changingPage = true
      this.fetch({ resetPage: false })
        .finally(() => {
          this.changingPage = false
        })
    },
    setStartDateFilter() {
      this.filterValues.startDates = this.startDates;
      this.$refs.dialog.save(this.filterValues.startDates.sort());
    },
    exportList() {
      this.loadingExport = true
      this.loadExport();
    },
    loadExport() {
      this.$snackbar.success(this.$t("messages.exporting_started"), 2000);

      appointmentService
        .listExport({ filters: this.filters, fileName: this.generateFileName() })
        .then(() => {
          this.loadListExportFileInfo()
            .finally(() => {
              this.loadingExport = false;
            });
        })
        .catch(error => {
          const response = error.response || {};
          const responseData = response.data || {};

          if (responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error" });
          }
          this.loadingExport = false;
        });
    },
    cancelExportList() {
      this.$snackbar.warning(this.$t("messages.exporting_canceled"), 2000);

      this.deletingExportList = true;
      appointmentService
        .deleteListExportFile()
        .then(() => {
          this.loadListExportFileInfo()
            .finally(() => {
              this.deletingExportList = false;
            });
        })
        .catch(() => {
          this.deletingExportList = false;
        });

    },
    filter() {
      this.$refs.filtersMenu.close();

      this.applyFilters(this.filterValues)

      this.loading = true
      this.fetch()
        .finally(() => {
          this.loading = false;
        })
    },
    clear() {
      this.filterValues = { ...this.initialFilters };
      this.applyFilters({})
      this.startDates = []
      this.loading = true

      this.fetch()
        .finally(() => {
          this.loading = false;
        })
    },
    removeFromAppliedFilters(filterName, filterId) {
      const filters = { ...this.filters };

      if (!filters[filterName]) return;

      if (Array.isArray(filters[filterName])) {
        filters[filterName] = filters[filterName]
          .filter((item, index, arr) => {
            return item !== filterId ||
              (new Set(arr).size !== arr.length && index === arr.indexOf(filterId));
          });
        if (filters[filterName].length === 0) delete filters[filterName];
        this.filterValues[filterName] = filters[filterName] || [];
      } else {
        delete filters[filterName];
        this.filterValues[filterName] = null;
      }

      this.applyFilters(filters)

      this.loading = true;
      this.fetch()
        .finally(() => {
          this.loading = false;
        });
    },
    removeStartDatesFilter() {
      const filters = { ...this.filters };

      this.filterValues.startDates = []
      delete filters['startDates']
      this.startDates = []

      this.applyFilters(filters)

      this.loading = true;
      this.fetch()
          .finally(() => {
            this.loading = false;
          });
    },
    generateFileName() {
      let fileName = "Terminübersicht";

      const startDates = this.appliedFilters.startDates || [];
      const lessonTypes = this.appliedFilters.lessonTypes || [];
      const licenses = this.appliedFilters.licenses || [];
      const students = this.appliedFilters.students || [];

      startDates.forEach((date, index) => {
        if (index === 0) {
          fileName += ` vom ${format(parseISO(date), "dd.MM.yyyy")}`;
        } else if (index === 1) {
          fileName += ` bis ${format(parseISO(date), "dd.MM.yyyy")}`;
        }
      });

      this.appliedFilters.type && (fileName += ` ${this.$t(`event_type.${this.appliedFilters.type.id}`)}`);
      lessonTypes.forEach(lessonType => fileName += `-${this.$tc(lessonType.name)}`);
      licenses.forEach(license => fileName += ` ${license.name}`);

      this.appliedFilters.teacher && (fileName += ` ${this.appliedFilters.teacher.fullName}`);
      students.forEach(student => fileName += ` ${student.fullName}`);

      this.appliedFilters.missed && (fileName += ` ${this.$t("eventStatus.MISSED")}`);
      this.appliedFilters.requested && (fileName += ` ${this.$t("eventStatus.REQUESTED")}`);
      this.appliedFilters.passedExam && (fileName += ` ${this.$t("eventStatus.passed_exam")}`);

      return fileName;
    },
    onFilterTypeInput() {
      this.filterValues.lessonTypes = [];
    },
    clearFilterByDate() {
      this.filterValues.startDates = [];
      this.startDates = []
    },
    getUsersFiltersOptionsWithHeaders(items) {
      const active = this.sortItemsByFullName(items.filter(item => item.active && item.deletedAt === null))
      const inActive = this.sortItemsByFullName(items.filter(item => !item.active && item.deletedAt === null))
      const deleted = this.sortItemsByFullName(items.filter(item => item.deletedAt !== null))

      let withHeaders = []
      if (active.length > 0) {
        withHeaders = [
          { header: this.$t('label.active') },
          { divider: true },
          ...active
        ]
      }
      if (inActive.length > 0) {
        withHeaders = [
          ...withHeaders,
          { header: this.$t('label.inactive') },
          { divider: true },
          ...inActive,
        ]
      }
      if (deleted.length > 0) {
        withHeaders = [
          ...withHeaders,
          { header: this.$t('label.deleted') },
          { divider: true },
          ...deleted,
        ]
      }

      return withHeaders
    },
    sortItemsByFullName(items = []) {
      return items.sort((b, a) => {
        if (a.fullName < b.fullName) return 1;
        if (a.fullName > b.fullName) return -1;

        return 0;
      });
    },
    verifyAndDownloadExportedFile(event) {
      if (event.status === "cancelled") {
        this.loadListExportFileInfo();
        return;
      }

      this.loadListExportFileInfo().then(() => {
        if (["pending", "cancelling"].includes(this.exportInfo.status)) return;

        if (!this.downloading) {
          this.downloadExportedFile();
        }
      });
    },
    downloadExportedFile() {
      this.downloading = true;
      appointmentService.listExportFileDownload()
        .then(response => {
          const fileSaver = new FileSaver([response.data]);
          fileSaver
            .setType("text/csv")
            .saveToDevice(this.exportInfo.fileName + ".csv", "appointments");

          this.loadListExportFileInfo()
            .finally(() => {
              this.$nextTick(() => {
                this.downloading = false;
              });
            });
        })
        .catch(() => {
          this.$nextTick(() => {
            this.downloading = false;
          });
        });
    }
  }
}
</script>

<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-card>
    <v-toolbar elevation="3">
      <v-card-title>{{ $t("label.appointmentsOverview") }}</v-card-title>
    </v-toolbar>

    <v-card-text>
      <v-row :class="{'flex-column-reverse': $vuetify.breakpoint.xs}">
        <v-col class="d-flex align-center" cols="12" md="7">
          <template v-if="filtersCount > 0">
            <v-chip-group show-arrows>
              <v-chip
                v-if="appliedFilters.startDates.length > 0"
                :disabled="loading"
                close
                small
                @click:close="removeStartDatesFilter"
              >
                <span>{{appliedFilters.startDates[0] | dateFormat("dd.MM.yyyy")}}</span>
                <span v-if="appliedFilters.startDates.length > 1">&nbsp;-&nbsp;{{appliedFilters.startDates[1] | dateFormat("dd.MM.yyyy")}}</span>
              </v-chip>

              <v-chip
                v-if="appliedFilters.type"
                :key="`type_${appliedFilters.type.id}`"
                :disabled="loading"
                close
                small
                @click:close="removeFromAppliedFilters('type', appliedFilters.type.id)"
              >
                {{ $t(`event_type.${appliedFilters.type.id}`) }}
              </v-chip>

              <v-chip
                v-for="lessonType in appliedFilters.lessonTypes"
                :key="`type_${lessonType.id}`"
                :disabled="loading"
                close
                small
                @click:close="removeFromAppliedFilters('lessonTypes', lessonType.id)"
              >
                {{ $tc(lessonType.name) }}
              </v-chip>

              <v-chip
                v-for="license in appliedFilters.licenses"
                :key="`license_${license.id}`"
                :disabled="loading"
                close
                small
                @click:close="removeFromAppliedFilters('licenses', license.id)"
              >
                {{ license.name }}
              </v-chip>

              <v-chip
                v-if="appliedFilters.teacher"
                :key="`teacher_${appliedFilters.teacher.id}`"
                :disabled="loading"
                close
                small
                @click:close="removeFromAppliedFilters('teacher', appliedFilters.teacher.id)"
              >
                {{ appliedFilters.teacher.fullName }}
              </v-chip>

              <v-chip
                v-for="student in appliedFilters.students"
                :key="`license_${student.id}`"
                :disabled="loading"
                close
                small
                @click:close="removeFromAppliedFilters('students', student.id)"
              >
                {{ student.fullName }}
              </v-chip>

              <v-chip
                v-if="appliedFilters.missed"
                key="missed"
                :disabled="loading"
                close
                small
                @click:close="removeFromAppliedFilters('missed')"
              >
                {{ $t("eventStatus.MISSED") }}
              </v-chip>

              <v-chip
                v-if="appliedFilters.requested"
                key="requested"
                :disabled="loading"
                close
                small
                @click:close="removeFromAppliedFilters('requested')"
              >
                {{ $t("eventStatus.REQUESTED") }}
              </v-chip>

              <v-chip
                v-if="appliedFilters.passedExam"
                key="passedExam"
                :disabled="loading"
                close
                small
                @click:close="removeFromAppliedFilters('passedExam')"
              >
                {{ $t("label.passed_exam") }}
              </v-chip>
            </v-chip-group>
          </template>
        </v-col>

        <v-col class="d-flex align-center" cols="12" md="5">
          <v-spacer />

          <div class="mr-1 flex align-center flex-grow-0">
            <v-btn
              v-if="loadingExportBtn && !deletingExportList && exportInfo.status !== 'cancelling'"
              :disabled="deletingExportList"
              icon
              small
              text
              @click="cancelExportList"
            >
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>

            <v-btn
              :disabled="isExportListBtnDisabled"
              :loading="loadingExportBtn"
              color="primary"
              icon
              text
              @click="exportList"
            >
              <v-icon>mdi-download-outline</v-icon>
            </v-btn>
          </div>

          <ShowsAs
            ref="filtersMenu"
            :options="{
              fullScreen: $vuetify.breakpoint.mobile,
              offsetX: true,
              offsetY: true,
              left: true,
              zIndex: 10,
              minWidth: 800,
              maxWidth: 200
            }"
            :type="$vuetify.breakpoint.mobile ? 'dialog' : 'menu'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                :content="filtersCount"
                :value="filtersCount"
                color="primary"
                overlap
              >
                <v-btn
                  :disabled="loading || filtering || changingPage"
                  :loading="filtering"
                  color="primary"
                  icon
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-filter-outline</v-icon>
                </v-btn>
              </v-badge>
            </template>

            <v-card :tile="$vuetify.breakpoint.mobile">
              <v-card-title>{{ $t("label.filters") }}</v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-dialog
                      ref="dialog"
                      v-model="dateFilterDialog"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="formattedDates"
                          clearable
                          dense
                          hide-details
                          :label="$t('label.appointmentDate')"
                          :placeholder="$t('label.appointmentDate')"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="clearFilterByDate"
                        />
                      </template>

                      <v-date-picker
                        v-model="startDates"
                        first-day-of-week="1"
                        range
                        scrollable
                      >
                        <v-spacer />
                        <v-btn color="primary" text @click="dateFilterDialog = false">{{ $t("btn.cancel") }}</v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click="setStartDateFilter"
                        >
                          {{ $t("btn.ok") }}
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="filterValues.type"
                      :items="appointmentTypesOptions"
                      :label="$t('label.appointmentType')"
                      :placeholder="$t('label.appointmentType')"
                      clearable
                      dense
                      hide-details
                      outlined
                      @input="onFilterTypeInput"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="filterValues.lessonTypes"
                      :items="lessonTypeOptions"
                      :label="$t('label.lesson_type')"
                      :placeholder="$t('label.lesson_type')"
                      clearable
                      dense
                      hide-details
                      multiple
                      outlined
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="filterValues.licenses"
                      :items="licensesOptions"
                      :label="$t('form.licenses')"
                      :placeholder="$t('form.licenses')"
                      clearable
                      dense
                      hide-details
                      multiple
                      outlined
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="filterValues.teacher"
                      :items="teachersOptions"
                      :disabled="loadingTeachers"
                      :loading="loadingTeachers"
                      :label="$t('label.teacher')"
                      :placeholder="$t('label.teacher')"
                      clearable
                      dense
                      hide-details
                      outlined
                      item-text="fullName"
                      item-value="id"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title :class="{'font-italic disabled': (item.deletedAt !== null || !item.active)}">
                            {{ item.fullName }}
                            <v-icon small v-if="item.deletedAt !== null" class="mb-1 ml-1">mdi-trash-can</v-icon>
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="filterValues.students"
                      :items="studentsOptions"
                      :label="$t('label.students')"
                      :placeholder="$t('label.students')"
                      :loading="loadingStudents"
                      :disabled="loadingStudents"
                      :menu-props="{maxHeight: '200px', contentClass: 'fit-parent-width'}"
                      clearable
                      dense
                      multiple
                      outlined
                      item-text="fullName"
                      item-value="id"
                      @update:search-input="setSearchStudentText"
                      hide-details
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-action>
                          <v-checkbox :input-value="filterValues.students.includes(item.id)"></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title :class="{'font-italic disabled': (item.deletedAt !== null || !item.active)}">
                            {{ item.fullName }}
                            <v-icon small v-if="item.deletedAt !== null" class="mb-1 ml-1">mdi-trash-can</v-icon>
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-checkbox
                      v-model="filterValues.missed"
                      dense
                      hide-details
                      :label="$t('label.missed')"
                      :placeholder="$t('label.missed')"
                    />
                  </v-col>
                  <v-col>
                    <v-checkbox
                      v-model="filterValues.requested"
                      dense
                      hide-details
                      :label="$t('label.requested')"
                      :placeholder="$t('label.requested')"
                    />
                  </v-col>
                  <v-col>
                    <v-checkbox
                      v-model="filterValues.passedExam"
                      dense
                      hide-details
                      :label="$t('label.passed_exam')"
                      :placeholder="$t('label.passed_exam')"
                    />
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider />

              <v-card-actions>
                <v-btn color="red" text @click="clear">{{ $t("btn.clear") }}</v-btn>
                <v-spacer />
                <v-btn color="primary" text @click="filter">{{ $t("btn.apply") }}</v-btn>
              </v-card-actions>
            </v-card>
          </ShowsAs>

          <template v-if="pagination.lastPage > 0">
            <v-divider class="mx-2" vertical />

            <Pagination
              ref="pagination"
              :current-page="pagination.currentPage"
              :from="pagination.from"
              :loading="changingPage"
              :to="pagination.to"
              :total-nr-of-items="pagination.total"
              :total-nr-of-pages="pagination.lastPage"
              @pageChange="changePage"
            />
          </template>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text v-if="loading">
      <v-skeleton-loader class="mx-auto" type="table-row@3" />
    </v-card-text>

    <template v-else>
      <AppointmentsTable v-if="appointments.length > 0" :appointments="appointments"/>
      <empty-box v-else />
    </template>
  </v-card>
  </v-container>
</template>

<style scoped>

</style>
