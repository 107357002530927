<template>
  <v-dialog
    v-model="show"
    width="1010"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>{{ $t("label.add_licenses") }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <ValidationObserver ref="form">
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <ValidationProvider v-slot="{ errors }" name="licensesIds">
                <v-chip-group v-model="form.licensesIds" column multiple>
                  <v-chip
                    class="text-uppercase"
                    :color="errors.length > 0 ? 'red' : ''"
                    filter
                    outlined
                    v-for="license in licensesOptions"
                    :key="license.value"
                    :value="license.value">
                    {{ license.text }}
                  </v-chip>
                </v-chip-group>

                <span class="red--text text-caption" v-if="errors.length > 0">{{errors[0]}}</span>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-text>
      </ValidationObserver>

      <v-divider class="mt-auto" />

      <v-card-actions>
        <v-btn text @click="close">
          <v-icon v-if="$vuetify.breakpoint.mobile">mdi-close</v-icon>
          <span v-else>{{ $t("btn.close") }}</span>
        </v-btn>

        <v-spacer/>

        <v-btn
          class="tile"
          color="primary"
          @click="save"
          :disabled="formLoading"
          :loading="formLoading"
        >
          <v-icon v-if="$vuetify.breakpoint.mobile">mdi-content-save</v-icon>
          <span v-else>{{ $t("btn.save") }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import licenses from "@/utils/mixins/licenses";
import LicenseModel from "@/store/models/LicenseModel";
import priceCategoriesService from '@/services/priceCategoriesService'

export default {
  name: "AddLicensesDialog",
  mixins: [licenses],
  data: () => ({
    show: false,
    formLoading: false,
    form: {
      licensesIds: [],
    },
    initialForm: {},
    priceCategoryId: null,
    licenses: []
  }),
  computed: {
    licensesOptions() {
      return LicenseModel.query()
        .where(license => !this.licenses.some(item => item.id === license.id))
        .get()
        .map((license) => {
          return {
            text: license.name,
            value: license.id,
          };
        });
    }
  },
  created() {
    this.initialForm = JSON.parse(JSON.stringify(this.form));
  },
  methods: {
    open(priceCategoryId, licenses) {
      this.show = true;
      this.priceCategoryId = priceCategoryId
      this.licenses = licenses
    },
    close() {
      this.show = false;
      this.form = JSON.parse(JSON.stringify(this.initialForm));
      this.$refs.form.reset();
      this.priceCategoryId = null
      this.licenses = []
    },
    save() {
      this.formLoading = true;
      priceCategoriesService
        .addLicenses(this.priceCategoryId, this.form)
        .then((response) => {
          this.$emit('added', response.data || [])
          this.close();
        })
        .catch((error) => {
          const responseData = error.response.data || {};
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error", });
          }

          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
