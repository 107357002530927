<template>
  <v-dialog
    max-width="750px"
    :value="showModalGetter"
    @click:outside="closeAppointmentModal"
    @keydown.esc="closeAppointmentModal"
    :fullscreen="isMobile"
    :hide-overlay="isMobile"
    scrollable
  >
    <v-card :tile="isMobile" style="padding-top:0!important;">
      <TopBar class="flex-shrink-0 flex-grow-0" :color="appointmentColor.bodyColor" :happyHour="false" />

      <AppointmentTypesTabs class="my-2" :color="appointmentColor.bodyColor" />
      <v-divider></v-divider>

      <v-card-text class="mt-3 pt-1">
        <template v-if="skeleton">
          <component :is="skeletonComponent" />
        </template>

        <template v-else>
          <FormDateTimeArea />
          <ValidationObserver ref="observer">
            <component
                class="mt-3"
                :is="formComponent"
                :teachers="teachers"
                @emitPickRequestedStudent="pickRequestedStudent"
                :placeholderRequestedStudent = placeholderRequestedStudent />
          </ValidationObserver>
        </template>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <Button @click.native="closeAppointmentModal"
                :text="true" color="black" icon="mdi-close"
                :label="$t('btn.close')" :disabled="appointmentLoader" />

        <v-spacer />

        <Button v-if="showDeleteBtn"
                @click.native="deleteApp"
                color="error" :icon="currentAppointment.type === 'PLACEHOLDER' ? 'mdi-delete-forever' : 'mdi-delete'"
                :label="$t('btn.delete')"
                :disabled="appointmentLoader" :loading="buttonLoaders.deleteLoader" />

        <Button v-if="showSimulatorDeclineBtn"
                @click.native="declineSimulatorApp"
                color="error" icon="mdi-minus-circle"
                :label="$t('btn.decline')"
                :disabled="appointmentLoader" :loading="buttonLoaders.unsubscribeLoader" />

        <Button v-if="showUpdateBtn"
                @click.native="updateApp"
                color="primary" icon="mdi-content-save-edit"
                :label="$t('btn.update')"
                :disabled="appointmentLoader" :loading="buttonLoaders.updateLoader" />

        <Button v-if="showCreateBtn"
                @click.native="createApp"
                color="primary" icon="mdi-content-save"
                :label="$t('btn.create')"
                :disabled="appointmentLoader" :loading="buttonLoaders.createLoader" />

        <template  v-if="showRequestedPlaceholderBtn">
          <Button @click.native="declinePlaceholderApp"
                  color="error" icon="mdi-minus-circle"
                  :label="$t('btn.decline')"
                  :disabled="appointmentLoader" :loading="buttonLoaders.declineLoader" />

          <Button @click.native="confirmPlaceholderApp"
                  color="primary" icon="mdi-check-circle"
                  :label="$t('btn.confirm')"
                  :disabled="appointmentLoader" :loading="buttonLoaders.confirmLoader" />
        </template>

        <template  v-if="showRequestedPracticeBtn">
          <Button @click.native="declinePracticeApp"
                  color="error" icon="mdi-minus-circle"
                  :label="$t('btn.decline')"
                  :disabled="appointmentLoader" :loading="buttonLoaders.declineLoader" />

          <Button @click.native="confirmPracticeApp"
                  color="primary" icon="mdi-check-circle"
                  :label="$t('btn.confirm')"
                  :disabled="appointmentLoader" :loading="buttonLoaders.confirmLoader" />
        </template>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import colorMixin from '@/utils/mixins/colorMixin'

import Button from "@/components/calendar/appointmentModal/Button";
import TopBar from "@/components/calendar/appointmentModal/TopBar";
import FormDateTimeArea from "@/components/calendar/appointmentModal/fields/FormDateTimeArea";
import AppointmentTypesTabs from "@/components/calendar/appointmentModal/AppointmentTypesTabs";
import OfftimeForm from "@/components/calendar/appointmentModal/forms/OfftimeForm";
import PlaceholderForm from "@/components/calendar/appointmentModal/forms/PlaceholderForm";
import PracticeForm from "@/components/calendar/appointmentModal/forms/PracticeForm";
import SimulatorForm from "@/components/calendar/appointmentModal/forms/SimulatorForm";
import SchoolSpecialForm from "@/components/calendar/appointmentModal/forms/SpecialForm";
import SchoolTheoryForm from "@/components/calendar/appointmentModal/forms/TheoryForm";

import OfftimeLoader from "@/components/skeleton-loaders/appointment-form/OfftimeLoader";
import PlaceholderLoader from "@/components/skeleton-loaders/appointment-form/PlaceholderLoader";

import TeacherModel from "@/store/models/TeacherModel";

import { showConfirm } from "@/helpers/dialogs";

export default {
  name: "SchoolAppointmentModal",
  mixins: [colorMixin],
  components: {
    Button,
    TopBar,
    AppointmentTypesTabs,
    FormDateTimeArea,

    OFFTIME_FORM: OfftimeForm,
    PLACEHOLDER_FORM: PlaceholderForm,
    PRACTICE_FORM: PracticeForm,
    SIMULATOR_FORM: SimulatorForm,
    SPECIAL_FORM: SchoolSpecialForm,
    THEORY_FORM: SchoolTheoryForm,

    OFFTIME_LOADER: OfftimeLoader,
    PLACEHOLDER_LOADER: PlaceholderLoader,
  },
  data: () => ({
    skeleton: false,
    placeholderRequestedStudent: null,
  }),
  props: {
    currentAppointment: {
      type: Object,
      required: true,
    }
  },
  watch: {
    showModalGetter() {
      this.skeleton = false;
      this.placeholderRequestedStudent = null;
    },
  },

  computed: {
    ...mapState("appointments", ["buttonLoaders"]),
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("appointments", ["showModalGetter", "initialDurationGetter",]),
    showDeleteBtn() {
      return this.currentAppointment.id &&
          !this.showRequestedPracticeBtn &&
          !this.showRequestedPlaceholderBtn &&
          !this.showSimulatorDeclineBtn;
    },
    showSimulatorDeclineBtn() {
      return this.currentAppointment.type === 'SIMULATOR' && this.currentAppointment.isSubscribed;
    },
    showRequestedPlaceholderBtn() {
      return this.currentAppointment.type === 'PLACEHOLDER' && this.currentAppointment.isRequested;
    },
    showRequestedPracticeBtn() {
      return this.currentAppointment.type === 'PRACTICE' && this.currentAppointment.status === 'REQUESTED';
    },
    showCreateBtn() {
      return !this.currentAppointment.id;
    },
    showUpdateBtn() {
      return this.currentAppointment.id && !this.showRequestedPlaceholderBtn && !this.showRequestedPracticeBtn;
    },
    appointmentLoader() {
      return this.$store.state.appointments.appointmentLoader || false;
    },
    teachers() {
      return TeacherModel.query().where("active", true).get();
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    formComponent() {
      return this.currentAppointment.type + "_FORM";
    },
    skeletonComponent() {
      return this.currentAppointment.type + "_LOADER";
    },
  },
  methods: {
    ...mapActions("appointments", [
          "closeAppointmentModal",
          "createAppointment",
          "updateAppointment",
          "deleteAppointment",
          "confirmPlaceholder",
          "declinePlaceholder",
          "confirmPractice",
          "declinePractice",
          "unsubscribeFromSimulator",
    ]),
    pickRequestedStudent (val) {
      this.placeholderRequestedStudent = val
    },

    async createApp () {
      const validate = await this.$refs.observer.validate()
      if (validate) this.createAppointment()
    },

    async updateApp () {
      const validate = await this.$refs.observer.validate()
      if (validate) {
        await showConfirm(
            this.$t('btn.confirm'),
            this.$t('messages.are_your_sure_delete'),
            () => this.updateAppointment()
        )
      }
    },
    deleteApp () {
      showConfirm(
          this.$t('btn.confirm'),
          this.$t('messages.are_your_sure_update'),
          () => this.deleteAppointment()
      )
    },
    async confirmPlaceholderApp () {
      const validate = await this.$refs.observer.validate()
      if (validate) {
        await showConfirm(
            this.$t('btn.confirm'),
            this.$t('messages.are_your_sure_confirm_placeholder'),
            () => this.confirmPlaceholder(this.placeholderRequestedStudent)
        )
      }
    },
    declinePlaceholderApp () {
      showConfirm(
          this.$t('btn.confirm'),
          this.$t('messages.are_your_sure_decline_placeholder'),
          () => this.declinePlaceholder()
      )
    },
    async confirmPracticeApp () {
      const validate = await this.$refs.observer.validate()
      if (validate) {
        await showConfirm(
            this.$t('btn.confirm'),
            this.$t('messages.are_your_sure_confirm_practice'),
            () => this.confirmPractice()
        )
      }
    },

    declinePracticeApp () {
      showConfirm(
          this.$t('btn.confirm'),
          this.$t('messages.are_your_sure_decline_practice'),
          () => this.declinePractice()
      )
    },

    declineSimulatorApp () {
      showConfirm(
          this.$t('btn.confirm'),
          this.$t('messages.are_your_sure_unsubscribe_simulator'),
          () => this.unsubscribeFromSimulator()
      )
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input__prepend-inner,
.v-input__append-inner {
  cursor: pointer;
}
.justify-center {
  display: flex;
  justify-content: space-between;
}
</style>
