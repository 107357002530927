<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.mobile"
    scrollable
    width="500"
    :value="show"
    persistent
    @keydown.esc="close"
  >
    <v-card>
      <v-toolbar class="mb-4 flex-grow-0" style="border-radius: 0" dark color="primary" dense>
        <v-toolbar-title>{{ $t('label.upcoming_theories_for_topic') }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-date-picker
        v-model="date"
        :min="today"
        no-title
        full-width
        first-day-of-week="1"
        :disabled="loading"
        :events="calendarEvents"
        @update:picker-date="monthChange"
      />

      <v-divider/>

      <v-card-text>
        <v-subheader v-if="theoryTopic" class="mt-2">
          {{ $t('form.topic') + ' ' + theoryTopic.orderIndex }}: {{ theoryTopic.name }}
        </v-subheader>
        <div v-if="loading">
          <v-skeleton-loader type="list-item-two-line" />
        </div>
        <template v-else-if="appointments.length">
          <v-list>
            <template v-if="appointmentsForDate.length">
              <v-list-item two-line v-for="appointment in appointmentsForDate" :key="appointment.id">
                <v-list-item-content>
                  <v-list-item-title>{{ appointment.start | dateFormat("dd.MM.yyyy HH:mm") }}</v-list-item-title>
                  <v-list-item-subtitle>{{ appointment.teacherName }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    small
                    :color="appointment.isSubscribed ? 'red' : 'primary'"
                    text
                    :disabled="subscribing !== null"
                    :loading="subscribing === appointment.id"
                    @click="appointment.isSubscribed ? unsubscribe(appointment.id) : subscribe(appointment.id)"
                  >
                    <v-icon left>{{ appointment.isSubscribed ? 'mdi-calendar-minus' : 'mdi-calendar-plus' }}</v-icon>
                    {{ appointment.isSubscribed ? $t('btn.abandon') : $t('btn.attend') }}
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
            <empty-box v-else />
          </v-list>
        </template>
        <empty-box v-else />
      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-btn @click="close" text>{{ $t("btn.close") }}</v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import appointmentService from "@/services/appointmentService";
import user from "@/utils/mixins/user";
import {format, isSameDay, parseISO} from "date-fns";

export default {
  name: "TheoryAttendanceShortcutDialog",
  mixins: [user],
  data: () => ({
    show: false,
    loading: true,
    theoryTopic: null,
    appointments: [],
    student: null,
    subscribing: null,
    today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    date: null,
  }),
  computed: {
    studentId () {
      return this.isStudent ? this.currentUser.id : parseInt(this.student);
    },
    appointmentsForDate () {
      return this.appointments.filter(appointment => {
        const selectedDate = parseISO(this.date)
        const appointmentDate = parseISO(appointment.start)
        return isSameDay(selectedDate, appointmentDate)
      })
    },
    calendarEvents () {
      // generate the dots for the calendar based on the appointments
      const appointments = {}
      this.appointments.forEach(appointment => {
        const date = parseISO(appointment.start)
        const key = format(date, 'yyyy-MM-dd')
        if (!appointments[key]) {
          appointments[key] = new Set()
        }
        appointments[key].add(appointment.isSubscribed ? 'green' : 'purple')
      })

      const keys = Object.keys(appointments)

      keys.forEach(key => {
        appointments[key] = [...appointments[key]]
      })

      return appointments
    }
  },
  methods: {
    open(theoryTopic, studentId = null) {
      this.show = true
      this.theoryTopic = theoryTopic
      this.student = studentId
      this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.loading = true;
      this.load()
    },
    close() {
      this.show = false
      this.theoryTopic = null
    },
    load(month = null) {
      const date = month || this.date
      appointmentService
          .getUpcomingTheoriesByTopic(this.theoryTopic.id, { studentId: this.studentId, date })
          .then(resp => {
            this.appointments = resp.data.map(item => {
              return {
                ...item,
                isSubscribed: item.studentsIds.filter(item => item.id === this.studentId).length > 0
              }
            })
          })
          .finally(() => {
            this.loading = false
          })
    },
    monthChange (date) {
      this.loading = true;
      this.load(date + '-01')
    },
    subscribe (theoryId) {
      this.subscribing = theoryId
      appointmentService
          .subscribe_to_theory(theoryId, { studentsIds: [this.studentId], wasScannedWithQR: false })
          .then(() => {
            const index = this.appointments.findIndex(item => item.id === theoryId)
            this.$set(this.appointments, index, {...this.appointments[index], isSubscribed: true })
          })
          .catch((err) => {
            const { data } = err.response;

            if (data?.message) {
              this.$snackbar.show({ message: data?.message, color: "error" });
            }
          })
          .finally(() => {
            this.subscribing = null
          })
    },
    unsubscribe(theoryId) {
      this.subscribing = theoryId
      appointmentService
          .unsubscribe_from_theory(theoryId, { studentsIds: [this.studentId] })
          .then(() => {
            const index = this.appointments.findIndex(item => item.id === theoryId)
            this.$set(this.appointments, index, {...this.appointments[index], isSubscribed: false })
          })
          .catch((err) => {
            const { data } = err.response
            this.$snackbar.show({ message: data?.message, color: "error" })
          })
          .finally(() => {
            this.subscribing = null
          })
    },
  }
}
</script>

<style scoped>

</style>
