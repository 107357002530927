<template>
  <ListFiltersMenu @clear="onClearFilters" @apply="onApplyFilters">
    <template>
      <v-row>
        <v-col cols="12" md="6">
          <v-sheet class="pa-2" rounded outlined>
            <v-switch
              class="mt-0"
              v-model="filters.withoutAppointments"
              :label="$t('filters.without_appointments')"
              :placeholder="$t('filters.without_appointments')"
              dense
              hide-details
            />
          </v-sheet>
        </v-col>

        <v-col cols="12" md="6">
          <v-sheet class="pa-2" rounded outlined>
            <v-switch
              class="mt-0"
              v-model="filters.noBalance"
              :label="$t('filters.no_balance')"
              :placeholder="$t('filters.no_balance')"
              dense
              hide-details
            />
          </v-sheet>
        </v-col>

        <v-col cols="12" md="6">
          <v-sheet class="pa-2" rounded outlined>
            <v-switch
              class="mt-0"
              v-model="filters.automaticInvoicingDisabled"
              :label="$t('filters.automatic_invoicing_disabled')"
              :placeholder="$t('filters.automatic_invoicing_disabled')"
              dense
              hide-details
            />
          </v-sheet>
        </v-col>

        <v-col cols="12" md="6" v-if="$store.state.school.hasClickAndLearn">
          <v-sheet class="pa-2" rounded outlined>
            <v-switch
              class="mt-0"
              v-model="filters.hasClickAndLearn"
              :label="$t('filters.hasClickAndLearn')"
              :placeholder="$t('filters.hasClickAndLearn')"
              dense
              hide-details
            />
          </v-sheet>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6">
          <v-menu
            v-model="createdAtMenu"
            offset-y
            min-width="auto"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                readonly
                :label="$t('filters.createdAt')"
                :placeholder="$t('filters.createdAt')"
                prepend-inner-icon="mdi-calendar"
                :value="formattedDates"
                v-bind="attrs"
                v-on="on"
                clearable
                outlined
                hide-details
                @click:clear="filters.createdAtDates = []"
              />
            </template>

            <v-date-picker
              v-model="filters.createdAtDates"
              first-day-of-week="1"
              no-title
              range
            >
              <v-spacer />
              <v-btn text color="primary" @click="createdAtMenu = false">{{$t("btn.cancel")}}</v-btn>
              <v-btn text color="primary" @click="onCreatedAtDatesMenuOK">{{$t("btn.ok") }}</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" lg="3">
          <v-select
            type="string"
            v-model="filters.activityStatus"
            :items="[
              { text: $t('label.active'), value: 'active' },
              { text: $t('label.inactive'), value: 'inactive' },
            ]"
            :label="$t('filters.active_inactive')"
            :placeholder="$t('filters.active_inactive')"
            dense
            outlined
            hide-details
            clearable
            :disabled="filters.deletedStudents"
          />
        </v-col>
        <v-col cols="12" lg="3">
          <v-select
            type="string"
            v-model="filters.educationStatus"
            :items="educationStatusesAsOptions"
            :label="$t('filters.education_status')"
            :placeholder="$t('filters.education_status')"
            dense
            outlined
            hide-details
            clearable
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="12">
          <v-select
            type="string"
            v-model="filters.licenses"
            :items="licensesAsOptions"
            :label="$t('filters.licenses')"
            :placeholder="$t('filters.licenses')"
            dense
            outlined
            hide-details
            multiple
            clearable
            offset-y
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-select
            type="string"
            v-model="filters.teachers"
            :items="teachersAsOptions"
            :label="$t('filters.teachers')"
            :placeholder="$t('filters.teachers')"
            dense
            outlined
            hide-details
            multiple
            clearable
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-select
              type="string"
              v-model="filters.priceCategory"
              :items="priceCategoriesAsOptions"
              :label="$t('filters.price_list')"
              :placeholder="$t('filters.price_list')"
              dense
              outlined
              hide-details
              clearable
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="12">
          <v-select
              type="string"
              v-model="filters.office"
              :items="officesAsOptions"
              :label="$t('filters.office')"
              :placeholder="$t('filters.office')"
              dense
              outlined
              hide-details
              clearable
          />
        </v-col>
      </v-row>
    </template>
  </ListFiltersMenu>
</template>

<script>
import LicenseModel from "@/store/models/LicenseModel";
import ListFiltersMenu from "@/components/list/ListFiltersMenu";
import listFilters from "@/utils/mixins/listFilters";
import i18n from "@/plugins/i18n";
import teachersService from "@/services/teachersService";
import officesService from "@/services/officesService";
import { format, parseISO } from "date-fns";
import ProductCategoryModel from '@/store/models/ProductCategoryModel'

export default {
  name: "StudentListFilters",
  components: { ListFiltersMenu },
  mixins: [listFilters],
  data: () => ({
    licenses: [],
    offices: [],
    teachers: [],
    initialFilters: {},
    createdAtMenu: false,
    filters: {
      office: "",
      licenses: [],
      teachers: [],
      priceCategory: "",
      createdAtDates: [],
      activityStatus: "",
      educationStatus: "",
      withoutAppointments: false,
      noBalance: false,
      automaticInvoicingDisabled: false,
      hasClickAndLearn: false,
    },
  }),
  computed: {
    licensesAsOptions() {
      return LicenseModel.all().map((item) => ({
        text: item.name,
        value: item.id.toString(),
      }));
    },
    teachersAsOptions() {
      return this.teachers.map((item) => ({
        text: item.fullName,
        value: item.id.toString(),
      }));
    },
    officesAsOptions() {
      return this.offices.map((item) => ({
        text: item.name,
        value: item.id.toString(),
      }));
    },
    educationStatusesAsOptions() {
      return [
        { text: i18n.t("education_statuses.registered"), value: "registered" },
        { text: i18n.t("education_statuses.learning"), value: "learning" },
        { text: i18n.t("education_statuses.finished"), value: "finished" },
      ];
    },
    priceCategoriesAsOptions() {
      return ProductCategoryModel.all().map((item) => ({
        text: item.name,
        value: item.id.toString(),
      }))
    },
    formattedDates() {
      const periodDates =
        typeof this.filters.createdAtDates === "string"
          ? [this.filters.createdAtDates]
          : this.filters.createdAtDates;

      const formatted = periodDates
        .sort()
        .map((item) => format(parseISO(item), "dd.MM.yyyy"));

      return formatted.length > 1
        ? `vom ${formatted[0]} bis ${formatted[1]}`
        : formatted;
    },
  },
  mounted() {
    this.initialFilters = { ...this.filters };
    if (Object.keys(this.appliedFiltersValues).length) {
      Object.keys(this.appliedFiltersValues).forEach((queryParam) => {
        if (Object.prototype.hasOwnProperty.call(this.filters, queryParam)) {
          this.$set(
            this.filters,
            queryParam,
            this.appliedFiltersValues[queryParam]
          );
        }
      });
    }

    const formattedFilters = this.formatFilters(this.filters);
    if (formattedFilters && formattedFilters !== this.$route.query.filters) {
      this.$router.replace({ query: { filters: formattedFilters } });
    }

    teachersService
      .load()
      .then((response) => (this.teachers = response.data || []));

    officesService
      .load()
      .then((response) => (this.offices = response.data || []));
  },
  methods: {
    onApplyFilters() {
      const filters = this.formatFilters(this.filters);
      this.$router
        .replace({
          query: {
            filters: filters || undefined,
            sort_type: this.$route.query.sort_type || undefined,
          },
        })
        .then(() => {
          this.$emit("applyFilters");
        });
    },
    onClearFilters() {
      this.filters = { ...this.initialFilters };
    },
    onCreatedAtDatesMenuOK() {
      this.createdAtMenu = false;
    },
  },
};
</script>

<style scoped></style>
