<template>
  <v-bottom-navigation class="bottom-menu pr-0" fixed app grow dark>
    <v-btn
        :class="!isCurrentUserActive || isStudentRegistered ? 'disabled-btn' : ''"
        to="/dashboard"
        :disabled="!isCurrentUserActive || isStudentRegistered"
    >
      <span class="mt-1">{{ $t("route.dashboard") }}</span>
      <v-img
        width="22"
        height="22"
        contain
        class="flex-grow-0 "
        :src="require('@/assets/svg/dashboard.svg')" />
    </v-btn>

    <v-btn
      :class="!isCurrentUserActive || isStudentRegistered ? 'disabled-btn' : ''"
      to="/calendar"
      :disabled="!isCurrentUserActive || isStudentRegistered"
    >
      <span class="mt-1">{{ $t('route.calendar') }}</span>
      <v-img
        width="22"
        height="22"
        contain
        class="flex-grow-0"
        :src="require('@/assets/svg/calendar.svg')"/>
    </v-btn>

    <v-btn
      v-if="!isSchoolAdministrator"
      :to="{name: 'messenger'}"
      :class="!isCurrentUserActive || isStudentRegistered ? 'disabled-btn' : ''"
      :disabled="!isCurrentUserActive || isStudentRegistered"
    >
      <span class="mt-1">{{ $t("route.messenger") }}</span>
      <v-badge
        class="new-badge"
        color="red"
        :content="totalUnseenMessages"
        :value="totalUnseenMessages > 0 && !isElter"
        offset-y="15"
        offset-x="15"
      >
        <v-img
          width="22"
          height="22"
          contain
          class="flex-grow-0"
          :src="require('@/assets/svg/messenger.svg')"
        />
      </v-badge>
    </v-btn>

    <v-btn
      :class="!hasPaymentAbility || !isCurrentUserActive ? 'disabled-btn' : ''"
      to="/balance"
      v-if="isStudent"
      :disabled="!hasPaymentAbility || !isCurrentUserActive"
    >
      <span class="mt-1">{{ $t("route.balance") }}</span>
      <v-img
        width="22"
        height="22"
        contain
        class="flex-grow-0"
        :src="require('@/assets/svg/balance-overview.svg')" />
    </v-btn>

    <v-btn
      v-if="isSchoolAdministrator || isTeacher"
      :class="!isCurrentUserActive ? 'disabled-btn' : ''"
      to="/students"
      :disabled="!isCurrentUserActive"
    >
      <span class="mt-1">{{ $t("route.students") }}</span>
      <v-img
        width="22"
        height="22"
        contain
        class="flex-grow-0"
        :src="require('@/assets/svg/students.svg')" />
    </v-btn>

    <v-btn
      v-if="isSchoolAdministrator"
      :class="!isCurrentUserActive ? 'disabled-btn' : ''"
      to="/teachers"
      :disabled="!isCurrentUserActive"
    >
      <span class="mt-1">{{ $t("route.teachers") }}</span>
      <v-img
        width="22"
        height="22"
        contain
        class="flex-grow-0"
        :src="require('@/assets/svg/teachers.svg')" />
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
import user from "@/utils/mixins/user";

export default {
  name: "BottomMenu",
  mixins: [user],
  computed: {
    isCurrentUserActive() {
      const isPrivacyPolicyAccepted = !this.isStudent || this.isStudent && this.currentUser.privacyPolicyAccepted;
      return isPrivacyPolicyAccepted && this.currentUser.active;
    },
    totalUnseenMessages() {
      return this.currentUser ? this.currentUser.unSeenMessagesCount : 0;
    },
    mdLandscapeAndUp() {
      return (
        (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.mdAndUp) &&
        window.innerHeight < window.innerWidth
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-menu {
  background-color: #00435e !important;
}
.v-item-group.v-bottom-navigation .v-btn {
  min-width: 75px;
  font-size: 0.65rem;
}
.disabled-btn {
  opacity: 0.5;
  pointer-events: none;
}
</style>
