<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between">
      {{ $t("label.your_next_appointments") }}
      <v-icon color="black" size="23">mdi-calendar</v-icon>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-progress-circular
        size="30"
        class="d-flex ma-auto"
        v-if="loading"
        indeterminate />

      <div v-else>
        <div v-if="events.length > 0">
          <EventAlert
            v-for="event in events"
            :key="event.id"
            :event="event"
            :color="eventBodyColorByType[event.eventType]"
            @open="
              $router.push({
                name: 'calendar.appointment',
                params: { id: event.id },
                query: { return_to: 'dashboard' },
              })
            " />
        </div>
        <empty-box v-else />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import EventAlert from "@/components/event/EventAlert";
import eventsUtils from "@/utils/mixins/eventsUtils";
import teacherService from "@/services/teacherService";

export default {
  name: "TeacherNextAppointments",
  props: {
    eventTypes: {
      type: Array,
      default: () => [],
    },
  },
  components: { EventAlert },
  mixins: [eventsUtils],
  data: () => ({
    loading: true,
    events: [],
  }),
  computed: {
    eventBodyColorByType() {
      const byType = {};
      this.eventTypes.forEach((eventType) => {
        if (!(eventType.id in byType))
          byType[eventType.id] = eventType.bodyColor;
      });
      return byType;
    },
  },
  mounted() {
    teacherService
      .nextEvents({ amount: 20 })
      .then((response) => {
        this.events = response.data || [];
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped></style>
