<template>
  <div :style="{width}">
    <v-progress-linear
      rounded
      :value="totalProgress"
      :height='mdLandscapeAndUp ? 25 : 18'
      color="secondary"
    >
      <span class="black--text font-weight-bold caption">
        {{ totalProgress }}%
      </span>
    </v-progress-linear>
  </div>
</template>

<script>
import responsive from "@/utils/mixins/responsive";

export default {
  name: "TotalProgress",
  mixins: [responsive],
  props: {
    width: {
      type: String,
      default: '30%',
    },
    totalProgress: {
      type: Number,
      required: true,
      default: 0,
    },
  },
};
</script>

<style scoped></style>
