<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="red" v-bind="attrs" v-on="on"> mdi-calendar-star </v-icon>
    </template>
    <span>{{ day.holidayName }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "HolidayMarker",
  props: {
    day: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
