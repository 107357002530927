<template>
  <div>
    <v-row>
      <v-col>
        <TeachersField
          :disabled="true"
          :validation="true"
          :filteredTeachers="teachers"
          :teacherProp="currentAppointment.teacherId"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <LicenseField
            @emitLicenseSelect="updateLicenseField"
            :disabled="currentAppointment.isRequested || studentHasNoPriceList || isElter"
            :validation="true"
            :multiple="false"
            :licenseProp="licenseId"
            :licenseIdsList="activeLicensesIds"
            :licenseStatus="practiceLicensesStatus"
        />
      </v-col>
      <v-col cols="6" v-if="teacherAllowsCreatePractice">
        <LessonTypesField
            @emitLessonTypeSelect="(val) => updateFields({ lessonTypeId: val })"
            :validation="true"
            :disabled="licenseIsNotSelected || isElter"
            :lessonTypeProp="currentAppointment.lessonTypeId"
            :lessonTypesList="lessonTypesList"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <MeetingPlaceField
          @emitMeetingPlaceSelect="(val) => updateFields({ meetingPlace: val })"
          :disabled="currentAppointment.isRequested || presetMeetingPlace || studentHasNoPriceList || isElter"
          :validation="false"
          :meetingPlacesList="placesGroups"
          :meetingPlaceProp="meetingPlace"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <NotesField
          :disabled="true"
          :validation="false"
          :notesProp="currentAppointment.notes"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import user from "@/utils/mixins/user";
import placeholderPracticeMixin from '@/utils/mixins/placeholderPracticeMixin';
import priceCategoryMixin from '@/utils/mixins/priceCategoryMixin'

import TeachersField from "@/components/calendar/appointmentModal/fields/TeachersField";
import LicenseField from "@/components/calendar/appointmentModal/fields/LicenseField";
import LessonTypesField from "@/components/calendar/appointmentModal/fields/LessonTypesField";
import MeetingPlaceField from "@/components/calendar/appointmentModal/fields/MeetingPlaceField";
import NotesField from "@/components/calendar/appointmentModal/fields/NotesField";

import AppointmentModel from "@/store/models/AppointmentModel";
import TeacherModel from "@/store/models/TeacherModel";
import StudentModel from '@/store/models/StudentModel'
import LessonTypeModel from '@/store/models/LessonTypeModel'

import SelectField from '@/helpers/selectField'
import LicenseModel from '@/store/models/LicenseModel'

export default {
  name: "StudentPlaceholderForm",
  mixins: [user, placeholderPracticeMixin, priceCategoryMixin],
  components: {
    TeachersField,
    LicenseField,
    LessonTypesField,
    MeetingPlaceField,
    NotesField,
  },
  data: () => ({
    initialDuration: 0,
  }),
  props: {
    teachers: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { currentAppointment: "currentAppointmentGetter", }),
    presetMeetingPlace() {
      return AppointmentModel.find(this.currentAppointment.id).meetingPlace !== null
    },
    student() {
      return StudentModel.find(this.currentUser.id) || {}
    },
    studentHasNoPriceList() {
      const teacher = TeacherModel.find(this.currentAppointment.teacherId)
      return this.student.bookAndPay &&
          !this.student.priceCategoryId &&
          teacher?.studentCanCreatePractice
    },
    baseLessonTypes() {
      return LessonTypeModel.query()
          .where("eventTypeId", "PRACTICE")
          .where('group', groupItem => ["normal", "special", "instructions"].includes(groupItem))
          .get() || [];
    },
    activeLicensesIds() {
      const { teacherId, allowedLicenses } = this.currentAppointment
      const teacher = TeacherModel.find(teacherId);
      const student = StudentModel.find(this.currentUser.id);
      const licenseList = allowedLicenses.length ? allowedLicenses : teacher.licensesIds;
      const practiceLicensesIds = LicenseModel.query().where('forPractice', true).get().map(license => license.id);

      return licenseList
          .filter((licenseId) => practiceLicensesIds.includes(licenseId) && student.licensesIds.includes(licenseId));
    },
    studentRequestData() {
      return this.currentAppointment.studentsIds.find(item => item.id === this.student.id) || {}
    },
    licenseId() {
      return this.currentAppointment.isRequested
          ? this.studentRequestData.licenseId
          : this.currentAppointment.licenseId;
    },
    meetingPlace() {
      return this.currentAppointment.isRequested
          ? this.studentRequestData.meetingPlace
          : this.currentAppointment.meetingPlace;
    },
    licenseIsNotSelected() {
      return !this.currentAppointment.licenseId;
    },
    teacherAllowsCreatePractice() {
      const teacher = TeacherModel.find(this.currentAppointment.teacherId) || {};
      return teacher?.studentCanCreatePractice;
    },
  },
  methods: {
    updateLicenseField(val) {
      this.updateFields({ licenseId: val })
      const lessonTypeVal = new SelectField(this.currentAppointment).lessonTypeSelector(this.lessonTypesList, false)
      this.$nextTick(() => {
        this.updateLessonTypeField(lessonTypeVal)
      })
    },
    updateLessonTypeField(val) {
      this.updateFields({ lessonTypeId: val })
    },
  },
};
</script>
