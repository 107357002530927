<template>
  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  name: "Lines",
};
</script>

<style lang="scss" scoped>
.lines {
  position: absolute;
  display: grid;
  width: 100vw;
  height: 100%;
  top: 0;
  left: var(--calendar-hours-column);
  & > .line {
    pointer-events: none;
    width: 100%;
    z-index: 4;
    height: 0;
    border-bottom: 1px dashed #ccc;
    &:first-child {
      border: none;
    }
  }
}
</style>
