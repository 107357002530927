<template>
  <div>
    <v-row>
      <v-col md="3" sm="9">
        <v-switch
          v-model="active"
          :disabled="disabled"
          :label="$t('form.copy')"
          inset
          dense
          hide-details />
      </v-col>
      <v-col offset-md="5" md="4" sm="3">
        <v-select
          class="mt-0"
          v-model="copyForNrOfWeeks"
          :items="weeksItems"
          :disabled="!active"
          :label="$t('form.week_numbers')"
          outlined
          dense
          hide-details
          :menu-props="{ offsetY: true }" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "CopyAppointment",
  data: () => ({
    active: false,
  }),
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    weeksAmount: {
      type: [Number, String],
      required: true,
    },
    copyNrProp: {
      type: Number,
    },
  },
  watch: {
    active(val) {
      this.$emit("emitSetCopiedAmount", val ?  1 : null);
    },
    "currentAppointment.id"() {
      this.active = false
    }
  },
  computed: {
    ...mapGetters("appointments", { currentAppointment: "currentAppointmentGetter" }),
    copyForNrOfWeeks: {
      set(value) {
        this.$emit("emitSetCopiedAmount", value);
      },
      get() {
        return this.copyNrProp;
      },
    },
    weeksItems() {
      const arr = [];
      for (let i = 1; i <= parseInt(this.weeksAmount); i++) arr.push(i);
      return arr;
    },
  },
};
</script>
