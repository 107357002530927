<template>
<v-card :loading="loading" :disabled="disabled">
  <v-img height="100px" :src="image" eager />
  <v-card-title>{{ $t("route.vehicleExpenses") }}</v-card-title>

  <v-card-subtitle class="student-card">
    {{ $t('label.vehicle_card_expenses_description') }}
  </v-card-subtitle>

  <v-card-text v-if="!disabled">
    <v-skeleton-loader v-if="loading" type="list-item-two-line" />
    <v-list v-else>
        <v-list-item class="pa-0 mb-2">
            <v-list-item-content class="pa-0">
                <v-list-item-title>{{ $t('label.this_month_total_expenses') }}</v-list-item-title>
                <v-list-item-subtitle>{{ thisMonthTotalExpenses | currency }}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-list>
  </v-card-text>

  <v-card-actions>
    <v-btn
      small
      text
      class="ml-auto"
      :to="{ name: `vehicles.vehicle.expenses` }"
    >
        {{ $t("btn.open") }}
    </v-btn>
  </v-card-actions>
</v-card>
</template>

<script>
import cardsService from "@/services/cardsService";
import { EventBus } from '@/EventBus'

export default {
  name: "VehicleExpensesCard",
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      image: require("@/assets/img/expenses.jpg"),
      thisMonthTotalExpenses: 0
    };
  },
  created() {
    EventBus.$on("expense-created", this.init);
    EventBus.$on("expense-updated", this.init);
    EventBus.$on('expense-deleted', this.init);
  },
  destroyed() {
    EventBus.$off('expense-created', this.init);
    EventBus.$off('expense-updated', this.init);
    EventBus.$off('expense-deleted', this.init);
  },
  mounted() {
    if (!this.disabled) this.init();
  },
  methods: {
    init() {
      this.loading = true;
      cardsService.vehicleExpenses(this.$route.params.id)
        .then(response => {
          this.thisMonthTotalExpenses = response.data?.thisMonthTotalExpenses || 0;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>

</style>
