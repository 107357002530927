import i18n from "@/plugins/i18n";
import AppointmentNotification from '@/notifications/events/appointmentNotification'

export default class AppointmentDeletedNotification extends AppointmentNotification {
  icon() {
    return "mdi-calendar-remove";
  }

  wildCardsData() {
    const data = this.notification.data;
    const eventType = i18n.t(`event_type.${data.type}`);

    return {
      title: {
        eventType: eventType,
      },
      message: {
        eventType: eventType,
      },
    };
  }
}
