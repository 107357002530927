import { parseISO, addMinutes, format, differenceInMinutes } from "date-fns";

export default class BaseEvent {
  constructor(eventData) {
    Object.assign(this, eventData);

    this.startDate = parseISO(`${this.date} ${this.startTime}`);
    this.endDate = addMinutes(this.startDate, this.duration);
    this.endTime = format(this.endDate, "HH:mm");
    this.render = {
      top: this.getTopOffset(),
      height: this.duration * 2,
    };
  }

  getTopOffset() {
    const startOfTheDay = parseISO(`${this.date} 05:00`);
    return differenceInMinutes(this.startDate, startOfTheDay) * 2;
  }
}
