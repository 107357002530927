<template>
  <v-container class="d-flex" :fluid="$vuetify.breakpoint.lgAndDown">
    <v-card
      class="menu align-self-start"
      :class="{ 'mobile-menu': !mdLandscapeAndUp, 'mr-3': mdLandscapeAndUp }"
      v-if="showMobileMenu || mdLandscapeAndUp"
      min-width="250">
      <v-list dense>
        <v-list-item-group color="primary">
          <v-list-item
            v-if="isSchoolAdministrator && store.state.school.isDemoModeEnabled"
            :ripple="false"
            :to="{ name: 'school-settings.demo-mode' }">
            <v-list-item-icon class="ml-1">
              <v-icon>mdi-timer-sand</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                  $t("route.schoolSettings.children.demoMode")
                }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="isSchoolAdministrator"
            :ripple="false"
            :to="{ name: 'school-settings.general' }">
            <v-list-item-icon class="ml-1">
              <v-icon>mdi-information</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("route.schoolSettings.children.general")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="isSchoolAdministrator"
            :ripple="false"
            :to="{ name: 'school-settings.limits' }">
            <v-list-item-icon class="ml-1">
              <v-icon>mdi-lock-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("route.schoolSettings.children.limits")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="isSchoolAdministrator"
            :ripple="false"
            :to="{ name: 'school-settings.holidays' }">
            <v-list-item-icon class="ml-1">
              <v-icon>mdi-calendar-star</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("route.schoolSettings.children.holidays")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!--          <v-list-item v-if="isSchoolAdministrator" :ripple="false" :to="{ name: 'school-settings.licensePrice' }">-->
          <!--            <v-list-item-icon class="ml-1">-->
          <!--              <v-icon>mdi-currency-eur</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>{{ $t('route.schoolSettings.children.licensePrice') }}</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->

          <v-list-item
            v-if="isSchoolAdministrator"
            :ripple="false"
            :to="{ name: 'school-settings.event-styles' }">
            <v-list-item-icon class="ml-1">
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("route.schoolSettings.children.eventStyles")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="isSchoolAdministrator"
            :ripple="false"
            :to="{ name: 'school-settings.notifications' }">
            <v-list-item-icon class="ml-1">
              <v-icon>mdi-bell</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("route.schoolSettings.children.notifications")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="isSchoolAdministrator"
            :ripple="false"
            :to="{ name: 'school-settings.custom-messages' }">
            <v-list-item-icon class="ml-1">
              <v-icon>mdi-message-text-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                  $t("route.schoolSettings.children.customMessages")
                }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="isSchoolAdministrator && school.isWithStripePayment"
            :ripple="false"
            :to="{ name: 'school-settings.payments' }">
            <v-list-item-icon class="ml-1">
              <v-icon>mdi-credit-card</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("route.schoolSettings.children.payments")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="isSchoolAdministrator && school.isWithStripePayment"
            :ripple="false"
            :to="{ name: 'school-settings.payment-bonus' }">
            <v-list-item-icon class="ml-1">
              <v-icon>mdi-wallet-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("route.schoolSettings.children.paymentBonus")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="isSchoolAdministrator"
            :ripple="false"
            :to="{ name: 'school-settings.invoices' }">
            <v-list-item-icon class="ml-1">
              <v-icon>mdi-file-clock-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{$t("route.schoolSettings.children.invoices")}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
              v-if="isSchoolAdministrator"
              :ripple="false"
              :to="{ name: 'school-settings.cars-park' }">
            <v-list-item-icon class="ml-1">
              <v-icon>mdi-car-cog</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{$t("route.schoolSettings.children.carsPark")}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="isSchoolAdministrator && school.hasClickAndLearn"
            :ripple="false"
            :to="{ name: 'school-settings.clicklearn' }"
          >
            <v-list-item-icon class="ml-1">
                <v-icon>$clickAndLearn</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>{{$t("route.schoolSettings.children.clicklearn")}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="isSchoolAdministrator"
            :ripple="false"
            :to="{ name: 'school-settings.digital-attendance' }">
            <v-list-item-icon class="ml-1">
              <v-icon>mdi-qrcode-scan</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("route.schoolSettings.children.digitalAttendance")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>

    <v-card
      v-if="(!mdLandscapeAndUp && !showMobileMenu) || mdLandscapeAndUp"
      class="flex-grow-1 align-self-start"
      color="white"
      elevation="1"
      rounded
    >
      <v-fade-transition mode="out-in" :hide-on-leave="$vuetify.breakpoint.mobile">
        <router-view />
      </v-fade-transition>
    </v-card>
  </v-container>
</template>

<script>
import user from "@/utils/mixins/user";
import responsive from "@/utils/mixins/responsive";
import { mapGetters } from "vuex";
import store from '@/store'

export default {
  name: "Index",
  mixins: [user, responsive],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.name === "school-settings" && this.mdLandscapeAndUp) {
        vm.redirectToIndexPage(from)
      }
    });
  },
  beforeRouteUpdate (to, from, next) {
    if (to.name === "school-settings" && this.mdLandscapeAndUp) {
      this.redirectToIndexPage(from)
    }
    next()
  },
  created() {
    window.addEventListener("orientationchange", () => this.redirectToIndexPage(this.$route));
  },
  beforeDestroy () {
    window.removeEventListener("orientationchange", () => this.redirectToIndexPage(this.$route));
  },
  computed: {
    store () {
      return store
    },
    ...mapGetters("school", ["school"]),
    showMobileMenu() {
      return this.$route.name === "school-settings";
    },
  },
  methods: {
    redirectToIndexPage(from) {
      if (this.$route.name !== 'school-settings') return

      if (from.name !== "school-settings.general") {
        this.$router.push({ name: "school-settings.general" });
      }
      if (from.name !== "school-settings.teacher-preferences" && this.isTeacher) {
        this.$router.push({ name: "school-settings.teacher-preferences" });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.menu {
  width: 250px;
}

.mobile-menu {
  width: 100% !important;
}
</style>
