<template>
  <v-dialog
    v-model="show"
    :fullscreen="$vuetify.breakpoint.mobile"
    persistent
    scrollable
    width="500"
  >
    <v-card>
      <v-toolbar class="flex-grow-0 mb-4" color="primary" dark dense>
        <v-toolbar-title>
          {{
            $t("label.create_or_edit_service_appointment", { createOrEdit: serviceId ? $t("label.edit") : $t("label.create") })
          }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn dark icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text v-if="loading">
        <div class="d-flex justify-center pt-5">
          <v-progress-circular color="primary" indeterminate />
        </div>
      </v-card-text>

      <v-card-text v-else>
        <VehicleAlert v-if="showDetailsAlert && vehicle" :vehicle="vehicle" />

        <ValidationObserver ref="form">
          <v-row class="mt-2" dense>
            <v-col cols="8">
              <DatePickerField
                name="dateFrom"
                :key="form.dateTo"
                :label="$t('label.start_date')"
                :placeholder="$t('label.start_date')"
                :value="form.dateFrom"
                @clear="clearDateFrom"
                @save="onInputDateFrom"
              />
            </v-col>
            <v-col cols="4">
              <TimePickerField
                name="timeFrom"
                :disabled="!form.dateFrom"
                :allowedMinutes="m => m % 15 === 0"
                :label="$t('label.start_time')"
                :placeholder="$t('label.start_time')"
                :value="form.timeFrom"
                @save="onInputTimeFrom"
                @clear="form.timeFrom = null"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="8">
              <DatePickerField
                name="dateTo"
                :key="form.dateFrom"
                :label="$t('label.end_date')"
                :min="form.dateFrom"
                :placeholder="$t('label.end_date')"
                :value="form.dateTo"
                @clear="clearDateTo"
                @save="onInputDateTo"
              />
            </v-col>
            <v-col cols="4">
              <TimePickerField
                name="timeTo"
                :disabled="!form.dateTo"
                :min="minTimeTo"
                :allowedMinutes="m => m % 15 === 0"
                :label="$t('label.end_time')"
                :placeholder="$t('label.end_date')"
                :value="form.timeTo"
                @save="onInputTimeTo"
                @clear="form.timeTo = null"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <ValidationProvider v-slot="{ errors }" name="notes">
                <v-textarea
                  v-model="form.notes"
                  dense
                  outlined
                  :error-messages="errors"
                  :label="$t('label.notes')"
                  :placeholder="$t('label.notes')"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn text @click="close">{{ $t("btn.close") }}</v-btn>
        <v-spacer />
        <v-btn
          :disabled="savingDisabled"
          :loading="saving"
          color="primary"
          @click="save"
        >
          <v-icon left>{{ `mdi-content-save${serviceId ? '-edit' : ''}` }}</v-icon>
          {{ $t("btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <VehicleServiceAppointmentOverlapDialog ref="vehicleServiceAppointmentOverlapDialog" @confirm="onUnassignFromAppointmentsConfirm" />
  </v-dialog>
</template>

<script>
import { addMinutes, format, isAfter, parseISO } from "date-fns";
import vehiclesService from "@/services/vehiclesService";
import DatePickerField from "@/components/form/DatePickerField.vue";
import TimePickerField from "@/components/form/TimePickerField.vue";
import { EventBus } from "@/EventBus";
import VehicleAlert from "@/components/vehicle/VehicleAlert.vue";
import VehicleModel from "@/store/models/VehicleModel";
import VehicleServiceAppointmentOverlapDialog from '@/components/vehicle/VehicleServiceAppointmentOverlapDialog.vue'

export default {
  name: "VehicleServiceDialog",
  props: {
    showDetailsAlert: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VehicleServiceAppointmentOverlapDialog,
    VehicleAlert,
    TimePickerField,
    DatePickerField
  },
  data() {
    return {
      show: false,
      loading: false,
      saving: false,
      menuDateFrom: false,
      menuTimeFrom: false,
      menuDateTo: false,
      menuTimeTo: false,
      vehicleId: null,
      serviceId: null,
      service: null,
      form: {
        dateFrom: null,
        timeFrom: null,
        dateTo: null,
        timeTo: null,
        notes: "",
        unassignVehicleFromAppointmentsIds: []
      }
    };
  },
  computed: {
    vehicle() {
      return VehicleModel.find(this.vehicleId);
    },
    savingDisabled() {
      return this.saving || this.loading;
    },
    minTimeTo() {
      if (!this.form.timeFrom || this.form.dateFrom !== this.form.dateTo) return undefined
      return format(addMinutes(parseISO(this.form.dateFrom + ' ' + this.form.timeFrom), 15), "HH:mm");
    },
    types() {
      const types = {
        MAINTENANCE_AND_SERVICING: 'MAINTENANCE_AND_SERVICING',
        INSURANCE_PREMIUMS: 'INSURANCE_PREMIUMS',
        REGISTRATION_FEES: 'REGISTRATION_FEES',
        ANNUAL_TAX: 'ANNUAL_TAX',
        INSPECTION_FEES: 'INSPECTION_FEES',
        WIPER_REPLACEMENT: 'WIPER_REPLACEMENT',
        CAR_WASH_DETAILING: 'CAR_WASH_DETAILING',
        PARKING_FEES_TOLLS: 'PARKING_FEES_TOLLS',
        DEPRECIATION_AMORTIZATION: 'DEPRECIATION_AMORTIZATION',
        LEASING_FINANCING: 'LEASING_FINANCING',
        EMERGENCY_ROADSIDE_ASSIST: 'EMERGENCY_ROADSIDE_ASSIST',
        UPGRADES_MODIFICATIONS: 'UPGRADES_MODIFICATIONS',
        SPARE_PARTS_STORAGE: 'SPARE_PARTS_STORAGE',
        OTHER: 'OTHER'
      }

      return Object.keys(types).map(key => {
        return {
          value: types[key],
          text: this.$t('vehicleExpenseType.' + key)
        }
      })
    }
  },
  methods: {
    format,
    open(vehicleId, serviceId) {
      this.show = true;
      this.vehicleId = vehicleId;
      this.serviceId = serviceId;

      if (serviceId) {
        this.loading = true;

        vehiclesService.getService(vehicleId, serviceId)
          .then((response) => {
            const responseData = response.data || {};
            const from = new Date(responseData.from);
            const to = new Date(responseData.to);

            this.form = {
              dateFrom: format(from, "yyyy-MM-dd"),
              timeFrom: format(from, "HH:mm"),
              dateTo: format(to, "yyyy-MM-dd"),
              timeTo: format(to, "HH:mm"),
              notes: responseData.notes
            };
          }).finally(() => {
          this.loading = false;
        });
      }
    },
    close() {
      this.$refs.form.reset();

      this.show = false;

      setTimeout(() => {
        this.form = {
          dateFrom: null,
          timeFrom: null,
          dateTo: null,
          timeTo: null,
          notes: "",
          unassignVehicleFromAppointmentsIds: []
        };
      }, 200)
    },
    onInputDateFrom(value) {
      this.form.dateFrom = value;

      if (!this.form.dateFrom || !this.form.dateTo) return;

      const from = parseISO(this.form.dateFrom);
      const to = parseISO(this.form.dateTo);

      if (isAfter(from, to)) {
        this.form.dateTo = this.form.dateFrom;
      }
    },
    onInputTimeFrom(value) {
      this.form.timeFrom = value;
      this.setTimeToToMinimumValue();
    },
    onInputDateTo(value) {
      this.form.dateTo = value;
      this.setTimeToToMinimumValue();
    },
    onInputTimeTo(value) {
      this.form.timeTo = value;
    },
    clearDateFrom() {
      this.form.dateFrom = null;
      this.form.timeFrom = null;
    },
    clearDateTo() {
      this.form.dateTo = null;
      this.form.timeTo = null;
    },
    setTimeToToMinimumValue() {
      const dateFrom = this.form.dateFrom ?? format(new Date(), "yyyy-MM-dd");
      const dateTo = this.form.dateTo ?? format(new Date(), "yyyy-MM-dd");

      if (!this.form.timeFrom || !this.form.timeTo) return;

      const from = parseISO(dateFrom + " " + this.form.timeFrom);
      const to = parseISO(dateTo + " " + this.form.timeTo);

      if (isAfter(from, to)) {
        this.form.timeTo = format(addMinutes(from, 15), "HH:mm");
      }
    },
    save() {
      this.saving = true
      this[this.serviceId ? 'update' : 'store'](this.form)
        .catch((error) => {
          this.$refs.form.reset();

          const responseData = error.response.data || {};
          const errors = responseData.errors || [];
          const responseParams = responseData.params || {};

          if (responseParams.overlapIds) {
            this.$refs.vehicleServiceAppointmentOverlapDialog.open(responseParams.overlapIds)
            return
          }

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({
              message: responseData.message,
              color: "error"
            });
          }

          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    store(formData) {
      return vehiclesService.storeService(this.vehicleId, formData)
        .then(response => {
          this.$emit("created", response.data);
          EventBus.$emit("service-appointment-created", response.data)
          this.close();
        })
    },
    update(formData) {
      return vehiclesService.updateService(this.vehicleId, this.serviceId, formData)
        .then(response => {
          this.$emit("updated", response.data);
          EventBus.$emit("service-appointment-updated", response.data);
          this.close();
        })
    },
    onUnassignFromAppointmentsConfirm(data) {
      this.form.unassignVehicleFromAppointmentsIds = data.appointmentsIds || []
      this.save()
    }
  }
};
</script>

<style scoped>

</style>
