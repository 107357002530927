import api from "@/utils/api";

export default {
  listTemplates: () => {
    return new Promise((resolve, reject) => {
      api
        .get('/insights/templates')
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  getTemplate: (templateId, params)=> {
    return new Promise((resolve, reject) => {
      api
        .get(`/insights/templates/${templateId}`, {params})
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  checkAppointmentEvaluation: (appointmentId)=> {
    return new Promise((resolve, reject) => {
      api
        .get(`/insights/appointment/${appointmentId}/evaluation/check`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  getAppointmentEvaluation: (appointmentId)=> {
    return new Promise((resolve, reject) => {
      api
        .get(`/insights/appointment/${appointmentId}/evaluation`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  deleteAppointmentEvaluation: (appointmentId)=> {
    return new Promise((resolve, reject) => {
      api
        .delete(`/insights/appointment/${appointmentId}/evaluation`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  addItemToAppointmentEvaluation: (appointmentId, payload)=> {
    return new Promise((resolve, reject) => {
      api
        .post(`/insights/appointment/${appointmentId}/evaluation/items`, payload)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  editItemInAppointmentEvaluation: (appointmentId, itemId, payload)=> {
    return new Promise((resolve, reject) => {
      api
        .put(`/insights/appointment/${appointmentId}/evaluation/items/${itemId}`, payload)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  removeItemFromAppointmentEvaluation: (appointmentId, itemId)=> {
    return new Promise((resolve, reject) => {
      api
        .delete(`/insights/appointment/${appointmentId}/evaluation/items/${itemId}`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  publishAppointmentEvaluation: (appointmentId, payload)=> {
    return new Promise((resolve, reject) => {
      api
        .post(`/insights/appointment/${appointmentId}/evaluation/publication`, payload)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  withdrawAppointmentEvaluation: (appointmentId)=> {
    return new Promise((resolve, reject) => {
      api
        .delete(`/insights/appointment/${appointmentId}/evaluation/publication`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  getStudentLicensesProgress: (studentId) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/insights/progress/${studentId}/licenses`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  getStudentLicenseProgress: (studentId, licenseId)=> {
    return new Promise((resolve, reject) => {
      api
        .get(`/insights/progress/${studentId}/license/${licenseId}`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  getCompletedItems: (studentId, licenseId) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/insights/progress/${studentId}/license/${licenseId}/completed-items`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  assignTemplateToStudentLicense(student, license, params={}) {
    return new Promise((resolve, reject) => {
      api
        .post(`/insights/assignment/${student}/license/${license}`, params)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  revokeTemplateForStudentLicense(student, license) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/insights/assignment/${student}/license/${license}`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  downloadLicenseTemplatePDF(student, license, params = {}) {
    return new Promise((resolve, reject) => {
      api
        .get(`/insights/template-pdf/student/${student}/license/${license}`,
          { params, responseType: "blob", },
        )
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  }
}
