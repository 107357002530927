<template>
  <v-list-item @click.left="$emit('itemClick')">
    <v-list-item-title>
      <v-icon left :color="type.bodyColor">{{ `mdi-calendar-plus` }}</v-icon>
      <span :style="`color: ${type.bodyColor}`">{{
        $t(`event_type.${type.id}`)
      }}</span>
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: "CreationMenuItem",
  props: {
    type: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss"></style>
