<template>
  <div>
    <v-btn-toggle
      dense
      tile
      mandatory
      :value="calendarLayout"
      @change="changeLayout">
      <v-btn
        small
        v-for="(layout, index) in layouts"
        :value="layout.id"
        :key="index"
        outlined
        :ripple="false"
        >{{ $t(layout.label) }}</v-btn
      >
    </v-btn-toggle>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import user from '@/utils/mixins/user'
import loadAppointments from '@/utils/mixins/loadAppointments'

export default {
  name: "CalendarLayoutControls",
  mixins: [user, loadAppointments],
  data() {
    return {
      layouts: [
        { id: "LAYOUT_DAY", label: "calendar.day" },
        { id: "LAYOUT_THREE_DAYS", label: "calendar.days_3" },
        { id: "LAYOUT_WEEK", label: "calendar.week" },
        // { id: 'LAYOUT_MONTH', label: 'calendar.month' }
      ],
    };
  },
  computed: {
    ...mapGetters(["calendarLayout"]),
    ...mapGetters("teachers", { activeTeachersIds: "activeIds" }),
  },
  methods: {
    ...mapActions(["updateCalendarLayout"]),
    ...mapActions("teachers", { setTeachers: "set" }),
    changeLayout(layout) {
      this.updateCalendarLayout(layout);
      let teachers = this.activeTeachersIds
      if (this.calendarLayout === "LAYOUT_THREE_DAYS") teachers.slice(0, 10);
      if (this.calendarLayout === "LAYOUT_WEEK") teachers.slice(0, 5);
      this.loadAppointments(true)
      this.setTeachers(teachers);
    },
  },
};
</script>
