<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'personal-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>
        {{ $t("route.personalSettings.children.profile") }}
      </v-card-title>

      <v-spacer/>

      <v-btn
          text
          color="primary"
          @click="save"
          :loading="formLoading"
          :disabled="formLoading">
        {{ $t("btn.save") }}
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <ValidationObserver ref="form">
        <v-row class="mt-3">
          <v-col cols="12" lg="9">
            <ValidationProvider v-slot="{ errors }" name="firstName">
              <v-text-field
                dense
                v-model="form.firstName"
                :label="$t('form.first_name')"
                :placeholder="$t('form.first_name')"
                outlined
                :error-messages="errors"
                required
              />
            </ValidationProvider>
            <ValidationProvider dense v-slot="{ errors }" name="lastName">
              <v-text-field
                dense
                v-model="form.lastName"
                :label="$t('form.last_name')"
                :placeholder="$t('form.last_name')"
                outlined
                :error-messages="errors" />
            </ValidationProvider>

            <EmailVerificationNotification
              :email="currentUser.email"
              v-if="!currentUser.emailVerifiedAt" />
            <ValidationProvider v-slot="{ errors }" name="email">
              <v-text-field
                dense
                v-model="form.email"
                :label="$t('form.email')"
                :placeholder="$t('form.email')"
                outlined
                :error-messages="errors"
                required
                :disabled="!!currentUser.email">
                <template v-slot:append>
                  <v-tooltip top v-if="!currentUser.emailVerifiedAt">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="warning" v-bind="attrs" v-on="on"
                      >mdi-information-outline</v-icon
                      >
                    </template>
                    <span>{{ $t("label.email_not_verified") }}</span>
                  </v-tooltip>
                  <v-tooltip top v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="success" v-bind="attrs" v-on="on"
                      >mdi-check</v-icon
                      >
                    </template>
                    <span>{{ $t("label.email_verified") }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" lg="3">
            <UploadBtn
              class="mb-2"
              @file-picked="onFilePick"
              accept="image/jpeg,image/png"
              input-name="avatar"
            >
              <UserAvatar class="elevation-3" size="150" :path="avatarImage" :alt="currentUser.fullName" />
            </UploadBtn>
          </v-col>
        </v-row>
      </ValidationObserver>
    </v-card-text>
  </div>
</template>

<script>
import UploadBtn from "@/components/UploadBtn.vue";
import { mapActions, mapState } from "vuex";
import userService from "@/services/userService";
import localizationService from "@/services/localizationService";
import responsive from "@/utils/mixins/responsive";
import EmailVerificationNotification from "@/components/EmailVerificationNotification.vue";
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  name: "StudentSettingsGeneral",
  mixins: [responsive],
  components: {
    UserAvatar,
    EmailVerificationNotification,
    UploadBtn,
  },
  data: () => ({
    activeTab: null,
    form: {
      avatar: null,
      firstName: "",
      lastName: "",
      email: "",
    },
    formLoading: false,
    avatarImage: "",
    languages: [],
  }),
  computed: {
    ...mapState("auth", { currentUser: "authUser" }),
    environmentType() {
      return process.env.NODE_ENV;
    },
  },
  mounted() {
    localizationService
      .locales()
      .then((resp) => {
        const languages = resp.data || {};

        this.languages = Object.keys(languages).map((id) => {
          return { value: id, text: languages[id] };
        });
      })
      .catch((error) => console.log(error));

    this.form.firstName = this.currentUser.firstName;
    this.form.lastName = this.currentUser.lastName;
    this.form.email = this.currentUser.email;

    this.avatarImage = this.currentUser.avatar;
  },
  methods: {
    ...mapActions("auth", ["getAuthUser"]),
    onFilePick(url, file) {
      this.avatarImage = url;
      this.form.avatar = file;
    },
    save() {
      const formData = new FormData();

      Object.keys(this.form).forEach((field) => {
        const value = this.form[field];

        if (!value) return;

        if (Array.isArray(value)) {
          value.map((item) => formData.append(field + "[]", item));
        } else {
          formData.set(field, value);
        }
      });

      formData.set("_method", "PUT");

      this.formLoading = true;
      userService
        .updateGeneralInfo(formData)
        .then(() => {
          this.getAuthUser();
          this.$snackbar.show({
            message: this.$t("messages.successfully_sent"),
            color: "success",
            timeout: 2000,
          });
        })
        .catch((error) => {
          if (!error.response) console.log(error);

          const responseData = error.response.data;
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message)
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });

          Object.keys(errors).forEach((fieldName) =>
            this.$refs.form.setErrors({ [fieldName]: errors[fieldName] })
          );
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
