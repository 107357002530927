<script>
export default {
  name: "TemplateSkeletonLoader"
}
</script>

<template>
  <div>
    <div class="loader-item d-flex flex-row align-center" v-for="item in [1,2,3,4]" :key="item">
      <v-skeleton-loader type="image" width="36" height="36" />
      <v-skeleton-loader type="text@1" class="mx-4 flex-grow-1 flex-shrink-1 my-0" width="250" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.loader-item {
  border: solid 1px #eee;
  border-bottom: none;
  height: 50px;
  padding: 5px;
  &:last-child {
    border-bottom: solid 1px #eee;
  }
}
</style>