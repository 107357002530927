<template>
  <div>
    <v-row>
      <LessonTypesField
        :cols="!mdLandscapeAndUp ? 12 : 6"
        :model="model"
        :disabled="!!model.id || isPast"
        eventType="THEORY" />

      <LicenseField
        @theoryStudentChange="studentChange"
        v-if="isSchoolAdministrator"
        :activeLicenses="schoolLicenses"
        :disabled="isPast"
        :cols="!mdLandscapeAndUp ? 12 : 6" />
    </v-row>

    <TimeFields
      :disabled="isPast"
      :model="model"
      :eventDuration="eventDuration" />

    <TextField :disabled="!model.licenseId || isPast" :model="model" />

    <template v-if="isSchoolAdministrator">
      <v-row>
        <AllStudentsField
          :cols="12"
          :disabled="!model.licenseId || isPast"
          :model="model"
          :students="students"
          validation
          clearable
          multiple
          :menu-props="{ top: true }" />
      </v-row>

      <v-row v-if="model.studentsIds && assignedStudents.length">
        <TheorySubscribersField
          :students="assignedStudents"
          :disabled="false"
          :readOnly="false"
          :model="model"
          temporary />
      </v-row>
      <v-row v-if="subscribedStudents.length">
        <TheorySubscribersField
          :students="subscribedStudents"
          :disabled="isPast"
          :readOnly="false"
          :model="model"
          :temporary="false" />
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import user from "@/utils/mixins/user";
import responsive from "@/utils/mixins/responsive";

import TheorySubscribersField from "@/components/calendar/eventModal/fields/TheorySubscribersField";
import LessonTypesField from "@/components/calendar/eventModal/fields/LessonTypesField";
import AllStudentsField from "@/components/calendar/eventModal/fields/AllStudentsField";
import TimeFields from "@/components/calendar/eventModal/fields/TimeFields";
import LicenseField from "@/components/calendar/eventModal/fields/LicenseField";
import TextField from "@/components/calendar/eventModal/fields/TextField";

import LicenseModel from "@/store/models/LicenseModel";
import StudentModel from "@/store/models/StudentModel";
import EventModel from "@/store/models/EventModel";

export default {
  name: "TheoryExamForm",
  mixins: [user, responsive],
  components: {
    TheorySubscribersField,
    LicenseField,
    LessonTypesField,
    AllStudentsField,
    TimeFields,
    TextField,
  },
  props: {
    eventDuration: {
      type: Number,
      required: true,
    },
    filteredTeachers: {
      type: Array,
      required: true,
    },
    isPast: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    schoolLicenses() {
      return LicenseModel.all() || [];
    },
    students() {
      const schoolStudents = StudentModel.all() || [];
      const studentsByLicense = [];
      const modelLicensesIds = [
        this.model.licenseId,
        ...this.model.licensesIds,
      ];

      schoolStudents.map((student) => {
        if (
          student.licensesIds.some((licenseId) =>
            modelLicensesIds.includes(licenseId)
          )
        )
          studentsByLicense.push(student);
      });

      const students = studentsByLicense.filter(
        (student) => !this.model.studentsIds.includes(student.id)
      );
      return students.filter(
        (student) =>
          !student.licensesIdsWithTheoryExam.some((licenseId) =>
            modelLicensesIds.includes(licenseId)
          )
      );
    },
    subscribedStudents() {
      let requests = [];
      const event = EventModel.find(this.model.id) || {};
      if (event.id && this.model.eventType === "THEORY") {
        requests = StudentModel.query()
          .where((student) => !student.isDeleted)
          .whereIdIn(event.studentsIds)
          .get();
      }
      return requests;
    },
    assignedStudents() {
      if (this.model.id && this.subscribedStudents.length) {
        const staticStudentsIds = this.subscribedStudents.map(
          (student) => student.id
        );
        const selectStudents = this.model.studentsIds.map(
          (studentId) => StudentModel.find(studentId).id
        );
        const diff = selectStudents.filter(
          (item) => !staticStudentsIds.includes(item)
        );
        return StudentModel.query()
          .where((student) => !student.isDeleted)
          .whereIdIn(diff)
          .get();
      } else {
        return StudentModel.query()
          .where((student) => !student.isDeleted)
          .whereIdIn(this.model.studentsIds)
          .get();
      }
    },
  },
  methods: {
    ...mapActions("appointments", ["finishEventCreation"]),
    studentChange() {
      this.model.studentsIds = [];
    },
  },
};
</script>

<style lang="scss">
.license-select-ellipsis {
  input[type="text"] {
    display: none !important;
  }
}
</style>
