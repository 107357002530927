var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ShowsAs',{ref:"showAs",attrs:{"type":_vm.$vuetify.breakpoint.mobile ? 'dialog' : 'menu',"options":{
    offsetY: true,
    right: true,
    zIndex: 10,
    nudgeWidth: 850,
    nudgeLeft: 40,
    nudgeBottom: 10,
    maxWidth: 850
  }},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-badge',{attrs:{"color":"primary","overlap":"","content":_vm.appliedFilters.length,"value":_vm.appliedFilters.length,"offset-x":"15","offset-y":"15"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.appliedFilters.length > 0 ? "mdi-filter-plus-outline" : "mdi-filter-outline"))])],1)],1)]}}])},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("label.filters")))]),_c('v-card-text',[_vm._t("default")],2),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.clear}},[_vm._v(_vm._s(_vm.$t("btn.clear")))]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.apply}},[_vm._v(_vm._s(_vm.$t("btn.ok")))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }