<template>
  <v-list>
    <v-list-item two-line dense v-for="index in array" :key="index">
      <v-list-item-content>
        <v-list-item-title>
          <v-skeleton-loader type="text" width="100" />
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-skeleton-loader type="text" width="70" />
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action class="mx-0 my-0">
        <v-skeleton-loader type="button" width="60" height="32" />
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "TeachersWorkTimeLoader",
  props: {
    count: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    array() {
      return [...Array(this.count)];
    },
  },
};
</script>
