<template>
  <v-img :src="flag" :alt="locale" aspect-ratio="1.5" max-width="23" />
</template>

<script>
import supportedLocales from "@/locales/supportedLocales";

export default {
  name: "FlagIcon",
  props: {
    locale: {
      type: String,
      required: true
    }
  },
  computed: {
    flag() {
      return supportedLocales.map(item => item.code).includes(this.locale) ?
          require(`@/assets/flags/${this.locale}.svg`) :
          '';
    }
  }
}
</script>

<style scoped>

</style>