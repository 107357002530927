<script>
import {defineComponent} from 'vue'
import schoolBalanceLabel from "@/utils/mixins/schoolBalanceLabel";

export default defineComponent({
  name: "SchoolCreditsBalanceWidget",
  mixins: [schoolBalanceLabel],
  data() {
    return {
      loading: false,
    }
  }
})
</script>

<template>
  <v-card
    :loading="false"
    dark
    :color="color"
  >
    <v-card-subtitle class="my-0 pb-0">{{ $t('label.balance') }}</v-card-subtitle>
    <v-card-title class="my-0 pt-0">
      <span v-if="loading">{{ $t("loading") }}</span>
      <span v-else>
        {{ credits }}
      </span>
    </v-card-title>
    <v-icon v-if="true" x-large class="icon">$coins</v-icon>
  </v-card>
</template>

<style scoped>
.icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
</style>
