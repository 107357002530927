import { Capacitor } from "@capacitor/core";
import { saveAs } from "file-saver";
import { format } from "date-fns";
import {Directory, Filesystem} from "@capacitor/filesystem";
import {showAlert, showConfirm} from "@/helpers/dialogs";
import i18n from "@/plugins/i18n";
import { FileOpener } from '@capacitor-community/file-opener'
export default class FileSaver {
  constructor(fileData) {
    this.data = fileData
    this.type = undefined
    this.folder = undefined
  }

  /**
   * Can be of types:
   * - application/pdf
   * - blob
   * @string type
   */
  setType(type) {
    this.type = type

    return this;
  }

  /**
   * Saves the file to the user's device.
   * @throws Error
   */
  async saveToDevice (fileName = '', folderName = undefined) {
    if (this.type === undefined) {
      throw new Error('The file type is not defined. Define the type first using the `setType(<type: string>)` method.');
    }

    const name = this._getFileName(fileName)
    this.folder = folderName
    const blob = new Blob(this.data, { type: this.type });

    if (Capacitor.isNativePlatform()) {
      try {
        const { publicStorage } = await Filesystem.checkPermissions()

        if (publicStorage === 'denied') {
          showAlert(i18n.t('label.permission_denied'), i18n.t('label.no_save_file_permission'))
        } else {
          const uri = await this._saveFileOnDevice(blob, name)
          showConfirm(i18n.t('label.file_saved'), i18n.t('label.file_saved_in_the_documents_folder'), async () => {
            try {
              await FileOpener.open({
                filePath: uri,
                openWithDefault: false
              })
            } catch (e) {
              console.log(e.message)
            }

          }, i18n.t('btn.open'))
        }
      } catch (e) {
        showAlert(i18n.t('label.couldnt_save_the_file'), i18n.t('label.there_was_an_error_while_saving_the_file'))
      }
    } else {
      saveAs(blob, name);
    }
  }

  /**
   * Parse the entered `fileName` and check if the string exists.
   * @param fileName
   * @returns {string}
   * @private
   */
  _getFileName(fileName = '') {
    fileName = fileName.trim();
    return !fileName || fileName.length === 0 || fileName === ''
      ? `bookandpay_${format(new Date(), 'dd-MM-y_hh-mm-ss')}${this._getFileExtension(this.type)}`
      : fileName
  }

  _getFileExtension(type) {
    const extensionsDict = {
      'application/pdf': 'pdf',
      'blob': 'zip'
    }

    return `.${type in extensionsDict ? extensionsDict[type] : 'txt'}`
  }

  _saveFileOnDevice(blob, name) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob)
      reader.onloadend = async () => {
        const { uri } = await Filesystem.writeFile({
          path: `bookandpay/${ this.folder ? `${this.folder}/${name}` : name}`,
          data: reader.result,
          directory: Directory.Documents,
          recursive: true
        });
        resolve(uri)
      }

      reader.onerror = reject
    })
  }
}
