<template>
  <div class="mt-1">
    <ValidationProvider v-slot="{ errors }" name="schoolResourceId">
      <div class="d-flex">
        <v-text-field-mask
          class="mr-2"
          v-model="selectedSchoolResourceId"
          placeholder="S"
          :properties="{
            prefix: 'S',
            suffix: '',
            disabled: !!$route.query.school,
            readonly: false,
            outlined: true,
            label: 'Search by school ID',
            placeholder: '000000',
            dense: true,
            fullWidth: true,
            hideDetails: true,
          }"
          :options="{
            inputMask: '######',
            outputMask: '######',
            empty: '',
            applyAfter: false,
            alphanumeric: false,
            lowerCase: false,
          }"
          focus
          :error="!!errors.length"
          style="width: 100%">
          <template v-slot:append>
            <v-progress-circular
              v-if="loadingSchool"
              size="24"
              color="primary"
              indeterminate />
          </template>
        </v-text-field-mask>
        <v-btn
          v-if="!$route.query.school"
          color="primary"
          @click="searchSchool(selectedSchoolResourceId)">
          {{ $t("btn.search") }}
        </v-btn>
      </div>
      <small v-if="errors.length" class="red--text">{{ errors[0] }}</small>
    </ValidationProvider>

    <div
      class="d-flex flex-column align-center mt-5"
      v-if="!loadingSchool && school">
      <v-sheet
        class="d-flex flex-column pa-5 align-center"
        elevation="2"
        rounded>
        <div class="mb-3 d-flex flex-column align-center">
          <v-img
            v-if="school.avatar"
            class="mb-3"
            :src="school.avatar"
            contain
            max-width="200"
            height="120" />
        </div>

        <div class="d-flex flex-column text-center">
          <span class="mt-2 text-body-1">{{ school.name }}</span>

          <span class="mt-2 text-body-1">
            {{
              `${school.street}, ${school.houseNr}, ${school.postcode}, ${school.city}`
            }}
          </span>
        </div>

        <v-btn
          v-if="!this.$route.query.school"
          class="mt-5"
          color="warning"
          small
          text
          @click="notMySchool"
          >{{ $t("btn.not_my_school") }}</v-btn
        >
      </v-sheet>

      <ValidationProvider v-slot="{ errors }" name="licenseId">
        <v-select
          class="mt-5"
          v-model="stepForm.licenseId"
          :items="licensesOptions"
          :label="$t('form.choose_a_licence')"
          :placeholder="$t('form.choose_a_licence')"
          outlined
          required
          dense
          :error-messages="errors"
          full-width />
      </ValidationProvider>
    </div>

    <div class="mt-4">
      <v-btn
        class="mr-2"
        color="primary"
        @click="submit"
        :disabled="isDisabled"
        :loading="stepFormLoading"
        >{{ $t("btn.continue") }}</v-btn
      >
      <v-btn :to="{ name: 'login' }" text>{{ $t("btn.back") }}</v-btn>
    </div>
  </div>
</template>

<script>
import VTextFieldMask from "@/components/VTextFieldMask";
import schoolService from "@/services/schoolService";
import authService from "@/services/authService";

export default {
  name: "RegistrationStepSchool",
  components: {
    VTextFieldMask,
  },
  data: () => ({
    loadingSchool: false,
    school: null,
    licenses: [],
    selectedSchoolResourceId: "",
    passed: false,
    stepFormLoading: false,
    stepForm: {
      registrationStep: "school",
      schoolResourceId: "",
      licenseId: "",
    },
  }),
  watch: {
    school(value) {
      this.stepForm.schoolResourceId = value.resourceId;
    },
  },
  computed: {
    licensesOptions() {
      return this.licenses.map((license) => {
        return {
          text: `${license.name}`,
          value: license.id,
        };
      });
    },
    isDisabled() {
      return (
        !(this.stepForm.schoolResourceId && this.stepForm.licenseId) ||
        this.stepFormLoading
      );
    },
  },
  async created() {
    this.selectedSchoolResourceId = this.$route.query.school || "";

    if (this.$route.query.school)
      await this.searchSchool(this.$route.query.school);
  },
  methods: {
    async searchSchool(resourceId) {
      const completeResourceId = "S" + resourceId.replace(/S/g, "");

      this.loadingSchool = true;
      await schoolService
        .searchByResourceID(completeResourceId)
        .then((response) => {
          const data = response.data || {};

          this.school = data;
          this.licenses = data.licenses;
        })
        .catch((error) => {
          const responseData = error.response.data;
          const errors = responseData.errors || [];

          if (error.response.status === 404 && this.$route.query.school)
            this.notMySchool();

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });
          }

          Object.keys(errors).forEach((fieldName) =>
            this.$refs.form.setErrors({ [fieldName]: errors[fieldName] })
          );
        })
        .finally(() => {
          this.loadingSchool = false;
        });
    },
    notMySchool() {
      this.school = null;
      this.selectedSchoolResourceId = "";

      const query = Object.assign({}, this.$route.query);

      delete query.school;

      this.$router.replace({ query });
    },
    submit() {
      this.$emit("beforeSubmit");

      this.stepFormLoading = true;
      authService
        .registration(this.stepForm)
        .then(() => {
          this.$emit("submitted", "school", {
            form: {
              schoolResourceId: this.stepForm.schoolResourceId,
              licenseId: this.stepForm.licenseId,
            },
            errors: [],
            passed: true,
          });
        })
        .catch((error) => {
          const responseData = error.response.data;
          const errors = responseData.errors || [];

          this.$emit("submitted", "school", {
            responseMessage: responseData.message,
            errors: { ...errors },
            passed: false,
          });
        })
        .finally(() => {
          this.stepFormLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
