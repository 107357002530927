<template>
  <v-list>
    <v-list-item-group>
      <v-list-item v-for="ticket in data" :key="ticket.id">
        <v-list-item-avatar>
          <v-skeleton-loader type="avatar" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            <v-skeleton-loader type="text@1" width="80%" />
          </v-list-item-title>

          <v-list-item-subtitle>
            <span class="ml-1"
              ><v-skeleton-loader type="text@1" width="150px"
            /></span>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-skeleton-loader type="chip" />
        </v-list-item-action>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "SupportTicketsListLoader",
  data() {
    return {
      data: [{ id: 1 }, { id: 2 }, { id: 3 }],
    };
  },
};
</script>

<style scoped lang="scss"></style>
