<template>
  <ValidationProvider
    name="lesson-duration"
    rules="required"
    v-slot="{ errors }">
    <v-text-field
      class="lesson-duration"
      outlined
      inputmode="numeric"
      dense
      hide-details
      :label="$t('form.duration')"
      :error="errors.length > 0"
      :disabled="disabled"
      @keypress="onlyInteger"
      v-model.number="lessonDuration">
      <v-icon @click="subMin(5)" :disabled="disabled" slot="prepend-inner" color="red">
        mdi-chevron-double-left
      </v-icon>
      <v-icon @click="subMin(1)" :disabled="disabled" slot="prepend-inner" color="red">
        mdi-chevron-left
      </v-icon>
      <v-icon @click="addMin(1)" :disabled="disabled" slot="append" color="green">
        mdi-chevron-right
      </v-icon>
      <v-icon @click="addMin(5)" :disabled="disabled" slot="append" color="green">
        mdi-chevron-double-right
      </v-icon>
    </v-text-field>
  </ValidationProvider>
</template>

<script>

export default {
  name: "LessonDurationField",
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    empty: {
      type: Boolean,
      default: false,
    },
    durationProp: {
      type: Number,
      default: 15,
    },
  },
  computed: {
    lessonDuration: {
      set(val) {
        this.$emit("emitDurationChange", parseInt(val) || 0)
      },
      get() {
        return this.durationProp
      }
    },
  },
  methods: {
    subMin(minutes) {
      if ((this.durationProp - minutes) >= 5 ) {
        this.$emit("emitDurationChange", this.durationProp - minutes)
      }
    },
    addMin(minutes) {
      this.$emit("emitDurationChange", (this.durationProp + minutes))
    },
    onlyInteger ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57)) {
        $event.preventDefault();
      }
    },
  }
};
</script>

<style lang="scss">
.lesson-duration input {
  text-align: center;
  cursor: default;
}
</style>
