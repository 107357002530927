import * as mutationTypes from "./../mutation-types/vehiclesReservation"
import VehicleReservationModel from '@/store/models/VehicleReservationModel'
import VehicleServiceModel from '@/store/models/VehicleServiceModel'
import { startOfDay, endOfDay, format, parseISO } from 'date-fns'
import vehiclesService from '@/services/vehiclesService'

const defaultState = () => ({
  reservationMode: false,
  reservationsLoader: false,
  hoveredVehicleId: null,
  tempReservationPeriod: {},
  creatingStatus: false,
  draggingStatus: false,
  resizingStatus: false,
});

const state = () => defaultState();

const getters = {
  tempFocusedReservation: (state) => {
    return { ...state.tempReservationPeriod, id: 'temp' }
  },
  fixedEndTime: (state) => {
    const { from, to } = state.tempReservationPeriod
    const parsedTo = parseISO(to)
    return parsedTo > endOfDay(parseISO(from)) ? "23:45" : format(parsedTo, "HH:mm")
  }
};

const actions = {
  fetchVehiclesReservations: ( { commit }, value) => {
    commit(mutationTypes.SET_RESERVATIONS_LOADER, true)
    const params = {
      from: format(startOfDay(value), "yyyy-MM-dd HH:mm"),
      to: format(endOfDay(value), "yyyy-MM-dd HH:mm"),
    }
    vehiclesService
      .getAllVehiclesReservations(params)
      .then((resp) => VehicleReservationModel.create({ data: resp.data }))
      .catch((error) => console.log(error))
      .finally(() => commit(mutationTypes.SET_RESERVATIONS_LOADER, false))
  },
  fetchVehiclesServices: ( { commit }, value) => {
    commit(mutationTypes.SET_RESERVATIONS_LOADER, true)
    const params = {
      from: format(startOfDay(value), "yyyy-MM-dd HH:mm"),
      to: format(endOfDay(value), "yyyy-MM-dd HH:mm"),
    }
    vehiclesService
      .getAllVehiclesServices(params)
      .then((resp) => VehicleServiceModel.create({ data: resp.data }))
      .catch((error) => console.log(error))
      .finally(() => commit(mutationTypes.SET_RESERVATIONS_LOADER, false))
  },

  creatingReservation: ({ state, getters, commit }) => {
    commit(mutationTypes.SET_RESERVATIONS_LOADER, true)

    const { vehicleId, from, teacherId } = state.tempReservationPeriod
    const params = {
      date: format(parseISO(from), "yyyy-MM-dd"),
      timeFrom: format(parseISO(from), "HH:mm"),
      timeTo: getters.fixedEndTime,
      teacherId
    }

    vehiclesService
      .storeReservation(vehicleId, params)
      .then((resp) => VehicleReservationModel.insert({ data: resp.data }))
      .catch((error) => console.log(error))
      .finally(() => {
        commit(mutationTypes.SET_RESERVATIONS_LOADER, false)
        commit(mutationTypes.SET_RESERVATION_FIELDS, {})
      })
  },

  updateReservationStop: ({ state, getters, commit, dispatch }, equalPeriod = false) => {
    dispatch('resetStatuses');

    if (equalPeriod) {
      commit(mutationTypes.TOGGLE_CREATING_STATUS, false)
      return
    }

    commit(mutationTypes.SET_RESERVATIONS_LOADER, true)
    const { id, vehicleId, from, teacherId } = state.tempReservationPeriod
    const params = {
      date: format(parseISO(from), "yyyy-MM-dd"),
      timeFrom: format(parseISO(from), "HH:mm"),
      timeTo: getters.fixedEndTime,
      teacherId
    }

    vehiclesService
      .updateReservation(vehicleId, id, params)
      .then((resp) => VehicleReservationModel.insert({ data: resp.data }))
      .catch((error) => console.log(error))
      .finally(() => {
        commit(mutationTypes.SET_RESERVATIONS_LOADER, false)
        commit(mutationTypes.SET_RESERVATION_FIELDS, {})
      })
  },

  resetStatuses: ({ commit }) => {
    commit(mutationTypes.TOGGLE_RESIZING_STATUS, false)
    commit(mutationTypes.TOGGLE_DRAGGING_STATUS, false)
    commit(mutationTypes.HOVER_VEHICLE_LINE, null)
  },
};

const mutations = {
  [mutationTypes.RESET_RESERVATION_STATE]: (state) => {
    Object.assign(state, defaultState())
  },
  [mutationTypes.TOGGLE_RESERVATION_MODE]: (state, value) => {
    state.reservationMode = value
  },

  [mutationTypes.SET_RESERVATIONS_LOADER]: (state, value) => {
    state.reservationsLoader = value
  },

  [mutationTypes.HOVER_VEHICLE_LINE]: (state, value) => {
    state.hoveredVehicleId = value
  },
  [mutationTypes.TOGGLE_CREATING_STATUS]: (state, value) => {
    state.creatingStatus = value
  },
  [mutationTypes.TOGGLE_DRAGGING_STATUS]: (state, value) => {
    state.draggingStatus = value
  },
  [mutationTypes.TOGGLE_RESIZING_STATUS]: (state, value) => {
    state.resizingStatus = value
  },
  [mutationTypes.SET_RESERVATION_FIELDS]: (state, value) => {
    state.tempReservationPeriod = value
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
