<template>
  <v-container>
    <v-card>
      <v-toolbar elevation="3">
        <v-btn icon :to="{ name: 'recycle-bin' }" class="ml-0" exact>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-card-title class="text-overflow-ellipsis">
          <span class="mr-2 text-capitalize">{{ $t(recyclable) }}</span>
        </v-card-title>

        <v-spacer />
      </v-toolbar>

      <v-card-text>
        <component :is="listComponent[recyclable]" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import RecyclableStudentsList from "@/components/recycle-bin/RecyclableStudentsList";
import RecyclableEventsList from "@/components/recycle-bin/RecyclableEventsList";
import RecyclableVehiclesList from "@/components/recycle-bin/RecyclableVehiclesList.vue";

export default {
  name: "Recyclable",
  components: { RecyclableEventsList, RecyclableStudentsList, RecyclableVehiclesList },
  computed: {
    recyclable() {
      return this.$route.params.recyclable;
    },
    listComponent: () => ({
      students: "RecyclableStudentsList",
      events: "RecyclableEventsList",
      vehicles: "RecyclableVehiclesList"
    })
  }
};
</script>

<style scoped></style>
