<script>

import LicenseModel from '@/store/models/LicenseModel'

export default {
  name: "VehicleDetailsList",
  props: {
    sidebar: {
      type: Boolean,
      default: false
    },
    vehicle: {
      type: Object,
    },
  },
  computed: {
    itemLicenses() {
      return LicenseModel
        .query()
        .where(item => this.vehicle.licensesIds.includes(item.id))
        .get()
        .map(item => item.name);
    }
  },
};
</script>

<template>
  <v-list :class="sidebar ? 'py-0' : ''" dense>
    <v-subheader v-if="sidebar" class="d-flex justify-space-between">
      <span>{{ $t('route.vehicleDetails') }}</span>
    </v-subheader>

    <v-list-item v-if="vehicle.gearboxType" :class="sidebar ? 'py-0' : ''" dense two-line>
      <v-list-item-content class="pa-0">
        <v-list-item-title>{{ $t("form.transmission_type") }}</v-list-item-title>
        <v-list-item-subtitle>{{ $t(`gearboxType.${vehicle.gearboxType}`) }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>mdi-cogs</v-icon>
      </v-list-item-action>
    </v-list-item>
    <v-list-item v-if="vehicle.fuelType" :class="sidebar ? 'py-0' : ''" dense two-line>
      <v-list-item-content :class="sidebar ? 'py-0' : ''">
        <v-list-item-title>{{ $t("form.fuel_type") }}</v-list-item-title>
        <v-list-item-subtitle>{{ $t(`fuelType.${vehicle.fuelType}`) }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>mdi-gas-station</v-icon>
      </v-list-item-action>
    </v-list-item>

    <v-list-item :class="sidebar ? 'py-0' : ''" dense two-line>
      <v-list-item-content :class="sidebar ? 'py-0' : ''">
        <v-list-item-title>{{ $t("form.licenses") }}</v-list-item-title>
        <v-list-item-subtitle>{{ itemLicenses.join(", ") }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>mdi-license</v-icon>
      </v-list-item-action>
    </v-list-item>

    <v-list-item v-if="vehicle.priceModifier && vehicle.priceModifier > 0" :class="sidebar ? 'py-0' : ''" dense two-line>
      <v-list-item-content :class="sidebar ? 'py-0' : ''">
        <v-list-item-title>{{ $t("label.price_modifier") }}</v-list-item-title>
        <v-list-item-subtitle>{{ vehicle.priceModifier | currency }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>mdi-cash-plus</v-icon>
      </v-list-item-action>
    </v-list-item>

    <v-list-item v-if="vehicle.servicePeriod" :class="sidebar ? 'py-0' : ''" dense two-line>
      <v-list-item-content :class="sidebar ? 'py-0' : ''">
        <v-list-item-title>{{ $t("form.service_period") }}</v-list-item-title>
        <v-list-item-subtitle>{{ vehicle.servicePeriod }} Km</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>mdi-car-wrench</v-icon>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<style scoped>

</style>
