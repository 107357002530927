<template>
  <v-dialog v-model="show" width="700" @click:outside="close">
    <v-card>
      <v-toolbar class="mb-2" dark color="primary" dense>
        <v-toolbar-title>{{
          $t("label.notes") +
          " " +
          (isEdit ? $t("label.edit") : $t("label.create"))
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-container>
        <ValidationObserver ref="form">
          <ValidationProvider v-slot="{ errors }" name="text">
            <v-textarea
              :label="$t('form.note')"
              v-model="form.text"
              solo
              :error-messages="errors"
              rows="7" />
          </ValidationProvider>
        </ValidationObserver>
      </v-container>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="save"
          :disabled="loading"
          :loading="loading"
          >{{ $t("btn.save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import studentsService from "@/services/studentsService";

export default {
  name: "NoticeFormDialog",
  props: {
    studentId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    show: false,
    isEdit: false,
    loading: false,
    form: {
      text: "",
    },
    item: "",
  }),
  methods: {
    open(item) {
      this.show = true;

      if (item) {
        this.isEdit = true;
        this.item = item;
        this.$set(this.form, "text", item.text);
      }
    },
    close() {
      this.form = { note: "" };
      this.show = false;
      this.isEdit = false;
    },
    save() {
      this.isEdit ? this.update(this.form) : this.store(this.form);
    },
    store(data) {
      this.loading = true;
      studentsService
        .createNote(this.studentId, data)
        .then((resp) => {
          this.$emit("saved", resp.data || {});
          this.close();
        })
        .catch((error) => {
          const responseData = error.response.data || {};
          const errors = responseData.errors || [];
          if (Object.keys(errors).length === 0 && responseData.message)
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });
          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update(data) {
      this.loading = true;
      studentsService
        .updateNote(this.studentId, this.item.id, data)
        .then((resp) => {
          this.$emit("saved", resp.data || {});
          this.close();
        })
        .catch((error) => {
          const responseData = error.response.data;
          const errors = responseData.errors || [];
          if (Object.keys(errors).length === 0 && responseData.message)
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });
          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
