<template>
  <v-sheet
    :outlined="outlined"
    :rounded="rounded"
    :elevation="elevation"
    :style="styles"
    :class="classes"
    :width="w"
    :height="h"
  >
    <img
      :src="img"
      :alt="name"
      :style="`width: ${imgw}; height: ${imgh}`"
    />

    <span
      class="text-center"
      :class="{ 'font-weight-bold': active, 'text-caption': smallLabel }"
      v-if="!noLabel"
    >
      {{ name }}
    </span>
  </v-sheet>
</template>

<script>
export default {
  props: {
    transparent: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    "no-label": {
      type: Boolean,
      default: false,
    },
    row: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 190,
    },
    height: {
      type: [Number, String],
      default: 60,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    "icon-centered": {
      type: Boolean,
      default: false,
    },
    smallLabel: {
      type: Boolean,
      default: true
    }
  },
  name: "LicenseIcon",
  computed: {
    img() {
      try {
        return require(`@/assets/icons/licenses/${this.icon}.svg`)
      } catch (e) {
        return require(`@/assets/icons/licenses/B.svg`)
      }
    },
    w() {
      if (this.fluid && this.row) return "100%";

      let w = this.width.toString();
      if (w.indexOf("%") !== -1) {
        return w;
      }
      if (w.indexOf("px") === -1) {
        w += "px";
      }
      return w;
    },
    h() {
      let h = this.height.toString();
      if (h.indexOf("px") === -1) {
        h += "px";
      }
      return h;
    },
    styles() {
      let styles = "";

      // styles += `width: ${this.w}; `
      // styles += `height: ${this.h}; `
      styles += "overflow: hidden;";

      if (this.transparent) {
        styles += "background-color: transparent !important;"
      }

      return styles;
    },
    classes() {
      const classes = ["d-inline-flex", "pa-1", "align-center"];

      if (this.row) {
        classes.push("flex-row");
        classes.push(
          this.noLabel && this.iconCentered
            ? "justify-center"
            : "justify-space-between"
        );
      } else {
        classes.push("flex-column");
      }

      if (this.reverse)
        classes.push(this.row ? "flex-row-reverse" : "flex-column-reverse");

      return classes;
    },
    imgw() {
      return this.row ? "" : "auto";
    },
    imgh() {
      return this.row ? `${this.height * 0.65}px` : "70%";
    },
  },
};
</script>

<style scoped></style>
