<script>
import LicenseProgress from "@/components/calendar/appointmentModal/fields/LicenseProgress.vue";
import studentsService from "@/services/studentsService";
import ExpandablePanel from "@/components/ExpandablePanel.vue";

export default {
  name: "StudentLicenses",
  components: {ExpandablePanel, LicenseProgress},
  props: {
    student: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      progressLoading: true,
      progressByLicense: [],
    }
  },
  mounted() {
    studentsService
        .studentProgress(this.student.id)
        .then(resp => {
          this.progressByLicense = resp.data || []
        })
        .finally(() => {
          this.progressLoading = false
        })
  },
  methods: {
    expirationColorByWeeks(license) {
      if (license.examiningPeriodStartedAt === null) return 'grey';

      const examiningPeriodInWeeks = parseInt(this.examiningPeriods[license.id]);

      if (!(license.forTheoryExam || license.forPractice)) return undefined;

      if (examiningPeriodInWeeks > 12) return "success";
      if (examiningPeriodInWeeks <= 12 && examiningPeriodInWeeks > 4) return "warning";

      return "error";
    },
    getLicenseProgress(licenseId) {
      return this.progressByLicense.find(item => item.licenseId === licenseId) || null;
    },
    openDetailedProgress(licenseId) {
      this.$router.push({ name: 'students.student.education.license', params: {id: this.student.id, licenseId} })
    }
  },
  computed: {
    schoolHasInsights () {
      return this.$store.state.school.hasInsights
    },
    licenses () {
      return this.student?.licenses
    },
    examiningPeriods () {
      return this.student?.licensesIdsWithExaminingPeriodInWeeks
    }
  }
}
</script>

<template>
  <expandable-panel panel-name="licensesPanelExpanded">
    <template v-slot:title="{expanded}">
      {{ $t('form.licenses') }}
      <v-badge inline color="grey lighten-1" :value="licenses.length && ! expanded" :content="licenses.length"/>
    </template>
    <v-simple-table v-if="licenses.length" dense>
      <thead>
      <tr>
        <th scope="col" class="text-left pr-0" style="width: 50px">{{ $t('label.license') }}</th>
        <th scope="col" class="text-center pr-0" style="width: 30px">{{ $t('label.theory_exam_abbr') }}</th>
        <th scope="col" class="text-center pr-0" style="width: 30px">{{ $t('label.practice_exam_abbr') }}</th>
        <th scope="col" class="text-center pr-0" style="width: 30px">{{ $t('label.examining_period_abbr') }}</th>
        <th scope="col" class="text-left">{{ $t('label.progress') }}</th>
        <th v-if="schoolHasInsights" class="text-center" style="width: 50px;">{{ $t('insights.feature_name') }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="license in licenses" :key="license.id" @click="openDetailedProgress(license.id)" style="cursor:pointer;">
        <th scope="row" class="pr-0">{{ license.name }}</th>
        <td class="text-center pr-0">
          <v-chip label x-small :color="(license.theoryExamPassedAt || !license.theoryExamRequired) ? 'success' : 'error'">
            <template v-if="license.theoryExamRequired">
              <v-icon left x-small>{{ license.theoryExamPassedAt != null ? 'mdi-check' : 'mdi-close' }}</v-icon>
              {{ getLicenseProgress(license.id)?.examResults?.theoryExamsCount }}
            </template>
            <template v-else>
              <v-icon left x-small>mdi-check</v-icon>
              &mdash;
            </template>
          </v-chip>
        </td>
        <td class="text-center pr-0">
          <v-chip label x-small :color="(license.practiceExamPassedAt || !license.practiceExamRequired) ? 'success' : 'error'">
            <template v-if="license.practiceExamRequired">
              <v-icon left x-small>{{ license.practiceExamPassedAt != null ? 'mdi-check' : 'mdi-close' }}</v-icon>
              {{ getLicenseProgress(license.id)?.examResults?.practiceExamsCount }}
            </template>
            <template v-else>
              <v-icon left x-small>mdi-check</v-icon>
              &mdash;
            </template>
          </v-chip>
        </td>
        <td class="text-center pr-0">
          <v-icon color="success" v-if="license.practiceExamPassedAt">mdi-check</v-icon>
          <template v-else>
            <v-btn
                icon
                v-if="! student.hasDocumentsApproved || examiningPeriods[license.id] === null"
                @click.stop
                :to="{name: 'students.student.notes', params: {id: student.id}}"
            >
              <v-icon>mdi-calendar-question</v-icon>
            </v-btn>
            <v-badge
                inline
                :color="expirationColorByWeeks(license)"
                :value="license.examiningPeriodStartedAt"
                :content="examiningPeriods[license.id]"
                v-else-if="examiningPeriods[license.id]"
            />
            <v-icon v-else color="error">mdi-alert-outline</v-icon>
          </template>
        </td>
        <td>
          <span v-if="progressLoading">{{ $t('progress_loading') }}</span>
          <div v-else class="d-flex flex-row align-center-center">
            <div style="width: 60px;">
              <v-progress-linear
                  v-if="! progressLoading"
                  height="16"
                  color="grey"
                  rounded
                  :value="getLicenseProgress(license.id)?.totalProgress"
              >
                <span style="font-size: 12px;">{{ getLicenseProgress(license.id)?.totalProgress }}%</span>
              </v-progress-linear>
            </div>
            <license-progress
                class="ml-2"
                :license-progress="getLicenseProgress(license.id)"
                show-theory-progress
                :outlined="false"
            />
          </div>
        </td>
        <td v-if="schoolHasInsights" class="text-center">
          <v-icon :color="license.hasInsights ? 'success' : 'error'">
            {{ license.hasInsights ? 'mdi-check' : 'mdi-close' }}
          </v-icon>
        </td>
      </tr>
      </tbody>
    </v-simple-table>
    <empty-box v-else />
  </expandable-panel>
</template>

<style scoped>

</style>
