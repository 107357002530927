import TeacherModel from "@/store/models/TeacherModel";
import { addWeeks, differenceInMinutes, format, parseISO } from "date-fns";
import EventModel from "@/store/models/EventModel";
import EventTypeModel from "@/store/models/EventTypeModel";
import LessonTypeModel from "@/store/models/LessonTypeModel";
import { mapActions, mapGetters } from "vuex";
import eventServices from "@/services/eventService";
import i18n from "@/plugins/i18n";
import StudentModel from "@/store/models/StudentModel";
import DateRange from "@/helpers/dateRange";
import { showConfirm } from "@/helpers/dialogs";

export default {
  data() {
    return {
      eventModel: {},
      loader: false,
      alert: {
        status: false,
        message: "",
        extra_message: "",
      },
      windowHeight: window.innerHeight,
    };
  },
  props: {
    activeTeachers: {
      type: Array,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    formComponent() {
      if (this.eventModel.type === "THEORY") {
        return this.eventModel.lessonTypeGroup === "lesson"
          ? "THEORY_LESSON_FORM"
          : "THEORY_EXAM_FORM";
      } else {
        return this.eventModel.type + "_FORM";
      }
    },
    alertExtraMessage() {
      return this.alert.extra_message;
    },
    showPracticeMissedButton() {
      const event = EventModel.find(this.eventModel.id) || {};
      const properGroups = ["normal", "special"].includes(event.group);
      const pastStatus = parseISO(this.eventModel.start) <= new Date();
      return (
        properGroups &&
        pastStatus &&
        !event.signature &&
        event.status !== "MISSED_REFUNDED"
      );
    },
    missedStatus() {
      const event = EventModel.find(this.eventModel.id) || {};
      return event.status === "MISSED" || event.status === "MISSED_REFUNDED";
    },
    disabledJoker() {
      const student = StudentModel.find(this.eventModel.studentsIds[0]) || {};
      return !student.joker;
    },
    hideDeleteButton() {
      const filteredEventTypes = ["PRACTICE", "SPECIAL", "THEORY"].includes(
        this.eventModel.eventType
      );
      return this.isPast && (filteredEventTypes || this.bookedSimulator);
    },
    hideOkButton() {
      const filteredEventTypes = [
        "PLACEHOLDER",
        "SIMULATOR",
        "SPECIAL",
        "THEORY",
      ].includes(this.eventModel.eventType);
      return this.eventModel.id && this.isPast && filteredEventTypes;
    },
    bookedSimulator() {
      const event = EventModel.find(this.eventModel.id) || {};
      return event.eventType === "SIMULATOR" && event.studentsIds.length;
    },
    teachers() {
      return TeacherModel.query().where("active", true).get();
    },
    isPast() {
      /*commented past exam logic*/
      // return !this.schoolSettings.pastEventsAllowed && parseISO(this.eventModel.start) <= new Date()
      return false
    },
    holiday() {
      const regions = this.schoolSettings.regions?.split(", ");
      return this.eventModel.date
        ? new DateRange().isHoliday(parseISO(this.eventModel.date), regions)
        : {};
    },
    requestedPractice() {
      return (
        this.eventModel.type === "PRACTICE" &&
        this.eventModel.status === "REQUESTED"
      );
    },
    requestedPlaceholder() {
      let status = false;
      const model = EventModel.find(this.eventModel.id);
      if (model)
        status =
          model.eventType === "PLACEHOLDER" &&
          !!(model.requests && model.requests.length);
      return status;
    },
    isCreatedPractice() {
      const model = EventModel.find(this.eventModel.id);
      return model?.eventType === "PRACTICE";
    },
    isCreatedTheory() {
      const model = EventModel.find(this.eventModel.id);
      return model?.eventType === "THEORY";
    },
    eventTypes() {
      const allTypes = EventTypeModel.all() || {};
      return this.isSchoolAdministrator
        ? allTypes
        : allTypes.filter((type) => type.id !== "SIMULATOR");
    },
    filteredTypes() {
      let types = this.eventTypes;
      if (this.holiday)
        types = types.filter(
          (type) => type.id === "OFFTIME" || type.id === "SPECIAL"
        );
      if (this.isPast) types = types.filter((type) => type.id === "OFFTIME");
      return types;
    },
    eventColor() {
      if (
        this.eventModel.type === "PRACTICE" ||
        this.eventModel.type === "THEORY"
      ) {
        return LessonTypeModel.find(this.eventModel.lessonTypeId) || {};
      } else {
        return EventTypeModel.find(this.eventModel.type) || {};
      }
    },
    eventDuration() {
      if (
        (this.eventModel.type !== "PRACTICE" &&
          this.eventModel.type !== "PLACEHOLDER") ||
        !this.eventModel.teacher
      ) {
        const startISO = parseISO(this.eventModel.start);
        const endISO = parseISO(
          `${this.eventModel.date} ${this.eventModel.endTime}`
        );
        return differenceInMinutes(endISO, startISO);
      } else {
        return this.eventModel.lessonDuration * this.eventModel.lessonsAmount;
      }
    },
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("appointments", [
      "createEventModalGetter",
      "newEventModelGetter",
      "initialDurationGetter",
    ]),
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    buttonLoader() {
      this.loader = !this.loader;
    },
    eventTypeLabel(item) {
      return this.$t(`event_type.${item.id}`);
    },
    async eventSave() {
      const pastPractice =
        this.eventModel.type === "PRACTICE" &&
        this.eventModel.status === "ACCEPTED";
      if (this.isPast && !pastPractice && this.eventModel.type !== "OFFTIME") {
        this.showAlert(i18n.t("alert.event_cant_be_created_in_past"));
      } else {
        const validate = await this.$refs.observer.validate();
        if (validate) {
          this.buttonLoader();
          return this.eventModel.id
            ? this.eventEdit(this.eventModel)
            : this.eventStore(this.eventModel);
        }
      }
    },
    eventStore(event) {
      const eventModel = {
        ...event,
        duration: this.eventDuration,
        studentsIds: event.studentsIds,
        meetingPlace: event.meetingPlace,
        manualLesson: event.manualLesson,
        allowMultipleRequests: event.allowMultipleRequests,
        allowedLicenses: event.allowedLicenses,
        isWorkTime: event.isWorkTime,
        primaryVehicleId: event.primaryVehicleId,
        secondaryVehicleId: event.secondaryVehicleId,
        theoryTopicId: event.theoryTopicId,
        cost: event.cost,
        officeId: event.officeId,
        specialType: event.specialType,
        text: event.eventText,
        lessonTypeId: event.lessonTypeId,
        copyForNrOfWeeks: event.copyForNrOfWeeks,
      };


      return new Promise((resolve, reject) => {
        eventServices
          .store(eventModel)
          .then((response) => {
            const responseData = response.data || {};
            EventModel.insert({
              where: eventModel.id,
              data: responseData,
            }).then(this.finishEventCreation);

            if (eventModel.copyForNrOfWeeks > 0) {
              const params = this.copyWeeksObject(
                eventModel.date,
                eventModel.copyForNrOfWeeks
              );
              eventServices.copy(responseData.id, params).then((resp) => {
                if (resp) {
                  const copies = resp.data.items || {};
                  const overlapIds = resp.data.overlapIds || {};
                  if (copies) EventModel.insert({ data: copies });
                  if (overlapIds.length)
                    overlapIds.forEach((eventId) => EventModel.delete(eventId));
                }
              });
            }

            const teacherId = responseData.teacherId;
            if (!this.$props.activeTeachers.includes(teacherId) && teacherId)
              this.calendarActivate(teacherId);

            resolve(response);
          })
          .catch((err) => {
            this.showAlert(
              err.response.data.message,
              err.response.data.overlappingVehicles
            );
            reject(err);
          })
          .finally(() => this.buttonLoader());
      });
    },
    eventUpdate(event) {
      const eventModel = {
        ...event,
        duration: this.eventDuration,
        studentsIds: event.studentsIds,
        meetingPlace: event.meetingPlace,
        manualLesson: event.manualLesson,
        allowMultipleRequests: event.allowMultipleRequests,
        allowedLicenses: event.allowedLicenses,
        isWorkTime: event.isWorkTime,
        primaryVehicleId: event.primaryVehicleId,
        secondaryVehicleId: event.secondaryVehicleId,
        theoryTopicId: event.theoryTopicId,
        cost: event.cost,
        officeId: event.officeId,
        specialType: event.specialType,
        text: event.eventText,
        lessonTypeId: event.lessonTypeId,
        copyForNrOfWeeks: event.copyForNrOfWeeks,
        notificationLicenses: event.notificationLicenses,
      };

      const oldEventType = eventModel.eventType;
      const newEventType = eventModel.type;

      if (
        ["OFFTIME", "PLACEHOLDER"].includes(oldEventType) &&
        oldEventType !== newEventType
      ) {
        return new Promise((resolve) =>
          eventServices.delete(event.id).then(() => {
            this.eventStore(eventModel).then(() =>
              EventModel.delete(event.id).then(() => resolve())
            );
          })
        );
      } else {
        return new Promise((resolve, reject) => {
          eventServices
            .update(eventModel.id, eventModel)
            .then((response) => {
              const responseData = response.data || {};
              EventModel.update({ where: eventModel.id, data: responseData });

              if (eventModel.copyForNrOfWeeks > 0) {
                const params = this.copyWeeksObject(
                  eventModel.date,
                  eventModel.copyForNrOfWeeks
                );
                eventServices.copy(responseData.id, params).then((resp) => {
                  if (resp) {
                    const copies = resp.data.items || {};
                    const overlapIds = resp.data.overlapIds || {};
                    if (copies) EventModel.insert({ data: copies });
                    if (overlapIds.length)
                      overlapIds.forEach((eventId) =>
                        EventModel.delete(eventId)
                      );
                  }
                });
              }

              const teacherId = responseData.teacherId;
              if (!this.$props.activeTeachers.includes(teacherId) && teacherId)
                this.calendarActivate(teacherId);
              resolve();
            })
            .catch((err) => {
              this.showAlert(
                err.response.data.message,
                err.response.data.overlappingVehicles
              );
              reject(err);
            })
            .finally(() => this.buttonLoader());
        });
      }
    },
    copyWeeksObject(date, nrOfWeeks) {
      const parsedCurDate = parseISO(date);
      const regions = this.schoolSettings.regions?.split(", ");
      const interval = [];
      for (let i = 1; i <= nrOfWeeks; i++) {
        interval.push(addWeeks(parsedCurDate, i));
      }
      const holidays = interval.filter((day) =>
        new DateRange().isHoliday(day, regions)
      );
      const formattedHolidays = holidays.map((day) =>
        format(day, "yyyy-MM-dd")
      );

      const params = {
        copyForNrOfWeeks: nrOfWeeks,
        exceptedDays: formattedHolidays,
      };
      return params || {};
    },
    eventEdit(event) {
      this.eventUpdate(event)
        .then(this.finishEventCreation)
        .catch((error) => console.log(error));
    },
    eventConfirm(evt) {
      this.buttonLoader();
      this.eventUpdate(evt)
        .then(() =>
          eventServices.confirmPractice(evt.id, evt).then(() => {
            const event = EventModel.find(evt.id);
            EventModel.update({
              where: event.id,
              data: {
                typeEntity: {
                  ...event.typeEntity,
                  status: "ACCEPTED",
                },
              },
            });
            this.finishEventCreation();
          })
        )
        .catch((err) => this.showAlert(err.response.data.message));
    },
    async eventDecline(event) {
      this.buttonLoader();
      await showConfirm(
        i18n.t("alert.are_you_sure"),
        i18n.t("messages.are_your_sure_delete"),
        async () => {
          const response = await eventServices.declinePractice(event.id);
          if (response) {
            EventModel.delete(event.id).then(this.finishEventCreation);
            await EventModel.insert({ data: response.data || {} });
          }
        }
      );
      this.buttonLoader();
    },
    async placeholderConfirm(evt) {
      const validate = await this.$refs.observer.validate();
      if (validate) {
        this.eventUpdate(evt)
          .then(() =>
            eventServices
              .placeholderConfirm(evt.id, {
                ...evt,
                studentId: evt.subscriber.studentId,
              })
              .then((response) => {
                EventModel.update({ where: evt.id, data: response.data });
                this.finishEventCreation();
              })
          )
          .catch((err) => this.showAlert(err.response.data.message))
          .finally(() => this.buttonLoader());
      }
    },
    async eventDelete(event) {
      this.buttonLoader();
      await showConfirm(
        i18n.t("alert.are_you_sure"),
        i18n.t("messages.are_your_sure_delete"),
        () => {
          eventServices
            .delete(event.id)
            .then((response) => {
              EventModel.delete(event.id).then(this.finishEventCreation);
              EventModel.insert({ data: response.data || {} });
            })
            .catch((error) => console.log(error));
        }
      );
      this.buttonLoader();
    },
    async placeholderDecline(event) {
      this.buttonLoader();
      const response = await eventServices.placeholderDecline(event.id);
      if (response) {
        EventModel.update({ where: event.id, data: response.data });
        this.eventModel = { ...event, requests: [] };
      }
      this.buttonLoader();
    },
    async simulatorDecline(event) {
      this.buttonLoader();
      const response = await eventServices.simulatorDecline(event.id);
      if (response) {
        EventModel.update({ where: event.id, data: response.data });
        this.eventModel = { ...event, studentsIds: [], licenseId: null };
      }
      this.buttonLoader();
    },
    eventCancel() {
      this.$refs.observer.reset();
      this.finishEventCreation();
    },
    showAlert(text, extra = "") {
      this.alert.status = true;
      this.alert.message = text;
      this.alert.extra_message = extra;
    },
    async setMissedEventStatus(eventId) {
      await showConfirm(
        i18n.t("alert.are_you_sure"),
        i18n.t("messages.are_your_sure_set_missed_practice"),
        () => {
          eventServices.setMissedStatus(eventId).then(() => {
            const event = EventModel.find(eventId) || {};
            if (event) {
              EventModel.update({
                where: event.id,
                data: { typeEntity: { ...event.typeEntity, status: "MISSED" } },
              });
            }
          });
        }
      )
    },
    refund(eventId, withJoker = false) {
      eventServices
        .refundMissedEvent(eventId, { withJoker: withJoker })
        .then(() => {
          const event = EventModel.find(eventId) || {};
          if (event) {
            EventModel.update({
              where: event.id,
              data: {
                typeEntity: { ...event.typeEntity, status: "MISSED_REFUNDED" },
              },
            });
          }
          if (withJoker)
            StudentModel.update({
              where: event.studentsIds[0],
              data: { joker: false },
            });
        })
        .catch((error) => console.log(error));
    },

    ...mapActions("appointments", ["finishEventCreation"]),
    ...mapActions("teachers", { calendarActivate: "enable" }),
  },
};
