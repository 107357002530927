<template>
  <span
    :class="value > 0 ? 'green--text' : value === 0 ? '' : 'red--text'"
    class="font-weight-bold text--darken-1">
    {{ value | currency }}
  </span>
</template>

<script>
export default {
  name: "ColoredCurrency",
  props: {
    value: {
      required: true,
    },
  },
};
</script>
