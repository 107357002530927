import api from "@/utils/api";

export default {
  load(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/students", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  studentHistory(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .get(`students/${studentId}/history`, { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadItem(itemId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/students/${itemId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadFormData() {
    return new Promise((resolve, reject) => {
      api
        .get("/students/formData")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  create(params) {
    return new Promise((resolve, reject) => {
      api
        .post("/students", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  update(itemId, params) {
    return new Promise((resolve, reject) => {
      api
        .post(`/students/${itemId}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  destroy(itemId) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/students/${itemId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  makeActive(itemId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/students/${itemId}/make-active`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateTeachers(itemId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/students/${itemId}/update-teachers`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  documents(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .get("/students/" + studentId + "/own-documents", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  uploadDocument(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .post("/students/" + studentId + "/own-documents", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err)
        });
    });
  },
  deleteDocument(studentId, documentId) {
    return new Promise((resolve, reject) => {
      api
        .delete("/students/" + studentId + "/own-documents/" + documentId)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  downloadOnTimePassword(studentId) {
    return new Promise((resolve, reject) => {
      api
        .get("/students/" + studentId + "/documents/one-time-password", {
          responseType: "blob",
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  downloadEducationReport(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .get("/students/" + studentId + "/documents/education-report", {
          params: params,
          responseType: "blob",
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  downloadEducationCertificate(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .get("/students/" + studentId + "/documents/education-certificate", {
          params: params,
          responseType: "blob"
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  manualUpdateBalance(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/students/${studentId}/update-manual-balance`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateOnTimePassword(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .put("/students/" + studentId + "/update-on-time-password", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  resetEducationStatus(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/students/${studentId}/reset-education-status`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  studentExamResults(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/students/${studentId}/exam-results`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  studentExamsProgress(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .get(`/students/${studentId}/exam-results`, { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  setEducationSignature(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/students/${studentId}/education-signature`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  studentsEvents(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .get(`/students/${studentId}/events`, { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  buyLearningMaterials(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .post(`/students/${studentId}/buy/learning-material`, { ...params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  agenda: (studentId, params) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/students/${studentId}/agenda`, { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  reactivateJoker(studentId) {
    return new Promise((resolve, reject) => {
      api
        .post(`/students/${studentId}/set-joker`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  setVaccinationStatus(studentId) {
    return new Promise((resolve, reject) => {
      api
        .patch(`/students/${studentId}/set-vaccination-status`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  downloadEducationContract(studentId, params = {}) {
    return new Promise((resolve, reject) => {
      api
        .post(`/students/${studentId}/documents/contract`,{ ...params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  setHasGlasses(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/students/${studentId}/set-has-glasses`, { ...params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  setHasDocumentsComplete(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/students/${studentId}/set-has-documents-complete`, { ...params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  setHasDocumentsApproved(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/students/${studentId}/set-has-documents-approved`, { ...params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  setExaminingPeriodStartDate(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/students/${studentId}/set-examining-period-start-date`, {
          ...params,
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  setExamOrganization(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .patch(`/students/${studentId}/set-exam-organization`, { ...params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadNotes(studentId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/students/${studentId}/notes`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  createNote(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .post(`/students/${studentId}/notes`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateNote(studentId, itemId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/students/${studentId}/notes/${itemId}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  destroyNote(studentId, itemId) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/students/${studentId}/notes/${itemId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  destroyedInfo() {
    return new Promise((resolve, reject) => {
      api
        .get("/students/destroyed-info")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  destroyedList(params = {}) {
    return new Promise((resolve, reject) => {
      api
        .get("/students/destroyed-list", { params: params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  restore(studentId) {
    return new Promise((resolve, reject) => {
      api
        .patch(`/students/${studentId}/restore`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  licenses(studentId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/students/${studentId}/licenses`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  services(studentId, params = {}) {
    return new Promise((resolve, reject) => {
      api
        .get(`/students/${studentId}/services`, { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  createService(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .post(`/students/${studentId}/services`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  assignLicenseToService(studentId, serviceId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/students/${studentId}/services/${serviceId}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  deleteService(studentId, serviceId) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/students/${studentId}/services/${serviceId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  invoices(studentId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/students/${studentId}/invoices`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateInvoicingSettings(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .patch(`/students/${studentId}/update-invoicing-settings`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  priceLists(studentId) {
    return new Promise((resolve, reject) => {
      api
          .get(`/students/${studentId}/price-lists`)
          .then((resp) => {
            resolve(resp);
          })
          .catch(reject);
    });
  },
  practiceRequests(studentId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/students/${studentId}/practice-requests`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  fetchAppointmentsForDate(studentId, date) {
    return new Promise((resolve, reject) => {
      api
        .get(`/students/${studentId}/appointments?date=${date}&forApologyLetter=true`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  createApologyLetter(studentId, data) {
    return new Promise((resolve, reject) => {
      api
          .post(`/students/${studentId}/documents/apology-letter`, data)
          .then((resp) => {
            resolve(resp);
          })
          .catch(reject);
    });
  },
  studentProgress(studentId, params) {
    return new Promise((resolve, reject) => {
      api
        .get(`students/${studentId}/licenses/progress`, { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  studentProgressByLicense(studentId, licenseId, params) {
    return new Promise((resolve, reject) => {
      api
        .get(`students/${studentId}/licenses/${licenseId}/progress`, { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  studentLicenseAppointmentsCount(studentId, licenseId, params) {
    return new Promise((resolve, reject) => {
      api
        .get(`students/${studentId}/licenses/${licenseId}/appointments-count`, { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  costsOverview(studentId, params = null) {
    return new Promise((resolve, reject) => {
      api
        .get(`students/${studentId}/costs-overview`, { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  costsOverviewPdf(studentId, params = null) {
    return new Promise((resolve, reject) => {
      api
        .get(`students/${studentId}/costs-overview/export`, { params, responseType: "blob" })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  sendNegativeBalanceReminder(studentId) {
    return new Promise((resolve, reject) => {
      api
        .post(`students/${studentId}/notify/negative-balance`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  setDefaultInvoiceAddress(studentId, params = null) {
    return new Promise((resolve, reject) => {
      api
        .patch(`students/${studentId}/set-default-invoice-address`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  unpaidStudents: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("/students/unpaid-students", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  payForLicense(studentId, data) {
    return new Promise((resolve, reject) => {
      api.post(`students/${studentId}/pay-for-license`, data)
        .then((resp) => {
          resolve(resp)
        })
        .catch(reject)
    })
  },
  basePayments(studentId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/students/${studentId}/base-payments`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  markLicenseAsPaid(studentId, data) {
    return new Promise((resolve, reject) => {
      api.post(`students/${studentId}/base-payments`, { data })
        .then((resp) => {
          resolve(resp)
        })
        .catch(reject)
    })
  },
  loadStudentTeachers(studentId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/students/${studentId}/teachers`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  removeScheduledPriceLists(studentId) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/students/${studentId}/scheduled-price-lists`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  createCertificate(studentId, data) {
    return new Promise((resolve, reject) => {
      api
        .post(`/students/${studentId}/documents/certificate`,{...data})
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  autocompleteData(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/students/autocomplete-data", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  setReadyForExam(studentId, licenseId, status) {
    return new Promise((resolve, reject) => {
      api
        .patch(`students/${studentId}/licenses/${licenseId}/set-ready-for-exam`,{ status })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  setExamPriorityScore(studentId, licenseId, score) {
    return new Promise((resolve, reject) => {
      api
        .patch(`students/${studentId}/licenses/${licenseId}/set-exam-priority-score`,{ score })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  }
};
