<script>
import {defineComponent} from 'vue'
import vehiclesService from "@/services/vehiclesService";
import VehicleAvatar from "@/components/VehicleAvatar.vue";
import VehicleRefuelDialog from "@/components/vehicle/VehicleRefuelDialog.vue";
import TeacherVehiclesListLoader from "@/components/skeleton-loaders/TeacherVehiclesListLoader.vue";
import Button from "@/components/calendar/appointmentModal/Button.vue";

export default defineComponent({
  name: "TeacherVehiclesWidget",
  components: {Button, TeacherVehiclesListLoader, VehicleRefuelDialog, VehicleAvatar},
  data() {
    return {
      loading: false,
      reloading: false,
      vehicles: [],
    };
  },
  mounted() {
    this.loading = true
    this.load();
  },
  methods: {
    load(reloading = false) {
      this.reloading = reloading
      vehiclesService
        .loadVehiclesForCurrentTeacher({ mostRecentFirst: true })
        .then((response) => {
          this.vehicles = response.data;
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = this.reloading = false));
    }
  }
})
</script>

<template>
  <v-card :loading="loading || reloading">
    <v-card-title class="d-flex justify-space-between py-2">
      {{ $t("label.my_vehicles") }}
      <v-btn icon small @click="load(true)" :loading="reloading">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <teacher-vehicles-list-loader :count="2" v-if="loading" />

      <template v-else>
        <empty-box v-if="vehicles.length === 0" />
        <v-list dense v-else>
          <v-list-item dense v-for="vehicle in vehicles" :key="vehicle.id">
            <VehicleAvatar
                left
                size="50"
                class="mr-2"
                :path="vehicle.photo"
                :contain="!vehicle.photo"
                :color="vehicle.color"
                v-if="$vuetify.breakpoint.smAndUp"
            />

            <v-list-item-content>
              <v-list-item-title>{{ vehicle.name }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ $t('label.your_latest_appointment', { appointmentTime: vehicle.latestAppointment?.formattedStart ?? $t('label.never') }) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ $t('label.current_mileage', { kmAmount: vehicle.currentMileage}) }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <Button
                :label="$t('btn.refuel')"
                @click.native="$refs.vehicleRefuelDialog.open(vehicle.id)"
                color="primary"
                icon="mdi-gas-station"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>

    </v-card-text>
    <vehicle-refuel-dialog ref="vehicleRefuelDialog" show-details-alert />
  </v-card>
</template>

<style scoped>

</style>
