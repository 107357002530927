<template>
  <div>
    <v-menu
      :transition="false"
      v-model="eventCreationMenuGetter.visible"
      :position-x="eventCreationMenuGetter.x"
      :position-y="eventCreationMenuGetter.y"
      offset-y
      :close-on-click="false">
      <v-list dense>
        <CreationMenuItem
          v-for="type in typesItems"
          :key="type.id"
          :type="type"
          @itemClick="openModal(type.id)" />
        <v-divider></v-divider>
        <v-list-item @click.left="close">
          <v-list-item-title>
            <v-icon left>mdi-close</v-icon>
            <span>{{ $t("close") }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CreationMenuItem from "@/components/calendar/CreationMenuItem";
import { parseISO } from "date-fns";
import user from "@/utils/mixins/user";
import DateRange from "@/helpers/dateRange";
import EventTypeModel from "@/store/models/EventTypeModel";

export default {
  name: "AppointmentCreationMenu",
  mixins: [user],
  components: { CreationMenuItem },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("appointments", [
      "eventCreationMenuGetter",
      "currentAppointmentGetter",
    ]),
    holiday() {
      const date = this.eventCreationMenuGetter.tempAppointment?.start;
      let status = false;
      const regions = this.schoolSettings.regions?.split(", ");
      if (date) status = new DateRange().isHoliday(parseISO(date), regions);
      return status;
    },
    isPast() {
      /*commented past exam logic*/
      // return !this.schoolSettings.pastEventsAllowed && isPast(parseISO(this.eventCreationMenuGetter.tempAppointment?.start))
      return false
    },
    typesItems() {
      let types = EventTypeModel.all() || {};
      // if (this.isPast && !this.schoolSettings.pastEventsAllowed) {
      //   types = types.filter((type) => type.id === "OFFTIME");
      // }
      if (this.isPast || this.holiday) {
        types = types.filter((type) => ["OFFTIME", "SPECIAL"].includes(type.id));
      }
      if (!this.isSchoolAdministrator) {
        types = types.filter((type) => type.id !== "SIMULATOR");
      }
      return types;
    },
  },
  methods: {
    ...mapActions("appointments", [
      "showAppointmentModal",
      "eventCreationMenuOff",
    ]),
    openModal(typeId) {
      this.showAppointmentModal({ ...this.eventCreationMenuGetter.tempAppointment, type: typeId, });
    },
    close() {
      this.eventCreationMenuOff();
    },
  },
};
</script>

<style lang="scss"></style>
