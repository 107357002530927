<template>
  <v-card
      :class="`appointment mb-2 ${disabled ? 'grayscale' : ''}`"
      :style="eventStyle"
      :disabled="disabled"
  >
    <div class="d-flex justify-space-between">
      <div class="ellipsis">{{ startTime }} - {{ endTime }}</div>
      <div class="license-block">{{ infoString }}</div>
    </div>
    <div class="d-flex justify-space-between">
      <div class="ellipsis">{{ appointment.teacherName }}</div>
      <v-icon v-if="appointment.isManualB197" small :color="appointment.fontColor">mdi-alpha-s-box</v-icon>
    </div>
    <div class="ellipsis">{{ appointment.studentName }}</div>

    <v-overlay :value="loading" absolute opacity="0.2">
      <v-progress-circular indeterminate size="25"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { parseISO, format, addMinutes } from 'date-fns'
import { mapState } from 'vuex'
import LicenseModel from '@/store/models/LicenseModel'
import LessonTypeModel from '@/store/models/LessonTypeModel'

export default  {
  name: "WithoutVehicleAppointment.",
  props: {
    appointment: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('vehiclesCalendar', ['shorthands']),
    licenseName() {
      return LicenseModel.find(this.appointment.licenseId).name
    },
    lessonTypeName() {
      return this.shorthands[LessonTypeModel.find(this.appointment.lessonTypeId).name]
    },
    infoString() {
      return (this.lessonTypeName ? this.lessonTypeName + ' - ' : '') +  this.licenseName
    },
    parsedDate(){
      return parseISO(this.appointment.start)
    },
    startTime() {
      return format(this.parsedDate, 'HH:mm')
    },
    endTime() {
      return format(addMinutes(this.parsedDate, this.appointment.duration), 'HH:mm')
    },
    eventStyle() {
      return `backgroundColor: ${this.appointment.bodyColor}; color: ${this.appointment.fontColor};`
    },
  }
}
</script>

<style lang="scss" scoped>
.appointment{
  padding: 2px 5px;
  box-sizing: border-box;
  font-size: 11px;
  line-height: normal;
  &.grayscale{
    opacity: 0.5;
  }
}
.ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.license-block{
  min-width: 50px;
  font-size: 10px;
  font-weight: 900;
  text-align: right;
}
</style>
