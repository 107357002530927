import Vue from "vue";
import i18n from "./i18n";
import * as rules from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  configure,
} from "vee-validate";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

configure({
  defaultMessage: (field, values) => {
    values._field_ = i18n.te(`validation.fields.${field}`)
      ? i18n.t(`validation.fields.${field}`)
      : field;
    return i18n.t(`validation.rules.${values._rule_}`, values);
  },
});
