<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'school-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>
        {{ $t("route.schoolSettings.children.clicklearn") }}
      </v-card-title>
      <v-spacer/>
      <DemoModeToolTipWrapper :disabled="!isDemoModeEnabled" :tooltipText="$t('label.demo_mode_inactive_button')">
        <v-btn
            text
            color="primary"
            @click="save"
            :disabled="loading || isDemoModeEnabled"
            :loading="loading"
        >
          {{ $t('btn.save') }}
        </v-btn>
      </DemoModeToolTipWrapper>
    </v-toolbar>
    <v-card-text>
      <ValidationObserver ref="form">
        <SettingsResponsiveRow
          :title="$t('settings.click_and_learn.api_key')"
          :description="$t('settings.click_and_learn.api_key_explanation')"
          :divider-after="false"
        >
          <template #description>
            <v-alert class="mt-1" border="left" colored-border type="info" dense elevation="1">
              <v-list-item-title>
                {{ $t('settings.click_and_learn.get_api_key') }}
              </v-list-item-title>
              <div class="text--secondary">{{ $t('settings.click_and_learn.get_api_key_explanation_intro') }}</div>
              <ol class="my-2">
                <li class="text--secondary">{{ $t('settings.click_and_learn.get_api_key_explanation_step1') }}</li>
                <li class="text--secondary">{{ $t('settings.click_and_learn.get_api_key_explanation_step2') }}</li>
                <li class="text--secondary">{{ $t('settings.click_and_learn.get_api_key_explanation_step3') }}</li>
              </ol>
              <div class="text--secondary">{{ $t('settings.click_and_learn.get_api_key_explanation_outro') }}</div>

              <DemoModeToolTipWrapper :disabled="!isDemoModeEnabled" :tooltipText="$t('label.demo_mode_inactive_button')">
                <v-btn class="mt-5 mb-2" color="primary" target="_blank" :href="clickAndLearnAdministrationPanelUrl" :disabled="isDemoModeEnabled">
                  <v-icon left>mdi-open-in-new</v-icon>
                  {{ $t('settings.click_and_learn.get_api_key_button') }}
                </v-btn>
              </DemoModeToolTipWrapper>
            </v-alert>
          </template>
          <ValidationObserver ref="form">
            <ValidationProvider name="apiKey" rules="required" v-slot="{ errors }">
              <v-text-field
                ref="apiKeyField"
                :disabled="hasApiKey"
                outlined
                dense
                :hide-details="!errors.length"
                :error="errors.length > 0"
                :error-messages="errors"
                v-model="localState.apiKey"
                :label="$t('settings.click_and_learn.api_key')"
              />
            </ValidationProvider>
          </ValidationObserver>
          <div class="mt-2 d-flex justify-space-between" v-if="hasApiKey">
            <DemoModeToolTipWrapper :disabled="!isDemoModeEnabled" :tooltipText="$t('label.demo_mode_inactive_button')">
              <v-btn color="blue" text @click="editMode" :disabled="isDemoModeEnabled">{{$t('btn.edit')}}</v-btn>
            </DemoModeToolTipWrapper>
            <DemoModeToolTipWrapper :disabled="!isDemoModeEnabled" :tooltipText="$t('label.demo_mode_inactive_button')">
              <v-btn color="red" text @click="deleteKey" :disabled="isDemoModeEnabled">{{$t('btn.delete')}}</v-btn>
            </DemoModeToolTipWrapper>
          </div>
        </SettingsResponsiveRow>
      </ValidationObserver>
    </v-card-text>
  </div>
</template>

<script>
import SettingsResponsiveRow from "@/components/settings/SettingsResponsiveRow.vue";
import DemoModeToolTipWrapper from '@/views/DemoModeToolTipWrapper.vue'
import schoolService from "@/services/schoolService";
import {showConfirm} from "@/helpers/dialogs";
import responsive from '@/utils/mixins/responsive'

export default {
  name: "ClickAndLearn",
  components: { SettingsResponsiveRow, DemoModeToolTipWrapper },
  mixins: [responsive],
  data() {
    return {
      localState: {
        apiKey: null
      },
      hasApiKey: false,
      changing: true,
      loading: false,
    };
  },
  computed: {
    isDemoModeEnabled () {
      return this.$store.state.school.isDemoModeEnabled
    },
    clickAndLearnAdministrationPanelUrl() {
      return process.env.VUE_APP_CLICK_AND_LEARN_ADMINISTRATION_PANEL_URL
    }
  },
  mounted() {
    this.localState = {
      apiKey: this.$store.state.school.clickAndLearnApiKey
    }
    this.hasApiKey = this.$store.state.school.clickAndLearnApiKey !== null
  },
  methods: {
    editMode() {
      this.hasApiKey = false
      this.localState.apiKey = null
      this.$refs.apiKeyField.$el.focus()
    },
    deleteKey() {
      showConfirm(this.$t('alert.are_you_sure'), this.$t('alert.api_key_delete_warning'), () => {
        this.loading = true

        schoolService.deleteClickAndLearnApiKey().then(() => {
          this.$store.commit("school/UPDATE_CLICKANDLEARN_API_KEY", null)
          this.localState.apiKey = null
          this.hasApiKey = false
          this.$snackbar.show({
            message: this.$t('label.deleted'),
            timeout: 3000,
            color: 'success'
          });
        }).finally(() =>{
          this.loading = false
        })
      })
    },
    save() {
      this.loading = true
      this.$refs.form.reset()
      schoolService
        .updateClickAndLearnApiKey(this.localState)
        .then((resp) => {
          const key = resp.data
          this.$store.commit("school/UPDATE_CLICKANDLEARN_API_KEY", key)
          this.hasApiKey = true
          this.localState.apiKey = key
          this.$snackbar.show({
            message: this.$t('messages.successfully_applied'),
            timeout: 3000,
            color: 'success'
          });
        })
        .catch(error => {
          const responseData = error.response.data;
          const errors = responseData.errors || [];

          this.$refs.form.setErrors(errors);

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error"});
          }
        })
        .finally(() => (this.loading = false));
    }
  }
}
</script>

<style scoped>

</style>
