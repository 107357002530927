<template>
<v-dialog
  v-model="show"
  width="700"
  @click:outside="close"
  @keydown.esc="close"
  :fullscreen="$vuetify.breakpoint.mdAndDown"
  scrollable
>
  <v-card tile>
    <v-toolbar class="mb-2 flex-grow-0" dark color="primary" dense>
      <v-toolbar-title>{{ $t('label.change_address') }}</v-toolbar-title>
      <v-spacer/>
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-title>
      <span>{{ $t('label.invoice_addresses') }}</span>

      <v-spacer/>

      <v-btn text color="primary" @click="openAddressFormDialog">
        {{ $t("btn.create") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <template v-if="loading">
        <v-skeleton-loader v-for="item in [1, 2]" :key="item" type="list-item-two-line"/>
      </template>

      <v-list v-else two-line>
        <v-list-item-group v-model="selectedAddressId">
          <template v-for="(item, index) in addresses">
            <v-list-item :key="item.title" :value="item.id" @dblclick="select(item.id)">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>

                <v-list-item-subtitle>
                  <template v-if="item.id !== null">{{ `${item.clientFirstName} ${item.clientLastName}, ` }}</template>
                  {{ `${item.street} ${item.houseNr}, ${item.postcode} ${item.city}`}}<span v-if="item.country">, {{ item.country }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action v-if="item.isDefault">
                <span class="text-uppercase text--secondary">{{ $t('label.default') }}</span>
              </v-list-item-action>
            </v-list-item>

            <v-divider v-if="index < item.length - 1" :key="index"/>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card-text>

    <v-divider class="mt-auto" />

    <v-card-actions>
      <v-btn text @click="close">{{$t("btn.close")}}</v-btn>

      <v-spacer />

      <v-checkbox v-if="! studentless" v-model="setAsDefault" class="mt-0" hide-details :label="$t('btn.set_default')"/>

      <v-btn
        class="ml-2"
        color="primary"
        @click="select()"
        :loading="savingDefaultInvoiceAddress"
        :disabled="savingDefaultInvoiceAddress"
      >
        {{ $t("btn.select") }}
      </v-btn>
    </v-card-actions>
  </v-card>

  <AddressFormDialog ref="addressFormDialog" disable-types/>
</v-dialog>
</template>

<script>
import studentsService from '@/services/studentsService'
import AddressFormDialog from '@/components/AddressFormDialog.vue'
import invoiceAddressesService from '@/services/invoiceAddressesService'
import StudentModel from '@/store/models/StudentModel'
import InvoiceAddressesModel from '@/store/models/InvoiceAddressesModel'

export default {
  name: 'InvoiceAddressListDialog',
  components: { AddressFormDialog },
  props: {
    studentless: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      loading: true,
      show: false,
      savingDefaultInvoiceAddress: false,
      selectedAddressId: null,
      studentId: null,
      setAsDefault: false
    }
  },
  computed: {
    student() {
      return StudentModel.find(this.studentId)
    },
    invoiceAddresses() {
      return InvoiceAddressesModel.all()
    },
    addresses () {
      const addresses = []
      if (this.student) {
        addresses.push(this.student.invoiceAddress)
      }
      return [...addresses, ...this.invoiceAddresses].map(item => {
        return { ...item, isDefault: item.id === this.student?.invoiceAddressId }
      })
    },
  },
  methods: {
    open(id = null, studentId = null) {
      this.show = true
      this.selectedAddressId = id
      this.studentId = studentId

      this.loading = true
      invoiceAddressesService
        .load()
        .then(response => {
          InvoiceAddressesModel.create({ data: response.data || [] })
        })
        .finally(() => {
          this.loading = false
        })
    },
    close() {
      this.show = false

      setTimeout(() => {
        this.selectedAddressId = null
        this.studentId = null
        this.setAsDefault = false
      }, 200)
    },
    select(id = null) {
      console.log(id ?? this.selectedAddressId)
      const address = this.addresses.find(item => item.id === (id ?? this.selectedAddressId))

      if (this.setAsDefault) {
        this.savingDefaultInvoiceAddress = true
        studentsService
          .setDefaultInvoiceAddress(this.studentId, { invoice_address_id: this.selectedAddressId })
          .then(() => {
            StudentModel.update({ where: this.studentId, data: { invoiceAddressId: this.selectedAddressId }})

            this.$emit('selected', address)
            this.close()
          })
          .finally(() => {
            this.savingDefaultInvoiceAddress = false
          })
      } else {
        this.$emit('selected', address)
        this.close()
      }
    },
    openAddressFormDialog() {
      this.$refs.addressFormDialog.open('invoiceAddress')
    }
  }
}
</script>

<style scoped>

</style>
