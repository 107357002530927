export default {
  methods: {
    getUsersFiltersOptionsWithHeaders(items) {
      const active = this.sortItemsByFullName(items.filter(item => item.active && item.deletedAt === null));
      const inActive = this.sortItemsByFullName(items.filter(item => !item.active && item.deletedAt === null));
      const deleted = this.sortItemsByFullName(items.filter(item => item.deletedAt !== null));

      let withHeaders = [];
      if (active.length > 0) {
        withHeaders = [
          { header: this.$t("label.active") },
          { divider: true },
          ...active
        ];
      }
      if (inActive.length > 0) {
        withHeaders = [
          ...withHeaders,
          { header: this.$t("label.inactive") },
          { divider: true },
          ...inActive
        ];
      }
      if (deleted.length > 0) {
        withHeaders = [
          ...withHeaders,
          { header: this.$t("label.deleted") },
          { divider: true },
          ...deleted
        ];
      }

      return withHeaders;
    },
    sortItemsByFullName(items = []) {
      return items.sort((b, a) => {
        if (a.fullName < b.fullName) return 1;
        if (a.fullName > b.fullName) return -1;

        return 0;
      });
    }
  }
};
