import Vue from "vue";
import ChatRoom from "@/store/models/ChatRoom";
import * as mutationTypes from "./../mutation-types/messenger";

const state = () => ({
  unseenMessagesCount: 0,
  isSideBarMini: JSON.parse(localStorage.getItem("isSideBarMini")) || false,
  isSearchParticipantsOpen: false,
  onlineParticipantsIds: [],
});

const getters = {
  schoolRoom: (state, getters, rootState) => {
    return (
      ChatRoom.query()
        .with("lastMessage")
        .where("schoolId", rootState.school.id)
        .first() || {}
    );
  },
};

const actions = {
  toggleSideBar: ({ commit, state }) => {
    const isSideBarMini = state.isSideBarMini;

    localStorage.setItem("isSideBarMini", !isSideBarMini);
    commit(mutationTypes.SET_SIDEBAR, !isSideBarMini);
  },
  isOnline: ({ commit }, userId) => {
    commit(mutationTypes.SET_ONLINE, userId);
  },
  isOffline: ({ commit, state }, userId) => {
    const onlineIds = [...state.onlineParticipantsIds];

    onlineIds.splice(state.onlineParticipantsIds.indexOf(userId), 1);
    commit(mutationTypes.SET_OFFLINE, onlineIds);
  },
  selectActivePartner({ commit }, { isSchool = false, partnerId = "" }) {
    const activePartner = { isSchool, partnerId };
    commit(mutationTypes.SELECT_ACTIVE_PARTNER, activePartner);
  },
  deselectPartner({ commit }) {
    commit(mutationTypes.DESELECT_ACTIVE_PARTNER);
  },
};

const mutations = {
  [mutationTypes.SET_SIDEBAR]: (state, value) => {
    state.isSideBarMini = value;
  },
  [mutationTypes.SET_ONLINE]: (state, userId) => {
    if (!state.onlineParticipantsIds.includes(userId)) {
      Vue.set(state, "onlineParticipantsIds", [
        ...state.onlineParticipantsIds,
        userId,
      ]);
    }
  },
  [mutationTypes.SET_OFFLINE]: (state, onlineIds) => {
    Vue.set(state, "onlineParticipantsIds", onlineIds);
  },
  [mutationTypes.SELECT_ACTIVE_PARTNER]: (state, activePartner) => {
    Vue.set(state, "activePartner", activePartner);
  },
  [mutationTypes.DESELECT_ACTIVE_PARTNER]: (state) => {
    Vue.set(state, "activePartner", null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
