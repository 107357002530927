import i18n from "@/plugins/i18n";
import AppointmentNotification from '@/notifications/events/appointmentNotification'

export default class SimulatorUnsubscribedNotification extends AppointmentNotification {
  icon() {
    return "mdi-calendar-minus";
  }

  wildCardsData() {
    const data = this.notification.data;
    const eventType = i18n.t(`event_type.${data.type}`);

    return {
      title: {
        eventType: eventType,
      },
      message: {
        studentName: data.studentName,
        eventType: eventType,
      },
    };
  }
}
