<template>
  <v-container>
    <v-card :loading="loading">
      <v-toolbar elevation="3" :dark="!loading" :color="ticket.color">
        <v-btn icon :to="{ name: 'support' }" class="ml-0" exact>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-card-title class="text-overflow-ellipsis">
          <v-skeleton-loader
            width="150"
            height="12"
            type="text@1"
            v-if="loading" />
          <span class="mr-2 text-overflow-ellipsis" v-else>#{{ ticket.id }}</span>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="closeTicket"
              :loading="closingTicket"
              :disabled="closingTicket || ticket.status === 'closed'">
              <v-icon>mdi-comment-check-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("btn.close_ticket") }}</span>
        </v-tooltip>
        <v-btn
          text
          class="ml-2"
          v-if="ticket.acceptsReply"
          @click="$refs.replyModal.open()">
          <v-icon left>mdi-reply</v-icon>
          {{ $t("btn.reply_ticket") }}
        </v-btn>
      </v-toolbar>

      <SupportTicketContentLoader v-if="loading" />

      <template v-else>
        <v-card-title>
          {{ ticket.topic }}
          <v-spacer />
          <v-chip-group>
            <v-chip label small :color="ticket.color" dark>
              {{$t(`ticket_status.${ticket.status}`)}}
            </v-chip>
            <v-chip label small >
              {{$t(`ticket_type.${ticket.type}`)}}
            </v-chip>
          </v-chip-group>
        </v-card-title>
        <v-card-subtitle>
          {{ $t("label.last_updated") }} {{ ticket.updated }}
        </v-card-subtitle>
        <v-divider />
        <v-card-text>
          <v-alert
            icon="mdi-exclamation"
            outlined
            v-if="ticket.status === 'awaiting_reply'"
            :color="ticket.color">
            {{ $t("label.waiting_for_your_reply") }}</v-alert
          >
          <TicketMessage
            v-for="message in ticket.messages"
            :key="message.id"
            :message="message" />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            text
            @click="closeTicket"
            :loading="closingTicket"
            :disabled="closingTicket || ticket.status === 'closed'">
            <v-icon left>mdi-comment-check-outline</v-icon>
            {{ $t("btn.close_ticket") }}
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
    <TicketReplyModal
      v-if="!loading"
      :ticket="ticket.id"
      ref="replyModal"
      @reply="handleNewReply" />
  </v-container>
</template>

<script>
import supportTicketsService from "@/services/supportTicketsService";
import SupportTicketContentLoader from "@/components/skeleton-loaders/SupportTicketContentLoader";
import TicketReplyModal from "@/components/support/TicketReplyModal";
import TicketMessage from "@/components/support/TicketMessage";
import laravelEcho from "@/plugins/laravel-echo";
import {mapGetters} from "vuex";

export default {
  name: "ViewTicket",
  components: { TicketMessage, TicketReplyModal, SupportTicketContentLoader },
  data() {
    return {
      loading: true,
      closingTicket: false,
      ticket: {},
    };
  },
  watch: {
    '$route': function() {
      this.fetch(this.$route.params.ticket)
    }
  },
  mounted() {
    const ticket = this.$route.params.ticket;
    this.fetch(ticket)
    laravelEcho
        .private("user." + this.currentUser.userId)
        .listen("TicketStatusChangedEvent", ({ticket}) => {
          if (ticket.id === this.$route.params.ticket) {
            this.fetch(ticket.id)
          }
        });
  },
  beforeDestroy() {
    laravelEcho.leave("user." + this.currentUser.userId)
  },
  computed: mapGetters("auth", ["currentUser"]),
  methods: {
    fetch(id) {
      supportTicketsService
          .getTicket(id)
          .then((resp) => {
            this.ticket = resp.data;
          })
          .catch(({ message }) => {
            this.$snackbar.show({ message, timeout: 3000, color: "red" });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    handleNewReply(ticket) {
      this.ticket = ticket;
    },
    closeTicket() {
      this.closingTicket = true;
      supportTicketsService
        .close(this.ticket.id)
        .then((resp) => {
          this.ticket = resp.data;
        })
        .catch(console.log)
        .finally(() => (this.closingTicket = false));
    },
  },
};
</script>

<style scoped lang="scss">
.preline {
  white-space: pre-line;
}
</style>
