<template>
<v-list subheader dense>
  <v-subheader>{{$t('label.other_accounts')}}</v-subheader>

  <v-list-group v-for="item in otherAccounts" :key="item.id" no-action @click.stop.native>
    <template v-slot:activator>
      <v-list-item-avatar>
        <UserAvatar size="32" :path="item.avatar" :alt="item.name" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
        <v-list-item-subtitle>{{ $t(`user_type.${item.type}`) }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>

    <v-list-item @click="switchTo(item)" :disabled="switching">
      <v-list-item-title>{{$t('label.switch_to')}}</v-list-item-title>
      <v-list-item-icon><v-icon>mdi-account-switch</v-icon></v-list-item-icon>
    </v-list-item>

    <v-list-item @click="accountLogout(item)" :disabled="switching">
      <v-list-item-title>{{ $t('menu.logout') }}</v-list-item-title>
      <v-list-item-icon><v-icon>mdi-account-remove</v-icon></v-list-item-icon>
    </v-list-item>
  </v-list-group>

  <v-list-item
    dense
    v-if="isSchoolAdministrator"
    :disabled="!currentUser.active"
    @click="showAddAccountDialog"
    @click.stop.native
  >
    <v-list-item-icon style="margin-right: 15px">
      <v-icon size="20">mdi-account-plus-outline</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ $t("btn.add") }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>

  <AddAccountDialog ref="addAccountDialog" @success="addAccount"/>
</v-list>
</template>

<script>
import user from '@/utils/mixins/user'
import AddAccountDialog from '@/components/AddAccountDialog.vue'
import UserAvatar from '@/components/UserAvatar.vue'
import otherAccountsService from '@/services/otherAccountsService'

export default {
  name: 'OtherAccountsList',
  components: { UserAvatar, AddAccountDialog },
  mixins: [user],
  data() {
    return {
      switching: false,
      otherAccounts: JSON.parse(localStorage.getItem('otherAccounts')) || []
    }
  },
  methods: {
    switchTo(account) {
      this.switching = true
      this.menu = false

      const accounts = [...this.otherAccounts]

      accounts.unshift({
        id: this.currentUser.userId,
        avatar: this.currentUser.avatar,
        name: this.currentUser.fullName,
        type: this.currentUser.type,
        accessToken: localStorage.getItem('accessToken')
      })

      localStorage.setItem('accessToken', account.accessToken)
      localStorage.setItem('otherAccounts', JSON.stringify(this.removeAccount(accounts, account)))

      this.$nextTick(() => {
        location.reload();
      })
    },
    showAddAccountDialog() {
      this.$refs.addAccountDialog.open()
    },
    accountLogout(account) {
      otherAccountsService
        .logout({ userId: account.id, accessToken: account.accessToken })
        .then(() => {
          this.otherAccounts = this.removeAccount(this.otherAccounts, account)
          localStorage.setItem('otherAccounts', JSON.stringify(this.otherAccounts))
        })
    },
    removeAccount(accounts, account) {
      const accountIndex = accounts.findIndex(item => item.id === account.id)

      if (accountIndex !== -1) accounts.splice(accountIndex, 1)

      return accounts
    },
    addAccount(account) {
      const { user } = account
      const existingAccountIndex = this.otherAccounts.findIndex(item => item.id === user.id)

      if (existingAccountIndex !== -1) {
        this.otherAccounts[existingAccountIndex] = user
      } else {
        this.otherAccounts.push(user)
      }

      localStorage.setItem('otherAccounts', JSON.stringify(this.otherAccounts))
    }
  }
}
</script>

<style scoped>

</style>