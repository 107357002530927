import { Model } from "@vuex-orm/core";

export default class HappyHoursModel extends Model {
  static entity = "happy-hours";

  static fields() {
    return {
      id: this.attr(null),
      licenseId: this.attr(null),
      lessonTypeId: this.attr(null),
      days: this.attr(null),
      timeFrom: this.attr(null),
      timeTo: this.attr(null),
      discount: this.string(0),
    };
  }

  get daysArr() {
    return this.days.split(",");
  }
}
