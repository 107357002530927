<template>
  <v-container>
    <v-card :loading="loading">
      <v-toolbar elevation="3">
        <v-card-title>{{ $t("label.available_insights_templates") }}</v-card-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <v-skeleton-loader type="paragraph, line, line" v-if="loading" />
        <empty-box v-else-if="! templates.length" />
        <template v-else>
          <p>{{ $t('label.predefined_template') }}</p>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="4" v-for="template in templates" :key="template.id">
              <insights-template-card
                :template="template"
                v-ripple
                @click.native="$router.push({name: 'insights.template', params: { template: template.id }})" />
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import insightsService from "@/services/insightsService";
import TemplateModel from "@/store/models/insights/TemplateModel";
import InsightsTemplateCard from "@/components/insights/InsightsTemplateCard.vue";

export default {
  name: "InsightsIndex",
  components: {InsightsTemplateCard},
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    templates () {
      return TemplateModel.query().with(['categories']).all();
    }
  },
  mounted() {
    insightsService.listTemplates().then(resp => {
      TemplateModel.create({data: resp.data})
    }).catch(err => {
      alert('There was an error loading the templates. Try again later.')
      console.log(err)
    }).finally(() => {
      this.loading = false
    })
  }
}
</script>

<style scoped lang="scss">
.hidden-until-row-hovered {
  display: none;
}
.table-row:hover > td > .hidden-until-row-hovered {
  display: inline-flex;
}
</style>
