<script>
import { showConfirm } from '@/helpers/dialogs'
import user from '@/utils/mixins/user'
import insightsService from "@/services/insightsService";
import TemplateRenderer from '@/components/insights/TemplateRenderer.vue'
import EvaluationsScoreLegend from '@/components/insights/EvaluationsScoreLegend.vue'
import EvaluationModel from "@/store/models/insights/EvaluationModel";
import EvaluationItemModel from '@/store/models/insights/EvaluationItemModel'

export default {
  name: "AppointmentEvaluationResult",
  components: {TemplateRenderer,  EvaluationsScoreLegend },
  mixins: [user],
  props: {
    appointmentId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      templateLoading: true,
      hasEvaluation: false,
      publishEvaluationLoading: false,
      checkAppointmentEvaluationLoading: true,
      loadingEvaluation: true
    }
  },
  created () {
    EvaluationModel.deleteAll()
    EvaluationItemModel.deleteAll()
  },
  mounted() {
    insightsService
      .checkAppointmentEvaluation(this.appointmentId)
      .then(resp => {
        const { evaluation } = resp.data
        if (evaluation) {
          this.hasEvaluation = true;
          this.getEvaluation()
        }
      })
      .finally(() => {
        this.checkAppointmentEvaluationLoading = false;
        this.loadingEvaluation = false
      })
  },
  computed: {
    loading() {
      return this.checkAppointmentEvaluationLoading || this.loadingEvaluation
        || this.publishEvaluationLoading || (this.hasEvaluation && this.templateLoading)
    },
    evaluation() {
      return EvaluationModel.query().with(['items', 'items.category']).where('appointmentId', this.appointmentId).first()
    },
    isSchoolOrTeacher() {
      return this.isSchoolAdministrator || this.isTeacher
    },
    showEvaluation() {
      return this.isSchoolOrTeacher && this.evaluation?.items?.length > 0
    },
    showForStudent() {
      return !this.isSchoolOrTeacher && this.evaluation?.isPublished &&
        (!this.evaluation?.selfEvaluation || this.evaluation?.isPublishedByStudent)
    },
    isAwaitingStudentEvaluation() {
      return !this.isSchoolOrTeacher
        && this.evaluation?.selfEvaluation
        && this.evaluation?.isPublished
        && !this.evaluation?.isPublishedByStudent
    },
    showDraftAlert() {
      return this.evaluation
        && !this.evaluation?.isPublished
        && this.isSchoolOrTeacher
    },
    showEmptyBox() {
      return (this.isSchoolOrTeacher && (!this.evaluation || !this.evaluation?.items.length === 0))
        || (!this.isSchoolOrTeacher && !this.evaluation?.isPublished)
    }
  },
  methods: {
    getEvaluation() {
      insightsService.getAppointmentEvaluation(this.appointmentId)
        .then((response) => {
          const data = response.data
          EvaluationModel.create({ data })
          EvaluationItemModel.create({ data: data.items })
        })
    },

    handlePublish() {
      if (this.evaluation?.selfEvaluation) {
        showConfirm(
          this.$t('btn.confirm'),
          this.$t('insights.publish_evaluation_for_self_evaluation_confirm'),
          async () => this.publish());
      } else {
        this.publish()
      }

    },

    publish() {
      this.publishEvaluationLoading = true
      insightsService.publishAppointmentEvaluation(this.appointmentId, { comment: this.evaluation.comment })
        .then(() => {
          EvaluationModel.update({
            where: item => item.appointmentId === this.appointmentId,
            data: { isPublished: true },
          })
        })
        .catch(error => {
          const response = error.response || {};
          const responseData = response.data || {};

          if (responseData.message) this.$snackbar.show({ message: responseData.message, color: "error", });
        })
        .finally(() => {
          this.publishEvaluationLoading = false;
        })
    },

    async editEvaluation() {
      if (this.evaluation && this.evaluation.isPublished) {
        await showConfirm(
          this.$t('btn.confirm'),
          this.$t('insights.edit_evaluation_confirm'),
          async () => {
            try {
              await insightsService
                .withdrawAppointmentEvaluation(this.appointmentId)
                .then(() => {
                  this.$router.push({ name: 'calendar.appointment.evaluation' })
                })
            } catch (error) {
              const response = error.response || {};
              const responseData = response.data || {};

              if (responseData.message) await this.$snackbar.show({ message: responseData.message, color: "error", });
            }
        })
      } else {
        await this.$router.push({ name: 'calendar.appointment.evaluation' })
      }
    },
    evaluateAsStudent() {
      if (this.isElter) return
      this.$router.push({ name: 'calendar.appointment.evaluation' })
    },
    deleteAppointmentEvaluation() {
      showConfirm(
        this.$t('btn.confirm'),
        this.$t('insights.delete_evaluation_confirm'),
        async () => {
          this.publishEvaluationLoading = true
          try {
            await insightsService.deleteAppointmentEvaluation(this.appointmentId)
              .then(() => EvaluationModel.delete(this.evaluation.id))
              .finally(() => this.hasEvaluation = false)

          } catch (error) {
            const response = error.response || {};
            const responseData = response.data || {};

            if (responseData.message) this.$snackbar.show({ message: responseData.message, color: "error", });
          }
          this.publishEvaluationLoading = false
        }
      )
    },
    handleTemplateLoading(val) {
      this.templateLoading = val
    }

  }
}
</script>

<template>
  <v-card elevation="3" class="mt-3">
    <v-toolbar elevation="3">
      <v-card-title>
        {{ $t('insights.feature_name') }}
      </v-card-title>
      <v-spacer/>

      <v-menu v-if="hasEvaluation && isSchoolOrTeacher"  offset-y left nudge-bottom="5" :loading="loading" :disabled="loading">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-2" icon large v-bind="attrs" v-on="on" :disabled="loading">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-sheet>
          <v-list dense>
            <v-list-item v-if="!evaluation?.isPublished && evaluation?.items.length" @click="handlePublish" color="primary">
              <v-list-item-icon class="mr-2">
                <v-icon left>mdi-content-save</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('insights.btn.publish_evaluation') }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="!evaluation?.selfEvaluation || (evaluation?.selfEvaluation && !evaluation?.isPublished)"
              @click="editEvaluation"
              color="primary"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('insights.btn.edit_evaluation') }}</v-list-item-title>
            </v-list-item>

            <v-list-item @click="deleteAppointmentEvaluation" color="primary">
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('insights.btn.delete_evaluation') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-menu>
    </v-toolbar>

    <v-card-text v-if="loading" class="d-flex justify-center py-10">
      <v-progress-circular indeterminate size="64" />
    </v-card-text>

    <v-card-text v-show="!loading">
      <v-alert v-if="showDraftAlert" type="warning" elevation="1">
        <div class="text-h6">{{ $t('insights.draft_title') }}</div>
        <p>{{ $t('insights.draft_text') }}</p>
      </v-alert>

      <template v-if="hasEvaluation && !loadingEvaluation">
        <v-sheet v-if="evaluation?.comment && (showForStudent || isSchoolOrTeacher)" border="left" class="mb-3 pa-3 pre-wrap" dense elevation="1">
          {{ $t('insights.comment') }}: {{ evaluation.comment }}
        </v-sheet>
        <v-sheet v-if="evaluation?.drivingArea && (showForStudent || isSchoolOrTeacher)" border="left" class="mb-3 pa-3 pre-wrap" dense elevation="1">
          {{ $t("label.driving_area") }}: {{ evaluation.drivingArea }}
        </v-sheet>

        <EvaluationsScoreLegend v-if="evaluation?.selfEvaluation && (showEvaluation || showForStudent)" class="mb-1" />

        <template-renderer
          v-show="showEvaluation || showForStudent"
          tile
          expanded
          :template-loaded="true"
          :show-description="false"
          :show-toolbar="false"
          :template-id="evaluation.templateId"
          :evaluation="evaluation"
          @templateLoader="handleTemplateLoading"
        />
      </template>

      <template v-if="isAwaitingStudentEvaluation">
        <empty-box image="assets/img/evaluation_missing.png" :text="$t('insights.awaiting_your_evaluation')" />
        <div class="d-flex justify-center">
          <v-btn :disabled="isElter" class="ma-auto" color="secondary" @click="evaluateAsStudent">{{ $t('insights.btn.evaluate') }}</v-btn>
        </div>
      </template>
      <template v-if="showEmptyBox">
        <empty-box image="assets/img/evaluation_missing.png" :text="$t('insights.no_evaluation_yet')" />
        <div v-if="isSchoolOrTeacher" class="d-flex justify-center">
          <v-btn class="ma-auto" color="secondary" @click="editEvaluation">{{ $t('insights.btn.evaluate') }}</v-btn>
        </div>
      </template>
    </v-card-text>

  </v-card>
</template>

