<template>
<v-list-item-content>
  <v-list-item-title class="d-flex flex-column white--text">{{ title }}</v-list-item-title>
  <v-list-item-subtitle class="caption" v-if="$vuetify.breakpoint.mobile && subtitle">{{ subtitle }}</v-list-item-subtitle>
</v-list-item-content>
</template>

<script>
export default {
  name: 'SidebarListItemContent',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: undefined
    },
  }
}
</script>

<style scoped>

</style>