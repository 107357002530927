<template>
  <v-sheet
    class="pa-3 mt-2 d-flex align-center justify-space-between"
    color="white"
    elevation="1"
    width="100%"
    rounded>
    <div class="d-flex align-center">
      <v-icon size="30" class="mr-3" color="primary">$vaccine</v-icon>
      <h5>{{ $t("label.vaccination_status") }}</h5>
    </div>
    <div>
      <a
        class="check-certificate"
        v-if="document.file"
        :href="document.file"
        download
        target="_blank">
        <v-icon class="mr-3">mdi-certificate</v-icon>
      </a>
      <v-icon @click="approveVaccination" :disabled="student.deletedAt">
        {{
          student.vaccinationStatus === "APPROVED"
            ? "mdi-checkbox-marked-outline"
            : "mdi-checkbox-blank-outline"
        }}
      </v-icon>
    </div>
  </v-sheet>
</template>

<script>
import studentsService from "@/services/studentsService";
import StudentModel from "@/store/models/StudentModel";
import {showConfirm} from "@/helpers/dialogs";

export default {
  name: "VaccinationWidget",
  data: () => ({
    document: {},
  }),
  props: {
    studentId: {
      type: Number,
      require: true,
    },
  },
  mounted() {
    this.document = {};
    studentsService.documents(this.studentId).then((documentResponse) => {
      this.document =
        documentResponse.data.find(
          (doc) => doc.type === "VACCINATION_CERTIFICATE"
        ) || {};
    });
  },
  computed: {
    student() {
      return StudentModel.find(this.studentId) || {};
    },
    vaccinationApproved() {
      return this.student.vaccinationStatus === "APPROVED";
    },
  },
  methods: {
    approveVaccination() {
      if (this.vaccinationApproved) return;

      showConfirm(
          this.$i18n.t("alert.are_you_sure"),
          this.$i18n.t("label.school_approves_vaccination_status"),
          () => {
            studentsService.setVaccinationStatus(this.student.id).then(() =>
                StudentModel.update({
                  where: this.student.id,
                  data: { vaccinationStatus: "APPROVED" },
                })
            );
          }
      );
    },
  },
};
</script>

<style scoped>
.check-certificate {
  text-decoration: none !important;
}
</style>
