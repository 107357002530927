<script>
import EvaluationModel from '@/store/models/insights/EvaluationModel'
import EvaluationItemModel from '@/store/models/insights/EvaluationItemModel'
import insightsService from '@/services/insightsService'
import InsightsIcon from '@/components/insights/InsightsIcon.vue'
import { showConfirm } from '@/helpers/dialogs'
import user from '@/utils/mixins/user'

export default {
  name: 'EvaluationItem',
  mixins: [user],
  components: { InsightsIcon },
  data: () => ({
    loading: false,
    commentDialogState: false,
    commentModel: null,
  }),
  props: {
    item: {
      type: Object,
      required: true
    },
    showItemName: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    evaluationModel() {
      return EvaluationModel.query().where('appointmentId', this.appointmentId).first()
    },
    evaluationItemModel() {
      const evaluationId = this.evaluationModel?.id
      return EvaluationItemModel.query().where('evaluationId', evaluationId).where('itemId', this.item.id).first()
    },
    score() {
      return this.evaluationItemModel?.score || 0
    },
    isFinal() {
      return this.evaluationItemModel?.isFinal
    },
    comment() {
      return this.evaluationItemModel?.comment
    },
    isEvaluated() {
      return this.score > 0;
    },
    hasComment() {
      return this.comment !== null && this.comment !== undefined
    },
    appointmentId() {
      return parseInt(this.$route.params.id)
    },
    ratingColor() {
      const colorByClassName = {
        bad: 'error',
        average: 'warning',
        good: 'secondary'
      }

      return (this.ratingClassName in colorByClassName) ? colorByClassName[this.ratingClassName] : 'grey lighten-2'
    },
    ratingClassName() {
      if (this.score === 5) return 'good';
      if (this.score >= 3 && this.score <= 4) return 'average';
      if (this.score >= 1 && this.score <= 2) return 'bad';
      return ''
    },
    commentChanged() {
      return this.commentModel !== this.comment
    }
  },
  methods: {
    removeEvaluationItem() {
      this.loading = true

      insightsService.removeItemFromAppointmentEvaluation(this.appointmentId, this.item.id)
        .then(() => EvaluationItemModel.delete([this.item.id, this.evaluationModel?.id]))
        .catch(error => {
          const response = error.response || {};
          const responseData = response.data || {};

          if (responseData.message) this.$snackbar.show({ message: responseData.message, color: "error", });
        })
        .finally(() => this.loading = false)
    },

    rate(newScore, isFinal = null, comment = undefined) {
      if (this.loading) return

      if (newScore === this.score && isFinal === null) newScore = 0 // >>>>>>>>>>>>>>>>
      if (isFinal === null) isFinal = this.isFinal
      if (comment === undefined) comment = this.comment

      // if score  = 0 delete the rating
      if (!this.isStudent && this.score !== 0 && newScore === 0) {
        if (comment) {
          showConfirm(
            this.$t('btn.confirm'),
            this.$t('messages.delete_evaluation_comment'),
            () => this.removeEvaluationItem()
          )
          return
        }
        this.removeEvaluationItem()
        return
      }

      const payload = {
        itemId: this.item.id,
        score: newScore,
        isFinal,
        comment
      }

      this.loading = true

      // if curren score = 0 item has not been rated
      if (!this.isStudent && this.score === 0) {
        insightsService.addItemToAppointmentEvaluation(this.appointmentId, payload)
          .then(() => EvaluationItemModel.insert({data: {evaluationId: this.evaluationModel?.id, ...payload}}))
          .catch(error => {
            const response = error.response || {};
            const responseData = response.data || {};

            if (responseData.message) this.$snackbar.show({ message: responseData.message, color: "error", });
          })
          .finally(() => this.loading = false)
        return
      }
      // if current score !=  and is different from score, update the rating
      if (this.isStudent ||
        (this.score !== 0 && (this.score !== newScore || isFinal !== this.isFinal || this.comment !== comment))) {
        insightsService.editItemInAppointmentEvaluation(this.appointmentId, this.item.id, payload)
          .then(() => {
            EvaluationItemModel.update({
              where: (item) => {
                return item.itemId === this.item.id && item.evaluationId === this.evaluationModel?.id
              }, data: payload
            })
            this.commentDialogState = false
          })
          .catch(error => {
            const response = error.response || {};
            const responseData = response.data || {};

            if (responseData.message) this.$snackbar.show({ message: responseData.message, color: "error", });
          })
          .finally(() => this.loading = false)
        return
      }

      this.loading = this.commentDialogState = false
    },
    toggleFinalState() {
      this.rate(this.score === 0 ? 5 : this.score, !this.isFinal)
    },
    showItemCommentDialog() {
      this.commentModel = this.comment
      this.commentDialogState = true;
    },
    removeExistingComment() {
      if (! this.hasComment) return;

      showConfirm(
        this.$t('btn.confirm'),
        this.$t('messages.delete_evaluation_comment'),
        () => this.rate(this.score, this.isFinal, null)
      )
    },
    saveComment () {
      this.rate(this.score, this.isFinal, this.commentModel)
    }
  }
}
</script>

<template>
  <div class="d-flex flex-row evaluation-item" :class="{[`is-evaluated ${ratingClassName}`]: isEvaluated}">
    <v-progress-linear class="evaluation-item-loader" indeterminate height="1" style="" v-if="loading" />

    <div class="d-flex flex-row pa-1 flex-shrink-1 flex-grow-1 align-center">
      <InsightsIcon :icon="item.icon" :alt="item.name" min-width="64" :width="64" :height="64"/>
      <div class="d-flex flex-column flex-shrink-1 flex-grow-1 justify-space-between fill-height">
        <div v-if="showItemName">
          <span class="word-breaker">{{ item.name }}<span v-if="item.description"> - {{ item.description }}</span></span>
        </div>
        <div v-else>
          <span v-if="item.description" class="word-breaker">{{ item.description }}</span>
          <span v-else>{{item.name}} {{ hasComment }}</span>
        </div>
        <v-rating
          :background-color="isEvaluated ? 'grey lighten-1' : 'grey lighten-2'"
          v-model="score"
          dense
          clearable
          :readonly="loading"
          empty-icon="mdi-steering"
          full-icon="mdi-steering"
        >
          <template v-slot:item="props">
            <v-icon @click="rate(props.index + 1)" :color="props.isFilled ? ratingColor : 'grey lighten-2'">
              mdi-steering
            </v-icon>
          </template>
        </v-rating>
      </div>
    </div>

    <div v-if="!isStudent" class="d-flex flex-column actions">
      <div
        :class="{'active': isFinal}"
        class="flex-grow-1 flex-shrink-1 button d-flex align-center justify-center"
        @click="toggleFinalState"
        v-ripple
      >
        <v-icon class="icon mark-as-final">{{ isFinal ? 'mdi-check-decagram' : 'mdi-check-decagram-outline' }}</v-icon>
      </div>
      <div
        :class="{'active': hasComment}"
        class="flex-grow-1 flex-shrink-1 button d-flex align-center justify-center"
        @click="showItemCommentDialog"
        v-ripple
        v-if="isEvaluated"
      >
        <v-icon class="icon notes">{{ hasComment ? 'mdi-note-edit' : 'mdi-note-plus-outline' }}</v-icon>
      </div>
    </div>
    <v-dialog v-model="commentDialogState" width="600px">
      <v-card :loading="loading">
        <v-toolbar color="primary" dark>
          <v-card-title>
            {{ $t('insights.comment') }}
          </v-card-title>
          <v-spacer />
          <v-btn @click="commentDialogState = false" icon><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-textarea v-model="commentModel" solo hide-details />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn color="primary" :disabled="loading" text @click="commentDialogState = false">
            {{ $t('btn.close') }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn v-if="hasComment" :disabled="loading" color="error" @click="removeExistingComment">
            <v-icon left>mdi-eraser</v-icon>
            <span v-if="!$vuetify.breakpoint.mobile">{{ $t('btn.remove') }}</span>
          </v-btn>

          <v-btn color="primary" :disabled="loading || !commentModel || !commentChanged" @click="saveComment">
            <v-icon left>mdi-content-save</v-icon>
            <span v-if="!$vuetify.breakpoint.mobile">{{ $t('btn.save') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.word-breaker {
  -ms-word-break: break-all;
  -webkit-word-break: break-word;
  -webkit-word-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
.evaluation-item-loader {
  position: absolute;
  left: 0;
  top: 0;
}
.evaluation-item {
  border-radius: 5px;
  border: solid 1px #ccc;
  background: #fff;
  font-size: 1rem;
  transition: 1s;
  position: relative;
}
.is-evaluated {
  &.bad {
    background-color: rgba(255, 188, 188, 0.05) !important;
  }
  &.average {
    background-color: rgba(245, 210, 108, 0.05);
  }
  &.good {
    background-color: rgba(155, 238, 155, 0.05) !important;
  }
}

.actions {
  min-width: 30px;
  width: 30px;
  border-left: solid 1px #ccc;

  & > .button {
    transition: .5s;
    cursor: pointer;
    opacity: 0.5;
    border-bottom: solid 1px #ccc;
    &:last-child {
      border-bottom: none;
    }

    &.active {
      opacity: 1;
      & > .icon {
        &.mark-as-final {
          color: var(--v-secondary-base);
        }
        &.notes {
          color: var(--v-warning-base);
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        opacity: 1;

        & > .icon {
          &.mark-as-final {
            color: var(--v-secondary-base);
          }
          &.notes {
            color: var(--v-warning-base);
          }
        }
      }
    }
  }
}
</style>
