import i18n from "@/plugins/i18n";
import { format, sub } from "date-fns";

export default {
  data() {
    return {
      rangeFilter: {
        selectOptions: [
          { text: i18n.t("label.last_7_days"), value: 7 },
          { text: i18n.t("label.last_14_days"), value: 14 },
          { text: i18n.t("label.last_30_days"), value: 30 },
        ],
        selectedValue: 7,
      },
    };
  },
  computed: {
    selectedRange() {
      return this.rangeFilter.selectOptions.find(
        (item) => item.value === this.rangeFilter.selectedValue
      );
    },
    queryValue() {
      return this.selectedRange.text.toLocaleLowerCase().replaceAll(" ", "-");
    },
    datesRange() {
      return {
        from: format(
          sub(new Date(), { days: this.rangeFilter.selectedValue }),
          "yyy-MM-dd"
        ),
        to: format(new Date(), "yyy-MM-dd"),
      };
    },
  },
};
