<template>
  <v-dialog
      content-class="dialog-overflow"
      v-model="show"
      max-width="800"
      persistent
      :fullscreen="!mdLandscapeAndUp"
      scrollable
  >
    <v-card :class="mdLandscapeAndUp || 'mobile-grid'">
      <v-toolbar class="mb-3" dark color="primary" dense>
        <v-toolbar-title>Happy Hour</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div style="border-bottom: 1px solid #ccc; overflow-y: auto">
        <ValidationObserver ref="observer">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="6">
                <ValidationProvider name="days" rules="required" v-slot="{ errors }">
                  <v-select
                      :error="errors.length > 0"
                      :error-messages="errors"
                      :items="weekDays"
                      v-model="form.days"
                      :label="$t('label.days')"
                      item-value="id"
                      :item-text="dayLabel"
                      multiple
                      solo
                  />
                </ValidationProvider>
              </v-col>

              <v-col>
                <v-menu
                    ref="menuFrom"
                    v-model="menuFrom"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="form.timeFrom"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider name="timeFrom" rules="required" v-slot="{ errors }">
                      <v-text-field
                          v-model="form.timeFrom"
                          :error="errors.length > 0"
                          :error-messages="errors"
                          :label="$t('filters.from')"
                          readonly
                          solo
                          v-bind="attrs"
                          v-on="on"
                      />
                    </ValidationProvider>
                  </template>

                  <v-time-picker
                      v-if="menuFrom"
                      v-model="form.timeFrom"
                      :allowed-minutes="(m) => m % 5 === 0"
                      :max="form.timeTo"
                      format="24hr"
                      full-width
                      no-title
                      ampm-in-title
                      @click:minute="$refs.menuFrom.save(form.timeFrom)"
                  />
                </v-menu>
              </v-col>

              <v-col>
                <v-menu
                    ref="menuTo"
                    v-model="menuTo"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="form.timeTo"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider name="timeTo" rules="required" v-slot="{ errors }">
                      <v-text-field
                          v-model="form.timeTo"
                          :error="errors.length > 0"
                          :error-messages="errors"
                          :label="$t('filters.to')"
                          readonly
                          solo
                          v-bind="attrs"
                          v-on="on"></v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-time-picker
                      v-if="menuTo"
                      v-model="form.timeTo"
                      :allowed-minutes="(m) => m % 5 === 0"
                      :min="form.timeFrom"
                      format="24hr"
                      full-width
                      no-title
                      ampm-in-title
                      @click:minute="
                        $refs.menuTo.save(form.timeTo)
                      "></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider/>

          <v-card-text>
            <v-row class="mb-3">
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" color="primary">{{ $t("list.licenses") }}</v-btn>
                  </template>

                  <v-list max-height="300">
                    <v-list-item
                      v-for="(item, index) in activeLicense"
                      :key="index"
                      style="background: #fff"
                      @click="selectLicense(item.id)"
                    >
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>

            <div v-if="licensesArr" :class="`pa-1 ${mdLandscapeAndUp && 'overflowed'}`">
              <LessonTypesSection
                  v-for="license in licensesArr"
                  :key="license"
                  :license="license"
                  :activeLicense="activeLicense"
                  :item="filteredLessons(license)"
                  @licenseDelete="removeLicense(license)"
                  @addLessons="updateLessons"
                  @deleteLesson="removeLesson"
                  @addPrice="updatePrice"
                  @clone="cloneItem"
              />
            </div>

            <v-alert v-if="overlapError" class="mt-5" dense type="error">{{ $t("alert.overlapped_happy_hours") }}</v-alert>
          </v-card-text>
        </ValidationObserver>
      </div>

      <v-card-actions>
        <v-spacer/>

        <v-btn :disabled="form.items.length === 0" color="primary" @click="create">
          {{ $t("btn.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import currency from '@/utils/mixins/currency'
import responsive from '@/utils/mixins/responsive'
import happyHoursService from '@/services/happyHoursService'
import axios from 'axios'
import HappyHoursModel from '@/store/models/HappyHoursModel'
import LicenseModel from '@/store/models/LicenseModel'
import LessonTypesSection from '@/components/happyHours/LessonTypesSection'

export default {
  name: 'HappyHourFormDialog',
  mixins: [currency, responsive],
  components: { LessonTypesSection },
  data() {
    return {
      overlapError: false,
      show: false,
      menuFrom: false,
      menuTo: false,
      weekDays: [
        { id: "mon", label: "monday" },
        { id: "tue", label: "tuesday" },
        { id: "wed", label: "wednesday" },
        { id: "thu", label: "thursday" },
        { id: "fri", label: "friday" },
        { id: "sat", label: "saturday" },
        { id: "sun", label: "sunday" },
      ],
      form: {
        days: [],
        timeFrom: null,
        timeTo: null,
        items: [],
      },
      licensesArr: []
    }
  },
  computed: {
    activeLicense() {
      return LicenseModel.all().filter(license => !this.licensesArr.includes(license.id));
    }
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.show = false
      this.overlapError = false;
      this.form.days = [];
      this.form.timeFrom = null;
      this.form.timeTo = null;
      this.form.items = [];
      this.licensesArr = [];
      this.$refs.observer.reset()
    },
    selectLicense(val) {
      this.licensesArr = [...this.licensesArr, val];
      this.form.items = [...this.form.items, { license: val, lessons: [] }];
    },
    filteredLessons(license) {
      return this.form.items.find((item) => item.license === license) || {};
    },
    removeLicense(val) {
      this.licensesArr = this.licensesArr.filter((license) => license !== val);
      this.form.items = this.form.items.filter((item) => item.license !== val);
    },
    updateLessons(val) {
      const { license, lesson } = val;
      const licenseItems = this.filteredLessons(license);
      licenseItems.lessons.push({ lessonTypeId: lesson, discountAmount: null });
    },
    removeLesson(val) {
      const { license, lessonTypeId } = val;
      const licenseItems = this.filteredLessons(license);

      licenseItems.lessons = licenseItems.lessons.filter((lesson) => lesson.lessonTypeId !== lessonTypeId);
    },
    updatePrice(val) {
      const { license, lessonTypeId } = val;
      const activeLessons = this.form.items[license].filter(
          (lesson) => lesson.lessonTypeId !== lessonTypeId
      );
      this.form.items[license] = [...activeLessons, val];
    },
    cloneItem(data) {
      const { clonedLicense, newLicense } = data;
      const original = this.form.items.find(
          (item) => item.license === clonedLicense
      );
      const parsedLessons = JSON.parse(JSON.stringify(original.lessons));
      const newItem = { lessons: parsedLessons, license: newLicense };
      this.form.items = [...this.form.items, newItem];
      this.licensesArr = [...this.licensesArr, newLicense];
    },
    dayLabel(day) {
      return this.$t("days." + day.label);
    },
    async create() {
      const validate = await this.$refs.observer.validate();

      if (validate) {
        const happyHoursRequests = this.getFormData().map((happyHourData) => {
          return happyHoursService.create(happyHourData).catch((error) => ({ error }))
        });

        const overlappedArr = [];
        axios
          .all(happyHoursRequests)
          .then(responses => {
            responses.filter(response => response.data).forEach(response =>
                HappyHoursModel.insert({ data: response.data })
            );
            responses.filter(response => response.error).forEach((err) =>
                overlappedArr.push(err.error.response.data.overlap)
            );
          })
          .finally(() => {
            if (overlappedArr.length > 0) {
              this.$emit('overlapError', overlappedArr)
            }
            this.close();
          });
      }
    },
    getFormData() {
      const { days, timeFrom, timeTo, items } = this.form;

      return items.map((item) => {
        const { license, lessons } = item;

        return lessons.map((lesson) => {
          const { lessonTypeId, discountAmount } = lesson;
          const discount = this.sanitizePrice(discountAmount, ".");

          return {
            days: days,
            timeFrom: timeFrom,
            timeTo: timeTo,
            licenseId: license,
            lessonTypeId: lessonTypeId,
            discount: discount,
          };
        });
      }).flat();
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-overflow {
  overflow: visible;
}
.overflowed {
  max-height: 600px;
  overflow-y: auto;
}
</style>
