import api from "@/utils/api";

export default {
  updateLanguage(language) {
    return new Promise((resolve, reject) => {
      api
        .patch("/user/language", { language })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  resendEmailVerification(params) {
    return new Promise((resolve, reject) => {
      api
        .post("/user/resend-email-verification", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  profileActivation(params) {
    return new Promise((resolve, reject) => {
      api
        .post("/user/account-activation", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  updateGeneralInfo(params) {
    return new Promise((resolve, reject) => {
      api
        .post("/user/settings/general", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  resetPassword(params) {
    return new Promise((resolve, reject) => {
      api
        .post("/user/settings/reset-password", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  upcomingAppointmentsForToday() {
    return new Promise((resolve, reject) => {
      api
          .get("/widgets/upcoming-appointments")
          .then((resp) => {
            resolve(resp);
          })
          .catch(reject);
    });
  },
  getSignature() {
    return new Promise((resolve, reject) => {
      api
        .get("/user/signature")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  saveSignature(signature, signatureType= null) {
    return new Promise((resolve, reject) => {
      api
        .post("/user/signature", { signature, signatureType })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  deleteSignature() {
    return new Promise((resolve, reject) => {
      api
        .delete(`/user/signature`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  }
};
