<script>
import insightsService from "@/services/insightsService";
import InsightsCategoriesGroup from "@/components/insights/InsightsCategoriesGroup.vue";
import TemplateSkeletonLoader from "@/components/insights/TemplateSkeletonLoader.vue";
import TemplateModel from '@/store/models/insights/TemplateModel'

export default {
  name: "TemplateRenderer",
  components: {TemplateSkeletonLoader, InsightsCategoriesGroup},
  props: {
    hasLoadedTemplate: {
      type: Boolean,
      default: false
    },
    templateId: {
      type: Number,
      required: true
    },
    showToolbar: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: false
    },
    tile: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null,
      required: false
    },
    showDescription: {
      type: Boolean,
      default: true
    },
    evaluation: {
      type: Object,
      default: null
    },
    studentProgress: {
      type: Object,
      default: null
    },
    expanded: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      template: null,
      loading: true,
    }
  },
  mounted() {
    let params = {}
    if (this.hasLoadedTemplate) {
      params.appointmentId = this.evaluation.appointmentId
      params.awaitingEvaluation = true
    }

    this.$emit('templateLoader', true)
    insightsService.getTemplate(this.templateId, params).then(resp => {
      TemplateModel.create({ data: resp.data })
      this.template = resp.data || {}
    })
      .finally(() => {
        this.$emit('templateLoader', false)
        this.loading = false
    })
  },
  computed: {
    categoriesInEvaluation() {
      return this.evaluation
        ? this.evaluation.items.reduce((result, item) => {
          result.add(item.category.id);
          if (item.category.parentId !== null) {
            result.add(item.category.parentId);
          }
          return result;
        }, new Set())
        : null;
    },
    categoryTree() {
      const tree = [];
      const categories = this.template.categories;
      const items = this.evaluation ? this.evaluation.items : this.template.items;

      const finishedItems = new Set(this.progress?.finishedItems);

      // Function to recursively build the category tree
      const buildTree = (parentId) => {
        const subtree = categories.filter(category => category.parentId === parentId);
        if (subtree.length === 0) return null;
        return subtree.map(category => {
          const subcategories = buildTree(category.id);
          const categoryItems = items.filter(item => item.categoryId === category.id);

          return {
            ...category,
            progress: this.progress && category.id in this.progress.categoriesProgress
              ? this.progress.categoriesProgress[category.id]
              : null,
            subcategories: subcategories ? subcategories : null,
            items: this.progress ? categoryItems.map(item => {
              return {
                ...item,
                isFinal: finishedItems.has(item.id),
                evaluations: item.id in this.progress.evaluationsByItem ? this.progress.evaluationsByItem[item.id] : []
              }
            }) : categoryItems,
          };
        });
      };

      // Build the top-level categories
      tree.push(...buildTree(null));
      return tree;
    },
  }
}
</script>

<template>
  <v-card loader-height="1" :outlined="outlined" :tile="tile" :elevation="tile ? 0 : 3">
    <v-toolbar elevation="3" v-if="showToolbar">
      <v-btn icon @click="$router.back()" class="ml-0" exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-card-title class="text-overflow-ellipsis">
        <v-skeleton-loader
          width="150"
          height="12"
          type="text@1"
          v-if="loading"/>
        <span class="mr-2 text-overflow-ellipsis" v-else>
          {{ template.name }}
        </span>
      </v-card-title>
    </v-toolbar>
    <v-card-title v-if="title">
      {{ title }}
    </v-card-title>
    <v-card-subtitle v-if="showDescription && ! loading">
      {{ template.description }}
    </v-card-subtitle>
    <v-card-text :class="{'pa-0' : tile}">
      <template-skeleton-loader type="table" v-if="loading"/>
      <template v-else>
        <v-sheet v-if="progress" class="text-h6 mb-2">{{ $t('insights.progress_breakdown') }}</v-sheet>
        <insights-categories-group
          :expanded="expanded"
          :tree="categoryTree"
          :filter="categoriesInEvaluation"
          :progress="progress"
        />
      </template>
    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>
