<script>
import InsightsCategory from "@/components/insights/InsightsCategory.vue";

export default {
  name: "InsightsCategoriesGroup",
  components: {InsightsCategory},
  props: {
    tree: {
      type: Array,
      required: true
    },
    filter: {
      type: Set,
      default: null
    },
    expanded: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Object,
      default: null
    }
  },
  computed: {
    filteredTree() {
      if (this.filter === null) return this.tree

      if (!this.filter.size) return []

      return this.tree.filter(item => this.filter.has(item.id));
    }
  }
}
</script>

<template>
<div>
  <insights-category
    class="insights-category"
    v-for="category in filteredTree"
    :category="category"
    :key="category.id"
    :expanded-state="expanded"
  />
</div>
</template>

<style scoped>
  .insights-category {
    transition-duration: 0.2s;
    border: 1px solid #eee;
    border-bottom-style: none;
    &:last-child {
      border-bottom-style: solid;
    }
    &:first-child.expanded {
      margin-top: 0 !important;
    }
    &:last-child.expanded {
      margin-bottom: 0 !important;
    }
    &:has(+ .expanded) {
      border-bottom-style: solid;
    }

    &.expanded {
      box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
      margin: 10px 0;
      border-style: none;
    }
  }
</style>
