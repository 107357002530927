<script>
import {mapGetters} from "vuex";
import StudentFile from "@/components/students-directory/StudentFile.vue";
import HomeTab from "@/components/students-directory/HomeTab.vue";

export default {
  name: "ContentBody",
  components: {HomeTab, StudentFile},
  computed: {
    ...mapGetters('studentsDirectory', ['openedStudents', 'activeTab'])
  },
}
</script>

<template>
  <v-tabs-items :value="activeTab">
    <v-tab-item value="home" class="" style="">
      <home-tab />
    </v-tab-item>
    <v-tab-item
      v-for="student in openedStudents"
      :key="student.id"
      :value="`${student.id}`"
    >
      <student-file :student="student" />
    </v-tab-item>
  </v-tabs-items>

</template>

<style scoped>

</style>