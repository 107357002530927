import api from "@/utils/api";

export default {
  load(params) {
    return new Promise((resolve, reject) => {
      api
        .get("school/happy-hours", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  create(params) {
    return new Promise((resolve, reject) => {
      api
        .post("school/happy-hours", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  update(happyHourId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`school/happy-hours/${happyHourId}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  destroy(happyHourId) {
    return new Promise((resolve, reject) => {
      api
        .delete(`school/happy-hours/${happyHourId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
