<template>
<div class="d-flex text-center flex-column flex-md-row flex-grow-1 fill-height">
  <v-sheet class="layout-side mx-auto mx-md-1 d-none d-md-flex flex-md-column justify-space-between px-4">
    <div class="mt-6 mt-md-20 pa-8">
      <v-img :src="require('@/assets/svg/book-and-pay.svg')" />

      <div class="title my-2">
        Wir digitalisieren die Abläufe in Ihrer Fahrschule
      </div>

      <v-btn :to="{name: 'login'}" elevation="1" text>Zurück</v-btn>
    </div>

    <img :src="require('@/assets/img/calendar-screen.png')" style="width: 100%"/>
  </v-sheet>

  <div class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column">
    <div class="layout-content ma-auto" style="width: 100%">
      <v-card class="layout-content pa-2 ma-auto">
        <v-card-title class="justify-center mb-2">
          <span>{{ $t('label.forgot_password') }}</span>
        </v-card-title>

        <v-card-subtitle>{{ $t('messages.complete_with_email_indicated_at_registration') }}</v-card-subtitle>

        <v-card-text>
          <ValidationObserver ref="form">
            <v-form @keyup.native.enter="submit">
              <ValidationProvider
                  v-slot="{ errors }"
                  name="email"
                  rules="required"
              >
                  <v-text-field
                      outlined
                      inputmode="email"
                      :label="$t('validation.fields.email')"
                      :placeholder="$t('validation.fields.email')"
                      v-model="form.email"
                      :error-messages="errors"
                      clearable
                  />
              </ValidationProvider>
            </v-form>
          </ValidationObserver>

          <v-btn
            :dark="!loading"
            block
            x-large
            color="primary"
            elevation="1"
            :loading="loading"
            :disabled="loading"
            @click="submit"
          >
            {{ $t('btn.send_password_reset_link') }}
          </v-btn>

          <div class="mt-5">
            <router-link :to="{name: 'login'}">{{ $t('btn.back_to_login') }}</router-link>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <CopyrightAndVersion/>
  </div>

  <ResetPasswordLinkSuccessDialog ref="resetPasswordLinkSuccessDialog"/>
</div>
</template>

<script>
import authService from '@/services/authService'
import ResetPasswordLinkSuccessDialog from '@/components/ResetPasswordLinkSuccessDialog'
import CopyrightAndVersion from "@/views/Auth/CopyrightAndVersion";

export default {
  name: 'ForgotPassword',
  components: {CopyrightAndVersion, ResetPasswordLinkSuccessDialog },
  data () {
    return {
      form: {
        email: ''
      },
      loading: false
    }
  },
  methods: {
    submit () {
      this.loading = true

      authService.sendResetPasswordLink({ email: this.form.email })
        .then(() => {
          this.$refs.resetPasswordLinkSuccessDialog.open()
          this.form.email = ''
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$refs.form.reset()

          const responseData = error.response.data || {};
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error", });
          }

          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.layout-side {
  width: 420px;
  height: 100vh;
}
.layout-content {
  max-width: 480px;
}
</style>
