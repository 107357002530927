<template>
  <div class="mt-1">
    <v-row dense>
      <v-col cols="12" class="d-flex flex-column pb-4">
        <v-select
          :items="[
            { text: this.$t('gender.M'), value: 'M' },
            { text: this.$t('gender.W'), value: 'W' },
            { text: this.$t('gender.D'), value: 'D' },
          ]"
          outlined
          hide-details
          dense
          :placeholder="$t('label.gender')"
          :label="$t('label.gender')"
          v-model="stepForm.gender"
        />
      </v-col>

      <v-col cols="12" sm="6" md="12" lg="8">
        <ValidationProvider v-slot="{ errors }" name="placeOfBirth">
          <v-text-field
            dense
            v-model="stepForm.placeOfBirth"
            :label="$t('form.place_of_birth')"
            :placeholder="$t('form.place_of_birth')"
            outlined
            :error-messages="errors"
            required />
        </ValidationProvider>
      </v-col>

      <v-col cols="12" sm="6" md="12" lg="4">
        <ValidationProvider v-slot="{ errors }" name="birthDate">
          <v-menu
            v-model="menuBirthDate"
            :close-on-content-click="false"
            :nudge-bottom="-20"
            transition="scale-transition"
            offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                :value="birthDateFormat"
                :label="$t('form.birthday')"
                :placeholder="$t('form.birthday')"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                :error-messages="errors"
                clearable
                @click:clear="stepForm.birthDat = ''"></v-text-field>
            </template>
            <v-date-picker
              ref="birthDatePicker"
              @input="onInputBirthDate"
              :value="minDateOfBirthFromToday"
              no-title
              scrollable
              :max="minDateOfBirthFromToday" />
          </v-menu>
        </ValidationProvider>
      </v-col>
    </v-row>

    <div class="mb-3">
      <span class="text-subtitle-1 font-weight-medium">{{
        $t("label.contact")
      }}</span>
      <v-divider />
    </div>

    <v-row dense>
      <v-col cols="12" md="6">
        <ValidationProvider v-slot="{ errors }" name="phone">
          <v-text-field
            outlined
            dense
            v-model="stepForm.phone"
            :label="$t('form.phone_mobile')"
            :placeholder="$t('form.phone_mobile')"
            type="text"
            required
            :error-messages="errors"></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider v-slot="{ errors }" name="landline">
          <v-text-field
            outlined
            dense
            v-model="stepForm.landline"
            :label="$t('form.phone_landline')"
            :placeholder="$t('form.phone_landline')"
            type="text"
            required
            :error-messages="errors"></v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>

    <div class="mb-3">
      <span class="text-subtitle-1 font-weight-medium">{{
        $t("label.address")
      }}</span>
      <v-divider />
    </div>

    <v-row dense>
      <v-col cols="12" sm="6" md="5">
        <ValidationProvider v-slot="{ errors }" name="street">
          <v-text-field
            outlined
            dense
            v-model="stepForm.street"
            :label="$t('form.street')"
            :placeholder="$t('form.street')"
            type="text"
            required
            :error-messages="errors"></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="6" md="2">
        <ValidationProvider v-slot="{ errors }" name="houseNr">
          <v-text-field
            outlined
            dense
            v-model="stepForm.houseNr"
            :label="$t('form.house')"
            :placeholder="$t('form.house')"
            type="text"
            required
            :error-messages="errors"></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="6" md="2">
        <ValidationProvider v-slot="{ errors }" name="postcode">
          <v-text-field
            outlined
            dense
            v-model="stepForm.postcode"
            :label="$t('form.plz')"
            :placeholder="$t('form.plz')"
            type="text"
            required
            :error-messages="errors"></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <ValidationProvider v-slot="{ errors }" name="city">
          <v-text-field
            outlined
            dense
            v-model="stepForm.city"
            :label="$t('form.city')"
            :placeholder="$t('form.city')"
            type="text"
            required
            :error-messages="errors"></v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>

    <div class="mt-4">
      <v-btn
        class="mr-2"
        color="primary"
        @click="submit"
        :loading="stepFormLoading"
        :disabled="stepFormLoading || registrationWithLoading">
        {{ $t("btn.continue") }}
      </v-btn>
      <v-btn text @click="$emit('changeStep', 1)">{{ $t("btn.back") }}</v-btn>
    </div>
  </div>
</template>

<script>
import { format, sub } from "date-fns";
import authService from "@/services/authService";

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  name: "RegistrationStepPersonalInfo",
  data: () => ({
    menuBirthDate: false,
    stepFormLoading: false,
    registrationWithLoading: false,
    stepForm: {
      registrationStep: "personalInfo",
      gender: "",
      placeOfBirth: "",
      birthDate: "",
      street: "",
      houseNr: "",
      postcode: "",
      city: "",
    },
  }),
  watch: {
    menuBirthDate(val) {
      val &&
        setTimeout(() => (this.$refs.birthDatePicker.activePicker = "YEAR"));
    },
  },
  computed: {
    birthDateFormat() {
      return this.stepForm.birthDate
        ? format(new Date(this.stepForm.birthDate), "dd.MM.yyyy")
        : "";
    },
    minDateOfBirthFromToday () {
      return format(sub(new Date(), { years: 14 }), 'yyyy-MM-dd')
    }
  },
  methods: {
    onInputBirthDate(value) {
      this.stepForm.birthDate = value;
      this.menuBirthDate = false;
    },
    submit() {
      this.$emit("beforeSubmit");

      const form = { ...this.form, ...this.stepForm };

      this.stepFormLoading = true;
      authService
        .registration(form)
        .then(() => {
          this.$emit("submitted", "personalInfo", {
            form: { ...this.stepForm },
            errors: [],
            passed: true,
          });
        })
        .catch((error) => {
          const responseData = error.response.data;
          const errors = responseData.errors || [];

          this.$emit("submitted", responseData.step, {
            form: this.form,
            responseMessage: responseData.message,
            errors: { ...errors },
            passed: false,
          });
        })
        .finally(() => {
          this.stepFormLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
