<template>
  <v-row>
    <v-col cols="12" :lg="hasExams ? '6' : '12'" class="px-1">
      <v-card class="mx-auto mb-3" elevation="0" outlined>
        <v-skeleton-loader width="200px" type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item"></v-skeleton-loader>
        <v-divider class="ma-4"/>
        <div class="mb-6">
          <v-skeleton-loader width="200px" height="35px" type="list-item"></v-skeleton-loader>
          <v-skeleton-loader height="35px" v-for="item in basicTopicsCount" :key="item.id" type="list-item"></v-skeleton-loader>
          <v-skeleton-loader width="200px" height="35px" type="list-item" class="mt-3"></v-skeleton-loader>
          <v-skeleton-loader height="35px" v-for="item in specialTopicsCount" :key="item.id" type="list-item"></v-skeleton-loader>
        </div>
      </v-card>
    </v-col>
    <v-col v-if="hasExams" cols="12" lg="6" class="px-1">
      <v-card class="mb-5" elevation="0" outlined>
        <v-skeleton-loader width="200px" type="list-item-two-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item"></v-skeleton-loader>
        <v-divider class="ma-4"/>
        <div class="mb-6">
          <v-skeleton-loader type="list-item"></v-skeleton-loader>
          <v-skeleton-loader type="list-item"></v-skeleton-loader>
          <v-skeleton-loader type="list-item"></v-skeleton-loader>
        </div>
      </v-card>

      <div>
        <v-card class="mb-6" elevation="0" outlined>
          <v-skeleton-loader width="200px" type="list-item-two-line"></v-skeleton-loader>
          <v-divider class="mx-4 my-2"/>
          <div class="d-flex justify-space-between">
            <v-skeleton-loader width="40%" type="list-item"></v-skeleton-loader>
            <v-skeleton-loader width="20%" type="list-item"></v-skeleton-loader>
          </div>
          <div class="d-flex justify-space-between">
            <v-skeleton-loader width="40%" type="list-item"></v-skeleton-loader>
            <v-skeleton-loader width="20%" type="list-item"></v-skeleton-loader>
          </div>
        </v-card>

        <v-card elevation="0" outlined>
          <v-skeleton-loader width="200px" type="list-item-two-line"></v-skeleton-loader>
          <v-divider class="mx-4 my-2"/>
          <div class="d-flex justify-space-between">
            <v-skeleton-loader width="40%" type="list-item"></v-skeleton-loader>
            <v-skeleton-loader width="20%" type="list-item"></v-skeleton-loader>
          </div>
          <div class="d-flex justify-space-between">
            <v-skeleton-loader width="40%" type="list-item"></v-skeleton-loader>
            <v-skeleton-loader width="20%" type="list-item"></v-skeleton-loader>
          </div>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "EducationTabContentLoader",
  props: {
    hasExams: {
      type: Boolean,
      default: false
    },
    basicTopicsCount: {
      type: Number,
      default: 0
    },
    specialTopicsCount: {
      type: Number,
      default: 0
    },
  },
};
</script>

<style scoped></style>
