import userDevicesService from "@/services/userDevicesService";
import PushNotificationActionHandler from "@/notifications/push/pushNotificationActionHandler";

import { PushNotifications } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";

export default class FcmService {

  async initPush() {
    if (Capacitor.getPlatform() === 'web') return;

    await this._registerPush();
  }

  async removeDeviceToken() {
    // implement token removal for this specific deviceId
    // to be called on logout so user will not receive push notifications after he logs out
    const { uuid } = await Device.getId();

    await userDevicesService.delete(uuid);

    if (Capacitor.getPlatform() !== 'web') {
      await PushNotifications.unregister();
    }

    return true;
  }

  removeListeners() {
    if (Capacitor.getPlatform() === 'web') return;

    PushNotifications.removeAllListeners();
  }

  async addListeners() {
    await PushNotifications.addListener("registration", async (token) => {
      const deviceInfo = await Device.getInfo();
      const { identifier } = await Device.getId();
      const appInfo = await App.getInfo();
      // implement token saving for user and device id
      await userDevicesService.set({
        ...deviceInfo,
        uuid: identifier,
        fcmToken: token,
        appVersion: appInfo.version,
        appBuild: appInfo.build
      });
    });

    await PushNotifications.addListener("registrationError", (error) => {
      console.error("Error on registration: " + JSON.stringify(error));
    });

    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (action) => {
        const data = action.notification.data;
        if (Object.keys(data).includes("action")) {
          const handler = new PushNotificationActionHandler(
            data.action,
            data.arg
          );
          handler.run();
        }
      }
    );
  }

  async _registerPush() {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await this.addListeners()

    await PushNotifications.register();
  }
}
