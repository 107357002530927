import Echo from 'laravel-echo';

import Pusher from 'pusher-js';
window.Pusher = Pusher;

const env = localStorage.getItem('appenv')

const DEFAULT_WSHOST = (env && env !== 'prod') ? process.env.VUE_APP_WS_HOST_DEV : process.env.VUE_APP_WS_HOST
const DEFAULT_AUTH_ENDPOINT = (env && env !== 'prod') ? process.env.VUE_APP_API_BASE_URL_DEV : process.env.VUE_APP_API_BASE_URL

const EchoInstance = new Echo({
  broadcaster: 'reverb',
  key: process.env.VUE_APP_WS_KEY,
  wsHost: DEFAULT_WSHOST,
  wsPort: process.env.VUE_APP_WS_PORT,
  wssPort: process.env.VUE_APP_WSS_PORT,
  forceTLS: process.env.VUE_APP_WS_TLS === "true",
  enabledTransports: ['ws', 'wss'],
  // Previous entries
  authEndpoint: DEFAULT_AUTH_ENDPOINT + "/broadcasting/auth",
  auth: {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  }
});

export default EchoInstance;
