<template>
  <ValidationProvider
    name="text"
    :rules="`${validation ? 'required' : ''}`"
    v-slot="{ errors }">
    <v-textarea
      :error="errors.length > 0"
      v-model="notes"
      :disabled="disabled"
      :label="$t('form.text')"
      auto-grow
      outlined
      dense
      rows="1"
      row-height="15"
      hide-details />
  </ValidationProvider>
</template>

<script>
export default {
  name: "NotesField",
  props: {
    validation: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
    },
    notesProp: {
      type: String,
    },
  },
  computed: {
    notes: {
      set(value) {
        this.$emit("emitNotesChange", value);
      },
      get() {
        return this.notesProp;
      },
    },
  },
};
</script>
