export default function complexSort(arr, key) {
  // The pattern matches strings of form "Number(.Number)*(Letter)?"
  const pattern = /^(\d+(\.\d+)*)([a-z])?$/i;

  return arr.sort((a, b) => {
    const aVal = String(a[key]).match(pattern);
    const bVal = String(b[key]).match(pattern);

    if (!aVal || !bVal) throw new Error("Invalid value");

    const aParts = aVal[0].split(".");
    const bParts = bVal[0].split(".");

    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      // Convert "NumberLetter" to ["Number", "Letter"]
      const aSubParts = /^(\d+)([a-z])?$/i.exec(aParts[i] || "0");
      const bSubParts = /^(\d+)([a-z])?$/i.exec(bParts[i] || "0");

      if (!aSubParts || !bSubParts) throw new Error("Invalid value");

      // Compare numbers
      if (+aSubParts[1] < +bSubParts[1]) return -1;
      if (+aSubParts[1] > +bSubParts[1]) return 1;

      // If numbers are equal, compare letters
      if (aSubParts[2] || bSubParts[2]) {
        if (aSubParts[2] < bSubParts[2]) return -1;
        if (aSubParts[2] > bSubParts[2]) return 1;
      }
    }

    // If all parts are equal, return 0
    return 0;
  });
}
