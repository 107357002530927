<template>
  <v-row>
    <v-col>
      <ValidationProvider
        name="meeting-place"
        :rules="`${validation ? 'required' : ''}`"
        v-slot="{ errors }">
        <v-combobox
          dense
          outlined
          hide-details
          open-on-clear
          @update:search-input="updateTextModel"
          :error="errors.length > 0"
          v-model="model.meetingPlace"
          :disabled="disabled"
          :label="$t('form.meeting_place')"
          :items="placesList"
          item-text="value"
          item-value="value"
          :append-icon="!disabled ? 'mdi-menu-down' : ''">
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title v-if="data.item.name">{{
                data.item.name
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                data.item.address
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-combobox>
      </ValidationProvider>
    </v-col>
  </v-row>
</template>

<script>
import MeetingPlaceModel from "@/store/models/MeetingPlaceModel";
import OfficeModel from "@/store/models/OfficeModel";
import StudentModel from "@/store/models/StudentModel";
import user from "@/utils/mixins/user";
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";

export default {
  name: "MeetingPlaceField",
  mixins: [user],
  props: {
    validation: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    schoolOffices() {
      return OfficeModel.all() || [];
    },
    meetingPlaces() {
      return MeetingPlaceModel.all() || [];
    },
    student() {
      let studentId = this.isStudent
        ? this.currentUser.id
        : this.model.studentsIds[0];
      if (this.model.eventType === "PLACEHOLDER" && this.model.subscriber)
        studentId = this.model.subscriber.studentId;
      return StudentModel.find(studentId);
    },
    placesList() {
      let placeList = [];
      if (this.schoolOffices.length) {
        placeList = placeList.concat(
          this.createPlaceListGroup(
            i18n.t("label.school_offices"),
            this.schoolOffices
          )
        );
      }
      if (this.meetingPlaces.length) {
        placeList = placeList.concat(
          this.createPlaceListGroup(
            i18n.t("label.meeting_place"),
            this.meetingPlaces
          )
        );
      }
      if (this.student) {
        const studentAddress = [
          {
            name: undefined,
            address: this.student.address,
            value: this.student.address,
          },
        ];
        placeList = placeList.concat(
          this.createPlaceListGroup(
            i18n.t("label.student_address"),
            studentAddress
          )
        );
      }
      return placeList;
    },
  },
  methods: {
    updateTextModel(val) {
      this.model.meetingPlace = val;
    },
    createPlaceListGroup(header, places = []) {
      return [
        { header: header },
        { divider: true },
        ...places.map((place) => ({
          name: place.name,
          address: place.address,
          value: place.name ? place.name + ", " + place.address : place.address,
        })),
      ];
    },
  },
};
</script>
