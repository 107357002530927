<template>
  <v-col :cols="cols">
    <v-autocomplete
      :disabled="disabled"
      @change="teacherSelect"
      v-model="model.teacherId"
      :items="filteredTeachers"
      item-value="id"
      :item-text="getFullName"
      outlined
      dense
      hide-details
      :label="$t('form.teacher')"
      :append-icon="!disabled ? 'mdi-menu-down' : ''">
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>{{ getFullName(data.item) }}</v-list-item-title>
        </v-list-item-content>
        <div name="other">
          <v-list-item-subtitle>{{
            !data.item.studentCanCreatePractice ? $t("label.request") : ""
          }}</v-list-item-subtitle>
        </div>
      </template>
    </v-autocomplete>
  </v-col>
</template>

<script>
import { addMinutes, format, parseISO } from "date-fns";
import TeacherModel from "@/store/models/TeacherModel";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TeachersField",
  props: {
    cols: {
      type: Number,
      required: true,
    },
    filteredTeachers: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    teachersArray() {
      const teachers = this.filteredTeachers;
      if (teachers.length === 1) {
        this.teacherSelect();
        this.preselectTeacher(teachers[0].id);
      } else {
        this.preselectTeacher(this.model.teacherId);
      }
      return teachers;
    },
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("appointments", [
      "initialDurationGetter",
      "createEventModalGetter",
    ]),
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    ...mapGetters(["separateTeachersGetter"]),
  },
  mounted() {
    if (this.separateTeachersGetter) this.teacherSelect();
  },
  watch: {
    createEventModalGetter() {
      if (this.separateTeachersGetter) this.teacherSelect();
    },
  },
  methods: {
    ...mapActions("appointments", ["eventErrorAlertToggle"]),
    preselectTeacher(id) {
      this.model.teacherId = id;
    },
    teacherSelect() {
      if (this.model.id) return;
      this.model.teacher = TeacherModel.find(this.model.teacherId);
      if (
        this.model.type !== "PRACTICE" &&
        this.model.type !== "PLACEHOLDER" &&
        this.currentUser.type !== "student"
      )
        return;
      const teacherDuration = this.model.teacher.lessonDuration;
      let fitAmount = Math.ceil(this.initialDurationGetter / teacherDuration);
      fitAmount = fitAmount <= 5 ? fitAmount : 5;
      this.model.lessonsAmount = fitAmount;
      this.model.lessonDuration = teacherDuration;
      this.model.start = this.model.date + " " + this.model.startTime + ":00";
      const starTime = addMinutes(
        parseISO(this.model.start),
        teacherDuration * fitAmount
      );
      this.model.endTime = format(starTime, "HH:mm");
    },
    getFullName(item) {
      return `${item.firstName} ${item.lastName}`;
    },
  },
};
</script>

<style lang="scss" scoped></style>
