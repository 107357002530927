import Notification from "@/notifications/notification";
import router from "@/router";

export default class StudentReplenishedBalance extends Notification {
  wildCardsData() {
    const data = this.notification.data;

    return {
      title: {
        studentName: data.studentName,
      },
      message: {
        moneyAmount: data.moneyAmount,
        studentCurrentBalance: data.studentCurrentBalance
      },
    };
  }

  action() {
    router.push({
      name: "students.student.finances",
      params: { id: this.notification.data.studentId }
    })
      .catch((error) => console.log(error));
  }
}
