<script>
import { defineComponent } from "vue";
import VehicleModel from "@/store/models/VehicleModel";
import VehicleAvatar from "@/components/VehicleAvatar.vue";
import VehicleSidebar from "@/components/vehicle/VehicleSidebar.vue";
import VehicleDetailsList from "@/components/vehicle/VehicleDetailsList.vue";

export default defineComponent({
  name: "VehicleInfoCard",
  components: { VehicleSidebar, VehicleAvatar, VehicleDetailsList },
  props: {
    orientation: {
      type: String,
      default: "vertical"
    },
    vehicleId: {
      type: [Number, String],
      required: true
    }
  },
  computed: {
    vehicle() {
      return VehicleModel.find(this.vehicleId) || {};
    },
  },
  methods: {
    openSidebar() {
      this.$refs.sidebar.open();
    }
  }
});
</script>

<template>
  <v-sheet
    :class="{ 'd-flex': orientation === 'horizontal' }"
    class="py-4 px-3"
    color="white"
    elevation="1"
    rounded
    width="100%"
  >
    <template v-if="orientation === 'horizontal'">
      <div class="d-flex mx-auto">
        <VehicleAvatar :alt="vehicle.fullName" :path="vehicle.photo" class="elevation-2" contain size="90" />
      </div>

      <div class="ml-3 d-flex flex-column">
        <span class="text-h5">{{ vehicle.make }} {{ vehicle.model }}, {{ vehicle.year }}</span>
        <span class="text-subtitle-2">{{ vehicle.licensePlate }}</span>
        <span v-if="vehicle.vehicleId" class="text-subtitle-2 text-monospace">ID# {{ vehicle.vehicleId }}</span>
      </div>
      <v-spacer />

      <v-btn icon large @click="openSidebar">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>

      <VehicleSidebar ref="sidebar" :vehicle-id="vehicle.id" />
    </template>

    <template v-else>
      <div class="d-flex flex-column text-center mx-3">
        <div class="d-flex mx-auto">
          <VehicleAvatar :alt="vehicle.fullName" :path="vehicle.photo" class="elevation-2" contain size="130" />
        </div>

        <div class="d-flex flex-column">
          <span class="mt-3 text-h5">{{ vehicle.make }} {{ vehicle.model }}, {{ vehicle.year }}</span>
          <span class="text-subtitle-2">{{ vehicle.licensePlate }}</span>
          <span v-if="vehicle.vehicleId" class="text-subtitle-2 text-monospace">ID# {{ vehicle.vehicleId }}</span>
        </div>
      </div>

      <v-divider class="my-4" />

      <VehicleDetailsList :vehicle="vehicle" :sidebar="false" />
    </template>
  </v-sheet>
</template>

<style scoped>

</style>
