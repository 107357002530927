<template>
  <v-container>
    <v-sheet class="px-3" elevation="5" rounded>
      <v-row class="my-1 d-flex align-center">
        <v-col cols="12" md="4">
          <v-dialog
              ref="dialog"
              v-model="calendarDialog"
              :return-value.sync="date"
              persistent
              width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  solo
                  dense
                  readonly
                  :value="formattedDates"
                  :label="$t('label.filter_by_date')"
                  prepend-inner-icon="mdi-calendar"
                  clearable
                  hide-details
                  @click:clear="date = []"
                  v-bind="attrs"
                  v-on="on">
              </v-text-field>
            </template>
            <v-date-picker v-model="date" scrollable range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="calendarDialog = false">
                {{ $t("btn.cancel") }}
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(date.sort())"
              >
                {{ $t("btn.ok") }}</v-btn
              >
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
              dense
              solo
              hide-details
              :items="licenses"
              item-value="id"
              item-text="name"
              v-model="filters.licenses"
              multiple
              :placeholder="$t('form.licenses')"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn text color="primary" class="mr-1" @click="filter" :loading="loading">
            {{$t("btn.filter_results")}}
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>

    <v-card outlined elevation="5" class="my-5" :loading="loading">
      <v-card-title>{{ $t('label.deliveredServices') }}</v-card-title>
      <v-card-subtitle>
        {{
          showCostOverviewTab
            ? $t('label.delivered_services_explanation')
            : $t('label.delivered_services_explanation_nonbookandpay')
        }}
      </v-card-subtitle>
      <v-card-text>
        <div v-if="loading">
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </div>
        <template v-else-if="services.length">
          <v-list>
            <template v-for="(service, index) in services">
              <v-list-item three-line :key="service.id">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ service.description }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ service.date | dateFormat("dd.MM.yyyy")}}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ $t('label.license') }}: {{ service.license ? service.license.name : '-' }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <span style="font-weight: bold">{{ service.cost | currency }}</span>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="index < services.length - 1" :key="`service_divider_${index}`"></v-divider>
            </template>
          </v-list>
          <v-sheet elevation="0" class="px-3 py-2 d-flex justify-space-between" rounded color="secondary" dark>
            <h6 class="text-h6">{{ $t("label.total") }}</h6>
            <h6 class="text-h6">{{ servicesTotalCost | currency }}</h6>
          </v-sheet>
        </template>
        <empty-box v-else/>
      </v-card-text>
    </v-card>

    <v-card v-if="showCostOverviewTab" outlined elevation="5" class="my-5" :loading="loading">
      <v-card-title>{{ $t('label.payments') }}</v-card-title>
      <v-card-subtitle>
        {{ $t("label.payments_explanation") }}
      </v-card-subtitle>
      <v-card-text>
        <div v-if="loading">
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </div>
        <template v-else-if="payments.length">
          <v-list>
            <template v-for="(payment, index) in payments">
              <v-list-item three-line :key="payment.id">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t(`history_action.${payment.action}`) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ payment.createdAt | dateFormat("dd.MM.yyyy")}}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ payment.note }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <span style="font-weight: bold">{{ payment.moneyAmount | currency }}</span>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="index < payments.length - 1" :key="`payments_divider_${index}`"></v-divider>
            </template>
          </v-list>
          <v-sheet elevation="0" class="px-3 py-2 d-flex justify-space-between" rounded color="secondary" dark>
            <h6 class="text-h6">{{ $t("label.total") }}</h6>
            <h6 class="text-h6">{{ paymentsTotalAmount | currency }}</h6>
          </v-sheet>
        </template>
        <empty-box v-else/>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import studentsService from "@/services/studentsService";
import user from "@/utils/mixins/user";
import formattedDatesMixin from "@/utils/mixins/formattedDatesMixin";
import LicenseModel from "@/store/models/LicenseModel";

export default {
  name: "CostsOverview",
  mixins: [user, formattedDatesMixin],
  data() {
    return {
      studentId: null,
      services: [],
      payments: [],
      loading: false,
      calendarDialog: false,
      filters: {
        licenses: [],
      }
    }
  },
  mounted() {
    this.studentId = this.currentUser.id

    this.filter()
  },
  methods: {
    filter () {
      this.load(this.params)
    },
    load (params = null) {
      this.loading = true
      studentsService.costsOverview(this.studentId, params).then(resp => {
        this.services = resp.data.services
        this.payments = resp.data.payments

        if (this.licenses.length === 1) {
          const licenseId = this.licenses[0].id

          this.services = this.services.map(service => {
            return service.licenseId === null ? { ...service, licenseId } : service
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
  },
  computed: {
    showCostOverviewTab () {
      if (!this.isStudent) return
      return this.currentUser.isElter
        ? this.parentSelectedStudent.bookAndPay
        : this.currentUser.bookAndPay
    },
    licenses() {
      return LicenseModel.findIn(this.currentUser.licensesIds) || {};
    },
    servicesTotalCost () {
      return this.services.reduce((a, b) => {
        return a + b.cost
      }, 0)
    },
    paymentsTotalAmount () {
      return this.payments.reduce((a, b) => {
        return a + parseFloat(b.moneyAmount)
      }, 0)
    },
    params () {
      return {
        from: this.startDate,
        to: this.endDate,
        licenses: this.filters.licenses
      }
    }
  }
}
</script>

<style scoped>

</style>
