<script>
export default {
  name: "SignaturePad",
  props: {
    closeOnSave: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      show: false,
      signature: null,
      loading: false,
    }
  },
  mounted(){

  },
  methods: {
    open(signature = null) {
      this.show = true
      this.signature = signature
      this.$nextTick(() => {
        this.clear()
      })

      if (signature) {
        this.$nextTick(() => {
          this.$refs.signaturePad.fromDataURL(signature);
          this.$refs.signaturePad.lockSignaturePad();
          this.$refs.signaturePad.resizeCanvas();
        });
      }
    },
    close() {
      this.show = false
      this.signature = null
      this.loading = false
      this.$nextTick(() => {
        this.clear();
      });
    },
    clear() {
      if (this.$refs.signaturePad) {
        this.$refs.signaturePad.clearSignature();
        this.$refs.signaturePad.resizeCanvas();
      }
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) return;
      this.$emit('sign', data)
      if (this.closeOnSave) {
        this.close();
        return
      }
      this.loading = true;
    },
  }
}
</script>

<template>
<v-dialog v-model="show" max-width="700" persistent eager>
  <v-card>
    <v-toolbar dark color="primary" dense>
      <v-toolbar-title>
        {{ signature ? $t("label.show_signature") : $t("label.sign") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-sheet outlined rounded class="mt-5">
        <VueSignaturePad height="200px" width="100%" ref="signaturePad" />
      </v-sheet>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions v-if="!signature">
      <v-btn text @click="close" :disabled="loading">
        <v-icon v-if="$vuetify.breakpoint.mobile" left>mdi-close</v-icon>
        <span v-if="!$vuetify.breakpoint.mobile">{{$t("btn.close")}}</span>
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn v-if="!signature" color="error" @click="clear" :disabled="loading">
        <v-icon :left="!$vuetify.breakpoint.mobile">{{ 'mdi-eraser-variant' }}</v-icon>
        <span v-if="!$vuetify.breakpoint.mobile">{{ $t(`btn.clear`) }}</span>
      </v-btn>
      <v-btn v-if="!signature" color="primary" @click="save" :disabled="loading" :loading="loading">
        <v-icon :left="!$vuetify.breakpoint.mobile">mdi-content-save</v-icon>
        <span v-if="!$vuetify.breakpoint.mobile">{{$t("btn.save")}}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<style scoped>

</style>
