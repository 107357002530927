<template>
  <v-alert
    text
    prominent
    v-if="eventModel.type === 'PRACTICE'"
    :icon="icon"
    :color="color">
    <div>Student balance: {{ Number(studentBalance).toFixed(2) }} €</div>
    <div>
      Price per lesson (45 min): {{ Number(productPrice).toFixed(2) }} €
    </div>
    <div>Total price: {{ Number(totalPrice).toFixed(2) }} €</div>
    <div v-if="!eventModel.id">
      Should remain: {{ Number(remainedBalance).toFixed(2) }} €
    </div>
  </v-alert>
</template>

<script>
export default {
  name: "PracticeCalculations",
  props: {
    eventModel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    icon() {
      return this.totalPrice > this.studentBalance
        ? "mdi-close"
        : this.totalPrice
        ? "mdi-check"
        : "mdi-dots-horizontal";
    },
    color() {
      return this.totalPrice > this.studentBalance
        ? "red"
        : this.totalPrice
        ? "green"
        : "grey";
    },
  },
};
</script>

<style lang="scss" scoped></style>
