<template>
  <router-link
    class="category-item d-flex flex-column align-center justify-start pa-2 text-decoration-none"
    :to="{ name: 'calendar.appointment.evaluation.category', params: { categoryId: category.id } }"
  >
    <v-badge
      v-if="category.evaluatedItemsAmount"
      class="absolute-badge"
      color="error"
      :content="category.evaluatedItemsAmount"
    />

    <InsightsIcon :icon="category.icon" :alt="category.name" min-width="80" :width="80" :height="80"/>

    <span class="mt-3 text-center d-block" style="font-size: 0.8rem !important;">
      {{ category.name }}
    </span>

    <span v-if="category.description" class="d-block text-center grey--text" style="font-size: 0.7rem !important;">
      {{ category.description }}
    </span>
  </router-link>
</template>

<script>

import InsightsIcon from '@/components/insights/InsightsIcon.vue'

export default {
  name: 'EvaluationCategoryItem',
  components: { InsightsIcon },
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  computed: {
    icon() {
      try {
        return require(`@/assets/insights/icons/${this.category.icon}`)
      } catch (e) {
        return require(`@/assets/insights/icons/no-icon.svg`)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.category-item{
  border-radius: 5px;
  border: solid 1px #ccc;
  position: relative;
}
.absolute-badge{
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
