import router from "@/router";

export default class GoTo {
  constructor(routeName) {
    // split the route name by ? and store the first part in routeName and the second as query
    const [routeNameWithoutQuery, query] = routeName.split("?");
    this.routeName = routeNameWithoutQuery;
    // split query by & and store the key value pairs in an object
    this.queryObject = {};
    if (query) {
      query.split("&").forEach(q => {
        const [key, value] = q.split("=");
        this.queryObject[key] = value;
      });
    }
  }

  run() {
    router.push({ name: this.routeName, query: this.queryObject });
  }
}
