<template>
  <v-card
      :class="`appointment ${isSecondaryVehicle ? 'secondary-vehicle' : ''}`"
      :style="`${eventStyle} ${eventPosition}`"
      :disabled="disabled"
  >
    <div class="d-flex justify-space-between">
      <div class="ellipsis">{{ startTime }} - {{ endTime }}</div>
      <div class="license-block">
        {{ infoString }}
        <v-icon v-if="isSecondaryVehicle" small :color="appointment.fontColor">mdi-alpha-z-box</v-icon>
      </div>
    </div>
    <div class="d-flex justify-space-between">
      <div class="ellipsis">{{ appointment.teacherName }}</div>
      <v-icon v-if="appointment.isManualB197" small :color="appointment.fontColor">mdi-alpha-s-box</v-icon>
    </div>
    <div class="ellipsis">{{ appointment.studentName }}</div>

    <v-overlay :value="loading" absolute opacity="0.2">
      <v-progress-circular indeterminate size="25"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { parseISO, startOfDay, differenceInMinutes, format, addMinutes } from 'date-fns'
import LicenseModel from '@/store/models/LicenseModel'
import LessonTypeModel from '@/store/models/LessonTypeModel'

export default  {
  name: "VehicleAppointment",
  props: {
    appointment: {
      type: Object,
      required: true
    },
    vehicleId: {
      type: [Number, String],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('vehiclesCalendar', ['shorthands']),
    ...mapGetters('vehiclesCalendar', ['oneMinuteSize']),
    isSecondaryVehicle() {
      return this.vehicleId && this.vehicleId === this.appointment.secondaryVehicleId
    },
    licenseName() {
      return LicenseModel.find(this.appointment.licenseId).name
    },
    lessonTypeName() {
      return this.shorthands[LessonTypeModel.find(this.appointment.lessonTypeId).name]
    },
    infoString() {
      return (this.lessonTypeName ? this.lessonTypeName + ' - ' : '') +  this.licenseName
    },
    parsedDate(){
      return parseISO(this.appointment.start)
    },
    startTime() {
      return format(this.parsedDate, 'HH:mm')
    },
    endTime() {
      return format(addMinutes(this.parsedDate, this.appointment.duration), 'HH:mm')
    },
    positionLeft() {
      const parsedDate = parseISO(this.appointment.start)
      return differenceInMinutes(parsedDate, startOfDay(parsedDate))
    },
    eventPosition() {
      return `width: ${this.appointment.duration * this.oneMinuteSize }px; left: ${this.positionLeft * this.oneMinuteSize}px;`
    },
    eventStyle() {
      return `backgroundColor: ${this.appointment.bodyColor}; color: ${this.appointment.fontColor};`
    },
  }
}
</script>

<style lang="scss" scoped>
.appointment{
  height: 50px;
  position: absolute;
  padding: 2px 5px;
  box-sizing: border-box;
  font-size: 10px;
  line-height: normal;
  z-index: 2;
}
.ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.license-block{
  min-width: 65px;
  font-size: 10px;
  font-weight: 900;
  text-align: right;
}
.secondary-vehicle{
  cursor: not-allowed;
}
</style>
