<template>
  <div class="skeleton mt-3">
    <v-row class="mb-2">
      <v-col cols="2" v-for="item in 6" :key="item">
        <v-skeleton-loader type="button" />
      </v-col>
    </v-row>

    <v-row class="mb-2">
      <v-col>
        <v-skeleton-loader type="heading" />
      </v-col>
    </v-row>

    <v-row class="mb-2">
      <v-col cols="5">
        <v-skeleton-loader type="heading" />
      </v-col>
      <v-col cols="3">
        <v-skeleton-loader type="heading" />
      </v-col>
      <v-col cols="1">
        <v-skeleton-loader type="heading" />
      </v-col>
      <v-col cols="3">
        <v-skeleton-loader type="heading" />
      </v-col>
    </v-row>

    <v-row class="mb-2">
      <v-col>
        <v-skeleton-loader type="heading" />
      </v-col>
    </v-row>

    <v-row class="mb-2">
      <v-col>
        <v-skeleton-loader type="heading" />
      </v-col>
      <v-spacer />
      <v-col cols="6">
        <v-skeleton-loader type="heading" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "OfftimeLoader",
};
</script>

<style lang="scss">
.skeleton {
  .v-skeleton-loader__heading {
    width: 100%;
  }
  .v-skeleton-loader__button {
    width: 100%;
  }
}
</style>
