<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'school-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>{{ $t("route.schoolSettings.children.eventStyles") }}</v-card-title>

      <v-spacer />

      <v-btn
        text
        color="primary"
        @click="save"
        :loading="loadingForm"
        :disabled="loadingForm || loadingDataForEventTypes"
      >
        {{ $t("btn.save") }}
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-row class="mx-1 my-2" no-gutters>
        <v-col cols="12" md="6" lg="9">
          <v-switch
            class="mt-0 pt-0 d-flex align-center"
            style="height: 38px"
            v-model="teacherColorLine"
            inset
            :label="$t('label.teacher_color_line')"
            hide-details
            dense
            :loading="loadingTeacherColorLine"
            :disabled="loadingTeachers" />
        </v-col>

        <v-col cols="12" md="6" lg="3">
          <div class="d-flex flex-row" v-if="teacherColorLine">
            <v-select
              column
              mandatory
              v-model="teacherId"
              solo
              hide-details
              dense
              :items="teachersAsOptions"
              :disabled="loadingTeachers" />
          </div>
        </v-col>
      </v-row>

      <v-divider class="mt-3 mb-7" />

      <v-row v-if="loadingDataForEventTypes">
        <v-col
          cols="12"
          md="6"
          lg="3"
          v-for="eventSkeleton in Array.from(Array(12).keys())"
          :key="eventSkeleton">
          <v-skeleton-loader
            class="mx-auto"
            type="card-heading,image"
            height="140px"
          />
        </v-col>
      </v-row>

      <template v-else>
        <v-row>
           <v-col cols="12" md="6" lg="3" v-for="eventType in eventTypes" :key="eventType.id">
             <v-menu
                 left
                 z-index="101"
                 nudge-bottom="30"
                 :close-on-content-click="false"
                 :offset-x="!$vuetify.breakpoint.mobile"
                 :offset-y="$vuetify.breakpoint.mobile"
                 :disabled="eventType.id === 'PLACEHOLDER' && showRequestColor"
             >
               <template v-slot:activator="{ on, attrs }">
                 <div v-bind="attrs" v-on="on">
                   <EventCard
                       :body-color="eventType.bodyColor"
                       :font-color="eventType.fontColor"
                       :type="eventType.type"
                       :lesson-type-name="eventType.lessonType"
                       :teacher="eventCardTeacherOptions"
                   />
                 </div>
               </template>

               <EventColorForm
                   :event-type="eventType.type"
                   :lesson-type-name="eventType.lessonType"
                   :font-color="eventType.fontColor"
                   :body-color="eventType.bodyColor"
                   @colorsUpdate="data => onEventTypeUpdate(eventType.id, data)"
               />
             </v-menu>

             <template v-if="eventType.type === 'PLACEHOLDER'">
               <v-tooltip bottom max-width="400" color="#333" opacity="1">
                 <template v-slot:activator="{ on, attrs }">
                   <v-icon @click="toggle" v-bind="attrs" v-on="on" class="mr-2">
                     mdi-toggle-switch{{ showRequestColor ? "" : "-off" }}
                   </v-icon>
                 </template>
                 <span>{{ $t('messages.warn_about_requested_placeholder_color') }}</span>
               </v-tooltip>
               <span>{{ $t('label.preview_request') }}</span>
             </template>
           </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <span class="subtitle-1">{{ $t(`event_type.PRACTICE`) }}</span>

            <v-divider class="my-1" />

            <v-row>
              <v-col
                  cols="12"
                  md="6"
                  lg="3"
                  v-for="lessonType in practiceLessonTypes"
                  :key="lessonType.id"
              >
                <v-menu
                    left
                    :close-on-content-click="false"
                    :offset-x="!$vuetify.breakpoint.mobile"
                    :offset-y="$vuetify.breakpoint.mobile"
                    nudge-bottom="30"
                    z-index="101"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <EventCard
                          :body-color="lessonType.bodyColor"
                          :font-color="lessonType.fontColor"
                          :lesson-type-name="lessonType.lessonType"
                          :teacher="eventCardTeacherOptions"
                      />
                    </div>
                  </template>

                  <EventColorForm
                      :event-type="lessonType.type"
                      :lesson-type-name="lessonType.lessonType"
                      :font-color="lessonType.fontColor"
                      :body-color="lessonType.bodyColor"
                      @colorsUpdate="data => onLessonTypeUpdateByName(lessonType.lessonType, data)"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
         <v-col cols="12">
           <span class="subtitle-1">{{ $t(`event_type.THEORY`) }}</span>

           <v-divider class="my-1"/>

           <v-row>
             <v-col
                 cols="12"
                 md="6"
                 lg="3"
                 v-for="lessonType in theoryLessonTypes"
                 :key="lessonType.id"
             >
               <v-menu
                   left
                   :close-on-content-click="false"
                   :offset-x="!$vuetify.breakpoint.mobile"
                   :offset-y="$vuetify.breakpoint.mobile"
                   nudge-bottom="30"
                   z-index="101"
               >
                 <template v-slot:activator="{ on, attrs }">
                   <div v-bind="attrs" v-on="on">
                     <EventCard
                         :body-color="lessonType.bodyColor"
                         :font-color="lessonType.fontColor"
                         :lesson-type-name="lessonType.lessonType"
                         :teacher="eventCardTeacherOptions"
                     />
                   </div>
                 </template>

                 <EventColorForm
                     :event-type="lessonType.type"
                     :lesson-type-name="lessonType.lessonType"
                     :font-color="lessonType.fontColor"
                     :body-color="lessonType.bodyColor"
                     @colorsUpdate="data => onLessonTypeUpdate(lessonType.id, data)"
                 />
               </v-menu>
             </v-col>
           </v-row>
         </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <span class="subtitle-1">{{ $t(`event_type.SIMULATOR`) }}</span>

            <v-divider class="my-1"/>

            <v-row>
              <v-col
                  cols="12"
                  md="6"
                  lg="3"
                  v-for="lessonType in simulatorLessonTypes"
                  :key="lessonType.id"
              >
                <v-menu
                    left
                    :close-on-content-click="false"
                    :offset-x="!$vuetify.breakpoint.mobile"
                    :offset-y="$vuetify.breakpoint.mobile"
                    nudge-bottom="30"
                    z-index="101"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <EventCard
                          :body-color="lessonType.bodyColor"
                          :font-color="lessonType.fontColor"
                          :lesson-type-name="lessonType.lessonType"
                          :teacher="eventCardTeacherOptions"
                      />
                    </div>
                  </template>

                  <EventColorForm
                      :event-type="lessonType.type"
                      :lesson-type-name="lessonType.lessonType"
                      :font-color="lessonType.fontColor"
                      :body-color="lessonType.bodyColor"
                      @colorsUpdate="data => onLessonTypeUpdate(lessonType.id, data)"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </div>
</template>

<script>
import EventTypeModel from "@/store/models/EventTypeModel";
import LessonTypeModel from "@/store/models/LessonTypeModel";
import TeacherModel from "@/store/models/TeacherModel";

import teachersService from "@/services/teachersService";
import schoolService from "@/services/schoolService";

import school from "@/utils/mixins/school";
import responsive from "@/utils/mixins/responsive";

import EventCard from "@/components/school/settings/EventCard";
import EventColorForm from "@/components/school/settings/EventColorForm";

import { mapActions } from "vuex";
import Color from "color";
import AppointmentModel from '@/store/models/AppointmentModel'

export default {
  name: "EventStyles",
  components: { EventColorForm, EventCard },
  mixins: [school, responsive],
  data() {
    return {
      loadingTeachers: true,
      loadingStudents: true,
      loadingEventTypes: true,
      loadingLessonTypes: true,
      loadingForm: false,
      teacherId: "",
      teacherColorLine: true,
      loadingTeacherColorLine: false,
      showRequestColor: false,
      placeholderBodyColor: null,
      form: {
        eventTypes: [],
        lessonTypes: []
      }
    };
  },
  mounted() {
    teachersService
      .load()
      .then(response => {
        TeacherModel.insert({ data: response.data });

        this.teacherId = this.teachers[0]?.id;

        if (this.teachers.length === 0) {
          TeacherModel
              .insert({ data: { id: 1, firstName: "Manni", lastName: "Muster", color: "#76ab26", }, })
              .then(() => this.teacherId = 1);
        }
      })
      .finally(() => this.loadingTeachers = false);

    this.teacherColorLine = this.school.showEventTeacherLine;
  },
  computed: {
    loadingDataForEventTypes() {
      return this.loadingTeachers;
    },
    eventTypes() {
      return EventTypeModel
        .all()
        .map(eventType => ({
          id: eventType.id,
          type: eventType.id,
          fontColor: eventType.fontColor,
          bodyColor: eventType.bodyColor,
          showMenu: false,
        }))
          .filter(lessonType => !['PRACTICE', 'THEORY', 'SIMULATOR'].includes(lessonType.type));
    },
    lessonTypes() {
      const types =  LessonTypeModel.all()
          .map(lessonType => ({
            id: lessonType.id,
            type: lessonType.eventTypeId,
            lessonType: lessonType.name,
            fontColor: lessonType.fontColor,
            bodyColor: lessonType.bodyColor,
            lessonTypeId: lessonType.id,
            lessonTypeGroup: lessonType.group,
            showMenu: false,
          }))

      return types.filter((value, index, self) => { // filter unique lessonTypes
        return self.findIndex(v => v.lessonType === value.lessonType) === index;
      })
    },
    practiceLessonTypes() {
      return this.lessonTypes.filter(lessonType => lessonType.type === 'PRACTICE');
    },
    theoryLessonTypes() {
      return this.lessonTypes.filter(lessonType => lessonType.type === 'THEORY');
    },
    simulatorLessonTypes() {
      return this.lessonTypes.filter(lessonType => lessonType.type === 'SIMULATOR');
    },
    teacher() {
      return TeacherModel.find(this.teacherId) || {};
    },
    teachers() {
      return TeacherModel.query().limit(3).get();
    },
    teachersAsOptions() {
      return this.teachers.map((teacher) => ({ text: teacher.fullName, value: teacher.id, }));
    },
    eventCardTeacherOptions() {
      return { colorLine: this.teacherColorLine ? this.teacher.color : '', fullName: this.teacher.fullName }
    }
  },
  methods: {
    ...mapActions("school", { loadSchool: "load" }),
    toggle() {
      this.showRequestColor = !this.showRequestColor;

      const placeholderEventTypeIndex = this.eventTypes.findIndex(eventType => eventType.id === 'PLACEHOLDER');
      const placeholderEventTypeBodyColor = this.eventTypes[placeholderEventTypeIndex].bodyColor
      const lightenPlaceholderBodyColor = Color(placeholderEventTypeBodyColor).lighten(0.5).hex();

      if (this.showRequestColor) this.placeholderBodyColor = placeholderEventTypeBodyColor

      this.$set(
        this.eventTypes[placeholderEventTypeIndex],
        "bodyColor",
        this.showRequestColor ? lightenPlaceholderBodyColor : this.placeholderBodyColor
      )
    },
    changeFontColor(e) {
      this.fontColor = e;
    },
    resetColorData() {
      this.bodyColor = "";
      this.fontColor = "";
      this.name = "";
    },
    onEventTypeUpdate (eventTypeId, data) {
      EventTypeModel.update({
        where: eventTypeId,
        data: {
          fontColor: data.fontColor,
          bodyColor: data.bodyColor,
        }
      })

      if (eventTypeId === AppointmentModel.TYPES.SIMULATOR) this.updateSimulatorLessonType()
    },
    onLessonTypeUpdate(lessonTypeId, data) {
      LessonTypeModel.update({
        where: lessonTypeId,
        data: {
          fontColor: data.fontColor,
          bodyColor: data.bodyColor,

        }
      })
    },
    onLessonTypeUpdateByName(name, data) {
      LessonTypeModel.update({
        where: (type) => {
          return type.name === name
        },
        data: {
          fontColor: data.fontColor,
          bodyColor: data.bodyColor,

        }
      })
    },
    fillForm(type, id, data) {
      const formTypeIndex = this.form[type].findIndex(item => item.id === id);

      if (formTypeIndex !== -1) {
        this.form[type][formTypeIndex].fontColor = data.fontColor;
        this.form[type][formTypeIndex].bodyColor = data.bodyColor;
        this.form[type][formTypeIndex].name = data.name;

        return
      }

      this.form[type].push({
        id: id,
        fontColor: data.fontColor,
        bodyColor: data.bodyColor,
      })
    },
    save() {
      this.fillFormWithEventTypes()
      this.fillFormWithLessonTypes()

      this.loadingForm = true;
      schoolService
        .updateEventColorsSettings({ ...this.form, showEventTeacherLine: this.teacherColorLine })
        .then(() => {
          this.$snackbar.show({
            message: this.$t("messages.successfully_updated"),
            color: "success",
            timeout: 2000,
          });
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    fillFormWithLessonTypes() {
      // const filteredTypes = LessonTypeModel.all().filter((value, index, self) => { // filter unique lessonTypes
      //   return self.findIndex(v => v.name === value.name) === index;
      // })
      // console.log({filteredTypes})
      LessonTypeModel.all().forEach(lessonType => {
        this.fillForm('lessonTypes', lessonType.id, {
          fontColor: lessonType.fontColor,
          bodyColor: lessonType.bodyColor,
        })
      })
    },
    fillFormWithEventTypes() {
      EventTypeModel.query()
        .get()
        .forEach(eventType => this.fillFormWithEventType(eventType.id, eventType))

      if (this.practiceLessonTypes[0]) {
        this.fillFormWithEventType('PRACTICE', this.practiceLessonTypes[0])
      }

      if (this.theoryLessonTypes[0]) {
        this.fillFormWithEventType('THEORY', this.theoryLessonTypes[0])
      }

      if (this.simulatorLessonTypes[0]) {
        this.fillFormWithEventType('SIMULATOR', this.simulatorLessonTypes[0])
      }
    },
    updateSimulatorLessonType() {
      const simulatorType = this.eventTypes.find(type => type.id === AppointmentModel.TYPES.SIMULATOR)

      LessonTypeModel.update({
        where: (lessonType) => {
          return lessonType.eventTypeId === AppointmentModel.TYPES.SIMULATOR
        },
        data: {
          fontColor: simulatorType.fontColor,
          bodyColor: simulatorType.bodyColor,
        }
      })
    },
    fillFormWithEventType(eventType, { fontColor, bodyColor }) {
      this.fillForm('eventTypes', eventType, { fontColor, bodyColor, })
    }
  },
};
</script>

<style lang="scss" scoped></style>
