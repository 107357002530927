<template>
  <v-row>
    <v-col :class="`d-flex justify-${sign ? 'space-between' : 'end'}`">
      <v-dialog v-model="dialog" eager max-width="700" :persistent="!sign"  @click:outside="dialog = false" @keydown.esc="dialog = false">
        <v-card>
          <v-toolbar dark color="primary" dense>
            <v-toolbar-title>
              {{ $t("label.sign") }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-divider></v-divider>

          <v-card-text>
            <v-sheet class="mt-5" outlined rounded>
              <v-skeleton-loader v-if="loading" type="image"/>
              <VueSignaturePad
                v-show="!loading"
                :key="loading"
                v-resize="redrawInsert"
                height="200px"
                width="100%"
                ref="signaturePad"
                :options="{ onEnd: onEnd }"
              />
            </v-sheet>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn text color="primary" @click="dialog = false">
              <v-icon v-if="$vuetify.breakpoint.mobile" left>mdi-close</v-icon>
              <span v-if="!$vuetify.breakpoint.mobile">{{$t("btn.close")}}</span>
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn color="error" :disabled="loading || isSignatureEmpty" :loading="loading" @click="destroy">
              <v-icon :left="!$vuetify.breakpoint.mobile">{{ 'mdi-eraser-variant' }}</v-icon>
              <span v-if="!$vuetify.breakpoint.mobile">{{ $t(`btn.clear`) }}</span>
            </v-btn>

            <v-btn color="primary" :loading="loading" :disabled="loading || isSignatureEmpty || !!sign" @click="save">
              <v-icon :left="!$vuetify.breakpoint.mobile">mdi-content-save</v-icon>
              <span v-if="!$vuetify.breakpoint.mobile">{{$t("btn.save")}}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-list-item class="pa-0">
        <v-list-item-content>
          <v-list-item-title>{{ $t("label.sign") }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-chip
            small
            :color="!sign ? 'primary' : 'secondary'"
            text-color="white"
            @click="open(sign)"
          >
            {{ sign ? $t("label.show_signature") : $t("label.sign") }}
          </v-chip>
        </v-list-item-icon>
      </v-list-item>
    </v-col>
  </v-row>
</template>

<script>
import studentsService from "@/services/studentsService";
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: "EducationPassedSignature",
  props: {
    studentId: {
      type: [Number, String],
      required: true,
    },
    licenseId: {
      type: [Number, String],
      required: true,
    },
    sign: {
      type: String,
    },
  },
  data: () => ({
    dialog: false,
    loading: false,
    isSignatureEmpty: true,
  }),
  methods: {
    open(signature) {
      this.dialog = true
      this.clear();
      this.$nextTick(() => {
        this.$refs.signaturePad.resizeCanvas();
      });
      if (signature) {
        this.$nextTick(() => {
          this.$refs.signaturePad.fromDataURL(signature);
          this.$refs.signaturePad.lockSignaturePad();
          this.updateEmptyStatusOfSignature();
        });
      }
    },
    clear() {
      this.isSignatureEmpty = true
      this.$refs.signaturePad.clearSignature();
    },
    clickClear() {
      this.clear()
      this.updateEmptyStatusOfSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      const params = {
        educationType: this.type,
        licenseId: this.licenseId,
        educationSignature: data,
      };
      if (isEmpty) return;
      studentsService
        .setEducationSignature(this.studentId, params)
        .then((resp) => (this.$emit('emitSign', resp.data.educationSignature || {})))
        .finally(() => (this.dialog = false));
    },

    destroy () {
      if (this.sign) {
        showConfirm(
          this.$t('btn.confirm'),
          this.$t('messages.are_your_sure_delete'),
          () => {
            const params = {
              educationType: this.type,
              licenseId: this.licenseId,
              educationSignature: '',
            };
            studentsService.setEducationSignature(this.studentId, params)
              .then((resp) => {
                this.$emit('emitSign', resp.data.educationSignature)
                this.clickClear()
              })
              .catch(error => {
                const response = error.response || {}
                const responseData = response.data || {}

                if (responseData.message) {
                  this.$snackbar.show({
                    message: responseData.message,
                    color: 'error',
                  })
                }
              })
              .finally(() => {
                this.deleting = false
              })
          }
        )
      } else {
        this.clickClear()
      }
    },

    redrawInsert () {
      this.$refs.signaturePad.resizeCanvas();

      if (this.signature) {
        this.$refs.signaturePad.fromDataURL(this.signature);
      }
    },
    onEnd() {
      this.updateEmptyStatusOfSignature()
    },
    updateEmptyStatusOfSignature() {
      const { isEmpty } = this.$refs.signaturePad.saveSignature();
      this.isSignatureEmpty = isEmpty

      if (isEmpty) {
        this.$refs.signaturePad.openSignaturePad();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
//.signature-pad {
//  width: 100%;
//  aspect-ratio: 5 / 2;
//}
</style>
