<template>
  <div>
    <v-row dense>
      <v-col cols="12" lg="4">
        <v-dialog
          ref="dialog"
          v-model="menu"
          :return-value.sync="filters.periodDates"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              readonly
              :value="formattedDates"
              :label="$t('label.filter_by_date')"
              prepend-inner-icon="mdi-calendar"
              clearable
              @click:clear="onPeriodDatesClear"
              v-bind="attrs"
              v-on="on"
              hide-details
              solo
              max-width="290px"
            />
          </template>

          <v-date-picker v-model="filters.periodDates" scrollable range>
            <v-spacer />

            <v-btn text color="primary" @click="menu = false">{{$t("btn.cancel") }}</v-btn>
            <v-btn text color="primary" @click="onPeriodDatesDialogOK">{{$t("btn.ok") }}</v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-col cols="12" lg="3">
        <v-select
          v-model="filters.type"
          :label="$t('form.event_type')"
          :items="eventTypes"
          hide-details
          dense
          item-value="id"
          :menu-props="{ offsetY: true }"
          solo
        />
      </v-col>

      <v-col>
        <v-btn text color="primary" @click="filter" :loading="loadingEvents">{{ $t("btn.filter_results") }}</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-chip-group
            v-if="eventsYears.length > 1"
            v-model="filters.year"
            hide-details
            class="pa-0"
            active-class="primary--text"
        >
          <v-chip
              class="ma-0 mr-1"
              v-for="year in eventsYears"
              :key="year"
              :value="year"
          >
            <v-avatar left color="white">{{ eventCountByYear[year] }}</v-avatar>{{ year }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>

    <div class="mt-4">
      <StudentAgendaListLoader v-if="loadingEvents" class="mb-4" />

      <template v-else>
        <v-row v-for="(eventsByMonth, month) in eventsByMonth" :key="month">
          <v-col cols="12" class="flex-column">
            <div class="d-flex">
              <span class="text-h6 text--secondary">{{ month }}</span>
            </div>

            <v-divider class="my-2 mb-4" />

            <AppointmentCard
              v-for="event in eventsByMonth"
              :key="event.id"
              :appointment="event"
              @click.native="goToDetailsPage(event.id)" />
          </v-col>
        </v-row>

        <empty-box v-if="Object.values(eventsByMonth).length === 0" />
      </template>
    </div>
  </div>
</template>

<script>
import { compareAsc, compareDesc, format, parseISO } from 'date-fns'
import i18n from "@/plugins/i18n";

import studentsService from "@/services/studentsService";
import EventTypeModel from "@/store/models/EventTypeModel";

import StudentAgendaListLoader from "@/components/skeleton-loaders/StudentAgendaListLoader";
import AppointmentCard from '@/components/appointment/AppointmentCard'
import AppointmentModel from '@/store/models/AppointmentModel'
import LocalizationManager from "@/helpers/LocalizationManager";

export default {
  name: "AgendaTab",
  components: { AppointmentCard, StudentAgendaListLoader },
  props: {
    studentId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    loading: false,
    menu: false,
    dates: [],
    eventTypes: [
      { id: "ALL", text: i18n.t("label.all") },
      { id: "PLACEHOLDER", text: i18n.t("event_type.PLACEHOLDER") },
      { id: "PRACTICE", text: i18n.t("event_type.PRACTICE") },
      { id: "THEORY", text: i18n.t("event_type.THEORY") },
      { id: "SPECIAL", text: i18n.t("event_type.SPECIAL") },
      { id: "SIMULATOR", text: i18n.t("event_type.SIMULATOR") },
    ],
    filters: {
      type: "ALL",
      year: null,
      periodDates: [],
    },
    loadingEvents: false,
  }),
  computed: {
    events() {
      return AppointmentModel.all()
    },
    eventsOrderedByStartDate() {
      return [...this.events].sort((first, second) =>
        compareAsc(parseISO(second.startDate), parseISO(first.startDate))
      );
    },
    eventCountByYear() {
      const countByYear = {};
      this.events.forEach((event) => {
        const year = format(
          parseISO(event.start),
          "yyyy",
          { locale: LocalizationManager.dateFnsLocale }
        );
        if (!(year in countByYear)) countByYear[year] = 0;
        countByYear[year] = countByYear[year] + 1;
      });
      return countByYear;
    },
    eventsYears() {
      return Object.keys(this.eventCountByYear).sort(
        (first, second) => second - first
      );
    },
    eventsByMonth() {
      let events = this.eventsOrderedByStartDate

      let monthFormat = "MMMM, yyyy";
      if (this.filters.year) {
        events = events.filter((event) => event.start.includes(this.filters.year))
        monthFormat = "MMMM";
      }

      let eventsByMonth = {};

      events.forEach((event) => {
        const month = format(parseISO(event.start), monthFormat,{ locale: LocalizationManager.dateFnsLocale });
        if (!(month in eventsByMonth)) eventsByMonth[month] = [];
        eventsByMonth[month].push(event);
      });

      Object.keys(eventsByMonth).forEach((month) => {
        eventsByMonth[month] = eventsByMonth[month]
            .sort((first, second) => compareDesc(parseISO(first.start), parseISO(second.start)))
      })

      return eventsByMonth;
    },
    formattedDates() {
      const periodDates = this.filters.periodDates;
      if (periodDates.length) {
        const formatted = periodDates
          .sort()
          .map((item) => format(parseISO(item), "dd.MM.yyyy"));

        return formatted.length > 1
          ? `vom ${formatted[0]} bis ${formatted[1]}`
          : formatted;
      }
      return periodDates;
    },
    eventBodyColorByType() {
      const byType = {};
      EventTypeModel.all().forEach((eventType) => {
        if (!(eventType.id in byType))
          byType[eventType.id] = eventType.bodyColor;
      });
      return byType;
    },
    sixthMonth() {
      const date = new Date();
      return format(date.setMonth(date.getMonth() + 6), "MMMM", { locale: LocalizationManager.dateFnsLocale });
    },
    seventhMonth() {
      const date = new Date();
      return format(date.setMonth(date.getMonth() + 6), "MMMM", { locale: LocalizationManager.dateFnsLocale });
    },
  },
  mounted () {
    this.filter();
  },
  methods: {
    filter() {
      const startDates = this.filters.periodDates
          .sort((first, second) => compareAsc(parseISO(first), parseISO(second)));

      const type = this.filters.type === "ALL" ? null : this.filters.type;

      this.loadAgenda({ startDates, type });
    },
    loadAgenda(params) {
      this.loadingEvents = true;
      studentsService
        .agenda(this.studentId, params)
        .then(eventsResponse => AppointmentModel.create({ data: eventsResponse.data || []}))
        .finally(() => this.loadingEvents = false);
    },
    onPeriodDatesClear() {
      this.filters.periodDates = [];
    },
    onPeriodDatesDialogOK() {
      this.$refs.dialog.save(this.filters.periodDates.sort());
    },
    goToDetailsPage(id) {
      this.$router.push({name: 'calendar.appointment', params: { id }})
      sessionStorage.setItem('from', 'agenda');
    }
  },
};
</script>

<style scoped></style>
