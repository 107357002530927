<script>
import {defineComponent} from 'vue'
import invoicesService from "@/services/invoicesService";

export default defineComponent({
  name: "MarkInvoiceAsPaidDialog",
  data() {
    return {
      show: false,
      invoiceId: null,
      date: null,
      loading: false,
    }
  },
  methods: {
    open(invoiceId) {
      this.show = true
      this.date = (new Date()).toISOString().substr(0, 10)
      this.invoiceId = invoiceId
    },
    close() {
      this.show = false
      setTimeout(() => {
        this.invoiceId = null
      }, 300)
    },
    save() {
      this.loading = true;
      invoicesService
        .markAsPaid(this.invoiceId, this.date)
        .then((resp) => {
          this.$emit('paid', resp.data);
        })
        .finally(() => {
          this.loading = false;
          this.close();
        });
    }
  }
})
</script>

<template>
  <v-dialog
    v-model="show"
    width="320"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card tile>
      <v-toolbar class="mb-2 flex-grow-0" dark color="primary" dense>
        <v-toolbar-title>{{ $t('label.mark_as_paid') }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <p class="mt-2">
          {{ $t('label.mark_as_paid_description') }}
        </p>
        <v-row justify="center">
          <v-date-picker
            v-model="date"
            no-title first-day-of-week="1"
          />
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" :disabled="loading" :loading="loading" @click="save">
          <v-icon left>mdi-cash-check</v-icon>
          {{ $t('btn.save') }}
        </v-btn>
      </v-card-actions>
     </v-card>
  </v-dialog>
</template>

<style scoped>

</style>