import api from "@/utils/api";

export default {
  load(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/offices", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadItem(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/offices/${id}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  create(params) {
    return new Promise((resolve, reject) => {
      api
        .post("/offices", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  update(id, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/offices/${id}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  destroy(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/offices/${id}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
