import { Model } from "@vuex-orm/core";
import { parseISO, format, differenceInMinutes } from 'date-fns'

export default class VehicleReservationModel extends Model {
  static entity = "reservations";

  static fields() {
    return {
      id: this.attr(null),
      vehicleId: this.attr(null),
      teacherId: this.attr(null),
      teacherName: this.attr(null),
      from: this.string(""),
      to: this.string(""),
    };
  }

  get start() {
    return this.from
  }

  get end() {
    return this.from
  }

  get parsedStart() {
    return parseISO(this.from)
  }
  get parsedEnd() {
    return parseISO(this.to)
  }

  get date() {
    return format(this.parsedStart, "yyyy-MM-dd");
  }

  get duration() {
    return differenceInMinutes(this.parsedEnd, this.parsedStart)
  }

}
