<template>
  <div>
    <v-row>
      <TeachersField
        v-if="!isTeacher"
        :cols="12"
        :model="model"
        :eventDuration="eventDuration"
        :filteredTeachers="filteredTeachers"
        :disabled="!!model.id || isPast" />
      <StudentsField
        v-if="!holiday"
        :cols="12"
        :disabled="disabled || isPast"
        :model="model"
        :eventDuration="eventDuration"
        :filteredStudents="filteredStudents"
        :multiple="true"
        :clearable="true"
        :validation="false" />
    </v-row>

    <TimeFields
      :disabled="disabled || isPast"
      :model="model"
      :eventDuration="eventDuration" />
    <TitleField :disabled="disabled || isPast" :model="model" />
    <TextField :disabled="disabled || isPast" :model="model" label="Title" />
    <v-row>
      <v-col>
        <v-switch
          v-model="model.isWorkTime"
          :disabled="isPast"
          inset
          :label="$t('label.teacher_work_time')"
          hide-details></v-switch>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TeachersField from "@/components/calendar/eventModal/fields/TeachersField";
import StudentsField from "@/components/calendar/eventModal/fields/StudentsField";
import TimeFields from "@/components/calendar/eventModal/fields/TimeFields";
import TitleField from "@/components/calendar/eventModal/fields/TitleField";
import TextField from "@/components/calendar/eventModal/fields/TextField";
import user from "@/utils/mixins/user";
import { parseISO } from "date-fns";
import StudentModel from "@/store/models/StudentModel";
import DateRange from "@/helpers/dateRange";
import { mapGetters } from "vuex";

export default {
  name: "SpecialForm",
  mixins: [user],
  components: {
    TeachersField,
    StudentsField,
    TimeFields,
    TitleField,
    TextField,
  },
  props: {
    eventDuration: {
      type: Number,
      required: true,
    },
    filteredTeachers: {
      type: Array,
      required: true,
    },
    isPast: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    disabled() {
      return !this.model.teacher;
    },
    holiday() {
      const regions = this.schoolSettings.regions?.split(", ");
      return new DateRange().isHoliday(parseISO(this.model.date), regions);
    },
    filteredStudents() {
      if (!this.model.teacher) return [];

      return (
        StudentModel.query()
          .where("teachersIds", (teachersIds) =>
            teachersIds.includes(this.model.teacher.id)
          )
          .get() || []
      );
    },
  },
};
</script>
