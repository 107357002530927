<template>
<div>
  <v-skeleton-loader
      class="mb-2"
      v-for="nr in Array.from(Array(nrOfLines).keys())"
      :key="nr"
      type="list-item-avatar-two-line"
  />
</div>
</template>

<script>
export default {
  name: 'LicenseProgressCardsLoader',
  props: {
    nrOfLines: {
      type: Number,
      default: 3
    }
  }
}
</script>

<style scoped>

</style>
