<script>
import UserAvatar from "@/components/UserAvatar.vue";
import user from "@/utils/mixins/user";

export default {
  name: "ChildInfoCard",
  mixins: [user],
  components: {
    UserAvatar
  },
  data: () => ({
    loading: true,
    updating: false,
  }),
};
</script>

<template>
  <v-sheet class=" my-2 d-flex flex-column" elevation="2" rounded>
    <v-list two-line>
      <v-list-item>
        <v-list-item-avatar>
          <UserAvatar
            :alt="parentSelectedStudent.fullName"
            :path="parentSelectedStudent.avatar"
            size="40"
          />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ parentSelectedStudent.fullName }}</v-list-item-title>
          <v-list-item-subtitle
            v-if="parentSelectedStudent.bookAndPay"
            :class="parentSelectedStudent.balance > 0 || 'red--text'"
          >
            {{ parentSelectedStudent.balance | currency }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-sheet>
</template>

<style scoped>

</style>
