<template>
  <v-list class="pa-0" nav dense>
    <div class="d-flex flex-column mb-1">
      <span class="subtitle-1">{{ $t('label.requests') }}</span>
      <span v-if="appointmentIsPast" class="caption warning--text">
        {{ $t('messages.past_appointment_can_not_be_confirmed') }}
      </span>
    </div>

    <div class="items-grid">
       <template v-for="student in appointment.students">
        <v-list-item :key="student.id" dense>
          <v-list-item-avatar class="my-0" size="30">
            <UserAvatar size="30" :path="student.avatar" :alt="student.name"/>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{student.name}}</v-list-item-title>
            <v-list-item-subtitle>
              {{student.chosenLicense}}
              <span v-if="appointment.isManualLesson">, {{$t('lesson_type.manual_lessons')}}</span>
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action :class="{'flex-row': $vuetify.breakpoint.smAndUp}">
            <v-btn
              :class="!$vuetify.breakpoint.smAndUp ? 'mb-2' : 'mr-2'"
              v-if="canBeDeclined"
              x-small
              color="error"
              @click="declinePractice"
              :disabled="declineLoading || appointmentIsPast"
              :loading="declineLoading"
              :block="!$vuetify.breakpoint.smAndUp"
            >
              {{ $t('btn.decline') }}
            </v-btn>

            <v-btn
              x-small
              color="primary"
              @click="openApproveRequestDialog(student)"
              :disabled="appointmentIsPast"
              :block="!$vuetify.breakpoint.smAndUp"
            >
              {{ $t('btn.confirm') }}
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </div>

    <AppointmentConfirmDialog ref="appointmentConfirmDialog" @confirmed="$emit('confirmed')"/>
  </v-list>
</template>

<script>
import { isPast, parseISO } from 'date-fns'

import AppointmentConfirmDialog from '@/components/appointment/AppointmentConfirmDialog'
import appointmentService from '@/services/appointmentService'
import UserAvatar from '@/components/UserAvatar'
import user from '@/utils/mixins/user'
import { showConfirm } from '@/helpers/dialogs'

export default {
  name: 'AppointmentDetailsRequestsList',
  mixins: [user],
  components: { UserAvatar, AppointmentConfirmDialog },
  props: {
    appointment: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    declineLoading: false,
  }),
  computed: {
    appointmentIsPast() {
      return isPast(parseISO(this.appointment.start))
    },
    canBeDeclined() {
      return this.appointment.type === 'PRACTICE' && this.appointment.status === 'REQUESTED'
    }
  },
  methods: {
    openApproveRequestDialog (student) {
      this.$refs.appointmentConfirmDialog.open(this.appointment, student)
    },
    declinePractice() {
      showConfirm(
          this.$t('btn.confirm'),
          this.$t('messages.are_your_sure_decline_practice'),
          () => {
            this.declinePracticeLoading = true;
            appointmentService
                .decline_practice_request(this.appointment.id)
                .then(() => this.$emit('declined'))
                .catch((error) => {
                  const responseData = error.response.data || {};
                  if (responseData.message) {
                    this.$snackbar.show({ message: responseData.message, color: "error", });
                  }
                })
                .finally(() => {
                  this.declinePracticeLoading = false;
                });
          }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.items-grid {
  grid-gap: .5em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 400px));
  padding: 0;
}
</style>
