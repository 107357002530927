<template>
  <v-toolbar height="45" outlined flat>
    <v-app-bar-nav-icon @click="onNavigationDrawerInput" color="primary">
      <v-icon>mdi-filter-outline</v-icon>
    </v-app-bar-nav-icon>

    <v-btn
           icon
           color="primary"
           :disabled="loaderGetter"
           @click="loadAppointments(true)">
      <v-icon>mdi-calendar-refresh</v-icon>
    </v-btn>

    <template v-if="gridView">
      <CalendarRangeControls isMobile />

      <div class="ml-2">{{ currentMonth }}</div>

      <v-spacer />

      <div class="layout-controls mr-2">
        <v-btn
          v-if="calendarLayout === 'LAYOUT_WEEK'"
          class="ma-auto"
          text
          icon
          small
          color="primary"
          @click="updateCalendarLayout('LAYOUT_DAY')"
          ><v-icon>mdi-numeric-1-box-multiple-outline</v-icon></v-btn
        >
        <v-btn
          v-if="calendarLayout === 'LAYOUT_THREE_DAYS'"
          class="ma-auto"
          text
          icon
          small
          color="primary"
          @click="updateCalendarLayout('LAYOUT_WEEK')"
          ><v-icon>mdi-numeric-7-box-multiple-outline</v-icon></v-btn
        >
        <v-btn
          v-if="calendarLayout === 'LAYOUT_DAY'"
          class="ma-auto"
          text
          icon
          small
          color="primary"
          @click="updateCalendarLayout('LAYOUT_THREE_DAYS')"
          ><v-icon>mdi-numeric-3-box-multiple-outline</v-icon></v-btn
        >
      </div>
    </template>

    <v-spacer v-else />

    <v-btn text icon small @click="toggleCalendarView" color="primary">
      <v-icon>{{ gridView ? "mdi-view-agenda" : "mdi-view-grid" }} </v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { format } from "date-fns";

import CalendarRangeControls from "@/components/calendar/CalendarRangeControls";
import loadAppointments from '@/utils/mixins/loadAppointments';
import LocalizationManager from "@/helpers/LocalizationManager";


export default {
  name: "NavbarCalendarMobile",
  mixins: [loadAppointments],
  components: { CalendarRangeControls },
  data() {
    return {
      toggleView: false,
    };
  },
  props: {
    gridView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["activeDate", "days", "calendarLayout", "loaderGetter"]),
    ...mapState("messenger", ["unseenMessagesCount"]),
    mdLandscapeAndDown() {
      return (
        this.$vuetify.breakpoint.mdAndDown &&
        !(this.$vuetify.breakpoint.md && window.innerHeight < window.innerWidth)
      );
    },
    currentDate() {
      return format(new Date(this.activeDate), "dd MMM yyyy", { locale: LocalizationManager.dateFnsLocale });
    },
    currentMonth() {
      return format(new Date(this.activeDate), "MMM, yyyy", { locale: LocalizationManager.dateFnsLocale });
    },
    dateRange() {
      let range = this.days[0].originalDate;
      const rangeLength = this.days.length;
      if (rangeLength > 1) {
        range = `${this.days[0].date}-${this.days[0].month} - ${
          this.days[rangeLength - 1].date
        }-${this.days[rangeLength - 1].month}`;
      }
      return range;
    },
  },
  methods: {
    ...mapActions(["updateCalendarLayout"]),
    ...mapActions("messenger", { selectRoom: "selectRoom" }),
    toggleCalendarView() {
      this.$emit("toggle-view", (this.toggleView = !this.toggleView));
    },
    onNavigationDrawerInput(value) {
      this.$store.commit("main/toggle-filters-menu", value);
    },
  },
};
</script>

<style scoped></style>
