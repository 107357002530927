import api from "@/utils/api";

export default {
  load(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/meeting-places", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadItem(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/meeting-places/${id}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadFormData() {
    return new Promise((resolve, reject) => {
      api
        .get("/meeting-places/formData")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  create(params) {
    return new Promise((resolve, reject) => {
      api
        .post("/meeting-places", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  update(id, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/meeting-places/${id}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  destroy(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/meeting-places/${id}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
