<script>
export default {
  name: "ContentFooter"
}
</script>

<template>
  <div>
    Footer information
  </div>
</template>

<style scoped>

</style>