<template>
  <v-col cols="6" md="6" sm="12">
    <v-select
      @change="$emit('theoryStudentChange')"
      :disabled="disabled"
      v-model="model.allowedLicenses"
      :label="$t('form.licence')"
      :items="licenses"
      item-value="id"
      item-text="name"
      clearable
      multiple
      outlined
      hide-details
      dense
      type="number"
      :append-icon="!disabled ? 'mdi-menu-down' : ''"
      :menu-props="{ offsetY: true }" />
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AllowedLicenseField",
  props: {
    licenses: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
  },
};
</script>

<style lang="scss" scoped></style>
