<template>
<v-card>
  <v-card-title class="d-flex flex-column py-2">
    <LicenseIcon
        row
        fluid
        :height="45"
        class="mx-auto subtitle-1"
        :name="license.name"
        :icon="license.icon"
        :small-label="false"
    />
  </v-card-title>

  <v-divider/>

  <v-card-text class="py-2">
    <div class="mb-4">
      <div class="subtitle-1 mb-1">{{ $t('label.overall_progress') }}</div>
      <v-progress-linear
          rounded
          height="18"
          :value="licenseProgress.totalProgress"
          color="secondary"
      >
        {{licenseProgress.totalProgress}}%
      </v-progress-linear>
    </div>

    <div class="d-flex justify-start">
      <div class="flex-grow-1">
        <v-progress-circular
            class="mr-2"
            :rotate="360"
            :size="50"
            :width="6"
            :value="theoryProgress"
            :color="colorByProgress(theoryProgress)"
        >
          <span class="text-caption">{{theoryProgress}}%</span>
        </v-progress-circular>
        <span class="subtitle-2">{{ $t('label.theory')}}</span>
      </div>

      <div class="flex-grow-1" v-if="license.forPractice">
        <v-progress-circular
            class="mr-2"
            :rotate="360"
            :size="50"
            :width="6"
            :value="practiceProgress"
            :color="colorByProgress(practiceProgress)"
        >
          <span class="text-caption">{{practiceProgress}}%</span>
        </v-progress-circular>
        <span class="subtitle-2">{{ $t('label.practice') }}</span>
      </div>
    </div>
  </v-card-text>

  <v-divider/>

  <v-card-text class="py-2">
    <div class="d-flex justify-space-between">
      <span>{{ $t('lesson_type.theory_exam') }}</span>
      <span v-if="licenseRequirements.theoryExamRequired">{{ theoryExamEndDate ?? '-' }}</span>
      <span v-else>{{$t('label.not_required')}}</span>
    </div>
    <div class="d-flex justify-space-between">
      <span>{{ $t('lesson_type.practice_exam') }}</span>
      <span v-if="license.forPractice && licenseRequirements.practiceExamRequired">{{ practiceExamEndDate ?? '-'}}</span>
      <span v-else>{{$t('label.not_required')}}</span>
    </div>
  </v-card-text>

  <v-divider/>
  <v-card-actions>
    <v-avatar v-if="school.hasInsights" rounded size="20">
      <v-img :src="require('@/assets/img/evaluation_published.png')" v-if="licenseProgress.hasInsights" />
      <v-img :src="require('@/assets/img/evaluation_missing.png')" style="opacity: 0.5" v-else />
    </v-avatar>
    <v-spacer/>
    <v-btn small text :to="detailsTo">
      {{ $t('btn.details') }}
    </v-btn>
  </v-card-actions>
</v-card>
</template>

<script>
import LicenseIcon from '@/components/LicenseIcon.vue'
import LicenseModel from '@/store/models/LicenseModel'
import { format, parseISO } from 'date-fns'
import { mapGetters } from 'vuex'

export default {
  props: {
    licenseId: {
      type: [Number, String],
      required: true
    },
    licenseProgress: {
      type: Object,
      required: true
    },
    licenseRequirements: {
      type: Object,
      required: true
    },
    detailsTo: {
      type: [String, Object]
    }
  },
  components: { LicenseIcon },
  computed: {
    ...mapGetters("school", ["school"]),
    license() {
      return LicenseModel.find(this.licenseId) || {}
    },
    examsResults() {
      return this.licenseProgress.examResults || {}
    },
    theoryProgress() {
      return this.licenseProgress.theoryProgress
    },
    practiceProgress() {
      return this.licenseProgress.practiceProgress
    },
    theoryExamEndDate() {
      return this.examsResults.theoryExamPassedAt
          ? format(parseISO(this.examsResults.theoryExamPassedAt), "dd.MM.yyyy")
          : null;
    },
    practiceExamEndDate() {
      return this.examsResults.practiceExamEventId &&
      this.examsResults.practiceExamPassedAt
          ? format(
              parseISO(this.examsResults.practiceExamPassedAt),
              "dd.MM.yyyy"
          )
          : null;
    },
  },
  methods: {
    colorByProgress(progress) {
      if (progress >= 20 && progress < 80) return "warning";
      if (progress >= 80) return "secondary";

      return 'error'
    },
  }
}
</script>

<style scoped>

</style>
