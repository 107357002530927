import api from "@/utils/api";

export default {
  studentsWithNoAppointments: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("/widgets/students-without-appointments", {params})
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  upcomingAppointments(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/widgets/upcoming-appointments", {params})
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  upcomingRequests(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/widgets/upcoming-requests", {params})
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  appointmentsRequests: () => {
    return new Promise((resolve, reject) => {
      api
        .get("/widgets/appointments-requests")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  unpaidStudents: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("/widgets/unpaid-students", {params})
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
