<template>
  <v-list v-if="show" class="py-0">
    <div class="text-left px-2" v-if="!isSideBarMini">
      <span class="text-caption">{{ $t("label.school") }}</span>
    </div>

    <v-list-item
      class="participant"
      @click="loadRoom"
      :input-value="activeRoom && activeRoom.id === schoolRoom.id"
    >
      <v-list-item-avatar class="participant__avatar">
        <v-badge
          color="green"
          bottom
          right
          overlap
          dot
          offset-x="10"
          offset-y="10"
          :value="!!schoolRoom.online"
        >
          <UserAvatar :alt="school.name" :path="school.avatar" />
        </v-badge>
      </v-list-item-avatar>

      <v-list-item-content>
        <div class="participant__header d-flex justify-space-between">
          <div class="participant__title text-truncate mr-1">{{ school.name }}</div>
          <div v-if="schoolRoom && schoolRoom.lastMessage"
               class="participant__last-message d-flex justify-end text-caption">
            <MessageStatus :message="schoolRoom.lastMessage" />
          </div>
        </div>

        <div class="d-flex" style="width: 100%" v-if="schoolRoom">
          <div
            class="text-caption text-truncate text--secondary mr-2"
            v-if="schoolRoom.lastMessage"
          >
            <span v-if="schoolRoom.lastMessage.userId === currentUser.userId">{{ $t("label.you") }}:</span>

            {{ schoolRoom.lastMessage.message }}
          </div>
          <v-badge
            v-if="!isElter"
            class="ml-auto"
            color="red"
            bottom
            right
            overlap
            inline
            :value="!!schoolRoom.unSeenMessagesCount"
            :content="schoolRoom.unSeenMessagesCount"
          />
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MessageStatus from "@/components/messenger/MessageStatus";
import ChatRoom from "@/store/models/ChatRoom";
import messenger from "@/utils/mixins/messenger";
import UserAvatar from '@/components/UserAvatar'
import messengerService from "@/services/messengerService";
import axios from "axios";
import ChatParticipant from "@/store/models/ChatParticipant";

export default {
  name: "MessengerSchool",
  props: {
    appliedFilters: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [messenger],
  components: {
    UserAvatar,
    MessageStatus
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("school", ["school"]),
    ...mapGetters("messenger", ["schoolRoom"]),
    ...mapState("messenger", { isSideBarMini: "isSideBarMini" }),
    ...mapGetters("messenger", { schoolRoom: "schoolRoom" }),
    show() {
      return this.school && this.currentUser.type !== "school-administrator";
    },
    activeRoom() {
      return ChatRoom.query().find(this.$route.query.r);
    }
  },
  mounted() {
    if (this.show) {
      ChatRoom.insert({
        data: {
          title: this.school.name,
          schoolId: this.school.id
        }
      }).then(() => {
        const loadParticipantsRequests = this.school.administratorsUsersIds
          .map((loadParticipantsId) => messengerService.loadParticipant(loadParticipantsId));

        axios.all(loadParticipantsRequests).then((responses) => {
          ChatParticipant.insert({ data: responses.map(response => response.data || {}) });
        });
      });
    }
  },
  methods: {
    loadRoom() {
      this.setRoomInRouteQuery(this.schoolRoom);
    },
  },
};
</script>

<style scoped lang="scss">
.chat {
  .participant {
    &__header {
      max-width: 100%;
    }

    &__avatar {
      overflow: unset;
    }

    &__last-message {
      min-width: 65px;
    }
  }
}
</style>
