<template>
  <v-list-item class="pa-0" dense>
    <v-list-item-icon
      ><v-icon>{{ sendMethod.icon }}</v-icon></v-list-item-icon
    >

    <v-list-item-content>
      <div class="d-flex justify-space-between">
        <span class="text-subtitle-1">{{
          $t(`notifications.sendMethods.${sendMethod.key}`)
        }}</span>

        <v-switch
          class="mt-0"
          inset
          dense
          hide-details
          :loading="loading"
          :disabled="disabled"
          :input-value="isActive"
          @change="(value) => toggleSendMethod(value)" />
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import notificationsService from "@/services/notificationsService";
import NotificationsSettings from "@/store/models/NotificationsSettings";
import user from "@/utils/mixins/user";

export default {
  name: "NotificationMethod",
  props: {
    settingsArea: {
      type: String,
      required: true,
    },
    sendMethod: {
      type: Object,
      required: true,
    },
    userType: {
      type: String,
      default: "",
    },
    notificationGroup: {
      type: String,
      required: true,
    },
    settingsByGroup: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [user],
  data: () => ({
    loading: false,
  }),
  watch: {
    loading(value) {
      this.$emit("loading", value);
    },
  },
  computed: {
    settings() {
      return NotificationsSettings.all();
    },
    sendMethods() {
      return this.settingsByGroup.sendMethod;
    },
    isActive() {
      if (this.sendMethod.key === "app") {
        return this.sendMethods.some((sendMethod) =>
          ["broadcast", "database"].includes(sendMethod)
        );
      }

      return this.sendMethods.includes(this.sendMethod.key);
    },
  },
  methods: {
    toggleSendMethod(value) {
      const sendMethodsTypes = {
        app: ["broadcast", "database"],
        mail: ["mail"],
        push: ["push"],
      };

      let sendMethods = [...this.sendMethods];
      if (value) {
        sendMethods = [
          ...sendMethods,
          ...sendMethodsTypes[this.sendMethod.key],
        ];
      } else {
        sendMethods = sendMethods.filter((sendMethod) => {
          return !sendMethodsTypes[this.sendMethod.key].includes(sendMethod);
        });
      }

      if (this.settingsArea === "user") this.updateUserSettings(sendMethods);
      if (this.settingsArea === "school")
        this.updateSchoolSettings(sendMethods);
    },
    updateUserSettings(sendMethods) {
      this.loading = true;
      notificationsService
        .updateUserNotificationsSettings(this.currentUser.userId, {
          notificationGroup: this.notificationGroup,
          sendMethod: sendMethods,
        })
        .then((response) => {
          const responseData = response.data || {};
          NotificationsSettings.update({
            where: responseData.id,
            data: responseData,
          });
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;

          this.$snackbar.show({
            message: this.$t("messages.notifications_saved"),
            color: "success",
            timeout: 2000,
          });
        });
    },
    updateSchoolSettings(sendMethods) {
      this.loading = true;
      notificationsService
        .updateSchoolNotificationsSettings({
          userType: this.userType,
          notificationGroup: this.notificationGroup,
          sendMethod: sendMethods,
        })
        .then((response) => {
          const responseData = response.data || {};
          NotificationsSettings.update({
            where: responseData.id,
            data: responseData,
          });
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;

          this.$snackbar.show({
            message: this.$t("messages.notifications_saved"),
            color: "success",
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style scoped></style>
