import { Model } from "@vuex-orm/core";
import { v4 as uuid4 } from "uuid";

export default class ProductPriceModel extends Model {
  static entity = "productPrices";

  static fields() {
    return {
      id: this.uid(() => uuid4()),
      price: this.string(""),
      productId: this.attr(null),
      productType: this.string(""),
      priceCategoryId: this.attr(null),
      licenseId: this.attr(null),
      active: this.boolean(true),
      createdAt: this.attr(null),
    };
  }

  get isCreated () {
    return this.createdAt !== null
  }
}
