import api from "@/utils/api";

export default {
  load(params) {
    return new Promise((resolve, reject) => {
      api
        .get("school/payouts", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadItem(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`school/payouts/${id}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  downloadReport(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`school/payouts/${id}/report`, {
          responseType: "blob",
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
