import { render, staticRenderFns } from "./TeacherStudentsCount.vue?vue&type=template&id=0e64b07a&scoped=true"
import script from "./TeacherStudentsCount.vue?vue&type=script&lang=js"
export * from "./TeacherStudentsCount.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e64b07a",
  null
  
)

export default component.exports