<template>
  <v-card class="mt-3">
    <v-card-title class="subtitle-1">{{ $t("route.schoolSettings.children.limits") }}</v-card-title>
    <v-card-subtitle class="caption">{{ $t("label.date") }} {{ date | dateFormat('dd.MM.yyyy') }}</v-card-subtitle>
    <v-card-text>
      <template v-if="student.maxLessonsAmountPerWeek">
        <div class="caption">{{ $t('form.max_practices_per_week') }}</div>

        <v-progress-linear
            :color="thisWeekLessonsAmount >= student.maxLessonsAmountPerWeek ? 'error' : 'primary'"
            :value="getProgressValue(thisWeekLessonsAmount, student.maxLessonsAmountPerWeek)">
        </v-progress-linear>

        <div class="caption">
          {{ thisWeekLessonsAmountLabel }} / {{ student.maxLessonsAmountPerWeek }}
        </div>
      </template>

      <template v-if="student.maxLessonsAmountPerDay">
        <div class="caption">{{ $t('form.max_practices_per_day') }}</div>

        <v-progress-linear
            :color="thisDayLessonsAmount >= student.maxLessonsAmountPerDay ? 'error' : 'primary'"
            :value="getProgressValue(thisDayLessonsAmount, student.maxLessonsAmountPerDay)">
        </v-progress-linear>

        <div class="caption">
          {{ thisDayLessonsAmountLabel }} / {{ student.maxLessonsAmountPerDay }}
        </div>
      </template>

      <template v-if="student.maxLessonsDurationPerDay">
        <div class="caption ">{{ $t('form.max_duration_per_day') }}</div>

        <v-progress-linear
            :color="thisDayDuration >= student.maxLessonsDurationPerDay ? 'error' : 'primary'"
            :value="getProgressValue(thisDayDuration, student.maxLessonsDurationPerDay)">
        </v-progress-linear>

        <div class="caption">
          {{ thisDayDuration }} / {{ student.maxLessonsDurationPerDay }}
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import user from '@/utils/mixins/user'
import { endOfWeek, parseISO, startOfWeek } from "date-fns"
import AppointmentModel from "@/store/models/AppointmentModel"
import { mapGetters } from 'vuex'
import LocalizationManager from '@/helpers/LocalizationManager'

export default {
  name: "StudentEventsLimitCard",
  mixins: [user],
  props: {
    student: {
      type: Object,
      required: true
    },
    date: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('school', ['school']),
    thisWeekLessonsAmount() {
      const appointments = this.appointmentsFor(this.date,true)

      const lessonsAmount = this.appointmentsLessonsAmount(appointments)

      return parseFloat(lessonsAmount.toFixed(1))
    },
    thisDayLessonsAmount() {
      const appointments = this.appointmentsFor(this.date)

      const lessonsAmount = this.appointmentsLessonsAmount(appointments)

      return parseFloat(lessonsAmount.toFixed(1))
    },
    thisDayDuration() {
      return this.appointmentsFor(this.date)
          .filter(app => app.isIncludedInNormalDurationTime || app.isIncludedInSpecialDurationTime)
          .reduce((sum, app) => sum + app.duration, 0)
    },
    thisWeekLessonsAmountLabel() {
      return this.thisWeekLessonsAmount.toLocaleString(LocalizationManager.getCurrent())
    },
    thisDayLessonsAmountLabel() {
      return this.thisDayLessonsAmount.toLocaleString(LocalizationManager.getCurrent())
    }
  },
  methods: {
    getProgressValue(value, limit){
      return value / limit * 100
    },
    appointmentsLessonsAmount(appointments = []) {
      const normalAppointmentsDuration = appointments
          .filter(app => app.isIncludedInNormalDurationTime)
          .reduce((sum, app) => sum + app.duration, 0)

      const specialAppointmentsDuration = appointments
          .filter(app => app.isIncludedInSpecialDurationTime)
          .reduce((sum, app) => sum + app.duration, 0)

      const normalLessonsAmount = normalAppointmentsDuration / this.school.normalLessonDuration
      const specialLessonsAmount = specialAppointmentsDuration / this.school.specialLessonDuration

      return normalLessonsAmount + specialLessonsAmount
    },
    appointmentsFor(date = '', week = false) {
      const appointmentsQuery = AppointmentModel.query()

        if (week) {
          const weekStart = startOfWeek(parseISO(date), { weekStartsOn: 1, })
          const weekEnd = endOfWeek(parseISO(date), { weekStartsOn: 1 })

          appointmentsQuery.where("startDate", (date) => parseISO(date) >= weekStart && parseISO(date) <= weekEnd)
        } else {
          appointmentsQuery.where("startDate", date)
        }

      return appointmentsQuery.where(app => {
        return app.studentsIds.some(id => {
          return (id instanceof Object ? id.id : id) === this.student.id
        })
      }).get()
    }
  }
}
</script>

<style scoped lang="scss">
#limit {
  min-width: 70px;
  text-align: right;
  &.reached {
    color: tomato;
    font-weight: bold;
  }
}
</style>
