<template>
  <ValidationProvider name="teacher" :rules="`${validation ? 'required' : ''}`" v-slot="{ errors }">
    <v-autocomplete
        :error="errors.length > 0"
        :disabled="disabled"
        v-model="teacherId"
        :items="filteredTeachers"
        @change="onChange"
        item-value="id"
        :item-text="getFullName"
        outlined
        dense
        hide-details
        :label="$t('form.teacher')"
        :append-icon="!disabled ? 'mdi-menu-down' : ''">
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>{{ getFullName(data.item) }}</v-list-item-title>
        </v-list-item-content>
        <div name="other">
          <v-list-item-subtitle>
            {{ !data.item.studentCanCreatePractice ? $t('label.request') : '' }}
          </v-list-item-subtitle>
        </div>
      </template>
    </v-autocomplete>
  </ValidationProvider>
</template>

<script>
export default {
  name: "TeachersField",
  props: {
    filteredTeachers: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Boolean,
      default: false,
    },
    teacherProp: {
      type: Number,
    },
  },
  computed: {
    teacherId: {
      set(value) {
        this.$emit("emitTeachersSelect", value);
      },
      get() {
        return this.teacherProp;
      },
    },
  },
  methods: {
    onChange() {
      this.$emit("emitRecalculation");
    },
    getFullName(item) {
      return item.fullName;
    },
  },
};
</script>

<style lang="scss" scoped></style>
