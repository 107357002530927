<template>
  <div class="messages__user d-flex flex-column mb-2">
    <div class="d-flex">
      <v-avatar
        item
        size="32px"
        class="message__avatar mt-auto mr-3"
        v-if="showMessageAvatar(user)"
      >
        <v-img :src="user.type === 'school-administrator' ? school.avatar : user.avatar"
      />
      </v-avatar>

      <div class="messages__stack">
        <MessengerMessage
          v-for="(message, messageIndex) in user.messages"
          :key="messageIndex"
          :user="user"
          :message="message"
          :message-index="messageIndex" />
      </div>
    </div>
  </div>
</template>

<script>
import MessengerMessage from "@/components/messenger/MessengerMessage";
import { mapGetters } from "vuex";
import user from '@/utils/mixins/user'

export default {
  name: "MessagesByUser",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  mixins: [user],
  components: { MessengerMessage },
  computed: {
    ...mapGetters("school", ["school"]),
    authenticatedUser() {
      return this.isElterWithSelectedStudent ? this.parentSelectedStudent : this.currentUser;
    }
  },
  methods: {
    showMessageAvatar(user) {
      return (
        (this.authenticatedUser.type === "school-administrator" && user.type !== "school-administrator") ||
        (this.authenticatedUser.type !== "school-administrator" && this.authenticatedUser.id !== user.id)
      );
    },
  },
};
</script>

<style scoped></style>
