<template>
  <v-dialog v-model="dialog" width="500" @click:outside="close">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item link v-bind="attrs" v-on="on" :disabled="student.isDeleted">
        <v-list-item-icon>
          <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t(title) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
        <v-spacer/>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-title>
        {{ $t(subtitle) }}
      </v-card-title>

      <v-card-subtitle>
        {{ student.fullName }}
      </v-card-subtitle>

      <v-card-text>
        <ValidationObserver ref="form">
          <v-row dense>
            <v-col>
              <h4>{{ $t("form.for_license") }}</h4>
              <ValidationProvider v-slot="{ errors }" name="licenseId">
                <v-select
                    :placeholder="$t('form.select_category')"
                    :items="availableLicense"
                    v-model="form.licenseId"
                    item-value="id"
                    item-text="name"
                    outlined
                    dense
                    hide-details
                    :error="errors.length > 0"
                />
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <h4>{{ $t("form.preowned_licenses") }}</h4>
              <v-select
                  :placeholder="$t('form.select_owned_category')"
                  :items="allLicense"
                  v-model="form.preOwnedLicenses"
                  item-value="name"
                  item-text="name"
                  outlined
                  dense
                  multiple
                  hide-details
              />
            </v-col>
          </v-row>

          <v-row v-if="isSchoolAdministrator" dense>
            <v-col>
              <SignatureAutocompleteField
                :key="dialog"
                :signatureId="form.signatureId"
                @change="onSignatureSelected"
              />
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn text @click="close">{{$t('btn.close')}}</v-btn>
        <v-spacer/>
        <v-btn
            color="primary"
            download
            :disabled="downloading || !form.licenseId"
            :loading="downloading"
            @click="downloadPdf"
        >
          <v-icon left>mdi-file-document</v-icon>
          {{ $t("btn.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LicenseModel from "@/store/models/LicenseModel";
import studentsService from "@/services/studentsService";
import licensesService from "@/services/licensesService";
import SignatureAutocompleteField from '@/components/SignatureAutocompleteField.vue'
import user from '@/utils/mixins/user'
import FileSaver from "@/helpers/fileSaver";
import { format } from "date-fns";

export default {
  name: "StudentEducationStatus",
  mixins: [user],
  components: { SignatureAutocompleteField },
  data() {
    return {
      dialog: false,
      allLicense: [],
      downloading: false,
      form: {
        licenseId: null,
        preOwnedLicenses: [],
        signatureId: null
      },
      initialForm: {
        licenseId: null,
        preOwnedLicenses: [],
        signatureId: null
      },
      icon: "mdi-file-certificate-outline",
      title: "label.education_status",
      subtitle: "label.education_status_for",
      type: "education-status"
    };
  },
  props: {
    student: {
      type: Object,
      require: true,
    },
    documentType: {
      type: String,
      default: "education_status"
    }
  },
  computed: {
    availableLicense() {
      const license = LicenseModel.all() || [];
      return license.filter((license) =>
        this.student.licensesIds.includes(license.id)
      );
    },
  },
  created() {
    const documentTypes = {
      "theory_certificate": {
        title: "label.theory_certificate",
        subtitle: "label.theory_certificate_for",
        type: "theory-certificate"
      },
      "practice_certificate": {
        title: "label.practice_certificate",
        subtitle: "label.practice_certificate_for",
        type: "practice-certificate"
      }
    };

    if (documentTypes[this.documentType]) {
      this.title = documentTypes[this.documentType].title;
      this.subtitle = documentTypes[this.documentType].subtitle;
      this.type = documentTypes[this.documentType].type;
    }
  },
  mounted() {
    licensesService
      .all()
      .then((response) => (this.allLicense = response.data || []));
  },
  methods: {
    close() {
      this.downloading = false;
      this.dialog = false
      setTimeout(() => {
        this.form = { ...this.initialForm }
      }, 200)
    },
    async downloadPdf() {
      this.downloading = true;

      let request = this.type === "education-status" ? this.getEducationReport() : this.getEducationCertificate();

      request.then((response) => {
        let documentName = "Ausbildungsnachweis";
        if (this.documentType === "theory_certificate") documentName = "Bescheinigung (Theorie)";
        if (this.documentType === "practice_certificate") documentName = "Bescheinigung (Praxis)";

        const fileSaver = new FileSaver([response.data]);
        fileSaver
          .setType("application/pdf")
          .saveToDevice(`${documentName} ${this.student.fullName} ${format(new Date(), "dd.MM.yyyy")}.pdf`);

        this.close();
      })
        .catch((error) => {
          const blob = new Blob([error.response.data], { type: "application/pdf" });

          blob.text().then(response => {
            let responseData = {};

            try {
              responseData = JSON.parse(response || null)
            } catch(e) {
              console.log(e)
            }

            const errors = responseData.errors || [];

            if (Object.keys(errors).length === 0 && responseData.message) {
              this.$snackbar.show({ message: responseData.message, color: "error" });
            }

            this.$refs.form.setErrors(errors);
          })
        })
        .finally(() => {
          this.downloading = false;
        })
    },
    getEducationReport() {
      return studentsService.downloadEducationReport(this.student.id, this.form);

    },
    getEducationCertificate() {
      return studentsService
        .downloadEducationCertificate(this.student.id, { ...this.form, documentType: this.type });
    },
    onSignatureSelected(value) {
      this.form.signatureId = value
    }
  },
};
</script>

<style scoped>
.pdf-button {
  display: block;
  margin: auto;
}
</style>
