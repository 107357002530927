import SnackbarModel from "@/store/models/SnackbarModel";

const show = (data) => {
  return new Promise((resolve, reject) => {
    const snackbars = SnackbarModel.query().where('timeout', -1).get()

    if (snackbars.length > 0) SnackbarModel.delete(snackbars[0].id);

    SnackbarModel.insert({ data: { ...data } })
      .then((response) => {
        const firstSnackBar = response.snackbars[0] || {};
        resolve(firstSnackBar);
      })
      .catch((error) => reject(error));
  });
}

const success = (message, timeout = 3000) => {
  return show({
    message,
    timeout,
    color: "success",
  });
};

const warning = (message, timeout = 3000) => {
  return show({
    message,
    timeout,
    color: "warning",
  });
};

const error = (message, timeout = 3000) => {
  return show({
    message,
    timeout,
    color: "error",
  });
};
export default {
  install(Vue) {
    Vue.prototype.$snackbar = {
      show,
      success,
      warning,
      error,
      delete(snackbarId) {
        return SnackbarModel.delete(snackbarId);
      },
    };
  },
};
