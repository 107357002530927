<template>
  <v-container>
    <v-card :loading="loading">
      <v-toolbar elevation="3">
        <v-card-title>{{ ! loading && selectedVersion ? $t('label.whats_new_in_version', {version: selectedVersion.number}) : $t("route.patch-notes") }}</v-card-title>
      </v-toolbar>
      <v-card-text>
        <PatchNotesLoader v-if="loading" />
        <template v-else>
          <empty-box v-if="! this.data.length"></empty-box>
          <template v-else>
            <v-select
                :items="versions"
                :label="$t('label.app_version')"
                outlined
                v-model="selectedVersion"
                :hint="$t('label.released_on', {releaseDate: selectedVersion.publishedAtFormatted})"
                persistent-hint
            />
            <v-divider class="my-5"/>
            <PatchNotesList :patch-notes="selectedVersion.patchNotes" />
          </template>
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PatchNotesLoader from "@/components/skeleton-loaders/PatchNotesLoader";
import patchNotesService from "@/services/patchNotesService";
import {Device} from "@capacitor/device";
import PatchNotesList from "@/components/PatchNotesList";
export default {
  name: "PatchNotes",
  components: {PatchNotesList, PatchNotesLoader},
  data () {
    return {
      loading: true,
      data: [],
      selectedVersion: null
    }
  },
  async mounted() {
    const { platform } = await Device.getInfo();

    patchNotesService.load({ platform })
      .then(resp => {
        this.data = resp.data
        if (this.data.length) {
          this.selectedVersion = this.data[0]
        }
      })
      .finally(() => {
        this.loading = false
      })
  },
  computed: {
    versions () {
      return this.data.length ? this.data.map(version => {
        return {
          text: version?.number,
          value: version
        }
      }) : []
    }
  }
}
</script>

<style scoped>

</style>