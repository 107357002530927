<template>
  <div
      :class="`period ellipsis ${!temp && !creatingStatus ? 'can-be-hovered' : ''} ${isStatic ? 'is-static' : ''} ${onlyClickable ? 'only-clickable' : ''}`"
      :style="`${position} ${opacity}`"
      @mousedown.stop="$emit('movePeriod', $event)"
  >

    <div v-if="!isStatic && !onlyClickable" class="handler start" @mousedown.stop="$emit('resizeStart', $event)">
      <v-icon class="icon">mdi-drag-vertical-variant</v-icon>
    </div>
    <span class="name">{{ period.teacherName }}</span>
    <div v-if="!isStatic && !onlyClickable" class="handler end" @mousedown.stop="$emit('resizeEnd', $event)">
      <v-icon class="icon">mdi-drag-vertical-variant</v-icon>
    </div>

    <v-overlay :value="loading" absolute opacity="0.2">
      <v-progress-circular indeterminate size="25"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { parseISO, differenceInMinutes, startOfDay, } from 'date-fns'

export default {
  name: 'ReservedPeriod',
  props: {
    period: {
      type: Object,
      required: true
    },
    temp: {
      type: Boolean,
      default: false,
    },
    onlyClickable: {
      type: Boolean,
      default: false,
    },
    isStatic: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('vehiclesReservation', ['creatingStatus', 'tempReservationPeriod', 'draggingStatus', 'resizingStatus']),
    ...mapState('vehiclesCalendar', ['date']),
    ...mapGetters('vehiclesCalendar', ['oneMinuteSize']),
    parsedStart () {
      return parseISO(this.period.from)
    },
    parsedEnd () {
      return parseISO(this.period.to)
    },
    duration () {
      return differenceInMinutes(this.parsedEnd, this.parsedStart)
    },
    positionLeft () {
      return differenceInMinutes(this.parsedStart, startOfDay(this.parsedStart))
    },
    opacity() {
      return `opacity: ${this.period.id === this.tempReservationPeriod.id ? 0 : 1};`
    },
    position () {
      return `width: ${this.duration * this.oneMinuteSize}px; left: ${this.positionLeft * this.oneMinuteSize}px;`
    },
  },
}
</script>

<style lang="scss" scoped>
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.period {
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  font-size: 10px;
  line-height: normal;
  background: rgba(131, 212, 255, 0.5);
  border-left: 3px solid rgba(131, 212, 255, 0.8);
  border-right: 3px solid rgba(131, 212, 255, 0.8);
  pointer-events: none;
  &.can-be-hovered {
    pointer-events: auto;
    &:hover {
      cursor: grab;
    }
  }
}
.only-clickable{
  cursor: pointer !important;
}
.is-static{
  cursor: default !important;
  &:hover {
    cursor: default !important;
  }
}

.name {
  position: relative;
  padding: 2px 5px;
  left: 10px;
}

.handler {
  width: 24px;
  height: 100%;
  position: absolute;
  cursor: e-resize;
  display: flex;
  align-items: center;
  top: 0;
  //border: 1px solid #333;

  &:hover {
    .icon {
      top: -1px;
      display: inline;
    }
  }
  &.start {
    left: -5px;
  }
  &.end {
    right: -5px;
  }
  .icon {
    //border: 1px solid red;
    display: none;
  }
}
</style>
