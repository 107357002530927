<template>
  <v-row>
    <v-col cols="12">
      <label class="pl-4">Erbrachte Leistungen</label>
      <v-simple-table dense>
        <thead>
          <tr>
            <th :style="`width: ${showNewServiceForm ? 150 : 50}px`">{{ $t("label.date") }}</th>
            <th>{{ $t("label.description") }}</th>
            <th :style="`width: ${showNewServiceForm ? 200 : 150}px`">{{ $t("label.price_without_tax") }}</th>
            <th :style="`width: ${showNewServiceForm ? 200 : 150}px`">{{ $t("label.tax_rate") }}</th>
            <th :style="`width: ${showNewServiceForm ? 200 : 150}px`">{{ $t("label.cost") }}</th>
            <th v-if="showNewServiceForm" style="width: 20px"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="service in sortedServices" :key="service.id" class="table-row">
            <td class="text-monospace">{{ service.date | dateFormat('dd.MM.yyyy') }}</td>
            <td >
              <div class="d-flex justify-space-between align-center">
                {{ service.description }}
                <student-service-tooltip :service="service" />
              </div>
            </td>
            <td class="text-monospace">
              {{ service.costWithoutTax | currency }}
            </td>
            <td class="text-monospace">{{ service.taxRate }} %</td>
            <td class="text-monospace">{{ service.cost | currency }}</td>
            <td v-if="showNewServiceForm" class="text-right">
              <v-btn text small @click="$emit('deleteService', service.id)">{{ $t('btn.delete') }}</v-btn>
            </td>
          </tr>
          <template v-if="showNewServiceForm">
            <tr><th colspan="6">{{ $t('label.add_service') }}</th></tr>
            <tr>
              <td>
                <v-menu
                    ref="menu1"
                    v-model="newServiceDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :value="formattedNewServiceDate"
                        :label="$t('label.date')"
                        dense
                        solo
                        flat
                        hide-details
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    />
                  </template>
                  <v-date-picker
                      v-model="newService.date"
                      no-title
                      @input="newServiceDateMenu = false"
                      :max="(new Date()).toISOString()"
                  />
                </v-menu>
              </td>
              <td>
                <v-text-field
                  hide-details
                  dense
                  :label="$t('label.description')"
                  v-model="newService.description"
                  solo
                  flat
                />
              </td>
              <td>
                {{ newService.costWithoutTax | currency }}
              </td>
              <td>
                <v-select
                  hide-details
                  dense
                  :label="$t('label.tax_rate')"
                  v-model="newService.taxRate"
                  @input="calculatePriceWithoutTax"
                  :items="[
                    { text: '19%', value: 19 },
                    { text: '7%', value: 7 },
                    { text: '0%', value: 0 },
                  ]"
                  solo
                  flat
                />
              </td>
              <td>
                <v-text-field
                  hide-details
                  dense
                  :label="$t('label.cost')"
                  v-model="newService.cost"
                  @input="calculatePriceWithoutTax"
                  solo
                  flat
                  v-currency="{
                    currency: null,
                    allowNegative: true,
                    valueAsInteger: false,
                    precision: 2,
                    valueRange: { max: 999999 },
                  }"
                />
              </td>
              <td class="text-right">
                <v-btn text :disabled="addServiceButtonDisabled" small @click="saveService">{{$t('btn.save')}}</v-btn>
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr>
            <th :colspan="showNewServiceForm ? 5 : 4" style="text-align: right">
              {{ $t("label.invoice_total") }}
            </th>
            <th class="text-monospace">{{ invoiceAmount | currency }}</th>
          </tr>
        </tfoot>
      </v-simple-table>
    </v-col>
    <v-col cols="12" md="6" lg="5">
      <v-simple-table dense>
        <thead>
          <tr>
            <th></th>
            <th>Netto</th>
            <th>Ust.</th>
            <th>Brutto</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="taxRate in uniqueTaxRates" :key="taxRate">
            <th>{{ taxRate }}%</th>
            <td class="text-monospace">
              {{ getCostWithoutTaxForFilteredServicesByTaxRate(taxRate) | currency }}
            </td>
            <td class="text-monospace">
              {{ getTaxAmountForFilteredServicesByTaxRate(taxRate) | currency }}
            </td>
            <td class="text-monospace">
              {{ getCostForFilteredServicesByTaxRate(taxRate) | currency }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import StudentServiceTooltip from "@/components/invoices/StudentServiceTooltip.vue";
import {format, parseISO} from "date-fns";

export default {
  name: "InvoiceServicesTable",
  components: {StudentServiceTooltip},
  props: {
    services: {
      type: Array,
      required: true,
    },
    showNewServiceForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newServiceDateMenu: false,
      newService: {
        date: null,
        description: null,
        costWithoutTax: null,
        taxRate: null,
        cost: null,
        isDeletable: true,
      }
    }
  },
  computed: {
    formattedNewServiceDate () {
      return this.newService.date ? format(parseISO(this.newService.date), 'dd.MM.yyyy') : ''
    },
    addServiceButtonDisabled() {
      return ! (
          this.newService.date
          && this.newService.description
          && this.newService.costWithoutTax
          && this.newService.taxRate !== null
          && this.newService.cost
      )
    },
    invoiceAmount() {
      return this.services.reduce(
        (sum, item) => sum + parseFloat(item.cost),
        0
      );
    },
    uniqueTaxRates() {
      return [...new Set(this.services.map((item) => item.taxRate))];
    },
    parsedNewServiceCost () {
      return this.newService.cost?.replace(".", "")?.replace(",", ".")
    },
    sortedServices () {
      return this.services.slice().sort((a, b) => new Date(a.date) - new Date(b.date));
    }
  },
  methods: {
    saveService() {
      this.$emit('serviceCreated', {
        date: this.newService.date,
        description: this.newService.description,
        taxRate: this.newService.taxRate,
        cost: this.parsedNewServiceCost,
        costWithoutTax: parseFloat(this.newService.costWithoutTax).toFixed(2),
      })
      this.clearForm()
    },
    clearForm() {
      this.newService = {
        date: null,
        description: null,
        costWithoutTax: null,
        taxRate: null,
        cost: null,
        isDeletable: true,
      }
    },
    calculatePriceWithoutTax() {
      if (!(this.newService.taxRate !== null && this.newService.cost !== null)) {
        this.newService.costWithoutTax = null;
        return;
      }

      this.newService.costWithoutTax = parseFloat(this.parsedNewServiceCost / (1 + this.newService.taxRate / 100))
          .toFixed(2)
    },
    getFilteredServicesByTaxRate(taxRate) {
      return this.services.filter((item) => item.taxRate === taxRate);
    },
    getCostForFilteredServicesByTaxRate(taxRate) {
      return this.getFilteredServicesByTaxRate(taxRate)
        .map((item) => item.cost)
        .reduce((sum, item) => sum + parseFloat(item), 0);
    },
    getCostWithoutTaxForFilteredServicesByTaxRate(taxRate) {
      return this.getFilteredServicesByTaxRate(taxRate)
        .map((item) => item.costWithoutTax)
        .reduce((sum, item) => sum + parseFloat(item), 0);
    },
    getTaxAmountForFilteredServicesByTaxRate(taxRate) {
      return (
        this.getCostForFilteredServicesByTaxRate(taxRate) -
        this.getCostWithoutTaxForFilteredServicesByTaxRate(taxRate)
      );
    },
  },
};
</script>

<style scoped lang="scss"></style>
