import api from '@/utils/api'

export default {
  list: (params= {}) => {
    return new Promise((resolve, reject) => {
      api
        .get(`exam-statistics`, { params })
        .then((resp) => resolve(resp))
        .catch(reject);
    })
  },
  monthlyByTeacherAndYear: (teacherId, year) => {
    return new Promise((resolve, reject) => {
      api
        .get(`exam-statistics/${teacherId}/${year}`)
        .then((resp) => resolve(resp))
        .catch(reject);
    })
  }
}
