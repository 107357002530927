<template>
  <v-sheet class="note pa-4 pr-6 mb-2" rounded elevation="2">
    {{ note.text }}

    <RightClickMenu :item="note">
      <template v-slot="{ showItemMenu }">
        <v-icon
          class="note-options"
          size="25"
          @click="(e) => showItemMenu(e, note)"
          >mdi-dots-vertical</v-icon
        >
      </template>

      <template v-slot:menu>
        <v-list-item @click="$emit('edit', note)">
          <v-list-item-icon class="mr-2"
            ><v-icon>mdi-pencil</v-icon></v-list-item-icon
          >
          <v-list-item-title>{{ $t("label.edit") }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$emit('delete', note)">
          <v-list-item-icon class="mr-2"
            ><v-icon>mdi-delete</v-icon></v-list-item-icon
          >
          <v-list-item-title>{{ $t("label.delete") }}</v-list-item-title>
        </v-list-item>
      </template>
    </RightClickMenu>
  </v-sheet>
</template>

<script>
import RightClickMenu from "@/components/RightClickMenu";

export default {
  name: "Note",
  props: {
    note: {
      type: Object,
      required: true,
    },
  },
  components: { RightClickMenu },
};
</script>

<style scoped lang="scss">
.note {
  position: relative;
  &-options {
    position: absolute;
    right: 10px;
    top: 15px;
  }
}
</style>
