<template>
  <div
    :class="`hour-cell ${!this.mdLandscapeAndUp ? 'is-mobile' : ''}`"
    :style="`height: ${60 * this.oneMinuteSize}px`">
    <span class="hour"><slot /></span>
    <Lines v-if="this.mdLandscapeAndUp" />
    <div class="wide-line"></div>
  </div>
</template>

<script>
import responsive from "@/utils/mixins/responsive";
import Lines from "@/components/calendar/Lines.vue";

export default {
  name: "HourCell",
  mixins: [responsive],
  components: {
    Lines,
  },
};
</script>

<style lang="scss" scoped>
.hour-cell {
  border-bottom: var(--darker-border);
  position: relative;
  text-align: center;
  &:first-child {
    border-bottom: none !important;
  }
  &.is-mobile {
    font-size: 0.8rem;
  }
  & > .hour {
    color: #999;
    z-index: 9;
  }
  .wide-line {
    border-bottom: var(--darker-border);
    position: absolute;
    width: 100vw;
    z-index: 1;
    bottom: -1px;
    left: var(--calendar-hours-column);
    pointer-events: none;
  }
}
</style>
