<template>
  <v-card>
    <v-card-title class="py-2 d-flex justify-space-between">
      <div class="d-flex flex-column">
        <span>{{ $t("label.next_scheduled_students") }}</span>
      </div>
      <v-btn icon small @click="load(true)" :loading="loading">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </v-card-title>

    <v-divider />

    <v-card-text v-if="loading">
      <NextStudentSkeletonLoader v-for="item in [1, 2, 3, 4, 5]" :key="item" />
    </v-card-text>

    <v-card-text v-else>
      <empty-box v-if="!students.length" />

      <NextStudent
        v-for="student in students"
        :key="student.studentId"
        :student="student"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import teacherService from "@/services/teacherService";
import LessonTypeModel from "@/store/models/LessonTypeModel";
import NextStudent from "@/components/teacher/NextStudent";
import NextStudentSkeletonLoader from "@/components/skeleton-loaders/NextStudentSkeletonLoader";

export default {
  name: "NextScheduledStudents",
  components: {NextStudentSkeletonLoader, NextStudent},
  data() {
    return {
      loading: true,
      students: [],
      loadingLessonAmountByLicense: true,
      lessonTypesAmountByLicense: [],
    };
  },
  computed: {
    lessonTypes() {
      return LessonTypeModel.all();
    },
  },
  methods: {
    toggleScheduledStudentDetails(scheduledStudent) {
      if (this.$vuetify.breakpoint.lgAndUp) return;

      const indexOfLicense = this.students.findIndex(
        (item) => item.id === scheduledStudent.id
      );
      this.$set(this.students, indexOfLicense, {
        ...scheduledStudent,
        isOpenDetails: !scheduledStudent.isOpenDetails,
      });
    },
    load () {
      this.loading = true
      teacherService
          .nextScheduledStudents()
          .then((response) => {
            this.students = (response.data || []).map((student) => ({
              ...student,
              isOpenDetails: false,
              isDeleted: student.deletedAt !== null,
            }));
          })
          .catch(error => {
            const responseData = error.response.data || {};
            const errors = responseData.errors || [];

            if (Object.keys(errors).length === 0 && responseData.message) {
              this.$snackbar.show({ message: responseData.message, color: "error", });
            }
          })
          .finally(() => (this.loading = false));
    }
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped></style>
