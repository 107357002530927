<template>
<div class="d-flex text-center flex-column flex-md-row flex-grow-1 fill-height">
  <v-sheet class="layout-side mx-auto mx-md-1 d-none d-md-flex flex-md-column justify-space-between px-4">
    <div class="mt-6 mt-md-20 pa-8">
      <img :src="require('@/assets/svg/book-and-pay.svg')" alt=""/>

      <div class="title my-2">
        Wir digitalisieren die Abläufe in Ihrer Fahrschule
      </div>

      <v-btn :to="{name: 'login'}" elevation="1" text>Zurück</v-btn>
    </div>

    <img :src="require('@/assets/img/calendar-screen.png')" style="width: 100%" alt=""/>
  </v-sheet>

  <div class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column">
    <div class="layout-content ma-auto" style="width: 100%">
      <v-card class="layout-content pa-2 ma-auto">
        <v-card-title class="justify-center display-1 mb-2">
          <span>{{ $t('label.reset_password') }}</span>
        </v-card-title>

        <v-card-text>
          <ValidationObserver ref="form">
            <v-form @keyup.native.enter="submit">
              <ValidationProvider
                  v-slot="{ errors }"
                  name="email"
                  rules="required"
              >
                <v-text-field
                    outlined
                    prepend-inner-icon="mdi-email-outline"
                    inputmode="email"
                    :label="$t('validation.fields.email')"
                    :placeholder="$t('validation.fields.email')"
                    v-model="form.email"
                    :error-messages="errors"
                />
              </ValidationProvider>

              <ValidationProvider
                  v-slot="{ errors }"
                  name="password"
                  rules="required"
              >
                <v-text-field
                    outlined
                    autocomplete="new-password"
                    prepend-inner-icon="mdi-lock-outline"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="!showPassword ? 'password' : 'text'"
                    :label="$t('validation.fields.password')"
                    :placeholder="$t('validation.fields.password')"
                    v-model="form.password"
                    :error-messages="errors"
                    @click:append="showPassword = !showPassword"
                />
              </ValidationProvider>

              <ValidationProvider
                  v-slot="{ errors }"
                  name="passwordConfirmation"
                  rules="required"
              >
                <v-text-field
                    outlined
                    prepend-inner-icon="mdi-lock-outline"
                    type="password"
                    :label="$t('validation.fields.passwordConfirmation')"
                    :placeholder="$t('validation.fields.passwordConfirmation')"
                    v-model="form.password_confirmation"
                    :error-messages="errors"
                />
              </ValidationProvider>
            </v-form>
          </ValidationObserver>

          <v-btn
              :dark="!loading"
              block
              x-large
              color="primary"
              elevation="1"
              :loading="loading"
              :disabled="loading"
              @click="submit"
          >
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </div>
    <CopyrightAndVersion/>
  </div>
</div>
</template>

<script>
import authService from '@/services/authService'
import CopyrightAndVersion from "@/views/Auth/CopyrightAndVersion";

export default {
  name: 'ResetPassword',
  components: {CopyrightAndVersion},
  data () {
    return {
      showPassword: false,
      form: {
        token: '',
        email: '',
        password: '',
        password_confirmation: ''
      },
      loading: false
    }
  },
  methods: {
    submit() {
      this.form.token = this.$route.params.token

      this.loading = true
      authService.resetPassword(this.form)
        .then(() => {
          this.$router
              .push({name: 'login'})
              .then(() => {
                this.$snackbar
                  .show({ message: this.$t('messages.password_has_been_reset'), color: 'success', timeout: 5000 });
              })
        })
        .catch(error => {
          this.$refs.form.reset()

          const responseData = error.response.data || {};
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error", });
          }

          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.layout-side {
  width: 420px;
  height: 100vh;
}
.layout-content {
  max-width: 480px;
}
</style>
