<template>
  <div>
    <v-menu absolute offset-y :transition="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            small
            text
            v-bind="attrs"
            v-on="on"
            min-width="30"
        >
          <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
      </template>

      <v-sheet>
        <v-list dense>
          <v-list-item :disabled="loadingMakeDefault" @click="setPriceCategoryAsDefault">
            <v-list-item-icon class="mr-2">
              <v-icon :disabled="loadingMakeDefault">
                {{priceCategory.isDefault ? 'mdi-bookmark-remove' : 'mdi-bookmark'}}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content><v-list-item-title>
              {{ priceCategory.isDefault ? $t("label.disable_default") : $t("label.enable_default") }}
            </v-list-item-title></v-list-item-content>
          </v-list-item>
          <v-list-item v-if="doesntHaveAllLicenses" @click="openAddLicenses">
            <v-list-item-icon class="mr-2"><v-icon>mdi-book-plus</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>{{ $t("btn.add_licenses") }}</v-list-item-title></v-list-item-content>
          </v-list-item>
          <v-list-item @click="$emit('openPriceCatFormDialog')">
            <v-list-item-icon class="mr-2"><v-icon>mdi-pencil</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>{{ $t("btn.edit") }}</v-list-item-title></v-list-item-content>
          </v-list-item>
          <v-list-item v-if="priceCategory.studentsCount === 0" @click="deletePriceCategory">
            <v-list-item-icon class="mr-2"><v-icon>mdi-delete</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>{{ $t("btn.delete") }}</v-list-item-title></v-list-item-content>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-menu>

    <AddLicensesDialog ref="addLicensesDialog" @added="data => $emit('licensesAdded', data)"/>
  </div>
</template>

<script>
import productsService from "@/services/productsService";
import ProductCategoryModel from "@/store/models/ProductCategoryModel";
import AddLicensesDialog from "@/components/products/AddLicensesDialog";
import LicenseModel from "@/store/models/LicenseModel";
import { showConfirm } from '@/helpers/dialogs'
import priceCategoriesService from '@/services/priceCategoriesService'

export default {
  name: "PricesListsSettings",
  props: {
    priceCategory: {
      type: Object,
      required: true,
    },
    licenses: {
      type: Array,
      required: true
    }
  },
  components: { AddLicensesDialog },
  data: () => ({
    loadingMakeDefault: false,
    form: {
      loadingMakeDefault: false,
      priceCategoryName: "",
    },
  }),
  computed: {
    doesntHaveAllLicenses() {
      return LicenseModel.query().count() !== this.licenses.length
    }
  },
  methods: {
    updatePriceCategoryName() {
      productsService
        .updatePriceCategory(this.priceCategory.id, {
          name: this.form.priceCategoryName,
        })
        .then((response) => {
          ProductCategoryModel.update({
            where: this.priceCategory.id,
            data: response.data,
          });
        })
        .catch((error) => {
          const responseData = error.response.data;
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message)
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });

          Object.keys(errors).forEach((fieldName) =>
            this.$refs.form.setErrors({ [fieldName]: errors[fieldName] })
          );
        });
    },
    setPriceCategoryAsDefault() {
      this.loadingMakeDefault = true;
      priceCategoriesService
        .setDefault(this.priceCategory.id, !this.priceCategory.isDefault)
        .then(() => {
          this.$emit('updateIsDefault', !this.priceCategory.isDefault)
        })
        .catch((error) => {
          const response = error.response || {};
          const responseData = response.data || {};

          if (responseData.message) {
            this.$snackbar.show({ message: responseData.message, color: "error", });
          }
        })
        .finally(() => (this.loadingMakeDefault = false));
    },
    deletePriceCategory() {
      showConfirm(
        this.$t('btn.confirm'),
        this.$t("messages.are_your_sure_delete"),
        () => {
          productsService
            .deletePriceCategory(this.priceCategory.id)
            .then(() => (this.$emit('deleted')));
        }
      );
    },
    openAddLicenses() {
      this.$refs.addLicensesDialog.open(this.priceCategory.id, this.licenses);
    }
  },
};
</script>

<style scoped></style>
