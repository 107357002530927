<template>
<v-dialog
  v-model="show"
  :scrollable="true"
  width="500"
  @click:outside="close"
  @keydown.esc="close"
>
<v-card :loading="loading">
  <v-toolbar color="primary" dark dense>
      <v-toolbar-title></v-toolbar-title>
      <v-spacer/>
      <v-btn dark icon @click="close">
          <v-icon>mdi-close</v-icon>
      </v-btn>
  </v-toolbar>
  <v-card-text>
      <slot name="content" />
  </v-card-text>
  <v-card-actions>
      <slot name="actions" />
  </v-card-actions>
</v-card>
</v-dialog>
</template>

<script>
// import * as mutationTypes from '@/store/mutation-types/vehiclesCalendar'
export default {
  name: 'Dialog',
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    show: {
      set (value) {
        console.log(value)
        //TODO create in main module of vuex
        // this.$store.commit(`main/${mutationTypes.TOGGLE_DIALOG}`, value)
      },
      get () {
        return this.reservationDialog.show
      }
    },
  },
  methods: {
    open() {},
    close() {}
  }
}
</script>

<style scoped>

</style>