<template>
  <div id="layout" ref="layout" v-touch="{ start: (e) => touchOutside(e) }">
    <v-app-bar class="app-bar" dense elevation="1">
      <VehiclesFiltersMenu :external="!!activeDate" :disabled="disabledButtons"/>

      <v-btn icon color="primary" :disabled="disabledButtons" @click="fetchDateData">
        <v-icon>mdi-calendar-refresh</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <DateControls :activeDate="activeDate" :disabled="disabledButtons" @emitFocusCenter="focusCenter" />

      <v-spacer></v-spacer>

      <v-btn
          icon
          :disabled="disabledButtons || !activePdfButton"
          :loading="pdfLoader"
          @click="dailySchedulePdf"
      >
        <v-icon>mdi-file-download-outline</v-icon>
      </v-btn>
      <v-btn icon @click="decreaseSize" :disabled="disabledButtons || hourSize === 50">
        <v-icon>mdi-magnify-minus-outline</v-icon>
      </v-btn>
      <v-btn icon @click="increaseSize" :disabled="disabledButtons || hourSize === 200">
        <v-icon>mdi-magnify-plus-outline</v-icon>
      </v-btn>
    </v-app-bar>

    <div id="mainGrid" :style="mainGrid" ref="mainGrid">
      <div id="grid" :style="gridTemplateColumn">
        <div class="sidebar-left vehicles-grid">
          <div class="vehicles-filters px-2 d-flex justify-space-between align-center" @click="sortVehicles">
            <div class="hovered">
              <span v-if="!isCollapsedSidebar" class="ml-1">{{ $t('label.vehicles') }}</span>
              {{ `${activeVehiclesIds.length}/${allVehicles.length}` }}
            </div>
            <v-icon v-if="!isCollapsedSidebar" small class="hovered">
              {{ vehicleOrder === 'desc' ? 'mdi-sort-alphabetical-descending' : 'mdi-sort-alphabetical-ascending' }}
            </v-icon>
          </div>

          <div v-if="initialDataLoader">
            <v-skeleton-loader class="vehicles-skeleton" v-for="n in 5" :key="n" type="list-item-avatar-two-line"/>
          </div>
          <div v-else class="sidebar-content-wrapper" :style="`height: ${baseHeight}px`" ref="sidebar" v-scroll.self="onScrollSidebar">
            <VehiclesList
                v-for="vehicle in activeVehicles"
                :key="vehicle.id"
                :vehicle="vehicle"
                :isCollapsed="isCollapsedSidebar"
            />
          </div>
        </div>

        <SidebarSlider type="left" :size="leftSidebar" @click.native="toggleLeft" />

        <div class="calendar" v-resize="onResize">
          <v-progress-linear v-if="disabledButtons" style="width: 100%" absolute top indeterminate></v-progress-linear>
          <div class="vehicles-grid" ref="calendar">
            <HoursLine :scrollLeft="horizontalScroll"/>
            <div :class="`calendar-wrapper ${ appointmentsLoader ? 'loading-bg' : '' }`" :style="`height: ${baseHeight}px`" ref="calendarGrid" v-scroll.self="onScrollCalendarGrid">
              <div class="apps-lines" :style="`min-width: ${minWidth}px`">
                <template v-if="initialDataLoader">
                  <v-skeleton-loader v-for="n in 5" :key="n" class="appointments-skeleton"></v-skeleton-loader>
                </template>
                <template v-else>
                  <VehicleAppointmentsRow
                      v-for="vehicle in activeVehicles"
                      :key="vehicle.id"
                      :vehicle="vehicle"
                      :appointments="appointmentsByVehicle(vehicle.id)"
                      :reservations="reservationsByVehicle(vehicle.id)"
                      :services="servicesByVehicle(vehicle.id)"
                      @drop.native="appDropCheck($event, vehicle.id)"
                      v-touch="{ start: (e) => appDropCheck(e, vehicle.id) }"
                      @dragover.native.prevent
                      @mousedown.native="(e) => startDrawing(e, vehicle.id)"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SidebarSlider type="right" :size="rightSidebar" @click.native="toggleRight" />
      <div class="sidebar-right">
        <div class="label px-2 d-flex justify-space-between align-center">
          {{ $t('label.appointments') }}
        </div>

        <div :class="`sidebar-wrapper ${draggingProcess ? 'hidden' : ''}`">
          <div class="pa-3" v-if="noVehiclesAppointments.length && !showOverlay">
            <WithoutVehicleAppointment
              v-for="appointment in noVehiclesAppointments"
              :key="appointment.id"
              :appointment="appointment"
              :loading="appointmentLoader(appointment.id)"
              :disabled="disableAppointment || reservationMode"
              :draggable="!disableAppointment"
              @dragstart.native="startAppointmentDragging({ appointment, updateOrDelete: false })"
              v-touch="{ start: (e) => touchAppointment(e, appointment) }"
              @dragend.native="stopAppointmentDragging"
            />
          </div>
          <DropZone
              v-if="draggingProcess"
              @dragover.native.prevent
              @drop.native="appDropCheck($event, null)"
              v-touch="{ start: (e) => appDropCheck(e, null) }"
          />

          <div v-if="showOverlay" class="d-flex justify-center my-5">
            <v-progress-circular indeterminate size="36"></v-progress-circular>
          </div>

          <EmptyBox v-if="!noVehiclesAppointments.length && !showOverlay" />
        </div>
        <ReservationModeSwitch v-if="allowReservations" />
      </div>
    </div>

    <VehiclesErrorBottomSheet />
    <VehicleReservationDialog
      v-if="allowReservations"
      ref="vehicleReservationDialog"
      @created="addNewReservation"
      @updated="updateReservation"
      @deleted="deleteReservation"
      @closed="closeReservation"
    />
  </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex'
import CalendarWebsocketsHandlers from '@/helpers/calendarWebsocketsHandlers'
import FileSaver from '@/helpers/fileSaver'
import { addMinutes, format, parseISO } from 'date-fns'
import user from '@/utils/mixins/user'

import schoolService from '@/services/schoolService'
import VehicleModel from '@/store/models/VehicleModel'
import VehicleReservationModel from '@/store/models/VehicleReservationModel'
import VehicleServiceModel from '@/store/models/VehicleServiceModel'

import HoursLine from '@/components/vehicles-calendar/HoursLine.vue'
import DateControls from '@/components/vehicles-calendar/DateControls.vue'
import VehiclesList from '@/components/vehicles-calendar/VehiclesList.vue'
import VehicleAppointmentsRow from '@/components/vehicles-calendar/VehicleAppointmentsRow.vue'
import SidebarSlider from '@/components/vehicles-calendar/SidebarSlider.vue'
import WithoutVehicleAppointment from '@/components/vehicles-calendar/WithoutVehicleAppointment.vue'
import VehiclesFiltersMenu from '@/components/vehicles-calendar/VehiclesFiltersMenu.vue'
import DropZone from '@/components/vehicles-calendar/DropZone.vue'
import VehiclesErrorBottomSheet from '@/components/vehicles-calendar/VehiclesErrorBottomSheet.vue'
import ReservationModeSwitch from '@/components/vehicles-calendar/ReservationModeSwitch.vue'
import VehicleReservationDialog from '@/components/vehicle/VehicleReservationDialog.vue'
import EmptyBox from '@/components/EmptyBox.vue'

import {
  RESET_STATE,
  ON_RESIZE_HANDLER,
  SET_GRID_SIZE,
  SET_ACTIVE_VEHICLES_IDS,
  SET_VEHICLES_ORDER,
  SET_INITIAL_LOADER
} from '@/store/mutation-types/vehiclesCalendar'
import { SET_RESERVATION_FIELDS, TOGGLE_CREATING_STATUS, RESET_RESERVATION_STATE, } from '@/store/mutation-types/vehiclesReservation'
import SchoolDataLoader from '@/helpers/schoolDataLoader'

export default {
  name: 'Calendar',
  mixins: [user],
  components: {
    WithoutVehicleAppointment,
    DateControls,
    VehiclesFiltersMenu,
    SidebarSlider,
    HoursLine,
    VehiclesList,
    VehicleAppointmentsRow,
    DropZone,
    VehiclesErrorBottomSheet,
    ReservationModeSwitch,
    VehicleReservationDialog,
    EmptyBox,
  },
  data: () => ({
    allowReservations: false,
    baseHeight: 0,
    horizontalScroll: 0,
    pdfLoader: false,
  }),
  props: {
    activeDate: {
      type: Date,
      default: null,
    }
  },
  watch: {
    activeVehiclesIds() {
      this.recalculateBaseHeight()
    },
  },
  created () {
    SchoolDataLoader.load({ method: 'create', except: ['ds'] }).then(() => {
      SchoolDataLoader.load({ method: 'insert', only: ['ds'] })
    });
    this.$store.commit(`vehiclesCalendar/${RESET_STATE}`)
    this.$store.commit(`vehiclesReservation/${RESET_RESERVATION_STATE}`)
  },
  async mounted () {
    this.allowReservations = this.$store.state.school.isVehicleReservationsEnabled
    const handler = new CalendarWebsocketsHandlers(
        this.currentUser,
        this.isSchoolAdministrator,
    );

    handler.startListening()

    await this.fetchVehicles()
    await this.fetchVehiclesReservations(this.activeDate ? this.activeDate : this.date)
    await this.fetchVehiclesServices(this.activeDate ? this.activeDate : this.date)
    this.setDate(this.activeDate ? this.activeDate : this.date)
    setTimeout(() => {
      this.recalculateBaseHeight()
      this.$store.commit(`vehiclesCalendar/${SET_GRID_SIZE}`, this.$refs.calendar.scrollWidth)
      this.focusCenter()
    }, 0)

    this.$store.commit(`vehiclesCalendar/${SET_ACTIVE_VEHICLES_IDS}`, this.allVehicles.map((item) => item.id))
    this.$store.commit(`vehiclesCalendar/${SET_INITIAL_LOADER}`, false)
  },
  computed: {
    ...mapState('vehiclesCalendar', [
      'date',
      'gridSize',
      'hourSize',
      'activeVehiclesIds',
      'vehicleOrder',
      'disableAppointment',
      'appointmentsLoader',
      'initialDataLoader',
      'leftSidebar',
      'rightSidebar',
      'draggableAppointment',
      'draggingProcess',
      'validVehiclesIds',
    ]),
    ...mapState('vehiclesReservation', [
      'reservationMode',
      'tempReservationPeriod',
      'draggingStatus',
      'resizingStatus',
    ]),

    ...mapGetters('vehiclesCalendar', [
      'minWidth',
      'allVehicles',
      'appointments',
      'formattedDate',
      'activePdfButton',
      'oneMinuteSize',
    ]),
    reservations(){
      return VehicleReservationModel.query()
          .where((app) => app.date === this.formattedDate)
          .get() || []
    },
    services(){
      return VehicleServiceModel.query()
          .where((app) => app.date === this.formattedDate)
          .get() || []
    },
    disabledButtons() {
      return this.initialDataLoader || this.appointmentsLoader || this.disableAppointment
    },
    showOverlay() {
      return this.appointmentsLoader || this.initialDataLoader
    },
    activeVehicles() {
      const query = VehicleModel.query()
          .whereIdIn(this.activeVehiclesIds)
      if (!this.vehicleOrder) return query.get()
      return query.orderBy('make', this.vehicleOrder).get() || []
    },
    noVehiclesAppointments() {
      return this.appointments.filter((event) =>  !event.primaryVehicleId) || []
    },
    mainGrid() {
      return `
        grid-template-columns: 1fr 20px ${this.rightSidebar}px;
        cursor: ${this.draggingStatus ? 'move' : this.resizingStatus ? 'e-resize' : ''};
      `
    },
    isCollapsedSidebar() {
      return this.leftSidebar === 60
    },
    gridTemplateColumn() {
      return `grid-template-columns: ${this.leftSidebar}px 20px 1fr`
    },
  },
  methods: {
    ...mapActions('vehiclesCalendar', [
      'decreaseCellSize',
      'increaseCellSize',
      'toggleLeftSidebar',
      'toggleRightSidebar',
      'fetchAppointments',
      'fetchVehicles',
      'setDate',
      'startAppointmentDragging',
      'stopAppointmentDragging',
      'stopTouch',
      'appointmentDrop',
    ]),
    ...mapActions('vehiclesReservation', ['fetchVehiclesReservations', 'fetchVehiclesServices',]),

    fetchDateData() {
      this.fetchAppointments()
      this.fetchVehiclesReservations(this.date)
      this.fetchVehiclesServices(this.date)
    },
    startDrawing(e, vehicleId) {
      const clickOnService = e.target.closest(".service")
      if (!this.reservationMode || clickOnService) return

      const rawMinutes = e.offsetX / this.oneMinuteSize
      const snappedMinutes = Math.floor(rawMinutes / 15) * 15
      const startTime = addMinutes(parseISO(this.formattedDate), snappedMinutes)
      const endTime = addMinutes(startTime, 15)
      const tempObj = {
        vehicleId: vehicleId,
        from: format(startTime, "yyyy-MM-dd HH:mm"),
        to: format(endTime, "yyyy-MM-dd HH:mm")
      }
      this.$store.commit(`vehiclesReservation/${SET_RESERVATION_FIELDS}`, tempObj)
      this.$store.commit(`vehiclesReservation/${TOGGLE_CREATING_STATUS}`, true)

      window.addEventListener("mousemove", this.drawing);
      window.addEventListener("mouseup", this.stopDrawing);
    },

    drawing(e) {
      const rawMinutes = e.offsetX / this.oneMinuteSize
      const snappedMinutes = Math.ceil(rawMinutes / 15) * 15
      const endTime = addMinutes(parseISO(this.formattedDate), snappedMinutes)

      if (endTime <= parseISO(this.tempReservationPeriod.from)) return
      const fields = { to: format(endTime, "yyyy-MM-dd HH:mm") }
      this.$store.commit(`vehiclesReservation/${SET_RESERVATION_FIELDS}`, { ...this.tempReservationPeriod, ...fields })
    },
    stopDrawing() {
      this.$refs.vehicleReservationDialog.open(this.tempReservationPeriod)
      this.$store.commit(`vehiclesReservation/${TOGGLE_CREATING_STATUS}`, false)

      window.removeEventListener("mousemove", this.drawing, false);
      window.removeEventListener("mouseup", this.stopDrawing, false);
    },

    touchOutside() {
      this.stopTouch()
    },
    touchAppointment(e, appointment) {
      e.stopPropagation()
      this.startAppointmentDragging({ appointment, updateOrDelete: false })
    },

    appDropCheck(e, vehicleId) {
      const isDropzone = e.target.closest('.drop-zone')
      if (isDropzone) this.appointmentDrop(vehicleId)
    },

    recalculateBaseHeight() {
      this.baseHeight = this.$refs.mainGrid.offsetHeight - 40 - (this.$vuetify.breakpoint.mobile ? 10 : 0)
    },
    appointmentLoader(appointmentId) {
      return this.disableAppointment && this.draggableAppointment && this.draggableAppointment.id === appointmentId
    },
    focusCenter() {
      this.horizontalScroll =
          this.$refs.calendarGrid.scrollLeft =
          (this.$refs.calendarGrid.scrollWidth  - this.$refs.calendarGrid.clientWidth) / 2
    },
    onResize() {
      this.recalculateBaseHeight()
      this.$store.commit(`vehiclesCalendar/${ON_RESIZE_HANDLER}`,
          { clientWidth: this.$refs.calendar.clientWidth, minWidth: this.minWidth })
    },
    async decreaseSize() {
      if (this.hourSize === 50) return
      await this.decreaseCellSize()
      this.onResize()
    },
    async increaseSize() {
      if (this.hourSize === 200) return
      await this.increaseCellSize()
      this.onResize()
    },
    async toggleLeft() {
      await this.toggleLeftSidebar()
      this.onResize()
    },
    async toggleRight() {
      await this.toggleRightSidebar()
      this.onResize()
    },
    appointmentsByVehicle(vehicleId) {
      return this.appointments?.filter((event) => {
        return event.primaryVehicleId === vehicleId || event.secondaryVehicleId === vehicleId
      }) || []
    },
    reservationsByVehicle(vehicleId) {
      // if (!this.allowReservations) return []
      return this.reservations?.filter((item) => item.vehicleId === vehicleId) || []
    },
    servicesByVehicle(vehicleId) {
      // if (!this.allowReservations) return []
      return this.services?.filter((item) => item.vehicleId === vehicleId) || []
    },

    sortVehicles() {
      this.$store.commit(`vehiclesCalendar/${SET_VEHICLES_ORDER}`)
    },

    onScrollSidebar(e) {
      this.$refs.calendarGrid.scrollTop = e.target.scrollTop;
    },
    onScrollCalendarGrid(e) {
      this.$refs.sidebar.scrollTop = e.target.scrollTop;
      this.horizontalScroll = e.target.scrollLeft;
    },
    dailySchedulePdf() {
      this.pdfLoader = true
      return schoolService
          .dailySchedulePdf({ date: this.formattedDate })
          .then(response => {
            this.saveAsFile(response.data)
          })
          .catch((error) => console.log(error))
          .finally(() => this.pdfLoader = false)
    },

    saveAsFile(file) {
      const fileSaver = new FileSaver([file])
      fileSaver
        .setType("application/pdf")
        .saveToDevice(`Fahrzeugübersicht  ${format(parseISO(this.formattedDate), "dd.MM.yyyy")}.pdf`);
    },

    addNewReservation(dataObj) {
      VehicleReservationModel.insert({ data: dataObj })
    },
    updateReservation(dataObj) {
      VehicleReservationModel.insert({ data: dataObj })
    },
    deleteReservation(reservationId) {
      VehicleReservationModel.delete(reservationId)
    },
    closeReservation() {
      this.$store.commit(`vehiclesReservation/${SET_RESERVATION_FIELDS}`, {})
    },
  },
}
</script>

<style scoped lang="scss">
.sidebar-content-wrapper {
  overflow: scroll;
  overflow-y: hidden;
}

.calendar-wrapper{
  z-index: 2;
  overflow: auto;
  position: relative;
}

#layout {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.app-bar{
  z-index: 9;
}
#mainGrid {
  width: 100%;
  height: calc(100% - 48px);
  display: grid;
  position: relative;
}
#grid {
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
}

.vehicles-grid{
  display: grid;
  grid-template-rows: 40px 1fr;
  position: relative;
}

.calendar {
  display: block;
  overflow: hidden;
  flex-direction: column;
  position: relative;
  .date{
    background: #ccc;
  }
}

.sidebar-right{
  display: grid;
  grid-template-rows: 40px 1fr auto;
  overflow: hidden;
  .label{
    border-bottom: 1px solid #ccc;
    font-weight: 900;
  }
  .sidebar-wrapper {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    position: relative;
    &.hidden{
      overflow: hidden;
    }
  }
}

.vehicles-filters{
  height: 40px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  font-weight: 900;
  &:hover .hovered{
    color: var(--v-primary-base)
  }
}

.loading-bg{
  background: rgba(225, 225, 225, .3);
}
.vehicles-skeleton{
  height: 60px;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  border-radius: 0;
}
.appointments-skeleton{
  height: 60px;
  border-bottom: 1px solid #ccc;
}
</style>
