<template>
  <v-card
    :loading="loading"
    dark
    :color="color"
    @click.native="openBalanceBreakdown"
    :style="cursor"
  >
    <v-card-subtitle class="my-0 pb-0">{{ $t('label.balance') }}</v-card-subtitle>

    <v-card-title class="my-0 pt-0">
      <span v-if="loading">{{ $t("loading") }}</span>
      <span v-else>
        {{ student.balance | currency }}
        <v-tooltip right v-if="hasInsufficientFunds">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="white">mdi-alert</v-icon>
          </template>
          {{ $t('dialogs.balance.available_amount') }}: {{ student.availableBalance | currency }}
        </v-tooltip>
      </span>

    </v-card-title>

    <v-icon v-if="!loading" x-large class="icon">{{ iconValue }}</v-icon>

    <StudentBalanceBreakdownDialog :student="student" ref="StudentBalanceBreakdownDialog"/>
  </v-card>
</template>

<script>
import studentBalanceLabel from "@/utils/mixins/studentBalanceLabel";

export default {
  name: "StudentBalanceWidget",
  mixins: [studentBalanceLabel],
  data () {
    return {
      loading: false,
    }
  }
}
</script>

<style scoped>
.icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
</style>
