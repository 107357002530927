<template>
  <div class="calendar">
    <v-progress-linear
      v-if="loaderGetter"
      indeterminate
      color="secondary"
      height="5"
      absolute
    ></v-progress-linear>
    <CalendarDesktop v-if="mdLandscapeAndUp" />
    <CalendarMobile v-else />

    <v-btn
      v-show="multiDeleteStatusGetter && multiDeleteEventsGetter.length"
      @click="deleteSelectedEvents"
      color="error"
      dark
      absolute
      bottom
      right
      fab>
      <v-badge
        top
        color="primary"
        :content="multiDeleteEventsGetter.length"
        overlap
        style="top: -20px; right: -30px" />
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { showConfirm } from "@/helpers/dialogs";
import laravelEcho from "@/plugins/laravel-echo";
import CalendarDesktop from "@/components/calendar/CalendarDesktop.vue";
import CalendarMobile from "@/components/calendar/CalendarMobile.vue";

import * as calendarMutationTypes from "@/store/mutation-types/calendar";
import * as eventsMutationTypes from "@/store/mutation-types/events";
import * as mainMutationTypes from "@/store/mutation-types/main";
import { SET_CALENDAR_LAYOUT, SET_CALENDAR_MONTH } from "@/store/mutation-types/calendar";

import StudentModel from "@/store/models/StudentModel";
import AppointmentModel from "@/store/models/AppointmentModel";

import user from "@/utils/mixins/user";
import responsive from "@/utils/mixins/responsive";
import loadAppointments from "@/utils/mixins/loadAppointments";
import appointmentService from "@/services/appointmentService";
import SchoolDataLoader from '@/helpers/schoolDataLoader'
import CalendarWebsocketsHandlers from "@/helpers/calendarWebsocketsHandlers";

export default {
  name: "Calendar",
  mixins: [responsive, user, loadAppointments],
  components: { CalendarDesktop, CalendarMobile },
  created() {
    this.$store.commit(SET_CALENDAR_MONTH, null);
    this.setSchoolRegions(this.schoolSettings.regions);
    AppointmentModel.deleteAll();
    SchoolDataLoader.load({ method: 'create', except: ['ds'] }).then(() => {
      SchoolDataLoader.load({ method: 'insert', only: ['ds'] })
    });
    this.loadAppointments(true);
  },
  mounted() {
    const handler = new CalendarWebsocketsHandlers(
        this.currentUser,
        this.isSchoolAdministrator,
        this.isTeacher,
        this.isExactlyStudent
    );

    handler.startListening()

    laravelEcho
      .private("school-channel." + this.currentUser.schoolId)
      .listen("StudentBalanceUpdated", (response) => {
        StudentModel.update({
          where: response.studentId,
          data: { balance: response.balance, availableBalance: response.availableBalance },
        });
      });

    if (!this.$vuetify.breakpoint.lgAndUp) this.toggleSeparateTeachers(false);
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit(
      `main/${mainMutationTypes.MINIMIZE_SIDEBAR}`,
      JSON.parse(window.localStorage.getItem("isSidebarMinimized")) || false
    );
    next();
  },
  destroyed() {
    //Leave channel to prevent duplicated connections
    laravelEcho.leave("school-channel." + this.currentUser.schoolId)

    this.$store.commit(SET_CALENDAR_MONTH, null);
    this.$store.commit(
      SET_CALENDAR_LAYOUT,
      this.mdLandscapeAndUp ? "LAYOUT_WEEK" : "LAYOUT_THREE_DAYS"
    );
  },
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapState("main", ["isSidebarMinimized"]),
    ...mapGetters(["dateRange", "activeDate", "loaderGetter"]),
    ...mapGetters("teachers", { activeTeachersIds: "activeIds" }),
    ...mapGetters("appointments", [
      "createEventModalGetter",
      "multiDeleteStatusGetter",
      "multiDeleteEventsGetter",
      "newEventModelGetter",
    ]),
  },
  methods: {
    ...mapActions("teachers", { calendarActivate: "enable" }),
    ...mapActions([
      "updateCalendarLayout",
      "setSchoolRegions",
      "toggleSeparateTeachers",
    ]),
    ...mapActions("appointments", [
      "assignEventTypes",
      "multiDeleteClear",
    ]),
    deleteSelectedEvents() {
      showConfirm(
          this.$t('btn.confirm'),
          this.$t('messages.are_your_sure_delete'),
          () => {
            this.$store.commit(calendarMutationTypes.SET_CALENDAR_LOADER);
            appointmentService
                .deleteMultiple({ appointmentsIds: this.multiDeleteEventsGetter })
                .then((response) => {
                  const data = response.data || {}

                  data.deleted.forEach(appointmentId => {
                    AppointmentModel.delete(appointmentId);
                  })

                  AppointmentModel.insert({ data: data.created });
                })
                .then(() => this.multiDeleteClear())
                .finally(() => {
                  this.$store.commit(`appointments/${eventsMutationTypes.MULTI_DELETE_STATUS_TOGGLE}`);
                  this.$store.commit(calendarMutationTypes.SET_CALENDAR_LOADER);
                });
          }
      )
    },
    setUpdateTypeForEventModal(eventData, type) {
      if (
        !(
          this.createEventModalGetter &&
          this.newEventModelGetter.id === eventData.id
        )
      ) {
        const event = AppointmentModel.find(eventData.id);

        if (event) {
          if (type === "edited") {
            AppointmentModel.update({ where: eventData.id, data: eventData });
          }
          if (type === "deleted") AppointmentModel.delete(event.id);
        }

        return false;
      }
      this.$store.commit(
        "events/" + eventsMutationTypes.SET_UPDATED_TYPE,
        type
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar {
  height: 100%;
}
</style>
