<template>
  <v-dialog width="500" :value="show" persistent>
    <v-card>
      <v-card-title
        class="headline info lighten-1 white--text flex justify-space-between"
        primary-title>
        {{ title }}
      </v-card-title>

      <v-card-text class="pl-10 pr-10 pt-3">
        <div class="subtitle-1">
          <v-icon size="40" color="info">mdi-reload-alert</v-icon>
          {{ body }}
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions class="d-flex justify-end">
        <v-btn color="info" small outlined @click="reloadApp">{{
          $t("btn.update")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import laravelEcho from "@/plugins/laravel-echo";
import { mapGetters } from "vuex";
import user from '@/utils/mixins/user'

export default {
  name: "AppReload",
  mixins: [user],
  data: () => ({
    show: false,
    demoStatusChanged: false,
  }),
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    title() {
      return this.demoStatusChanged ? this.$t("label.demo_status_changed") : this.$t("label.settings_changed");
    },
    body() {
      return this.demoStatusChanged ? this.$t("messages.demo_status_changed") : this.$t("messages.settings_were_changed");
    }
  },
  mounted() {
    let userId = this.isElterWithSelectedStudent ? this.parentSelectedStudent.userId : this.currentUser.userId;

    laravelEcho
      .private(`user.${userId}`)
      .listen("ReloadApp", data => {
        this.show = true;
        this.demoStatusChanged = data['type'] === 'demo-status-changed';
      })
  },
  methods: {
    reloadApp() {
      window.location.reload();
    },
  },
};
</script>

<style scoped></style>
