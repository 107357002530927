import api from "@/utils/api";

export default {
  locales() {
    return new Promise((resolve, reject) => {
      api
        .get("/locales")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
