<template>
  <v-avatar :rounded="rounded" :size="size">
    <v-img :src="img" :alt="alt" />
  </v-avatar>
</template>

<script>
import { createAvatar } from '@dicebear/core';
import * as initials  from '@dicebear/initials';

export default {
  name: 'UserAvatar',
  props: {
    path: {
      type: [String, null],
      default: null
    },
    size: {
      type: [Number, String],
      default: 40
    },
    alt: {
      type: String,
      default: ""
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    img() {
      if (this.path !== null) return this.path;

      return createAvatar(initials, { seed: this.alt, size: this.size }).toDataUriSync()
    }
  }
}
</script>

<style scoped>

</style>
