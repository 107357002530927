import ProductCategoryModel from "@/store/models/ProductCategoryModel";
import PriceCategoryLessonTypeStatusModel from "@/store/models/PriceCategoryLessonTypeStatusModel";
// import { isPast, parseISO } from "date-fns";
// import LessonTypeModel from "@/store/models/LessonTypeModel";

export default {
  computed: {
    priceCategory() {
      return ProductCategoryModel.find(this.student.priceCategoryId);
    },

    // licensePriceIsCompleted() {
    //   if (
    //     this.student.priceCategoryId &&
    //     this.student.bookAndPay &&
    //     (this.currentAppointment.type === "PRACTICE" ||
    //       (this.isStudent && this.currentAppointment.type === "PLACEHOLDER"))
    //   ) {
    //     const productStatusByLicense =
    //       this.priceCategory?.licenseCompletionStatus.find(
    //         (item) => item.id === this.currentAppointment.licenseId
    //       ) || false;
    //     return productStatusByLicense
    //       ? productStatusByLicense.completed
    //       : false;
    //   }
    //   return true;
    // },

    practiceLicensesStatus() {
      if (!this.priceCategory || !this.student.bookAndPay) return null;
      return this.priceCategory.licenseCompletionStatus.filter((item) => {
        return this.activeLicensesIds.includes(item.id);
      });
    },

    lessonTypesList() {
      const priceCategory = PriceCategoryLessonTypeStatusModel.query()
        .where("priceCategoryId", this.student.priceCategoryId)
        .where("licenseId", this.currentAppointment.licenseId)
        .get();

      return this.lessonTypes.map((lessonType) => {
        const lessonTypeStatus = priceCategory.find(
          (item) => item.lessonTypeId === lessonType.id
        );

        /*commented past exam logic*/
        // const appGroup = LessonTypeModel.find(lessonType.id).group;
        // const isPastExam = isPast(parseISO(this.currentAppointment.start)) && appGroup === "exam";
        const isPastExam = false

        let lessonTypeDoesNotHavePrice = false;
        let lessonTypeIsDisabled = isPastExam;

        if (this.student.bookAndPay) {
          lessonTypeDoesNotHavePrice = lessonType.isPayable && !lessonTypeStatus;

          lessonTypeIsDisabled = lessonTypeIsDisabled ||
            (lessonTypeDoesNotHavePrice || (priceCategory && !lessonTypeStatus?.isEnabled && lessonType.payable !== "no_cost"))
        }

        return {
          ...lessonType,
          disabled: lessonTypeIsDisabled,
          selected: false,
          name: this.getLessonTypeName(lessonType),
          doesNotHavePrice: lessonTypeDoesNotHavePrice,
        };
      });
    },
  },
  methods: {
    getLessonTypeName(lessonType) {
      if (this.isStudent && lessonType?.name === "lesson_type.gratis_exam") {
        return this.$tc("lesson_type.exam", 1); // gratis exam case for student
      }

      return this.$tc(lessonType?.name, 1);
    },
  },
};
