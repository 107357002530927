<template>
  <v-banner
    class="pa-0"
    icon="mdi-currency-eur"
    color="orange darken-4"
    single-line
    dark>
    {{ $t("payment.setupNotification") }}
    <template v-slot:actions>
      <v-btn text @click="$router.push({ name: 'school-settings.payments' })">
        {{ $t("payment.setupButton") }}
        <v-icon right>mdi-chevron-right</v-icon>
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
import responsive from "@/utils/mixins/responsive";

export default {
  name: "PaymentAccountNotification",
  mixins: [responsive],
};
</script>

<style lang="scss">
.v-banner__icon {
  margin-right: 4px !important;
}

.v-banner {
  //position: fixed;
  z-index: 9;
  width: 100%;
}

.v-banner__wrapper {
  padding-left: 8px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
</style>
