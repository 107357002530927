<template>
  <div class="d-flex">
    <v-btn v-if="!activeDate" class="mr-3" text outlined small :disabled="disabled" @click="setToday">
      <v-icon left small>mdi-calendar-today</v-icon>
      {{ $t('today') }}
    </v-btn>

    <v-btn v-if="!activeDate" icon small :disabled="disabled" @click="switchDay(-1)">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>

    <v-menu
        v-model="dateMenu"
        class="date-menu mx-3"
        :disabled="!!activeDate || disabled"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn text small v-bind="attrs" v-on="on">{{ dateAsLabel }}</v-btn>
      </template>
      <v-date-picker
          :value="formattedDate"
          first-day-of-week="1"
          :disabled="disabled"
          no-title
          @change="dateSelect"
          @input="dateMenu = false"
      />
    </v-menu>

    <v-btn v-if="!activeDate" icon small :disabled="disabled" @click="switchDay(1)">
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { addDays, parseISO } from 'date-fns'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'DateControls',
  data: () => ({
    dateMenu: false,
  }),
  props: {
    activeDate: {
      type: Date,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('vehiclesCalendar', ['date']),
    ...mapGetters('vehiclesCalendar', ['dateAsLabel', 'formattedDate']),
  },
  methods: {
    ...mapActions('vehiclesCalendar', ['setDate']),
    setToday() {
      this.setDate(new Date())
    },
    switchDay(day) {
      this.setDate(addDays(this.date, day))
      this.$emit('emitFocusCenter')
    },
    dateSelect(date) {
      this.setDate(parseISO(date))
      this.$emit('emitFocusCenter')
    },
  }
}
</script>

<style lang="scss" scoped>
.date-menu{
  max-width: 290px;
  min-width: auto;
}

</style>
