<template>
<v-dialog v-model="show" max-width="600"  @click:outside="close" @keydown.esc="close" scrollable>
  <v-card :loading="loadingOverlappedAppointments">
    <v-toolbar class="mb-2" dark color="error" dense>
      <v-toolbar-title>{{ $t('messages.restore_error') }}</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <p class="body-1 mt-4">{{ message }}</p>

      <template v-if="!loadingOverlappedAppointments">
        <v-list v-if="overlaps.length > 0" dense>
          <v-list-item dense three-line v-for="overalp in overlaps" :key="overalp.id">
            <v-list-item-icon v-if="!$vuetify.breakpoint.mobile"><v-icon>mdi-calendar</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ overalp.start | dateFormat }}</v-list-item-title>
              <v-list-item-subtitle>{{ $t("event_type." + overalp.type)  }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="overalp.license">{{ overalp.license  }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ overalp.teacherName  }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <empty-box v-else/>
      </template>
    </v-card-text>

    <v-divider/>

    <v-card-actions>
      <v-btn text @click="close">
        <v-icon v-if="$vuetify.breakpoint.mobile">mdi-close</v-icon>
        <span v-else>{{ $t("btn.close") }}</span>
      </v-btn>
      <v-spacer/>

      <v-btn color="primary" :disabled="loadingOverlappedAppointments" @click="restore">
        <v-icon v-if="$vuetify.breakpoint.mobile">mdi-restore</v-icon>
        <span v-else>{{ $t('btn.try_again') }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import appointmentService from '@/services/appointmentService'
import axios from 'axios'

export default {
  name: 'AppointmentOverlapErrorDialog',
  data() {
    return {
      show: false,
      loadingOverlappedAppointments: true,
      message: '',
      overlaps: []
    }
  },
  methods: {
    open(message, overlapIds) {
      this.show = true
      this.message = message

      this.getOverlapAppointment(overlapIds)
    },
    close() {
      this.show = false
      setTimeout(() => {
        this.message = []
        this.overlaps = []
      }, 200)
    },
    getOverlapAppointment(overlapIds) {
      const briefEventRequests = [];

      overlapIds.forEach((eventOverlapId) => {
        return briefEventRequests.push(appointmentService.get(eventOverlapId))
      });

      this.loadingOverlappedAppointments = true
      axios
          .all(briefEventRequests)
          .then(
              axios.spread((...responses) => {
                responses.forEach((response) => (this.overlaps.push(response.data)));
              })
          )
          .finally(() => (this.loadingOverlappedAppointments = false));
    },
    restore() {
      this.overlaps = []
      this.loadingOverlappedAppointments = true
      this.$emit('restore')
    }
  }
}
</script>

<style scoped>

</style>
