<template>
  <v-switch
    class="mt-0"
    v-model="model.allowMultipleRequests"
    :disabled="disabled"
    inset
    :label="$t('form.multiple')"
    hide-details
    dense />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MultiplePlaceholderSwitch",
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    allowedPracticeCreation: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
  },
};
</script>
