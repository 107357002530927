<template>
  <div class="list-action-bar px-3 py-2">
    <v-row no-gutters>
      <v-col
        v-if="mdLandscapeAndUp"
        cols="12"
        sm="12"
        md="2"
        lg="4"
        class="d-flex justify-start">
        <slot name="buttons">
          <v-btn
            class="mr-5 text-subtitle-2"
            color="primary"
            @click="$emit('clickCreateItem')"
            >{{ createBtnName }}</v-btn
          >
        </slot>

        <template v-if="isTeacher">
          <v-btn color="primary" dark @click="showDialog">
            {{ $t("label.daily_practices") }}
            <v-icon right>mdi-file-pdf-box</v-icon>
          </v-btn>

          <TeacherDailyEvents ref="dailyEvents" :teacher="currentUser" />
        </template>
      </v-col>

      <v-col
        cols="12"
        :offset-md="mdLandscapeAndUp ? 4 : 0"
        md="6"
        lg="4"
        class="d-flex justify-end">
        <slot name="filters-body" v-if="isFilters" />

        <v-select
          v-if="$route.matched.some(({ name }) => name === 'students')"
          style="max-width: 300px"
          @change="changeLicense"
          :label="$t('form.licence')"
          :items="licenses"
          item-value="id"
          item-text="name"
          clearable
          multiple
          dense
          outlined
          hide-details />

        <v-text-field
          v-if="isSearch"
          class="ml-2 mr-2"
          v-model="searchText"
          :label="$t('search')"
          hide-details
          @input="search"
          dense
          outlined
          full-width>
          <template v-slot:append>
            <v-progress-circular
              v-if="searchLoading"
              class="font-weight-light"
              size="20"
              width="2"
              indeterminate
              color="grey" />

            <v-icon v-else>mdi-magnify</v-icon>
          </template>
        </v-text-field>

        <div>
          <v-btn
            height="36"
            v-if="mdLandscapeAndUp && hasToggleListView"
            class="mr-1"
            icon
            color="primary"
            text
            @click="toggleListView">
            <v-icon v-if="itemsView !== 'list'">mdi-view-headline</v-icon>
            <v-icon v-else>mdi-apps</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters dense v-if="appliedFilters.length">
      <v-col cols="12">
        <div class="applied-filters mt-2">
          <v-chip-group>
            <v-chip
              class="mr-2"
              small
              close
              v-for="filter in appliedFilters"
              :key="filter.key + '_' + filter.value"
              @click:close="onCloseFilter(filter)">
              {{ filter.label }}
            </v-chip>
          </v-chip-group>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import responsive from "@/utils/mixins/responsive";
import user from "@/utils/mixins/user";
import TeacherDailyEvents from "@/components/teacher/TeacherDailyEvents";
import { mapState } from "vuex";
import LicenseModel from "@/store/models/LicenseModel";

export default {
  name: "ListActionBar",
  mixins: [responsive, user],
  components: { TeacherDailyEvents },
  props: {
    searchLoading: {
      type: Boolean,
      default: false,
    },
    isFilters: {
      type: Boolean,
      default: false,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    hasToggleListView: {
      type: Boolean,
      default: true,
    },
    createBtnName: {
      type: String,
    },
  },
  data: () => ({
    searchText: "",
    act: { label: "Active" },
    tch: { label: "Teacher" },
    noApp: { label: "Without Appointments" },
  }),
  watch: {
    $route(to, from) {
      console.log(to, from);
    },
  },
  computed: {
    ...mapState("main", ["itemsView"]),
    appliedFilters() {
      return [];
    },
    licenses() {
      return LicenseModel.all() || [];
    },
  },
  methods: {
    showDialog() {
      this.$refs.dailyEvents.open();
    },
    toggleListView() {
      this.$store.commit("main/toggle-items-view");
    },
    changeLicense(val) {
      this.$emit("licenseFilter", val);
    },
    search() {
      this.$emit("search", this.searchText);
    },
    onCloseFilter(filter) {
      const query = Object.assign({}, this.$route.query);

      if (Object.prototype.hasOwnProperty.call(query, filter.key)) {
        if (Array.isArray(query[filter.key])) {
          const filterItemArray = query[filter.key];
          const filterValueIndex = filterItemArray.indexOf(filter.value);

          if (filterValueIndex !== -1)
            query[filter.key] = filterItemArray.splice(filterValueIndex, 1);

          console.log(query[filter.key], filter.key, filter.value);
        } else {
          delete query[filter.key];
        }
      }

      this.$router.replace({ query });
    },
  },
};
</script>

<style scoped>
.list-action-bar {
  border-bottom: 1px solid #eee;
}
</style>
