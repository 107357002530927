<template>
  <ShowsAs
    :type="$vuetify.breakpoint.mobile ? 'dialog' : 'menu'"
    :options="{
      offsetX: true,
      nudgeWidth: 300
    }"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn small icon :color="color" v-bind="attrs" v-on="on">
        <v-icon size="18">{{ icon }}</v-icon>
      </v-btn>
    </template>

    <template v-slot:default="{ close }">
      <v-card>
        <v-card-title v-if="$vuetify.breakpoint.mobile"/>

        <v-card-text>
          <div v-for="appointment in apps" :key="appointment.id" class="d-flex justify-space-between">
            <div style="font-size: 0.8rem">
              {{ appointment.teacherName }}
            </div>
            <div style="font-size: 0.8rem">
              {{ appointment.start }}
            </div>
          </div>
        </v-card-text>

        <template v-if="$vuetify.breakpoint.mobile">
          <v-divider class="mt-auto" />

          <v-card-actions>
            <v-btn text @click="close">{{$t("btn.close")}}</v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </template>
  </ShowsAs>
</template>

<script>

import ShowsAs from '@/components/ShowsAs'
export default {
  name: "EducationTheoryPopup",
  components: { ShowsAs },
  props: {
    apps: {
      type: Array,
    },
    lastVisited: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon() {
      return this.apps.length >= 0 && this.apps.length <= 9
          ? `mdi-numeric-${this.apps.length}-box${this.boxStyle}`
          : `mdi-numeric-9-plus-box${this.boxStyle}`
    },
    color() {
      return this.lastVisited ? 'green' : 'gray'
    },
    boxStyle() {
      return this.lastVisited ? '' : '-outline'
    }
  }
};
</script>

<style scoped>
</style>
