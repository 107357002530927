<template>
  <div>
    <v-skeleton-loader class="mb-2" type="chip" />
    <v-skeleton-loader class="mb-2" type="divider" />

    <v-skeleton-loader
      class="mb-2"
      v-for="item in array"
      :key="item"
      type="image"
      height="80" />
  </div>
</template>

<script>
export default {
  name: "StudentAgendaListLoader",
  props: {
    cardsCount: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    array() {
      return [...Array(this.cardsCount)];
    },
  },
};
</script>

<style scoped></style>
