<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
      <tr>
        <th style="width: 10px" v-if="!student" class="pa-1">
          <v-checkbox
            :input-value="allSelectionCheckboxValue"
            :indeterminate="allSelectionCheckboxIndeterminate"
            dense
            style="margin: 0"
            hide-details
            :ripple="false"
            @click="toggleAll"
          />
        </th>
        <th style="width: 30px" class="pl-1">{{ $t("label.number") }}</th>
        <th style="width: 10px" class="pa-0"></th>
        <th style="width: 100px" class="pl-1">{{ $t("label.date") }}</th>
        <th v-if="!student">{{ $t("label.client") }}</th>
        <th></th>
        <th style="width: 150px; max-width: 160px">{{ $t("label.invoice_amount") }}</th>
        <th style="width: 150px;text-align: right" v-if="!student">
          {{ $t("label.actions") }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="invoice in invoices"
          :key="invoice.id"
          class="table-row"
          @click="toggleItem(invoice.id)"
      >
        <td v-if="!student" class="pa-1">
          <v-checkbox
              :value="invoice.id"
              v-model="selectedInvoicesIds"
              hide-details
              class="ma-0"
              :ripple="false"
              dense
              @click.stop
          />
        </td>
        <td class="pl-0">
          <v-chip
              label
              small
              class="text-monospace"
              link
              @click.stop="openInvoice(invoice)"
              :color="invoiceColor(invoice)"
          >
            <v-icon left small v-if="student && downloading !== invoice.id">
              mdi-download
            </v-icon>

            <v-icon left small v-else>{{ invoiceIcon(invoice) }}</v-icon>

            <v-progress-circular
              size="15"
              color="primary"
              class="mr-1"
              style="margin-left: -3px"
              indeterminate
              small
              v-if="student && downloading === invoice.id"
            />
              <span :class="{'canceled-invoice': invoice.isCanceled}">
                <span style='color:black'>{{ invoice.number }}</span>
              </span>
          </v-chip>
        </td>
        <td class="pa-0">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-if="invoice.isCreatedAutomatically" small color="green" v-bind="attrs" v-on="on">
                mdi-autorenew
              </v-icon>
            </template>
            <span>{{ $t('label.automatic') }}</span>
          </v-tooltip>
        </td>
        <td class="pl-1">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ invoice.formattedDate }}</span>
            </template>
            <span>{{ invoice.fullFormattedDate }}</span>
          </v-tooltip>
        </td>
        <td v-if="!student" :class="{'font-italic': ! invoice.studentId }" class="ellipsis">
          {{ invoice.studentName ?? invoice.clientName }}
        </td>
        <td class="text-right grey--text ellipsis">
          <span v-if="invoice.companyName" style="font-weight: 500">{{ invoice.companyName }}, </span>
          <span class="font-weight-light">{{ invoice.addressLine1 }}, {{ invoice.addressLine2 }}</span>
        </td>
        <td :class="{'red--text font-weight-bold': invoice.amount < 0}">{{ invoice.amount | currency }}</td>
        <td style="text-align: right" v-if="!student" class="pl-0">
          <v-progress-linear
            indeterminate
            color="primary"
            v-if="[downloading, canceling, sending].includes(invoice.id)"
          />
          <template v-else>
            <v-chip
                class="hidden-until-row-hovered mx-1"
                @click.stop="downloadInvoice(invoice)"
                link
                label
                x-small
                :disabled="downloading !== null"
                :loading="downloading === invoice.id">
              <v-icon x-small>mdi-download</v-icon>
            </v-chip>
            <v-chip
                :disabled="invoice.isCanceled || sending !== null"
                class="hidden-until-row-hovered mx-1"
                link
                label
                x-small
                dark
                @click.stop="sendInvoice(invoice)"
                :color="invoice.isSentToStudent ? 'green' : 'blue'">
              <v-icon x-small>{{ invoice.isSentToStudent ? "mdi-send-check" : "mdi-send" }}</v-icon>
            </v-chip>
            <v-chip
                :disabled="invoice.isCanceled || canceling !== null"
                @click.stop="cancelInvoice(invoice.id)"
                class="hidden-until-row-hovered mx-1"
                color="red"
                link
                label
                x-small
                :loading="canceling === invoice.id">
              <v-icon x-small color="white">mdi-close</v-icon>
            </v-chip>
          </template>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import invoicesService from "@/services/invoicesService";
import i18n from "@/plugins/i18n";
import FileSaver from "@/helpers/fileSaver";
import {showConfirm} from '@/helpers/dialogs'

export default {
  name: "InvoicesTable",
  props: {
    invoices: {
      type: Array,
      required: true,
    },
    totalSelectedInvoices: {
      type: Array,
      default: () => []
    },
    student: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      downloading: null,
      canceling: null,
      sending: null,
      selectedInvoicesIds: [],
    };
  },
  created() {
    this.selectedInvoicesIds = this.existingSelectedInvoices
  },
  computed: {
    selectedInvoices() {
      const selectedInvoicesIds = new Set(this.selectedInvoicesIds)
      return this.invoices.filter(item => selectedInvoicesIds.has(item.id))
    },
    totalSelectedInvoicesIds() {
      return this.totalSelectedInvoices.map(item => item.id)
    },
    existingSelectedInvoices() {
      const totalSelectedInvoices = new Set(this.totalSelectedInvoicesIds)

      return this.invoices
          .filter(invoice => totalSelectedInvoices.has(invoice.id))
          .map(item => item.id)
    },
    exceptExistingInvoices() {
      const existingSelectedInvoicesIds = new Set(this.existingSelectedInvoices)

      return this.totalSelectedInvoices.filter(item => !existingSelectedInvoicesIds.has(item.id))
    },
    allSelectionCheckboxValue() {
      return this.existingSelectedInvoices.length === this.invoices.length
    },
    allSelectionCheckboxIndeterminate() {
      return this.existingSelectedInvoices.length !== 0 &&
          this.existingSelectedInvoices.length < this.invoices.length
    }
  },
  watch: {
    selectedInvoicesIds: {
      deep: true,

      handler() {
        const selectedInvoices = this.selectedInvoices.map(item => ({id: item.id, fileName: item.fileName}))
        this.$emit("selectionChange", [...this.exceptExistingInvoices, ...selectedInvoices]);
      },
    },
  },
  methods: {
    openInvoice(invoice) {
      if (this.student) {
        this.downloadInvoice(invoice);
      } else {
        this.$router.push({
          name: "invoices.view",
          params: {id: invoice.id},
        });
      }
    },
    downloadInvoice(invoice) {
      this.downloading = invoice.id;
      invoicesService
          .downloadInvoice(invoice.id)
          .then((resp) => {
            const responseData = [resp.data] || [];
            const file = new FileSaver(responseData)
            file.setType('application/pdf').saveToDevice(invoice.fileName, 'Rechnungen');
          })
          .finally(() => {
            this.downloading = null;
          });
    },
    send(invoice) {
      this.sending = invoice.id;
      invoicesService
          .sendInvoice(invoice.id)
          .then((resp) => {
            const index = this.invoices.findIndex(
                (invoice) => invoice.id === resp.data.id
            );
            if (index !== -1) {
              // this.invoices[index] = resp.data;
              this.$emit("invoiceSent", {index, invoice: resp.data});
            }
            this.$snackbar.show({
              message: this.$t("label.invoice_sent"),
              color: "green",
              timeout: 5000,
            });
          })
          .catch((error) => {
            if (!error.response) console.log(error);

            const responseData = error.response.data;

            if (responseData.message) {
              this.$snackbar.show({
                message: responseData.message,
                color: "error",
              });
            }
          })
          .finally(() => {
            this.sending = null;
          });
    },
    sendInvoice(invoice) {
      if (invoice.isSentToStudent) {
        showConfirm(
            i18n.t("label.invoice_already_sent"),
            i18n.t("label.do_you_want_to_send_invoice_again"),
            () => {
              this.send(invoice)
            }
        );
      } else {
        this.send(invoice);
      }
    },
    cancelInvoice(id) {
      const invoiceIndex = this.invoices.findIndex(
          (invoice) => invoice.id === id
      );
      const invoice = this.invoices[invoiceIndex]

      showConfirm(
          this.$t("label.invoice_cancellation_question"),
          invoice.studentId
              ? this.$t("label.invoice_cancellation_description")
              : this.$t("label.studentless_invoice_cancellation_description"),
          () => {
            this.canceling = id;
            invoicesService
                .cancel(id)
                .then((resp) => {
                  const index = this.invoices.findIndex(
                      (invoice) => invoice.id === resp.data.id
                  );
                  if (index !== -1) {
                    // this.invoices[index] = resp.data;
                    this.$emit("invoiceCancelled", {index, invoice: resp.data});
                  }
                })
                .finally(() => {
                  this.canceling = null;
                });
          }
      );
    },
    toggleAll() {
      this.selectedInvoicesIds =
          this.selectedInvoicesIds.length === this.invoices.length
              ? []
              : this.invoices.map((item) => item.id);
    },
    toggleItem(id) {
      if (this.selectedInvoicesIds.includes(id)) {
        this.selectedInvoicesIds.splice(this.selectedInvoicesIds.indexOf(id), 1);
      } else {
        this.selectedInvoicesIds.push(id);
      }
    },
    invoiceIcon(invoice) {
      if (invoice.isCanceled) {
        return 'mdi-close';
      } else if (invoice.isPaid) {
        return 'mdi-cash-check';
      } else {
        return 'mdi-cash-remove';
      }
    },
    invoiceColor(invoice) {
      if (invoice.isCanceled) {
        return 'grey lighten-3';
      } else if (invoice.isPaid) {
        return 'green lighten-2';
      } else {
        return 'red lighten-3';
      }
    }
  },
};
</script>

<style scoped lang="scss">
.hidden-until-row-hovered {
  display: none;
}

.table-row:hover > td > .hidden-until-row-hovered {
  display: inline-flex;
}

.canceled-invoice {
  text-decoration: line-through;
  color: #e89292;
  opacity: .8;
}

td.ellipsis
{
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
