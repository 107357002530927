<script>
import {defineComponent} from 'vue'
import appointmentService from "@/services/appointmentService";

export default defineComponent({
  name: "AppointmentRefundDialog",
  data() {
    return {
      show: false,
      loading: false,
      refundPercentage: 0,
      appointmentCost: null,
      amountAlreadyRefunded: null,
      refunding: false,
    }
  },
  methods: {
    open(appointmentId) {
      this.show = true
      this.loading = true
      this.id = appointmentId
      appointmentService.get(appointmentId)
        .then(resp => {
          const appointment = resp.data
          this.appointmentCost = appointment.cost
          this.amountAlreadyRefunded = parseFloat(appointment.refundedAmount)
          this.refundPercentage = this.alreadyRefundedPercentage
        })
        .finally(() => this.loading = false)
    },
    close() {
      this.show = false
      this.refundPercentage = 0;
      this.appointmentCost = null;
      this.amountAlreadyRefunded = null;
      this.$emit('close')
    },
    refund() {
      this.refunding = true
      appointmentService.refund_practice(this.id, { amount: this.amountToRefund })
        .then((resp) => {
          this.$emit('refunded', resp.data)
          this.close()
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => this.refunding = false)
    }
  },
  computed: {
    refundDisabled() {
      return this.amountToRefund === 0 || this.loading || this.refunding
    },
    alreadyRefundedPercentage() {
      return this.amountAlreadyRefunded / this.appointmentCost * 100
    },
    amountToRefund() {
      // calculate the amount to be refunded excluding already refunded amount
      return (this.appointmentCost * this.refundPercentage / 100) - this.amountAlreadyRefunded
    },
  }
})
</script>

<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="500"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card :loading="loading">
      <v-toolbar color="primary" dark dense>
        <v-toolbar-title>{{ $t('label.refund_appointment') }}</v-toolbar-title>
        <v-spacer/>
        <v-btn dark icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-5">
        <p>{{ $t('label.select_refund_amount') }}</p>
        <v-slider
          v-model="refundPercentage"
          step="5"
          thumb-label
          ticks
          hide-details
          :disabled="loading || refunding || alreadyRefundedPercentage === 100"
          :min="alreadyRefundedPercentage"
        >
          <template v-slot:thumb-label="{ value }">
            {{ `${value}%` }}
          </template>
        </v-slider>
        <v-chip-group>
          <v-chip
            small
            link
            v-for="item in [25, 50, 75, 100]"
            :key="item"
            :class="{'primary': item === refundPercentage}"
            @click="refundPercentage = item"
            :disabled="item <= alreadyRefundedPercentage || loading || refunding"
          >
            {{ `${item}%` }}
          </v-chip>
        </v-chip-group>
        <v-list dense>
          <v-list-item dense>
            <v-list-item-content>
              <v-list-item-title>{{ $t('label.appointment_total_cost') }}</v-list-item-title>
              <v-list-item-subtitle>
                <v-skeleton-loader type="text@1" width="100" v-if="loading" />
                <span v-else>
                  {{ appointmentCost | currency }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense>
            <v-list-item-content>
              <v-list-item-title> {{$t('label.amount_already_refunded')}} </v-list-item-title>
              <v-list-item-subtitle>
                <v-skeleton-loader type="text@1" width="100" v-if="loading" />
                <span v-else>
                  {{ amountAlreadyRefunded | currency }} ({{ alreadyRefundedPercentage.toFixed(0) }}%)
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense>
            <v-list-item-content>
              <v-list-item-title>{{ $t('label.amount_will_be_refunded') }}</v-list-item-title>
              <v-list-item-subtitle>
                <v-skeleton-loader type="text@1" width="100" v-if="loading" />
                <span v-else>
                  {{ alreadyRefundedPercentage.toFixed(0) == refundPercentage ? 0 : amountToRefund | currency }}
<!--                  {{ alreadyRefundedPercentage.toFixed(0) == refundPercentage ? '' : `(${refundPercentage}%)` }}-->
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-btn text @click="close">{{ $t('btn.cancel') }}</v-btn>
        <v-spacer/>
        <v-btn color="primary" @click="refund" :disabled="refundDisabled" :loading="refunding">
          <v-icon left>mdi-cash-refund</v-icon>
          {{ $t('btn.refund') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>
