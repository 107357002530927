<template>
  <div>
    <div v-if="eventType === 'THEORY'" class="students-counter ml-2">
      <label> {{roomSize > 0 ? studentsCount + "/" + roomSize : studentsCount }} </label>
      <v-icon class="ml-1 icon" size="15">mdi-account-multiple</v-icon>
    </div>

    <div v-else class="students-counter ml-2">
      <span v-if="studentsIds.length === 1" :class="{ 'font-italic': student.isDeleted }">
        {{ student.fullName }}
      </span>
      <div v-else>
        <label>{{ studentsIds.length }}</label>
        <v-icon class="ml-1 icon" size="15">mdi-account-multiple</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import responsive from "@/utils/mixins/responsive";
import StudentModel from "@/store/models/StudentModel";

export default {
  name: "EventSubscribedStudents",
  mixins: [responsive],
  props: {
    eventType: {
      type: String,
    },
    studentsCount: {
      type: [Number, String],
      default: 0,
    },
    studentsIds: {
      type: Array,
      default: () => [],
    },
    roomSize: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    student() {
      return StudentModel.find(this.studentsIds[0]) || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.students-counter {
  border-radius: 10px;
  padding: 0 5px;
  position: relative;
  bottom: 1px;
  label {
    position: relative;
    top: 1px;
  }
  .icon {
    color: inherit;
    line-height: 0;
  }
}
</style>
