<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <div class="page-grid">
      <v-row dense v-if="$vuetify.breakpoint.mobile">
        <v-col cols="12">
          <TeacherInfoCard orientation="horizontal" :teacher-id="$route.params.id"/>
        </v-col>
      </v-row>

      <div v-if="!$vuetify.breakpoint.mobile">
        <v-card class="pa-3 mb-2" color="white" elevation="1" width="100%" rounded>
          <div class="d-flex flex-column text-center mx-3">
            <div class="d-flex mx-auto">
              <v-badge
                class="user__color"
                :color="item.color"
                bottom
                overlap
                bordered
                avatar
                offset-x="72"
                offset-y="21"
                size="80"
              >
                <UserAvatar class="elevation-2" size="130" :path="item.avatar" :alt="item.fullName" />
              </v-badge>
            </div>

            <div class="d-flex flex-column">
              <span class="mt-3 text-h5">{{ item.fullName }}</span>
              <span v-if="item.email" class="text-subtitle-2">{{
                item.email
              }}</span>
              <span class="text-subtitle-1 font-weight-medium"> </span>
            </div>
          </div>

          <v-divider class="my-4" />

          <v-list class="pt-0" dense>
            <v-list-item v-if="item.birthDate" class="pa-0 mb-2">
              <v-list-item-content class="pa-0">
                <v-list-item-title>{{ $t("form.birthday") }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  item.formatBirthDate
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="item.phone" class="pa-0 mb-2">
              <v-list-item-content class="pa-0">
                <v-list-item-title>{{
                  $t("form.phone_mobile")
                }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.phone }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="item.landline" class="pa-0 mb-2">
              <v-list-item-content class="pa-0">
                <v-list-item-title>{{
                  $t("form.phone_landline")
                }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.landline }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="item.street && item.houseNr && item.postcode && item.city"
              class="pa-0 mb-2"
              two-line>
              <v-list-item-content class="pa-0">
                <v-list-item-title>{{ $t("form.address") }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  item.street + " " + item.houseNr
                }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{
                  item.postcode + " " + item.city
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>

        <v-list
            class="mt-2 mb-2"
            dense
            nav
            width="100%"
            color="white"
            elevation="1"
            rounded
        >
          <v-list-item @click="openMessengerRoom(item)">
            <v-list-item-icon>
              <v-icon>mdi-chat-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{$t("chat.write_a_message")}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="editItem(item)">
            <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("btn.edit") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isSchoolAdministrator" @click="deleteItem(item)">
            <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("btn.delete") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>


        <v-list
            dense
            nav
            width="100%"
            class="mb-2"
            color="white"
            elevation="1"
            rounded
        >
          <v-list-item link @click="$refs.dailyEvents.open()">
            <v-list-item-icon>
              <v-icon>mdi-file-document</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("label.daily_work_report") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <div>
        <v-card v-show="$route.name === 'teachers.teacher'">
          <v-toolbar class="px-2" elevation="3">
            <v-btn class="mr-4" :to="{ name: 'teachers' }" icon exact>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>

            <span class="title">{{ $t("btn.back") }}</span>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" lg="4" v-for="tab in tabs" :key="tab.value">
                <component
                    :is="tab.component"
                    :student-id="item.id"
                    :disabled="item.isDeleted"
                    :params="tab.params" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <router-view />
      </div>
    </div>
    <TeacherFormDialog ref="formItem" />
    <TeacherDailyEvents ref="dailyEvents" :teacher="item" />
  </v-container>
</template>

<script>
import teachersService from "@/services/teachersService";
import TeacherModel from "@/store/models/TeacherModel";
import user from "@/utils/mixins/user";
import messenger from "@/utils/mixins/messenger";
import TeacherFormDialog from "@/components/teacher/TeacherFormDialog";
import TeacherWorkTimeCard from "@/components/teacher/TeacherWorkTimeCard";
import TeacherDailyEvents from "@/components/teacher/TeacherDailyEvents";
import responsive from "@/utils/mixins/responsive";
import UserAvatar from '@/components/UserAvatar'
import TeacherInfoCard from '@/components/teacher/TeacherInfoCard.vue'
import {showConfirm} from "@/helpers/dialogs";

export default {
  name: "Teacher",
  components: { UserAvatar, TeacherInfoCard, TeacherDailyEvents, TeacherFormDialog, TeacherWorkTimeCard },
  mixins: [user, messenger, responsive],
  data: () => ({
    loadingAction: false,
    activeTab: "work-statistics",
  }),
  computed: {
    item() {
      return TeacherModel.find(this.$route.params.id) || {};
    },
    tabs() {
      return [
        { value: "work-time", component: "TeacherWorkTimeCard", isShow: true },
      ].filter((item) => item.isShow);
    },
  },
  beforeRouteEnter(to, from, next) {
    const teacherId = to.params.id;
    const teacher = TeacherModel.find(teacherId);

    if (teacher) return next();

    teachersService
      .loadItem(teacherId)
      .then((resp) => {
        TeacherModel.insert({ data: resp.data || {} }).then(() => {
          return next();
        });
      })
      .catch((error) => {
        const response = error.response || {};
        const { status, data } = response;

        if (status && [404, 403].includes(status)) {
          next((vm) => {
            vm.$router.push({ name: "teachers" });
            vm.$snackbar.show({
              message: data ? data.message : "",
              color: "error",
            });
          });
        }
      });
  },
  methods: {
    editItem(item) {
      this.$refs.formItem.open(item);
    },
    deleteItem(item) {
      this.loadingAction = true;
      showConfirm(
          this.$i18n.t('alert.are_you_sure'),
          this.$i18n.t("messages.are_your_sure_delete"),
          () => {
            teachersService
                .destroy(item.id)
                .then(() => {
                  this.$router.push({ name: "teachers" });
                })
                .catch((error) => console.log(error))
                .finally(() => {
                  this.loadingAction = false;
                });
          }
      );
      this.loadingAction = false;
    },
    openMessengerRoom(teacher) {
      this.$router.push({ name: "messenger" }).then(() => this.openDialogRoom(teacher));
    },
    onTabClick(tab) {
      this.$router
        .push({ name: "teachers.teacher", query: { tab: tab } })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style scoped></style>
