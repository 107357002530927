import { Model } from "@vuex-orm/core";
import { v4 as uuid4 } from "uuid";
import {
  formatDuration,
  formatISO,
  intervalToDuration,
  parseISO,
} from "date-fns";

import AppointmentCreatedNotification from "@/notifications/events/appointmentCreatedNotification";
import OfftimeCreatedNotification from "@/notifications/events/offtimeCreatedNotification";
import SpecialCreatedNotification from "@/notifications/events/specialCreatedNotification";
import TheoryCreatedNotification from "@/notifications/events/theoryCreatedNotification";
import AppointmentUpdatedNotification from "@/notifications/events/appointmentUpdatedNotification";
import AppointmentDeletedNotification from "@/notifications/events/appointmentDeletedNotification";
import AppointmentRestoredNotification from "@/notifications/events/appointmentRestoredNotification";
import PlaceholderRequestedNotification from "@/notifications/events/placeholderRequestedNotification";
import PlaceholderConfirmedNotification from "@/notifications/events/placeholderConfirmedNotification";
import PlaceholderDeclinedNotification from "@/notifications/events/placeholderDeclinedNotification";
import PracticeRequestedNotification from "@/notifications/events/practiceRequestedNotification";
import PracticeConfirmedNotification from "@/notifications/events/practiceConfirmedNotification";
import PracticeDeclinedNotification from "@/notifications/events/practiceDeclinedNotification";
import TheoryParticipantAddedNotification from "@/notifications/events/theoryParticipantAddedNotification";
import TheoryParticipantRemovedNotification from "@/notifications/events/theoryParticipantRemovedNotification";
import SimulatorSubscribedNotification from "@/notifications/events/simulatorSubscribedNotification";
import SimulatorUnsubscribedNotification from "@/notifications/events/simulatorUnsubscribedNotification";

import StudentBalanceUpdated from "@/notifications/student/studentBalanceUpdated";
import StudentNegativeBalanceReminder from "@/notifications/student/studentNegativeBalanceReminder";
import StudentInsufficientBalanceReminder from "@/notifications/student/studentInsufficientBalanceReminder";
import StudentDocumentUploaded from "@/notifications/student/studentDocumentUploaded";
import StudentReplenishedBalance from "@/notifications/student/studentReplenishedBalance";
import AppointmentReminder from "@/notifications/events/appointmentReminder";
import Notification from "@/notifications/notification";
import StudentEducationStarted from "@/notifications/student/studentEducationStarted";
import StudentRegistered from "@/notifications/student/studentRegistered";
import PlaceholderByLicenseCreated from "@/notifications/events/placeholderByLicenseCreated";
import LocalizationManager from "@/helpers/LocalizationManager";
import SupportTicketResponseNotification from "@/notifications/events/supportTicketResponseNotification";
import AppointmentsListExportCompletedNotification
  from "@/notifications/events/appointmentsListExportCompletedNotification";

export default class NotificationModel extends Model {
  static entity = "notifications";

  constructor(props) {
    super(props);
    this.typeInstance = this.createTypeInstance();
  }

  static state() {
    return {
      totalUnreadCount: 0,
    };
  }

  static fields() {
    return {
      id: this.uid(() => uuid4()),
      type: this.string(""),
      userId: this.string(""),
      readAt: this.attr(null),
      data: this.attr({}),
      createdAt: this.attr(() => formatISO(new Date())),
    };
  }

  get icon() {
    return this.typeInstance.icon();
  }

  get titleByType() {
    return this.typeInstance.title();
  }

  get subtitleByType() {
    return this.typeInstance.subtitle();
  }

  get messageByType() {
    return this.typeInstance.message();
  }

  get studentName() {
    return this.data?.studentName;
  }

  actionByType() {
    return this.typeInstance.action();
  }

  createTypeInstance() {
    if (this.typeInstance) return this.typeInstance;

    const types = {
      AppointmentCreatedNotification,
      OfftimeCreatedNotification,
      SpecialCreatedNotification,
      TheoryCreatedNotification,
      AppointmentDeletedNotification,
      AppointmentRestoredNotification,
      AppointmentUpdatedNotification,
      PlaceholderRequestedNotification,
      PlaceholderConfirmedNotification,
      PlaceholderDeclinedNotification,
      PracticeRequestedNotification,
      PracticeConfirmedNotification,
      PracticeDeclinedNotification,
      SimulatorSubscribedNotification,
      SimulatorUnsubscribedNotification,
      TheoryParticipantAddedNotification,
      TheoryParticipantRemovedNotification,
      AppointmentReminder,
      PlaceholderByLicenseCreated,
      StudentEducationStarted,
      StudentBalanceUpdated,
      StudentNegativeBalanceReminder,
      StudentInsufficientBalanceReminder,
      StudentDocumentUploaded,
      StudentRegistered,
      SupportTicketResponseNotification,
      StudentReplenishedBalance,
      AppointmentsListExportCompletedNotification
    };

    return this.type in types ?
        new types[this.type](this) :
        new Notification(this);
  }

  get timeAgo() {
    const durationByTimeParams = intervalToDuration({
      start: parseISO(this.createdAt),
      end: new Date(),
    });
    const firstNonZeroParam =
      Object.keys(durationByTimeParams).find(
        (key) => durationByTimeParams[key] !== 0
      ) || "seconds";
    if (firstNonZeroParam === "seconds" && durationByTimeParams.seconds === 0)
      durationByTimeParams.seconds = 1;
    return formatDuration(durationByTimeParams, {
      format: [firstNonZeroParam],
      locale: LocalizationManager.dateFnsLocale,
    });
  }
}
