<template>
  <component :is="cardWrapper ? 'VCard' : 'div'">
    <v-toolbar v-if="$route.name === 'students.student.finances.overview'" class="px-2" elevation="3">
      <v-btn
        class="mr-4"
        icon
        :to="{ name: 'students.student.finances', id: $route.params.id }"
        exact
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="title">{{ $t("label.overview") }}</span>
    </v-toolbar>

    <component :is="cardWrapper ? 'VCardText' : 'div'">
      <v-row class="my-1 d-flex align-center">
        <v-col cols="12" md="4">
          <v-dialog
              ref="dialog"
              v-model="calendarDialog"
              :return-value.sync="date"
              persistent
              width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  solo
                  dense
                  readonly
                  :value="formattedDates"
                  :label="$t('label.filter_by_date')"
                  prepend-inner-icon="mdi-calendar"
                  clearable
                  hide-details
                  @click:clear="date = []"
                  v-bind="attrs"
                  v-on="on">
              </v-text-field>
            </template>
            <v-date-picker v-model="date" scrollable range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="calendarDialog = false">
                {{ $t("btn.cancel") }}
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(date.sort())"
              >
                {{ $t("btn.ok") }}</v-btn
              >
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
              dense
              solo
              hide-details
              :items="licenses"
              item-value="id"
              item-text="name"
              v-model="filters.licenses"
              multiple
              :placeholder="$t('form.licenses')"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn text color="primary" class="mr-1" @click="filter" :loading="loading">
            {{$t("btn.filter_results")}}
          </v-btn>
          <v-btn color="primary" @click="download" text :loading="downloading" :disabled="loading || downloading">
            {{$t('btn.export')}}
          </v-btn>
        </v-col>
      </v-row>

      <v-card outlined elevation="0" :loading="loading">
        <v-card-title>
          {{ $t('label.deliveredServices') }}
        </v-card-title>
        <v-card-text>
          <v-skeleton-loader type="table-row@5" v-if="loading"/>
          <template v-else>
            <v-alert type="warning" v-if="servicesWithoutLicenseExist">
              <div class="text-h6">
                {{ $t('label.we_have_an_issue') }}
              </div>
              <div>
                {{ $t('label.assign_a_license_to_services_without_one') }}
              </div>
            </v-alert>
            <v-simple-table v-if="services.length" dense>
              <thead>
              <tr>
                <th scope="row">{{ $t('list.name') }}</th>
                <th scope="row" style="width: 200px">{{ $t('label.date') }}</th>
                <th scope="row" style="width: 150px">{{ $t('label.license') }}</th>
                <th scope="row" style="width: 150px">{{ $t('form.price') }}</th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="service in services" :key="service.id" :class="! service.license ? 'warning white--text' : ''">
                  <td>{{ service.description }}</td>
                  <td class="text-monospace">{{ service.date | dateFormat('dd.MM.yyyy') }}</td>
                  <td v-if="service.license">{{ service.license.name }}</td>
                  <td v-else>
                      <v-select
                        dense
                        hide-details
                        dark
                        :items="licenses"
                        item-value="id"
                        item-text="name"
                        v-model="service.licenseId"
                        style="width: 150px;"
                        :placeholder="$t('label.assign_a_license')"
                        append-outer-icon="mdi-check"
                        @click:append-outer="confirmSelectedLicense(service.id)"
                        :disabled="updatingService === service.id"
                        :loading="updatingService === service.id"
                      ></v-select>
                  </td>
                  <th scope="col">{{ service.cost | currency }}</th>
                </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="3" class="text-right">{{ $t('label.total') }}</td>
                <th>{{ servicesTotalCost | currency }}</th>
              </tr>
              </tfoot>
            </v-simple-table>
            <empty-box v-else/>
          </template>
        </v-card-text>
      </v-card>
      <v-card v-if="student.bookAndPay" outlined elevation="0" class="mt-5" :loading="loading">
        <v-card-title>
          {{ $t('label.payments') }}
        </v-card-title>
        <v-card-text>
          <v-skeleton-loader type="table-row@5" v-if="loading"/>
          <template v-else>
            <v-simple-table dense v-if="payments.length">
              <thead>
              <tr>
                <th scope="row" style="width: 250px">{{ $t('label.payment_method') }}</th>
                <th scope="row">{{ $t('label.notes') }}</th>
                <th scope="row" style="width: 180px">{{ $t('label.date') }}</th>
                <th scope="row" style="width: 150px">{{ $t('label.amount') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="payment in payments" :key="payment.id">
                <td>{{ $t(`history_action.${payment.action}`) }}</td>
                <td>{{ payment.note }}</td>
                <td class="text-monospace">{{ payment.createdAt | dateFormat }}</td>
                <th scope="col">{{ payment.moneyAmount | currency }}</th>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="3" class="text-right">{{ $t('label.total') }}</td>
                <th>{{ paymentsTotalAmount | currency }}</th>
              </tr>
              </tfoot>
            </v-simple-table>
            <empty-box v-else/>
          </template>
        </v-card-text>
      </v-card>
    </component>
  </component>
</template>

<script>
import { VCard, VCardText } from 'vuetify/lib'
import StudentModel from "@/store/models/StudentModel";
import user from "@/utils/mixins/user";
import studentsService from "@/services/studentsService";
import LicenseModel from "@/store/models/LicenseModel";
import formattedDatesMixin from "@/utils/mixins/formattedDatesMixin";
import FileSaver from "@/helpers/fileSaver";

export default {
  name: "StudentsCostsOverview",
  components: { VCard, VCardText },
  mixins: [user, formattedDatesMixin],
  data() {
    return {
      studentId: null,
      updatingService: null,
      services: [],
      payments: [],
      loading: false,
      downloading: false,
      calendarDialog: false,
      filters: {
        licenses: [],
      }
    }
  },
  props: {
    cardWrapper: {
      type: Boolean,
      default: true
    }
  },
  beforeRouteEnter(from, to, next) {
    const student = StudentModel.find(from.params.id);

    if ((student && student.isDeleted)) {
      next({ name: "students.student", params: { id: from.params.id } });
      return;
    }

    next();
  },
  mounted() {
    this.studentId = this.$route.params.id

    this.filter()
  },
  computed: {
    student () {
      return StudentModel.find(this.$route.params.id)
    },
    servicesWithoutLicenseExist() {
      return this.services.some(service => service.license === null);
    },
    servicesTotalCost () {
      return this.services.reduce((a, b) => {
        return a + b.cost
      }, 0)
    },
    paymentsTotalAmount () {
      return this.payments.reduce((a, b) => {
        return a + parseFloat(b.moneyAmount)
      }, 0)
    },
    licenses() {
      return LicenseModel.findIn(this.student.licensesIds) || {};
    },
    params () {
      return {
        from: this.startDate,
        to: this.endDate,
        licenses: this.filters.licenses
      }
    }
  },
  methods: {
    filter () {
      this.load(this.params)
    },
    download() {
      this.downloading = true

      studentsService.costsOverviewPdf(this.studentId, this.params)
        .then(resp => {
          const responseData = [resp.data] || [];
          const fileSaver = new FileSaver(responseData)
          fileSaver.setType('application/pdf').saveToDevice('Costs Overview', 'Reports')
        })
        .finally(() => {
          this.downloading = false
        })
    },
    load (params = null) {
      this.loading = true
      studentsService.costsOverview(this.studentId, params).then(resp => {
        this.services = resp.data.services
        this.payments = resp.data.payments

        if (this.licenses.length === 1) {
          const licenseId = this.licenses[0].id

          this.services = this.services.map(service => {
            return service.licenseId === null ? { ...service, licenseId } : service
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    confirmSelectedLicense(serviceId) {
      this.updatingService = serviceId
      const serviceIndex = this.services.findIndex(item => item.id === serviceId)
      const service = this.services[serviceIndex]
      studentsService
          .assignLicenseToService(this.studentId, service.id, { licenseId: service.licenseId })
          .then(resp => {
            // trigger reactivity
            this.$set(this.services[serviceIndex], 'license', resp.data.license)
          })
          .finally(() => {
            this.updatingService = null
          })
    }
  }
};
</script>

<style scoped></style>
