<script>
import CategoryModel from "@/store/models/insights/CategoryModel";
import EvaluationModel from '@/store/models/insights/EvaluationModel'
import EvaluationPreviewDialog from '@/components/insights/EvaluationPreviewDialog.vue'
import user from '@/utils/mixins/user'

export default {
  name: 'TopBar',
  mixins: [user],
  components: { EvaluationPreviewDialog },
  data: () => ({
    loading: false,
    showDialog: false,
  }),
  props: {
    loadingEvaluation: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    appointmentId() {
      return parseInt(this.$route.params.id);
    },
    evaluation () {
      return EvaluationModel.query().with('items').where('appointmentId', parseInt(this.appointmentId)).first()
    },
    evaluationItemsAmount () {
      let items = this.evaluation?.items || []
      if (this.isStudent) {
        return items.filter(item => item.pivot?.score === 0).length || 0
      }
      return items.length || 0
    },
    preventPublish() {
      if (this.isStudent) {
        return this.evaluationItemsAmount > 0
      }
      return this.evaluationItemsAmount === 0
    },

    activeCategory () {
      const categoryId = parseInt(this.$route.params.categoryId)
      return CategoryModel.find(categoryId)
    },
    categorySelected () {
      return this.activeCategory !== null
    },
    backButtonRoute() {
      if (! this.categorySelected) return { name: 'calendar.appointment', params: { id: this.appointmentId } }

      return this.activeCategory.parentId ?
        { name: 'calendar.appointment.evaluation.category', params: { categoryId: this.activeCategory.parentId } } :
        { name: 'calendar.appointment.evaluation', params: { id: this.appointmentId } }
    }
  }

}
</script>

<template>
  <v-app-bar elevation="0" absolute color="white" dense style="padding-top: 0 !important;">
    <v-btn class="mr-2" :to="backButtonRoute" icon exact>
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <v-toolbar-title class="pa-0" style="font-size: 1em">
      {{ activeCategory?.name ?? $t('insights.select_a_topic') }}
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn
      :disabled="loadingEvaluation || loading || preventPublish"
      :loading="(loadingEvaluation || loading) &&  preventPublish"
      color="primary"
      small
      :dark="!loadingEvaluation && !loading && !preventPublish"
      @click="$refs.evaluationPreviewDialog.open(evaluation)"
    >
      <v-icon left>mdi-format-list-checks</v-icon>
      {{ $t('insights.btn.preview') }} <span v-if="evaluationItemsAmount">({{ evaluationItemsAmount }})</span>
    </v-btn>
    <evaluation-preview-dialog ref="evaluationPreviewDialog" />
  </v-app-bar>
</template>

<style lang="scss" scoped>

</style>
