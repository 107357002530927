<template>
  <v-simple-table v-if="events.length" :events="events" dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th width="20px">
            <v-checkbox
              class="ma-2"
              hide-details
              dense
              @click="toggleEventsSelector"
              v-model="checkedAll"
              :indeterminate="indeterminate" />
          </th>
          <th width="120px">{{ $t("date") }}</th>
          <th>{{ $t("time") }}</th>
          <th>{{ $t("label.teacher") }}</th>
          <th>{{ $t("theories_title") }}</th>
          <th class="text-center">{{ $t("students") }}</th>
          <th class="text-center" style="width: 90px; text-align: right"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="event in events" :key="event.id" class="table-row">
          <td>
            <v-checkbox
              class="ma-2"
              hide-details
              dense
              v-model="selectedTheories"
              :value="event.id" />
          </td>
          <td>{{ event.formattedDate }}</td>
          <td>
            <div class="text-overflow-ellipsis" style="max-width: 120px">
              {{ `${event.startTime} - ${event.endTime}` }}
            </div>
          </td>
          <td>
            <div class="text-overflow-ellipsis" style="max-width: 200px">
              {{ event.teacherName }}
            </div>
          </td>
          <td>
            <div class="text-overflow-ellipsis" style="max-width: 400px">
              {{ topic(event.theoryTopicId) }}
            </div>
          </td>
          <td class="text-center">{{ classRoomSizeLabel(event) }}</td>
          <td class="text-center">
            <v-progress-linear
              indeterminate
              color="primary"
              v-if="downloading === event.id"
            />

            <template v-else>
              <v-chip
                  class="hidden-until-row-hovered mx-0"
                  @click.stop="generatePdf(event.id)"
                  link
                  label
                  x-small
                  :disabled="downloading !== null"
              >
                <v-icon x-small>mdi-download</v-icon>
              </v-chip>
            </template>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import TheoryTopicModel from "@/store/models/TheoryTopicModel";
import { mapGetters } from "vuex";
import axios from "axios";
import JSZip from "jszip";
import appointmentService from '@/services/appointmentService'
import FileSaver from "@/helpers/fileSaver";

export default {
  name: "TheoriesTable",
  data: () => ({
    downloading: null,
    selectedTheories: [],
    checkedAll: false,
    indeterminate: false,
  }),
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  watch: {
    selectedTheories(value) {
      const eventsNr = this.events.length;
      const selectedNr = value.length;
      this.indeterminate = (selectedNr > 0 && selectedNr < eventsNr) || false;
      this.checkedAll = eventsNr === selectedNr || false;
      this.$emit("childToParent", value);
    }
  },
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
  },
  methods: {
    toggleEventsSelector() {
      const eventsNr = this.events.length;
      const selectedNr = this.selectedTheories.length;
      if (selectedNr === eventsNr) {
        this.selectedTheories = [];
      } else {
        this.selectedTheories = this.events.map((event) => event.id);
      }
    },
    classRoomSizeLabel(event) {
      const presentStudents = event.studentsIds.filter((student) => student.isPresent) || [];

      let label = presentStudents.length
      if (event.roomSize) label = label + `/${event.roomSize}`

      return label;
    },
    topic(id) {
      const topic = TheoryTopicModel.find(id) || {};
      return `${topic.orderIndex}. ${topic.title}`;
    },
    generatePdf (id) {
      this.downloading = id
      appointmentService.get_theory_attendance_report(id)
        .then(response => this.saveAsSingleFile(response.data, id))
        .finally(() => {
          this.downloading = null
        });
    },
    exportPdfList(ids = []) {
      const pdfDocsRequests = ids.map((id) => appointmentService.get_theory_attendance_report(id));

      return axios
        .all(pdfDocsRequests)
        .then(
            axios.spread((...responses) => {
              if (responses.length === 1) {
                this.saveAsSingleFile(responses[0].data, ids[0]);
              }

              if (responses.length > 1) {
                this.saveAsZip(responses, ids)
              }
            })
        )
    },
    generateFileName(event) {
      return `Anwesenheitsliste ${event.teacherFullName} ${event.formattedDate} ${event.startTime} ${event.endTime}`;
    },
    saveAsZip(responses, ids) {
      const zip = new JSZip();

      responses.forEach((response, index) => {
        const event = this.events.find(event => event.id === ids[index]);
        zip.file(this.generateFileName(event) + ".pdf", response.data || {});
      });

      zip.generateAsync({ type: "blob" }).then((content) => {
        const file = new FileSaver([content])
        file.setType("blob").saveToDevice(`Anwesenheitsliste_${responses.length}.zip`, "Anwesenheitsliste");
      });
    },
    saveAsSingleFile(responseDate, id) {
      const event = this.events.find((event) => event.id === id);
      const fileSaver = new FileSaver([responseDate])

      fileSaver.setType("application/pdf").saveToDevice(this.generateFileName(event) + ".pdf", "Anwesenheitsliste");
    }
  },
};
</script>

<style scoped lang="scss">
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
</style>
