<template>
  <v-dialog
    max-width="750px"
    :value="showModalGetter"
    @click:outside="closeAppointmentModal"
    @keydown.esc="closeAppointmentModal"
    :fullscreen="isMobile"
    :hide-overlay="isMobile"
    scrollable>
    <v-card :tile="isMobile" style="padding-top:0!important;">
      <TopBar class="flex-shrink-0 flex-grow-0" :color="appointmentColor.bodyColor" :happyHour="false" />

      <AppointmentTypesTabs class="my-2" :color="appointmentColor.bodyColor" />
      <v-divider></v-divider>

      <v-card-text class="mt-3 pt-1">
        <template v-if="skeleton">
          <component :is="skeletonComponent" />
        </template>

        <template v-else>
          <FormDateTimeArea />
          <ValidationObserver ref="observer">
            <component
                class="mt-3"
               :is="formComponent"
               :teachers="teachers"
               @emitPickRequestedStudent="pickRequestedStudent"
               :placeholderRequestedStudent = placeholderRequestedStudent />
          </ValidationObserver>
        </template>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn @click="closeAppointmentModal" :icon="isMobile" text color="black" :disabled="appointmentLoader">
          <v-icon :left="!isMobile">mdi-close</v-icon>
          <span v-if="!isMobile">{{ $t('btn.close') }}</span>
        </v-btn>

        <v-spacer />

        <v-btn v-if="showDeleteBtn"
               @click="deleteApp"
               color="error"
               :disabled="appointmentLoader"
               :loading="buttonLoaders.deleteLoader">
          <v-icon :left="!isMobile">{{ currentAppointment.type === 'PLACEHOLDER' ? 'mdi-delete-forever' : 'mdi-delete' }}</v-icon>
          <span v-if="!isMobile">{{ $t('btn.delete') }}</span>
        </v-btn>

        <v-btn v-if="showUpdateBtn"
               @click="updateApp"
               color="primary"
               :disabled="appointmentLoader"
               :loading="buttonLoaders.updateLoader">
          <v-icon :left="!isMobile">mdi-content-save-edit</v-icon>
          <span v-if="!isMobile">{{ $t('btn.update') }}</span>
        </v-btn>

        <v-btn v-if="showCreateBtn"
               @click="createApp"
               :icon="isMobile"
               color="primary"
               :disabled="appointmentLoader"
               :loading="buttonLoaders.createLoader">
          <v-icon :left="!isMobile">mdi-content-save</v-icon>
          <span v-if="!isMobile">{{ $t('btn.create') }}</span>
        </v-btn>

        <template  v-if="showRequestedPlaceholderBtn">
          <v-btn @click="declinePlaceholder"
                 color="error"
                 :disabled="appointmentLoader"
                 :loading="buttonLoaders.confirmLoader">
            <v-icon :left="!isMobile">mdi-minus-circle</v-icon>
            <span v-if="!isMobile">{{ $t('btn.decline') }}</span>
          </v-btn>

          <v-btn @click="confirmCurrentPlaceholder"
                 color="primary"
                 :disabled="appointmentLoader"
                 :loading="buttonLoaders.declineLoader">
            <v-icon :left="!isMobile">mdi-check-circle</v-icon>
            <span v-if="!isMobile">{{ $t('btn.confirm') }}</span>
          </v-btn>
        </template>

        <template  v-if="showRequestedPracticeBtn">
          <v-btn @click="declinePractice"
                 color="error"
                 :disabled="appointmentLoader"
                 :loading="buttonLoaders.confirmLoader">
            <v-icon :left="!isMobile">mdi-minus-circle</v-icon>
            <span v-if="!isMobile">{{ $t('btn.decline') }}</span>
          </v-btn>

          <v-btn @click="confirmPractice"
                 color="primary"
                 :disabled="appointmentLoader"
                 :loading="buttonLoaders.declineLoader">
            <v-icon :left="!isMobile">mdi-check-circle</v-icon>
            <span v-if="!isMobile">{{ $t('btn.confirm') }}</span>
          </v-btn>
        </template>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import user from '@/utils/mixins/user'
import colorMixin from '@/utils/mixins/colorMixin'

import TopBar from "@/components/calendar/appointmentModal/TopBar";
import AppointmentTypesTabs from "@/components/calendar/appointmentModal/AppointmentTypesTabs";
import FormDateTimeArea from "@/components/calendar/appointmentModal/fields/FormDateTimeArea";
import OfftimeForm from "@/components/calendar/appointmentModal/forms/OfftimeForm";
import PlaceholderForm from "@/components/calendar/appointmentModal/forms/PlaceholderForm";
import PracticeForm from "@/components/calendar/appointmentModal/forms/PracticeForm";
import TeacherSpecialForm from "@/components/calendar/appointmentModal/forms/TeacherSpecialForm";
import SchoolTheoryForm from "@/components/calendar/appointmentModal/forms/TheoryForm";

import OfftimeLoader from "@/components/skeleton-loaders/appointment-form/OfftimeLoader";
import PlaceholderLoader from "@/components/skeleton-loaders/appointment-form/PlaceholderLoader";

import TeacherModel from "@/store/models/TeacherModel";
import StudentModel from "@/store/models/StudentModel";
import VehicleModel from "@/store/models/VehicleModel";
import {showConfirm} from "@/helpers/dialogs";

export default {
  name: "TeacherAppointmentModal",
  mixins: [user, colorMixin],
  components: {
    TopBar,
    AppointmentTypesTabs,
    FormDateTimeArea,

    OFFTIME_FORM: OfftimeForm,
    PLACEHOLDER_FORM: PlaceholderForm,
    PRACTICE_FORM: PracticeForm,
    SPECIAL_FORM: TeacherSpecialForm,
    THEORY_FORM: SchoolTheoryForm,

    OFFTIME_LOADER: OfftimeLoader,
    PLACEHOLDER_LOADER: PlaceholderLoader,
  },
  data: () => ({
    skeleton: false,
    placeholderRequestedStudent: null,
  }),
  props: {
    currentAppointment: {
      type: Object,
      required: true,
    }
  },
  watch: {
    showModalGetter() {
      this.skeleton = false;
      this.placeholderRequestedStudent = null;
      this.$store.commit(`appointments/UPDATE_APPOINTMENT_FIELD`, { teacherId: this.currentUser.id });
      // if (val) {
      //   setTimeout(() => (this.skeleton = false), 1000);
      // }
    },
  },
  computed: {
    ...mapState("appointments", ["buttonLoaders"]),
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("appointments", ["showModalGetter", "initialDurationGetter",]),
    showDeleteBtn() {
      return this.currentAppointment.id &&
          !this.showRequestedPlaceholderBtn &&
          !this.showRequestedPracticeBtn;
    },
    showRequestedPlaceholderBtn() {
      return this.currentAppointment.type === 'PLACEHOLDER' && this.currentAppointment.isRequested;
    },
    showRequestedPracticeBtn() {
      return this.currentAppointment.type === 'PRACTICE' && this.currentAppointment.status === 'REQUESTED'
    },
    showCreateBtn() {
      return !this.currentAppointment.id;
    },
    showUpdateBtn() {
      return this.currentAppointment.id && !this.showRequestedPlaceholderBtn && !this.showRequestedPracticeBtn;
    },
    appointmentLoader() {
      return this.$store.state.appointments.appointmentLoader || false;
    },
    teachers() {
      return TeacherModel.query().where("active", true).get();
    },
    students() {
      return StudentModel.query().get();
    },
    vehicles() {
      return VehicleModel.all();
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    formComponent() {
      return this.currentAppointment.type + "_FORM";
    },
    skeletonComponent() {
      return this.currentAppointment.type + "_LOADER";
    },
  },
  methods: {
    ...mapActions("appointments", [
          "closeAppointmentModal",
          "createAppointment",
          "updateAppointment",
          "deleteAppointment",
          "confirmPlaceholder",
          "declinePlaceholder",
          "confirmPractice",
          "declinePractice",
    ]),
    pickRequestedStudent(val) {
      this.placeholderRequestedStudent = val;
    },
    confirmCurrentPlaceholder() {
      this.confirmPlaceholder(this.placeholderRequestedStudent)
    },
    async createApp() {
      const validate = await this.$refs.observer.validate();
      if (validate) this.createAppointment();
    },
    async updateApp() {
      const validate = await this.$refs.observer.validate();
      if (validate) this.updateAppointment();
    },
    deleteApp() {
      showConfirm(
          this.$i18n.t('alert.are_you_sure'),
          this.$i18n.t("messages.are_your_sure_delete"),
          () => {
            this.deleteAppointment();
          }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input__prepend-inner,
.v-input__append-inner {
  cursor: pointer;
}
.justify-center {
  display: flex;
  justify-content: space-between;
}
</style>
