import api from "@/utils/api";

export default {
  load(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/invoice-addresses", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  loadItem(id) {
    return new Promise((resolve, reject) => {
      api
        .get(`/invoice-addresses/${id}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  create(params) {
    return new Promise((resolve, reject) => {
      api
        .post("/invoice-addresses", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  update(id, params) {
    params._method = "PUT";

    return new Promise((resolve, reject) => {
      api
        .post(`/invoice-addresses/${id}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  destroy(id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/invoice-addresses/${id}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
