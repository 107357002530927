import api from "@/utils/api";

export default {
  load(params = null) {
    return new Promise((resolve, reject) => {
      api
        .get("prices", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  setActiveStatus(priceId, isActive) {
    return new Promise((resolve, reject) => {
      api
        .patch(`prices/${priceId}/active-status`,{ active: isActive })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
}
