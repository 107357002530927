import { render, staticRenderFns } from "./StudentTeachers.vue?vue&type=template&id=5f55dcd2&scoped=true"
import script from "./StudentTeachers.vue?vue&type=script&lang=js"
export * from "./StudentTeachers.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f55dcd2",
  null
  
)

export default component.exports