import { Model } from "@vuex-orm/core";
import TemplateModel from "./TemplateModel";
import ItemModel from '@/store/models/insights/ItemModel'
import EvaluationItemModel from '@/store/models/insights/EvaluationItemModel'

export default class CategoryModel extends Model {
  static entity = "InsightsCategories";

  static fields() {
    return {
      id: this.attr(null),
      templateId: this.attr(null),
      template: this.belongsTo(TemplateModel, "templateId"),
      parent: this.belongsTo(CategoryModel, "parentId"),
      children: this.hasMany(CategoryModel, "parentId"),
      items: this.hasMany(ItemModel, "categoryId"),
      parentId: this.attr(null),
      name: this.attr(null),
      description: this.attr(null),
      order: this.number(0),
      color: this.string(""),
      icon: this.string(""),
    };
  }

  get siblings() {
    return CategoryModel.query().where('parentId', this.parentId).with(['items', 'children']).get()
  }

  get evaluatedItemsAmount() {
    const currentUser = this.$store().state.auth.authUser;
    const query = EvaluationItemModel.query()

    // if student - calculate items without score
    // if teacher or school-administrator - calculate scored items
    if (currentUser.type === 'student') query.where('score', 0)

    const evaluatedItems = query.get()
    const evaluatedIdsPluck = evaluatedItems.map(item => item.itemId)

    let pluckedIds = this.items.map(item => item.id)
    if (this.children.length) {
      const nestedItems = ItemModel.query().where((item) => {
        return this.children.map(child => child.id).includes(item.categoryId)
      }).get()
      pluckedIds = nestedItems.map(item => item.id)
    }

    return pluckedIds.filter(id => evaluatedIdsPluck.includes(id)).length
  }
}
