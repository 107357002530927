<script>
import UserAvatar from "@/components/UserAvatar.vue";
import {hasFlag} from "country-flag-icons";
import clipboard from "@/utils/mixins/clipboard";
import studentsService from "@/services/studentsService";

export default {
  name: "MainInformation",
  components: {UserAvatar},
  mixins: [clipboard],
  props: {
    student: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeStatus: null,
      changingActiveStatus: false,
    }
  },
  mounted() {
    this.activeStatus = this.student.active
  },
  methods: {
    toggleActiveStatus(value) {
      this.changingActiveStatus = true
      console.log(value)
      studentsService
          .makeActive(this.student.id, {active: value})
          .then(() => {
            this.$emit('active-status-changed', this.activeStatus)
            this.$snackbar.show({
              message: this.$t(`label.student_${this.activeStatus ? 'activated' : 'deactivated'}`),
              timeout: 3000,
              color: 'success',
            })
          })
          .catch(() => {
            this.$snackbar.show({
              message: this.$t(`label.error_performing_the_action`),
              timeout: 3000,
              color: 'error',
            })
            this.activeStatus = !this.activeStatus;
          })
          .finally(() => {
            this.changingActiveStatus = false
          })
    }
  },
  computed: {
    genderChipColor() {
      if (! this.student.gender) return ''

      return this.student.gender === 'M' ? 'blue' : (this.student.gender === 'W' ? 'pink' : 'grey darken-5')
    },
    genderIcon() {
      if (! this.student.gender) return ''

      return `mdi-gender-${this.student.gender === 'M' ? 'male' : (this.student.gender === 'W' ? 'female' : 'non-binary')}`
    },
    nationalityFlagAvailable() {
      return this.student && hasFlag(this.student.nationality)
    },
    colorByEducationStatus() {
      if (!this.student) return "";

      switch (this.student.educationStatus) {
        case 'registered' :
          return 'warning';
        case 'learning' :
          return 'info';
        case 'finished' :
          return 'success';
        default :
          return '';
      }
    }
  }
}
</script>

<template>
  <v-card outlined class="user" :class="{ 'inactive' : ! student.active }">
    <v-list-item three-line>
      <v-badge avatar bordered overlap bottom :value="nationalityFlagAvailable">
        <template v-slot:badge>
          <v-avatar>
            <v-img :src="`https://purecatamphetamine.github.io/country-flag-icons/3x2/${student.nationality}.svg`"/>
          </v-avatar>
        </template>
        <user-avatar rounded size="80" :path="student.avatar" :alt="student.fullName"/>
      </v-badge>

      <v-list-item-content class="ml-4">
        <div class="text-overline" v-if="student.birthDate">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ student.age.y }} {{ $tc('label.years', student.age.y) }},
                {{ student.age.m }} {{ $tc('label.months', student.age.m) }}
              </span>
            </template>
            <span>{{ student.birthDate }}</span>
          </v-tooltip>
        </div>
        <div class="overline error--text" v-else>{{ $t('label.no_birthday_is_set') }}</div>
        <v-list-item-title class="text-h5 mb-1">
          {{ student.fullName }}
        </v-list-item-title>
        <v-list-item-subtitle>{{ student.fullAddress }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-chip-group>
          <v-tooltip bottom>
            <template v-slot:activator="{on, attrs}">
              <v-chip :color="student.isPushActive ? 'success' : 'error'" dark outlined label v-bind="attrs" v-on="on">
                <v-icon left>{{ student.isPushActive ? 'mdi-cellphone-message' : 'mdi-cellphone-message-off' }}</v-icon>
                {{ $t('notifications.sendMethods.push') }}
              </v-chip>
            </template>
            <span v-if="student.isPushActive">{{ $t('label.push_notifications_are_on') }}</span>
            <span v-else>{{ $t('label.push_notifications_are_off') }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="student.email">
            <template v-slot:activator="{on, attrs}">
              <v-chip :color="student.hasEmailNotifications ? 'success' : 'error'" dark outlined label v-bind="attrs" v-on="on">
                <v-icon left>{{ student.hasEmailNotifications ? 'mdi-email' : 'mdi-email-off' }}</v-icon>
                {{ $t('notifications.sendMethods.mail') }}
              </v-chip>
            </template>
            <span v-if="student.hasEmailNotifications">{{ $t('label.email_notifications_are_on') }}</span>
            <span v-else>{{ $t('label.email_notifications_are_off') }}</span>
          </v-tooltip>
          <v-chip v-if="student.examOrganization" :color="student.examOrganization.color" dark outlined label>
            {{ student.examOrganization.name }}
          </v-chip>
        </v-chip-group>
      </v-list-item-action>
    </v-list-item>

    <v-card-text>
      <v-chip v-if="student.gender" dark :color="genderChipColor" class="mr-2">
        <v-icon left>
          {{ genderIcon }}
        </v-icon>
        {{ $t(`gender.${student.gender}`) }}
      </v-chip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-chip class="mr-2" :color="colorByEducationStatus" v-bind="attrs" v-on="on">
            <v-icon left>
              {{
                student.educationStatus === 'registered'
                    ? 'mdi-progress-alert'
                    : (student.educationStatus === 'learning')
                        ? 'mdi-progress-clock' : 'mdi-progress-check'
              }}
            </v-icon>
            {{ $t(`education_status.${student.educationStatus}`) }}
          </v-chip>
        </template>
        <span v-if="student.educationStartedAt">
            {{ $t('label.education_started_on') }}: {{ student.educationStartedAt }}
          </span>
        <span v-else>
          {{ $t('label.education_started_unknown') }}
        </span>
      </v-tooltip>

      <v-tooltip top v-if="student.hasGlasses">
        <template v-slot:activator="{ on, attrs }">
          <v-chip color="warning" class="mr-2" v-bind="attrs" v-on="on">
            <v-icon left>mdi-glasses</v-icon>
            {{ $t('label.has_glasses') }}
          </v-chip>
        </template>
        {{ $t('label.student_requires_a_visual_aid') }}
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            :color="student.hasDocumentsComplete ? 'success' : 'error'" class="mr-2"
            :to="{name: 'students.student.notes', params: {id: student.id}}"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>
              {{ student.hasDocumentsComplete ? 'mdi-file-document-check' : 'mdi-file-document-remove' }}
            </v-icon>
            {{ student.hasDocumentsComplete ? $t('label.has_documents_complete') : $t('label.has_documents_incomplete') }}
          </v-chip>
        </template>
        {{ $t('label.click_to_change') }}
      </v-tooltip>

      <v-tooltip top v-if="student.bookAndPay">
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            :color="student.hasPaymentAbility ? 'success' : 'warning'"
            class="mr-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>mdi-account-cash</v-icon>
            bookandpay
          </v-chip>
        </template>
        <span v-if="student.hasPaymentAbility">
          {{ $t('label.student_is_bookandpay_and_able_to_make_payments') }}
        </span>
        <span v-else>
          {{ $t('label.student_is_bookandpay_but_unable_to_make_payments') }}
        </span>
      </v-tooltip>

      <v-tooltip top v-if="student.clickAndLearnEnabled">
        <template v-slot:activator="{ on, attrs }">
          <v-chip  color="success" class="mr-2" v-bind="attrs" v-on="on">
            <v-icon left>$clickAndLearnWhite</v-icon>
            {{ $t('clickandlearn.feature_name') }}
          </v-chip>
        </template>
        {{ $t('label.student_has_clickandlearn_enabled') }}
      </v-tooltip>
    </v-card-text>

    <v-card-actions>
      <v-tooltip top max-width="400">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" outlined rounded text small @click="copyToClipboard(student.id)" v-on="on" v-bind="attrs">
            <v-icon left>mdi-identifier</v-icon>
            {{ student.id }}
          </v-btn>
        </template>
        {{ $t('label.student_id_explanation') }}
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" outlined rounded text small v-on="on" v-bind="attrs">
            <v-icon left>mdi-calendar</v-icon>
            {{ student.createdAt | dateFormat }}
          </v-btn>
        </template>
        {{ $t('label.student_created_at_explanation') }}
      </v-tooltip>

      <v-tooltip top v-if="student.email" >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" outlined rounded text small @click="copyToClipboard(student.email)" v-on="on" v-bind="attrs">
            <v-icon left>mdi-email</v-icon>
            {{ student.email }}
          </v-btn>
        </template>
        {{ $t('label.student_email_explanation') }}
      </v-tooltip>

      <v-tooltip top v-if="student.phone"  >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" outlined rounded text small @click="copyToClipboard(student.phone)"  v-on="on" v-bind="attrs">
            <v-icon left>mdi-phone</v-icon>
            {{ student.phone }}
          </v-btn>
        </template>
        {{ $t('label.student_phone_explanation') }}
      </v-tooltip>

      <v-spacer/>

      <v-tooltip top open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-switch
            class="mt-1 v-input--reverse"
            height="20"
            flat
            inset
            v-model="activeStatus"
            :loading="changingActiveStatus"
            :disabled="changingActiveStatus"
            dense
            hide-details
            @change="toggleActiveStatus"
            @click.stop
            v-on="on"
            v-bind="attrs"
          />
        </template>
        {{ $t('label.toggle_student_state') }}
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.user {
  background: rgba(255, 255, 255, 1); /* white background */
  transition: background 0.5s ease-in-out; /* transition for the background change */
  position: relative;
}
/* Inactive state */
.user.inactive {
  background: rgba(255, 51, 0, 0.1);
}
</style>