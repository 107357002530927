<template>
  <v-sheet
    class="py-4 px-3"
    :class="{ 'd-flex': orientation === 'horizontal' }"
    color="white"
    elevation="1"
    width="100%"
    rounded
  >

    <template v-if="orientation === 'horizontal'">
      <div class="d-flex mx-auto">
        <UserAvatar class="elevation-2" size="90" :path="student.avatar" :alt="student.fullName" />
      </div>

      <div class="ml-3 d-flex flex-column">
        <span class="text-h5">{{ student.fullName }}</span>

        <a class="student-info subtitle-2 text-truncate" :href="`mailto:${student.email}`">{{ student.email }}</a>
        <a class="student-info subtitle-2 text-truncate" :href="`tel:${student.phone}`">{{ student.phone }}</a>
        <a class="student-info subtitle-2 text-truncate" :href="`tel:${student.landline}`">{{ student.landline }}</a>

        <span class="subtitle-2">
          {{ student.street + " " + student.houseNr }}
          {{ student.postcode + " " + student.city }}
        </span>

        <span class="subtitle-2" v-if="student.birthDate">
          {{ student.birthDate | dateFormat('dd.MM.yyyy') }} ({{ student.ageInYearsAndMonths }})
        </span>

        <span class="subtitle-2">
          {{ $t('label.education_started_on') }}:
          <span v-if="student.educationStartedAt">{{ student.educationStartedAt | dateFormat('dd.MM.yyyy') }}</span>
          <span v-else>-</span>
        </span>
      </div>

      <v-spacer />

      <v-btn icon large @click="openStudentSidebar">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <template v-else>
      <div class="d-flex flex-column text-center mx-3">
        <div class="d-flex mx-auto">
          <UserAvatar class="elevation-2" size="130" :path="student.avatar" :alt="student.fullName" />
        </div>

        <div class="d-flex flex-column">
          <span class="mt-3 text-h5">{{student.fullName}}</span>
          <span v-if="student.email" class="text-subtitle-2">
            <a style="text-decoration: none" :href="`mailto:${student.email}`">{{ student.email }}</a>
          </span>
          <span class="text-subtitle-1 font-weight-medium" />
        </div>
      </div>

      <v-divider class="my-4" />

      <v-list class="py-0" dense>
        <v-list-item v-if="student.phone" class="pa-0 mb-2">
          <v-list-item-content class="pa-0">
            <v-list-item-title>{{$t("form.phone_mobile")}}</v-list-item-title>
            <v-list-item-subtitle>
              <a style="text-decoration: none" :href="`tel:${student.phone}`">{{student.phone}}</a>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="student.landline" class="pa-0 mb-2">
          <v-list-item-content class="pa-0">
            <v-list-item-title>{{$t("form.phone_landline")}}</v-list-item-title>
            <v-list-item-subtitle>
              <a style="text-decoration: none" :href="`tel:${student.landline}`">{{ student.landline }}</a>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="pa-0 mb-2" two-line>
          <v-list-item-content class="pa-0">
            <v-list-item-title>{{ $t("form.address") }}</v-list-item-title>
            <v-list-item-subtitle>{{student.street + " " + student.houseNr}}</v-list-item-subtitle>
            <v-list-item-subtitle>{{student.postcode + " " + student.city}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="pa-0 mb-2">
          <v-list-item-content class="pa-0">
            <v-list-item-title>{{ $t("form.birthday") }}</v-list-item-title>
            <v-list-item-subtitle v-if="student.birthDate">
              {{ student.birthDate | dateFormat('dd.MM.yyyy') }} ({{ student.ageInYearsAndMonths }})
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else>{{ $t('label.notSpecified') }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="pa-0 mb-2">
          <v-list-item-content class="pa-0">
            <v-list-item-title>{{ $t("label.education_started_on") }}</v-list-item-title>
            <v-list-item-subtitle>
              <span v-if="student.educationStartedAt">
                {{ student.educationStartedAt | dateFormat('dd.MM.yyyy') }}
              </span>
              <span v-else>-</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

    <StudentSidebar ref="sidebar" :student-id="studentId" />
  </v-sheet>
</template>

<script>
import StudentModel from "@/store/models/StudentModel";
import UserAvatar from '@/components/UserAvatar'
import StudentSidebar from '@/components/student/StudentSidebar'

export default {
  name: "StudentInfoCard",
  components: { StudentSidebar, UserAvatar },
  props: {
    orientation: {
      type: String,
      default: "vertical",
    },
    studentId: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    student() {
      return StudentModel.find(this.studentId) || {};
    },
  },
  methods: {
    openStudentSidebar() {
      this.$refs.sidebar.open();
    }
  }
};
</script>

<style scoped lang="scss">
.student-info {
  text-decoration: none;
  max-width: 200px;
}
</style>
