import LicenseModel from "@/store/models/LicenseModel";
import i18n from "@/plugins/i18n";

export default {
  computed: {
    orderedLicenses() {
      return LicenseModel.query().where("forTheory", true).orderBy("order", "asc").get();
    },
    licensesOrderById() {
      return Object.fromEntries(this.orderedLicenses.map((license) => [license.id, license.order]));
    }
  },
  methods: {
    getSpecialTopicsGroups(specialTopics) {
      if (specialTopics.length === 0) return [];
      const licensesNamesById = Object.fromEntries(
        this.orderedLicenses.map((license) => [license.id, license.name])
      );

      const specialTopicsGroups = [];
      const specialTopicsByGroup = this.groupTopicsByLicensesIds(specialTopics);
      const orderedLicensesIds = this.orderedLicenses.map((license) => license.id);
      Object.keys(specialTopicsByGroup).forEach((licenses) => {
        const licensesIds = (JSON.parse(licenses) || []).filter((licenseId) => orderedLicensesIds.includes(licenseId));
        const licensesNames = licensesIds.map((licenseId) => licensesNamesById[licenseId]) || [];

        const groupIndex = this.getGroupIndex(specialTopicsGroups, licensesIds);

        if (!specialTopicsGroups[groupIndex]) specialTopicsGroups[groupIndex] = [];
        specialTopicsGroups[groupIndex] = {
          header: i18n.t("label.special") + " - " + licensesNames.join(", "),
          topics: [...specialTopicsByGroup[licenses]]
        };
      });

      return specialTopicsGroups;
    },
    groupTopicsByLicensesIds(specialTopics) {
      const orderDictionary = {};
      this.orderedLicenses.forEach(license => orderDictionary[license.id] = license.order);

      const specialTopicsByGroup = {};
      specialTopics.forEach(specialTopic => {
        const licensesIdsAsString = JSON.stringify(
          specialTopic.licensesIds.sort((a, b) => orderDictionary[a] - orderDictionary[b])
        );

        if (!specialTopicsByGroup[licensesIdsAsString]) specialTopicsByGroup[licensesIdsAsString] = [];
        specialTopicsByGroup[licensesIdsAsString].push(specialTopic);
      });
      return specialTopicsByGroup;
    },
    getGroupIndex(specialTopicsGroups = [], licensesIds = [], bottomIndex = -1) {
      // When there is no index found, get the last one + 1 or 0
      if (Math.abs(bottomIndex) > licensesIds.length) {
        const lastSpecialTopicsGroupsKeys = Object.keys(specialTopicsGroups);
        const lastSpecialTopicsGroupsIndex = lastSpecialTopicsGroupsKeys[lastSpecialTopicsGroupsKeys.length - 1];

        return lastSpecialTopicsGroupsIndex ? parseInt(lastSpecialTopicsGroupsIndex) + 1 : 0;
      }

      let groupIndex = this.licensesOrderById[licensesIds.at(bottomIndex)];
      if (specialTopicsGroups[groupIndex]) {
        groupIndex = this.getGroupIndex(specialTopicsGroups, licensesIds, bottomIndex -= 1);
      }

      return groupIndex;
    }
  }
};
