<template>
  <v-dialog v-model="show" scrollable width="290">
    <v-card>
      <v-toolbar class="mb-2" dark color="primary" dense>
        <v-toolbar-title>{{
          $t("label.examining_period_start_license", { license: license.name })
        }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-date-picker
        v-model="date"
        scrollable
        flat
        no-title
        full-width
        :loading="loading" />

      <v-card-actions>
        <v-spacer />
        <v-btn text color="primary" @click="close">{{
          $t("btn.cancel")
        }}</v-btn>
        <v-btn
          text
          color="primary"
          @click="save"
          :disabled="loading"
          :loading="loading"
          >{{ $t("btn.save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format, parseISO } from "date-fns";
import studentsService from "@/services/studentsService";

export default {
  name: "ExaminingPeriodStartDialog",
  props: {
    studentId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    show: false,
    loading: false,
    title: "",
    license: {},
    date: format(new Date(), "yyyy-MM-dd"),
  }),
  methods: {
    open(license) {
      this.show = true;
      this.license = license;

      if (license.examiningPeriodStartedAt)
        this.date = format(
          parseISO(license.examiningPeriodStartedAt),
          "yyyy-MM-dd"
        );
    },
    close() {
      this.show = false;
      this.license = {};
    },
    save() {
      this.loading = true;
      studentsService
        .setExaminingPeriodStartDate(this.studentId, {
          licenseId: this.license.id,
          date: this.date,
        })
        .then(() => {
          this.$emit("saved");
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped></style>
