import api from "@/utils/api";
import laravelEcho from "@/plugins/laravel-echo";
import * as mutationTypes from "./../mutation-types/auth";
import FcmService from "@/services/fcmService";
import i18n from "@/plugins/i18n";
import LocalizationManager from "@/helpers/LocalizationManager";
import studentsService from "@/services/studentsService";
import store from "@/store";

const fcmService = new FcmService();

const state = () => ({
  userLoaded: false,
  loadingAuthUser: false,
  authUser: null,
  sessionExpired: false,
  restrictedAccess: false,
  authToken: localStorage.getItem("accessToken"),
  parentSelectedStudentId: localStorage.getItem("parentSelectedStudentId"),
  parentSelectedStudent: null
});

const getters = {
  authToken: (state) => state.authToken,
  parentSelectedStudentId: (state)=> state.parentSelectedStudentId ? parseInt(state.parentSelectedStudentId) : null,
  parentSelectedStudent: (state) => state.parentSelectedStudent,
  getSessionExpired: (state) => state.sessionExpired,
  getRestrictedAccess: (state) => state.restrictedAccess,
  unsignedDocumentsCount: (state) => state.authUser.unsignedDocumentsCount,
  currentUser: (state) => {
    if (!state.authUser) return null;

    const authUser = {...state.authUser};

    if (authUser?.type === "elter" && state.parentSelectedStudent) {
      authUser.id = state.parentSelectedStudent.id;
      authUser.schoolId = state.parentSelectedStudent.schoolId;
      authUser.active = state.parentSelectedStudent.active;
      authUser.licensesIds = state.parentSelectedStudent.licensesIds || [];
      authUser.unSeenMessagesCount = state.parentSelectedStudent.unSeenMessagesCount || 0;
      authUser.unreadNotificationsCount = state.parentSelectedStudent.unreadNotificationsCount || 0;
      authUser.unsignedDocumentsCount = state.parentSelectedStudent.unsignedDocumentsCount || 0;

      // Complete current user with selected student data
      Object.keys(state.parentSelectedStudent).forEach(key => {
        if (!(key in authUser)) authUser[key] = state.parentSelectedStudent[key];
      });
    }
    authUser.studentCanDeletePractice = true;
    authUser.deletePracticeBeforeStartLimit = 2;

    return authUser;
  }
};

const actions = {
  getAuthUser: ({ commit }) => {
    if (!localStorage.getItem("accessToken")){
      commit('set_user_loaded', true);
      return false;
    }

    laravelEcho.connector.options.auth.headers.Authorization =
      "Bearer " + localStorage.getItem("accessToken");

    return new Promise((resolve, reject) => {
      commit(mutationTypes.USER_LOADING, true);
      api
        .get("/user")
        .then((response) => {
          if (! response) {
            localStorage.removeItem('accessToken')
            location.href = '/'
          }
          const data = response.data
          commit(mutationTypes.USER_SUCCESS, data);
          if ('linkedAccounts' in data) {
            localStorage.setItem('otherAccounts', JSON.stringify(data.linkedAccounts))
          }
          resolve();
        })
        .catch((error) => {
          if (!error.response) console.log(error);
          reject(error);
        })
        .finally(() => {
          commit(mutationTypes.USER_LOADING, false);
          commit("set_user_loaded", true);
        });
    });
  },
  setParentSelectedStudentId: ({ commit }, studentId) => {
    localStorage.setItem("parentSelectedStudentId", studentId);
    commit(mutationTypes.SET_PARENT_SELECTED_STUDENT_ID, studentId);
  },
  getParentSelectedStudent: ({ commit }) => {
    const parentSelectedStudentId = store.state.auth.parentSelectedStudentId;

    laravelEcho.connector.options.auth.headers["X-Student-Id"] = parentSelectedStudentId;

    return new Promise((resolve, reject) => {
      studentsService
        .loadItem(parentSelectedStudentId)
        .then(response => {
          commit(mutationTypes.SET_PARENT_SELECTED_STUDENT, response.data || null);
          resolve();
        })
        .catch(error => reject(error));
    });
  },
  clearParentSelectedStudent: ({ commit }) => {
    localStorage.removeItem("parentSelectedStudentId");
    commit(mutationTypes.SET_PARENT_SELECTED_STUDENT_ID, null);
    commit(mutationTypes.SET_PARENT_SELECTED_STUDENT, null);
  },
  login: ({ dispatch }, data) => {
    return new Promise((resolve, reject) => {
      api
        .post("/login", data)
        .then((response) => {
          dispatch("setAccessToken", response.data.access_token);
          laravelEcho.connect();
          api
            .get("locales")
            .then((resp) => {
              const localization = resp.data;
              const locales = Object.keys(localization);
              locales.forEach((locale) => i18n.setLocaleMessage(locale, localization[locale] || {}));
            })
            .finally(() => LocalizationManager.init());

          resolve(response.data);
        })
        .catch((error) => {
          if (!error.response) {
            console.log(error);

            return false;
          }

          reject(error);
        });
    });
  },
  logout: ({ dispatch }) => {
    return new Promise((resolve, reject) => {
      fcmService.removeDeviceToken().finally(() => {
        api
          .post("/logout")
          .then(async () => {
            await dispatch("removeSessionData");

            resolve();
          })
          .catch((error) => {
            if (!error.response) console.log(error);

            reject(error);
          });
      });
    });
  },
  removeSessionData: async ({ dispatch, commit }) => {
    laravelEcho.disconnect();
    localStorage.clear();

    await dispatch("reset", null, { root: true });
    await dispatch("entities/deleteAll", null, { root: true });

    delete api.defaults.headers.common.Authorization;
    delete api.defaults.headers.common["X-Student-Id"];
    delete laravelEcho.connector.options.auth.headers.Authorization;

    commit(mutationTypes.SET_TOKEN, null);
  },
  setAccessToken: ({ commit }, accessToken) => {
    return new Promise((resolve) => {
      localStorage.setItem("accessToken", accessToken);
      commit(mutationTypes.SET_TOKEN, accessToken);
      resolve();
    });
  },
  removeAccessToken: ({ commit }) => {
    return new Promise((resolve) => {
      localStorage.removeItem("accessToken");
      commit(mutationTypes.SET_TOKEN, null);
      resolve();
    });
  },
  setPrivacyPolicyStatus: ({ commit }, status) => {
    return new Promise((resolve) => {
      commit(mutationTypes.SET_PRIVACY_POLICY_STATUS, status);
      resolve();
    });
  }
};

const mutations = {
  [mutationTypes.SET_TOKEN]: (state, token) => {
    state.authToken = token;
  },
  [mutationTypes.USER_LOADING]: (state, status) => {
    state.loadingAuthUser = status;
  },
  [mutationTypes.USER_SUCCESS]: (state, data) => {
    state.authUser = data;
  },
  [mutationTypes.SESSION_EXPIRED]: (state, status) => {
    state.sessionExpired = status;
  },
  [mutationTypes.RESTRICTED_ACCESS]: (state, status) => {
    state.restrictedAccess = status;
  },
  [mutationTypes.USER_UPDATE]: (state, data) => {
    state.authUser = { ...state.authUser, ...data };

    if (state.authUser.type === "elter" && state.parentSelectedStudent) {
      Object.keys(data).forEach(key => {
        if (key in state.parentSelectedStudent) state.parentSelectedStudent[key] = data[key];
      });
    }
  },
  [mutationTypes.SET_UNSEEN_MESSAGES_COUNT]: (state, value) => {
    state.authUser = { ...state.authUser, unSeenMessagesCount: value };
  },
  [mutationTypes.SET_UNSIGNED_DOCUMENTS_COUNT]: (state, value) => {
    state.authUser = { ...state.authUser, unsignedDocumentsCount: value };
  },
  set_user_loaded: (state, value) => {
    state.userLoaded = value;
  },
  [mutationTypes.SET_PARENT_SELECTED_STUDENT_ID]: (state, data) => {
    state.parentSelectedStudentId = data;
  },
  [mutationTypes.SET_PARENT_SELECTED_STUDENT]: (state, data) => {
    state.parentSelectedStudent = data;
  },
  [mutationTypes.SET_PRIVACY_POLICY_STATUS]: (state, data) => {
    state.authUser = { ...state.authUser, privacyPolicyAccepted: data };
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
