<template>
  <div>
    <v-row>
      <v-col>
        <LessonTypesField
          @emitLessonTypeSelect="changeTheoryLessonType"
          :disabled="!!currentAppointment.id && !createdPlaceholderApp || isTeacher"
          :lessonTypeProp="currentAppointment.lessonTypeId"
          :lessonTypesList="lessonTypesList"
        />
      </v-col>
      <v-col v-if="isTheoryLesson" :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
        <v-select
          @change="(val) => updateFields({ roomSize: val })"
          :disabled="teacherIsNotSelected"
          v-model="roomSize"
          :items="roomSizeItems"
          item-text="value"
          item-value="value"
          clearable
          hide-details
          outlined
          dense
          :label="$t('form.room_size')"
        />
      </v-col>
      <v-col v-if="isTheoryExam" :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
        <LicenseField
          @emitLicenseSelect="changeTheoryLicense"
          :disabled="!!currentAppointment.id || isExamInPast"
          :validation="true"
          :multiple="false"
          :licenseProp="currentAppointment.licenseId"
          :licenseIdsList="licensesIds"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <NotesField
          @emitNotesChange="(val) => updateFields({ notes: val })"
          :disabled="false"
          :validation="false"
          :notesProp="currentAppointment.notes"
        />
      </v-col>
    </v-row>

    <template v-if="isTheoryLesson">
      <v-row>
        <v-col v-if="isSchoolAdministrator" :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
          <TeachersField
            @emitTeachersSelect="(val) => updateFields({ teacherId: val })"
            :disabled="false"
            :validation="true"
            :filteredTeachers="teachers"
            :teacherProp="currentAppointment.teacherId"
          />
        </v-col>
        <v-col>
          <OfficesField
            @emitOfficeChange="(val) => updateFields({ officeId: val })"
            :validation="false"
            :disabled="teacherIsNotSelected"
            :officeIdProp="currentAppointment.officeId"
            :officesList="schoolOffices"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="12">
          <TopicsField
            @emitTopiSelect="(val) => updateFields({ theoryTopicId: val })"
            @emitLicenseSelect=" (val) => updateFields({ allowedLicenses: val })"
            :disabled="teacherIsNotSelected"
            :topicProp="currentAppointment.theoryTopicId"
            :topicsList="theoryTopicsList"
          />
        </v-col>
        <v-col :cols="12">
          <LicenseField
              @emitLicenseSelect="(val) => updateFields({ allowedLicenses: val })"
              :label="$t('form.licences_for_special_theory')"
              :disabled="!currentAppointment.theoryTopicId || !isSpecialTopic"
              :validation="true"
              :multiple="true"
              :licenseProp="currentAppointment.allowedLicenses"
              :licenseIdsList="theoryLessonLicenses"
          />
        </v-col>
      </v-row>
    </template>

    <v-row v-if="isSchoolAdministrator">
      <v-col>
        <StudentsField
            @emitStudentChange="selectTheoryStudent"
            :disabled="studentSelectLoader || disabledStudentsForLessons || disabledStudentsForExams"
            :validation="isTheoryExam"
            :multiple="true"
            :clearable="true"
            :decorated="isTheoryExam"
            :studentsProp="currentAppointment.studentsIds"
            :studentsList="filteredStudents"
            :hidden="true"
            :loading="studentSelectLoader"
        />

        <v-alert v-if="isTheoryExam" class="mt-5" dense type="warning" border="left" colored-border elevation="1">
          <span v-if="!this.currentAppointment.id">{{ $t('alert.theory_exam_create_warning') }}</span>
          <span v-else>{{ $t('alert.theory_exam_update_warning') }}</span>
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <TheorySignedStudents
          v-if="signedStudentsIds.length"
          :disabled="studentSelectLoader || disabledStudentsForExams"
          :isNewAppointment="!currentAppointment.id || !!createdPlaceholderApp"
          :loading="studentSelectLoader"
          :roomSize="theoryRoomSize"
          :students="regStuds"
          :theoryType="lessonType.group"
          @emitSetPresent="setPresentStatus"
          @emitUnsubscribe="unsubscribeStudent"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n"
import user from '@/utils/mixins/user'
import { mapGetters } from "vuex"
import complexSort from '@/helpers/complexSort'

import LessonTypesField from "@/components/calendar/appointmentModal/fields/LessonTypesField"
import TeachersField from "@/components/calendar/appointmentModal/fields/TeachersField"
import StudentsField from "@/components/calendar/appointmentModal/fields/StudentsField"
import OfficesField from "@/components/calendar/appointmentModal/fields/OfficesField"
import TopicsField from "@/components/calendar/appointmentModal/fields/TopicsField"
import LicenseField from "@/components/calendar/appointmentModal/fields/LicenseField"
import NotesField from "@/components/calendar/appointmentModal/fields/NotesField"
import TheorySignedStudents from "@/components/calendar/appointmentModal/fields/TheorySignedStudents"

import LicenseModel from "@/store/models/LicenseModel"
import StudentModel from "@/store/models/StudentModel"
import OfficeModel from "@/store/models/OfficeModel"
import LessonTypeModel from "@/store/models/LessonTypeModel"
import TheoryTopicModel from "@/store/models/TheoryTopicModel"
import AppointmentModel from '@/store/models/AppointmentModel'
import PriceCategoryLessonTypeStatusModel from "@/store/models/PriceCategoryLessonTypeStatusModel"

import appointmentService from "@/services/appointmentService/";
import theoryTopics from "@/utils/mixins/theoryTopics";

export default {
  name: "TheoryForm",
  mixins: [user, theoryTopics],
  data: () => ({
    studentSelectLoader: false
  }),
  components: {
    LessonTypesField,
    TeachersField,
    StudentsField,
    OfficesField,
    TopicsField,
    LicenseField,
    NotesField,
    TheorySignedStudents,
  },
  props: {
    teachers: {
      type: Array,
      required: true,
    },
    createdPlaceholderApp: {
      type: Object,
    },
  },
  mounted() {
    // this.registeredStudents = this.currentAppointment.studentsIds
    if (this.isTeacher && !this.currentAppointment.id) {
      const lessonType = LessonTypeModel.query().where('group', 'lesson').first()
      const updatedStudents = this.$route.query.updated_students

      if (updatedStudents) this.currentAppointment.studentsIds = updatedStudents

      this.updateFields({ lessonTypeId: lessonType.id, duration: lessonType.defaultDuration * lessonType.multiplier })
    }
  },
  computed: {
    ...mapGetters("appointments", { currentAppointment: "currentAppointmentGetter", }),
    ...mapGetters("school", { schoolSettings: "school" }),
    disabledStudentsForLessons () {
      return this.isTheoryLesson && (this.teacherIsNotSelected ||
          !this.currentAppointment.allowedLicenses.length ||
          !this.currentAppointment.theoryTopicId)
    },
    disabledStudentsForExams () {
      return this.isTheoryExam && (!!this.currentAppointment.id || !this.currentAppointment.licenseId)
    },
    isExamInPast() {
      /*commented past exam logic*/
      // const { id, lessonTypeId, start } = this.currentAppointment
      // const lessonType = LessonTypeModel.find(lessonTypeId)
      // return !!id && isPast(parseISO(start)) && (lessonType && !lessonType.canBeMovedInPast)
      return false
    },
    teacherIsNotSelected() {
      return !this.currentAppointment.teacherId
    },
    theoryRoomSize() {
      return this.isTheoryLesson ? this.currentAppointment.roomSize : null
    },
    lessonType() {
      return this.currentAppointment.lessonTypeId && this.isSchoolAdministrator
        ? LessonTypeModel.find(this.currentAppointment.lessonTypeId)
        : LessonTypeModel.query().where('eventTypeId', 'THEORY').first()
    },
    isTheoryLesson() {
      return this.lessonType.group === 'lesson'
    },
    isTheoryExam() {
      return this.lessonType.group === 'exam'
    },
    roomSize: {
      set(value) {
        this.updateFields({ roomSize: value })
      },
      get() {
        return this.currentAppointment.roomSize
      },
    },
    lessonTypesList() {
      return LessonTypeModel.query().where("eventTypeId", "THEORY").get() || []
    },
    schoolOffices() {
      return OfficeModel.all() || []
    },
    schoolStudents() {
      let query = StudentModel.query().where("active", true).where('educationStatus', 'learning')
      if (this.isTheoryExam) {
        query = query.where((student) => student.licensesIds.includes(this.currentAppointment.licenseId))
      }
      return query.get()
    },

    regStuds () {
      if (this.currentAppointment.id) {
        return this.currentAppointment.studentsIds.filter((item) => 'id' in item)
      } else {
        return this.currentAppointment.studentsIds.map((item) => {
          return { id: item, isPresent: false, wasScannedWithQR: false }
        })
      }
    },

    filteredStudents () {
      const { theoryTopicId, allowedLicenses, roomSize } = this.currentAppointment
      const topic = TheoryTopicModel.find(theoryTopicId)
      const isSpecialTopic = topic?.type === 'special'

      const mappedStudentsIds = !this.currentAppointment.id
          ? this.currentAppointment.studentsIds
          : this.signedStudentsIds
      let students = this.schoolStudents.filter((student) => !mappedStudentsIds.includes(student.id)) || []

      if (this.isTheoryExam) {
        return students.map((student) => {
          let lessonTypeDoesNotHavePrice = false
          if (this.lessonType.isPayable && student.priceCategoryId) {
            const lessonTypeStatus = PriceCategoryLessonTypeStatusModel.query()
            .where("priceCategoryId", student.priceCategoryId)
            .where("licenseId", this.currentAppointment.licenseId)
            .where("lessonTypeId", this.lessonType.id)
            .first();

            lessonTypeDoesNotHavePrice = !lessonTypeStatus
          }

          const disabled = student.bookAndPay && (!student.priceCategoryId || lessonTypeDoesNotHavePrice)

          return {
            ...student,
            categoryName: student.categoryName,
            disabled,
            lessonTypeDoesNotHavePrice
          }
        })
      }

      if (this.isTheoryLesson && isSpecialTopic) {
        students = students.filter((student) => {
          return student.licensesIds.some((license) => allowedLicenses.includes(license))
        })
      }

      return !roomSize || (roomSize && roomSize > this.signedStudentsIds.length) ? students : []
    },
    signedStudentsIds() {
      return this.currentAppointment.studentsIds.map(student => student.id) || [];
    },
    roomSizeItems() {
      const arr = [];
      for (let i = 1; i <= 50; i++) arr.push({ value: i, disabled: this.currentAppointment.studentsIds.length > i });
      return arr;
    },
    licenses() {
      return LicenseModel.query().orderBy("order", "asc").get() || [];
    },
    licensesIds() {
      return (this.licenses.map((license) => license.id) || [])
        .filter((licenseId) => this.availableLicenseIds.includes(licenseId));
    },
    availableLicenseIds() {
      const forType = this.isTheoryExam ? "forTheoryExam" : "forTheory";
      return LicenseModel.query().where(forType, true).get().map(license => license.id);
    },
    theoryLessonLicenses() {
      if (this.currentAppointment.theoryTopicId && this.isSpecialTopic) {
        const topic = TheoryTopicModel.find(this.currentAppointment.theoryTopicId);
        return (topic.licensesIds || []).filter((licenseId) => this.availableLicenseIds.includes(licenseId));
      } else {
        return (this.licenses.map((license) => license.id) || [])
            .filter((licenseId) => this.availableLicenseIds.includes(licenseId))
      }
    },
    basicTopics() {
      const topics = TheoryTopicModel.query()
        .where("type", "basic")
        .get() || []

      return complexSort(topics, 'orderIndex')
    },
    specialTopics() {
      const topics = TheoryTopicModel.query()
        .where("type", "special")
        .get() || []
      return complexSort(topics, 'orderIndex')
    },
    isSpecialTopic() {
      const topic = TheoryTopicModel.find(this.currentAppointment.theoryTopicId) || {}
      return topic.type === 'special'
    },

    theoryTopicsList() {
      const topicsArray = [
        { header: i18n.t("label.normal") },
        { divider: true },
        ...this.basicTopics
      ];

      if (!this.teacherIsNotSelected) {
        const specialTopicsGroups = this.getSpecialTopicsGroups(this.specialTopics);

        specialTopicsGroups.forEach((specialTopicsGroup) => {
          topicsArray.push(
            { header: specialTopicsGroup.header },
            { divider: true },
            ...specialTopicsGroup.topics
          );
        });
      }

      return topicsArray
    },
  },
  methods: {
    changeTheoryLessonType(val) {
      const { lessonTypeId } = this.currentAppointment
      const lessonType = LessonTypeModel.find(val)
      const oldGroup = lessonTypeId ? LessonTypeModel.find(lessonTypeId).group : null
      const newGroup = LessonTypeModel.find(val).group

      this.updateFields({ lessonTypeId: val })
      if (oldGroup !== newGroup) {
        if (oldGroup) this.updateFields({ teacherId: null })
        this.updateFields({
          roomSize: null,
          studentsIds: [],
          licenseId: null ,
          allowedLicenses: [],
          officeId: null ,
          theoryTopicId: null,
          notes: '',
          duration: lessonType.defaultDuration * lessonType.multiplier
        })
      }
    },
    changeTheoryLicense(val) {
      this.updateFields({ licenseId: val, studentsIds: [] })
    },
    selectTheoryStudent (val) {
      if (!val.length) return
      this.studentSelectLoader = true

      if (this.currentAppointment.id && !this.createdPlaceholderApp) {
        const selectedStudent = val.filter(studentId => !this.signedStudentsIds.includes(studentId))
        this.currentAppointment.studentsIds.push({ id: selectedStudent[0], isPresent: false, wasScannedWithQR: false })

        this.$store.commit("main/toggle-error-bottom-sheet", { status: "pending" }, { root: true });
        appointmentService
          .subscribe_to_theory(this.currentAppointment.id, { studentsIds: selectedStudent })
          .then((response) => {
            AppointmentModel.update({ where: this.currentAppointment.id, data: response.data || {} });
          })
          .catch((err) => {
            this.$store.commit("main/toggle-error-bottom-sheet", { status: "show", ...err.response.data }, { root: true });
            this.currentAppointment.studentsIds.pop();
          })
          .finally(() => this.studentSelectLoader = false);
      } else {
        this.updateFields({ studentsIds: val })
        this.studentSelectLoader = false
      }
    },
    unsubscribeStudent(val) {
      this.studentSelectLoader = true

      if (this.currentAppointment.id && !this.createdPlaceholderApp) {
        this.$store.commit("main/toggle-error-bottom-sheet", { status: "pending" }, { root: true });
        appointmentService
          .unsubscribe_from_theory(this.currentAppointment.id, { studentsIds: [val] })
          .then((response) => {
            const filteredStudents = this.currentAppointment.studentsIds.filter(student => student.id !== val);
            this.updateFields({ studentsIds: filteredStudents });
            AppointmentModel.update({ where: this.currentAppointment.id, data: response.data || {} });
          })
          .catch((err) => {
            this.$store.commit("main/toggle-error-bottom-sheet", { status: "show", ...err.response.data }, { root: true });
          })
            .finally(() => this.studentSelectLoader = false)
      } else {
        const updatedSignedStudentsIds = this.currentAppointment.studentsIds.filter(studentId => studentId !== val)
        this.updateFields({ studentsIds: updatedSignedStudentsIds })
        this.studentSelectLoader = false
      }
    },
    setPresentStatus(val) {
      this.studentSelectLoader = true;
      this.$store.commit("main/toggle-error-bottom-sheet", { status: "pending" }, { root: true });
      appointmentService
        .confirm_presence(this.currentAppointment.id, { studentsIds: [val] })
        .then((response) => {
          AppointmentModel.update({ where: this.currentAppointment.id, data: response.data || {} });
          const objIndex = this.currentAppointment.studentsIds.findIndex((obj => obj.id === val));
          this.currentAppointment.studentsIds[objIndex].isPresent = true;
        })
        .catch((err) => {
          this.$store.commit("main/toggle-error-bottom-sheet", { status: "show", ...err.response.data }, { root: true });
        })
          .finally(() => {
            this.studentSelectLoader = false
          })
    },
    updateFields(value) {
      this.$store.commit(`appointments/UPDATE_APPOINTMENT_FIELD`, value);
    }
  },
}
</script>

<style lang="scss" scoped>
.skeleton-border {
  border: thin solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
}
.item {
  border-bottom: thin solid rgba(0, 0, 0, 0.38);
  &:last-child {
    border-bottom: none;
  }
}
</style>
