// import api from "@/utils/api";
// import laravelEcho from "@/plugins/laravel-echo";
import parentService from '@/services/parentService'
import ParentModel from '@/store/models/ParentModel'

const state = () => ({
  loadingParents: false,
});

const getters = {

};

const actions = {
  fetchParents: ({ commit }) => {
    commit('loading_parents', true)
    parentService.getParentsList()
      .then((resp) => {
        ParentModel.create({ data: resp.data })
      })
      .finally(() => {
        commit('loading_parents', false)
      })
  },
};

const mutations = {
  loading_parents: (state, data) => {
    state.loadingParents = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
