<template>
  <div>
    <v-row>
      <TeachersField
        v-if="!isTeacher"
        :cols="12"
        :model="model"
        :eventDuration="eventDuration"
        :filteredTeachers="filteredTeachers" />
    </v-row>
    <TimeFields
      :disabled="disabled"
      :model="model"
      :eventDuration="eventDuration" />
    <TextField :disabled="disabled" :model="model" />
    <CopyCurrentEvent
      :model="model"
      :disabled="false"
      :infinitePlaceholders="false" />
  </div>
</template>

<script>
import TeachersField from "@/components/calendar/eventModal/fields/TeachersField";
import TimeFields from "@/components/calendar/eventModal/fields/TimeFields";
import TextField from "@/components/calendar/eventModal/fields/TextField";
import CopyCurrentEvent from "@/components/calendar/eventModal/fields/CopyCurrentEvent";
import user from "@/utils/mixins/user";
import { mapGetters } from "vuex";

export default {
  name: "OfftimeForm",
  mixins: [user],
  components: { TeachersField, TimeFields, TextField, CopyCurrentEvent },
  props: {
    eventDuration: {
      type: Number,
      required: true,
    },
    filteredTeachers: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters("appointments", { model: "newEventModelGetter" }),
    disabled() {
      return !this.model.teacherId;
    },
  },
};
</script>
