const state = () => ({
  items: [
    {
      id: 1,
      title: "Elementary",
      icon: "mdi-ship-wheel",
      itemsGroup: [
        {
          id: 1,
          name: 'test 1',
          description: 'test 1 description',
          items: [
            { id: 1, name: 'some name', score: 2 }
          ]
        },
      ]
    },
    {
      id: 2,
      title: "Advanced",
      icon: "mdi-alert",
      itemsGroup: [
        {
          id: 2,
          name: 'test 2',
          items: [
            { id: 2, name: 'koko 1', score: 2 },
            { id: 3, name: 'koko 2', score: 3 }
          ]
        },
        {
          id: 3,
          name: 'test 3',
          items: [
            { id: 4,name: 'koko 4',  score: 4 },
            { id: 5, name: 'koko 5', score: 5 },
            { id: 7, name: 'koko 7', score: 2 },
            { id: 6, name: 'koko 6', score: 2 }
          ]
        },
      ]
    },
    {
      id: 3,
      title: "Basic Driving",
      icon: "mdi-car-door",
      itemsGroup: []
    },
    {
      id: 4,
      title: "Performance Lvl",
      icon: "mdi-car",
      itemsGroup: []
    }
  ]
});

const getters = {

};

const actions = {

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
};
