import api from "@/utils/api";

export default {
  load(params) {
    return new Promise((resolve, reject) => {
      api
        .get("/learning-materials", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  store(params) {
    return new Promise((resolve, reject) => {
      api
        .post("/learning-materials", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  update(learningMaterialId, params) {
    return new Promise((resolve, reject) => {
      api
        .put(`/learning-materials/${learningMaterialId}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  delete(learningMaterialId) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/learning-materials/${learningMaterialId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
