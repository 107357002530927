<script lang="ts">
import {defineComponent} from 'vue'
import {CapacitorUpdater} from "@capgo/capacitor-updater";
// import {Device} from "@capacitor/device";
import {Dialog} from "@capacitor/dialog";

export default defineComponent({
  name: "OTAUDialog",
  data() {
    return {
      show: false,
      downloadProgress: 0,
      showDownloadProgress: false,
      currentBundle: null,
      nextBundle: null,

      downloadCompleted: false,
      downloadFailed: false,
      updateAvailable: false,
      noUpdateNeeded: false,
      updateFailed: false,

      alreadyOpenedDuringDownload: false,

      deviceId: null,
      platform: null,
      nativeVersion: null,
    }
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.show = false
    },
    applyUpdate() {
      if (this.nextBundle && this.nextBundle.id) {
        CapacitorUpdater.set({ id: this.nextBundle.id })
      }
    },
    checkForUpdates() {
      CapacitorUpdater.getLatest().then(latest => {
        this.nextBundle = latest
      })
    },
    downloadNextBundle() {
      if (this.nextBundle && this.nextBundle.url) {
        CapacitorUpdater.download(this.nextBundle).then(response => {
          console.log(response)
        })
      }
    }
  },
  beforeDestroy() {
      CapacitorUpdater.removeAllListeners()
  },
  mounted() {
    CapacitorUpdater.addListener('updateAvailable', async (res) => {
      try {
        await Dialog.alert({
          title: 'Update Available',
          message: `Version ${res.bundle.version} is available. The app will update now`,
        })
        await CapacitorUpdater.set(res.bundle)
      }
      catch (error) {
        console.log(error)
      }
    })

    CapacitorUpdater.notifyAppReady();

    // Device.getInfo().then(info => {
    //   this.platform = `${info.manufacturer} ${info.model} (${info.operatingSystem} ${info.osVersion})`
    // });
    //
    // CapacitorUpdater.getDeviceId().then(r => {
    //   this.deviceId = r.deviceId
    // })
    //
    // CapacitorUpdater.getBuiltinVersion().then(r => {
    //   this.nativeVersion = r.version
    // })
    //
    // CapacitorUpdater.addListener('download', downloadEvent => {
    //   this.downloadProgress = downloadEvent.percent
    //   this.nextBundle = downloadEvent.bundle
    //   if (! this.alreadyOpenedDuringDownload) {
    //     this.alreadyOpenedDuringDownload = true
    //     this.showDownloadProgress = true
    //     this.open();
    //   }
    // })
    //
    // CapacitorUpdater.addListener('noNeedUpdate', event => {
    //   this.noUpdateNeeded = true
    //   this.currentBundle = event.bundle
    // })
    //
    // CapacitorUpdater.addListener('updateAvailable', event => {
    //   this.nextBundle = event.bundle
    //   this.updateAvailable = true
    //   this.open();
    // })
    //
    // CapacitorUpdater.addListener('downloadComplete', event => {
    //   this.nextBundle = event.bundle
    //   this.downloadCompleted = true
    //
    //   this.open();
    // })
    //
    // CapacitorUpdater.addListener('downloadFailed', ({version}) => {
    //   this.downloadFailed = true
    //   this.nextBundle = { version }
    //
    //   this.open();
    // })
    //
    // // CapacitorUpdater.addListener('majorAvailable', event => {
    // //   this.open();
    // // })
    //
    // CapacitorUpdater.addListener('updateFailed', event => {
    //   this.updateFailed = true
    //   this.nextBundle = event.bundle
    //   this.open();
    // })
    //
    // CapacitorUpdater.addListener('appReady', event => {
    //   this.nextBundle = event.bundle
    // })
  },
  computed: {
    downloadBarColor () {
      if (this.downloadFailed) return 'error'

      if (this.downloadCompleted) return 'secondary';

      return 'primary'
    },
    downloadBarText () {
      if (this.downloadFailed) return 'Failed!';
      if (this.downloadCompleted) return 'Completed!';

      return `${this.downloadProgress}%`
    }
  }
})
</script>

<template>
<v-dialog v-model="show" fullscreen scrollable>
  <v-card>
    <v-toolbar class="mb-4 flex-grow-0" elevation="0" dense>
      <v-toolbar-title>Update center</v-toolbar-title>
      <v-spacer/>
      <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text>
      <div v-if="showDownloadProgress">
        <span class="overline">
          Downloading update
          <span v-if="nextBundle">
            ({{ nextBundle.version }} <span v-if="nextBundleSize">{{ nextBundleSize}} MB</span>)
          </span>
        </span>
        <v-progress-linear dark height="20" :value="downloadProgress" :color="downloadBarColor">
          {{ downloadBarText }}
        </v-progress-linear>
        <v-alert type="error" colored-border elevation="1" border="left" class="mt-4 py-1" style="font-size: small" v-if="downloadFailed">
          Download failed! Please try closing the app completely and then reopening it.
          If the problem persists, contact your driving school.
          <div class="text-monospace mt-3">
            ID: {{ deviceId }} <br>
            {{ platform }}<br>
            App native version: {{ nativeVersion }} <br>
          </div>
        </v-alert>
        <v-alert type="success" colored-border elevation="1" border="left" class="mt-4 py-1" style="font-size: small" v-else-if="downloadCompleted">
          <v-row align="center" class="flex-column">
            <v-col class="grow">
              Download completed. The update will be applied once the app becomes inactive.
              To apply it now, press the button below.
            </v-col>
            <v-col class="shrink" v-if="updateAvailable">
              <v-btn color="primary" class="mb-2" @click="applyUpdate">Update now</v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <v-alert colored-border type="info" elevation="1" border="left" class="mt-4 py-1" style="font-size: small" v-else>
          To download the update package, please keep the app open and the screen unlocked. You can close this window;
          it will reopen automatically once the download is finished.
        </v-alert>
      </div>
      <div v-else-if="updateFailed">
        <v-alert type="error">
          <div class="text-h6">
            Oh crap!
          </div>
          <div>
            There was a problem applying the update. Please try again later.
          </div>
        </v-alert>
      </div>
      <div v-else-if="noUpdateNeeded">
        <v-alert type="success">
          <div class="text-h6">
            You're all set!
          </div>
          <div>
            The current version is the latest one, no new updates available.
          </div>
        </v-alert>
      </div>
      <div v-else>
        Nothing to see here. You may close this window.
      </div>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<style scoped>

</style>
