<script>
import InsightsItem from "@/components/insights/InsightsItem.vue";

export default {
  name: "InsightsItemsGroup",
  components: {InsightsItem},
  props: {
    items: {
      type: Array,
    },
    groupTitle: {
      type: String,
      default: null
    }
  },
  computed: {
    showGroupTitle() {
      return this.items.length > 1
    }
  }
}
</script>

<template>
<div :class="{'items-group': showGroupTitle}">
  <span v-if="showGroupTitle" class="font-weight-bold">{{ groupTitle }}</span>
  <insights-item v-for="item in items" :show-item-name="! showGroupTitle" :item="item" :key="item.id"/>
</div>
</template>

<style scoped lang="scss">
.items-group {
  margin-top: 10px;
  position: relative;
  padding-left: 10px;
  &::before {
    position: absolute;
    width: 10px;
    content: '';
    left: 0;
    bottom: 0;
    top: 0;
    border: solid 1px #ccc;
    border-right-style: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}
</style>