<template>
  <v-card
      class="appointment"
      :class="focused ? 'booked-event' : 'blank-event'"
      :style="`${eventStyle} ${eventPosition}`"
  >
    <div class="ellipsis">{{ startTime }} - {{ endTime }}</div>
    <div class="ellipsis">{{ appointment.teacherName }}</div>
    <div class="ellipsis">{{ appointment.studentName }}</div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { parseISO, startOfDay, differenceInMinutes, format, addMinutes } from 'date-fns'

export default  {
  name: "ShadowAppointment",
  props: {
    appointment: {
      type: Object,
      required: true
    },
    focused: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('vehiclesCalendar', ['oneMinuteSize']),
    parsedDate(){
      return parseISO(this.appointment.start)
    },
    startTime() {
      return format(this.parsedDate, 'HH:mm')
    },
    endTime() {
      return format(addMinutes(this.parsedDate, this.appointment.duration), 'HH:mm')
    },
    positionLeft() {
      const parsedDate = parseISO(this.appointment.start)
      return differenceInMinutes(parsedDate, startOfDay(parsedDate))
    },
    eventPosition() {
      return `width: ${this.appointment.duration * this.oneMinuteSize }px; left: ${this.positionLeft * this.oneMinuteSize}px;`
    },
    eventStyle() {
      return `backgroundColor: ${this.appointment.bodyColor}; color: ${this.appointment.fontColor};`
    },
  }
}
</script>

<style lang="scss" scoped>
.appointment{
  height: 50px;
  position: absolute;
  padding: 2px 5px;
  box-sizing: border-box;
  font-size: 10px;
  line-height: normal;
}

.booked-event{
  opacity: 1;
  transition: opacity 0.1s linear;
}
.blank-event {
  background-color: transparent !important;
  color: #858585 !important;
  pointer-events: none !important;
  border: 1px dashed #ccc;
}
.ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
