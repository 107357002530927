<template>
  <div>
    <v-expansion-panels :disabled="!status">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="d-flex align-center justify-space-between">
            <div class="d-flex align-center justify-start">
              <h4 class="mr-3">{{ $t("label.current_covid_status_label") }}</h4>
              <h1 class="mr-3" :style="`color: ${color}`">{{ status.name }}</h1>
            </div>
            <v-btn
              v-if="school"
              class="mr-3"
              icon
              color="primary"
              :to="{ name: 'school-settings.covid-2019' }">
              <v-icon color="primary">mdi-cogs</v-icon>
            </v-btn>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <CovidStatusLabel
            class="recovered mb-1"
            name="Genesen"
            :status="parseInt(status.recovered)"
            :card="true" />
          <CovidStatusLabel
            class="vaccinated mb-1"
            name="Geimpft"
            :status="parseInt(status.vaccinated)"
            :card="true" />
          <CovidStatusLabel
            class="unvaccinated mb-1"
            name="Ungeimpft"
            :status="parseInt(status.unvaccinated)"
            :card="true" />
          <div v-if="status.message" class="pb-1">
            <v-divider class="my-3"></v-divider>
            <p class="font-weight-bold">
              {{ $t("label.extra_info_for_school_label") }}
            </p>
            {{ status.message }}
          </div>
          <div v-if="this.isStudent" class="pb-1">
            <v-divider class="my-3"></v-divider>
            <p class="font-weight-bold">
              {{ $t("label.my_vaccination_status_label") }}
            </p>

            <div class="flex-gap d-flex align-center">
              <v-btn icon @click="setStatus">
                <v-icon color="primary">{{ icon }}</v-icon>
              </v-btn>
              <span>{{ $t("label.my_vaccination_status_text") }}</span>
            </div>

            <div class="flex-gap d-flex align-center mt-3">
              <v-btn
                small
                text
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                :to="{ name: 'student.documents' }">
                {{ $t("btn.upload_vaccination_certificate") }}
                <v-icon class="ml-2" v-if="document.length">mdi-check</v-icon>
              </v-btn>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import CovidStatusLabel from "@/components/covid2019/CovidStatusLabel";
import responsive from "@/utils/mixins/responsive";
import user from "@/utils/mixins/user";
import studentsService from "@/services/studentsService";
import StudentModel from "@/store/models/StudentModel";
import {showConfirm} from "@/helpers/dialogs";

export default {
  name: "CovidStatusCard",
  components: { CovidStatusLabel },
  mixins: [responsive, user],
  data: () => ({
    document: {},
  }),
  props: {
    status: {
      type: Object,
      required: true,
    },
    school: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    this.document = {};
    if (this.isStudent) {
      studentsService.loadItem(this.currentUser.id).then((response) =>
        StudentModel.create({
          where: this.currentUser.id,
          data: response.data,
        })
      );
    }
    studentsService.documents(this.currentUser.id).then((documentResponse) => {
      this.document =
        documentResponse.data.filter(
          (doc) => doc.type === "VACCINATION_CERTIFICATE"
        ) || {};
    });
  },
  computed: {
    icon() {
      const student = StudentModel.find(this.currentUser.id) || {};
      switch (student.vaccinationStatus) {
        case "REQUESTED":
          return "mdi-help-box";
        case "APPROVED":
          return "mdi-checkbox-marked";
        default:
          return "mdi-checkbox-blank-outline";
      }
    },
    color() {
      const arr = [
        this.status.recovered,
        this.status.vaccinated,
        this.status.unvaccinated,
      ];
      let color = "green";
      const yellow = arr.some((item) => !item);
      const red = arr.every((item) => !item);
      if (yellow) color = "#ffc107 ";
      if (red) color = "red";
      return color;
    },
  },
  methods: {
    setStatus() {
      if (this.isStudent) {
        const student = StudentModel.find(this.currentUser.id) || {};
        if (student.vaccinationStatus) return;

        showConfirm(
            this.$i18n.t("alert.are_you_sure"),
            this.$t("label.student_requests_vaccination_approve"),
            () => {
              studentsService.setVaccinationStatus(student.id).then(() =>
                  StudentModel.update({
                    where: student.id,
                    data: { vaccinationStatus: "REQUESTED" },
                  })
              );
            }
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-gap {
  column-gap: 10px;
}
.grid {
  display: grid;
  grid-template-columns: 0.3fr 1fr 1fr 1fr;
  align-content: center;
  align-items: center;
}
.mobile-card {
  display: grid;
  align-items: center;
  grid-template-areas:
    "label recovered"
    "label vaccinated"
    "label unvaccinated";
  .lbl {
    grid-area: label;
  }
  .recovered {
    grid-area: recovered;
  }
  .vaccinated {
    grid-area: vaccinated;
  }
  .unvaccinated {
    grid-area: unvaccinated;
  }
}
</style>
