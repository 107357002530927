<template>
  <g>
    <text
      v-for="(d, i) in days"
      :key="i"
      :x="padX / 2"
      :y="d.y"
      :style="styles.text2"
      >{{ d.v }}</text
    >
  </g>
</template>

<script>
export default {
  name: "DayTitles",
  props: {
    styles: {
      type: Object,
      default: () => {},
    },
    size: {
      type: [Number, String],
    },
    space: {
      type: [Number, String],
    },
    padX: {
      type: [Number, String],
    },
    padY: {
      type: [Number, String],
    },
  },
  data: () => ({
    days: [],
  }),
  created() {
    const s = this.size + this.space * 2;
    const half = s / 2;

    this.days = [
      { v: "Mo", y: this.padY + half },
      { v: "Mi", y: this.padY + s * 2 + half },
      { v: "Fr", y: this.padY + s * 4 + half },
      { v: "So", y: this.padY + s * 6 + half },
    ];
  },
};
</script>

<style scoped></style>
