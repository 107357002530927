<template>
  <v-card v-if="isSchoolAdministrator" class="mt-2">
    <v-card-subtitle class="d-flex justify-space-between text-subtitle-1">
      {{ $t("label.balance") }}

      <BalanceLabel
        v-if="student.bookAndPay"
        :student="student"
        :disabled="student.isDeleted"
      />
    </v-card-subtitle>

    <div class="pt-0 pb-5 px-4">
      <div class="d-flex">
        <v-btn
          class="flex-grow-1 mr-1"
          small
          color="primary"
          outlined
          @click="openBalanceForm('manual_funded')"
          :disabled="student.isDeleted">
          {{ $t("btn.fill") }} <v-icon right>mdi-cash-plus</v-icon>
        </v-btn>
        <v-btn
          class="flex-grow-1 ml-1"
          small
          color="primary"
          outlined
          @click="openBalanceForm('manual_withdrawn')"
          :disabled="student.isDeleted">
          {{ $t("btn.withdraw") }} <v-icon right>mdi-cash-minus</v-icon>
        </v-btn>
      </div>

      <div class="d-flex justify-center text--lighten-2 my-1">oder</div>

      <div class="d-flex">
        <v-btn
          class="flex-grow-1 my-1"
          small
          color="primary"
          outlined
          :to="{ name: 'terminal', query: { studentId: student.id } }"
          :disabled="student.isDeleted"
        >
          <v-icon left>mdi-contactless-payment</v-icon>
          {{ $t("label.terminal_payment") }}
        </v-btn>
      </div>

      <BalanceFormDialog ref="balanceFormDialog" :student="student" />
    </div>
  </v-card>
</template>

<script>
import user from "@/utils/mixins/user";
import BalanceLabel from "@/components/BalanceLabel";
import BalanceFormDialog from "@/components/student/BalanceFormDialog";

export default {
  props: {
    student: {
      type: Object,
      require: true,
    },
  },
  name: "BalanceWidget",
  mixins: [user],
  components: { BalanceLabel, BalanceFormDialog },
  methods: {
    openBalanceForm(type) {
      this.$refs.balanceFormDialog.open(type);
    },
  },
};
</script>

<style scoped></style>
