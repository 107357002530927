<template>
  <v-alert dense outlined color="#eee" elevation="1" class="pa-0">
    <div class="d-flex align-center flex-column flex-lg-row pa-2" style="width: 100%">
      <div :style="{ width: $vuetify.breakpoint.lgAndUp ? '50%' : '100%' }" class="d-flex flex-row">
        <div class="d-flex flex-column text-center justify-center text--secondary" style="width: 35px;">
          <span class="text-h5"><v-skeleton-loader type="text"/></span>
          <span class="text-caption"><v-skeleton-loader type="text"/></span>
        </div>

        <v-divider vertical class="mx-2" />

        <div class="flex-grow-1 d-flex text--secondary">
          <v-skeleton-loader type="avatar" class="mr-2 flex-shrink-1"/>
          <div class="d-flex flex-column flex-grow-1">
            <div class="d-flex">
              <v-skeleton-loader type="text" :width="$vuetify.breakpoint.smAndUp ? 200 : 100"/>
            </div>
            <v-skeleton-loader type="image" :width="$vuetify.breakpoint.smAndUp ? 100 : 75" height="25"/>
          </div>
          <v-skeleton-loader type="image" class="mr-2" :width="$vuetify.breakpoint.smAndUp ? 80 : 60"  height="44"/>
        </div>
      </div>

      <template v-if="$vuetify.breakpoint.lgAndUp">
        <v-divider
            class="mx-2"
            vertical
        />

        <div style="width: 50%;">
          <div class="d-flex flex-column ma-0">
            <div class="d-flex flex-row ma-0">
              <v-skeleton-loader type="image" class="flex-grow-1 mb-1 mr-1" width="100" height="25"/>
              <v-skeleton-loader type="image" class="flex-grow-1 mb-1 mx-1" width="100" height="25"/>
              <v-skeleton-loader type="image" class="flex-grow-1 mb-1 mx-1" width="100" height="25"/>
              <v-skeleton-loader type="image" class="flex-grow-1 mb-1 ml-1" width="100" height="25"/>
            </div>
            <v-skeleton-loader type="image" width="100%" height="30"/>
          </div>
        </div>
      </template>
    </div>
  </v-alert>
</template>

<script>
export default {
  name: "NextStudentSkeletonLoader"
}
</script>

<style scoped>

</style>
