<template>
  <div>
    <v-dialog v-model="show" width="700" persistent @keydown.esc="close">
      <v-card>
        <v-toolbar class="mb-2" dark color="primary" dense>
          <v-toolbar-title>
            {{ $t("label.balance") }}
            {{ $t(`label.${selectedType.text}`) }}
          </v-toolbar-title>
          <v-spacer/>
          <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>

        <v-container>
          <ValidationObserver ref="form">
            <template v-if="form.type === 'manual_withdrawn'">
              <v-row dense>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" name="licenseId">
                    <v-select
                      solo
                      :label="$t('form.licence')"
                      v-model="form.licenseId"
                      :items="licenses"
                      item-value="id"
                      item-text="name"
                      :error-messages="errors"
                      :hide-details="errors.length === 0"
                      @change="onLicenseChange"
                      :disabled="loading"
                      :loading="loading"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text>
                            <v-chip
                              small
                              label
                              dark
                              class="mr-2"
                              :class="{'chip-with-border': wasBasicAmountBilled(item.id)}"
                              :outlined="!wasBasicAmountBilled(item.id)"
                              :disabled="wasProductBilled(item.id,'wasBasicAmountPartialBilled')"
                              :color="wasBasicAmountBilled(item.id) ? 'green' : 'grey'"
                            >G</v-chip>
                            <v-chip
                              small
                              label
                              dark
                              class="mr-2"
                              :class="{'chip-with-border': wasBasicAmountBilled(item.id)}"
                              :outlined="!wasBasicAmountBilled(item.id)"
                              :disabled="wasProductBilled(item.id,'wasBasicAmountFullBilled')"
                              :color="wasBasicAmountBilled(item.id) ? 'green' : 'grey'"
                            >T</v-chip>
                            <v-chip
                              small
                              label
                              dark
                              :class="{'chip-with-border': wasProductBilled(item.id)}"
                              :outlined="!wasProductBilled(item.id,'wasLearningMaterialBilled')"
                              :color="wasProductBilled(item.id,'wasLearningMaterialBilled')? 'green' : 'grey'"
                            >L</v-chip>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </template>
                    </v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" name="oneTimeProductId">
                    <v-select
                      solo
                      clearable
                      :label="$t('label.products')"
                      :value="selectedProductOptionKey"
                      :items="productsSelectOptions"
                      item-value="optionKey"
                      item-text="name"
                      :error-messages="errors"
                      :hide-details="errors.length === 0"
                      :disabled="loadingOneTimeProducts || !form.licenseId"
                      :loading="loadingOneTimeProducts"
                      @click:clear="clearForm"
                      @change="fillFormWithProductData"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <div v-if="item.billHistory && item.billHistory.wasBilled" class="text-caption">
                            <span v-if="item.billHistory.date" class="mr-1">{{ item.billHistory.date | dateFormat('dd.MM.yyyy') }},</span>
                            <span v-if="item.billHistory.amount">{{ item.billHistory.amount }} &euro;</span>
                          </div>
                          <template v-if="!item.disabled">
                            <span v-if="item.price" class="mr-1 text-caption">{{ item.price }} &euro;</span>
                            <div class="mr-1" v-else>
                              <v-icon color="orange" x-small left>mdi-alert</v-icon> <span class="text-caption">{{ $t('label.missing_prices') }}</span>
                            </div>
                          </template>
                        </v-list-item-action>
                      </template>
                    </v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </template>

            <v-row dense>
              <v-col cols="12">
                <ValidationProvider v-slot="{ errors }" class="flex-grow-1 flex-shrink-1" name="amount">
                  <v-text-field
                    :key="show"
                    :label="$t('form.amount')"
                    v-model="form.amount"
                    placeholder="0,00"
                    solo
                    suffix="€"
                    full-width
                    v-currency="{
                      currency: null,
                      allowNegative: false,
                      valueRange: { min: 0.0 },
                    }"
                    :error-messages="errors"
                    :hide-details="errors.length === 0"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <ValidationProvider v-slot="{ errors }" name="note">
                  <v-textarea
                    solo
                    :label="$t('form.note')"
                    v-model="form.note"
                    :error-messages="errors"
                    :hide-details="errors.length === 0"
                    rows="5"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </ValidationObserver>
        </v-container>

        <v-divider/>

        <v-card-actions>
          <v-btn text @click="close">{{$t('btn.close')}}</v-btn>
          <v-spacer/>
          <v-btn
            color="primary"
            @click="balanceAction"
            :loading="loading || loadingForm"
            :disabled="loading || loadingForm"
          >
            <v-icon left>mdi-cash-{{form.type === 'manual_withdrawn' ? 'minus' : 'plus'}}</v-icon>
            {{ $t(`label.${selectedType.text}`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ProductPriceCalculator ref="ProductPriceCalculator" @confirm="confirmCalculatedPrice" :student="student"/>
  </div>
</template>

<script>
import currency from "@/utils/mixins/currency";
import studentsService from "@/services/studentsService";
import StudentModel from "@/store/models/StudentModel";
import ProductPriceCalculator from "@/components/student/ProductPriceCalculator";
import { mapActions } from "vuex";
import StudentHistoryModel from "@/store/models/StudentHistoryModel";
import LicenseModel from "@/store/models/LicenseModel";
import productsService from '@/services/productsService'
import OneTimeProductModel from '@/store/models/OneTimeProductModel'
import pricesService from '@/services/pricesService'
import ProductPriceModel from '@/store/models/ProductPriceModel'
import keyBy from '@/helpers/keyBy'
import learningMaterialsService from '@/services/learningMaterialsService'
import LearningMaterialModel from '@/store/models/LearningMaterialModel'

export default {
  name: "BalanceFormDialog",
  components: { ProductPriceCalculator },
  mixins: [currency],
  data() {
    return {
      show: false,
      type: "manual_funded",
      studentLicenses: [],
      actions: [
        {
          value: "manual_funded",
          text: "fill",
          color: "green",
          form_icon: "mdi-basket-plus-outline",
        },
        {
          value: "manual_withdrawn",
          text: "withdraw",
          color: "red",
          form_icon: "mdi-basket-minus-outline",
        },
      ],
      selectedProductOptionKey: null,
      form: {
        type: "",
        amount: "",
        note: "",
        licenseId: null,
        productId: null,
        productType: null,
      },
      loadingForm: false,
      loadingOneTimeProducts: true,
      loadingPrices: true,
      loadingStudentLicenses: true,
      loadingLearningMaterial: true
    };
  },
  props: {
    student: {
      type: Object,
      require: true,
    },
  },
  watch: {
    'form.productId': function () {
      this.prefillFormWithOneTimeProductValues()
    },
  },
  computed: {
    loading() {
      return this.loadingStudent ||
          this.loadingOneTimeProducts ||
          this.loadingLearningMaterial ||
          this.loadingPrices
    },
    licenses() {
      return LicenseModel.findIn(this.student.licensesIds) || {};
    },
    studentLicensesById() {
      return keyBy(this.studentLicenses,'id')
    },
    selectedStudentLicense() {
      return this.studentLicenses.find(item => this.form.licenseId === item.id) || {}
    },
    selectedType() {
      return this.actions.find((item) => item.value === this.form.type) || {};
    },
    oneTimeProducts() {
      return OneTimeProductModel.query().with('prices').get()
    },
    learningMaterials() {
      return LearningMaterialModel.query()
          .where(
        (product) =>
            this.student.licensesIds.includes(product.licenseId) &&
            product.priceCategoryId === this.student.priceCategoryId &&
            product.licenseId === this.form.licenseId
          )
          .get() || []
    },
    productsSelectOptions() {
      return [...this.oneTimeProductsSelectGroup, ...this.learningMaterialsSelectGroup]
    },
    oneTimeProductsSelectGroup() {
      const type = 'one-time-product'

      return [
        { header: this.$t('label.one_time_product') },
        { divider: true },
        ...this.oneTimeProducts.map(item => {
          const price = item.getPrice(this.student.priceCategoryId, this.form.licenseId)
          const productBillHistory = this.productBillHistoryBuyKey[item.key]

          return {
            optionKey: item.id + '-' + type,
            id: item.id,
            type: type,
            name: this.$t(`one_time_product.${item.key}`),
            price: price?.price,
            disabled: productBillHistory.disabled,
            billHistory: productBillHistory
          }
        })
      ]
    },
    learningMaterialsSelectGroup() {
      if (this.learningMaterials.length === 0) return []

      const type = 'learning-material'

      return [
        { header: this.$t('label.learning_materials') },
        { divider: true },
        ...this.learningMaterials.map(item => ({
          optionKey: item.id + '-' + type,
          id: item.id,
          type: type,
          name: item.name,
          price: item.price
        }))
      ]
    },
    selectedProduct() {
      return this.productsSelectOptions.find(item => item?.optionKey === this.selectedProductOptionKey)
    },
    wasSelectedStudentLicenseBasicAmountBilled() {
      return this.selectedStudentLicense?.wasBasicAmountFullBilled ||
          this.selectedStudentLicense?.wasBasicAmountPartialBilled
    },
    productBillHistoryBuyKey() {
      return {
        'basic_amount_full': {
          disabled: this.wasSelectedStudentLicenseBasicAmountBilled,
          wasBilled: this.selectedStudentLicense.wasBasicAmountFullBilled,
          amount: this.selectedStudentLicense.basicAmountPayment,
          date: this.selectedStudentLicense.basicAmountBilledAt
        },
        'basic_amount_partial': {
          disabled: this.wasSelectedStudentLicenseBasicAmountBilled,
          wasBilled: this.selectedStudentLicense.wasBasicAmountPartialBilled,
          amount: this.selectedStudentLicense.basicAmountPayment,
          date: this.selectedStudentLicense.basicAmountBilledAt
        },
        'learning_material': {
          disabled: this.selectedStudentLicense.wasLearningMaterialBilled,
          wasBilled: this.selectedStudentLicense.wasLearningMaterialBilled,
          amount: this.selectedStudentLicense.learningMaterialPayment,
          date: this.selectedStudentLicense.learningMaterialBilledAt
        }
      }
    },
  },
  methods: {
    ...mapActions("auth", ["getAuthUser"]),
    confirmCalculatedPrice() {
      this.show = false;
    },
    open(type) {
      this.show = true;
      this.form.type = type;

      this.loadingOneTimeProducts = true
      productsService
        .oneTimeProducts()
        .then(response => {
          OneTimeProductModel.create({data: response.data})
        })
        .finally(() => {
          this.loadingOneTimeProducts = false
        })

      this.loadingPrices = true
      pricesService
        .load({
          priceCategoryId: this.student.priceCategoryId,
          licensesIds: this.student.licensesIds,
          productType: 'one-time-product'
        })
        .then(response => {
          ProductPriceModel.create({data: response.data})
        })
        .finally(() => {
          this.loadingPrices = false
        })

      this.loadingStudentLicenses = true
      studentsService
        .licenses(this.student.id)
        .then((response) => {
          this.studentLicenses = response.data || []
        })
        .finally(() => {
          this.loadingStudentLicenses = false
        })

      this.loadingLearningMaterial = true
      learningMaterialsService
          .load()
          .then((response) => {
            LearningMaterialModel.create({ data: response.data });
          })
          .finally(() => {
            this.loadingLearningMaterial = false
          });
    },
    async balanceAction() {
      const amount = parseFloat(this.form.amount) ? this.sanitizePrice(this.form.amount) : null;

      this.loadingForm = true;
      studentsService
        .manualUpdateBalance(this.student.id, { ...this.form, amount })
        .then((response) => {
          const responseData = response.data || {};
          const { balance, availableBalance } = responseData;

          StudentModel.update({
            where: this.student.id,
            data: { balance, availableBalance },
          });
          StudentHistoryModel.insert({ data: responseData.history });

          this.close();
        })
        .catch((error) => {
          const responseData = error.response.data || {};
          const errors = responseData.errors || [];

          if (Object.keys(errors).length === 0 && responseData.message) {
            this.$snackbar.show({
              message: responseData.message,
              color: "error",
            });
          }

          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    close() {
      this.show = false;

      setTimeout(() => {
        this.$refs.form.reset();
        this.selectedProductOptionKey = null
        this.form = { type: "", amount: "", note: "", productId: null, productType: null };
      }, 200)
    },
    onLicenseChange() {
      this.selectedProductOptionKey = null
      this.form.productId = null
      this.form.productType = null
    },
    prefillFormWithOneTimeProductValues() {
      this.form.note = this.selectedProduct?.name || ""
      this.form.amount = parseFloat(this.selectedProduct?.price) ? this.sanitizePrice(this.selectedProduct.price,',') : ""
    },
    wasProductBilled(licenseId, productType) {
      return this.studentLicensesById?.[licenseId]?.[productType]
    },
    wasBasicAmountBilled (licenseId) {
      return this.wasProductBilled(licenseId,'wasBasicAmountFullBilled') ||
          this.wasProductBilled(licenseId,'wasBasicAmountPartialBilled')
    },
    fillFormWithProductData(value) {
      this.selectedProductOptionKey = value

      this.$nextTick(() => {
        this.form.productId = this.selectedProduct?.id || null
        this.form.productType = this.selectedProduct?.type || null
      })
    },
    clearForm() {
      this.form.amount = ""
      this.$nextTick(() => {
        this.prefillFormWithOneTimeProductValues()
      })
    }
  },
};
</script>

<style scoped>
.chip-with-border {
  border: 1px solid transparent;
}
</style>