<template>
  <v-card>
    <v-card-title class="py-2 d-flex justify-space-between">
      <div class="d-flex flex-column">
        <span>{{ $t("label.sales_stats") }}</span>
        <span class="text-caption">{{ selectedRange.text }}</span>
      </div>

      <v-menu offset-y left>
        <template v-slot:activator="{ attrs, on }">
          <v-btn icon small v-bind="attrs" v-on="on"
            ><v-icon>mdi-filter-outline</v-icon></v-btn
          >
        </template>

        <v-list>
          <v-list-item
            @click="filterRange(item)"
            v-for="item in rangeFilter.selectOptions"
            :key="item.value"
            link>
            <v-list-item-title v-text="item.text" />
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>

    <v-divider />

    <v-card-text>
      <v-progress-circular
        size="30"
        class="d-flex ma-auto"
        v-if="loading"
        indeterminate />

      <v-list dense v-else>
        <v-list-item dense v-for="saleStat in saleStats" :key="saleStat.name">
          <v-list-item-content class="d-flex">
            <div class="d-flex justify-space-between">
              <span class="text-subtitle-1">{{ $tc(saleStat.name, 1) }}</span>

              <div class="d-flex">
                <v-chip class="mr-4" small label>{{ saleStat.amount }}</v-chip>

                <div style="width: 100px; text-align: right">
                  <v-chip small label>{{
                    saleStat.totalCost | currency
                  }}</v-chip>
                </div>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import schoolService from "@/services/schoolService";
import productsService from "@/services/productsService";
import daysFilter from "@/utils/mixins/daysFilter";

export default {
  name: "SalesStats",
  mixins: [daysFilter],
  data: () => ({
    lessonTypes: [],
    dates: [],
    loading: true,
    saleStats: [],
  }),
  mounted() {
    productsService
      .lessonTypes()
      .then((response) => {
        this.lessonTypes = (response.data || []).filter(
          (item) => item.payable !== "no_cost"
        );
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.loading = false;
      });

    this.load();
  },
  methods: {
    filterRange(filterItem) {
      this.rangeFilter.selectValue = filterItem.value;

      this.load();
    },
    load() {
      this.loading = true;
      schoolService
        .saleStats({
          from: this.datesRange.from,
          to: this.datesRange.to,
        })
        .then((response) => {
          this.saleStats = response.data || {};
          this.saleStats
            // Sort by product amount descending
            .sort(function (prev, next) {
              return next.amount - prev.amount;
            })
            // Sort by total cost descending
            .sort(function (prev, next) {
              return next.totalCost - prev.totalCost;
            });
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
