<template>
  <v-sheet elevation="2" class="mb-3 pa-3">
    <v-row>
      <v-col cols="2" class="d-flex align-center">
        <strong>{{ licenseName }}</strong>
        <v-icon color="red" class="ml-1" @click="$emit('licenseDelete')">mdi-close</v-icon>
      </v-col>
      <v-col class="d-flex justify-end">
        <div class="d-flex justify-space-between">
          <ValidationProvider
            name="lessons"
            :rules="`${selectedLessons.length ? '' : 'required'}`"
            v-slot="{ errors }">
            <v-select
              :disabled="!lessonTypes.length"
              :error-messages="errors"
              :items="lessonTypes"
              v-model="selectedLessons"
              :label="$t('label.lesson_types')"
              item-value="id"
              item-text="name"
              solo
              dense
              hide-details
              multiple>
            </v-select>
          </ValidationProvider>
        </div>

        <v-menu offset-y v-if="selectedLessons.length">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2" v-bind="attrs" v-on="on" color="primary">
              <v-icon>mdi-content-duplicate</v-icon>
            </v-btn>
          </template>
          <v-list max-height="300">
            <v-list-item
              @click.left="emitClone(item.id)"
              v-for="(item, index) in activeLicense"
              :key="index"
              style="background: #fff"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" md="6" lg="6">
        <PriceSection
          v-for="lesson in item.lessons"
          :key="lesson.lessonTypeId"
          :lesson="lesson.lessonTypeId"
          :discount="lesson.discountAmount"
          :license="license"
          @emitDelete="deleteThisLesson"
          @discountChange="onDiscountChange"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import LicenseModel from "@/store/models/LicenseModel";
import LessonTypeModel from "@/store/models/LessonTypeModel";
import PriceSection from "@/components/happyHours/PriceSection";

export default {
  name: "LessonTypesSection",
  components: { PriceSection },
  data: () => ({
    lessonsIdsArr: [],
  }),
  props: {
    license: {
      type: Number,
      required: true,
    },
    activeLicense: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.lessonsIdsArr = this.item.lessons.map(lesson => lesson.lessonTypeId) || []
  },
  overlapped(val) {
    if (val.length) this.$refs.happyHourErrorDialog.open();
  },
  computed: {
    selectedLessons: {
      get() {
        return this.lessonsIdsArr || [];
      },
      set(val) {
        const pickedLesson = val[val.length - 1];
        this.lessonsIdsArr = [...this.lessonsIdsArr, pickedLesson];

        this.$emit("addLessons", { license: this.license, lesson: pickedLesson });
      },
    },
    licenseName() {
      const license = LicenseModel.find(this.license) || {};
      return license.name;
    },
    lessonTypes() {
      const filteredTypes =
        LessonTypeModel.query()
          .where("eventTypeId", "PRACTICE")
          .where((item) => item.group === "normal" || item.group === "special")
          .get() || [];

      const types = filteredTypes.map((lessonType) => {
        return { ...lessonType, name: this.$tc(lessonType.name, 1) };
      });
      return (
        types.filter((type) => !this.selectedLessons.includes(type.id)) || []
      );
    },
  },
  methods: {
    emitClone(license) {
      const data = { clonedLicense: this.license, newLicense: license };
      return this.$emit("clone", data);
    },
    discountProp(lesson) {
      return (
        this.item.lessons.find((item) => item.lessonTypeId === lesson) || {}
      );
    },
    deleteThisLesson(val) {
      this.$emit("deleteLesson", { license: this.license, lessonTypeId: val });
    },
    onDiscountChange(lessonTypeId, value) {
      const lessonIndex = this.item.lessons.findIndex(lesson => lesson.lessonTypeId === lessonTypeId)

      if (lessonIndex !== -1) {
        this.$set(this.item.lessons[lessonIndex], 'discountAmount', value)
      }
    }
  },
};
</script>

<style scoped lang="scss"></style>
