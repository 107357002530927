<template>
  <div>
    <v-toolbar elevation="3">
      <v-btn v-if="!mdLandscapeAndUp" :to="{ name: 'personal-settings' }" icon exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title>
        {{ $t('route.personalSettings.children.parents') }}
      </v-card-title>

      <v-spacer/>

      <v-btn
        v-if="parents.length"
        text
        color="primary"
        :loading="false"
        :disabled="false"
        @click="openLinkParentDialog"
      >
        {{ $t('label.link_parent') }}
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <ChildrenAccountsSkeletonLoader v-if="loading"/>

      <template v-else>
        <v-card-subtitle>{{ $t("label.parent_accounts_explanation") }}</v-card-subtitle>
        <div v-if="!parents.length" class="d-flex flex-column align-center pb-5">
          <empty-box :text=" $t('label.no_linked_parents_accounts')" />
          <v-btn x-large color="secondary" @click="openLinkParentDialog">
            <v-icon left>mdi-account-plus</v-icon>
            {{ $t('label.link_parent') }}
          </v-btn>
        </div>

        <template v-else>
          <v-divider></v-divider>

          <ParentsList :parents="parents"/>
        </template>
      </template>
    </v-card-text>

    <LinkParentAccountDialog ref="linkParentAccountDialog"/>
  </div>
</template>

<script>
import user from '@/utils/mixins/user'
import responsive from '@/utils/mixins/responsive'
import ChildrenAccountsSkeletonLoader from '@/components/skeleton-loaders/ChildrenAccountsSkeletonLoader.vue'
import LinkParentAccountDialog from '@/components/parent/LinkParentAccountDialog.vue'
import ParentsList from '@/components/ParentsList.vue'
import ParentModel from '@/store/models/ParentModel'
import { mapActions } from 'vuex'

export default {
  name: 'Parents',
  components: {
    ParentsList,
    ChildrenAccountsSkeletonLoader,
    LinkParentAccountDialog
  },
  mixins: [user, responsive],
  computed: {
    parents() {
      return ParentModel.query().get() || []
    },
    loading() {
      return this.$store.state.parents.loadingParents
    }
  },
  mounted () {
    this.fetchParents()
  },
  methods: {
    ...mapActions("parents", ["fetchParents"]),
    openLinkParentDialog () {
      this.$refs.linkParentAccountDialog.open()
    },
  },
}
</script>

<style scoped></style>
