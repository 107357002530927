import api from "@/utils/api";

export default {
  getParentsList: () => {
    return new Promise((resolve, reject) => {
      api
        .get(`eltern`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  linkParentToStudent: (code) => {
    return new Promise((resolve, reject) => {
      api
        .post(`eltern`, code)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  grantParentAccess: (id) => {
    return new Promise((resolve, reject) => {
      api
        .patch(`eltern/${id}`, {'grantedPermission': true})
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  unlinkParentFromStudent: (id) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`eltern/${id}`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },

};
