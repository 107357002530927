<script>
import insightsService from '@/services/insightsService'
import TemplateRenderer from '@/components/insights/TemplateRenderer.vue'
import user from '@/utils/mixins/user'
import EvaluationModel from '@/store/models/insights/EvaluationModel'
import TemplateModel from '@/store/models/insights/TemplateModel'
import EvaluationItemModel from '@/store/models/insights/EvaluationItemModel'
import TemplateOverallProgressByStudentLicense from '@/components/insights/TemplateOverallProgressByStudentLicense.vue'
import StudentEvaluations from '@/components/insights/StudentEvaluations.vue'

export default {
  name: 'StudentLicenseInsights',
  mixins: [user],
  components: { StudentEvaluations, TemplateOverallProgressByStudentLicense, TemplateRenderer },
  data() {
    return {
      loading: true,
      progress: null,
    }
  },
  computed: {
    studentId() {
      return this.isStudent ? this.currentUser.id : parseInt(this.$route.params.id)
    },
    licenseId() {
      return parseInt(this.$route.params.licenseId)
    },
    teachersIds() {
      return [...new Set(this.progress?.evaluations.map(item => item.appointmentData.teacherId))] || []
    }
  },
  mounted() {
    this.getProgress()
  },
  methods: {
    getProgress() {
      insightsService.getStudentLicenseProgress(this.studentId, this.licenseId)
      .then(resp => {
        this.progress = resp.data || {}

        const evaluations = this.progress.evaluations || []
        const evaluationsItems = this.progress.evaluations.map(item => item.items).flat()

        EvaluationModel.create({ data: evaluations })
        EvaluationItemModel.create({ data: evaluationsItems })

        insightsService.getTemplate(this.progress.insightsTemplateId)
          .then((response) => {
            TemplateModel.create({ data: response.data })
          })
      })
      .catch(error => {
        const response = error.response || {}

        if ([404, 400].includes(response.status)) {
          let to = { name: 'students.student.education', params: { id: this.studentId } }
          if (response.status === 400) {
            to = { name: 'students.student.education.license', params: { id: this.studentId, licenseId: this.licenseId } }
          }

          this.$router.push(to)
        }
      })
      .finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<template>
<div :class="{'d-flex align-center justify-center': loading}">
  <v-progress-circular indeterminate color="primary" v-if="loading" />

  <template v-else>
    <template-overall-progress-by-student-license
        :loading="loading"
        :average-score="progress?.averageScore"
        :overall-progress="progress.overallProgress"
        :has-evaluated-appointment="progress.evaluations.length > 0"
        :teachers-ids="teachersIds"
        :student-id="studentId"
        :license-id="licenseId"
    />
    <student-evaluations
      v-if="progress.evaluations.length > 0"
      :evaluations="progress.evaluations"
      :is-self-evaluated="progress.selfEvaluation"
    />
    <template-renderer
        tile
        :template-id="progress.insightsTemplateId"
        :progress="progress"
        :show-toolbar="false"
        :show-description="false"
    />
  </template>
</div>
</template>

<style scoped>

</style>
