<template>
  <v-card
    class="dragged-event event"
    :style="`${styleCSS} background: ${eventColor.bodyColor}; color: ${eventColor.fontColor};`">
    <div v-if="event.teacher" class="color-line" :style="`height: 8px; background:${event.teacher.color}`" />
    <div v-else class="spacer"></div>
    <div class="event-content px-2 pt-1">
      <div class="text-overflow-ellipsis">
        {{ time }}
      </div>
      <div class="font-weight-bold text-overflow-ellipsis" v-if="event.teacher && !isTeacher">
        {{ event.teacher.fullName }}
      </div>
      <div>
        <div class="text-overflow-ellipsis" v-if="!isStudent && event.type !== 'THEORY' && event.studentsIds.length">
          {{ studentsLabel }}
        </div>
        <div class="text-overflow-ellipsis" v-if="event.type !== 'PRACTICE'">
          {{ eventLabel }}
        </div>
        <div v-else class="text-overflow-ellipsis">
          {{ licenseName }}
          <span v-if="lessonTypeLabel" class="text-uppercase">— {{ lessonTypeLabel }}</span>
        </div>
        <div v-if="event.type === 'SPECIAL'" class="text-overflow-ellipsis">
          {{ event.title }}
        </div>
        <div v-if="event.type === 'THEORY'" class="text-overflow-ellipsis">
          <v-icon color="white" class="mr-1" size="15">
            {{theoryTopic.type === "basic" ? "mdi-alpha-g-box" : "mdi-alpha-z-box" }}
          </v-icon>
          {{ theoryTopicLabel }}
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import LessonTypeModel from "@/store/models/LessonTypeModel";
import responsive from "@/utils/mixins/responsive";
import user from "@/utils/mixins/user";
import colorMixin from "@/utils/mixins/colorMixin";
import LicenseModel from "@/store/models/LicenseModel";
import StudentModel from "@/store/models/StudentModel";
import TheoryTopicModel from "@/store/models/TheoryTopicModel";
import i18n from "@/plugins/i18n";

export default {
  name: "DraggedEvent",
  mixins: [responsive, user, colorMixin],
  props: {
    event: {
      type: Object,
      required: true,
    },
    styleCSS: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
  },
  computed: {
    studentsLabel() {
      const student = StudentModel.find(this.event.studentsIds[0]) || {};
      return this.event.studentsIds.length === 1 ? student.fullName : "";
    },
    lessonTypeLabel() {
      const type = LessonTypeModel.find(this.event.lessonTypeId) || {};
      return this.$t(type.name);
    },
    licenseName() {
      const getLicense = LicenseModel.find(this.event.licenseId) || {};
      return getLicense.name;
    },
    theoryTopic() {
      return TheoryTopicModel.find(this.event.theoryTopicId) || {};
    },
    theoryTopicLabel() {
      return this.event.group === "lesson"
        ? `${i18n.t("form.topic")} ${this.theoryTopic.orderIndex}: ${
            this.theoryTopic.title
          }`
        : "";
    },
    eventLabel() {
      const type = LessonTypeModel.find(this.event.lessonTypeId) || {};
      if (this.event.type === "THEORY") {
        return this.$t(type.name) || null;
      } else {
        return this.event.eventTypeTranslation;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dragged-event {
  pointer-events: none;
  overflow: hidden;
  color: #fff;
  z-index: 8;
  min-width: 10%;
  border-radius: 5px;
  position: absolute;
  cursor: grab;
  width: calc(100% - 10px);
  transition: opacity;

  & > * {
    pointer-events: none;
    font-size: 11px;
    padding: 0 5px;
  }
  .spacer {
    height: 8px;
  }
}
</style>
