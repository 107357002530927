import api from "@/utils/api";

export default {
  events: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("/student/events", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  nextEvents: (params) => {
    return new Promise((resolve, reject) => {
      api
        .get("/student/next-events", { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  licensesStats: () => {
    return new Promise((resolve, reject) => {
      api
        .get("/student/licenses-stats")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  switchEmailNotifications: (params) => {
    return new Promise((resolve, reject) => {
      api
        .patch("/student/switch-email-notifications", { ...params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
};
