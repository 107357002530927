<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="100"
    :nudge-bottom="15"
    z-index="10"
    offset-y
    offset-x>
    <template v-slot:activator="{ on }">
      <v-btn class="ma-2" icon text v-on="on"><v-icon>mdi-apps</v-icon></v-btn>
    </template>

    <v-card>
      <div class="menu">
        <router-link
          class="menu__link"
          v-for="item in menuItems"
          :key="item.title"
          :exact="true"
          :to="item.route">
          <v-img
            width="50"
            height="50"
            :contain="true"
            :src="item.icon"></v-img>
          <span class="menu__text">{{ item.title }}</span>
        </router-link>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "CalendarNavigationRoutes",
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
};
</script>

<style scoped lang="scss">
.menu {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;

  a {
    text-decoration: none;
    margin: 10px;
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-decoration: none;
  }

  &__text {
    margin-top: 5px;
    font-weight: 400;
    color: black;
    font-size: 14px;
  }
}
</style>
