<template>
  <v-autocomplete
    dense
    solo
    hide-details
    item-text="text"
    item-value="value"
    :disabled="loading"
    :label="$t('form.price_category')"
    :placeholder="$t('form.price_category')"
    :items="categoriesAsOptions"
    :value="priceCategoryId"
    @change="onChange"
  >
    <template v-slot:item="{ item }">
      <div class="d-flex justify-space-between">
        <div>
          {{ item.text }}
          <span class="text-caption">({{ item.studentsCount }} {{ $t("students") }})</span>
        </div>
        <v-btn class="ml-1" v-if="item.isDefault" label outlined small disabled>{{ $t("label.default") }}</v-btn>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import ProductCategoryModel from "@/store/models/ProductCategoryModel";

export default {
  name: "PricesListsCategoriesSelect",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    priceCategoryId: {
      type: [Number, String],
    },
  },
  computed: {
    categoriesAsOptions() {
      return ProductCategoryModel.all().map((item) => {
        return {
          text: item.name,
          value: item.id,
          studentsCount: item.studentsCount,
          isDefault: item.isDefault,
        };
      });
    },
  },
  methods: {
    onChange(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style scoped></style>
